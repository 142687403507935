import React from "react";
import styled from "styled-components";

function Flashcard() {
  return <div>

  </div>
}

export default styled(Flashcard)`

`;