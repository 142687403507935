import React, { useState, useRef, useLayoutEffect } from "react";
import hangul from "hangul-js";

import styled from "styled-components";

import Block from "./Block.jsx";
import Letter from "./Letter.jsx";

import Level from "./Level.js";
import Modal from "./Modal.jsx";
import CheckBox from "./CheckBox.jsx";

import Keyboard from "./Keyboard.jsx";
import { lastCharState, attemptsState } from "../atoms/atoms.js";
import { useRecoilState, useRecoilValue } from "recoil";

function getStyle(elem, name) {
  // J/S Pro Techniques p136
  if (elem.style[name]) {
    return elem.style[name];
  } else if (elem.currentStyle) {
    return elem.currentStyle[name];
  } else if (document.defaultView && document.defaultView.getComputedStyle) {
    name = name.replace(/([A-Z])/g, "-$1");
    name = name.toLowerCase();
    let s = document.defaultView.getComputedStyle(elem, "");
    return s && s.getPropertyValue(name);
  } else {
    return null;
  }
}

function Display({
  levels,
  setLevel,
  newChar,
  answers,
  string,
  input,
  level,
  streak,
  setInput,
  ...props
}) {
  const lastChar = useRecoilValue(lastCharState);
  const attempts = useRecoilValue(attemptsState);
  const [hideKeyboard, setHideKeyboard] = React.useState(true);

  const [temp, setTemp] = useState(false);
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    document.getElementsByClassName("grid")[0].scrollLeft =
      document.getElementsByClassName("active")[0].offsetWidth / 2 +
      document.getElementsByClassName("active")[0].offsetLeft -
      document.getElementsByClassName("grid")[0].offsetWidth / 2;
  }, [string]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    let element = document.getElementsByClassName("margin")[0];
    let padding = getStyle(element, "paddingLeft"); // eg "10px"

    if (answers.length === 6) {
      if (temp === false) {
        setTemp(true);
      } else {
        element.style.paddingLeft = parseInt(padding, 10) + 100 + "px";
      }
    }
    document.getElementsByClassName("grid")[0].scrollLeft =
      document.getElementsByClassName("active")[0].offsetWidth / 2 +
      document.getElementsByClassName("active")[0].offsetLeft -
      document.getElementsByClassName("grid")[0].offsetWidth / 2;
  }, [answers, temp]);

  let lchar = "";
  for (const [i, char] of hangul.disassemble(string[0]).entries()) {
    if (hangul.disassemble(input)[i] !== char) {
      lchar = char;
      break;
    }
  }

  return (
    <div {...props}>
      <Modal level={level}></Modal>
      <Level
        setInput={setInput}
        setLevel={setLevel}
        streak={streak}
        level={level}
      />
      <div className="grid">
        <div className="scroller">
          <div className="margin"></div>
          {answers.map((answer, i) => {
            return (
              <Block className="answered">
                {hangul.disassemble(answer).map((char, i) => {
                  return (
                    <Letter
                      index={i}
                      active={true}
                      highlight={true}
                      overlay={hangul.disassemble(answer)}
                    >
                      {char}
                    </Letter>
                  );
                })}
              </Block>
            );
          })}
          <Block className="active">
            {hangul.disassemble(string[0]).map((char, i) => {
              if (hangul.disassemble(input)[i] === char) {
                return (
                  <Letter
                    index={i}
                    active={true}
                    highlight={true}
                    overlay={hangul.disassemble(string[0])}
                  >
                    {char}
                  </Letter>
                );
              } else if (
                hangul.disassemble(input).length === i &&
                lastChar !== undefined &&
                char !== lastChar
              ) {
                return (
                  <Letter
                    index={i}
                    key={attempts}
                    active={true}
                    shake={true}
                    overlay={hangul.disassemble(string[0])}
                  >
                    {char}
                  </Letter>
                );
              }
              return (
                <Letter
                  index={i}
                  active={true}
                  overlay={hangul.disassemble(string[0])}
                >
                  {char}
                </Letter>
              );
            })}
          </Block>
          {[...string.slice(1, 7)].map((string, i) => {
            return (
              <Block className="next">
                {hangul.disassemble(string).map((char, i) => {
                  return (
                    <Letter
                      index={i}
                      active={true}
                      overlay={hangul.disassemble(string[0])}
                    >
                      {char}
                    </Letter>
                  );
                })}
              </Block>
            );
          })}
        </div>
      </div>
      {!hideKeyboard && (
        <Keyboard char={lchar.toLowerCase()} levels={levels} level={level} />
      )}
      <div style={{ alignSelf: "flex-end", marginRight: "auto", marginLeft: "auto", marginTop:"32px" }}>
        <CheckBox
          checked={hideKeyboard}
          setChecked={setHideKeyboard}
        ></CheckBox>
      </div>
    </div>
  );
}

export default styled(Display)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .scroller {
    .margin {
      content: "";
      display: inline-block;
      transition: 0s ease 0s;
    }
    margin-left: calc(50% - 50px);
    height: 100%;
    overflow: visible;
    white-space: nowrap;
    padding-right: 1000000%;
    > div {
      display: inline-block;
    }
  }

  .grid {
    overflow: hidden;
  }
`;
