import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import svgMap from "../svg_map";
import {map} from "../svg_map_map"
import styled from "styled-components";
import {lookup} from "./../info";
import hangul from "hangul-js"

// arr=( * )   
// echo "${arr[@]}"
// more specific paths first followed by fallback paths
// specific paths tend to have a lot of (*) in them and general paths tend to have c, hd, hu, v in them.
// only 1 character should be specified per path which is done by not wrapping it in brackets
// the length of the syllable block is identified by the last character
// order by large syllable blocks first since require the most amount of lookups so it should be more efficient.


function Letter({children, overlay, ...props}) {
    const [resolved, setResolved] = useState([]);
    
    useEffect(() => {
        const paths = []
        for (const [index, letter] of overlay.entries()) {
            paths.push(map[hangul.assemble(overlay)][index])
        }
        setResolved(paths)
    }, [children, overlay])

    return <>
        {
            resolved.map((path, i) => {   
                if (i === props.index) {
                    return React.cloneElement(
                        svgMap[path].path, {
                            className: `
                            svg
            
                            ${props.highlight ? 'highlight' : ''} 
                            ${props.shake ? 'shake' : ''}
                            `, 
                            transform: "translate(0 0)"
                        }
                    )}
                    return <></>
                }
            )
        }
    </>
}

export default styled(Letter)`

`