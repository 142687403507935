export default [
  "것",
  "하다",
  "있다",
  "수",
  "하다",
  "나",
  "없다",
  "않다",
  "사람",
  "우리",
  "그",
  "아니다",
  "보다",
  "거",
  "보다",
  "같다",
  "주다",
  "대하다",
  "가다",
  "년",
  "한",
  "말",
  "일",
  "이",
  "말하다",
  "위하다",
  "그러나",
  "오다",
  "알다",
  "씨",
  "그렇다",
  "크다",
  "일",
  "사회",
  "많다",
  "안",
  "좋다",
  "더",
  "받다",
  "그것",
  "집",
  "나오다",
  "그리고",
  "문제",
  "그런",
  "살다",
  "저",
  "못하다",
  "생각하다",
  "모르다",
  "속",
  "만들다",
  "데",
  "앞",
  "경우",
  "중",
  "어떤",
  "잘",
  "그녀",
  "먹다",
  "자신",
  "문화",
  "원",
  "생각",
  "어떻다",
  "명",
  "통하다",
  "그러다",
  "그러다",
  "소리",
  "다시",
  "다른",
  "이런",
  "여자",
  "개",
  "정도",
  "다",
  "좀",
  "싶다",
  "보이다",
  "가지다",
  "함께",
  "아이",
  "지나다",
  "많이",
  "시간",
  "너",
  "인간",
  "사실",
  "나다",
  "이렇다",
  "어머니",
  "눈",
  "뭐",
  "점",
  "의하다",
  "시대",
  "다음",
  "이러하다",
  "누구",
  "곳",
  "여러",
  "안",
  "하나",
  "세계",
  "버리다",
  "위",
  "운동",
  "퍼센트",
  "학교",
  "자기",
  "가장",
  "대통령",
  "가지",
  "시작하다",
  "바로",
  "어느",
  "그래서",
  "무엇",
  "정부",
  "모든",
  "번",
  "그거",
  "돈",
  "국가",
  "그런데",
  "날",
  "여기",
  "모두",
  "여성",
  "친구",
  "마음",
  "후",
  "놓다",
  "관계",
  "아버지",
  "남자",
  "어디",
  "몸",
  "얼굴",
  "왜",
  "나타나다",
  "지역",
  "다르다",
  "모습",
  "물",
  "만나다",
  "내다",
  "보이다",
  "쓰다",
  "이것",
  "없이",
  "이번",
  "길",
  "생활",
  "쓰다",
  "뿐",
  "사이",
  "방법",
  "새롭다",
  "내다",
  "앉다",
  "처음",
  "손",
  "몇",
  "그때",
  "과정",
  "삶",
  "갖다",
  "찾다",
  "특히",
  "시",
  "이상",
  "지금",
  "나가다",
  "이야기",
  "교육",
  "사다",
  "경제",
  "아직",
  "잡다",
  "같이",
  "선생님",
  "예술",
  "서다",
  "못",
  "역사",
  "읽다",
  "결과",
  "내용",
  "물론",
  "책",
  "일어나다",
  "당신",
  "시장",
  "넣다",
  "중요하다",
  "무슨",
  "느끼다",
  "어렵다",
  "힘",
  "너무",
  "나라",
  "부르다",
  "의미",
  "자리",
  "밝히다",
  "죽다",
  "이미",
  "쪽",
  "정치",
  "국민",
  "생명",
  "얘기",
  "학생",
  "연구",
  "엄마",
  "이름",
  "하나",
  "내리다",
  "사건",
  "및",
  "쉽다",
  "짓다",
  "이유",
  "필요하다",
  "글",
  "생기다",
  "사용하다",
  "남편",
  "밖",
  "세상",
  "작다",
  "타다",
  "대학",
  "작품",
  "상황",
  "가운데",
  "보내다",
  "즉",
  "상태",
  "이후",
  "당시",
  "문학",
  "더욱",
  "아주",
  "지방",
  "밤",
  "높다",
  "최근",
  "채",
  "현실",
  "환경",
  "컴퓨터",
  "먼저",
  "다니다",
  "얼마나",
  "자체",
  "열다",
  "머리",
  "묻다",
  "남다",
  "부분",
  "기업",
  "변화",
  "아들",
  "아",
  "선거",
  "관하다",
  "분",
  "그냥",
  "나누다",
  "이용하다",
  "거의",
  "곧",
  "중심",
  "활동",
  "오늘",
  "서로",
  "관심",
  "역시",
  "이거",
  "애",
  "광고",
  "나다",
  "방",
  "정신",
  "이르다",
  "이루다",
  "아침",
  "웃다",
  "현상",
  "기술",
  "전체",
  "그래",
  "얻다",
  "아름답다",
  "끝",
  "민족",
  "간",
  "조사",
  "듯",
  "입",
  "뭐",
  "그대로",
  "영화",
  "필요",
  "줄",
  "하늘",
  "년대",
  "과학",
  "자연",
  "정말",
  "구조",
  "결국",
  "밥",
  "입다",
  "오히려",
  "프로그램",
  "네",
  "이루어지다",
  "남",
  "하루",
  "그림",
  "적",
  "터",
  "마시다",
  "치다",
  "혼자",
  "나가다",
  "이제",
  "교수",
  "술",
  "사랑",
  "전화",
  "끝나다",
  "맞다",
  "아빠",
  "걸리다",
  "지키다",
  "한번",
  "커피",
  "가슴",
  "길다",
  "바라보다",
  "알아보다",
  "맛",
  "대부분",
  "산업",
  "매우",
  "오르다",
  "음식",
  "표정",
  "꼭",
  "일부",
  "요즘",
  "계획",
  "느낌",
  "얼마",
  "고개",
  "성격",
  "계속",
  "세기",
  "세우다",
  "아내",
  "가족",
  "현재",
  "세",
  "발전",
  "차",
  "놀다",
  "향하다",
  "관련",
  "형태",
  "각",
  "도시",
  "작업",
  "분위기",
  "그러하다",
  "나이",
  "우선",
  "믿다",
  "바꾸다",
  "낳다",
  "바",
  "정보",
  "열리다",
  "좋아하다",
  "그리다",
  "만큼",
  "배우다",
  "시",
  "역할",
  "옆",
  "행동",
  "어",
  "국내",
  "비하다",
  "기관",
  "입장",
  "만하다",
  "예",
  "아래",
  "방식",
  "영향",
  "그럼",
  "나서다",
  "흐르다",
  "저",
  "깊다",
  "배",
  "내",
  "모양",
  "산",
  "새",
  "하지만",
  "조건",
  "문",
  "꽃",
  "단계",
  "올리다",
  "그동안",
  "교사",
  "갑자기",
  "넘다",
  "지니다",
  "바람",
  "잘하다",
  "마을",
  "어리다",
  "대표",
  "가능성",
  "방향",
  "대회",
  "목소리",
  "노래",
  "바다",
  "힘들다",
  "공부",
  "움직이다",
  "의원",
  "노력",
  "전혀",
  "언니",
  "단체",
  "분",
  "알려지다",
  "가능하다",
  "능력",
  "주장하다",
  "자식",
  "불",
  "주민",
  "모으다",
  "자료",
  "존재",
  "학년",
  "신문",
  "가지다",
  "이해하다",
  "제품",
  "분야",
  "선생",
  "사업",
  "행위",
  "수준",
  "지난해",
  "표현",
  "기분",
  "대",
  "젊다",
  "옷",
  "기능",
  "순간",
  "전쟁",
  "전",
  "꿈",
  "할머니",
  "회의",
  "방송",
  "이야기하다",
  "나무",
  "자다",
  "연극",
  "마찬가지",
  "걷다",
  "노동",
  "이때",
  "과거",
  "가치",
  "시간",
  "집단",
  "현대",
  "살펴보다",
  "장관",
  "차이",
  "풀다",
  "시절",
  "물건",
  "직접",
  "개인",
  "근데",
  "발",
  "작가",
  "효과",
  "불교",
  "끌다",
  "대로",
  "빨리",
  "시작되다",
  "말다",
  "설명하다",
  "우주",
  "시기",
  "마치",
  "살",
  "생산",
  "바라다",
  "강하다",
  "경험",
  "음악",
  "최고",
  "나타내다",
  "아프다",
  "적다",
  "비",
  "고향",
  "놀라다",
  "다양하다",
  "울다",
  "농민",
  "은행",
  "지내다",
  "결혼",
  "법",
  "소설",
  "예",
  "오후",
  "질서",
  "담다",
  "모이다",
  "시민",
  "회장",
  "빠르다",
  "스스로",
  "아기",
  "아저씨",
  "옛날",
  "이날",
  "제대로",
  "달",
  "던지다",
  "참",
  "공간",
  "이곳",
  "마지막",
  "벌이다",
  "병원",
  "자세",
  "강조하다",
  "경찰",
  "맡다",
  "저녁",
  "한편",
  "그러면",
  "기자",
  "넓다",
  "시험",
  "잠",
  "주로",
  "훨씬",
  "면",
  "통일",
  "건강",
  "가깝다",
  "건물",
  "시설",
  "외국",
  "밑",
  "어른",
  "주변",
  "대신",
  "원인",
  "팔다",
  "차례",
  "군",
  "열심히",
  "일하다",
  "재산",
  "팀",
  "부모",
  "약간",
  "언어",
  "요구하다",
  "올라가다",
  "첫",
  "감독",
  "그날",
  "사실",
  "자주",
  "당하다",
  "삼다",
  "약",
  "기간",
  "담배",
  "일으키다",
  "일단",
  "할아버지",
  "조직",
  "태어나다",
  "공장",
  "벌써",
  "즐기다",
  "지",
  "환자",
  "변하다",
  "사고",
  "불",
  "아무리",
  "맞추다",
  "쌀",
  "일반",
  "재미있다",
  "가르치다",
  "대화",
  "막다",
  "올해",
  "형",
  "달리",
  "버리다",
  "붙이다",
  "인물",
  "늘",
  "모두",
  "전국",
  "마치다",
  "전",
  "다만",
  "도움",
  "가정",
  "걸다",
  "빠지다",
  "멀다",
  "버스",
  "오늘날",
  "잠시",
  "농업",
  "대다",
  "식",
  "의견",
  "무대",
  "사진",
  "주장",
  "표현하다",
  "인하다",
  "이상하다",
  "제일",
  "붙다",
  "아마",
  "얘기하다",
  "잇다",
  "조금",
  "경기",
  "목적",
  "태도",
  "남성",
  "주위",
  "대책",
  "그만",
  "발생하다",
  "다리",
  "아무",
  "어쩌다",
  "재료",
  "각각",
  "결코",
  "옮기다",
  "항상",
  "해",
  "잃다",
  "자유",
  "책임",
  "바뀌다",
  "비슷하다",
  "심하다",
  "경쟁",
  "사랑하다",
  "아니",
  "여름",
  "자라다",
  "회",
  "구체적",
  "기회",
  "실시하다",
  "지구",
  "번째",
  "소비자",
  "싫다",
  "규모",
  "기준",
  "말",
  "반드시",
  "셈",
  "갖추다",
  "그러니까",
  "받아들이다",
  "값",
  "현장",
  "건설",
  "꺼내다",
  "노동자",
  "언제나",
  "완전히",
  "자동차",
  "전하다",
  "존재하다",
  "개월",
  "맞다",
  "별로",
  "어린이",
  "정하다",
  "한마디",
  "유지하다",
  "이데올로기",
  "공부하다",
  "대중",
  "늘어나다",
  "닦다",
  "만",
  "말씀",
  "괜찮다",
  "눈물",
  "각종",
  "빛",
  "아니",
  "피하다",
  "거치다",
  "나아가다",
  "야",
  "지식",
  "여전히",
  "주인",
  "발견하다",
  "선",
  "인류",
  "특징",
  "선수",
  "형식",
  "마련하다",
  "반",
  "발표하다",
  "주제",
  "걸치다",
  "겪다",
  "관점",
  "귀",
  "기본",
  "미터",
  "사라지다",
  "어떠하다",
  "감정",
  "기억",
  "놈",
  "인기",
  "배",
  "아파트",
  "가끔",
  "구성",
  "술",
  "실제로",
  "짧다",
  "고맙다",
  "관리",
  "그곳",
  "보다",
  "비롯하다",
  "과연",
  "달리다",
  "바쁘다",
  "이전",
  "인정하다",
  "자",
  "중앙",
  "나쁘다",
  "불구하다",
  "시키다",
  "게임",
  "국제",
  "그룹",
  "인생",
  "전통",
  "기르다",
  "잔",
  "조사하다",
  "커다랗다",
  "있다",
  "시인",
  "언제",
  "외",
  "평가",
  "내려오다",
  "위치",
  "줄이다",
  "가격",
  "달라지다",
  "비다",
  "삼국",
  "손님",
  "원하다",
  "통신",
  "확인하다",
  "모임",
  "수",
  "웃음",
  "기계",
  "모양",
  "물질",
  "아나운서",
  "뉴스",
  "살아가다",
  "펴다",
  "배",
  "수업",
  "겨울",
  "종교",
  "층",
  "자연스럽다",
  "장",
  "식사",
  "안다",
  "이해",
  "잊다",
  "제시하다",
  "반",
  "불과하다",
  "혹은",
  "엄청나다",
  "편",
  "텔레비전",
  "파악하다",
  "편",
  "실천",
  "노력하다",
  "보호",
  "씻다",
  "늦다",
  "이웃",
  "편지",
  "공동",
  "까닭",
  "방안",
  "센티미터",
  "팔",
  "분명하다",
  "분석",
  "소녀",
  "지나가다",
  "차",
  "상품",
  "설명",
  "훌륭하다",
  "관계자",
  "새로",
  "세",
  "이어지다",
  "티브이",
  "봄",
  "종류",
  "낮다",
  "어깨",
  "부부",
  "오래",
  "요구",
  "키우다",
  "눕다",
  "발전하다",
  "여행",
  "죽음",
  "고통",
  "공",
  "어울리다",
  "오월",
  "쉬다",
  "알리다",
  "차다",
  "과",
  "멀리",
  "빼다",
  "예정",
  "오빠",
  "즐겁다",
  "한계",
  "흔히",
  "바탕",
  "사월",
  "싸우다",
  "예쁘다",
  "갈등",
  "느껴지다",
  "의지",
  "전문",
  "정확하다",
  "초기",
  "나중",
  "맛있다",
  "며칠",
  "쓴",
  "찾아오다",
  "미",
  "사용",
  "시선",
  "아무런",
  "언론",
  "투자",
  "지원",
  "결정하다",
  "경영",
  "목표",
  "성장",
  "숲",
  "없어지다",
  "작년",
  "내려가다",
  "미치다",
  "새벽",
  "쓰레기",
  "얼른",
  "임금",
  "피해",
  "한",
  "무섭다",
  "직장",
  "참다",
  "크기",
  "고기",
  "남기다",
  "서양",
  "주요",
  "가져오다",
  "냄새",
  "부드럽다",
  "여기다",
  "이",
  "공연",
  "남녀",
  "내놓다",
  "만들어지다",
  "속도",
  "심각하다",
  "준비",
  "계속되다",
  "구월",
  "맑다",
  "소년",
  "소식",
  "유월",
  "작용",
  "허리",
  "골",
  "공업",
  "그중",
  "노인",
  "벌다",
  "살리다",
  "새",
  "영어",
  "출신",
  "결정",
  "경향",
  "기록",
  "나름",
  "대답하다",
  "반면",
  "썰다",
  "움직임",
  "이미지",
  "터지다",
  "특성",
  "교장",
  "벗다",
  "업무",
  "입시",
  "준비하다",
  "청소년",
  "응",
  "이기다",
  "찾아보다",
  "취하다",
  "다루다",
  "달",
  "사장",
  "삼월",
  "그렇지만",
  "선배",
  "업체",
  "키",
  "구하다",
  "국회",
  "그러므로",
  "포함하다",
  "걱정",
  "결혼하다",
  "만약",
  "바르다",
  "세월",
  "숨",
  "행사",
  "깨닫다",
  "누나",
  "신",
  "왕",
  "점점",
  "질문",
  "특별",
  "판단",
  "해결하다",
  "거리",
  "계속하다",
  "그치다",
  "근처",
  "너무나",
  "높이다",
  "부정",
  "사정",
  "도대체",
  "막",
  "부모님",
  "수출",
  "계시다",
  "그",
  "자르다",
  "데리다",
  "마리",
  "무척",
  "비용",
  "비행기",
  "옳다",
  "원래",
  "처리",
  "최초",
  "꼴",
  "놀이",
  "뿌리",
  "수입",
  "초",
  "그리하여",
  "낮",
  "일찍",
  "직원",
  "찍다",
  "가볍다",
  "내부",
  "다소",
  "상대",
  "오전",
  "피부",
  "가게",
  "가득",
  "그저",
  "도",
  "벽",
  "장군",
  "무역",
  "부담",
  "약속",
  "인사",
  "줄",
  "쳐다보다",
  "충분히",
  "신체",
  "에너지",
  "위원",
  "정리하다",
  "집안",
  "배경",
  "죽이다",
  "단순하다",
  "반대",
  "법칙",
  "빠지다",
  "소금",
  "오염",
  "자전거",
  "참여하다",
  "탓",
  "푸르다",
  "걔",
  "그래",
  "목",
  "발표",
  "범죄",
  "위",
  "흔들다",
  "기초",
  "논리",
  "뽑다",
  "피우다",
  "감각",
  "미리",
  "부족하다",
  "저희",
  "진행되다",
  "교통",
  "기구",
  "법",
  "오랜",
  "젊은이",
  "후보",
  "거리",
  "과제",
  "근거",
  "기록하다",
  "다가오다",
  "불다",
  "시각",
  "이끌다",
  "종합",
  "한글",
  "가을",
  "개발하다",
  "내일",
  "매일",
  "손가락",
  "수단",
  "자",
  "자유롭다",
  "적극적",
  "판매",
  "형성",
  "기울이다",
  "길이",
  "장면",
  "점차",
  "톤",
  "관련되다",
  "급",
  "나머지",
  "날씨",
  "더불다",
  "의사",
  "개방",
  "건강하다",
  "미래",
  "앞서",
  "여러분",
  "왜냐하면",
  "인구",
  "기대하다",
  "네",
  "도착하다",
  "병",
  "소프트웨어",
  "흘리다",
  "반응",
  "주인공",
  "당연하다",
  "비판",
  "빌리다",
  "세대",
  "축구",
  "형님",
  "놓이다",
  "당장",
  "무렵",
  "밝다",
  "사물",
  "일반적",
  "장소",
  "곱다",
  "바닥",
  "새끼",
  "생각되다",
  "서비스",
  "선택하다",
  "심다",
  "적다",
  "코",
  "간단하다",
  "고등학교",
  "공개",
  "교실",
  "스스로",
  "견디다",
  "기사",
  "막히다",
  "매체",
  "별",
  "복잡하다",
  "뿌리다",
  "영역",
  "체험",
  "구속",
  "어쩌면",
  "극복하다",
  "불법",
  "비밀",
  "색",
  "쓰이다",
  "일정하다",
  "다지다",
  "아까",
  "알맞다",
  "이념",
  "희다",
  "가리키다",
  "모시다",
  "발달",
  "수많다",
  "치르다",
  "평화",
  "공사",
  "박사",
  "성",
  "전문가",
  "단지",
  "말씀하다",
  "무용",
  "불리다",
  "싸움",
  "자꾸",
  "차리다",
  "해외",
  "그리",
  "문화재",
  "미소",
  "보통",
  "식당",
  "의미하다",
  "이래",
  "체육",
  "구성되다",
  "독특하다",
  "사례",
  "소개하다",
  "잘되다",
  "추진하다",
  "칠월",
  "틀",
  "평균",
  "훈련",
  "흐름",
  "십이월",
  "쌓이다",
  "이익",
  "쥐다",
  "컵",
  "게다가",
  "끓이다",
  "논문",
  "멈추다",
  "사용되다",
  "오랫동안",
  "위기",
  "정당",
  "종이",
  "찾아가다",
  "폭력",
  "혹시",
  "늘다",
  "양",
  "절차",
  "계시다",
  "공기",
  "닿다",
  "속하다",
  "올림픽",
  "이외",
  "재미",
  "제공하다",
  "증가하다",
  "기대",
  "식물",
  "옛",
  "외치다",
  "적어도",
  "편하다",
  "평",
  "권리",
  "끝내다",
  "대답",
  "시작",
  "어려움",
  "일주일",
  "자원",
  "춤",
  "넘기다",
  "물체",
  "분명히",
  "시위",
  "아무것",
  "온",
  "젖다",
  "제외하다",
  "최대",
  "평소",
  "견해",
  "깨끗하다",
  "농사",
  "안정",
  "어둠",
  "어둡다",
  "어쨌든",
  "주택",
  "고장",
  "관련하다",
  "눈길",
  "물어보다",
  "미안하다",
  "밀다",
  "스트레스",
  "음",
  "인사",
  "주어지다",
  "고려하다",
  "과일",
  "널리",
  "농촌",
  "올라오다",
  "챙기다",
  "고르다",
  "바르다",
  "벌어지다",
  "소재",
  "전망",
  "포기하다",
  "형성되다",
  "고치다",
  "그림자",
  "눈",
  "다하다",
  "마침내",
  "비교하다",
  "시월",
  "커지다",
  "한쪽",
  "검사",
  "결론",
  "맡기다",
  "박물관",
  "소문",
  "싣다",
  "쌓다",
  "어서",
  "자녀",
  "제목",
  "짓",
  "판결",
  "팔월",
  "하얗다",
  "희망",
  "가방",
  "군대",
  "그만큼",
  "무어",
  "비로소",
  "상대방",
  "서구",
  "소유",
  "시골",
  "실수",
  "잘못되다",
  "치료",
  "폭",
  "호",
  "내밀다",
  "맞다",
  "부문",
  "시리즈",
  "임신",
  "잡히다",
  "규정",
  "그램",
  "밭",
  "분석하다",
  "식구",
  "아예",
  "어찌",
  "울리다",
  "작용하다",
  "확실하다",
  "개선",
  "그릇",
  "글자",
  "바람직하다",
  "연구하다",
  "착하다",
  "개",
  "부동산",
  "신화",
  "양",
  "점",
  "직업",
  "거두다",
  "방학",
  "범위",
  "조상",
  "철학",
  "검다",
  "곁",
  "근본적",
  "너희",
  "대형",
  "문제점",
  "본격적",
  "불가능하다",
  "충격",
  "퍼지다",
  "금방",
  "남쪽",
  "누르다",
  "미술",
  "백성",
  "상당히",
  "색깔",
  "요리",
  "유명하다",
  "자네",
  "기",
  "꽤",
  "외국인",
  "한참",
  "군사",
  "끊다",
  "넘어가다",
  "담기다",
  "마당",
  "부인",
  "서두르다",
  "지적",
  "짝",
  "참으로",
  "충분하다",
  "기쁘다",
  "숙제",
  "앞두다",
  "예산",
  "온갖",
  "우려",
  "우산",
  "기쁨",
  "깊이",
  "꾸미다",
  "늘리다",
  "무릎",
  "발견되다",
  "보호하다",
  "시스템",
  "이용",
  "지난달",
  "지르다",
  "참여",
  "걸음",
  "겨우",
  "마르다",
  "비교적",
  "애쓰다",
  "올바르다",
  "책상",
  "춥다",
  "흔하다",
  "높아지다",
  "늙다",
  "단위",
  "무겁다",
  "바람",
  "상상",
  "소득",
  "수도",
  "역",
  "인식하다",
  "자",
  "침대",
  "권",
  "맺다",
  "수요",
  "스타",
  "시계",
  "입술",
  "잎",
  "중간",
  "지도자",
  "천천히",
  "구성하다",
  "대체로",
  "몹시",
  "문득",
  "스포츠",
  "위원장",
  "저기",
  "특별하다",
  "효과적",
  "가까이",
  "낫다",
  "넘어서다",
  "볶다",
  "생산하다",
  "언젠가",
  "예술가",
  "의도",
  "저지르다",
  "줄어들다",
  "가만히",
  "건",
  "교회",
  "대구",
  "외부",
  "한두",
  "한때",
  "화",
  "흙",
  "가난하다",
  "고객",
  "과학자",
  "관광",
  "살아오다",
  "상대적",
  "수술",
  "식품",
  "연기",
  "일월",
  "조",
  "첫째",
  "회원",
  "도서관",
  "들려오다",
  "조금씩",
  "조미료",
  "풀리다",
  "강력하다",
  "마늘",
  "선물",
  "습관",
  "아주머니",
  "위험",
  "지하",
  "활용하다",
  "가꾸다",
  "고민",
  "맨",
  "법률",
  "상처",
  "좁다",
  "지하철",
  "집다",
  "현",
  "화면",
  "군",
  "대표적",
  "만일",
  "사회적",
  "생겨나다",
  "이어",
  "주부",
  "진리",
  "태양",
  "틀림없다",
  "프로",
  "피다",
  "공급",
  "도로",
  "잘못",
  "지다",
  "채우다",
  "균형",
  "기본적",
  "부족",
  "사무실",
  "이월",
  "일요일",
  "접근",
  "지켜보다",
  "개성",
  "달리다",
  "더하다",
  "무너지다",
  "보통",
  "쓰다",
  "이러다",
  "일어서다",
  "죄",
  "참",
  "총장",
  "핵심",
  "후반",
  "단순히",
  "달려가다",
  "방문",
  "불만",
  "불편하다",
  "종",
  "피",
  "강",
  "관객",
  "막",
  "밀리미터",
  "비싸다",
  "숫자",
  "열",
  "왼쪽",
  "중세",
  "택시",
  "통합",
  "계산",
  "꼬리",
  "놀랍다",
  "양식",
  "예전",
  "전기",
  "주식",
  "틀리다",
  "끊임없이",
  "모델",
  "붓다",
  "상식",
  "상표",
  "시원하다",
  "아니하다",
  "의식하다",
  "고모",
  "궁금하다",
  "민주화",
  "보도",
  "살피다",
  "약하다",
  "잘못하다",
  "잡지",
  "거부하다",
  "공무원",
  "그만두다",
  "댁",
  "반갑다",
  "부족",
  "실시",
  "운명",
  "재정",
  "차라리",
  "학자",
  "다녀오다",
  "달다",
  "대규모",
  "동",
  "민간",
  "법원",
  "비디오",
  "사실상",
  "아끼다",
  "이쪽",
  "지대",
  "판단하다",
  "행복하다",
  "굽다",
  "기름",
  "실천하다",
  "쏟아지다",
  "연습",
  "오른쪽",
  "용어",
  "익히다",
  "지도",
  "지위",
  "풍부하다",
  "화장실",
  "기억하다",
  "식량",
  "실험",
  "용기",
  "토론",
  "핵",
  "고급",
  "고생",
  "미치다",
  "밟다",
  "상당하다",
  "섞다",
  "수석",
  "없애다",
  "이뤄지다",
  "적절하다",
  "정상",
  "제사",
  "주말",
  "지혜",
  "참새",
  "화장품",
  "굵다",
  "깨끗이",
  "낡다",
  "내년",
  "농산물",
  "눈앞",
  "대학생",
  "방문하다",
  "붉다",
  "사고",
  "순서",
  "아무래도",
  "연구소",
  "올",
  "위대하다",
  "이사",
  "지배하다",
  "틈",
  "가령",
  "거대하다",
  "닫다",
  "매달리다",
  "생일",
  "섬",
  "이하",
  "참석하다",
  "토대",
  "해결",
  "행복",
  "걸어가다",
  "근로자",
  "글쎄",
  "목숨",
  "백화점",
  "변화하다",
  "병",
  "빠져나가다",
  "안녕하다",
  "여론",
  "의복",
  "체조",
  "출발하다",
  "현실적",
  "화제",
  "결정되다",
  "고양이",
  "공격",
  "물가",
  "민주주의",
  "불안",
  "소중하다",
  "여유",
  "의문",
  "중학교",
  "킬로미터",
  "파도",
  "흰색",
  "가수",
  "단",
  "방송국",
  "빛나다",
  "숨다",
  "실리다",
  "압력",
  "예금",
  "예상되다",
  "입학",
  "증권",
  "직후",
  "차량",
  "출산",
  "물다",
  "선진국",
  "약",
  "어느새",
  "재판",
  "저쪽",
  "제자",
  "창문",
  "초",
  "치즈",
  "회복",
  "구역",
  "대응",
  "반대하다",
  "발휘하다",
  "소비",
  "심장",
  "아이고",
  "조용히",
  "중소기업",
  "직접적",
  "진실",
  "필자",
  "협력",
  "가스",
  "계층",
  "구멍",
  "담당",
  "만화",
  "먹이다",
  "무시하다",
  "보도하다",
  "살짝",
  "생각나다",
  "우유",
  "인상",
  "차갑다",
  "철저하다",
  "태아",
  "관찰하다",
  "괜히",
  "끼다",
  "날개",
  "녀석",
  "눈빛",
  "단지",
  "물속",
  "민주",
  "성공하다",
  "소나무",
  "여기저기",
  "여인",
  "운영하다",
  "평가하다",
  "표",
  "필요성",
  "감추다",
  "머무르다",
  "부위",
  "비우다",
  "설치하다",
  "십일월",
  "싸다",
  "아울러",
  "지시",
  "형성하다",
  "형제",
  "화려하다",
  "거울",
  "덧붙이다",
  "몇몇",
  "무기",
  "세계적",
  "안전",
  "양파",
  "이상",
  "일종",
  "처지",
  "촬영",
  "타다",
  "틀다",
  "형편",
  "가지",
  "감다",
  "고추",
  "규칙",
  "본질",
  "비치다",
  "빵",
  "서서히",
  "스승",
  "신분",
  "실시되다",
  "아마도",
  "안방",
  "앓다",
  "어제",
  "오직",
  "위험하다",
  "자신",
  "잠시",
  "졸업하다",
  "증거",
  "초점",
  "포함되다",
  "호랑이",
  "강화하다",
  "공포",
  "권위",
  "덜",
  "미루다",
  "본래",
  "부엌",
  "세금",
  "실정",
  "영양",
  "육체",
  "입구",
  "잔뜩",
  "적극",
  "최소한",
  "펼쳐지다",
  "경험하다",
  "그이",
  "넘치다",
  "놓치다",
  "대기",
  "독립",
  "머릿속",
  "북쪽",
  "불안하다",
  "쇠고기",
  "위반",
  "카드",
  "평생",
  "해당하다",
  "간부",
  "관념",
  "굉장히",
  "단어",
  "덮다",
  "도와주다",
  "도입",
  "몰다",
  "배우",
  "비추다",
  "신발",
  "알",
  "앞서다",
  "여건",
  "오래전",
  "자격",
  "통제",
  "계단",
  "김치",
  "끄덕이다",
  "낯설다",
  "높이",
  "닮다",
  "마음속",
  "못지않다",
  "반영하다",
  "뼈",
  "성장하다",
  "소속",
  "연결되다",
  "장사",
  "제작",
  "제한",
  "차다",
  "추진",
  "취하다",
  "콩",
  "한숨",
  "헤어지다",
  "구입하다",
  "날다",
  "너머",
  "여간",
  "마련",
  "살",
  "선장",
  "설탕",
  "순수하다",
  "스타일",
  "시점",
  "싸다",
  "의사",
  "집중",
  "코드",
  "큰일",
  "거짓말",
  "대단하다",
  "대단히",
  "마주",
  "세계관",
  "온통",
  "의존하다",
  "자세히",
  "점심",
  "정확히",
  "지치다",
  "청년",
  "혀",
  "확대하다",
  "후춧가루",
  "건설하다",
  "경쟁력",
  "마침",
  "신용",
  "연락",
  "온몸",
  "정",
  "정신적",
  "증상",
  "출연하다",
  "칼",
  "한꺼번에",
  "강제",
  "건너다",
  "깨다",
  "낚시",
  "다가가다",
  "문자",
  "묻다",
  "비롯되다",
  "슬프다",
  "신부",
  "여겨지다",
  "오징어",
  "요금",
  "용돈",
  "위주",
  "음료",
  "의자",
  "전자",
  "전해지다",
  "전환",
  "지경",
  "진행",
  "카메라",
  "통증",
  "편리하다",
  "하천",
  "현금",
  "화학",
  "활발하다",
  "가리다",
  "거칠다",
  "그나마",
  "껍질",
  "남부",
  "명령",
  "몰래",
  "무조건",
  "반장",
  "부근",
  "선택",
  "역사가",
  "연구자",
  "연기자",
  "조그맣다",
  "줄기",
  "찾아내다",
  "토요일",
  "경찰관",
  "권하다",
  "극장",
  "끝내",
  "대문",
  "더욱이",
  "별도",
  "비극",
  "솜씨",
  "시내",
  "신",
  "실내",
  "쏟다",
  "연결하다",
  "외교",
  "주머니",
  "계약",
  "과장",
  "극",
  "나란히",
  "다방",
  "부끄럽다",
  "성공",
  "수행하다",
  "신청",
  "실로",
  "아픔",
  "알아듣다",
  "약간",
  "연기",
  "이동",
  "인연",
  "일행",
  "지도",
  "지식인",
  "진지하다",
  "치다",
  "호흡",
  "흔들리다",
  "기온",
  "날아가다",
  "많아지다",
  "바위",
  "버릇",
  "비판하다",
  "빨갛다",
  "세워지다",
  "술집",
  "쉬다",
  "오랜만",
  "일부러",
  "전체적",
  "절반",
  "접근하다",
  "편안하다",
  "확실히",
  "흩어지다",
  "갈다",
  "교류",
  "글쓰기",
  "기후",
  "내내",
  "대기업",
  "대응하다",
  "도구",
  "매력",
  "백",
  "보험",
  "부럽다",
  "부장",
  "섞이다",
  "소",
  "심리",
  "심정",
  "쏘다",
  "아줌마",
  "위치하다",
  "이룩하다",
  "이틀",
  "전달하다",
  "정말로",
  "추다",
  "켜다",
  "코너",
  "행동하다",
  "형사",
  "희곡",
  "감동",
  "공항",
  "군인",
  "굳이",
  "그늘",
  "급하다",
  "달걀",
  "대중문화",
  "마련되다",
  "마루",
  "무게",
  "물기",
  "성적",
  "솔직히",
  "시도하다",
  "아니요",
  "예절",
  "인상",
  "인터넷",
  "적용하다",
  "전통적",
  "참가하다",
  "치마",
  "친척",
  "특정하다",
  "호텔",
  "경계",
  "공식",
  "그다지",
  "긴장",
  "깎다",
  "다리",
  "머물다",
  "명예",
  "뱃사람",
  "부처",
  "숙이다",
  "아가씨",
  "온도",
  "이마",
  "절대로",
  "지붕",
  "지원하다",
  "진짜",
  "짙다",
  "창밖",
  "청소",
  "침묵",
  "텍스트",
  "표면",
  "햇살",
  "객관적",
  "걱정하다",
  "경제적",
  "구름",
  "구석",
  "끄다",
  "담당하다",
  "벗기다",
  "부대",
  "불빛",
  "서클",
  "시청자",
  "실체",
  "엄격하다",
  "완벽하다",
  "완전하다",
  "절",
  "종업원",
  "증가",
  "출연",
  "학기",
  "한동안",
  "후기",
  "계절",
  "만지다",
  "목사",
  "바늘",
  "버티다",
  "부",
  "분포하다",
  "비중",
  "수입",
  "아이디어",
  "양쪽",
  "예컨대",
  "운동장",
  "전개",
  "접촉",
  "짐",
  "풀",
  "해마다",
  "후배",
  "휴가",
  "경기",
  "계곡",
  "국회의원",
  "다투다",
  "도저히",
  "뻗다",
  "생활하다",
  "솔직하다",
  "안타깝다",
  "앞장서다",
  "요청하다",
  "이르다",
  "일치하다",
  "젓다",
  "정치권",
  "처녀",
  "축제",
  "터뜨리다",
  "학습",
  "개발되다",
  "높이",
  "당연히",
  "대비하다",
  "마구",
  "만남",
  "미디어",
  "복도",
  "수상",
  "쓰러지다",
  "영상",
  "영혼",
  "운전사",
  "자랑스럽다",
  "잠깐",
  "적당하다",
  "적용되다",
  "주",
  "지속적",
  "진행하다",
  "차별",
  "곳곳",
  "구분",
  "금지",
  "내지",
  "달려오다",
  "분노",
  "비교",
  "소위",
  "스치다",
  "역사적",
  "열차",
  "요즈음",
  "유난히",
  "의무",
  "잃어버리다",
  "잠깐",
  "잠들다",
  "정식",
  "조용하다",
  "즉시",
  "최선",
  "탑",
  "한복",
  "활동하다",
  "건축",
  "귀엽다",
  "답답하다",
  "당황하다",
  "말",
  "밀가루",
  "바깥",
  "바지",
  "벌리다",
  "보고서",
  "보관하다",
  "부딪치다",
  "빨래",
  "상하다",
  "설치",
  "식용유",
  "싫어하다",
  "업종",
  "연출",
  "테이블",
  "트이다",
  "피곤하다",
  "한국어",
  "한자",
  "겁",
  "관리",
  "국제화",
  "근육",
  "깔리다",
  "사전",
  "산소",
  "석유",
  "소리치다",
  "시",
  "신선하다",
  "안경",
  "약속하다",
  "연합",
  "인격",
  "장",
  "적",
  "조심하다",
  "질",
  "찌르다",
  "책임자",
  "총",
  "취미",
  "표",
  "품목",
  "확대되다",
  "거실",
  "논",
  "덥다",
  "바보",
  "별",
  "보람",
  "부작용",
  "비난",
  "신고",
  "야구",
  "어떡하다",
  "예상",
  "위협",
  "이",
  "이상",
  "잠기다",
  "주고받다",
  "주먹",
  "지지",
  "털다",
  "경고",
  "공개하다",
  "과목",
  "구경",
  "그분",
  "근무",
  "논쟁",
  "달아나다",
  "묶다",
  "성명",
  "소스",
  "수십",
  "신다",
  "우리말",
  "유교",
  "이불",
  "이성",
  "일상",
  "자동",
  "장래",
  "전개되다",
  "죄송하다",
  "중요성",
  "크다",
  "탤런트",
  "특별히",
  "품질",
  "회견",
  "훔치다",
  "흔적",
  "고전",
  "과학적",
  "구분하다",
  "그럼",
  "뇌",
  "봉투",
  "분명",
  "사설",
  "승리",
  "좌우",
  "주",
  "지다",
  "추억",
  "하여튼",
  "함부로",
  "향기",
  "현지",
  "가늘다",
  "개인적",
  "겉",
  "굽히다",
  "근무하다",
  "깜짝",
  "넓히다",
  "대",
  "매년",
  "발생",
  "셋",
  "소주",
  "손바닥",
  "승용차",
  "어색하다",
  "얹다",
  "의학",
  "장점",
  "적당히",
  "줍다",
  "진출",
  "창조",
  "킬로그램",
  "평범하다",
  "프로",
  "홍보",
  "확인",
  "가루",
  "건지다",
  "깨지다",
  "내다보다",
  "대량",
  "마땅하다",
  "바로잡다",
  "박히다",
  "방금",
  "생선",
  "슬픔",
  "아무렇지",
  "이용되다",
  "이튿날",
  "임시",
  "제거하다",
  "줄다",
  "지구",
  "지우다",
  "참기름",
  "통",
  "특수",
  "팬",
  "합치다",
  "호기심",
  "골목",
  "기운",
  "기획",
  "대충",
  "맞서다",
  "모자라다",
  "박다",
  "본성",
  "상상하다",
  "생산력",
  "안전하다",
  "유학",
  "이르다",
  "장비",
  "정치인",
  "정치적",
  "졸업",
  "처벌",
  "카페",
  "콤플렉스",
  "검토",
  "그다음",
  "기법",
  "끓다",
  "날아오다",
  "농담",
  "닭",
  "담임",
  "도중",
  "맥주",
  "모래",
  "살아남다",
  "살인",
  "성질",
  "신호",
  "아버님",
  "의심하다",
  "인",
  "잇따르다",
  "자랑하다",
  "자판",
  "적용",
  "전기",
  "중반",
  "지시하다",
  "출발",
  "허용하다",
  "가르침",
  "각국",
  "교과서",
  "그려지다",
  "꾸준히",
  "년도",
  "다수",
  "덕분",
  "무리",
  "물음",
  "빌다",
  "수영",
  "스님",
  "썩다",
  "어머님",
  "엉뚱하다",
  "여보",
  "잊어버리다",
  "정면",
  "조각",
  "태우다",
  "해석",
  "가리다",
  "개국",
  "계산하다",
  "근원",
  "기차",
  "담그다",
  "마음대로",
  "며느리",
  "믿음",
  "발견",
  "보고",
  "부탁하다",
  "사용자",
  "사회주의",
  "설치되다",
  "수도권",
  "식탁",
  "실패하다",
  "영원하다",
  "우연히",
  "자극",
  "정해지다",
  "짚다",
  "총리",
  "칭찬",
  "튼튼하다",
  "햇빛",
  "공원",
  "긍정적",
  "논의하다",
  "대출",
  "덕",
  "먼지",
  "바닷가",
  "바치다",
  "반하다",
  "발표되다",
  "부정하다",
  "불러일으키다",
  "사원",
  "소유자",
  "씹다",
  "아무튼",
  "안되다",
  "액수",
  "왠지",
  "욕심",
  "우승",
  "익다",
  "제출하다",
  "조심스럽다",
  "직전",
  "짐작하다",
  "택하다",
  "터",
  "피시",
  "흘러나오다",
  "가구",
  "간단히",
  "강물",
  "궁극적",
  "극히",
  "기여하다",
  "꼭",
  "날짜",
  "대륙",
  "모니터",
  "범인",
  "변동",
  "불가피하다",
  "삶다",
  "샌드위치",
  "서류",
  "손해",
  "숨지다",
  "아유",
  "요청",
  "운",
  "일상생활",
  "자존심",
  "주저앉다",
  "증권사",
  "진하다",
  "초원",
  "최종",
  "회복하다",
  "흘러가다",
  "흥미",
  "검사",
  "그쪽",
  "꾸다",
  "내려다보다",
  "눈썹",
  "대신하다",
  "마디",
  "미처",
  "비닐",
  "비율",
  "생물",
  "소음",
  "양국",
  "얼핏",
  "오래되다",
  "웬",
  "조각",
  "중부",
  "차",
  "채소",
  "침",
  "털",
  "판매하다",
  "항의",
  "해당",
  "확보",
  "환경오염",
  "가치관",
  "검은색",
  "고생하다",
  "기원전",
  "기타",
  "긴급",
  "꺾다",
  "꿈꾸다",
  "무려",
  "묻히다",
  "수표",
  "실태",
  "아쉽다",
  "애정",
  "얘",
  "어머",
  "여보세요",
  "일정",
  "장르",
  "저렇다",
  "접하다",
  "정성",
  "제약",
  "쫓다",
  "추가",
  "테이프",
  "품",
  "해석",
  "확인되다",
  "가하다",
  "간장",
  "강의",
  "거",
  "고민하다",
  "과",
  "교환",
  "국어",
  "노랗다",
  "다치다",
  "상",
  "새",
  "악기",
  "연말",
  "올려놓다",
  "외면하다",
  "원고",
  "줄곧",
  "지출",
  "차",
  "치우다",
  "피로",
  "향",
  "개선하다",
  "계좌",
  "당기다",
  "들다",
  "말기",
  "말없이",
  "망하다",
  "문서",
  "박수",
  "부자",
  "사흘",
  "서쪽",
  "성립되다",
  "손자",
  "신랑",
  "실습",
  "일찍이",
  "자율",
  "제법",
  "집중되다",
  "창조적",
  "천장",
  "파다",
  "하긴",
  "해결되다",
  "향상",
  "화가",
  "가라앉다",
  "감싸다",
  "고작",
  "구경하다",
  "국물",
  "기구",
  "깔다",
  "다짐하다",
  "대합실",
  "말씀드리다",
  "매장",
  "멀어지다",
  "명칭",
  "불쌍하다",
  "상업",
  "생산되다",
  "숨기다",
  "실력",
  "야채",
  "엉덩이",
  "울음",
  "이달",
  "인체",
  "저고리",
  "전부",
  "즐거움",
  "지다",
  "진단",
  "질병",
  "짜다",
  "체력",
  "친하다",
  "헤아리다",
  "각기",
  "경찰서",
  "곧바로",
  "관찰",
  "구두",
  "군사",
  "귀신",
  "귀하다",
  "그토록",
  "기초하다",
  "깊이",
  "나뭇가지",
  "낫다",
  "답하다",
  "대가",
  "더위",
  "말리다",
  "무덤",
  "물결",
  "밥상",
  "보장하다",
  "사과",
  "사귀다",
  "서너",
  "솟다",
  "실패",
  "예상하다",
  "예외",
  "완전",
  "웃기다",
  "유리",
  "절대적",
  "제작하다",
  "쥐",
  "최대한",
  "턱",
  "팔리다",
  "품다",
  "하하",
  "형부",
  "휴식",
  "굳다",
  "굳어지다",
  "굶다",
  "금년",
  "기기",
  "까맣다",
  "나침반",
  "농부",
  "대학원",
  "더럽다",
  "도달하다",
  "맛보다",
  "무늬",
  "문장",
  "묻다",
  "변호사",
  "상",
  "상상력",
  "여학생",
  "영업",
  "예",
  "예방",
  "완성",
  "유리하다",
  "자연환경",
  "증명하다",
  "지적되다",
  "지점",
  "철저히",
  "코스",
  "학부모",
  "합리적",
  "해석하다",
  "힘쓰다",
  "거듭",
  "귀국하다",
  "기뻐하다",
  "날리다",
  "내려놓다",
  "냉장고",
  "당근",
  "덜다",
  "마누라",
  "맞이하다",
  "불행하다",
  "신고하다",
  "연속",
  "외롭다",
  "욕",
  "원장",
  "유물",
  "저절로",
  "절",
  "정비",
  "주의",
  "지난번",
  "집어넣다",
  "통계",
  "파일",
  "판",
  "피해자",
  "필수적",
  "학용품",
  "화분",
  "환하다",
  "갈색",
  "감기",
  "강남",
  "구속되다",
  "멋지다",
  "반발",
  "반찬",
  "방지",
  "배다",
  "보장",
  "빠져나오다",
  "새다",
  "소개되다",
  "씨",
  "안쪽",
  "억울하다",
  "오가다",
  "요구되다",
  "음성",
  "이윽고",
  "장식",
  "전개하다",
  "전날",
  "전반적",
  "척하다",
  "풍습",
  "한가운데",
  "한정되다",
  "해내다",
  "흉내",
  "감옥",
  "갚다",
  "건전하다",
  "결혼식",
  "계산기",
  "광장",
  "그렇지",
  "깨다",
  "꽉",
  "끼우다",
  "노래하다",
  "다듬다",
  "다름없다",
  "당당하다",
  "댐",
  "덩어리",
  "모처럼",
  "물리학",
  "벌",
  "부정적",
  "북부",
  "분리",
  "상당",
  "세다",
  "소개",
  "수화기",
  "애인",
  "애초",
  "얇다",
  "양념",
  "에",
  "오염되다",
  "오해",
  "웬일",
  "의식",
  "의지하다",
  "일기",
  "절대",
  "제한하다",
  "존중하다",
  "창고",
  "채",
  "책임지다",
  "청춘",
  "초반",
  "트럭",
  "파",
  "폭넓다",
  "표현되다",
  "학번",
  "학원",
  "허가",
  "헤매다",
  "가장",
  "곡",
  "곡식",
  "꽂다",
  "망설이다",
  "무리",
  "버터",
  "별다르다",
  "보편적",
  "부품",
  "뻔하다",
  "뽑히다",
  "상담",
  "서부",
  "속담",
  "시중",
  "심사",
  "아이",
  "앞세우다",
  "유사하다",
  "유치원",
  "의욕",
  "인근",
  "일반인",
  "작성하다",
  "전공",
  "정기",
  "제의",
  "종일",
  "좋아지다",
  "차림",
  "창조하다",
  "추위",
  "토마토",
  "파랗다",
  "펴내다",
  "하드웨어",
  "화장",
  "가져가다",
  "감자",
  "강렬하다",
  "갖다",
  "괴롭다",
  "교통사고",
  "군데",
  "메시지",
  "면하다",
  "모색하다",
  "붙잡다",
  "빛깔",
  "뺨",
  "사방",
  "살림",
  "실현",
  "아냐",
  "연예인",
  "영원히",
  "월급",
  "유행",
  "이내",
  "이리",
  "제발",
  "차츰",
  "투표",
  "파괴하다",
  "하느님",
  "해당되다",
  "홀로",
  "가까이",
  "가득하다",
  "각자",
  "감히",
  "강요하다",
  "개미",
  "관습",
  "광경",
  "금세",
  "낮추다",
  "내주다",
  "명의",
  "물고기",
  "물러나다",
  "밀접하다",
  "부탁",
  "분량",
  "불과",
  "뻔하다",
  "상자",
  "새해",
  "소박하다",
  "수없이",
  "승진",
  "양",
  "얼음",
  "열매",
  "적히다",
  "점수",
  "제한되다",
  "지속되다",
  "쫓기다",
  "쭉",
  "통장",
  "평가되다",
  "하도",
  "한창",
  "해안",
  "호박",
  "흑인",
  "간",
  "갇히다",
  "결심하다",
  "경기장",
  "고장",
  "근로",
  "눈동자",
  "늦다",
  "닥치다",
  "도시락",
  "몸짓",
  "부담하다",
  "부회장",
  "빼앗다",
  "삼키다",
  "성실하다",
  "스튜디오",
  "실제",
  "요새",
  "이상적",
  "일쑤",
  "일체",
  "자꾸만",
  "잦다",
  "절망",
  "접다",
  "제시되다",
  "주방",
  "진출하다",
  "짜증",
  "첫날",
  "통과하다",
  "투명하다",
  "확산되다",
  "경력",
  "고통스럽다",
  "곤란하다",
  "공중",
  "공해",
  "귀찮다",
  "기대다",
  "깨어나다",
  "꼽히다",
  "대다수",
  "듯싶다",
  "먹이",
  "무",
  "무료",
  "밉다",
  "발레",
  "상징하다",
  "실현하다",
  "아깝다",
  "안정되다",
  "어리석다",
  "엎드리다",
  "여럿",
  "연설",
  "연필",
  "염려",
  "우습다",
  "운전",
  "익숙하다",
  "일자",
  "잔치",
  "전달되다",
  "차다",
  "치료하다",
  "칸",
  "한",
  "갈수록",
  "갖가지",
  "거절하다",
  "관광객",
  "구별하다",
  "국",
  "다행",
  "마무리",
  "마주치다",
  "막상",
  "몸통",
  "못",
  "묘사하다",
  "미술관",
  "베다",
  "보장되다",
  "볼",
  "부분적",
  "불고기",
  "불리다",
  "불행",
  "비명",
  "순수",
  "시각",
  "실질적",
  "안개",
  "여름철",
  "오로지",
  "완성하다",
  "이런저런",
  "임무",
  "자연히",
  "장난",
  "저런",
  "전용",
  "지극히",
  "청하다",
  "확정",
  "가상",
  "각자",
  "간혹",
  "개방하다",
  "관계되다",
  "국산",
  "그해",
  "꺼지다",
  "꼭대기",
  "끊어지다",
  "도",
  "마이크",
  "메우다",
  "면적",
  "목욕",
  "반성",
  "벌떡",
  "보상",
  "분리되다",
  "빚",
  "새기다",
  "서민",
  "석",
  "선정하다",
  "세다",
  "소수",
  "쇼",
  "수학",
  "시어머니",
  "시집",
  "연간",
  "영화제",
  "예측하다",
  "용도",
  "의심",
  "이동하다",
  "자극하다",
  "재빨리",
  "적합하다",
  "전화번호",
  "조절하다",
  "조정",
  "중얼거리다",
  "지급하다",
  "확장",
  "힘차다",
  "건조",
  "고요하다",
  "그대",
  "그립다",
  "긋다",
  "기둥",
  "긴장하다",
  "날카롭다",
  "논하다",
  "느리다",
  "도덕",
  "뒷모습",
  "만",
  "만만하다",
  "못되다",
  "보고하다",
  "사무소",
  "사촌",
  "상",
  "상인",
  "선언하다",
  "소원",
  "수입하다",
  "식다",
  "엿보다",
  "완성되다",
  "유적",
  "일생",
  "입히다",
  "잡아먹다",
  "정보화",
  "종종",
  "지갑",
  "차다",
  "창작",
  "축소",
  "취향",
  "친절하다",
  "카운터",
  "탄생하다",
  "하숙집",
  "학급",
  "해군",
  "효율적",
  "가슴속",
  "간",
  "간판",
  "건드리다",
  "관리하다",
  "괴로움",
  "근거하다",
  "금액",
  "다행히",
  "달래다",
  "말리다",
  "맘",
  "먹다",
  "명절",
  "못하다",
  "바이올린",
  "밝혀내다",
  "부서지다",
  "비행",
  "상관없다",
  "성립하다",
  "수명",
  "시즌",
  "신기하다",
  "업다",
  "예감",
  "음식물",
  "자신감",
  "죄인",
  "주의하다",
  "준비물",
  "천",
  "출입",
  "쾌감",
  "탄생",
  "튀다",
  "하필",
  "활용",
  "회색",
  "거액",
  "고교",
  "고집하다",
  "곧잘",
  "골짜기",
  "교훈",
  "구르다",
  "글씨",
  "노래방",
  "단편",
  "대처하다",
  "매다",
  "머리카락",
  "명확하다",
  "발걸음",
  "발길",
  "벌레",
  "빼앗기다",
  "생산자",
  "선진",
  "성인",
  "소매",
  "시멘트",
  "신규",
  "신청하다",
  "실은",
  "심판",
  "안기다",
  "양심",
  "이사장",
  "이자",
  "익숙해지다",
  "자세하다",
  "저거",
  "저축",
  "제비",
  "제안",
  "제안하다",
  "증세",
  "지난날",
  "진급",
  "콩나물",
  "튀어나오다",
  "표준",
  "항구",
  "허용",
  "헬기",
  "간신히",
  "갈아입다",
  "감다",
  "같이하다",
  "거짓",
  "거품",
  "걸어오다",
  "공군",
  "기술자",
  "나물",
  "년생",
  "달리기",
  "대도시",
  "도둑",
  "도전",
  "면",
  "모자",
  "보조",
  "불리하다",
  "불편",
  "비비다",
  "사과",
  "살아나다",
  "소파",
  "속이다",
  "수면",
  "수천",
  "스무",
  "승부",
  "시기",
  "신념",
  "신입생",
  "어린아이",
  "어쩐지",
  "여간",
  "역",
  "연락하다",
  "영향력",
  "예의",
  "이내",
  "이리저리",
  "인간적",
  "인사하다",
  "임금",
  "잘나다",
  "재능",
  "지불하다",
  "파출소",
  "판",
  "한문",
  "건네주다",
  "결승",
  "공공",
  "교양",
  "국왕",
  "규칙적",
  "극복",
  "글쎄요",
  "내려지다",
  "냉동",
  "녹다",
  "대개",
  "배추",
  "센터",
  "수염",
  "수집하다",
  "승객",
  "싱싱하다",
  "아아",
  "어리다",
  "여권",
  "열쇠",
  "오렌지",
  "외모",
  "인공",
  "잠자리",
  "장남",
  "저마다",
  "전국적",
  "전반",
  "제자리",
  "지루하다",
  "척",
  "포도주",
  "학술",
  "혈액",
  "화살",
  "화재",
  "가르다",
  "각각",
  "간격",
  "고함",
  "공격하다",
  "그놈",
  "기록되다",
  "끝없다",
  "끼어들다",
  "노선",
  "답",
  "대사",
  "도망치다",
  "독서",
  "땅속",
  "벌금",
  "복",
  "봉사",
  "부서",
  "성",
  "소형",
  "신인",
  "악몽",
  "안내",
  "앞뒤",
  "언덕",
  "열정",
  "오페라",
  "입맛",
  "재즈",
  "적응하다",
  "절대",
  "접시",
  "제공",
  "지나치다",
  "참가",
  "체중",
  "추석",
  "충돌",
  "통로",
  "포장",
  "해소",
  "현관",
  "현대인",
  "혜택",
  "호수",
  "확",
  "건너가다",
  "골고루",
  "구석구석",
  "그제서야",
  "껌",
  "녹색",
  "막내",
  "반복되다",
  "발전",
  "방해",
  "번호",
  "벼",
  "본인",
  "상점",
  "수백",
  "실장",
  "쓸쓸하다",
  "악수",
  "애",
  "어지럽다",
  "어찌하다",
  "예정되다",
  "이중",
  "인원",
  "인정받다",
  "인터뷰",
  "입학하다",
  "자랑",
  "저것",
  "정문",
  "착각",
  "창",
  "친정",
  "켜지다",
  "타입",
  "팩",
  "표시하다",
  "행하다",
  "활짝",
  "감상하다",
  "개별",
  "공식적",
  "그런대로",
  "기독교",
  "깨달음",
  "끼다",
  "넘어지다",
  "눈부시다",
  "단점",
  "마음먹다",
  "목록",
  "반복하다",
  "반성하다",
  "별명",
  "보전",
  "불어오다",
  "소유하다",
  "수박",
  "스위치",
  "신세",
  "알아내다",
  "암",
  "야단",
  "얘",
  "엔",
  "연결",
  "연관",
  "용서하다",
  "우정",
  "육군",
  "음식점",
  "의논하다",
  "인분",
  "주요하다",
  "죽",
  "차마",
  "참조",
  "첫째",
  "타고나다",
  "태우다",
  "행사",
  "향수",
  "허용되다",
  "가입",
  "가정",
  "과자",
  "교체",
  "김",
  "꼬마",
  "끝없이",
  "나르다",
  "놀리다",
  "늘어놓다",
  "닫히다",
  "대표하다",
  "대학교",
  "도로",
  "막걸리",
  "맡다",
  "미스",
  "밀리다",
  "밥그릇",
  "방문",
  "병들다",
  "부러지다",
  "불꽃",
  "상대성",
  "설문",
  "소설가",
  "쓸다",
  "안녕히",
  "안팎",
  "예방하다",
  "올",
  "유의하다",
  "유학",
  "이해되다",
  "인하",
  "작성",
  "잘리다",
  "전달",
  "전시회",
  "전화하다",
  "전환하다",
  "정치학",
  "좌석",
  "주문",
  "집안일",
  "체계적",
  "출근",
  "크림",
  "탁월하다",
  "텅",
  "토하다",
  "푹",
  "한순간",
  "가사",
  "가입자",
  "감소하다",
  "개구리",
  "결과적",
  "괴롭히다",
  "구별",
  "깨우다",
  "끈",
  "끊기다",
  "끊임없다",
  "넉넉하다",
  "두어",
  "마약",
  "밑바닥",
  "보도되다",
  "보리",
  "본부",
  "부",
  "성숙하다",
  "소극적",
  "소용",
  "손질하다",
  "수건",
  "순식간",
  "시도",
  "시집",
  "아무개",
  "약점",
  "양복",
  "어째서",
  "얼다",
  "여",
  "연령",
  "예비",
  "울음소리",
  "이놈",
  "이력서",
  "인정되다",
  "잠자다",
  "젓가락",
  "주년",
  "중단하다",
  "중대하다",
  "중심지",
  "집중적",
  "참되다",
  "초청",
  "출판사",
  "탁자",
  "토끼",
  "퍽",
  "풍속",
  "피아노",
  "핑계",
  "한층",
  "행운",
  "가로막다",
  "간섭",
  "갈증",
  "갑작스럽다",
  "교환하다",
  "그래야",
  "기원",
  "길가",
  "난리",
  "남매",
  "내버리다",
  "미사일",
  "발가락",
  "보존",
  "보호되다",
  "뵙다",
  "부치다",
  "상관",
  "설명되다",
  "섭섭하다",
  "세",
  "세련되다",
  "손쉽다",
  "안주",
  "어린애",
  "열기",
  "옷차림",
  "위로하다",
  "이같이",
  "인형",
  "일치",
  "재생",
  "전철",
  "찢어지다",
  "철학자",
  "축하하다",
  "컬러",
  "통화",
  "특이하다",
  "필연적",
  "하룻밤",
  "흘러내리다",
  "가짜",
  "강도",
  "곰",
  "급히",
  "기념",
  "나아지다",
  "넘어오다",
  "매너",
  "민속",
  "버려지다",
  "빗물",
  "사위",
  "설날",
  "설득하다",
  "손질",
  "손톱",
  "식빵",
  "아르바이트",
  "외로움",
  "외우다",
  "운전자",
  "유리창",
  "입대",
  "적성",
  "전망하다",
  "집단적",
  "차이점",
  "찬물",
  "철도",
  "총각",
  "캠페인",
  "태풍",
  "한눈",
  "햇볕",
  "홍수",
  "가뭄",
  "간접",
  "강력히",
  "개선되다",
  "건네다",
  "게",
  "결심",
  "곧장",
  "교직",
  "구속하다",
  "귀중하다",
  "긁다",
  "금지하다",
  "기사",
  "기숙사",
  "나뭇잎",
  "내쉬다",
  "넓어지다",
  "늘어지다",
  "단독",
  "마당",
  "마음껏",
  "몰려들다",
  "미팅",
  "바",
  "바퀴",
  "발자국",
  "뱉다",
  "벌어지다",
  "보너스",
  "산책",
  "생기",
  "서적",
  "소화",
  "수험생",
  "시끄럽다",
  "신세대",
  "안기다",
  "어기다",
  "어때",
  "엘리베이터",
  "연구원",
  "연애",
  "연휴",
  "요약하다",
  "운동하다",
  "월요일",
  "위성",
  "의외로",
  "이모",
  "자살",
  "제시",
  "제외되다",
  "조카",
  "주소",
  "찍히다",
  "철",
  "취직",
  "타락",
  "통화",
  "틀림없이",
  "파티",
  "회관",
  "계획하다",
  "공연장",
  "국적",
  "근래",
  "나뉘다",
  "낱말",
  "내외",
  "냄비",
  "늑대",
  "늘어서다",
  "다정하다",
  "달다",
  "달려들다",
  "망원경",
  "모범",
  "무리하다",
  "묶이다",
  "방해하다",
  "뱀",
  "벌",
  "뵈다",
  "상류",
  "석",
  "선명하다",
  "선호하다",
  "성공적",
  "소규모",
  "수시로",
  "시야",
  "실현되다",
  "아니야",
  "어느덧",
  "오르내리다",
  "왕자",
  "우수하다",
  "유명",
  "유형",
  "으레",
  "일상적",
  "일일이",
  "전공하다",
  "전후",
  "정상",
  "정상적",
  "질적",
  "척",
  "철",
  "청소하다",
  "충격적",
  "치아",
  "편의",
  "폐지",
  "해설",
  "헌",
  "흑백",
  "희생",
  "가까워지다",
  "감사하다",
  "강도",
  "경비",
  "경제력",
  "금하다",
  "기대되다",
  "깊숙이",
  "꿀",
  "넘어뜨리다",
  "농사짓다",
  "단단하다",
  "담당자",
  "답변",
  "대비",
  "대체",
  "더욱더",
  "도심",
  "마찰",
  "마크",
  "만족",
  "망치다",
  "메뉴",
  "모금",
  "묘사",
  "문화적",
  "묻히다",
  "미혼",
  "발전되다",
  "방송사",
  "방지하다",
  "사표",
  "삼가다",
  "생방송",
  "셋째",
  "소홀히",
  "손발",
  "쌍둥이",
  "안내하다",
  "앉히다",
  "영하",
  "옆구리",
  "외교관",
  "외국어",
  "웬만하다",
  "유행하다",
  "으응",
  "이어서",
  "자장면",
  "자정",
  "종합하다",
  "중학생",
  "짜다",
  "참",
  "채",
  "최후",
  "취재",
  "테러",
  "토론회",
  "한잔",
  "호주머니",
  "휴일",
  "가난",
  "가죽",
  "관계없이",
  "군",
  "기다",
  "다툼",
  "달력",
  "대",
  "대낮",
  "데려가다",
  "도자기",
  "모조리",
  "변경",
  "부지런히",
  "사슴",
  "삼촌",
  "상당수",
  "소풍",
  "속옷",
  "손목",
  "손실",
  "슬쩍",
  "시나리오",
  "신문지",
  "실컷",
  "양보하다",
  "엔진",
  "용",
  "운행",
  "이대로",
  "인간성",
  "인상적",
  "자라나다",
  "전시",
  "조그마하다",
  "주차장",
  "주한",
  "중년",
  "지다",
  "찌꺼기",
  "춤추다",
  "테니스",
  "파리",
  "포스터",
  "포함",
  "형식적",
  "후회하다",
  "가이드",
  "가입하다",
  "감소",
  "고르다",
  "고속도로",
  "공책",
  "구분되다",
  "구입",
  "기여",
  "기호",
  "꾸리다",
  "낭비",
  "내외",
  "대략",
  "덮이다",
  "도리어",
  "멋있다",
  "멸치",
  "바람",
  "발목",
  "밤늦다",
  "방울",
  "배",
  "벌",
  "보관",
  "보살피다",
  "보완하다",
  "보존하다",
  "빨다",
  "빼놓다",
  "사상",
  "사춘기",
  "상반기",
  "서점",
  "선전",
  "설립하다",
  "수집",
  "수필",
  "신기하다",
  "신비",
  "신설",
  "실감",
  "실망",
  "쓰다듬다",
  "알루미늄",
  "알아주다",
  "양말",
  "여우",
  "와",
  "외출",
  "용기",
  "웨이터",
  "유발하다",
  "유지되다",
  "자가용",
  "잡아당기다",
  "점검",
  "정원",
  "조기",
  "존경하다",
  "종",
  "종소리",
  "중순",
  "지각",
  "직선",
  "참외",
  "체하다",
  "체험하다",
  "초대하다",
  "총",
  "칭찬하다",
  "코치",
  "통일하다",
  "패션",
  "프린터",
  "피디",
  "하나하나",
  "학위",
  "회의",
  "가로등",
  "감",
  "개개인",
  "걷다",
  "고집",
  "금",
  "급격히",
  "기능",
  "기막히다",
  "나누어지다",
  "낙엽",
  "넘겨주다",
  "논리적",
  "다가서다",
  "대기하다",
  "대접",
  "말다",
  "맞은편",
  "멋",
  "면",
  "박",
  "밤하늘",
  "변명",
  "보자기",
  "부상",
  "분리하다",
  "붙들다",
  "사들이다",
  "상관없이",
  "새우",
  "선원",
  "설사",
  "실망하다",
  "심해지다",
  "씨름",
  "아쉬움",
  "앞길",
  "약해지다",
  "어젯밤",
  "엉망",
  "여관",
  "여쭈다",
  "연습하다",
  "연출하다",
  "열중하다",
  "영웅",
  "오",
  "유산",
  "의도적",
  "이따금",
  "임신부",
  "장기적",
  "재주",
  "전설",
  "절",
  "젖",
  "조르다",
  "주문하다",
  "주사",
  "지겹다",
  "짐작",
  "쫓겨나다",
  "찢다",
  "채널",
  "출근하다",
  "취소하다",
  "취업",
  "칠하다",
  "타오르다",
  "탁",
  "평",
  "평화롭다",
  "표시",
  "표시",
  "하반기",
  "한구석",
  "한하다",
  "해답",
  "해소하다",
  "허락하다",
  "확립하다",
  "확신하다",
  "흥분",
  "흥분하다",
  "힘겹다",
  "감수성",
  "거들다",
  "건너편",
  "경치",
  "급속히",
  "급증하다",
  "깔끔하다",
  "꼼꼼하다",
  "낮아지다",
  "단순",
  "대사",
  "두세",
  "매",
  "먹고살다",
  "멎다",
  "면담",
  "모집",
  "모퉁이",
  "바구니",
  "바싹",
  "발달되다",
  "발음",
  "방울",
  "보름",
  "봉지",
  "부딪히다",
  "비상",
  "비서",
  "뿌리치다",
  "사업자",
  "상대편",
  "세트",
  "소망",
  "수동적",
  "술잔",
  "슬그머니",
  "시장",
  "시청률",
  "신문사",
  "여군",
  "연인",
  "연장",
  "열흘",
  "예선",
  "옥수수",
  "원",
  "은행나무",
  "이웃집",
  "자부심",
  "장차",
  "재미없다",
  "저편",
  "정신과",
  "제도적",
  "조절",
  "주거",
  "주스",
  "준비되다",
  "중단되다",
  "중요시하다",
  "지점",
  "진단하다",
  "콜라",
  "타다",
  "편견",
  "플라스틱",
  "환율",
  "활기",
  "강사",
  "강수량",
  "교대",
  "구청",
  "국내외",
  "금지되다",
  "기도하다",
  "기성",
  "깨뜨리다",
  "꼼짝",
  "남학생",
  "농사일",
  "농장",
  "데이트",
  "마음가짐",
  "머리칼",
  "모",
  "바퀴",
  "번개",
  "변화되다",
  "부러워하다",
  "부재",
  "사망",
  "생",
  "서늘하다",
  "성경",
  "소요되다",
  "소화하다",
  "순간적",
  "시집가다",
  "시청",
  "싶어지다",
  "씌우다",
  "야간",
  "엇갈리다",
  "엷다",
  "오락",
  "오른손",
  "우아하다",
  "이것저것",
  "이슬",
  "인간관계",
  "장기간",
  "저자",
  "전선",
  "정지",
  "제의하다",
  "조명",
  "조화되다",
  "줄거리",
  "중단",
  "차선",
  "착각하다",
  "초상화",
  "출장",
  "쿠데타",
  "퇴근",
  "특수성",
  "항공기",
  "활발히",
  "회화",
  "흥미롭다",
  "가져다주다",
  "각오",
  "개방되다",
  "겨자",
  "경고하다",
  "경제학",
  "골프",
  "관심사",
  "구별되다",
  "국사",
  "굳히다",
  "귀국",
  "그간",
  "그럴듯하다",
  "극작가",
  "기",
  "기울다",
  "기적",
  "끌리다",
  "내보내다",
  "담",
  "따스하다",
  "말투",
  "명령어",
  "목욕탕",
  "미끄러지다",
  "바닷물",
  "변신",
  "부친",
  "붙잡히다",
  "브랜드",
  "빨다",
  "사무",
  "사소하다",
  "상",
  "상쾌하다",
  "성",
  "손길",
  "순위",
  "식사하다",
  "쌍",
  "썰렁하다",
  "암시",
  "앞날",
  "역사상",
  "연주",
  "우울하다",
  "우체국",
  "울리다",
  "육상",
  "음주",
  "이별",
  "인재",
  "인종",
  "일시적",
  "재밌다",
  "전문적",
  "젊음",
  "점잖다",
  "조개",
  "주무시다",
  "중계방송",
  "중독",
  "지급",
  "진실하다",
  "차창",
  "초청하다",
  "출발점",
  "출판",
  "출현하다",
  "클럽",
  "키스",
  "탈출하다",
  "판사",
  "허락",
  "확신",
  "흐리다",
  "걱정스럽다",
  "건설되다",
  "고개",
  "고프다",
  "그제야",
  "금연",
  "기성세대",
  "기술하다",
  "까치",
  "꿈속",
  "나비",
  "남북",
  "납득하다",
  "단",
  "대사관",
  "독창적",
  "만점",
  "매달",
  "모여들다",
  "묵다",
  "바깥쪽",
  "배고프다",
  "버섯",
  "보수",
  "보안",
  "뵈다",
  "부",
  "비웃다",
  "비키다",
  "사나이",
  "사모님",
  "삼",
  "상징적",
  "색다르다",
  "세미나",
  "세제",
  "속삭이다",
  "시일",
  "시합",
  "신속하다",
  "신제품",
  "신중하다",
  "썩",
  "암컷",
  "여행하다",
  "예고하다",
  "위반하다",
  "윗몸",
  "은은하다",
  "이해관계",
  "익히다",
  "일대",
  "입력",
  "자연현상",
  "정류장",
  "정신없이",
  "정직하다",
  "조정하다",
  "주름",
  "중요",
  "챔피언",
  "최신",
  "축하",
  "취소",
  "캐릭터",
  "커튼",
  "팬",
  "해롭다",
  "현관문",
  "활발해지다",
  "회복되다",
  "희생하다",
  "가능해지다",
  "겨울철",
  "고추장",
  "골프장",
  "공연히",
  "과정",
  "국수",
  "국제적",
  "그루",
  "그전",
  "금고",
  "꾸중",
  "끌어당기다",
  "끼",
  "능동적",
  "달빛",
  "당분간",
  "도움말",
  "뒷산",
  "만족하다",
  "맵다",
  "메다",
  "몰려오다",
  "물질적",
  "미인",
  "믿어지다",
  "반죽",
  "반지",
  "밤",
  "배치",
  "백인",
  "본",
  "본사",
  "부르다",
  "불평등하다",
  "비타민",
  "빌딩",
  "빠뜨리다",
  "산속",
  "새로이",
  "서양인",
  "소지품",
  "송이",
  "쇠",
  "수컷",
  "승리하다",
  "식초",
  "심부름",
  "씨앗",
  "야외",
  "얻어먹다",
  "엄숙하다",
  "여가",
  "여동생",
  "연상하다",
  "옆집",
  "오래간만",
  "오래도록",
  "올려다보다",
  "외다",
  "외침",
  "이빨",
  "이해",
  "일손",
  "잠바",
  "잠수함",
  "장수",
  "장학금",
  "재다",
  "쟤",
  "점심시간",
  "지도하다",
  "지적",
  "진로",
  "진행자",
  "쪽",
  "찾아다니다",
  "책임감",
  "체온",
  "초밥",
  "출구",
  "출입문",
  "통과",
  "포장마차",
  "푸다",
  "풀",
  "피망",
  "필름",
  "한결",
  "한여름",
  "항공",
  "호선",
  "회전",
  "휴지",
  "가위",
  "고속",
  "곧다",
  "골치",
  "구하다",
  "내달",
  "넷째",
  "놔두다",
  "늦어지다",
  "도망가다",
  "독하다",
  "만",
  "명단",
  "무의미하다",
  "문구",
  "뭣",
  "미만",
  "백색",
  "비바람",
  "사기",
  "사망하다",
  "사무직",
  "소질",
  "솜",
  "시디롬",
  "식생활",
  "신호등",
  "신혼여행",
  "실",
  "씩씩하다",
  "연구실",
  "월드컵",
  "유머",
  "의류",
  "입원",
  "잊혀지다",
  "잘살다",
  "장가",
  "적응",
  "정리되다",
  "조",
  "졸업생",
  "죽다",
  "창피하다",
  "친절",
  "침실",
  "태권도",
  "토론하다",
  "하나하나",
  "합하다",
  "핸드백",
  "햄버거",
  "가정교사",
  "감사",
  "공통적",
  "교육자",
  "교재",
  "기타",
  "길어지다",
  "깨소금",
  "나빠지다",
  "남",
  "놓아두다",
  "대접하다",
  "무궁화",
  "바이러스",
  "방바닥",
  "번거롭다",
  "사냥",
  "사회생활",
  "설거지",
  "수년",
  "수출하다",
  "숙소",
  "슈퍼마켓",
  "심리적",
  "연기",
  "위로",
  "이용자",
  "입력하다",
  "자격증",
  "진료",
  "창가",
  "초조하다",
  "타다",
  "한잔하다",
  "함께하다",
  "합격하다",
  "화장지",
  "힘껏",
  "가요",
  "감상",
  "공짜",
  "공통되다",
  "그만하다",
  "나들이",
  "녹이다",
  "눈가",
  "도망",
  "모기",
  "미니",
  "방면",
  "법적",
  "벤치",
  "보수",
  "볼일",
  "부끄러움",
  "비누",
  "비행",
  "빗줄기",
  "사회학",
  "성적",
  "세수",
  "순진하다",
  "스웨터",
  "심심하다",
  "어려워지다",
  "여행사",
  "올라서다",
  "왼손",
  "유학생",
  "은",
  "자리",
  "잘생기다",
  "장애인",
  "재활용품",
  "저러다",
  "저리",
  "절약하다",
  "정반대",
  "진동",
  "찌개",
  "추천",
  "학점",
  "한가하다",
  "합격",
  "환영하다",
  "간접적",
  "갈다",
  "경영하다",
  "그리움",
  "그사이",
  "기념하다",
  "기도",
  "길거리",
  "꽃잎",
  "넥타이",
  "대강",
  "독립하다",
  "문밖",
  "방송하다",
  "보충하다",
  "불완전하다",
  "불평",
  "붐비다",
  "생활환경",
  "서명",
  "손등",
  "역사학",
  "영",
  "오리",
  "요리사",
  "원",
  "원서",
  "월",
  "이론적",
  "이제야",
  "임신하다",
  "입원하다",
  "자취",
  "잠그다",
  "잡수시다",
  "장난감",
  "장례",
  "전기밥솥",
  "지금껏",
  "지름길",
  "지진",
  "참석자",
  "창구",
  "최저",
  "코미디",
  "튀기다",
  "필수",
  "하품",
  "한국말",
  "횟수",
  "흐리다",
  "가능",
  "값싸다",
  "계란",
  "교문",
  "권투",
  "긴장감",
  "김",
  "넷",
  "매달다",
  "매주",
  "문제되다",
  "받침",
  "북",
  "불이익",
  "불필요하다",
  "산부인과",
  "새우다",
  "서명하다",
  "수",
  "수고하다",
  "수학",
  "신부",
  "쓸데없다",
  "아뇨",
  "아래쪽",
  "아스팔트",
  "아하",
  "알코올",
  "액세서리",
  "에어컨",
  "염려하다",
  "오이",
  "외삼촌",
  "요",
  "웃음소리",
  "위쪽",
  "음료수",
  "인쇄",
  "잔디",
  "전세",
  "조깅",
  "초대",
  "치다",
  "캠퍼스",
  "터널",
  "통",
  "퇴직금",
  "환갑",
  "가만있다",
  "간편하다",
  "감동적",
  "건너오다",
  "관광지",
  "교복",
  "교포",
  "귀가하다",
  "날아다니다",
  "마음씨",
  "만",
  "메모",
  "몇십",
  "몸매",
  "몸무게",
  "무관심",
  "반짝이다",
  "배달",
  "번역",
  "부인",
  "분주하다",
  "비닐봉지",
  "비판적",
  "뺏다",
  "사전",
  "서랍",
  "소나기",
  "소중히",
  "손잡이",
  "수도꼭지",
  "실례",
  "싸구려",
  "안녕",
  "안되다",
  "약국",
  "어찌나",
  "엉터리",
  "연하다",
  "원숭이",
  "위법",
  "육체적",
  "음력",
  "이혼",
  "일회용",
  "잔디밭",
  "저기",
  "전문직",
  "전화기",
  "제출",
  "지난주",
  "진달래",
  "쪽",
  "찌다",
  "차남",
  "채점",
  "침착하다",
  "캄캄하다",
  "타자기",
  "팬티",
  "편히",
  "포인트",
  "포크",
  "한밤중",
  "효도",
  "가구",
  "간호사",
  "개나리",
  "고등학생",
  "골목길",
  "귀가",
  "그리워하다",
  "기억나다",
  "기침",
  "노랫소리",
  "다섯",
  "닭고기",
  "대학교수",
  "딸아이",
  "만족스럽다",
  "미움",
  "바가지",
  "발끝",
  "배꼽",
  "병실",
  "봉사하다",
  "비행장",
  "사랑스럽다",
  "숟가락",
  "술병",
  "습기",
  "쓰이다",
  "엽서",
  "예보",
  "요일",
  "욕실",
  "용감하다",
  "운전기사",
  "위험성",
  "익다",
  "일",
  "장마",
  "재학",
  "전기",
  "전시하다",
  "정기적",
  "종교적",
  "주관적",
  "직장인",
  "진심",
  "질문하다",
  "짧아지다",
  "찌다",
  "책가방",
  "천국",
  "치약",
  "큰절",
  "학과",
  "후회",
  "강조",
  "게시판",
  "공통점",
  "과외",
  "교내",
  "구",
  "금요일",
  "김밥",
  "내적",
  "놀이터",
  "다녀가다",
  "답장",
  "마라톤",
  "매스컴",
  "무",
  "묵다",
  "바나나",
  "발등",
  "밤새",
  "별일",
  "보내오다",
  "부채",
  "불법",
  "블라우스",
  "사업가",
  "사이좋다",
  "세상에",
  "수고",
  "수요일",
  "슬퍼하다",
  "시금치",
  "신청서",
  "쓰레기통",
  "아랫사람",
  "앞쪽",
  "앨범",
  "양",
  "여전하다",
  "영남",
  "옥상",
  "왼발",
  "운전하다",
  "위",
  "일기",
  "일자리",
  "자매",
  "저번",
  "전시장",
  "절약",
  "정답",
  "정오",
  "제대하다",
  "졸다",
  "즉석",
  "지방",
  "차차",
  "추측",
  "충고",
  "코피",
  "턱",
  "팩시밀리",
  "한낮",
  "할인",
  "가득히",
  "간호",
  "갑",
  "건너",
  "걷다",
  "겁나다",
  "고구마",
  "국기",
  "금메달",
  "노트",
  "눈감다",
  "눈뜨다",
  "대화하다",
  "도마",
  "동화책",
  "모집하다",
  "미워하다",
  "밤색",
  "밥맛",
  "배우자",
  "복사",
  "볶음밥",
  "성별",
  "세탁",
  "소문나다",
  "소용없다",
  "속상하다",
  "손수건",
  "송아지",
  "수돗물",
  "수만",
  "수입되다",
  "수입품",
  "술자리",
  "식기",
  "싼값",
  "안",
  "안부",
  "양주",
  "어린이날",
  "영화배우",
  "오른발",
  "용서",
  "우편",
  "이사하다",
  "일등",
  "작아지다",
  "정장",
  "좁히다",
  "좋아",
  "죽",
  "줄무늬",
  "지워지다",
  "지저분하다",
  "진통",
  "찬성하다",
  "참석",
  "초콜릿",
  "최고급",
  "최상",
  "최악",
  "출국",
  "친해지다",
  "코끝",
  "터미널",
  "피자",
  "한국적",
  "햄",
  "환영",
  "가로수",
  "간식",
  "감",
  "개다",
  "고급스럽다",
  "고소하다",
  "공주",
  "깨어지다",
  "남",
  "네거리",
  "노란색",
  "녹음하다",
  "달러",
  "대여섯",
  "되돌아보다",
  "마사지",
  "만",
  "먹히다",
  "몸살",
  "무더위",
  "무용가",
  "박스",
  "반기다",
  "밥솥",
  "베개",
  "벨트",
  "복사하다",
  "붓다",
  "비",
  "사계절",
  "사과하다",
  "사투리",
  "상금",
  "수저",
  "식욕",
  "앞바다",
  "얄밉다",
  "양상추",
  "여대생",
  "연기하다",
  "온라인",
  "외갓집",
  "외할머니",
  "유능하다",
  "유적지",
  "음악가",
  "응답하다",
  "이롭다",
  "이분",
  "인삼",
  "인상",
  "입사",
  "자",
  "재활용",
  "점원",
  "제삿날",
  "주름살",
  "참고하다",
  "찻잔",
  "철학적",
  "초대",
  "추천하다",
  "칫솔",
  "큰길",
  "판매되다",
  "편",
  "포근하다",
  "포도",
  "하나님",
  "학력",
  "한정하다",
  "향상되다",
  "홈페이지",
  "횡단보도",
  "가톨릭",
  "강당",
  "건조하다",
  "걷기",
  "고모부",
  "공중전화",
  "공통",
  "과거",
  "국가적",
  "귓속",
  "그래픽",
  "근교",
  "기초적",
  "녹음",
  "다양성",
  "떠나오다",
  "마중",
  "밝다",
  "밤중",
  "배드민턴",
  "배부르다",
  "버튼",
  "보수적",
  "캐나다",
  "불확실하다",
  "비만",
  "빗방울",
  "사회자",
  "샤워",
  "서투르다",
  "세탁기",
  "손뼉",
  "손수",
  "송편",
  "시들다",
  "시디",
  "신사",
  "신혼부부",
  "싱겁다",
  "쓸데없이",
  "야하다",
  "어쩌다가",
  "옛날이야기",
  "오븐",
  "온종일",
  "와인",
  "왕비",
  "우승하다",
  "운반",
  "웃어른",
  "인사말",
  "자판기",
  "장모님",
  "저녁때",
  "저울",
  "적어지다",
  "지폐",
  "진실로",
  "촛불",
  "출퇴근",
  "치과",
  "테스트",
  "통역",
  "퇴근하다",
  "표시하다",
  "피로하다",
  "하",
  "한데",
  "혼잣말",
  "화나다",
  "효도하다",
  "힘없이",
  "가로",
  "갈아타다",
  "건넌방",
  "곧이어",
  "공기",
  "굉장하다",
  "그래서",
  "기업인",
  "긴장되다",
  "깨끗해지다",
  "낚싯대",
  "남동생",
  "냇물",
  "단골",
  "단맛",
  "담요",
  "대",
  "더러워지다",
  "데우다",
  "도착",
  "동그랗다",
  "안동",
  "무덥다",
  "뭘",
  "반대편",
  "발바닥",
  "발톱",
  "밝아지다",
  "밤새다",
  "부잣집",
  "분명해지다",
  "사진기",
  "산길",
  "상추",
  "섭씨",
  "소시지",
  "쇼핑",
  "수업",
  "스케줄",
  "스키",
  "시외",
  "심각해지다",
  "싹",
  "씻기다",
  "안심하다",
  "약",
  "약수",
  "약품",
  "양배추",
  "양보",
  "양옆",
  "얕다",
  "어두워지다",
  "어쩜",
  "엊그제",
  "여고생",
  "여왕",
  "연기되다",
  "열",
  "예약",
  "올라타다",
  "운동화",
  "이곳저곳",
  "이다음",
  "일회용품",
  "읽히다",
  "잡수다",
  "장사꾼",
  "점심때",
  "주일",
  "즐거워하다",
  "집중하다",
  "짜다",
  "짜증스럽다",
  "천재",
  "초등학교",
  "초보",
  "초여름",
  "초저녁",
  "충돌하다",
  "코트",
  "피곤",
  "학비",
  "해",
  "해외여행",
  "효자",
  "감소되다",
  "강변",
  "공연되다",
  "공휴일",
  "괴로워하다",
  "교시",
  "그리로",
  "까먹다",
  "깜빡",
  "깡패",
  "꾸다",
  "낚시꾼",
  "다양해지다",
  "대중교통",
  "데려오다",
  "마요네즈",
  "마흔",
  "만세",
  "만화가",
  "맘대로",
  "머리말",
  "면접",
  "명함",
  "몸속",
  "무책임하다",
  "반짝거리다",
  "밤새우다",
  "번지",
  "볼펜",
  "빨간색",
  "사립",
  "사생활",
  "사이사이",
  "사자",
  "새소리",
  "생활수준",
  "서툴다",
  "석사",
  "성당",
  "속마음",
  "손잡다",
  "숙녀",
  "순하다",
  "시댁",
  "시부모",
  "신체적",
  "실수하다",
  "쓴맛",
  "야옹",
  "얼마간",
  "올여름",
  "외제",
  "외출하다",
  "욕하다",
  "위아래",
  "음반",
  "의논",
  "이",
  "이래서",
  "이발소",
  "자살하다",
  "자연적",
  "장례식",
  "재수",
  "재작년",
  "재채기",
  "전구",
  "전문점",
  "전통문화",
  "정거장",
  "존댓말",
  "주전자",
  "지우다",
  "찬성",
  "초보자",
  "초청장",
  "촌스럽다",
  "카레",
  "컨디션",
  "코스모스",
  "킬로",
  "토론자",
  "튼튼히",
  "티셔츠",
  "페인트",
  "평상시",
  "중국",
  "학교생활",
  "한둘",
  "해물",
  "형",
  "혼나다",
  "가만",
  "감정적",
  "강북",
  "거리",
  "고무신",
  "고춧가루",
  "관람",
  "교외",
  "교육비",
  "국립",
  "금",
  "기억되다",
  "까다",
  "꽃씨",
  "꾸준하다",
  "난방",
  "내용물",
  "녹화",
  "농구",
  "달다",
  "매번",
  "먹다",
  "무관심하다",
  "무사하다",
  "밤낮",
  "배구",
  "번역하다",
  "병아리",
  "부지런하다",
  "부피",
  "비기다",
  "생활용품",
  "선풍기",
  "세로",
  "섹시하다",
  "소비하다",
  "수리하다",
  "스물",
  "식료품",
  "식히다",
  "싫어지다",
  "씻기다",
  "얼리다",
  "여직원",
  "열리다",
  "옆방",
  "오피스텔",
  "올가을",
  "와이셔츠",
  "원피스",
  "윗사람",
  "이민",
  "이성",
  "이혼하다",
  "인제",
  "입사하다",
  "재우다",
  "전시되다",
  "졸리다",
  "졸음",
  "종이컵",
  "주문",
  "지능",
  "쩔쩔매다",
  "철",
  "청바지",
  "추가되다",
  "치료법",
  "치우다",
  "코끼리",
  "콘서트",
  "큰소리",
  "큰아들",
  "특급",
  "평일",
  "풀어지다",
  "한겨울",
  "한평생",
  "해수욕장",
  "핸드폰",
  "허허",
  "호실",
  "휴지통",
  "흐려지다",
  "희망하다",
  "힘들어하다",
  "강의하다",
  "걱정되다",
  "공연하다",
  "귤",
  "내과",
  "넷째",
  "목걸이",
  "무지개",
  "문법",
  "볶음",
  "볼링",
  "비둘기",
  "생활비",
  "스키장",
  "시대적",
  "쓰다",
  "앞문",
  "인도",
  "책방",
  "추가하다",
  "칠판",
  "크리스마스",
  "탁구",
  "현대적",
  "구",
  "나흘",
  "늦가을",
  "다이어트",
  "목요일",
  "못생기다",
  "영화관",
  "예술적",
  "장모",
  "중국집",
  "지우개",
  "지하도",
  "튀김",
  "고궁",
  "고속버스",
  "단추",
  "대중적",
  "미역",
  "반말",
  "분홍색",
  "사",
  "소포",
  "손녀",
  "수영장",
  "아래층",
  "일본어",
  "작은아버지",
  "잠옷",
  "장갑",
  "초순",
  "퇴원하다",
  "하순",
  "갈비",
  "기혼",
  "대장",
  "모레",
  "삼계탕",
  "생신",
  "서른",
  "시내버스",
  "아홉",
  "예약하다",
  "우표",
  "장미",
  "천둥",
  "퇴원",
  "파란색",
  "게으르다",
  "기념품",
  "냉면",
  "단풍",
  "독일어",
  "문학적",
  "미끄럽다",
  "복숭아",
  "비빔밥",
  "여든",
  "외아들",
  "이따가",
  "일흔",
  "장인",
  "화요일",
  "강아지",
  "닷새",
  "선물하다",
  "아이스크림",
  "외할아버지",
  "요리하다",
  "입국",
  "주차",
  "체육관",
  "최소",
  "칠",
  "칼국수",
  "큰아버지",
  "홍차",
  "국민적",
  "국제선",
  "맛없다",
  "백",
  "사십",
  "안과",
  "연락처",
  "연세",
  "예식장",
  "월세",
  "위층",
  "인삼차",
  "진찰",
  "초록색",
  "축구공",
  "큰딸",
  "팔",
  "결석",
  "김치찌개",
  "녹차",
  "독감",
  "만두",
  "발음하다",
  "보라색",
  "복습하다",
  "스케이트",
  "실례하다",
  "그렇게",
  "여섯",
  "연두색",
  "온돌",
  "육",
  "일곱",
  "출입국",
  "클래식",
  "팝송",
  "팩스",
  "깍두기",
  "국내선",
  "복습",
  "삼십",
  "성함",
  "세탁소",
  "시외버스",
  "야구장",
  "오십",
  "운동복",
  "작은아들",
  "잠자리",
  "주차하다",
  "중국어",
  "청소기",
  "칠십",
  "필통",
  "학생증",
  "형수",
  "경상도",
  "공항버스",
  "관광버스",
  "그저께",
  "냉방",
  "눈병",
  "다섯째",
  "배추김치",
  "사탕",
  "색연필",
  "소아과",
  "양력",
  "예순",
  "오",
  "작은어머니",
  "축구장",
  "한글날",
  "한식",
  "세종대왕",
  "넉",
  "메일",
  "복사기",
  "빗",
  "설렁탕",
  "종로",
  "신라",
  "십",
  "아드님",
  "천",
  "결석하다",
  "기념일",
  "까만색",
  "내후년",
  "쉰",
  "시아버지",
  "식품점",
  "약혼녀",
  "어저께",
  "영상",
  "예매하다",
  "예습",
  "예습하다",
  "육십",
  "출석하다",
  "큰어머니",
  "팔십",
  "멍멍",
  "분필",
  "약혼자",
  "양식",
  "억",
  "여덟",
  "외과",
  "제과점",
  "중식",
  "케첩",
  "편의점",
  "갈비탕",
  "검정색",
  "미용실",
  "아흔",
  "이십",
  "충청도",
  "설악산",
  "조선",
  "거꾸로",
  "인천공항",
  "저렇게",
  "대전",
  "뉴욕",
  "풍경",
  "아프리카",
  "근본",
  "정리",
  "도쿄",
  "동경",
  "워낙",
  "독일",
  "이렇게",
  "발달하다",
  "지적하다",
  "영국",
  "회사",
  "유럽",
  "의식",
  "구십",
  "아시아",
  "기다리다",
  "인천",
  "거기",
  "일식",
  "작은딸",
  "저곳",
  "초등학생",
  "하얀색",
  "남미",
  "금강산",
  "전주",
  "전라도",
  "북한",
  "부산",
  "미국",
  "백두산",
  "백제",
  "베이징",
  "북경",
  "우리나라",
  "대한민국",
  "한국",
  "강원도",
  "경기도",
  "경복궁",
  "경주",
  "고구려",
  "고려",
  "광주",
  "김포공항",
  "울산",
  "호남",
  "일본",
  "파리",
  "지리산",
  "제주도",
  "서울",
  "서울역",
  "남대문",
  "한강",
  "한라산",
  "한반도",
  "평양",
  "프랑스",
  "호주",
  "남대문시장",
  "남산"
]