import React, { useState } from "react";
import { useSpring, a } from "@react-spring/web";

import styles from "./Card.module.css";
import cn from "classnames";
import { useEffect } from "react";


export default function Card({children, className, flip, index, color}) {
  const [flipped, set] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  useEffect(() => {
    if (flip) {
      setTimeout(() => {
        set((state) => !state)
      }, index * 200)
    }
  }, [flip])
  return (
    <div className={cn(className, styles.container)}>
      <a.div
        className={`${styles.c} ${styles.back} ${styles.nothing}`}
        style={{ opacity: opacity.to((o) => 1 - o), transform }}
      >{children}</a.div>
      <a.div
        className={`${styles.c} ${styles.front} ${styles.nothing} ${color}`}
        style={{
          opacity,
          transform,
          rotateX: "180deg"
        }}
      >{children}</a.div>
    </div>
  );
}
