import React from "react";
import "../styles.css";

import styled from "styled-components";

import {rgba} from "polished";


function Block({ children, ...props }) {
    return (
        <div {...props}>
            <div>
                <div>
                    <svg width="15" height="19" viewBox="0 0 15 19" class="injected-svg">
                        <g transform="translate(0 15)">
                            <rect width="15" height="19" fill="rgba(255,255,255,0)"></rect>
                            {children}
                        </g>
                    </svg>
                </div>    
            </div>
        </div>
    );
}

export default styled(Block)`
    position: relative;
    width: max-content;
    position: relative;
    color: rgba(0,0,0,0.1);
    
    width: 100px;
    /* height: 200px; */
    position: relative;

    color: transparent;
  
    .svg {
        position: absolute;
        top: 0;
        left: 0;
        fill: ${rgba('#606060', 0.33)};

        width: 100%;
        height: 100%;
    }

    path {
        stroke: #606060;
        stroke-width: 0.25px;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    path.highlight {
        fill: ${rgba("#00A7FF", 0.33)};
        stroke: #00A7FF !important;
    }

    .shake {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }

    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }
`;