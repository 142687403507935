export default [
  "가",
  "가",
  "가",
  "가",
  "가",
  "가",
  "가가우스어",
  "가감",
  "가강수량",
  "가게",
  "가격",
  "가계",
  "가계",
  "가곡",
  "가공",
  "가공품",
  "가공하다",
  "가관",
  "가구",
  "가구",
  "가까이",
  "가까이",
  "가까이하다",
  "가깝다",
  "가꾸다",
  "가끔",
  "가끔씩",
  "가나",
  "가나",
  "가난",
  "가난하다",
  "가난히",
  "가내공업",
  "가는돌고기",
  "가늘다",
  "가늠",
  "가늠하다",
  "가능",
  "가능성",
  "가능하다",
  "가닛",
  "가다",
  "가돌리늄",
  "가두녀성",
  "가두다",
  "가두리",
  "가두배추",
  "가득",
  "가득가득",
  "가득하다",
  "가득히",
  "가따까나",
  "가라데",
  "가라앉다",
  "가라오케",
  "가락",
  "가락지",
  "가락지빵",
  "가람",
  "가람",
  "가래",
  "가련하다",
  "가련히",
  "가렵다",
  "가렸지만",
  "가령",
  "가로",
  "가로",
  "가로등",
  "가로막",
  "가로채다",
  "가루",
  "가르다",
  "가르치다",
  "가르침",
  "가름",
  "가리개",
  "가리다",
  "가리비",
  "가리키다",
  "가마",
  "가마",
  "가마",
  "가마",
  "가마솥",
  "가마우지",
  "가마치",
  "가만",
  "가만",
  "가만가만",
  "가만두다",
  "가만히",
  "가면",
  "가면극",
  "가명",
  "가무이야기",
  "가물가물",
  "가물치",
  "가뭄",
  "가미카제",
  "가발",
  "가방",
  "가벼워지다",
  "가볍다",
  "가보로네",
  "가봉",
  "가브리엘",
  "가사",
  "가사",
  "가사",
  "가산",
  "가새",
  "가설",
  "가설",
  "가설",
  "가설법",
  "가세요",
  "가소롭다",
  "가소제",
  "가솔린",
  "가수",
  "가숭어",
  "가스",
  "가스레인지",
  "가스파초",
  "가슴",
  "가슴뼈",
  "가시",
  "가시고기",
  "가시나",
  "가시납지리",
  "가시다",
  "가시아버지",
  "가시어머니",
  "가시집",
  "가식",
  "가식하다",
  "가야",
  "가야금",
  "가언적",
  "가언적",
  "가없다",
  "가없이",
  "가엾다",
  "가오리",
  "가요",
  "가요",
  "가운데",
  "가위",
  "가위바위보",
  "가을",
  "가이드",
  "가이아나",
  "가자",
  "가장",
  "가장",
  "가장",
  "가장자리",
  "가재",
  "가정",
  "가정",
  "가정법",
  "가정부",
  "가제",
  "가젤",
  "가져가다",
  "가져오다",
  "가족",
  "가주",
  "가죽",
  "가죽끈",
  "가지",
  "가지",
  "가지",
  "가지",
  "가지가지",
  "가지가지",
  "가지고",
  "가지다",
  "가지뿔",
  "가짜",
  "가차",
  "가창",
  "가창",
  "가창오리",
  "가축",
  "가치",
  "가치관",
  "가타나",
  "가타카나",
  "가톨릭",
  "가톨릭교회",
  "가파르다",
  "가하다",
  "가하다",
  "가히",
  "각",
  "각",
  "각각",
  "각국",
  "각기",
  "각도",
  "각막",
  "각본",
  "각선미",
  "각시붕어",
  "각자",
  "각종",
  "각주구검",
  "각하",
  "간",
  "간",
  "간",
  "간",
  "간계",
  "간곡",
  "간곡하다",
  "간균",
  "간단",
  "간단",
  "간단하다",
  "간단하다",
  "간단히",
  "간모",
  "간물",
  "간부",
  "간사",
  "간사하다",
  "간사하다",
  "간선도로",
  "간섭",
  "간성",
  "간식",
  "간신",
  "간쑤",
  "간악",
  "간악하다",
  "간유",
  "간음",
  "간장",
  "간장",
  "간접",
  "간접화법",
  "간지",
  "간직하다",
  "간질",
  "간질거리다",
  "간질대다",
  "간질이다",
  "간첩",
  "간체",
  "간체",
  "간체자",
  "간추리다",
  "간통",
  "간판",
  "간편하다",
  "간헐천",
  "간호사",
  "간호원",
  "갇히다",
  "갈",
  "갈겨니",
  "갈고",
  "갈고랑이",
  "갈고리",
  "갈기",
  "갈까마귀",
  "갈다",
  "갈다",
  "갈다",
  "갈등",
  "갈라지다",
  "갈래",
  "갈륨",
  "갈리",
  "갈리시아",
  "갈림길",
  "갈매기",
  "갈문망둑",
  "갈밭쥐",
  "갈보",
  "갈비",
  "갈비뼈",
  "갈비탕",
  "갈색",
  "갈색얼가니새",
  "갈수록",
  "갈아입다",
  "갈아타다",
  "갈옷",
  "갈음하다",
  "갈증",
  "갈퀴",
  "갈퀴다",
  "갈토",
  "갉다",
  "갋다",
  "감",
  "감",
  "감",
  "감",
  "감각",
  "감각적",
  "감각적인",
  "감금",
  "감기",
  "감기다",
  "감다",
  "감당하다",
  "감독",
  "감돌고기",
  "감동",
  "감동사",
  "감람",
  "감람",
  "감로",
  "감리교",
  "감마",
  "감미료",
  "감방",
  "감비아",
  "감사",
  "감사하다",
  "감사하다",
  "감사합니다",
  "감사합니다",
  "감사합니다",
  "감소",
  "감수하다",
  "감숙",
  "감시",
  "감싸다",
  "감염",
  "감옥",
  "감자",
  "감자",
  "감정",
  "감초",
  "감추다",
  "감칠맛",
  "감탄",
  "감탄사",
  "감히",
  "갑",
  "갑",
  "갑",
  "갑각",
  "갑옷",
  "갑이다",
  "갑이다",
  "갑이다",
  "갑자기",
  "갑작변이",
  "갑작부자",
  "갑작스럽다",
  "갑작스레",
  "갑충",
  "갑판",
  "값",
  "값나가다",
  "값어치",
  "강",
  "강간",
  "강계",
  "강남",
  "강냉이",
  "강당",
  "강대국",
  "강도",
  "강도다리",
  "강력",
  "강력하다",
  "강렬하다",
  "강물",
  "강사",
  "강생이",
  "강서",
  "강설",
  "강세",
  "강소",
  "강수",
  "강수량",
  "강아지",
  "강요하다",
  "강원도",
  "강의",
  "강정",
  "강제",
  "강조",
  "강조하다",
  "강좌",
  "강주걱양태",
  "강준치",
  "강철",
  "강추",
  "강판",
  "강하다",
  "강화도",
  "강화하다",
  "갖가지",
  "갖가지",
  "갖다",
  "갖추다",
  "같다",
  "같은",
  "같이",
  "같이",
  "같이하다",
  "같잖다",
  "갚다",
  "개",
  "개",
  "개가",
  "개가하다",
  "개고기",
  "개관식",
  "개구락지",
  "개구리",
  "개구리꺽정이",
  "개구리매",
  "개구장이",
  "개구쟁이",
  "개국",
  "개국",
  "개꿈",
  "개꿩",
  "개나리",
  "개나리꽃",
  "개념",
  "개다",
  "개량",
  "개량",
  "개리",
  "개미",
  "개발",
  "개발되다",
  "개발자",
  "개발하다",
  "개밥바라기",
  "개방",
  "개방하다",
  "개벽",
  "개별자",
  "개살구",
  "개새끼",
  "개선",
  "개선하다",
  "개성",
  "개성",
  "개성",
  "개성",
  "개소겡",
  "개수",
  "개시",
  "개월",
  "개인",
  "개인숭배",
  "개인적",
  "개자식",
  "개장",
  "개장",
  "개장국",
  "개저씨",
  "개정",
  "개지랄",
  "개지랄하다",
  "개척",
  "개천",
  "개체",
  "개판",
  "개폐기",
  "개학",
  "개한민국",
  "개혁",
  "개회식",
  "객",
  "객공잡이",
  "객관",
  "객관적",
  "객기",
  "객실",
  "객쩍다",
  "객차",
  "객체",
  "갤러리",
  "갤리",
  "갭",
  "갯과",
  "갯첨서",
  "갱",
  "갱",
  "갱개",
  "갸울다",
  "갸웃",
  "갸웃거리다",
  "갹출",
  "걔",
  "거",
  "거",
  "거기",
  "거꾸로",
  "거대하다",
  "거두다",
  "거드름",
  "거듭",
  "거듭씨",
  "거듭홀소리",
  "거래",
  "거렁뱅이",
  "거룩하다",
  "거류",
  "거르다",
  "거름",
  "거리",
  "거리",
  "거리",
  "거리다",
  "거만",
  "거만하다",
  "거머리",
  "거문고",
  "거미",
  "거미줄",
  "거부기",
  "거부하다",
  "거북",
  "거북이",
  "거세",
  "거세다",
  "거센소리",
  "거스러미",
  "거스르다",
  "거스름돈",
  "거시기",
  "거시기",
  "거실",
  "거야",
  "거야",
  "거울",
  "거웃",
  "거위",
  "거의",
  "거인",
  "거절하다",
  "거주",
  "거주하다",
  "거즈",
  "거지",
  "거짓",
  "거짓말",
  "거짓말장이",
  "거짓말쟁이",
  "거짓말하다",
  "거치다",
  "거치적거리다",
  "거칠다",
  "거품",
  "거흠",
  "걱정",
  "걱정되다",
  "걱정하다",
  "건",
  "건",
  "건",
  "건",
  "건강",
  "건강하다",
  "건강히",
  "건너가다",
  "건너다",
  "건너오다",
  "건너편",
  "건널목",
  "건네다",
  "건달",
  "건답",
  "건드리다",
  "건망증",
  "건물",
  "건반",
  "건배",
  "건설",
  "건설하다",
  "건의",
  "건전하다",
  "건조",
  "건조기",
  "건조하다",
  "건조하다",
  "건지다",
  "건초",
  "건축",
  "건축가",
  "건축물",
  "건축학",
  "걷기",
  "걷다",
  "걷다",
  "걷다",
  "걸다",
  "걸다",
  "걸레",
  "걸리다",
  "걸림씨",
  "걸신",
  "걸어",
  "걸어가다",
  "걸음",
  "걸음걸이",
  "걸음마",
  "걸인",
  "걸작",
  "걸쭉하다",
  "걸치다",
  "걸프렌드",
  "걸핏걸핏",
  "검",
  "검",
  "검다",
  "검댕",
  "검독수리",
  "검둥오리",
  "검둥오리사촌",
  "검둥이",
  "검사",
  "검열",
  "검은금",
  "검은담비",
  "검은댕기해오라기",
  "검은딸기",
  "검은머리흰따오기",
  "검은머리흰죽지",
  "검은목논병아리",
  "검은색",
  "검은해오라기",
  "검정",
  "검정망둑",
  "검정색",
  "검증",
  "검질",
  "검질기다",
  "검찰",
  "검찰청",
  "검토",
  "겁",
  "겁나다",
  "겁대가리",
  "겁먹다",
  "겁쟁이",
  "것",
  "것도",
  "것일까",
  "겉",
  "겉핥다",
  "게",
  "게",
  "게",
  "게",
  "게",
  "게",
  "게",
  "게놈",
  "게다가",
  "게르마늄",
  "게르만",
  "게릴라",
  "게맛살",
  "게사니",
  "게시판",
  "게우다",
  "게으르다",
  "게으름",
  "게으름뱅이",
  "게이",
  "게이기",
  "게이샤",
  "게이트",
  "게임",
  "게장",
  "게타",
  "게톱치",
  "겠",
  "겨",
  "겨누다",
  "겨드랑이",
  "겨레",
  "겨루다",
  "겨를",
  "겨리",
  "겨우",
  "겨우살이",
  "겨울",
  "겨자",
  "격",
  "격납고",
  "격려",
  "격언",
  "겪다",
  "견",
  "견과",
  "견디다",
  "견본",
  "견유",
  "견해",
  "결",
  "결",
  "결",
  "결과",
  "결과",
  "결과적",
  "결과적",
  "결국",
  "결론",
  "결사옹위",
  "결석",
  "결석하다",
  "결심",
  "결심하다",
  "결장",
  "결점",
  "결정",
  "결정",
  "결정되다",
  "결정되다",
  "결정수",
  "결정짓다",
  "결정하다",
  "결정화",
  "결코",
  "결핵",
  "결혼",
  "결혼식",
  "결혼하다",
  "겸",
  "겸손",
  "겸손하다",
  "겹",
  "겹다",
  "겹치다",
  "경",
  "경",
  "경",
  "경계",
  "경계하다",
  "경고",
  "경고하다",
  "경구",
  "경기",
  "경기",
  "경기도",
  "경기장",
  "경력",
  "경련",
  "경로",
  "경마",
  "경매",
  "경멸적",
  "경보기",
  "경비",
  "경비",
  "경비원",
  "경사",
  "경사",
  "경상남도",
  "경상도",
  "경상북도",
  "경색",
  "경성",
  "경성",
  "경어",
  "경영",
  "경옥",
  "경외",
  "경우",
  "경이롭다",
  "경이원지",
  "경쟁",
  "경쟁력",
  "경제",
  "경제적",
  "경제학",
  "경죄",
  "경주",
  "경찰",
  "경찰견",
  "경찰관",
  "경찰서",
  "경치",
  "경향",
  "경험",
  "경험치",
  "경험하다",
  "곁",
  "곁사슬",
  "계간",
  "계곡",
  "계곡산개구리",
  "계급",
  "계단",
  "계란",
  "계란후라이",
  "계렬",
  "계모",
  "계부",
  "계사",
  "계산",
  "계산기",
  "계산서",
  "계산원",
  "계산하다",
  "계세요",
  "계속",
  "계속",
  "계속되다",
  "계속하다",
  "계수",
  "계승",
  "계시",
  "계시다",
  "계시판",
  "계약",
  "계약하다",
  "계열",
  "계절",
  "계절적",
  "계좌",
  "계집",
  "계집년",
  "계집아이",
  "계집애",
  "계층",
  "계통",
  "계획",
  "계획하다",
  "고",
  "고",
  "고",
  "고개",
  "고객",
  "고갱이",
  "고고리",
  "고관",
  "고구려",
  "고구마",
  "고국",
  "고급",
  "고기",
  "고기",
  "고기겹빵",
  "고기떡",
  "고난",
  "고냉이",
  "고니",
  "고대",
  "고대교회슬라브어",
  "고독",
  "고독하다",
  "고드름",
  "고등어",
  "고등어과",
  "고등학교",
  "고라니",
  "고래",
  "고래",
  "고량주",
  "고려",
  "고려",
  "고려말",
  "고려하다",
  "고루",
  "고르다",
  "고르다",
  "고름",
  "고름",
  "고름",
  "고리",
  "고리",
  "고리",
  "고리도롱뇽",
  "고리무늬물범",
  "고리짝",
  "고릴라",
  "고립지",
  "고마워",
  "고맙다",
  "고맙습니다",
  "고모",
  "고모부",
  "고무",
  "고무오리",
  "고문",
  "고물",
  "고민",
  "고민하다",
  "고바야시박쥐",
  "고발",
  "고방오리",
  "고백",
  "고백하다",
  "고뿌",
  "고산",
  "고생",
  "고생하다",
  "고소하다",
  "고속",
  "고속도로",
  "고수",
  "고수",
  "고수",
  "고수",
  "고스트",
  "고슴도치",
  "고시",
  "고아",
  "고아원",
  "고약하다",
  "고양이",
  "고양이고래",
  "고요",
  "고요하다",
  "고유",
  "고유명사",
  "고유어",
  "고을",
  "고이다",
  "고이다",
  "고인돌",
  "고작",
  "고장",
  "고장",
  "고전",
  "고전적",
  "고정",
  "고조",
  "고조되다",
  "고지",
  "고질병",
  "고집",
  "고집불통",
  "고집스럽다",
  "고집하다",
  "고체",
  "고추",
  "고추돌고래",
  "고추자지",
  "고추장",
  "고춧가루",
  "고치다",
  "고통",
  "고통스럽다",
  "고투",
  "고프다",
  "고해",
  "고향",
  "고혈압",
  "고환",
  "곡",
  "곡물",
  "곡식",
  "곡예사",
  "곡조",
  "곤",
  "곤",
  "곤난",
  "곤돌라",
  "곤들매기",
  "곤란",
  "곤란하다",
  "곤밥",
  "곤이",
  "곤이",
  "곤이",
  "곤충",
  "곤충학",
  "곧",
  "곧다",
  "곧바로",
  "곧은창자",
  "곧장",
  "골",
  "골",
  "골",
  "골다",
  "골목",
  "골무",
  "골반",
  "골수",
  "골인",
  "골짜기",
  "골키퍼",
  "골탄",
  "골프",
  "곪다",
  "곰",
  "곰국",
  "곰쥐",
  "곰팡이",
  "곱다",
  "곱사",
  "곱사연어",
  "곱셈",
  "곱하다",
  "곳",
  "곳곳",
  "공",
  "공",
  "공",
  "공",
  "공간",
  "공갈",
  "공갈치다",
  "공갈하다",
  "공개",
  "공개하다",
  "공격",
  "공격전",
  "공공",
  "공교",
  "공구",
  "공군",
  "공급",
  "공기",
  "공기",
  "공동",
  "공동체",
  "공로",
  "공룡",
  "공리",
  "공모",
  "공무원",
  "공민",
  "공병",
  "공부",
  "공부",
  "공부",
  "공부자",
  "공부하다",
  "공사",
  "공사",
  "공사",
  "공산주의",
  "공산주의자",
  "공세",
  "공손",
  "공손하다",
  "공수도",
  "공수병",
  "공습",
  "공습",
  "공식",
  "공식어",
  "공알",
  "공언",
  "공언",
  "공언하다",
  "공언하다",
  "공업",
  "공연",
  "공용어",
  "공원",
  "공인",
  "공인",
  "공자",
  "공자",
  "공작",
  "공작",
  "공작",
  "공장",
  "공정",
  "공정",
  "공정",
  "공정하다",
  "공주",
  "공중",
  "공중전화",
  "공직자",
  "공짜",
  "공책",
  "공통점",
  "공포",
  "공학",
  "공항",
  "공해",
  "공허하다",
  "공화국",
  "공황",
  "곶",
  "과",
  "과",
  "과",
  "과",
  "과거",
  "과거",
  "과거",
  "과거",
  "과거",
  "과금",
  "과녁",
  "과두",
  "과들루쁘",
  "과들루프",
  "과떼말라",
  "과로",
  "과로사",
  "과목",
  "과부",
  "과식",
  "과업",
  "과연",
  "과일",
  "과일단물",
  "과자",
  "과장",
  "과정",
  "과제",
  "과즙",
  "과태료",
  "과테말라",
  "과학",
  "과학관",
  "과학기술",
  "과학자",
  "과학적",
  "곽",
  "곽",
  "곽밥",
  "관",
  "관",
  "관개",
  "관객",
  "관계",
  "관계대명사",
  "관계어",
  "관계없이",
  "관계자",
  "관광",
  "관광객",
  "관광학",
  "관념",
  "관대하다",
  "관람차",
  "관련",
  "관련되다",
  "관련하다",
  "관료제",
  "관리",
  "관리자",
  "관리하다",
  "관목",
  "관문",
  "관박쥐",
  "관복",
  "관사",
  "관상동맥",
  "관상동맥질환",
  "관성",
  "관세",
  "관수리",
  "관습",
  "관심",
  "관용구",
  "관음",
  "관자놀이",
  "관장",
  "관절",
  "관절염",
  "관점",
  "관찰",
  "관찰하다",
  "관코박쥐",
  "관하다",
  "관한",
  "관형사",
  "괄",
  "괄시",
  "괄약근",
  "괄호",
  "괌",
  "괌도",
  "광",
  "광",
  "광검",
  "광견병",
  "광경",
  "광고",
  "광구",
  "광구",
  "광구",
  "광년",
  "광대",
  "광대뼈",
  "광동",
  "광둥",
  "광명성절",
  "광물",
  "광부",
  "광산",
  "광서",
  "광석",
  "광선",
  "광시",
  "광야",
  "광업",
  "광역시",
  "광우병",
  "광원",
  "광장",
  "광주",
  "광주",
  "광천수",
  "광초",
  "광학",
  "광합성",
  "광호흡",
  "광화문",
  "괜찮다",
  "괜찮아",
  "괜히",
  "괭이",
  "괭이",
  "괴",
  "괴다",
  "괴롭다",
  "괴롭히다",
  "괴뢰",
  "괴물",
  "괴수",
  "괴짜",
  "괴한",
  "굉장히",
  "교",
  "교가",
  "교각",
  "교거",
  "교과서",
  "교구",
  "교도",
  "교도소",
  "교또",
  "교량",
  "교류",
  "교리",
  "교만",
  "교미",
  "교미하다",
  "교복",
  "교사",
  "교사",
  "교수",
  "교수대",
  "교실",
  "교양",
  "교역",
  "교외",
  "교원",
  "교육",
  "교육하다",
  "교육학",
  "교의",
  "교장",
  "교재",
  "교전",
  "교정",
  "교정폭력",
  "교조",
  "교차로",
  "교차위",
  "교탁",
  "교통",
  "교통사고",
  "교편",
  "교포",
  "교향곡",
  "교환",
  "교활하다",
  "교황",
  "교회",
  "교회론",
  "구",
  "구",
  "구",
  "구",
  "구",
  "구",
  "구각형",
  "구강",
  "구개",
  "구결",
  "구경",
  "구경군",
  "구경하다",
  "구굴무치",
  "구글",
  "구급차",
  "구기자",
  "구나",
  "구내식당",
  "구녕",
  "구니",
  "구니하다",
  "구더기",
  "구덩이",
  "구두",
  "구두",
  "구두쇠",
  "구들",
  "구라파",
  "구락부",
  "구렁",
  "구레나룻",
  "구류",
  "구르다",
  "구르다",
  "구름",
  "구름다리",
  "구리",
  "구리",
  "구리다",
  "구린내",
  "구마",
  "구마모또",
  "구매",
  "구매하다",
  "구멍",
  "구무",
  "구미호",
  "구별",
  "구별되다",
  "구별하다",
  "구부러지다",
  "구부리다",
  "구분",
  "구분자",
  "구분하다",
  "구상",
  "구석",
  "구석구석",
  "구석차기",
  "구성",
  "구성",
  "구성되다",
  "구성원",
  "구성하다",
  "구속",
  "구슬",
  "구식",
  "구십",
  "구아니딘",
  "구아바",
  "구애",
  "구애하다",
  "구약성경",
  "구어",
  "구역",
  "구역질",
  "구요",
  "구월",
  "구유",
  "구이",
  "구이저우",
  "구입",
  "구입하다",
  "구쟁이",
  "구절",
  "구조",
  "구주",
  "구체",
  "구체적",
  "구축",
  "구축함",
  "구치소",
  "구타",
  "구하다",
  "구호금",
  "구혼",
  "국",
  "국",
  "국가",
  "국가",
  "국가였던",
  "국경",
  "국고",
  "국기",
  "국내",
  "국내선",
  "국립",
  "국면",
  "국물",
  "국민",
  "국민학교",
  "국밥",
  "국뽕",
  "국수",
  "국수뱅어",
  "국시",
  "국시",
  "국어",
  "국어학",
  "국왕",
  "국자",
  "국자",
  "국장",
  "국적",
  "국제",
  "국제보조어",
  "국제어",
  "국제화",
  "국토",
  "국한",
  "국한문",
  "국화",
  "국회",
  "국회의원",
  "군",
  "군",
  "군",
  "군관",
  "군국주의",
  "군다",
  "군단",
  "군대",
  "군데",
  "군도",
  "군디",
  "군만두",
  "군바리",
  "군복",
  "군사",
  "군사",
  "군소리",
  "군요",
  "군인",
  "군주",
  "군주국",
  "군주제",
  "군중",
  "군중가요",
  "군체",
  "군함조",
  "굳",
  "굳다",
  "굳다",
  "굳어지다",
  "굳이",
  "굴",
  "굴곡위",
  "굴다",
  "굴뚝",
  "굴리다",
  "굴묵낭",
  "굴비",
  "굴착기",
  "굵다",
  "굵다랗다",
  "굶기다",
  "굶다",
  "굶어",
  "굶주리다",
  "굶주림",
  "굽",
  "굽다",
  "굽다",
  "굽이",
  "굽이굽이",
  "굽히다",
  "굿",
  "궁",
  "궁",
  "궁",
  "궁극적",
  "궁금하다",
  "궁둥이",
  "궁민",
  "궁수",
  "궁전",
  "궁중",
  "궁핍",
  "권",
  "권고",
  "권고하다",
  "권력",
  "권리",
  "권위",
  "권위주의",
  "권총",
  "권투",
  "권하다",
  "궐",
  "궐련",
  "궤도",
  "궨당",
  "귀",
  "귀고리",
  "귀구멍",
  "귀국",
  "귀국하다",
  "귀두",
  "귀때기",
  "귀뚜라미",
  "귀리",
  "귀밑",
  "귀밑털",
  "귀뿔논병아리",
  "귀신",
  "귀신고래",
  "귀싸대기",
  "귀엣말",
  "귀엣말하다",
  "귀여운",
  "귀염둥이",
  "귀엽다",
  "귀요미",
  "귀절",
  "귀주",
  "귀찮다",
  "귀책사유",
  "귀하다",
  "귓결",
  "귓구멍",
  "귓불",
  "규률",
  "규모",
  "규소",
  "규약",
  "규율",
  "규정",
  "규칙",
  "규칙적",
  "균",
  "균계",
  "균형",
  "귤",
  "그",
  "그",
  "그거",
  "그것",
  "그게",
  "그곳",
  "그끄저께",
  "그끄제",
  "그나마",
  "그날",
  "그냥",
  "그네",
  "그네들",
  "그녀",
  "그녀들",
  "그노시스",
  "그늘",
  "그늘지다",
  "그다음",
  "그다지",
  "그대",
  "그대로",
  "그동안",
  "그들",
  "그때",
  "그라프",
  "그라피토",
  "그람",
  "그래",
  "그래",
  "그래도",
  "그래서",
  "그래프",
  "그래픽",
  "그램",
  "그러나",
  "그러니까",
  "그러다",
  "그러면",
  "그러므로",
  "그러하다",
  "그런",
  "그런데",
  "그럼",
  "그럼",
  "그렇게",
  "그렇다",
  "그렇지",
  "그렇지만",
  "그레나다",
  "그레이비",
  "그레이트브리튼",
  "그레이프프루트",
  "그려지다",
  "그루",
  "그루지야",
  "그루터기",
  "그룹",
  "그르다",
  "그르치다",
  "그릇",
  "그릇",
  "그릇되다",
  "그리",
  "그리고",
  "그리다",
  "그리스",
  "그리스도",
  "그리스도교",
  "그리스어",
  "그리움",
  "그리워",
  "그리워하다",
  "그리하여",
  "그린란드",
  "그릴",
  "그림",
  "그림",
  "그림엽서",
  "그림자",
  "그림책",
  "그립다",
  "그만",
  "그만두다",
  "그만큼",
  "그만큼",
  "그물",
  "그믐",
  "그믐날",
  "그분",
  "그야말로",
  "그을다",
  "그이",
  "그저",
  "그저께",
  "그제",
  "그중",
  "그쪽",
  "그치다",
  "그토록",
  "극단주의",
  "극동",
  "극복하다",
  "극작가",
  "극장",
  "극히",
  "근",
  "근",
  "근거",
  "근교",
  "근데",
  "근래",
  "근로",
  "근로자",
  "근면",
  "근면하다",
  "근무",
  "근무하다",
  "근본",
  "근본적",
  "근본적",
  "근삿값",
  "근시",
  "근원",
  "근육",
  "근일점",
  "근절하다",
  "근접",
  "근지럽다",
  "근질근질하다",
  "근질근질하다",
  "근처",
  "근혜",
  "글",
  "글라스",
  "글라스노스트",
  "글라이더",
  "글래머",
  "글램핑",
  "글루텐",
  "글리세롤",
  "글리세린",
  "글쎄",
  "글쎄요",
  "글쓰기",
  "글씨",
  "글자",
  "글피",
  "긁다",
  "금",
  "금",
  "금",
  "금강모치",
  "금개구리",
  "금고",
  "금귤",
  "금년",
  "금발",
  "금방",
  "금성",
  "금세",
  "금속",
  "금연",
  "금요일",
  "금욕",
  "금일",
  "금자탑",
  "금전",
  "금주",
  "금지",
  "금지하다",
  "금하다",
  "급",
  "급격하게",
  "급격하다",
  "급기야",
  "급료",
  "급여",
  "급하다",
  "긋다",
  "긍정적",
  "기",
  "기",
  "기",
  "기",
  "기",
  "기",
  "기",
  "기",
  "기",
  "기",
  "기각",
  "기간",
  "기계",
  "기계",
  "기계",
  "기공",
  "기관",
  "기관",
  "기관",
  "기관차",
  "기관총",
  "기구",
  "기구",
  "기구",
  "기근",
  "기기",
  "기꺼이",
  "기녀",
  "기념비",
  "기념일",
  "기념품",
  "기능",
  "기니",
  "기니",
  "기니비사우",
  "기니피그",
  "기다랗다",
  "기다리다",
  "기대",
  "기대다",
  "기대되다",
  "기대하다",
  "기도",
  "기도매듭",
  "기도하다",
  "기독",
  "기독교",
  "기독교도",
  "기둥",
  "기똥차다",
  "기라성",
  "기러기",
  "기록",
  "기록하다",
  "기르다",
  "기름",
  "기름",
  "기름사탕",
  "기름종개",
  "기린",
  "기린영양",
  "기만",
  "기만되다",
  "기만하다",
  "기망",
  "기모노",
  "기물",
  "기민하다",
  "기발",
  "기법",
  "기본",
  "기본적",
  "기본적",
  "기부",
  "기분",
  "기뻐하다",
  "기쁘다",
  "기쁨",
  "기쁨조",
  "기사",
  "기사",
  "기사",
  "기상학",
  "기생",
  "기생물",
  "기생충",
  "기선",
  "기소",
  "기수사",
  "기숙사",
  "기술",
  "기술자",
  "기슭",
  "기승위",
  "기시감",
  "기아",
  "기어가다",
  "기억",
  "기억력",
  "기억하다",
  "기업",
  "기업가",
  "기업인",
  "기여",
  "기여하다",
  "기역",
  "기온",
  "기와",
  "기요틴",
  "기운",
  "기울",
  "기울",
  "기울다",
  "기울이다",
  "기원",
  "기원전",
  "기윽",
  "기자",
  "기저",
  "기저귀",
  "기적",
  "기적",
  "기준",
  "기중기",
  "기지",
  "기집애",
  "기차",
  "기차다",
  "기차역",
  "기체",
  "기초",
  "기초하다",
  "기총",
  "기치",
  "기침",
  "기침하다",
  "기타",
  "기타",
  "기하학",
  "기합",
  "기호",
  "기회",
  "기회주의자",
  "기획",
  "기후",
  "긴급",
  "긴꼬리꼬마쥐",
  "긴꼬리윗수염박쥐",
  "긴날개박쥐",
  "긴몰개",
  "긴발톱첨서",
  "긴부리참돌고래",
  "긴장",
  "긷다",
  "길",
  "길거리",
  "길냥이",
  "길다",
  "길림",
  "길이",
  "길잡이",
  "김",
  "김",
  "김",
  "김",
  "김멜",
  "김밥",
  "김일성화",
  "김정일화",
  "김책",
  "김치",
  "김치전",
  "김치찌개",
  "김치찌게",
  "깁",
  "깁",
  "깁다",
  "깁스",
  "깃",
  "깃",
  "깃발",
  "깃털",
  "깊다",
  "깊이",
  "깊이",
  "까",
  "까까",
  "까께",
  "까뀌",
  "까다",
  "까다롭다",
  "까닭",
  "까렐리야",
  "까마귀",
  "까마귓과",
  "까막눈",
  "까맣다",
  "까메룬",
  "까벨",
  "까불다",
  "까비",
  "까자흐스딴",
  "까지",
  "까치",
  "까치돌고래",
  "까치복",
  "깍두기",
  "깎다",
  "깔",
  "깔개",
  "깔다",
  "깔때기",
  "깔리다",
  "깜둥이",
  "깜짝",
  "깡통",
  "깡통따개",
  "깡패",
  "깨끗이",
  "깨끗하다",
  "깨끗한",
  "깨다",
  "깨닫다",
  "깨달음",
  "깨뜨리다",
  "깨물다",
  "깨알",
  "깨어나다",
  "깨우다",
  "깨인절미",
  "깨지다",
  "깨트리다",
  "깻잎",
  "꺼",
  "꺼내다",
  "꺼지다",
  "꺼지다",
  "꺼풀",
  "꺽저구",
  "꺽저기",
  "꺽정이",
  "꺽지",
  "꺾다",
  "꺾어지다",
  "껌",
  "껍질",
  "께",
  "께서",
  "껴",
  "꼬리",
  "꼬리지느러미",
  "꼬리치레도롱뇽",
  "꼬리표",
  "꼬마",
  "꼬마뒤쥐",
  "꼬마청황",
  "꼬마향고래",
  "꼬집다",
  "꼬챙이",
  "꼬치",
  "꼬치구이",
  "꼬치동자개",
  "꼭",
  "꼭",
  "꼭대기",
  "꼭두각시",
  "꼴",
  "꼴사납다",
  "꽁치",
  "꽂다",
  "꽃",
  "꽃가루",
  "꽃꽂이",
  "꽃말",
  "꽃미남",
  "꽃뱀",
  "꽃병",
  "꽃봉오리",
  "꽃잎",
  "꽃집",
  "꽉",
  "꽤",
  "꽹과리",
  "꾀꼬리",
  "꾀병",
  "꾸구리",
  "꾸는",
  "꾸는",
  "꾸다",
  "꾸다",
  "꾸란",
  "꾸러미",
  "꾸르륵꾸르륵",
  "꾸리다",
  "꾸미다",
  "꾸바",
  "꾸준히",
  "꾸짖다",
  "꾹저구",
  "꿀",
  "꿀밤",
  "꿀벌",
  "꿀잼",
  "꿇다",
  "꿈",
  "꿈꾸다",
  "꿈을",
  "꿋꿋하다",
  "꿩",
  "꿰다",
  "꿰매다",
  "끄다",
  "끄덕이다",
  "끄르다",
  "끄리",
  "끄집다",
  "끄트러기",
  "끈",
  "끈기",
  "끈적끈적하다",
  "끊다",
  "끊이다",
  "끊임",
  "끊임없다",
  "끊임없이",
  "끌",
  "끌다",
  "끌리다",
  "끌어안다",
  "끓다",
  "끓이다",
  "끔찍하다",
  "끝",
  "끝나다",
  "끝내",
  "끝내다",
  "끼",
  "끼니",
  "끼다",
  "끼우다",
  "끼치다",
  "끽반하다",
  "나",
  "나",
  "나",
  "나",
  "나",
  "나가다",
  "나각",
  "나귀",
  "나근나근",
  "나나니",
  "나누다",
  "나누이다",
  "나눗셈",
  "나는",
  "나는",
  "나는",
  "나는",
  "나는",
  "나다",
  "나도",
  "나도",
  "나도",
  "나도",
  "나도",
  "나라",
  "나란히",
  "나루",
  "나룻배",
  "나르다",
  "나름",
  "나리",
  "나머지",
  "나무",
  "나무껍질",
  "나무늘보",
  "나무라다",
  "나물",
  "나뭇가지",
  "나미비아",
  "나발",
  "나방",
  "나비",
  "나빠지다",
  "나쁘다",
  "나사",
  "나사돌리개",
  "나서다",
  "나선",
  "나선형",
  "나성",
  "나소",
  "나아가다",
  "나아지다",
  "나오다",
  "나우루",
  "나의",
  "나이",
  "나이로비",
  "나이오븀",
  "나이지리아",
  "나이트",
  "나이트",
  "나이트",
  "나이팅게일",
  "나이프",
  "나이피다우",
  "나일론",
  "나일틸라피아",
  "나절",
  "나중",
  "나찰",
  "나치",
  "나치즘",
  "나침반",
  "나타나다",
  "나타내다",
  "나트륨",
  "나폴리",
  "나프타",
  "나프탈렌",
  "나프티리딘",
  "나한",
  "나홋카",
  "나흘",
  "낙",
  "낙",
  "낙엽",
  "낙제",
  "낙지",
  "낙천주의",
  "낙천주의자",
  "낙타",
  "낙태",
  "낙하산",
  "낙하하다",
  "낚",
  "낚시",
  "낚시꾼",
  "낚시하다",
  "낛",
  "난",
  "난",
  "난각",
  "난간",
  "난로",
  "난리",
  "난리",
  "난민",
  "난백",
  "난센스",
  "난소",
  "난수",
  "난시",
  "난자",
  "난쟁이",
  "난처하다",
  "난황",
  "낟",
  "날",
  "날",
  "날",
  "날",
  "날",
  "날",
  "날",
  "날개",
  "날개망둑",
  "날갯죽지",
  "날것",
  "날고기",
  "날다",
  "날뛰다",
  "날리다",
  "날마다",
  "날망둑",
  "날씨",
  "날씬하다",
  "날아가다",
  "날아오다",
  "날아오르다",
  "날짐승",
  "날짜",
  "날카롭다",
  "낡다",
  "남",
  "남",
  "남극",
  "남극대륙",
  "남극해",
  "남근",
  "남기다",
  "남녀",
  "남녀노소",
  "남녘",
  "남다",
  "남동생",
  "남루",
  "남만",
  "남미",
  "남방",
  "남방동사리",
  "남방종개",
  "남벌",
  "남부",
  "남비",
  "남빙양",
  "남사친",
  "남새",
  "남새",
  "남새",
  "남서부",
  "남성",
  "남수단",
  "남아공",
  "남아메리카",
  "남아프리카",
  "남아프리카공화국",
  "남용",
  "남자",
  "남자다운",
  "남자답다",
  "남자아이",
  "남자친구",
  "남작",
  "남장",
  "남조선",
  "남존여비",
  "남쪽",
  "남체",
  "남친",
  "남편",
  "남포",
  "남한",
  "남해",
  "남해",
  "납",
  "납량",
  "납세자",
  "납자루",
  "납작하다",
  "납줄개",
  "납지리",
  "납치",
  "낫",
  "낫다",
  "낫다",
  "낫돌고래",
  "낭비",
  "낭인",
  "낭하",
  "낮",
  "낮다",
  "낮아지다",
  "낮에는",
  "낮잠",
  "낮추다",
  "낯",
  "낯설다",
  "낯익다",
  "낱",
  "낱낱이",
  "낱말",
  "낳다",
  "내",
  "내",
  "내",
  "내가",
  "내가",
  "내가",
  "내각",
  "내강",
  "내내",
  "내년",
  "내놓다",
  "내다",
  "내다보다",
  "내려가다",
  "내려놓다",
  "내려다보다",
  "내려오다",
  "내리다",
  "내몰다",
  "내몽고",
  "내몽골",
  "내밀다",
  "내보내다",
  "내부",
  "내심",
  "내외",
  "내용",
  "내음",
  "내일",
  "내전",
  "내접원",
  "내주다",
  "내지",
  "낼",
  "낼",
  "냄비",
  "냄새",
  "냅킨",
  "냉동",
  "냉동고",
  "냉면",
  "냉무",
  "냉수",
  "냉장고",
  "냉전",
  "냐",
  "너",
  "너구리",
  "너그럽다",
  "너는",
  "너더댓",
  "너덧",
  "너만큼",
  "너머",
  "너머로",
  "너무",
  "너무나",
  "너비아니",
  "너트",
  "너희",
  "넋",
  "넌",
  "널",
  "널",
  "널다",
  "널뛰기",
  "널리",
  "널빤지",
  "넓다",
  "넓이",
  "넓적다리",
  "넓적부리",
  "넓히다",
  "넘기다",
  "넘다",
  "넘어가다",
  "넘어서다",
  "넘어지다",
  "넘치다",
  "넙치",
  "넣다",
  "네",
  "네",
  "네",
  "네",
  "네",
  "네거리",
  "네다섯",
  "네댓",
  "네덜란드",
  "네덜란드어",
  "네로",
  "네모",
  "네모꼴",
  "네바다",
  "네발가락도롱뇽",
  "네안데르탈인",
  "네오나치",
  "네오디뮴",
  "네온",
  "네이멍구",
  "네트워크",
  "네티즌",
  "네팔",
  "네피도",
  "넥타이",
  "넵투늄",
  "넷",
  "녀석",
  "녀자",
  "년",
  "년",
  "년대",
  "년도",
  "념",
  "녕",
  "녕하",
  "녘",
  "노",
  "노",
  "노",
  "노",
  "노",
  "노",
  "노고지리",
  "노끈",
  "노년",
  "노느다",
  "노는",
  "노다지",
  "노동",
  "노동법",
  "노동자",
  "노동조합",
  "노란",
  "노란색",
  "노랑",
  "노랑부리백로",
  "노랑부리저어새",
  "노랗다",
  "노래",
  "노래방",
  "노래하다",
  "노랫말",
  "노려보다",
  "노력",
  "노력하다",
  "노례",
  "노루",
  "노르다",
  "노르마",
  "노르웨이",
  "노르웨이어",
  "노른자",
  "노른자위",
  "노리다",
  "노리다",
  "노리다",
  "노면",
  "노면전차",
  "노벨륨",
  "노병",
  "노보시비르스크",
  "노사",
  "노새",
  "노서아",
  "노선",
  "노쇠",
  "노숙하다",
  "노숙하다",
  "노아",
  "노안",
  "노안",
  "노어",
  "노여움",
  "노엽다",
  "노예",
  "노을",
  "노이로제",
  "노인",
  "노인병학",
  "노인의학",
  "노인학",
  "노잼",
  "노처녀",
  "노출",
  "노크",
  "노크노크",
  "노크하다",
  "노트",
  "노트북",
  "노파",
  "노화",
  "노환",
  "녹",
  "녹다",
  "녹두나물",
  "녹말",
  "녹색",
  "녹음",
  "녹음기",
  "녹음실",
  "녹이다",
  "녹차",
  "논",
  "논다니",
  "논란",
  "논리",
  "논리학",
  "논문",
  "논병아리",
  "논의",
  "논의하다",
  "논쟁",
  "논쟁점",
  "논평",
  "논하다",
  "놀",
  "놀다",
  "놀라다",
  "놀라움",
  "놀랍다",
  "놀리다",
  "놀아나다",
  "놀이",
  "놀이공원",
  "놀이터",
  "놈",
  "놋쇠",
  "농",
  "농",
  "농경",
  "농구",
  "농노",
  "농담",
  "농담",
  "농담하다",
  "농민",
  "농부",
  "농부",
  "농사",
  "농산물",
  "농어",
  "농업",
  "농장",
  "농촌",
  "높다",
  "높아지다",
  "높이",
  "높이다",
  "놓다",
  "놓이다",
  "놓치다",
  "놔",
  "놔두다",
  "뇌",
  "뇌",
  "뇌관",
  "뇌물",
  "뇌우",
  "뇨",
  "뇨소",
  "누",
  "누구",
  "누군가",
  "누굽다",
  "누그러뜨리다",
  "누나",
  "누난",
  "누난",
  "누님",
  "누다",
  "누두",
  "누들",
  "누렁이",
  "누렇다",
  "누르다",
  "누르다",
  "누리",
  "누리",
  "누리",
  "누리",
  "누리",
  "누리다",
  "누리다",
  "누악쇼트",
  "누이",
  "누이동생",
  "누치",
  "누쿠알로파",
  "눅다",
  "눅다",
  "눈",
  "눈",
  "눈",
  "눈길",
  "눈꺼풀",
  "눈꼬리",
  "눈꼴사납다",
  "눈다랑어",
  "눈동자",
  "눈동자개",
  "눈두덩",
  "눈뜨다",
  "눈물",
  "눈보라",
  "눈불개",
  "눈빛",
  "눈사람",
  "눈사태",
  "눈송이",
  "눈싸움",
  "눈싸움",
  "눈썹",
  "눈앞",
  "눈엣가시",
  "눈웃음",
  "눈치",
  "눋다",
  "눕다",
  "눠",
  "뉘우치다",
  "뉴델리",
  "뉴스",
  "뉴욕",
  "뉴질랜드",
  "뉴트리아",
  "느껴지다",
  "느끼다",
  "느끼하다",
  "느낌",
  "느낌표",
  "느냐",
  "느리다",
  "느린",
  "느티나무",
  "늑대",
  "늑대인간",
  "는",
  "는",
  "는",
  "는다",
  "는데",
  "는지",
  "늘",
  "늘다",
  "늘리다",
  "늘어나다",
  "늘어서다",
  "늙다",
  "늙다리",
  "늙은이",
  "능",
  "능격",
  "능동태",
  "능력",
  "늦가을",
  "늦다",
  "늦다",
  "늦잠",
  "늦추다",
  "늪",
  "늬",
  "니",
  "니",
  "니",
  "니",
  "니들",
  "니들",
  "니아메",
  "니은",
  "니제르",
  "니카라과",
  "니케아",
  "니켈",
  "니코시아",
  "니코틴",
  "니트릴",
  "니호늄",
  "닉네임",
  "닉스타말화",
  "닌자",
  "님",
  "님",
  "닙",
  "닝샤",
  "닢",
  "다",
  "다",
  "다",
  "다",
  "다",
  "다",
  "다",
  "다가",
  "다가",
  "다가가다",
  "다가오다",
  "다각형",
  "다거다",
  "다과",
  "다그치다",
  "다녀가다",
  "다녀오다",
  "다니다",
  "다다르다",
  "다다미",
  "다들",
  "다듬다",
  "다락",
  "다락방",
  "다람쥐",
  "다랑어",
  "다랗다",
  "다래",
  "다래",
  "다롄",
  "다루다",
  "다르다",
  "다르에스살람",
  "다른",
  "다름슈타튬",
  "다름없다",
  "다리",
  "다리미",
  "다리미질",
  "다리미질하다",
  "다리미판",
  "다림질",
  "다림질하다",
  "다마네기",
  "다마스쿠스",
  "다만",
  "다묵장어",
  "다물다",
  "다발",
  "다방",
  "다섯",
  "다섯째",
  "다소",
  "다소",
  "다수",
  "다시",
  "다신론",
  "다양하다",
  "다엽",
  "다운로드",
  "다윗",
  "다음",
  "다이아몬드",
  "다이어그램",
  "다이어트",
  "다이오드",
  "다정하다",
  "다정히",
  "다지다",
  "다짐",
  "다짐하다",
  "다차",
  "다치다",
  "다카",
  "다카르",
  "다큐멘터리",
  "다크서클",
  "다투다",
  "다툼",
  "다하다",
  "다행",
  "닥치다",
  "닥치다",
  "닦다",
  "단",
  "단",
  "단계",
  "단고기",
  "단념",
  "단념하다",
  "단단하다",
  "단락",
  "단무지",
  "단발",
  "단발",
  "단발",
  "단백",
  "단백질",
  "단소",
  "단수",
  "단순하다",
  "단순히",
  "단어",
  "단엽기",
  "단위",
  "단일",
  "단지",
  "단지",
  "단천",
  "단체",
  "단추",
  "단편소설",
  "단풍",
  "단풍나무",
  "닫다",
  "닫다",
  "달",
  "달",
  "달걀",
  "달구다",
  "달구지",
  "달다",
  "달다",
  "달다",
  "달다",
  "달다",
  "달다",
  "달다",
  "달다",
  "달라다",
  "달라다",
  "달라다",
  "달라다",
  "달라다",
  "달라다",
  "달라다",
  "달라다",
  "달라지다",
  "달러",
  "달려가다",
  "달려오다",
  "달력",
  "달리",
  "달리다",
  "달마다",
  "달마다",
  "달무리",
  "달빛",
  "달성",
  "달아나다",
  "달콤하다",
  "달콤한",
  "달팽이",
  "달필",
  "달하다",
  "닭",
  "닭갈비",
  "닭고기",
  "닭발",
  "닭살",
  "닭싸움",
  "닭쌈",
  "닭쌈",
  "닮다",
  "닮음",
  "닳다",
  "담",
  "담그다",
  "담기다",
  "담다",
  "담당",
  "담당하다",
  "담박하다",
  "담배",
  "담배갑",
  "담백하다",
  "담뱃대",
  "담벼락",
  "담보물",
  "담비",
  "담요",
  "담임",
  "담주",
  "담황",
  "답",
  "답",
  "답다",
  "답답하다",
  "답사",
  "답정너",
  "답하다",
  "닷새",
  "당",
  "당",
  "당",
  "당구",
  "당근",
  "당기다",
  "당나귀",
  "당뇨병",
  "당당하다",
  "당면",
  "당밀",
  "당시",
  "당시",
  "당신",
  "당신들",
  "당연",
  "당연하다",
  "당연해",
  "당연히",
  "당장",
  "당장",
  "당질",
  "당하다",
  "당황",
  "당황하다",
  "닻",
  "닿다",
  "닿소리",
  "대",
  "대",
  "대",
  "대가",
  "대개",
  "대개",
  "대검집",
  "대관령",
  "대교구",
  "대구",
  "대구",
  "대규모",
  "대기",
  "대기",
  "대기실",
  "대기업",
  "대기하다",
  "대나무",
  "대농갱이",
  "대다",
  "대다",
  "대다",
  "대다",
  "대다수",
  "대단하다",
  "대단히",
  "대답",
  "대답하다",
  "대두",
  "대두어",
  "대략",
  "대량",
  "대령",
  "대로",
  "대륙",
  "대륙밭쥐",
  "대륙사슴",
  "대륙송사리",
  "대륙종개",
  "대리인",
  "대리점",
  "대마",
  "대만",
  "대명사",
  "대모",
  "대문",
  "대박",
  "대박",
  "대변",
  "대부분",
  "대북",
  "대비하다",
  "대비하다",
  "대사",
  "대사관",
  "대상",
  "대상",
  "대상자",
  "대서양",
  "대성당",
  "대수",
  "대수",
  "대수",
  "대시보드",
  "대신",
  "대신",
  "대신하다",
  "대야",
  "대양",
  "대여섯",
  "대역폭",
  "대왕고래",
  "대우",
  "대위",
  "대음순",
  "대응",
  "대응하다",
  "대작자",
  "대장",
  "대장",
  "대장",
  "대장",
  "대저택",
  "대전",
  "대전",
  "대주교",
  "대중",
  "대중문화",
  "대지",
  "대책",
  "대천사",
  "대체로",
  "대추",
  "대출",
  "대충",
  "대콴",
  "대통령",
  "대퇴",
  "대포",
  "대폭적",
  "대폭적",
  "대표",
  "대표단",
  "대표적",
  "대표적",
  "대프리카",
  "대하다",
  "대학",
  "대학교",
  "대학생",
  "대학원",
  "대학원생",
  "대한민국",
  "대한제국",
  "대합실",
  "대형",
  "대화",
  "대황어",
  "대회",
  "댁",
  "댁",
  "댄서",
  "댄스",
  "댄스",
  "댈러스",
  "댐",
  "댕기흰죽지",
  "더",
  "더",
  "더구나",
  "더듬다",
  "더듬이",
  "더듬이다리",
  "더러워지다",
  "더럽다",
  "더불다",
  "더불어",
  "더브늄",
  "더블린",
  "더블유",
  "더빙",
  "더욱",
  "더욱이",
  "더위",
  "더하다",
  "더하다",
  "덕",
  "덕분",
  "덕천",
  "덕택",
  "덕택에",
  "덕트",
  "던",
  "던데",
  "던지다",
  "던지럽다",
  "덜",
  "덜다",
  "덤불해오라기",
  "덥다",
  "덧",
  "덧글",
  "덧니",
  "덧붙다",
  "덧붙이다",
  "덧셈",
  "덩어리",
  "덫",
  "덮다",
  "덮밥",
  "덮이다",
  "데",
  "데",
  "데까지",
  "데려가다",
  "데리다",
  "데메테르",
  "데모",
  "데바나가리",
  "데뷔",
  "데이터",
  "데이터베이스",
  "데이트",
  "데이트하다",
  "덱스트린",
  "덴마크",
  "덴마크어",
  "덴푸라",
  "델리",
  "도",
  "도",
  "도",
  "도가니",
  "도검",
  "도교",
  "도구",
  "도금",
  "도기",
  "도깨비",
  "도꾜",
  "도끼",
  "도넛",
  "도다",
  "도다리",
  "도달",
  "도달하다",
  "도대체",
  "도덕",
  "도도마",
  "도둑",
  "도라지",
  "도로",
  "도롱뇽",
  "도료",
  "도리",
  "도리",
  "도마",
  "도마뱀",
  "도망",
  "도망치다",
  "도매",
  "도메인",
  "도미니카",
  "도박",
  "도살장",
  "도서",
  "도서관",
  "도시",
  "도시",
  "도시락",
  "도안",
  "도예",
  "도와주다",
  "도우미",
  "도움",
  "도움말",
  "도이췰란드",
  "도입",
  "도자기",
  "도작",
  "도장",
  "도저히",
  "도적",
  "도중",
  "도착",
  "도착하다",
  "도쿄",
  "도토리",
  "도파민",
  "도표",
  "도플갱어",
  "도하",
  "도화뱅어",
  "독",
  "독",
  "독극물",
  "독도",
  "독립",
  "독립성",
  "독물",
  "독사",
  "독살",
  "독서",
  "독서실",
  "독소",
  "독수리",
  "독신",
  "독신자",
  "독어",
  "독일",
  "독일어",
  "독자",
  "독재",
  "독재자",
  "독특하다",
  "돈",
  "돈",
  "돈가스",
  "돈까스",
  "돋다",
  "돋다",
  "돋보기",
  "돋우다",
  "돌",
  "돌",
  "돌",
  "돌",
  "돌가자미",
  "돌격",
  "돌고기",
  "돌고래",
  "돌궐",
  "돌다",
  "돌돔",
  "돌려주다",
  "돌리다",
  "돌마자",
  "돌멩이",
  "돌보다",
  "돌상어",
  "돌솥",
  "돌아가다",
  "돌아가시다",
  "돌아다니다",
  "돌아보다",
  "돌아서다",
  "돌아오다",
  "돌이키다",
  "돌잔치",
  "돕다",
  "돗자리",
  "동",
  "동",
  "동",
  "동갑",
  "동거",
  "동거인",
  "동경",
  "동경",
  "동공",
  "동굴",
  "동그라미",
  "동글다",
  "동기",
  "동남",
  "동남아시아",
  "동네",
  "동등하다",
  "동력",
  "동료",
  "동맥",
  "동맹",
  "동무",
  "동물",
  "동물원",
  "동방",
  "동방종개",
  "동버들개",
  "동사",
  "동사리",
  "동생",
  "동성",
  "동성애",
  "동성애자",
  "동시",
  "동안",
  "동양",
  "동의",
  "동의어",
  "동의하다",
  "동이",
  "동일",
  "동일하다",
  "동자개",
  "동작",
  "동전",
  "동정",
  "동지",
  "동지",
  "동지",
  "동쪽",
  "동창",
  "동티모르",
  "동포",
  "동해",
  "동해",
  "동행",
  "동화",
  "돛",
  "돛대",
  "돼",
  "돼지",
  "돼지고기",
  "돼지꿈",
  "되",
  "되게",
  "되뇌다",
  "되다",
  "되다",
  "되돌리다",
  "되면",
  "되면",
  "되찾다",
  "되풀이",
  "되풀이하다",
  "된",
  "된소리",
  "된장",
  "된장녀",
  "된장찌개",
  "된장찌게",
  "됨됨",
  "됨됨이",
  "됭경모치",
  "두개골",
  "두건",
  "두견",
  "두견새",
  "두근거리다",
  "두근두근",
  "두꺼비",
  "두껍다",
  "두께",
  "두뇌",
  "두다",
  "두더지",
  "두덩",
  "두드러기",
  "두드러지다",
  "두드리다",
  "두려움",
  "두려워하다",
  "두렵다",
  "두루마기",
  "두루미",
  "두르다",
  "두리안",
  "두마",
  "두만가시고기",
  "두만강자그사니",
  "두목",
  "두바이",
  "두발",
  "두부",
  "두샨베",
  "두서너",
  "두서넛",
  "두세",
  "두셋",
  "두어서넛",
  "두엄",
  "두우쟁이",
  "두유",
  "두음",
  "두줄망둑",
  "두째",
  "두통",
  "둑",
  "둑중개",
  "둔",
  "둔",
  "둔치다",
  "둘",
  "둘",
  "둘",
  "둘러보다",
  "둘러싸다",
  "둥간어",
  "둥글다",
  "둥지",
  "둬",
  "둬",
  "뒤",
  "뒤늦다",
  "뒤따르다",
  "뒤떨어지다",
  "뒤섞다",
  "뒤쥐",
  "뒤지다",
  "뒤집다",
  "뒤쫓다",
  "뒷간",
  "뒷다리",
  "뒷마당",
  "뒷모습",
  "뒹굴다",
  "뒹굴던",
  "뒹굴뒹굴",
  "듀오",
  "드디어",
  "드라마",
  "드라이기",
  "드라이브",
  "드라이브하다",
  "드러나다",
  "드럼",
  "드렁허리",
  "드레스",
  "드론",
  "드르렁드르렁",
  "드리다",
  "드물다",
  "드시다",
  "득남",
  "득녀",
  "득도",
  "득도하다",
  "득점",
  "득템",
  "든",
  "든",
  "듣다",
  "들",
  "들",
  "들",
  "들것",
  "들고양이고래",
  "들꿩",
  "들끓다",
  "들다",
  "들러리",
  "들려오다",
  "들르다",
  "들리다",
  "들리다",
  "들소",
  "들쇠고래",
  "들어가다",
  "들어서다",
  "들어오다",
  "들여다보다",
  "들이다",
  "들치기",
  "들키다",
  "들판",
  "듯",
  "듯",
  "듯싶다",
  "듯이",
  "듯이",
  "듯하다",
  "등",
  "등",
  "등",
  "등광",
  "등나무",
  "등대",
  "등등",
  "등락",
  "등록",
  "등록기",
  "등반",
  "등불",
  "등산",
  "등산하다",
  "등신",
  "등심",
  "등유",
  "등자",
  "등장",
  "등장하다",
  "등줄숭어",
  "등줄쥐",
  "등피",
  "등허리",
  "디귿",
  "디바이스",
  "디스켓",
  "디스코",
  "디스크",
  "디스프로슘",
  "디스플레이",
  "디자이너",
  "디자인",
  "디제이",
  "디지털",
  "딜",
  "딜리",
  "따",
  "따",
  "따님",
  "따다",
  "따뜻하다",
  "따라가다",
  "따라서",
  "따라오다",
  "따라하다",
  "따로",
  "따르다",
  "따르다",
  "따름",
  "따사로운",
  "따사롭다",
  "따오기",
  "따오기",
  "따옴표",
  "따지다",
  "딱",
  "딱",
  "딱",
  "딱다그르르딱다그르르하다",
  "딱딱하다",
  "딱지",
  "딴",
  "딸",
  "딸기",
  "딸꾹질",
  "딸꾹질하다",
  "딸딸이",
  "땀",
  "땃쥐",
  "땅",
  "땅콩",
  "땅크",
  "땋다",
  "때",
  "때가",
  "때늦다",
  "때때로",
  "때로",
  "때리는",
  "때리다",
  "때문",
  "때문에",
  "땐스",
  "땔나무",
  "땜납",
  "땡땡이치다",
  "떠",
  "떠나다",
  "떠돌다",
  "떠들다",
  "떠들썩하다",
  "떠들지",
  "떠세",
  "떠오르다",
  "떠올리다",
  "떡",
  "떡",
  "떡국",
  "떡납줄갱이",
  "떡볶이",
  "떡붕어",
  "떨다",
  "떨리다",
  "떨어뜨리다",
  "떨어지다",
  "떫다",
  "떫디떫다",
  "떳떳하다",
  "떼",
  "떼다",
  "떽",
  "뗀노",
  "뗏목",
  "또",
  "또는",
  "또다시",
  "또한",
  "똑같다",
  "똑같이",
  "똑똑",
  "똑똑이",
  "똑똑하다",
  "똑바로",
  "똠얌",
  "똥",
  "똥개",
  "똥거름",
  "똥구멍",
  "똥남아",
  "똥남아",
  "똥싸다",
  "똥양인",
  "똥차",
  "똥침",
  "뙤약볕",
  "뚜껑",
  "뚜렷하다",
  "뚝배기",
  "뚝섬",
  "뚫다",
  "뚫리다",
  "뚱뚱하다",
  "뛰는",
  "뛰다",
  "뛰르끼예",
  "뛰어가다",
  "뛰어나다",
  "뛰어넘다",
  "뛰어다니다",
  "뛰어들다",
  "뜨거워지는",
  "뜨거워지다",
  "뜨겁다",
  "뜨다",
  "뜨락또르",
  "뜨리다",
  "뜬소문",
  "뜯다",
  "뜰",
  "뜸하다",
  "뜻",
  "뜻밖",
  "뜻밖에",
  "뜻하다",
  "띄다",
  "띠",
  "띠다",
  "띠동갑",
  "띠무늬물범",
  "띠앗",
  "띵호아",
  "라",
  "라",
  "라",
  "라",
  "라",
  "라",
  "라",
  "라고",
  "라고",
  "라고",
  "라고스",
  "라그나로크",
  "라뇨",
  "라는",
  "라니",
  "라니",
  "라도",
  "라돈",
  "라듐",
  "라디에이터",
  "라디오",
  "라며",
  "라며",
  "라며",
  "라면",
  "라바트",
  "라벨",
  "라볶이",
  "라서",
  "라선",
  "라오스",
  "라우터",
  "라운지",
  "라이벌",
  "라이베리아",
  "라이선스",
  "라이터",
  "라이터돌",
  "라이트세이버",
  "라이플",
  "라인",
  "라임",
  "라자냐",
  "라지오",
  "라크로스",
  "라트비아",
  "라틴",
  "라틴어",
  "라파스",
  "라파엘",
  "라한",
  "락사",
  "락하산",
  "란",
  "란",
  "란",
  "란",
  "란시",
  "란제리",
  "란타넘",
  "랍비",
  "랑",
  "래",
  "랜섬웨어",
  "랜턴",
  "랠리",
  "램프",
  "랩",
  "랩톱",
  "랴오닝",
  "략어",
  "량자",
  "러",
  "러더포듐",
  "러브호텔",
  "러시아",
  "러시아말",
  "러시아어",
  "럭비",
  "런던",
  "럼",
  "레",
  "레늄",
  "레닌그라드",
  "레몬",
  "레몬그래스",
  "레바논",
  "레소토",
  "레스토랑",
  "레슬링",
  "레위니옹",
  "레이저",
  "레이캬비크",
  "레인지",
  "레일",
  "레즈비언",
  "레코드",
  "레퍼토리",
  "렌즈",
  "력사",
  "련방",
  "렬도",
  "령도자",
  "령사",
  "령사관",
  "로",
  "로",
  "로",
  "로고",
  "로그",
  "로동",
  "로듐",
  "로드아일랜드",
  "로렌슘",
  "로마",
  "로마자",
  "로망스",
  "로맨스",
  "로맨틱하다",
  "로메",
  "로므니아",
  "로봇",
  "로사리오",
  "로스앤젤레스",
  "로씨야",
  "로안",
  "로조",
  "로켓",
  "로크포르",
  "로키",
  "로토",
  "록",
  "롤러코스터",
  "롭다",
  "롯",
  "뢴트게늄",
  "뢴트겐",
  "료리",
  "료법",
  "룡",
  "루마니아",
  "루멘",
  "루바슈카",
  "루블",
  "루비듐",
  "루사카",
  "루시",
  "루안다",
  "루이지애나",
  "루카",
  "루테늄",
  "루테튬",
  "루페",
  "룩",
  "룩셈부르크",
  "룰렛",
  "룸메이트",
  "류블랴나",
  "류월",
  "류음",
  "류탄",
  "륙",
  "륙십",
  "륜",
  "륜회",
  "르네상스",
  "르완다",
  "를",
  "리",
  "리",
  "리",
  "리",
  "리",
  "리",
  "리",
  "리",
  "리가",
  "리그",
  "리눅스",
  "리더",
  "리듬",
  "리마",
  "리모넨",
  "리모컨",
  "리무진",
  "리버모륨",
  "리본",
  "리브르빌",
  "리비도",
  "리비아",
  "리셉션",
  "리스본",
  "리야드",
  "리어카",
  "리옹",
  "리우데자네이루",
  "리원",
  "리을",
  "리큐어",
  "리터",
  "리투아니아",
  "리튬",
  "리포트",
  "리히텐슈타인",
  "린스",
  "릴",
  "릴롱궤",
  "릴리스",
  "립스틱",
  "링",
  "링크",
  "마",
  "마",
  "마",
  "마가린",
  "마구",
  "마구간",
  "마그네슘",
  "마그마",
  "마나과",
  "마나마",
  "마네킹",
  "마녀",
  "마노",
  "마누",
  "마누라",
  "마늘",
  "마니",
  "마니",
  "마니",
  "마니다",
  "마닐라",
  "마다",
  "마다가스카르",
  "마당",
  "마드라스",
  "마드리드",
  "마디",
  "마땅하다",
  "마라톤",
  "마력",
  "마련",
  "마련되다",
  "마련하다",
  "마루",
  "마르다",
  "마르멜로",
  "마르스",
  "마르코",
  "마르크스주의",
  "마르티니크",
  "마른",
  "마름모",
  "마리",
  "마리화나",
  "마마",
  "마멀레이드",
  "마멋",
  "마무르다",
  "마무리",
  "마무리하다",
  "마법",
  "마법사",
  "마비",
  "마사지",
  "마사지하다",
  "마세루",
  "마술",
  "마술사",
  "마스카라",
  "마스크",
  "마스터베이션",
  "마시다",
  "마약",
  "마요네즈",
  "마우스",
  "마유",
  "마육",
  "마을",
  "마음",
  "마음대로",
  "마음속",
  "마이너스",
  "마이애미",
  "마이크",
  "마이크로버스",
  "마이크로파",
  "마이크로폰",
  "마이크로프로세서",
  "마이트너륨",
  "마일",
  "마작",
  "마장",
  "마주",
  "마주치다",
  "마중하다",
  "마지막",
  "마차",
  "마찬가지",
  "마천루",
  "마취",
  "마치",
  "마치다",
  "마침",
  "마침내",
  "마침표",
  "마카오",
  "마케도니아",
  "마케팅",
  "마켓",
  "마크",
  "마태오",
  "마트료시카",
  "마티아",
  "마파두부",
  "마푸토",
  "마피아",
  "마흔",
  "막",
  "막",
  "막걸리",
  "막내",
  "막다",
  "막대기",
  "막바지",
  "막히다",
  "막힘",
  "막힘없다",
  "만",
  "만",
  "만",
  "만경대",
  "만나다",
  "만날",
  "만남",
  "만년",
  "만년필",
  "만능",
  "만두",
  "만들다",
  "만들어지다",
  "만류",
  "만성",
  "만세",
  "만약",
  "만억",
  "만유인력",
  "만일",
  "만족",
  "만족하다",
  "만주",
  "만지다",
  "만큼",
  "만큼",
  "만포",
  "만하다",
  "만화",
  "만화가",
  "만화책",
  "많다",
  "많아지다",
  "많은",
  "많이",
  "말",
  "말",
  "말",
  "말",
  "말",
  "말",
  "말갛다",
  "말거머리",
  "말고기",
  "말기",
  "말다",
  "말다",
  "말다툼",
  "말똥가리",
  "말똥구리",
  "말뚝",
  "말뚝망둥어",
  "말라깽이",
  "말라보",
  "말라빠지다",
  "말라위",
  "말레",
  "말레이시아",
  "말리",
  "말리다",
  "말리다",
  "말벌",
  "말살하다",
  "말솜씨",
  "말썽꾸러기",
  "말씀",
  "말씀하다",
  "말없이",
  "말을",
  "말을",
  "말을",
  "말을",
  "말을",
  "말을",
  "말하기",
  "말하다",
  "맑다",
  "맑스주의",
  "맘마",
  "맘속",
  "맛",
  "맛",
  "맛보다",
  "맛없다",
  "맛있다",
  "망",
  "망",
  "망",
  "망가니즈",
  "망간",
  "망고",
  "망고스틴",
  "망둑",
  "망막",
  "망명",
  "망설이다",
  "망아지",
  "망연하다",
  "망원경",
  "망치",
  "망하다",
  "맞다",
  "맞다",
  "맞벌이",
  "맞부딪치다",
  "맞서다",
  "맞선",
  "맞은편",
  "맞이",
  "맞이하다",
  "맞추다",
  "맞춤",
  "맞춤법",
  "맞흥정",
  "맡기다",
  "맡다",
  "맡다",
  "매",
  "매",
  "매",
  "매",
  "매국노",
  "매끄럽다",
  "매년",
  "매니저",
  "매니큐어",
  "매다",
  "매다",
  "매달",
  "매달다",
  "매달리다",
  "매독",
  "매듭",
  "매력",
  "매리복",
  "매미",
  "매번",
  "매섭다",
  "매실주",
  "매우",
  "매월",
  "매음굴",
  "매일",
  "매일",
  "매일과",
  "매일과",
  "매장",
  "매점",
  "매주",
  "매주",
  "매체",
  "매춘부",
  "매출",
  "매콤하다",
  "매탄",
  "매트리스",
  "맥",
  "맥",
  "맥아",
  "맥주",
  "맨",
  "맨",
  "맨",
  "맨날",
  "맨션",
  "맬웨어",
  "맵다",
  "맹꽁이",
  "맹렬하다",
  "맹목적",
  "맹세",
  "맹장염",
  "맺다",
  "맺음말",
  "먄마",
  "머구리",
  "머그잔",
  "머루",
  "머리",
  "머리카락",
  "머리칼",
  "머리하다",
  "머릿결",
  "머릿속",
  "머무르다",
  "머물다",
  "머뭇거리다",
  "머슴",
  "머저리",
  "머핀",
  "먹",
  "먹다",
  "먹물",
  "먹방",
  "먹었다",
  "먹이",
  "먹이그물",
  "먹이다",
  "먹황새",
  "먼저",
  "먼지",
  "멀다",
  "멀리",
  "멀미",
  "멀어지다",
  "멀쩡하다",
  "멀찌감치",
  "멈추다",
  "멈칫",
  "멋",
  "멋없다",
  "멋있다",
  "멋지다",
  "멍멍",
  "멍멍",
  "멍멍이",
  "멍에",
  "멍청이",
  "멍청하다",
  "멍텅구리",
  "멍하다",
  "멎다",
  "메",
  "메",
  "메",
  "메가나",
  "메기",
  "메뉴",
  "메다",
  "메달",
  "메뚜기",
  "메릴랜드",
  "메모",
  "메모리",
  "메밀",
  "메서드",
  "메소드",
  "메스",
  "메시아",
  "메시지",
  "메아리",
  "메인",
  "메일",
  "메주",
  "메추라기",
  "메카",
  "메터",
  "메토끼",
  "메히꼬",
  "멕시코",
  "멕시코시티",
  "멘델레븀",
  "멘붕",
  "멜대",
  "멜라닌",
  "멜로디",
  "멜론",
  "멜버른",
  "멜번",
  "멜키체덱",
  "멤버",
  "멥쌀",
  "멧닭",
  "멧돼지",
  "멧박쥐",
  "멧밭쥐",
  "멧토끼",
  "며",
  "며",
  "며느리",
  "며칠",
  "멱",
  "멱살",
  "면",
  "면",
  "면도",
  "면도기",
  "면도칼",
  "면도하다",
  "면사",
  "면서",
  "면세",
  "면양",
  "면역",
  "면적",
  "면접",
  "면책조항",
  "면포",
  "면하다",
  "면허증",
  "면화",
  "멸종",
  "멸치",
  "명",
  "명곡",
  "명관",
  "명란",
  "명령",
  "명령법",
  "명령하다",
  "명명",
  "명명법",
  "명명하다",
  "명부",
  "명사",
  "명사",
  "명성",
  "명예",
  "명왕성",
  "명운",
  "명의",
  "명인",
  "명일",
  "명자",
  "명절",
  "명칭",
  "명태",
  "명하다",
  "명함",
  "몇",
  "몇몇",
  "모",
  "모",
  "모가디슈",
  "모과",
  "모교",
  "모국",
  "모국어",
  "모기",
  "모나코",
  "모니터",
  "모닥불",
  "모델",
  "모뎀",
  "모두",
  "모두",
  "모듈",
  "모드",
  "모든",
  "모듬",
  "모란봉",
  "모래",
  "모래무지",
  "모래상자",
  "모래주사",
  "모레",
  "모로니",
  "모로코",
  "모루",
  "모르겠어",
  "모르다",
  "모르폴린",
  "모르핀",
  "모리셔스",
  "모리타니",
  "모반",
  "모사",
  "모색하다",
  "모샘치",
  "모서리",
  "모세",
  "모세관",
  "모세혈관",
  "모순",
  "모스코븀",
  "모스크",
  "모스크바",
  "모습",
  "모시다",
  "모양",
  "모어",
  "모오캐",
  "모오케",
  "모욕",
  "모욕하다",
  "모으다",
  "모음",
  "모이",
  "모이다",
  "모임",
  "모자",
  "모자",
  "모자라다",
  "모자이크",
  "모잠비크",
  "모조리",
  "모처럼",
  "모충",
  "모치망둑",
  "모태",
  "모터",
  "모터보트",
  "모텔",
  "모토",
  "모퉁이",
  "모페드",
  "모피",
  "모험",
  "모형",
  "목",
  "목",
  "목",
  "목",
  "목걸이",
  "목격자",
  "목구멍",
  "목다리",
  "목도리",
  "목란",
  "목련",
  "목록",
  "목마",
  "목마르다",
  "목마름",
  "목마름이",
  "목면",
  "목발",
  "목사",
  "목성",
  "목소리",
  "목수",
  "목숨",
  "목요일",
  "목욕",
  "목욕실",
  "목욕탕",
  "목욕하다",
  "목자",
  "목장",
  "목재",
  "목적",
  "목적어",
  "목젖",
  "목차",
  "목청",
  "목청껏",
  "목탄",
  "목표",
  "목화",
  "몫",
  "몫몫이",
  "몬로비아",
  "몬순",
  "몬스터",
  "몬테네그로",
  "몬테비데오",
  "몬트리올",
  "몰",
  "몰",
  "몰개",
  "몰다",
  "몰도바",
  "몰디브",
  "몰라",
  "몰라요",
  "몰래",
  "몰리브데넘",
  "몰타",
  "몰타어",
  "몸",
  "몸빛깔",
  "몸짓",
  "몸통",
  "몹시",
  "못",
  "못",
  "못",
  "못살다",
  "못생기다",
  "못지않다",
  "못하다",
  "몽고",
  "몽골",
  "몽구스",
  "뫼",
  "묘사하다",
  "묘지",
  "묠니르",
  "무",
  "무",
  "무",
  "무거운",
  "무겁다",
  "무게",
  "무관심하다",
  "무궁화",
  "무기",
  "무기고",
  "무너지다",
  "무늬",
  "무늬망둑",
  "무당",
  "무당개구리",
  "무당벌레",
  "무대",
  "무덤",
  "무디다",
  "무려",
  "무렵",
  "무례",
  "무료",
  "무료",
  "무르다",
  "무르다",
  "무르다",
  "무릎",
  "무리",
  "무리",
  "무리",
  "무빙워크",
  "무사",
  "무사하다",
  "무산쇠족제비",
  "무서움",
  "무서워하다",
  "무섭다",
  "무성애",
  "무수",
  "무술",
  "무스카트",
  "무슨",
  "무슬림",
  "무시하다",
  "무신론",
  "무어",
  "무어",
  "무어",
  "무언가",
  "무언가",
  "무언증",
  "무엇",
  "무역",
  "무예",
  "무용",
  "무용",
  "무용하다",
  "무자맥질",
  "무자맥질하다",
  "무제한",
  "무조건",
  "무죄",
  "무지개",
  "무진장",
  "무찌르다",
  "무척",
  "무쿠리",
  "무태장어",
  "무화과",
  "묵",
  "묵게하다",
  "묵게하다",
  "묵게하다",
  "묵납자루",
  "묵다",
  "묵다",
  "묵다",
  "묵묵히",
  "묵은지",
  "묵은해",
  "묵주",
  "묶다",
  "묶었던",
  "문",
  "문구",
  "문구점",
  "문둥이박쥐",
  "문득",
  "문명",
  "문방",
  "문배",
  "문법",
  "문서",
  "문신",
  "문어",
  "문의",
  "문자",
  "문자반",
  "문장",
  "문장",
  "문절망둑",
  "문제",
  "문제점",
  "문지기",
  "문지르다",
  "문지방",
  "문진",
  "문천",
  "문턱",
  "문학",
  "문해",
  "문해교육",
  "문화",
  "문화어",
  "문화원",
  "문화재",
  "문화적",
  "문화주택",
  "묻다",
  "묻다",
  "묻다",
  "묻히다",
  "물",
  "물",
  "물가",
  "물가",
  "물개",
  "물건",
  "물결",
  "물고기",
  "물기",
  "물냉면",
  "물다",
  "물두꺼비",
  "물들이다",
  "물러나다",
  "물러서다",
  "물레",
  "물려받다",
  "물론",
  "물리치다",
  "물리학",
  "물만두",
  "물망초",
  "물범",
  "물소",
  "물속",
  "물수리",
  "물시계",
  "물어떼다",
  "물어뜯다",
  "물어보다",
  "물에네르기",
  "물오리",
  "물윗수염박쥐",
  "물음",
  "물음표",
  "물질",
  "물체",
  "묽다",
  "뭄바이",
  "뭍",
  "뭐",
  "뭘",
  "뮈슬리",
  "뮌헨",
  "뮤지컬",
  "뮤직비디오",
  "미",
  "미",
  "미각",
  "미국",
  "미국오리",
  "미국인",
  "미국흰죽지",
  "미깡",
  "미꾸라지",
  "미꾸리",
  "미끄럼대",
  "미끄럽다",
  "미끈망둑",
  "미나리",
  "미녀",
  "미누스",
  "미니버스",
  "미니스커트",
  "미닫이문",
  "미디어",
  "미래",
  "미래학",
  "미러",
  "미로",
  "미루다",
  "미르",
  "미륵",
  "미륵보살",
  "미리",
  "미리보기",
  "미분",
  "미분몫",
  "미분법",
  "미사",
  "미사일",
  "미생물",
  "미성숙하다",
  "미소",
  "미소짓다",
  "미수",
  "미술",
  "미술관",
  "미스",
  "미스터",
  "미스터리",
  "미시간",
  "미신",
  "미싱",
  "미싸일",
  "미아",
  "미아",
  "미안하다",
  "미안하다",
  "미얀마",
  "미어",
  "미어캣",
  "미역",
  "미역국",
  "미용사",
  "미용실",
  "미용원",
  "미워하다",
  "미유기",
  "미음",
  "미인",
  "미장원",
  "미주",
  "미주리",
  "미주알",
  "미주알고주알",
  "미지수",
  "미처",
  "미쳐버리는",
  "미쳐버리는",
  "미치광이",
  "미치다",
  "미치다",
  "미카엘",
  "미터",
  "미투리",
  "미팅",
  "미하일",
  "미합중국",
  "미호종개",
  "미혼모",
  "미확인비행물체",
  "믹서",
  "민",
  "민",
  "민간",
  "민감",
  "민국",
  "민낯",
  "민달팽이",
  "민들레",
  "민물가마우지",
  "민물검정망둑",
  "민병",
  "민부리고래",
  "민서",
  "민속",
  "민수기",
  "민스크",
  "민요",
  "민족",
  "민족주의",
  "민주",
  "민주주의",
  "민주주의자",
  "민주화",
  "민중",
  "민첩성",
  "믿다",
  "믿음",
  "밀",
  "밀",
  "밀가루",
  "밀다",
  "밀당",
  "밀도",
  "밀라노",
  "밀랍",
  "밀리그램",
  "밀리다",
  "밀리미터",
  "밀림",
  "밀어",
  "밀월",
  "밀자개",
  "밀접하다",
  "밀크",
  "밈",
  "밉다",
  "밍크",
  "밍크고래",
  "및",
  "밑",
  "바",
  "바",
  "바",
  "바게트",
  "바구니",
  "바그다드",
  "바깥",
  "바깥어른",
  "바깥쪽",
  "바꾸다",
  "바뀌다",
  "바나나",
  "바나듐",
  "바누아투",
  "바늘",
  "바늘겨레",
  "바다",
  "바다꿩",
  "바다말",
  "바다비오리",
  "바다사자",
  "바다제비",
  "바다코끼리",
  "바다표범",
  "바닥",
  "바닷가",
  "바닷물고기",
  "바둑",
  "바라다",
  "바라보다",
  "바람",
  "바람",
  "바람",
  "바람둥이",
  "바람직하다",
  "바래다",
  "바래다",
  "바램",
  "바레인",
  "바로",
  "바로가기",
  "바로잡다",
  "바륨",
  "바르다",
  "바르다",
  "바르다",
  "바르다",
  "바르다",
  "바르샤바",
  "바른",
  "바리",
  "바마코",
  "바베이도스",
  "바보",
  "바비큐",
  "바쁘다",
  "바삭바삭하다",
  "바스스카프",
  "바스테르",
  "바알",
  "바오로",
  "바오즈",
  "바위",
  "바이러스",
  "바이슨",
  "바이올린",
  "바이트",
  "바자",
  "바자회",
  "바지",
  "바지락",
  "바질",
  "바치다",
  "바쿠",
  "바퀴",
  "바퀴벌레",
  "바탕",
  "바텐더",
  "바티칸",
  "바하마",
  "박",
  "박",
  "박",
  "박",
  "박",
  "박",
  "박",
  "박",
  "박",
  "박다",
  "박대",
  "박람회",
  "박멸",
  "박물관",
  "박사",
  "박수",
  "박수하다",
  "박스",
  "박엽지",
  "박제",
  "박쥐",
  "박차",
  "박치기",
  "박테리아",
  "박하",
  "박해자",
  "박히다",
  "밖",
  "밖에",
  "반",
  "반갑다",
  "반값",
  "반경",
  "반구",
  "반다르세리베가완",
  "반달",
  "반달",
  "반달가슴곰",
  "반대",
  "반대말",
  "반대어",
  "반대하다",
  "반도",
  "반도체",
  "반드시",
  "반딧불",
  "반딧불이",
  "반란",
  "반려",
  "반립자",
  "반말",
  "반면",
  "반물질",
  "반바지",
  "반반",
  "반발",
  "반복",
  "반복하다",
  "반사",
  "반사경",
  "반성",
  "반시옷",
  "반역",
  "반역자",
  "반영",
  "반영하다",
  "반올림",
  "반응",
  "반의어",
  "반입자",
  "반장",
  "반전",
  "반절",
  "반주",
  "반죽",
  "반줄",
  "반지",
  "반지름",
  "반짝이다",
  "반찬",
  "반침",
  "반하다",
  "반항",
  "반항하다",
  "받다",
  "받아들이다",
  "받아쓰기",
  "받으세요",
  "받침",
  "발",
  "발",
  "발가락",
  "발갛다",
  "발견",
  "발견되다",
  "발견하다",
  "발구지",
  "발기",
  "발기",
  "발기다",
  "발끈",
  "발끝",
  "발달",
  "발달하다",
  "발뒤꿈",
  "발뒤꿈치",
  "발라드",
  "발란",
  "발랄라이카",
  "발레",
  "발레타",
  "발매",
  "발매되다",
  "발매하다",
  "발명",
  "발목",
  "발바닥",
  "발바리",
  "발바리차",
  "발생",
  "발생하다",
  "발음",
  "발음하다",
  "발인",
  "발전",
  "발전",
  "발전하다",
  "발정",
  "발짝",
  "발코니",
  "발톱",
  "발표",
  "발표되다",
  "발표하다",
  "발해",
  "발행소",
  "발행인",
  "발효",
  "발휘하다",
  "밝다",
  "밝다",
  "밝을녘",
  "밝쥐",
  "밝혀지다",
  "밝히다",
  "밟다",
  "밤",
  "밤",
  "밤낮",
  "밤새",
  "밤새",
  "밤색",
  "밤샘",
  "밤이",
  "밤이",
  "밤참",
  "밥",
  "밥벌이",
  "밥상",
  "밥솥",
  "밧줄",
  "방",
  "방",
  "방가면",
  "방공",
  "방광",
  "방구",
  "방귀",
  "방귀뀌다",
  "방글라데시",
  "방금",
  "방기",
  "방망이",
  "방명",
  "방문",
  "방문객",
  "방문하다",
  "방법",
  "방법론",
  "방사능",
  "방석집",
  "방송",
  "방송국",
  "방송원",
  "방송하다",
  "방수",
  "방수의",
  "방식",
  "방식",
  "방심",
  "방아",
  "방안",
  "방앗간",
  "방어",
  "방어하다",
  "방언",
  "방영",
  "방울",
  "방위각",
  "방위군",
  "방점",
  "방접원",
  "방정식",
  "방주",
  "방주",
  "방지",
  "방추",
  "방치하다",
  "방콕",
  "방탄",
  "방패",
  "방학",
  "방해",
  "방해하다",
  "방향",
  "방향족",
  "방화",
  "방화",
  "밭",
  "배",
  "배",
  "배",
  "배",
  "배",
  "배가사리",
  "배경",
  "배고프다",
  "배고픔",
  "배교",
  "배구",
  "배기관",
  "배꼽",
  "배낭",
  "배낭",
  "배다",
  "배다",
  "배다",
  "배다",
  "배달",
  "배드민턴",
  "배반",
  "배반하다",
  "배변하다",
  "배송",
  "배수관",
  "배수관",
  "배스",
  "배신",
  "배신하다",
  "배아",
  "배알",
  "배양액",
  "배우",
  "배우다",
  "배우자",
  "배워주다",
  "배지",
  "배추",
  "배터리",
  "배트맨",
  "백",
  "백",
  "백개먼",
  "백경",
  "백과",
  "백과",
  "백과사전",
  "백과전서",
  "백금",
  "백내장",
  "백단",
  "백두산",
  "백두산뒤쥐",
  "백련어",
  "백로",
  "백로",
  "백마",
  "백만",
  "백미러",
  "백반",
  "백설",
  "백설",
  "백성",
  "백신",
  "백악",
  "백악관",
  "백악기",
  "백야",
  "백은",
  "백의민족",
  "백인",
  "백일몽",
  "백작",
  "백제",
  "백조",
  "백조어",
  "백질",
  "백치",
  "백치",
  "백파이프",
  "백포도주",
  "백합",
  "백해",
  "백혈구",
  "백혈병",
  "백화점",
  "밴대질",
  "밴댕이",
  "밴드",
  "밸런타인데이",
  "밸브",
  "뱀",
  "뱀머리돌고래",
  "뱀장어",
  "뱃사람",
  "뱅어",
  "뱉다",
  "버드나무",
  "버들",
  "버들가지",
  "버들개",
  "버들매치",
  "버들붕어",
  "버들치",
  "버릇",
  "버리다",
  "버릴",
  "버마",
  "버무리다",
  "버버리",
  "버버리",
  "버섯",
  "버스",
  "버전",
  "버지니아",
  "버짐",
  "버찌",
  "버클",
  "버클륨",
  "버터",
  "버터밀크",
  "버텅",
  "버텅아래",
  "버튼",
  "버티다",
  "버팔로",
  "벅차다",
  "번",
  "번",
  "번개",
  "번데기",
  "번역",
  "번역가",
  "번역자",
  "번역하다",
  "번지다",
  "번째",
  "번호",
  "벌",
  "벌",
  "벌",
  "벌거벗다",
  "벌거벗은",
  "벌금",
  "벌기다",
  "벌꿀",
  "벌다",
  "벌레",
  "벌리다",
  "벌매",
  "벌새",
  "벌써",
  "벌어지다",
  "벌이다",
  "벌주다",
  "벌집",
  "범",
  "범",
  "범",
  "범고래",
  "범부",
  "범성애",
  "범신론",
  "범어",
  "범위",
  "범이신론",
  "범인",
  "범재신론",
  "범죄",
  "범죄자",
  "범주",
  "법",
  "법",
  "법관",
  "법률",
  "법률학",
  "법안",
  "법원",
  "법인",
  "법정",
  "법칙",
  "법학",
  "벗",
  "벗겨지다",
  "벗기다",
  "벗다",
  "벙어리",
  "벚",
  "벚꽃",
  "벚꽃뱅어",
  "베개",
  "베냉",
  "베네수엘라",
  "베누스",
  "베니스",
  "베닌",
  "베다",
  "베드로",
  "베란다",
  "베른",
  "베를린",
  "베릴륨",
  "베스트셀러",
  "베오그라드",
  "베이루트",
  "베이비시터",
  "베이징",
  "베이컨",
  "베일",
  "베일",
  "베짱이",
  "베테랑",
  "베트남",
  "베트남어",
  "베풀다",
  "벡터",
  "벤또",
  "벤젠",
  "벤졸",
  "벤질기",
  "벤치",
  "벤토",
  "벨",
  "벨기에",
  "벨라루스",
  "벨로키랍토르",
  "벨리즈",
  "벨모판",
  "벨트",
  "벼",
  "벼락",
  "벼락부자",
  "벼랑",
  "벼룩",
  "벼룩시장",
  "벼슬",
  "벽",
  "벽",
  "벽",
  "벽난로",
  "벽돌",
  "벽력",
  "벽장",
  "변기",
  "변동",
  "변비",
  "변소",
  "변수",
  "변압기",
  "변이하다",
  "변절",
  "변절자",
  "변절하다",
  "변하다",
  "변호사",
  "변화",
  "변화하다",
  "변환",
  "별",
  "별다르다",
  "별도",
  "별로",
  "별로",
  "별명",
  "별별",
  "별빛",
  "별세",
  "별세하다",
  "별실",
  "별일",
  "별자리",
  "별표",
  "볏",
  "병",
  "병",
  "병",
  "병기고",
  "병기창",
  "병독",
  "병들다",
  "병따개",
  "병리학",
  "병법",
  "병사",
  "병신",
  "병아리",
  "병원",
  "병음",
  "볕",
  "보건",
  "보고",
  "보고서",
  "보고싶다",
  "보고타",
  "보고하다",
  "보관",
  "보관하다",
  "보급",
  "보기",
  "보기",
  "보내다",
  "보너스",
  "보닛",
  "보다",
  "보다",
  "보다",
  "보도",
  "보도",
  "보도하다",
  "보드",
  "보드카",
  "보디빌딩",
  "보따리",
  "보라색",
  "보람",
  "보륨",
  "보르시",
  "보름",
  "보름날",
  "보리",
  "보리",
  "보리고래",
  "보면",
  "보면",
  "보면",
  "보물",
  "보배",
  "보병",
  "보살",
  "보상",
  "보상하다",
  "보석",
  "보수",
  "보수주의",
  "보스니아",
  "보습",
  "보신탕",
  "보쌈",
  "보양탕",
  "보어",
  "보온병",
  "보이다",
  "보이지만",
  "보이프렌드",
  "보일러",
  "보잘것없다",
  "보장",
  "보장하다",
  "보조개",
  "보조사",
  "보증",
  "보지",
  "보지",
  "보지",
  "보지하다",
  "보충하다",
  "보츠와나",
  "보통",
  "보통",
  "보통화",
  "보편적",
  "보행",
  "보행자",
  "보험",
  "보호",
  "보호하다",
  "복",
  "복",
  "복",
  "복건",
  "복건",
  "복권",
  "복날",
  "복도",
  "복리",
  "복무",
  "복무하다",
  "복받치다",
  "복사기",
  "복사하다",
  "복서",
  "복섬",
  "복소수",
  "복수",
  "복수전",
  "복수하다",
  "복수형",
  "복숭아",
  "복스럽다",
  "복습",
  "복습하다",
  "복싱",
  "복엽기",
  "복음서",
  "복잡하다",
  "복장",
  "복제",
  "복제",
  "복제",
  "복제",
  "복제",
  "복지",
  "복호",
  "볶다",
  "볶이",
  "본",
  "본",
  "본",
  "본",
  "본",
  "본",
  "본격적",
  "본격적",
  "본능",
  "본래",
  "본래",
  "본문",
  "본받다",
  "본부",
  "본사",
  "본색",
  "본성",
  "본질",
  "본토",
  "볼",
  "볼",
  "볼거리",
  "볼기",
  "볼리비아",
  "볼링",
  "볼모",
  "볼셰비키",
  "볼트",
  "볼트",
  "볼펜",
  "봄",
  "봄",
  "봄날",
  "봇",
  "봇짐",
  "봉",
  "봉",
  "봉",
  "봉",
  "봉",
  "봉급",
  "봉기",
  "봉사",
  "봉사",
  "봉지",
  "봉투",
  "봐",
  "뵈다",
  "뵙다",
  "부",
  "부",
  "부",
  "부",
  "부귀",
  "부귀영화",
  "부근",
  "부끄러움",
  "부끄러워하다",
  "부끄럽다",
  "부농",
  "부다페스트",
  "부닥치다",
  "부담",
  "부담하다",
  "부대",
  "부대찌개",
  "부도",
  "부도",
  "부동산",
  "부두",
  "부드럽다",
  "부들",
  "부딪다",
  "부딪치다",
  "부딪히다",
  "부뚜막",
  "부러뜨리다",
  "부러움",
  "부러지다",
  "부럼",
  "부럼",
  "부럼없다",
  "부럽다",
  "부레",
  "부루퉁이",
  "부룬디",
  "부르다",
  "부르다",
  "부르심",
  "부르짖다",
  "부르키나파소",
  "부르트다",
  "부름",
  "부릅뜨다",
  "부리",
  "부리고래",
  "부리다",
  "부리다",
  "부모",
  "부모님",
  "부문",
  "부부",
  "부분",
  "부사",
  "부산",
  "부상",
  "부서지다",
  "부수",
  "부수다",
  "부스깨",
  "부스러기",
  "부시",
  "부식",
  "부신",
  "부아",
  "부안종개",
  "부엉이",
  "부엌",
  "부에노스아이레스",
  "부여",
  "부여하다",
  "부위",
  "부유",
  "부유하다",
  "부이온",
  "부이온",
  "부인",
  "부인",
  "부인하다",
  "부자",
  "부자",
  "부작용",
  "부잣집",
  "부장",
  "부재",
  "부적",
  "부전령",
  "부전자전",
  "부젓가락",
  "부정",
  "부정",
  "부정",
  "부정",
  "부정사",
  "부정적",
  "부정적",
  "부정적분",
  "부정하다",
  "부제",
  "부조리",
  "부족",
  "부족",
  "부족하다",
  "부줌부라",
  "부지런",
  "부지런하다",
  "부채",
  "부채",
  "부채선인장",
  "부처",
  "부처",
  "부처님",
  "부추",
  "부추기다",
  "부치다",
  "부침개",
  "부쿠레슈티",
  "부탁",
  "부탁하다",
  "부탁합니다",
  "부탁해요",
  "부탁해요",
  "부탄",
  "부터",
  "부통령",
  "부티크",
  "부패",
  "부풀다",
  "부품",
  "부피",
  "부호",
  "부활",
  "부활절",
  "북",
  "북",
  "북",
  "북",
  "북경",
  "북괴",
  "북극",
  "북극곰",
  "북극해",
  "북돋다",
  "북돋우다",
  "북동",
  "북마크",
  "북미",
  "북방",
  "북방긴수염고래",
  "북방애기박쥐",
  "북방종개",
  "북방중고기",
  "북방토끼",
  "북방흰뺨오리",
  "북부",
  "북빙양",
  "북서",
  "북숲쥐",
  "북아메리카",
  "북장",
  "북조선",
  "북쪽",
  "북한",
  "분",
  "분",
  "분",
  "분광",
  "분광기",
  "분광학",
  "분노",
  "분량",
  "분류",
  "분류학",
  "분리",
  "분리주의",
  "분명",
  "분명하다",
  "분명히",
  "분모",
  "분사",
  "분석",
  "분석하다",
  "분수",
  "분수령",
  "분식류",
  "분식점",
  "분야",
  "분위기",
  "분자",
  "분재",
  "분쟁",
  "분점",
  "분주하다",
  "분투",
  "분포",
  "분포하다",
  "분필",
  "분홍색",
  "붇다",
  "불",
  "불",
  "불",
  "불",
  "불",
  "불가결",
  "불가능하다",
  "불가리아",
  "불가리아어",
  "불가사리",
  "불가지론",
  "불가피",
  "불가피하다",
  "불거지다",
  "불고기",
  "불곰",
  "불과",
  "불과하다",
  "불교",
  "불구",
  "불구속",
  "불구하다",
  "불굴",
  "불규칙하다",
  "불까다",
  "불꽃",
  "불꽃놀이",
  "불끈",
  "불다",
  "불닭",
  "불도저",
  "불라",
  "불란서",
  "불러일으키다",
  "불륜",
  "불륜하다",
  "불리다",
  "불만",
  "불면증",
  "불바다",
  "불법",
  "불빛",
  "불사조",
  "불상사",
  "불쌍",
  "불쌍하다",
  "불안",
  "불안하다",
  "불알",
  "불야성",
  "불어",
  "불완전하다",
  "불장난",
  "불지르다",
  "불치다",
  "불치병",
  "불친절",
  "불친절하다",
  "불타",
  "불편",
  "불편하다",
  "불포화",
  "불행",
  "불행하다",
  "불현듯",
  "불황",
  "붉다",
  "붉은가슴흰죽지",
  "붉은발슴새",
  "붉은배새매",
  "붉은부리흰죽지",
  "붉은뺨가마우지",
  "붉은사슴",
  "붉은왜가리",
  "붉은해오라기",
  "붉히다",
  "붓",
  "붓다",
  "붓다",
  "붓펜",
  "붕대",
  "붕소",
  "붕어",
  "붕어빵",
  "붕퉁뱅어",
  "붙다",
  "붙이다",
  "붙임표",
  "붙잡다",
  "붙잡히다",
  "뷁",
  "브라더",
  "브라우저",
  "브라이드고래",
  "브라자빌",
  "브라질",
  "브라질리아",
  "브라키오사우루스",
  "브라티슬라바",
  "브래지어",
  "브랜드",
  "브랜디",
  "브러시",
  "브레이크",
  "브로민",
  "브로치",
  "브로커",
  "브로콜리",
  "브루나이",
  "브뤼셀",
  "브리지",
  "브리지타운",
  "브리튼",
  "브리핑",
  "블라디미르",
  "블라디보스토크",
  "블라우스",
  "블라지보스또크",
  "블랙홀",
  "블로그",
  "블루길",
  "블루진",
  "블룸폰테인",
  "비",
  "비겁",
  "비계",
  "비고",
  "비교",
  "비교급",
  "비교적",
  "비교적",
  "비교적",
  "비교하다",
  "비구니",
  "비구름",
  "비극",
  "비기다",
  "비난",
  "비난하다",
  "비날론",
  "비녀",
  "비누",
  "비늘",
  "비늘흰발망둑",
  "비닐",
  "비다",
  "비다",
  "비단",
  "비단",
  "비단길",
  "비단털들쥐",
  "비단털등줄쥐",
  "비단털쥐",
  "비둘기",
  "비둘기조롱이",
  "비듬",
  "비디오",
  "비디오테이프",
  "비뚤다",
  "비뚤어지다",
  "비로소",
  "비롯되다",
  "비롯하다",
  "비료",
  "비리다",
  "비린내",
  "비망록",
  "비밀",
  "비밀리",
  "비밀스럽다",
  "비버",
  "비번",
  "비번",
  "비법",
  "비비다",
  "비빔",
  "비빔밥",
  "비사우",
  "비상",
  "비상",
  "비상사태",
  "비새",
  "비서",
  "비소",
  "비숍",
  "비숍",
  "비슈케크",
  "비스무트",
  "비스켓",
  "비슷하다",
  "비싸다",
  "비애",
  "비엔나",
  "비엔티안",
  "비오리",
  "비용",
  "비우다",
  "비웃다",
  "비유",
  "비율",
  "비읍",
  "비자",
  "비잔티온",
  "비잔티움",
  "비중",
  "비추다",
  "비취",
  "비치다",
  "비키니",
  "비키다",
  "비타민",
  "비트",
  "비트박스",
  "비트코인",
  "비파",
  "비파",
  "비파나무",
  "비판",
  "비판적",
  "비판하다",
  "비평",
  "비평가",
  "비하다",
  "비핵화",
  "비행",
  "비행기",
  "비행기록장치",
  "비행기표",
  "비행선",
  "비행장",
  "빅토리아",
  "빈",
  "빈곤",
  "빈뇨",
  "빈대",
  "빈대떡",
  "빈도",
  "빈민",
  "빈민가",
  "빈민굴",
  "빈자",
  "빈트후크",
  "빈틈",
  "빌기",
  "빌뉴스",
  "빌다",
  "빌딩",
  "빌리다",
  "빗",
  "빗기다",
  "빗다",
  "빗물",
  "빗자루",
  "빗장뼈",
  "빙고",
  "빙빙",
  "빙산",
  "빙어",
  "빙하",
  "빚",
  "빚다",
  "빛",
  "빛깔",
  "빛나다",
  "빛내다",
  "빠르다",
  "빠리",
  "빠이빠이",
  "빠져나가다",
  "빠져나오다",
  "빠지다",
  "빤쯔",
  "빨간색",
  "빨강",
  "빨갛다",
  "빨갱이",
  "빨다",
  "빨다",
  "빨다",
  "빨대",
  "빨래",
  "빨래집게",
  "빨래하다",
  "빨리",
  "빨아먹다",
  "빨찌산",
  "빵",
  "빵",
  "빵집",
  "빻다",
  "빼",
  "빼놓다",
  "빼다",
  "빼닮다",
  "빼빼하다",
  "빼앗다",
  "빼주",
  "빼치다",
  "뺄셈",
  "뺏다",
  "뺑소니",
  "뺑소니치다",
  "뺨",
  "뻐꾸기",
  "뻐스",
  "뻔하다",
  "뻗다",
  "뼈",
  "뼈대",
  "뼘",
  "뽀르뚜갈",
  "뽀뽀",
  "뽀뽀하다",
  "뽈스까",
  "뽈스카",
  "뽐내다",
  "뽑다",
  "뽑히다",
  "뽕",
  "뾰족하다",
  "뿌리",
  "뿌리다",
  "뿌리혹",
  "뿌셔뿌셔",
  "뿌옇다",
  "뿐",
  "뿔",
  "뿔논병아리",
  "뿔매",
  "뿜다",
  "쁠류스",
  "삐딱하다",
  "삐뚤다",
  "삥궐",
  "사",
  "사",
  "사",
  "사",
  "사각형",
  "사거리",
  "사건",
  "사격",
  "사계",
  "사고",
  "사고",
  "사고",
  "사과",
  "사과",
  "사과하다",
  "사구",
  "사귀다",
  "사기",
  "사기꾼",
  "사기죄",
  "사나",
  "사나이",
  "사납다",
  "사내",
  "사내",
  "사내",
  "사냥",
  "사냥개",
  "사냥하다",
  "사다",
  "사다리",
  "사다새",
  "사닥다리",
  "사단",
  "사담",
  "사담",
  "사도행전",
  "사라예보",
  "사라지다",
  "사람",
  "사람들",
  "사랑",
  "사랑",
  "사랑스러워",
  "사랑스럽다",
  "사랑하다",
  "사령부",
  "사례",
  "사로잡다",
  "사루기",
  "사르르",
  "사리원",
  "사립",
  "사마귀",
  "사마귀",
  "사마륨",
  "사막",
  "사망",
  "사모바르",
  "사모아",
  "사무라이",
  "사무소",
  "사무실",
  "사무치다",
  "사물",
  "사발",
  "사방",
  "사백어",
  "사법",
  "사뿐사뿐",
  "사상",
  "사상",
  "사상이",
  "사상이",
  "사생",
  "사생",
  "사생",
  "사생자",
  "사생팬",
  "사설",
  "사설",
  "사소하다",
  "사슬",
  "사슴",
  "사시미",
  "사신",
  "사실",
  "사실",
  "사실상",
  "사실상",
  "사악",
  "사양",
  "사양",
  "사양",
  "사양하다",
  "사업",
  "사업가",
  "사용",
  "사용되다",
  "사용자",
  "사용하다",
  "사우",
  "사우나",
  "사우디아라비아",
  "사울",
  "사원",
  "사원",
  "사월",
  "사위",
  "사이",
  "사이다",
  "사이렌",
  "사이보그",
  "사이시옷",
  "사이즈",
  "사이클링",
  "사이트",
  "사이폰",
  "사인",
  "사인",
  "사인",
  "사임",
  "사자",
  "사자",
  "사자고추",
  "사장",
  "사장",
  "사전",
  "사전",
  "사전",
  "사절",
  "사정",
  "사정",
  "사제",
  "사증",
  "사직",
  "사진",
  "사진기",
  "사진빨",
  "사진사",
  "사진술",
  "사찰",
  "사창",
  "사천",
  "사철",
  "사촌",
  "사춘기",
  "사치품",
  "사타구니",
  "사탄",
  "사탄교",
  "사탕",
  "사탕수수",
  "사태",
  "사투르누스",
  "사투리",
  "사튀로스",
  "사티로스",
  "사하라",
  "사학",
  "사할린",
  "사항",
  "사해",
  "사향",
  "사향고양이",
  "사향노루",
  "사향쥐",
  "사형",
  "사회",
  "사회",
  "사회적",
  "사회적",
  "사회주의",
  "사회학",
  "사흘",
  "삯",
  "산",
  "산",
  "산개구리",
  "산달",
  "산동",
  "산둥",
  "산마리노",
  "산맥",
  "산문",
  "산보",
  "산불",
  "산사태",
  "산살바도르",
  "산서",
  "산소",
  "산술",
  "산스크리트어",
  "산시",
  "산양",
  "산업",
  "산장",
  "산조",
  "산책",
  "산책하다",
  "산토끼",
  "산토도밍고",
  "산티아고",
  "산파",
  "산판",
  "산호",
  "산호세",
  "산호초",
  "살",
  "살",
  "살",
  "살",
  "살갗",
  "살결물",
  "살구",
  "살금살금",
  "살다",
  "살롱",
  "살리다",
  "살림",
  "살림살이",
  "살살하다",
  "살아",
  "살아가는",
  "살아가다",
  "살아남다",
  "살아오다",
  "살인",
  "살인자",
  "살짝",
  "살찌다",
  "살찌우다",
  "살찐",
  "살치",
  "살펴보다",
  "살피다",
  "삵",
  "삶",
  "삶다",
  "삼",
  "삼각형",
  "삼겹살",
  "삼계탕",
  "삼국",
  "삼다",
  "삼륜차",
  "삼림",
  "삼바",
  "삼색",
  "삼색의태극",
  "삼선",
  "삼성",
  "삼성",
  "삼성",
  "삼수",
  "삼신각",
  "삼십",
  "삼엽기",
  "삼월",
  "삼위일체",
  "삼인칭",
  "삼초",
  "삼촌",
  "삼키다",
  "삼태극",
  "삽",
  "삽입곡",
  "삿갓",
  "상",
  "상",
  "상",
  "상",
  "상",
  "상관",
  "상괭이",
  "상권",
  "상권",
  "상권",
  "상념",
  "상담",
  "상당",
  "상당하다",
  "상당히",
  "상대",
  "상대방",
  "상대적",
  "상대적",
  "상사병",
  "상상",
  "상상력",
  "상상하다",
  "상세하다",
  "상속인",
  "상수",
  "상식",
  "상실",
  "상아",
  "상어",
  "상업",
  "상원",
  "상의하다",
  "상인",
  "상자",
  "상점",
  "상징",
  "상처",
  "상추",
  "상태",
  "상투메",
  "상트페테르부르크",
  "상표",
  "상품",
  "상하다",
  "상하이",
  "상해",
  "상해",
  "상형",
  "상환되다",
  "상황",
  "샅",
  "샅샅이",
  "새",
  "새",
  "새기다",
  "새기다",
  "새기다",
  "새끼",
  "새끼새",
  "새끼손가락",
  "새끼주머니",
  "새내기",
  "새다",
  "새들",
  "새똥",
  "새로",
  "새롭다",
  "새매",
  "새미",
  "새벽",
  "새벽녘",
  "새우",
  "새우젓",
  "새집",
  "새코미꾸리",
  "새콤하다",
  "새해",
  "새홀리기",
  "색",
  "색깔",
  "색동다리",
  "색맹",
  "색소",
  "색안경",
  "색연필",
  "색욕",
  "색인",
  "색정",
  "샌드위치",
  "샌들",
  "샌디에이고",
  "샌프란시스코",
  "샐러드",
  "샐러리",
  "샐러리맨",
  "샘",
  "샘",
  "샘",
  "샘",
  "샘플",
  "샛별",
  "생",
  "생각",
  "생각나다",
  "생각되다",
  "생각하다",
  "생각해",
  "생강",
  "생겨나다",
  "생기다",
  "생기다",
  "생년월일",
  "생도",
  "생리학",
  "생머리",
  "생명",
  "생물",
  "생물체",
  "생물학",
  "생물화학",
  "생박쥐",
  "생산",
  "생산되다",
  "생산력",
  "생산하다",
  "생생하다",
  "생선",
  "생선회",
  "생식",
  "생신",
  "생애",
  "생일",
  "생쥐",
  "생태계",
  "생태학",
  "생화학",
  "생활",
  "생활용품",
  "생활하다",
  "생황",
  "샤발",
  "샤쓰",
  "샤워",
  "샤쯔",
  "샤타",
  "샤프펜실",
  "샴페인",
  "샴푸",
  "샹들리에",
  "샹차이",
  "서",
  "서",
  "서",
  "서강",
  "서고",
  "서고",
  "서고",
  "서구",
  "서까래",
  "서남",
  "서너",
  "서넛",
  "서다",
  "서두르다",
  "서랍",
  "서럽다",
  "서로",
  "서로",
  "서류",
  "서류철",
  "서른",
  "서리",
  "서명",
  "서명하다",
  "서문",
  "서밋",
  "서반아",
  "서방",
  "서방",
  "서버",
  "서벌",
  "서법",
  "서부",
  "서브컬처",
  "서비스",
  "서사하라",
  "서서히",
  "서술",
  "서술하다",
  "서양",
  "서양배",
  "서양자두",
  "서예",
  "서울",
  "서울",
  "서원",
  "서장",
  "서재",
  "서점",
  "서쪽",
  "서체",
  "서커스",
  "서클",
  "서투르다",
  "서툴다",
  "서표",
  "서호납줄갱이",
  "석",
  "석가",
  "석거",
  "석관",
  "석랍",
  "석류",
  "석류석",
  "석방",
  "석사",
  "석순",
  "석유",
  "석의",
  "석탄",
  "석탄산",
  "섞다",
  "섞이다",
  "선거",
  "선거하다",
  "선교",
  "선교",
  "선교사",
  "선글라스",
  "선로",
  "선물",
  "선박",
  "선반",
  "선배",
  "선상",
  "선생",
  "선생님",
  "선수",
  "선술집",
  "선실",
  "선언",
  "선율",
  "선인장",
  "선장",
  "선전",
  "선전",
  "선종",
  "선진국",
  "선착장",
  "선창",
  "선택",
  "선택하다",
  "선풍",
  "선풍기",
  "섣달",
  "설",
  "설",
  "설거지",
  "설거지하다",
  "설계",
  "설계도",
  "설교",
  "설교자",
  "설날",
  "설농탕",
  "설렁탕",
  "설레다",
  "설레이다",
  "설마",
  "설마",
  "설명",
  "설명하다",
  "설비",
  "설사",
  "설산",
  "설인",
  "설정",
  "설치",
  "설치되다",
  "설치하다",
  "설탕",
  "설포닐기",
  "설폰",
  "설프히드릴기",
  "설화",
  "섧다",
  "섬",
  "섬서",
  "섬짱깨",
  "섭",
  "섭",
  "섭",
  "섭씨",
  "섯",
  "성",
  "성",
  "성",
  "성",
  "성",
  "성",
  "성",
  "성가",
  "성감",
  "성감대",
  "성게",
  "성격",
  "성경",
  "성공",
  "성공하다",
  "성공하다",
  "성곽",
  "성교",
  "성교하다",
  "성구",
  "성구",
  "성군",
  "성남",
  "성냥",
  "성년",
  "성당",
  "성대",
  "성립",
  "성립되다",
  "성매매",
  "성명",
  "성문",
  "성별",
  "성병",
  "성부",
  "성분",
  "성사",
  "성상",
  "성생활",
  "성서",
  "성스럽다",
  "성신",
  "성실",
  "성실하다",
  "성심",
  "성씨",
  "성어",
  "성욕",
  "성운",
  "성인",
  "성자",
  "성장",
  "성장",
  "성장하다",
  "성적",
  "성전환",
  "성조",
  "성질",
  "성찬",
  "성찬",
  "성채",
  "성체",
  "성체성사",
  "성취",
  "성탄절",
  "성하",
  "성함",
  "성행위",
  "세",
  "세가락메추라기",
  "세계",
  "세계관",
  "세계어",
  "세계적",
  "세계평화",
  "세관",
  "세균",
  "세금",
  "세기",
  "세네갈",
  "세다",
  "세다",
  "세다",
  "세대",
  "세력",
  "세례",
  "세로",
  "세륨",
  "세르보크로아트어",
  "세르비아",
  "세르비아어",
  "세모",
  "세모꼴",
  "세미나",
  "세미콜론",
  "세배",
  "세상",
  "세상에",
  "세상에",
  "세수",
  "세수하다",
  "세슘",
  "세요",
  "세우다",
  "세워지다",
  "세월",
  "세이셸",
  "세인트루시아",
  "세인트조지스",
  "세입자",
  "세자",
  "세자",
  "세자",
  "세젤귀",
  "세차",
  "세탁",
  "세탁기",
  "세탁소",
  "세탁실",
  "세탁하다",
  "세포",
  "세포학",
  "세포핵",
  "섹스",
  "섹스하다",
  "섹시하다",
  "섹시한",
  "센스쟁이",
  "센터",
  "센트",
  "센티미터",
  "셀러리",
  "셀레나",
  "셀레늄",
  "셀로판",
  "셀카",
  "셈",
  "셈판",
  "셋",
  "셋",
  "셋째",
  "셔벗",
  "셔츠",
  "셔터",
  "소",
  "소",
  "소",
  "소개",
  "소개팅",
  "소개하다",
  "소고기",
  "소굴",
  "소금",
  "소꿉",
  "소꿉동무",
  "소꿉친구",
  "소나기",
  "소나무",
  "소나타",
  "소녀",
  "소년",
  "소년궁전",
  "소농",
  "소대",
  "소대",
  "소득",
  "소라",
  "소련",
  "소령",
  "소리",
  "소리치다",
  "소말리아",
  "소매",
  "소매치기",
  "소맥",
  "소문",
  "소믈리에",
  "소박하다",
  "소방관",
  "소방서",
  "소방수",
  "소방차",
  "소변",
  "소비",
  "소비에트",
  "소비자",
  "소비하다",
  "소설",
  "소설",
  "소설가",
  "소설책",
  "소속",
  "소송",
  "소수",
  "소수",
  "소수민족",
  "소스",
  "소스",
  "소시지",
  "소식",
  "소용",
  "소용돌이",
  "소원",
  "소원",
  "소원",
  "소원",
  "소원",
  "소위",
  "소유",
  "소유물",
  "소유자",
  "소유하다",
  "소음",
  "소음순",
  "소재",
  "소재",
  "소제",
  "소제하다",
  "소좌",
  "소주",
  "소중하다",
  "소지품",
  "소쩍새",
  "소책자",
  "소총",
  "소치",
  "소치",
  "소파",
  "소포",
  "소품",
  "소풍",
  "소프트볼",
  "소프트웨어",
  "소피아",
  "소학교",
  "소행성",
  "소화",
  "소화기",
  "소화전",
  "소환",
  "속",
  "속견",
  "속눈썹",
  "속다",
  "속담",
  "속도",
  "속마음",
  "속삭이다",
  "속삭임",
  "속셈",
  "속속",
  "속속들이",
  "속쓰림",
  "속어",
  "속옷",
  "속이다",
  "속하다",
  "손",
  "손",
  "손가락",
  "손가락질",
  "손녀",
  "손님",
  "손목",
  "손바닥",
  "손발톱",
  "손상하다",
  "손수건",
  "손자",
  "손전등",
  "손전화",
  "손주",
  "손질",
  "손질하다",
  "손톱",
  "손톱깎이",
  "손해",
  "솔",
  "솔개",
  "솔기",
  "솔깃하다",
  "솔로몬",
  "솔직",
  "솔직하다",
  "솔직히",
  "솜",
  "솜사탕",
  "솜씨",
  "솟다",
  "송과선",
  "송구",
  "송도",
  "송두리째",
  "송림",
  "송사리",
  "송아지",
  "송어",
  "송이",
  "송장",
  "송장",
  "솥",
  "쇠",
  "쇠",
  "쇠가마우지",
  "쇠갈밭쥐",
  "쇠고기",
  "쇠기러기",
  "쇠돌고래",
  "쇠뒤쥐",
  "쇠백로",
  "쇠부리슴새",
  "쇠오리",
  "쇠재두루미",
  "쇠퇴하다",
  "쇠향고래",
  "쇠황조롱이",
  "쇳돌",
  "쇼",
  "쇼룸",
  "쇼비니즘",
  "쇼윈도",
  "쇼츠",
  "쇼케이스",
  "쇼크",
  "쇼핑",
  "쇼핑하다",
  "수",
  "수",
  "수",
  "수",
  "수",
  "수간",
  "수갑",
  "수건",
  "수고",
  "수고하다",
  "수급",
  "수납장",
  "수녀",
  "수뇌상봉",
  "수뇌회담",
  "수다",
  "수다떨다",
  "수다쟁이",
  "수단",
  "수달",
  "수도",
  "수도",
  "수도",
  "수도",
  "수도",
  "수도",
  "수도",
  "수도권",
  "수도꼭지",
  "수도사",
  "수도원",
  "수동태",
  "수라",
  "수량",
  "수레",
  "수력",
  "수렴",
  "수령",
  "수로",
  "수록",
  "수류탄",
  "수리",
  "수리",
  "수리",
  "수리남",
  "수리아",
  "수리하다",
  "수많다",
  "수말",
  "수면",
  "수면",
  "수명",
  "수문학",
  "수바",
  "수박",
  "수법",
  "수사",
  "수사",
  "수사",
  "수사",
  "수산기",
  "수상",
  "수상하다",
  "수서",
  "수석",
  "수석",
  "수성",
  "수소",
  "수소",
  "수소탄",
  "수소폭탄",
  "수송",
  "수송선",
  "수수께끼",
  "수수미꾸리",
  "수술",
  "수시로",
  "수식어",
  "수심",
  "수십",
  "수액백",
  "수업",
  "수없이",
  "수염",
  "수염수리",
  "수영",
  "수영복",
  "수영장",
  "수영하다",
  "수요",
  "수요일",
  "수원",
  "수원청개구리",
  "수유탄",
  "수은",
  "수음",
  "수음하다",
  "수입",
  "수장룡",
  "수저",
  "수정란",
  "수조",
  "수준",
  "수줍다",
  "수지",
  "수지",
  "수지",
  "수지",
  "수지",
  "수집하다",
  "수첩",
  "수출",
  "수출품",
  "수치",
  "수캐",
  "수컷",
  "수탉",
  "수태",
  "수판",
  "수평",
  "수평선",
  "수표",
  "수프",
  "수피",
  "수필",
  "수하물",
  "수학",
  "수학자",
  "수행하다",
  "수행하다",
  "수호",
  "수화",
  "수화기",
  "수확",
  "숙녀",
  "숙련자",
  "숙명",
  "숙박",
  "숙소",
  "숙어",
  "숙이다",
  "숙제",
  "숙제하다",
  "숙주나물",
  "순",
  "순",
  "순",
  "순간",
  "순결",
  "순교",
  "순교자",
  "순두부",
  "순무",
  "순서",
  "순수",
  "순수하다",
  "순양함",
  "순위",
  "순천",
  "순흥",
  "숟가락",
  "술",
  "술",
  "술래",
  "술래잡기",
  "술병",
  "술어",
  "술집",
  "술탄",
  "숨",
  "숨구멍",
  "숨기다",
  "숨다",
  "숨바꼭질",
  "숨쉬다",
  "숨지다",
  "숨통",
  "숫자",
  "숭어",
  "숯",
  "숲",
  "숲들쥐",
  "쉬다",
  "쉬리",
  "쉬리",
  "쉰",
  "쉽다",
  "쉽사리",
  "슈",
  "슈니첼",
  "슈크림",
  "슈트",
  "슈트",
  "슈트케이스",
  "슈퍼",
  "슈퍼마켓",
  "슈퍼스타",
  "스노보드",
  "스님",
  "스도쿠",
  "스라소니",
  "스럽다",
  "스리랑카",
  "스마트폰",
  "스며들다",
  "스모",
  "스모그",
  "스몰렌스크",
  "스무",
  "스무째",
  "스물",
  "스미다",
  "스스로",
  "스스로",
  "스승",
  "스시",
  "스와질란드",
  "스웨덴",
  "스웨덴어",
  "스웨리예",
  "스웨터",
  "스위스",
  "스위치",
  "스치다",
  "스카프",
  "스칸듐",
  "스캐너",
  "스캔들",
  "스커트",
  "스컹크",
  "스케이트",
  "스케이트보드",
  "스케줄",
  "스코틀랜드",
  "스코페",
  "스쿠버다이빙",
  "스쿠터",
  "스쿼시",
  "스크린",
  "스키",
  "스킨십",
  "스타",
  "스타디움",
  "스타벅스",
  "스타일",
  "스타킹",
  "스태프",
  "스터킹",
  "스테로이드",
  "스테아르산",
  "스테아린",
  "스테이크",
  "스테이플러",
  "스테파노",
  "스텝",
  "스토브",
  "스톡홀름",
  "스톤헨지",
  "스튜",
  "스튜디오",
  "스트레스",
  "스트레이트",
  "스트레이트",
  "스트로",
  "스트론튬",
  "스파게티",
  "스파이",
  "스파이웨어",
  "스펀지",
  "스페이드",
  "스페인",
  "스페인어",
  "스펙트럼",
  "스포일러",
  "스포츠",
  "스푸트니크",
  "스푼",
  "스풀",
  "스프링클러",
  "스피커",
  "슬기",
  "슬기롭다",
  "슬라브",
  "슬로건",
  "슬로바키아",
  "슬로바키아어",
  "슬로베니아",
  "슬로베니아어",
  "슬로벤스꼬",
  "슬리퍼",
  "슬슬",
  "슬프다",
  "슬픈",
  "슬픔",
  "슴베",
  "슴새",
  "습관",
  "습기",
  "습니까",
  "습니다",
  "습도",
  "습득",
  "습득",
  "습지",
  "승",
  "승강기",
  "승객",
  "승냥이",
  "승려",
  "승리",
  "승무원",
  "승부",
  "승용차",
  "승진",
  "승하",
  "승하하다",
  "승합차",
  "승화",
  "시",
  "시",
  "시",
  "시",
  "시",
  "시",
  "시",
  "시가",
  "시각",
  "시각",
  "시간",
  "시간표",
  "시계",
  "시골",
  "시금치",
  "시기",
  "시꺼멓다",
  "시끄럽다",
  "시나리오",
  "시나브로",
  "시내",
  "시냇물",
  "시다",
  "시달리다",
  "시대",
  "시대정신",
  "시도",
  "시도하다",
  "시동생",
  "시드니",
  "시들다",
  "시디",
  "시디",
  "시럽",
  "시력",
  "시련",
  "시리아",
  "시리즈",
  "시립",
  "시멘트",
  "시민",
  "시민권",
  "시발",
  "시발",
  "시베리아",
  "시보",
  "시보",
  "시보귬",
  "시상",
  "시상하부",
  "시선",
  "시설",
  "시숙",
  "시스터",
  "시스템",
  "시시덕거리다",
  "시아버지",
  "시애틀",
  "시어머니",
  "시에라리온",
  "시옷",
  "시울",
  "시원하다",
  "시월",
  "시위",
  "시인",
  "시인",
  "시작",
  "시작",
  "시작되다",
  "시작하다",
  "시작하다",
  "시장",
  "시장",
  "시장",
  "시장하다",
  "시장하시다",
  "시절",
  "시점",
  "시제",
  "시조",
  "시조",
  "시조새",
  "시조조",
  "시중",
  "시집",
  "시집",
  "시집보내다",
  "시짱",
  "시청",
  "시청자",
  "시체",
  "시치미",
  "시카고",
  "시키다",
  "시편",
  "시한폭탄",
  "시합",
  "시험",
  "시험하다",
  "식",
  "식구",
  "식기세척기",
  "식다",
  "식당",
  "식도",
  "식량",
  "식료품",
  "식료품점",
  "식물",
  "식물학",
  "식민주의",
  "식민지",
  "식사",
  "식사",
  "식사하다",
  "식사하다",
  "식사하다",
  "식생활",
  "식성",
  "식욕",
  "식용유",
  "식전",
  "식중독",
  "식초",
  "식탁",
  "식탁보",
  "식품",
  "식품보존료",
  "식혜",
  "식히다",
  "신",
  "신",
  "신",
  "신",
  "신",
  "신강",
  "신경",
  "신경전달물질",
  "신고",
  "신고하다",
  "신교도",
  "신기루",
  "신기하다",
  "신나다",
  "신념",
  "신놀린",
  "신다",
  "신데렐라",
  "신도",
  "신도",
  "신라",
  "신랑",
  "신뢰",
  "신뢰하다",
  "신명기",
  "신문",
  "신미",
  "신발",
  "신부",
  "신부",
  "신분",
  "신분증",
  "신비",
  "신사",
  "신사",
  "신선",
  "신선하다",
  "신성",
  "신성하다",
  "신수",
  "신시대",
  "신앙",
  "신어",
  "신용",
  "신의주",
  "신임",
  "신임하다",
  "신입생",
  "신자",
  "신장",
  "신장",
  "신장위",
  "신조어",
  "신주",
  "신중하다",
  "신지학",
  "신천옹",
  "신청",
  "신청서",
  "신청자",
  "신체",
  "신칸센",
  "신탁통치",
  "신포",
  "신학",
  "신호",
  "신호등",
  "신화",
  "싣다",
  "실",
  "실고기",
  "실내",
  "실내복",
  "실내악",
  "실내운동",
  "실력",
  "실례",
  "실례",
  "실례하다",
  "실로",
  "실로폰",
  "실리다",
  "실린더",
  "실마리",
  "실망",
  "실망시키다",
  "실뱅어",
  "실수",
  "실수",
  "실수하다",
  "실습",
  "실시",
  "실시되다",
  "실시하다",
  "실업",
  "실업",
  "실업가",
  "실정",
  "실제",
  "실제",
  "실제로",
  "실천",
  "실천하다",
  "실체",
  "실컷",
  "실태",
  "실패",
  "실패",
  "실패하다",
  "실험",
  "실현",
  "실현하다",
  "싫다",
  "싫어하다",
  "심",
  "심각하다",
  "심근경색",
  "심다",
  "심리",
  "심리학",
  "심방",
  "심부름",
  "심사",
  "심산유곡",
  "심성",
  "심심하다",
  "심야",
  "심연",
  "심장",
  "심장이",
  "심정",
  "심줄",
  "심지",
  "심페로폴",
  "심하다",
  "심혼",
  "십",
  "십각형",
  "십구",
  "십날",
  "십대",
  "십륙",
  "십사",
  "십삼",
  "십억",
  "십오",
  "십육",
  "십이",
  "십이월",
  "십이지장",
  "십일",
  "십일월",
  "십자",
  "십자가",
  "십자고상",
  "십칠",
  "십팔",
  "십해",
  "싱가포르",
  "싱겁다",
  "싱글싱글",
  "싱어",
  "싱어",
  "싶다",
  "싶으면",
  "싸고돌다",
  "싸다",
  "싸다",
  "싸우다",
  "싸울아비",
  "싸움",
  "싸움터",
  "싸이다",
  "싹",
  "싹",
  "싼룬처",
  "쌀",
  "쌀국수",
  "쌀미꾸리",
  "쌀밥",
  "쌀쌀하다",
  "쌀쌀히",
  "쌈장",
  "쌍",
  "쌍곡선",
  "쌍꺼풀",
  "쌍동이",
  "쌍둥이",
  "쌍반점",
  "쌍발",
  "쌍방",
  "쌍안경",
  "쌍점",
  "쌍정",
  "쌓다",
  "쌓이다",
  "쌤",
  "쌩얼",
  "써",
  "써",
  "써레",
  "써리다",
  "써우지",
  "썩다",
  "썬글라스",
  "썰다",
  "썰매",
  "썸",
  "썸팅",
  "쎈터",
  "쏘가리",
  "쏘다",
  "쏘련",
  "쏜살같이",
  "쏟다",
  "쏟아지다",
  "쏠다",
  "쏨땀",
  "쏴",
  "쐐기",
  "쐐기",
  "쐬다",
  "쑤시다",
  "쑥",
  "쑥갓",
  "쒀이러우",
  "쓰다",
  "쓰다",
  "쓰다",
  "쓰다",
  "쓰다듬다",
  "쓰러지다",
  "쓰레기",
  "쓰레기통",
  "쓰레받기",
  "쓰리",
  "쓰이다",
  "쓰촨",
  "쓸개",
  "쓸개즙",
  "쓸다",
  "쓸다",
  "쓸데",
  "쓸데없다",
  "쓸모",
  "씁쓸하다",
  "씌우다",
  "씨",
  "씨",
  "씨",
  "씨름",
  "씨발",
  "씨앗",
  "씨팔",
  "씹",
  "씹거웃",
  "씹다",
  "씹두덩",
  "씹새끼",
  "씹하다",
  "씻다",
  "씻은듯이",
  "아",
  "아",
  "아",
  "아",
  "아가",
  "아가",
  "아가미",
  "아가씨",
  "아귀",
  "아귀",
  "아귀",
  "아귀",
  "아그라",
  "아기",
  "아까",
  "아까",
  "아깝다",
  "아끼다",
  "아나운서",
  "아나콘다",
  "아나키즘",
  "아나테마",
  "아낙",
  "아내",
  "아냐",
  "아는",
  "아니",
  "아니",
  "아니다",
  "아니메",
  "아니야",
  "아니요",
  "아니하다",
  "아니하다",
  "아담",
  "아도",
  "아드님",
  "아들",
  "아디스아바바",
  "아라",
  "아라비아",
  "아라비아인",
  "아라사",
  "아라한",
  "아랍",
  "아랍어",
  "아랍에미리트",
  "아래",
  "아랫사람",
  "아레스",
  "아련",
  "아련하다",
  "아련히",
  "아론",
  "아뢰다",
  "아르곤",
  "아르메니아",
  "아르바이트",
  "아르테미스",
  "아르헨티나",
  "아름",
  "아름다운",
  "아름다워",
  "아름답다",
  "아리",
  "아리땁다",
  "아리랑",
  "아리새",
  "아리쇠",
  "아리수",
  "아리스토텔레스",
  "아마",
  "아마",
  "아마도",
  "아마추어",
  "아말감",
  "아메리슘",
  "아메리카",
  "아메리카홍머리오리",
  "아메바",
  "아멘",
  "아몬드",
  "아무",
  "아무",
  "아무것",
  "아무래도",
  "아무런",
  "아무렇다",
  "아무르강",
  "아무르산개구리",
  "아무리",
  "아무튼",
  "아미노",
  "아미노산",
  "아미딘",
  "아민",
  "아바나",
  "아버님",
  "아버지",
  "아버진",
  "아베크",
  "아벨",
  "아보카도",
  "아부다비",
  "아부자",
  "아브라함",
  "아비",
  "아비",
  "아비",
  "아비",
  "아비달마",
  "아비장",
  "아빠",
  "아빤",
  "아서",
  "아서",
  "아세나프텐",
  "아순시온",
  "아쉬움",
  "아쉽다",
  "아스마라",
  "아스타나",
  "아스타틴",
  "아스파라거스",
  "아스하바트",
  "아시새",
  "아시아",
  "아실기",
  "아싸",
  "아싸",
  "아씨",
  "아악",
  "아야",
  "아야",
  "아야톨라",
  "아연",
  "아예",
  "아오자이",
  "아요",
  "아우",
  "아우구스투스",
  "아울러",
  "아웃",
  "아유",
  "아이",
  "아이고",
  "아이누어",
  "아이들",
  "아이디어",
  "아이스",
  "아이스캔디",
  "아이스크림",
  "아이스하키",
  "아이슬란드",
  "아이오딘",
  "아이콘",
  "아이티",
  "아이폰",
  "아인슈타이늄",
  "아일랜드",
  "아자",
  "아쟁",
  "아저씨",
  "아제르바이잔",
  "아제티딘",
  "아주",
  "아주",
  "아주머니",
  "아줄렌",
  "아줌마",
  "아줌만",
  "아지",
  "아지트",
  "아직",
  "아첨",
  "아첨꾼",
  "아첨하는",
  "아첨하다",
  "아치",
  "아침",
  "아침에",
  "아카데미",
  "아콰리움",
  "아크라",
  "아크리딘",
  "아테나",
  "아테네",
  "아테지",
  "아틀란티스",
  "아티스트",
  "아티초크",
  "아파르트헤이트",
  "아파트",
  "아포스트로피",
  "아폴론",
  "아프가니스탄",
  "아프다",
  "아프로디테",
  "아프리카",
  "아프리칸스어",
  "아픔",
  "아피아",
  "아홉",
  "아홉째",
  "아휴",
  "아흐레",
  "아흔",
  "악",
  "악곡",
  "악귀",
  "악기",
  "악단",
  "악담",
  "악대",
  "악대",
  "악덕",
  "악동",
  "악력",
  "악마",
  "악몽",
  "악성",
  "악수",
  "악수하다",
  "악습",
  "악어",
  "악영향",
  "악티늄",
  "안",
  "안",
  "안",
  "안개",
  "안경",
  "안남",
  "안내",
  "안내문",
  "안내서",
  "안내인",
  "안내장",
  "안내하다",
  "안녕",
  "안녕",
  "안녕하다",
  "안녕하세요",
  "안녕히",
  "안녕히",
  "안다",
  "안도라",
  "안돼요",
  "안되다",
  "안드레아",
  "안드레이",
  "안뜰",
  "안락사",
  "안락의자",
  "안마",
  "안마당",
  "안면승마",
  "안목",
  "안목",
  "안방",
  "안성맞춤",
  "안습",
  "안심",
  "안심하다",
  "안심해",
  "안와",
  "안장",
  "안전",
  "안전제일",
  "안전하다",
  "안정",
  "안정",
  "안주",
  "안주",
  "안주",
  "안주애기박쥐",
  "안쪽",
  "안타깝다",
  "안타나나리보",
  "안테나",
  "안트라센",
  "안티모니",
  "안티오키아",
  "안해",
  "안후이",
  "안휘",
  "앉다",
  "앉은자리",
  "않다",
  "않다",
  "않아",
  "않아",
  "알",
  "알고리즘",
  "알곡",
  "알까기",
  "알다",
  "알데하이드",
  "알데히드",
  "알라",
  "알라딘",
  "알락개구리매",
  "알락오리",
  "알락해오라기",
  "알레르기",
  "알레이스터",
  "알렉산드리아",
  "알려지다",
  "알루미늄",
  "알리고",
  "알리다",
  "알릴기",
  "알맞다",
  "알바",
  "알바니아",
  "알바니아어",
  "알밥",
  "알부민",
  "알아듣다",
  "알아보다",
  "알아차리다",
  "알약",
  "알제",
  "알제리",
  "알카인",
  "알칸",
  "알칼리",
  "알케인",
  "알켄",
  "알코올",
  "알콜",
  "알킨",
  "알파",
  "알파벳",
  "알판",
  "앎",
  "앓다",
  "앓아눕다",
  "앓이",
  "암",
  "암",
  "암기",
  "암기하다",
  "암만",
  "암말",
  "암살자",
  "암석",
  "암소",
  "암스테르담",
  "암실",
  "암유",
  "암초",
  "암캐",
  "암컷",
  "암탉",
  "암퇘지",
  "암페어",
  "암호",
  "암흑",
  "압도하다",
  "압력",
  "압록강",
  "압록자그사니",
  "압박",
  "압생트",
  "압하스어",
  "앗",
  "았",
  "앙",
  "앙골라",
  "앙상블",
  "앙카라",
  "앞",
  "앞다리",
  "앞두다",
  "앞뒤",
  "앞서",
  "앞서다",
  "앞장서다",
  "앞치마",
  "애",
  "애",
  "애교",
  "애교살",
  "애교쟁이",
  "애국가",
  "애국심",
  "애국자",
  "애국주의",
  "애굽",
  "애기",
  "애기망둑",
  "애기박쥐",
  "애꾸",
  "애니메이션",
  "애도",
  "애드웨어",
  "애매모호",
  "애매모호하다",
  "애무",
  "애무하다",
  "애벌레",
  "애비",
  "애쓰다",
  "애완동물",
  "애인",
  "애정",
  "애초",
  "애칭",
  "애틋하다",
  "애플리케이션",
  "애환",
  "액세서리",
  "액수",
  "액적",
  "액체",
  "앨라배마",
  "앨리게이터",
  "앨리트",
  "앨범",
  "앰뷸런스",
  "앰풀",
  "앱",
  "앵무새",
  "야",
  "야",
  "야",
  "야",
  "야",
  "야곱",
  "야구",
  "야구장",
  "야금야금",
  "야기하다",
  "야드",
  "야레",
  "야마토",
  "야만인",
  "야망",
  "야무수크로",
  "야민정음",
  "야심",
  "야운데",
  "야자유",
  "야채",
  "야쿠자",
  "야쿠츠크",
  "야하다",
  "야한",
  "야한",
  "야회",
  "야훼",
  "약",
  "약",
  "약",
  "약간",
  "약간",
  "약과",
  "약국",
  "약물",
  "약물",
  "약사",
  "약속",
  "약속하다",
  "약식",
  "약식",
  "약실",
  "약어",
  "약자",
  "약점",
  "약주",
  "약탈",
  "약하다",
  "약학",
  "약혼",
  "약혼녀",
  "약혼자",
  "얇다",
  "얌차",
  "양",
  "양",
  "양",
  "양",
  "양",
  "양",
  "양곤",
  "양국",
  "양금",
  "양념",
  "양동이",
  "양력",
  "양로쵈",
  "양말",
  "양모",
  "양반",
  "양배추",
  "양복",
  "양봉",
  "양봉가",
  "양상",
  "양서류",
  "양성애",
  "양성애자",
  "양수사",
  "양식",
  "양식",
  "양식",
  "양심",
  "양우",
  "양유",
  "양자",
  "양자",
  "양자역학",
  "양자전기역학",
  "양조장",
  "양주",
  "양즈",
  "양쪽",
  "양탄자",
  "양태",
  "양파",
  "양피지",
  "양하",
  "얕다",
  "얕보다",
  "얕잡다",
  "얘",
  "얘기",
  "얘기하다",
  "어",
  "어",
  "어",
  "어",
  "어",
  "어",
  "어간",
  "어감",
  "어구",
  "어근",
  "어긋나다",
  "어깨",
  "어깨뼈",
  "어느",
  "어느새",
  "어댑터",
  "어덩이",
  "어도",
  "어둠",
  "어둡다",
  "어디",
  "어디",
  "어디",
  "어디여",
  "어떠하다",
  "어떡하다",
  "어떤",
  "어떻게",
  "어떻다",
  "어라",
  "어란",
  "어려움",
  "어렴풋이",
  "어렵다",
  "어렵사리",
  "어뢰",
  "어룡",
  "어루다",
  "어르다",
  "어른",
  "어름치",
  "어리다",
  "어리다",
  "어리석다",
  "어린아이",
  "어린이",
  "어린이집",
  "어릿광대",
  "어머",
  "어머나",
  "어머니",
  "어머님",
  "어미",
  "어미",
  "어버이",
  "어부",
  "어븀",
  "어색",
  "어색하다",
  "어서",
  "어서",
  "어야",
  "어여머리",
  "어여쁘다",
  "어엿하다",
  "어요",
  "어우러지다",
  "어울리다",
  "어원",
  "어원학",
  "어이",
  "어이",
  "어저께",
  "어저께",
  "어제",
  "어제",
  "어젯밤",
  "어족",
  "어지럽다",
  "어쨌든",
  "어쩌다",
  "어쩌다",
  "어쩌면",
  "어쩌면",
  "어찌",
  "어찌하다",
  "어차피",
  "어치",
  "어파",
  "어학",
  "어휘",
  "어휘소",
  "억",
  "억류",
  "억류자",
  "억울하다",
  "언급하다",
  "언니",
  "언닌",
  "언덕",
  "언뜻",
  "언론",
  "언론가",
  "언론학",
  "언론학자",
  "언문",
  "언어",
  "언어학",
  "언제",
  "언제",
  "언제나",
  "언젠가",
  "언짢다",
  "얹다",
  "얻다",
  "얻다",
  "얼",
  "얼",
  "얼",
  "얼굴",
  "얼굴빛",
  "얼다",
  "얼룩",
  "얼룩동사리",
  "얼룩말",
  "얼룩새코미꾸리",
  "얼른",
  "얼마",
  "얼마나",
  "얼버무리다",
  "얼쑤",
  "얼어붙다",
  "얼음",
  "얼음과자",
  "얼음보숭이",
  "얼짱",
  "얼핏",
  "얽다",
  "얽다",
  "얽매다",
  "얽매이다",
  "엄격",
  "엄격하다",
  "엄니",
  "엄마",
  "엄만",
  "엄지",
  "엄지손가락",
  "엄청나다",
  "엄친아",
  "업",
  "업그레이드",
  "업다",
  "업동이",
  "업둥이",
  "업무",
  "업신여기다",
  "업종",
  "업체",
  "없는",
  "없다",
  "없애다",
  "없어지다",
  "없었",
  "없었",
  "없었다",
  "없었다",
  "없이",
  "엇비슷하다",
  "었",
  "엉겁결에",
  "엉덩방아",
  "엉덩이",
  "엉뚱하다",
  "엉망",
  "엉망진창",
  "엉엉",
  "엉큼하다",
  "엎드리다",
  "엎지르다",
  "에",
  "에게",
  "에너지",
  "에네르기",
  "에다",
  "에다",
  "에러",
  "에르븀",
  "에리트레아",
  "에멜무지로",
  "에모지",
  "에뮤",
  "에미",
  "에미리트",
  "에서",
  "에스빠냐",
  "에스체트",
  "에스컬레이터",
  "에스터",
  "에스테르",
  "에스토니아",
  "에스파냐",
  "에스페란토",
  "에어컨",
  "에이즈",
  "에잇",
  "에취",
  "에콰도르",
  "에터",
  "에테르",
  "에티오피아",
  "에티켓",
  "에피소드",
  "엔",
  "엔",
  "엔",
  "엔진",
  "엘니노",
  "엘니뇨",
  "엘리베이터",
  "엘리트",
  "엘살바도르",
  "여",
  "여가",
  "여객",
  "여객기",
  "여건",
  "여겨지다",
  "여고생",
  "여과",
  "여과기",
  "여관",
  "여권",
  "여기",
  "여기다",
  "여기저기",
  "여남은",
  "여닫다",
  "여대생",
  "여덟",
  "여동생",
  "여드레",
  "여드름",
  "여든",
  "여든째",
  "여러",
  "여러분",
  "여러해살이",
  "여럿",
  "여론",
  "여름",
  "여배우",
  "여보",
  "여보세요",
  "여사친",
  "여색",
  "여섯",
  "여섯째",
  "여성",
  "여성스럽다",
  "여신",
  "여야",
  "여왕",
  "여우",
  "여우비",
  "여우원숭이",
  "여울",
  "여울마자",
  "여유",
  "여유를",
  "여인",
  "여인숙",
  "여자",
  "여자친구",
  "여장",
  "여전하다",
  "여전히",
  "여정",
  "여중생",
  "여진",
  "여진",
  "여쭈다",
  "여체",
  "여친",
  "여학생",
  "여행",
  "여행사",
  "여행자",
  "여행하다",
  "여호와",
  "역",
  "역겹다",
  "역경",
  "역경",
  "역대급",
  "역도",
  "역량",
  "역사",
  "역사가",
  "역사적",
  "역시",
  "역원",
  "역학",
  "역학",
  "역할",
  "엮다",
  "연",
  "연가",
  "연결",
  "연결되다",
  "연결하다",
  "연고",
  "연골",
  "연구",
  "연구되다",
  "연구소",
  "연구원",
  "연구원",
  "연구원생",
  "연구자",
  "연구하다",
  "연극",
  "연기",
  "연기",
  "연기",
  "연기자",
  "연대",
  "연대",
  "연락",
  "연락처",
  "연락하다",
  "연령",
  "연료",
  "연루",
  "연말",
  "연맹",
  "연못",
  "연방",
  "연뿌리",
  "연상",
  "연상",
  "연설",
  "연세",
  "연세",
  "연속",
  "연속극",
  "연습",
  "연습곡",
  "연습하다",
  "연안",
  "연애",
  "연애편지",
  "연어",
  "연어",
  "연예인",
  "연옥",
  "연월일",
  "연적",
  "연주",
  "연주하다",
  "연준모치",
  "연체동물",
  "연초",
  "연출",
  "연패",
  "연패",
  "연필",
  "연합",
  "연혁",
  "연회",
  "연휴",
  "연흔",
  "엳아홉",
  "열",
  "열",
  "열넷",
  "열다",
  "열다섯",
  "열다섯째",
  "열대붉은해오라기",
  "열도",
  "열동갈문절",
  "열둘",
  "열등감",
  "열람실",
  "열리다",
  "열매",
  "열목어",
  "열반",
  "열병",
  "열셋",
  "열셋째",
  "열쇠",
  "열심히",
  "열아홉",
  "열없다",
  "열없쟁이",
  "열여덟",
  "열여덟째",
  "열여섯",
  "열역학",
  "열일곱",
  "열정",
  "열차",
  "열하나",
  "열한째",
  "열흘",
  "엷다",
  "염",
  "염",
  "염",
  "염",
  "염기",
  "염려",
  "염려하다",
  "염병",
  "염복",
  "염색체",
  "염세주의",
  "염소",
  "염소",
  "염주",
  "염통",
  "엽궐련",
  "엽록소",
  "엽록체",
  "엽서",
  "엽전",
  "엿",
  "엿",
  "엿",
  "엿기름",
  "엿듣다",
  "엿새",
  "영",
  "영",
  "영광",
  "영구",
  "영국",
  "영국인",
  "영도자",
  "영리하다",
  "영묘",
  "영사",
  "영사관",
  "영상",
  "영성",
  "영성체",
  "영수증",
  "영양",
  "영양",
  "영양물",
  "영양탕",
  "영어",
  "영어",
  "영업",
  "영역",
  "영웅",
  "영원",
  "영원하다",
  "영원히",
  "영자팔법",
  "영적",
  "영지",
  "영토",
  "영하",
  "영향",
  "영향력",
  "영혼",
  "영화",
  "영화",
  "영화관",
  "옅다",
  "옆",
  "옆얼굴",
  "예",
  "예",
  "예",
  "예",
  "예",
  "예금",
  "예능",
  "예니레",
  "예닐곱",
  "예레반",
  "예루살렘",
  "예멘",
  "예문",
  "예물",
  "예방",
  "예배",
  "예배당",
  "예쁘다",
  "예사소리",
  "예산",
  "예상",
  "예상되다",
  "예상하다",
  "예수",
  "예수회",
  "예순",
  "예순째",
  "예술",
  "예술가",
  "예술품",
  "예식",
  "예약",
  "예약하다",
  "예언",
  "예언자",
  "예외",
  "예의",
  "예전",
  "예절",
  "예정",
  "예취기",
  "예카테린부르크",
  "예컨대",
  "예후",
  "옛",
  "옛날",
  "옛날이야기",
  "옛말",
  "옛한글",
  "오",
  "오",
  "오가네손",
  "오가다",
  "오각형",
  "오곰",
  "오금",
  "오늄",
  "오늘",
  "오늘날",
  "오다",
  "오덕후",
  "오데사",
  "오뎅",
  "오드콜로뉴",
  "오드투왈렛",
  "오등",
  "오디",
  "오딘",
  "오라버니",
  "오라버님",
  "오락",
  "오랑우탄",
  "오랑캐",
  "오래",
  "오래",
  "오래간만",
  "오래다",
  "오래되다",
  "오래된",
  "오래전",
  "오랜",
  "오랜만",
  "오랫동안",
  "오렌지",
  "오렌지색",
  "오렌지윗수염박쥐",
  "오롯하다",
  "오룔",
  "오류",
  "오르가슴",
  "오르간",
  "오르내리다",
  "오르다",
  "오르되브르",
  "오른",
  "오른",
  "오른손잡이",
  "오른쪽",
  "오름",
  "오리",
  "오리건",
  "오리나무",
  "오리너구리",
  "오만",
  "오만",
  "오면",
  "오문",
  "오물",
  "오므라이스",
  "오믈렛",
  "오바마",
  "오보에",
  "오븐",
  "오블라스티",
  "오빠",
  "오빤",
  "오사존",
  "오사카",
  "오세아니아",
  "오소리",
  "오스뮴",
  "오스트레일리아",
  "오스트리아",
  "오슬로",
  "오십",
  "오염",
  "오염되다",
  "오온",
  "오옴",
  "오옴",
  "오월",
  "오이",
  "오적어",
  "오전",
  "오존",
  "오줌",
  "오직",
  "오징어",
  "오카피",
  "오케스트라",
  "오케이",
  "오케이",
  "오크로시카",
  "오타와",
  "오타쿠",
  "오토메이션",
  "오토바이",
  "오트밀",
  "오페라",
  "오페레타",
  "오피스텔",
  "오하이오",
  "오해",
  "오해하다",
  "오후",
  "오히려",
  "옥",
  "옥고",
  "옥내",
  "옥문",
  "옥사졸",
  "옥상",
  "옥새",
  "옥색",
  "옥석",
  "옥수수",
  "옥시토신",
  "옥심",
  "옥잠화",
  "옥좌",
  "옥중",
  "옥탑",
  "옥탑",
  "옥토",
  "온",
  "온",
  "온",
  "온갖",
  "온도",
  "온도계",
  "온돌",
  "온두라스",
  "온라인",
  "온몸",
  "온수",
  "온실",
  "온종일",
  "온종일",
  "온천",
  "온통",
  "올",
  "올",
  "올겨울",
  "올라가다",
  "올라오다",
  "올려놓다",
  "올리고",
  "올리다",
  "올리브",
  "올림말",
  "올림픽",
  "올바르다",
  "올빼미",
  "올여름",
  "올챙이",
  "올해",
  "옮기다",
  "옮다",
  "옮아가다",
  "옮아오다",
  "옳다",
  "옳다",
  "옳아",
  "옳아",
  "옴",
  "옴",
  "옴",
  "옴",
  "옴개구리",
  "옴스크",
  "옴쌀",
  "옷",
  "옷감",
  "옷걸이",
  "옷장",
  "와",
  "와",
  "와",
  "와가두구",
  "와봐",
  "와사비",
  "와서",
  "와이셔츠",
  "와이파이",
  "와이프",
  "와인",
  "와트",
  "왁자그르르",
  "왁자지껄하다",
  "왁자지껄하다",
  "왁찐",
  "완곡어법",
  "완곡하다",
  "완곡한",
  "완두",
  "완료",
  "완벽",
  "완벽하다",
  "완성",
  "완성하다",
  "완소",
  "완전",
  "완전",
  "완전하다",
  "완전히",
  "왈츠",
  "왕",
  "왕가",
  "왕관",
  "왕국",
  "왕도마뱀",
  "왕복",
  "왕새매",
  "왕수",
  "왕실",
  "왕자",
  "왕종개",
  "왕태자",
  "왕후",
  "왜",
  "왜가리",
  "왜간장",
  "왜구",
  "왜꾹저구",
  "왜냐하면",
  "왜년",
  "왜놈",
  "왜매치",
  "왜몰개",
  "왜인",
  "왜케",
  "왜풀망둑",
  "왠지",
  "외",
  "외계어",
  "외과",
  "외교",
  "외교관",
  "외국",
  "외국어",
  "외국인",
  "외동딸",
  "외딸",
  "외래어",
  "외롭다",
  "외면",
  "외면하다",
  "외부",
  "외삼촌",
  "외손녀",
  "외손자",
  "외심",
  "외아들",
  "외우다",
  "외인",
  "외접원",
  "외출하다",
  "외치다",
  "외침",
  "외침",
  "외투",
  "외할머니",
  "외할아버지",
  "외화",
  "외환",
  "왼",
  "왼손잡이",
  "왼쪽",
  "요",
  "요",
  "요가",
  "요괴",
  "요구",
  "요구되다",
  "요구르트",
  "요구하다",
  "요금",
  "요녕",
  "요도",
  "요람",
  "요람본",
  "요르단",
  "요리",
  "요리사",
  "요리하다",
  "요망",
  "요법",
  "요새",
  "요새",
  "요소",
  "요소",
  "요술",
  "요약하다",
  "요오드",
  "요일",
  "요정",
  "요즈음",
  "요즘",
  "요청",
  "요청하다",
  "요충",
  "요크셔",
  "요하다",
  "요한",
  "요해",
  "욕",
  "욕",
  "욕망",
  "욕실",
  "욕심",
  "욕조",
  "욕하다",
  "용",
  "용감",
  "용감하다",
  "용과",
  "용기",
  "용기",
  "용대",
  "용돈",
  "용모",
  "용병",
  "용상어",
  "용서",
  "용서하다",
  "용암",
  "용액",
  "용어",
  "용언",
  "용의자",
  "용이",
  "용이하다",
  "용접하다",
  "용지",
  "우",
  "우",
  "우",
  "우",
  "우",
  "우간다",
  "우눈셉튬",
  "우눈헥슘",
  "우는토끼",
  "우동",
  "우라누스",
  "우라늄",
  "우러러보다",
  "우러르다",
  "우레",
  "우레",
  "우려",
  "우롱차",
  "우루과이",
  "우리",
  "우리",
  "우리나라",
  "우리들",
  "우리말",
  "우리집",
  "우린",
  "우먼",
  "우물",
  "우물우물",
  "우물쭈물",
  "우박",
  "우산",
  "우상",
  "우선",
  "우세",
  "우수리",
  "우스개",
  "우스갯소리",
  "우습다",
  "우승",
  "우아",
  "우아",
  "우아하다",
  "우아한",
  "우연히",
  "우울",
  "우울증",
  "우울하다",
  "우웩",
  "우유",
  "우의",
  "우의",
  "우정",
  "우주",
  "우주비행사",
  "우주선",
  "우주인",
  "우주진",
  "우즈베키스탄",
  "우체국",
  "우체부",
  "우체통",
  "우크라이나",
  "우크라이나어",
  "우펀국",
  "우편",
  "우편번호",
  "우편함",
  "우표",
  "우화",
  "운",
  "운검",
  "운남",
  "운동",
  "운동량",
  "운동복",
  "운동장",
  "운동하다",
  "운동화",
  "운라",
  "운명",
  "운반",
  "운반",
  "운석",
  "운송",
  "운수",
  "운수",
  "운수",
  "운수",
  "운수",
  "운영하다",
  "운전",
  "운전기사",
  "운전대",
  "운전사",
  "운전자",
  "운전하다",
  "운하",
  "울",
  "울",
  "울",
  "울",
  "울다",
  "울란바토르",
  "울루루",
  "울리다",
  "울산",
  "울안",
  "울음",
  "울음소리",
  "울타리",
  "울퉁불퉁",
  "움",
  "움직이다",
  "움직임",
  "움짤",
  "움찔하다",
  "웁살라",
  "웃",
  "웃기다",
  "웃다",
  "웃음",
  "웃음소리",
  "웃프다",
  "웅덩이",
  "웅변",
  "웅어",
  "워낙",
  "워라밸",
  "워싱턴",
  "원",
  "원",
  "원",
  "원고",
  "원고",
  "원곡",
  "원기둥",
  "원래",
  "원래",
  "원료",
  "원룸",
  "원리",
  "원망",
  "원망",
  "원반",
  "원산",
  "원소",
  "원숭이",
  "원시",
  "원앙",
  "원앙사촌",
  "원인",
  "원자",
  "원자력",
  "원자폭탄",
  "원장",
  "원주율",
  "원주필",
  "원추각막",
  "원칙",
  "원통",
  "원폭",
  "원피스",
  "원하다",
  "원화",
  "월",
  "월",
  "월경",
  "월급",
  "월남",
  "월남",
  "월드컵",
  "월병",
  "월병",
  "월세",
  "월식",
  "월요일",
  "월일",
  "웨이터",
  "웬",
  "웬일",
  "웰링턴",
  "웹",
  "웹사이트",
  "윁남",
  "위",
  "위구르어",
  "위기",
  "위대하다",
  "위반",
  "위병",
  "위생",
  "위생실",
  "위선",
  "위선자",
  "위성",
  "위스키",
  "위안부",
  "위에",
  "위원",
  "위원장",
  "위원회",
  "위주",
  "위증",
  "위치",
  "위치하다",
  "위키",
  "위키낱말사전",
  "위키백과",
  "위풍당당",
  "위하다",
  "위험",
  "위험하다",
  "위협",
  "위협하다",
  "윅셔너리",
  "윈난",
  "윈도",
  "윈도우",
  "윗",
  "윗사람",
  "윗수염박쥐",
  "유",
  "유가",
  "유가증권",
  "유감",
  "유고슬라비아",
  "유과",
  "유곽",
  "유교",
  "유기체",
  "유난히",
  "유능",
  "유능하다",
  "유니코드",
  "유니콘",
  "유니폼",
  "유대",
  "유대교",
  "유대인",
  "유도",
  "유도기",
  "유도탄",
  "유독",
  "유독하다",
  "유두",
  "유두",
  "유라시아",
  "유량",
  "유럽",
  "유럽짱깨",
  "유령",
  "유로",
  "유로비트",
  "유로퓸",
  "유륜",
  "유르트",
  "유리",
  "유리",
  "유리",
  "유리기",
  "유리잔",
  "유리하다",
  "유릿조각",
  "유머",
  "유명",
  "유명하다",
  "유명한",
  "유모",
  "유모차",
  "유물",
  "유방",
  "유비무환",
  "유사하다",
  "유산",
  "유서",
  "유성",
  "유술",
  "유신론",
  "유아",
  "유아원",
  "유언",
  "유엔",
  "유용",
  "유용하다",
  "유원지",
  "유월",
  "유음",
  "유의어",
  "유익하다",
  "유인원",
  "유일",
  "유전자",
  "유전학",
  "유제품",
  "유죄",
  "유지하다",
  "유진",
  "유충",
  "유치원",
  "유쾌하다",
  "유탄",
  "유태인",
  "유튜브",
  "유학",
  "유학생",
  "유해하다",
  "유해한",
  "유행",
  "유행병",
  "유행성이하선염",
  "유혹",
  "유희",
  "육",
  "육각수",
  "육각형",
  "육개장",
  "육교",
  "육군",
  "육분의",
  "육서",
  "육십",
  "육이오",
  "육자대명왕진언",
  "육자진언",
  "육조",
  "육지",
  "육체",
  "육회",
  "윤년",
  "윤리",
  "윤리학",
  "윤회",
  "윷",
  "윷놀이",
  "으",
  "으로",
  "으르다",
  "으르다",
  "으스러뜨리다",
  "으스름하다",
  "으쌰",
  "은",
  "은",
  "은어",
  "은어",
  "은연어",
  "은유",
  "은인",
  "은자메나",
  "은하",
  "은하계",
  "은하단",
  "은하수",
  "은행",
  "은행",
  "은혜",
  "을",
  "을",
  "을게",
  "을까",
  "을이다",
  "을이다",
  "읊다",
  "읊조리다",
  "음",
  "음",
  "음",
  "음경",
  "음경골",
  "음계",
  "음독",
  "음량",
  "음력",
  "음렬",
  "음료",
  "음료수",
  "음모",
  "음모",
  "음문",
  "음바바네",
  "음반",
  "음성",
  "음성모음",
  "음소",
  "음소문자",
  "음순",
  "음식",
  "음식값",
  "음식점",
  "음악",
  "음악가",
  "음악회",
  "음양",
  "음역",
  "음역자",
  "음욕",
  "음운론",
  "음원",
  "음절",
  "음침하다",
  "음표",
  "음핵",
  "음향",
  "음향양자",
  "음훈",
  "읍",
  "응",
  "응가",
  "응결",
  "응당",
  "응모자",
  "응시",
  "응용",
  "응원",
  "응집력",
  "응회암",
  "의",
  "의견",
  "의견란",
  "의도",
  "의례",
  "의료",
  "의료보험증",
  "의무",
  "의문",
  "의문사",
  "의미",
  "의미론",
  "의미하다",
  "의복",
  "의사",
  "의사",
  "의사",
  "의상",
  "의성어",
  "의식",
  "의식",
  "의식하다",
  "의심",
  "의심쩍다",
  "의심하다",
  "의연금",
  "의열단",
  "의욕",
  "의원",
  "의원",
  "의의",
  "의자",
  "의장",
  "의제",
  "의존",
  "의존증",
  "의존하다",
  "의지",
  "의지하다",
  "의태어",
  "의하다",
  "의학",
  "의향",
  "의혹",
  "의회",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이",
  "이거",
  "이거",
  "이걸",
  "이것",
  "이게",
  "이곳",
  "이곳은",
  "이교",
  "이교도",
  "이구아나",
  "이글",
  "이글이글",
  "이기다",
  "이기다",
  "이기적",
  "이기주의",
  "이끌다",
  "이끼",
  "이끼도롱뇽",
  "이나",
  "이날",
  "이내",
  "이념",
  "이다",
  "이단",
  "이달",
  "이데올로기",
  "이도",
  "이동",
  "이두",
  "이듬해",
  "이디시어",
  "이따가",
  "이때",
  "이때다",
  "이라",
  "이라",
  "이라뇨",
  "이라크",
  "이라크어",
  "이란",
  "이란다",
  "이랑",
  "이래",
  "이래",
  "이래",
  "이러다",
  "이러쿵저러쿵",
  "이러하다",
  "이런",
  "이런",
  "이렇게",
  "이렇다",
  "이레",
  "이력서",
  "이론",
  "이롭다",
  "이루다",
  "이루어지다",
  "이룩하다",
  "이뤄지다",
  "이르다",
  "이르다",
  "이르다",
  "이르쿠츠크",
  "이름",
  "이리",
  "이리",
  "이리",
  "이리듐",
  "이마",
  "이맘",
  "이메일",
  "이며",
  "이며",
  "이모",
  "이모지",
  "이미",
  "이미다졸",
  "이미지",
  "이민",
  "이발",
  "이발사",
  "이발소",
  "이번",
  "이벤트",
  "이별",
  "이부자리",
  "이분",
  "이불",
  "이빨",
  "이쁘네요",
  "이쁘다",
  "이사",
  "이사",
  "이사악",
  "이사야",
  "이사하다",
  "이산화탄소",
  "이삼",
  "이상",
  "이상",
  "이상",
  "이상야릇하다",
  "이상하다",
  "이상한",
  "이상한",
  "이상향",
  "이성",
  "이성",
  "이성애",
  "이성애자",
  "이소니트릴",
  "이소시아니드",
  "이스라엘",
  "이스마엘",
  "이스탄불",
  "이슬",
  "이슬라마바드",
  "이슬람",
  "이슬람교",
  "이슬람교도",
  "이슬람국가",
  "이슬비",
  "이승",
  "이신론",
  "이심전심",
  "이십",
  "이쑤시개",
  "이야",
  "이야",
  "이야기",
  "이야기하다",
  "이야깃거리",
  "이어",
  "이어지다",
  "이어폰",
  "이엉",
  "이외",
  "이용",
  "이용",
  "이용되다",
  "이용하다",
  "이울다",
  "이웃",
  "이웃집",
  "이원",
  "이월",
  "이유",
  "이윽고",
  "이음매",
  "이응",
  "이익",
  "이인칭",
  "이자",
  "이전",
  "이점",
  "이제",
  "이젠",
  "이주",
  "이집트",
  "이쪽",
  "이체자",
  "이케바나",
  "이크라",
  "이탈리아",
  "이탈리아어",
  "이태리",
  "이터븀",
  "이테르븀",
  "이트륨",
  "이튿날",
  "이틀",
  "이하",
  "이해",
  "이해하다",
  "이행",
  "이혼",
  "이혼하다",
  "이후",
  "익다",
  "익다",
  "익룡",
  "익살",
  "익살스럽다",
  "익히다",
  "인",
  "인",
  "인",
  "인",
  "인",
  "인",
  "인",
  "인",
  "인",
  "인간",
  "인감",
  "인격",
  "인공",
  "인공지능",
  "인구",
  "인국",
  "인권",
  "인근",
  "인기",
  "인내",
  "인대",
  "인덴",
  "인도",
  "인도네시아",
  "인도양",
  "인도차이나",
  "인돌",
  "인듐",
  "인력",
  "인력",
  "인력거",
  "인류",
  "인명",
  "인물",
  "인민",
  "인사",
  "인사하다",
  "인산인해",
  "인삼",
  "인삼차",
  "인상",
  "인상",
  "인상",
  "인색하다",
  "인색함",
  "인생",
  "인쇄",
  "인쇄기",
  "인수",
  "인수",
  "인식",
  "인식론",
  "인식표",
  "인식하다",
  "인어",
  "인연",
  "인용",
  "인장",
  "인재",
  "인절미",
  "인정",
  "인정하다",
  "인제",
  "인종",
  "인종차별",
  "인중",
  "인증",
  "인증하다",
  "인질",
  "인천",
  "인체",
  "인촨",
  "인치",
  "인칭",
  "인터넷",
  "인터뷰",
  "인터페이스",
  "인텔리겐치아",
  "인티파다",
  "인플레이션",
  "인플루엔자",
  "인하다",
  "인형",
  "인형극",
  "인화지",
  "인후",
  "일",
  "일",
  "일",
  "일",
  "일각수",
  "일건서류",
  "일고여덟",
  "일곱",
  "일광",
  "일광욕",
  "일광욕",
  "일군",
  "일기",
  "일기",
  "일꾼",
  "일단",
  "일대일",
  "일들을",
  "일들을",
  "일들을",
  "일들을",
  "일등",
  "일리노이",
  "일리리아",
  "일반",
  "일반인",
  "일반적",
  "일반적",
  "일본",
  "일본어",
  "일본원숭이",
  "일본인",
  "일본주",
  "일본해",
  "일부",
  "일부러",
  "일상",
  "일상과",
  "일상생활",
  "일생",
  "일식",
  "일신론",
  "일심단결",
  "일쑤",
  "일어",
  "일어나다",
  "일어나라",
  "일어서다",
  "일여덟",
  "일요일",
  "일원",
  "일월",
  "일월",
  "일으키다",
  "일인칭",
  "일자",
  "일자리",
  "일정",
  "일정하다",
  "일종",
  "일주일",
  "일찍",
  "일찍이",
  "일체",
  "일출",
  "일치하다",
  "일컫다",
  "일컬리다",
  "일하다",
  "일행",
  "일화",
  "일흔",
  "일흔째",
  "읽다",
  "잃다",
  "잃어버리다",
  "임금",
  "임금",
  "임대",
  "임대하다",
  "임마누엘",
  "임부",
  "임산부",
  "임시",
  "임신",
  "임신부",
  "임신하다",
  "임실납자루",
  "임진왜란",
  "임하다",
  "입",
  "입겿",
  "입구",
  "입다",
  "입다",
  "입다",
  "입대",
  "입덧",
  "입력",
  "입력기",
  "입맞추다",
  "입맞춤",
  "입방체",
  "입법자",
  "입빠이",
  "입술",
  "입술연지",
  "입시",
  "입쌀",
  "입양",
  "입원",
  "입자",
  "입장",
  "입장료",
  "입천장",
  "입학",
  "잇다",
  "잇따르다",
  "잇몸",
  "있는",
  "있다",
  "있어",
  "있으시다",
  "잉걸",
  "잉글랜드",
  "잉부",
  "잉어",
  "잉여",
  "잉크",
  "잊다",
  "잊어버리다",
  "잊혀지다",
  "잊히다",
  "잎",
  "자",
  "자",
  "자",
  "자",
  "자가사리",
  "자가용",
  "자갈",
  "자격",
  "자격지심",
  "자궁",
  "자그레브",
  "자극",
  "자기",
  "자기",
  "자기소개",
  "자기애",
  "자꾸",
  "자꾸만",
  "자네",
  "자네들",
  "자녀",
  "자다",
  "자동",
  "자동사",
  "자동차",
  "자동판매기",
  "자동화",
  "자두",
  "자두나무",
  "자라다",
  "자랑",
  "자랑스럽다",
  "자랑하다",
  "자료",
  "자루",
  "자류석",
  "자르다",
  "자리",
  "자릿하다",
  "자막",
  "자만",
  "자매",
  "자메이카",
  "자명금",
  "자명종",
  "자모",
  "자몽",
  "자물쇠",
  "자백",
  "자벌레",
  "자본",
  "자본주의",
  "자비",
  "자살",
  "자살하다",
  "자석",
  "자선",
  "자세",
  "자세히",
  "자손",
  "자수",
  "자수",
  "자수하다",
  "자식",
  "자신",
  "자아",
  "자업자득",
  "자연",
  "자연스럽다",
  "자연환경",
  "자외선",
  "자원",
  "자위",
  "자위하다",
  "자유",
  "자유롭다",
  "자유적",
  "자유주의",
  "자율",
  "자음",
  "자의",
  "자작",
  "자장가",
  "자장노래",
  "자장면",
  "자전",
  "자전거",
  "자제",
  "자존심",
  "자주",
  "자주",
  "자주복",
  "자지",
  "자지",
  "자차",
  "자체",
  "자취하다",
  "자치",
  "자칫하면",
  "자카르타",
  "자칼",
  "자판",
  "자판기",
  "자폐증",
  "자행거",
  "작",
  "작가",
  "작곡자",
  "작년",
  "작다",
  "작동",
  "작별",
  "작사가",
  "작사하다",
  "작성",
  "작성하다",
  "작업",
  "작업복",
  "작용",
  "작용하다",
  "작은",
  "작은관코박쥐",
  "작은두꺼비",
  "작은땃쥐",
  "작은위수염박쥐",
  "작은윗수염박쥐",
  "작일",
  "작작",
  "작품",
  "작품집",
  "잔",
  "잔가시고기",
  "잔돈",
  "잔디",
  "잔디밭",
  "잔뜩",
  "잔류",
  "잔소리",
  "잔점박이물범",
  "잔치",
  "잔텐",
  "잖아",
  "잘",
  "잘다",
  "잘되다",
  "잘못",
  "잘못",
  "잘못되다",
  "잘못하다",
  "잘생기다",
  "잘잘못",
  "잘하다",
  "잠",
  "잠그다",
  "잠그다",
  "잠기다",
  "잠깐",
  "잠꼬대",
  "잠들다",
  "잠비아",
  "잠수함",
  "잠시",
  "잠언",
  "잠언",
  "잠자다",
  "잠자리",
  "잠자리",
  "잠잠우물",
  "잡년",
  "잡다",
  "잡담",
  "잡담하다",
  "잡수시다",
  "잡신",
  "잡종",
  "잡종견",
  "잡지",
  "잡채",
  "잡초",
  "잡히다",
  "잣",
  "잣",
  "장",
  "장",
  "장",
  "장",
  "장",
  "장",
  "장",
  "장가",
  "장갑",
  "장과",
  "장관",
  "장교",
  "장군",
  "장군석",
  "장기",
  "장기",
  "장기",
  "장기",
  "장난",
  "장난감",
  "장뇌",
  "장독",
  "장래",
  "장려금",
  "장력",
  "장례",
  "장르",
  "장마",
  "장면",
  "장모",
  "장미",
  "장보다",
  "장비",
  "장사",
  "장석",
  "장소",
  "장소",
  "장수",
  "장수",
  "장승",
  "장시",
  "장식",
  "장쑤",
  "장애",
  "장애인",
  "장어",
  "장원",
  "장인",
  "장작",
  "장점",
  "장정",
  "장정",
  "장정",
  "장치",
  "장화",
  "잦다",
  "재",
  "재",
  "재",
  "재규어",
  "재기하다",
  "재난",
  "재능",
  "재다",
  "재다",
  "재다",
  "재다",
  "재다",
  "재단사",
  "재두루미",
  "재떨이",
  "재료",
  "재미",
  "재미없다",
  "재미있는",
  "재미있다",
  "재벌",
  "재봉틀",
  "재빠르다",
  "재빨리",
  "재산",
  "재수",
  "재앙",
  "재작년",
  "재잘거리다",
  "재정",
  "재주",
  "재즈",
  "재채기",
  "재채기하다",
  "재킷",
  "재판",
  "재판관",
  "재판소",
  "재해",
  "잭",
  "잭",
  "잭프루트",
  "잼",
  "잽싸다",
  "잿빛개구리매",
  "쟁기",
  "쟁기질",
  "쟁반",
  "쟁이",
  "쟤",
  "쟨",
  "저",
  "저",
  "저거",
  "저것",
  "저게",
  "저고리",
  "저곳",
  "저금",
  "저금통",
  "저급",
  "저기",
  "저기",
  "저널리스트",
  "저녁",
  "저녁밥",
  "저런",
  "저렇게",
  "저렇다",
  "저마늄",
  "저분",
  "저수지",
  "저승",
  "저승사자",
  "저어새",
  "저울",
  "저자",
  "저장",
  "저장",
  "저장하다",
  "저절로",
  "저주",
  "저주하다",
  "저지",
  "저지르다",
  "저지하다",
  "저쪽",
  "저편",
  "저항",
  "저항기",
  "저희",
  "적",
  "적",
  "적",
  "적극",
  "적극적",
  "적극적",
  "적다",
  "적다",
  "적당",
  "적당하다",
  "적당히",
  "적도",
  "적막",
  "적분",
  "적어도",
  "적외선",
  "적용",
  "적용되다",
  "적용하다",
  "적은",
  "적응도",
  "적자",
  "적절하다",
  "적정량",
  "적포도주",
  "적혈구",
  "적확하다",
  "적히다",
  "전",
  "전",
  "전",
  "전",
  "전",
  "전",
  "전",
  "전",
  "전",
  "전",
  "전갈",
  "전개",
  "전개되다",
  "전개하다",
  "전골",
  "전공",
  "전공",
  "전관예우",
  "전구",
  "전국",
  "전국",
  "전국",
  "전국",
  "전국",
  "전국",
  "전국",
  "전국적",
  "전국적",
  "전기",
  "전날",
  "전달하다",
  "전당포",
  "전도",
  "전도사",
  "전도서",
  "전등",
  "전라남도",
  "전라도",
  "전라북도",
  "전람회",
  "전략",
  "전략가",
  "전력",
  "전류",
  "전리품",
  "전망",
  "전모",
  "전문",
  "전문가",
  "전문화",
  "전반적",
  "전보",
  "전부",
  "전사",
  "전설",
  "전세",
  "전세",
  "전술",
  "전시",
  "전시하다",
  "전시회",
  "전신",
  "전압",
  "전어",
  "전에",
  "전에",
  "전에",
  "전에",
  "전에",
  "전에",
  "전에",
  "전에",
  "전염병",
  "전위",
  "전자",
  "전자",
  "전자",
  "전자레인지",
  "전자메일",
  "전장",
  "전쟁",
  "전주",
  "전지",
  "전차",
  "전철",
  "전체",
  "전체적",
  "전체주의",
  "전치사",
  "전통",
  "전통적",
  "전투",
  "전하다",
  "전학",
  "전해지다",
  "전혀",
  "전화",
  "전화기",
  "전화번호",
  "전화하다",
  "전환",
  "전환하다",
  "절",
  "절",
  "절",
  "절강",
  "절다",
  "절다",
  "절대",
  "절대로",
  "절대적",
  "절망",
  "절망",
  "절반",
  "절차",
  "젊다",
  "젊은이",
  "젊음",
  "점",
  "점검",
  "점령",
  "점몰개",
  "점박이물범",
  "점수",
  "점심",
  "점액",
  "점원",
  "점잖다",
  "점잖아",
  "점점",
  "점줄망둑",
  "점줄종개",
  "점차",
  "점프",
  "점프하다",
  "접",
  "접골",
  "접근",
  "접근하다",
  "접다",
  "접두사",
  "접때",
  "접미사",
  "접미어",
  "접사",
  "접선",
  "접속",
  "접수처",
  "접수하다",
  "접시",
  "접전",
  "접종하다",
  "접촉",
  "접하다",
  "젓",
  "젓가락",
  "젓가락질",
  "젓가락질하다",
  "젓다",
  "젓뱅어",
  "정",
  "정",
  "정",
  "정가",
  "정강이",
  "정거장",
  "정겹다",
  "정교회",
  "정구",
  "정권",
  "정규",
  "정글",
  "정기",
  "정답다",
  "정당",
  "정당",
  "정당",
  "정도",
  "정량",
  "정류",
  "정류장",
  "정리",
  "정리",
  "정리하다",
  "정말",
  "정말",
  "정말로",
  "정맥",
  "정면",
  "정변",
  "정보",
  "정부",
  "정비",
  "정사각형",
  "정상",
  "정상",
  "정상위",
  "정상치",
  "정상회담",
  "정서",
  "정수",
  "정수",
  "정수",
  "정식",
  "정신",
  "정신분열병",
  "정신분열증",
  "정신적",
  "정액",
  "정역학",
  "정오",
  "정욕",
  "정원",
  "정월",
  "정유",
  "정육점",
  "정은",
  "정의",
  "정의",
  "정일봉",
  "정자",
  "정자",
  "정장",
  "정적분",
  "정전",
  "정전",
  "정조",
  "정조대",
  "정주",
  "정직",
  "정직하다",
  "정찰병",
  "정책",
  "정치",
  "정치가",
  "정치권",
  "정치인",
  "정치적",
  "정치판",
  "정치학",
  "정크푸드",
  "정통",
  "정하다",
  "정해지다",
  "정확하다",
  "정확히",
  "정황",
  "젖",
  "젖가공품",
  "젖꼭지",
  "젖다",
  "젖당못견딤증",
  "젖먹이",
  "젖은",
  "젖통",
  "제",
  "제",
  "제거",
  "제거하다",
  "제공",
  "제공하다",
  "제과점",
  "제국",
  "제국주의",
  "제금",
  "제기차기",
  "제네바",
  "제노바",
  "제논",
  "제단",
  "제대",
  "제대로",
  "제도",
  "제도",
  "제독",
  "제동기",
  "제로",
  "제명",
  "제목",
  "제발",
  "제법",
  "제보",
  "제복",
  "제비",
  "제사",
  "제설차",
  "제시",
  "제시하다",
  "제안",
  "제안하다",
  "제약",
  "제외하다",
  "제우스",
  "제유",
  "제유법",
  "제육덮밥",
  "제의",
  "제의하다",
  "제일",
  "제자",
  "제작",
  "제작자",
  "제작하다",
  "제재",
  "제조업",
  "제주",
  "제주도",
  "제주도",
  "제주도롱뇽",
  "제주땃쥐",
  "제주말",
  "제주모치망둑",
  "제주어",
  "제쳐놓다",
  "제출",
  "제출하다",
  "제탄",
  "제트기",
  "제품",
  "제한",
  "제한하다",
  "제화",
  "젤",
  "젤",
  "젤리",
  "조",
  "조",
  "조가비",
  "조각",
  "조개",
  "조건",
  "조계종",
  "조교",
  "조교",
  "조교",
  "조교",
  "조국",
  "조그맣다",
  "조금",
  "조금",
  "조금씩",
  "조깅",
  "조끼",
  "조끼",
  "조랑말",
  "조례",
  "조로아스터",
  "조롱이",
  "조류",
  "조르다",
  "조르다",
  "조리",
  "조리개",
  "조립하다",
  "조만간",
  "조미료",
  "조반",
  "조부모",
  "조사",
  "조사",
  "조사하다",
  "조산부",
  "조산사",
  "조상",
  "조상",
  "조선",
  "조선",
  "조선",
  "조선",
  "조선",
  "조선글",
  "조선말",
  "조선민주주의인민공화국",
  "조선반도",
  "조선어",
  "조선옷",
  "조선인",
  "조세",
  "조센징",
  "조수",
  "조심",
  "조심스럽다",
  "조심하다",
  "조약돌",
  "조언",
  "조언하다",
  "조용",
  "조용하다",
  "조용히",
  "조의",
  "조이다",
  "조이스틱",
  "조작",
  "조종사",
  "조지아",
  "조지타운",
  "조직",
  "조직학",
  "조차",
  "조촐하다",
  "조치",
  "조카",
  "조카딸",
  "조폭",
  "조피볼락",
  "조합",
  "조현병",
  "조화",
  "조화",
  "족발",
  "족제비",
  "존",
  "존경",
  "존나",
  "존댓말",
  "존맛",
  "존예",
  "존재",
  "존재하다",
  "존중",
  "존중하다",
  "졸",
  "졸다",
  "졸리다",
  "졸업",
  "졸업생",
  "졸업식",
  "졸업하다",
  "졸혼",
  "좀",
  "좀",
  "좀구굴치",
  "좀비",
  "좀수수치",
  "좁다",
  "종",
  "종",
  "종개",
  "종교",
  "종다리",
  "종달새",
  "종로",
  "종료",
  "종류",
  "종성",
  "종아리",
  "종어",
  "종업원",
  "종유석",
  "종이",
  "종이접기",
  "종일",
  "종족",
  "종파",
  "종파",
  "종합",
  "좆",
  "좆목",
  "좋다",
  "좋아지다",
  "좋아하다",
  "좋아해",
  "좋아해요",
  "좋은",
  "좌우",
  "좌우명",
  "죄",
  "죄다",
  "죄다",
  "죄송",
  "죄송하다",
  "죄수",
  "죄악",
  "주",
  "주거",
  "주고받다",
  "주교",
  "주교관",
  "주구",
  "주권",
  "주근깨",
  "주님",
  "주다",
  "주두",
  "주둥치",
  "주로",
  "주류",
  "주막",
  "주말",
  "주머니",
  "주머니칼",
  "주먹",
  "주목하다",
  "주무르다",
  "주무시다",
  "주문",
  "주문하다",
  "주민",
  "주바",
  "주방",
  "주방장",
  "주변",
  "주부",
  "주부",
  "주부",
  "주뼛주뼛",
  "주사기",
  "주사위",
  "주석",
  "주석",
  "주세요",
  "주소",
  "주스",
  "주식",
  "주식",
  "주식",
  "주어",
  "주어",
  "주어지다",
  "주연",
  "주요",
  "주요하다",
  "주위",
  "주유소",
  "주의",
  "주의",
  "주의하다",
  "주인",
  "주인공",
  "주일",
  "주일",
  "주일",
  "주일",
  "주장",
  "주장",
  "주장",
  "주장",
  "주장하다",
  "주저앉다",
  "주전자",
  "주점",
  "주제",
  "주차",
  "주차장",
  "주차하다",
  "주체",
  "주키니",
  "주택",
  "주판",
  "주황색",
  "죽",
  "죽",
  "죽",
  "죽다",
  "죽순",
  "죽음",
  "죽이다",
  "죽지",
  "준",
  "준",
  "준말",
  "준비",
  "준비하다",
  "줄",
  "줄",
  "줄곧",
  "줄공치",
  "줄기",
  "줄납자루",
  "줄다",
  "줄망둑",
  "줄몰개",
  "줄박이돌고래",
  "줄어들다",
  "줄이다",
  "줄종개",
  "줍다",
  "중",
  "중",
  "중간",
  "중개인",
  "중경",
  "중계",
  "중고기",
  "중공",
  "중국",
  "중국산개구리",
  "중국어",
  "중국인",
  "중국집",
  "중년",
  "중대",
  "중대백로",
  "중대하다",
  "중도",
  "중독",
  "중동",
  "중량",
  "중력",
  "중력자",
  "중립",
  "중반",
  "중백로",
  "중복되다",
  "중복된",
  "중부",
  "중사",
  "중성",
  "중성",
  "중성자",
  "중세",
  "중소기업",
  "중순",
  "중심",
  "중앙",
  "중얼거리다",
  "중얼중얼",
  "중요",
  "중요성",
  "중요하다",
  "중위",
  "중재인",
  "중적분",
  "중죄",
  "중학교",
  "중화",
  "중화대북",
  "중화민국",
  "중화인민공화국",
  "줘",
  "쥐",
  "쥐다",
  "쥐며느리",
  "즉",
  "즉시",
  "즐거운",
  "즐거움",
  "즐겁다",
  "즐기다",
  "즙",
  "증가",
  "증가시키다",
  "증가하다",
  "증거",
  "증권",
  "증권사",
  "증기",
  "증기선",
  "증명",
  "증명하다",
  "증상",
  "증오심",
  "증인",
  "증정",
  "증진하다",
  "증후군",
  "지",
  "지",
  "지",
  "지",
  "지",
  "지각",
  "지각",
  "지갑",
  "지겹다",
  "지경",
  "지구",
  "지구",
  "지구대",
  "지구본",
  "지구의",
  "지금",
  "지금",
  "지금",
  "지금부터",
  "지금부터",
  "지금부터",
  "지껄이다",
  "지나가다",
  "지나다",
  "지나치다",
  "지난",
  "지난달",
  "지난밤",
  "지난번",
  "지난해",
  "지내다",
  "지네",
  "지느러미",
  "지능",
  "지니다",
  "지다",
  "지다",
  "지다",
  "지다",
  "지다",
  "지대",
  "지도",
  "지도",
  "지도력",
  "지도자",
  "지도책",
  "지동차",
  "지라",
  "지라프",
  "지랄하다",
  "지레",
  "지레",
  "지뢰",
  "지루하다",
  "지류",
  "지르다",
  "지르코늄",
  "지름",
  "지름길",
  "지리학",
  "지린",
  "지만",
  "지명",
  "지방",
  "지방",
  "지방산",
  "지방족",
  "지배하다",
  "지복직관",
  "지부티",
  "지불",
  "지불하다",
  "지붕",
  "지사",
  "지사",
  "지속적",
  "지시",
  "지시봉",
  "지시하다",
  "지식",
  "지식인",
  "지압",
  "지어내다",
  "지역",
  "지옥",
  "지옥철",
  "지우개",
  "지우다",
  "지우다",
  "지워",
  "지워",
  "지원",
  "지원자",
  "지원자",
  "지원하다",
  "지위",
  "지읒",
  "지저귀다",
  "지적",
  "지적",
  "지적",
  "지적되다",
  "지적하다",
  "지점",
  "지주",
  "지주",
  "지중해",
  "지지",
  "지지자",
  "지지하다",
  "지진",
  "지질",
  "지질학",
  "지출",
  "지치다",
  "지켜보다",
  "지키다",
  "지팡이",
  "지퍼",
  "지평선",
  "지폐",
  "지푸라기",
  "지피다",
  "지피다",
  "지피다",
  "지피다",
  "지하",
  "지하도",
  "지하드",
  "지하실",
  "지하철",
  "지협",
  "지혜",
  "지혜서",
  "지휘소",
  "직감",
  "직관",
  "직기",
  "직물",
  "직발",
  "직사각형",
  "직업",
  "직원",
  "직위",
  "직장",
  "직장",
  "직전",
  "직접",
  "직접",
  "직접적",
  "직접적",
  "직접화법",
  "직캠",
  "직할시",
  "직후",
  "진",
  "진",
  "진",
  "진공",
  "진공청소기",
  "진균",
  "진눈깨비",
  "진단",
  "진달래",
  "진동수",
  "진드기",
  "진료소",
  "진리",
  "진보",
  "진부하다",
  "진실",
  "진실하다",
  "진심",
  "진여",
  "진열장",
  "진원지",
  "진정하다",
  "진주",
  "진주층",
  "진지",
  "진지하다",
  "진짜",
  "진짜",
  "진출",
  "진출하다",
  "진취",
  "진하다",
  "진행",
  "진행되다",
  "진행자",
  "진행하다",
  "진화",
  "진흙",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질",
  "질녀",
  "질량",
  "질리다",
  "질리다",
  "질문",
  "질문하다",
  "질병",
  "질서",
  "질소",
  "질책하다",
  "질투",
  "질환",
  "짊다",
  "짊어지다",
  "짐",
  "짐",
  "짐바브웨",
  "짐승",
  "짐작",
  "짐작하다",
  "집",
  "집게",
  "집다",
  "집단",
  "집단화",
  "집들",
  "집들이",
  "집박쥐",
  "집사람",
  "집안",
  "집어넣다",
  "집오리",
  "집중",
  "집중되다",
  "집쥐",
  "집토끼",
  "집필",
  "집합",
  "집합명사",
  "집회",
  "집회서",
  "짓",
  "짓",
  "짓궂다",
  "짓다",
  "징",
  "징계",
  "징그럽다",
  "짖다",
  "짙다",
  "짙푸르다",
  "짚",
  "짚다",
  "짜",
  "짜다",
  "짜다",
  "짜다",
  "짜릿하다",
  "짜장면",
  "짜증",
  "짜하다",
  "짝",
  "짝사랑",
  "짝짓기",
  "짝짓다",
  "짠하다",
  "짧다",
  "짧은부리참돌고래",
  "짬",
  "짬뽕",
  "짭새",
  "짭짤하다",
  "짱",
  "짱",
  "짱",
  "짱개",
  "짱깨",
  "짱꼴라",
  "짱뚱어",
  "째",
  "쩍다",
  "쩨마",
  "쪄",
  "쪼개다",
  "쪼다",
  "쪽",
  "쪽발이",
  "쫓기다",
  "쫓다",
  "쭉",
  "쯔놈",
  "쯤",
  "찌개",
  "찌그러지다",
  "찌그러진",
  "찌다",
  "찌르다",
  "찌창",
  "찌푸리다",
  "찍다",
  "찍소리",
  "찐만두",
  "찔리다",
  "찜",
  "찜질방",
  "찡그리다",
  "찢다",
  "찢어지다",
  "찧다",
  "차",
  "차",
  "차",
  "차갑다",
  "차고",
  "차다",
  "차다",
  "차다",
  "차돌박이",
  "차드",
  "차라리",
  "차량",
  "차례",
  "차례차례",
  "차르",
  "차리다",
  "차림",
  "차림표",
  "차별",
  "차분몫",
  "차분법",
  "차색",
  "차용어",
  "차원",
  "차이",
  "차이나타운",
  "차이니스",
  "차지하다",
  "차츰",
  "차트",
  "착각",
  "착륙",
  "착암기",
  "착용하다",
  "착하다",
  "찬",
  "찬가",
  "찬넬동자개",
  "찬란하다",
  "찬사",
  "찬스",
  "찬장",
  "찰",
  "찰나",
  "찰밥",
  "찰흙",
  "참",
  "참",
  "참",
  "참가",
  "참가자",
  "참가하다",
  "참개구리",
  "참고",
  "참고래",
  "참고서",
  "참기름",
  "참다",
  "참되다",
  "참둑중개",
  "참마자",
  "참말",
  "참매",
  "참몰개",
  "참붕어",
  "참빗",
  "참사",
  "참새",
  "참석하다",
  "참수리",
  "참여",
  "참여하다",
  "참외",
  "참으로",
  "참을성",
  "참전",
  "참종개",
  "참주",
  "참중고기",
  "참치",
  "참회",
  "찹쌀",
  "찻숱가락",
  "찻잎",
  "찻주전자",
  "찻집",
  "창",
  "창",
  "창",
  "창고",
  "창녀",
  "창문",
  "창밖",
  "창세기",
  "창원",
  "창자",
  "창제",
  "창조",
  "창조자",
  "창조적",
  "창조하다",
  "창턱",
  "창포검",
  "찾다",
  "찾아가다",
  "찾아내다",
  "찾아보다",
  "찾아오다",
  "채",
  "채",
  "채널",
  "채소",
  "채소밭",
  "채식주의",
  "채식주의자",
  "채용하다",
  "채우다",
  "채찍",
  "채팅",
  "책",
  "책가방",
  "책갈피",
  "책들",
  "책략",
  "책방",
  "책상",
  "책씻이",
  "책임",
  "책임감",
  "책임자",
  "책임지다",
  "책장",
  "책하다",
  "챔피언",
  "챙기다",
  "처남",
  "처녀",
  "처녀막",
  "처럼",
  "처리",
  "처방",
  "처벌",
  "처음",
  "처음",
  "처지",
  "척",
  "척",
  "척량",
  "척추",
  "척추동물",
  "척하다",
  "천",
  "천",
  "천국",
  "천국",
  "천당",
  "천둥",
  "천리마",
  "천리안",
  "천만",
  "천만에요",
  "천문단위",
  "천문대",
  "천문학",
  "천사",
  "천산갑",
  "천수국",
  "천식",
  "천왕성",
  "천장",
  "천재",
  "천적",
  "천정",
  "천정",
  "천조",
  "천주",
  "천주교",
  "천지",
  "천지",
  "천지",
  "천지",
  "천지",
  "천진",
  "천천히",
  "천체",
  "천체물리학",
  "천황",
  "철",
  "철갑상어",
  "철도",
  "철도역",
  "철마",
  "철밥통",
  "철사",
  "철새",
  "철인",
  "철자법",
  "철저하다",
  "철저히",
  "철판",
  "철폐",
  "철학",
  "첨가물",
  "첨서",
  "첨서",
  "첩",
  "첩",
  "첩",
  "첫",
  "첫날",
  "첫사랑",
  "첫소리",
  "첫째",
  "청",
  "청가시고기",
  "청각",
  "청각",
  "청개구리",
  "청국장",
  "청년",
  "청동",
  "청둥오리",
  "청량음료",
  "청머리오리",
  "청바지",
  "청색",
  "청설모",
  "청소",
  "청소기",
  "청소년",
  "청소하다",
  "청와대",
  "청주",
  "청주",
  "청진",
  "청진기",
  "청천",
  "청첩장",
  "청춘",
  "청하다",
  "청해",
  "청회색",
  "체",
  "체계",
  "체내",
  "체력",
  "체리",
  "체모",
  "체스",
  "체스꼬슬로벤스꼬",
  "체언",
  "체온계",
  "체위",
  "체육",
  "체육관",
  "체인",
  "체적",
  "체제",
  "체조",
  "체중",
  "체질",
  "체첸어",
  "체커",
  "체코",
  "체코슬로바키아",
  "체포",
  "체포하다",
  "체험",
  "체형",
  "쳐",
  "쳐다보다",
  "쳐죽이다",
  "초",
  "초",
  "초고속",
  "초고추장",
  "초기",
  "초대",
  "초대하다",
  "초대형",
  "초등학교",
  "초딩",
  "초록",
  "초록",
  "초록빛",
  "초록색",
  "초롱초롱",
  "초반",
  "초밥",
  "초성",
  "초승달",
  "초어",
  "초원",
  "초원수리",
  "초은하단",
  "초음파",
  "초점",
  "초지",
  "초콜렛",
  "초콜릿",
  "초특급",
  "촉각",
  "촉수",
  "촉지",
  "촌",
  "촌",
  "촌놈",
  "촌락",
  "촌스럽다",
  "촛불",
  "총",
  "총각",
  "총각김치",
  "총대주교",
  "총리",
  "총명",
  "총명하다",
  "총알",
  "총알열차",
  "총장",
  "총통",
  "촬영",
  "최",
  "최고",
  "최고",
  "최고",
  "최근",
  "최대",
  "최대한",
  "최대한",
  "최상급",
  "최선",
  "최소한",
  "최종",
  "최초",
  "최후",
  "추",
  "추",
  "추",
  "추가",
  "추격",
  "추구",
  "추구하다",
  "추기경",
  "추다",
  "추락",
  "추리닝",
  "추밀원",
  "추석",
  "추악하다",
  "추악한",
  "추억",
  "추위",
  "추적하다",
  "추진",
  "추진하다",
  "추천",
  "추출",
  "추측",
  "추측하다",
  "추켜세우다",
  "축",
  "축구",
  "축배",
  "축사",
  "축음기",
  "축일",
  "축전기",
  "축제",
  "축제일",
  "축척",
  "축축하다",
  "축하하다",
  "춘절",
  "춘하추동",
  "출구",
  "출근",
  "출근하다",
  "출납계",
  "출발",
  "출발하다",
  "출산",
  "출생",
  "출생일",
  "출생하다",
  "출석",
  "출석하다",
  "출신",
  "출신성분",
  "출신성분제도",
  "출애굽기",
  "출연",
  "출연하다",
  "출원자",
  "출장",
  "출처",
  "출출하다",
  "출퇴근하다",
  "출판하다",
  "출혈",
  "춤",
  "춤추다",
  "춥다",
  "충격",
  "충고",
  "충고하다",
  "충돌",
  "충분하다",
  "충분히",
  "충수",
  "충심",
  "충전기",
  "충청남도",
  "충청북도",
  "충칭",
  "춰",
  "췌장암",
  "취급",
  "취나물",
  "취리히",
  "취미",
  "취소",
  "취소",
  "취조",
  "취직",
  "취하다",
  "취하다",
  "취향",
  "취흥",
  "츄잉껌",
  "측위",
  "층",
  "치",
  "치",
  "치는",
  "치다",
  "치닫다",
  "치료",
  "치르다",
  "치리",
  "치마",
  "치아",
  "치안",
  "치약",
  "치어리더",
  "치어리딩",
  "치욕",
  "치우다",
  "치읓",
  "치즈",
  "치킨",
  "치타",
  "치통",
  "치한",
  "친구",
  "친근감",
  "친근하다",
  "친밀",
  "친삼촌",
  "친애",
  "친우",
  "친일파",
  "친절",
  "친절하다",
  "친절한",
  "친족",
  "친척",
  "친하다",
  "친할아버지",
  "칠",
  "칠",
  "칠각형",
  "칠레",
  "칠리",
  "칠면조",
  "칠성말배꼽",
  "칠성장어",
  "칠십",
  "칠월",
  "칠판",
  "칠하다",
  "칡",
  "침",
  "침",
  "침공",
  "침낭",
  "침대",
  "침략",
  "침략자",
  "침몰",
  "침묵",
  "침묵하다",
  "침술",
  "침실",
  "침입",
  "침팬지",
  "칩",
  "칫솔",
  "칭",
  "칭찬",
  "칭찬하다",
  "칭하이",
  "카",
  "카게베",
  "카나",
  "카나다",
  "카나리아",
  "카드",
  "카드뮴",
  "카디건",
  "카라반",
  "카라치",
  "카라카스",
  "카란",
  "카렌더",
  "카르보닐기",
  "카르복실기",
  "카르볼린",
  "카르빌아민",
  "카메라",
  "카메룬",
  "카멜레온",
  "카바레",
  "카바졸",
  "카보베르데",
  "카복실산",
  "카불",
  "카브",
  "카세트",
  "카옌",
  "카우보이",
  "카우치",
  "카이로",
  "카이만",
  "카이사르",
  "카인",
  "카자흐스탄",
  "카지노",
  "카타르",
  "카트",
  "카트만두",
  "카페",
  "카페인",
  "카페테리아",
  "카피바라",
  "칵테일",
  "칸",
  "칸",
  "칸",
  "칸델라",
  "칼",
  "칼국수",
  "칼날",
  "칼납자루",
  "칼라",
  "칼럼",
  "칼로리",
  "칼륨",
  "칼리닌그라드",
  "칼리파",
  "칼상어",
  "칼슘",
  "칼집",
  "캄보디아",
  "캄판",
  "캄팔라",
  "캐",
  "캐나다",
  "캐나다기러기",
  "캐넌",
  "캐다",
  "캐릭터",
  "캐비어",
  "캐스팅",
  "캔",
  "캔디",
  "캔버라",
  "캔버스",
  "캘리포늄",
  "캠퍼스",
  "캠페인",
  "캠프",
  "캠핑",
  "캠핑족",
  "캡",
  "캡슐",
  "캡스턴",
  "캥거루",
  "커",
  "커닝",
  "커닝하다",
  "커다랗다",
  "커리",
  "커뮤니티",
  "커브",
  "커서",
  "커지다",
  "커튼",
  "커플",
  "커피",
  "커피숍",
  "컬래버레이션",
  "컬트",
  "컴맹",
  "컴퓨터",
  "컵",
  "케냐",
  "케이블",
  "케이크",
  "케이프타운",
  "케익",
  "케첩",
  "케톤",
  "케피르",
  "켈빈",
  "켕기다",
  "켜",
  "켜다",
  "켜다",
  "켜다",
  "켜다",
  "켜다",
  "켜다",
  "켜다",
  "켤레",
  "코",
  "코",
  "코끼리",
  "코나크리",
  "코냑",
  "코너",
  "코드",
  "코리아",
  "코린",
  "코멘트",
  "코모로",
  "코미디",
  "코발트",
  "코뿔소",
  "코소보",
  "코스",
  "코스모스",
  "코스타리카",
  "코스프레",
  "코알라",
  "코요테",
  "코쟁이",
  "코치",
  "코카인",
  "코코넛",
  "코크스",
  "코토누",
  "코토레타",
  "코트",
  "코트",
  "코트디부아르",
  "코튼",
  "코페르니슘",
  "코펜하겐",
  "코퓰러",
  "코헬렛",
  "콘도르",
  "콘도미니엄",
  "콘돔",
  "콘리",
  "콘비프",
  "콘서트",
  "콘센트",
  "콘셉트",
  "콘스탄티노폴리스",
  "콘스탄티누스",
  "콘크리트",
  "콘택트",
  "콜",
  "콜",
  "콜라",
  "콜라보",
  "콜라보레이션",
  "콜레라",
  "콜레스테롤",
  "콜론",
  "콜롬보",
  "콜롬비아",
  "콜린",
  "콜카타",
  "콜호스",
  "콤마",
  "콤바인",
  "콤퓨터",
  "콤플렉스",
  "콧구멍",
  "콧노래",
  "콧물",
  "콧수염",
  "콩",
  "콩고",
  "콩자반",
  "콩팥",
  "쾌락",
  "쿠냥",
  "쿠데타",
  "쿠바",
  "쿠션",
  "쿠알라룸푸르",
  "쿠웨이트",
  "쿠키",
  "쿨라크",
  "쿨쿨",
  "쿨쿨",
  "쿵후",
  "쿼크",
  "퀴나졸린",
  "퀴녹살린",
  "퀴논",
  "퀴놀린",
  "퀴륨",
  "퀴즈",
  "퀸",
  "크기",
  "크다",
  "크다",
  "크라상",
  "크래시",
  "크래커",
  "크레용",
  "크레인",
  "크로뮴",
  "크로스드레싱",
  "크로아티아",
  "크로아티아어",
  "크로아티아인",
  "크롬",
  "크루아상",
  "크리스마스",
  "크리스마스이브",
  "크리스천",
  "크리켓",
  "크림",
  "크림",
  "크립톤",
  "크메르어",
  "크바르크",
  "크바스",
  "크산텐",
  "크세논",
  "큰",
  "큰가시고기",
  "큰고니",
  "큰귀박쥐",
  "큰기러기",
  "큰납지리",
  "큰논병아리",
  "큰논병아리",
  "큰덤불해오라기",
  "큰돌고래",
  "큰말똥가리",
  "큰머리돌고래",
  "큰물",
  "큰바다사자",
  "큰발뒤쥐",
  "큰발윗수염박쥐",
  "큰볏말뚝망둥어",
  "큰부리고래",
  "큰이빨부리고래",
  "큰일",
  "큰줄납자루",
  "큰집박쥐",
  "큰첨서",
  "큰회색머리아비",
  "큰흰죽지",
  "클래스",
  "클럽",
  "클로버",
  "클리닝",
  "클리토리스",
  "클립",
  "키",
  "키갈리",
  "키다리",
  "키로와트",
  "키르기스스탄",
  "키리바시",
  "키릴",
  "키릴문자",
  "키보드",
  "키스",
  "키스하다",
  "키시너우",
  "키예프",
  "키오스크",
  "키우다",
  "키위",
  "키읔",
  "키토",
  "키프로스",
  "킨샤사",
  "킬로",
  "킬로그람",
  "킬로그램",
  "킬로미터",
  "킬로와트",
  "킬롬미터",
  "킬트",
  "킹",
  "킹스타운",
  "킹스턴",
  "킹왕짱",
  "타갈로그어",
  "타나크",
  "타나토스",
  "타다",
  "타다",
  "타다",
  "타다",
  "타달타달",
  "타동사",
  "타락",
  "타밀어",
  "타슈켄트",
  "타워",
  "타원",
  "타월",
  "타이",
  "타이가",
  "타이르다",
  "타이머",
  "타이베이",
  "타이어",
  "타이어",
  "타이완",
  "타이타늄",
  "타이틀곡",
  "타이페이",
  "타이포그래피",
  "타이프라이터",
  "타일",
  "타입",
  "타자기",
  "타자하다",
  "타조",
  "타지크어",
  "타지키스탄",
  "타타르어",
  "타협",
  "타협하다",
  "탁",
  "탁구",
  "탁아소",
  "탁자",
  "탁치",
  "탄",
  "탄",
  "탄도",
  "탄소",
  "탄수화물",
  "탄알",
  "탄약고",
  "탄자니아",
  "탄탈럼",
  "탄트라",
  "탄화",
  "탄화수소",
  "탄환",
  "탄환열차",
  "탈",
  "탈",
  "탈것",
  "탈륨",
  "탈린",
  "탈북자",
  "탈영병",
  "탈영자",
  "탈의실",
  "탈조선",
  "탈주병",
  "탈주자",
  "탈출",
  "탈출기",
  "탈취제",
  "탐관오리",
  "탐구",
  "탐나다",
  "탐사",
  "탐욕",
  "탐정",
  "탐험",
  "탐험가",
  "탑",
  "탓",
  "탕",
  "탕평채",
  "태국",
  "태국어",
  "태권도",
  "태극",
  "태극기",
  "태도",
  "태반",
  "태산",
  "태산",
  "태아",
  "태양",
  "태양계",
  "태양계의",
  "태양절",
  "태양초",
  "태어나다",
  "태우다",
  "태우다",
  "태자",
  "태초",
  "태평양",
  "태풍",
  "택",
  "택",
  "택",
  "택",
  "택",
  "택시",
  "택하다",
  "탤런트",
  "탱크",
  "터",
  "터",
  "터널",
  "터뜨리다",
  "터럭",
  "터미널",
  "터븀",
  "터빈",
  "터지다",
  "터치패드",
  "터키",
  "터키어",
  "턱",
  "턱뼈",
  "턱수염",
  "털",
  "털다",
  "털발말똥가리",
  "털실",
  "털어놓다",
  "텅스텐",
  "테",
  "테구시갈파",
  "테네신",
  "테니스",
  "테니스장",
  "테러",
  "테러리스트",
  "테러리즘",
  "테러범",
  "테레비",
  "테마",
  "테스트",
  "테오도시우스",
  "테이블",
  "테이프",
  "테크네튬",
  "테헤란",
  "텍스트",
  "텐구",
  "텐트",
  "텔레비",
  "텔레비전",
  "텔레비죤",
  "텔레타이프",
  "텔루륨",
  "텔루르",
  "텔펜",
  "템포",
  "톈진",
  "토",
  "토",
  "토",
  "토고",
  "토끼",
  "토끼굴",
  "토끼박쥐",
  "토네이도",
  "토대",
  "토란",
  "토록",
  "토론",
  "토론토",
  "토륨",
  "토르",
  "토마스",
  "토마토",
  "토바고",
  "토성",
  "토스터",
  "토스트",
  "토양",
  "토오쿄",
  "토요일",
  "토지",
  "토착",
  "토착어",
  "토치",
  "토치카",
  "토픽",
  "토하다",
  "톤",
  "톤",
  "톱",
  "통",
  "통가",
  "통가리",
  "통계",
  "통계학",
  "통과하다",
  "통근",
  "통근하다",
  "통발",
  "통사리",
  "통솔자",
  "통신",
  "통역사",
  "통역자",
  "통일",
  "통일안",
  "통장",
  "통제",
  "통조림",
  "통증",
  "통지",
  "통찰력",
  "통치하다",
  "통풍",
  "통하다",
  "통합",
  "통행인",
  "통화",
  "퇴근",
  "퇴근하다",
  "퇴비",
  "퇴직금",
  "퇴진",
  "퇴학",
  "투",
  "투르",
  "투르크",
  "투르크메니스탄",
  "투명",
  "투명하다",
  "투발루",
  "투사지",
  "투숙",
  "투숙하다",
  "투어",
  "투자",
  "투쟁",
  "투표",
  "투표하다",
  "툰드라",
  "툴",
  "툴륨",
  "튀각",
  "튀기다",
  "튀김",
  "튀니스",
  "튀니지",
  "튀다",
  "튀르크",
  "튤립",
  "트다",
  "트랙타",
  "트랙터",
  "트랜지스터",
  "트램",
  "트램펄린",
  "트러스트",
  "트럭",
  "트럼펫",
  "트럼프",
  "트럼프",
  "트렁크",
  "트레이너",
  "트로이카",
  "트로트",
  "트로피",
  "트로필륨",
  "트롤",
  "트롤리버스",
  "트롱프뢰유",
  "트리니다드",
  "트리케라톱스",
  "트리폴리",
  "트림",
  "트림하다",
  "트빌리시",
  "트위터",
  "트이다",
  "특권",
  "특기",
  "특별",
  "특별하다",
  "특별히",
  "특성",
  "특수",
  "특정",
  "특정하다",
  "특징",
  "특히",
  "튼튼하다",
  "틀",
  "틀다",
  "틀리다",
  "틀림없다",
  "틀림없이",
  "틈",
  "티라나",
  "티라노사우루스",
  "티베리우스",
  "티베트",
  "티브이",
  "티셔츠",
  "티슈",
  "티아졸",
  "티아진",
  "티오펜",
  "티읕",
  "티켓",
  "티탄",
  "틴에이저",
  "팀",
  "팁",
  "파",
  "파고다",
  "파괴",
  "파괴되다",
  "파괴하다",
  "파나마",
  "파다",
  "파단",
  "파데",
  "파도",
  "파동",
  "파동함수",
  "파두츠",
  "파라과이",
  "파라마리보",
  "파라솔",
  "파라오",
  "파랑",
  "파랑새",
  "파랗다",
  "파렴치",
  "파렴치하다",
  "파리",
  "파리",
  "파리채",
  "파리하다",
  "파마",
  "파멸",
  "파문",
  "파벌",
  "파슈토어",
  "파스타",
  "파스텔",
  "파슬리",
  "파시스트",
  "파시즘",
  "파악하다",
  "파업",
  "파운데이션",
  "파운드",
  "파이",
  "파이팅",
  "파이프",
  "파인더",
  "파인애플",
  "파일",
  "파자마",
  "파장",
  "파전",
  "파출소",
  "파충",
  "파충류",
  "파키스탄",
  "파트너",
  "파트타임",
  "파티",
  "파파라치",
  "파파야",
  "파피루스",
  "파하다",
  "파하다",
  "파행",
  "파행",
  "판",
  "판",
  "판",
  "판",
  "판",
  "판",
  "판",
  "판",
  "판결",
  "판공실",
  "판권",
  "판다",
  "판단",
  "판단하다",
  "판도",
  "판도라",
  "판매",
  "판매점",
  "판매하다",
  "판문점",
  "판소리",
  "판옥선",
  "판자",
  "판지",
  "판판하다",
  "팔",
  "팔",
  "팔각형",
  "팔걸이",
  "팔꿈치",
  "팔다",
  "팔뚝",
  "팔라듐",
  "팔라우",
  "팔레스타인",
  "팔리다",
  "팔목",
  "팔미트산",
  "팔십",
  "팔월",
  "팔찌",
  "팝송",
  "팝콘",
  "팥",
  "팥빙수",
  "팥소",
  "패",
  "패거리",
  "패다",
  "패럴림픽",
  "패배",
  "패배자",
  "패션",
  "패션",
  "패션프루츠",
  "패스워드",
  "패전",
  "패킷",
  "팩",
  "팩스",
  "팬",
  "팬",
  "팬케이크",
  "팬티",
  "팸플릿",
  "퍼",
  "퍼",
  "퍼브",
  "퍼센트",
  "퍼즐",
  "퍼지다",
  "펄럭",
  "펄럭펄럭",
  "펄서",
  "펌프",
  "페나진",
  "페난트렌",
  "페난트리딘",
  "페놀",
  "페니",
  "페닐기",
  "페달",
  "페레스트로이카",
  "페루",
  "페르뮴",
  "페르세포네",
  "페르시아",
  "페미니즘",
  "페샤와르",
  "페스트",
  "페이지",
  "페인트",
  "페지",
  "페지",
  "페치카",
  "페티시즘",
  "펜",
  "펠라티오",
  "펠리컨",
  "펠메니",
  "펩타이드",
  "펩티드",
  "펭귄",
  "펴",
  "펴내다",
  "펴다",
  "펴세요",
  "편",
  "편경",
  "편도",
  "편람",
  "편리",
  "편리하다",
  "편복",
  "편성",
  "편식",
  "편안하다",
  "편암",
  "편의",
  "편의점",
  "편자",
  "편종",
  "편지",
  "편집",
  "편집자",
  "편집하다",
  "편찮다",
  "편찮으시다",
  "편하다",
  "펼쳐지다",
  "펼치다",
  "평",
  "평가",
  "평가하다",
  "평균",
  "평등",
  "평면",
  "평범",
  "평범하다",
  "평생",
  "평성",
  "평소",
  "평야",
  "평양",
  "평온",
  "평원",
  "평정",
  "평지",
  "평창",
  "평판",
  "평해",
  "평행",
  "평행한",
  "평형",
  "평화",
  "평화로운",
  "평화롭다",
  "폐",
  "폐기종",
  "폐렴",
  "폐어",
  "폐하",
  "폐회식",
  "포",
  "포",
  "포그롬",
  "포근하다",
  "포기",
  "포기",
  "포기하다",
  "포도",
  "포도당",
  "포도주",
  "포도탄",
  "포드고리차",
  "포럼",
  "포로",
  "포르노그래피",
  "포르토노보",
  "포르토프랭스",
  "포르투갈",
  "포르투갈어",
  "포르피린",
  "포르핀",
  "포름알데히드",
  "포리지",
  "포물선",
  "포세이돈",
  "포스터",
  "포옹",
  "포옹하다",
  "포유류",
  "포인트",
  "포치",
  "포커",
  "포켓",
  "포켓몬",
  "포켓몬스터",
  "포크",
  "포탈",
  "포털",
  "포트",
  "포트루이스",
  "포트모르즈비",
  "포트오브스페인",
  "포플러",
  "포피",
  "포함되다",
  "포함하다",
  "포화",
  "폭",
  "폭격기",
  "폭군",
  "폭넓다",
  "폭동",
  "폭력",
  "폭력단",
  "폭발",
  "폭발물",
  "폭정",
  "폭탄",
  "폭탄주",
  "폭포",
  "폭풍",
  "폭풍",
  "폭풍우",
  "폭행",
  "폰",
  "폰",
  "폴더",
  "폴란드",
  "폴란드어",
  "폴란드인",
  "폴로",
  "폴로늄",
  "퐁뒤",
  "표",
  "표",
  "표결",
  "표고",
  "표기",
  "표기",
  "표면",
  "표면장력",
  "표범",
  "표식",
  "표어",
  "표절",
  "표정",
  "표준",
  "표준어",
  "표지판",
  "표하다",
  "표현",
  "표현하다",
  "표현형",
  "푸나푸티",
  "푸는",
  "푸다",
  "푸르다",
  "푸르디푸르다",
  "푸른",
  "푸른얼굴얼가니새",
  "푸를",
  "푸에르토리코",
  "푸젠",
  "푸주한",
  "푹",
  "풀",
  "풀",
  "풀네임",
  "풀다",
  "풀리다",
  "풀망둑",
  "풀무",
  "풀밭",
  "풀버전",
  "풀어지다",
  "풀오버",
  "품",
  "품",
  "품격",
  "품다",
  "품목",
  "품사",
  "품삯",
  "품절",
  "품질",
  "풋",
  "풋내기",
  "풋볼",
  "풋사과",
  "풋사랑",
  "풋풋하다",
  "풍",
  "풍경",
  "풍금",
  "풍기다",
  "풍뎅이",
  "풍령",
  "풍부하다",
  "풍선",
  "풍수",
  "풍습",
  "풍자문",
  "퓨라잔",
  "퓨란",
  "퓨린",
  "퓨마",
  "프놈펜",
  "프라세오디뮴",
  "프라이아",
  "프라이팬",
  "프라하",
  "프랑",
  "프랑슘",
  "프랑스",
  "프랑스빵",
  "프랑스어",
  "프러포즈",
  "프런트",
  "프레젠테이션",
  "프로",
  "프로",
  "프로그람",
  "프로그래머",
  "프로그램",
  "프로메튬",
  "프로모션",
  "프로세서",
  "프로세스",
  "프로이센",
  "프로젝트",
  "프로트악티늄",
  "프로파길",
  "프로펠러",
  "프로필렌",
  "프롤레타리아",
  "프롯",
  "프리랜서",
  "프리메이슨",
  "프리슈티나",
  "프리즘",
  "프리지어",
  "프리타운",
  "프리토리아",
  "프린터",
  "프탈라진",
  "프탈레인",
  "프테리딘",
  "프테린",
  "플라스마",
  "플라스크",
  "플라스틱",
  "플래시몹",
  "플래티나",
  "플랫폼",
  "플러그",
  "플러그인",
  "플러쉬",
  "플러스",
  "플레로븀",
  "플루오렌",
  "플루오르",
  "플루오린",
  "플루토늄",
  "플루트",
  "플립플롭",
  "피",
  "피곤하다",
  "피난",
  "피난처",
  "피넨",
  "피다",
  "피라냐",
  "피라미",
  "피라미드",
  "피라졸",
  "피라진",
  "피란",
  "피로",
  "피롤",
  "피리",
  "피리다진",
  "피리딘",
  "피리미딘",
  "피망",
  "피부",
  "피센",
  "피스타치오",
  "피스톨",
  "피시",
  "피시방",
  "피씨방",
  "피아노",
  "피아니스트",
  "피에로",
  "피우다",
  "피읖",
  "피자",
  "피지",
  "피지",
  "피짠짜짜라어",
  "피크닉",
  "피트",
  "피페라진",
  "피페리딘",
  "피폭자",
  "피하다",
  "피해",
  "피해",
  "피해자",
  "픽업",
  "핀",
  "핀란드",
  "핀세트",
  "핀셋",
  "필",
  "필",
  "필드",
  "필드하키",
  "필름",
  "필리핀",
  "필리핀독수리",
  "필수",
  "필수적",
  "필업",
  "필요",
  "필요성",
  "필요하다",
  "필자",
  "필터",
  "필통",
  "핍박",
  "핑퐁",
  "하게체",
  "하고",
  "하긴",
  "하나",
  "하나님",
  "하나도",
  "하남",
  "하노이",
  "하느님",
  "하늘",
  "하늘다람쥐",
  "하늘색",
  "하다",
  "하다",
  "하데스",
  "하도",
  "하드웨어",
  "하라레",
  "하락하다",
  "하루",
  "하루살이",
  "하르툼",
  "하마",
  "하물",
  "하부",
  "하북",
  "하세요",
  "하셔요",
  "하수도",
  "하숙집",
  "하슘",
  "하시다",
  "하십시오체",
  "하얀",
  "하얀색",
  "하양",
  "하얗다",
  "하얼빈",
  "하여간",
  "하여튼",
  "하와",
  "하원",
  "하이난",
  "하이드라존",
  "하이드라진",
  "하이드록실아민",
  "하이에나",
  "하이킹",
  "하이퍼링크",
  "하이픈",
  "하이힐",
  "하인",
  "하즈",
  "하지만",
  "하지만",
  "하천",
  "하키",
  "하트",
  "하품",
  "하품하다",
  "하프",
  "하프늄",
  "하플로그룹",
  "하하",
  "학",
  "학공치",
  "학과",
  "학교",
  "학급",
  "학기",
  "학년",
  "학명",
  "학문",
  "학번",
  "학부",
  "학부모",
  "학사",
  "학살",
  "학생",
  "학생답다",
  "학생소년궁전",
  "학설",
  "학술",
  "학습",
  "학습하다",
  "학용품",
  "학원",
  "학위",
  "학자",
  "학회",
  "한",
  "한",
  "한",
  "한",
  "한",
  "한가",
  "한가운데",
  "한가위",
  "한강",
  "한강",
  "한강납줄개",
  "한겨레",
  "한겨레",
  "한계",
  "한국",
  "한국말",
  "한국산개구리",
  "한국어",
  "한국요리",
  "한국인",
  "한글",
  "한글날",
  "한꺼번에",
  "한담",
  "한담하다",
  "한도",
  "한동안",
  "한두",
  "한둑중개",
  "한둘",
  "한때",
  "한라봉",
  "한류",
  "한류",
  "한마디",
  "한문",
  "한물",
  "한민족",
  "한민족",
  "한반도",
  "한번",
  "한복",
  "한복",
  "한사군",
  "한숨",
  "한숨짓다",
  "한식",
  "한식집",
  "한쌍",
  "한어",
  "한어",
  "한여름",
  "한영",
  "한자",
  "한자어",
  "한잔",
  "한잔의",
  "한정사",
  "한족",
  "한족",
  "한중",
  "한쪽",
  "한참",
  "한탄하다",
  "한턱내다",
  "한테",
  "한테서",
  "한파",
  "한편",
  "할라페뇨",
  "할렐루야",
  "할로윈",
  "할리우드",
  "할머니",
  "할머님",
  "할미새",
  "할아버지",
  "할인",
  "할퀴다",
  "핥다",
  "함",
  "함경",
  "함구증",
  "함께",
  "함께하다",
  "함대",
  "함묵증",
  "함부로",
  "함부르크",
  "함수",
  "함흥",
  "합",
  "합격",
  "합금",
  "합기도",
  "합니다",
  "합니다",
  "합리적",
  "합법화",
  "합법화되다",
  "합쇼체",
  "합의",
  "합의",
  "합창",
  "합창단",
  "합치다",
  "핫도그",
  "핫라인",
  "핫스팟",
  "항",
  "항공",
  "항공기",
  "항공사",
  "항구",
  "항라머리검독수리",
  "항로",
  "항목",
  "항문",
  "항상",
  "항생물질",
  "항생제",
  "항성",
  "항의",
  "항의하다",
  "해",
  "해",
  "해",
  "해",
  "해",
  "해",
  "해",
  "해결",
  "해결되다",
  "해결하다",
  "해고",
  "해고하다",
  "해골",
  "해군",
  "해귀엣골",
  "해금",
  "해금",
  "해남",
  "해내다",
  "해달",
  "해답",
  "해당",
  "해당하다",
  "해도",
  "해돋이",
  "해동",
  "해라체",
  "해로운",
  "해롭다",
  "해마",
  "해마다",
  "해면",
  "해묵다",
  "해물",
  "해바라기",
  "해발",
  "해방",
  "해방하다",
  "해변",
  "해병",
  "해부학",
  "해빛",
  "해산물",
  "해삼",
  "해석",
  "해석자",
  "해석하다",
  "해시계",
  "해쓱하다",
  "해안",
  "해양",
  "해오라기",
  "해왕성",
  "해외",
  "해외구매",
  "해요",
  "해요체",
  "해자",
  "해저",
  "해적",
  "해주",
  "해중",
  "해질녘",
  "해체",
  "해초",
  "해치다",
  "해커",
  "해탄",
  "해태",
  "해파리",
  "해학",
  "해협",
  "핵",
  "핵가족",
  "핵무기",
  "핵반응",
  "핵심",
  "핸드백",
  "핸드볼",
  "핸드폰",
  "핸들",
  "햄",
  "햄버거",
  "햄스터",
  "햇무리",
  "햇볕",
  "햇빛",
  "햇살",
  "했어요",
  "했어요",
  "행",
  "행군",
  "행동",
  "행동하다",
  "행렬식",
  "행복",
  "행복하다",
  "행복한",
  "행사",
  "행성",
  "행수",
  "행운",
  "행운아",
  "행위",
  "행인",
  "행정학",
  "행주",
  "행진",
  "향",
  "향고래",
  "향기",
  "향기롭다",
  "향년",
  "향상",
  "향수",
  "향수",
  "향신료",
  "향유",
  "향찰",
  "향하다",
  "향항",
  "허",
  "허",
  "허가",
  "허구",
  "허난",
  "허둥지둥",
  "허락",
  "허락하다",
  "허리",
  "허리케인",
  "허물다",
  "허벅다리",
  "허베이",
  "허브티",
  "허수",
  "허수아비",
  "허영심",
  "허용",
  "허용하다",
  "허파",
  "허혼",
  "헌법",
  "헌병",
  "헐",
  "헐다",
  "험담",
  "헛",
  "헛간",
  "헛되다",
  "헛방귀",
  "헛소리",
  "헛코골다",
  "헝가리",
  "헝가리어",
  "헤드폰",
  "헤라",
  "헤로인",
  "헤르메스",
  "헤매다",
  "헤모글로빈",
  "헤스티아",
  "헤아리다",
  "헤어지다",
  "헤엄치다",
  "헤이그",
  "헤이룽장",
  "헤파이스토스",
  "헤프다",
  "헬기",
  "헬륨",
  "헬리콥터",
  "헬멧",
  "헬스",
  "헬스클럽",
  "헬싱키",
  "헬조선",
  "헷갈리다",
  "헹구다",
  "혀",
  "혁명",
  "현",
  "현",
  "현관",
  "현금",
  "현금",
  "현대",
  "현대",
  "현대적",
  "현명하다",
  "현미",
  "현미경",
  "현상",
  "현상",
  "현실",
  "현실적",
  "현실적",
  "현자",
  "현장",
  "현재",
  "현지",
  "현찰",
  "혈암",
  "혈액",
  "혈액형",
  "혈장",
  "혐오",
  "혐의",
  "협곡",
  "협력",
  "협력하다",
  "협박",
  "협박하다",
  "협상",
  "협잡질",
  "협회",
  "형",
  "형",
  "형",
  "형",
  "형",
  "형광",
  "형님",
  "형법",
  "형부",
  "형사",
  "형상",
  "형성",
  "형성",
  "형성되다",
  "형성하다",
  "형식",
  "형용사",
  "형제",
  "형제자매",
  "형태",
  "형편",
  "형화",
  "혜산",
  "혜성",
  "혜택",
  "호",
  "호기심",
  "호남",
  "호놀룰루",
  "호두",
  "호랑이",
  "호루라기",
  "호르몬",
  "호리",
  "호리",
  "호리",
  "호모포비아",
  "호밀",
  "호박",
  "호박",
  "호북",
  "호사",
  "호사비오리",
  "호선",
  "호수",
  "호수",
  "호숫가",
  "호스",
  "호실",
  "호정",
  "호주",
  "호주머니",
  "호주인",
  "호지명",
  "호치키스",
  "호텔",
  "호프",
  "호프",
  "호흡",
  "호흡하다",
  "혹",
  "혹고니",
  "혹등고래",
  "혹부리고래",
  "혹부리오리",
  "혹성",
  "혹시",
  "혹은",
  "혼돈",
  "혼돈하다",
  "혼란",
  "혼례식",
  "혼백",
  "혼수",
  "혼약",
  "혼인",
  "혼자",
  "혼혈",
  "홀",
  "홀로",
  "홀리다",
  "홀뮴",
  "홀소리",
  "홀스터",
  "홀아비",
  "홀어미",
  "홈",
  "홋카이도",
  "홍당무",
  "홍머리오리",
  "홍보",
  "홍송어",
  "홍수",
  "홍어",
  "홍어회",
  "홍역",
  "홍진",
  "홍차",
  "홍채",
  "홍콩",
  "홍학",
  "홍합",
  "홍해",
  "홑이불",
  "화",
  "화",
  "화가",
  "화기",
  "화나다",
  "화내다",
  "화단",
  "화려하다",
  "화면",
  "화물",
  "화병",
  "화병",
  "화보",
  "화분",
  "화사",
  "화산",
  "화살",
  "화살표",
  "화석",
  "화성",
  "화신",
  "화약",
  "화염병",
  "화요일",
  "화음",
  "화이팅",
  "화장",
  "화장",
  "화장",
  "화장실",
  "화장장",
  "화장지",
  "화장품",
  "화재",
  "화제",
  "화지",
  "화차",
  "화창하다",
  "화채",
  "화평",
  "화폐",
  "화학",
  "화학전달물질",
  "화합물",
  "확대",
  "확대경",
  "확대되다",
  "확대하다",
  "확률",
  "확립",
  "확보",
  "확성기",
  "확신",
  "확실하다",
  "확실히",
  "확인",
  "확인되다",
  "확인하다",
  "확장",
  "확정하다",
  "환",
  "환",
  "환갑",
  "환경",
  "환도",
  "환락",
  "환멸",
  "환상",
  "환송하다",
  "환영",
  "환영",
  "환영하다",
  "환율",
  "환자",
  "환하다",
  "활",
  "활동",
  "활동가",
  "활동적",
  "활동하다",
  "활발",
  "활발하다",
  "활성",
  "활성탄",
  "활시위",
  "활용",
  "활용하다",
  "황",
  "황",
  "황",
  "황금",
  "황도",
  "황동",
  "황로",
  "황복",
  "황사",
  "황새",
  "황소",
  "황소개구리",
  "황어",
  "황오리",
  "황제",
  "황제",
  "황조롱이",
  "황줄망둑",
  "황천",
  "황천",
  "황천",
  "황해",
  "황후",
  "횃불",
  "회",
  "회견",
  "회계",
  "회계사",
  "회계학",
  "회관",
  "회교",
  "회교도",
  "회기",
  "회담",
  "회답",
  "회랑",
  "회령",
  "회백질",
  "회복",
  "회복하다",
  "회비",
  "회사",
  "회사원",
  "회상",
  "회색",
  "회색기러기",
  "회색머리아비",
  "회원",
  "회음",
  "회음부",
  "회의",
  "회의",
  "회장",
  "회전목마",
  "회합",
  "회화",
  "획득하다",
  "횡단",
  "횡단보도",
  "횡령",
  "횡파",
  "효과",
  "효과적",
  "효과적",
  "효모",
  "효소",
  "효소학",
  "효자손",
  "후",
  "후각",
  "후견인",
  "후계자",
  "후과",
  "후기",
  "후난",
  "후뇌",
  "후드",
  "후라이팬",
  "후반",
  "후배",
  "후배위",
  "후베이",
  "후보",
  "후보자",
  "후사",
  "후사",
  "후식",
  "후예",
  "후유증",
  "후작",
  "후천성면역결핍증",
  "후추",
  "후춧가루",
  "후회",
  "후회하다",
  "훈",
  "훈독",
  "훈련",
  "훈몽자회",
  "훈장",
  "훌륭하다",
  "훑다",
  "훑어보다",
  "훔치다",
  "훔치다",
  "훨씬",
  "휘파람",
  "휠체어",
  "휴가",
  "휴게",
  "휴게소",
  "휴게실",
  "휴대",
  "휴대전화",
  "휴대폰",
  "휴식",
  "휴식하다",
  "휴일",
  "휴전",
  "휴지",
  "휴지통",
  "휴척",
  "휴학",
  "흄",
  "흄",
  "흉내",
  "흉노",
  "흉상",
  "흉터",
  "흐느끼다",
  "흐려지다",
  "흐르다",
  "흐르바쯔까",
  "흐름",
  "흐리다",
  "흐릿하다",
  "흐지부지",
  "흑",
  "흑",
  "흑고니",
  "흑기러기",
  "흑기사",
  "흑두루미",
  "흑로",
  "흑룡강",
  "흑발",
  "흑색종",
  "흑역사",
  "흑인",
  "흑자",
  "흑형",
  "흑흑",
  "흔들다",
  "흔들리다",
  "흔적",
  "흔하다",
  "흔히",
  "흘러가다",
  "흘러나오다",
  "흘러내리다",
  "흘리다",
  "흙",
  "흠뻑",
  "흠잡다",
  "흡수지",
  "흡연",
  "흡인력",
  "흡충",
  "흡혈귀",
  "흥",
  "흥겹다",
  "흥륭",
  "흥미",
  "흥미롭다",
  "흥미진진하다",
  "흥분",
  "흥분하다",
  "흥정",
  "흥청망청",
  "흥행",
  "흥행장",
  "흩다",
  "흩어지다",
  "희",
  "희곡",
  "희극",
  "희끗희끗",
  "희다",
  "희로애락",
  "희롱하다",
  "희망",
  "희망하다",
  "희생",
  "희생양",
  "희생자",
  "희소식",
  "희천",
  "희한하다",
  "희한히",
  "흰",
  "흰개미",
  "흰고래",
  "흰곰",
  "흰기러기",
  "흰꼬리수리",
  "흰날개해오라기",
  "흰넓적다리붉은쥐",
  "흰동가리",
  "흰머리기러기",
  "흰발망둑",
  "흰배슴새",
  "흰배윗수염박쥐",
  "흰부리아비",
  "흰비오리",
  "흰뺨검둥오리",
  "흰뺨오리",
  "흰색",
  "흰수마자",
  "흰쌀",
  "흰이마기러기",
  "흰자위",
  "흰점복",
  "흰죽지",
  "흰죽지수리",
  "흰줄납줄개",
  "흰줄박이오리",
  "히",
  "히",
  "히",
  "히드록시기",
  "히드록실기",
  "히라가나",
  "히브리어",
  "히읗",
  "히잡",
  "히터",
  "히프",
  "힌두교",
  "힌디어",
  "힘",
  "힘내다",
  "힘들다",
  "힘바리",
  "힘쓰다",
  "힘주다",
  "힘줄",
  "힘줄",
  "힙합"
]