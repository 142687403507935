import React, { useState, useEffect } from "react";
import "../../styles.css";

import styled from "styled-components";

import Controller from "../../components/Controller";
import Display from "../../components/Display";

import {useRecoilState} from "recoil";

import { inputState, lastCharState, attemptsState } from "../../atoms/atoms.js";
import { levels, charsForLevel } from "../../info";

function TypeingGame(props) {
  // Current Input
  const [input, setInput] = useRecoilState(inputState);

  // Last Character Input
  const [lastChar, setLastChar] = useRecoilState(lastCharState);

  // Number of Keypresses
  const [attempts, setAttempts] = useRecoilState(attemptsState);

  // Current Level
  const [level, setLevel] = useState(1);

  // Hangul Block to Type
  const [string, setString] = useState(
    Array.from(
      { length: 7 },
      () => levels[level][Math.floor(Math.random() * levels[level].length)]
    )
  );

  useEffect(() => {
    setString(
      Array.from(
        { length: 7 },
        () => levels[level][Math.floor(Math.random() * levels[level].length)]
      )
    );
  }, [level]);

  // Completed Hangul Blocks
  const [answers, setAnswers] = useState([]);

  const [streak, setStreak] = useState(
    Object.fromEntries(
      levels[level].map((obj) => {
        return [obj, 0];
      })
    )
  );
  useEffect(() => {
    setStreak({
      ...Object.fromEntries(
        charsForLevel[level].map((obj) => {
          return [obj, 0];
        })
      ),
    });
  }, [level]);

  return (
    <div className="App" {...props}>
      <Controller
        input={input}
        streak={streak}
        setStreak={setStreak}
        level={level}
        levels={levels}
        string={string}
        setString={setString}
        setAttempts={setAttempts}
        attempts={attempts}
        setInput={setInput}
        setAnswers={setAnswers}
        answers={answers}
        setLevel={setLevel}
      />
      <Display
        input={input}
        streak={streak}
        setStreak={setStreak}
        level={level}
        levels={levels}
        string={string}
        setString={setString}
        setAttempts={setAttempts}
        attempts={attempts}
        setInput={setInput}
        setAnswers={setAnswers}
        answers={answers}
        setLevel={setLevel}
      />
    </div>
  );
}

export default styled(TypeingGame)`
  height: 100%;
  .App > div {
    display: inline-block;
  }
`;
