import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import Button from "./Button";
import Draw from "./Draw";
import svg_map from "../svg_map";
import Keyboard from "./Keyboard";
import { newChar } from "../info";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#0D2027",
    borderColor: "#244B5A",
    color: "#919191",
    // width: '50vw',
    // maxWidth: '500px',
    // height: '50vw',
    // maxHeight: '500px',
    // minWidth: 'max-content',
    // minHeight: '300px',
    display: "flex",
  },
  overlay: {
    zIndex: 100000,
    background: "rgba(13, 32, 39, 0.85)",
  },
};

const consonant = (type, char, sound, name, soundName, strokes) => {
  return `The next character to be introduced is the ${type} ${char} ${sound} called ${name} ${soundName} which has a total of ${strokes} ${(() =>
    strokes.length <= 1 ? "stokes" : "stroke")()}.`;
};
const vowel = (type, char, sound, strokes) => {
  return `The next character to be introduced is the ${type} ${char} ${sound} which has a total of ${strokes} ${(() =>
    strokes.length <= 1 ? "stokes" : "stroke")()}.`;
};

const intros = {
  ㄱ: consonant("consonant", "ㄱ", "[k/g]", "기역", "[gi-yeok]", 2),
  ㄴ: consonant("consonant", "ㄴ", "[n]", "니은", "[ni-eun]", 1),
  ㄷ: consonant("consonant", "ㄷ", "[d/t]", "디귿", "[di-geut]", 2),
  ㄹ: consonant("consonant", "ㄹ", "[r/l]", "리을", "[ri-eul]", 3),
  ㅁ: consonant("consonant", "ㅁ", "[m]", "미음", "[mi-eum]", 3),
  ㅂ: consonant("consonant", "ㅂ", "[b/p]", "비읍", "[bi-eup]", 4),
  ㅅ: consonant("consonant", "ㅅ", "[s/t]", "시옷", "[si-ot]", 2),
  ㅇ: consonant("consonant", "ㅇ", "", "이응", "[i-eung]", 1),
  ㅈ: consonant("consonant", "ㅈ", "[ch/j/t]", "지읒", "[ji-eut]", 3),
  ㅊ: consonant("consonant", "ㅊ", "[ch/t]", "치읓", "[chi-eut]", 4),
  ㅋ: consonant("consonant", "ㅋ", "[kh]", "키읔", "[ki-euk]", 2),
  ㅌ: consonant("consonant", "ㅌ", "[t]", "티읕", "[ti-eut]", 3),
  ㅍ: consonant("consonant", "ㅍ", "[p]", "피읖", "[pi-eup]", 4),
  ㅎ: consonant("consonant", "ㅎ", "[h]", "히읕", "[hi-eut]", 3),
  ㅏ: vowel("vowel", "ㅏ", "[ah]", 2),
  ㅣ: vowel("vowel", "ㅣ", "[ee]", 1),
  ㅓ: vowel("vowel", "ㅓ", "[eo]", 2),
  ㅗ: vowel("vowel", "ㅗ", "[oh]", 2),
  ㅜ: vowel("vowel", "ㅜ", "[oo]", 2),
  ㅡ: vowel("vowel", "ㅡ", "[uh]", 1),
  ㅑ: vowel("vowel", "ㅑ", "[yah]", 3),
  ㅕ: vowel("vowel", "ㅕ", "[yeo]", 3),
  ㅛ: vowel("vowel", "ㅛ", "[yoh]", 3),
  ㅠ: vowel("vowel", "ㅠ", "[yoo]", 3),
};

const maps = {
  ㄱ: svg_map["_ㄱ_1.svg"],
  ㄴ: svg_map["_ㄴ_1.svg"],
  ㄷ: svg_map["_ㄷ_1.svg"],
  ㄹ: svg_map["_ㄹ_1.svg"],
  ㅁ: svg_map["_ㅁ_1.svg"],
  ㅂ: svg_map["_ㅂ_1.svg"],
  ㅅ: svg_map["_ㅅ_1.svg"],
  ㅇ: svg_map["_ㅇ_1.svg"],
  ㅈ: svg_map["_ㅈ_1.svg"],
  ㅊ: svg_map["_ㅊ_1.svg"],
  ㅋ: svg_map["_ㅋ_1.svg"],
  ㅌ: svg_map["_ㅌ_1.svg"],
  ㅍ: svg_map["_ㅍ_1.svg"],
  ㅎ: svg_map["_ㅎ_1.svg"],
  ㅓ: svg_map["_ㅓ_1.svg"],
  ㅏ: svg_map["_ㅏ_1.svg"],
  ㅡ: svg_map["_ㅡ_1.svg"],
  ㅗ: svg_map["_ㅗ_1.svg"],
  ㅜ: svg_map["_ㅜ_1.svg"],
  ㅣ: svg_map["_ㅣ_1.svg"],
  ㅠ: svg_map["_ㅠ_1.svg"],
  ㅛ: svg_map["_ㅛ_1.svg"],
  ㅕ: svg_map["_ㅕ_1.svg"],
  ㅑ: svg_map["_ㅑ_1.svg"],
};

function Modal({ levels, level, children, ...props }) {
  const [modalIsOpen, setIsOpen] = useState(true);
  useEffect(() =>  {
    if (level) {
      setIsOpen(true);
    }
  }, [level]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div {...props}>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            margin: "auto",
            width: "100vw",
            textAlign: "center",
            maxWidth: "500px",
          }}
        >
          {children ? (
            <div>{children}</div>
          ) : level <= 24 ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: "rgb(120, 165, 187)" }}>
                  {intros[newChar[level]]}
                </p>
                <Draw character={maps[newChar[level]]} />
              </div>
              <Keyboard char={newChar[level]} levels={levels} level={level} />
              <Button
                onClick={() => {
                  closeModal();
                }}
              >
                Ok, I understand.
              </Button>
            </>
          ) : (
            <div>
              <p style={{ color: "rgb(120, 165, 187)" }}>
                Now that you have learned all the characters we will now be able
                to create Hangul Jamo blocks which are composed of two or more{" "}
                <span style={{ fontWeight: "bold", color: "#FED831" }}>
                  consonants
                </span>{" "}
                and{" "}
                <span style={{ fontWeight: "bold", color: "#3AB54A" }}>
                  vowels
                </span>
                .
              </p>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {level === 25 ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="173"
                      height="173"
                      viewBox="0 0 173 173"
                    >
                      <g
                        id="Group_38"
                        data-name="Group 38"
                        transform="translate(599 -2759)"
                      >
                        <g
                          id="Rectangle_20"
                          data-name="Rectangle 20"
                          transform="translate(-428 2842) rotate(90)"
                          fill="#3ab54a"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="88" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="90"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_21"
                          data-name="Rectangle 21"
                          transform="translate(-428 2761) rotate(90)"
                          fill="#fed831"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="81" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="83"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <text
                          id="요"
                          transform="translate(-579 2891)"
                          font-size="146"
                          font-family="NanumGothic"
                          style={{
                            mixBlendMode: "overlay",
                            isolation: "isolate",
                          }}
                        >
                          <tspan x="0" y="0">
                            요
                          </tspan>
                        </text>
                      </g>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="173"
                      height="173"
                      viewBox="0 0 173 173"
                    >
                      <g
                        id="Group_35"
                        data-name="Group 35"
                        transform="translate(789 -2759)"
                      >
                        <g
                          id="Rectangle_18"
                          data-name="Rectangle 18"
                          transform="translate(-706 2761)"
                          fill="#3ab54a"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="88" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="90"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_19"
                          data-name="Rectangle 19"
                          transform="translate(-787 2761)"
                          fill="#fed831"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="81" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="83"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <text
                          id="이"
                          transform="translate(-773 2895)"
                          font-size="146"
                          font-family="NanumGothic"
                          style={{
                            mixBlendMode: "overlay",
                            isolation: "isolate",
                          }}
                        >
                          <tspan x="0" y="0">
                            이
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="173"
                      height="173"
                      viewBox="0 0 173 173"
                    >
                      <g
                        id="Group_39"
                        data-name="Group 39"
                        transform="translate(59 -2435)"
                      >
                        <g
                          id="Rectangle_24"
                          data-name="Rectangle 24"
                          transform="translate(112 2437) rotate(90)"
                          fill="#3ab54a"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="169" height="85" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="171"
                            height="87"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_22"
                          data-name="Rectangle 22"
                          transform="translate(27 2518) rotate(90)"
                          fill="#3ab54a"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="88" height="84" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="90"
                            height="86"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_23"
                          data-name="Rectangle 23"
                          transform="translate(27 2437) rotate(90)"
                          fill="#fed831"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="81" height="84" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="83"
                            height="86"
                            fill="none"
                          />
                        </g>
                        <text
                          id="과"
                          transform="translate(-40 2567)"
                          font-size="146"
                          font-family="NanumGothic"
                          style={{
                            mixBlendMode: "overlay",
                            isolation: "isolate",
                          }}
                        >
                          <tspan x="0" y="0">
                            과
                          </tspan>
                        </text>
                      </g>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="173"
                      height="173"
                      viewBox="0 0 173 173"
                    >
                      <g
                        id="Group_40"
                        data-name="Group 40"
                        transform="translate(789 -2973)"
                      >
                        <g
                          id="Rectangle_27"
                          data-name="Rectangle 27"
                          transform="translate(-787 3060)"
                          fill="#1dbab4"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="169" height="84" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="171"
                            height="86"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_25"
                          data-name="Rectangle 25"
                          transform="translate(-706 2975)"
                          fill="#3ab54a"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="88" height="85" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="90"
                            height="87"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_26"
                          data-name="Rectangle 26"
                          transform="translate(-787 2975)"
                          fill="#fed831"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="81" height="85" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="83"
                            height="87"
                            fill="none"
                          />
                        </g>
                        <text
                          id="간"
                          transform="translate(-773 3106)"
                          font-size="146"
                          font-family="NanumGothic"
                          style={{
                            mixBlendMode: "overlay",
                            isolation: "isolate",
                          }}
                        >
                          <tspan x="0" y="0">
                            간
                          </tspan>
                        </text>
                      </g>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="173"
                      height="173"
                      viewBox="0 0 173 173"
                    >
                      <g
                        id="Group_41"
                        data-name="Group 41"
                        transform="translate(599 -2973)"
                      >
                        <g
                          id="Rectangle_30"
                          data-name="Rectangle 30"
                          transform="translate(-428 3088) rotate(90)"
                          fill="#1dbab4"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="56" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="58"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_28"
                          data-name="Rectangle 28"
                          transform="translate(-428 3032) rotate(90)"
                          fill="#3ab54a"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="56" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="58"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_29"
                          data-name="Rectangle 29"
                          transform="translate(-428 2975) rotate(90)"
                          fill="#fed831"
                          stroke="#0d2027"
                          stroke-width="2"
                        >
                          <rect width="57" height="169" stroke="none" />
                          <rect
                            x="-1"
                            y="-1"
                            width="59"
                            height="171"
                            fill="none"
                          />
                        </g>
                        <text
                          id="곤"
                          transform="translate(-579 3106)"
                          font-size="146"
                          font-family="NanumGothic"
                          style={{
                            mixBlendMode: "overlay",
                            isolation: "isolate",
                          }}
                        >
                          <tspan x="0" y="0">
                            곤
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

export default styled(Modal)`
  ${ReactModal} {
    z-index: 100000;
  }

  span.consonants {
    font-weight: bold;
    color: #fed831;
  }

  .even {
    display: flex;
    justify-content: space-evenly;
  }
`;
