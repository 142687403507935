import React, { useState } from "react";

import hangul from "hangul-js";

import styled from "styled-components";

import useSound from "use-sound";

import { englishToHangul } from "hangul-utils";

import two from "./2.mp3";

import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useRecoilState } from "recoil";
import { inputState, lastCharState, attemptsState } from "../atoms/atoms.js";


import {vowels, consonants} from "../info.js";

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
}

function shuffle(o) {
  for (
    var j, x, i = o.length;
    i;
    j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
  );
  return o;
}

function Controller({
  streak,
  setStreak,
  level,
  levels,
  string,
  setString,
  setAnswers,
  answers,
  setLevel
}) {
  const [input, setInput] = useRecoilState(inputState);
  const [lastChar, setLastChar] = useRecoilState(lastCharState);
  const [attempts, setAttempts] = useRecoilState(attemptsState);


  const [play] = useSound(two);
  const [batch, setBatch] = useState([]);

  const isCompletedBlock = (input) => {
    return input === string[0];
  };

  const generateNextBlock = () => {
    switch (level) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
      case 24: {
        const mostTroubledCharacters = Object.keys(streak).sort(function (
          a,
          b
        ) {
          return streak[a] - streak[b];
        });
        const newAnswers = answers.splice(
          Math.max(answers.length - 5, 0),
          answers.length
        );
        setAnswers([...newAnswers, string[0]]);

        let batched = [];
        if (batch.length === 0) {
          batched = shuffle(mostTroubledCharacters.slice(0, 10));
        } else {
          batched = batch;
        }

        setString([
          ...string.slice(1),
          batched.pop(),
        ]);

        setBatch(batched);
        break;
      }
      case 25: {
        const newAnswers = answers.splice(
          Math.max(answers.length - 5, 0),
          answers.length
        );
        setAnswers([...newAnswers, string[0]]);
        setString([
          ...string.splice(1),
          hangul.assemble([
            consonants[Math.floor(Math.random() * consonants.length)],
            vowels[Math.floor(Math.random() * vowels.length)],
          ]),
        ]);
        break;
      }
      case 26: {
        const newAnswers = answers.splice(
          Math.max(answers.length - 5, 0),
          answers.length
        );
        setAnswers([...newAnswers, string[0]]);
        setString([
          ...string.splice(1),
          hangul.assemble([
            consonants[Math.floor(Math.random() * consonants.length)],
            vowels[Math.floor(Math.random() * vowels.length)],
            consonants[Math.floor(Math.random() * consonants.length)],
          ]),
        ]);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleInput = (event) => {
    const hangulArray = hangul.disassemble(event.key);
    const key =
      englishToHangul(hangulArray[hangulArray.length - 1]) ||
      hangulArray[hangulArray.length - 1];

    const answerArray = hangul.disassemble(string[0]);
    const currentChar = answerArray[hangul.disassemble(input).length];

    setLastChar(key);
    setAttempts(attempts + 1);
    switch (key) {
      case currentChar: {
        setStreak({ ...streak, [currentChar]: streak[currentChar] + 1 });
        setInput(hangul.assemble([input, key]));
        setLastChar(undefined);
        break;
      }
      default: {
        setStreak({
          ...streak,
          [currentChar]: Math.max(streak[currentChar] - 1, 0),
        });
        break;
      }
    }

    if (
      isCompletedBlock(hangul.assemble([...hangul.disassemble(input), key]))
    ) {
      generateNextBlock();
      play();
      setInput("");
      setLastChar(undefined);

      let levelUp = false;
      let percents = [];

      for (let s in streak) {
        percents.push(Math.max(Math.min(streak[s] * 10, 100), 0));
      }

      if (sum(percents) >= 100 * Object.keys(streak).length) {
        levelUp = true;
      }

      if (levelUp) {
        setStreak({
          ...Object.fromEntries(
            levels[level + 1].map((obj) => {
              return [obj, 0];
            })
          ),
          ...streak,
        });
        setLevel(level + 1);
      }
    }
  };

  return <KeyboardEventHandler
    handleKeys={['all']}
    onKeyEvent={(key, e) => {
      if (e.repeat) return {};

      try {
        const element = document.getElementsByClassName("shake")[0];
        element.classList.remove("shake");
        void element.offsetWidth;
      } catch(err) {
        
      }

      handleInput(e);

      
    }} 
  />;
}

export default styled(Controller)`
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  color: transparent;
  z-index: 1000;
  cursor: default;
`;
