import React from 'react';

export default {
	'_(ㄱ)_(ㅑ)_ㄹ_3.svg': { 
		path: <path d="M12.656,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.4a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-1.2a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-2.56a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048A.147.147,0,0,1,3.152-3.1V-3.68a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.2a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H4.624q-.224,0-.28.064a.524.524,0,0,0-.056.3V.992a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅑ)_ㅂ_3.svg': { 
		path: <path d="M4.272-.88V.784a.43.43,0,0,0,.072.3.473.473,0,0,0,.3.064h6.176a.513.513,0,0,0,.3-.064q.08-.064.08-.3V-.88Zm.112,2.992a2.813,2.813,0,0,1-.632-.056.839.839,0,0,1-.36-.168.907.907,0,0,1-.176-.368A2.5,2.5,0,0,1,3.168.976v-4.4a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v1.616H11.2V-3.392a.228.228,0,0,1,.056-.168.185.185,0,0,1,.136-.056h.688a.213.213,0,0,1,.144.056.192.192,0,0,1,.064.152v4.5a1.024,1.024,0,0,1-.256.808,1.547,1.547,0,0,1-.96.216Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㄱ_3.svg': { 
		path: <path d="M11.12-2.7a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-1.408q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.672a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.576a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㄷ_3.svg': { 
		path: <path d="M12.1-3.1A.184.184,0,0,1,12.3-2.9v.528q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H12.3a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H3.9a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-1.968a1.212,1.212,0,0,1,.256-.9A1.464,1.464,0,0,1,3.9-3.1Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M11.04-4.3a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.344a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.432a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-3.024a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-4.3Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㅁ_3.svg': { 
		path: <path d="M11.1-3.1a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976V-1.968a1.212,1.212,0,0,1,.256-.9A1.464,1.464,0,0,1,3.9-3.1Zm-6.928.96a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.728a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㅇ_3.svg': { 
		path: <path d="M8.048-3.328A10.646,10.646,0,0,1,9.736-3.1a5.039,5.039,0,0,1,1.416.52,2.911,2.911,0,0,1,.968.856A2.087,2.087,0,0,1,12.48-.5,2.1,2.1,0,0,1,12.056.832a3.174,3.174,0,0,1-1.12.888,5.748,5.748,0,0,1-1.6.5,11.324,11.324,0,0,1-1.88.152,10.939,10.939,0,0,1-1.848-.152,5.634,5.634,0,0,1-1.576-.5,3.114,3.114,0,0,1-1.1-.888A2.122,2.122,0,0,1,2.512-.5a2.087,2.087,0,0,1,.36-1.232,2.89,2.89,0,0,1,.976-.856A5.165,5.165,0,0,1,5.264-3.1a10.463,10.463,0,0,1,1.68-.224ZM11.312-.48a1.149,1.149,0,0,0-.368-.864,2.861,2.861,0,0,0-.928-.576,5.62,5.62,0,0,0-1.24-.328,8.48,8.48,0,0,0-2.648,0,5.328,5.328,0,0,0-1.216.328,2.612,2.612,0,0,0-.888.576A1.189,1.189,0,0,0,3.68-.48a1.189,1.189,0,0,0,.344.864,2.522,2.522,0,0,0,.888.568,5.621,5.621,0,0,0,1.216.32,8.673,8.673,0,0,0,1.328.1,8.729,8.729,0,0,0,1.32-.1,6.1,6.1,0,0,0,1.248-.32,2.657,2.657,0,0,0,.928-.568A1.161,1.161,0,0,0,11.312-.48Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㅈ_3.svg': { 
		path: <path d="M10.416-3.456q2.4,0,.9,1.6a6.734,6.734,0,0,1-1,.792,13.358,13.358,0,0,1-1.464.84q.512.176,1.144.368t1.256.368q.624.176,1.192.328t.968.232q.208.048.144.224l-.224.528a.225.225,0,0,1-.288.128q-.464-.1-1.128-.28t-1.4-.4Q9.776,1.056,9.024.816T7.632.352q-.688.3-1.416.592T4.76,1.472q-.728.24-1.432.424T2,2.192a.264.264,0,0,1-.2-.008.289.289,0,0,1-.088-.168l-.144-.48a.172.172,0,0,1,.016-.16.19.19,0,0,1,.128-.1A18.876,18.876,0,0,0,3.968.712Q5.168.336,6.3-.128T8.424-1.1a11.563,11.563,0,0,0,1.608-.968q.224-.176.2-.3T10.016-2.5H2.944A.184.184,0,0,1,2.736-2.7v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.112q-.064.16-.3.128l-1.432-.288q-.792-.16-1.608-.336t-1.6-.384q-.32-.08-.624-.176A31.959,31.959,0,0,1,2.032,2.4q-.128.048-.184.008a.363.363,0,0,1-.1-.168L1.6,1.728a.19.19,0,0,1,.016-.168.2.2,0,0,1,.1-.1q1.168-.144,2.336-.4T6.336.48q1.088-.3,2.04-.656a13.239,13.239,0,0,0,1.64-.72q.224-.128.216-.2t-.28-.072H2.96q-.224,0-.224-.208v-.56q0-.192.224-.192h7.408a3.059,3.059,0,0,1,1.024.136.89.89,0,0,1,.48.328.454.454,0,0,1-.032.488,1.871,1.871,0,0,1-.56.536A13.21,13.21,0,0,1,9.072.512L9.2.544q.64.144,1.312.3.672.144,1.36.264t1.3.2q.224.032.128.224ZM9.552-3.968a.2.2,0,0,1,.224.224v.48q0,.256-.224.256H5.264q-.24,0-.24-.256v-.48q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅜ)_ㅋ_3.svg': { 
		path: <path d="M2.368-2.944a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.48a1.3,1.3,0,0,1,.984.272,1.44,1.44,0,0,1,.232.928v4.08a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.256H2.864q-.208,0-.208-.16V-.448a.184.184,0,0,1,.208-.208H11.2V-1.84q0-.224-.08-.28a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅠ)_ㄱ_3.svg': { 
		path: <path d="M2.368-2.368a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.509,1.509,0,0,1,.232.952v3.5a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-1.248q0-.224-.08-.28a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_(ㅠ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.112q-.064.16-.3.128l-1.432-.288q-.792-.16-1.608-.336t-1.6-.384q-.32-.08-.624-.176A31.959,31.959,0,0,1,2.032,2.4q-.128.048-.184.008a.363.363,0,0,1-.1-.168L1.6,1.728a.19.19,0,0,1,.016-.168.2.2,0,0,1,.1-.1q1.168-.144,2.336-.4T6.336.48q1.088-.3,2.04-.656a13.239,13.239,0,0,0,1.64-.72q.224-.128.216-.2t-.28-.072H2.96q-.224,0-.224-.208v-.56q0-.192.224-.192h7.408a3.059,3.059,0,0,1,1.024.136.89.89,0,0,1,.48.328.454.454,0,0,1-.032.488,1.871,1.871,0,0,1-.56.536A13.21,13.21,0,0,1,9.072.512L9.2.544q.64.144,1.312.3.672.144,1.36.264t1.3.2q.224.032.128.224ZM9.552-3.968a.2.2,0,0,1,.224.224v.48q0,.256-.224.256H5.264q-.24,0-.24-.256v-.48q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_hd_ㄹ_3.svg': { 
		path: <path d="M11.04-4.3a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.344a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.432a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-3.024a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-4.3Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_hd_ㅈ_3.svg': { 
		path: <path d="M13.312,1.824a.2.2,0,0,1-.288.112q-.608-.112-1.384-.28T10.048,1.28q-.816-.208-1.6-.416Q8,.752,7.632.624L7.616.64Q6.4,1.1,4.968,1.512a27.331,27.331,0,0,1-2.952.664q-.16.064-.216.024a.289.289,0,0,1-.088-.168l-.128-.48a.222.222,0,0,1,.008-.176.167.167,0,0,1,.12-.1A30.944,30.944,0,0,0,6.616.032a15.688,15.688,0,0,0,3.4-1.632q.24-.208.216-.32T10-2.032H2.944a.184.184,0,0,1-.208-.208V-2.8q0-.176.208-.176c8.3,0,8.795.139,8.944.416s-.053.661-.608,1.152A7.934,7.934,0,0,1,9.76-.368q-.368.208-.784.4.24.064.5.128.624.176,1.3.336T12.12.808q.68.152,1.272.264.208.048.144.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-3.168a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-4.3h8.224q.192,0,.192.224v.48q0,.208-.192.208H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V-1.6h8.08a.184.184,0,0,1,.208.208V-.88q0,.208-.192.208h-8.1V.8a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h8.08q.192,0,.192.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅑ_(ㄹ)_3.svg': { 
		path: <path d="M12.32-4.8a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.488a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.272h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.68h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅑ_(ㅂ)_3.svg': { 
		path: <path d="M14.736-8.928a.2.2,0,0,1-.08.136.229.229,0,0,1-.144.072H12.288v1.744h2.224a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.84q0,.208-.224.208h-.672q-.192,0-.192-.208v-8.08q0-.208.192-.208h.672q.224,0,.224.208V-9.68h2.224a.26.26,0,0,1,.144.056.192.192,0,0,1,.08.168Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅓ_c_3.svg': { 
		path: <path d="M11.776-8.432v-3.856A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.44a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.472H8.512q-.208,0-.208-.192V-8.24q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅕ_2.svg': { 
		path: <path d="M8.176-8.5h3.6v-3.792A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-5.84H7.648q-.208,0-.208-.192v-.56q0-.192.208-.192h4.128V-7.552H7.9A9.54,9.54,0,0,1,8.176-8.5Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅕ_c_3.svg': { 
		path: <path d="M8.336-9.824h3.44v-2.464A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.44a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-1.2H7.7q-.208,0-.208-.192V-6.8q0-.192.208-.192h4.08V-8.88H7.968Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅗ_c_3.svg': { 
		path: <path d="M7.3-8.7a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.688H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.88.19.19,0,0,1,.48-5.024v-.544A.182.182,0,0,1,.552-5.72.266.266,0,0,1,.72-5.776H6.432V-8.464a.266.266,0,0,1,.056-.168A.182.182,0,0,1,6.64-8.7Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅛ_2.svg': { 
		path: <path d="M14.32-1.248a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.544V-6.16A.266.266,0,0,1,4.6-6.328.182.182,0,0,1,4.752-6.4H5.44a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v4.912H8.064V-6.16a.266.266,0,0,1,.056-.168A.182.182,0,0,1,8.272-6.4H8.96a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v4.912Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅛ_c_3.svg': { 
		path: <path d="M8.96-8.752A.19.19,0,0,1,9.1-8.68a.245.245,0,0,1,.064.168v2.544H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.216V-5.76a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.968H4.544V-8.512A.266.266,0,0,1,4.6-8.68a.182.182,0,0,1,.152-.072H5.44a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.544H8.064V-8.512A.266.266,0,0,1,8.12-8.68a.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㄱ)_3.svg': { 
		path: <path d="M14.32-6.752a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-6a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2.7h-1.1V-5.792H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6v-.544A.182.182,0,0,1,.552-6.7.266.266,0,0,1,.72-6.752Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㄴ)_3.svg': { 
		path: <path d="M14.32-5.968a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.176V-1.52a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.28a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-5.008H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.216V-5.76a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.968Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-7.472a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-4.3h-1.1V-6.512H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6.72v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-7.472Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅂ)_3.svg': { 
		path: <path d="M14.32-7.024a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-3.44a.266.266,0,0,1-.056.168A.182.182,0,0,1,7.84-3.2H7.152a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-6.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6.272v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-7.024Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M14.32-6.224a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.736l-1.1-.112V-5.264H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.472v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅇ)_3.svg': { 
		path: <path d="M14.32-6.912a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.624h-1.1V-5.952H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6.16V-6.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.912Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.32-7.1a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.688h-1.1V-6.144H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6.352V-6.9a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-7.1Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-7.28a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H8.016V-3.97h-1.1V-6.336H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-7.28Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅋ)_3.svg': { 
		path: <path d="M14.288-6.912a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544q0,.048-.088.12a.259.259,0,0,1-.152.072H8.016V-3.14h-1.1V-5.952H.7a.241.241,0,0,1-.16-.072q-.08-.072-.08-.12v-.544a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-6.912Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅍ)_3.svg': { 
		path: <path d="M14.288-6.912a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544q0,.048-.088.12a.259.259,0,0,1-.152.072H8.016V-3.28a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.12a.2.2,0,0,1-.136-.072.23.23,0,0,1-.072-.168V-5.952H.7a.241.241,0,0,1-.16-.072q-.08-.072-.08-.12v-.544a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-6.912Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-7.28a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H8.016V-4.26h-1.1V-6.336H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-7.28Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_2.svg': { 
		path: <path d="M14.32-5.44a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.032V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.136a.19.19,0,0,1-.144-.072A.245.245,0,0,1,6.928,1.9V-4.48H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.688v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.44Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅜ_c_3.svg': { 
		path: <path d="M14.32-6.912a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-3.1h-1.1V-5.952H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6.16V-6.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.912Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㄱ)_3.svg': { 
		path: <path d="M9.552-3.344a.2.2,0,0,1-.136-.08A.258.258,0,0,1,9.344-3.6V-5.888H5.552V-3.6a.3.3,0,0,1-.056.176.192.192,0,0,1-.168.08H4.64a.2.2,0,0,1-.136-.08A.258.258,0,0,1,4.432-3.6V-5.888H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-6.1V-6.64a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.848H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-6.1a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H10.464V-3.6a.3.3,0,0,1-.056.176.192.192,0,0,1-.168.08Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㄴ)_3.svg': { 
		path: <path d="M14.32-6.1a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.528V-1.36a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.632a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-5.136H6.192V-1.36a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H5.3a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-5.136H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-7.536a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.448V-4.3h-1.1V-6.576H5.6V-4.3H4.5V-6.576H.72A.245.245,0,0,1,.552-6.64.19.19,0,0,1,.48-6.784v-.544A.182.182,0,0,1,.552-7.48.266.266,0,0,1,.72-7.536Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㅈ)_3.svg': { 
		path: <path d="M9.344-2.98V-5.936H5.552V-2.98H4.432V-5.936H.7a.241.241,0,0,1-.16-.072.2.2,0,0,1-.08-.136v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.193.193,0,0,1-.088.136.259.259,0,0,1-.152.072H10.464V-2.98Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㅊ)_3.svg': { 
		path: <path d="M10.256-3.872a.214.214,0,0,1-.152-.072.23.23,0,0,1-.072-.168v-2.4H4.96v2.4a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072H4.048A.19.19,0,0,1,3.9-3.944a.245.245,0,0,1-.064-.168v-2.4H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-7.472H14.288a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H11.152v2.4a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㅌ)_3.svg': { 
		path: <path d="M9.312-4.3V-6.576H5.584V-4.3H4.48V-6.576H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-7.536H14.288a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.416V-4.3Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_(ㅎ)_3.svg': { 
		path: <path d="M10.256-3.872a.214.214,0,0,1-.152-.072.23.23,0,0,1-.072-.168v-2.4H4.96v2.4a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072H4.048A.19.19,0,0,1,3.9-3.944a.245.245,0,0,1-.064-.168v-2.4H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-7.472H14.288a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H11.152v2.4a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_2.svg': { 
		path: <path d="M14.32-5.536a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.584a.19.19,0,0,1-.144-.072A.245.245,0,0,1,9.376,1.9v-6.48H5.52V1.9a.126.126,0,0,1-.072.128.372.372,0,0,0-.136.112H4.624a.19.19,0,0,1-.144-.072A.245.245,0,0,1,4.416,1.9v-6.48H.72A.245.245,0,0,1,.552-4.64.19.19,0,0,1,.48-4.784v-.544A.182.182,0,0,1,.552-5.48.266.266,0,0,1,.72-5.536Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅠ_c_3.svg': { 
		path: <path d="M9.552-3.344a.2.2,0,0,1-.136-.08A.258.258,0,0,1,9.344-3.6V-5.888H5.552V-3.6a.3.3,0,0,1-.056.176.192.192,0,0,1-.168.08H4.64a.2.2,0,0,1-.136-.08A.258.258,0,0,1,4.432-3.6V-5.888H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-6.1V-6.64a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.848H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-6.1a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H10.464V-3.6a.3.3,0,0,1-.056.176.192.192,0,0,1-.168.08Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㄱ)_3.svg': { 
		path: <path d="M14.32-6.5a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.6.19.19,0,0,1,.48-5.744v-.544A.182.182,0,0,1,.552-6.44.266.266,0,0,1,.72-6.5Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㄴ)_3.svg': { 
		path: <path d="M14.32-5.584a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.832v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.584Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㄷ)_3.svg': { 
		path: <path d="M14.32-6.56a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.808v-.544A.182.182,0,0,1,.552-6.5.266.266,0,0,1,.72-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-7.168a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-6.416V-6.96a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-7.168Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅁ)_3.svg': { 
		path: <path d="M14.32-6.56a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.808v-.544A.182.182,0,0,1,.552-6.5.266.266,0,0,1,.72-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅂ)_3.svg': { 
		path: <path d="M14.32-6.64a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.888v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.64Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅅ)_3.svg': { 
		path: <path d="M14.32-6.112a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.36V-5.9a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.112Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅇ)_3.svg': { 
		path: <path d="M14.32-6.56a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.808v-.544A.182.182,0,0,1,.552-6.5.266.266,0,0,1,.72-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-6.56a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56A.176.176,0,0,1,.544-6.5.278.278,0,0,1,.7-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅊ)_3.svg': { 
		path: <path d="M14.32-6.56a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.808v-.544A.182.182,0,0,1,.552-6.5.266.266,0,0,1,.72-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅋ)_3.svg': { 
		path: <path d="M14.288-6.56a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56A.176.176,0,0,1,.544-6.5.278.278,0,0,1,.7-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅌ)_3.svg': { 
		path: <path d="M14.288-6.784a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.784Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅍ)_3.svg': { 
		path: <path d="M14.288-6.56a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56A.176.176,0,0,1,.544-6.5.278.278,0,0,1,.7-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄱ)_ㅡ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-6.784a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.784Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_(ㅕ)_ㄹ_3.svg': { 
		path: <path d="M13.216,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.96a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-1.2a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-2.56a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048A.147.147,0,0,1,3.712-3.1V-3.68a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.2a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H5.184q-.224,0-.28.064a.524.524,0,0,0-.056.3V.992a.473.473,0,0,0,.064.3A.4.4,0,0,0,5.2,1.36h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_(ㅗ)_ㅅ_3.svg': { 
		path: <path d="M8.064-2.848q-.016.112-.032.216a1.764,1.764,0,0,1-.048.216,2.691,2.691,0,0,0,.336.7A5.951,5.951,0,0,0,10.224.1,10.439,10.439,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A11.554,11.554,0,0,1,9.528.808a6.475,6.475,0,0,1-2.04-1.976A6.58,6.58,0,0,1,5.48.88a10.5,10.5,0,0,1-3.1,1.408q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128,8.484,8.484,0,0,0,1.976-.7A7.4,7.4,0,0,0,5.5-.416,5.352,5.352,0,0,0,6.552-1.632,2.679,2.679,0,0,0,6.976-2.9q.016-.192.192-.192l.768.016Q8.08-3.072,8.064-2.848Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅑ_(ㄹ)_3.svg': { 
		path: <path d="M12.32-4.8a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.488a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.272h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.68h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅑ_(ㅂ)_3.svg': { 
		path: <path d="M12.32-4.8a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.488a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.272h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.68h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅑ_(ㅌ)_3.svg': { 
		path: <path d="M12.32-4.8a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.488a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.272h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.68h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-9.7v-2.592A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.96a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.736H7.6q-.208,0-.208-.192V-9.5q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-9.7v-2.592A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.96a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.736H7.6q-.208,0-.208-.192V-9.5q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.96a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-8.72H7.584q-.224,0-.224-.192v-.576q0-.192.224-.192h4.16v-2.592a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅕ_(ㄴ)_3.svg': { 
		path: <path d="M11.776-10.512v-1.776A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v10.32a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.816h-4q-.208,0-.208-.192v-.56q0-.192.208-.192h4V-9.568h-4q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-10.976v-1.312A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.744h-4q-.208,0-.208-.192V-8.5q0-.192.208-.192h4v-1.344h-4q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-10.976v-1.312A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.744h-4q-.208,0-.208-.192V-8.5q0-.192.208-.192h4v-1.344h-4q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M11.776-10.976v-1.312A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.744h-4q-.208,0-.208-.192V-8.5q0-.192.208-.192h4v-1.344h-4q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅕ_2.svg': { 
		path: <path d="M11.776-9.168v-3.12A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-5.248h-4.3q-.208,0-.208-.192V-6q0-.192.208-.192h4.3V-8.224h-4.3q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅕ_c_3.svg': { 
		path: <path d="M12.832-4.3a.2.2,0,0,1-.224.224h-.64a.2.2,0,0,1-.224-.224v-3.04H7.76q-.208,0-.208-.176V-8.1q0-.176.208-.176h3.984v-1.5H7.76q-.208,0-.208-.192v-.56q0-.176.208-.176h3.984v-1.552a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M8.048-7.92V-6.3H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.552V-6.1a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.3H6.944V-7.92Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M8.048-7.92V-6.3H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.552V-6.1a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.3H6.944V-7.92Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M8.048-7.92V-6.3H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.552V-6.1a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.3H6.944V-7.92Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M8.048-7.92V-6.3H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.552V-6.1a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.3H6.944V-7.92Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅗ_c_3.svg': { 
		path: <path d="M8.048-7.168v1.984H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.432v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.184H6.944V-7.168Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M10.208-7.968v1.76H14.32a.278.278,0,0,1,.16.056A.176.176,0,0,1,14.56-6v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.456V-6a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.208H4.848v-1.76h1.1v1.76H9.1v-1.76Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.92H5.92v1.632H9.072V-7.92h1.1v1.632h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.92H5.92v1.632H9.072V-7.92h1.1v1.632h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.92H5.92v1.632H9.072V-7.92h1.1v1.632h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅛ_2.svg': { 
		path: <path d="M10.208-5.024v3.776H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.848V-5.024h1.1v3.776H9.1V-5.024Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅛ_c_3.svg': { 
		path: <path d="M4.816-5.04V-7.15H5.9v2.11H9.088V-7.15h1.088v2.11h4.112A.379.379,0,0,1,14.44-5a.151.151,0,0,1,.088.152V-4.3a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.206.206,0,0,1,.464-4.3v-.544A.154.154,0,0,1,.544-5,.355.355,0,0,1,.7-5.04Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅡ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅡ_(ㅊ)_3.svg': { 
		path: <path d="M14.32-6.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅡ_(ㅌ)_3.svg': { 
		path: <path d="M14.32-6.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅡ_(ㅎ)_3.svg': { 
		path: <path d="M14.32-6.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32Z" />, 
		strokes: [
		] 
	},
	'_(ㄴ)_ㅡ_c_3.svg': { 
		path: <path d="M14.32-5.328a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.576V-5.12a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.328Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M12.864-4.976a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224v-3.28H7.584q-.224,0-.224-.192v-.576q0-.192.224-.192h4.16v-3.056a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M12.864-4.976a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224v-3.28H7.584q-.224,0-.224-.192v-.576q0-.192.224-.192h4.16v-3.056a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅓ_(ㅊ)_3.svg': { 
		path: <path d="M12.864-4.976a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224v-3.28H7.584q-.224,0-.224-.192v-.576q0-.192.224-.192h4.16v-3.056a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.976a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224v-3.28H7.584q-.224,0-.224-.192v-.576q0-.192.224-.192h4.16v-3.056a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅓ_(ㅎ)_3.svg': { 
		path: <path d="M12.864-4.976a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224v-3.28H7.584q-.224,0-.224-.192v-.576q0-.192.224-.192h4.16v-3.056a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅕ_2.svg': { 
		path: <path d="M11.776-8.864v-3.424A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.912H8.016q-.208,0-.208-.192v-.56q0-.192.208-.192h3.76V-7.92H8.016q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅕ_c_3.svg': { 
		path: <path d="M12.832-4.4q0,.208-.224.208h-.64q-.224,0-.224-.208V-6.752H8.16a.17.17,0,0,1-.192-.192V-7.5A.17.17,0,0,1,8.16-7.7h3.584V-9.152H8.16q-.192,0-.192-.176V-9.9q0-.176.192-.176h3.584v-2.192q0-.208.224-.208h.64q.224,0,.224.208Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M8.064-7.824v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32H6.96v-1.5Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M8.064-7.824v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32H6.96v-1.5Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M8.064-7.824v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32H6.96v-1.5Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M8.064-7.824v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32H6.96v-1.5Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅗ_2.svg': { 
		path: <path d="M8.032-4.736v3.488H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.928V-4.736Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅗ_c_3.svg': { 
		path: <path d="M14.32-4.976a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976H6.944V-7.04h1.1v2.064Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.82H5.92v1.532H9.072V-7.82h1.1v1.532h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.82H5.92v1.532H9.072V-7.82h1.1v1.532h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.82H5.92v1.532H9.072V-7.82h1.1v1.532h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.848-6.288V-7.82H5.92v1.532H9.072V-7.82h1.1v1.532h4.112a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.08a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅛ_c_3.svg': { 
		path: <path d="M4.816-5.04V-7.02H5.9v1.98H9.088V-7.02h1.088v1.98h4.112A.379.379,0,0,1,14.44-5a.151.151,0,0,1,.088.152V-4.3a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.206.206,0,0,1,.464-4.3v-.544A.154.154,0,0,1,.544-5,.355.355,0,0,1,.7-5.04Z" />, 
		strokes: [
		] 
	},
	'_(ㄷ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-3.664V-5.232H.72A.245.245,0,0,1,.552-5.3.19.19,0,0,1,.48-5.44v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.192h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.568Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_(ㅛ)_ㅊ_3.svg': { 
		path: <path d="M13.2,2.128q-.064.16-.3.128-.624-.112-1.416-.272T9.872,1.632q-.832-.176-1.616-.384-.32-.08-.624-.144a36.118,36.118,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L1.7,1.744a.228.228,0,0,1,0-.168.147.147,0,0,1,.112-.1q1.152-.144,2.328-.408T6.416.528Q7.5.192,8.456-.152A11.97,11.97,0,0,0,10.112-.88q.224-.112.216-.192t-.3-.08H3.04a.184.184,0,0,1-.208-.208v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.432.432,0,0,1-.04.48,2.188,2.188,0,0,1-.536.528A13.844,13.844,0,0,1,9.152.528L9.3.56q.624.144,1.3.3.672.128,1.36.248t1.28.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.424a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M13.072,2.064a.322.322,0,0,1-.088.128q-.056.048-.216,0A12.178,12.178,0,0,1,9.464.824,6.1,6.1,0,0,1,7.52-1.008,5.847,5.847,0,0,1,5.616.9,11.19,11.19,0,0,1,2.384,2.288q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A10.138,10.138,0,0,0,5.5-.256,3.423,3.423,0,0,0,6.944-2.3h1.1a1.686,1.686,0,0,0,.336.816A5.512,5.512,0,0,0,10.248.192a11.137,11.137,0,0,0,2.984,1.072.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_(ㅜ)_ㅇ_3.svg': { 
		path: <path d="M8.048-2.752a12.887,12.887,0,0,1,1.488.16,5.956,5.956,0,0,1,1.44.416,3.052,3.052,0,0,1,1.08.768,1.746,1.746,0,0,1,.424,1.2,1.77,1.77,0,0,1-.512,1.32A3.516,3.516,0,0,1,10.7,1.9a6.882,6.882,0,0,1-1.632.376,14.527,14.527,0,0,1-1.576.1q-.464,0-1.016-.04a9.774,9.774,0,0,1-1.1-.144A6.94,6.94,0,0,1,4.3,1.9a3.681,3.681,0,0,1-.912-.472,2.306,2.306,0,0,1-.64-.688,1.8,1.8,0,0,1-.24-.944,1.727,1.727,0,0,1,.432-1.2,3.134,3.134,0,0,1,1.088-.768,5.962,5.962,0,0,1,1.432-.416,12.635,12.635,0,0,1,1.48-.16Zm3.264,2.56a.964.964,0,0,0-.432-.832A3.327,3.327,0,0,0,9.832-1.5a6.663,6.663,0,0,0-1.264-.216Q7.92-1.76,7.488-1.76q-.448,0-1.1.048A6.359,6.359,0,0,0,5.144-1.5a3.345,3.345,0,0,0-1.032.472.964.964,0,0,0-.432.832.97.97,0,0,0,.432.824A3.115,3.115,0,0,0,5.144,1.1a6.776,6.776,0,0,0,1.248.208q.648.048,1.1.048.432,0,1.08-.048A7.105,7.105,0,0,0,9.832,1.1,3.1,3.1,0,0,0,10.88.632.97.97,0,0,0,11.312-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_(ㅠ)_ㄱ_3.svg': { 
		path: <path d="M2.368-2a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.5a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176A.2.2,0,0,1,12.1,2.4H11.44a.2.2,0,0,1-.224-.224V-.9q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㄱ_3.svg': { 
		path: <path d="M2.368-2.016a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.5a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176A.2.2,0,0,1,12.1,2.4H11.44a.2.2,0,0,1-.224-.224V-.912q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㄷ_3.svg': { 
		path: <path d="M12.464,1.888q0,.224-.208.224H3.872a1.431,1.431,0,0,1-.944-.224,1.235,1.235,0,0,1-.24-.9v-2a1.235,1.235,0,0,1,.24-.9,1.431,1.431,0,0,1,.944-.224h8.176a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.16a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V.752a.414.414,0,0,0,.088.288.412.412,0,0,0,.312.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅁ_3.svg': { 
		path: <path d="M2.688-1.28a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.04a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A35.824,35.824,0,0,1,7.92.96a28.553,28.553,0,0,1-5.9,1.232q-.16.048-.216,0a.323.323,0,0,1-.088-.16l-.1-.48a.226.226,0,0,1,.008-.176.13.13,0,0,1,.12-.1A32.028,32.028,0,0,0,10.016-.768q.368-.224.344-.328T10-1.2H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.6A18.111,18.111,0,0,1,9.328.448q1.936.384,4,.608.208.08.128.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅊ_3.svg': { 
		path: <path d="M13.12,2.128q-.064.16-.3.128-.64-.112-1.432-.272T9.776,1.632q-.832-.176-1.616-.384-.32-.08-.608-.144a36.67,36.67,0,0,1-5.52,1.312q-.128.048-.184.008a.467.467,0,0,1-.12-.168L1.6,1.744a.19.19,0,0,1,.016-.168.2.2,0,0,1,.1-.1q1.152-.144,2.328-.408t2.3-.536q1.088-.336,2.04-.68a11.179,11.179,0,0,0,1.64-.728q.224-.112.216-.192t-.28-.08H2.96q-.224,0-.224-.208v-.576q0-.192.224-.192h7.408a2.762,2.762,0,0,1,1.024.152.915.915,0,0,1,.48.36.432.432,0,0,1-.04.48,2.11,2.11,0,0,1-.552.528A13.844,13.844,0,0,1,9.056.528L9.2.56q.64.144,1.312.3.672.128,1.36.248t1.3.216q.224.016.128.224Zm-3.664-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅋ_3.svg': { 
		path: <path d="M2.368-2.016a.172.172,0,0,1,.08-.16.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.16a.2.2,0,0,1-.224.224h-.656A.2.2,0,0,1,11.2,2.16V.736H2.864q-.208,0-.208-.176V.016a.184.184,0,0,1,.208-.208H11.2v-.72q0-.24-.08-.3a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-1.9a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-3.04h8.224q.192,0,.192.208v.48q0,.224-.192.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v.768h8.08q.208,0,.208.192v.544A.17.17,0,0,1,11.872,0h-8.1V.784a.533.533,0,0,0,.088.344.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅍ_3.svg': { 
		path: <path d="M12.5-1.04q0,.208-.24.208H10.464v2h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256A.226.226,0,0,1,2.1,2.048a.205.205,0,0,1-.072-.16v-.5A.2.2,0,0,1,2.1,1.224a.244.244,0,0,1,.152-.056H4.464v-2H2.576a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.208ZM5.552,1.168H9.376v-2H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hd_ㅎ_3.svg': { 
		path: <path d="M10.016,1.12A.5.5,0,0,0,9.792.672,2.394,2.394,0,0,0,9.2.416a4.432,4.432,0,0,0-.832-.16,8.917,8.917,0,0,0-1.848,0A4.105,4.105,0,0,0,5.7.416,1.446,1.446,0,0,0,5.08.648a.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.01,2.01,0,0,0,.616.28,3.91,3.91,0,0,0,.824.12q.472.024.92.024.464,0,.928-.032A4.549,4.549,0,0,0,9.2,1.792a2.044,2.044,0,0,0,.592-.272A.477.477,0,0,0,10.016,1.12Zm1.1,0a1,1,0,0,1-.32.752,2.86,2.86,0,0,1-.832.528,4.577,4.577,0,0,1-1.176.288,11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,4.96,2.4,2.691,2.691,0,0,1,4.1,1.864a1,1,0,0,1-.32-.744A1.066,1.066,0,0,1,4.1.336,2.43,2.43,0,0,1,4.96-.192,4.122,4.122,0,0,1,6.1-.48,11.159,11.159,0,0,1,7.44-.56a10.158,10.158,0,0,1,1.336.088,5.67,5.67,0,0,1,1.192.28A2.765,2.765,0,0,1,10.8.344,1.04,1.04,0,0,1,11.12,1.12Zm1.472-2.48q0,.208-.256.208H2.624a.3.3,0,0,1-.152-.056.172.172,0,0,1-.088-.152v-.448a.206.206,0,0,1,.088-.176.275.275,0,0,1,.152-.064h9.712q.256,0,.256.224ZM9.808-3.024q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.163.163,0,0,1-.08-.136v-.48a.217.217,0,0,1,.064-.16A.217.217,0,0,1,5.2-3.7H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_hu_ㅊ_3.svg': { 
		path: <path d="M13.2,2.128q-.064.16-.3.128-.624-.112-1.416-.272T9.872,1.632q-.832-.176-1.616-.384-.32-.08-.624-.144a36.118,36.118,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L1.7,1.744a.228.228,0,0,1,0-.168.147.147,0,0,1,.112-.1q1.152-.144,2.328-.408T6.416.528Q7.5.192,8.456-.152A11.97,11.97,0,0,0,10.112-.88q.224-.112.216-.192t-.3-.08H3.04a.184.184,0,0,1-.208-.208v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.432.432,0,0,1-.04.48,2.188,2.188,0,0,1-.536.528A13.844,13.844,0,0,1,9.152.528L9.3.56q.624.144,1.3.3.672.128,1.36.248t1.28.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.424a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅑ_(ㄹ)_3.svg': { 
		path: <path d="M14.736-9.424a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.584h2.224a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152V-6.9a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.552a.2.2,0,0,1-.224.224h-.672q-.192,0-.192-.224v-7.136q0-.224.192-.224h.672a.2.2,0,0,1,.224.224v2.112h2.224a.26.26,0,0,1,.144.056.192.192,0,0,1,.08.168Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅑ_(ㅂ)_3.svg': { 
		path: <path d="M14.736-9.424a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.584h2.224a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152V-6.9a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.552a.2.2,0,0,1-.224.224h-.672q-.192,0-.192-.224v-7.136q0-.224.192-.224h.672a.2.2,0,0,1,.224.224v2.112h2.224a.26.26,0,0,1,.144.056.192.192,0,0,1,.08.168Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅑ_(ㅌ)_3.svg': { 
		path: <path d="M14.736-9.424a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.584h2.224a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152V-6.9a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.552a.2.2,0,0,1-.224.224h-.672q-.192,0-.192-.224v-7.136q0-.224.192-.224h.672a.2.2,0,0,1,.224.224v2.112h2.224a.26.26,0,0,1,.144.056.192.192,0,0,1,.08.168Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅓ_c_3.svg': { 
		path: <path d="M11.776-8.832v-3.456A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.824a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.872H9.04q-.208,0-.208-.192V-8.64q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-10.576v-1.712A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.632a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.072h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192h2.8V-9.632h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-10.576v-1.712A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.632a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.072h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192h2.8V-9.632h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㅊ)_3.svg': { 
		path: <path d="M12.864-4.816q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-7.072H8.96q-.208,0-.208-.176v-.56Q8.752-8,8.96-8h2.784V-9.616H8.96q-.208,0-.208-.176v-.56a.184.184,0,0,1,.208-.208h2.784v-1.7a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㅋ)_3.svg': { 
		path: <path d="M12.864-4.192q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-6.576H8.96q-.208,0-.208-.192v-.576q0-.176.208-.176h2.784V-9.2H8.96q-.208,0-.208-.176V-9.92a.184.184,0,0,1,.208-.208h2.784v-2.128a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.816q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-7.072H8.96q-.208,0-.208-.176v-.56Q8.752-8,8.96-8h2.784V-9.616H8.96q-.208,0-.208-.176v-.56a.184.184,0,0,1,.208-.208h2.784v-1.7a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㅍ)_3.svg': { 
		path: <path d="M12.864-4.192q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-6.576H8.96q-.208,0-.208-.192v-.576q0-.176.208-.176h2.784V-9.2H8.96q-.208,0-.208-.176V-9.92a.184.184,0,0,1,.208-.208h2.784v-2.128a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_(ㅎ)_3.svg': { 
		path: <path d="M12.864-4.816q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-7.072H8.96q-.208,0-.208-.176v-.56Q8.752-8,8.96-8h2.784V-9.616H8.96q-.208,0-.208-.176v-.56a.184.184,0,0,1,.208-.208h2.784v-1.7a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_2.svg': { 
		path: <path d="M11.776-8.576v-3.712A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-6.1H8.816q-.208,0-.208-.192v-.56q0-.192.208-.192h2.96V-7.632H8.816q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅕ_c_3.svg': { 
		path: <path d="M11.776-10.16v-2.128A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.336a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.544h-2.8q-.208,0-.208-.192V-7.3q0-.192.208-.192h2.8V-9.216h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M8.016-7.008V-5.7h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.8.19.19,0,0,1,.48-4.944v-.544A.182.182,0,0,1,.552-5.64.266.266,0,0,1,.72-5.7H6.912V-7.008Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M8.016-7.008V-5.7h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.8.19.19,0,0,1,.48-4.944v-.544A.182.182,0,0,1,.552-5.64.266.266,0,0,1,.72-5.7H6.912V-7.008Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M8.016-7.008V-5.7h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.8.19.19,0,0,1,.48-4.944v-.544A.182.182,0,0,1,.552-5.64.266.266,0,0,1,.72-5.7H6.912V-7.008Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M8.016-7.008V-5.7h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.8.19.19,0,0,1,.48-4.944v-.544A.182.182,0,0,1,.552-5.64.266.266,0,0,1,.72-5.7H6.912V-7.008Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅗ_2.svg': { 
		path: <path d="M6.928-1.248V-4.032h1.1v2.784H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅗ_c_3.svg': { 
		path: <path d="M8.016-6.24v1.488h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4v-.544A.182.182,0,0,1,.552-4.7.266.266,0,0,1,.72-4.752H6.912V-6.24Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M10.24-7.04v1.408h4.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.88v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.632h4.1V-7.04H5.9v1.408H9.152V-7.04Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M10.24-7.04v1.408h4.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.88v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.632h4.1V-7.04H5.9v1.408H9.152V-7.04Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M10.24-7.04v1.408h4.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.88v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.632h4.1V-7.04H5.9v1.408H9.152V-7.04Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M10.24-7.04v1.408h4.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.88v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.632h4.1V-7.04H5.9v1.408H9.152V-7.04Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅛ_2.svg': { 
		path: <path d="M14.32-1.248a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.7V-4.08H5.792v2.832H9.264V-4.08h1.088v2.832Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅛ_c_3.svg': { 
		path: <path d="M4.8-4.752V-6.22H5.888v1.468H9.12V-6.22h1.1v1.468h4.064a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168V-4a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.206.206,0,0,1,.464-4v-.528A.192.192,0,0,1,.544-4.7.278.278,0,0,1,.7-4.752Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㄱ)_3.svg': { 
		path: <path d="M6.944-2.208V-3.936H.72A.245.245,0,0,1,.552-4,.19.19,0,0,1,.48-4.144v-.544A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544A.183.183,0,0,1,14.48-4a.256.256,0,0,1-.16.064H8.048v1.728Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㄷ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016V-2.13h-1.1V-3.936H.7A.256.256,0,0,1,.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-3.472V-4.9H.72A.245.245,0,0,1,.552-4.96.19.19,0,0,1,.48-5.1v-.544A.182.182,0,0,1,.552-5.8.266.266,0,0,1,.72-5.856h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.1a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.424Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M6.944-2.3V-4.016H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2.3Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅇ)_3.svg': { 
		path: <path d="M14.32-5.28a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.568h-1.1V-4.32H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.528v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.28Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016V-2.16h-1.1V-3.936H.7A.256.256,0,0,1,.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-5.856a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-5.1a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H8.016v1.01h-1.1V-4.88H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.544A.176.176,0,0,1,.544-5.8.278.278,0,0,1,.7-5.856Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅋ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016v1.712h-1.1V-3.936H.7A.256.256,0,0,1,.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M14.288-5.872a.275.275,0,0,1,.152.064.192.192,0,0,1,.088.16V-5.1a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016v1.488a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.12a.2.2,0,0,1-.136-.072.23.23,0,0,1-.072-.168V-4.9H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-5.1v-.544a.2.2,0,0,1,.08-.16A.256.256,0,0,1,.7-5.872Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅍ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016v1.712a.3.3,0,0,1-.056.176.176.176,0,0,1-.152.08H7.12a.2.2,0,0,1-.136-.08.258.258,0,0,1-.072-.176V-3.936H.7A.256.256,0,0,1,.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-5.856a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-5.1a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H8.016V-3.7h-1.1V-4.88H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.544A.176.176,0,0,1,.544-5.8.278.278,0,0,1,.7-5.856Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅜ_c_3.svg': { 
		path: <path d="M7.152-2.48a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.384H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.592v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.344h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2.72a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅠ_(ㄱ)_3.svg': { 
		path: <path d="M4.464-2.192v-1.76H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48v1.76Zm1.1,0H9.376v-1.76H5.568Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅠ_(ㅂ)_3.svg': { 
		path: <path d="M4.816-2.56a.19.19,0,0,1-.144-.072A.245.245,0,0,1,4.608-2.8V-4.384H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.592v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.344h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.336V-2.8a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.44A.19.19,0,0,1,9.3-2.632.245.245,0,0,1,9.232-2.8V-4.384H5.712V-2.8a.266.266,0,0,1-.056.168A.182.182,0,0,1,5.5-2.56Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅠ_(ㅅ)_3.svg': { 
		path: <path d="M4.272-1.632A.19.19,0,0,1,4.128-1.7a.245.245,0,0,1-.064-.168V-4.016H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.88v2.144a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.984A.19.19,0,0,1,9.84-1.7a.245.245,0,0,1-.064-.168V-4.016H5.168v2.144a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅠ_(ㅋ)_3.svg': { 
		path: <path d="M9.344-2.22V-3.952H5.552V-2.22H4.432V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.22Z" />, 
		strokes: [
		] 
	},
	'_(ㄹ)_ㅠ_(ㅍ)_3.svg': { 
		path: <path d="M9.552-2.256a.2.2,0,0,1-.136-.072A.23.23,0,0,1,9.344-2.5V-3.952H5.552V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072H4.64A.2.2,0,0,1,4.5-2.328.23.23,0,0,1,4.432-2.5V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_hd_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.48,1.92q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-2.528a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.24a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.088h8.1q.208,0,.208.192v.544A.17.17,0,0,1,11.9-.32H3.792V.8a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅓ_c_3.svg': { 
		path: <path d="M8.608-9.072h3.168v-3.216A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.44a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.112H8.608Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M8.608-10.336h3.168v-1.952A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.752H8.608V-7.7h3.168v-1.7H8.608Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M8.608-10.336h3.168v-1.952A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.752H8.608V-7.7h3.168v-1.7H8.608Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M8.608-10.336h3.168v-1.952A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.752H8.608V-7.7h3.168v-1.7H8.608Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅕ_(ㅎ)_3.svg': { 
		path: <path d="M8.608-10.336h3.168v-1.952A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.312a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.752H8.608V-7.7h3.168v-1.7H8.608Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅕ_2.svg': { 
		path: <path d="M8.592-9.04h3.184v-3.248A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.112H8.592v-.944h3.184V-8.1H8.592Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅕ_c_3.svg': { 
		path: <path d="M12.832-4.24a.2.2,0,0,1-.224.224h-.64a.2.2,0,0,1-.224-.224V-6.256H8.59V-7.2h3.154V-9.072H8.59v-.944h3.154v-2.256a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M8.064-7.52v1.472H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.3a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.3V-5.84a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.048H6.96V-7.52Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.5H8.032v1.452h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.5H8.032v1.452h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.5H8.032v1.452h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅗ_2.svg': { 
		path: <path d="M8.032-4.736v3.488H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.928V-4.736Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅗ_c_3.svg': { 
		path: <path d="M8.048-7.04v2.064H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976H6.944V-7.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.736-6V-7.5H5.808V-6H9.152V-7.5H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.736-6V-7.5H5.808V-6H9.152V-7.5H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.736-6V-7.5H5.808V-6H9.152V-7.5H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-6V-7.5H5.808V-6H9.152V-7.5H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅛ_2.svg': { 
		path: <path d="M10.272-4.736v3.488H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.688V-4.736H5.776v3.488H9.184V-4.736Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅛ_c_3.svg': { 
		path: <path d="M4.816-5.04V-7.02H5.9v1.98H9.088V-7.02h1.088v1.98h4.112A.379.379,0,0,1,14.44-5a.151.151,0,0,1,.088.152V-4.3a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.206.206,0,0,1,.464-4.3v-.544A.154.154,0,0,1,.544-5,.355.355,0,0,1,.7-5.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M6.944-3.664V-5.232H.72A.245.245,0,0,1,.552-5.3.19.19,0,0,1,.48-5.44v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.192h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.568Z" />, 
		strokes: [
		] 
	},
	'_(ㅁ)_ㅡ_c_3.svg': { 
		path: <path d="M14.32-5.328a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.576V-5.12a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.328Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_(ㅛ)_ㄹ_3.svg': { 
		path: <path d="M12.624,1.936a.167.167,0,0,1-.072.136.244.244,0,0,1-.152.056H3.936a1.3,1.3,0,0,1-1-.28A1.509,1.509,0,0,1,2.7.9V-.24a1.14,1.14,0,0,1,.28-.912,1.618,1.618,0,0,1,.984-.224h6.864a.473.473,0,0,0,.3-.064.43.43,0,0,0,.072-.3v-.88a.481.481,0,0,0-.056-.3.443.443,0,0,0-.28-.056H2.88a.26.26,0,0,1-.144-.056.163.163,0,0,1-.08-.136v-.576a.128.128,0,0,1,.08-.12A.328.328,0,0,1,2.88-3.9h8.144a2.855,2.855,0,0,1,.616.056.784.784,0,0,1,.392.2.739.739,0,0,1,.184.352,2.356,2.356,0,0,1,.056.56V-1.6a1.118,1.118,0,0,1-.28.888,1.575,1.575,0,0,1-.984.232H4.128q-.224,0-.28.064a.524.524,0,0,0-.056.3v.96a.436.436,0,0,0,.072.288.4.4,0,0,0,.3.08h8.224a.3.3,0,0,1,.168.048.136.136,0,0,1,.072.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M12.656,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.032a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.688a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.5H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.944a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.288a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.48,1.92q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-2.368a1.212,1.212,0,0,1,.256-.9A1.464,1.464,0,0,1,3.92-3.5h8.24a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.024H11.9q.224,0,.224.176v.512a.184.184,0,0,1-.208.208H3.792V.8a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅓ_c_3.svg': { 
		path: <path d="M8.608-9.04h3.168v-3.248A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.36a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.08H8.608Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅕ_2.svg': { 
		path: <path d="M8.592-8.96h3.184v-3.328A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.112H8.592v-.944h3.184v-2.96H8.592Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅕ_c_3.svg': { 
		path: <path d="M8.624-10.1h3.152v-2.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.36a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-1.5H8.624v-.944h3.152V-9.152H8.624Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.34H8.032v1.292h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.34H8.032v1.292h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.34H8.032v1.292h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M6.944-6.048V-7.34H8.032v1.292h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅗ_2.svg': { 
		path: <path d="M8.032-4.544v3.3H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.928v-3.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅗ_c_3.svg': { 
		path: <path d="M8.048-6.56v1.584H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976H6.944V-6.56Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.736-6V-7.34H5.808V-6H9.152V-7.34H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.736-6V-7.34H5.808V-6H9.152V-7.34H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.736-6V-7.34H5.808V-6H9.152V-7.34H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-6V-7.34H5.808V-6H9.152V-7.34H10.24V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅛ_c_3.svg': { 
		path: <path d="M5.824-4.96H9.072V-6.54h1.072v1.58h4.144a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.544a.172.172,0,0,1,.08-.16A.309.309,0,0,1,.7-4.96H4.736V-6.54H5.824Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-3.5V-5.136H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-3.5Z" />, 
		strokes: [
		] 
	},
	'_(ㅂ)_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M6.944-3.5V-5.136H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-3.5Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_(ㅗ)_ㅊ_3.svg': { 
		path: <path d="M13.2,2.128q-.064.16-.3.128-.624-.112-1.416-.272T9.872,1.632q-.832-.176-1.616-.384-.32-.08-.624-.144a36.118,36.118,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L1.7,1.744a.228.228,0,0,1,0-.168.147.147,0,0,1,.112-.1q1.152-.144,2.328-.408T6.416.528Q7.5.192,8.456-.152A11.97,11.97,0,0,0,10.112-.88q.224-.112.216-.192t-.3-.08H3.04a.184.184,0,0,1-.208-.208v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.432.432,0,0,1-.04.48,2.188,2.188,0,0,1-.536.528A13.844,13.844,0,0,1,9.152.528L9.3.56q.624.144,1.3.3.672.128,1.36.248t1.28.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.424a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_hd_ㄹ_3.svg': { 
		path: <path d="M11.04-3.664a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.48,1.92q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-2.528a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.24a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.088h8.1q.208,0,.208.192v.544A.17.17,0,0,1,11.9-.32H3.792V.8a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅑ_(ㄹ)_3.svg': { 
		path: <path d="M12.32-4.96a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.328a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.272h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.744h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M12.848-4.88a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-8.192H8.88A.184.184,0,0,1,8.672-8.4v-.576q0-.192.208-.192h2.864v-3.1a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M12.848-4.88a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-8.192H8.88A.184.184,0,0,1,8.672-8.4v-.576q0-.192.208-.192h2.864v-3.1a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M12.848-4.88a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-8.192H8.88A.184.184,0,0,1,8.672-8.4v-.576q0-.192.208-.192h2.864v-3.1a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-10.656v-1.632A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.96a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.312H8.512Q8.3-7.312,8.3-7.5v-.56q0-.192.208-.192h3.264V-9.712H8.512Q8.3-9.712,8.3-9.9v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅕ_(ㅊ)_3.svg': { 
		path: <path d="M12.864-4.816q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-7.072H8.96q-.208,0-.208-.176v-.56Q8.752-8,8.96-8h2.784V-9.616H8.96q-.208,0-.208-.176v-.56a.184.184,0,0,1,.208-.208h2.784v-1.7a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.816q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-7.072H8.96q-.208,0-.208-.176v-.56Q8.752-8,8.96-8h2.784V-9.616H8.96q-.208,0-.208-.176v-.56a.184.184,0,0,1,.208-.208h2.784v-1.7a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅕ_(ㅎ)_3.svg': { 
		path: <path d="M11.776-10.656v-1.632A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.96a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.312H8.512Q8.3-7.312,8.3-7.5v-.56q0-.192.208-.192h3.264V-9.712H8.512Q8.3-9.712,8.3-9.9v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅕ_2.svg': { 
		path: <path d="M11.776-5.584V-7.952H8.352q-.208,0-.208-.192V-8.7q0-.192.208-.192h3.424v-3.392A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.64H8.352q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-5.92a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.168v-.56a.162.162,0,0,1,.072-.144A.3.3,0,0,1,.72-5.92H6.912V-7.984a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V-5.92Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M7.984-5.9h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.172.172,0,0,1-.088.128.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.173.173,0,0,1-.08-.128v-.56a.157.157,0,0,1,.08-.144A.309.309,0,0,1,.7-5.9H6.9V-7.968a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M14.32-5.92a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.168v-.56a.162.162,0,0,1,.072-.144A.3.3,0,0,1,.72-5.92H6.912V-7.984a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V-5.92Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M7.984-5.9h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.172.172,0,0,1-.088.128.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.173.173,0,0,1-.08-.128v-.56a.157.157,0,0,1,.08-.144A.309.309,0,0,1,.7-5.9H6.9V-7.968a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅗ_c_3.svg': { 
		path: <path d="M14.32-5.232a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.48v-.56a.162.162,0,0,1,.072-.144A.3.3,0,0,1,.72-5.232H6.912v-2.16a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v2.16Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-5.872a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.12v-.56a.162.162,0,0,1,.072-.144A.3.3,0,0,1,.72-5.872H4.752V-7.664a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v1.792H9.088V-7.664a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v1.792Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.736-5.872V-7.648q0-.224.24-.224H5.6a.2.2,0,0,1,.224.224v1.776H9.072V-7.648q0-.224.208-.224h.64a.2.2,0,0,1,.224.224v1.776h4.144a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56a.157.157,0,0,1,.08-.144A.309.309,0,0,1,.7-5.872Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-5.872V-7.648q0-.224.24-.224H5.6a.2.2,0,0,1,.224.224v1.776H9.072V-7.648q0-.224.208-.224h.64a.2.2,0,0,1,.224.224v1.776h4.144a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.56a.157.157,0,0,1,.08-.144A.309.309,0,0,1,.7-5.872Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅛ_c_3.svg': { 
		path: <path d="M14.32-5.136a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.24.19.19,0,0,1,.48-4.384v-.56a.162.162,0,0,1,.072-.144A.3.3,0,0,1,.72-5.136H4.752V-7.2a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v2.064H9.088V-7.2a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v2.064Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.288a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.664h-1.1V-5.328H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.536V-6.08a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.288Z" />, 
		strokes: [
		] 
	},
	'_(ㅅ)_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M6.944-3.664V-5.328H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.536V-6.08a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.288h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M11.04-3.664a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_(ㅠ)_ㄹ_3.svg': { 
		path: <path d="M12.688,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.872a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.656.912V-.08a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.976A.435.435,0,0,0,11.2-1.28a.47.47,0,0,0,.064-.3V-2.32a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.848A.27.27,0,0,1,2.7-2.736a.147.147,0,0,1-.072-.128v-.56A.147.147,0,0,1,2.7-3.552.27.27,0,0,1,2.848-3.6h8.24a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.992a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H4.08q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.368a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅓ_2.svg': { 
		path: <path d="M12.88,2.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-5.952H8.912v-.96h2.864v-5.376A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅓ_c_3.svg': { 
		path: <path d="M12.88-4.88a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.048H8.96v-.96h2.816v-3.28A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M12.88-4.928a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.544H8.1l.656-.944h3.024V-9.536H8.752l-.4-.944h3.424v-1.808A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M12.88-4.928a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.544H8.1l.656-.944h3.024V-9.536H8.752l-.4-.944h3.424v-1.808A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M12.88-4.928a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.544H8.1l.656-.944h3.024V-9.536H8.752l-.4-.944h3.424v-1.808A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅕ_2.svg': { 
		path: <path d="M12.88,2.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-3.616H8.384L8.69-4.56h3.086V-8.352H8.69L8.368-9.3h3.408v-2.992A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅕ_c_3.svg': { 
		path: <path d="M12.832-4.16q0,.24-.224.24h-.64q-.224,0-.224-.24V-6.1H8.192L8.8-7.024h2.943v-2.16H8.95l-.365-.944h3.159v-2.128q0-.24.224-.24h.64q.224,0,.224.24Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.328v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.08H6.96v-1.2l1.1-.016V-6.08Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M14.32-6.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.328v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.08H6.96v-1.2l1.1-.016V-6.08Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M14.32-6.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.328v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.08H6.96v-1.2l1.1-.016V-6.08Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M14.32-6.08a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.328v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.08H6.96v-1.2l1.1-.016V-6.08Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅗ_2.svg': { 
		path: <path d="M14.32-1.248a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.928V-4.576h1.1v3.328Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅗ_c_3.svg': { 
		path: <path d="M14.32-5.04a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.288v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.04H6.944V-7.008h1.1V-5.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.048a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.3a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.3V-5.84a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.048H4.352V-7.776l1.1.32v1.408H9.568V-7.456l1.1-.32v1.728Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M14.32-6.048a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.3a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.3V-5.84a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.048H4.352V-7.776l1.1.32v1.408H9.568V-7.456l1.1-.32v1.728Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M14.32-6.048a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.3a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.3V-5.84a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.048H4.352V-7.776l1.1.32v1.408H9.568V-7.456l1.1-.32v1.728Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M14.32-6.048a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.3a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.3V-5.84a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.048H4.352V-7.776l1.1.32v1.408H9.568V-7.456l1.1-.32v1.728Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅛ_c_3.svg': { 
		path: <path d="M5.44-7.213V-5.3H9.552V-7.24l1.091-.314,0,2.258h3.648a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.56a.172.172,0,0,1,.08-.16A.309.309,0,0,1,.7-5.3H4.336v-2.24Z" />, 
		strokes: [
		] 
	},
	'_(ㅇ)_ㅜ_2.svg': { 
		path: <path d="M7.136,2.144a.19.19,0,0,1-.144-.072A.245.245,0,0,1,6.928,1.9V-3.328H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.536V-4.08a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.288h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.032V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_hd_ㄹ_3.svg': { 
		path: <path d="M11.04-3.664a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅓ_c_3.svg': { 
		path: <path d="M11.776-8.8v-3.488A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.32a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.84H8.816q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅕ_2.svg': { 
		path: <path d="M11.776-5.152V-7.728h-2.7q-.208,0-.208-.192v-.56q0-.192.208-.192h2.7v-3.616A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.208h-2.7q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-6V-7.264a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224V-6h6.256a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M6.944-6V-7.264a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224V-6h6.256a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M6.944-6V-7.264a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224V-6h6.256a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M6.944-6V-7.264a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224V-6h6.256a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅗ_c_3.svg': { 
		path: <path d="M7.84-7.36a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.144H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976H6.944V-7.12A.266.266,0,0,1,7-7.288a.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.736-6V-7.472a.266.266,0,0,1,.056-.168.167.167,0,0,1,.136-.072H5.6a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168V-6H9.1V-7.472A.266.266,0,0,1,9.16-7.64a.182.182,0,0,1,.152-.072H10a.177.177,0,0,1,.128.072.245.245,0,0,1,.064.168V-6h4.1a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.736-6V-7.472a.266.266,0,0,1,.056-.168.167.167,0,0,1,.136-.072H5.6a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168V-6H9.1V-7.472A.266.266,0,0,1,9.16-7.64a.182.182,0,0,1,.152-.072H10a.177.177,0,0,1,.128.072.245.245,0,0,1,.064.168V-6h4.1a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.736-6V-7.472a.266.266,0,0,1,.056-.168.167.167,0,0,1,.136-.072H5.6a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168V-6H9.1V-7.472A.266.266,0,0,1,9.16-7.64a.182.182,0,0,1,.152-.072H10a.177.177,0,0,1,.128.072.245.245,0,0,1,.064.168V-6h4.1a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-6V-7.472a.266.266,0,0,1,.056-.168.167.167,0,0,1,.136-.072H5.6a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168V-6H9.1V-7.472A.266.266,0,0,1,9.16-7.64a.182.182,0,0,1,.152-.072H10a.177.177,0,0,1,.128.072.245.245,0,0,1,.064.168V-6h4.1a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅛ_c_3.svg': { 
		path: <path d="M4.816-5.072V-6.848a.3.3,0,0,1,.056-.176.176.176,0,0,1,.152-.08H5.7a.183.183,0,0,1,.144.08.275.275,0,0,1,.064.176v1.776H9.088V-6.848a.3.3,0,0,1,.056-.176.192.192,0,0,1,.168-.08h.656a.2.2,0,0,1,.136.08.258.258,0,0,1,.072.176v1.776h4.112a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168V-4.3a.193.193,0,0,1-.088.136.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.2.2,0,0,1,.464-4.3v-.544a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-5.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅈ)_ㅜ_2.svg': { 
		path: <path d="M7.136,2.144a.19.19,0,0,1-.144-.072A.245.245,0,0,1,6.928,1.9V-3.28H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.488v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.24h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.032V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅑ)_ㄹ_3.svg': { 
		path: <path d="M12.656,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.4a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952V.032a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.7a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.3a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.992a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H4.624q-.224,0-.28.064a.524.524,0,0,0-.056.3v.8a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅑ)_ㅊ_3.svg': { 
		path: <path d="M13.776,2.112q-.048.176-.288.128-.64-.112-1.432-.272t-1.608-.32q-.832-.192-1.616-.4-.32-.08-.624-.16A37.3,37.3,0,0,1,2.7,2.416q-.128.048-.184.008a.363.363,0,0,1-.1-.168l-.144-.5a.222.222,0,0,1,.016-.184.2.2,0,0,1,.1-.1q1.168-.144,2.336-.408T7.008.512Q8.1.192,9.048-.144a9.691,9.691,0,0,0,1.64-.736q.224-.128.216-.2t-.28-.072H3.632q-.224,0-.224-.208v-.56q0-.192.224-.192H11.04a3.059,3.059,0,0,1,1.024.136.85.85,0,0,1,.48.344.44.44,0,0,1-.032.48,2.289,2.289,0,0,1-.56.56A13.256,13.256,0,0,1,9.744.528L9.872.56q.64.144,1.312.3.656.144,1.344.256t1.312.208q.224.016.128.224ZM10.128-3.76a.212.212,0,0,1,.24.24v.448a.212.212,0,0,1-.24.24H6a.212.212,0,0,1-.24-.24V-3.52A.212.212,0,0,1,6-3.76Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅑ)_ㅌ_3.svg': { 
		path: <path d="M12.64,2.064q0,.208-.192.208h-7.9A1.471,1.471,0,0,1,3.6,2.048a1.209,1.209,0,0,1-.256-.912V-2.288A1.209,1.209,0,0,1,3.6-3.2a1.471,1.471,0,0,1,.944-.224H12.16q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.848a.467.467,0,0,0-.312.08.433.433,0,0,0-.088.32v1.12h7.616q.224,0,.224.176V-.3A.184.184,0,0,1,12.08-.1H4.448V.928a.5.5,0,0,0,.088.328.392.392,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅑ)_ㅎ_3.svg': { 
		path: <path d="M10.912.9A.633.633,0,0,0,10.7.4a1.545,1.545,0,0,0-.584-.3A3.991,3.991,0,0,0,9.28-.088a7.872,7.872,0,0,0-.944-.056q-.432,0-.9.04a3,3,0,0,0-.84.184,1.636,1.636,0,0,0-.6.312A.637.637,0,0,0,5.76.9a.621.621,0,0,0,.232.488,1.83,1.83,0,0,0,.6.328,4.868,4.868,0,0,0,.84.168,7.681,7.681,0,0,0,.9.056A7.872,7.872,0,0,0,9.28,1.88a4.873,4.873,0,0,0,.832-.168,1.644,1.644,0,0,0,.584-.328A.64.64,0,0,0,10.912.9ZM12.032.88a1.172,1.172,0,0,1-.328.84,2.6,2.6,0,0,1-.824.568A4.347,4.347,0,0,1,9.7,2.632a8.621,8.621,0,0,1-1.336.1,8.775,8.775,0,0,1-1.344-.1,3.91,3.91,0,0,1-1.152-.344,2.358,2.358,0,0,1-.864-.576A1.2,1.2,0,0,1,4.688.88a1.23,1.23,0,0,1,.32-.872A2.414,2.414,0,0,1,5.872-.56,4.27,4.27,0,0,1,7.016-.888a8.519,8.519,0,0,1,1.32-.1A8.115,8.115,0,0,1,9.688-.88a5.309,5.309,0,0,1,1.192.336A2.6,2.6,0,0,1,11.7.024,1.192,1.192,0,0,1,12.032.88Zm1.456-2.512q0,.192-.256.192H3.52A.242.242,0,0,1,3.376-1.5a.2.2,0,0,1-.08-.16v-.448a.206.206,0,0,1,.08-.152.229.229,0,0,1,.144-.072h9.712q.256,0,.256.208ZM10.72-3.2q0,.224-.24.224H6.112a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.9H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅓ)_ㄱ_3.svg': { 
		path: <path d="M3.552-2.432a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h7.872a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.08a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-1.328q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.792a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅓ)_ㄷ_3.svg': { 
		path: <path d="M13.04,1.9a.184.184,0,0,1-.208.208H4.96a1.5,1.5,0,0,1-.968-.224,1.207,1.207,0,0,1-.248-.9V-1.376a1.207,1.207,0,0,1,.248-.9A1.5,1.5,0,0,1,4.96-2.5h7.584q.208,0,.208.192v.528q0,.224-.208.224H5.216a.379.379,0,0,0-.288.088.5.5,0,0,0-.08.328V.752a.477.477,0,0,0,.08.3.344.344,0,0,0,.288.1h7.616q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅓ)_ㄹ_3.svg': { 
		path: <path d="M13.216,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.96a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952V.032a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.7a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.3a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.992a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H5.184q-.224,0-.28.064a.524.524,0,0,0-.056.3v.8a.473.473,0,0,0,.064.3A.4.4,0,0,0,5.2,1.36h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅓ)_ㅌ_3.svg': { 
		path: <path d="M13.2,2.032q0,.224-.192.224H5.1a1.471,1.471,0,0,1-.944-.224,1.182,1.182,0,0,1-.256-.9V-1.9a1.182,1.182,0,0,1,.256-.9A1.471,1.471,0,0,1,5.1-3.024H12.72q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H5.408a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328v.9h7.616q.224,0,.224.192V-.1a.184.184,0,0,1-.208.208H5.008V.928a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅓ)_ㅍ_3.svg': { 
		path: <path d="M13.248-1.824q0,.208-.24.208h-1.7V1.152H13.5q.24,0,.24.208V1.9q0,.208-.24.208H3.3A.257.257,0,0,1,3.16,2.04a.2.2,0,0,1-.088-.152V1.376a.192.192,0,0,1,.088-.16A.27.27,0,0,1,3.3,1.152H5.552V-1.616H3.808a.206.206,0,0,1-.152-.08.229.229,0,0,1-.072-.144v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.2q.24,0,.24.208ZM6.624,1.152h3.6V-1.616h-3.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅓ)_ㅎ_3.svg': { 
		path: <path d="M10.912,1.12A.5.5,0,0,0,10.7.672a2.24,2.24,0,0,0-.584-.256A4.432,4.432,0,0,0,9.28.256,9.169,9.169,0,0,0,8.336.208a8.945,8.945,0,0,0-.9.048,4.439,4.439,0,0,0-.84.16,1.428,1.428,0,0,0-.6.232.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.027,2.027,0,0,0,.6.28,4.185,4.185,0,0,0,.84.12q.472.024.9.024.48,0,.944-.032a4.549,4.549,0,0,0,.832-.128A1.925,1.925,0,0,0,10.7,1.52.481.481,0,0,0,10.912,1.12Zm1.12,0a.984.984,0,0,1-.328.752,3.043,3.043,0,0,1-.824.528A4.577,4.577,0,0,1,9.7,2.688a11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,5.872,2.4a2.691,2.691,0,0,1-.864-.536,1,1,0,0,1-.32-.744,1.066,1.066,0,0,1,.32-.784,2.43,2.43,0,0,1,.864-.528A4.122,4.122,0,0,1,7.016-.48a11.026,11.026,0,0,1,1.32-.08,10.28,10.28,0,0,1,1.352.088,5.67,5.67,0,0,1,1.192.28A2.936,2.936,0,0,1,11.7.344,1.027,1.027,0,0,1,12.032,1.12Zm1.456-2.48q0,.208-.256.208H3.52a.26.26,0,0,1-.144-.056A.176.176,0,0,1,3.3-1.36v-.448a.213.213,0,0,1,.08-.176.242.242,0,0,1,.144-.064h9.712q.256,0,.256.224ZM10.72-2.992a.212.212,0,0,1-.24.24H6.112a.26.26,0,0,1-.144-.056.192.192,0,0,1-.08-.168v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.7H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅕ)_ㄱ_3.svg': { 
		path: <path d="M3.536-2.464q0-.064.088-.112a.352.352,0,0,1,.168-.048h7.84a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.048a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-1.344q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.792a.315.315,0,0,1-.168-.056q-.088-.056-.088-.12Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅕ)_ㄹ_3.svg': { 
		path: <path d="M13.184,2.08a.182.182,0,0,1-.072.152.244.244,0,0,1-.152.056H4.928A1.224,1.224,0,0,1,3.96,2a1.546,1.546,0,0,1-.232-.96V.192a1.143,1.143,0,0,1,.28-.9,1.575,1.575,0,0,1,.984-.232h6.4A.446.446,0,0,0,11.664-1q.064-.056.064-.328V-1.92a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H3.92a.27.27,0,0,1-.152-.048A.147.147,0,0,1,3.7-2.464V-3.04a.155.155,0,0,1,.072-.12.244.244,0,0,1,.152-.056h7.664a2.855,2.855,0,0,1,.616.056.866.866,0,0,1,.392.184.743.743,0,0,1,.184.36,2.461,2.461,0,0,1,.056.568v.9a1.143,1.143,0,0,1-.28.9,1.575,1.575,0,0,1-.984.232H5.152q-.224,0-.264.064a.647.647,0,0,0-.04.288V.992a.527.527,0,0,0,.056.3q.056.072.28.072H12.96a.27.27,0,0,1,.152.048.136.136,0,0,1,.072.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅕ)_ㅂ_3.svg': { 
		path: <path d="M4.816-.432v1.2a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.176a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312v-1.2Zm.112,2.544a3.161,3.161,0,0,1-.616-.048.694.694,0,0,1-.36-.176.908.908,0,0,1-.176-.376A2.522,2.522,0,0,1,3.728.976V-2.688A.138.138,0,0,1,3.792-2.8a.234.234,0,0,1,.144-.048h.688a.185.185,0,0,1,.136.056.156.156,0,0,1,.056.1V-1.36H11.76V-2.64a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V1.072a1.049,1.049,0,0,1-.256.824,1.547,1.547,0,0,1-.96.216Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅕ)_ㅊ_3.svg': { 
		path: <path d="M14.208,2.128q-.064.16-.32.128-.608-.112-1.4-.272t-1.624-.352q-.832-.176-1.6-.384-.32-.08-.624-.144a36.746,36.746,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L2.7,1.744a.141.141,0,0,1-.016-.168.188.188,0,0,1,.128-.1q1.152-.144,2.328-.408T7.424.528Q8.5.192,9.456-.152A11.819,11.819,0,0,0,11.12-.88q.224-.112.208-.192t-.288-.08H4.048A.184.184,0,0,1,3.84-1.36v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.467.467,0,0,1-.048.48,1.981,1.981,0,0,1-.544.528A13,13,0,0,1,10.144.528L10.3.56q.624.144,1.3.3.656.128,1.352.248t1.288.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H6.432q-.256,0-.256-.24v-.464q0-.224.256-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅕ)_ㅌ_3.svg': { 
		path: <path d="M13.2,2.032q0,.224-.192.224H5.1a1.471,1.471,0,0,1-.944-.224,1.182,1.182,0,0,1-.256-.9V-1.9a1.182,1.182,0,0,1,.256-.9A1.471,1.471,0,0,1,5.1-3.024H12.72q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H5.408a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328v.9h7.616q.224,0,.224.192V-.1a.184.184,0,0,1-.208.208H5.008V.928a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅕ)_ㅎ_3.svg': { 
		path: <path d="M10.912,1.12A.5.5,0,0,0,10.7.672a2.24,2.24,0,0,0-.584-.256A4.432,4.432,0,0,0,9.28.256,9.169,9.169,0,0,0,8.336.208a8.945,8.945,0,0,0-.9.048,4.439,4.439,0,0,0-.84.16,1.428,1.428,0,0,0-.6.232.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.027,2.027,0,0,0,.6.28,4.185,4.185,0,0,0,.84.12q.472.024.9.024.48,0,.944-.032a4.549,4.549,0,0,0,.832-.128A1.925,1.925,0,0,0,10.7,1.52.481.481,0,0,0,10.912,1.12Zm1.12,0a.984.984,0,0,1-.328.752,3.043,3.043,0,0,1-.824.528A4.577,4.577,0,0,1,9.7,2.688a11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,5.872,2.4a2.691,2.691,0,0,1-.864-.536,1,1,0,0,1-.32-.744,1.066,1.066,0,0,1,.32-.784,2.43,2.43,0,0,1,.864-.528A4.122,4.122,0,0,1,7.016-.48a11.026,11.026,0,0,1,1.32-.08,10.28,10.28,0,0,1,1.352.088,5.67,5.67,0,0,1,1.192.28A2.936,2.936,0,0,1,11.7.344,1.027,1.027,0,0,1,12.032,1.12Zm1.456-2.48q0,.208-.256.208H3.52a.26.26,0,0,1-.144-.056A.176.176,0,0,1,3.3-1.36v-.448a.213.213,0,0,1,.08-.176.242.242,0,0,1,.144-.064h9.712q.256,0,.256.224ZM10.72-2.992a.212.212,0,0,1-.24.24H6.112a.26.26,0,0,1-.144-.056.192.192,0,0,1-.08-.168v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.7H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅗ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅗ)_ㅎ_3.svg': { 
		path: <path d="M10.016,1.12A.5.5,0,0,0,9.792.672,2.394,2.394,0,0,0,9.2.416a4.432,4.432,0,0,0-.832-.16,8.917,8.917,0,0,0-1.848,0A4.105,4.105,0,0,0,5.7.416,1.446,1.446,0,0,0,5.08.648a.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.01,2.01,0,0,0,.616.28,3.91,3.91,0,0,0,.824.12q.472.024.92.024.464,0,.928-.032A4.549,4.549,0,0,0,9.2,1.792a2.044,2.044,0,0,0,.592-.272A.477.477,0,0,0,10.016,1.12Zm1.1,0a1,1,0,0,1-.32.752,2.86,2.86,0,0,1-.832.528,4.577,4.577,0,0,1-1.176.288,11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,4.96,2.4,2.691,2.691,0,0,1,4.1,1.864a1,1,0,0,1-.32-.744A1.066,1.066,0,0,1,4.1.336,2.43,2.43,0,0,1,4.96-.192,4.122,4.122,0,0,1,6.1-.48,11.159,11.159,0,0,1,7.44-.56a10.158,10.158,0,0,1,1.336.088,5.67,5.67,0,0,1,1.192.28A2.765,2.765,0,0,1,10.8.344,1.04,1.04,0,0,1,11.12,1.12Zm1.472-2.48q0,.208-.256.208H2.624a.3.3,0,0,1-.152-.056.172.172,0,0,1-.088-.152v-.448a.206.206,0,0,1,.088-.176.275.275,0,0,1,.152-.064h9.712q.256,0,.256.224ZM9.808-3.024q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.163.163,0,0,1-.08-.136v-.48a.217.217,0,0,1,.064-.16A.217.217,0,0,1,5.2-3.7H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㄷ_3.svg': { 
		path: <path d="M12.464,1.888q0,.224-.208.224H3.872a1.359,1.359,0,0,1-.944-.24,1.27,1.27,0,0,1-.24-.9V-1.248a1.267,1.267,0,0,1,.24-.9,1.393,1.393,0,0,1,.944-.232h8.176a.184.184,0,0,1,.208.208v.512q0,.224-.208.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V.752a.414.414,0,0,0,.088.288.412.412,0,0,0,.312.1h8.08a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.244.244,0,0,1-.152.056H3.936a1.3,1.3,0,0,1-1-.28A1.509,1.509,0,0,1,2.7.912V.08a1.14,1.14,0,0,1,.28-.912,1.618,1.618,0,0,1,.984-.224h6.9q.224,0,.28-.072A.569.569,0,0,0,11.2-1.44V-2a.481.481,0,0,0-.056-.3.443.443,0,0,0-.28-.056H2.9a.309.309,0,0,1-.16-.048.135.135,0,0,1-.08-.112v-.576a.145.145,0,0,1,.08-.128.309.309,0,0,1,.16-.048h8.128a2.855,2.855,0,0,1,.616.056.866.866,0,0,1,.392.184.931.931,0,0,1,.192.36,1.985,1.985,0,0,1,.064.552v.832a1.126,1.126,0,0,1-.288.9,1.571,1.571,0,0,1-.992.24H4.128a.443.443,0,0,0-.28.056.481.481,0,0,0-.056.3V.864a.405.405,0,0,0,.072.272.4.4,0,0,0,.3.08H12.4a.266.266,0,0,1,.168.056q.072.056.072.1Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅁ_3.svg': { 
		path: <path d="M2.688-1.12a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v2.1a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976Zm1.488-.208a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-.912a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅂ_3.svg': { 
		path: <path d="M3.76-.432V.816a.463.463,0,0,0,.08.32.433.433,0,0,0,.3.08h6.672a.4.4,0,0,0,.3-.08.5.5,0,0,0,.072-.32V-.432ZM3.872,2.16A2.722,2.722,0,0,1,3.256,2.1a.644.644,0,0,1-.344-.184.593.593,0,0,1-.184-.352,3.267,3.267,0,0,1-.04-.56v-3.7a.213.213,0,0,1,.056-.144,3.406,3.406,0,0,1,.96.008.226.226,0,0,1,.056.136V-1.36h7.424V-2.688a.226.226,0,0,1,.056-.136,3.519,3.519,0,0,1,.976-.008.213.213,0,0,1,.056.144V1.12a1.049,1.049,0,0,1-.256.824,1.512,1.512,0,0,1-.944.216Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅅ_3.svg': { 
		path: <path d="M13.056,2.048a.214.214,0,0,1-.088.112.407.407,0,0,1-.216.016A11.476,11.476,0,0,1,9.12.784,5.422,5.422,0,0,1,7.488-.752,4.641,4.641,0,0,1,6.576.24,12.145,12.145,0,0,1,2.384,2.272q-.24.08-.32-.08L1.84,1.6a.487.487,0,0,1,0-.176.232.232,0,0,1,.128-.144A10.488,10.488,0,0,0,5.72-.288a2.784,2.784,0,0,0,1.256-2.08.17.17,0,0,1,.192-.192l.736.032q.144,0,.128.224a3.183,3.183,0,0,0-.048.336A1.8,1.8,0,0,0,8.1-1.68q.128.24.272.464a3.637,3.637,0,0,0,.816.864,7.288,7.288,0,0,0,1.168.736A8.29,8.29,0,0,0,11.728.92a11.068,11.068,0,0,0,1.488.328q.1,0,.1.1a.42.42,0,0,1-.008.152Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅇ_3.svg': { 
		path: <path d="M11.3-.1a.951.951,0,0,0-.44-.808,3.192,3.192,0,0,0-1.064-.472,5.823,5.823,0,0,0-1.256-.248Q7.888-1.68,7.472-1.68q-.432,0-1.08.056a5.557,5.557,0,0,0-1.24.248A3.1,3.1,0,0,0,4.1-.9.955.955,0,0,0,3.664-.1.944.944,0,0,0,4.1.728a3.074,3.074,0,0,0,1.056.44,5.557,5.557,0,0,0,1.24.248q.648.056,1.08.056t1.08-.056a5.557,5.557,0,0,0,1.24-.248,3.164,3.164,0,0,0,1.064-.44A.939.939,0,0,0,11.3-.1Zm1.168-.016a1.547,1.547,0,0,1-.248.872,2.916,2.916,0,0,1-.632.68,4.149,4.149,0,0,1-.928.432A9.709,9.709,0,0,1,9.6,2.16a8.456,8.456,0,0,1-1.1.152q-.56.04-1.024.04-.448,0-1-.04a8.462,8.462,0,0,1-1.1-.152A9.348,9.348,0,0,1,4.3,1.872a3.81,3.81,0,0,1-.912-.432A2.545,2.545,0,0,1,2.736.76,1.585,1.585,0,0,1,2.5-.112a1.517,1.517,0,0,1,.24-.848,2.213,2.213,0,0,1,.656-.64A3.34,3.34,0,0,1,4.3-2.072a8.4,8.4,0,0,1,1.072-.3q.544-.1,1.088-.144T7.472-2.56a13.028,13.028,0,0,1,1.56.1,7.288,7.288,0,0,1,1.64.392,3.69,3.69,0,0,1,1.264.728A1.565,1.565,0,0,1,12.464-.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A44.654,44.654,0,0,1,7.824.848,30.4,30.4,0,0,1,2.016,2.176q-.16.064-.216.016a.338.338,0,0,1-.088-.176l-.1-.48a.187.187,0,0,1,.008-.16.167.167,0,0,1,.12-.1A36.172,36.172,0,0,0,10.016-.928q.368-.224.344-.328T10-1.376H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.584A18.685,18.685,0,0,1,9.2.336q2,.432,4.128.72.208.064.128.24Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅋ_3.svg': { 
		path: <path d="M2.368-2.16a.172.172,0,0,1,.08-.16.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.509,1.509,0,0,1,.232.952V2.176a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.656H2.864q-.208,0-.208-.176V-.032q0-.224.208-.224H11.2v-.8q0-.224-.08-.28a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232A1.21,1.21,0,0,1,2.7,1.024V-2.128a1.213,1.213,0,0,1,.248-.88,1.424,1.424,0,0,1,.968-.24h8.224q.192,0,.192.208v.48q0,.224-.192.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v.848h8.08q.208,0,.208.176v.544a.17.17,0,0,1-.192.192h-8.1V.8a.5.5,0,0,0,.088.328.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅍ_3.svg': { 
		path: <path d="M12.5-1.568q0,.208-.24.208H10.464V1.152h2.1q.256,0,.256.224V1.9q0,.208-.256.208H2.256A.244.244,0,0,1,2.1,2.056a.2.2,0,0,1-.072-.168V1.376a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.464V-1.36H2.576a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.576-2.3h9.68q.24,0,.24.192ZM5.552,1.152H9.376V-1.36H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅛ)_ㅎ_3.svg': { 
		path: <path d="M10.016,1.12A.5.5,0,0,0,9.792.672,2.394,2.394,0,0,0,9.2.416a4.432,4.432,0,0,0-.832-.16,8.917,8.917,0,0,0-1.848,0A4.105,4.105,0,0,0,5.7.416,1.446,1.446,0,0,0,5.08.648a.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.01,2.01,0,0,0,.616.28,3.91,3.91,0,0,0,.824.12q.472.024.92.024.464,0,.928-.032A4.549,4.549,0,0,0,9.2,1.792a2.044,2.044,0,0,0,.592-.272A.477.477,0,0,0,10.016,1.12Zm1.1,0a1,1,0,0,1-.32.752,2.86,2.86,0,0,1-.832.528,4.577,4.577,0,0,1-1.176.288,11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,4.96,2.4,2.691,2.691,0,0,1,4.1,1.864a1,1,0,0,1-.32-.744A1.066,1.066,0,0,1,4.1.336,2.43,2.43,0,0,1,4.96-.192,4.122,4.122,0,0,1,6.1-.48,11.159,11.159,0,0,1,7.44-.56a10.158,10.158,0,0,1,1.336.088,5.67,5.67,0,0,1,1.192.28A2.765,2.765,0,0,1,10.8.344,1.04,1.04,0,0,1,11.12,1.12Zm1.472-2.48q0,.208-.256.208H2.624a.3.3,0,0,1-.152-.056.172.172,0,0,1-.088-.152v-.448a.206.206,0,0,1,.088-.176.275.275,0,0,1,.152-.064h9.712q.256,0,.256.224ZM9.808-3.024q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.163.163,0,0,1-.08-.136v-.48a.217.217,0,0,1,.064-.16A.217.217,0,0,1,5.2-3.7H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92A1.384,1.384,0,0,1,2.936,1.9,1.366,1.366,0,0,1,2.7.976V-.016q0-.672.288-.864a1.879,1.879,0,0,1,.992-.192h6.9q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-.528a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56A.147.147,0,0,1,2.744-3.2.27.27,0,0,1,2.9-3.248H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568V-1.3a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3v.64a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M13.072,2.064a.322.322,0,0,1-.088.128q-.056.048-.216,0A12.178,12.178,0,0,1,9.464.824,6.1,6.1,0,0,1,7.52-1.008,5.847,5.847,0,0,1,5.616.9,11.19,11.19,0,0,1,2.384,2.288q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A10.131,10.131,0,0,0,5.5-.264,3.368,3.368,0,0,0,6.944-2.32l1.1.016a1.686,1.686,0,0,0,.336.816A5.512,5.512,0,0,0,10.248.192a11.137,11.137,0,0,0,2.984,1.072.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅜ)_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A44.654,44.654,0,0,1,7.824.848,30.4,30.4,0,0,1,2.016,2.176q-.16.064-.216.016a.338.338,0,0,1-.088-.176l-.1-.48a.187.187,0,0,1,.008-.16.167.167,0,0,1,.12-.1A36.172,36.172,0,0,0,10.016-.928q.368-.224.344-.328T10-1.376H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.584A18.685,18.685,0,0,1,9.2.336q2,.432,4.128.72.208.064.128.24Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅠ)_ㅋ_3.svg': { 
		path: <path d="M2.368-2.16a.172.172,0,0,1,.08-.16.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.509,1.509,0,0,1,.232.952V2.176a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.656H2.864q-.208,0-.208-.176V-.032q0-.224.208-.224H11.2v-.8q0-.224-.08-.28a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_(ㅠ)_ㅍ_3.svg': { 
		path: <path d="M12.5-1.568q0,.208-.24.208H10.464V1.152h2.1q.256,0,.256.224V1.9q0,.208-.256.208H2.256A.244.244,0,0,1,2.1,2.056a.2.2,0,0,1-.072-.168V1.376a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.464V-1.36H2.576a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.576-2.3h9.68q.24,0,.24.192ZM5.552,1.152H9.376V-1.36H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_hd_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92A1.384,1.384,0,0,1,2.936,1.9,1.366,1.366,0,0,1,2.7.976V-.016q0-.672.288-.864a1.879,1.879,0,0,1,.992-.192h6.9q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-.528a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56A.147.147,0,0,1,2.744-3.2.27.27,0,0,1,2.9-3.248H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568V-1.3a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3v.64a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_hd_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅇ)_3.svg': { 
		path: <path d="M11.776-7.728v-4.56A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.976a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.768H9.12q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅈ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅊ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅋ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M11.776-8.1v-4.192A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.68a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.136H9.12q-.208,0-.208-.192V-7.9q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅓ_(ㅍ)_3.svg': { 
		path: <path d="M12.848-3.744a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-6.768H9.088q-.208,0-.208-.192v-.56q0-.192.208-.192h2.656v-4.56a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_(ㄱ)_3.svg': { 
		path: <path d="M12.864-3.5a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-5.68H9.3q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448V-8.416H9.3q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448v-2.912a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_(ㄴ)_3.svg': { 
		path: <path d="M11.776-9.008v-3.28A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-1.76a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.992H9.312q-.208,0-.208-.192v-.56q0-.192.208-.192h2.464V-8.064H9.312q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M12.864-3.5a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-5.68H9.3q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448V-8.416H9.3q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448v-2.912a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_(ㅇ)_3.svg': { 
		path: <path d="M12.864-3.5a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-5.68H9.3q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448V-8.416H9.3q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448v-2.912a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_(ㅊ)_3.svg': { 
		path: <path d="M12.864-4.192a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-6.1H9.3q-.208,0-.208-.192v-.56q0-.176.208-.176h2.448V-8.512H9.3a.184.184,0,0,1-.208-.208v-.544q0-.192.208-.192h2.448v-2.8a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.192a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-6.1H9.3q-.208,0-.208-.192v-.56q0-.176.208-.176h2.448V-8.512H9.3a.184.184,0,0,1-.208-.208v-.544q0-.192.208-.192h2.448v-2.8a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅕ_2.svg': { 
		path: <path d="M11.776-7.952v-4.336A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-3.552H9.12q-.208,0-.208-.192V-4.3q0-.192.208-.192h2.656V-7.008H9.12q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M6.9-5.472V-6.784a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224v1.312h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.472Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅗ_(ㅂ)_3.svg': { 
		path: <path d="M6.9-5.472V-6.784a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224v1.312h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.472Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M6.9-5.472V-6.784a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224v1.312h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.472Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M6.9-5.472V-6.784a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224v1.312h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.472Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M6.9-5.472V-6.784a.2.2,0,0,1,.224-.224h.64a.2.2,0,0,1,.224.224v1.312h6.3a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.472Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅗ_c_3.svg': { 
		path: <path d="M14.32-4.656a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-3.9a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-3.76.19.19,0,0,1,.48-3.9v-.544A.182.182,0,0,1,.552-4.6.266.266,0,0,1,.72-4.656H6.912v-1.52A.2.2,0,0,1,7.136-6.4h.656a.2.2,0,0,1,.224.224v1.52Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.736-5.152v-1.44q0-.224.24-.224H5.6a.2.2,0,0,1,.224.224v1.44H9.072v-1.44q0-.224.208-.224h.64a.2.2,0,0,1,.224.224v1.44h4.144a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-4.4v-.576a.14.14,0,0,1,.08-.136.355.355,0,0,1,.16-.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.736-5.152v-1.44q0-.224.24-.224H5.6a.2.2,0,0,1,.224.224v1.44H9.072v-1.44q0-.224.208-.224h.64a.2.2,0,0,1,.224.224v1.44h4.144a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-4.4v-.576a.14.14,0,0,1,.08-.136.355.355,0,0,1,.16-.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.736-5.152v-1.44q0-.224.24-.224H5.6a.2.2,0,0,1,.224.224v1.44H9.072v-1.44q0-.224.208-.224h.64a.2.2,0,0,1,.224.224v1.44h4.144a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-4.4v-.576a.14.14,0,0,1,.08-.136.355.355,0,0,1,.16-.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-5.152v-1.44q0-.224.24-.224H5.6a.2.2,0,0,1,.224.224v1.44H9.072v-1.44q0-.224.208-.224h.64a.2.2,0,0,1,.224.224v1.44h4.144a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-4.4v-.576a.14.14,0,0,1,.08-.136.355.355,0,0,1,.16-.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅛ_2.svg': { 
		path: <path d="M10.224-4.016a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.528H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.56V-3.776a.266.266,0,0,1,.056-.168.182.182,0,0,1,.152-.072H5.44a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.528h3.7V-3.776A.266.266,0,0,1,9.4-3.944a.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅛ_c_3.svg': { 
		path: <path d="M14.32-4.368a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.616V-4.16a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.368H4.752V-5.824a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v1.456H9.088V-5.824a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v1.456Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M6.928-3.248v-1.52H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.976V-5.52a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.728h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.52Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M6.944-2.32v-1.6H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.128v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.88h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016v1.6h-1.1v-1.6C.651-3.936.6-3.957.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅜ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-5.856a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-5.1a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H8.016v1.42h-1.1V-4.88H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.544A.176.176,0,0,1,.544-5.8.278.278,0,0,1,.7-5.856Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅠ_(ㅂ)_3.svg': { 
		path: <path d="M9.552-2.256a.2.2,0,0,1-.136-.072A.23.23,0,0,1,9.344-2.5V-3.952H5.552V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072H4.64A.2.2,0,0,1,4.5-2.328.23.23,0,0,1,4.432-2.5V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅠ_(ㅋ)_3.svg': { 
		path: <path d="M9.344-2.37V-3.952H5.552V-2.37H4.432V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.37Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅠ_(ㅍ)_3.svg': { 
		path: <path d="M9.344-2.3V-3.952H5.552V-2.3H4.432V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅡ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-5.648a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4.9a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.9V-5.44a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.648Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅡ_(ㅊ)_3.svg': { 
		path: <path d="M14.32-5.648a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4.9a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.9V-5.44a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.648Z" />, 
		strokes: [
		] 
	},
	'_(ㅊ)_ㅡ_(ㅎ)_3.svg': { 
		path: <path d="M14.32-5.648a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4.9a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.9V-5.44a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.648Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.544a4.454,4.454,0,0,0,.32.7A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_(ㅜ)_ㅇ_3.svg': { 
		path: <path d="M8.048-3.072a12.164,12.164,0,0,1,1.5.2,5.645,5.645,0,0,1,1.432.472,3.324,3.324,0,0,1,1.072.816A1.807,1.807,0,0,1,12.48-.368a1.847,1.847,0,0,1-.5,1.328,3.633,3.633,0,0,1-1.256.84A6.8,6.8,0,0,1,9.1,2.24a11.13,11.13,0,0,1-1.608.128A11.038,11.038,0,0,1,5.9,2.24,6.8,6.8,0,0,1,4.272,1.8,3.633,3.633,0,0,1,3.016.96a1.847,1.847,0,0,1-.5-1.328,1.807,1.807,0,0,1,.424-1.216,3.231,3.231,0,0,1,1.072-.808A6.1,6.1,0,0,1,5.44-2.864a11.286,11.286,0,0,1,1.5-.208Zm3.264,2.72a1.038,1.038,0,0,0-.424-.848,3.2,3.2,0,0,0-1.024-.528A6.821,6.821,0,0,0,8.608-2a9.469,9.469,0,0,0-1.12-.08A9.368,9.368,0,0,0,6.36-2a6.832,6.832,0,0,0-1.248.272A3.1,3.1,0,0,0,4.1-1.2a1.045,1.045,0,0,0-.416.848A1.023,1.023,0,0,0,4.1.488a3.2,3.2,0,0,0,1.016.52A6.832,6.832,0,0,0,6.36,1.28a9.368,9.368,0,0,0,1.128.08,9.469,9.469,0,0,0,1.12-.08,6.821,6.821,0,0,0,1.256-.272,3.3,3.3,0,0,0,1.024-.52A1.017,1.017,0,0,0,11.312-.352Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_(ㅜ)_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-3.168a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-4.3h8.224q.192,0,.192.224v.48q0,.208-.192.208H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V-1.6h8.08a.184.184,0,0,1,.208.208V-.88q0,.208-.192.208h-8.1V.8a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h8.08q.192,0,.192.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_(ㅠ)_ㄹ_3.svg': { 
		path: <path d="M11.04-3.984a5.506,5.506,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.184a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.272a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-2.7a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.5.5,0,0,1,2.9-3.984Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_(ㅠ)_ㅎ_3.svg': { 
		path: <path d="M10.064.784A.609.609,0,0,0,9.848.3,1.627,1.627,0,0,0,9.28,0a4,4,0,0,0-.808-.192,6.611,6.611,0,0,0-.92-.064,7.217,7.217,0,0,0-.9.056,3.678,3.678,0,0,0-.8.184A1.923,1.923,0,0,0,5.264.3.6.6,0,0,0,5.04.784a.6.6,0,0,0,.224.456,1.556,1.556,0,0,0,.592.312,4,4,0,0,0,.8.192,6.328,6.328,0,0,0,.9.064,6.611,6.611,0,0,0,.92-.064,4,4,0,0,0,.808-.192,1.485,1.485,0,0,0,.568-.312A.607.607,0,0,0,10.064.784Zm1.168,0a1.254,1.254,0,0,1-.32.848,2.474,2.474,0,0,1-.832.608A4.76,4.76,0,0,1,8.9,2.6a7.5,7.5,0,0,1-1.336.12A7.631,7.631,0,0,1,6.224,2.6a4.245,4.245,0,0,1-1.152-.36,2.579,2.579,0,0,1-.864-.624,1.234,1.234,0,0,1-.32-.832,1.259,1.259,0,0,1,.32-.88,2.524,2.524,0,0,1,.864-.592A4.068,4.068,0,0,1,6.216-1.04a8.024,8.024,0,0,1,2.672,0,4.8,4.8,0,0,1,1.192.352,2.422,2.422,0,0,1,.832.616A1.284,1.284,0,0,1,11.232.784Zm1.6-2.672q0,.208-.24.208h-10a.217.217,0,0,1-.16-.064.217.217,0,0,1-.064-.16v-.448a.238.238,0,0,1,.064-.176.217.217,0,0,1,.16-.064h10q.24,0,.24.224Zm-2.944-1.7q0,.224-.256.224H5.408a.234.234,0,0,1-.16-.056.174.174,0,0,1-.064-.136v-.48a.226.226,0,0,1,.064-.152.205.205,0,0,1,.16-.072H9.632q.256,0,.256.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_hd_ㄹ_3.svg': { 
		path: <path d="M11.04-3.984a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.184a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.272a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-2.7a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.984Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_hd_ㅁ_3.svg': { 
		path: <path d="M11.1-2.832a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976V-1.7a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232Zm-6.928.96a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.456a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-3.168a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-4.3h8.224q.192,0,.192.224v.48q0,.208-.192.208H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V-1.6h8.08a.184.184,0,0,1,.208.208V-.88q0,.208-.192.208h-8.1V.8a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h8.08q.192,0,.192.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅓ_(ㄴ)_3.svg': { 
		path: <path d="M11.776-7.824v-4.464A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-2.08a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.864H8.5q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-8.56v-3.728A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.9a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.6H8.512Q8.3-7.6,8.3-7.792v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-8.56v-3.728A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.9a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.6H8.512Q8.3-7.6,8.3-7.792v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M11.776-8.56v-3.728A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.9a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.6H8.512Q8.3-7.6,8.3-7.792v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅓ_c_3.svg': { 
		path: <path d="M11.776-8.32v-3.968A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.36H8.512q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M8.464-10.08h3.312v-2.208A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.36a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.352h-4q-.208,0-.208-.192V-7.1q0-.192.208-.192h4v-1.84H8.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M8.464-10.08h3.312v-2.208A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.36a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.352h-4q-.208,0-.208-.192V-7.1q0-.192.208-.192h4v-1.84H8.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M8.464-10.08h3.312v-2.208A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.36a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.352h-4q-.208,0-.208-.192V-7.1q0-.192.208-.192h4v-1.84H8.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅕ_2.svg': { 
		path: <path d="M7.936-8.336h3.84v-3.952A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-5.68H7.616q-.208,0-.208-.192v-.56q0-.192.208-.192h4.16V-7.392H7.648Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅕ_c_3.svg': { 
		path: <path d="M8.432-9.744h3.344v-2.544A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-5.92h-4q-.208,0-.208-.192v-.56q0-.192.208-.192h4V-8.8H8.08S8.485-9.893,8.432-9.744Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M7.3-8.1a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168V-6.1H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1H6.432v-1.76a.266.266,0,0,1,.056-.168A.182.182,0,0,1,6.64-8.1Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M6.944-6.048v-1.68q0-.208.224-.208h.64q.224,0,.224.208v1.68h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M6.944-6.048v-1.68q0-.208.224-.208h.64q.224,0,.224.208v1.68h6.256a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.56a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064A.183.183,0,0,1,.464-5.3v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.048Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅗ_c_3.svg': { 
		path: <path d="M7.3-7.6a.19.19,0,0,1,.144.072A.245.245,0,0,1,7.5-7.36v2.016H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.592v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.344H6.432V-7.36a.266.266,0,0,1,.056-.168A.182.182,0,0,1,6.64-7.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.736-6V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056H5.6a.213.213,0,0,1,.144.056.233.233,0,0,1,.064.184V-6H9.152V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056h.672a.234.234,0,0,1,.16.056.233.233,0,0,1,.064.184V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.736-6V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056H5.6a.213.213,0,0,1,.144.056.233.233,0,0,1,.064.184V-6H9.152V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056h.672a.234.234,0,0,1,.16.056.233.233,0,0,1,.064.184V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.736-6V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056H5.6a.213.213,0,0,1,.144.056.233.233,0,0,1,.064.184V-6H9.152V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056h.672a.234.234,0,0,1,.16.056.233.233,0,0,1,.064.184V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-6V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056H5.6a.213.213,0,0,1,.144.056.233.233,0,0,1,.064.184V-6H9.152V-7.744a.253.253,0,0,1,.056-.184.185.185,0,0,1,.136-.056h.672a.234.234,0,0,1,.16.056.233.233,0,0,1,.064.184V-6h4.048a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅛ_c_3.svg': { 
		path: <path d="M5.424-7.536a.182.182,0,0,1,.152.072.266.266,0,0,1,.056.168v2.112h2.4V-7.3a.266.266,0,0,1,.056-.168.2.2,0,0,1,.168-.072h.688a.2.2,0,0,1,.136.072.23.23,0,0,1,.072.168v2.112h5.136a.33.33,0,0,1,.152.048.168.168,0,0,1,.088.16v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.172.172,0,0,1,.08-.16A.309.309,0,0,1,.7-5.184H4.528V-7.3a.266.266,0,0,1,.056-.168.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㄱ)_3.svg': { 
		path: <path d="M14.32-6.368a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2.64h-1.1V-5.408H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.616V-6.16a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.368Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㄴ)_3.svg': { 
		path: <path d="M14.32-5.76a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.176v3.408a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.28a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.8H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.008v-.544A.182.182,0,0,1,.552-5.7.266.266,0,0,1,.72-5.76Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㄷ)_3.svg': { 
		path: <path d="M14.288-6.144a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016v2.58h-1.1V-5.2H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.144Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.8a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.856h-1.1V-5.84H.72A.245.245,0,0,1,.552-5.9.19.19,0,0,1,.48-6.048v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.8Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅁ)_3.svg': { 
		path: <path d="M14.32-6.176a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.384h-1.1V-5.216H.72A.245.245,0,0,1,.552-5.28.19.19,0,0,1,.48-5.424v-.544A.182.182,0,0,1,.552-6.12.266.266,0,0,1,.72-6.176Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M14.32-5.824a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.32H8.032l-1.088-.1V-4.864H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.072v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.824Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅇ)_3.svg': { 
		path: <path d="M14.32-6.144a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.112a6.36,6.36,0,0,1-1.1,0V-5.184H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.392v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.144Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-6.7a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016v1.79a7.628,7.628,0,0,1-1.1,0V-5.76H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.544a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.7Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅋ)_3.svg': { 
		path: <path d="M14.288-6.144a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016v2.43h-1.1V-5.2H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.144Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M14.288-6.784a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H8.016V-4.3h-1.1V-5.808H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.784Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅍ)_3.svg': { 
		path: <path d="M14.288-6.144a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016v2.56h-1.1V-5.2H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.144Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-6.7a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016v1.5h-1.1v-1.5H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.544a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.7Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_2.svg': { 
		path: <path d="M14.32-5.168a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.032V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.136a.19.19,0,0,1-.144-.072A.245.245,0,0,1,6.928,1.9V-4.208H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.416V-4.96a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.168Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅜ_c_3.svg': { 
		path: <path d="M14.32-6.544a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v2.24a.266.266,0,0,1-.056.168A.182.182,0,0,1,7.84-3.1H7.152a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168v-2.24H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.792v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.544Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_(ㄴ)_3.svg': { 
		path: <path d="M14.32-5.76a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.528v3.264a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.632a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.8H6.192v3.264a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H5.3a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.8H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.008v-.544A.182.182,0,0,1,.552-5.7.266.266,0,0,1,.72-5.76Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.8a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48v1.856h-1.1V-5.84H5.568v1.856h-1.1V-5.84H.72A.245.245,0,0,1,.552-5.9.19.19,0,0,1,.48-6.048v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.8Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_(ㅁ)_3.svg': { 
		path: <path d="M14.32-6.176a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48v2.384h-1.1V-5.216H5.568v2.384h-1.1V-5.216H.72A.245.245,0,0,1,.552-5.28.19.19,0,0,1,.48-5.424v-.544A.182.182,0,0,1,.552-6.12.266.266,0,0,1,.72-6.176Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_(ㅊ)_3.svg': { 
		path: <path d="M10.24-3.648a.2.2,0,0,1-.16-.08.256.256,0,0,1-.064-.16v-1.92h-5.1v1.92a.278.278,0,0,1-.056.16.176.176,0,0,1-.152.08H4.016a.183.183,0,0,1-.144-.08.256.256,0,0,1-.064-.16v-1.92H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.56a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-6.784H14.288a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H11.136v1.92a.278.278,0,0,1-.056.16.176.176,0,0,1-.152.08Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_(ㅌ)_3.svg': { 
		path: <path d="M9.344-4.3V-5.824H5.552V-4.3H4.432V-5.824H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.784H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-4.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_(ㅎ)_3.svg': { 
		path: <path d="M10.24-3.648a.2.2,0,0,1-.16-.08.256.256,0,0,1-.064-.16v-1.92h-5.1v1.92a.278.278,0,0,1-.056.16.176.176,0,0,1-.152.08H4.016a.183.183,0,0,1-.144-.08.256.256,0,0,1-.064-.16v-1.92H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144V-6.56a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-6.784H14.288a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H11.136v1.92a.278.278,0,0,1-.056.16.176.176,0,0,1-.152.08Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_2.svg': { 
		path: <path d="M14.32-5.072a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.584a.19.19,0,0,1-.144-.072A.245.245,0,0,1,9.376,1.9V-4.112H5.52V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H4.624a.19.19,0,0,1-.144-.072A.245.245,0,0,1,4.416,1.9V-4.112H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.32v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅠ_c_3.svg': { 
		path: <path d="M9.552-2.976a.212.212,0,0,1-.136-.064.223.223,0,0,1-.072-.176V-5.2H5.552v1.984A.258.258,0,0,1,5.5-3.04a.211.211,0,0,1-.168.064H4.64A.212.212,0,0,1,4.5-3.04a.223.223,0,0,1-.072-.176V-5.2H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.144H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H10.464v1.984a.258.258,0,0,1-.056.176.211.211,0,0,1-.168.064Z" />, 
		strokes: [
		] 
	},
	'_(ㅋ)_ㅡ_c_3.svg': { 
		path: <path d="M14.32-5.856a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.1a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.96.19.19,0,0,1,.48-5.1v-.544A.182.182,0,0,1,.552-5.8.266.266,0,0,1,.72-5.856Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M13.072,2.064a.322.322,0,0,1-.088.128q-.056.048-.216,0A12.178,12.178,0,0,1,9.464.824,6.1,6.1,0,0,1,7.52-1.008,5.847,5.847,0,0,1,5.616.9,11.19,11.19,0,0,1,2.384,2.288q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A10.131,10.131,0,0,0,5.5-.264,3.368,3.368,0,0,0,6.944-2.32l1.1.016a1.686,1.686,0,0,0,.336.816A5.512,5.512,0,0,0,10.248.192a11.137,11.137,0,0,0,2.984,1.072.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_hd_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A35.824,35.824,0,0,1,7.92.96a28.553,28.553,0,0,1-5.9,1.232q-.16.048-.216,0a.323.323,0,0,1-.088-.16l-.1-.48a.226.226,0,0,1,.008-.176.13.13,0,0,1,.12-.1A32.028,32.028,0,0,0,10.016-.768q.368-.224.344-.328T10-1.2H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.6A18.111,18.111,0,0,1,9.328.448q1.936.384,4,.608.208.08.128.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_hu_ㅊ_3.svg': { 
		path: <path d="M13.2,2.128q-.064.16-.3.128-.624-.112-1.416-.272T9.872,1.632q-.832-.176-1.616-.384-.32-.08-.624-.144a36.118,36.118,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L1.7,1.744a.228.228,0,0,1,0-.168.147.147,0,0,1,.112-.1q1.152-.144,2.328-.408T6.416.528Q7.5.192,8.456-.152A11.97,11.97,0,0,0,10.112-.88q.224-.112.216-.192t-.3-.08H3.04a.184.184,0,0,1-.208-.208v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.432.432,0,0,1-.04.48,2.188,2.188,0,0,1-.536.528A13.844,13.844,0,0,1,9.152.528L9.3.56q.624.144,1.3.3.672.128,1.36.248t1.28.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.424a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_hu_ㅌ_3.svg': { 
		path: <path d="M12.448,1.9q0,.224-.192.224H3.92A1.5,1.5,0,0,1,2.952,1.9a1.207,1.207,0,0,1-.248-.9v-3.28a1.207,1.207,0,0,1,.248-.9,1.5,1.5,0,0,1,.968-.224h8.224q.192,0,.192.208v.464q0,.224-.192.224H4.176a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328v.928h8.08a.184.184,0,0,1,.208.208v.512q0,.208-.192.208h-8.1V.8a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_v_ㅊ_3.svg': { 
		path: <path d="M13.776,2.176q-.048.176-.288.16-.624-.08-1.416-.224L10.48,1.824q-.8-.192-1.6-.368-.48-.112-.928-.24A28.492,28.492,0,0,1,2.976,2.48q-.224.032-.272-.112L2.56,1.84q-.08-.24.128-.256a36.9,36.9,0,0,0,4.5-1.12A22.47,22.47,0,0,0,10.656-.944q.224-.128.192-.208t-.24-.08h-6.9a.2.2,0,0,1-.224-.224v-.5q0-.208.224-.208h7.344a2.343,2.343,0,0,1,.976.16.743.743,0,0,1,.416.384.55.55,0,0,1-.072.48,1.552,1.552,0,0,1-.488.48A18.4,18.4,0,0,1,9.408.656q.256.048.56.128.608.112,1.28.24.688.128,1.344.232t1.184.184q.224.016.16.224Zm-3.648-6.3a.2.2,0,0,1,.224.224v.464q0,.24-.224.24H6.08a.212.212,0,0,1-.24-.24V-3.9q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_v_ㅎ_3.svg': { 
		path: <path d="M10.912.9A.633.633,0,0,0,10.7.4a1.545,1.545,0,0,0-.584-.3A3.991,3.991,0,0,0,9.28-.088a7.872,7.872,0,0,0-.944-.056q-.432,0-.9.04a3,3,0,0,0-.84.184,1.636,1.636,0,0,0-.6.312A.637.637,0,0,0,5.76.9a.621.621,0,0,0,.232.488,1.83,1.83,0,0,0,.6.328,4.868,4.868,0,0,0,.84.168,7.681,7.681,0,0,0,.9.056A7.872,7.872,0,0,0,9.28,1.88a4.873,4.873,0,0,0,.832-.168,1.644,1.644,0,0,0,.584-.328A.64.64,0,0,0,10.912.9ZM12.032.88a1.172,1.172,0,0,1-.328.84,2.6,2.6,0,0,1-.824.568A4.347,4.347,0,0,1,9.7,2.632a8.621,8.621,0,0,1-1.336.1,8.775,8.775,0,0,1-1.344-.1,3.91,3.91,0,0,1-1.152-.344,2.358,2.358,0,0,1-.864-.576A1.2,1.2,0,0,1,4.688.88a1.23,1.23,0,0,1,.32-.872A2.414,2.414,0,0,1,5.872-.56,4.27,4.27,0,0,1,7.016-.888a8.519,8.519,0,0,1,1.32-.1A8.115,8.115,0,0,1,9.688-.88a5.309,5.309,0,0,1,1.192.336A2.6,2.6,0,0,1,11.7.024,1.192,1.192,0,0,1,12.032.88Zm1.456-2.512q0,.192-.256.192H3.52A.242.242,0,0,1,3.376-1.5a.2.2,0,0,1-.08-.16v-.448a.206.206,0,0,1,.08-.152.229.229,0,0,1,.144-.072h9.712q.256,0,.256.208ZM10.72-3.2q0,.224-.24.224H6.112a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.9H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-9.12v-3.168A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.7a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.16H9.1q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-8.8v-3.488A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.824a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.84H9.1q-.208,0-.208-.192v-.576Q8.9-8.8,9.1-8.8Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M12.848-4.88a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-8.192H8.88A.184.184,0,0,1,8.672-8.4v-.576q0-.192.208-.192h2.864v-3.1a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅓ_c_3.svg': { 
		path: <path d="M11.776-8.64v-3.648A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-3.9a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.68H9.1q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅕ_(ㄴ)_3.svg': { 
		path: <path d="M11.776-9.712v-2.576A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-1.84a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-5.664h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192h2.8v-2.16h-2.8q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅕ_2.svg': { 
		path: <path d="M11.776-8.7v-3.584A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.352H8.816q-.208,0-.208-.192V-5.1q0-.192.208-.192h2.96V-7.76H8.816q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M6.9-5.664V-7.01H7.984v1.346h6.3a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-5.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M6.9-5.664V-7.01H7.984v1.346h6.3a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-5.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M6.9-5.664V-7.01H7.984v1.346h6.3a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-5.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M6.9-5.664V-7.01H7.984v1.346h6.3a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-5.664Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅗ_2.svg': { 
		path: <path d="M14.56-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.928V-4.192h1.1v2.944H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅗ_c_3.svg': { 
		path: <path d="M8.016-6.4v1.648h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4v-.544A.182.182,0,0,1,.552-4.7.266.266,0,0,1,.72-4.752H6.912V-6.4Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M4.736-6V-7.01H5.808V-6H9.1V-7.01h1.088V-6h4.1a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M4.8-5.632V-7.01H5.888v1.378H9.12V-7.01h1.1v1.378h4.064a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.632Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M4.8-5.632V-7.01H5.888v1.378H9.12V-7.01h1.1v1.378h4.064a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.632Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M4.736-6V-7.01H5.808V-6H9.1V-7.01h1.088V-6h4.1a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅛ_2.svg': { 
		path: <path d="M10.352-4.192v2.944H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.7V-4.192H5.792v2.944H9.264V-4.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅛ_c_3.svg': { 
		path: <path d="M4.8-4.752V-6.4H5.888v1.648H9.12V-6.4h1.1v1.648h4.064a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168V-4a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.206.206,0,0,1,.464-4v-.528A.192.192,0,0,1,.544-4.7.278.278,0,0,1,.7-4.752Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-3.472V-4.9H.72A.245.245,0,0,1,.552-4.96.19.19,0,0,1,.48-5.1v-.544A.182.182,0,0,1,.552-5.8.266.266,0,0,1,.72-5.856h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-5.1a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.424Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M6.944-2.32v-1.7H.72A.245.245,0,0,1,.552-4.08.19.19,0,0,1,.48-4.224v-.544A.182.182,0,0,1,.552-4.92.266.266,0,0,1,.72-4.976h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016V-2.16h-1.1V-3.936H.7A.256.256,0,0,1,.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅠ_(ㅂ)_3.svg': { 
		path: <path d="M9.552-2.256a.2.2,0,0,1-.136-.072A.23.23,0,0,1,9.344-2.5V-3.952H5.552V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072H4.64A.2.2,0,0,1,4.5-2.328.23.23,0,0,1,4.432-2.5V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅌ)_ㅠ_(ㅅ)_3.svg': { 
		path: <path d="M9.552-2.256a.2.2,0,0,1-.136-.072A.23.23,0,0,1,9.344-2.5V-3.952H5.552V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072H4.64A.2.2,0,0,1,4.5-2.328.23.23,0,0,1,4.432-2.5V-3.952H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.9H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H10.464V-2.5a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M13.072,2.064a.322.322,0,0,1-.088.128q-.056.048-.216,0A11.118,11.118,0,0,1,9.552.792a6.266,6.266,0,0,1-2.016-2.04A6.4,6.4,0,0,1,5.592.832,9.936,9.936,0,0,1,2.384,2.288q-.256.064-.32-.08L1.84,1.68A.281.281,0,0,1,1.832,1.5a.176.176,0,0,1,.136-.128A10.908,10.908,0,0,0,3.952.632a8.009,8.009,0,0,0,1.512-.96,4.917,4.917,0,0,0,1.008-1.1,3.018,3.018,0,0,0,.472-1.152l1.1.048a1.873,1.873,0,0,0,.32.816A5.858,5.858,0,0,0,10.28.112a10.587,10.587,0,0,0,2.952,1.152.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_(ㅠ)_ㅇ_3.svg': { 
		path: <path d="M12.48-.208a1.77,1.77,0,0,1-.512,1.32A3.516,3.516,0,0,1,10.7,1.9a6.882,6.882,0,0,1-1.632.376,14.527,14.527,0,0,1-1.576.1q-.464,0-1.016-.04a9.774,9.774,0,0,1-1.1-.144A6.94,6.94,0,0,1,4.3,1.9a3.681,3.681,0,0,1-.912-.472,2.306,2.306,0,0,1-.64-.688,1.8,1.8,0,0,1-.24-.944A1.723,1.723,0,0,1,3.08-1.552a3.859,3.859,0,0,1,1.384-.784s.576-.174,1.1-.272A6.632,6.632,0,0,1,6.576-2.73a7.546,7.546,0,0,1,.912-.04,6.93,6.93,0,0,1,.9.03,6.677,6.677,0,0,1,.984.12c.522.093,1.1.252,1.1.252a5.4,5.4,0,0,1,.776.336,2.777,2.777,0,0,1,.632.456,2.072,2.072,0,0,1,.432.6A1.766,1.766,0,0,1,12.48-.208Zm-1.168.016a.964.964,0,0,0-.432-.832A3.327,3.327,0,0,0,9.832-1.5a6.663,6.663,0,0,0-1.264-.216Q7.92-1.76,7.488-1.76q-.448,0-1.1.048A6.359,6.359,0,0,0,5.144-1.5a3.345,3.345,0,0,0-1.032.472.964.964,0,0,0-.432.832.97.97,0,0,0,.432.824A3.115,3.115,0,0,0,5.144,1.1a6.776,6.776,0,0,0,1.248.208q.648.048,1.1.048.432,0,1.08-.048A7.105,7.105,0,0,0,9.832,1.1,3.1,3.1,0,0,0,10.88.632.97.97,0,0,0,11.312-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_hd_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A44.654,44.654,0,0,1,7.824.848,30.4,30.4,0,0,1,2.016,2.176q-.16.064-.216.016a.338.338,0,0,1-.088-.176l-.1-.48a.187.187,0,0,1,.008-.16.167.167,0,0,1,.12-.1A36.172,36.172,0,0,0,10.016-.928q.368-.224.344-.328T10-1.376H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.584A18.685,18.685,0,0,1,9.2.336q2,.432,4.128.72.208.064.128.24Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_hu_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.32a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-2.64a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.92H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568V-1.68a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅓ_c_3.svg': { 
		path: <path d="M11.776-9.376v-2.912A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.44a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-8.416h-2.3q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.816q0,.224-.24.224h-.656a.2.2,0,0,1-.224-.224V-7.072H8.96q-.208,0-.208-.176v-.56Q8.752-8,8.96-8h2.784V-9.616H8.96q-.208,0-.208-.176v-.56a.184.184,0,0,1,.208-.208h2.784v-1.7a.2.2,0,0,1,.224-.224h.656q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅕ_2.svg': { 
		path: <path d="M11.776-9.184v-3.1A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.5h-2.4q-.208,0-.208-.192v-.56q0-.192.208-.192h2.4v-2.8h-2.4q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅕ_c_3.svg': { 
		path: <path d="M11.776-10.24v-2.048A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.32a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.736H9.328q-.208,0-.208-.192v-.56q0-.192.208-.192h2.448V-9.3H9.328q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M8.064-7.6v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1H6.96V-7.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M8.064-7.6v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1H6.96V-7.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M8.064-7.6v1.5H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.2.19.19,0,0,1,.48-5.344v-.544A.182.182,0,0,1,.552-6.04.266.266,0,0,1,.72-6.1H6.96V-7.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅗ_2.svg': { 
		path: <path d="M8.032-4.608v3.36H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.928v-3.36Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅗ_c_3.svg': { 
		path: <path d="M8.048-7.072V-5.3H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-4.4.19.19,0,0,1,.48-4.544V-5.1a.162.162,0,0,1,.072-.144A.3.3,0,0,1,.72-5.3H6.944V-7.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M10.384-7.616v1.6H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.12.19.19,0,0,1,.48-5.264v-.544A.182.182,0,0,1,.552-5.96.266.266,0,0,1,.72-6.016H4.576v-1.6H5.664v1.6H9.3v-1.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M10.384-7.616v1.6H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.12.19.19,0,0,1,.48-5.264v-.544A.182.182,0,0,1,.552-5.96.266.266,0,0,1,.72-6.016H4.576v-1.6H5.664v1.6H9.3v-1.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M10.384-7.616v1.6H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.12.19.19,0,0,1,.48-5.264v-.544A.182.182,0,0,1,.552-5.96.266.266,0,0,1,.72-6.016H4.576v-1.6H5.664v1.6H9.3v-1.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M10.384-7.616v1.6H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-5.12.19.19,0,0,1,.48-5.264v-.544A.182.182,0,0,1,.552-5.96.266.266,0,0,1,.72-6.016H4.576v-1.6H5.664v1.6H9.3v-1.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅛ_2.svg': { 
		path: <path d="M10.5-4.832v3.584H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248h3.7V-4.832H5.5v3.584H9.41V-4.832Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅛ_c_3.svg': { 
		path: <path d="M4.816-5.04v-1.9H5.9v1.9H9.088v-1.9h1.088v1.9h4.112A.379.379,0,0,1,14.44-5a.151.151,0,0,1,.088.152V-4.3a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H.7a.241.241,0,0,1-.16-.072A.206.206,0,0,1,.464-4.3v-.544A.154.154,0,0,1,.544-5,.355.355,0,0,1,.7-5.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅍ)_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-4.9a.3.3,0,0,1,.152.056.2.2,0,0,1,.088.184v.528A.172.172,0,0,1,14.44-4a.275.275,0,0,1-.152.064H8.016v1.6h-1.1v-1.6H.7A.256.256,0,0,1,.544-4a.173.173,0,0,1-.08-.128v-.528a.209.209,0,0,1,.08-.184A.278.278,0,0,1,.7-4.9Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㄱ_3.svg': { 
		path: <path d="M3.552-2.112a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h7.872a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.08a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-1.008q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.792a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㄷ_3.svg': { 
		path: <path d="M13.04,1.9a.184.184,0,0,1-.208.208H4.96a1.5,1.5,0,0,1-.968-.224,1.207,1.207,0,0,1-.248-.9V-1.376a1.207,1.207,0,0,1,.248-.9A1.5,1.5,0,0,1,4.96-2.5h7.584q.208,0,.208.192v.528q0,.224-.208.224H5.216a.379.379,0,0,0-.288.088.5.5,0,0,0-.08.328V.752a.477.477,0,0,0,.08.3.344.344,0,0,0,.288.1h7.616q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㄹ_3.svg': { 
		path: <path d="M13.216,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.96a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952V.192a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-1.92a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.04a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.9a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H5.184q-.224,0-.28.064a.524.524,0,0,0-.056.3v.64a.473.473,0,0,0,.064.3A.4.4,0,0,0,5.2,1.36h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅁ_3.svg': { 
		path: <path d="M3.744-1.248A1.212,1.212,0,0,1,4-2.152a1.464,1.464,0,0,1,.96-.232h6.7a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H4.96a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,3.744.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.16a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.008a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅂ_3.svg': { 
		path: <path d="M4.944,2.112a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352A2.616,2.616,0,0,1,3.744.976V-3.04a.152.152,0,0,1,.064-.128.234.234,0,0,1,.144-.048h.7a.185.185,0,0,1,.136.056.168.168,0,0,1,.056.12v1.424h6.928V-3.008a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.7a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V1.088a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.1-2.768V.768a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.16a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-.656Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅅ_3.svg': { 
		path: <path d="M8.976-2.672a1.729,1.729,0,0,1-.08.48,2.328,2.328,0,0,0,.4.688,6.25,6.25,0,0,0,2.04,1.72A7.767,7.767,0,0,0,14.1,1.1a.122.122,0,0,1,.112.1.3.3,0,0,1-.016.168l-.224.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008,8.49,8.49,0,0,1-5.3-3.2A7.327,7.327,0,0,1,6.32.76,11.563,11.563,0,0,1,3.3,2.128q-.256.08-.32-.08l-.224-.592a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A16.043,16.043,0,0,0,4.7.5,8.484,8.484,0,0,0,6.088-.248a5.067,5.067,0,0,0,1.016-.9,4.646,4.646,0,0,0,.688-1.08,1.16,1.16,0,0,0,.056-.248q.024-.168.04-.3a.225.225,0,0,1,.064-.16A.227.227,0,0,1,8.08-2.96l.752.064Q8.976-2.88,8.976-2.672Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅇ_3.svg': { 
		path: <path d="M12.992-.16A1.547,1.547,0,0,1,12.624.848a3.055,3.055,0,0,1-1,.768,5.958,5.958,0,0,1-1.472.5,8.77,8.77,0,0,1-1.8.176,9.316,9.316,0,0,1-1.888-.176,5.544,5.544,0,0,1-1.456-.5A2.759,2.759,0,0,1,4.064.848,1.638,1.638,0,0,1,3.728-.16a1.581,1.581,0,0,1,.336-.984,2.806,2.806,0,0,1,.944-.76,5.544,5.544,0,0,1,1.456-.5,9.316,9.316,0,0,1,1.888-.176,9.173,9.173,0,0,1,1.8.168,5.746,5.746,0,0,1,1.472.488,3.055,3.055,0,0,1,1,.768A1.526,1.526,0,0,1,12.992-.16Zm-1.136.048a.9.9,0,0,0-.288-.656,2.334,2.334,0,0,0-.776-.488,5.163,5.163,0,0,0-1.128-.3,8.27,8.27,0,0,0-1.328-.1,8.807,8.807,0,0,0-1.408.1,4.563,4.563,0,0,0-1.1.3,2.021,2.021,0,0,0-.712.488.96.96,0,0,0-.256.656A.912.912,0,0,0,5.12.52a2,2,0,0,0,.712.472,4.6,4.6,0,0,0,1.1.288,9.527,9.527,0,0,0,1.408.1,8.945,8.945,0,0,0,1.328-.1A5.19,5.19,0,0,0,10.792.992,2.315,2.315,0,0,0,11.568.52.862.862,0,0,0,11.856-.112Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅈ_3.svg': { 
		path: <path d="M14.192,1.84q-.08.176-.3.112-1.36-.24-2.624-.592Q10.24,1.072,9.184.688a24.374,24.374,0,0,1-4.864,1.5q-.224.1-.3-.08L3.824,1.6q-.08-.24.16-.256A29.425,29.425,0,0,0,8.1.1a15.3,15.3,0,0,0,3.032-1.56q.24-.16.216-.248t-.264-.088h-6.3A.184.184,0,0,1,4.576-2v-.528a.184.184,0,0,1,.208-.208h6.7q1.3,0,1.472.368t-.56,1.12A8.369,8.369,0,0,1,10.368.128q.784.24,1.712.464,1.1.3,2.144.512.224.016.192.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅊ_3.svg': { 
		path: <path d="M14.208,2.128q-.064.16-.32.128-.608-.112-1.4-.272t-1.624-.352q-.832-.176-1.6-.384-.32-.08-.624-.144a36.746,36.746,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L2.7,1.744a.141.141,0,0,1-.016-.168.188.188,0,0,1,.128-.1q1.152-.144,2.328-.408T7.424.528Q8.5.192,9.456-.152A11.819,11.819,0,0,0,11.12-.88q.224-.112.208-.192t-.288-.08H4.048A.184.184,0,0,1,3.84-1.36v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.467.467,0,0,1-.048.48,1.981,1.981,0,0,1-.544.528A13,13,0,0,1,10.144.528L10.3.56q.624.144,1.3.3.656.128,1.352.248t1.288.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H6.432q-.256,0-.256-.24v-.464q0-.224.256-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅋ_3.svg': { 
		path: <path d="M3.536-2.464q0-.064.088-.112a.352.352,0,0,1,.168-.048h7.84a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.048a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.416H4.032q-.224,0-.224-.176V-.272A.184.184,0,0,1,4.016-.48h7.728v-.864q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.792a.315.315,0,0,1-.168-.056q-.088-.056-.088-.12Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅌ_3.svg': { 
		path: <path d="M13.2,2.032q0,.224-.192.224H5.1a1.471,1.471,0,0,1-.944-.224,1.182,1.182,0,0,1-.256-.9V-1.9a1.182,1.182,0,0,1,.256-.9A1.471,1.471,0,0,1,5.1-3.024H12.72q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H5.408a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328v.9h7.616q.224,0,.224.192V-.1a.184.184,0,0,1-.208.208H5.008V.928a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅍ_3.svg': { 
		path: <path d="M13.248-1.824q0,.208-.24.208h-1.7V1.152H13.5q.24,0,.24.208V1.9q0,.208-.24.208H3.3A.257.257,0,0,1,3.16,2.04a.2.2,0,0,1-.088-.152V1.376a.192.192,0,0,1,.088-.16A.27.27,0,0,1,3.3,1.152H5.552V-1.616H3.808a.206.206,0,0,1-.152-.08.229.229,0,0,1-.072-.144v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.2q.24,0,.24.208ZM6.624,1.152h3.6V-1.616h-3.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅓ)_ㅎ_3.svg': { 
		path: <path d="M10.912,1.12A.5.5,0,0,0,10.7.672a2.24,2.24,0,0,0-.584-.256A4.432,4.432,0,0,0,9.28.256,9.169,9.169,0,0,0,8.336.208a8.945,8.945,0,0,0-.9.048,4.439,4.439,0,0,0-.84.16,1.428,1.428,0,0,0-.6.232.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.027,2.027,0,0,0,.6.28,4.185,4.185,0,0,0,.84.12q.472.024.9.024.48,0,.944-.032a4.549,4.549,0,0,0,.832-.128A1.925,1.925,0,0,0,10.7,1.52.481.481,0,0,0,10.912,1.12Zm1.12,0a.984.984,0,0,1-.328.752,3.043,3.043,0,0,1-.824.528A4.577,4.577,0,0,1,9.7,2.688a11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,5.872,2.4a2.691,2.691,0,0,1-.864-.536,1,1,0,0,1-.32-.744,1.066,1.066,0,0,1,.32-.784,2.43,2.43,0,0,1,.864-.528A4.122,4.122,0,0,1,7.016-.48a11.026,11.026,0,0,1,1.32-.08,10.28,10.28,0,0,1,1.352.088,5.67,5.67,0,0,1,1.192.28A2.936,2.936,0,0,1,11.7.344,1.027,1.027,0,0,1,12.032,1.12Zm1.456-2.48q0,.208-.256.208H3.52a.26.26,0,0,1-.144-.056A.176.176,0,0,1,3.3-1.36v-.448a.213.213,0,0,1,.08-.176.242.242,0,0,1,.144-.064h9.712q.256,0,.256.224ZM10.72-2.992a.212.212,0,0,1-.24.24H6.112a.26.26,0,0,1-.144-.056.192.192,0,0,1-.08-.168v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.7H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㄱ_3.svg': { 
		path: <path d="M3.552-2.112a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h7.872a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.08a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-1.008q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.792a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㄷ_3.svg': { 
		path: <path d="M13.04,1.9a.184.184,0,0,1-.208.208H4.96a1.5,1.5,0,0,1-.968-.224,1.207,1.207,0,0,1-.248-.9V-1.376a1.207,1.207,0,0,1,.248-.9A1.5,1.5,0,0,1,4.96-2.5h7.584q.208,0,.208.192v.528q0,.224-.208.224H5.216a.379.379,0,0,0-.288.088.5.5,0,0,0-.08.328V.752a.477.477,0,0,0,.08.3.344.344,0,0,0,.288.1h7.616q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㄹ_3.svg': { 
		path: <path d="M13.216,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.96a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952V.192a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-1.92a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.04a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.9a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H5.184q-.224,0-.28.064a.524.524,0,0,0-.056.3v.64a.473.473,0,0,0,.064.3A.4.4,0,0,0,5.2,1.36h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅁ_3.svg': { 
		path: <path d="M3.744-1.248A1.212,1.212,0,0,1,4-2.152a1.464,1.464,0,0,1,.96-.232h6.7a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H4.96a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,3.744.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.16a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.008a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅂ_3.svg': { 
		path: <path d="M4.944,2.112a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352A2.616,2.616,0,0,1,3.744.976V-3.04a.152.152,0,0,1,.064-.128.234.234,0,0,1,.144-.048h.7a.185.185,0,0,1,.136.056.168.168,0,0,1,.056.12v1.424h6.928V-3.008a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.7a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V1.088a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.1-2.768V.768a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.16a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-.656Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅈ_3.svg': { 
		path: <path d="M14.192,1.84q-.08.176-.3.112-1.36-.24-2.624-.592Q10.24,1.072,9.184.688a24.374,24.374,0,0,1-4.864,1.5q-.224.1-.3-.08L3.824,1.6q-.08-.24.16-.256A29.425,29.425,0,0,0,8.1.1a15.3,15.3,0,0,0,3.032-1.56q.24-.16.216-.248t-.264-.088h-6.3A.184.184,0,0,1,4.576-2v-.528a.184.184,0,0,1,.208-.208h6.7q1.3,0,1.472.368t-.56,1.12A8.369,8.369,0,0,1,10.368.128q.784.24,1.712.464,1.1.3,2.144.512.224.016.192.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅊ_3.svg': { 
		path: <path d="M14.208,2.128q-.064.16-.32.128-.608-.112-1.4-.272t-1.624-.352q-.832-.176-1.6-.384-.32-.08-.624-.144a36.746,36.746,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L2.7,1.744a.141.141,0,0,1-.016-.168.188.188,0,0,1,.128-.1q1.152-.144,2.328-.408T7.424.528Q8.5.192,9.456-.152A11.819,11.819,0,0,0,11.12-.88q.224-.112.208-.192t-.288-.08H4.048A.184.184,0,0,1,3.84-1.36v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.467.467,0,0,1-.048.48,1.981,1.981,0,0,1-.544.528A13,13,0,0,1,10.144.528L10.3.56q.624.144,1.3.3.656.128,1.352.248t1.288.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H6.432q-.256,0-.256-.24v-.464q0-.224.256-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅋ_3.svg': { 
		path: <path d="M3.536-2.464q0-.064.088-.112a.352.352,0,0,1,.168-.048h7.84a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.048a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.416H4.032q-.224,0-.224-.176V-.272A.184.184,0,0,1,4.016-.48h7.728v-.864q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.792a.315.315,0,0,1-.168-.056q-.088-.056-.088-.12Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅌ_3.svg': { 
		path: <path d="M13.2,2.032q0,.224-.192.224H5.1a1.471,1.471,0,0,1-.944-.224,1.182,1.182,0,0,1-.256-.9V-1.9a1.182,1.182,0,0,1,.256-.9A1.471,1.471,0,0,1,5.1-3.024H12.72q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H5.408a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328v.9h7.616q.224,0,.224.192V-.1a.184.184,0,0,1-.208.208H5.008V.928a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅍ_3.svg': { 
		path: <path d="M13.248-1.824q0,.208-.24.208h-1.7V1.152H13.5q.24,0,.24.208V1.9q0,.208-.24.208H3.3A.257.257,0,0,1,3.16,2.04a.2.2,0,0,1-.088-.152V1.376a.192.192,0,0,1,.088-.16A.27.27,0,0,1,3.3,1.152H5.552V-1.616H3.808a.206.206,0,0,1-.152-.08.229.229,0,0,1-.072-.144v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.2q.24,0,.24.208ZM6.624,1.152h3.6V-1.616h-3.6Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅕ)_ㅎ_3.svg': { 
		path: <path d="M10.912,1.12A.5.5,0,0,0,10.7.672a2.24,2.24,0,0,0-.584-.256A4.432,4.432,0,0,0,9.28.256,9.169,9.169,0,0,0,8.336.208a8.945,8.945,0,0,0-.9.048,4.439,4.439,0,0,0-.84.16,1.428,1.428,0,0,0-.6.232.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.027,2.027,0,0,0,.6.28,4.185,4.185,0,0,0,.84.12q.472.024.9.024.48,0,.944-.032a4.549,4.549,0,0,0,.832-.128A1.925,1.925,0,0,0,10.7,1.52.481.481,0,0,0,10.912,1.12Zm1.12,0a.984.984,0,0,1-.328.752,3.043,3.043,0,0,1-.824.528A4.577,4.577,0,0,1,9.7,2.688a11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,5.872,2.4a2.691,2.691,0,0,1-.864-.536,1,1,0,0,1-.32-.744,1.066,1.066,0,0,1,.32-.784,2.43,2.43,0,0,1,.864-.528A4.122,4.122,0,0,1,7.016-.48a11.026,11.026,0,0,1,1.32-.08,10.28,10.28,0,0,1,1.352.088,5.67,5.67,0,0,1,1.192.28A2.936,2.936,0,0,1,11.7.344,1.027,1.027,0,0,1,12.032,1.12Zm1.456-2.48q0,.208-.256.208H3.52a.26.26,0,0,1-.144-.056A.176.176,0,0,1,3.3-1.36v-.448a.213.213,0,0,1,.08-.176.242.242,0,0,1,.144-.064h9.712q.256,0,.256.224ZM10.72-2.992a.212.212,0,0,1-.24.24H6.112a.26.26,0,0,1-.144-.056.192.192,0,0,1-.08-.168v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.7H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅗ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅗ)_ㅎ_3.svg': { 
		path: <path d="M10.016,1.344A.445.445,0,0,0,9.792.968,1.359,1.359,0,0,0,9.2.768,5.018,5.018,0,0,0,8.368.632Q7.9.592,7.44.592q-.448,0-.92.032A3.516,3.516,0,0,0,5.7.768a1.446,1.446,0,0,0-.616.2.443.443,0,0,0-.232.376A.39.39,0,0,0,5.08,1.7,2.6,2.6,0,0,0,5.7,1.92a4.873,4.873,0,0,0,.824.1q.472.024.92.024.464,0,.928-.024A4.158,4.158,0,0,0,9.2,1.9a2.743,2.743,0,0,0,.592-.216A.375.375,0,0,0,10.016,1.344Zm1.1,0a.856.856,0,0,1-.32.664,2.928,2.928,0,0,1-.832.488,7.084,7.084,0,0,1-1.176.248,10.158,10.158,0,0,1-1.336.088,10.34,10.34,0,0,1-1.344-.088A6.247,6.247,0,0,1,4.96,2.5,2.75,2.75,0,0,1,4.1,2a.864.864,0,0,1-.32-.656A.9.9,0,0,1,4.1.632,2.632,2.632,0,0,1,4.96.16,4.355,4.355,0,0,1,6.1-.1,12.384,12.384,0,0,1,7.44-.176,11.159,11.159,0,0,1,8.776-.1,5.367,5.367,0,0,1,9.968.176,2.785,2.785,0,0,1,10.8.64.881.881,0,0,1,11.12,1.344ZM12.592-.864q0,.224-.256.224H2.624A.275.275,0,0,1,2.472-.7.206.206,0,0,1,2.384-.88v-.448a.216.216,0,0,1,.088-.144.248.248,0,0,1,.152-.08h9.712q.256,0,.256.208ZM9.808-2.384q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.464a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅛ)_ㅅ_3.svg': { 
		path: <path d="M8.032-2.3a.776.776,0,0,0-.016.152A.776.776,0,0,1,8-2a2.056,2.056,0,0,0,.144.432,2.263,2.263,0,0,0,.224.384,3.726,3.726,0,0,0,.824.848A7.364,7.364,0,0,0,10.36.392a8.606,8.606,0,0,0,1.384.552,9.029,9.029,0,0,0,1.488.32.122.122,0,0,1,.112.1.3.3,0,0,1-.016.168l-.256.528a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008A11.572,11.572,0,0,1,9.5,1.008,5.812,5.812,0,0,1,7.488-.736,5.9,5.9,0,0,1,5.632.92,14.315,14.315,0,0,1,2.384,2.288q-.256.08-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A11.168,11.168,0,0,0,5.712-.272a2.745,2.745,0,0,0,1.264-2.08.17.17,0,0,1,.192-.192l.736.016Q8.048-2.528,8.032-2.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅛ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅛ)_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-1.9a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-3.04h8.224q.192,0,.192.208v.48q0,.224-.192.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v.768h8.08q.208,0,.208.192v.544A.17.17,0,0,1,11.872,0h-8.1V.784a.533.533,0,0,0,.088.344.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅛ)_ㅎ_3.svg': { 
		path: <path d="M10.016,1.344A.445.445,0,0,0,9.792.968,1.359,1.359,0,0,0,9.2.768,5.018,5.018,0,0,0,8.368.632Q7.9.592,7.44.592q-.448,0-.92.032A3.516,3.516,0,0,0,5.7.768a1.446,1.446,0,0,0-.616.2.443.443,0,0,0-.232.376A.39.39,0,0,0,5.08,1.7,2.6,2.6,0,0,0,5.7,1.92a4.873,4.873,0,0,0,.824.1q.472.024.92.024.464,0,.928-.024A4.158,4.158,0,0,0,9.2,1.9a2.743,2.743,0,0,0,.592-.216A.375.375,0,0,0,10.016,1.344Zm1.1,0a.856.856,0,0,1-.32.664,2.928,2.928,0,0,1-.832.488,7.084,7.084,0,0,1-1.176.248,10.158,10.158,0,0,1-1.336.088,10.34,10.34,0,0,1-1.344-.088A6.247,6.247,0,0,1,4.96,2.5,2.75,2.75,0,0,1,4.1,2a.864.864,0,0,1-.32-.656A.9.9,0,0,1,4.1.632,2.632,2.632,0,0,1,4.96.16,4.355,4.355,0,0,1,6.1-.1,12.384,12.384,0,0,1,7.44-.176,11.159,11.159,0,0,1,8.776-.1,5.367,5.367,0,0,1,9.968.176,2.785,2.785,0,0,1,10.8.64.881.881,0,0,1,11.12,1.344ZM12.592-.864q0,.224-.256.224H2.624A.275.275,0,0,1,2.472-.7.206.206,0,0,1,2.384-.88v-.448a.216.216,0,0,1,.088-.144.248.248,0,0,1,.152-.08h9.712q.256,0,.256.208ZM9.808-2.384q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.464a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M12.656,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V.336a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.9q.224,0,.28-.064a.524.524,0,0,0,.056-.3V-1.5a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-2.784H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.592a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.288a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M13.072,2.064a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008A11.572,11.572,0,0,1,9.5,1.008,5.812,5.812,0,0,1,7.488-.736,5.687,5.687,0,0,1,5.64.92,14.5,14.5,0,0,1,2.384,2.288q-.256.08-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A12.325,12.325,0,0,0,5.488-.12a3.059,3.059,0,0,0,1.44-1.848l1.1.1a1.619,1.619,0,0,0,.136.36,3,3,0,0,0,.2.328,3.726,3.726,0,0,0,.824.848A7.364,7.364,0,0,0,10.36.392a8.606,8.606,0,0,0,1.384.552,9.029,9.029,0,0,0,1.488.32.122.122,0,0,1,.112.1.3.3,0,0,1-.016.168Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㄱ_3.svg': { 
		path: <path d="M2.368-1.712a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.5a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176A.2.2,0,0,1,12.1,2.4H11.44a.2.2,0,0,1-.224-.224V-.608q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.608A.278.278,0,0,1,2.448-1a.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㄴ_3.svg': { 
		path: <path d="M12.848,1.76a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H3.7a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.48.672V-1.76A.2.2,0,0,1,2.7-1.984H3.36a.2.2,0,0,1,.224.224V.656q0,.224.072.28a.527.527,0,0,0,.3.056h8.656a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㄷ_3.svg': { 
		path: <path d="M12.464,1.888q0,.224-.208.224H3.872a1.431,1.431,0,0,1-.944-.224,1.235,1.235,0,0,1-.24-.9v-2a1.235,1.235,0,0,1,.24-.9,1.431,1.431,0,0,1,.944-.224h8.176a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.16a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V.752a.414.414,0,0,0,.088.288.412.412,0,0,0,.312.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅁ_3.svg': { 
		path: <path d="M2.688-.88a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-.64a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅂ_3.svg': { 
		path: <path d="M3.76-.256V.8q0,.24.08.3a.573.573,0,0,0,.3.056h6.672a.527.527,0,0,0,.3-.056q.072-.056.072-.3V-.256Zm.112,2.368a3.161,3.161,0,0,1-.616-.048.669.669,0,0,1-.344-.16.754.754,0,0,1-.184-.368,2.986,2.986,0,0,1-.04-.544V-2.336a.144.144,0,0,1,.056-.112A.2.2,0,0,1,2.88-2.5h.672A.205.205,0,0,1,3.7-2.44a.156.156,0,0,1,.056.1v1.12h7.424V-2.3a.185.185,0,0,1,.056-.136.205.205,0,0,1,.152-.056h.672a.226.226,0,0,1,.152.048.144.144,0,0,1,.056.112V1.1a1,1,0,0,1-.256.8,1.558,1.558,0,0,1-.944.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅇ_3.svg': { 
		path: <path d="M12.48.048a1.5,1.5,0,0,1-.512,1.176,3.735,3.735,0,0,1-1.264.72A7.3,7.3,0,0,1,9.08,2.3a14.648,14.648,0,0,1-1.592.1q-.48,0-1.032-.04a9.774,9.774,0,0,1-1.1-.144,8.394,8.394,0,0,1-1.064-.272,3.641,3.641,0,0,1-.9-.432A2.273,2.273,0,0,1,2.752.888a1.479,1.479,0,0,1-.24-.84,1.451,1.451,0,0,1,.24-.824A2.273,2.273,0,0,1,3.384-1.4a3.641,3.641,0,0,1,.9-.432A8.394,8.394,0,0,1,5.352-2.1a9.774,9.774,0,0,1,1.1-.144q.552-.04,1.032-.04a14.648,14.648,0,0,1,1.592.1,7.441,7.441,0,0,1,1.632.36,3.708,3.708,0,0,1,1.264.712A1.489,1.489,0,0,1,12.48.048ZM11.312.064a.821.821,0,0,0-.432-.72,3.42,3.42,0,0,0-1.04-.416,7.425,7.425,0,0,0-1.256-.184q-.648-.04-1.1-.04-.464,0-1.112.04a7.253,7.253,0,0,0-1.248.184A3.319,3.319,0,0,0,4.1-.656a.824.824,0,0,0-.424.72A.794.794,0,0,0,4.1.76a3.452,3.452,0,0,0,1.024.408,7.813,7.813,0,0,0,1.248.192q.648.048,1.112.048.3,0,.7-.016t.824-.072q.424-.056.832-.144a3.5,3.5,0,0,0,.736-.24,1.659,1.659,0,0,0,.528-.368A.718.718,0,0,0,11.312.064Z" transform="translate(774 2207.238)"/>, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A35.824,35.824,0,0,1,7.92.96a28.553,28.553,0,0,1-5.9,1.232q-.16.048-.216,0a.323.323,0,0,1-.088-.16l-.1-.48a.226.226,0,0,1,.008-.176.13.13,0,0,1,.12-.1A32.028,32.028,0,0,0,10.016-.768q.368-.224.344-.328T10-1.2H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.6A18.111,18.111,0,0,1,9.328.448q1.936.384,4,.608.208.08.128.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-1.9a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-3.04h8.224q.192,0,.192.208v.48q0,.224-.192.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v.768h8.08q.208,0,.208.192v.544A.17.17,0,0,1,11.872,0h-8.1V.784a.533.533,0,0,0,.088.344.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅍ_3.svg': { 
		path: <path d="M12.5-1.04q0,.208-.24.208H10.464v2h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256A.226.226,0,0,1,2.1,2.048a.205.205,0,0,1-.072-.16v-.5A.2.2,0,0,1,2.1,1.224a.244.244,0,0,1,.152-.056H4.464v-2H2.576a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.208ZM5.552,1.168H9.376v-2H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅠ)_ㅎ_3.svg': { 
		path: <path d="M10.016,1.344A.445.445,0,0,0,9.792.968,1.359,1.359,0,0,0,9.2.768,5.018,5.018,0,0,0,8.368.632Q7.9.592,7.44.592q-.448,0-.92.032A3.516,3.516,0,0,0,5.7.768a1.446,1.446,0,0,0-.616.2.443.443,0,0,0-.232.376A.39.39,0,0,0,5.08,1.7,2.6,2.6,0,0,0,5.7,1.92a4.873,4.873,0,0,0,.824.1q.472.024.92.024.464,0,.928-.024A4.158,4.158,0,0,0,9.2,1.9a2.743,2.743,0,0,0,.592-.216A.375.375,0,0,0,10.016,1.344Zm1.1,0a.856.856,0,0,1-.32.664,2.928,2.928,0,0,1-.832.488,7.084,7.084,0,0,1-1.176.248,10.158,10.158,0,0,1-1.336.088,10.34,10.34,0,0,1-1.344-.088A6.247,6.247,0,0,1,4.96,2.5,2.75,2.75,0,0,1,4.1,2a.864.864,0,0,1-.32-.656A.9.9,0,0,1,4.1.632,2.632,2.632,0,0,1,4.96.16,4.355,4.355,0,0,1,6.1-.1,12.384,12.384,0,0,1,7.44-.176,11.159,11.159,0,0,1,8.776-.1,5.367,5.367,0,0,1,9.968.176,2.785,2.785,0,0,1,10.8.64.881.881,0,0,1,11.12,1.344ZM12.592-.864q0,.224-.256.224H2.624A.275.275,0,0,1,2.472-.7.206.206,0,0,1,2.384-.88v-.448a.216.216,0,0,1,.088-.144.248.248,0,0,1,.152-.08h9.712q.256,0,.256.208ZM9.808-2.384q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.464a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㄷ_3.svg': { 
		path: <path d="M12.512,1.888q0,.224-.208.224H3.9a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-.992a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H12.3a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㄹ_3.svg': { 
		path: <path d="M12.656,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V.256a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.9q.224,0,.28-.064a.524.524,0,0,0,.056-.3V-1.7A.524.524,0,0,0,11.16-2q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-2.8a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-2.976H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.7a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9Q3.9.032,3.848.1a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.288a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅁ_3.svg': { 
		path: <path d="M2.688-.992a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976ZM4.176-1.2a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-.784a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅅ_3.svg': { 
		path: <path d="M8.032-2.3a.776.776,0,0,0-.016.152A.776.776,0,0,1,8-2a2.056,2.056,0,0,0,.144.432,2.263,2.263,0,0,0,.224.384,3.726,3.726,0,0,0,.824.848A7.364,7.364,0,0,0,10.36.392a8.606,8.606,0,0,0,1.384.552,9.029,9.029,0,0,0,1.488.32.122.122,0,0,1,.112.1.3.3,0,0,1-.016.168l-.256.528a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008A11.572,11.572,0,0,1,9.5,1.008,5.812,5.812,0,0,1,7.488-.736,5.9,5.9,0,0,1,5.632.92,14.315,14.315,0,0,1,2.384,2.288q-.256.08-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A11.168,11.168,0,0,0,5.712-.272a2.745,2.745,0,0,0,1.264-2.08.17.17,0,0,1,.192-.192l.736.016Q8.048-2.528,8.032-2.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅇ_3.svg': { 
		path: <path d="M12.48.048a1.5,1.5,0,0,1-.512,1.176,3.735,3.735,0,0,1-1.264.72A7.3,7.3,0,0,1,9.08,2.3a14.648,14.648,0,0,1-1.592.1q-.48,0-1.032-.04a9.774,9.774,0,0,1-1.1-.144,8.394,8.394,0,0,1-1.064-.272,3.641,3.641,0,0,1-.9-.432A2.273,2.273,0,0,1,2.752.888a1.479,1.479,0,0,1-.24-.84,1.451,1.451,0,0,1,.24-.824A2.273,2.273,0,0,1,3.384-1.4a3.641,3.641,0,0,1,.9-.432A8.394,8.394,0,0,1,5.352-2.1a9.774,9.774,0,0,1,1.1-.144q.552-.04,1.032-.04a14.648,14.648,0,0,1,1.592.1,7.441,7.441,0,0,1,1.632.36,3.708,3.708,0,0,1,1.264.712A1.489,1.489,0,0,1,12.48.048ZM11.312.064a.821.821,0,0,0-.432-.72,3.42,3.42,0,0,0-1.04-.416,7.425,7.425,0,0,0-1.256-.184q-.648-.04-1.1-.04-.464,0-1.112.04a7.253,7.253,0,0,0-1.248.184A3.319,3.319,0,0,0,4.1-.656a.824.824,0,0,0-.424.72A.794.794,0,0,0,4.1.76a3.452,3.452,0,0,0,1.024.408,7.813,7.813,0,0,0,1.248.192q.648.048,1.112.048.3,0,.7-.016t.824-.072q.424-.056.832-.144a3.5,3.5,0,0,0,.736-.24,1.659,1.659,0,0,0,.528-.368A.718.718,0,0,0,11.312.064Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A35.824,35.824,0,0,1,7.92.96a28.553,28.553,0,0,1-5.9,1.232q-.16.048-.216,0a.323.323,0,0,1-.088-.16l-.1-.48a.226.226,0,0,1,.008-.176.13.13,0,0,1,.12-.1A32.028,32.028,0,0,0,10.016-.768q.368-.224.344-.328T10-1.2H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.6A18.111,18.111,0,0,1,9.328.448q1.936.384,4,.608.208.08.128.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅋ_3.svg': { 
		path: <path d="M2.368-2.016a.172.172,0,0,1,.08-.16.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.16a.2.2,0,0,1-.224.224h-.656A.2.2,0,0,1,11.2,2.16V.736H2.864q-.208,0-.208-.176V.016a.184.184,0,0,1,.208-.208H11.2v-.72q0-.24-.08-.3a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅌ_3.svg': { 
		path: <path d="M12.1-.192A.17.17,0,0,1,11.9,0H3.792V.8a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-1.9a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.24a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v.784h8.1q.208,0,.208.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅍ_3.svg': { 
		path: <path d="M12.5-1.04q0,.208-.24.208H10.464v2h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256A.226.226,0,0,1,2.1,2.048a.205.205,0,0,1-.072-.16v-.5A.2.2,0,0,1,2.1,1.224a.244.244,0,0,1,.152-.056H4.464v-2H2.576a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.208ZM5.552,1.168H9.376v-2H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅡ)_ㅎ_3.svg': { 
		path: <path d="M10.016,1.344A.445.445,0,0,0,9.792.968,1.359,1.359,0,0,0,9.2.768,5.018,5.018,0,0,0,8.368.632Q7.9.592,7.44.592q-.448,0-.92.032A3.516,3.516,0,0,0,5.7.768a1.446,1.446,0,0,0-.616.2.443.443,0,0,0-.232.376A.39.39,0,0,0,5.08,1.7,2.6,2.6,0,0,0,5.7,1.92a4.873,4.873,0,0,0,.824.1q.472.024.92.024.464,0,.928-.024A4.158,4.158,0,0,0,9.2,1.9a2.743,2.743,0,0,0,.592-.216A.375.375,0,0,0,10.016,1.344Zm1.1,0a.856.856,0,0,1-.32.664,2.928,2.928,0,0,1-.832.488,7.084,7.084,0,0,1-1.176.248,10.158,10.158,0,0,1-1.336.088,10.34,10.34,0,0,1-1.344-.088A6.247,6.247,0,0,1,4.96,2.5,2.75,2.75,0,0,1,4.1,2a.864.864,0,0,1-.32-.656A.9.9,0,0,1,4.1.632,2.632,2.632,0,0,1,4.96.16,4.355,4.355,0,0,1,6.1-.1,12.384,12.384,0,0,1,7.44-.176,11.159,11.159,0,0,1,8.776-.1,5.367,5.367,0,0,1,9.968.176,2.785,2.785,0,0,1,10.8.64.881.881,0,0,1,11.12,1.344ZM12.592-.864q0,.224-.256.224H2.624A.275.275,0,0,1,2.472-.7.206.206,0,0,1,2.384-.88v-.448a.216.216,0,0,1,.088-.144.248.248,0,0,1,.152-.08h9.712q.256,0,.256.208ZM9.808-2.384q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.464a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅣ)_ㄹ_3.svg': { 
		path: <path d="M13.216,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.96a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952V.192a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-1.92a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.04a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.9a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H5.184q-.224,0-.28.064a.524.524,0,0,0-.056.3v.64a.473.473,0,0,0,.064.3A.4.4,0,0,0,5.2,1.36h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅣ)_ㅊ_3.svg': { 
		path: <path d="M14.208,2.128q-.064.16-.32.128-.608-.112-1.4-.272t-1.624-.352q-.832-.176-1.6-.384-.32-.08-.624-.144a36.746,36.746,0,0,1-5.5,1.312q-.16.048-.216.008a.289.289,0,0,1-.088-.168L2.7,1.744a.141.141,0,0,1-.016-.168.188.188,0,0,1,.128-.1q1.152-.144,2.328-.408T7.424.528Q8.5.192,9.456-.152A11.819,11.819,0,0,0,11.12-.88q.224-.112.208-.192t-.288-.08H4.048A.184.184,0,0,1,3.84-1.36v-.576q0-.192.208-.192h7.424a2.707,2.707,0,0,1,1.008.152.915.915,0,0,1,.48.36.467.467,0,0,1-.048.48,1.981,1.981,0,0,1-.544.528A13,13,0,0,1,10.144.528L10.3.56q.624.144,1.3.3.656.128,1.352.248t1.288.216q.224.016.16.224Zm-3.648-6q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H6.432q-.256,0-.256-.24v-.464q0-.224.256-.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅣ)_ㅌ_3.svg': { 
		path: <path d="M13.2,2.032q0,.224-.192.224H5.1a1.471,1.471,0,0,1-.944-.224,1.182,1.182,0,0,1-.256-.9V-1.9a1.182,1.182,0,0,1,.256-.9A1.471,1.471,0,0,1,5.1-3.024H12.72q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H5.408a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328v.9h7.616q.224,0,.224.192V-.1a.184.184,0,0,1-.208.208H5.008V.928a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_(ㅣ)_ㅎ_3.svg': { 
		path: <path d="M10.912,1.12A.5.5,0,0,0,10.7.672a2.24,2.24,0,0,0-.584-.256A4.432,4.432,0,0,0,9.28.256,9.169,9.169,0,0,0,8.336.208a8.945,8.945,0,0,0-.9.048,4.439,4.439,0,0,0-.84.16,1.428,1.428,0,0,0-.6.232.549.549,0,0,0-.232.472.493.493,0,0,0,.232.408,2.027,2.027,0,0,0,.6.28,4.185,4.185,0,0,0,.84.12q.472.024.9.024.48,0,.944-.032a4.549,4.549,0,0,0,.832-.128A1.925,1.925,0,0,0,10.7,1.52.481.481,0,0,0,10.912,1.12Zm1.12,0a.984.984,0,0,1-.328.752,3.043,3.043,0,0,1-.824.528A4.577,4.577,0,0,1,9.7,2.688a11.159,11.159,0,0,1-1.336.08,11.36,11.36,0,0,1-1.344-.08A4.133,4.133,0,0,1,5.872,2.4a2.691,2.691,0,0,1-.864-.536,1,1,0,0,1-.32-.744,1.066,1.066,0,0,1,.32-.784,2.43,2.43,0,0,1,.864-.528A4.122,4.122,0,0,1,7.016-.48a11.026,11.026,0,0,1,1.32-.08,10.28,10.28,0,0,1,1.352.088,5.67,5.67,0,0,1,1.192.28A2.936,2.936,0,0,1,11.7.344,1.027,1.027,0,0,1,12.032,1.12Zm1.456-2.48q0,.208-.256.208H3.52a.26.26,0,0,1-.144-.056A.176.176,0,0,1,3.3-1.36v-.448a.213.213,0,0,1,.08-.176.242.242,0,0,1,.144-.064h9.712q.256,0,.256.224ZM10.72-2.992a.212.212,0,0,1-.24.24H6.112a.26.26,0,0,1-.144-.056.192.192,0,0,1-.08-.168v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.7H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㄱ_3.svg': { 
		path: <path d="M2.368-1.664a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.5a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176A.2.2,0,0,1,12.1,2.4H11.44a.2.2,0,0,1-.224-.224V-.56q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㄴ_3.svg': { 
		path: <path d="M12.832,1.76a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H3.776a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.56.672V-1.68A.2.2,0,0,1,2.784-1.9H3.44a.2.2,0,0,1,.224.224V.656q0,.224.072.28a.527.527,0,0,0,.3.056h8.56a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㄷ_3.svg': { 
		path: <path d="M12.464,1.888q0,.224-.208.224H3.872a1.431,1.431,0,0,1-.944-.224,1.235,1.235,0,0,1-.24-.9v-2a1.235,1.235,0,0,1,.24-.9,1.431,1.431,0,0,1,.944-.224h8.176a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.16a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V.752a.414.414,0,0,0,.088.288.412.412,0,0,0,.312.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㄹ_3.svg': { 
		path: <path d="M12.656,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V.336a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.9q.224,0,.28-.064a.524.524,0,0,0,.056-.3V-1.5a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-2.784H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.592a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.288a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅁ_3.svg': { 
		path: <path d="M2.688-.864a1.212,1.212,0,0,1,.256-.9A1.464,1.464,0,0,1,3.9-2h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976ZM4.176-1.04a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-.624a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅂ_3.svg': { 
		path: <path d="M3.76-.256V.8q0,.24.08.3a.573.573,0,0,0,.3.056h6.672a.527.527,0,0,0,.3-.056q.072-.056.072-.3V-.256Zm.112,2.368a3.161,3.161,0,0,1-.616-.048.669.669,0,0,1-.344-.16.754.754,0,0,1-.184-.368,2.986,2.986,0,0,1-.04-.544V-2.336a.144.144,0,0,1,.056-.112A.2.2,0,0,1,2.88-2.5h.672A.205.205,0,0,1,3.7-2.44a.156.156,0,0,1,.056.1v1.12h7.424V-2.3a.185.185,0,0,1,.056-.136.205.205,0,0,1,.152-.056h.672a.226.226,0,0,1,.152.048.144.144,0,0,1,.056.112V1.1a1,1,0,0,1-.256.8,1.558,1.558,0,0,1-.944.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅇ_3.svg': { 
		path: <path d="M12.48.16a1.357,1.357,0,0,1-.512,1.1,3.961,3.961,0,0,1-1.264.68,7.613,7.613,0,0,1-1.624.352,14.649,14.649,0,0,1-1.592.1,14.527,14.527,0,0,1-1.576-.1,7.613,7.613,0,0,1-1.624-.352,3.961,3.961,0,0,1-1.264-.68A1.357,1.357,0,0,1,2.512.16a1.334,1.334,0,0,1,.424-1A3.226,3.226,0,0,1,4.016-1.5a6.77,6.77,0,0,1,1.44-.376,14.083,14.083,0,0,1,1.488-.152h1.1a13.81,13.81,0,0,1,1.5.152,6.78,6.78,0,0,1,1.432.376,3.226,3.226,0,0,1,1.08.664A1.334,1.334,0,0,1,12.48.16ZM11.312.176a.746.746,0,0,0-.432-.664A3.71,3.71,0,0,0,9.84-.872a8.686,8.686,0,0,0-1.256-.184Q7.936-1.1,7.488-1.1q-.464,0-1.112.048a8.469,8.469,0,0,0-1.248.184A3.6,3.6,0,0,0,4.1-.488.747.747,0,0,0,3.68.176.73.73,0,0,0,4.1.832a3.765,3.765,0,0,0,1.024.376,7.816,7.816,0,0,0,1.248.176q.648.04,1.112.04.448,0,1.1-.04a7.831,7.831,0,0,0,1.256-.176A3.893,3.893,0,0,0,10.88.832.729.729,0,0,0,11.312.176Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅈ_3.svg': { 
		path: <path d="M13.328,1.824q-.064.176-.272.112A35.824,35.824,0,0,1,7.92.96a28.553,28.553,0,0,1-5.9,1.232q-.16.048-.216,0a.323.323,0,0,1-.088-.16l-.1-.48a.226.226,0,0,1,.008-.176.13.13,0,0,1,.12-.1A32.028,32.028,0,0,0,10.016-.768q.368-.224.344-.328T10-1.2H2.944a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.472q3.152,0,1.008,1.6A18.111,18.111,0,0,1,9.328.448q1.936.384,4,.608.208.08.128.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅊ_3.svg': { 
		path: <path d="M13.12,2.144q-.064.144-.3.112Q10.3,1.888,7.632,1.248a43.617,43.617,0,0,1-5.616,1.2.237.237,0,0,1-.176-.024.555.555,0,0,1-.112-.168L1.6,1.776a.243.243,0,0,1,.016-.192q.048-.1.1-.1a34.677,34.677,0,0,0,8.3-2.048q.224-.112.216-.184t-.28-.072H2.96q-.224,0-.224-.208V-1.6q0-.192.224-.192h7.408a2.9,2.9,0,0,1,1.024.144.882.882,0,0,1,.48.352.4.4,0,0,1-.048.464,1.923,1.923,0,0,1-.56.528A11.636,11.636,0,0,1,9.3.688q1.872.384,3.872.64.224.032.128.224Zm-3.664-5.6q.24,0,.24.208v.464a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.464q0-.208.24-.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅋ_3.svg': { 
		path: <path d="M2.368-2.016a.172.172,0,0,1,.08-.16.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.16a.2.2,0,0,1-.224.224h-.656A.2.2,0,0,1,11.2,2.16V.736H2.864q-.208,0-.208-.176V.016a.184.184,0,0,1,.208-.208H11.2v-.72q0-.24-.08-.3a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-1.9a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-3.04h8.224q.192,0,.192.208v.48q0,.224-.192.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v.768h8.08q.208,0,.208.192v.544A.17.17,0,0,1,11.872,0h-8.1V.784a.533.533,0,0,0,.088.344.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅍ_3.svg': { 
		path: <path d="M12.5-1.04q0,.208-.24.208H10.464v2h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256A.226.226,0,0,1,2.1,2.048a.205.205,0,0,1-.072-.16v-.5A.2.2,0,0,1,2.1,1.224a.244.244,0,0,1,.152-.056H4.464v-2H2.576a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.208ZM5.552,1.168H9.376v-2H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hd_ㅎ_3.svg': { 
		path: <path d="M10.016,1.344A.445.445,0,0,0,9.792.968,1.359,1.359,0,0,0,9.2.768,5.018,5.018,0,0,0,8.368.632Q7.9.592,7.44.592q-.448,0-.92.032A3.516,3.516,0,0,0,5.7.768a1.446,1.446,0,0,0-.616.2.443.443,0,0,0-.232.376A.39.39,0,0,0,5.08,1.7,2.6,2.6,0,0,0,5.7,1.92a4.873,4.873,0,0,0,.824.1q.472.024.92.024.464,0,.928-.024A4.158,4.158,0,0,0,9.2,1.9a2.743,2.743,0,0,0,.592-.216A.375.375,0,0,0,10.016,1.344Zm1.1,0a.856.856,0,0,1-.32.664,2.928,2.928,0,0,1-.832.488,7.084,7.084,0,0,1-1.176.248,10.158,10.158,0,0,1-1.336.088,10.34,10.34,0,0,1-1.344-.088A6.247,6.247,0,0,1,4.96,2.5,2.75,2.75,0,0,1,4.1,2a.864.864,0,0,1-.32-.656A.9.9,0,0,1,4.1.632,2.632,2.632,0,0,1,4.96.16,4.355,4.355,0,0,1,6.1-.1,12.384,12.384,0,0,1,7.44-.176,11.159,11.159,0,0,1,8.776-.1,5.367,5.367,0,0,1,9.968.176,2.785,2.785,0,0,1,10.8.64.881.881,0,0,1,11.12,1.344ZM12.592-.864q0,.224-.256.224H2.624A.275.275,0,0,1,2.472-.7.206.206,0,0,1,2.384-.88v-.448a.216.216,0,0,1,.088-.144.248.248,0,0,1,.152-.08h9.712q.256,0,.256.208ZM9.808-2.384q0,.224-.24.224H5.2a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.464a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H9.568q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㄱ_3.svg': { 
		path: <path d="M2.368-1.76a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.5a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176A.2.2,0,0,1,12.1,2.4H11.44a.2.2,0,0,1-.224-.224V-.656q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㄷ_3.svg': { 
		path: <path d="M12.464,1.888q0,.224-.208.224H3.872a1.431,1.431,0,0,1-.944-.224,1.235,1.235,0,0,1-.24-.9v-2a1.235,1.235,0,0,1,.24-.9,1.431,1.431,0,0,1,.944-.224h8.176a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.16a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V.752a.414.414,0,0,0,.088.288.412.412,0,0,0,.312.1h8.1a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㄹ_3.svg': { 
		path: <path d="M12.656,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V.3a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.9q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-.4a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-2.7a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-2.88H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v.656a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3v.4a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.288a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㅁ_3.svg': { 
		path: <path d="M2.688-.992a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976ZM4.176-1.2a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-.784a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㅋ_3.svg': { 
		path: <path d="M2.368-2.016a.172.172,0,0,1,.08-.16.309.309,0,0,1,.16-.048h8.48a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.16a.2.2,0,0,1-.224.224h-.656A.2.2,0,0,1,11.2,2.16V.736H2.864q-.208,0-.208-.176V.016a.184.184,0,0,1,.208-.208H11.2v-.72q0-.24-.08-.3a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㅌ_3.svg': { 
		path: <path d="M12.1-.16A.17.17,0,0,1,11.9.032H3.792V.8a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-1.84a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.24a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-.9h8.1Q12.1-.9,12.1-.7Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_hu_ㅍ_3.svg': { 
		path: <path d="M12.5-1.04q0,.208-.24.208H10.464v2h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256A.226.226,0,0,1,2.1,2.048a.205.205,0,0,1-.072-.16v-.5A.2.2,0,0,1,2.1,1.224a.244.244,0,0,1,.152-.056H4.464v-2H2.576a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.208ZM5.552,1.168H9.376v-2H5.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_v_ㄹ_3.svg': { 
		path: <path d="M12.672,2.1a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.416a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,3.2,1.056V.192a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-1.92a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064h-7.5a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.04a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h7.68a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.9a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H4.64q-.224,0-.28.064a.524.524,0,0,0-.056.3v.64a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h7.792a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_v_ㅊ_3.svg': { 
		path: <path d="M13.776,2.112q-.048.176-.288.128-.64-.112-1.432-.272t-1.608-.32q-.832-.192-1.616-.4-.32-.08-.624-.16A37.3,37.3,0,0,1,2.7,2.416q-.128.048-.184.008a.363.363,0,0,1-.1-.168l-.144-.5a.222.222,0,0,1,.016-.184.2.2,0,0,1,.1-.1q1.168-.144,2.336-.408T7.008.512Q8.1.192,9.048-.144a9.691,9.691,0,0,0,1.64-.736q.224-.128.216-.2t-.28-.072H3.632q-.224,0-.224-.208v-.56q0-.192.224-.192H11.04a3.059,3.059,0,0,1,1.024.136.85.85,0,0,1,.48.344.44.44,0,0,1-.032.48,2.289,2.289,0,0,1-.56.56A13.256,13.256,0,0,1,9.744.528L9.872.56q.64.144,1.312.3.656.144,1.344.256t1.312.208q.224.016.128.224ZM10.128-3.76a.212.212,0,0,1,.24.24v.448a.212.212,0,0,1-.24.24H6a.212.212,0,0,1-.24-.24V-3.52A.212.212,0,0,1,6-3.76Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_v_ㅌ_3.svg': { 
		path: <path d="M12.64,2.048q0,.24-.192.24h-7.9A1.432,1.432,0,0,1,3.6,2.056a1.239,1.239,0,0,1-.256-.92V-1.84A1.239,1.239,0,0,1,3.6-2.76a1.432,1.432,0,0,1,.944-.232H12.16a.2.2,0,0,1,.224.224v.48q0,.24-.224.24H4.848a.506.506,0,0,0-.312.072.4.4,0,0,0-.088.312v.88h7.616q.224,0,.224.176V-.08a.184.184,0,0,1-.208.208H4.448v.8a.469.469,0,0,0,.088.32.412.412,0,0,0,.312.1h7.6q.192,0,.192.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_v_ㅎ_3.svg': { 
		path: <path d="M10.912.9A.633.633,0,0,0,10.7.4a1.545,1.545,0,0,0-.584-.3A3.991,3.991,0,0,0,9.28-.088a7.872,7.872,0,0,0-.944-.056q-.432,0-.9.04a3,3,0,0,0-.84.184,1.636,1.636,0,0,0-.6.312A.637.637,0,0,0,5.76.9a.621.621,0,0,0,.232.488,1.83,1.83,0,0,0,.6.328,4.868,4.868,0,0,0,.84.168,7.681,7.681,0,0,0,.9.056A7.872,7.872,0,0,0,9.28,1.88a4.873,4.873,0,0,0,.832-.168,1.644,1.644,0,0,0,.584-.328A.64.64,0,0,0,10.912.9ZM12.032.88a1.172,1.172,0,0,1-.328.84,2.6,2.6,0,0,1-.824.568A4.347,4.347,0,0,1,9.7,2.632a8.621,8.621,0,0,1-1.336.1,8.775,8.775,0,0,1-1.344-.1,3.91,3.91,0,0,1-1.152-.344,2.358,2.358,0,0,1-.864-.576A1.2,1.2,0,0,1,4.688.88a1.23,1.23,0,0,1,.32-.872A2.414,2.414,0,0,1,5.872-.56,4.27,4.27,0,0,1,7.016-.888a8.519,8.519,0,0,1,1.32-.1A8.115,8.115,0,0,1,9.688-.88a5.309,5.309,0,0,1,1.192.336A2.6,2.6,0,0,1,11.7.024,1.192,1.192,0,0,1,12.032.88Zm1.456-2.512q0,.192-.256.192H3.52A.242.242,0,0,1,3.376-1.5a.2.2,0,0,1-.08-.16v-.448a.206.206,0,0,1,.08-.152.229.229,0,0,1,.144-.072h9.712q.256,0,.256.208ZM10.72-3.2q0,.224-.24.224H6.112a.26.26,0,0,1-.144-.056.176.176,0,0,1-.08-.152v-.5a.217.217,0,0,1,.064-.16A.2.2,0,0,1,6.1-3.9H10.48q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅓ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-8.256v-4.032A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-7.3H9.536q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅓ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-7.984v-4.3A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-4.32a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-2.7H9.536q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅓ_(ㅇ)_3.svg': { 
		path: <path d="M11.776-7.9v-4.384A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v9.264a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-3.92H9.536q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅓ_(ㅋ)_3.svg': { 
		path: <path d="M12.864-3.536a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-6.928H9.52a.184.184,0,0,1-.208-.208v-.576q0-.192.208-.192h2.224v-4.368a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅓ_(ㅌ)_3.svg': { 
		path: <path d="M12.864-4.288a.2.2,0,0,1-.224.224h-.672a.2.2,0,0,1-.224-.224V-7.28H9.52q-.208,0-.208-.192v-.56q0-.176.208-.176h2.224v-4.048a.2.2,0,0,1,.224-.224h.672a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅕ_(ㄹ)_3.svg': { 
		path: <path d="M11.776-8.56v-3.728A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-.976H9.76q-.208,0-.208-.192V-6q0-.192.208-.192h2.016V-7.616H9.76q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-8.56v-3.728A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-.976H9.76q-.208,0-.208-.192V-6q0-.192.208-.192h2.016V-7.616H9.76q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M11.776-8.56v-3.728A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-.976H9.76q-.208,0-.208-.192V-6q0-.192.208-.192h2.016V-7.616H9.76q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅕ_2.svg': { 
		path: <path d="M11.776-6.928v-5.36A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-2.832H9.5q-.208,0-.208-.192v-.56q0-.192.208-.192h2.272V-5.984H9.344q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅕ_c_3.svg': { 
		path: <path d="M11.776-8.432v-3.856A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.832a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-4.912H9.76q-.208,0-.208-.192v-.56q0-.192.208-.192h2.016V-7.488H9.76q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㄹ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㅂ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㅇ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㅈ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㅊ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㅌ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_(ㅎ)_3.svg': { 
		path: <path d="M8.016-5.776v.864h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16V-4.7a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.912H6.912v-.864Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_2.svg': { 
		path: <path d="M8.016-3.136v1.888h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.912V-3.136C6.336-3.179,8.581-3.168,8.016-3.136Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅗ_c_3.svg': { 
		path: <path d="M14.32-4.048a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-3.3a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.3V-3.84a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.048H6.912v-.96h1.1v.96Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㄹ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㅂ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㅇ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㅈ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㅊ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㅌ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_(ㅎ)_3.svg': { 
		path: <path d="M10.544-6.32V-4.9H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.16v-.528A.182.182,0,0,1,.552-4.84.266.266,0,0,1,.72-4.9H4.48V-6.272l1.1.32V-4.9H9.44V-5.952Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_2.svg': { 
		path: <path d="M10.608-3.328a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v1.84h3.5a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.176v-1.84a.266.266,0,0,1,.056-.168.182.182,0,0,1,.152-.072h.672a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v1.84H9.728v-1.84a.266.266,0,0,1,.056-.168.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅛ_c_3.svg': { 
		path: <path d="M5.552-5.243v1.307H9.424V-5.23l1.088-.338v1.632h3.776a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-3.936h3.76V-5.584A8.526,8.526,0,0,1,5.552-5.243Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㄱ)_3.svg': { 
		path: <path d="M6.944-1.856V-3.392H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.6v-.544A.182.182,0,0,1,.552-4.3.266.266,0,0,1,.72-4.352h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-3.6a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.536Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㄴ)_3.svg': { 
		path: <path d="M7.28-.4a.19.19,0,0,1-.144-.072A.245.245,0,0,1,7.072-.64v-2.5H.72A.245.245,0,0,1,.552-3.2.19.19,0,0,1,.48-3.344v-.544A.182.182,0,0,1,.552-4.04.266.266,0,0,1,.72-4.1h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.176v2.5a.266.266,0,0,1-.056.168A.182.182,0,0,1,7.968-.4Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㄷ)_3.svg': { 
		path: <path d="M14.288-4.336a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-3.6a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8V-2.13H6.912V-3.392H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-3.6v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.336Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M6.944-2.784V-4.048H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.256V-4.8a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.008h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.264Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅁ)_3.svg': { 
		path: <path d="M6.944-2V-3.392H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.6v-.544A.182.182,0,0,1,.552-4.3.266.266,0,0,1,.72-4.352h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-3.6a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048V-2Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅂ)_3.svg': { 
		path: <path d="M14.288-4.336a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-3.6a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8v1.52a.3.3,0,0,1-.048.168.178.178,0,0,1-.16.072H7.12A.182.182,0,0,1,6.968-1.7a.266.266,0,0,1-.056-.168v-1.52H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-3.6v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.336Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M6.928-1.968V-3.392H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.6v-.544A.182.182,0,0,1,.552-4.3.266.266,0,0,1,.72-4.352h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-3.6a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.032v1.52Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅇ)_3.svg': { 
		path: <path d="M6.944-2.032v-1.36H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.6v-.544A.182.182,0,0,1,.552-4.3.266.266,0,0,1,.72-4.352h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-3.6a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.36Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-4.336a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-3.6a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8V-2.16H6.912V-3.392H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-3.6v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.336Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-5.184a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.193.193,0,0,1-.088.136.259.259,0,0,1-.152.072H8.048v.764H6.96v-.764H.7A.241.241,0,0,1,.544-4.3a.2.2,0,0,1-.08-.136v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.184Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅋ)_3.svg': { 
		path: <path d="M14.288-4.336a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-3.6a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8V-2.22H6.912V-3.392H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-3.6v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.336Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M14.288-5.056A.3.3,0,0,1,14.44-5a.172.172,0,0,1,.088.152V-4.3a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.048V-3.04H6.96V-4.1H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-4.3v-.544A.176.176,0,0,1,.544-5,.278.278,0,0,1,.7-5.056Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅍ)_3.svg': { 
		path: <path d="M14.288-4.336a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152V-3.6a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8V-1.78H6.912V-3.392H.7a.278.278,0,0,1-.16-.056A.176.176,0,0,1,.464-3.6v-.528a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.336Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅜ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-5.184a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.193.193,0,0,1-.088.136.259.259,0,0,1-.152.072H8.048V-3.06H6.96V-4.224H.7A.241.241,0,0,1,.544-4.3a.2.2,0,0,1-.08-.136v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.184Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㄱ)_3.svg': { 
		path: <path d="M4.5-1.9V-3.456H.72A.245.245,0,0,1,.552-3.52.19.19,0,0,1,.48-3.664v-.544A.182.182,0,0,1,.552-4.36.266.266,0,0,1,.72-4.416h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.512V-1.9h-1.1l.064-1.552H5.6V-1.9Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㄴ)_3.svg': { 
		path: <path d="M5.3-.544a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-3.072H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.28v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.032h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.528V-.784a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.632a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-3.072H6.192V-.784a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㄷ)_3.svg': { 
		path: <path d="M9.392-2.13V-3.456H5.584V-2.13H4.48V-3.456H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.416H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H10.48V-2.13Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㄹ)_3.svg': { 
		path: <path d="M4.5-2.784V-4.112H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.32v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.072h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.512v1.328h-1.1V-4.112H5.6v1.328Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅁ)_3.svg': { 
		path: <path d="M4.5-2.016v-1.5H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.728v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.48h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.512v1.5h-1.1v-1.5H5.6v1.5Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅂ)_3.svg': { 
		path: <path d="M9.6-1.552a.182.182,0,0,1-.152-.072.266.266,0,0,1-.056-.168V-3.456H5.584v1.664a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H4.688a.2.2,0,0,1-.136-.072.23.23,0,0,1-.072-.168V-3.456H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.416H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H10.48v1.664a.266.266,0,0,1-.056.168.2.2,0,0,1-.168.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅇ)_3.svg': { 
		path: <path d="M4.336-1.616V-3.424H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.632v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.384h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.672v1.808l-1.1-.256V-3.424H5.44v1.552Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅈ)_3.svg': { 
		path: <path d="M9.392-2.16v-1.3H5.584v1.3H4.48v-1.3H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.416H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H10.48v1.3Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅊ)_3.svg': { 
		path: <path d="M10.256-2.8a.214.214,0,0,1-.152-.072.23.23,0,0,1-.072-.168V-4.176H4.816V-3.04a.266.266,0,0,1-.056.168.167.167,0,0,1-.136.072h-.7a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.176H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.136H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H11.152V-3.04a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅋ)_3.svg': { 
		path: <path d="M9.392-2.22V-3.456H5.584V-2.22H4.48V-3.456H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.416H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H10.48V-2.22Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅌ)_3.svg': { 
		path: <path d="M9.392-3.04V-4.176H5.584V-3.04H4.48V-4.176H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.136H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H10.48V-3.04Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅍ)_3.svg': { 
		path: <path d="M9.392-1.78V-3.456H5.584V-1.78H4.48V-3.456H.7a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.56a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-4.416H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.56a.161.161,0,0,1-.088.136.3.3,0,0,1-.152.056H10.48V-1.78Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_(ㅎ)_3.svg': { 
		path: <path d="M10.256-2.8a.214.214,0,0,1-.152-.072.23.23,0,0,1-.072-.168V-4.176H4.816V-3.04a.266.266,0,0,1-.056.168.167.167,0,0,1-.136.072h-.7a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.176H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-5.136H14.288a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H11.152V-3.04a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅠ_c_3.svg': { 
		path: <path d="M4.288-1.6a.19.19,0,0,1-.144-.072A.245.245,0,0,1,4.08-1.84v-1.7H.72A.245.245,0,0,1,.552-3.6.19.19,0,0,1,.48-3.744v-.544A.182.182,0,0,1,.552-4.44.266.266,0,0,1,.72-4.5h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.864v1.7a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.968a.19.19,0,0,1-.144-.072A.245.245,0,0,1,9.76-1.84v-1.7H5.184v1.7a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅡ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-4.944a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.192v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.944Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅡ_(ㅂ)_3.svg': { 
		path: <path d="M14.32-4.944a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.192v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.944Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅡ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-4.96a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.544A.192.192,0,0,1,.544-4.9.278.278,0,0,1,.7-4.96Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅡ_(ㅌ)_3.svg': { 
		path: <path d="M14.32-4.944a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.192v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.944Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅡ_(ㅎ)_3.svg': { 
		path: <path d="M14.32-4.944a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.192v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-4.944Z" />, 
		strokes: [
		] 
	},
	'_(ㅎ)_ㅡ_c_3.svg': { 
		path: <path d="M14.32-4.16a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-3.408v-.544A.182.182,0,0,1,.552-4.1.266.266,0,0,1,.72-4.16Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅜ)_ㄱ_3.svg': { 
		path: <path d="M2.432-2.48a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-1.376q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.672a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅜ)_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912v-.96a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.64a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-3.3a.147.147,0,0,1,.072-.128A.5.5,0,0,1,2.9-3.472H11.04a5.506,5.506,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.3v.9a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅜ)_ㅅ_3.svg': { 
		path: <path d="M8.048-2.528a1.782,1.782,0,0,0,.32.688A6.756,6.756,0,0,0,10.248.032,9.028,9.028,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A10.289,10.289,0,0,1,9.552.752,6.958,6.958,0,0,1,7.536-1.312,6.349,6.349,0,0,1,5.544.824a10.326,10.326,0,0,1-3.16,1.464q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A9.229,9.229,0,0,0,3.84.656,7.6,7.6,0,0,0,5.36-.3,5.409,5.409,0,0,0,6.424-1.432a3.173,3.173,0,0,0,.52-1.208Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅜ)_ㅇ_3.svg': { 
		path: <path d="M12.48-.192a1.727,1.727,0,0,1-.24.92,2.253,2.253,0,0,1-.64.672,3.812,3.812,0,0,1-.92.464,7.946,7.946,0,0,1-1.072.3,8.715,8.715,0,0,1-1.1.152q-.552.04-1.016.04-.448,0-1-.04a8.715,8.715,0,0,1-1.1-.152,7.946,7.946,0,0,1-1.072-.3,3.812,3.812,0,0,1-.92-.464,2.253,2.253,0,0,1-.64-.672,1.727,1.727,0,0,1-.24-.92,1.7,1.7,0,0,1,.24-.9,2.253,2.253,0,0,1,.64-.672,3.812,3.812,0,0,1,.92-.464,7.946,7.946,0,0,1,1.072-.3,8.726,8.726,0,0,1,1.1-.152q.544-.04,1.008-.04T8.5-2.68a8.973,8.973,0,0,1,1.112.152,7.534,7.534,0,0,1,1.072.3,3.854,3.854,0,0,1,.912.464,2.253,2.253,0,0,1,.64.672A1.7,1.7,0,0,1,12.48-.192Zm-1.168.016a1.014,1.014,0,0,0-.44-.864,3.216,3.216,0,0,0-1.056-.488,6.663,6.663,0,0,0-1.264-.216Q7.9-1.792,7.488-1.792q-.432,0-1.088.048a6.371,6.371,0,0,0-1.256.216,3.136,3.136,0,0,0-1.032.488,1.02,1.02,0,0,0-.432.864,1,1,0,0,0,.432.848,3.136,3.136,0,0,0,1.032.488A6.371,6.371,0,0,0,6.4,1.376q.656.048,1.088.048t1.08-.048A6.51,6.51,0,0,0,9.824,1.16,3.223,3.223,0,0,0,10.872.672,1,1,0,0,0,11.312-.176Z" transform="translate(747 1988.95)"/>, 
		strokes: [
		] 
	},
	'_c_(ㅜ)_ㅊ_3.svg': { 
		path: <path d="M13.12,2.112q-.064.16-.3.128l-1.432-.288q-.792-.16-1.608-.336t-1.6-.384q-.32-.08-.624-.176A31.959,31.959,0,0,1,2.032,2.4q-.128.048-.184.008a.363.363,0,0,1-.1-.168L1.6,1.728a.19.19,0,0,1,.016-.168.2.2,0,0,1,.1-.1q1.168-.144,2.336-.4T6.336.48q1.088-.3,2.04-.656a13.239,13.239,0,0,0,1.64-.72q.224-.128.216-.2t-.28-.072H2.96q-.224,0-.224-.208v-.56q0-.192.224-.192h7.408a3.059,3.059,0,0,1,1.024.136.89.89,0,0,1,.48.328.454.454,0,0,1-.032.488,1.871,1.871,0,0,1-.56.536A13.21,13.21,0,0,1,9.072.512L9.2.544q.64.144,1.312.3.672.144,1.36.264t1.3.2q.224.032.128.224ZM9.552-3.968a.2.2,0,0,1,.224.224v.48q0,.256-.224.256H5.264q-.24,0-.24-.256v-.48q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㄱ_3.svg': { 
		path: <path d="M2.288-2.272a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.736a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176a.2.2,0,0,1-.224.224H11.6a.2.2,0,0,1-.224-.224V-1.168q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.528a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㄹ_3.svg': { 
		path: <path d="M12.688,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.872a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.656.912V-.08a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.976A.435.435,0,0,0,11.2-1.28a.47.47,0,0,0,.064-.3V-2.32a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.848A.27.27,0,0,1,2.7-2.736a.147.147,0,0,1-.072-.128v-.56A.147.147,0,0,1,2.7-3.552.27.27,0,0,1,2.848-3.6h8.24a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.992a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H4.08q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.368a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㅁ_3.svg': { 
		path: <path d="M2.64-1.488a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.3a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-7.3a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.64.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H10.88a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.248a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㅅ_3.svg': { 
		path: <path d="M8.032-2.528a.814.814,0,0,0-.016.16A.814.814,0,0,1,8-2.208a1.742,1.742,0,0,0,.144.416,2.607,2.607,0,0,0,.224.368A5.205,5.205,0,0,0,10.232.208a10.269,10.269,0,0,0,3,1.056.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.528a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008A11.229,11.229,0,0,1,9.544.944,6.052,6.052,0,0,1,7.5-.912a5.866,5.866,0,0,1-1.9,1.84,11.521,11.521,0,0,1-3.224,1.36q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A10.14,10.14,0,0,0,5.664-.36,3.03,3.03,0,0,0,6.976-2.576a.17.17,0,0,1,.192-.192l.736.016Q8.048-2.752,8.032-2.528Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㅇ_3.svg': { 
		path: <path d="M12.48-.4a1.843,1.843,0,0,1-.24.952,2.553,2.553,0,0,1-.64.72,3.749,3.749,0,0,1-.92.52,6.732,6.732,0,0,1-1.072.328A9.9,9.9,0,0,1,8.5,2.3a10.123,10.123,0,0,1-1.016.056,9.99,9.99,0,0,1-1-.056,9.9,9.9,0,0,1-1.1-.176,6.731,6.731,0,0,1-1.072-.328,3.749,3.749,0,0,1-.92-.52,2.553,2.553,0,0,1-.64-.72A1.843,1.843,0,0,1,2.512-.4a1.811,1.811,0,0,1,.24-.936,2.454,2.454,0,0,1,.64-.712,3.912,3.912,0,0,1,.92-.512A7.356,7.356,0,0,1,5.384-2.9a8.87,8.87,0,0,1,1.1-.184,9.889,9.889,0,0,1,1.008-.056A10.123,10.123,0,0,1,8.5-3.08,10.235,10.235,0,0,1,9.616-2.9a6.411,6.411,0,0,1,1.072.328,3.963,3.963,0,0,1,.912.512,2.416,2.416,0,0,1,.64.72A1.855,1.855,0,0,1,12.48-.4Zm-1.168.016a1.106,1.106,0,0,0-.432-.9,3.208,3.208,0,0,0-1.048-.552A6.355,6.355,0,0,0,8.568-2.1a10.094,10.094,0,0,0-1.08-.072,10.189,10.189,0,0,0-1.1.072,6.046,6.046,0,0,0-1.248.272,3.235,3.235,0,0,0-1.032.552,1.106,1.106,0,0,0-.432.9A1.084,1.084,0,0,0,4.112.5a3.207,3.207,0,0,0,1.032.536A6.4,6.4,0,0,0,6.392,1.3a10.189,10.189,0,0,0,1.1.072A10.094,10.094,0,0,0,8.568,1.3,6.738,6.738,0,0,0,9.832,1.04,3.184,3.184,0,0,0,10.88.5,1.084,1.084,0,0,0,11.312-.384Z" transform="translate(705 1904.3)"/>, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㅈ_3.svg': { 
		path: <path d="M13.312,1.824a.2.2,0,0,1-.288.112q-.608-.112-1.384-.28T10.048,1.28q-.816-.208-1.6-.416Q8,.752,7.632.624L7.616.64Q6.4,1.1,4.968,1.512a27.331,27.331,0,0,1-2.952.664q-.16.064-.216.024a.289.289,0,0,1-.088-.168l-.128-.48a.222.222,0,0,1,.008-.176.167.167,0,0,1,.12-.1A30.944,30.944,0,0,0,6.616.032a15.688,15.688,0,0,0,3.4-1.632q.24-.208.216-.32T10-2.032H2.944a.184.184,0,0,1-.208-.208V-2.8q0-.176.208-.176c8.3,0,8.795.139,8.944.416s-.053.661-.608,1.152A7.934,7.934,0,0,1,9.76-.368q-.368.208-.784.4.24.064.5.128.624.176,1.3.336T12.12.808q.68.152,1.272.264.208.048.144.224Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㅊ_3.svg': { 
		path: <path d="M11.312-.608a9.549,9.549,0,0,1-.992.584Q9.776.256,9.1.528q.944.24,2.04.456t2.056.344q.224.032.144.224l-.192.576q-.048.176-.288.128-.56-.1-1.224-.224t-1.36-.28q-.7-.152-1.392-.32T7.568,1.1a39.3,39.3,0,0,1-5.52,1.328.306.306,0,0,1-.2,0,.247.247,0,0,1-.088-.16L1.616,1.76a.222.222,0,0,1,.008-.176.167.167,0,0,1,.12-.1q1.152-.16,2.328-.416T6.344.5Q7.44.192,8.4-.152a13.3,13.3,0,0,0,1.664-.712q.224-.128.208-.208t-.288-.08H2.976a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.44a3.072,3.072,0,0,1,1.016.136.838.838,0,0,1,.488.352.469.469,0,0,1-.048.48A1.871,1.871,0,0,1,11.312-.608ZM9.248-3.984q.24,0,.24.224v.5a.212.212,0,0,1-.24.24H5.728a.212.212,0,0,1-.24-.24v-.5q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_c_(ㅠ)_ㅎ_3.svg': { 
		path: <path d="M10.064.784A.609.609,0,0,0,9.848.3,1.627,1.627,0,0,0,9.28,0a4,4,0,0,0-.808-.192,6.611,6.611,0,0,0-.92-.064,7.217,7.217,0,0,0-.9.056,3.678,3.678,0,0,0-.8.184A1.923,1.923,0,0,0,5.264.3.6.6,0,0,0,5.04.784a.6.6,0,0,0,.224.456,1.556,1.556,0,0,0,.592.312,4,4,0,0,0,.8.192,6.328,6.328,0,0,0,.9.064,6.611,6.611,0,0,0,.92-.064,4,4,0,0,0,.808-.192,1.485,1.485,0,0,0,.568-.312A.607.607,0,0,0,10.064.784Zm1.168,0a1.254,1.254,0,0,1-.32.848,2.474,2.474,0,0,1-.832.608A4.76,4.76,0,0,1,8.9,2.6a7.5,7.5,0,0,1-1.336.12A7.631,7.631,0,0,1,6.224,2.6a4.245,4.245,0,0,1-1.152-.36,2.579,2.579,0,0,1-.864-.624,1.234,1.234,0,0,1-.32-.832,1.259,1.259,0,0,1,.32-.88,2.524,2.524,0,0,1,.864-.592A4.068,4.068,0,0,1,6.216-1.04a8.024,8.024,0,0,1,2.672,0,4.8,4.8,0,0,1,1.192.352,2.422,2.422,0,0,1,.832.616A1.284,1.284,0,0,1,11.232.784Zm1.6-2.672q0,.208-.24.208h-10a.217.217,0,0,1-.16-.064.217.217,0,0,1-.064-.16v-.448a.238.238,0,0,1,.064-.176.217.217,0,0,1,.16-.064h10q.24,0,.24.224Zm-2.944-1.7q0,.224-.256.224H5.408a.234.234,0,0,1-.16-.056.174.174,0,0,1-.064-.136v-.48a.226.226,0,0,1,.064-.152.205.205,0,0,1,.16-.072H9.632q.256,0,.256.224Z" />, 
		strokes: [
		] 
	},
	'_c_hd_v_ㄹ_c_5.svg': { 
		path: <path d="M12.818,2.912q.052.338-.312.39-.39.078-1.092.195t-1.274.169H6.4a4.246,4.246,0,0,1-.988-.091,1.39,1.39,0,0,1-.6-.3,1.277,1.277,0,0,1-.26-.637A6.909,6.909,0,0,1,4.5,1.716V.338q0-1.066.429-1.378A2.833,2.833,0,0,1,6.5-1.352H9.828a.707.707,0,0,0,.468-.1.823.823,0,0,0,.1-.52v-.91a.851.851,0,0,0-.091-.494.6.6,0,0,0-.429-.1H4.81a.518.518,0,0,1-.221-.078q-.143-.078-.143-.182v-.91a.234.234,0,0,1,.143-.208A.518.518,0,0,1,4.81-4.94h5.3a4.278,4.278,0,0,1,.975.091,1.608,1.608,0,0,1,.637.3.982.982,0,0,1,.286.559,4.642,4.642,0,0,1,.078.923v1.274a1.886,1.886,0,0,1-.429,1.443,2.438,2.438,0,0,1-1.573.377H6.708q-.39,0-.481.1a.918.918,0,0,0-.091.52v.936a.761.761,0,0,0,.117.494.652.652,0,0,0,.481.13h1.82q.468,0,.91-.013t.65-.039l.52-.078q.286-.026.6-.078.338-.078.6-.13L12.3,1.82a.3.3,0,0,1,.39.286Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㄱ_3.svg': { 
		path: <path d="M2.432-2.48a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.176a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V-1.376q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.672a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㄴ_3.svg': { 
		path: <path d="M12.832,1.76a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H3.776a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.56.672V-2.544a.2.2,0,0,1,.224-.224H3.44a.2.2,0,0,1,.224.224v3.2q0,.224.072.28a.527.527,0,0,0,.3.056h8.56a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㄷ_3.svg': { 
		path: <path d="M12.512,1.888q0,.224-.208.224H3.9a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-1.536a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H12.3a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㄹ_3.svg': { 
		path: <path d="M11.04-3.664a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅁ_3.svg': { 
		path: <path d="M2.688-1.536a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.3a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅂ_3.svg': { 
		path: <path d="M3.888,2.144A3.161,3.161,0,0,1,3.272,2.1a.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552V-3.024a.174.174,0,0,1,.064-.136A.213.213,0,0,1,2.9-3.216h.7a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v1.488h7.424V-3.008a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.7a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V1.12a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.1-2.72V.8a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.656a.468.468,0,0,0,.3-.072A.43.43,0,0,0,11.216.8V-.576Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅇ_3.svg': { 
		path: <path d="M20.28-.442a2.92,2.92,0,0,1-.832,2.158,5.784,5.784,0,0,1-2.054,1.3,10.991,10.991,0,0,1-2.639.637,22,22,0,0,1-2.587.169q-.78,0-1.677-.078T8.7,3.5a10.4,10.4,0,0,1-1.729-.481A5.8,5.8,0,0,1,5.5,2.223,3.972,3.972,0,0,1,4.472,1.092a2.925,2.925,0,0,1-.39-1.534,2.843,2.843,0,0,1,.832-2.119A5.881,5.881,0,0,1,6.968-3.848,11.423,11.423,0,0,1,9.607-4.5a20.279,20.279,0,0,1,2.561-.182,22,22,0,0,1,2.587.169,11.2,11.2,0,0,1,2.652.637,5.635,5.635,0,0,1,2.054,1.3A2.911,2.911,0,0,1,20.28-.442Zm-1.9.026a1.6,1.6,0,0,0-.7-1.365,5.449,5.449,0,0,0-1.69-.793,10.606,10.606,0,0,0-2.041-.377q-1.053-.091-1.781-.091-.754,0-1.807.091a10.354,10.354,0,0,0-2.028.377,5.3,5.3,0,0,0-1.664.793A1.608,1.608,0,0,0,5.98-.416,1.618,1.618,0,0,0,6.669.936a4.945,4.945,0,0,0,1.664.793,11.026,11.026,0,0,0,2.028.364q1.053.091,1.807.091.728,0,1.794-.091A11.035,11.035,0,0,0,16,1.729,5.1,5.1,0,0,0,17.68.936,1.609,1.609,0,0,0,18.382-.416Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅈ_3.svg': { 
		path: <path d="M13.312,1.824a.2.2,0,0,1-.288.112q-.608-.112-1.384-.28T10.048,1.28q-.816-.208-1.6-.416Q8,.752,7.632.624L7.616.64Q6.4,1.1,4.968,1.512a27.331,27.331,0,0,1-2.952.664q-.16.064-.216.024a.289.289,0,0,1-.088-.168l-.128-.48a.222.222,0,0,1,.008-.176.167.167,0,0,1,.12-.1A30.944,30.944,0,0,0,6.616.032a15.688,15.688,0,0,0,3.4-1.632q.24-.208.216-.32T10-2.032H2.944a.184.184,0,0,1-.208-.208V-2.8q0-.176.208-.176h7.472q1.248,0,1.472.416T11.28-1.408A7.934,7.934,0,0,1,9.76-.368q-.368.208-.784.4.24.064.5.128.624.176,1.3.336T12.12.808q.68.152,1.272.264.208.048.144.224Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅋ_3.svg': { 
		path: <path d="M2.368-2.592a.14.14,0,0,1,.08-.136.355.355,0,0,1,.16-.04h8.48a1.3,1.3,0,0,1,.984.272,1.472,1.472,0,0,1,.232.944V2.176a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.464H2.864q-.208,0-.208-.192V-.24q0-.224.208-.224H11.2V-1.472q0-.224-.08-.28a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056A.163.163,0,0,1,2.368-2Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅌ_3.svg': { 
		path: <path d="M12.448,1.92q0,.224-.192.224H3.92a1.461,1.461,0,0,1-.968-.232,1.238,1.238,0,0,1-.248-.9V-1.9a1.238,1.238,0,0,1,.248-.9A1.461,1.461,0,0,1,3.92-3.04h8.224q.192,0,.192.208v.48q0,.224-.192.224H4.176a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v.768h8.08q.208,0,.208.192v.544A.17.17,0,0,1,11.872,0h-8.1V.784a.533.533,0,0,0,.088.344.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅍ_3.svg': { 
		path: <path d="M12.5-1.92q0,.208-.24.208H10.464V1.152h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256a.25.25,0,0,1-.224-.224V1.376a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.464V-1.712H2.576a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.192ZM5.552,1.152H9.376V-1.712H5.552Z" />, 
		strokes: [
		] 
	},
	'_c_hd_ㅎ_3.svg': { 
		path: <path d="M10.064.784A.609.609,0,0,0,9.848.3,1.627,1.627,0,0,0,9.28,0a4,4,0,0,0-.808-.192,6.611,6.611,0,0,0-.92-.064,7.217,7.217,0,0,0-.9.056,3.678,3.678,0,0,0-.8.184A1.923,1.923,0,0,0,5.264.3.6.6,0,0,0,5.04.784a.6.6,0,0,0,.224.456,1.556,1.556,0,0,0,.592.312,4,4,0,0,0,.8.192,6.328,6.328,0,0,0,.9.064,6.611,6.611,0,0,0,.92-.064,4,4,0,0,0,.808-.192,1.485,1.485,0,0,0,.568-.312A.607.607,0,0,0,10.064.784Zm1.168,0a1.254,1.254,0,0,1-.32.848,2.474,2.474,0,0,1-.832.608A4.76,4.76,0,0,1,8.9,2.6a7.5,7.5,0,0,1-1.336.12A7.631,7.631,0,0,1,6.224,2.6a4.245,4.245,0,0,1-1.152-.36,2.579,2.579,0,0,1-.864-.624,1.234,1.234,0,0,1-.32-.832,1.259,1.259,0,0,1,.32-.88,2.524,2.524,0,0,1,.864-.592A4.068,4.068,0,0,1,6.216-1.04a8.024,8.024,0,0,1,2.672,0,4.8,4.8,0,0,1,1.192.352,2.422,2.422,0,0,1,.832.616A1.284,1.284,0,0,1,11.232.784Zm1.6-2.672q0,.208-.24.208h-10a.217.217,0,0,1-.16-.064.217.217,0,0,1-.064-.16v-.448a.238.238,0,0,1,.064-.176.217.217,0,0,1,.16-.064h10q.24,0,.24.224Zm-2.944-1.7q0,.224-.256.224H5.408a.234.234,0,0,1-.16-.056.174.174,0,0,1-.064-.136v-.48a.226.226,0,0,1,.064-.152.372.372,0,0,1,.16-.072H9.632s.256.075.256.224Z" />, 
		strokes: [
		] 
	},
	'_c_hu_v_ㄱ_c_5.svg': { 
		path: <path d="M4.186-3.9a.286.286,0,0,1,.13-.247.451.451,0,0,1,.26-.091h5.642a2.116,2.116,0,0,1,1.612.455,2.34,2.34,0,0,1,.39,1.521V3.354a.322.322,0,0,1-.364.364h-.962a.322.322,0,0,1-.364-.364V-2.158a.554.554,0,0,0-.117-.416.713.713,0,0,0-.455-.1H4.576a.451.451,0,0,1-.26-.091.265.265,0,0,1-.13-.221Z" />, 
		strokes: [
		] 
	},
	'_c_hu_v_ㄴ_c_5.svg': { 
		path: <path d="M12.168,2.782a.241.241,0,0,1-.091.247.588.588,0,0,1-.247.117,2.411,2.411,0,0,1-.377.1,4.624,4.624,0,0,1-.533.078q-.26.026-.533.039t-.429.013q-.312,0-.767.026t-.949.026h-2a2.333,2.333,0,0,1-1.625-.481A2.334,2.334,0,0,1,4.238,1.43V-3.718A.322.322,0,0,1,4.6-4.082H5.694a.322.322,0,0,1,.364.364V1.4a.549.549,0,0,0,.1.4.851.851,0,0,0,.494.091q.364,0,.9.039t1,.039a7.713,7.713,0,0,0,.793-.039q.377-.039.689-.065l.884-.117q.494-.065.754-.117a.33.33,0,0,1,.26.065.545.545,0,0,1,.182.2Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㄱ_3.svg': { 
		path: <path d="M2.368-2.368a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.5a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936v3.52A.2.2,0,0,1,12.1,2.4H11.44a.2.2,0,0,1-.224-.224v-3.44q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㄴ_3.svg': { 
		path: <path d="M12.832,1.76a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H3.9A1.189,1.189,0,0,1,2.92,1.64,1.628,1.628,0,0,1,2.688.672v-2.8a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V.656q0,.224.072.28a.527.527,0,0,0,.3.056h8.432a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㄷ_3.svg': { 
		path: <path d="M12.464,1.888q0,.224-.208.224H3.872a1.431,1.431,0,0,1-.944-.224,1.235,1.235,0,0,1-.24-.9v-2.88a1.209,1.209,0,0,1,.24-.872,1.393,1.393,0,0,1,.944-.232h8.176q.208,0,.208.192v.544q0,.224-.208.224H4.176a.506.506,0,0,0-.312.072q-.088.072-.088.344V.752a.446.446,0,0,0,.088.3.392.392,0,0,0,.312.1h8.08a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.272a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.824H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅁ_3.svg': { 
		path: <path d="M2.688-1.68a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.44a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅂ_3.svg': { 
		path: <path d="M3.888,2.144A3.161,3.161,0,0,1,3.272,2.1a.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552V-3.2a.174.174,0,0,1,.064-.136A.213.213,0,0,1,2.9-3.392h.7a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v1.568h7.424V-3.184a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.7a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V1.12a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.1-2.816V.8a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.656a.468.468,0,0,0,.3-.072A.43.43,0,0,0,11.216.8V-.672Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅅ_3.svg': { 
		path: <path d="M8.064-2.848q-.016.112-.032.216a1.764,1.764,0,0,1-.048.216,2.691,2.691,0,0,0,.336.7A5.951,5.951,0,0,0,10.224.1,10.439,10.439,0,0,0,13.2,1.248a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.322.322,0,0,1-.088.128q-.056.048-.216,0A11.554,11.554,0,0,1,9.528.808a6.475,6.475,0,0,1-2.04-1.976A6.58,6.58,0,0,1,5.48.88a10.5,10.5,0,0,1-3.1,1.408q-.256.064-.32-.08L1.84,1.616a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128,8.484,8.484,0,0,0,1.976-.7A7.4,7.4,0,0,0,5.5-.416,5.352,5.352,0,0,0,6.552-1.632,2.679,2.679,0,0,0,6.976-2.9q.016-.192.192-.192l.768.016Q8.08-3.072,8.064-2.848Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅇ_3.svg': { 
		path: <path d="M12.48-.272a1.8,1.8,0,0,1-.512,1.328,3.56,3.56,0,0,1-1.264.8,6.764,6.764,0,0,1-1.624.392,13.536,13.536,0,0,1-1.592.1q-.48,0-1.032-.048t-1.1-.152a6.4,6.4,0,0,1-1.064-.3,3.569,3.569,0,0,1-.9-.488,2.445,2.445,0,0,1-.632-.7,1.8,1.8,0,0,1-.24-.944,1.75,1.75,0,0,1,.512-1.3,3.619,3.619,0,0,1,1.264-.792,7.029,7.029,0,0,1,1.624-.4A12.479,12.479,0,0,1,7.488-2.88a13.536,13.536,0,0,1,1.592.1,6.892,6.892,0,0,1,1.632.392,3.467,3.467,0,0,1,1.264.8A1.792,1.792,0,0,1,12.48-.272Zm-1.168.016a.984.984,0,0,0-.432-.84,3.353,3.353,0,0,0-1.04-.488,6.527,6.527,0,0,0-1.256-.232q-.648-.056-1.1-.056-.464,0-1.112.056a6.371,6.371,0,0,0-1.248.232A3.26,3.26,0,0,0,4.1-1.1a.99.99,0,0,0-.424.84A1,1,0,0,0,4.1.576a3.043,3.043,0,0,0,1.024.488,6.785,6.785,0,0,0,1.248.224q.648.056,1.112.056.448,0,1.1-.056a6.791,6.791,0,0,0,1.256-.224A3.136,3.136,0,0,0,10.88.576.99.99,0,0,0,11.312-.256Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅈ_3.svg': { 
		path: <path d="M13.312,1.824a.2.2,0,0,1-.288.112q-.608-.112-1.384-.28T10.048,1.28q-.816-.208-1.6-.416Q8,.752,7.632.624L7.616.64Q6.4,1.1,4.968,1.512a27.331,27.331,0,0,1-2.952.664q-.16.064-.216.024a.289.289,0,0,1-.088-.168l-.128-.48a.222.222,0,0,1,.008-.176.167.167,0,0,1,.12-.1A30.944,30.944,0,0,0,6.616.032a15.688,15.688,0,0,0,3.4-1.632q.24-.208.216-.32T10-2.032H2.944a.184.184,0,0,1-.208-.208V-2.8q0-.176.208-.176h7.472q1.248,0,1.472.416T11.28-1.408A7.934,7.934,0,0,1,9.76-.368q-.368.208-.784.4.24.064.5.128.624.176,1.3.336T12.12.808q.68.152,1.272.264.208.048.144.224Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅊ_3.svg': { 
		path: <path d="M11.312-.976a11.385,11.385,0,0,1-1.04.632q-.576.312-1.3.616.512.16,1.088.312t1.168.28q.592.128,1.176.248t1.112.216q.208.048.144.224l-.192.576q-.048.176-.288.128-.56-.112-1.264-.28t-1.456-.36q-.752-.192-1.488-.4t-1.36-.4q-1.2.448-2.592.856t-2.976.76a.306.306,0,0,1-.2,0,.247.247,0,0,1-.088-.16L1.616,1.76q-.08-.24.128-.272Q3.008,1.232,4.224.9T6.52.216Q7.6-.144,8.5-.52t1.56-.712q.208-.112.192-.224t-.272-.112H2.976a.184.184,0,0,1-.208-.208V-2.3q0-.192.208-.192h7.44a3.333,3.333,0,0,1,.976.12.925.925,0,0,1,.52.328.46.46,0,0,1-.008.488A1.751,1.751,0,0,1,11.312-.976ZM9.584-4.336q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.28a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅋ_3.svg': { 
		path: <path d="M2.368-2.944a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.48a1.3,1.3,0,0,1,.984.272,1.44,1.44,0,0,1,.232.928v4.08a.2.2,0,0,1-.224.224h-.656a.2.2,0,0,1-.224-.224V.256H2.864q-.208,0-.208-.16V-.448a.184.184,0,0,1,.208-.208H11.2V-1.84q0-.224-.08-.28a.573.573,0,0,0-.3-.056H2.608a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅌ_3.svg': { 
		path: <path d="M12.448,1.9q0,.224-.192.224H3.92A1.461,1.461,0,0,1,2.952,1.9,1.21,1.21,0,0,1,2.7,1.008V-2.528a1.21,1.21,0,0,1,.248-.888,1.461,1.461,0,0,1,.968-.232h8.224a.17.17,0,0,1,.192.192v.5q0,.208-.192.208H4.176a.436.436,0,0,0-.312.088.465.465,0,0,0-.088.328V-1.28h8.08q.208,0,.208.176V-.56q0,.208-.192.208h-8.1V.8a.473.473,0,0,0,.088.312.392.392,0,0,0,.312.1h8.08q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅍ_3.svg': { 
		path: <path d="M12.5-2.064q0,.176-.24.176H10.464v3.04h2.1q.256,0,.256.208V1.9q0,.208-.256.208H2.256a.25.25,0,0,1-.224-.224V1.376a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.464v-3.04H2.576a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.68q.24,0,.24.208ZM5.552,1.152H9.376v-3.04H5.552Z" />, 
		strokes: [
		] 
	},
	'_c_hu_ㅎ_3.svg': { 
		path: <path d="M10.064.784A.609.609,0,0,0,9.848.3,1.627,1.627,0,0,0,9.28,0a4,4,0,0,0-.808-.192,6.611,6.611,0,0,0-.92-.064,7.217,7.217,0,0,0-.9.056,3.678,3.678,0,0,0-.8.184A1.923,1.923,0,0,0,5.264.3.6.6,0,0,0,5.04.784a.6.6,0,0,0,.224.456,1.556,1.556,0,0,0,.592.312,4,4,0,0,0,.8.192,6.328,6.328,0,0,0,.9.064,6.611,6.611,0,0,0,.92-.064,4,4,0,0,0,.808-.192,1.485,1.485,0,0,0,.568-.312A.607.607,0,0,0,10.064.784Zm1.168,0a1.254,1.254,0,0,1-.32.848,2.474,2.474,0,0,1-.832.608A4.76,4.76,0,0,1,8.9,2.6a7.5,7.5,0,0,1-1.336.12A7.631,7.631,0,0,1,6.224,2.6a4.245,4.245,0,0,1-1.152-.36,2.579,2.579,0,0,1-.864-.624,1.234,1.234,0,0,1-.32-.832,1.259,1.259,0,0,1,.32-.88,2.524,2.524,0,0,1,.864-.592A4.068,4.068,0,0,1,6.216-1.04a8.024,8.024,0,0,1,2.672,0,4.8,4.8,0,0,1,1.192.352,2.422,2.422,0,0,1,.832.616A1.284,1.284,0,0,1,11.232.784Zm1.6-2.672q0,.208-.24.208h-10a.217.217,0,0,1-.16-.064.217.217,0,0,1-.064-.16v-.448a.238.238,0,0,1,.064-.176.217.217,0,0,1,.16-.064h10q.24,0,.24.224Zm-2.944-1.7q0,.224-.256.224H5.408a.234.234,0,0,1-.16-.056.174.174,0,0,1-.064-.136v-.48a.226.226,0,0,1,.064-.152.205.205,0,0,1,.16-.072H9.632q.256,0,.256.224Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㄱ_3.svg': { 
		path: <path d="M2.992-2.912a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.1a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V2.08A.2.2,0,0,1,12.1,2.3H11.44a.2.2,0,0,1-.224-.224V-1.808q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.232a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㄴ_3.svg': { 
		path: <path d="M13.04,1.76a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H4.64a1.273,1.273,0,0,1-.984-.28A1.477,1.477,0,0,1,3.424.736v-3.1a.2.2,0,0,1,.224-.224H4.3a.2.2,0,0,1,.224.224V.672q0,.224.072.272a.6.6,0,0,0,.3.048h7.9a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㄷ_3.svg': { 
		path: <path d="M12.64,1.888q0,.224-.192.224h-7.9A1.432,1.432,0,0,1,3.6,1.88a1.212,1.212,0,0,1-.256-.9V-2.048a1.182,1.182,0,0,1,.256-.9,1.471,1.471,0,0,1,.944-.224H12.16q.224,0,.224.208v.5a.2.2,0,0,1-.224.224H4.848a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336V.736a.446.446,0,0,0,.088.3.392.392,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㄹ_3.svg': { 
		path: <path d="M12.64,1.952a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.272a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.824H11.04a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅁ_3.svg': { 
		path: <path d="M2.688-1.68a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.2a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V.976a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.9a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.92,1.88a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,2.688.976Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V.736a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.656a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-1.44a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅂ_3.svg': { 
		path: <path d="M4.384,2.112a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352A2.616,2.616,0,0,1,3.184.976V-3.616a.152.152,0,0,1,.064-.128.234.234,0,0,1,.144-.048h.7a.185.185,0,0,1,.136.056.168.168,0,0,1,.056.12v1.7h6.928V-3.584a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.7a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V1.088a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM4.288-.96V.768a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.16a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-.96Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅅ_3.svg': { 
		path: <path d="M8.608-3.216Q8.592-3.1,8.584-3a1.092,1.092,0,0,1-.04.216,2.109,2.109,0,0,0,.32.7,6.664,6.664,0,0,0,2.048,2A9.79,9.79,0,0,0,13.744,1.1a.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.272.544a.322.322,0,0,1-.088.128q-.056.048-.216,0a10.543,10.543,0,0,1-1.672-.608A9.187,9.187,0,0,1,10.136.6a8.994,8.994,0,0,1-1.2-1.016,6.241,6.241,0,0,1-.888-1.12,6.647,6.647,0,0,1-.84,1.128A7.8,7.8,0,0,1,6.048.624a9.532,9.532,0,0,1-1.44.864,10.15,10.15,0,0,1-1.68.64q-.256.064-.32-.08l-.224-.592a.281.281,0,0,1-.008-.176.176.176,0,0,1,.136-.128A8.659,8.659,0,0,0,4.48.4,7.822,7.822,0,0,0,6.04-.68,5.146,5.146,0,0,0,7.088-1.96,3.206,3.206,0,0,0,7.52-3.312q.016-.192.192-.192l.768.064Q8.64-3.44,8.608-3.216Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅇ_3.svg': { 
		path: <path d="M12.512-.624A2.148,2.148,0,0,1,12.144.608a3.088,3.088,0,0,1-1,.92A5.233,5.233,0,0,1,9.672,2.1a7.756,7.756,0,0,1-1.8.2,8.236,8.236,0,0,1-1.888-.2,4.885,4.885,0,0,1-1.456-.576,2.837,2.837,0,0,1-.944-.92A2.3,2.3,0,0,1,3.248-.624a2.227,2.227,0,0,1,.336-1.208,2.868,2.868,0,0,1,.944-.912A4.885,4.885,0,0,1,5.984-3.32a8.236,8.236,0,0,1,1.888-.2,7.756,7.756,0,0,1,1.8.2,5.393,5.393,0,0,1,1.472.568,3.047,3.047,0,0,1,1,.9A2.111,2.111,0,0,1,12.512-.624Zm-1.136.048a1.338,1.338,0,0,0-.288-.848,2.272,2.272,0,0,0-.776-.616,4.59,4.59,0,0,0-1.128-.384A6.373,6.373,0,0,0,7.856-2.56a6.782,6.782,0,0,0-1.408.136,4.056,4.056,0,0,0-1.1.384,2.025,2.025,0,0,0-.712.616,1.448,1.448,0,0,0-.256.848A1.386,1.386,0,0,0,4.64.248a1.989,1.989,0,0,0,.712.6,4.063,4.063,0,0,0,1.1.368,7.192,7.192,0,0,0,1.408.128,6.757,6.757,0,0,0,1.328-.128A4.592,4.592,0,0,0,10.312.848a2.237,2.237,0,0,0,.776-.6A1.282,1.282,0,0,0,11.376-.576Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅈ_3.svg': { 
		path: <path d="M13.552,1.856a.243.243,0,0,1-.3.112A26.873,26.873,0,0,1,10.5,1.184Q9.536.88,8.608.5A.619.619,0,0,0,8.5.576q-1.072.464-2.3.9a24.106,24.106,0,0,1-2.52.736q-.24.08-.3-.1L3.168,1.6q-.08-.224.16-.256A24.5,24.5,0,0,0,7.264.024a19.334,19.334,0,0,0,3.184-1.736q.3-.224.264-.336t-.28-.112H4.16q-.224,0-.224-.208v-.576q0-.192.224-.192h6.672q1.28,0,1.48.4t-.584,1.2A7.936,7.936,0,0,1,10.368-.48a4.946,4.946,0,0,1-.64.4,1.978,1.978,0,0,0,.3.1q.608.208,1.232.432.624.192,1.224.368t1.1.288q.224.048.176.224Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅊ_3.svg': { 
		path: <path d="M13.776,2.176q-.048.176-.288.16-.624-.08-1.416-.224L10.48,1.824q-.8-.192-1.6-.368-.48-.112-.928-.24A28.492,28.492,0,0,1,2.976,2.48q-.224.032-.272-.112L2.56,1.84q-.08-.24.128-.256a36.9,36.9,0,0,0,4.5-1.12A22.47,22.47,0,0,0,10.656-.944q.224-.128.192-.208t-.24-.08h-6.9a.2.2,0,0,1-.224-.224v-.5q0-.208.224-.208h7.344a2.343,2.343,0,0,1,.976.16.743.743,0,0,1,.416.384.55.55,0,0,1-.072.48,1.552,1.552,0,0,1-.488.48A18.4,18.4,0,0,1,9.408.656q.256.048.56.128.608.112,1.28.24.688.128,1.344.232t1.184.184q.224.016.16.224Zm-3.648-6.3a.2.2,0,0,1,.224.224v.464q0,.24-.224.24H6.08a.212.212,0,0,1-.24-.24V-3.9q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅋ_3.svg': { 
		path: <path d="M2.976-3.088a.155.155,0,0,1,.088-.144.352.352,0,0,1,.168-.048h7.856a1.246,1.246,0,0,1,.968.28,1.477,1.477,0,0,1,.232.936V2.064a.2.2,0,0,1-.224.224h-.64a.2.2,0,0,1-.224-.224V.1H3.472Q3.248.1,3.248-.08V-.592A.184.184,0,0,1,3.456-.8H11.2V-2q0-.224-.08-.28a.573.573,0,0,0-.3-.056H3.232a.315.315,0,0,1-.168-.056q-.088-.056-.088-.12Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅌ_3.svg': { 
		path: <path d="M13.2,2.064q0,.224-.192.224H5.1a1.432,1.432,0,0,1-.944-.232,1.212,1.212,0,0,1-.256-.9V-2.72a1.212,1.212,0,0,1,.256-.9A1.432,1.432,0,0,1,5.1-3.856H12.72a.2.2,0,0,1,.224.224v.48a.2.2,0,0,1-.224.224H5.408a.412.412,0,0,0-.312.1.5.5,0,0,0-.088.336v1.3h7.616q.224,0,.224.176V-.5q0,.192-.208.192H5.008V.928a.5.5,0,0,0,.088.328.392.392,0,0,0,.312.1h7.6q.192,0,.192.208Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅍ_3.svg': { 
		path: <path d="M13.28,1.9q0,.208-.24.208H2.848A.226.226,0,0,1,2.7,2.048a.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h2.24V-2.224H3.328a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H12.56q.24,0,.24.208v.528q0,.208-.24.208h-1.7V1.168H13.04q.24,0,.24.208Zm-7.1-.736h3.6V-2.224h-3.6Z" />, 
		strokes: [
		] 
	},
	'_c_v_ㅎ_3.svg': { 
		path: <path d="M10.56.736a.706.706,0,0,0-.192-.5A1.523,1.523,0,0,0,9.84-.112,3.667,3.667,0,0,0,9.056-.3,6.921,6.921,0,0,0,8.08-.368,6.847,6.847,0,0,0,7.128-.3a3.668,3.668,0,0,0-.792.192A1.614,1.614,0,0,0,5.8.24a.691.691,0,0,0-.2.5.7.7,0,0,0,.2.488,1.39,1.39,0,0,0,.536.344,4.505,4.505,0,0,0,.792.176,6.847,6.847,0,0,0,.952.064,6.921,6.921,0,0,0,.976-.064,4.515,4.515,0,0,0,.784-.176,1.318,1.318,0,0,0,.528-.344A.715.715,0,0,0,10.56.736ZM11.712.72a1.315,1.315,0,0,1-.3.864,2.856,2.856,0,0,1-.768.64,4.56,4.56,0,0,1-1.16.384A7.137,7.137,0,0,1,8.1,2.736,7.173,7.173,0,0,1,6.72,2.608a4.3,4.3,0,0,1-1.152-.384,2.607,2.607,0,0,1-.792-.64A1.337,1.337,0,0,1,4.48.72a1.4,1.4,0,0,1,.3-.88,2.173,2.173,0,0,1,.792-.624,4.5,4.5,0,0,1,1.144-.36,7.505,7.505,0,0,1,1.368-.12,7.6,7.6,0,0,1,1.384.12,4.5,4.5,0,0,1,1.144.36,2.461,2.461,0,0,1,.8.632A1.346,1.346,0,0,1,11.712.72Zm1.344-2.688q0,.192-.24.192H3.424a.226.226,0,0,1-.152-.064A.205.205,0,0,1,3.2-2v-.448a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.392q.24,0,.24.208ZM10.3-3.728a.212.212,0,0,1-.24.24H6.016a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.48a.238.238,0,0,1,.064-.176A.2.2,0,0,1,6-4.432h4.064a.212.212,0,0,1,.24.24Z" />, 
		strokes: [
		] 
	},
	'_c_ㅏ_2.svg': { 
		path: <path d="M12.32,2.016a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-14.3a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v5.824h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.544a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅏ_c_3.svg': { 
		path: <path d="M12.32-4.864a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.424a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v3.376h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.544a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_(ㄴ)_3.svg': { 
		path: <path d="M12.32-2.08a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224V-12.288a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.96h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v2.432h2.208A.266.266,0,0,1,14.7-5.9a.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_(ㄹ)_3.svg': { 
		path: <path d="M14.736-9.12a.2.2,0,0,1-.08.16.242.242,0,0,1-.144.064H12.288v1.584h2.224a.26.26,0,0,1,.144.056.163.163,0,0,1,.08.136v.544a.2.2,0,0,1-.08.136.229.229,0,0,1-.144.072H12.288v1.376a.2.2,0,0,1-.224.224h-.672q-.192,0-.192-.224v-7.28q0-.224.192-.224h.672a.2.2,0,0,1,.224.224v2.416h2.224a.26.26,0,0,1,.144.056.192.192,0,0,1,.08.168Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_(ㅂ)_3.svg': { 
		path: <path d="M12.32-4.9a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.392a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.336h2.208A.266.266,0,0,1,14.7-9.9a.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.776h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152V-6.5a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_(ㅅ)_3.svg': { 
		path: <path d="M12.32-3.392a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-8.9a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.736h2.208A.266.266,0,0,1,14.7-9.5a.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32V-6.4h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_(ㅌ)_3.svg': { 
		path: <path d="M12.32-4.9a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-7.392a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224v2.336h2.208A.266.266,0,0,1,14.7-9.9a.182.182,0,0,1,.072.152v.528a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32v1.776h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152V-6.5a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_2.svg': { 
		path: <path d="M12.32,2.016a.2.2,0,0,1-.224.224H11.44a.2.2,0,0,1-.224-.224v-14.3a.2.2,0,0,1,.224-.224H12.1a.2.2,0,0,1,.224.224V-8.48h2.208a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.512a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H12.32V-4.16h2.208A.266.266,0,0,1,14.7-4.1a.182.182,0,0,1,.072.152v.512A.19.19,0,0,1,14.7-3.3a.245.245,0,0,1-.168.064H12.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅑ_c_3.svg': { 
		path: <path d="M14.736-8.928a.2.2,0,0,1-.08.136.229.229,0,0,1-.144.072H12.288v1.744h2.224a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152v.528a.183.183,0,0,1-.08.144.242.242,0,0,1-.144.064H12.288v1.84q0,.208-.224.208h-.672q-.192,0-.192-.208v-8.08q0-.208.192-.208h.672q.224,0,.224.208V-9.68h2.224a.26.26,0,0,1,.144.056.192.192,0,0,1,.08.168Z" />, 
		strokes: [
		] 
	},
	'_c_ㅓ_(ㅇ)_3.svg': { 
		path: <path d="M11.776-9.344v-2.944A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.144a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-4.24H7.6q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_c_ㅓ_2.svg': { 
		path: <path d="M12.88,2.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.176H9.056q-.208,0-.208-.192v-.576q0-.192.208-.192h2.72v-5.152A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅓ_c_3.svg': { 
		path: <path d="M11.776-7.9v-4.384A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.752a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.944H9.536q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_c_ㅕ_(ㄴ)_3.svg': { 
		path: <path d="M11.776-9.776v-2.512A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224V-2.08a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6H8.512Q8.3-6,8.3-6.192v-.56q0-.192.208-.192h3.264V-8.832H8.512q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_c_ㅕ_(ㅂ)_3.svg': { 
		path: <path d="M11.776-10.368v-1.92A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.44a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.832H8.512q-.208,0-.208-.192v-.56q0-.192.208-.192h3.264V-9.424H8.512q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_c_ㅕ_(ㅌ)_3.svg': { 
		path: <path d="M11.776-10.368v-1.92A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v7.44a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-6.832H8.512q-.208,0-.208-.192v-.56q0-.192.208-.192h3.264V-9.424H8.512q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_c_ㅕ_c_3.svg': { 
		path: <path d="M11.776-10.144v-2.144A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224v8.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-2.32H9.152q-.208,0-.208-.192v-.56q0-.192.208-.192h2.624V-9.2H9.152q-.208,0-.208-.192v-.56q0-.192.208-.192Z" />, 
		strokes: [
		] 
	},
	'_c_ㅗ_2.svg': { 
		path: <path d="M7.808-4.944a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v3.456h6.3a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H6.912V-4.7a.266.266,0,0,1,.056-.168.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅗ_c_3.svg': { 
		path: <path d="M13.078-11.44v3.354H23.27a.451.451,0,0,1,.26.091.286.286,0,0,1,.13.247v.884a.3.3,0,0,1-.13.234.416.416,0,0,1-.26.1H1.17A.4.4,0,0,1,.9-6.63.308.308,0,0,1,.78-6.864v-.884A.3.3,0,0,1,.9-7.995a.432.432,0,0,1,.273-.091H11.284V-11.44Z" />, 
		strokes: [
		] 
	},
	'_c_ㅛ_2.svg': { 
		path: <path d="M10.224-4.416a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.928H14.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248H4.56V-4.176a.266.266,0,0,1,.056-.168.182.182,0,0,1,.152-.072H5.44a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v2.928h3.7V-4.176A.266.266,0,0,1,9.4-4.344a.182.182,0,0,1,.152-.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㄱ)_3.svg': { 
		path: <path d="M6.944-2.672V-4.608H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.816V-5.36a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.568h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.936Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㄴ)_3.svg': { 
		path: <path d="M7.28-.944a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.4H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.608v-.544A.182.182,0,0,1,.552-5.3.266.266,0,0,1,.72-5.36h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.176v3.216a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㄷ)_3.svg': { 
		path: <path d="M6.944-2.672V-4.624H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.832v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.584h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.952Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.224a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.6h-1.1v-1.6H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.472v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅁ)_3.svg': { 
		path: <path d="M6.944-2.672V-4.624H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.832v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.584h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.952Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅅ)_3.svg': { 
		path: <path d="M8.048-2.528l-1.1-.112V-4.32H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.528v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.28h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅇ)_3.svg': { 
		path: <path d="M6.944-2.752V-4.624H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.832v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.584h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.872Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅈ)_3.svg': { 
		path: <path d="M14.288-6.08a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H8.016V-3.52a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.12a.2.2,0,0,1-.136-.072.23.23,0,0,1-.072-.168V-5.1H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.08Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-6.368a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016V-3.97h-1.1V-5.424H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152V-6.16a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.368Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅋ)_3.svg': { 
		path: <path d="M14.288-5.68a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544a.193.193,0,0,1-.088.136.259.259,0,0,1-.152.072H8.016v1.936h-1.1V-4.7H.7a.241.241,0,0,1-.16-.072.2.2,0,0,1-.08-.136v-.544a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-5.68Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅌ)_3.svg': { 
		path: <path d="M14.288-6.08a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.544a.2.2,0,0,1-.088.152.259.259,0,0,1-.152.072H8.016V-3.52a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H7.12a.2.2,0,0,1-.136-.072.23.23,0,0,1-.072-.168V-5.1H.7a.241.241,0,0,1-.16-.072.206.206,0,0,1-.08-.152v-.544a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.08Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅍ)_3.svg': { 
		path: <path d="M14.288-5.68a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.544a.193.193,0,0,1-.088.136.259.259,0,0,1-.152.072H8.016V-2.64h-1.1V-4.7H.7a.241.241,0,0,1-.16-.072.2.2,0,0,1-.08-.136v-.544a.192.192,0,0,1,.08-.168A.278.278,0,0,1,.7-5.68Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-6.368a.3.3,0,0,1,.152.056.172.172,0,0,1,.088.152v.528a.172.172,0,0,1-.088.152.3.3,0,0,1-.152.056H8.016V-4.26h-1.1V-5.424H.7a.278.278,0,0,1-.16-.056.176.176,0,0,1-.08-.152V-6.16a.176.176,0,0,1,.08-.152A.278.278,0,0,1,.7-6.368Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_2.svg': { 
		path: <path d="M7.136,2.144a.19.19,0,0,1-.144-.072A.245.245,0,0,1,6.928,1.9V-2.5H.72A.245.245,0,0,1,.552-2.56.19.19,0,0,1,.48-2.7v-.544A.182.182,0,0,1,.552-3.4.266.266,0,0,1,.72-3.456h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-2.7a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.032V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_c_3.svg': { 
		path: <path d="M7.152-2.544a.19.19,0,0,1-.144-.072.245.245,0,0,1-.064-.168V-4.72H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.928v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.68h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H8.048v1.936a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅜ_v_c_c_5.svg': { 
		path: <path d="M8.45-5.408a.32.32,0,0,1-.26-.13.416.416,0,0,1-.1-.26V-9.1q-.208,0-.494.026l-2.106.052q-1.092.052-2.119.065T1.612-8.97a.386.386,0,0,1-.286-.1.323.323,0,0,1-.1-.234v-.858a.311.311,0,0,1,.1-.247.42.42,0,0,1,.286-.091q.728,0,1.742-.013t2.106-.065l2.132-.052q1.014-.026,1.742-.026.884-.026,1.9-.1t2-.13q1.04-.13,2-.221T17-11.258a.276.276,0,0,1,.234,0,.25.25,0,0,1,.156.208l.078.884q.1.312-.286.364-1.742.234-3.822.416-1.768.13-3.458.234V-5.8a.451.451,0,0,1-.091.26.286.286,0,0,1-.247.13Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㄱ)_3.svg': { 
		path: <path d="M4.5-2.464V-4.816H.72A.245.245,0,0,1,.552-4.88.19.19,0,0,1,.48-5.024v-.544A.182.182,0,0,1,.552-5.72.266.266,0,0,1,.72-5.776h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.512v2.352h-1.1V-4.816H5.6v2.352Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㄴ)_3.svg': { 
		path: <path d="M5.3-1.024A.19.19,0,0,1,5.152-1.1a.245.245,0,0,1-.064-.168V-4.288H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.5V-5.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.248h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.528v3.024a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.632A.19.19,0,0,1,9.488-1.1a.245.245,0,0,1-.064-.168V-4.288H6.192v3.024a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㄹ)_3.svg': { 
		path: <path d="M4.464-3.6V-5.264H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.472v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.224h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48V-3.6h-1.1V-5.264H5.568V-3.6Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㅁ)_3.svg': { 
		path: <path d="M4.464-2.624V-4.592H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.8v-.544A.182.182,0,0,1,.552-5.5.266.266,0,0,1,.72-5.552h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-4.8a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48v1.968h-1.1V-4.592H5.568v1.968Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㅇ)_3.svg': { 
		path: <path d="M4.384-2.32V-4.672H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.88v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.632h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.56v2.336l-1.1-.254V-4.672H5.488V-2.59Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㅈ)_3.svg': { 
		path: <path d="M9.344-2.98V-5.072H5.552V-2.98H4.432V-5.072H.7a.256.256,0,0,1-.16-.064.173.173,0,0,1-.08-.128v-.56a.157.157,0,0,1,.08-.144A.309.309,0,0,1,.7-6.016H14.288a.33.33,0,0,1,.152.048.155.155,0,0,1,.088.144v.56a.172.172,0,0,1-.088.128.275.275,0,0,1-.152.064H10.464V-2.98Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_(ㅎ)_3.svg': { 
		path: <path d="M4.112-3.36a.19.19,0,0,1-.144-.072A.245.245,0,0,1,3.9-3.6V-5.264H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.472v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.224h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H11.04V-3.6a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072h-.688A.19.19,0,0,1,10-3.432.245.245,0,0,1,9.936-3.6V-5.264H5.008V-3.6a.266.266,0,0,1-.056.168A.182.182,0,0,1,4.8-3.36Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_2.svg': { 
		path: <path d="M4.624,2.144a.19.19,0,0,1-.144-.072A.245.245,0,0,1,4.416,1.9V-2.544H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-2.752V-3.3a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-3.5h13.6a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H10.48V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072H9.584a.19.19,0,0,1-.144-.072A.245.245,0,0,1,9.376,1.9V-2.544H5.52V1.9a.266.266,0,0,1-.056.168.182.182,0,0,1-.152.072Z" />, 
		strokes: [
		] 
	},
	'_c_ㅠ_c_3.svg': { 
		path: <path d="M9.552-3.456a.2.2,0,0,1-.136-.08.258.258,0,0,1-.072-.176v-1.6H5.552v1.6a.3.3,0,0,1-.056.176.192.192,0,0,1-.168.08H4.64a.2.2,0,0,1-.136-.08.258.258,0,0,1-.072-.176v-1.6H.7a.256.256,0,0,1-.16-.064.183.183,0,0,1-.08-.144v-.544A.163.163,0,0,1,.544-6.2.278.278,0,0,1,.7-6.256H14.288a.3.3,0,0,1,.152.056.161.161,0,0,1,.088.136v.544a.18.18,0,0,1-.088.144.275.275,0,0,1-.152.064H10.464v1.6a.3.3,0,0,1-.056.176.192.192,0,0,1-.168.08Z" />, 
		strokes: [
		] 
	},
	'_c_ㅡ_(ㄹ)_3.svg': { 
		path: <path d="M14.32-6.32a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-5.568v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-6.32Z" />, 
		strokes: [
		] 
	},
	'_c_ㅡ_(ㅊ)_3.svg': { 
		path: <path d="M14.288-6.176a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.528a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.176Z" />, 
		strokes: [
		] 
	},
	'_c_ㅡ_(ㅎ)_3.svg': { 
		path: <path d="M14.288-6.176a.3.3,0,0,1,.152.056.186.186,0,0,1,.088.168v.528a.192.192,0,0,1-.088.16.275.275,0,0,1-.152.064H.7a.256.256,0,0,1-.16-.064.2.2,0,0,1-.08-.16v-.56a.163.163,0,0,1,.08-.136A.278.278,0,0,1,.7-6.176Z" />, 
		strokes: [
		] 
	},
	'_c_ㅡ_2.svg': { 
		path: <path d="M14.32-1.248a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152V-.5a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72A.245.245,0,0,1,.552-.352.19.19,0,0,1,.48-.5V-1.04a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-1.248Z" />, 
		strokes: [
		] 
	},
	'_c_ㅡ_c_3.svg': { 
		path: <path d="M14.32-5.328a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.72a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.48-4.576V-5.12a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.72-5.328Z" />, 
		strokes: [
		] 
	},
	'_c_ㅡ_v_c_c_5.svg': { 
		path: <path d="M17.264-9.022q.208-.052.273,0a.333.333,0,0,1,.091.208l.13.91a.3.3,0,0,1-.052.26.494.494,0,0,1-.286.1q-2.262.26-4.992.429t-5.434.169H1.612a.353.353,0,0,1-.26-.1.323.323,0,0,1-.1-.234v-.91a.24.24,0,0,1,.1-.221.482.482,0,0,1,.26-.065H6.084q1.612,0,3.146-.065t2.99-.091q1.4-.1,2.7-.2T17.264-9.022Z" />, 
		strokes: [
		] 
	},
	'_c_ㅣ_(ㄴ)_3.svg': { 
		path: <path d="M12.88-2.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224V-12.288A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅣ_(ㄹ)_3.svg': { 
		path: <path d="M12.88-4.928a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-7.36A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅣ_(ㅂ)_3.svg': { 
		path: <path d="M12.88-4.928a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-7.36A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅣ_(ㅌ)_3.svg': { 
		path: <path d="M12.88-4.928a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-7.36A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅣ_2.svg': { 
		path: <path d="M12.88,2.016a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-14.3A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_c_ㅣ_c_3.svg': { 
		path: <path d="M12.88-4.3a.2.2,0,0,1-.224.224H12a.2.2,0,0,1-.224-.224v-7.984A.2.2,0,0,1,12-12.512h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅑ)_(ㄹ)_3.svg': { 
		path: <path d="M8.224-10.816a7.233,7.233,0,0,1-.64,1.952,6.825,6.825,0,0,1-1.224,1.7A10.178,10.178,0,0,1,4.448-5.656a19.806,19.806,0,0,1-2.7,1.384q-.256.112-.352-.1L1.152-4.9A.2.2,0,0,1,1.144-5.1a.3.3,0,0,1,.136-.112q5.168-2.128,5.744-5.312.1-.464-.384-.464H1.712q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.3a1.518,1.518,0,0,1,1.048.272A.91.91,0,0,1,8.224-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅕ)_2.svg': { 
		path: <path d="M8.592-10.16a9.9,9.9,0,0,1-.544,2.7A10.824,10.824,0,0,1,6.76-4.92,12.2,12.2,0,0,1,4.776-2.68,13.451,13.451,0,0,1,2.144-.864q-.208.112-.3-.048l-.352-.56q-.112-.192.064-.272A12.379,12.379,0,0,0,3.96-3.352a10.824,10.824,0,0,0,1.792-1.96A9.683,9.683,0,0,0,6.928-7.536a9.322,9.322,0,0,0,.544-2.4.369.369,0,0,0-.064-.328.469.469,0,0,0-.32-.088H2q-.192,0-.192-.208v-.56A.17.17,0,0,1,2-11.312H7.392a1.3,1.3,0,0,1,.992.28A1.215,1.215,0,0,1,8.592-10.16Z" transform="translate(-451.2 -44)"/>, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅕ)_c_3.svg': { 
		path: <path d="M8.656-10.8a7.13,7.13,0,0,1-.632,1.984A7.031,7.031,0,0,1,6.792-7.072a9.933,9.933,0,0,1-1.92,1.536,18.865,18.865,0,0,1-2.7,1.376q-.272.112-.352-.1l-.24-.528a.2.2,0,0,1-.008-.208A.3.3,0,0,1,1.712-5.1,13.011,13.011,0,0,0,5.768-7.56a5.436,5.436,0,0,0,1.688-2.952q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.3a1.484,1.484,0,0,1,1.04.272A.954.954,0,0,1,8.656-10.8Z" transform="translate(703.92 1739)"/>, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㄴ)_3.svg': { 
		path: <path d="M11.264-10.544a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.432-.048,1.056t-.12,1.3Q12.112-7.68,12-7.04t-.224,1.072H10.768a8.178,8.178,0,0,0,.2-1.008q.088-.608.152-1.256t.1-1.264Q11.264-10.112,11.264-10.544Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㄹ)_3.svg': { 
		path: <path d="M11.264-11.04a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952,15.213,15.213,0,0,1-.12,1.888,17.357,17.357,0,0,1-.328,1.84H10.88a17.128,17.128,0,0,0,.3-1.776A13.62,13.62,0,0,0,11.264-11.04Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㅂ)_3.svg': { 
		path: <path d="M11.264-10.832a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208V-12a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952,17.749,17.749,0,0,1-.12,2A18.011,18.011,0,0,1,11.9-7.024h-1.04q.192-.912.3-1.912A14.7,14.7,0,0,0,11.264-10.832Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㅅ)_3.svg': { 
		path: <path d="M11.264-10.688a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.416-.048,1.024t-.12,1.256q-.072.648-.176,1.272t-.216,1.072H10.784q.1-.416.184-1.008t.152-1.216q.064-.624.1-1.224T11.264-10.688Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㅈ)_3.svg': { 
		path: <path d="M11.264-10.832a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208V-12a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952,17.08,17.08,0,0,1-.12,1.952Q12.112-8,11.92-7.1H10.88a18.005,18.005,0,0,0,.3-1.88A14.989,14.989,0,0,0,11.264-10.832Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㅊ)_3.svg': { 
		path: <path d="M11.248-11.008a.5.5,0,0,0-.088-.336.387.387,0,0,0-.3-.1H2.832q-.224,0-.224-.192v-.544a.2.2,0,0,1,.224-.224h8.256a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96,14.149,14.149,0,0,1-.152,2.048l-.327,1.84H10.839l.3-1.792A13.944,13.944,0,0,0,11.248-11.008Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_(ㅎ)_3.svg': { 
		path: <path d="M11.248-11.008a.5.5,0,0,0-.088-.336.387.387,0,0,0-.3-.1H2.832q-.224,0-.224-.192v-.544a.2.2,0,0,1,.224-.224h8.256a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96,14.149,14.149,0,0,1-.152,2.048l-.335,1.84H10.834l.31-1.792A13.944,13.944,0,0,0,11.248-11.008Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅜ)_2.svg': { 
		path: <path d="M2.416-11.872a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576,15.289,15.289,0,0,1-.152,2.544q-.184,1.3-.5,2.8H10.592q.16-.624.3-1.36t.232-1.472q.088-.736.12-1.392a9.446,9.446,0,0,0,0-1.1.558.558,0,0,0-.088-.28.443.443,0,0,0-.28-.056H2.656a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅠ)_2.svg': { 
		path: <path d="M2.416-11.872a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576,16.5,16.5,0,0,1-.16,2.5Q12-7.024,11.68-5.536H10.592q.16-.624.3-1.344t.232-1.44q.088-.72.12-1.36a9.184,9.184,0,0,0,0-1.088.558.558,0,0,0-.088-.28.443.443,0,0,0-.28-.056H2.656a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㄴ)_3.svg': { 
		path: <path d="M11.264-10.544a.606.606,0,0,0-.088-.328.376.376,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.416-.04,1.088T12.2-8.208q-.072.736-.168,1.44T11.84-5.584H10.816q.112-.544.2-1.232T11.168-8.2q.064-.7.088-1.32T11.264-10.544Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㄷ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.056t-.1,1.168q-.064.592-.152,1.16t-.2,1.032H10.848q.1-.464.176-1.016T11.16-8.7q.056-.568.08-1.112T11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M10.848-7.168a6.838,6.838,0,0,0,.208-.9q.08-.488.128-.976t.064-.96q.016-.472.016-.856a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.368-.024.832t-.072.976q-.048.512-.144,1.04a9.29,9.29,0,0,1-.24,1.008Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅁ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.056t-.1,1.168q-.064.592-.152,1.16t-.2,1.032H10.848q.1-.464.176-1.016T11.16-8.7q.056-.568.08-1.112T11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅂ)_3.svg': { 
		path: <path d="M11.264-10.832a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208V-12a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.464-.032,1.016T12.232-8.84q-.056.584-.136,1.152T11.92-6.64H10.9q.1-.464.16-1.016t.112-1.112q.048-.56.072-1.1T11.264-10.832Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅅ)_3.svg': { 
		path: <path d="M11.184-10.968a.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.416-.048,1.032t-.12,1.28q-.072.664-.176,1.3t-.216,1.08H10.784q.1-.416.184-1.016t.152-1.24q.064-.64.1-1.248A6.532,6.532,0,0,0,11.184-10.968Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅇ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.056t-.1,1.168q-.064.592-.152,1.16t-.2,1.032H10.848q.1-.464.176-1.016T11.16-8.7q.056-.568.08-1.112T11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅈ)_3.svg': { 
		path: <path d="M11.248-10.7a.535.535,0,0,0-.08-.336.384.384,0,0,0-.3-.1H2.848q-.224,0-.224-.208v-.512a.2.2,0,0,1,.224-.224h8.24a1.388,1.388,0,0,1,1,.264,1.368,1.368,0,0,1,.248.936q0,.512-.04,1.112t-.12,1.208q-.064.608-.176,1.192l-.17.808H10.8l.15-.792q.088-.568.168-1.144.064-.608.1-1.168T11.248-10.7Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.056t-.1,1.168q-.064.592-.152,1.16t-.2,1.032H10.848q.1-.464.176-1.016T11.16-8.7q.056-.568.08-1.112T11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅋ)_3.svg': { 
		path: <path d="M11.248-10.7a.535.535,0,0,0-.08-.336.384.384,0,0,0-.3-.1H2.848q-.224,0-.224-.208v-.512a.2.2,0,0,1,.224-.224h8.24a1.388,1.388,0,0,1,1,.264,1.368,1.368,0,0,1,.248.936q0,.512-.04,1.112t-.12,1.208q-.064.608-.176,1.192l-.175.808-1.017,0,.145-.789q.088-.568.168-1.144.064-.608.1-1.168T11.248-10.7Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅌ)_3.svg': { 
		path: <path d="M2.624-12.016a.172.172,0,0,1,.088-.152.289.289,0,0,1,.136-.056H11.12a1.705,1.705,0,0,1,.608.088.9.9,0,0,1,.352.232.887.887,0,0,1,.2.4,2.568,2.568,0,0,1,.056.576A9.952,9.952,0,0,1,12.2-9.08q-.152.888-.312,1.64a2.05,2.05,0,0,1-.1.184.141.141,0,0,1-.152.072l-.624-.064q-.272-.016-.192-.272.1-.368.176-.8t.144-.88q.064-.432.088-.88t.024-.848q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.318.318,0,0,1-.136-.048.144.144,0,0,1-.088-.128Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅍ)_3.svg': { 
		path: <path d="M11.248-10.7a.535.535,0,0,0-.08-.336.384.384,0,0,0-.3-.1H2.848q-.224,0-.224-.208v-.512a.2.2,0,0,1,.224-.224h8.24a1.388,1.388,0,0,1,1,.264,1.368,1.368,0,0,1,.248.936q0,.512-.04,1.112t-.12,1.208q-.064.608-.176,1.192l-.164.808h-1.03l.147-.792q.088-.568.168-1.144.064-.608.1-1.168T11.248-10.7Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M2.624-12.016a.172.172,0,0,1,.088-.152.289.289,0,0,1,.136-.056H11.12a1.705,1.705,0,0,1,.608.088.9.9,0,0,1,.352.232.887.887,0,0,1,.2.4,2.568,2.568,0,0,1,.056.576A9.952,9.952,0,0,1,12.2-9.08q-.152.888-.312,1.64c-.032.064-1.125.091-1.072-.08q.1-.368.176-.8t.144-.88q.064-.432.088-.88t.024-.848q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.318.318,0,0,1-.136-.048.144.144,0,0,1-.088-.128Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_2.svg': { 
		path: <path d="M2.448-11.088a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.576a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.858.858,0,0,1,.176.392A3.941,3.941,0,0,1,12.48-10q.016.432-.008,1.1T12.4-7.448q-.048.792-.12,1.648t-.16,1.68q-.088.824-.184,1.568t-.192,1.3H10.656q.1-.416.2-1.1t.2-1.5q.1-.816.168-1.712t.12-1.728q.048-.832.064-1.536t-.016-1.152a.443.443,0,0,0-.08-.28.484.484,0,0,0-.288-.056H2.688a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_(ㅡ)_c_3.svg': { 
		path: <path d="M11.264-10.736a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208V-11.9a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.04,1.056T12.2-8.68q-.072.584-.16,1.152T11.84-6.5H10.8q.1-.464.184-1.016t.152-1.112q.064-.56.1-1.1T11.264-10.736Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_1.svg': { 
		path: <path d="M3.744-8.208a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h6.08a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V-1.6a.2.2,0,0,1-.224.224H10.4a.2.2,0,0,1-.224-.224V-7.1q0-.224-.072-.28a.527.527,0,0,0-.3-.056H3.984a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
			<path   d="M807.719,2169.041h6.188a.986.986,0,0,1,.713.271,1.577,1.577,0,0,1,.2.869v5.474" fill="none" stroke="#000" stroke-width="2"/>,
		] 
	},
	'_ㄱ_hd_(ㄴ)_3.svg': { 
		path: <path d="M11.264-10.544a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.416-.048,1.024t-.128,1.256q-.08.648-.184,1.272T11.776-6.1H10.752a8.269,8.269,0,0,0,.2-.992q.088-.592.16-1.216t.112-1.216Q11.264-10.112,11.264-10.544Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_(ㄹ)_3.svg': { 
		path: <path d="M11.264-11.04a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952,14.645,14.645,0,0,1-.12,1.864,16.834,16.834,0,0,1-.328,1.8H10.88a17.5,17.5,0,0,0,.3-1.744A12.97,12.97,0,0,0,11.264-11.04Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_(ㅈ)_3.svg': { 
		path: <path d="M11.248-10.784a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848q-.224,0-.224-.208v-.544q0-.208.224-.208h8.24a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96q0,.48-.04,1.032t-.12,1.1q-.064.56-.152,1.088l-.156.812-1.028,0,.3-1.847A15.891,15.891,0,0,0,11.248-10.784Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_(ㅊ)_3.svg': { 
		path: <path d="M11.248-11.008a.5.5,0,0,0-.088-.336.387.387,0,0,0-.3-.1H2.832q-.224,0-.224-.192v-.544a.2.2,0,0,1,.224-.224h8.256a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96,14.149,14.149,0,0,1-.152,2.048L11.9-7.469l-1.02,0,.269-1.6A13.944,13.944,0,0,0,11.248-11.008Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_(ㅌ)_3.svg': { 
		path: <path d="M11.248-11.008a.5.5,0,0,0-.088-.336.387.387,0,0,0-.3-.1H2.832q-.224,0-.224-.192v-.544a.2.2,0,0,1,.224-.224h8.256a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96,14.149,14.149,0,0,1-.152,2.048l-.278,1.58H10.889l.255-1.533A13.944,13.944,0,0,0,11.248-11.008Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_(ㅎ)_3.svg': { 
		path: <path d="M11.248-11.008a.5.5,0,0,0-.088-.336.387.387,0,0,0-.3-.1H2.832q-.224,0-.224-.192v-.544a.2.2,0,0,1,.224-.224h8.256a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96,14.149,14.149,0,0,1-.152,2.048L11.9-7.47H10.873l.271-1.6A13.944,13.944,0,0,0,11.248-11.008Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_2.svg': { 
		path: <path d="M2.416-11.872a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576,15.289,15.289,0,0,1-.152,2.544q-.184,1.3-.5,2.8H10.592q.16-.624.3-1.36t.232-1.472q.088-.736.12-1.392a9.446,9.446,0,0,0,0-1.1.558.558,0,0,0-.088-.28.443.443,0,0,0-.28-.056H2.656a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hd_c_3.svg': { 
		path: <path d="M11.248-10.784a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848q-.224,0-.224-.208v-.544q0-.208.224-.208h8.24a1.359,1.359,0,0,1,1,.272,1.432,1.432,0,0,1,.248.96q0,.48-.04,1.032t-.12,1.1q-.064.56-.152,1.088l-.163.862H10.832l.312-1.894A15.891,15.891,0,0,0,11.248-10.784Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hu_2.svg': { 
		path: <path d="M2.448-11.248a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.624a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.858.858,0,0,1,.176.392,3.941,3.941,0,0,1,.064.576q.032.768.016,1.64t-.072,1.752q-.056.88-.152,1.72T12.1-3.5q-.048.272-.24.24l-.64-.048q-.272-.016-.192-.288a13.048,13.048,0,0,0,.224-1.424q.1-.864.152-1.792t.072-1.816q.016-.888-.032-1.512a.443.443,0,0,0-.08-.28.484.484,0,0,0-.288-.056H2.688a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_hu_c_3.svg': { 
		path: <path d="M2.64-12a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.272a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576q0,.48-.032,1.024t-.1,1.072q-.064.528-.152,1.016t-.168.856a.68.68,0,0,1-.088.176.144.144,0,0,1-.152.064l-.64-.048q-.272-.016-.192-.288a8.2,8.2,0,0,0,.176-.856q.08-.5.144-1.04t.088-1.056q.024-.52.024-.9,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.88a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_v_2.svg': { 
		path: <path d="M8.592-10.16a9.9,9.9,0,0,1-.544,2.7A10.824,10.824,0,0,1,6.76-4.92,12.2,12.2,0,0,1,4.776-2.68,13.451,13.451,0,0,1,2.144-.864q-.208.112-.3-.048l-.352-.56q-.112-.192.064-.272A12.379,12.379,0,0,0,3.96-3.352a10.824,10.824,0,0,0,1.792-1.96A9.683,9.683,0,0,0,6.928-7.536a9.322,9.322,0,0,0,.544-2.4.369.369,0,0,0-.064-.328.469.469,0,0,0-.32-.088H2q-.192,0-.192-.208v-.56A.17.17,0,0,1,2-11.312H7.392a1.3,1.3,0,0,1,.992.28A1.215,1.215,0,0,1,8.592-10.16Z" />, 
		strokes: [
		] 
	},
	'_ㄱ_v_c_3.svg': { 
		path: <path d="M8.224-10.672a7.46,7.46,0,0,1-1.936,3.92A12.888,12.888,0,0,1,1.744-3.808a.3.3,0,0,1-.232.048.257.257,0,0,1-.12-.144l-.24-.528a.2.2,0,0,1-.008-.208.3.3,0,0,1,.136-.112,12.186,12.186,0,0,0,3.992-2.5,5.908,5.908,0,0,0,1.752-3.128q.1-.464-.384-.464H1.712q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.3a1.484,1.484,0,0,1,1.04.272A.954.954,0,0,1,8.224-10.672Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅕ)_(ㄴ)_3.svg': { 
		path: <path d="M3.04-5.568a.463.463,0,0,0,.08.32.484.484,0,0,0,.3.1q.688.032,1.432.024t1.488-.04q.744-.032,1.456-.1t1.352-.184a.315.315,0,0,1,.216.008.264.264,0,0,1,.1.184l.048.432q.016.192-.064.248a.26.26,0,0,1-.144.056q-.64.112-1.416.2T6.3-4.192q-.816.048-1.616.056T3.2-4.16A1.361,1.361,0,0,1,2.208-4.5a1.475,1.475,0,0,1-.272-.984V-11.6a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅕ)_c_3.svg': { 
		path: <path d="M3.168-6.448a.54.54,0,0,0,.072.328.417.417,0,0,0,.3.1q1.52.032,3.184-.1a30.591,30.591,0,0,0,3.152-.4.243.243,0,0,1,.32.176l.064.48q.016.192-.208.272a14.071,14.071,0,0,1-1.424.256q-.832.112-1.76.176-.88.064-1.816.112a15.684,15.684,0,0,1-1.736,0,1.359,1.359,0,0,1-.976-.336,1.442,1.442,0,0,1-.272-.976v-5.312a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M12.592-8.112a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.832-9.2v-3.136a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.12q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M12.592-8.112a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.832-9.2v-3.136a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.12q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M12.592-8.112a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.832-9.2v-3.136a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.12q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M12.592-8.112a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.832-9.2v-3.136a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.12q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅗ)_c_3.svg': { 
		path: <path d="M12.592-7.36a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.664a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V-8.48a.4.4,0,0,0,.072.288.473.473,0,0,0,.3.064h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M12.592-8.16a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.088a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.072q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M12.56-8.1a.14.14,0,0,1-.08.136.328.328,0,0,1-.144.04H4.048a1.208,1.208,0,0,1-.984-.3,1.589,1.589,0,0,1-.232-.96V-12.3q0-.208.224-.208h.656q.224,0,.224.208v3.1q0,.224.072.28a.527.527,0,0,0,.3.056h8.032a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M12.56-8.1a.14.14,0,0,1-.08.136.328.328,0,0,1-.144.04H4.048a1.208,1.208,0,0,1-.984-.3,1.589,1.589,0,0,1-.232-.96V-12.3q0-.208.224-.208h.656q.224,0,.224.208v3.1q0,.224.072.28a.527.527,0,0,0,.3.056h8.032a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.56-8.1a.14.14,0,0,1-.08.136.328.328,0,0,1-.144.04H4.048a1.208,1.208,0,0,1-.984-.3,1.589,1.589,0,0,1-.232-.96V-12.3q0-.208.224-.208h.656q.224,0,.224.208v3.1q0,.224.072.28a.527.527,0,0,0,.3.056h8.032a.26.26,0,0,1,.144.056.176.176,0,0,1,.08.152Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅛ)_2.svg': { 
		path: <path d="M20.722-8.476a.255.255,0,0,1-.13.234.5.5,0,0,1-.26.078H6.37a1.932,1.932,0,0,1-1.6-.507,2.645,2.645,0,0,1-.377-1.573v-8.19a.322.322,0,0,1,.364-.364H5.824a.322.322,0,0,1,.364.364v8.164q0,.364.117.455a.857.857,0,0,0,.481.091H20.332a.451.451,0,0,1,.26.091.265.265,0,0,1,.13.221Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅛ)_c_3.svg': { 
		path: <path d="M12.56-7.344a.157.157,0,0,1-.08.144.287.287,0,0,1-.144.048H4.048a1.171,1.171,0,0,1-.984-.32,1.634,1.634,0,0,1-.232-.96V-12.08a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.6a.433.433,0,0,0,.072.3.433.433,0,0,0,.3.072h8.032a.26.26,0,0,1,.144.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M12.592-7.872a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.376a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.344q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M12.592-7.872a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.376a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.344q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅡ)_(ㅌ)_3.svg': { 
		path: <path d="M12.592-7.872a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.376a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.344q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M12.592-7.872a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.376a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.344q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_(ㅡ)_c_3.svg': { 
		path: <path d="M12.592-7.232a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.76a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.728a.4.4,0,0,0,.072.288A.473.473,0,0,0,4.3-8h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_1.svg': { 
		path: <path d="M11.312-1.68a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H4.928a1.273,1.273,0,0,1-.984-.28A1.477,1.477,0,0,1,3.712-2.7V-8.032a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v5.248q0,.224.072.28a.527.527,0,0,0,.3.056h5.888a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
			<path   d="M808.258,2168.711v5.406a1.326,1.326,0,0,0,.245.74,1.078,1.078,0,0,0,.794.286l6.065.109" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㄴ_hd_2.svg': { 
		path: <path d="M12.752-6.5a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H3.92a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.7-7.584v-4.48a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V-7.6q0,.224.072.28a.527.527,0,0,0,.3.056h8.336a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_hd_c_3.svg': { 
		path: <path d="M12.592-7.52a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312,1.628,1.628,0,0,1-.232-.968v-3.52a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V-8.64a.4.4,0,0,0,.072.288.473.473,0,0,0,.3.064h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_hu_(ㄹ)_3.svg': { 
		path: <path d="M12.592-8.112a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048h-8.3a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.832-9.2v-3.136a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v3.12q0,.224.072.28a.527.527,0,0,0,.3.056h8.048a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_hu_2.svg': { 
		path: <path d="M12.752-5.216a.157.157,0,0,1-.08.144.309.309,0,0,1-.16.048H3.92a1.189,1.189,0,0,1-.984-.312A1.628,1.628,0,0,1,2.7-6.3v-5.04a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224V-6.32q0,.224.072.28a.527.527,0,0,0,.3.056h8.336a.278.278,0,0,1,.16.056.163.163,0,0,1,.08.136Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_v_2.svg': { 
		path: <path d="M3.168-3.6a.463.463,0,0,0,.08.32.484.484,0,0,0,.3.1q.784.032,1.672.008t1.784-.1q.9-.072,1.752-.184t1.592-.256q.272-.064.32.192l.08.448q.048.224-.208.288-.544.128-1.4.24t-1.848.2q-.992.088-2.032.128t-1.936.024a1.3,1.3,0,0,1-.992-.336,1.509,1.509,0,0,1-.272-.992v-7.872a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_ㄴ_v_c_3.svg': { 
		path: <path d="M3.04-7.008a.463.463,0,0,0,.08.32.484.484,0,0,0,.3.1,29.034,29.034,0,0,0,3.16-.08A29.11,29.11,0,0,0,9.76-7.088q.256-.048.3.176l.08.5q.016.192-.208.256-.624.144-1.48.256t-1.768.192q-.912.08-1.824.112T3.2-5.6a1.361,1.361,0,0,1-.992-.344,1.475,1.475,0,0,1-.272-.984v-4.96a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅕ)_c_3.svg': { 
		path: <path d="M9.9-5.328q.048.224-.224.256-.592.112-1.424.2t-1.712.12q-.912.08-1.832.088t-1.72.008A1.2,1.2,0,0,1,2.032-5a1.614,1.614,0,0,1-.256-1v-4.688a1.207,1.207,0,0,1,.248-.9,1.467,1.467,0,0,1,.952-.224H8.368a.17.17,0,0,1,.192.192v.576q0,.176-.192.176H3.184q-.224,0-.272.056a.593.593,0,0,0-.048.312V-6.08a.46.46,0,0,0,.08.328.526.526,0,0,0,.3.088A31.284,31.284,0,0,0,6.4-5.7q1.632-.1,3.152-.3.144-.032.2.024a.382.382,0,0,1,.088.152Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M12.464-8.048q0,.224-.208.224H3.968a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.4a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.176a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.24a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-9.2a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M12.464-8.048q0,.224-.208.224H3.968a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.4a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.176a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.24a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-9.2a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M12.464-8.048q0,.224-.208.224H3.968a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.4a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.176a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.24a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-9.2a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M12.464-8.048q0,.224-.208.224H3.968a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.4a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.176a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.24a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-9.2a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅗ)_2.svg': { 
		path: <path d="M12.576-4.96q0,.224-.208.224H3.728a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-4.464a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.528a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4a.384.384,0,0,0-.3.1.535.535,0,0,0-.08.336v3.968a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.368a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅗ)_c_3.svg': { 
		path: <path d="M3.984-7.04a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.88a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.144a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.256a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v2.4a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H12.24a.184.184,0,0,1,.208.208v.528q0,.224-.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M12.416-8.048q0,.224-.208.224H3.952a1.541,1.541,0,0,1-.968-.224,1.158,1.158,0,0,1-.264-.9V-11.36a1.161,1.161,0,0,1,.264-.888,1.5,1.5,0,0,1,.968-.232H12.1a.184.184,0,0,1,.208.208v.544q0,.224-.208.224H4.224a.465.465,0,0,0-.312.072.5.5,0,0,0-.072.328v1.92a.506.506,0,0,0,.072.312.4.4,0,0,0,.312.088h7.984q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M12.416-8.048q0,.224-.208.224H3.952a1.541,1.541,0,0,1-.968-.224,1.158,1.158,0,0,1-.264-.9V-11.36a1.161,1.161,0,0,1,.264-.888,1.5,1.5,0,0,1,.968-.232H12.1a.184.184,0,0,1,.208.208v.544q0,.224-.208.224H4.224a.465.465,0,0,0-.312.072.5.5,0,0,0-.072.328v1.92a.506.506,0,0,0,.072.312.4.4,0,0,0,.312.088h7.984q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M12.416-8.048q0,.224-.208.224H3.952a1.541,1.541,0,0,1-.968-.224,1.158,1.158,0,0,1-.264-.9V-11.36a1.161,1.161,0,0,1,.264-.888,1.5,1.5,0,0,1,.968-.232H12.1a.184.184,0,0,1,.208.208v.544q0,.224-.208.224H4.224a.465.465,0,0,0-.312.072.5.5,0,0,0-.072.328v1.92a.506.506,0,0,0,.072.312.4.4,0,0,0,.312.088h7.984q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.416-8.048q0,.224-.208.224H3.952a1.541,1.541,0,0,1-.968-.224,1.158,1.158,0,0,1-.264-.9V-11.36a1.161,1.161,0,0,1,.264-.888,1.5,1.5,0,0,1,.968-.232H12.1a.184.184,0,0,1,.208.208v.544q0,.224-.208.224H4.224a.465.465,0,0,0-.312.072.5.5,0,0,0-.072.328v1.92a.506.506,0,0,0,.072.312.4.4,0,0,0,.312.088h7.984q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅛ)_c_3.svg': { 
		path: <path d="M12.4-7.248q0,.224-.208.224H3.968A1.5,1.5,0,0,1,3-7.256a1.161,1.161,0,0,1-.264-.888v-2.9A1.161,1.161,0,0,1,3-11.928a1.5,1.5,0,0,1,.968-.232H12.08a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.224a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v2.416a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h7.968a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅜ)_c_3.svg': { 
		path: <path d="M12.448-7.376q0,.224-.208.224H3.984a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.768a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.144a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.256a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v2.288a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H12.24a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_(ㅡ)_c_3.svg': { 
		path: <path d="M12.448-7.2q0,.224-.208.224H3.984a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.944a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.144a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.256a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v2.464a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H12.24a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_1.svg': { 
		path: <path d="M11.44-1.84q0,.224-.208.224H4.8a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-7.136a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h6.16a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H5.088a.406.406,0,0,0-.3.088A.5.5,0,0,0,4.7-6.9v3.9a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h6.144a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
			<path   d="M807.292,2169.254h7.665" transform="translate(0.24 -0.554)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.068,2169.66v4.767a.6.6,0,0,0,.242.464,1.708,1.708,0,0,0,.721.162h6.46" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㄷ_hd_2.svg': { 
		path: <path d="M12.576-6.384q0,.224-.208.224H3.728a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.76a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.528a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4a.384.384,0,0,0-.3.1.535.535,0,0,0-.08.336v3.248a.539.539,0,0,0,.08.328.366.366,0,0,0,.3.1h8.368a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_hd_c_3.svg': { 
		path: <path d="M12.5-7.376q0,.224-.208.224H3.936a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-2.768a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.24a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4.208a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v2.288a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.08A.184.184,0,0,1,12.5-7.9Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_hu_2.svg': { 
		path: <path d="M12.576-4.8q0,.224-.208.224H3.728a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-4.624a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.528a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v4.144a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.368a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_v_2.svg': { 
		path: <path d="M10.4-2.88a.222.222,0,0,1-.208.272q-.544.128-1.44.264t-1.9.232q-1.008.1-2.032.152t-1.808.04a1.268,1.268,0,0,1-.984-.336,1.542,1.542,0,0,1-.264-.992v-6.944a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224h5.5q.208,0,.208.192v.576q0,.192-.208.192h-5.3q-.224,0-.272.056a.547.547,0,0,0-.048.3v6.672A.5.5,0,0,0,2.944-3a.406.406,0,0,0,.3.088q.88,0,1.8-.04t1.8-.128q.88-.088,1.688-.2t1.48-.256q.256-.048.3.176Z" />, 
		strokes: [
		] 
	},
	'_ㄷ_v_c_3.svg': { 
		path: <path d="M10.176-5.824q.048.208-.192.272-.528.128-1.4.256T6.72-5.072q-.992.1-1.992.152t-1.784.04a1.268,1.268,0,0,1-.984-.336A1.542,1.542,0,0,1,1.7-6.208v-4.5a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H8.368q.208,0,.208.192v.576q0,.192-.208.192H3.12q-.224,0-.272.056a.547.547,0,0,0-.048.3v4.224a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088q.816,0,1.68-.04t1.712-.12q.848-.08,1.664-.192T9.776-6.48a.267.267,0,0,1,.216.016.316.316,0,0,1,.088.144Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅏ)_2.svg': { 
		path: <path d="M2.72-1.424a1.421,1.421,0,0,1-.968-.3A1.368,1.368,0,0,1,1.5-2.656V-5.824a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232H6.816A.435.435,0,0,0,7.1-7.024a.47.47,0,0,0,.064-.3V-9.984a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H1.792A.27.27,0,0,1,1.64-10.4a.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048H7.008a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v3.008a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H2.944q-.224,0-.28.064a.524.524,0,0,0-.056.3v2.9q0,.368.352.368a31.319,31.319,0,0,0,3.656-.144,28.8,28.8,0,0,0,3.4-.544.2.2,0,0,1,.272.16l.1.528q.032.208-.208.272-.848.192-1.784.328t-1.9.224q-.96.088-1.92.12T2.72-1.424Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅓ)_2.svg': { 
		path: <path d="M2.88-1.376a1.421,1.421,0,0,1-.968-.3,1.368,1.368,0,0,1-.248-.936V-5.792a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232H6.4a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-9.984a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H1.952A.27.27,0,0,1,1.8-10.4a.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h4.64a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568V-7.1a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H3.1q-.224,0-.28.064a.524.524,0,0,0-.056.3v2.912q0,.368.352.368A28.67,28.67,0,0,0,6.1-2.408a24.11,24.11,0,0,0,2.952-.44.2.2,0,0,1,.272.16l.1.528a.222.222,0,0,1-.208.272,25.868,25.868,0,0,1-3.088.448A23.688,23.688,0,0,1,2.88-1.376Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M2.992-5.008A1.346,1.346,0,0,1,2.024-5.3a1.4,1.4,0,0,1-.248-.944v-1.7a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232H6.208A.435.435,0,0,0,6.5-9.136a.47.47,0,0,0,.064-.3v-1.248a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.032q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192H6.4a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568v1.6a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H3.216q-.224,0-.28.064a.524.524,0,0,0-.056.3V-6.32q0,.368.352.368.736,0,1.408-.016T5.96-6.04q.648-.056,1.312-.144T8.64-6.4a.352.352,0,0,1,.216,0,.247.247,0,0,1,.088.16l.1.512q.032.144-.024.192a.479.479,0,0,1-.2.08q-.768.144-1.464.24t-1.384.144q-.688.048-1.416.064T2.992-5.008Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅕ)_(ㅊ)_3.svg': { 
		path: <path d="M2.992-5.12a1.386,1.386,0,0,1-.96-.3,1.4,1.4,0,0,1-.24-.936V-7.984a1.143,1.143,0,0,1,.28-.9,1.575,1.575,0,0,1,.984-.232H6.32a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-1.184a.524.524,0,0,0-.056-.3.368.368,0,0,0-.264-.064h-4.3q-.192,0-.192-.208v-.56A.17.17,0,0,1,2.032-12h4.48a2.722,2.722,0,0,1,.616.056.776.776,0,0,1,.376.2.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56V-9.3a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.216q-.224,0-.28.064a.524.524,0,0,0-.056.3v1.344q0,.368.352.368.736,0,1.408-.016T5.968-6.16q.624-.064,1.3-.144t1.36-.208q.16-.048.216-.008a.289.289,0,0,1,.088.168l.08.5q.048.16-.008.208a.459.459,0,0,1-.184.08q-.768.128-1.464.232A13.652,13.652,0,0,1,5.968-5.2q-.7.048-1.416.072T2.992-5.12Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M2.992-5.12a1.386,1.386,0,0,1-.96-.3,1.4,1.4,0,0,1-.24-.936V-7.984a1.143,1.143,0,0,1,.28-.9,1.575,1.575,0,0,1,.984-.232H6.32a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-1.184a.524.524,0,0,0-.056-.3.368.368,0,0,0-.264-.064h-4.3q-.192,0-.192-.208v-.56A.17.17,0,0,1,2.032-12h4.48a2.722,2.722,0,0,1,.616.056.776.776,0,0,1,.376.2.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56V-9.3a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.216q-.224,0-.28.064a.524.524,0,0,0-.056.3v1.344q0,.368.352.368.736,0,1.408-.016T5.968-6.16q.624-.064,1.3-.144t1.36-.208q.16-.048.216-.008a.289.289,0,0,1,.088.168l.08.5q.048.16-.008.208a.459.459,0,0,1-.184.08q-.768.128-1.464.232A13.652,13.652,0,0,1,5.968-5.2q-.7.048-1.416.072T2.992-5.12Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅕ)_(ㅎ)_3.svg': { 
		path: <path d="M2.992-5.12a1.386,1.386,0,0,1-.96-.3,1.4,1.4,0,0,1-.24-.936V-7.984a1.143,1.143,0,0,1,.28-.9,1.575,1.575,0,0,1,.984-.232H6.32a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-1.184a.524.524,0,0,0-.056-.3.368.368,0,0,0-.264-.064h-4.3q-.192,0-.192-.208v-.56A.17.17,0,0,1,2.032-12h4.48a2.722,2.722,0,0,1,.616.056.776.776,0,0,1,.376.2.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56V-9.3a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.216q-.224,0-.28.064a.524.524,0,0,0-.056.3v1.344q0,.368.352.368.736,0,1.408-.016T5.968-6.16q.624-.064,1.3-.144t1.36-.208q.16-.048.216-.008a.289.289,0,0,1,.088.168l.08.5q.048.16-.008.208a.459.459,0,0,1-.184.08q-.768.128-1.464.232A13.652,13.652,0,0,1,5.968-5.2q-.7.048-1.416.072T2.992-5.12Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅕ)_c_3.svg': { 
		path: <path d="M2.992-4.384a1.346,1.346,0,0,1-.968-.288,1.4,1.4,0,0,1-.248-.944V-7.568a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232H6.208A.435.435,0,0,0,6.5-8.768a.47.47,0,0,0,.064-.3v-1.5a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.032q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192H6.4a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568V-8.88a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H3.216q-.224,0-.28.064a.524.524,0,0,0-.056.3V-5.7q0,.368.352.368.752,0,1.432-.016t1.328-.072q.648-.056,1.3-.144T8.64-5.776a.352.352,0,0,1,.216,0,.247.247,0,0,1,.088.16l.1.512q.032.144-.024.192a.479.479,0,0,1-.2.08,25.645,25.645,0,0,1-2.72.368Q4.72-4.352,2.992-4.384Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952v-.88a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.64a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.912a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.688a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952v-.88a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.64a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.912a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.688a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952v-.88a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.64a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.912a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.688a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952v-.88a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.64a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.912a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.688a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅗ)_2.svg': { 
		path: <path d="M12.784-4.224a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.728a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-2a1.14,1.14,0,0,1,.28-.912,1.653,1.653,0,0,1,1-.224h7.216q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-1.664a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.736a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.448a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v2a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.952q-.224,0-.28.064a.524.524,0,0,0-.056.3V-5.36a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072H12.56a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M12.528-7.232a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.32a1.509,1.509,0,0,1-.232-.952v-.864a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.624a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.9a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M12.528-7.232a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.32a1.509,1.509,0,0,1-.232-.952v-.864a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.624a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.9a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M12.528-7.232a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.32a1.509,1.509,0,0,1-.232-.952v-.864a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.624a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.9a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.528-7.232a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.32a1.509,1.509,0,0,1-.232-.952v-.864a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.624a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.9a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅛ)_2.svg': { 
		path: <path d="M3.7-4.08a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952V-7.264a1.14,1.14,0,0,1,.28-.912,1.653,1.653,0,0,1,1-.224h7.28q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-1.664a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.7a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.7-11.76h8.512a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v2a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.92q-.224,0-.28.064a.524.524,0,0,0-.056.3v1.68a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.656a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.592a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅛ)_c_3.svg': { 
		path: <path d="M12.5-6.4a.16.16,0,0,1-.064.12.213.213,0,0,1-.144.056H3.968a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-1.1a1.143,1.143,0,0,1,.28-.9A1.642,1.642,0,0,1,4.048-9.7H10.8a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.864a.565.565,0,0,0-.056-.32q-.056-.064-.28-.064H2.976a.287.287,0,0,1-.144-.048.145.145,0,0,1-.08-.128v-.576a.145.145,0,0,1,.08-.128.287.287,0,0,1,.144-.048h8a2.833,2.833,0,0,1,.624.056.887.887,0,0,1,.4.2.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56V-9.9a1.124,1.124,0,0,1-.288.9,1.647,1.647,0,0,1-1.008.232H4.176a.443.443,0,0,0-.28.056.481.481,0,0,0-.056.3V-7.5a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.08a.234.234,0,0,1,.16.056.174.174,0,0,1,.064.136Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952V-9.1a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.656a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.928a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952V-9.1a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.656a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.928a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M12.528-7.2a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.288a1.509,1.509,0,0,1-.232-.952V-9.1a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.656a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.928a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224h-6.8q-.224,0-.28.064a.524.524,0,0,0-.056.3v.672a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅡ)_2.svg': { 
		path: <path d="M12.784-4.224a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.728a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-2a1.14,1.14,0,0,1,.28-.912,1.653,1.653,0,0,1,1-.224h7.216q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-1.664a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.736a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.448a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v2a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.952q-.224,0-.28.064a.524.524,0,0,0-.056.3V-5.36a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072H12.56a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_(ㅣ)_2.svg': { 
		path: <path d="M3.024-1.376a1.421,1.421,0,0,1-.968-.3,1.368,1.368,0,0,1-.248-.936V-5.792a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232H7.12a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-9.984a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.1a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048H7.312a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568V-7.1a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H3.248q-.224,0-.28.064a.524.524,0,0,0-.056.3v2.912q0,.368.352.368A31.319,31.319,0,0,0,6.92-2.464a28.8,28.8,0,0,0,3.4-.544.2.2,0,0,1,.272.16l.1.528q.048.208-.208.272-.848.192-1.784.328t-1.9.224q-.968.088-1.928.12T3.024-1.376Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_1.svg': { 
		path: <path d="M11.5-1.552a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H4.768a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-1.68a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h4.88A.435.435,0,0,0,10-5.472a.47.47,0,0,0,.064-.3V-7.168a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H3.744a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048H9.9a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v1.68a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-4.9q-.224,0-.28.064a.524.524,0,0,0-.056.3v1.456a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072H11.28a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
			<path   d="M807.3,2168.471h6.571a1.463,1.463,0,0,1,.8.306,2.119,2.119,0,0,1,.4.758v3.005" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M807.516,2172.286H814.1" transform="translate(0 -0.766)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.053,2172.479v2.05a.783.783,0,0,0,.224.557,1.238,1.238,0,0,0,.684.147h6.6" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㄹ_hd_(ㄹ)_3.svg': { 
		path: <path d="M12.528-7.36a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-7.448,1.509,1.509,0,0,1,2.768-8.4v-.8a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.56a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.576a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v.848a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.8a.443.443,0,0,0-.28.056.521.521,0,0,0-.056.312v.592a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hd_(ㅊ)_3.svg': { 
		path: <path d="M12.432-7.208a.213.213,0,0,1-.144.056H3.968a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-.8a1.143,1.143,0,0,1,.28-.9,1.642,1.642,0,0,1,1.016-.232H10.8a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.56a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.976a.287.287,0,0,1-.144-.048.135.135,0,0,1-.08-.112v-.592a.128.128,0,0,1,.08-.12.328.328,0,0,1,.144-.04h8a2.833,2.833,0,0,1,.624.056.991.991,0,0,1,.4.184.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56v.848a1.124,1.124,0,0,1-.288.9,1.647,1.647,0,0,1-1.008.232H4.176a.443.443,0,0,0-.28.056.481.481,0,0,0-.056.3v.608a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.08a.259.259,0,0,1,.16.048A.138.138,0,0,1,12.5-7.9,4.167,4.167,0,0,1,12.432-7.208Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hd_(ㅌ)_3.svg': { 
		path: <path d="M12.5-7.344a.174.174,0,0,1-.064.136.213.213,0,0,1-.144.056H3.968a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-.8a1.143,1.143,0,0,1,.28-.9,1.642,1.642,0,0,1,1.016-.232H10.8a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.56a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.976a.287.287,0,0,1-.144-.048.135.135,0,0,1-.08-.112v-.592a.128.128,0,0,1,.08-.12.328.328,0,0,1,.144-.04h8a2.833,2.833,0,0,1,.624.056.991.991,0,0,1,.4.184.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56v.848a1.124,1.124,0,0,1-.288.9,1.647,1.647,0,0,1-1.008.232H4.176a.443.443,0,0,0-.28.056.481.481,0,0,0-.056.3v.608a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.08a.259.259,0,0,1,.16.048A.138.138,0,0,1,12.5-7.9Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hd_(ㅎ)_3.svg': { 
		path: <path d="M12.5-7.344a.174.174,0,0,1-.064.136.213.213,0,0,1-.144.056H3.968a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-.8a1.143,1.143,0,0,1,.28-.9,1.642,1.642,0,0,1,1.016-.232H10.8a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.56a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.976a.287.287,0,0,1-.144-.048.135.135,0,0,1-.08-.112v-.592a.128.128,0,0,1,.08-.12.328.328,0,0,1,.144-.04h8a2.833,2.833,0,0,1,.624.056.991.991,0,0,1,.4.184.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56v.848a1.124,1.124,0,0,1-.288.9,1.647,1.647,0,0,1-1.008.232H4.176a.443.443,0,0,0-.28.056.481.481,0,0,0-.056.3v.608a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.08a.259.259,0,0,1,.16.048A.138.138,0,0,1,12.5-7.9Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hd_2.svg': { 
		path: <path d="M12.752-5.456a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.76a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.544-6.5V-8.032a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h7.168q.224,0,.28-.064a.524.524,0,0,0,.056-.3V-10.88a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.768a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.592a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.384a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568V-9.36a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H3.984q-.224,0-.28.064a.524.524,0,0,0-.056.3v1.28a.473.473,0,0,0,.064.3A.4.4,0,0,0,4-6.208h8.528a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hd_c_3.svg': { 
		path: <path d="M12.528-6.592a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-6.68a1.509,1.509,0,0,1-.232-.952v-1.04a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.8a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-12.1a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v1.088a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.8a.443.443,0,0,0-.28.056.521.521,0,0,0-.056.312v.832a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hu_2.svg': { 
		path: <path d="M12.784-4.224a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.728a1.273,1.273,0,0,1-.984-.28,1.509,1.509,0,0,1-.232-.952v-2a1.14,1.14,0,0,1,.28-.912,1.653,1.653,0,0,1,1-.224h7.216q.224,0,.28-.064a.524.524,0,0,0,.056-.3v-1.664a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.736a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.448a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v2a1.14,1.14,0,0,1-.28.912,1.653,1.653,0,0,1-1,.224H3.952q-.224,0-.28.064a.524.524,0,0,0-.056.3V-5.36a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072H12.56a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_hu_c_3.svg': { 
		path: <path d="M12.528-6.432a.167.167,0,0,1-.072.136.408.408,0,0,1-.136.056H3.984A1.273,1.273,0,0,1,3-6.52a1.509,1.509,0,0,1-.232-.952v-1.12a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.784a.443.443,0,0,0,.28-.056.521.521,0,0,0,.056-.312v-.88a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.992a.27.27,0,0,1-.152-.048.147.147,0,0,1-.072-.128V-12.1a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048h8.016a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.46,2.46,0,0,1,.056.568v1.168a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.8a.443.443,0,0,0-.28.056.521.521,0,0,0-.056.312v.912a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.1a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_v_2.svg': { 
		path: <path d="M2.88-1.376a1.421,1.421,0,0,1-.968-.3,1.368,1.368,0,0,1-.248-.936V-5.792a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232H6.176a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3V-9.984a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H1.952A.27.27,0,0,1,1.8-10.4a.147.147,0,0,1-.072-.128v-.608a.147.147,0,0,1,.072-.128.27.27,0,0,1,.152-.048H6.368a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36,2.461,2.461,0,0,1,.056.568V-7.1a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232H3.1q-.224,0-.28.064a.524.524,0,0,0-.056.3v2.912q0,.368.352.368a25.9,25.9,0,0,0,2.928-.1A26,26,0,0,0,8.9-2.848a.2.2,0,0,1,.272.16l.1.528a.222.222,0,0,1-.208.272,26.125,26.125,0,0,1-3.008.448A22.379,22.379,0,0,1,2.88-1.376Z" />, 
		strokes: [
		] 
	},
	'_ㄹ_v_c_3.svg': { 
		path: <path d="M2.848-5.12A1.223,1.223,0,0,1,1.9-5.4a1.471,1.471,0,0,1-.24-.952V-7.984a1.143,1.143,0,0,1,.28-.9,1.575,1.575,0,0,1,.984-.232h3.84a.484.484,0,0,0,.288-.056q.064-.056.064-.312v-1.184a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H1.9q-.192,0-.192-.208v-.56A.17.17,0,0,1,1.9-12H6.96a2.947,2.947,0,0,1,.632.056.69.69,0,0,1,.376.2.675.675,0,0,1,.2.352,2.356,2.356,0,0,1,.056.56V-9.3a1.14,1.14,0,0,1-.28.912,1.618,1.618,0,0,1-.984.224H3.088q-.24,0-.3.064a.524.524,0,0,0-.056.3v1.344A.325.325,0,0,0,3.1-6.08q1.872,0,3.472-.136T9.84-6.624q.16-.048.216-.008a.236.236,0,0,1,.072.168l.112.5q.016.16-.04.208a.479.479,0,0,1-.2.08q-1.488.256-3.336.416T2.848-5.12Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅏ)_(ㅎ)_3.svg': { 
		path: <path d="M3.184-10.976a.376.376,0,0,0-.3.088.54.54,0,0,0-.072.328v3.92a.54.54,0,0,0,.072.328.376.376,0,0,0,.3.088H7.04a.376.376,0,0,0,.3-.088.54.54,0,0,0,.072-.328v-3.92a.54.54,0,0,0-.072-.328.376.376,0,0,0-.3-.088ZM1.712-10.8a1.187,1.187,0,0,1,.264-.9,1.468,1.468,0,0,1,.952-.232H7.312a2.722,2.722,0,0,1,.616.056.839.839,0,0,1,.36.168.6.6,0,0,1,.184.36,3.3,3.3,0,0,1,.04.552v4.4a1.215,1.215,0,0,1-.256.9,1.4,1.4,0,0,1-.944.24H2.928A2.813,2.813,0,0,1,2.3-5.32a.679.679,0,0,1-.36-.2.683.683,0,0,1-.176-.344A2.522,2.522,0,0,1,1.712-6.4Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅑ)_(ㅎ)_3.svg': { 
		path: <path d="M3.184-10.976a.376.376,0,0,0-.3.088.54.54,0,0,0-.072.328v3.92a.54.54,0,0,0,.072.328.376.376,0,0,0,.3.088H7.04a.376.376,0,0,0,.3-.088.54.54,0,0,0,.072-.328v-3.92a.54.54,0,0,0-.072-.328.376.376,0,0,0-.3-.088ZM1.712-10.8a1.187,1.187,0,0,1,.264-.9,1.468,1.468,0,0,1,.952-.232H7.312a2.722,2.722,0,0,1,.616.056.839.839,0,0,1,.36.168.6.6,0,0,1,.184.36,3.3,3.3,0,0,1,.04.552v4.4a1.215,1.215,0,0,1-.256.9,1.4,1.4,0,0,1-.944.24H2.928A2.813,2.813,0,0,1,2.3-5.32a.679.679,0,0,1-.36-.2.683.683,0,0,1-.176-.344A2.522,2.522,0,0,1,1.712-6.4Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M1.776-10.816a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h4.4a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.5a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.4a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-6.56a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H7.12a.406.406,0,0,0,.3-.088A.5.5,0,0,0,7.5-6.56v-4.016a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅕ)_(ㅂ)_3.svg': { 
		path: <path d="M1.776-10.816a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h4.4a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.5a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.4a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-6.56a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H7.12a.406.406,0,0,0,.3-.088A.5.5,0,0,0,7.5-6.56v-4.016a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M1.776-10.816a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h4.4a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.5a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.4a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-6.56a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H7.12a.406.406,0,0,0,.3-.088A.5.5,0,0,0,7.5-6.56v-4.016a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅕ)_(ㅎ)_3.svg': { 
		path: <path d="M1.776-10.816a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h4.4a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.5a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.4a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-6.56a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H7.12a.406.406,0,0,0,.3-.088A.5.5,0,0,0,7.5-6.56v-4.016a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅕ)_2.svg': { 
		path: <path d="M1.616-10.176a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H7.376a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v7.088a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H2.832A3.161,3.161,0,0,1,2.216-2a.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552ZM3.1-10.352a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v6.608A.5.5,0,0,0,2.8-3a.406.406,0,0,0,.3.088h4A.406.406,0,0,0,7.408-3a.5.5,0,0,0,.08-.328V-9.936a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅕ)_c_3.svg': { 
		path: <path d="M3.264-10.752a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v4.128a.535.535,0,0,0,.08.336.384.384,0,0,0,.3.1H7.12a.357.357,0,0,0,.3-.1.581.581,0,0,0,.072-.336v-4.128a.54.54,0,0,0-.072-.328.376.376,0,0,0-.3-.088Zm-1.472.176a1.238,1.238,0,0,1,.248-.9,1.4,1.4,0,0,1,.936-.232h4.4a3.161,3.161,0,0,1,.616.048.8.8,0,0,1,.376.176.688.688,0,0,1,.176.352,2.734,2.734,0,0,1,.048.56v4.608a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.4a3.057,3.057,0,0,1-.6-.048.638.638,0,0,1-.36-.192.683.683,0,0,1-.176-.344,2.616,2.616,0,0,1-.048-.552Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅗ)_2.svg': { 
		path: <path d="M2.512-10.336a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.5a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.464a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-7.5a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184A.7.7,0,0,1,2.56-5.32a2.616,2.616,0,0,1-.048-.552ZM4-10.512a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v3.984a.5.5,0,0,0,.08.328A.406.406,0,0,0,4-5.7h6.96a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-10.1a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅛ)_2.svg': { 
		path: <path d="M2.464-10.336a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.6a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.464a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.68a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,2.7-4.968a.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v3.984a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h7.056a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-10.1a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_(ㅡ)_c_3.svg': { 
		path: <path d="M4.24-11.216a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v2.544a.531.531,0,0,0,.08.344.406.406,0,0,0,.3.088h6.544a.379.379,0,0,0,.288-.088.531.531,0,0,0,.08-.344V-10.8a.5.5,0,0,0-.08-.328.379.379,0,0,0-.288-.088Zm-1.488.176a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H11.04a3.14,3.14,0,0,1,.624.048.792.792,0,0,1,.368.176.6.6,0,0,1,.176.336,2.734,2.734,0,0,1,.048.56V-8A1.184,1.184,0,0,1,12-7.112a1.464,1.464,0,0,1-.96.232H3.968a3.035,3.035,0,0,1-.608-.048.72.72,0,0,1-.368-.192A.554.554,0,0,1,2.8-7.456,2.64,2.64,0,0,1,2.752-8Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_1.svg': { 
		path: <path d="M3.712-6.912a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h5.184A3.161,3.161,0,0,1,10.728-8a.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V-2.7a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H4.928a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,3.944-1.8a.7.7,0,0,1-.184-.352A2.616,2.616,0,0,1,3.712-2.7ZM5.2-7.088A.406.406,0,0,0,4.9-7a.5.5,0,0,0-.08.328v3.728a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H9.84a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-6.672A.5.5,0,0,0,10.144-7a.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
			<path   d="M808.223,2168.9v6.579" transform="translate(-0.403)" fill="none" stroke="#000" stroke-width="2"/>,
			<path   d="M808.764,2169.768h5.178a1.55,1.55,0,0,1,.791.333,2.145,2.145,0,0,1,.485.819v5" transform="translate(0 -0.45)" fill="none" stroke="#707070" stroke-width="2" opacity="0.792"/>,
			<path   d="M808.727,2174.912H814.3" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅁ_hd_2.svg': { 
		path: <path d="M2.512-11.056a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.5a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56V-7.28a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-7.5a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552ZM4-11.232a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v3.3a.5.5,0,0,0,.08.328A.406.406,0,0,0,4-7.1h6.96a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328v-3.3a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hd_c_3.svg': { 
		path: <path d="M4.24-11.216a.384.384,0,0,0-.3.1.535.535,0,0,0-.08.336v2.272a.535.535,0,0,0,.08.336.384.384,0,0,0,.3.1h6.512a.384.384,0,0,0,.3-.1.535.535,0,0,0,.08-.336v-2.272a.535.535,0,0,0-.08-.336.384.384,0,0,0-.3-.1Zm-1.5.192A1.217,1.217,0,0,1,3-11.936a1.463,1.463,0,0,1,.968-.24h7.056a2.6,2.6,0,0,1,.616.056.992.992,0,0,1,.376.184.6.6,0,0,1,.176.344,2.856,2.856,0,0,1,.048.568v2.752a1.242,1.242,0,0,1-.256.912,1.428,1.428,0,0,1-.96.24H3.968a2.613,2.613,0,0,1-.608-.056.856.856,0,0,1-.368-.184.771.771,0,0,1-.2-.36,2.254,2.254,0,0,1-.056-.552Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hu_(ㄹ)_3.svg': { 
		path: <path d="M2.768-11.344a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.088a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v2.688a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.984a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,3-7.752.7.7,0,0,1,2.816-8.1a2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-8.9a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H10.8a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-11.1a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hu_(ㅊ)_3.svg': { 
		path: <path d="M4.24-11.488a.433.433,0,0,0-.3.08.463.463,0,0,0-.08.32v2.224a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.544a.379.379,0,0,0,.288-.088.5.5,0,0,0,.08-.328v-2.224a.463.463,0,0,0-.08-.32.4.4,0,0,0-.288-.08Zm-1.488.16a1.188,1.188,0,0,1,.256-.88,1.428,1.428,0,0,1,.96-.24H11.04a3.753,3.753,0,0,1,.624.04.673.673,0,0,1,.368.184.792.792,0,0,1,.176.368,2.547,2.547,0,0,1,.048.528v2.7A1.184,1.184,0,0,1,12-7.736a1.464,1.464,0,0,1-.96.232H3.968a3.627,3.627,0,0,1-.608-.04.628.628,0,0,1-.368-.2A.627.627,0,0,1,2.8-8.088a2.522,2.522,0,0,1-.048-.536Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hu_(ㅌ)_3.svg': { 
		path: <path d="M4.24-11.488a.433.433,0,0,0-.3.08.463.463,0,0,0-.08.32v2.224a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.544a.379.379,0,0,0,.288-.088.5.5,0,0,0,.08-.328v-2.224a.463.463,0,0,0-.08-.32.4.4,0,0,0-.288-.08Zm-1.488.16a1.188,1.188,0,0,1,.256-.88,1.428,1.428,0,0,1,.96-.24H11.04a3.753,3.753,0,0,1,.624.04.673.673,0,0,1,.368.184.792.792,0,0,1,.176.368,2.547,2.547,0,0,1,.048.528v2.7A1.184,1.184,0,0,1,12-7.736a1.464,1.464,0,0,1-.96.232H3.968a3.627,3.627,0,0,1-.608-.04.628.628,0,0,1-.368-.2A.627.627,0,0,1,2.8-8.088a2.522,2.522,0,0,1-.048-.536Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hu_(ㅎ)_3.svg': { 
		path: <path d="M4.24-11.488a.433.433,0,0,0-.3.08.463.463,0,0,0-.08.32v2.224a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.544a.379.379,0,0,0,.288-.088.5.5,0,0,0,.08-.328v-2.224a.463.463,0,0,0-.08-.32.4.4,0,0,0-.288-.08Zm-1.488.16a1.188,1.188,0,0,1,.256-.88,1.428,1.428,0,0,1,.96-.24H11.04a3.753,3.753,0,0,1,.624.04.673.673,0,0,1,.368.184.792.792,0,0,1,.176.368,2.547,2.547,0,0,1,.048.528v2.7A1.184,1.184,0,0,1,12-7.736a1.464,1.464,0,0,1-.96.232H3.968a3.627,3.627,0,0,1-.608-.04.628.628,0,0,1-.368-.2A.627.627,0,0,1,2.8-8.088a2.522,2.522,0,0,1-.048-.536Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hu_2.svg': { 
		path: <path d="M2.512-10.336a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.5a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v4.624a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-7.5a3.161,3.161,0,0,1-.616-.048.709.709,0,0,1-.368-.184A.7.7,0,0,1,2.56-5.16a2.616,2.616,0,0,1-.048-.552ZM4-10.512a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v4.144a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088h6.96a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328V-10.1a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_hu_c_3.svg': { 
		path: <path d="M2.768-11.056a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h7.088a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v2.88a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232H3.984a3.161,3.161,0,0,1-.616-.048A.709.709,0,0,1,3-7.272a.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v2.4a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088H10.8a.406.406,0,0,0,.3-.088.5.5,0,0,0,.08-.328v-2.4a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_v_2.svg': { 
		path: <path d="M1.76-10.176a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h4.7a3.161,3.161,0,0,1,.616.048.744.744,0,0,1,.368.176.658.658,0,0,1,.184.352,2.734,2.734,0,0,1,.048.56v7.088a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.7A3.161,3.161,0,0,1,2.36-2a.709.709,0,0,1-.368-.184.7.7,0,0,1-.184-.352,2.616,2.616,0,0,1-.048-.552Zm1.488-.176a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v6.608A.5.5,0,0,0,2.944-3a.406.406,0,0,0,.3.088h4.16A.406.406,0,0,0,7.712-3a.5.5,0,0,0,.08-.328V-9.936a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088Z" />, 
		strokes: [
		] 
	},
	'_ㅁ_v_c_3.svg': { 
		path: <path d="M3.264-10.752a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v4.128a.535.535,0,0,0,.08.336.384.384,0,0,0,.3.1H7.12a.357.357,0,0,0,.3-.1.581.581,0,0,0,.072-.336v-4.128a.54.54,0,0,0-.072-.328.376.376,0,0,0-.3-.088Zm-1.472.176a1.238,1.238,0,0,1,.248-.9,1.4,1.4,0,0,1,.936-.232h4.4a3.161,3.161,0,0,1,.616.048.8.8,0,0,1,.376.176.688.688,0,0,1,.176.352,2.734,2.734,0,0,1,.048.56v4.608a1.212,1.212,0,0,1-.256.9,1.464,1.464,0,0,1-.96.232h-4.4a3.057,3.057,0,0,1-.6-.048.638.638,0,0,1-.36-.192.683.683,0,0,1-.176-.344,2.616,2.616,0,0,1-.048-.552Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅕ)_2.svg': { 
		path: <path d="M2.816-1.952A3.161,3.161,0,0,1,2.2-2a.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552V-11.5a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056h.688a.174.174,0,0,1,.136.064A.2.2,0,0,1,2.7-11.5v3.712H7.5v-3.7a.213.213,0,0,1,.056-.144A.174.174,0,0,1,7.7-11.7h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v8.528a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM2.7-6.848V-3.28a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072H7.12a.468.468,0,0,0,.3-.072A.43.43,0,0,0,7.5-3.28V-6.848Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅕ)_c_3.svg': { 
		path: <path d="M2.864-8.368V-6.24a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072H7.12a.465.465,0,0,0,.312-.072A.465.465,0,0,0,7.5-6.24V-8.368Zm.112,3.456a3.779,3.779,0,0,1-.616-.04.5.5,0,0,1-.344-.2A.591.591,0,0,1,1.84-5.48a2.616,2.616,0,0,1-.048-.552v-5.76a.168.168,0,0,1,.056-.12A.205.205,0,0,1,2-11.968h.672a.174.174,0,0,1,.136.064.184.184,0,0,1,.056.112v2.48H7.5v-2.464A.2.2,0,0,1,7.56-11.9a.192.192,0,0,1,.152-.064h.672a.205.205,0,0,1,.152.056.168.168,0,0,1,.056.12V-5.92a1,1,0,0,1-.256.8,1.593,1.593,0,0,1-.96.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅗ)_2.svg': { 
		path: <path d="M3.744-4.544a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552v-6a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056H3.44a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v2.544h7.7v-2.528a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v6.112a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM3.632-8.192v2.32a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.928a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312v-2.32Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅗ)_c_3.svg': { 
		path: <path d="M4-6.56a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352A2.616,2.616,0,0,1,2.8-7.7v-4.528a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056H3.7a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v1.808h7.264v-1.792a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v4.64a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM3.888-9.472v1.584a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.5a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-9.472Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_1.svg': { 
		path: <path d="M4.912-1.44A3.161,3.161,0,0,1,4.3-1.488a.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552V-7.952a.152.152,0,0,1,.064-.128.234.234,0,0,1,.144-.048h.7a.185.185,0,0,1,.136.056.168.168,0,0,1,.056.12v2.1h5.408V-7.92a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.7a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v5.472a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM4.816-4.9v2.112a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072H9.84a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-4.9Z" />, 
		strokes: [
			<path   d="M808.251,2168.785v6.825" transform="translate(-0.431)" fill="none" stroke="#696969" stroke-width="2"/>,
			<path   d="M808.251,2168.785v6.825" transform="translate(6.969)" fill="none" stroke="#696969" stroke-width="2"/>,
			<path   d="M808.781,2171.5h5.486" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.781,2171.5h5.486" transform="translate(0 3.1)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅂ_hd_2.svg': { 
		path: <path d="M3.744-6.048A3.161,3.161,0,0,1,3.128-6.1a.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552v-5.088a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056H3.44a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v2.128h7.7v-2.112a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v5.2a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM3.632-9.2v1.824a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.928a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-9.2Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hd_c_3.svg': { 
		path: <path d="M4-6.976a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352A2.616,2.616,0,0,1,2.8-8.112v-4.112a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056H3.7a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v1.6h7.264v-1.584a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136V-8a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.112-2.7V-8.3a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.5a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-9.68Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hu_(ㄹ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hu_(ㅊ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hu_(ㅌ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hu_(ㅎ)_3.svg': { 
		path: <path d="M3.872-9.92v1.248a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.92Zm.112,2.576a3.161,3.161,0,0,1-.616-.048.56.56,0,0,1-.344-.192.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-8.48v-3.888a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.167.167,0,0,1,.136.072.215.215,0,0,1,.056.12v1.5H11.12v-1.488a.226.226,0,0,1,.056-.136.167.167,0,0,1,.136-.072H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4a1.042,1.042,0,0,1-.248.816,1.554,1.554,0,0,1-.952.208Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hu_2.svg': { 
		path: <path d="M3.744-4.384a3.161,3.161,0,0,1-.616-.048.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552v-6.16a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056H3.44a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v2.624h7.7v-2.608a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v6.272a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208ZM3.632-8.112v2.4a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072h6.928a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312v-2.4Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_hu_c_3.svg': { 
		path: <path d="M3.872-9.44v1.568a.463.463,0,0,0,.08.32.463.463,0,0,0,.32.08h6.464a.433.433,0,0,0,.3-.08.463.463,0,0,0,.08-.32V-9.44Zm.112,2.9a3.779,3.779,0,0,1-.616-.04.5.5,0,0,1-.344-.2.587.587,0,0,1-.184-.344A3.124,3.124,0,0,1,2.8-7.68v-4.512a.174.174,0,0,1,.064-.136.2.2,0,0,1,.128-.056H3.68a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v1.824H11.12v-1.808a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064H12a.2.2,0,0,1,.128.056.174.174,0,0,1,.064.136v4.624a1.039,1.039,0,0,1-.248.824,1.6,1.6,0,0,1-.952.2Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_v_2.svg': { 
		path: <path d="M2.944-1.952A3.161,3.161,0,0,1,2.328-2a.663.663,0,0,1-.36-.184.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552V-11.5a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056H2.64a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v3.712h4.96v-3.7a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064h.688a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v8.528a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.112-4.9V-3.28a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072H7.408a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-6.848Z" />, 
		strokes: [
		] 
	},
	'_ㅂ_v_c_3.svg': { 
		path: <path d="M2.976-5.248A3.161,3.161,0,0,1,2.36-5.3.663.663,0,0,1,2-5.48a.733.733,0,0,1-.176-.352,2.616,2.616,0,0,1-.048-.552v-5.568a.174.174,0,0,1,.064-.136.213.213,0,0,1,.144-.056h.688a.174.174,0,0,1,.136.064.2.2,0,0,1,.056.128v2.4H7.52v-2.384a.213.213,0,0,1,.056-.144.174.174,0,0,1,.136-.064H8.4a.213.213,0,0,1,.144.056.174.174,0,0,1,.064.136v5.68a1.021,1.021,0,0,1-.256.816,1.593,1.593,0,0,1-.96.208Zm-.112-3.36v2.032a.43.43,0,0,0,.08.312.468.468,0,0,0,.3.072H7.136a.468.468,0,0,0,.3-.072.43.43,0,0,0,.08-.312V-8.608Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅑ)_(ㅅ)_3.svg': { 
		path: <path d="M5.856-11.776a10.141,10.141,0,0,1-.064,1.152A8.305,8.305,0,0,1,5.6-9.552a6.423,6.423,0,0,0,1.352,2.36A7.253,7.253,0,0,0,9.136-5.52a.133.133,0,0,1,.088.136.424.424,0,0,1-.04.152L8.848-4.7a.281.281,0,0,1-.112.112q-.064.032-.208-.048A6.435,6.435,0,0,1,7.52-5.272,9.842,9.842,0,0,1,6.568-6.1a8.639,8.639,0,0,1-.832-.944A6.843,6.843,0,0,1,5.1-8.032,8.068,8.068,0,0,1,3.68-5.824a10.665,10.665,0,0,1-2.1,1.776.5.5,0,0,1-.176.064.16.16,0,0,1-.16-.064l-.4-.528a.166.166,0,0,1-.016-.16A.255.255,0,0,1,.944-4.88,7.8,7.8,0,0,0,3.808-7.824a7.957,7.957,0,0,0,.928-3.968q0-.256.192-.256L5.68-12Q5.856-12,5.856-11.776Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅕ)_(ㄴ)_3.svg': { 
		path: <path d="M5.952-11.776a11.2,11.2,0,0,1-.4,2.832A6.445,6.445,0,0,0,6.08-7.736a8.572,8.572,0,0,0,.768,1.144,7.88,7.88,0,0,0,.936.992,5.344,5.344,0,0,0,1.048.736.145.145,0,0,1,.08.128.428.428,0,0,1-.032.16l-.352.512a.315.315,0,0,1-.128.1q-.064.024-.208-.056a4.731,4.731,0,0,1-.856-.608,9.431,9.431,0,0,1-.864-.864,12.287,12.287,0,0,1-.8-1.008,9.41,9.41,0,0,1-.648-1.04A9.264,9.264,0,0,1,1.664-3.7a.5.5,0,0,1-.176.064.16.16,0,0,1-.16-.064l-.4-.528a.166.166,0,0,1-.016-.16.255.255,0,0,1,.112-.144A7.187,7.187,0,0,0,2.6-5.84,8.547,8.547,0,0,0,3.816-7.568a8.628,8.628,0,0,0,.768-2.024,8.7,8.7,0,0,0,.248-2.2q0-.256.192-.256L5.776-12Q5.952-12,5.952-11.776Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M6-12.016A8.124,8.124,0,0,1,5.6-9.6a5.925,5.925,0,0,0,.608.984,6.994,6.994,0,0,0,.824.912,7.541,7.541,0,0,0,.976.768,6.51,6.51,0,0,0,1.048.568.121.121,0,0,1,.1.12A.336.336,0,0,1,9.12-6.1l-.3.544a.167.167,0,0,1-.136.1A.45.45,0,0,1,8.5-5.472a6.365,6.365,0,0,1-.976-.536,8.82,8.82,0,0,1-.944-.72,9.517,9.517,0,0,1-.84-.832,5.657,5.657,0,0,1-.648-.872,7.281,7.281,0,0,1-1.32,1.688,12.649,12.649,0,0,1-1.928,1.5.431.431,0,0,1-.184.088q-.072.008-.152-.088l-.4-.5q-.128-.176.08-.32a11.007,11.007,0,0,0,1.7-1.288A6.66,6.66,0,0,0,4.024-8.744a5.684,5.684,0,0,0,.648-1.544,7.041,7.041,0,0,0,.208-1.744.261.261,0,0,1,.064-.192.184.184,0,0,1,.128-.064l.752.048Q6-12.24,6-12.016Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M9.008-5.616A.154.154,0,0,1,8.88-5.5.251.251,0,0,1,8.7-5.536a5,5,0,0,1-1.072-.576,9.2,9.2,0,0,1-1.024-.832A8.947,8.947,0,0,1,5.68-7.92q-.272-.352-.5-.7-.112.192-.224.4a8.743,8.743,0,0,1-1.312,1.56,12.034,12.034,0,0,1-1.824,1.4.59.59,0,0,1-.176.1q-.064.016-.16-.1L1.1-5.76a.22.22,0,0,1,.064-.32,10.362,10.362,0,0,0,1.7-1.28A7.3,7.3,0,0,0,4.048-8.768a6.122,6.122,0,0,0,.608-1.52,7.041,7.041,0,0,0,.208-1.744.261.261,0,0,1,.064-.192.2.2,0,0,1,.144-.064l.736.064q.176,0,.176.208A7.782,7.782,0,0,1,5.7-9.968a.3.3,0,0,0-.016.1,6.467,6.467,0,0,0,.48.832,7.842,7.842,0,0,0,.928,1.12,7.292,7.292,0,0,0,1.056.9,5.305,5.305,0,0,0,1.088.6.1.1,0,0,1,.1.112.673.673,0,0,1-.016.16Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅕ)_(ㅎ)_3.svg': { 
		path: <path d="M6-12.016A8.124,8.124,0,0,1,5.6-9.6a5.925,5.925,0,0,0,.608.984,6.994,6.994,0,0,0,.824.912,7.541,7.541,0,0,0,.976.768,6.51,6.51,0,0,0,1.048.568.121.121,0,0,1,.1.12A.336.336,0,0,1,9.12-6.1l-.3.544a.167.167,0,0,1-.136.1A.45.45,0,0,1,8.5-5.472a6.365,6.365,0,0,1-.976-.536,8.82,8.82,0,0,1-.944-.72,9.517,9.517,0,0,1-.84-.832,5.657,5.657,0,0,1-.648-.872,7.281,7.281,0,0,1-1.32,1.688,12.649,12.649,0,0,1-1.928,1.5.431.431,0,0,1-.184.088q-.072.008-.152-.088l-.4-.5q-.128-.176.08-.32a11.007,11.007,0,0,0,1.7-1.288A6.66,6.66,0,0,0,4.024-8.744a5.684,5.684,0,0,0,.648-1.544,7.041,7.041,0,0,0,.208-1.744.261.261,0,0,1,.064-.192.184.184,0,0,1,.128-.064l.752.048Q6-12.24,6-12.016Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M8.08-12.352l-.032.192A1.264,1.264,0,0,1,8-11.968a3.006,3.006,0,0,0,.352.72,5.287,5.287,0,0,0,1.816,1.656,10.437,10.437,0,0,0,2.968,1.1.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008A10.282,10.282,0,0,1,9.5-8.824a6.419,6.419,0,0,1-2.032-1.944A6.294,6.294,0,0,1,5.5-8.856,11.51,11.51,0,0,1,2.384-7.5q-.256.064-.32-.08l-.288-.56a.281.281,0,0,1-.008-.176A.176.176,0,0,1,1.9-8.448a11.5,11.5,0,0,0,2.064-.744,8.13,8.13,0,0,0,1.56-.96,4.467,4.467,0,0,0,1.016-1.112,2.824,2.824,0,0,0,.432-1.2q.032-.224.208-.192l.72.08Q8.112-12.576,8.08-12.352Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M8.08-12.352q-.016.1-.032.184A1.343,1.343,0,0,1,8-11.984a2.608,2.608,0,0,0,.336.736,4.7,4.7,0,0,0,.928,1.072,7.223,7.223,0,0,0,1.176.816,9.376,9.376,0,0,0,1.328.608,12.052,12.052,0,0,0,1.368.416.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008,9.332,9.332,0,0,1-1.3-.408,10.1,10.1,0,0,1-1.456-.7,9.124,9.124,0,0,1-1.376-.984,4.91,4.91,0,0,1-1.048-1.248A6.508,6.508,0,0,1,5.528-8.76a11.172,11.172,0,0,1-3.16,1.416q-.256.064-.32-.08l-.272-.56a.281.281,0,0,1-.008-.176A.176.176,0,0,1,1.9-8.288a10.48,10.48,0,0,0,3.608-1.728,3.816,3.816,0,0,0,1.464-2.448q.032-.224.208-.192l.72.08Q8.112-12.576,8.08-12.352Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_(ㅗ)_c_3.svg': { 
		path: <path d="M8.08-12.176q-.032.128-.056.264t-.056.264a1.881,1.881,0,0,0,.192.416,4.7,4.7,0,0,0,.784,1.008,7.134,7.134,0,0,0,1.144.92,8.977,8.977,0,0,0,1.424.752,9.616,9.616,0,0,0,1.624.52.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.272.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008,9.5,9.5,0,0,1-1.632-.56,10.01,10.01,0,0,1-1.456-.8,8.936,8.936,0,0,1-1.224-.976,5.755,5.755,0,0,1-.9-1.088,7.281,7.281,0,0,1-2.024,2.1A10.092,10.092,0,0,1,2.336-6.96q-.256.064-.32-.08l-.24-.576a.281.281,0,0,1-.008-.176A.176.176,0,0,1,1.9-7.92a9.492,9.492,0,0,0,1.968-.76,7.983,7.983,0,0,0,1.56-1.056A5.423,5.423,0,0,0,6.5-10.976a3.28,3.28,0,0,0,.48-1.312q.032-.224.208-.192l.72.08A.187.187,0,0,1,8.08-12.176Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_1.svg': { 
		path: <path d="M7.312-4.928q-.16.3-.336.608A10.191,10.191,0,0,1,5.632-2.592,9.873,9.873,0,0,1,3.856-1.136a.431.431,0,0,1-.184.088Q3.6-1.04,3.52-1.136l-.368-.48q-.128-.176.08-.32A8.282,8.282,0,0,0,4.808-3.224,9.1,9.1,0,0,0,6.024-4.8a7.554,7.554,0,0,0,.768-1.688,5.2,5.2,0,0,0,.248-1.64A.261.261,0,0,1,7.1-8.32a.184.184,0,0,1,.128-.064l.736.048q.176,0,.176.224a6.7,6.7,0,0,1-.32,1.92,3.029,3.029,0,0,0,.432.864,8.447,8.447,0,0,0,.952,1.24,8.976,8.976,0,0,0,1.224,1.1,6.768,6.768,0,0,0,1.376.808.121.121,0,0,1,.1.12.336.336,0,0,1-.032.152l-.3.544a.167.167,0,0,1-.136.1.45.45,0,0,1-.184-.024,7.063,7.063,0,0,1-1.24-.728,10.119,10.119,0,0,1-1.184-1,10,10,0,0,1-.984-1.12A6.053,6.053,0,0,1,7.312-4.928Z" />, 
		strokes: [
			<path   d="M812.241,2168.6a8.4,8.4,0,0,1-4.182,6.641" transform="translate(-1)" fill="none" stroke="#000" stroke-width="2"/>,
			<path   d="M811.357,2171.036c1.359,2.213,1.777,2.681,4.467,4.369" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅅ_hd_2.svg': { 
		path: <path d="M8.08-11.52a5.194,5.194,0,0,1-.16.848,2.685,2.685,0,0,0,.08.328,1.355,1.355,0,0,0,.112.264A8.565,8.565,0,0,0,9.08-8.592a9.717,9.717,0,0,0,1.24,1.28,8.738,8.738,0,0,0,1.432,1,7.156,7.156,0,0,0,1.56.648.192.192,0,0,1,.128.128.25.25,0,0,1,0,.176l-.256.528a.517.517,0,0,1-.1.128q-.056.048-.216-.016a9.823,9.823,0,0,1-1.688-.768,10.36,10.36,0,0,1-1.5-1.048A9.945,9.945,0,0,1,8.4-7.816a9.432,9.432,0,0,1-.992-1.448A8.716,8.716,0,0,1,6.5-7.776,9.93,9.93,0,0,1,5.28-6.44,10.294,10.294,0,0,1,3.824-5.328a8.775,8.775,0,0,1-1.648.816q-.256.1-.32-.064l-.288-.56A.258.258,0,0,1,1.56-5.3a.214.214,0,0,1,.12-.136A8.6,8.6,0,0,0,3.664-6.488,8.958,8.958,0,0,0,5.272-7.944,7.585,7.585,0,0,0,6.408-9.7a6.543,6.543,0,0,0,.568-1.936.186.186,0,0,1,.208-.192l.72.08A.187.187,0,0,1,8.08-11.52Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_hd_c_3.svg': { 
		path: <path d="M8.08-12.176a.817.817,0,0,0-.04.184.6.6,0,0,1-.04.168,2.08,2.08,0,0,0,.136.4,2.238,2.238,0,0,0,.2.352,4.819,4.819,0,0,0,.864,1,7.45,7.45,0,0,0,1.16.848,8.683,8.683,0,0,0,1.344.648,8.9,8.9,0,0,0,1.432.416.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.272.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008,9.482,9.482,0,0,1-1.608-.536,9.581,9.581,0,0,1-1.48-.8,8.521,8.521,0,0,1-1.232-.984,5.376,5.376,0,0,1-.864-1.056A5.967,5.967,0,0,1,5.6-8.656a13.5,13.5,0,0,1-3.232,1.5q-.256.08-.32-.08l-.272-.576a.281.281,0,0,1-.008-.176A.176.176,0,0,1,1.9-8.112,15.224,15.224,0,0,0,3.96-8.88a7.615,7.615,0,0,0,1.512-.912,4.256,4.256,0,0,0,1-1.112,3.84,3.84,0,0,0,.5-1.384q.048-.208.208-.192l.72.08a.213.213,0,0,1,.144.056A.151.151,0,0,1,8.08-12.176Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_hu_(ㄹ)_3.svg': { 
		path: <path d="M8.08-12.352l-.032.192A1.264,1.264,0,0,1,8-11.968a3.006,3.006,0,0,0,.352.72,5.287,5.287,0,0,0,1.816,1.656,10.437,10.437,0,0,0,2.968,1.1.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.256.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008A10.282,10.282,0,0,1,9.5-8.824a6.419,6.419,0,0,1-2.032-1.944A6.294,6.294,0,0,1,5.5-8.856,11.51,11.51,0,0,1,2.384-7.5q-.256.064-.32-.08l-.288-.56a.281.281,0,0,1-.008-.176A.176.176,0,0,1,1.9-8.448a11.5,11.5,0,0,0,2.064-.744,8.13,8.13,0,0,0,1.56-.96,4.467,4.467,0,0,0,1.016-1.112,2.824,2.824,0,0,0,.432-1.2q.032-.224.208-.192l.72.08Q8.112-12.576,8.08-12.352Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_hu_(ㅊ)_3.svg': { 
		path: <path d="M8.048-12.352q-.032.208-.08.4a.631.631,0,0,0,.08.224,5.127,5.127,0,0,0,.272.48,5.116,5.116,0,0,0,1.808,1.656,10.565,10.565,0,0,0,2.96,1.1.122.122,0,0,1,.112.1.3.3,0,0,1-.016.168l-.256.544a.156.156,0,0,1-.072.112.459.459,0,0,1-.232.016,10.424,10.424,0,0,1-3.536-1.52,6.029,6.029,0,0,1-1.648-1.7A6.42,6.42,0,0,1,6.32-9.456,8.293,8.293,0,0,1,4.584-8.344a12.708,12.708,0,0,1-2.232.824q-.224.08-.32-.064l-.256-.56a.177.177,0,0,1-.032-.176.2.2,0,0,1,.144-.128,11.267,11.267,0,0,0,2.056-.744,9.34,9.34,0,0,0,1.576-.952,4.883,4.883,0,0,0,.992-1.112,2.782,2.782,0,0,0,.432-1.208q.048-.224.224-.176l.7.064Q8.08-12.576,8.048-12.352Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_hu_(ㅎ)_3.svg': { 
		path: <path d="M8.048-12.352q-.032.208-.08.4a.631.631,0,0,0,.08.224,5.127,5.127,0,0,0,.272.48,5.116,5.116,0,0,0,1.808,1.656,10.565,10.565,0,0,0,2.96,1.1.122.122,0,0,1,.112.1.3.3,0,0,1-.016.168l-.256.544a.156.156,0,0,1-.072.112.459.459,0,0,1-.232.016,10.424,10.424,0,0,1-3.536-1.52,6.029,6.029,0,0,1-1.648-1.7A6.42,6.42,0,0,1,6.32-9.456,8.293,8.293,0,0,1,4.584-8.344a12.708,12.708,0,0,1-2.232.824q-.224.08-.32-.064l-.256-.56a.177.177,0,0,1-.032-.176.2.2,0,0,1,.144-.128,11.267,11.267,0,0,0,2.056-.744,9.34,9.34,0,0,0,1.576-.952,4.883,4.883,0,0,0,.992-1.112,2.782,2.782,0,0,0,.432-1.208q.048-.224.224-.176l.7.064Q8.08-12.576,8.048-12.352Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_hu_2.svg': { 
		path: <path d="M8.08-11.52a5.194,5.194,0,0,1-.16.848,2.685,2.685,0,0,0,.08.328,1.355,1.355,0,0,0,.112.264A8.565,8.565,0,0,0,9.08-8.592a9.717,9.717,0,0,0,1.24,1.28,8.738,8.738,0,0,0,1.432,1,7.156,7.156,0,0,0,1.56.648.192.192,0,0,1,.128.128.25.25,0,0,1,0,.176l-.256.528a.517.517,0,0,1-.1.128q-.056.048-.216-.016a9.823,9.823,0,0,1-1.688-.768,10.36,10.36,0,0,1-1.5-1.048A9.945,9.945,0,0,1,8.4-7.816a9.432,9.432,0,0,1-.992-1.448A8.716,8.716,0,0,1,6.5-7.776,9.93,9.93,0,0,1,5.28-6.44,10.294,10.294,0,0,1,3.824-5.328a8.775,8.775,0,0,1-1.648.816q-.256.1-.32-.064l-.288-.56A.258.258,0,0,1,1.56-5.3a.214.214,0,0,1,.12-.136A8.6,8.6,0,0,0,3.664-6.488,8.958,8.958,0,0,0,5.272-7.944,7.585,7.585,0,0,0,6.408-9.7a6.543,6.543,0,0,0,.568-1.936.186.186,0,0,1,.208-.192l.72.08A.187.187,0,0,1,8.08-11.52Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_hu_c_3.svg': { 
		path: <path d="M8.08-12.176q-.032.128-.056.264t-.056.264a1.881,1.881,0,0,0,.192.416,4.7,4.7,0,0,0,.784,1.008,7.134,7.134,0,0,0,1.144.92,8.977,8.977,0,0,0,1.424.752,9.616,9.616,0,0,0,1.624.52.149.149,0,0,1,.112.112.277.277,0,0,1-.016.16l-.272.544a.262.262,0,0,1-.088.12.3.3,0,0,1-.216.008,9.5,9.5,0,0,1-1.632-.56,10.01,10.01,0,0,1-1.456-.8,8.936,8.936,0,0,1-1.224-.976,5.755,5.755,0,0,1-.9-1.088,7.281,7.281,0,0,1-2.024,2.1A10.092,10.092,0,0,1,2.336-6.96q-.256.064-.32-.08l-.24-.576a.281.281,0,0,1-.008-.176A.176.176,0,0,1,1.9-7.92a9.492,9.492,0,0,0,1.968-.76,7.983,7.983,0,0,0,1.56-1.056A5.423,5.423,0,0,0,6.5-10.976a3.28,3.28,0,0,0,.48-1.312q.032-.224.208-.192l.72.08A.187.187,0,0,1,8.08-12.176Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_v_(ㄹ)_3.svg': { 
		path: <path d="M5.84-12.016a8.029,8.029,0,0,1-.288,2.08A5.163,5.163,0,0,0,6.128-8.88a7.013,7.013,0,0,0,.84.992,7.4,7.4,0,0,0,1.024.84,6.326,6.326,0,0,0,1.128.616.121.121,0,0,1,.1.12.336.336,0,0,1-.032.152l-.3.544a.167.167,0,0,1-.136.1.45.45,0,0,1-.184-.024A6.543,6.543,0,0,1,7.536-6.1a8.9,8.9,0,0,1-.984-.768,9.573,9.573,0,0,1-.864-.88,5.847,5.847,0,0,1-.648-.9A7.839,7.839,0,0,1,3.7-6.856,11.52,11.52,0,0,1,1.68-5.248.431.431,0,0,1,1.5-5.16q-.072.008-.152-.088l-.4-.5q-.128-.176.08-.32a11.007,11.007,0,0,0,1.7-1.288A6.66,6.66,0,0,0,3.864-8.744a5.684,5.684,0,0,0,.648-1.544,7.041,7.041,0,0,0,.208-1.744.261.261,0,0,1,.064-.192.184.184,0,0,1,.128-.064l.752.048Q5.84-12.24,5.84-12.016Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_v_(ㅊ)_3.svg': { 
		path: <path d="M8.848-5.632a.167.167,0,0,1-.136.1.45.45,0,0,1-.184-.024,5.556,5.556,0,0,1-1.1-.616,11.5,11.5,0,0,1-1.064-.856,10.961,10.961,0,0,1-.912-.96,6.128,6.128,0,0,1-.448-.672q-.112.224-.24.432A7.615,7.615,0,0,1,3.48-6.664a12.8,12.8,0,0,1-1.816,1.4.554.554,0,0,1-.184.1q-.072.016-.152-.1l-.4-.5q-.128-.176.08-.32A10.06,10.06,0,0,0,2.7-7.36,6.957,6.957,0,0,0,3.856-8.768a5.473,5.473,0,0,0,.64-1.52A7.041,7.041,0,0,0,4.7-12.032a.261.261,0,0,1,.064-.192.176.176,0,0,1,.112-.064l.752.064q.176,0,.176.208a8.265,8.265,0,0,1-.272,2.048L5.52-9.9a4.056,4.056,0,0,0,.384.736A6.909,6.909,0,0,0,6.768-8.08a9.965,9.965,0,0,0,1.1.952,5.609,5.609,0,0,0,1.216.7.1.1,0,0,1,.1.112.262.262,0,0,1-.032.144Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_v_(ㅌ)_3.svg': { 
		path: <path d="M8.848-5.632a.167.167,0,0,1-.136.1.45.45,0,0,1-.184-.024,5.556,5.556,0,0,1-1.1-.616,11.5,11.5,0,0,1-1.064-.856,10.961,10.961,0,0,1-.912-.96,6.128,6.128,0,0,1-.448-.672q-.112.224-.24.432A7.615,7.615,0,0,1,3.48-6.664a12.8,12.8,0,0,1-1.816,1.4.554.554,0,0,1-.184.1q-.072.016-.152-.1l-.4-.5q-.128-.176.08-.32A10.06,10.06,0,0,0,2.7-7.36,6.957,6.957,0,0,0,3.856-8.768a5.473,5.473,0,0,0,.64-1.52A7.041,7.041,0,0,0,4.7-12.032a.261.261,0,0,1,.064-.192.176.176,0,0,1,.112-.064l.752.064q.176,0,.176.208a8.265,8.265,0,0,1-.272,2.048L5.52-9.9a4.056,4.056,0,0,0,.384.736A6.909,6.909,0,0,0,6.768-8.08a9.965,9.965,0,0,0,1.1.952,5.609,5.609,0,0,0,1.216.7.1.1,0,0,1,.1.112.262.262,0,0,1-.032.144Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_v_(ㅎ)_3.svg': { 
		path: <path d="M8.848-5.632a.167.167,0,0,1-.136.1.45.45,0,0,1-.184-.024,5.556,5.556,0,0,1-1.1-.616,11.5,11.5,0,0,1-1.064-.856,10.961,10.961,0,0,1-.912-.96,6.128,6.128,0,0,1-.448-.672q-.112.224-.24.432A7.615,7.615,0,0,1,3.48-6.664a12.8,12.8,0,0,1-1.816,1.4.554.554,0,0,1-.184.1q-.072.016-.152-.1l-.4-.5q-.128-.176.08-.32A10.06,10.06,0,0,0,2.7-7.36,6.957,6.957,0,0,0,3.856-8.768a5.473,5.473,0,0,0,.64-1.52A7.041,7.041,0,0,0,4.7-12.032a.261.261,0,0,1,.064-.192.176.176,0,0,1,.112-.064l.752.064q.176,0,.176.208a8.265,8.265,0,0,1-.272,2.048L5.52-9.9a4.056,4.056,0,0,0,.384.736A6.909,6.909,0,0,0,6.768-8.08a9.965,9.965,0,0,0,1.1.952,5.609,5.609,0,0,0,1.216.7.1.1,0,0,1,.1.112.262.262,0,0,1-.032.144Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_v_2.svg': { 
		path: <path d="M6.016-11.472q0,1.056-.088,2.024a15.657,15.657,0,0,1-.28,1.864,7.387,7.387,0,0,0,.5,1.352,12.126,12.126,0,0,0,.792,1.416A12.861,12.861,0,0,0,7.96-3.448,9.788,9.788,0,0,0,9.168-2.256a.222.222,0,0,1,.088.16.226.226,0,0,1-.04.16l-.4.5q-.144.176-.336,0a9.341,9.341,0,0,1-.92-.92Q7.088-2.9,6.648-3.488T5.824-4.7a12.422,12.422,0,0,1-.656-1.2,11.57,11.57,0,0,1-1.344,2.68A13.029,13.029,0,0,1,1.84-.944q-.16.128-.272.016l-.48-.5a.15.15,0,0,1-.04-.128.27.27,0,0,1,.088-.16,13.077,13.077,0,0,0,1.7-1.952A10.19,10.19,0,0,0,4.008-5.872,12.473,12.473,0,0,0,4.68-8.448,20,20,0,0,0,4.9-11.5a.228.228,0,0,1,.056-.168.185.185,0,0,1,.136-.056l.752.032Q6.016-11.7,6.016-11.472Z" />, 
		strokes: [
		] 
	},
	'_ㅅ_v_c_3.svg': { 
		path: <path d="M5.888-11.776a10.066,10.066,0,0,1-.072,1.216,11.277,11.277,0,0,1-.2,1.152,6.369,6.369,0,0,0,.552,1.3,7.909,7.909,0,0,0,.848,1.232A7.959,7.959,0,0,0,8.08-5.816a5.768,5.768,0,0,0,1.184.76q.1.048.088.128a.587.587,0,0,1-.04.16l-.32.528a.281.281,0,0,1-.112.112q-.064.032-.208-.048a4.875,4.875,0,0,1-.968-.64,12.143,12.143,0,0,1-.992-.92,10.222,10.222,0,0,1-.9-1.072A8.313,8.313,0,0,1,5.12-7.9a9.245,9.245,0,0,1-1.432,2.36A9.386,9.386,0,0,1,1.6-3.7a.5.5,0,0,1-.176.064.16.16,0,0,1-.16-.064l-.4-.528a.166.166,0,0,1-.016-.16A.255.255,0,0,1,.96-4.528,7.187,7.187,0,0,0,2.536-5.84,8.547,8.547,0,0,0,3.752-7.568,8.628,8.628,0,0,0,4.52-9.592a8.7,8.7,0,0,0,.248-2.2q0-.256.192-.256L5.712-12Q5.888-12,5.888-11.776Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅓ)_2.svg': { 
		path: <path d="M8.832-6.416a7.824,7.824,0,0,1-.216,1.808,5.4,5.4,0,0,1-.68,1.648A3.9,3.9,0,0,1,6.744-1.752a3.151,3.151,0,0,1-1.752.472,3.253,3.253,0,0,1-1.784-.464A3.565,3.565,0,0,1,2.04-2.952a5.422,5.422,0,0,1-.632-1.656,8.757,8.757,0,0,1-.192-1.808A9.1,9.1,0,0,1,1.408-8.28,5.318,5.318,0,0,1,2.04-9.936,3.447,3.447,0,0,1,3.208-11.12a3.343,3.343,0,0,1,1.784-.448,3.279,3.279,0,0,1,1.752.448A3.692,3.692,0,0,1,7.936-9.944,5.289,5.289,0,0,1,8.616-8.3,8.2,8.2,0,0,1,8.832-6.416ZM7.7-6.432a7.184,7.184,0,0,0-.152-1.456,4.831,4.831,0,0,0-.472-1.328,2.8,2.8,0,0,0-.832-.96,2.082,2.082,0,0,0-1.248-.368,2.1,2.1,0,0,0-1.272.368,2.685,2.685,0,0,0-.808.96A4.624,4.624,0,0,0,2.48-7.888a8.485,8.485,0,0,0-.128,1.456,8.848,8.848,0,0,0,.12,1.424A4.561,4.561,0,0,0,2.9-3.672a2.779,2.779,0,0,0,.816.984,2.073,2.073,0,0,0,1.28.384A2.021,2.021,0,0,0,6.24-2.688a2.937,2.937,0,0,0,.832-.984,4.95,4.95,0,0,0,.472-1.336A7.036,7.036,0,0,0,7.7-6.432Z" transform="translate(-441.9 -31)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅓ)_c_3.svg': { 
		path: <path d="M8.88-8.528a3.345,3.345,0,0,1-.3,1.424A3.635,3.635,0,0,1,7.76-5.968a3.731,3.731,0,0,1-1.208.752,4.029,4.029,0,0,1-1.48.272,3.965,3.965,0,0,1-1.448-.264,3.732,3.732,0,0,1-1.192-.736,3.44,3.44,0,0,1-.808-1.128,3.5,3.5,0,0,1-.3-1.456,3.678,3.678,0,0,1,.3-1.512,3.332,3.332,0,0,1,.808-1.128,3.583,3.583,0,0,1,1.192-.712,4.193,4.193,0,0,1,1.448-.248,4.123,4.123,0,0,1,1.408.248,3.843,3.843,0,0,1,1.224.712A3.458,3.458,0,0,1,8.56-10.04,3.44,3.44,0,0,1,8.88-8.528ZM7.76-8.544a2.583,2.583,0,0,0-.224-1.1,2.408,2.408,0,0,0-.6-.808,2.592,2.592,0,0,0-.856-.5,3.029,3.029,0,0,0-1.008-.168,3.029,3.029,0,0,0-1.008.168,2.485,2.485,0,0,0-.84.488,2.234,2.234,0,0,0-.568.808,2.813,2.813,0,0,0-.208,1.112,2.762,2.762,0,0,0,.2,1.072,2.46,2.46,0,0,0,.552.824,2.409,2.409,0,0,0,.832.528,2.881,2.881,0,0,0,1.04.184A2.792,2.792,0,0,0,6.08-6.12a2.75,2.75,0,0,0,.856-.52,2.494,2.494,0,0,0,.6-.816A2.543,2.543,0,0,0,7.76-8.544Z" transform="translate(746.12 1996)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M8.832-8.672a3.222,3.222,0,0,1-.3,1.384,3.423,3.423,0,0,1-.808,1.1,3.787,3.787,0,0,1-1.2.728A4.073,4.073,0,0,1,5.056-5.2a3.947,3.947,0,0,1-1.432-.256,3.714,3.714,0,0,1-1.168-.712,3.267,3.267,0,0,1-.792-1.1,3.392,3.392,0,0,1-.288-1.408,3.568,3.568,0,0,1,.288-1.464,3.165,3.165,0,0,1,.792-1.1,3.56,3.56,0,0,1,1.168-.688,4.182,4.182,0,0,1,1.432-.24,4.177,4.177,0,0,1,1.4.24,3.807,3.807,0,0,1,1.208.688,3.382,3.382,0,0,1,.848,1.1A3.262,3.262,0,0,1,8.832-8.672Zm-1.12-.016a2.4,2.4,0,0,0-.224-1.056,2.246,2.246,0,0,0-.592-.768,2.616,2.616,0,0,0-.848-.464,3.116,3.116,0,0,0-.992-.16,3.243,3.243,0,0,0-1,.152,2.263,2.263,0,0,0-.816.456A2.134,2.134,0,0,0,2.7-9.76a2.688,2.688,0,0,0-.2,1.072,2.637,2.637,0,0,0,.192,1.032,2.305,2.305,0,0,0,.536.784,2.282,2.282,0,0,0,.808.5,2.989,2.989,0,0,0,1.024.168,2.982,2.982,0,0,0,.992-.168A2.574,2.574,0,0,0,6.9-6.864a2.417,2.417,0,0,0,.592-.784A2.361,2.361,0,0,0,7.712-8.688Z" transform="translate(744.024 1888)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅕ)_(ㅂ)_3.svg': { 
		path: <path d="M8.832-8.672a3.222,3.222,0,0,1-.3,1.384,3.423,3.423,0,0,1-.808,1.1,3.787,3.787,0,0,1-1.2.728A4.073,4.073,0,0,1,5.056-5.2a3.947,3.947,0,0,1-1.432-.256,3.714,3.714,0,0,1-1.168-.712,3.267,3.267,0,0,1-.792-1.1,3.392,3.392,0,0,1-.288-1.408,3.568,3.568,0,0,1,.288-1.464,3.165,3.165,0,0,1,.792-1.1,3.56,3.56,0,0,1,1.168-.688,4.182,4.182,0,0,1,1.432-.24,4.177,4.177,0,0,1,1.4.24,3.807,3.807,0,0,1,1.208.688,3.382,3.382,0,0,1,.848,1.1A3.262,3.262,0,0,1,8.832-8.672Zm-1.12-.016a2.4,2.4,0,0,0-.224-1.056,2.246,2.246,0,0,0-.592-.768,2.616,2.616,0,0,0-.848-.464,3.116,3.116,0,0,0-.992-.16,3.243,3.243,0,0,0-1,.152,2.263,2.263,0,0,0-.816.456A2.134,2.134,0,0,0,2.7-9.76a2.688,2.688,0,0,0-.2,1.072,2.637,2.637,0,0,0,.192,1.032,2.305,2.305,0,0,0,.536.784,2.282,2.282,0,0,0,.808.5,2.989,2.989,0,0,0,1.024.168,2.982,2.982,0,0,0,.992-.168A2.574,2.574,0,0,0,6.9-6.864a2.417,2.417,0,0,0,.592-.784A2.361,2.361,0,0,0,7.712-8.688Z" transform="translate(744.024 1888)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M8.832-8.672a3.222,3.222,0,0,1-.3,1.384,3.423,3.423,0,0,1-.808,1.1,3.787,3.787,0,0,1-1.2.728A4.073,4.073,0,0,1,5.056-5.2a3.947,3.947,0,0,1-1.432-.256,3.714,3.714,0,0,1-1.168-.712,3.267,3.267,0,0,1-.792-1.1,3.392,3.392,0,0,1-.288-1.408,3.568,3.568,0,0,1,.288-1.464,3.165,3.165,0,0,1,.792-1.1,3.56,3.56,0,0,1,1.168-.688,4.182,4.182,0,0,1,1.432-.24,4.177,4.177,0,0,1,1.4.24,3.807,3.807,0,0,1,1.208.688,3.382,3.382,0,0,1,.848,1.1A3.262,3.262,0,0,1,8.832-8.672Zm-1.12-.016a2.4,2.4,0,0,0-.224-1.056,2.246,2.246,0,0,0-.592-.768,2.616,2.616,0,0,0-.848-.464,3.116,3.116,0,0,0-.992-.16,3.243,3.243,0,0,0-1,.152,2.263,2.263,0,0,0-.816.456A2.134,2.134,0,0,0,2.7-9.76a2.688,2.688,0,0,0-.2,1.072,2.637,2.637,0,0,0,.192,1.032,2.305,2.305,0,0,0,.536.784,2.282,2.282,0,0,0,.808.5,2.989,2.989,0,0,0,1.024.168,2.982,2.982,0,0,0,.992-.168A2.574,2.574,0,0,0,6.9-6.864a2.417,2.417,0,0,0,.592-.784A2.361,2.361,0,0,0,7.712-8.688Z" transform="translate(744.024 1888)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅕ)_2.svg': { 
		path: <path d="M8.832-6.416a7.824,7.824,0,0,1-.216,1.808,5.4,5.4,0,0,1-.68,1.648A3.9,3.9,0,0,1,6.744-1.752a3.151,3.151,0,0,1-1.752.472,3.253,3.253,0,0,1-1.784-.464A3.565,3.565,0,0,1,2.04-2.952a5.422,5.422,0,0,1-.632-1.656,8.757,8.757,0,0,1-.192-1.808A9.1,9.1,0,0,1,1.408-8.28,5.318,5.318,0,0,1,2.04-9.936,3.447,3.447,0,0,1,3.208-11.12a3.343,3.343,0,0,1,1.784-.448,3.279,3.279,0,0,1,1.752.448A3.692,3.692,0,0,1,7.936-9.944,5.289,5.289,0,0,1,8.616-8.3,8.2,8.2,0,0,1,8.832-6.416ZM7.7-6.432a7.184,7.184,0,0,0-.152-1.456,4.831,4.831,0,0,0-.472-1.328,2.8,2.8,0,0,0-.832-.96,2.082,2.082,0,0,0-1.248-.368,2.1,2.1,0,0,0-1.272.368,2.685,2.685,0,0,0-.808.96A4.624,4.624,0,0,0,2.48-7.888a8.485,8.485,0,0,0-.128,1.456,8.848,8.848,0,0,0,.12,1.424A4.561,4.561,0,0,0,2.9-3.672a2.779,2.779,0,0,0,.816.984,2.073,2.073,0,0,0,1.28.384A2.021,2.021,0,0,0,6.24-2.688a2.937,2.937,0,0,0,.832-.984,4.95,4.95,0,0,0,.472-1.336A7.036,7.036,0,0,0,7.7-6.432Z" transform="translate(-452.9 -95)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M6.96-7.28a11.676,11.676,0,0,1-1.5-.168A5.3,5.3,0,0,1,4.048-7.9,3.035,3.035,0,0,1,3-8.712a1.956,1.956,0,0,1-.408-1.272,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.956,1.956,0,0,1-.408,1.272,2.972,2.972,0,0,1-1.04.808,5.377,5.377,0,0,1-1.4.44,11.484,11.484,0,0,1-1.5.168S7.263-7.254,6.96-7.28ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M6.96-7.28a11.676,11.676,0,0,1-1.5-.168A5.3,5.3,0,0,1,4.048-7.9,3.035,3.035,0,0,1,3-8.712a1.956,1.956,0,0,1-.408-1.272,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.956,1.956,0,0,1-.408,1.272,2.972,2.972,0,0,1-1.04.808,5.377,5.377,0,0,1-1.4.44,11.484,11.484,0,0,1-1.5.168S7.263-7.254,6.96-7.28ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M6.96-7.28a11.676,11.676,0,0,1-1.5-.168A5.3,5.3,0,0,1,4.048-7.9,3.035,3.035,0,0,1,3-8.712a1.956,1.956,0,0,1-.408-1.272,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.956,1.956,0,0,1-.408,1.272,2.972,2.972,0,0,1-1.04.808,5.377,5.377,0,0,1-1.4.44,11.484,11.484,0,0,1-1.5.168S7.263-7.254,6.96-7.28ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M6.96-7.28a11.676,11.676,0,0,1-1.5-.168A5.3,5.3,0,0,1,4.048-7.9,3.035,3.035,0,0,1,3-8.712a1.956,1.956,0,0,1-.408-1.272,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.956,1.956,0,0,1-.408,1.272,2.972,2.972,0,0,1-1.04.808,5.377,5.377,0,0,1-1.4.44,11.484,11.484,0,0,1-1.5.168S7.263-7.254,6.96-7.28ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅗ)_2.svg': { 
		path: <path d="M7.488-4.352A7.8,7.8,0,0,1,5.736-4.56,5.857,5.857,0,0,1,4.08-5.208a3.889,3.889,0,0,1-1.232-1.12,2.771,2.771,0,0,1-.48-1.64,2.771,2.771,0,0,1,.48-1.64,3.889,3.889,0,0,1,1.232-1.12,5.689,5.689,0,0,1,1.656-.64,8.1,8.1,0,0,1,1.752-.2,8.171,8.171,0,0,1,1.768.2,5.582,5.582,0,0,1,1.648.64,3.918,3.918,0,0,1,1.224,1.12,2.771,2.771,0,0,1,.48,1.64,2.771,2.771,0,0,1-.48,1.64A3.918,3.918,0,0,1,10.9-5.208a5.745,5.745,0,0,1-1.648.648A7.871,7.871,0,0,1,7.488-4.352Zm0-6.192a7.4,7.4,0,0,0-1.264.12,4.62,4.62,0,0,0-1.28.416,2.91,2.91,0,0,0-.992.8,1.957,1.957,0,0,0-.4,1.256,1.924,1.924,0,0,0,.4,1.248,2.952,2.952,0,0,0,.992.792,4.62,4.62,0,0,0,1.28.416,7.4,7.4,0,0,0,1.264.12,7.187,7.187,0,0,0,1.24-.12,4.738,4.738,0,0,0,1.28-.416A3.012,3.012,0,0,0,11.016-6.7a1.9,1.9,0,0,0,.408-1.248,1.932,1.932,0,0,0-.408-1.256,2.968,2.968,0,0,0-1.008-.8,4.738,4.738,0,0,0-1.28-.416A7.187,7.187,0,0,0,7.488-10.544Z" transform="translate(-439 -67.2)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅗ)_c_3.svg': { 
		path: <path d="M6.944-6.992a10.317,10.317,0,0,1-1.5-.184,5.517,5.517,0,0,1-1.424-.472A3.256,3.256,0,0,1,2.96-8.464,1.852,1.852,0,0,1,2.544-9.7a1.826,1.826,0,0,1,.5-1.312A3.558,3.558,0,0,1,4.3-11.84a6.911,6.911,0,0,1,1.616-.432A11.13,11.13,0,0,1,7.52-12.4a10.005,10.005,0,0,1,1.624.144,6.934,6.934,0,0,1,1.616.464,3.716,3.716,0,0,1,1.24.84A1.754,1.754,0,0,1,12.5-9.7a1.762,1.762,0,0,1-.424,1.184A3.324,3.324,0,0,1,11-7.7a6.115,6.115,0,0,1-1.432.488,10.527,10.527,0,0,1-1.52.216Zm.576-4.4q-.512,0-1.168.064a5.745,5.745,0,0,0-1.24.256,3.031,3.031,0,0,0-.992.528,1.069,1.069,0,0,0-.408.864,1.069,1.069,0,0,0,.408.864,3.031,3.031,0,0,0,.992.528,5.745,5.745,0,0,0,1.24.256q.656.064,1.168.064.5,0,1.152-.064A5.886,5.886,0,0,0,9.92-8.288a3.02,3.02,0,0,0,1-.528,1.069,1.069,0,0,0,.408-.864,1.069,1.069,0,0,0-.408-.864,3.02,3.02,0,0,0-1-.528,5.886,5.886,0,0,0-1.248-.256Q8.016-11.392,7.52-11.392Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M4.352-7.776A3.327,3.327,0,0,1,3.1-8.6a1.939,1.939,0,0,1-.5-1.384,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.95,1.95,0,0,1-.5,1.392,3.3,3.3,0,0,1-1.248.816,9.823,9.823,0,0,1-1.1.316,5.806,5.806,0,0,1-1.056.14A7.632,7.632,0,0,1,7.5-7.28,7.606,7.606,0,0,1,6.5-7.32a5.641,5.641,0,0,1-1.04-.14A10.129,10.129,0,0,1,4.352-7.776ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M4.352-7.776A3.327,3.327,0,0,1,3.1-8.6a1.939,1.939,0,0,1-.5-1.384,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.95,1.95,0,0,1-.5,1.392,3.3,3.3,0,0,1-1.248.816,9.823,9.823,0,0,1-1.1.316,5.806,5.806,0,0,1-1.056.14A7.632,7.632,0,0,1,7.5-7.28,7.606,7.606,0,0,1,6.5-7.32a5.641,5.641,0,0,1-1.04-.14A10.129,10.129,0,0,1,4.352-7.776ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M4.352-7.776A3.327,3.327,0,0,1,3.1-8.6a1.939,1.939,0,0,1-.5-1.384,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.95,1.95,0,0,1-.5,1.392,3.3,3.3,0,0,1-1.248.816,9.823,9.823,0,0,1-1.1.316,5.806,5.806,0,0,1-1.056.14A7.632,7.632,0,0,1,7.5-7.28,7.606,7.606,0,0,1,6.5-7.32a5.641,5.641,0,0,1-1.04-.14A10.129,10.129,0,0,1,4.352-7.776ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M4.352-7.776A3.327,3.327,0,0,1,3.1-8.6a1.939,1.939,0,0,1-.5-1.384,1.9,1.9,0,0,1,.488-1.352A3.378,3.378,0,0,1,4.3-12.152,6.515,6.515,0,0,1,5.9-12.56,12.43,12.43,0,0,1,7.5-12.672a13.49,13.49,0,0,1,1.624.1,6.277,6.277,0,0,1,1.592.4,3.253,3.253,0,0,1,1.216.824,1.944,1.944,0,0,1,.48,1.36,1.95,1.95,0,0,1-.5,1.392,3.3,3.3,0,0,1-1.248.816,9.823,9.823,0,0,1-1.1.316,5.806,5.806,0,0,1-1.056.14A7.632,7.632,0,0,1,7.5-7.28,7.606,7.606,0,0,1,6.5-7.32a5.641,5.641,0,0,1-1.04-.14A10.129,10.129,0,0,1,4.352-7.776ZM7.5-11.712q-.512,0-1.168.064A5.932,5.932,0,0,0,5.1-11.4a2.767,2.767,0,0,0-.976.528,1.134,1.134,0,0,0-.4.9,1.134,1.134,0,0,0,.4.9,2.767,2.767,0,0,0,.976.528,5.6,5.6,0,0,0,1.232.24q.656.056,1.168.056.5,0,1.152-.056a5.739,5.739,0,0,0,1.24-.24,2.759,2.759,0,0,0,.984-.528,1.134,1.134,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9A2.759,2.759,0,0,0,9.9-11.4a6.081,6.081,0,0,0-1.24-.248Q8-11.712,7.5-11.712Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅛ)_c_3.svg': { 
		path: <path d="M7.5-11.392q-.512,0-1.168.064a5.06,5.06,0,0,0-1.232.272,2.813,2.813,0,0,0-1,.512A1.069,1.069,0,0,0,3.7-9.68a1.069,1.069,0,0,0,.408.864,3.02,3.02,0,0,0,1,.528,5.312,5.312,0,0,0,1.232.248q.656.056,1.168.056.48,0,1.144-.056A5.578,5.578,0,0,0,9.9-8.288a3.143,3.143,0,0,0,.992-.528,1.061,1.061,0,0,0,.416-.864,1.061,1.061,0,0,0-.416-.864,2.915,2.915,0,0,0-.992-.512,5.312,5.312,0,0,0-1.256-.272Q7.984-11.392,7.5-11.392Zm0,4.416A11.13,11.13,0,0,1,5.9-7.1a6.774,6.774,0,0,1-1.608-.432,3.788,3.788,0,0,1-1.256-.84,1.819,1.819,0,0,1-.5-1.32,1.8,1.8,0,0,1,.5-1.3,3.788,3.788,0,0,1,1.256-.84A6.774,6.774,0,0,1,5.9-12.272,11.13,11.13,0,0,1,7.5-12.4a10.005,10.005,0,0,1,1.624.144,6.794,6.794,0,0,1,1.608.464,3.827,3.827,0,0,1,1.24.84,1.739,1.739,0,0,1,.5,1.256,1.731,1.731,0,0,1-.5,1.264,3.9,3.9,0,0,1-1.24.832,7.059,7.059,0,0,1-1.608.472A9.493,9.493,0,0,1,7.5-6.976Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅜ)_(ㅊ)_3.svg': { 
		path: <path d="M7.472-11.68q-.528,0-1.176.064a6.3,6.3,0,0,0-1.224.24,3.222,3.222,0,0,0-.968.568,1.145,1.145,0,0,0-.392.9,1.121,1.121,0,0,0,.392.9,3.324,3.324,0,0,0,.968.56,6.3,6.3,0,0,0,1.224.24q.648.064,1.176.064.512,0,1.168-.064a6.629,6.629,0,0,0,1.248-.24,3.5,3.5,0,0,0,.96-.56,1.111,1.111,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9,3.386,3.386,0,0,0-.96-.568,6.629,6.629,0,0,0-1.248-.24Q7.984-11.68,7.472-11.68Zm0,4.512A12.613,12.613,0,0,1,5.856-7.28a5.676,5.676,0,0,1-1.584-.432,3.472,3.472,0,0,1-1.208-.84A1.957,1.957,0,0,1,2.592-9.92a1.964,1.964,0,0,1,.472-1.36,3.257,3.257,0,0,1,1.208-.832,6.276,6.276,0,0,1,1.584-.424,11.786,11.786,0,0,1,1.616-.12,13.021,13.021,0,0,1,1.656.112,5.835,5.835,0,0,1,1.576.416,3.238,3.238,0,0,1,1.192.84,1.994,1.994,0,0,1,.472,1.368,1.937,1.937,0,0,1-.48,1.368,3.634,3.634,0,0,1-1.184.84,5.891,5.891,0,0,1-1.6.432A12.723,12.723,0,0,1,7.472-7.168Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅜ)_(ㅎ)_3.svg': { 
		path: <path d="M7.472-11.68q-.528,0-1.176.064a6.3,6.3,0,0,0-1.224.24,3.222,3.222,0,0,0-.968.568,1.145,1.145,0,0,0-.392.9,1.121,1.121,0,0,0,.392.9,3.324,3.324,0,0,0,.968.56,6.3,6.3,0,0,0,1.224.24q.648.064,1.176.064.512,0,1.168-.064a6.629,6.629,0,0,0,1.248-.24,3.5,3.5,0,0,0,.96-.56,1.111,1.111,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9,3.386,3.386,0,0,0-.96-.568,6.629,6.629,0,0,0-1.248-.24Q7.984-11.68,7.472-11.68Zm0,4.512A12.613,12.613,0,0,1,5.856-7.28a5.676,5.676,0,0,1-1.584-.432,3.472,3.472,0,0,1-1.208-.84A1.957,1.957,0,0,1,2.592-9.92a1.964,1.964,0,0,1,.472-1.36,3.257,3.257,0,0,1,1.208-.832,6.276,6.276,0,0,1,1.584-.424,11.786,11.786,0,0,1,1.616-.12,13.021,13.021,0,0,1,1.656.112,5.835,5.835,0,0,1,1.576.416,3.238,3.238,0,0,1,1.192.84,1.994,1.994,0,0,1,.472,1.368,1.937,1.937,0,0,1-.48,1.368,3.634,3.634,0,0,1-1.184.84,5.891,5.891,0,0,1-1.6.432A12.723,12.723,0,0,1,7.472-7.168Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅠ)_(ㅊ)_3.svg': { 
		path: <path d="M7.5-7.184A12.613,12.613,0,0,1,5.888-7.3a6.084,6.084,0,0,1-1.6-.424,3.416,3.416,0,0,1-1.216-.84,1.966,1.966,0,0,1-.48-1.376,1.944,1.944,0,0,1,.48-1.36,3.333,3.333,0,0,1,1.216-.832,6.526,6.526,0,0,1,1.6-.424,11.786,11.786,0,0,1,1.616-.12,12.836,12.836,0,0,1,1.648.112,6.173,6.173,0,0,1,1.592.416,3.225,3.225,0,0,1,1.2.84,1.994,1.994,0,0,1,.472,1.368,1.966,1.966,0,0,1-.48,1.376,3.431,3.431,0,0,1-1.208.84A5.97,5.97,0,0,1,9.136-7.3,12.723,12.723,0,0,1,7.5-7.184Zm0-4.512q-.528,0-1.184.064a5.606,5.606,0,0,0-1.232.256,2.71,2.71,0,0,0-.968.544,1.169,1.169,0,0,0-.392.912,1.145,1.145,0,0,0,.392.9,2.78,2.78,0,0,0,.968.536,5.606,5.606,0,0,0,1.232.256q.656.064,1.184.064.512,0,1.168-.064A5.606,5.606,0,0,0,9.9-8.48a2.868,2.868,0,0,0,.976-.536,1.134,1.134,0,0,0,.4-.9,1.158,1.158,0,0,0-.4-.912,2.794,2.794,0,0,0-.976-.544,5.606,5.606,0,0,0-1.232-.256Q8.016-11.7,7.5-11.7Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅠ)_(ㅎ)_3.svg': { 
		path: <path d="M7.472-11.68q-.528,0-1.176.064a6.3,6.3,0,0,0-1.224.24,3.222,3.222,0,0,0-.968.568,1.145,1.145,0,0,0-.392.9,1.121,1.121,0,0,0,.392.9,3.324,3.324,0,0,0,.968.56,6.3,6.3,0,0,0,1.224.24q.648.064,1.176.064.512,0,1.168-.064a6.629,6.629,0,0,0,1.248-.24,3.5,3.5,0,0,0,.96-.56,1.111,1.111,0,0,0,.4-.9,1.134,1.134,0,0,0-.4-.9,3.386,3.386,0,0,0-.96-.568,6.629,6.629,0,0,0-1.248-.24Q7.984-11.68,7.472-11.68Zm0,4.512A12.613,12.613,0,0,1,5.856-7.28a5.676,5.676,0,0,1-1.584-.432,3.472,3.472,0,0,1-1.208-.84A1.957,1.957,0,0,1,2.592-9.92a1.964,1.964,0,0,1,.472-1.36,3.257,3.257,0,0,1,1.208-.832,6.276,6.276,0,0,1,1.584-.424,11.786,11.786,0,0,1,1.616-.12,13.021,13.021,0,0,1,1.656.112,5.835,5.835,0,0,1,1.576.416,3.238,3.238,0,0,1,1.192.84,1.994,1.994,0,0,1,.472,1.368,1.937,1.937,0,0,1-.48,1.368,3.634,3.634,0,0,1-1.184.84,5.891,5.891,0,0,1-1.6.432A12.723,12.723,0,0,1,7.472-7.168Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M7.5-7.184A12.613,12.613,0,0,1,5.888-7.3a6.084,6.084,0,0,1-1.6-.424,3.416,3.416,0,0,1-1.216-.84,1.966,1.966,0,0,1-.48-1.376,1.944,1.944,0,0,1,.48-1.36,3.333,3.333,0,0,1,1.216-.832,6.526,6.526,0,0,1,1.6-.424,11.786,11.786,0,0,1,1.616-.12,12.836,12.836,0,0,1,1.648.112,6.173,6.173,0,0,1,1.592.416,3.225,3.225,0,0,1,1.2.84,1.994,1.994,0,0,1,.472,1.368,1.966,1.966,0,0,1-.48,1.376,3.431,3.431,0,0,1-1.208.84A5.97,5.97,0,0,1,9.136-7.3,12.723,12.723,0,0,1,7.5-7.184Zm0-4.512q-.512,0-1.16.064a5.6,5.6,0,0,0-1.224.256,2.71,2.71,0,0,0-.968.544,1.169,1.169,0,0,0-.392.912,1.145,1.145,0,0,0,.392.9,2.78,2.78,0,0,0,.968.536,5.6,5.6,0,0,0,1.224.256q.648.064,1.16.064t1.16-.064A5.6,5.6,0,0,0,9.888-8.48a2.78,2.78,0,0,0,.968-.536,1.145,1.145,0,0,0,.392-.9,1.158,1.158,0,0,0-.4-.912,2.794,2.794,0,0,0-.976-.544,5.6,5.6,0,0,0-1.224-.256Q8-11.7,7.5-11.7Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M7.5-7.184A12.613,12.613,0,0,1,5.888-7.3a6.084,6.084,0,0,1-1.6-.424,3.416,3.416,0,0,1-1.216-.84,1.966,1.966,0,0,1-.48-1.376,1.944,1.944,0,0,1,.48-1.36,3.333,3.333,0,0,1,1.216-.832,6.526,6.526,0,0,1,1.6-.424,11.786,11.786,0,0,1,1.616-.12,12.836,12.836,0,0,1,1.648.112,6.173,6.173,0,0,1,1.592.416,3.225,3.225,0,0,1,1.2.84,1.994,1.994,0,0,1,.472,1.368,1.966,1.966,0,0,1-.48,1.376,3.431,3.431,0,0,1-1.208.84A5.97,5.97,0,0,1,9.136-7.3,12.723,12.723,0,0,1,7.5-7.184Zm0-4.512q-.512,0-1.16.064a5.6,5.6,0,0,0-1.224.256,2.71,2.71,0,0,0-.968.544,1.169,1.169,0,0,0-.392.912,1.145,1.145,0,0,0,.392.9,2.78,2.78,0,0,0,.968.536,5.6,5.6,0,0,0,1.224.256q.648.064,1.16.064t1.16-.064A5.6,5.6,0,0,0,9.888-8.48a2.78,2.78,0,0,0,.968-.536,1.145,1.145,0,0,0,.392-.9,1.158,1.158,0,0,0-.4-.912,2.794,2.794,0,0,0-.976-.544,5.6,5.6,0,0,0-1.224-.256Q8-11.7,7.5-11.7Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M7.5-7.184A12.613,12.613,0,0,1,5.888-7.3a6.084,6.084,0,0,1-1.6-.424,3.416,3.416,0,0,1-1.216-.84,1.966,1.966,0,0,1-.48-1.376,1.944,1.944,0,0,1,.48-1.36,3.333,3.333,0,0,1,1.216-.832,6.526,6.526,0,0,1,1.6-.424,11.786,11.786,0,0,1,1.616-.12,12.836,12.836,0,0,1,1.648.112,6.173,6.173,0,0,1,1.592.416,3.225,3.225,0,0,1,1.2.84,1.994,1.994,0,0,1,.472,1.368,1.966,1.966,0,0,1-.48,1.376,3.431,3.431,0,0,1-1.208.84A5.97,5.97,0,0,1,9.136-7.3,12.723,12.723,0,0,1,7.5-7.184Zm0-4.512q-.512,0-1.16.064a5.6,5.6,0,0,0-1.224.256,2.71,2.71,0,0,0-.968.544,1.169,1.169,0,0,0-.392.912,1.145,1.145,0,0,0,.392.9,2.78,2.78,0,0,0,.968.536,5.6,5.6,0,0,0,1.224.256q.648.064,1.16.064t1.16-.064A5.6,5.6,0,0,0,9.888-8.48a2.78,2.78,0,0,0,.968-.536,1.145,1.145,0,0,0,.392-.9,1.158,1.158,0,0,0-.4-.912,2.794,2.794,0,0,0-.976-.544,5.6,5.6,0,0,0-1.224-.256Q8-11.7,7.5-11.7Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_1.svg': { 
		path: <path d="M11.52-4.928a3.7,3.7,0,0,1-.3,1.5,3.642,3.642,0,0,1-.848,1.208,3.922,3.922,0,0,1-1.288.8,4.416,4.416,0,0,1-1.608.288A4.246,4.246,0,0,1,5.9-1.424a3.777,3.777,0,0,1-1.256-.8,3.785,3.785,0,0,1-.832-1.2,3.68,3.68,0,0,1-.3-1.5,4.1,4.1,0,0,1,.3-1.624A3.541,3.541,0,0,1,4.64-7.76,3.509,3.509,0,0,1,5.9-8.512a4.719,4.719,0,0,1,1.576-.256A4.515,4.515,0,0,1,9.008-8.5a3.939,3.939,0,0,1,1.3.76,3.608,3.608,0,0,1,.888,1.208A3.8,3.8,0,0,1,11.52-4.928Zm-1.184-.016A2.616,2.616,0,0,0,10.1-6.1a2.532,2.532,0,0,0-.632-.832,2.73,2.73,0,0,0-.912-.512,3.346,3.346,0,0,0-1.08-.176,3.324,3.324,0,0,0-1.088.176,2.655,2.655,0,0,0-.9.512,2.384,2.384,0,0,0-.608.84,2.789,2.789,0,0,0-.224,1.144,2.794,2.794,0,0,0,.216,1.112,2.51,2.51,0,0,0,.592.856,2.639,2.639,0,0,0,.888.544,3.166,3.166,0,0,0,1.12.192,2.983,2.983,0,0,0,1.08-.2A2.842,2.842,0,0,0,9.464-3a2.813,2.813,0,0,0,.632-.856A2.465,2.465,0,0,0,10.336-4.944Z" />, 
		strokes: [
			<path   d="M797.75,2168.88a3.26,3.26,0,1,0,3.3,3.26c0-1.8-1.535-3.477-4.3-3.26" transform="translate(13.71)" fill="none" stroke="#c1c1c1" stroke-width="2"/>,
		] 
	},
	'_ㅇ_hd_(ㄹ)_3.svg': { 
		path: <path d="M11.04-3.664a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_hd_(ㅊ)_3.svg': { 
		path: <path d="M11.04-3.664a2.833,2.833,0,0,1,.624.056.862.862,0,0,1,.384.184.679.679,0,0,1,.2.36A2.461,2.461,0,0,1,12.3-2.5v1.024a1.143,1.143,0,0,1-.28.9,1.608,1.608,0,0,1-1,.232h-6.9q-.224,0-.28.064a.524.524,0,0,0-.056.3V.848a.473.473,0,0,0,.064.3.4.4,0,0,0,.288.072h8.272a.27.27,0,0,1,.152.048.147.147,0,0,1,.072.128v.56a.167.167,0,0,1-.072.136.226.226,0,0,1-.136.056H3.92a1.273,1.273,0,0,1-.984-.28A1.509,1.509,0,0,1,2.7.912V-.112a1.143,1.143,0,0,1,.28-.9,1.608,1.608,0,0,1,1-.232h6.88a.435.435,0,0,0,.288-.064.47.47,0,0,0,.064-.3v-.768a.524.524,0,0,0-.056-.3q-.056-.064-.28-.064H2.9A.27.27,0,0,1,2.744-2.8a.147.147,0,0,1-.072-.128v-.56a.147.147,0,0,1,.072-.128A.27.27,0,0,1,2.9-3.664Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_hd_2.svg': { 
		path: <path d="M7.488-5.968a9.01,9.01,0,0,1-1.72-.176A6.561,6.561,0,0,1,4.1-6.7,3.644,3.644,0,0,1,2.856-7.68a2.278,2.278,0,0,1-.488-1.472,2.278,2.278,0,0,1,.488-1.472A3.644,3.644,0,0,1,4.1-11.608a6.348,6.348,0,0,1,1.664-.544,9.424,9.424,0,0,1,1.72-.168,9.633,9.633,0,0,1,1.744.168,6.1,6.1,0,0,1,1.656.544,3.686,3.686,0,0,1,1.232.984,2.278,2.278,0,0,1,.488,1.472A2.278,2.278,0,0,1,12.12-7.68a3.686,3.686,0,0,1-1.232.984,6.3,6.3,0,0,1-1.656.552A9.209,9.209,0,0,1,7.488-5.968Zm0-5.344a9.73,9.73,0,0,0-1.24.088,5.244,5.244,0,0,0-1.28.328,2.84,2.84,0,0,0-1.008.664,1.515,1.515,0,0,0-.408,1.1A1.487,1.487,0,0,0,3.96-8.048a2.9,2.9,0,0,0,1.008.656,5.244,5.244,0,0,0,1.28.328,9.731,9.731,0,0,0,1.24.088,9.628,9.628,0,0,0,1.224-.088,5.244,5.244,0,0,0,1.28-.328,2.982,2.982,0,0,0,1.016-.656,1.471,1.471,0,0,0,.416-1.088,1.5,1.5,0,0,0-.416-1.1A2.923,2.923,0,0,0,9.992-10.9a5.244,5.244,0,0,0-1.28-.328A9.628,9.628,0,0,0,7.488-11.312Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_hd_c_3.svg': { 
		path: <path d="M7.52-6.976a12.908,12.908,0,0,1-1.64-.112A6.415,6.415,0,0,1,4.264-7.5a3.4,3.4,0,0,1-1.232-.832A1.925,1.925,0,0,1,2.544-9.7a1.9,1.9,0,0,1,.488-1.344,3.317,3.317,0,0,1,1.232-.824A6.9,6.9,0,0,1,5.88-12.28a12.062,12.062,0,0,1,1.64-.12,10.916,10.916,0,0,1,1.664.136,6.543,6.543,0,0,1,1.616.456,3.616,3.616,0,0,1,1.216.832A1.8,1.8,0,0,1,12.5-9.7a1.81,1.81,0,0,1-.488,1.288,3.645,3.645,0,0,1-1.224.84,6.546,6.546,0,0,1-1.608.456A10.762,10.762,0,0,1,7.52-6.976Zm0-4.416q-.528,0-1.192.064a6.308,6.308,0,0,0-1.24.24,2.742,2.742,0,0,0-.976.512,1.094,1.094,0,0,0-.4.88,1.094,1.094,0,0,0,.4.88,2.842,2.842,0,0,0,.976.52,5.939,5.939,0,0,0,1.24.248q.664.064,1.192.064T8.7-8.048A6.081,6.081,0,0,0,9.944-8.3a2.833,2.833,0,0,0,.984-.52,1.094,1.094,0,0,0,.4-.88,1.094,1.094,0,0,0-.4-.88,2.736,2.736,0,0,0-.984-.512,6.468,6.468,0,0,0-1.248-.24Q8.032-11.392,7.52-11.392Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_hu_2.svg': { 
		path: <path d="M7.488-4.352A7.8,7.8,0,0,1,5.736-4.56,5.857,5.857,0,0,1,4.08-5.208a3.889,3.889,0,0,1-1.232-1.12,2.771,2.771,0,0,1-.48-1.64,2.771,2.771,0,0,1,.48-1.64,3.889,3.889,0,0,1,1.232-1.12,5.689,5.689,0,0,1,1.656-.64,8.1,8.1,0,0,1,1.752-.2,8.171,8.171,0,0,1,1.768.2,5.582,5.582,0,0,1,1.648.64,3.918,3.918,0,0,1,1.224,1.12,2.771,2.771,0,0,1,.48,1.64,2.771,2.771,0,0,1-.48,1.64A3.918,3.918,0,0,1,10.9-5.208a5.745,5.745,0,0,1-1.648.648A7.871,7.871,0,0,1,7.488-4.352Zm0-6.192a7.4,7.4,0,0,0-1.264.12,4.62,4.62,0,0,0-1.28.416,2.91,2.91,0,0,0-.992.8,1.957,1.957,0,0,0-.4,1.256,1.924,1.924,0,0,0,.4,1.248,2.952,2.952,0,0,0,.992.792,4.62,4.62,0,0,0,1.28.416,7.4,7.4,0,0,0,1.264.12,7.187,7.187,0,0,0,1.24-.12,4.738,4.738,0,0,0,1.28-.416A3.012,3.012,0,0,0,11.016-6.7a1.9,1.9,0,0,0,.408-1.248,1.932,1.932,0,0,0-.408-1.256,2.968,2.968,0,0,0-1.008-.8,4.738,4.738,0,0,0-1.28-.416A7.187,7.187,0,0,0,7.488-10.544Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_hu_c_3.svg': { 
		path: <path d="M7.488-6.672a11.011,11.011,0,0,1-1.68-.136A6.415,6.415,0,0,1,4.2-7.264a3.37,3.37,0,0,1-1.208-.872A2.011,2.011,0,0,1,2.512-9.5a1.951,1.951,0,0,1,.48-1.352A3.555,3.555,0,0,1,4.2-11.72a6.2,6.2,0,0,1,1.608-.464,11.011,11.011,0,0,1,1.68-.136,9.48,9.48,0,0,1,1.7.16,6.669,6.669,0,0,1,1.608.5,3.559,3.559,0,0,1,1.2.872A1.882,1.882,0,0,1,12.464-9.5a1.9,1.9,0,0,1-.472,1.3,3.54,3.54,0,0,1-1.208.872,6.821,6.821,0,0,1-1.608.5A9.349,9.349,0,0,1,7.488-6.672Zm0-4.672a8.6,8.6,0,0,0-1.224.1,5.945,5.945,0,0,0-1.24.312,2.918,2.918,0,0,0-.96.568,1.144,1.144,0,0,0-.384.88,1.144,1.144,0,0,0,.384.88,2.918,2.918,0,0,0,.96.568,5.626,5.626,0,0,0,1.24.3,9.366,9.366,0,0,0,1.224.088A9.264,9.264,0,0,0,8.7-7.736a5.626,5.626,0,0,0,1.24-.3,3.015,3.015,0,0,0,.968-.568,1.134,1.134,0,0,0,.392-.88,1.134,1.134,0,0,0-.392-.88,3.015,3.015,0,0,0-.968-.568,5.945,5.945,0,0,0-1.24-.312A8.506,8.506,0,0,0,7.488-11.344Z" transform="translate(752 1994.66)"/>, 
		strokes: [
		] 
	},
	'_ㅇ_v_2.svg': { 
		path: <path d="M9.136-6.416A7.824,7.824,0,0,1,8.92-4.608,5.4,5.4,0,0,1,8.24-2.96,3.9,3.9,0,0,1,7.048-1.752,3.151,3.151,0,0,1,5.3-1.28a3.253,3.253,0,0,1-1.784-.464A3.565,3.565,0,0,1,2.344-2.952a5.422,5.422,0,0,1-.632-1.656A8.757,8.757,0,0,1,1.52-6.416,9.1,9.1,0,0,1,1.712-8.28a5.318,5.318,0,0,1,.632-1.656A3.447,3.447,0,0,1,3.512-11.12,3.343,3.343,0,0,1,5.3-11.568a3.279,3.279,0,0,1,1.752.448A3.692,3.692,0,0,1,8.24-9.944,5.289,5.289,0,0,1,8.92-8.3,8.2,8.2,0,0,1,9.136-6.416ZM8-6.432A7.5,7.5,0,0,0,7.848-7.92a4.934,4.934,0,0,0-.472-1.352,2.857,2.857,0,0,0-.832-.976A2.05,2.05,0,0,0,5.3-10.624a2.069,2.069,0,0,0-1.272.376,2.74,2.74,0,0,0-.808.976A4.731,4.731,0,0,0,2.784-7.92a8.855,8.855,0,0,0-.128,1.488,9.089,9.089,0,0,0,.12,1.448A4.771,4.771,0,0,0,3.2-3.624a2.816,2.816,0,0,0,.816,1.008,2.044,2.044,0,0,0,1.28.392,1.992,1.992,0,0,0,1.248-.392,2.974,2.974,0,0,0,.832-1.008,5.186,5.186,0,0,0,.472-1.36A7.226,7.226,0,0,0,8-6.432Z" />, 
		strokes: [
		] 
	},
	'_ㅇ_v_c_3.svg': { 
		path: <path d="M8.88-8.32a3.64,3.64,0,0,1-.288,1.464A3.464,3.464,0,0,1,7.8-5.712a3.638,3.638,0,0,1-1.2.752,4.163,4.163,0,0,1-1.528.272,4.119,4.119,0,0,1-1.488-.264A3.552,3.552,0,0,1,2.392-5.7a3.347,3.347,0,0,1-.784-1.152,3.79,3.79,0,0,1-.28-1.472,3.98,3.98,0,0,1,.28-1.528A3.25,3.25,0,0,1,2.392-11a3.415,3.415,0,0,1,1.192-.72,4.356,4.356,0,0,1,1.488-.248,4.261,4.261,0,0,1,1.456.248,3.656,3.656,0,0,1,1.208.712,3.424,3.424,0,0,1,.832,1.144A3.663,3.663,0,0,1,8.88-8.32ZM7.76-8.336a2.764,2.764,0,0,0-.216-1.128,2.349,2.349,0,0,0-.584-.816,2.489,2.489,0,0,0-.856-.5,3.148,3.148,0,0,0-1.032-.168,3.127,3.127,0,0,0-1.04.168,2.336,2.336,0,0,0-.832.5,2.277,2.277,0,0,0-.552.816,2.957,2.957,0,0,0-.2,1.128A2.909,2.909,0,0,0,2.64-7.248a2.509,2.509,0,0,0,.536.832A2.274,2.274,0,0,0,4-5.88,2.974,2.974,0,0,0,5.072-5.7,2.9,2.9,0,0,0,6.1-5.88a2.5,2.5,0,0,0,.856-.536,2.635,2.635,0,0,0,.584-.832A2.624,2.624,0,0,0,7.76-8.336Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_(ㅕ)_c_3.svg': { 
		path: <path d="M8.272-10.512a6.047,6.047,0,0,1-.7,1.4A11.8,11.8,0,0,1,6.5-7.744,7.764,7.764,0,0,0,7.84-6.664a11.556,11.556,0,0,0,1.728.92q.128.048.144.136a.235.235,0,0,1-.032.168l-.32.576a.175.175,0,0,1-.144.088A.382.382,0,0,1,9.04-4.8q-.352-.16-.76-.384T7.44-5.7q-.432-.288-.856-.624a9.192,9.192,0,0,1-.792-.7A15.007,15.007,0,0,1,3.856-5.5a14.04,14.04,0,0,1-2.1,1.152A.912.912,0,0,1,1.6-4.3a.115.115,0,0,1-.128-.064l-.32-.56A.171.171,0,0,1,1.144-5.1a.22.22,0,0,1,.12-.12,15.4,15.4,0,0,0,3.52-2.272,9.36,9.36,0,0,0,2.272-2.96q.112-.224.032-.32a.384.384,0,0,0-.3-.1H2.1q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.12a1.389,1.389,0,0,1,1.072.3Q8.528-11.232,8.272-10.512Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_(ㅗ)_c_3.svg': { 
		path: <path d="M11.232-10.544a9.958,9.958,0,0,1-2.256,1.52q.448.128,1,.272t1.128.288q.576.144,1.152.272t1.072.224q.208.048.144.224l-.224.528a.225.225,0,0,1-.288.128q-.544-.1-1.216-.256t-1.368-.344Q9.68-7.872,9-8.064T7.744-8.448a23.734,23.734,0,0,1-2.712.936,28.526,28.526,0,0,1-2.952.664q-.144.032-.2-.024a.382.382,0,0,1-.088-.152L1.648-7.5q-.064-.24.128-.256A21.361,21.361,0,0,0,4.1-8.272q1.216-.336,2.344-.752T8.512-9.9a9.486,9.486,0,0,0,1.5-.872q.256-.192.2-.3t-.28-.112H3.024a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.312q1.232,0,1.456.424T11.232-10.544Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M13.1-7.872a.233.233,0,0,1-.288.144A10.959,10.959,0,0,1,11.6-7.9q-.736-.136-1.584-.3-.848-.176-1.68-.352-.464-.128-.912-.24l-.144.048Q6-8.32,4.688-8A23.185,23.185,0,0,1,2.3-7.536a.3.3,0,0,1-.152-.008.177.177,0,0,1-.12-.088l-.144-.512q-.08-.256.128-.288Q3.072-8.624,4.248-8.9t2.264-.592a18.985,18.985,0,0,0,1.976-.672,9.3,9.3,0,0,0,1.48-.736.458.458,0,0,0,.2-.24q.024-.112-.248-.112H3.04q-.224,0-.224-.208v-.576q0-.16.224-.16h7.3a3.02,3.02,0,0,1,.992.128.714.714,0,0,1,.448.352.664.664,0,0,1-.064.52,1.956,1.956,0,0,1-.5.6,6.556,6.556,0,0,1-1.584.944q-.352.16-.736.32.224.048.464.112.656.128,1.344.24t1.336.208q.648.1,1.112.144.192.016.144.24Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M13.1-7.872a.233.233,0,0,1-.288.144A10.959,10.959,0,0,1,11.6-7.9q-.736-.136-1.584-.3-.848-.176-1.68-.352-.464-.128-.912-.24l-.144.048Q6-8.32,4.688-8A23.185,23.185,0,0,1,2.3-7.536a.3.3,0,0,1-.152-.008.177.177,0,0,1-.12-.088l-.144-.512q-.08-.256.128-.288Q3.072-8.624,4.248-8.9t2.264-.592a18.985,18.985,0,0,0,1.976-.672,9.3,9.3,0,0,0,1.48-.736.458.458,0,0,0,.2-.24q.024-.112-.248-.112H3.04q-.224,0-.224-.208v-.576q0-.16.224-.16h7.3a3.02,3.02,0,0,1,.992.128.714.714,0,0,1,.448.352.664.664,0,0,1-.064.52,1.956,1.956,0,0,1-.5.6,6.556,6.556,0,0,1-1.584.944q-.352.16-.736.32.224.048.464.112.656.128,1.344.24t1.336.208q.648.1,1.112.144.192.016.144.24Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M13.1-7.872a.233.233,0,0,1-.288.144A10.959,10.959,0,0,1,11.6-7.9q-.736-.136-1.584-.3-.848-.176-1.68-.352-.464-.128-.912-.24l-.144.048Q6-8.32,4.688-8A23.185,23.185,0,0,1,2.3-7.536a.3.3,0,0,1-.152-.008.177.177,0,0,1-.12-.088l-.144-.512q-.08-.256.128-.288Q3.072-8.624,4.248-8.9t2.264-.592a18.985,18.985,0,0,0,1.976-.672,9.3,9.3,0,0,0,1.48-.736.458.458,0,0,0,.2-.24q.024-.112-.248-.112H3.04q-.224,0-.224-.208v-.576q0-.16.224-.16h7.3a3.02,3.02,0,0,1,.992.128.714.714,0,0,1,.448.352.664.664,0,0,1-.064.52,1.956,1.956,0,0,1-.5.6,6.556,6.556,0,0,1-1.584.944q-.352.16-.736.32.224.048.464.112.656.128,1.344.24t1.336.208q.648.1,1.112.144.192.016.144.24Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.816-7.728A10.959,10.959,0,0,1,11.6-7.9q-.736-.136-1.584-.3-.848-.176-1.68-.352-.464-.128-.912-.24l-.144.048Q6-8.32,4.688-8A23.185,23.185,0,0,1,2.3-7.536a.3.3,0,0,1-.152-.008.177.177,0,0,1-.12-.088l-.144-.512q-.08-.256.128-.288Q3.072-8.624,4.248-8.9t2.264-.592a18.985,18.985,0,0,0,1.976-.672,9.3,9.3,0,0,0,1.48-.736.458.458,0,0,0,.2-.24q.024-.112-.248-.112H3.04q-.224,0-.224-.208v-.576q0-.16.224-.16h7.3a3.02,3.02,0,0,1,.992.128.714.714,0,0,1,.448.352.664.664,0,0,1-.064.52,1.956,1.956,0,0,1-.5.6,6.556,6.556,0,0,1-1.584.944q-.352.16-.736.32.224.048.464.112.656.128,1.344.24t1.336.208q.648.1,1.112.144.192.016.144.24S12.965-7.707,12.816-7.728Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_1.svg': { 
		path: <path d="M7.312-3.824a.427.427,0,0,0-.112.08q-.88.688-1.9,1.352t-2.32,1.4a.211.211,0,0,1-.32-.08l-.288-.464a.205.205,0,0,1,.08-.3Q3.632-2.512,4.6-3.12T6.384-4.36A18.483,18.483,0,0,0,7.912-5.68q.712-.688,1.4-1.488.16-.176.152-.272t-.264-.1H3.28a.184.184,0,0,1-.208-.208V-8.3q0-.192.208-.192H9.632a3.062,3.062,0,0,1,.968.12.694.694,0,0,1,.44.336.644.644,0,0,1-.032.52,2.268,2.268,0,0,1-.464.656Q9.7-5.952,8.9-5.2q-.384.368-.784.7.368.24.768.464.592.368,1.2.7t1.232.632a11.757,11.757,0,0,0,1.232.5q.144.048.16.1a.2.2,0,0,1-.048.152l-.256.56q-.064.176-.3.064a17.465,17.465,0,0,1-2.752-1.3Q8.3-3.184,7.312-3.824Z" />, 
		strokes: [
			<path   d="M806.935,2169.112a19.017,19.017,0,0,0,3.368-.121c1.006-.074,3.85-.385,4.031.688.058.346-1.637.955-3.277,2.43a41.827,41.827,0,0,1-5.014,3.549" fill="none" stroke="#707070" stroke-width="2" opacity="0.532"/>,
			<path   d="M811.574,2172.708a18.906,18.906,0,0,0,5.033,2.833" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅈ_hd_2.svg': { 
		path: <path d="M13.2-6.368q-.064.16-.3.08a21.5,21.5,0,0,1-2.576-.776,27.663,27.663,0,0,1-2.576-1.1,25.942,25.942,0,0,1-2.5,1.112,29.571,29.571,0,0,1-2.864.936.209.209,0,0,1-.3-.128l-.192-.512q-.1-.24.128-.288A23.567,23.567,0,0,0,4.3-7.768q1.128-.424,2.16-.912T8.4-9.7a15.44,15.44,0,0,0,1.616-1.08q.192-.144.168-.256t-.28-.112H3.024a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.312a2.866,2.866,0,0,1,.984.136.779.779,0,0,1,.464.36.589.589,0,0,1-.04.52,2.128,2.128,0,0,1-.5.616A14.963,14.963,0,0,1,8.752-8.7q1.008.448,2.2.848t2.328.672q.144.032.16.08a.207.207,0,0,1-.032.144Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_hd_c_3.svg': { 
		path: <path d="M13.216-7.376a.212.212,0,0,1-.272.128q-.688-.128-1.536-.336t-1.68-.448Q8.9-8.256,8.112-8.5L7.92-8.544l-.208.064A27.532,27.532,0,0,1,2.08-7.024q-.144.048-.2-.008a.382.382,0,0,1-.088-.152l-.128-.464q-.08-.256.128-.272a23.157,23.157,0,0,0,2.416-.5q1.232-.32,2.352-.7a18,18,0,0,0,1.992-.8A9.128,9.128,0,0,0,10-10.752q.256-.176.2-.288t-.28-.112H3.04q-.224,0-.224-.208v-.576q0-.176.224-.176H10.3q1.248,0,1.472.432t-.56,1.168A8.763,8.763,0,0,1,9.168-9.136a1.135,1.135,0,0,1,.144.048q.624.192,1.328.352.72.192,1.408.352t1.264.272q.208.032.128.208Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_hu_2.svg': { 
		path: <path d="M11.248-9.792a19.788,19.788,0,0,1-2.5,2.112,22.78,22.78,0,0,0,2.2,1.048,17.132,17.132,0,0,0,2.3.776q.144.032.16.088a.223.223,0,0,1-.032.152l-.224.576q-.064.16-.3.08a19.052,19.052,0,0,1-2.5-.88,22.693,22.693,0,0,1-2.5-1.248A21.427,21.427,0,0,1,5.336-5.72,30.373,30.373,0,0,1,2.4-4.56q-.224.08-.3-.112l-.208-.512A.2.2,0,0,1,2-5.472q1.424-.512,2.552-1.016a22.212,22.212,0,0,0,2.08-1.056A16.678,16.678,0,0,0,8.4-8.72,20.9,20.9,0,0,0,10.016-10.1q.176-.176.16-.272t-.272-.1H3.024a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.312a2.957,2.957,0,0,1,.976.128.724.724,0,0,1,.448.344.628.628,0,0,1-.04.52A2.227,2.227,0,0,1,11.248-9.792Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_hu_c_3.svg': { 
		path: <path d="M13.216-7.184a.232.232,0,0,1-.272.128q-.656-.128-1.5-.328T9.76-7.84Q8.9-8.048,8.144-8.288q-.24-.064-.432-.128l-.288.1a21.358,21.358,0,0,1-2.568.888,27.266,27.266,0,0,1-2.776.616.241.241,0,0,1-.2-.04.382.382,0,0,1-.088-.152l-.128-.464q-.08-.256.128-.272a19.7,19.7,0,0,0,2.3-.5q1.224-.336,2.344-.752A22.061,22.061,0,0,0,8.5-9.864a9.609,9.609,0,0,0,1.5-.872q.256-.176.2-.3t-.28-.12H3.04q-.224,0-.224-.208v-.56q0-.176.224-.176H10.3q1.248,0,1.472.416t-.56,1.168A7.808,7.808,0,0,1,9.6-9.344q-.3.176-.64.352.128.032.272.064.608.176,1.312.352.7.192,1.416.36t1.352.28q.208.048.128.208Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_v_2.svg': { 
		path: <path d="M9.408-2.144a.189.189,0,0,1-.12.1.277.277,0,0,1-.2-.08q-.384-.288-.816-.664t-.848-.8q-.416-.424-.816-.88t-.736-.9A15.8,15.8,0,0,1,4.048-3.448,23.936,23.936,0,0,1,1.712-1.616a.705.705,0,0,1-.16.072.108.108,0,0,1-.128-.04l-.384-.5a.2.2,0,0,1-.048-.168.209.209,0,0,1,.08-.136A18.4,18.4,0,0,0,5.064-6.048,11.268,11.268,0,0,0,7.056-9.92a.459.459,0,0,0,.008-.328q-.056-.1-.28-.1H1.936q-.192,0-.192-.208v-.56q0-.192.224-.192H7.216q.832,0,1.056.288t0,1.024a14.291,14.291,0,0,1-1.76,3.76q.368.512.784,1.008t.832.936q.416.44.832.8a7.355,7.355,0,0,0,.784.6.183.183,0,0,1,.08.144.234.234,0,0,1-.048.144Z" />, 
		strokes: [
		] 
	},
	'_ㅈ_v_c_3.svg': { 
		path: <path d="M8.272-10.512a6.048,6.048,0,0,1-.632,1.3,9.729,9.729,0,0,1-.968,1.264,7.338,7.338,0,0,0,1.344,1.1,11.234,11.234,0,0,0,1.744.92q.128.048.144.136a.235.235,0,0,1-.032.168l-.32.576a.175.175,0,0,1-.144.088.382.382,0,0,1-.176-.024q-.352-.16-.76-.384t-.84-.512q-.432-.288-.856-.624a9.192,9.192,0,0,1-.792-.7A13.966,13.966,0,0,1,3.976-5.584,14.539,14.539,0,0,1,1.76-4.352.912.912,0,0,1,1.6-4.3a.115.115,0,0,1-.128-.064l-.32-.56A.171.171,0,0,1,1.144-5.1a.22.22,0,0,1,.12-.12,15.4,15.4,0,0,0,3.52-2.272,9.36,9.36,0,0,0,2.272-2.96q.112-.224.032-.32a.384.384,0,0,0-.3-.1H2.1q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.12a1.389,1.389,0,0,1,1.072.3Q8.528-11.232,8.272-10.512Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅏ)_(ㄹ)_3.svg': { 
		path: <path d="M8.112-9.088A8.144,8.144,0,0,1,7.024-7.6a3.52,3.52,0,0,1-.336.352A9.747,9.747,0,0,0,7.7-6.512a11.211,11.211,0,0,0,1.712.864.22.22,0,0,1,.128.32l-.256.5a.348.348,0,0,1-.128.144.266.266,0,0,1-.208-.032A14.668,14.668,0,0,1,7.1-5.7Q6.5-6.112,5.92-6.576a3.7,3.7,0,0,0-.384.3,13.845,13.845,0,0,1-1.88,1.136,14.85,14.85,0,0,1-2.168.88.2.2,0,0,1-.144.048.1.1,0,0,1-.112-.08L.992-4.88a.13.13,0,0,1-.016-.16.255.255,0,0,1,.144-.112A15.191,15.191,0,0,0,4.472-6.784,10.026,10.026,0,0,0,6.9-9.008q.144-.192.048-.3a.4.4,0,0,0-.32-.112H1.792q-.208,0-.208-.224v-.544a.184.184,0,0,1,.208-.208H7.056a1.521,1.521,0,0,1,1.136.32Q8.48-9.76,8.112-9.088Zm-1.36-3.3q.24,0,.24.224v.512a.212.212,0,0,1-.24.24h-3.6a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅏ)_(ㅊ)_3.svg': { 
		path: <path d="M8.112-9.088A8.144,8.144,0,0,1,7.024-7.6a3.52,3.52,0,0,1-.336.352A9.747,9.747,0,0,0,7.7-6.512a11.211,11.211,0,0,0,1.712.864.22.22,0,0,1,.128.32l-.256.5a.348.348,0,0,1-.128.144.266.266,0,0,1-.208-.032A14.668,14.668,0,0,1,7.1-5.7Q6.5-6.112,5.92-6.576a3.7,3.7,0,0,0-.384.3,13.845,13.845,0,0,1-1.88,1.136,14.85,14.85,0,0,1-2.168.88.2.2,0,0,1-.144.048.1.1,0,0,1-.112-.08L.992-4.88a.13.13,0,0,1-.016-.16.255.255,0,0,1,.144-.112A15.191,15.191,0,0,0,4.472-6.784,10.026,10.026,0,0,0,6.9-9.008q.144-.192.048-.3a.4.4,0,0,0-.32-.112H1.792q-.208,0-.208-.224v-.544a.184.184,0,0,1,.208-.208H7.056a1.521,1.521,0,0,1,1.136.32Q8.48-9.76,8.112-9.088Zm-1.36-3.3q.24,0,.24.224v.512a.212.212,0,0,1-.24.24h-3.6a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅏ)_(ㅌ)_3.svg': { 
		path: <path d="M8.112-9.088A8.144,8.144,0,0,1,7.024-7.6a3.52,3.52,0,0,1-.336.352A9.747,9.747,0,0,0,7.7-6.512a11.211,11.211,0,0,0,1.712.864.22.22,0,0,1,.128.32l-.256.5a.348.348,0,0,1-.128.144.266.266,0,0,1-.208-.032A14.668,14.668,0,0,1,7.1-5.7Q6.5-6.112,5.92-6.576a3.7,3.7,0,0,0-.384.3,13.845,13.845,0,0,1-1.88,1.136,14.85,14.85,0,0,1-2.168.88.2.2,0,0,1-.144.048.1.1,0,0,1-.112-.08L.992-4.88a.13.13,0,0,1-.016-.16.255.255,0,0,1,.144-.112A15.191,15.191,0,0,0,4.472-6.784,10.026,10.026,0,0,0,6.9-9.008q.144-.192.048-.3a.4.4,0,0,0-.32-.112H1.792q-.208,0-.208-.224v-.544a.184.184,0,0,1,.208-.208H7.056a1.521,1.521,0,0,1,1.136.32Q8.48-9.76,8.112-9.088Zm-1.36-3.3q.24,0,.24.224v.512a.212.212,0,0,1-.24.24h-3.6a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅏ)_(ㅎ)_3.svg': { 
		path: <path d="M8.112-9.088A8.144,8.144,0,0,1,7.024-7.6a3.52,3.52,0,0,1-.336.352A9.747,9.747,0,0,0,7.7-6.512a11.211,11.211,0,0,0,1.712.864.22.22,0,0,1,.128.32l-.256.5a.348.348,0,0,1-.128.144.266.266,0,0,1-.208-.032A14.668,14.668,0,0,1,7.1-5.7Q6.5-6.112,5.92-6.576a3.7,3.7,0,0,0-.384.3,13.845,13.845,0,0,1-1.88,1.136,14.85,14.85,0,0,1-2.168.88.2.2,0,0,1-.144.048.1.1,0,0,1-.112-.08L.992-4.88a.13.13,0,0,1-.016-.16.255.255,0,0,1,.144-.112A15.191,15.191,0,0,0,4.472-6.784,10.026,10.026,0,0,0,6.9-9.008q.144-.192.048-.3a.4.4,0,0,0-.32-.112H1.792q-.208,0-.208-.224v-.544a.184.184,0,0,1,.208-.208H7.056a1.521,1.521,0,0,1,1.136.32Q8.48-9.76,8.112-9.088Zm-1.36-3.3q.24,0,.24.224v.512a.212.212,0,0,1-.24.24h-3.6a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅏ)_c_3.svg': { 
		path: <path d="M9.392-4.272a.257.257,0,0,1-.12.144.315.315,0,0,1-.216-.016,12.742,12.742,0,0,1-1.7-.816,11.948,11.948,0,0,1-1.608-1.1A15.271,15.271,0,0,1,3.92-4.784,17.783,17.783,0,0,1,1.664-3.648a.587.587,0,0,1-.16.04.128.128,0,0,1-.128-.072l-.288-.576q-.1-.16.112-.272,1.12-.512,1.968-1a15.023,15.023,0,0,0,1.52-.992A9.456,9.456,0,0,0,5.9-7.592,10.976,10.976,0,0,0,6.912-8.848q.128-.192.04-.3a.376.376,0,0,0-.312-.112H1.792q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28A1.524,1.524,0,0,1,8.2-9.9q.3.32-.072.992A9.882,9.882,0,0,1,7.408-7.8,9.636,9.636,0,0,1,6.5-6.752a11.68,11.68,0,0,0,1.48.984,9.18,9.18,0,0,0,1.512.68.2.2,0,0,1,.144.288Zm-2.624-8q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.152a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅑ)_c_3.svg': { 
		path: <path d="M9.392-4.272a.257.257,0,0,1-.12.144.315.315,0,0,1-.216-.016,12.742,12.742,0,0,1-1.7-.816,11.948,11.948,0,0,1-1.608-1.1A15.271,15.271,0,0,1,3.92-4.784,17.783,17.783,0,0,1,1.664-3.648a.587.587,0,0,1-.16.04.128.128,0,0,1-.128-.072l-.288-.576q-.1-.16.112-.272,1.12-.512,1.968-1a15.023,15.023,0,0,0,1.52-.992A9.456,9.456,0,0,0,5.9-7.592,10.976,10.976,0,0,0,6.912-8.848q.128-.192.04-.3a.376.376,0,0,0-.312-.112H1.792q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28A1.524,1.524,0,0,1,8.2-9.9q.3.32-.072.992A9.882,9.882,0,0,1,7.408-7.8,9.636,9.636,0,0,1,6.5-6.752a11.68,11.68,0,0,0,1.48.984,9.18,9.18,0,0,0,1.512.68.2.2,0,0,1,.144.288Zm-2.624-8q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.152a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅓ)_(ㄹ)_3.svg': { 
		path: <path d="M9.456-4.688a.257.257,0,0,1-.12.144.283.283,0,0,1-.216-.032,12.414,12.414,0,0,1-1.56-.808,12.953,12.953,0,0,1-1.5-1.064A13.24,13.24,0,0,1,4.12-5.224a16.306,16.306,0,0,1-2.3.968.428.428,0,0,1-.16.032.113.113,0,0,1-.112-.08L1.3-4.9a.172.172,0,0,1,.016-.16.26.26,0,0,1,.128-.112A15.376,15.376,0,0,0,4.8-6.8,9.817,9.817,0,0,0,7.232-9.04q.144-.192.048-.3a.4.4,0,0,0-.32-.112H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28a1.524,1.524,0,0,1,1.128.32q.3.32-.072.992a8.785,8.785,0,0,1-1.6,2A11.148,11.148,0,0,0,8.2-6.168,8.832,8.832,0,0,0,9.584-5.5q.224.112.128.3ZM7.088-12.416q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅓ)_(ㅊ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅓ)_(ㅎ)_3.svg': { 
		path: <path d="M9.456-4.688a.257.257,0,0,1-.12.144.283.283,0,0,1-.216-.032,12.414,12.414,0,0,1-1.56-.808,12.953,12.953,0,0,1-1.5-1.064A13.24,13.24,0,0,1,4.12-5.224a16.306,16.306,0,0,1-2.3.968.428.428,0,0,1-.16.032.113.113,0,0,1-.112-.08L1.3-4.9a.172.172,0,0,1,.016-.16.26.26,0,0,1,.128-.112A15.376,15.376,0,0,0,4.8-6.8,9.817,9.817,0,0,0,7.232-9.04q.144-.192.048-.3a.4.4,0,0,0-.32-.112H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28a1.524,1.524,0,0,1,1.128.32q.3.32-.072.992a8.785,8.785,0,0,1-1.6,2A11.148,11.148,0,0,0,8.2-6.168,8.832,8.832,0,0,0,9.584-5.5q.224.112.128.3ZM7.088-12.416q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅓ)_c_3.svg': { 
		path: <path d="M9.488-4.064a.275.275,0,0,1-.136.136.367.367,0,0,1-.216-.024A12.7,12.7,0,0,1,7.488-4.8,12.178,12.178,0,0,1,5.92-5.936,14.876,14.876,0,0,1,4.152-4.728a19.093,19.093,0,0,1-2.168,1.08.587.587,0,0,1-.16.04A.128.128,0,0,1,1.7-3.68l-.288-.576q-.1-.16.112-.272,1.12-.512,1.968-1a15.023,15.023,0,0,0,1.52-.992A9.456,9.456,0,0,0,6.216-7.592,10.976,10.976,0,0,0,7.232-8.848q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28A1.524,1.524,0,0,1,8.52-9.9q.3.32-.072.992a9.8,9.8,0,0,1-1.776,2.3A10.265,10.265,0,0,0,8.12-5.592,10.067,10.067,0,0,0,9.6-4.88a.187.187,0,0,1,.128.288Zm-2.4-8.208q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_(ㄴ)_3.svg': { 
		path: <path d="M9.392-3.44a.257.257,0,0,1-.12.144.283.283,0,0,1-.216-.032A13.759,13.759,0,0,1,7.4-4.24a12.422,12.422,0,0,1-1.576-1.2,15.192,15.192,0,0,1-3.84,2.4.587.587,0,0,1-.16.04A.128.128,0,0,1,1.7-3.072l-.288-.576a.157.157,0,0,1-.008-.16.224.224,0,0,1,.12-.112A16.3,16.3,0,0,0,4.792-5.952,11.547,11.547,0,0,0,7.232-8.64q.128-.208.04-.312a.392.392,0,0,0-.312-.1H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28A1.524,1.524,0,0,1,8.52-9.7q.3.32-.072.992A12.611,12.611,0,0,1,6.56-6.144,10.733,10.733,0,0,0,8.016-5.032a9.44,9.44,0,0,0,1.52.792.192.192,0,0,1,.128.12.228.228,0,0,1,0,.168Zm-2.3-8.672q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_(ㅂ)_3.svg': { 
		path: <path d="M8.432-8.9a10.316,10.316,0,0,1-1.76,2.288,10,10,0,0,0,1.184.88,10.044,10.044,0,0,0,1.728.864.178.178,0,0,1,.112.288l-.224.512a.451.451,0,0,1-.144.144.266.266,0,0,1-.208-.032,14.129,14.129,0,0,1-1.856-.976A14.907,14.907,0,0,1,5.888-5.92a16.581,16.581,0,0,1-3.92,2.272.411.411,0,0,1-.144.04A.134.134,0,0,1,1.7-3.664l-.3-.576q-.1-.176.112-.288,1.12-.512,1.968-.992a13.392,13.392,0,0,0,1.52-.992A10.348,10.348,0,0,0,6.2-7.584,11.675,11.675,0,0,0,7.232-8.832q.112-.192.024-.3a.376.376,0,0,0-.312-.112H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192H7.376a1.5,1.5,0,0,1,1.112.32Q8.784-9.568,8.432-8.9Zm-1.36-3.36q.24,0,.24.224v.528a.212.212,0,0,1-.24.24H3.456q-.224,0-.224-.24v-.528a.2.2,0,0,1,.224-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_(ㅊ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_(ㅎ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅕ)_c_3.svg': { 
		path: <path d="M8.432-8.9a10.316,10.316,0,0,1-1.76,2.288,10,10,0,0,0,1.184.88,10.044,10.044,0,0,0,1.728.864.178.178,0,0,1,.112.288l-.224.512a.451.451,0,0,1-.144.144.266.266,0,0,1-.208-.032,14.129,14.129,0,0,1-1.856-.976A14.907,14.907,0,0,1,5.888-5.92a16.581,16.581,0,0,1-3.92,2.272.411.411,0,0,1-.144.04A.134.134,0,0,1,1.7-3.664l-.3-.576q-.1-.176.112-.288,1.12-.512,1.968-.992a13.392,13.392,0,0,0,1.52-.992A10.348,10.348,0,0,0,6.2-7.584,11.675,11.675,0,0,0,7.232-8.832q.112-.192.024-.3a.376.376,0,0,0-.312-.112H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192H7.376a1.5,1.5,0,0,1,1.112.32Q8.784-9.568,8.432-8.9Zm-1.36-3.36q.24,0,.24.224v.528a.212.212,0,0,1-.24.24H3.456q-.224,0-.224-.24v-.528a.2.2,0,0,1,.224-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅗ)_(ㅂ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅗ)_c_3.svg': { 
		path: <path d="M11.008-9.024A13.645,13.645,0,0,1,8.88-7.792q.9.272,1.944.5t2.04.376q.128.016.152.072a.265.265,0,0,1-.008.168l-.144.544q-.032.176-.256.128-.512-.08-1.144-.208T10.152-6.5q-.68-.16-1.36-.344t-1.3-.36a23.007,23.007,0,0,1-2.4.792Q3.824-6.064,2.5-5.84q-.24.048-.3-.112L2-6.5a.155.155,0,0,1,.016-.152.268.268,0,0,1,.144-.1q1.12-.224,2.112-.488t1.9-.56q.9-.3,1.744-.656t1.672-.776q.272-.144.248-.248t-.28-.1H3.184a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192H10.1q1.248,0,1.52.44T11.008-9.024ZM9.536-12.512q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.976-7.024a.279.279,0,0,1-.064.16.24.24,0,0,1-.192.032,10.31,10.31,0,0,1-1.248-.128q-.72-.112-1.52-.256Q9.168-7.36,8.4-7.52q-.528-.128-.944-.224-.08.016-.144.032-1.1.32-2.344.592t-2.52.448q-.176.048-.232,0a.232.232,0,0,1-.072-.144L2.032-7.3q-.08-.256.144-.288.976-.128,2.016-.336t2.032-.448q1.008-.24,1.856-.512A11.9,11.9,0,0,0,9.5-9.424q.336-.144.3-.248t-.256-.1H3.184q-.208,0-.208-.224v-.512q0-.192.208-.192h6.7a3.732,3.732,0,0,1,1.16.144,1.064,1.064,0,0,1,.552.336.487.487,0,0,1-.072.5,1.956,1.956,0,0,1-.632.552A13.009,13.009,0,0,1,9.312-8.4a2.687,2.687,0,0,0-.352.144q.208.032.448.08.544.08,1.152.16.576.1,1.176.16t1.16.1a.348.348,0,0,1,.136.056q.056.04.04.184ZM9.52-12.56a.2.2,0,0,1,.224.224v.528a.2.2,0,0,1-.224.224H5.328q-.24,0-.24-.224v-.528q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅛ)_c_3.svg': { 
		path: <path d="M11.008-9.024a11.193,11.193,0,0,1-.984.624q-.536.3-1.176.608.912.272,1.96.5t2.056.376q.128.016.152.072a.265.265,0,0,1-.008.168l-.144.544q-.032.176-.256.128-.528-.08-1.16-.216T10.128-6.5Q9.44-6.656,8.752-6.84T7.44-7.2q-1.1.416-2.344.76t-2.6.6q-.24.048-.3-.112L2-6.5a.155.155,0,0,1,.016-.152.268.268,0,0,1,.144-.1Q3.3-6.976,4.288-7.24t1.9-.56q.9-.3,1.744-.656t1.656-.776q.272-.144.248-.248t-.28-.1H3.184a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192H10.1q1.248,0,1.52.44T11.008-9.024ZM9.536-12.512q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅜ)_(ㄹ)_3.svg': { 
		path: <path d="M10.9-9.072a5.568,5.568,0,0,1-.88.488q-.512.232-1.12.456.448.08.968.152t1.048.136q.528.064,1.04.112t.976.064a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.528-.032-1.24-.12t-1.48-.208q-.768-.12-1.5-.264T7.216-7.6q-1.2.336-2.448.6t-2.336.424a.3.3,0,0,1-.216-.008.263.263,0,0,1-.088-.136l-.112-.5q-.048-.24.16-.272.96-.128,2.024-.344T6.264-8.3q1-.248,1.856-.52A12.854,12.854,0,0,0,9.5-9.328q.336-.16.3-.264t-.256-.1H3.184A.184.184,0,0,1,2.976-9.9v-.512q0-.192.208-.192h6.7a4.863,4.863,0,0,1,1.1.1,1.153,1.153,0,0,1,.608.3.388.388,0,0,1,.008.48A2.236,2.236,0,0,1,10.9-9.072ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅜ)_(ㅂ)_3.svg': { 
		path: <path d="M10.9-9.072a5.568,5.568,0,0,1-.88.488q-.512.232-1.12.456.448.08.968.152t1.048.136q.528.064,1.04.112t.976.064a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.528-.032-1.24-.12t-1.48-.208q-.768-.12-1.5-.264T7.216-7.6q-1.2.336-2.448.6t-2.336.424a.3.3,0,0,1-.216-.008.263.263,0,0,1-.088-.136l-.112-.5q-.048-.24.16-.272.96-.128,2.024-.344T6.264-8.3q1-.248,1.856-.52A12.854,12.854,0,0,0,9.5-9.328q.336-.16.3-.264t-.256-.1H3.184A.184.184,0,0,1,2.976-9.9v-.512q0-.192.208-.192h6.7a4.863,4.863,0,0,1,1.1.1,1.153,1.153,0,0,1,.608.3.388.388,0,0,1,.008.48A2.236,2.236,0,0,1,10.9-9.072ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅜ)_(ㅎ)_3.svg': { 
		path: <path d="M12.976-6.912a.238.238,0,0,1-.064.152.272.272,0,0,1-.192.024q-.544-.048-1.272-.136t-1.5-.216q-.768-.112-1.52-.256Q7.744-7.472,7.2-7.6a.178.178,0,0,0-.08.016q-1.184.336-2.408.592t-2.3.416a.264.264,0,0,1-.2-.008.263.263,0,0,1-.088-.136l-.112-.512q-.064-.224.16-.256.96-.128,2.016-.344T6.256-8.3q.992-.24,1.848-.512a12.854,12.854,0,0,0,1.384-.512q.32-.16.288-.264t-.24-.1H3.168A.184.184,0,0,1,2.96-9.9v-.512q0-.176.208-.176H9.856a4.863,4.863,0,0,1,1.1.1,1.205,1.205,0,0,1,.616.3.379.379,0,0,1,.008.464,2.23,2.23,0,0,1-.712.672,7.981,7.981,0,0,1-1.6.784l-.384.16q.272.032.576.08l1.152.16q.608.064,1.2.12l1.088.1a.266.266,0,0,1,.144.04q.064.04.032.184ZM9.52-12.528a.2.2,0,0,1,.224.224v.48q0,.24-.224.24H5.328a.212.212,0,0,1-.24-.24v-.48q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅜ)_c_3.svg': { 
		path: <path d="M10.9-9.072a5.568,5.568,0,0,1-.88.488q-.512.232-1.12.456.448.08.968.152t1.048.136q.528.064,1.04.112t.976.064a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.528-.032-1.24-.12t-1.48-.208q-.768-.12-1.5-.264T7.216-7.6q-1.2.336-2.448.6t-2.336.424a.3.3,0,0,1-.216-.008.263.263,0,0,1-.088-.136l-.112-.5q-.048-.24.16-.272.96-.128,2.024-.344T6.264-8.3q1-.248,1.856-.52A12.854,12.854,0,0,0,9.5-9.328q.336-.16.3-.264t-.256-.1H3.184A.184.184,0,0,1,2.976-9.9v-.512q0-.192.208-.192h6.7a4.863,4.863,0,0,1,1.1.1,1.153,1.153,0,0,1,.608.3.388.388,0,0,1,.008.48A2.236,2.236,0,0,1,10.9-9.072ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M10.912-9.04a8.094,8.094,0,0,1-.888.456q-.488.216-1.048.44.432.08.928.16t1.008.152q.512.072,1.024.128t.992.072a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.48-.032-1.144-.12t-1.4-.216q-.736-.128-1.456-.28t-1.3-.3q-1.12.336-2.384.608t-2.608.464a.342.342,0,0,1-.232-.008.2.2,0,0,1-.072-.136l-.112-.5q-.08-.24.16-.272.96-.128,2.008-.336t2.04-.456Q7.232-8.5,8.088-8.76A11,11,0,0,0,9.52-9.3q.336-.16.3-.264t-.256-.1H3.2a.184.184,0,0,1-.208-.208v-.512q0-.192.208-.192H9.9a4.008,4.008,0,0,1,1.176.136.91.91,0,0,1,.544.352.445.445,0,0,1-.064.488A2.131,2.131,0,0,1,10.912-9.04ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M10.912-9.04a8.094,8.094,0,0,1-.888.456q-.488.216-1.048.44.432.08.928.16t1.008.152q.512.072,1.024.128t.992.072a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.48-.032-1.144-.12t-1.4-.216q-.736-.128-1.456-.28t-1.3-.3q-1.12.336-2.384.608t-2.608.464a.342.342,0,0,1-.232-.008.2.2,0,0,1-.072-.136l-.112-.5q-.08-.24.16-.272.96-.128,2.008-.336t2.04-.456Q7.232-8.5,8.088-8.76A11,11,0,0,0,9.52-9.3q.336-.16.3-.264t-.256-.1H3.2a.184.184,0,0,1-.208-.208v-.512q0-.192.208-.192H9.9a4.008,4.008,0,0,1,1.176.136.91.91,0,0,1,.544.352.445.445,0,0,1-.064.488A2.131,2.131,0,0,1,10.912-9.04ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M10.912-9.04a8.094,8.094,0,0,1-.888.456q-.488.216-1.048.44.432.08.928.16t1.008.152q.512.072,1.024.128t.992.072a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.48-.032-1.144-.12t-1.4-.216q-.736-.128-1.456-.28t-1.3-.3q-1.12.336-2.384.608t-2.608.464a.342.342,0,0,1-.232-.008.2.2,0,0,1-.072-.136l-.112-.5q-.08-.24.16-.272.96-.128,2.008-.336t2.04-.456Q7.232-8.5,8.088-8.76A11,11,0,0,0,9.52-9.3q.336-.16.3-.264t-.256-.1H3.2a.184.184,0,0,1-.208-.208v-.512q0-.192.208-.192H9.9a4.008,4.008,0,0,1,1.176.136.91.91,0,0,1,.544.352.445.445,0,0,1-.064.488A2.131,2.131,0,0,1,10.912-9.04ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅡ)_c_3.svg': { 
		path: <path d="M11.008-9.024q-.448.32-.992.632t-1.152.6q.9.272,1.944.5t2.056.376q.128.016.152.072a.265.265,0,0,1-.008.168l-.144.544q-.032.176-.256.128-.512-.08-1.152-.216T10.136-6.5q-.68-.152-1.368-.336T7.456-7.2q-1.12.416-2.376.768T2.5-5.84q-.24.048-.3-.112L2-6.5a.155.155,0,0,1,.016-.152.268.268,0,0,1,.144-.1q1.136-.224,2.128-.48t1.888-.56q.9-.3,1.736-.656t1.672-.784q.272-.144.248-.248t-.28-.1H3.184a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192H10.1q1.248,0,1.52.44T11.008-9.024ZM9.536-12.512q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅣ)_(ㄹ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅣ)_(ㅊ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅣ)_(ㅌ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅣ)_(ㅎ)_3.svg': { 
		path: <path d="M8.432-9.088A8.144,8.144,0,0,1,7.344-7.6q-.24.256-.512.528.512.368,1.008.72a10.044,10.044,0,0,0,1.728.864q.224.112.128.3l-.24.512a.31.31,0,0,1-.144.144A.259.259,0,0,1,9.1-4.56a12.209,12.209,0,0,1-1.84-.976,11.86,11.86,0,0,1-1.216-.9,1.523,1.523,0,0,0-.192.16A14.083,14.083,0,0,1,3.984-5.136a14.511,14.511,0,0,1-2.16.88.377.377,0,0,1-.16.048q-.064,0-.1-.08L1.28-4.88a.22.22,0,0,1,.032-.16.255.255,0,0,1,.144-.112A15.292,15.292,0,0,0,4.792-6.784a10.309,10.309,0,0,0,2.44-2.224q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.128q-.192,0-.192-.224v-.544q0-.208.192-.208H7.376a1.554,1.554,0,0,1,1.136.32Q8.816-9.76,8.432-9.088Zm-1.344-3.3a.2.2,0,0,1,.224.224v.512q0,.24-.224.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_(ㅣ)_c_3.svg': { 
		path: <path d="M9.712-4.272a.257.257,0,0,1-.12.144.315.315,0,0,1-.216-.016,12.742,12.742,0,0,1-1.7-.816,11.948,11.948,0,0,1-1.608-1.1A15.271,15.271,0,0,1,4.24-4.784,17.783,17.783,0,0,1,1.984-3.648a.587.587,0,0,1-.16.04A.128.128,0,0,1,1.7-3.68l-.288-.576q-.1-.16.112-.272,1.12-.512,1.968-1a15.023,15.023,0,0,0,1.52-.992A9.456,9.456,0,0,0,6.216-7.592,10.976,10.976,0,0,0,7.232-8.848q.128-.192.04-.3a.376.376,0,0,0-.312-.112H2.112q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.28A1.524,1.524,0,0,1,8.52-9.9q.3.32-.072.992A9.882,9.882,0,0,1,7.728-7.8a9.636,9.636,0,0,1-.912,1.048,11.68,11.68,0,0,0,1.48.984,9.18,9.18,0,0,0,1.512.68.2.2,0,0,1,.144.288Zm-2.624-8q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.472a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_1.svg': { 
		path: <path d="M8.848-3.84q.208.144.432.3.464.32.952.608t.984.52a8.7,8.7,0,0,0,.928.376q.224.08.1.32l-.272.5a.245.245,0,0,1-.352.08,14.021,14.021,0,0,1-1.952-.992,15.954,15.954,0,0,1-1.68-1.136l-.1.064A20.716,20.716,0,0,1,5.824-2.072Q4.7-1.536,3.344-1.04a.526.526,0,0,1-.2.04.159.159,0,0,1-.1-.088l-.272-.56q-.112-.192.112-.272A26.034,26.034,0,0,0,7-3.816a16.988,16.988,0,0,0,2.92-2.1.212.212,0,0,0,.072-.272q-.072-.144-.344-.144H3.488q-.192,0-.192-.208V-7.1A.17.17,0,0,1,3.488-7.3H10.08a1.894,1.894,0,0,1,.792.144,1.065,1.065,0,0,1,.456.36.659.659,0,0,1,.1.48.952.952,0,0,1-.3.52,20.116,20.116,0,0,1-1.5,1.36Q9.248-4.128,8.848-3.84Zm.192-5.6q.24,0,.24.224V-8.7a.212.212,0,0,1-.24.24H5.424a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
			<path   d="M809.133,2168.144h4.228" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M807.214,2170.2h3.761c1.495-.109,3.544-.273,3.5.517a1.33,1.33,0,0,1-1.034,1.1c-.671.083-.728.252-1.345.663s-1.306.887-1.306.887c-1.857,1.023-4.015,2.148-4.015,2.148" fill="none" stroke="#707070" stroke-width="2" opacity="0.827"/>,
			<path   d="M812.326,2173.384c.442.657,2.706,1.545,3.884,2.146" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅊ_hd_(ㄹ)_3.svg': { 
		path: <path d="M10.9-9.072a5.568,5.568,0,0,1-.88.488q-.512.232-1.12.456.448.08.968.152t1.048.136q.528.064,1.04.112t.976.064a.363.363,0,0,1,.152.056q.056.04.024.184l-.08.5a.371.371,0,0,1-.08.16q-.048.048-.208.032-.528-.032-1.24-.12t-1.48-.208q-.768-.12-1.5-.264T7.216-7.6q-1.2.336-2.448.6t-2.336.424a.3.3,0,0,1-.216-.008.263.263,0,0,1-.088-.136l-.112-.5q-.048-.24.16-.272.96-.128,2.024-.344T6.264-8.3q1-.248,1.856-.52A12.854,12.854,0,0,0,9.5-9.328q.336-.16.3-.264t-.256-.1H3.184A.184.184,0,0,1,2.976-9.9v-.512q0-.192.208-.192h6.7a4.863,4.863,0,0,1,1.1.1,1.153,1.153,0,0,1,.608.3.388.388,0,0,1,.008.48A2.236,2.236,0,0,1,10.9-9.072ZM9.536-12.56q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_hd_(ㅊ)_3.svg': { 
		path: <path d="M12.976-6.912a.238.238,0,0,1-.064.152.272.272,0,0,1-.192.024q-.544-.048-1.272-.136t-1.5-.216q-.768-.112-1.52-.256Q7.744-7.472,7.2-7.6a.178.178,0,0,0-.08.016q-1.184.336-2.408.592t-2.3.416a.264.264,0,0,1-.2-.008.263.263,0,0,1-.088-.136l-.112-.512q-.064-.224.16-.256.96-.128,2.016-.344T6.256-8.3q.992-.24,1.848-.512a12.854,12.854,0,0,0,1.384-.512q.32-.16.288-.264t-.24-.1H3.168A.184.184,0,0,1,2.96-9.9v-.512q0-.176.208-.176H9.856a4.863,4.863,0,0,1,1.1.1,1.205,1.205,0,0,1,.616.3.379.379,0,0,1,.008.464,2.23,2.23,0,0,1-.712.672,7.981,7.981,0,0,1-1.6.784l-.384.16q.272.032.576.08l1.152.16q.608.064,1.2.12l1.088.1a.266.266,0,0,1,.144.04q.064.04.032.184ZM9.52-12.528a.2.2,0,0,1,.224.224v.48q0,.24-.224.24H5.328a.212.212,0,0,1-.24-.24v-.48q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_hd_(ㅌ)_3.svg': { 
		path: <path d="M12.976-6.912a.238.238,0,0,1-.064.152.272.272,0,0,1-.192.024q-.544-.048-1.272-.136t-1.5-.216q-.768-.112-1.52-.256Q7.744-7.472,7.2-7.6a.178.178,0,0,0-.08.016q-1.184.336-2.408.592t-2.3.416a.264.264,0,0,1-.2-.008.263.263,0,0,1-.088-.136l-.112-.512q-.064-.224.16-.256.96-.128,2.016-.344T6.256-8.3q.992-.24,1.848-.512a12.854,12.854,0,0,0,1.384-.512q.32-.16.288-.264t-.24-.1H3.168A.184.184,0,0,1,2.96-9.9v-.512q0-.176.208-.176H9.856a4.863,4.863,0,0,1,1.1.1,1.205,1.205,0,0,1,.616.3.379.379,0,0,1,.008.464,2.23,2.23,0,0,1-.712.672,7.981,7.981,0,0,1-1.6.784l-.384.16q.272.032.576.08l1.152.16q.608.064,1.2.12l1.088.1a.266.266,0,0,1,.144.04q.064.04.032.184ZM9.52-12.528a.2.2,0,0,1,.224.224v.48q0,.24-.224.24H5.328a.212.212,0,0,1-.24-.24v-.48q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_hd_2.svg': { 
		path: <path d="M11.264-8.752a5.953,5.953,0,0,1-.992.8,15.3,15.3,0,0,1-1.424.832q.48.144,1.048.32t1.144.336q.576.16,1.12.3t.96.216q.208.048.144.224l-.192.576q-.048.176-.288.128-.464-.1-1.1-.264t-1.344-.376q-.7-.208-1.416-.432T7.584-6.528A28.089,28.089,0,0,1,4.8-5.5a18.4,18.4,0,0,1-2.72.648.306.306,0,0,1-.2,0,.247.247,0,0,1-.088-.16L1.648-5.52a.251.251,0,0,1,.008-.192.175.175,0,0,1,.12-.08,18.8,18.8,0,0,0,2.12-.5q1.16-.336,2.288-.768t2.128-.928a10.637,10.637,0,0,0,1.64-.976.481.481,0,0,0,.208-.28q.032-.152-.224-.152H3.024A.184.184,0,0,1,2.816-9.6v-.56q0-.192.208-.192h7.344Q12.768-10.352,11.264-8.752Zm-1.728-3.76q.24,0,.24.224v.512a.212.212,0,0,1-.24.24h-4.3a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_hd_c_3.svg': { 
		path: <path d="M11.008-9.024A13.551,13.551,0,0,1,8.944-7.856q.88.256,1.92.472t2,.344q.128.016.152.072a.265.265,0,0,1-.008.168l-.144.544q-.032.176-.256.128-.512-.08-1.144-.2T10.16-6.6q-.672-.152-1.352-.32T7.52-7.264q-1.1.4-2.36.728T2.5-5.968q-.24.048-.3-.112L2-6.624a.172.172,0,0,1,.016-.16.2.2,0,0,1,.144-.1Q3.3-7.088,4.288-7.336t1.888-.536q.9-.288,1.736-.624t1.672-.736q.272-.128.248-.24t-.28-.112H3.184a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192H10.1q1.248,0,1.52.44T11.008-9.024ZM9.536-12.512q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.328a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_hu_2.svg': { 
		path: <path d="M11.28-8.288a7.22,7.22,0,0,1-1.088.96,14.206,14.206,0,0,1-1.536.976q.512.208,1.12.424T11-5.52q.616.192,1.192.352t1.024.256q.128.032.152.088a.218.218,0,0,1-.008.152l-.16.544A.2.2,0,0,1,12.928-4q-.432-.1-1.1-.288T10.416-4.72Q9.664-4.96,8.9-5.232T7.5-5.76A26.71,26.71,0,0,1,4.92-4.64q-1.368.512-2.776.9-.24.064-.3-.112l-.224-.528a.155.155,0,0,1,.016-.152.272.272,0,0,1,.128-.1A27.576,27.576,0,0,0,6.3-6.3,19.668,19.668,0,0,0,9.936-8.48q.256-.192.24-.328T9.9-8.944H2.976a.184.184,0,0,1-.208-.208v-.56q0-.192.208-.192h7.392Q12.752-9.9,11.28-8.288Zm-1.744-3.9q.24,0,.24.224v.512a.212.212,0,0,1-.24.24h-4.3a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_v_2.svg': { 
		path: <path d="M8.208-8.032A9.237,9.237,0,0,1,7.544-6.64a10.864,10.864,0,0,1-.9,1.312,9.135,9.135,0,0,0,.7.784q.4.4.816.752a10.005,10.005,0,0,0,.84.64,7.1,7.1,0,0,0,.808.48q.176.1.064.288l-.3.528q-.112.192-.32.064-.384-.224-.816-.536t-.856-.68q-.424-.368-.832-.768t-.76-.8A13.351,13.351,0,0,1,4.016-2.848,15.276,15.276,0,0,1,1.7-1.472q-.224.112-.3-.048l-.3-.528a.171.171,0,0,1-.008-.168.22.22,0,0,1,.12-.12,13.124,13.124,0,0,0,3.4-2.32A11.313,11.313,0,0,0,6.992-7.968q.112-.224.032-.32a.384.384,0,0,0-.3-.1H1.872q-.192,0-.192-.208v-.56q0-.192.224-.192H7.152a1.419,1.419,0,0,1,1.08.3Q8.48-8.752,8.208-8.032ZM7.136-11.776q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H3.184a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅊ_v_c_3.svg': { 
		path: <path d="M15.418-6.6a.448.448,0,0,1-.221.221.6.6,0,0,1-.351-.039A20.642,20.642,0,0,1,12.168-7.8,19.79,19.79,0,0,1,9.62-9.646,24.174,24.174,0,0,1,6.747-7.683,31.026,31.026,0,0,1,3.224-5.928a.954.954,0,0,1-.26.065.208.208,0,0,1-.208-.117l-.468-.936q-.156-.26.182-.442,1.82-.832,3.2-1.625A24.412,24.412,0,0,0,8.138-10.6,15.365,15.365,0,0,0,10.1-12.337a17.836,17.836,0,0,0,1.651-2.041q.208-.312.065-.494a.611.611,0,0,0-.507-.182H3.432q-.312,0-.312-.338v-.91a.276.276,0,0,1,.312-.312h8.58a2.476,2.476,0,0,1,1.833.52q.481.52-.117,1.612a15.926,15.926,0,0,1-2.886,3.744,16.681,16.681,0,0,0,2.353,1.651A16.358,16.358,0,0,0,15.6-7.93a.3.3,0,0,1,.208.468Zm-3.9-13.338q.39,0,.39.364v.832a.345.345,0,0,1-.39.39H5.642a.345.345,0,0,1-.39-.39v-.832q0-.364.39-.364Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅓ)_(ㄹ)_3.svg': { 
		path: <path d="M2.016-9.088q.48,0,.992-.016l1-.032.912-.032q.424-.016.712-.032.1,0,.264-.016t.368-.024q.2-.008.4-.024a2.545,2.545,0,0,0,.344-.048,5.478,5.478,0,0,0,.272-.64,4.573,4.573,0,0,0,.176-.656q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.544a.17.17,0,0,1,.192-.192h5.3a1.518,1.518,0,0,1,1.048.272.91.91,0,0,1,.168.864,7.233,7.233,0,0,1-.64,1.952,6.825,6.825,0,0,1-1.224,1.7A10.178,10.178,0,0,1,4.88-5.72a19.806,19.806,0,0,1-2.7,1.384.237.237,0,0,1-.352-.1l-.24-.528Q1.52-5.1,1.56-5.168a.323.323,0,0,1,.152-.112A16.181,16.181,0,0,0,4.456-6.688,8.422,8.422,0,0,0,6.32-8.3a1.84,1.84,0,0,1-.328.032H5.728q-.384.016-.856.032l-.96.032-.976.032q-.488.016-.92.016-.208,0-.208-.192V-8.9Q1.808-9.088,2.016-9.088Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅓ)_c_3.svg': { 
		path: <path d="M2.016-8.768q.48,0,.992-.016l1-.032.912-.032q.424-.016.712-.032.1,0,.256-.016T6.24-8.92q.192-.008.392-.024a2.546,2.546,0,0,0,.344-.048,5.228,5.228,0,0,0,.48-1.408q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.3a1.484,1.484,0,0,1,1.04.272.954.954,0,0,1,.176.864A7.46,7.46,0,0,1,6.72-6.768,12.888,12.888,0,0,1,2.176-3.824q-.272.112-.352-.1l-.24-.528a.2.2,0,0,1-.008-.208.3.3,0,0,1,.136-.112A14.258,14.258,0,0,0,4.448-6.232,8.7,8.7,0,0,0,6.336-7.984,1.88,1.88,0,0,1,6-7.952H5.728q-.384.016-.856.032l-.96.032-.976.032q-.488.016-.92.016-.208,0-.208-.192v-.544Q1.808-8.768,2.016-8.768Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M8.656-10.88a7.346,7.346,0,0,1-.192.8l-.352.944a7.242,7.242,0,0,1-2.08,2.6,16.261,16.261,0,0,1-3.856,2.2.237.237,0,0,1-.352-.1l-.24-.528Q1.52-5.1,1.56-5.168a.323.323,0,0,1,.152-.112A11.831,11.831,0,0,0,6.24-8.208a2.536,2.536,0,0,1-.28.016H5.728q-.384.016-.856.032l-.96.032L2.936-8.1q-.488.016-.92.016-.208,0-.208-.192v-.544q0-.192.208-.192.48,0,.992-.016l1-.032.912-.032q.424-.016.712-.032.1,0,.256-.016T6.24-9.16q.192-.008.384-.024a2.537,2.537,0,0,0,.336-.048,4.5,4.5,0,0,0,.5-1.376q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.544a.17.17,0,0,1,.192-.192h5.3a1.518,1.518,0,0,1,1.048.272A.91.91,0,0,1,8.656-10.88Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅕ)_(ㅂ)_3.svg': { 
		path: <path d="M8.656-10.88a7.346,7.346,0,0,1-.192.8l-.352.944a7.242,7.242,0,0,1-2.08,2.6,16.261,16.261,0,0,1-3.856,2.2.237.237,0,0,1-.352-.1l-.24-.528Q1.52-5.1,1.56-5.168a.323.323,0,0,1,.152-.112A11.831,11.831,0,0,0,6.24-8.208a2.536,2.536,0,0,1-.28.016H5.728q-.384.016-.856.032l-.96.032L2.936-8.1q-.488.016-.92.016-.208,0-.208-.192v-.544q0-.192.208-.192.48,0,.992-.016l1-.032.912-.032q.424-.016.712-.032.1,0,.256-.016T6.24-9.16q.192-.008.384-.024a2.537,2.537,0,0,0,.336-.048,4.5,4.5,0,0,0,.5-1.376q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.544a.17.17,0,0,1,.192-.192h5.3a1.518,1.518,0,0,1,1.048.272A.91.91,0,0,1,8.656-10.88Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M8.656-10.88a7.346,7.346,0,0,1-.192.8l-.352.944a7.242,7.242,0,0,1-2.08,2.6,16.261,16.261,0,0,1-3.856,2.2.237.237,0,0,1-.352-.1l-.24-.528Q1.52-5.1,1.56-5.168a.323.323,0,0,1,.152-.112A11.831,11.831,0,0,0,6.24-8.208a2.536,2.536,0,0,1-.28.016H5.728q-.384.016-.856.032l-.96.032L2.936-8.1q-.488.016-.92.016-.208,0-.208-.192v-.544q0-.192.208-.192.48,0,.992-.016l1-.032.912-.032q.424-.016.712-.032.1,0,.256-.016T6.24-9.16q.192-.008.384-.024a2.537,2.537,0,0,0,.336-.048,4.5,4.5,0,0,0,.5-1.376q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.544a.17.17,0,0,1,.192-.192h5.3a1.518,1.518,0,0,1,1.048.272A.91.91,0,0,1,8.656-10.88Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅕ)_c_3.svg': { 
		path: <path d="M8.656-10.688l-.1.48a3.3,3.3,0,0,1-.128.464L8.08-8.8A8.2,8.2,0,0,1,5.92-6.016,14.036,14.036,0,0,1,2.176-3.824q-.272.112-.352-.1l-.24-.528a.2.2,0,0,1-.008-.208.3.3,0,0,1,.136-.112,14.126,14.126,0,0,0,2.68-1.424,8.888,8.888,0,0,0,1.864-1.7q-.16.016-.3.016H5.728q-.384.016-.856.032l-.96.032-.976.032q-.488.016-.92.016-.208,0-.208-.192V-8.5q0-.192.208-.192.48,0,.992-.016l1-.032.912-.032q.424-.016.712-.032.1,0,.248-.008t.336-.024l.376-.032q.192-.016.336-.032a5.635,5.635,0,0,0,.528-1.5q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192h5.3a1.484,1.484,0,0,1,1.04.272A.954.954,0,0,1,8.656-10.688Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M2.976-9.9H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032.032-.32.048-.6t.016-.52q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.88a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.576a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.272a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576,12.6,12.6,0,0,1-.128,1.968,13.45,13.45,0,0,1-.368,1.808.68.68,0,0,1-.088.176.144.144,0,0,1-.152.064l-.64-.048Q10.72-7.232,10.8-7.5q.08-.336.16-.752T11.1-9.1q-.272.016-.632.032t-.7.024l-.632.016q-.288.008-.384.008Q8.368-9.008,7.576-9L5.9-8.984q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544Q2.768-9.9,2.976-9.9Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅗ)_c_3.svg': { 
		path: <path d="M2.976-9.232H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032.032-.432.048-.824t.016-.7q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.88a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136V-12a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.272a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576q0,.48-.032,1.04t-.1,1.12q-.064.56-.152,1.072t-.168.88a.68.68,0,0,1-.088.176.144.144,0,0,1-.152.064l-.64-.048q-.272-.016-.192-.288a6.367,6.367,0,0,0,.144-.688q.064-.4.128-.848-.272.016-.632.032t-.712.024l-.632.016q-.28.008-.392.008-.384.016-1.176.024L5.9-8.312q-.88.008-1.688.008H2.976q-.208,0-.208-.192V-9.04Q2.768-9.232,2.976-9.232Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M2.624-12.256a.155.155,0,0,1,.088-.144.318.318,0,0,1,.136-.048H11.12a1.862,1.862,0,0,1,.608.08.835.835,0,0,1,.352.224.887.887,0,0,1,.2.4,2.654,2.654,0,0,1,.056.592,12.83,12.83,0,0,1-.12,1.936,13.369,13.369,0,0,1-.36,1.808.426.426,0,0,1-.072.16.176.176,0,0,1-.152.08l-.64-.064q-.272-.016-.192-.288.1-.384.176-.912.048-.352.1-.672h-.048q-.384.016-.848.048-.5.016-.9.032t-.552.016q-.384.016-1.168.016H5.888q-.9.016-1.7.016H2.96q-.224,0-.224-.192V-9.7q0-.192.224-.192H4.848q.528,0,1.128-.008T7.1-9.92h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.528-.016t.88-.016l.912-.032a2.243,2.243,0,0,1,.24-.016q.016-.16.032-.3.032-.464.032-.816,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.289.289,0,0,1-.136-.056.172.172,0,0,1-.088-.152Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M2.624-12.256a.155.155,0,0,1,.088-.144.318.318,0,0,1,.136-.048H11.12a1.862,1.862,0,0,1,.608.08.835.835,0,0,1,.352.224.887.887,0,0,1,.2.4,2.654,2.654,0,0,1,.056.592,12.83,12.83,0,0,1-.12,1.936,13.369,13.369,0,0,1-.36,1.808.426.426,0,0,1-.072.16.176.176,0,0,1-.152.08l-.64-.064q-.272-.016-.192-.288.1-.384.176-.912.048-.352.1-.672h-.048q-.384.016-.848.048-.5.016-.9.032t-.552.016q-.384.016-1.168.016H5.888q-.9.016-1.7.016H2.96q-.224,0-.224-.192V-9.7q0-.192.224-.192H4.848q.528,0,1.128-.008T7.1-9.92h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.528-.016t.88-.016l.912-.032a2.243,2.243,0,0,1,.24-.016q.016-.16.032-.3.032-.464.032-.816,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.289.289,0,0,1-.136-.056.172.172,0,0,1-.088-.152Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M2.624-12.256a.155.155,0,0,1,.088-.144.318.318,0,0,1,.136-.048H11.12a1.862,1.862,0,0,1,.608.08.835.835,0,0,1,.352.224.887.887,0,0,1,.2.4,2.654,2.654,0,0,1,.056.592,12.83,12.83,0,0,1-.12,1.936,13.369,13.369,0,0,1-.36,1.808.426.426,0,0,1-.072.16.176.176,0,0,1-.152.08l-.64-.064q-.272-.016-.192-.288.1-.384.176-.912.048-.352.1-.672h-.048q-.384.016-.848.048-.5.016-.9.032t-.552.016q-.384.016-1.168.016H5.888q-.9.016-1.7.016H2.96q-.224,0-.224-.192V-9.7q0-.192.224-.192H4.848q.528,0,1.128-.008T7.1-9.92h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.528-.016t.88-.016l.912-.032a2.243,2.243,0,0,1,.24-.016q.016-.16.032-.3.032-.464.032-.816,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.289.289,0,0,1-.136-.056.172.172,0,0,1-.088-.152Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M2.624-12.256a.155.155,0,0,1,.088-.144.318.318,0,0,1,.136-.048H11.12a1.862,1.862,0,0,1,.608.08.835.835,0,0,1,.352.224.887.887,0,0,1,.2.4,2.654,2.654,0,0,1,.056.592,12.83,12.83,0,0,1-.12,1.936,13.369,13.369,0,0,1-.36,1.808.426.426,0,0,1-.072.16.176.176,0,0,1-.152.08l-.64-.064q-.272-.016-.192-.288.1-.384.176-.912.048-.352.1-.672h-.048q-.384.016-.848.048-.5.016-.9.032t-.552.016q-.384.016-1.168.016H5.888q-.9.016-1.7.016H2.96q-.224,0-.224-.192V-9.7q0-.192.224-.192H4.848q.528,0,1.128-.008T7.1-9.92h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.528-.016t.88-.016l.912-.032a2.243,2.243,0,0,1,.24-.016q.016-.16.032-.3.032-.464.032-.816,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.289.289,0,0,1-.136-.056.172.172,0,0,1-.088-.152Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅛ)_c_3.svg': { 
		path: <path d="M2.608-11.968a.154.154,0,0,1,.1-.144.368.368,0,0,1,.144-.048H11.12a1.705,1.705,0,0,1,.608.088,1.017,1.017,0,0,1,.352.216.885.885,0,0,1,.2.392,2.46,2.46,0,0,1,.056.568q0,.5-.04,1.056t-.1,1.12q-.048.56-.144,1.072t-.176.864a.832.832,0,0,1-.088.184.12.12,0,0,1-.152.056l-.624-.032q-.272-.016-.192-.3a8.108,8.108,0,0,0,.176-.864q.048-.32.08-.656h-.048q-.384.016-.848.032-.5.032-.9.04T8.7-8.32q-.368,0-1.152.008T5.888-8.3q-.9.032-1.7.032H2.96q-.224,0-.224-.192v-.544q0-.192.224-.192H4.832q.544,0,1.136-.008T7.1-9.232h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.52-.008t.888-.024q.5-.016.9-.032a2.243,2.243,0,0,1,.24-.016q.032-.288.048-.576.032-.544.032-.944,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.333.333,0,0,1-.144-.056.16.16,0,0,1-.1-.136Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅜ)_2.svg': { 
		path: <path d="M2.416-11.872a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576,17.557,17.557,0,0,1-.152,2.68Q12-6.672,11.68-5.168H10.592q.144-.544.272-1.216t.224-1.392q-.288.032-.688.048L9.616-7.7q-.384.016-.7.024T8.5-7.664q-.256.016-.712.016t-1,.008L5.64-7.624l-1.16.016Q3.92-7.6,3.456-7.6H2.7Q2.5-7.6,2.5-7.792v-.544q0-.192.208-.192h.808q.488,0,1.056-.008l1.168-.016,1.12-.016q.52-.008.936-.008T8.4-8.592q.1,0,.432-.016t.76-.024q.424-.008.864-.024t.728-.048q.048-.624.072-1.16a7.055,7.055,0,0,0-.008-.9.558.558,0,0,0-.088-.28.443.443,0,0,0-.28-.056H2.656a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅜ)_c_3.svg': { 
		path: <path d="M11.264-10.832a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208V-12a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.464-.032,1.048T12.232-8.76q-.056.6-.144,1.176T11.9-6.544h-1.04q.1-.448.168-.968t.12-1.064q-.256.032-.624.048T9.8-8.5l-.648.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.44q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192h.808q.488,0,1.056-.008T6-9.376q.592,0,1.12-.008t.936-.008q.408,0,.6-.016.1,0,.408-.008t.7-.024l.792-.032q.4-.016.672-.032.032-.368.032-.7Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅠ)_(ㄹ)_3.svg': { 
		path: <path d="M11.264-11.04a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.464-.032,1.04T12.224-9q-.064.584-.152,1.16T11.888-6.8H10.864s.117-.581.16-.912.085-.667.128-1.008q-.272.032-.632.048t-.72.024l-.648.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.584q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032Q11.264-10.416,11.264-11.04Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅠ)_(ㅊ)_3.svg': { 
		path: <path d="M11.248-11.1a.463.463,0,0,0-.08-.32.433.433,0,0,0-.3-.08H2.848a.2.2,0,0,1-.224-.224v-.544a.2.2,0,0,1,.224-.224h8.24a1.332,1.332,0,0,1,1,.28,1.436,1.436,0,0,1,.248.952q0,.512-.04,1.112t-.12,1.224q-.064.64-.152,1.272l-.154.876H10.843l.149-.852.128-1.152a.178.178,0,0,1-.08.016q-.384,0-.848.032-.5.016-.9.024T8.72-8.7q-.368.032-1.152.04t-1.68.008q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.016t.9-.048q.48-.016.9-.016h.24q0-.192.016-.352Q11.248-10.64,11.248-11.1Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅠ)_(ㅋ)_3.svg': { 
		path: <path d="M11.248-10.8a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848q-.224,0-.224-.208v-.544q0-.208.224-.208h8.24a1.359,1.359,0,0,1,1,.272,1.372,1.372,0,0,1,.248.928q0,.512-.04,1.168t-.12,1.36q-.064.688-.152,1.336l-.169.972H10.834l.158-.98q.08-.624.144-1.248a.172.172,0,0,1-.1.032q-.384,0-.848.016-.5.032-.9.04t-.568.008q-.368.016-1.152.032t-1.68.016q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.024t.9-.04l.9-.032h.24l.016-.4Q11.248-10.32,11.248-10.8Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅠ)_(ㅎ)_3.svg': { 
		path: <path d="M11.248-11.1a.463.463,0,0,0-.08-.32.433.433,0,0,0-.3-.08H2.848a.2.2,0,0,1-.224-.224v-.544a.2.2,0,0,1,.224-.224h8.24a1.332,1.332,0,0,1,1,.28,1.436,1.436,0,0,1,.248.952q0,.512-.04,1.112t-.12,1.224q-.064.64-.152,1.272l-.148.876h-1.02l.136-.852.128-1.152a.178.178,0,0,1-.08.016q-.384,0-.848.032-.5.016-.9.024T8.72-8.7q-.368.032-1.152.04t-1.68.008q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.016t.9-.048q.48-.016.9-.016h.24q0-.192.016-.352Q11.248-10.64,11.248-11.1Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅠ)_c_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.112t-.1,1.3q-.064.664-.152,1.288t-.2,1.1H10.848q.1-.448.168-1.016t.136-1.176q-.272.032-.632.048T9.8-8.3l-.648.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.232q-.88.008-1.688.008H2.976q-.208,0-.208-.192V-8.96q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032Q11.264-10.16,11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅡ)_2.svg': { 
		path: <path d="M2.56-11.264a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.464a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.858.858,0,0,1,.176.392,3.941,3.941,0,0,1,.064.576q.016.432-.008,1.112T12.4-7.584q-.048.8-.12,1.68t-.16,1.728q-.088.848-.184,1.608t-.192,1.32H10.656q.08-.336.16-.84t.152-1.12q.072-.616.144-1.3t.12-1.392q-.24.032-.616.048t-.768.024l-.712.016q-.32.008-.432.008-.384.016-1.176.024l-1.68.016-1.7.016q-.808.008-1.24.008-.208,0-.208-.192V-6.48q0-.192.208-.192H3.72q.488,0,1.056-.008L5.944-6.7l1.12-.016Q7.584-6.72,8-6.72t.608-.016q.1,0,.448-.016t.776-.024q.424-.008.84-.024t.64-.048q.064-1.024.088-1.9t-.008-1.408a.443.443,0,0,0-.08-.28.484.484,0,0,0-.288-.056H2.8a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_(ㅡ)_c_3.svg': { 
		path: <path d="M2.64-12a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.272a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576q0,.464-.032,1.144T12.248-8.36q-.056.728-.128,1.4t-.152,1.1H10.88q.08-.368.152-.992t.136-1.328q-.272.032-.64.048T9.8-8.1l-.648.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.04q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032.032-.56.048-1.032t.016-.76q0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.88a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_1.svg': { 
		path: <path d="M10.272-5.2V-7.248q0-.224-.072-.28a.527.527,0,0,0-.3-.056h-6a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.576a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H10.16a1.273,1.273,0,0,1,.984.28,1.477,1.477,0,0,1,.232.936V-1.44a.2.2,0,0,1-.224.224H10.5a.2.2,0,0,1-.224-.224V-4.288H4.144q-.224,0-.224-.176v-.528A.184.184,0,0,1,4.128-5.2Z" />, 
		strokes: [
			<path   d="M807.507,2167.427h6.571a1.463,1.463,0,0,1,.8.306,2.078,2.078,0,0,1,.382.758v6.313" transform="translate(0 1)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M807.856,2172.168h6.484" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅋ_hd_(ㄱ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.04,1.088t-.1,1.232q-.064.624-.152,1.224t-.2,1.064H10.848q.1-.448.168-.992t.136-1.12q-.272.032-.632.048t-.72.024l-.648.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.344q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192h.808q.488,0,1.056-.008T6-9.28q.592,0,1.12-.008T8.056-9.3q.408,0,.6-.016.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032Q11.264-10.176,11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㄴ)_3.svg': { 
		path: <path d="M11.264-10.672a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848A.184.184,0,0,1,2.64-11.3v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.432-.056,1.1T12.16-8.32q-.08.736-.2,1.424T11.728-5.76H10.72a8.735,8.735,0,0,0,.2-1.064q.088-.648.168-1.352-.272.016-.632.032t-.7.024L9.128-8.1q-.28.008-.376.008-.384.016-1.176.024L5.9-8.056q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192H4.84q.568,0,1.16-.008L7.12-9q.528-.008.936-.008t.6-.016q.1,0,.392-.008t.68-.024l.784-.032q.4-.016.672-.032.032-.464.056-.864T11.264-10.672Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㄷ)_3.svg': { 
		path: <path d="M11.248-10.8a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848q-.224,0-.224-.208v-.544q0-.208.224-.208h8.24a1.359,1.359,0,0,1,1,.272,1.372,1.372,0,0,1,.248.928q0,.512-.04,1.168t-.12,1.36q-.064.688-.152,1.336l-.171.973H10.831l.161-.98q.08-.624.144-1.248a.172.172,0,0,1-.1.032q-.384,0-.848.016-.5.032-.9.04t-.568.008q-.368.016-1.152.032t-1.68.016q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.024t.9-.04l.9-.032h.24l.016-.4Q11.248-10.32,11.248-10.8Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㄹ)_3.svg': { 
		path: <path d="M11.264-11.1a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.464-.032,1.048t-.1,1.184q-.064.6-.152,1.184T11.888-6.8H10.864q.1-.432.16-.944t.128-1.04q-.272.032-.632.048t-.72.024L9.152-8.7q-.288.008-.4.008-.384.016-1.176.024L5.9-8.648q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032Q11.264-10.48,11.264-11.1Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅁ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.112t-.1,1.3q-.064.664-.152,1.288t-.2,1.1H10.848q.1-.448.168-1.016t.136-1.176q-.272.032-.632.048T9.8-8.3l-.648.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.232q-.88.008-1.688.008H2.976q-.208,0-.208-.192V-8.96q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032Q11.264-10.16,11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅅ)_3.svg': { 
		path: <path d="M11.264-10.688a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.416-.048,1.088t-.12,1.392q-.072.72-.176,1.408t-.216,1.136H10.768a8.039,8.039,0,0,0,.192-1.032q.08-.616.16-1.288-.272.016-.632.032t-.712.024l-.632.016q-.28.008-.392.008-.384.016-1.176.024L5.9-8.024q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.392-.008t.68-.024l.784-.032q.4-.016.672-.032.032-.464.056-.88T11.264-10.688Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅇ)_3.svg': { 
		path: <path d="M11.264-10.816a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848a.184.184,0,0,1-.208-.208v-.544a.184.184,0,0,1,.208-.208H11.12a1.356,1.356,0,0,1,.992.264,1.431,1.431,0,0,1,.24.952q0,.48-.032,1.112t-.1,1.3q-.064.664-.152,1.3t-.2,1.12H10.848q.1-.432.168-.976t.12-1.136q-.272.032-.632.048t-.712.024l-.64.016q-.288.008-.4.008-.384.016-1.176.024L5.9-8.12q-.88.008-1.688.008H2.976q-.208,0-.208-.192v-.544q0-.192.208-.192H4.84q.568,0,1.16-.008l1.12-.016q.528-.008.936-.008t.6-.016q.1,0,.4-.008t.7-.024l.792-.032q.4-.016.672-.032.032-.464.04-.88T11.264-10.816Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅊ)_3.svg': { 
		path: <path d="M11.248-11.1a.463.463,0,0,0-.08-.32.433.433,0,0,0-.3-.08H2.848a.2.2,0,0,1-.224-.224v-.544a.2.2,0,0,1,.224-.224h8.24a1.332,1.332,0,0,1,1,.28,1.436,1.436,0,0,1,.248.952q0,.512-.04,1.112t-.12,1.224q-.064.64-.152,1.272l-.161.956H10.828l.164-.932.128-1.152a.178.178,0,0,1-.08.016q-.384,0-.848.032-.5.016-.9.024T8.72-8.7q-.368.032-1.152.04t-1.68.008q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.016t.9-.048q.48-.016.9-.016h.24q0-.192.016-.352Q11.248-10.64,11.248-11.1Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅋ)_3.svg': { 
		path: <path d="M11.248-10.8a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848q-.224,0-.224-.208v-.544q0-.208.224-.208h8.24a1.359,1.359,0,0,1,1,.272,1.372,1.372,0,0,1,.248.928q0,.512-.04,1.168t-.12,1.36q-.064.688-.152,1.336l-.163.972H10.839l.153-.98q.08-.624.144-1.248a.172.172,0,0,1-.1.032q-.384,0-.848.016-.5.032-.9.04t-.568.008q-.368.016-1.152.032t-1.68.016q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.024t.9-.04l.9-.032h.24l.016-.4Q11.248-10.32,11.248-10.8Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅌ)_3.svg': { 
		path: <path d="M11.248-11.1a.463.463,0,0,0-.08-.32.433.433,0,0,0-.3-.08H2.848a.2.2,0,0,1-.224-.224v-.544a.2.2,0,0,1,.224-.224h8.24a1.332,1.332,0,0,1,1,.28,1.436,1.436,0,0,1,.248.952q0,.512-.04,1.112t-.12,1.224q-.064.64-.152,1.272l-.156.876h-1.02l.144-.852.128-1.152a.178.178,0,0,1-.08.016q-.384,0-.848.032-.5.016-.9.024T8.72-8.7q-.368.032-1.152.04t-1.68.008q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.016t.9-.048q.48-.016.9-.016h.24q0-.192.016-.352Q11.248-10.64,11.248-11.1Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅍ)_3.svg': { 
		path: <path d="M11.248-10.8a.5.5,0,0,0-.08-.328.406.406,0,0,0-.3-.088H2.848q-.224,0-.224-.208v-.544q0-.208.224-.208h8.24a1.359,1.359,0,0,1,1,.272,1.372,1.372,0,0,1,.248.928q0,.512-.04,1.168t-.12,1.36q-.064.688-.152,1.336l-.171.974-1.026,0,.164-.98q.08-.624.144-1.248a.172.172,0,0,1-.1.032q-.384,0-.848.016-.5.032-.9.04t-.568.008q-.368.016-1.152.032t-1.68.016q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.024t.9-.04l.9-.032h.24l.016-.4Q11.248-10.32,11.248-10.8Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_(ㅎ)_3.svg': { 
		path: <path d="M11.248-11.1a.463.463,0,0,0-.08-.32.433.433,0,0,0-.3-.08H2.848a.2.2,0,0,1-.224-.224v-.544a.2.2,0,0,1,.224-.224h8.24a1.332,1.332,0,0,1,1,.28,1.436,1.436,0,0,1,.248.952q0,.512-.04,1.112t-.12,1.224q-.064.64-.152,1.272l-.16.956H10.836l.156-.932.128-1.152a.178.178,0,0,1-.08.016q-.384,0-.848.032-.5.016-.9.024T8.72-8.7q-.368.032-1.152.04t-1.68.008q-.88.016-1.68.016H2.96q-.208,0-.208-.192v-.544a.184.184,0,0,1,.208-.208H5.984q.592,0,1.12-.016h.944q.4,0,.592-.016.112,0,.52-.016t.9-.048q.48-.016.9-.016h.24q0-.192.016-.352Q11.248-10.64,11.248-11.1Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hd_2.svg': { 
		path: <path d="M2.416-11.872a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048H11.12a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.924.924,0,0,1,.184.392,2.568,2.568,0,0,1,.056.576,18.4,18.4,0,0,1-.152,2.728q-.184,1.48-.5,2.984H10.592q.144-.56.272-1.248t.224-1.408q-.272.032-.648.048L9.7-7.648q-.36.016-.648.024t-.4.008Q8.4-7.6,7.936-7.6T6.9-7.592l-1.192.016L4.52-7.56q-.568.008-1.048.008H2.7q-.208,0-.208-.192v-.544q0-.192.208-.192h.824q.5,0,1.088-.008l1.2-.016,1.16-.016q.544-.008.968-.008t.616-.016q.1,0,.408-.016t.712-.024q.4-.008.816-.024t.688-.048q.048-.64.072-1.192a7.282,7.282,0,0,0-.008-.92.558.558,0,0,0-.088-.28.443.443,0,0,0-.28-.056H2.656a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hu_(ㅊ)_3.svg': { 
		path: <path d="M2.624-12.256a.155.155,0,0,1,.088-.144.318.318,0,0,1,.136-.048H11.12a1.862,1.862,0,0,1,.608.08.835.835,0,0,1,.352.224.887.887,0,0,1,.2.4,2.654,2.654,0,0,1,.056.592,12.83,12.83,0,0,1-.12,1.936,13.369,13.369,0,0,1-.36,1.808.426.426,0,0,1-.072.16.176.176,0,0,1-.152.08l-.64-.064q-.272-.016-.192-.288.1-.384.176-.912.048-.352.1-.672h-.048q-.384.016-.848.048-.5.016-.9.032t-.552.016q-.384.016-1.168.016H5.888q-.9.016-1.7.016H2.96q-.224,0-.224-.192V-9.7q0-.192.224-.192H4.848q.528,0,1.128-.008T7.1-9.92h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.528-.016t.88-.016l.912-.032a2.243,2.243,0,0,1,.24-.016q.016-.16.032-.3.032-.464.032-.816,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.289.289,0,0,1-.136-.056.172.172,0,0,1-.088-.152Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hu_(ㅎ)_3.svg': { 
		path: <path d="M2.624-12.256a.155.155,0,0,1,.088-.144.318.318,0,0,1,.136-.048H11.12a1.862,1.862,0,0,1,.608.08.835.835,0,0,1,.352.224.887.887,0,0,1,.2.4,2.654,2.654,0,0,1,.056.592,12.83,12.83,0,0,1-.12,1.936,13.369,13.369,0,0,1-.36,1.808.426.426,0,0,1-.072.16.176.176,0,0,1-.152.08l-.64-.064q-.272-.016-.192-.288.1-.384.176-.912.048-.352.1-.672h-.048q-.384.016-.848.048-.5.016-.9.032t-.552.016q-.384.016-1.168.016H5.888q-.9.016-1.7.016H2.96q-.224,0-.224-.192V-9.7q0-.192.224-.192H4.848q.528,0,1.128-.008T7.1-9.92h.928a3.953,3.953,0,0,0,.592-.032q.112,0,.528-.016t.88-.016l.912-.032a2.243,2.243,0,0,1,.24-.016q.016-.16.032-.3.032-.464.032-.816,0-.224-.072-.28a.527.527,0,0,0-.3-.056H2.848a.289.289,0,0,1-.136-.056.172.172,0,0,1-.088-.152Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_hu_2.svg': { 
		path: <path d="M2.864-7.5h.824q.5,0,1.1-.008L6-7.528l1.176-.016L8.16-7.56q.432-.008.624-.024.1,0,.424-.016t.744-.032l.824-.032q.408-.016.664-.048.032-.7.032-1.336t-.032-1.1a.443.443,0,0,0-.08-.28.484.484,0,0,0-.288-.056H2.688a.278.278,0,0,1-.16-.056.163.163,0,0,1-.08-.136v-.576a.157.157,0,0,1,.08-.144.309.309,0,0,1,.16-.048h8.624a1.938,1.938,0,0,1,.616.08.8.8,0,0,1,.36.232.858.858,0,0,1,.176.392,3.941,3.941,0,0,1,.064.576q.032.768.016,1.64t-.072,1.752q-.056.88-.152,1.72T12.1-3.5q-.048.272-.24.24l-.64-.048q-.272-.016-.192-.288A13.142,13.142,0,0,0,11.248-5q.1-.84.16-1.768l-.624.04q-.384.024-.768.04t-.7.024q-.32.008-.432.008-.256,0-.728.016t-1.04.024l-1.2.016L4.7-6.584q-.584.008-1.064.008H2.864q-.208,0-.208-.192v-.544Q2.656-7.5,2.864-7.5Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_v_(ㄹ)_3.svg': { 
		path: <path d="M2.016-9.088q.48,0,1.016-.016L4.08-9.136l.968-.032q.456-.016.744-.032.1,0,.272-.016T6.44-9.24q.2-.008.4-.024a2.545,2.545,0,0,0,.344-.048,5.146,5.146,0,0,0,.432-1.3q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.544a.17.17,0,0,1,.192-.192H7.6a1.518,1.518,0,0,1,1.048.272.91.91,0,0,1,.168.864,7.293,7.293,0,0,1-.632,1.944A6.37,6.37,0,0,1,6.96-7.264,10.549,10.549,0,0,1,5-5.76,21.986,21.986,0,0,1,2.176-4.336a.237.237,0,0,1-.352-.1l-.24-.528Q1.52-5.1,1.56-5.168a.323.323,0,0,1,.152-.112A12.272,12.272,0,0,0,6.5-8.3a1.88,1.88,0,0,1-.336.032H5.888q-.384.016-.88.032l-1.016.032L2.96-8.176q-.512.016-.944.016-.208,0-.208-.192V-8.9Q1.808-9.088,2.016-9.088Z" />, 
		strokes: [
			<path   d="M805.818,2165.443s5.924-1.023,6.514,0,.218,5.366-7.043,6.758" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M805.63,2168.345l6.093-.184" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅋ_v_2.svg': { 
		path: <path d="M1.6-7.36q.48,0,.992-.016l1-.032L4.5-7.44q.424-.016.712-.032.1,0,.264-.016t.36-.024q.192-.008.392-.024a2.546,2.546,0,0,0,.344-.048A10.056,10.056,0,0,0,7.1-9.936a.369.369,0,0,0-.064-.328.469.469,0,0,0-.32-.088H1.84q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192H7.024a1.3,1.3,0,0,1,.992.28,1.215,1.215,0,0,1,.208.872A9.621,9.621,0,0,1,7.68-7.48,10.363,10.363,0,0,1,6.392-5,12.078,12.078,0,0,1,4.408-2.816,14.091,14.091,0,0,1,1.776-1.024q-.208.112-.3-.048l-.352-.56q-.112-.192.064-.272A13.027,13.027,0,0,0,4.168-4a9.667,9.667,0,0,0,1.96-2.592,4.234,4.234,0,0,1-.448.04q-.224.008-.368.008-.384.016-.856.032L3.5-6.48l-.976.032q-.488.016-.92.016-.208,0-.208-.192v-.544Q1.392-7.36,1.6-7.36Z" />, 
		strokes: [
		] 
	},
	'_ㅋ_v_c_3.svg': { 
		path: <path d="M2.016-8.688q.48,0,1.016-.016L4.08-8.736l.968-.032q.456-.016.744-.032.1,0,.256-.016T6.4-8.84q.192-.008.384-.024a2.537,2.537,0,0,0,.336-.048,5.242,5.242,0,0,0,.3-.72,6.738,6.738,0,0,0,.2-.768q.1-.464-.384-.464H2.144q-.192,0-.192-.208v-.56a.17.17,0,0,1,.192-.192H7.6a1.484,1.484,0,0,1,1.04.272.954.954,0,0,1,.176.864,7.7,7.7,0,0,1-.664,2.1,7.087,7.087,0,0,1-1.28,1.808A10.335,10.335,0,0,1,4.9-5.208,18.781,18.781,0,0,1,2.176-3.824q-.272.112-.352-.1l-.24-.528a.2.2,0,0,1-.008-.208.3.3,0,0,1,.136-.112,17.554,17.554,0,0,0,2.88-1.464A7.776,7.776,0,0,0,6.48-7.9a1.84,1.84,0,0,1-.328.032H5.888q-.384.016-.88.032l-1.016.032L2.96-7.776q-.512.016-.944.016-.208,0-.208-.192V-8.5Q1.808-8.688,2.016-8.688Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅏ)_(ㅎ)_3.svg': { 
		path: <path d="M9.152-5.792a.267.267,0,0,1-.032.2.444.444,0,0,1-.192.1q-.656.112-1.384.224t-1.5.16q-.752.032-1.528.072t-1.5.04a1.324,1.324,0,0,1-.992-.344,1.542,1.542,0,0,1-.256-.984v-4.464a1.207,1.207,0,0,1,.248-.9A1.434,1.434,0,0,1,2.96-11.9H7.7q.208,0,.208.192v.576q0,.192-.208.192H3.184q-.24,0-.288.056a.547.547,0,0,0-.048.3v1.6H4.4q.48,0,1.008-.016.48-.032,1.008-.04t.992-.04a.176.176,0,0,1,.168.048.213.213,0,0,1,.056.144l.016.528q0,.176-.208.208-.96.08-2.008.1t-1.9-.008a5.431,5.431,0,0,0-.592,0h-.1V-6.4a.438.438,0,0,0,.1.328.469.469,0,0,0,.32.088,23.273,23.273,0,0,0,2.792-.072,24.867,24.867,0,0,0,2.7-.376q.256-.032.3.192Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅏ)_c_3.svg': { 
		path: <path d="M7.808-7.824q-.464.032-1.048.056t-1.168.04q-.584.016-1.144.016t-.976-.016H2.8v1.936a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088q.7.016,1.48-.008t1.552-.08q.776-.056,1.52-.144T9.12-5.824q.272-.048.32.176l.08.464a.234.234,0,0,1-.016.2.357.357,0,0,1-.192.088q-.656.128-1.456.232T6.208-4.5q-.848.056-1.688.088T2.944-4.4a1.288,1.288,0,0,1-.984-.328A1.507,1.507,0,0,1,1.7-5.712V-10.7a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H8.08q.208,0,.208.192v.576q0,.192-.208.192H3.12q-.224,0-.272.056a.547.547,0,0,0-.048.3v1.856H4.416q.544,0,1.136-.016L6.728-8.7q.584-.016,1.064-.048a.155.155,0,0,1,.152.048A.213.213,0,0,1,8-8.56l.016.544Q8.016-7.856,7.808-7.824Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅑ)_c_3.svg': { 
		path: <path d="M9.5-5.184q.048.16-.016.216A.46.46,0,0,1,9.3-4.88q-.656.128-1.456.232t-1.632.136q-.864.08-1.7.1T2.944-4.4a1.279,1.279,0,0,1-.984-.312A1.446,1.446,0,0,1,1.68-5.7v-4.992a1.108,1.108,0,0,1,.272-.888,1.59,1.59,0,0,1,.96-.216H8.048q.208,0,.208.192v.56a.184.184,0,0,1-.208.208H3.1q-.224,0-.264.056a.59.59,0,0,0-.04.28V-8.64H4.4q.56,0,1.152-.016l1.16-.032Q7.3-8.7,7.792-8.736a.135.135,0,0,1,.136.048.213.213,0,0,1,.056.144L8-8q0,.16-.208.192-.464.016-1.048.048t-1.16.048Q4.992-7.7,4.432-7.7t-.96-.016a5.455,5.455,0,0,0-.592,0H2.8v1.92a.535.535,0,0,0,.08.336.384.384,0,0,0,.3.1q.688.016,1.472-.016t1.552-.08Q6.96-5.52,7.72-5.6T9.1-5.808q.272-.064.32.16Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅓ)_(ㄹ)_3.svg': { 
		path: <path d="M9.152-5.792a.267.267,0,0,1-.032.2.444.444,0,0,1-.192.1q-.656.112-1.384.224t-1.5.16q-.752.032-1.528.072t-1.5.04a1.324,1.324,0,0,1-.992-.344,1.542,1.542,0,0,1-.256-.984v-4.464a1.207,1.207,0,0,1,.248-.9A1.434,1.434,0,0,1,2.96-11.9H7.7q.208,0,.208.192v.576q0,.192-.208.192H3.184q-.24,0-.288.056a.547.547,0,0,0-.048.3v1.6H4.4q.48,0,1.008-.016.48-.032,1.008-.04t.992-.04a.176.176,0,0,1,.168.048.213.213,0,0,1,.056.144l.016.528q0,.176-.208.208-.96.08-2.008.1t-1.9-.008a5.431,5.431,0,0,0-.592,0h-.1V-6.4a.438.438,0,0,0,.1.328.469.469,0,0,0,.32.088,23.273,23.273,0,0,0,2.792-.072,24.867,24.867,0,0,0,2.7-.376q.256-.032.3.192Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅓ)_(ㅊ)_3.svg': { 
		path: <path d="M9.152-5.792a.267.267,0,0,1-.032.2.444.444,0,0,1-.192.1q-.656.112-1.384.224t-1.5.16q-.752.032-1.528.072t-1.5.04a1.324,1.324,0,0,1-.992-.344,1.542,1.542,0,0,1-.256-.984v-4.464a1.207,1.207,0,0,1,.248-.9A1.434,1.434,0,0,1,2.96-11.9H7.7q.208,0,.208.192v.576q0,.192-.208.192H3.184q-.24,0-.288.056a.547.547,0,0,0-.048.3v1.6H4.4q.48,0,1.008-.016.48-.032,1.008-.04t.992-.04a.176.176,0,0,1,.168.048.213.213,0,0,1,.056.144l.016.528q0,.176-.208.208-.96.08-2.008.1t-1.9-.008a5.431,5.431,0,0,0-.592,0h-.1V-6.4a.438.438,0,0,0,.1.328.469.469,0,0,0,.32.088,23.273,23.273,0,0,0,2.792-.072,24.867,24.867,0,0,0,2.7-.376q.256-.032.3.192Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅓ)_(ㅎ)_3.svg': { 
		path: <path d="M9.152-5.792a.267.267,0,0,1-.032.2.444.444,0,0,1-.192.1q-.656.112-1.384.224t-1.5.16q-.752.032-1.528.072t-1.5.04a1.324,1.324,0,0,1-.992-.344,1.542,1.542,0,0,1-.256-.984v-4.464a1.207,1.207,0,0,1,.248-.9A1.434,1.434,0,0,1,2.96-11.9H7.7q.208,0,.208.192v.576q0,.192-.208.192H3.184q-.24,0-.288.056a.547.547,0,0,0-.048.3v1.6H4.4q.48,0,1.008-.016.48-.032,1.008-.04t.992-.04a.176.176,0,0,1,.168.048.213.213,0,0,1,.056.144l.016.528q0,.176-.208.208-.96.08-2.008.1t-1.9-.008a5.431,5.431,0,0,0-.592,0h-.1V-6.4a.438.438,0,0,0,.1.328.469.469,0,0,0,.32.088,23.273,23.273,0,0,0,2.792-.072,24.867,24.867,0,0,0,2.7-.376q.256-.032.3.192Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅓ)_c_3.svg': { 
		path: <path d="M7.456-7.824q-.944.08-2,.1t-1.9-.008H2.88v1.936a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088A25.971,25.971,0,0,0,6.08-5.464q1.392-.12,2.688-.36.272-.048.32.176l.08.464a.234.234,0,0,1-.016.2A.357.357,0,0,1,8.96-4.9q-.656.128-1.384.232t-1.5.16q-.768.056-1.544.088T3.024-4.4a1.288,1.288,0,0,1-.984-.328,1.507,1.507,0,0,1-.264-.984V-10.7a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H7.728q.208,0,.208.192v.576q0,.192-.208.192H3.2q-.224,0-.272.056a.547.547,0,0,0-.048.3v1.856H4.432q.48,0,.984-.016L6.44-8.7q.52-.016,1-.048a.155.155,0,0,1,.152.048.213.213,0,0,1,.056.144l.016.544Q7.664-7.856,7.456-7.824Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅕ)_(ㄴ)_3.svg': { 
		path: <path d="M7.456-7.28q-.944.08-2,.1t-1.9-.008H2.88V-5.1a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088Q4.7-4.656,6.1-4.736t2.672-.256q.272-.048.32.176l.08.464a.234.234,0,0,1-.016.2.357.357,0,0,1-.192.088q-1.3.208-2.808.3t-3.128.048a1.352,1.352,0,0,1-.984-.336,1.473,1.473,0,0,1-.264-.976v-5.344a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H7.728q.208,0,.208.192v.576q0,.192-.208.192H3.2q-.224,0-.272.056a.547.547,0,0,0-.048.3v2.064H4.432q.48,0,.984-.016L6.44-8.16q.52-.016,1-.048a.155.155,0,0,1,.152.048.213.213,0,0,1,.056.144l.016.544Q7.664-7.312,7.456-7.28Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅕ)_c_3.svg': { 
		path: <path d="M9.152-5.184a.3.3,0,0,1-.032.216.357.357,0,0,1-.192.088q-.656.128-1.384.232a14.986,14.986,0,0,1-1.5.136q-.752.08-1.528.1t-1.5.016a1.245,1.245,0,0,1-.992-.312A1.542,1.542,0,0,1,1.776-5.7v-4.992a1.177,1.177,0,0,1,.248-.888,1.475,1.475,0,0,1,.936-.216H7.7q.208,0,.208.192v.56a.184.184,0,0,1-.208.208H3.184q-.24,0-.288.056a.5.5,0,0,0-.048.28V-8.64H4.4q.48,0,1.008-.016l1.008-.032q.528-.016.992-.048a.176.176,0,0,1,.168.048.213.213,0,0,1,.056.144L7.648-8q0,.16-.208.192-.96.08-2.008.1t-1.9-.008a5.431,5.431,0,0,0-.592,0h-.1v1.92a.468.468,0,0,0,.1.336.441.441,0,0,0,.32.1q1.392.016,2.792-.1a26.339,26.339,0,0,0,2.7-.352q.256-.064.3.16Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅗ)_c_3.svg': { 
		path: <path d="M12.416-6.624q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.552a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-7.9a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.1h8.064q.224,0,.224.176v.528a.184.184,0,0,1-.208.208H3.808v1.136a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅛ)_2.svg': { 
		path: <path d="M12.576-4.416q0,.224-.208.224H3.728a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-5.2a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.528a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-8.4h8.416q.224,0,.224.176v.544a.184.184,0,0,1-.208.208H3.616v1.9a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.368a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅛ)_c_3.svg': { 
		path: <path d="M12.4-6.624q0,.224-.208.224H3.936a1.493,1.493,0,0,1-.984-.232A1.21,1.21,0,0,1,2.7-7.52v-3.568a1.183,1.183,0,0,1,.248-.872,1.493,1.493,0,0,1,.984-.232h8.128q.224,0,.224.192v.5a.2.2,0,0,1-.224.224H4.192a.376.376,0,0,0-.3.088.506.506,0,0,0-.072.312v1.12H11.84q.224,0,.224.176v.528a.184.184,0,0,1-.208.208H3.824v1.136A.506.506,0,0,0,3.9-7.4a.376.376,0,0,0,.3.088h8q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅜ)_(ㄹ)_3.svg': { 
		path: <path d="M12.1-9.616a.184.184,0,0,1-.208.208H3.808v.928a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.136a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-7.9a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v.9h8.064q.224,0,.224.176Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅜ)_(ㅌ)_3.svg': { 
		path: <path d="M12.1-9.616a.184.184,0,0,1-.208.208H3.808v.928a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.136a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-7.9a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v.9h8.064q.224,0,.224.176Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅜ)_(ㅎ)_3.svg': { 
		path: <path d="M12.1-9.616a.184.184,0,0,1-.208.208H3.808v.928a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.136a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-7.9a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v.9h8.064q.224,0,.224.176Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅠ)_c_3.svg': { 
		path: <path d="M12.4-6.624q0,.224-.208.224H3.936a1.493,1.493,0,0,1-.984-.232A1.21,1.21,0,0,1,2.7-7.52v-3.568a1.183,1.183,0,0,1,.248-.872,1.493,1.493,0,0,1,.984-.232h8.128q.224,0,.224.192v.5a.2.2,0,0,1-.224.224H4.192a.376.376,0,0,0-.3.088.506.506,0,0,0-.072.312v1.12H11.84q.224,0,.224.176v.528a.184.184,0,0,1-.208.208H3.824v1.136A.506.506,0,0,0,3.9-7.4a.376.376,0,0,0,.3.088h8q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅡ)_c_3.svg': { 
		path: <path d="M12.1-8.976a.184.184,0,0,1-.208.208H3.808v1.232a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.744a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-7.9a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.2h8.064q.224,0,.224.176Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_(ㅣ)_c_3.svg': { 
		path: <path d="M7.984-7.824q-.352.032-.9.048t-1.16.024L4.72-7.736q-.592.008-1.04.008H2.928v1.936a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088q.7.016,1.464-.008t1.512-.08q.752-.056,1.48-.144t1.368-.216q.272-.048.32.176l.08.464a.234.234,0,0,1-.016.2.357.357,0,0,1-.192.088q-.656.128-1.44.232T6.28-4.5q-.824.056-1.648.088T3.072-4.4a1.288,1.288,0,0,1-.984-.328,1.507,1.507,0,0,1-.264-.984V-10.7a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H8.1q.208,0,.208.192v.576q0,.192-.208.192H3.248q-.224,0-.272.056a.547.547,0,0,0-.048.3v1.856h1.76q.576,0,1.176-.016L7.016-8.7q.552-.016.952-.048.208,0,.208.192l.016.544Q8.192-7.856,7.984-7.824Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_1.svg': { 
		path: <path d="M4.64-4.432V-2.8a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1H11.3a.184.184,0,0,1,.208.208v.5q0,.224-.208.224H4.736a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9V-7.264a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h6.288a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-6a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.712h6.288q.224,0,.224.176v.528a.184.184,0,0,1-.208.208Z" />, 
		strokes: [
			<path   d="M807.482,2169.192h7.787" transform="translate(0 -0.662)" fill="none" stroke="#676767" stroke-width="2"/>,
			<path   d="M0,0H6.588" transform="translate(808.635 2172.5)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.56,2168.533s-.9.518-.937,3.175v.946a4.784,4.784,0,0,0,.444,1.772.6.6,0,0,0,.242.464,1.712,1.712,0,0,0,.721.162h6.57" transform="translate(0.027)" fill="none" stroke="#404040" stroke-width="2"/>,
		] 
	},
	'_ㅌ_hd_(ㅈ)_3.svg': { 
		path: <path d="M12.4-6.624q0,.224-.208.224H3.936a1.493,1.493,0,0,1-.984-.232A1.21,1.21,0,0,1,2.7-7.52v-3.568a1.183,1.183,0,0,1,.248-.872,1.493,1.493,0,0,1,.984-.232h8.128q.224,0,.224.192v.5a.2.2,0,0,1-.224.224H4.192a.376.376,0,0,0-.3.088.506.506,0,0,0-.072.312v1.12H11.84q.224,0,.224.176v.528a.184.184,0,0,1-.208.208H3.824v1.136A.506.506,0,0,0,3.9-7.4a.376.376,0,0,0,.3.088h8q.208,0,.208.224Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_hd_(ㅊ)_3.svg': { 
		path: <path d="M12.4-7.36q0,.224-.208.224H3.936a1.493,1.493,0,0,1-.984-.232,1.238,1.238,0,0,1-.248-.9v-3.12a1.238,1.238,0,0,1,.248-.9,1.493,1.493,0,0,1,.984-.232h8.128q.224,0,.224.192v.512a.2.2,0,0,1-.224.224H4.192a.376.376,0,0,0-.3.088.54.54,0,0,0-.072.328v.88H11.84q.224,0,.224.176V-9.6a.184.184,0,0,1-.208.208H3.824v.912a.581.581,0,0,0,.072.336.357.357,0,0,0,.3.1h8a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_hd_(ㅌ)_3.svg': { 
		path: <path d="M12.4-7.36q0,.224-.208.224H3.936a1.493,1.493,0,0,1-.984-.232,1.238,1.238,0,0,1-.248-.9v-3.12a1.238,1.238,0,0,1,.248-.9,1.493,1.493,0,0,1,.984-.232h8.128q.224,0,.224.192v.512a.2.2,0,0,1-.224.224H4.192a.376.376,0,0,0-.3.088.54.54,0,0,0-.072.328v.88H11.84q.224,0,.224.176V-9.6a.184.184,0,0,1-.208.208H3.824v.912a.581.581,0,0,0,.072.336.357.357,0,0,0,.3.1h8a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_hd_2.svg': { 
		path: <path d="M12.576-5.536q0,.224-.208.224H3.728a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-4.56a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.528a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-9.2h8.416q.224,0,.224.176v.544a.184.184,0,0,1-.208.208H3.616v1.584a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.368a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_hd_c_3.svg': { 
		path: <path d="M12.1-9.072a.184.184,0,0,1-.208.208H3.808v1.136a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.016a.184.184,0,0,1,.208.208v.48q0,.224-.208.224H3.92a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-3.552a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232H12.1a.184.184,0,0,1,.208.208v.5q0,.224-.208.224h-7.9a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328v1.1h8.064q.224,0,.224.176Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_hu_(ㅌ)_3.svg': { 
		path: <path d="M12.4-7.232q0,.224-.208.224H3.936a1.534,1.534,0,0,1-.984-.224,1.179,1.179,0,0,1-.248-.88v-3.28a1.207,1.207,0,0,1,.248-.9,1.534,1.534,0,0,1,.984-.224h8.128q.224,0,.224.208v.48a.2.2,0,0,1-.224.224H4.192a.4.4,0,0,0-.3.08.5.5,0,0,0-.072.32v.96H11.84q.224,0,.224.192v.512a.184.184,0,0,1-.208.208H3.824V-8.3a.506.506,0,0,0,.072.312.376.376,0,0,0,.3.088h8A.184.184,0,0,1,12.4-7.7Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_hu_2.svg': { 
		path: <path d="M12.576-4.416q0,.224-.208.224H3.728a1.464,1.464,0,0,1-.96-.232,1.212,1.212,0,0,1-.256-.9v-5.2a1.212,1.212,0,0,1,.256-.9,1.464,1.464,0,0,1,.96-.232h8.528a.184.184,0,0,1,.208.208v.528q0,.224-.208.224H4a.406.406,0,0,0-.3.088.5.5,0,0,0-.08.328V-8.4h8.416q.224,0,.224.176v.544a.184.184,0,0,1-.208.208H3.616v1.9a.5.5,0,0,0,.08.32.384.384,0,0,0,.3.1h8.368a.184.184,0,0,1,.208.208Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_v_(ㅌ)_3.svg': { 
		path: <path d="M9.152-5.792a.267.267,0,0,1-.032.2.444.444,0,0,1-.192.1q-.656.112-1.384.224t-1.5.16q-.752.032-1.528.072t-1.5.04a1.324,1.324,0,0,1-.992-.344,1.542,1.542,0,0,1-.256-.984v-4.464a1.207,1.207,0,0,1,.248-.9A1.434,1.434,0,0,1,2.96-11.9H7.7q.208,0,.208.192v.576q0,.192-.208.192H3.184q-.24,0-.288.056a.547.547,0,0,0-.048.3v1.6H4.4q.48,0,1.008-.016.48-.032,1.008-.04t.992-.04a.176.176,0,0,1,.168.048.213.213,0,0,1,.056.144l.016.528q0,.176-.208.208-.96.08-2.008.1t-1.9-.008a5.431,5.431,0,0,0-.592,0h-.1V-6.4a.438.438,0,0,0,.1.328.469.469,0,0,0,.32.088,23.273,23.273,0,0,0,2.792-.072,24.867,24.867,0,0,0,2.7-.376q.256-.032.3.192Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_v_2.svg': { 
		path: <path d="M8.208-6.16q-.432.032-1.04.056T5.92-6.072l-1.248.016q-.608.008-1.056-.008H2.88v2.992a.5.5,0,0,0,.08.328.406.406,0,0,0,.3.088q.672.016,1.488-.008t1.664-.08Q7.264-2.8,8.1-2.9t1.52-.232q.272-.048.32.176l.08.464A.234.234,0,0,1,10-2.3a.357.357,0,0,1-.192.088q-.672.144-1.544.248t-1.8.176q-.928.072-1.824.1t-1.616.016A1.268,1.268,0,0,1,2.04-2a1.542,1.542,0,0,1-.264-.992v-7.2a1.182,1.182,0,0,1,.256-.9,1.5,1.5,0,0,1,.96-.224H8.5q.208,0,.208.192v.576q0,.192-.208.192H3.2q-.224,0-.272.056a.547.547,0,0,0-.048.3v3.008H4.688Q5.3-6.992,5.936-7t1.24-.032q.6-.024,1.032-.056a.155.155,0,0,1,.152.048.213.213,0,0,1,.056.144v.544Q8.416-6.192,8.208-6.16Z" />, 
		strokes: [
		] 
	},
	'_ㅌ_v_c_3.svg': { 
		path: <path d="M8.866-12.545q-1.716.039-3.094-.013H4.68v3.146a.809.809,0,0,0,.13.533.659.659,0,0,0,.494.143A42.2,42.2,0,0,0,9.88-8.879q2.262-.2,4.368-.585.442-.078.52.286l.13.754a.381.381,0,0,1-.026.325.58.58,0,0,1-.312.143q-1.066.208-2.249.377t-2.431.26q-1.248.091-2.509.143T4.914-7.15a2.093,2.093,0,0,1-1.6-.533,2.449,2.449,0,0,1-.429-1.6v-8.112A1.92,1.92,0,0,1,3.3-18.85a2.444,2.444,0,0,1,1.56-.364h7.7q.338,0,.338.312v.936q0,.312-.338.312H5.2q-.364,0-.442.091a.889.889,0,0,0-.078.481v3.016H7.2q.78,0,1.6-.026l1.664-.052q.845-.026,1.625-.078a.251.251,0,0,1,.247.078.346.346,0,0,1,.091.234l.026.884A13.939,13.939,0,0,1,8.866-12.545Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅕ)_(ㅌ)_3.svg': { 
		path: <path d="M9.264-11.2q0,.208-.224.208H7.728V-6.4q1.024-.1,1.952-.272a.288.288,0,0,1,.176-.008.267.267,0,0,1,.1.136l.08.528a.134.134,0,0,1,0,.128.382.382,0,0,1-.192.1q-.912.144-2.032.264t-2.288.2Q5.072-5.312,4.5-5.3T3.328-5.3q-.624.032-1.208.032T1.088-5.3A.245.245,0,0,1,.92-5.36a.177.177,0,0,1-.072-.128V-6A.182.182,0,0,1,.92-6.152a.266.266,0,0,1,.168-.056h1.84v-4.784H1.2a.217.217,0,0,1-.16-.064.217.217,0,0,1-.064-.16v-.5a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H9.04a.2.2,0,0,1,.224.224ZM5.52-6.256l1.1-.064v-4.672H3.984v4.784h.544Q5.072-6.224,5.52-6.256Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅗ)_2.svg': { 
		path: <path d="M12.912-4.816q0,.208-.24.208H2.176a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.3v-4.976H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.856q.24,0,.24.208v.528q0,.208-.24.208H10.624v4.976h2.048q.24,0,.24.208Zm-7.52-.736H9.536v-4.976H5.392Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M12.592-7.824q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.56H4.368v-2.976H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.56V-8.56h1.792q.24,0,.24.208ZM5.456-8.56H9.472v-2.976H5.456Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M12.592-7.824q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.56H4.368v-2.976H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.56V-8.56h1.792q.24,0,.24.208ZM5.456-8.56H9.472v-2.976H5.456Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M12.592-7.824q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.56H4.368v-2.976H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.56V-8.56h1.792q.24,0,.24.208ZM5.456-8.56H9.472v-2.976H5.456Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M12.592-7.824q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.56H4.368v-2.976H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.56V-8.56h1.792q.24,0,.24.208ZM5.456-8.56H9.472v-2.976H5.456Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅛ)_2.svg': { 
		path: <path d="M12.912-5.04q0,.208-.24.208H2.176A.226.226,0,0,1,2.024-4.9a.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.208v-4.752H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.856q.24,0,.24.208v.528q0,.208-.24.208H10.72v4.752h1.952q.24,0,.24.208ZM5.3-5.776H9.632v-4.752H5.3Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅛ)_c_3.svg': { 
		path: <path d="M12.256-11.44q0,.208-.256.208H10.448V-7.9H12.3q.256,0,.256.224v.528q0,.208-.256.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16V-7.68a.214.214,0,0,1,.072-.152A.214.214,0,0,1,2.5-7.9H4.448v-3.328H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H12q.256,0,.256.208ZM5.536-7.9H9.344v-3.328H5.536Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_(ㅡ)_c_3.svg': { 
		path: <path d="M12.256-11.44q0,.208-.256.208H10.448V-7.9H12.3q.256,0,.256.224v.528q0,.208-.256.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16V-7.68a.214.214,0,0,1,.072-.152A.214.214,0,0,1,2.5-7.9H4.448v-3.328H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H12q.256,0,.256.208ZM5.536-7.9H9.344v-3.328H5.536Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_1.svg': { 
		path: <path d="M8.848-7.536H6.24v5.152H8.848Zm-3.712,0H3.7A.226.226,0,0,1,3.544-7.6a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16A.226.226,0,0,1,3.7-8.5h7.632q.24,0,.24.208v.544q0,.208-.24.208H9.952v5.152h1.856q.24,0,.24.208v.544q0,.208-.24.208H3.216a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16V-2.16a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h1.92Z" />, 
		strokes: [
			<path   d="M807.42,2168.817h8.223" transform="translate(0 -0.347)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M809.642,2169.431v5.189" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M809.642,2169.431v5.189" transform="translate(4)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M806.926,2175.078h9.183" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅍ_hd_2.svg': { 
		path: <path d="M12.912-6.24q0,.208-.24.208H2.176A.226.226,0,0,1,2.024-6.1a.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.3v-4.272H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.856q.24,0,.24.208v.528q0,.208-.24.208H10.624v4.272h2.048q.24,0,.24.208Zm-7.52-.736H9.536v-4.272H5.392Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_hd_c_3.svg': { 
		path: <path d="M12.592-7.312q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.048H4.464v-3.2H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.464v3.2h1.888q.24,0,.24.208Zm-7.04-.736H9.376v-3.2H5.552Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_hu_(ㄹ)_3.svg': { 
		path: <path d="M12.592-7.808q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.544H4.464v-2.992H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.464v2.992h1.888q.24,0,.24.208Zm-7.04-.736H9.376v-2.992H5.552Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_hu_(ㅊ)_3.svg': { 
		path: <path d="M12.592-7.808q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.544H4.464v-2.992H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.464v2.992h1.888q.24,0,.24.208Zm-7.04-.736H9.376v-2.992H5.552Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_hu_(ㅎ)_3.svg': { 
		path: <path d="M12.592-7.808q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.544H4.464v-2.992H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.464v2.992h1.888q.24,0,.24.208Zm-7.04-.736H9.376v-2.992H5.552Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_hu_2.svg': { 
		path: <path d="M12.912-4.656q0,.208-.24.208H2.176a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H4.3v-5.136H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.856q.24,0,.24.208v.528q0,.208-.24.208H10.624v5.136h2.048q.24,0,.24.208Zm-7.52-.736H9.536v-5.136H5.392Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_hu_c_3.svg': { 
		path: <path d="M12.592-7.28q0,.208-.24.208H2.5a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16A.226.226,0,0,1,2.5-8.016H4.464v-3.232H2.816a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h9.216q.24,0,.24.208v.528q0,.208-.24.208H10.464v3.232h1.888q.24,0,.24.208Zm-7.04-.736H9.376v-3.232H5.552Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_v_2.svg': { 
		path: <path d="M9.968-3.392q.256-.048.272.112l.1.56q.032.192-.208.224-.432.08-1,.152t-1.192.136q-.624.064-1.264.12T5.456-2q-.448.032-1.016.048t-1.168.024q-.6.008-1.184.008T1.04-1.936A.245.245,0,0,1,.872-2,.19.19,0,0,1,.8-2.144v-.528a.182.182,0,0,1,.072-.152A.266.266,0,0,1,1.04-2.88q.368.032.832.032t.976-.016v-7.5h-1.7A.226.226,0,0,1,1-10.432a.205.205,0,0,1-.072-.16v-.5A.205.205,0,0,1,1-11.248a.226.226,0,0,1,.152-.064H9.2q.24,0,.24.208v.528q0,.208-.24.208H7.968V-3.12q.544-.048,1.048-.12T9.968-3.392Zm-4.544.448.72-.032q.368-.016.736-.048v-7.344H3.936V-2.88q.4-.016.776-.024T5.424-2.944Z" />, 
		strokes: [
		] 
	},
	'_ㅍ_v_c_3.svg': { 
		path: <path d="M9.76-6.336a.34.34,0,0,1,.192-.008.211.211,0,0,1,.08.136l.1.528a.2.2,0,0,1-.016.144q-.032.048-.192.08-.912.16-2.056.28t-2.312.184q-.448.032-1.024.04l-1.184.016q-.608.008-1.2.008T1.088-4.944A.245.245,0,0,1,.92-5.008a.19.19,0,0,1-.072-.144v-.512A.182.182,0,0,1,.92-5.816a.266.266,0,0,1,.168-.056h1.84v-4.992H1.2a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H9.12q.24,0,.24.208v.528q0,.208-.24.208H7.792v4.8q.528-.048,1.016-.12T9.76-6.336ZM5.52-5.92q.3-.016.6-.024t.584-.04v-4.88H4.016v4.992Q4.9-5.872,5.52-5.92Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_(ㄹ)_3.svg': { 
		path: <path d="M8.88-6.48a2.008,2.008,0,0,1-.256,1,2.459,2.459,0,0,1-.7.784,3.475,3.475,0,0,1-1.056.512A4.568,4.568,0,0,1,5.536-4,4.649,4.649,0,0,1,4.2-4.184,3.357,3.357,0,0,1,3.144-4.7a2.5,2.5,0,0,1-.7-.784,2.008,2.008,0,0,1-.256-1,2.008,2.008,0,0,1,.256-1,2.5,2.5,0,0,1,.7-.784,3.236,3.236,0,0,1,1.056-.5,4.847,4.847,0,0,1,1.336-.176,4.568,4.568,0,0,1,1.328.184,3.475,3.475,0,0,1,1.056.512,2.49,2.49,0,0,1,.7.776A1.964,1.964,0,0,1,8.88-6.48Zm-1.04,0a1.233,1.233,0,0,0-.176-.656,1.522,1.522,0,0,0-.488-.488,2.432,2.432,0,0,0-.728-.3,3.757,3.757,0,0,0-.912-.1,3.856,3.856,0,0,0-.92.1,2.56,2.56,0,0,0-.736.3,1.45,1.45,0,0,0-.488.48,1.263,1.263,0,0,0-.176.672,1.233,1.233,0,0,0,.176.656,1.6,1.6,0,0,0,.488.5,2.305,2.305,0,0,0,.736.312,3.856,3.856,0,0,0,.92.1,3.757,3.757,0,0,0,.912-.1,2.311,2.311,0,0,0,.728-.312,1.6,1.6,0,0,0,.488-.5A1.233,1.233,0,0,0,7.84-6.48Zm2.3-3.36q0,.208-.24.208H1.392A.226.226,0,0,1,1.24-9.7a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H9.9q.24,0,.24.208ZM7.872-11.68q0,.224-.24.224H3.408a.244.244,0,0,1-.152-.056.182.182,0,0,1-.072-.152v-.544a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_(ㅂ)_3.svg': { 
		path: <path d="M8.944-6.432a2.084,2.084,0,0,1-.256,1.024,2.511,2.511,0,0,1-.7.8,3.345,3.345,0,0,1-1.072.52A4.732,4.732,0,0,1,5.568-3.9a4.709,4.709,0,0,1-1.352-.184,3.356,3.356,0,0,1-1.064-.52,2.511,2.511,0,0,1-.7-.8,2.084,2.084,0,0,1-.256-1.024,2.084,2.084,0,0,1,.256-1.024,2.43,2.43,0,0,1,.7-.792A3.461,3.461,0,0,1,4.216-8.76a4.709,4.709,0,0,1,1.352-.184,4.732,4.732,0,0,1,1.344.184,3.345,3.345,0,0,1,1.072.52,2.511,2.511,0,0,1,.7.8A2.053,2.053,0,0,1,8.944-6.432Zm-1.056,0A1.263,1.263,0,0,0,7.712-7.1a1.6,1.6,0,0,0-.488-.5,2.305,2.305,0,0,0-.736-.312,3.856,3.856,0,0,0-.92-.1,3.957,3.957,0,0,0-.928.1,2.417,2.417,0,0,0-.744.3,1.494,1.494,0,0,0-.488.5,1.3,1.3,0,0,0-.176.68,1.274,1.274,0,0,0,.176.664,1.568,1.568,0,0,0,.488.5,2.431,2.431,0,0,0,.744.32,3.688,3.688,0,0,0,.928.112,3.594,3.594,0,0,0,.92-.112,2.44,2.44,0,0,0,.736-.32,1.568,1.568,0,0,0,.488-.5A1.274,1.274,0,0,0,7.888-6.432Zm2.256-3.376q0,.208-.24.208H1.392a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H9.9q.24,0,.24.208ZM7.888-11.632q0,.224-.24.224H3.424a.244.244,0,0,1-.152-.056.182.182,0,0,1-.072-.152v-.544a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_(ㅊ)_3.svg': { 
		path: <path d="M10.128-9.84q0,.208-.224.208H1.392A.242.242,0,0,1,1.248-9.7a.2.2,0,0,1-.08-.16v-.512a.2.2,0,0,1,.08-.16.242.242,0,0,1,.144-.064H9.9a.2.2,0,0,1,.224.224ZM7.856-11.664a.2.2,0,0,1-.224.224H3.408a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.528a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24a.2.2,0,0,1,.224.224Zm.128,5.2a1.222,1.222,0,0,0-.176-.664,1.606,1.606,0,0,0-.5-.488,2.667,2.667,0,0,0-.752-.3,3.957,3.957,0,0,0-.928-.1,3.929,3.929,0,0,0-.936.1,2.678,2.678,0,0,0-.744.3,1.462,1.462,0,0,0-.52.48,1.221,1.221,0,0,0-.184.672,1.164,1.164,0,0,0,.184.64,1.6,1.6,0,0,0,.52.5A2.2,2.2,0,0,0,4.7-5a3.929,3.929,0,0,0,.936.1A3.957,3.957,0,0,0,6.56-5a2.191,2.191,0,0,0,.752-.328,1.571,1.571,0,0,0,.5-.5A1.2,1.2,0,0,0,7.984-6.464Zm1.072,0a1.96,1.96,0,0,1-.264,1,2.25,2.25,0,0,1-.728.76,4.118,4.118,0,0,1-1.088.52,4.38,4.38,0,0,1-1.344.2,4.49,4.49,0,0,1-1.376-.2A3.857,3.857,0,0,1,3.184-4.7a2.25,2.25,0,0,1-.728-.76,1.96,1.96,0,0,1-.264-1,1.96,1.96,0,0,1,.264-1,2.56,2.56,0,0,1,.728-.792,3.116,3.116,0,0,1,1.072-.5,5.286,5.286,0,0,1,1.376-.168,4.732,4.732,0,0,1,1.344.184,4.078,4.078,0,0,1,1.088.488,2.777,2.777,0,0,1,.728.8A1.918,1.918,0,0,1,9.056-6.464Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_(ㅋ)_3.svg': { 
		path: <path d="M7.856-6.032a1.458,1.458,0,0,0-.168-.688A1.4,1.4,0,0,0,7.2-7.248a2.325,2.325,0,0,0-.736-.336A3.5,3.5,0,0,0,5.552-7.7a3.756,3.756,0,0,0-.944.112,2.127,2.127,0,0,0-.736.336,1.518,1.518,0,0,0-.48.528,1.405,1.405,0,0,0-.176.688,1.392,1.392,0,0,0,.176.7,1.7,1.7,0,0,0,.48.536,2.238,2.238,0,0,0,.736.344,3.518,3.518,0,0,0,.944.12,3.282,3.282,0,0,0,.912-.12A2.462,2.462,0,0,0,7.2-4.8a1.551,1.551,0,0,0,.488-.536A1.444,1.444,0,0,0,7.856-6.032Zm1.072,0a2.049,2.049,0,0,1-.272,1.056,2.9,2.9,0,0,1-.72.816,3.285,3.285,0,0,1-1.064.544,4.413,4.413,0,0,1-1.32.192A4.526,4.526,0,0,1,4.2-3.616,3.285,3.285,0,0,1,3.136-4.16a2.619,2.619,0,0,1-.7-.832,2.176,2.176,0,0,1-.256-1.04,2.209,2.209,0,0,1,.256-1.056,2.455,2.455,0,0,1,.7-.816A3.722,3.722,0,0,1,4.2-8.416a4.526,4.526,0,0,1,1.352-.192,4.413,4.413,0,0,1,1.32.192A3.722,3.722,0,0,1,7.936-7.9a2.818,2.818,0,0,1,.72.832A2.074,2.074,0,0,1,8.928-6.032Zm1.184-3.5q0,.208-.24.208h-8.5a.217.217,0,0,1-.16-.064.217.217,0,0,1-.064-.16v-.512a.226.226,0,0,1,.064-.152.205.205,0,0,1,.16-.072h8.5q.24,0,.24.208ZM7.856-11.5q0,.224-.24.224H3.408a.244.244,0,0,1-.152-.056.182.182,0,0,1-.072-.152v-.544a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064H7.616q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_(ㅌ)_3.svg': { 
		path: <path d="M10.128-9.84q0,.208-.224.208H1.392A.242.242,0,0,1,1.248-9.7a.2.2,0,0,1-.08-.16v-.512a.2.2,0,0,1,.08-.16.242.242,0,0,1,.144-.064H9.9a.2.2,0,0,1,.224.224ZM7.856-11.664a.2.2,0,0,1-.224.224H3.408a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.528a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24a.2.2,0,0,1,.224.224Zm.128,5.2a1.222,1.222,0,0,0-.176-.664,1.606,1.606,0,0,0-.5-.488,2.667,2.667,0,0,0-.752-.3,3.957,3.957,0,0,0-.928-.1,3.929,3.929,0,0,0-.936.1,2.678,2.678,0,0,0-.744.3,1.462,1.462,0,0,0-.52.48,1.221,1.221,0,0,0-.184.672,1.164,1.164,0,0,0,.184.64,1.6,1.6,0,0,0,.52.5A2.2,2.2,0,0,0,4.7-5a3.929,3.929,0,0,0,.936.1A3.957,3.957,0,0,0,6.56-5a2.191,2.191,0,0,0,.752-.328,1.571,1.571,0,0,0,.5-.5A1.2,1.2,0,0,0,7.984-6.464Zm1.072,0a1.96,1.96,0,0,1-.264,1,2.25,2.25,0,0,1-.728.76,4.118,4.118,0,0,1-1.088.52,4.38,4.38,0,0,1-1.344.2,4.49,4.49,0,0,1-1.376-.2A3.857,3.857,0,0,1,3.184-4.7a2.25,2.25,0,0,1-.728-.76,1.96,1.96,0,0,1-.264-1,1.96,1.96,0,0,1,.264-1,2.56,2.56,0,0,1,.728-.792,3.116,3.116,0,0,1,1.072-.5,5.286,5.286,0,0,1,1.376-.168,4.732,4.732,0,0,1,1.344.184,4.078,4.078,0,0,1,1.088.488,2.777,2.777,0,0,1,.728.8A1.918,1.918,0,0,1,9.056-6.464Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_(ㅎ)_3.svg': { 
		path: <path d="M10.128-9.84q0,.208-.224.208H1.392A.242.242,0,0,1,1.248-9.7a.2.2,0,0,1-.08-.16v-.512a.2.2,0,0,1,.08-.16.242.242,0,0,1,.144-.064H9.9a.2.2,0,0,1,.224.224ZM7.856-11.664a.2.2,0,0,1-.224.224H3.408a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.528a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24a.2.2,0,0,1,.224.224Zm.128,5.2a1.222,1.222,0,0,0-.176-.664,1.606,1.606,0,0,0-.5-.488,2.667,2.667,0,0,0-.752-.3,3.957,3.957,0,0,0-.928-.1,3.929,3.929,0,0,0-.936.1,2.678,2.678,0,0,0-.744.3,1.462,1.462,0,0,0-.52.48,1.221,1.221,0,0,0-.184.672,1.164,1.164,0,0,0,.184.64,1.6,1.6,0,0,0,.52.5A2.2,2.2,0,0,0,4.7-5a3.929,3.929,0,0,0,.936.1A3.957,3.957,0,0,0,6.56-5a2.191,2.191,0,0,0,.752-.328,1.571,1.571,0,0,0,.5-.5A1.2,1.2,0,0,0,7.984-6.464Zm1.072,0a1.96,1.96,0,0,1-.264,1,2.25,2.25,0,0,1-.728.76,4.118,4.118,0,0,1-1.088.52,4.38,4.38,0,0,1-1.344.2,4.49,4.49,0,0,1-1.376-.2A3.857,3.857,0,0,1,3.184-4.7a2.25,2.25,0,0,1-.728-.76,1.96,1.96,0,0,1-.264-1,1.96,1.96,0,0,1,.264-1,2.56,2.56,0,0,1,.728-.792,3.116,3.116,0,0,1,1.072-.5,5.286,5.286,0,0,1,1.376-.168,4.732,4.732,0,0,1,1.344.184,4.078,4.078,0,0,1,1.088.488,2.777,2.777,0,0,1,.728.8A1.918,1.918,0,0,1,9.056-6.464Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅓ)_c_3.svg': { 
		path: <path d="M8.928-6.032a2.162,2.162,0,0,1-.256,1.048,2.608,2.608,0,0,1-.712.824,3.383,3.383,0,0,1-1.072.544,4.469,4.469,0,0,1-1.336.192A4.526,4.526,0,0,1,4.2-3.616,3.285,3.285,0,0,1,3.136-4.16a2.619,2.619,0,0,1-.7-.832,2.176,2.176,0,0,1-.256-1.04,2.209,2.209,0,0,1,.256-1.056,2.455,2.455,0,0,1,.7-.816A3.485,3.485,0,0,1,4.2-8.432a4.526,4.526,0,0,1,1.352-.192,4.469,4.469,0,0,1,1.336.192A3.484,3.484,0,0,1,7.96-7.9a2.5,2.5,0,0,1,.712.824A2.176,2.176,0,0,1,8.928-6.032Zm-1.056,0a1.392,1.392,0,0,0-.176-.7,1.586,1.586,0,0,0-.488-.528,2.459,2.459,0,0,0-.736-.336,3.368,3.368,0,0,0-.92-.12,3.455,3.455,0,0,0-.928.12,2.447,2.447,0,0,0-.744.336,1.586,1.586,0,0,0-.488.528,1.392,1.392,0,0,0-.176.7,1.392,1.392,0,0,0,.176.7A1.663,1.663,0,0,0,3.88-4.8a2.335,2.335,0,0,0,.744.344,3.455,3.455,0,0,0,.928.12,3.368,3.368,0,0,0,.92-.12A2.345,2.345,0,0,0,7.208-4.8,1.663,1.663,0,0,0,7.7-5.336,1.392,1.392,0,0,0,7.872-6.032Zm2.256-3.52q0,.208-.24.208H1.376a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H9.888q.24,0,.24.208ZM7.872-11.52q0,.224-.24.224H3.408a.244.244,0,0,1-.152-.056.182.182,0,0,1-.072-.152v-.544a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24q.24,0,.24.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅕ)_(ㄹ)_3.svg': { 
		path: <path d="M10.128-9.84q0,.208-.224.208H1.392A.242.242,0,0,1,1.248-9.7a.2.2,0,0,1-.08-.16v-.512a.2.2,0,0,1,.08-.16.242.242,0,0,1,.144-.064H9.9a.2.2,0,0,1,.224.224ZM7.856-11.664a.2.2,0,0,1-.224.224H3.408a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.528a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24a.2.2,0,0,1,.224.224Zm.128,5.2a1.222,1.222,0,0,0-.176-.664,1.606,1.606,0,0,0-.5-.488,2.667,2.667,0,0,0-.752-.3,3.957,3.957,0,0,0-.928-.1,3.929,3.929,0,0,0-.936.1,2.678,2.678,0,0,0-.744.3,1.462,1.462,0,0,0-.52.48,1.221,1.221,0,0,0-.184.672,1.164,1.164,0,0,0,.184.64,1.6,1.6,0,0,0,.52.5A2.2,2.2,0,0,0,4.7-5a3.929,3.929,0,0,0,.936.1A3.957,3.957,0,0,0,6.56-5a2.191,2.191,0,0,0,.752-.328,1.571,1.571,0,0,0,.5-.5A1.2,1.2,0,0,0,7.984-6.464Zm1.072,0a1.96,1.96,0,0,1-.264,1,2.25,2.25,0,0,1-.728.76,4.118,4.118,0,0,1-1.088.52,4.38,4.38,0,0,1-1.344.2,4.49,4.49,0,0,1-1.376-.2A3.857,3.857,0,0,1,3.184-4.7a2.25,2.25,0,0,1-.728-.76,1.96,1.96,0,0,1-.264-1,1.96,1.96,0,0,1,.264-1,2.56,2.56,0,0,1,.728-.792,3.116,3.116,0,0,1,1.072-.5,5.286,5.286,0,0,1,1.376-.168,4.732,4.732,0,0,1,1.344.184,4.078,4.078,0,0,1,1.088.488,2.777,2.777,0,0,1,.728.8A1.918,1.918,0,0,1,9.056-6.464Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㄹ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㅂ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㅇ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㅈ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㅊ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㅌ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_(ㅎ)_3.svg': { 
		path: <path d="M11.616-7.616a1.134,1.134,0,0,1-.256.712,2.125,2.125,0,0,1-.728.568A4.717,4.717,0,0,1,9.5-5.952a8.6,8.6,0,0,1-1.48.176h-1.1A6.242,6.242,0,0,1,4.3-6.344a1.474,1.474,0,0,1-.968-1.272,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.616Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.48.808.736a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.616Zm-.912-4.928q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅗ)_c_3.svg': { 
		path: <path d="M6.912-5.008a7.751,7.751,0,0,1-1.456-.216,4.483,4.483,0,0,1-1.128-.44,2.3,2.3,0,0,1-.736-.64,1.368,1.368,0,0,1-.264-.816,1.389,1.389,0,0,1,.3-.872,2.477,2.477,0,0,1,.856-.672A4.924,4.924,0,0,1,5.8-9.088a9,9,0,0,1,1.672-.144,8.541,8.541,0,0,1,1.672.152,5.34,5.34,0,0,1,1.312.424,2.4,2.4,0,0,1,.856.664,1.389,1.389,0,0,1,.3.872,1.368,1.368,0,0,1-.264.816,2.382,2.382,0,0,1-.736.648,4.1,4.1,0,0,1-1.136.44,8.411,8.411,0,0,1-1.464.208ZM10.4-7.12a.763.763,0,0,0-.216-.536,1.611,1.611,0,0,0-.608-.384,4.082,4.082,0,0,0-.928-.224,8.867,8.867,0,0,0-1.176-.072,9.051,9.051,0,0,0-1.184.072,4.414,4.414,0,0,0-.936.216,1.614,1.614,0,0,0-.616.376.767.767,0,0,0-.224.552.749.749,0,0,0,.224.536,1.692,1.692,0,0,0,.616.384,4.361,4.361,0,0,0,.936.232,8.159,8.159,0,0,0,1.184.08,7.993,7.993,0,0,0,1.176-.08A4.362,4.362,0,0,0,9.576-6.2a1.612,1.612,0,0,0,.608-.384A.763.763,0,0,0,10.4-7.12Zm2.768-2.912q0,.208-.24.208H2.048A.226.226,0,0,1,1.9-9.888a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM9.472-12.5q.24,0,.24.224v.5a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.5q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㄹ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㅂ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㅇ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㅈ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㅊ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㅌ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_(ㅎ)_3.svg': { 
		path: <path d="M11.616-7.632a1.115,1.115,0,0,1-.28.744,2.312,2.312,0,0,1-.792.568,9.5,9.5,0,0,1-1.1.37,3.623,3.623,0,0,1-.92.13,8.487,8.487,0,0,1-1.048.04,8.033,8.033,0,0,1-1-.04,3.9,3.9,0,0,1-.888-.12,11.21,11.21,0,0,1-1.1-.332,2.457,2.457,0,0,1-.856-.6,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.544q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.632q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256,7.117,7.117,0,0,0,2.112-.256Q10.384-7.168,10.384-7.632Zm-.912-4.912q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅛ)_c_3.svg': { 
		path: <path d="M12.912-9.808H2.048a.205.205,0,0,1-.16-.072.226.226,0,0,1-.064-.152v-.528a.226.226,0,0,1,.064-.152.205.205,0,0,1,.16-.072H12.912a.2.2,0,0,1,.224.224S13.061-9.808,12.912-9.808ZM9.456-12.48a.2.2,0,0,1,.224.224v.5q0,.256-.224.256H5.376q-.224,0-.224-.256v-.5a.2.2,0,0,1,.224-.224Zm.912,5.36a.789.789,0,0,0-.208-.528,1.428,1.428,0,0,0-.592-.384,3.708,3.708,0,0,0-.936-.224A9.961,9.961,0,0,0,7.456-8.32a9.961,9.961,0,0,0-1.176.064,3.708,3.708,0,0,0-.936.224,1.476,1.476,0,0,0-.608.36.767.767,0,0,0-.224.552.767.767,0,0,0,.224.552,1.476,1.476,0,0,0,.608.36,4.2,4.2,0,0,0,.936.24,7.993,7.993,0,0,0,1.176.08,7.993,7.993,0,0,0,1.176-.08,4.2,4.2,0,0,0,.936-.24,1.348,1.348,0,0,0,.592-.36A.8.8,0,0,0,10.368-7.12Zm1.232,0a1.356,1.356,0,0,1-.312.88,2.776,2.776,0,0,1-.84.672,5.484,5.484,0,0,1-1.32.424,8.541,8.541,0,0,1-1.672.152A8.451,8.451,0,0,1,5.8-5.144a4.793,4.793,0,0,1-1.3-.44,2.516,2.516,0,0,1-.872-.656,1.356,1.356,0,0,1-.312-.88,1.335,1.335,0,0,1,.312-.864A2.865,2.865,0,0,1,4.5-8.672a5.458,5.458,0,0,1,1.3-.4,8.907,8.907,0,0,1,1.656-.144,8.541,8.541,0,0,1,1.672.152,6.024,6.024,0,0,1,1.32.408,2.776,2.776,0,0,1,.84.672A1.335,1.335,0,0,1,11.6-7.12Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅡ)_(ㄹ)_3.svg': { 
		path: <path d="M13.168-10.192q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208Zm-1.552,2.56a1.139,1.139,0,0,1-.3.76,2.323,2.323,0,0,1-.84.584,5.754,5.754,0,0,1-1.312.376,9.757,9.757,0,0,1-1.7.136A9.86,9.86,0,0,1,5.76-5.912a5.6,5.6,0,0,1-1.3-.376,2.334,2.334,0,0,1-.832-.584,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm-1.232.016q0-.48-.8-.736a7.117,7.117,0,0,0-2.112-.256,7.419,7.419,0,0,0-2.12.248q-.808.248-.808.744,0,.464.808.72a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.9Q10.384-7.152,10.384-7.616ZM9.472-12.56q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅡ)_(ㅂ)_3.svg': { 
		path: <path d="M11.616-7.632a1.139,1.139,0,0,1-.3.76,2.323,2.323,0,0,1-.84.584,5.754,5.754,0,0,1-1.312.376,9.757,9.757,0,0,1-1.7.136A9.86,9.86,0,0,1,5.76-5.912a5.6,5.6,0,0,1-1.3-.376,2.334,2.334,0,0,1-.832-.584,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.56q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.736a7.117,7.117,0,0,0-2.112-.256,7.419,7.419,0,0,0-2.12.248q-.808.248-.808.744,0,.464.808.72a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.9Q10.384-7.152,10.384-7.616ZM9.472-12.56q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅡ)_(ㅊ)_3.svg': { 
		path: <path d="M10.352-7.632q0-.48-.792-.728a7.249,7.249,0,0,0-2.1-.248,7.888,7.888,0,0,0-2.128.232q-.8.232-.8.744,0,.48.8.744a6.98,6.98,0,0,0,2.128.264,6.835,6.835,0,0,0,2.1-.264Q10.352-7.152,10.352-7.632Zm1.248,0a1.169,1.169,0,0,1-.3.768,2.132,2.132,0,0,1-.84.576,6.082,6.082,0,0,1-1.32.376,9.618,9.618,0,0,1-1.688.136,9.86,9.86,0,0,1-1.712-.136,5.451,5.451,0,0,1-1.3-.376,2.222,2.222,0,0,1-.832-.576,1.151,1.151,0,0,1-.3-.768,1.133,1.133,0,0,1,.3-.752,2.378,2.378,0,0,1,.832-.592,5.025,5.025,0,0,1,1.3-.36,11.146,11.146,0,0,1,1.712-.12,10.872,10.872,0,0,1,1.688.12,5.082,5.082,0,0,1,1.32.376,2.323,2.323,0,0,1,.84.584A1.12,1.12,0,0,1,11.6-7.632Zm1.536-2.544q0,.208-.224.208H2.048a.205.205,0,0,1-.16-.072.226.226,0,0,1-.064-.152v-.5a.217.217,0,0,1,.064-.16.217.217,0,0,1,.16-.064H12.912q.224,0,.224.208Zm-3.68-2.352a.2.2,0,0,1,.224.224v.464q0,.24-.224.24H5.376q-.224,0-.224-.24V-12.3a.2.2,0,0,1,.224-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅡ)_(ㅌ)_3.svg': { 
		path: <path d="M11.616-7.632a1.139,1.139,0,0,1-.3.76,2.323,2.323,0,0,1-.84.584,5.754,5.754,0,0,1-1.312.376,9.757,9.757,0,0,1-1.7.136A9.86,9.86,0,0,1,5.76-5.912a5.6,5.6,0,0,1-1.3-.376,2.334,2.334,0,0,1-.832-.584,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm1.552-2.56q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.616q0-.48-.8-.736a7.117,7.117,0,0,0-2.112-.256,7.419,7.419,0,0,0-2.12.248q-.808.248-.808.744,0,.464.808.72a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.9Q10.384-7.152,10.384-7.616ZM9.472-12.56q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅡ)_(ㅎ)_3.svg': { 
		path: <path d="M13.168-10.192q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208Zm-1.552,2.56a1.139,1.139,0,0,1-.3.76,2.323,2.323,0,0,1-.84.584,5.754,5.754,0,0,1-1.312.376,9.757,9.757,0,0,1-1.7.136A9.86,9.86,0,0,1,5.76-5.912a5.6,5.6,0,0,1-1.3-.376,2.334,2.334,0,0,1-.832-.584,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368,10.462,10.462,0,0,1,1.712-.128,9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.632Zm-1.232.016q0-.48-.8-.736a7.117,7.117,0,0,0-2.112-.256,7.419,7.419,0,0,0-2.12.248q-.808.248-.808.744,0,.464.808.72a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.9Q10.384-7.152,10.384-7.616ZM9.472-12.56q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_(ㅡ)_c_3.svg': { 
		path: <path d="M11.616-7.12a1.422,1.422,0,0,1-.3.88,2.358,2.358,0,0,1-.856.672,5.34,5.34,0,0,1-1.312.424,8.541,8.541,0,0,1-1.672.152A8.541,8.541,0,0,1,5.8-5.144a5.118,5.118,0,0,1-1.312-.432,2.477,2.477,0,0,1-.856-.672,1.389,1.389,0,0,1-.3-.872,1.389,1.389,0,0,1,.3-.872,2.477,2.477,0,0,1,.856-.672A4.924,4.924,0,0,1,5.8-9.088a9,9,0,0,1,1.672-.144,8.541,8.541,0,0,1,1.672.152,5.34,5.34,0,0,1,1.312.424,2.4,2.4,0,0,1,.856.664A1.389,1.389,0,0,1,11.616-7.12ZM10.4-7.1a.763.763,0,0,0-.216-.536,1.689,1.689,0,0,0-.608-.392,3.8,3.8,0,0,0-.928-.232,8.867,8.867,0,0,0-1.176-.072,9.051,9.051,0,0,0-1.184.072,4.088,4.088,0,0,0-.936.224,1.692,1.692,0,0,0-.616.384.767.767,0,0,0-.224.552.749.749,0,0,0,.224.536,1.692,1.692,0,0,0,.616.384,4.088,4.088,0,0,0,.936.224,9.051,9.051,0,0,0,1.184.072A8.867,8.867,0,0,0,8.648-5.96a4.082,4.082,0,0,0,.928-.224,1.611,1.611,0,0,0,.608-.384A.763.763,0,0,0,10.4-7.1ZM9.472-12.5q.24,0,.24.224v.5a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.5q0-.224.24-.224Zm3.7,2.448q0,.208-.24.208H2.048A.226.226,0,0,1,1.9-9.9a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_1.svg': { 
		path: <path d="M9.744-8.5q0,.224-.24.224H5.28a.244.244,0,0,1-.152-.056.182.182,0,0,1-.072-.152v-.56A.217.217,0,0,1,5.12-9.2a.2.2,0,0,1,.144-.064H9.5q.24,0,.24.224ZM12-6.656q0,.208-.24.208H3.248A.226.226,0,0,1,3.1-6.512a.205.205,0,0,1-.072-.16v-.5a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H11.76q.24,0,.24.208Zm-1.024,3.5a2.233,2.233,0,0,1-.264,1.08,2.521,2.521,0,0,1-.728.832A3.547,3.547,0,0,1,8.88-.7,4.767,4.767,0,0,1,7.5-.512,4.744,4.744,0,0,1,6.12-.7a3.56,3.56,0,0,1-1.1-.536A2.521,2.521,0,0,1,4.3-2.072a2.233,2.233,0,0,1-.264-1.08A2.233,2.233,0,0,1,4.3-4.232a2.521,2.521,0,0,1,.728-.832,3.433,3.433,0,0,1,1.1-.528A4.936,4.936,0,0,1,7.5-5.776a4.767,4.767,0,0,1,1.376.192,3.547,3.547,0,0,1,1.1.536,2.549,2.549,0,0,1,.728.824A2.187,2.187,0,0,1,10.976-3.152ZM9.888-3.12a1.453,1.453,0,0,0-.672-1.272A2.986,2.986,0,0,0,7.5-4.848a3.608,3.608,0,0,0-.952.12,2.545,2.545,0,0,0-.76.336,1.585,1.585,0,0,0-.5.544A1.462,1.462,0,0,0,5.1-3.12a1.418,1.418,0,0,0,.184.72,1.687,1.687,0,0,0,.5.544,2.427,2.427,0,0,0,.76.344,3.608,3.608,0,0,0,.952.12,2.947,2.947,0,0,0,1.712-.464A1.458,1.458,0,0,0,9.888-3.12Z" />, 
		strokes: [
			<path   d="M808.97,2168.2h4.853" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.97,2168.2h9.242" transform="translate(-2.086 2)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M797.75,2168.88c-1.8.022-3.106.071-3.091,1.866s1.3,2.1,3.091,2.09,2.857-.294,2.857-2.09-1.092-2.083-3.857-1.866" transform="translate(13.71 3)" fill="none" stroke="#c1c1c1" stroke-width="2"/>,
		] 
	},
	'_ㅎ_hd_(ㄹ)_3.svg': { 
		path: <path d="M11.616-7.6a1.139,1.139,0,0,1-.3.76,2.323,2.323,0,0,1-.84.584,5.754,5.754,0,0,1-1.312.376,9.757,9.757,0,0,1-1.7.136A9.86,9.86,0,0,1,5.76-5.88a5.6,5.6,0,0,1-1.3-.376,2.334,2.334,0,0,1-.832-.584,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368A10.462,10.462,0,0,1,7.472-9.44a9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.6Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.6q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.6Zm-.912-4.944q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_hd_(ㅁ)_3.svg': { 
		path: <path d="M11.616-7.264a1.367,1.367,0,0,1-.3.856,2.4,2.4,0,0,1-.856.664,5.34,5.34,0,0,1-1.312.424,8.541,8.541,0,0,1-1.672.152A8.541,8.541,0,0,1,5.8-5.32a5.118,5.118,0,0,1-1.312-.432,2.526,2.526,0,0,1-.856-.664,1.335,1.335,0,0,1-.3-.848,1.356,1.356,0,0,1,.3-.864,2.447,2.447,0,0,1,.856-.656A5.129,5.129,0,0,1,5.8-9.2a9,9,0,0,1,1.672-.144,8.541,8.541,0,0,1,1.672.152,5.34,5.34,0,0,1,1.312.424,2.447,2.447,0,0,1,.856.656A1.335,1.335,0,0,1,11.616-7.264Zm-1.152,0a.722.722,0,0,0-.224-.528,1.668,1.668,0,0,0-.624-.368,4.58,4.58,0,0,0-.952-.216,9.238,9.238,0,0,0-1.192-.072,9.238,9.238,0,0,0-1.192.072,4.58,4.58,0,0,0-.952.216,1.751,1.751,0,0,0-.632.368.71.71,0,0,0-.232.528.694.694,0,0,0,.232.512,1.839,1.839,0,0,0,.632.376,4.24,4.24,0,0,0,.952.224,9.237,9.237,0,0,0,1.192.072,9.237,9.237,0,0,0,1.192-.072,4.24,4.24,0,0,0,.952-.224,1.749,1.749,0,0,0,.624-.376A.7.7,0,0,0,10.464-7.264Zm2.7-2.8q0,.208-.24.208H2.048A.226.226,0,0,1,1.9-9.92a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM9.472-12.5q.24,0,.24.224v.5a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.5q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_hd_2.svg': { 
		path: <path d="M11.712-6.576a1.567,1.567,0,0,1-.312.952,2.6,2.6,0,0,1-.872.728,5.018,5.018,0,0,1-1.344.472,8.174,8.174,0,0,1-1.712.168,8.144,8.144,0,0,1-1.72-.168A5.024,5.024,0,0,1,4.416-4.9a2.6,2.6,0,0,1-.872-.728,1.567,1.567,0,0,1-.312-.952,1.567,1.567,0,0,1,.312-.952,2.6,2.6,0,0,1,.872-.728A4.845,4.845,0,0,1,5.752-8.72a8.537,8.537,0,0,1,1.72-.16,8.174,8.174,0,0,1,1.712.168,5.018,5.018,0,0,1,1.344.472,2.6,2.6,0,0,1,.872.728A1.543,1.543,0,0,1,11.712-6.576Zm-1.248,0a.891.891,0,0,0-.216-.592,1.6,1.6,0,0,0-.616-.424,4.162,4.162,0,0,0-.944-.256,7.654,7.654,0,0,0-1.216-.088,7.808,7.808,0,0,0-1.224.088,4.013,4.013,0,0,0-.944.256,1.682,1.682,0,0,0-.616.424.872.872,0,0,0-.224.592.872.872,0,0,0,.224.592,1.762,1.762,0,0,0,.616.432,3.757,3.757,0,0,0,.944.264A7.808,7.808,0,0,0,7.472-5.2a7.654,7.654,0,0,0,1.216-.088,3.891,3.891,0,0,0,.944-.264,1.667,1.667,0,0,0,.616-.432A.891.891,0,0,0,10.464-6.576Zm2.8-3.184q0,.208-.24.208H1.952A.226.226,0,0,1,1.8-9.616a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H13.024q.24,0,.24.208ZM9.488-12.5q.24,0,.24.224v.512a.212.212,0,0,1-.24.24H5.376a.212.212,0,0,1-.24-.24v-.512q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_hd_c_3.svg': { 
		path: <path d="M11.616-7.6a1.139,1.139,0,0,1-.3.76,2.323,2.323,0,0,1-.84.584,5.754,5.754,0,0,1-1.312.376,9.757,9.757,0,0,1-1.7.136A9.86,9.86,0,0,1,5.76-5.88a5.6,5.6,0,0,1-1.3-.376,2.334,2.334,0,0,1-.832-.584,1.139,1.139,0,0,1-.3-.76,1.139,1.139,0,0,1,.3-.76,2.334,2.334,0,0,1,.832-.584,5.369,5.369,0,0,1,1.3-.368A10.462,10.462,0,0,1,7.472-9.44a9.757,9.757,0,0,1,1.7.136,5.754,5.754,0,0,1,1.312.376,2.379,2.379,0,0,1,.84.576A1.109,1.109,0,0,1,11.616-7.6Zm1.552-2.576q0,.208-.24.208H2.048a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.48a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h10.88q.24,0,.24.208ZM10.384-7.6q0-.48-.8-.728a7.334,7.334,0,0,0-2.112-.248,7.654,7.654,0,0,0-2.12.24q-.808.24-.808.736,0,.464.808.72a7.2,7.2,0,0,0,2.12.256A7.117,7.117,0,0,0,9.584-6.88Q10.384-7.136,10.384-7.6Zm-.912-4.944q.24,0,.24.224v.464a.212.212,0,0,1-.24.24H5.392a.212.212,0,0,1-.24-.24v-.464q0-.224.24-.224Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_hu_(ㅂ)_3.svg': { 
		path: <path d="M13.136-10.016q0,.208-.224.208H2.048a.205.205,0,0,1-.16-.072.226.226,0,0,1-.064-.152v-.528a.226.226,0,0,1,.064-.152.205.205,0,0,1,.16-.072H12.912a.2.2,0,0,1,.224.224ZM9.456-12.48a.2.2,0,0,1,.224.224v.5q0,.256-.224.256H5.376q-.224,0-.224-.256v-.5a.2.2,0,0,1,.224-.224Zm.912,5.36a.789.789,0,0,0-.208-.528,1.428,1.428,0,0,0-.592-.384,3.708,3.708,0,0,0-.936-.224A9.961,9.961,0,0,0,7.456-8.32a9.961,9.961,0,0,0-1.176.064,3.708,3.708,0,0,0-.936.224,1.476,1.476,0,0,0-.608.36.767.767,0,0,0-.224.552.767.767,0,0,0,.224.552,1.476,1.476,0,0,0,.608.36,4.2,4.2,0,0,0,.936.24,7.993,7.993,0,0,0,1.176.08,7.993,7.993,0,0,0,1.176-.08,4.2,4.2,0,0,0,.936-.24,1.348,1.348,0,0,0,.592-.36A.8.8,0,0,0,10.368-7.12Zm1.232,0a1.356,1.356,0,0,1-.312.88,2.776,2.776,0,0,1-.84.672,5.484,5.484,0,0,1-1.32.424,8.541,8.541,0,0,1-1.672.152A8.451,8.451,0,0,1,5.8-5.144a4.793,4.793,0,0,1-1.3-.44,2.516,2.516,0,0,1-.872-.656,1.356,1.356,0,0,1-.312-.88,1.335,1.335,0,0,1,.312-.864A2.865,2.865,0,0,1,4.5-8.672a5.458,5.458,0,0,1,1.3-.4,8.907,8.907,0,0,1,1.656-.144,8.541,8.541,0,0,1,1.672.152,6.024,6.024,0,0,1,1.32.408,2.776,2.776,0,0,1,.84.672A1.335,1.335,0,0,1,11.6-7.12Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_hu_2.svg': { 
		path: <path d="M9.488-12.192q.24,0,.24.224v.5a.212.212,0,0,1-.24.24H5.376a.212.212,0,0,1-.24-.24v-.5q0-.224.24-.224Zm3.776,2.736q0,.208-.24.208H1.952A.226.226,0,0,1,1.8-9.312a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064H13.024q.24,0,.24.208ZM11.712-5.792a2.156,2.156,0,0,1-.3,1.112,2.69,2.69,0,0,1-.848.872,4.451,4.451,0,0,1-1.336.576,6.869,6.869,0,0,1-1.76.208,6.869,6.869,0,0,1-1.76-.208,4.451,4.451,0,0,1-1.336-.576,2.69,2.69,0,0,1-.848-.872,2.156,2.156,0,0,1-.3-1.112,2.2,2.2,0,0,1,.3-1.12,2.589,2.589,0,0,1,.848-.872,4.44,4.44,0,0,1,1.336-.56,7.13,7.13,0,0,1,1.76-.2,6.869,6.869,0,0,1,1.76.208,4.585,4.585,0,0,1,1.336.568,2.589,2.589,0,0,1,.848.872A2.169,2.169,0,0,1,11.712-5.792Zm-1.248,0a1.477,1.477,0,0,0-.792-1.3,4.191,4.191,0,0,0-2.2-.488,4.293,4.293,0,0,0-2.208.48,1.47,1.47,0,0,0-.8,1.312,1.493,1.493,0,0,0,.8,1.312,4.18,4.18,0,0,0,2.208.5,4.136,4.136,0,0,0,2.2-.5A1.5,1.5,0,0,0,10.464-5.792Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_v_(ㅎ)_3.svg': { 
		path: <path d="M10.048-9.84q0,.208-.24.208h-8.8A.245.245,0,0,1,.84-9.7a.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.245.245,0,0,1,.168-.064h8.8q.24,0,.24.224ZM7.712-11.664q0,.224-.24.224H3.1a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.528a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064H7.472q.24,0,.24.224Zm.032,5.2a1.182,1.182,0,0,0-.184-.664,1.77,1.77,0,0,0-.488-.488,2.667,2.667,0,0,0-.752-.3,4.031,4.031,0,0,0-.944-.1,3.957,3.957,0,0,0-.928.1,2.441,2.441,0,0,0-.736.3,1.462,1.462,0,0,0-.52.48,1.221,1.221,0,0,0-.184.672,1.164,1.164,0,0,0,.184.64,1.6,1.6,0,0,0,.52.5A2.024,2.024,0,0,0,4.448-5a3.957,3.957,0,0,0,.928.1A4.031,4.031,0,0,0,6.32-5a2.191,2.191,0,0,0,.752-.328,1.726,1.726,0,0,0,.488-.5A1.164,1.164,0,0,0,7.744-6.464Zm1.072,0a1.96,1.96,0,0,1-.264,1,2.25,2.25,0,0,1-.728.76,4.118,4.118,0,0,1-1.088.52,4.434,4.434,0,0,1-1.36.2,4.434,4.434,0,0,1-1.36-.2A3.857,3.857,0,0,1,2.944-4.7a2.25,2.25,0,0,1-.728-.76,1.96,1.96,0,0,1-.264-1,1.96,1.96,0,0,1,.264-1,2.56,2.56,0,0,1,.728-.792,3.116,3.116,0,0,1,1.072-.5,5.22,5.22,0,0,1,1.36-.168,4.792,4.792,0,0,1,1.36.184,4.078,4.078,0,0,1,1.088.488,2.777,2.777,0,0,1,.728.8A1.918,1.918,0,0,1,8.816-6.464Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_v_2.svg': { 
		path: <path d="M7.968-11.04q0,.224-.24.224H3.344a.244.244,0,0,1-.152-.056.182.182,0,0,1-.072-.152v-.544a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.4q.24,0,.24.224ZM10.3-8.32q0,.208-.24.208H1.232a.226.226,0,0,1-.152-.064.205.205,0,0,1-.072-.16v-.512a.205.205,0,0,1,.072-.16.226.226,0,0,1,.152-.064h8.832q.24,0,.24.208ZM9.1-3.856A2.815,2.815,0,0,1,8.84-2.64a3.13,3.13,0,0,1-.728.984,3.37,3.37,0,0,1-1.1.664,3.887,3.887,0,0,1-1.376.24,3.868,3.868,0,0,1-1.384-.24,3.466,3.466,0,0,1-1.1-.656,3.014,3.014,0,0,1-.728-.984A2.868,2.868,0,0,1,2.16-3.856,2.906,2.906,0,0,1,2.424-5.1a3.014,3.014,0,0,1,.728-.984,3.466,3.466,0,0,1,1.1-.656,3.868,3.868,0,0,1,1.384-.24,3.775,3.775,0,0,1,1.376.248,3.563,3.563,0,0,1,1.1.664,2.99,2.99,0,0,1,.728.992A2.885,2.885,0,0,1,9.1-3.856Zm-1.088,0A2.121,2.121,0,0,0,7.84-4.72a2.078,2.078,0,0,0-.5-.7,2.328,2.328,0,0,0-.76-.464,2.647,2.647,0,0,0-.952-.168,2.647,2.647,0,0,0-.952.168,2.328,2.328,0,0,0-.76.464,2.179,2.179,0,0,0-.5.7,2.042,2.042,0,0,0-.184.864A1.989,1.989,0,0,0,3.416-3a2.206,2.206,0,0,0,.5.688,2.328,2.328,0,0,0,.76.464,2.647,2.647,0,0,0,.952.168,2.647,2.647,0,0,0,.952-.168,2.4,2.4,0,0,0,.76-.456,2,2,0,0,0,.5-.688A2.121,2.121,0,0,0,8.016-3.856Z" />, 
		strokes: [
		] 
	},
	'_ㅎ_v_c_3.svg': { 
		path: <path d="M10.128-9.84q0,.208-.224.208H1.392A.242.242,0,0,1,1.248-9.7a.2.2,0,0,1-.08-.16v-.512a.2.2,0,0,1,.08-.16.242.242,0,0,1,.144-.064H9.9a.2.2,0,0,1,.224.224ZM7.856-11.664a.2.2,0,0,1-.224.224H3.408a.244.244,0,0,1-.152-.056.2.2,0,0,1-.072-.168v-.528a.217.217,0,0,1,.064-.16.2.2,0,0,1,.144-.064h4.24a.2.2,0,0,1,.224.224Zm.128,5.2a1.222,1.222,0,0,0-.176-.664,1.606,1.606,0,0,0-.5-.488,2.667,2.667,0,0,0-.752-.3,3.957,3.957,0,0,0-.928-.1,3.929,3.929,0,0,0-.936.1,2.678,2.678,0,0,0-.744.3,1.462,1.462,0,0,0-.52.48,1.221,1.221,0,0,0-.184.672,1.164,1.164,0,0,0,.184.64,1.6,1.6,0,0,0,.52.5A2.2,2.2,0,0,0,4.7-5a3.929,3.929,0,0,0,.936.1A3.957,3.957,0,0,0,6.56-5a2.191,2.191,0,0,0,.752-.328,1.571,1.571,0,0,0,.5-.5A1.2,1.2,0,0,0,7.984-6.464Zm1.072,0a1.96,1.96,0,0,1-.264,1,2.25,2.25,0,0,1-.728.76,4.118,4.118,0,0,1-1.088.52,4.38,4.38,0,0,1-1.344.2,4.49,4.49,0,0,1-1.376-.2A3.857,3.857,0,0,1,3.184-4.7a2.25,2.25,0,0,1-.728-.76,1.96,1.96,0,0,1-.264-1,1.96,1.96,0,0,1,.264-1,2.56,2.56,0,0,1,.728-.792,3.116,3.116,0,0,1,1.072-.5,5.286,5.286,0,0,1,1.376-.168,4.732,4.732,0,0,1,1.344.184,4.078,4.078,0,0,1,1.088.488,2.777,2.777,0,0,1,.728.8A1.918,1.918,0,0,1,9.056-6.464Z" />, 
		strokes: [
		] 
	},
	'_ㅏ_1.svg': { 
		path: <path d="M6.848-4.864v6.88a.2.2,0,0,1-.224.224H5.968a.2.2,0,0,1-.224-.224v-14.3a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v6.464H9.056a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.544a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064Z" />, 
		strokes: [
			<path   d="M810.238,2164.375v14.952" transform="translate(-0.388)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M810.616,2171.59h2.523" transform="translate(0.194)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅑ_1.svg': { 
		path: <path d="M6.848-8H9.056a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.512a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H6.848V-3.68H9.056a.266.266,0,0,1,.168.056.182.182,0,0,1,.072.152v.512a.19.19,0,0,1-.072.144.245.245,0,0,1-.168.064H6.848V2.016a.2.2,0,0,1-.224.224H5.968a.2.2,0,0,1-.224-.224v-14.3a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
			<path   d="M810.238,2164.375v14.952" transform="translate(-0.388)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M810.809,2169.422h2.521" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M810.809,2169.422h2.521" transform="translate(0 4)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅓ_1.svg': { 
		path: <path d="M8.528-6.224v-6.064a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H8.752a.2.2,0,0,1-.224-.224v-7.28H5.616q-.208,0-.208-.192v-.576q0-.192.208-.192Z" />, 
		strokes: [
			<path   d="M809.318,2169.422h3.214" transform="translate(0 2)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M813.008,2164.352v15.023" transform="translate(0.512)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅕ_1.svg': { 
		path: <path d="M8.576-7.1H5.552q-.208,0-.208-.192v-.56q0-.192.208-.192H8.576v-4.24a.2.2,0,0,1,.224-.224h.656a.2.2,0,0,1,.224.224v14.3a.2.2,0,0,1-.224.224H8.8a.2.2,0,0,1-.224-.224V-3.008H5.552q-.208,0-.208-.192v-.56q0-.192.208-.192H8.576Z" />, 
		strokes: [
			<path   d="M809.318,2169.422h3.263" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M809.318,2169.422h3.263" transform="translate(0 4)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M813.008,2164.352v15.023" transform="translate(0.512)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅗ_1.svg': { 
		path: <path d="M6.944-1.968V-6.88A.266.266,0,0,1,7-7.048a.182.182,0,0,1,.152-.072H7.84a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v4.912H14.16a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.88a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.64-1.216V-1.76a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.88-1.968Z" />, 
		strokes: [
			<path   d="M804.564,2175.544h13.892" transform="translate(0 0.486)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M811.5,2169.834v5.284" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅛ_1.svg': { 
		path: <path d="M4.544-1.968v-4.9A.266.266,0,0,1,4.6-7.032.182.182,0,0,1,4.752-7.1H5.44a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v4.9H9.264v-4.9a.266.266,0,0,1,.056-.168A.182.182,0,0,1,9.472-7.1h.688a.19.19,0,0,1,.144.072.245.245,0,0,1,.064.168v4.9H14.16a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.88a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.64-1.216V-1.76a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.88-1.968Z" />, 
		strokes: [
			<path   d="M809,2169.772v5.26" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M809,2169.772v5.263" transform="translate(5)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M804.564,2175.544h13.892" transform="translate(0 0.486)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅜ_1.svg': { 
		path: <path d="M8.064-6.016V.352A.266.266,0,0,1,8.008.52a.182.182,0,0,1-.152.072H7.168A.19.19,0,0,1,7.024.52.245.245,0,0,1,6.96.352V-6.016H.88A.245.245,0,0,1,.712-6.08.19.19,0,0,1,.64-6.224v-.544A.182.182,0,0,1,.712-6.92.266.266,0,0,1,.88-6.976H14.16a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064Z" />, 
		strokes: [
			<path   d="M804.594,2170.476h13.9" transform="translate(0 -0.476)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M811.5,2170.931v6.734" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅠ_1.svg': { 
		path: <path d="M10.64-6.016V.464a.266.266,0,0,1-.056.168A.182.182,0,0,1,10.432.7H9.744A.19.19,0,0,1,9.6.632.245.245,0,0,1,9.536.464v-6.48H5.52V.464a.266.266,0,0,1-.056.168A.182.182,0,0,1,5.312.7H4.624A.19.19,0,0,1,4.48.632.245.245,0,0,1,4.416.464v-6.48H.88A.245.245,0,0,1,.712-6.08.19.19,0,0,1,.64-6.224v-.544A.182.182,0,0,1,.712-6.92.266.266,0,0,1,.88-6.976H14.16a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064Z" />, 
		strokes: [
			<path   d="M804.594,2170.476h13.9" transform="translate(0 -0.476)" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.92,2170.922v6.836" fill="none" stroke="#707070" stroke-width="2"/>,
			<path   d="M808.92,2170.922v6.836" transform="translate(5)" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅡ_1.svg': { 
		path: <path d="M14.16-4.944a.278.278,0,0,1,.16.056.176.176,0,0,1,.08.152v.544a.183.183,0,0,1-.08.144.256.256,0,0,1-.16.064H.88a.245.245,0,0,1-.168-.064A.19.19,0,0,1,.64-4.192v-.544a.182.182,0,0,1,.072-.152A.266.266,0,0,1,.88-4.944Z" />, 
		strokes: [
			<path   d="M804.609,2172.6h13.84" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
	'_ㅣ_1.svg': { 
		path: <path d="M8.064,2.016a.2.2,0,0,1-.224.224H7.184a.2.2,0,0,1-.224-.224v-14.3a.2.2,0,0,1,.224-.224H7.84a.2.2,0,0,1,.224.224Z" />, 
		strokes: [
			<path   d="M811.5,2164.389v14.894" fill="none" stroke="#707070" stroke-width="2"/>,
		] 
	},
};