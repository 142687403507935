export default ["차",
  "사과",
  "가다",
  "보다",
  "먹다",
  "네",
  "아니요",
  "커피",
  "물",
  "가방",
  "사무실",
  "학교",
  "저",
  "뭐",
  "책",
  "카메라",
  "사전",
  "핸드폰",
  "사람",
  "우유",
  "학생",
  "사랑",
  "노래",
  "일하다",
  "고양이",
  "날씨",
  "오늘",
  "좋다",
  "하다",
  "오다",
  "사다",
  "마시다",
  "자다",
  "있다",
  "친구",
  "돈",
  "일어나다",
  "진짜",
  "의자",
  "맥주",
  "장갑",
  "밥",
  "엄마",
  "기억",
  "청소",
  "더",
  "영화",
  "읽다",
  "놀다",
  "쉬다",
  "고향",
  "웃다",
  "때리다",
  "보이다",
  "요리",
  "동사",
  "적다",
  "끝나다",
  "팔다",
  "회사원",
  "어디",
  "한국",
  "지금",
  "도착하다",
  "내일",
  "머리",
  "여행",
  "이상하다",
  "기다리다",
  "쓰다",
  "나중에",
  "어제",
  "버리다",
  "술",
  "이사하다",
  "공부하다",
  "후회하다",
  "인정하다",
  "노력",
  "청소하다",
  "요리하다",
  "노래하다",
  "동의하다",
  "운동하다",
  "사랑하다",
  "말",
  "가게",
  "누구",
  "전화",
  "은행",
  "만들다",
  "치즈",
  "어떻게",
  "왜",
  "얼마",
  "얼마나",
  "빨리",
  "찾다",
  "말하다",
  "내다",
  "도서관",
  "옷",
  "자주",
  "크다",
  "서울",
  "여기",
  "아침",
  "선물",
  "남자",
  "한국어",
  "생각하다",
  "주일",
  "캐나다",
  "아내",
  "눈",
  "끝",
  "음악",
  "저녁",
  "의사",
  "미국",
  "일본",
  "아이스크림",
  "영어",
  "만나다",
  "거기",
  "금요일",
  "혼자",
  "정말",
  "입다",
  "예외",
  "아기",
  "여자",
  "생일",
  "치마",
  "비",
  "얼음",
  "방",
  "텔레비전",
  "번",
  "피자",
  "아이",
  "여러분",
  "음식",
  "그리고",
  "빵",
  "그래서",
  "프랑스",
  "많이",
  "같이",
  "대통령",
  "춤추다",
  "선생님",
  "목소리",
  "컴퓨터",
  "피곤하다",
  "새",
  "월요일",
  "화요일",
  "좋아하다",
  "오렌지",
  "딸기",
  "매일",
  "애인",
  "그런데",
  "포도",
  "배",
  "수박",
  "감자",
  "그렇지만",
  "검색",
  "토마토",
  "복숭아",
  "늦게",
  "밤",
  "졸리다",
  "시험",
  "오이",
  "받다",
  "얻다",
  "잔",
  "모르다",
  "나오다",
  "초콜릿",
  "고기",
  "달걀",
  "증거",
  "수요일",
  "목요일",
  "그럼",
  "아주",
  "식당",
  "병원",
  "시내",
  "닫다",
  "수영하다",
  "배우다",
  "분",
  "출근하다",
  "보통",
  "수업",
  "지하철",
  "중요하다",
  "까만색",
  "개",
  "연필",
  "공",
  "몇",
  "남다",
  "다",
  "설탕",
  "시장",
  "토요일",
  "일요일",
  "듣다",
  "거리",
  "지루하다",
  "잠",
  "화장실",
  "호주",
  "공항",
  "이름",
  "여동생",
  "취미",
  "살다",
  "대학교",
  "중국어",
  "가르치다",
  "태어나다",
  "바쁘다",
  "비밀",
  "나이",
  "직장",
  "가족",
  "직업",
  "주다",
  "이야기하다",
  "앉다",
  "싫어하다",
  "버스",
  "주스",
  "아직",
  "점심",
  "올해",
  "극장",
  "신문",
  "가져오다",
  "잡다",
  "준비",
  "일찍",
  "주문하다",
  "바지",
  "조금",
  "비싸다",
  "빠르다",
  "멀다",
  "나쁘다",
  "덥다",
  "전혀",
  "운전하다",
  "잘못하다",
  "달리다",
  "글",
  "글씨",
  "빌리다",
  "숙제",
  "결혼하다",
  "자전거",
  "찍다",
  "사진",
  "편지",
  "계획",
  "풀다",
  "알다",
  "빨간색",
  "가깝다",
  "주말",
  "백화점",
  "서점",
  "회사",
  "오전",
  "힘들다",
  "쉽다",
  "싸다",
  "아름답다",
  "춥다",
  "맵다",
  "귀엽다",
  "아프다",
  "예쁘다",
  "봄",
  "온도",
  "맑다",
  "느리다",
  "오후",
  "다르다",
  "부르다",
  "가득하다",
  "거의",
  "들르다",
  "먼저",
  "같다",
  "방법",
  "해보다",
  "어렵다",
  "착하다",
  "예전",
  "소설",
  "독일",
  "수학",
  "법",
  "교육",
  "하루",
  "모두",
  "층",
  "싫다",
  "슬프다",
  "제일",
  "길다",
  "작다",
  "엎다",
  "맞다",
  "리모콘",
  "만약",
  "맛",
  "환자",
  "나무",
  "벌써",
  "졸업하다",
  "정치",
  "월",
  "언젠가",
  "잠시",
  "만화",
  "그만두다",
  "그렇다",
  "문제",
  "고르다",
  "접다",
  "조심하다",
  "모레",
  "종일",
  "공연",
  "햄버거",
  "부엌",
  "마당",
  "공원",
  "불",
  "돼지",
  "돕다",
  "무섭다",
  "침대",
  "남기다",
  "약속",
  "못하다",
  "속",
  "가지다",
  "나다",
  "왼쪽",
  "길",
  "유명하다",
  "사고",
  "다치다",
  "역사",
  "멋있다",
  "유난히",
  "택시",
  "출구",
  "곳",
  "여러",
  "습관",
  "예문",
  "테이블",
  "미치다",
  "글쎄",
  "종이",
  "여보",
  "할머니",
  "설명하다",
  "말다",
  "만지다",
  "걱정하다",
  "보내다",
  "오래",
  "위",
  "의문",
  "무겁다",
  "책상",
  "옷장",
  "문맥",
  "앞",
  "아래",
  "냉장고",
  "복수",
  "용서하다",
  "축하하다",
  "세탁기",
  "뒤",
  "구두",
  "질문",
  "시작하다",
  "아들",
  "날짜",
  "고치다",
  "옆",
  "방금",
  "청바지",
  "안",
  "소금",
  "별로",
  "키",
  "우산",
  "코트",
  "너무",
  "여우",
  "꼬리",
  "달",
  "시끄럽다",
  "인사",
  "흐리다",
  "행복하다",
  "요즘",
  "교실",
  "제",
  "기숙사",
  "다시",
  "편의점",
  "세탁소",
  "주차장",
  "자동차",
  "소파",
  "즐기다",
  "사적",
  "되다",
  "도시",
  "동안",
  "사정",
  "아버지",
  "꿈",
  "한숨",
  "외국",
  "약",
  "외국어",
  "똑똑하다",
  "재미있다",
  "명사",
  "산책하다",
  "계속",
  "어머니",
  "다음",
  "역",
  "나가다",
  "우리",
  "문법",
  "년",
  "가장제일",
  "바로",
  "한번",
  "집중하다",
  "느낌",
  "혹시",
  "모으다",
  "진행하다",
  "딸",
  "이렇게",
  "열심히",
  "장학금",
  "약하다",
  "비슷하다",
  "외우다",
  "멜론",
  "거짓말",
  "천사",
  "천재",
  "대답",
  "참다",
  "사라지다",
  "복습하다",
  "아마",
  "전",
  "들어오다",
  "노크하다",
  "도망가다",
  "얼굴",
  "시제",
  "현재",
  "형",
  "강의",
  "천천히",
  "좁다",
  "반지",
  "마음",
  "숨기다",
  "농담",
  "조용하다",
  "방해하다",
  "문학",
  "사장",
  "반갑다",
  "어둡다",
  "지하",
  "약간",
  "당근",
  "생선",
  "건강",
  "세계",
  "평화",
  "부모님",
  "교재",
  "휴가",
  "밖",
  "다니다",
  "항상",
  "계속하다",
  "벌다",
  "울다",
  "죽다",
  "꽃",
  "볼펜",
  "공책",
  "주인",
  "셔츠",
  "모자",
  "거스름돈",
  "변하다",
  "가짜",
  "독학",
  "독립",
  "과학",
  "유학",
  "겨울",
  "물건",
  "많다",
  "값",
  "편하다",
  "미안하다",
  "라면",
  "강",
  "벽",
  "지붕",
  "바꾸다",
  "반말",
  "믿다",
  "간단하다",
  "당연하다",
  "불규칙",
  "신발",
  "열쇠",
  "신경",
  "걸리다",
  "냄새",
  "넓다",
  "방학",
  "시원하다",
  "가수",
  "등",
  "반복하다",
  "인기",
  "낫다",
  "잇다",
  "짓다",
  "감기",
  "벗다",
  "씻다",
  "손",
  "위험",
  "연락하다",
  "결정하다",
  "유행",
  "대화",
  "치다",
  "정신",
  "타다",
  "섬",
  "큰길",
  "절대로",
  "경쟁",
  "깨끗하다",
  "연습하다",
  "병",
  "늦다",
  "소리",
  "사실은",
  "일반",
  "문",
  "반",
  "세수하다",
  "빨래하다",
  "걷다",
  "복잡하다",
  "창피하다",
  "필요하다",
  "실망하다",
  "인터넷",
  "잊다",
  "배달",
  "열다",
  "켜다",
  "묻다",
  "깨닫다",
  "더위",
  "반바지",
  "기적",
  "바람",
  "상처",
  "기회",
  "모험",
  "입술",
  "포기하다",
  "자랑하다",
  "복사하다",
  "놓다",
  "식사",
  "회의",
  "공사",
  "중간",
  "부부",
  "약국",
  "던지다",
  "유치하다",
  "아무나",
  "힘",
  "능력",
  "지나다",
  "배우",
  "삶",
  "경찰서",
  "지키다",
  "인도",
  "단점",
  "구경하다",
  "할아버지",
  "누나",
  "계시다",
  "손님",
  "불안하다",
  "완전하다",
  "불만",
  "가끔",
  "비행기",
  "하얗다",
  "달다",
  "소식",
  "관심",
  "여름",
  "간호사",
  "참",
  "살인",
  "덜",
  "돌려주다",
  "우체국",
  "야구",
  "대단하다",
  "어떤",
  "내리다",
  "장소",
  "따뜻하다",
  "분명히",
  "어쩌면",
  "떨어지다",
  "자르다",
  "이제",
  "따라하다",
  "날",
  "훨씬",
  "산",
  "평소에",
  "초대하다",
  "정답",
  "궁금하다",
  "바다",
  "게으르다",
  "시키다",
  "경제",
  "곧",
  "줄",
  "과일",
  "번호",
  "제목",
  "보기",
  "나라",
  "이따가",
  "어느",
  "어휘",
  "댁",
  "샤워하다",
  "이용하다",
  "가구",
  "색깔",
  "계단",
  "기분",
  "끝내다",
  "때문에",
  "며칠",
  "면접",
  "웃기다",
  "작년",
  "그제",
  "뻔하다",
  "떨어뜨리다",
  "잃다",
  "문장",
  "실례하다",
  "잠깐",
  "좀",
  "뜨겁다",
  "높다",
  "짜다",
  "낮다",
  "짧다",
  "종업원",
  "드시다",
  "제발",
  "바나나",
  "마지막",
  "굉장히",
  "부지런하다",
  "신기하다",
  "언니",
  "오빠",
  "직접",
  "수고하다",
  "합격하다",
  "신호",
  "출발하다",
  "멈추다",
  "고맙다",
  "처음",
  "문서",
  "문화",
  "잠들다",
  "가르다",
  "창문",
  "오른쪽",
  "잘",
  "원",
  "이런",
  "함께",
  "남편",
  "시간",
  "퍼센트",
  "모든",
  "몸",
  "밑",
  "이번",
  "생활",
  "사이",
  "그때",
  "서다",
  "물론",
  "넣다",
  "자리",
  "쪽",
  "얘기",
  "생기다",
  "비서",
  "작가",
  "이혼",
  "설마",
  "심심하다",
  "올라가다",
  "사용하다",
  "가운데",
  "서로",
  "희망",
  "그래",
  "입",
  "하늘",
  "그림",
  "교수",
  "아빠",
  "공짜",
  "일등",
  "뛰다",
  "대본",
  "가슴",
  "매우",
  "오르다",
  "지역",
  "갑자기",
  "왕",
  "가을",
  "녹음하다",
  "미리",
  "부탁하다",
  "지각하다",
  "맨날",
  "역시",
  "정보",
  "놀라다",
  "죄송하다",
  "저희",
  "이미",
  "꼭",
  "그리다",
  "하지만",
  "잘하다",
  "학년",
  "이해하다",
  "그렇게",
  "출장",
  "아까",
  "싸우다",
  "숨다",
  "발",
  "살",
  "이유",
  "지내다",
  "예",
  "아저씨",
  "옛날",
  "경찰",
  "건물",
  "자연",
  "어른",
  "팀",
  "담배",
  "숨",
  "걸다",
  "다리",
  "해",
  "언제나",
  "구하다",
  "주소",
  "파랗다",
  "초록색",
  "비다",
  "친절하다",
  "돌아오다",
  "최고",
  "회비",
  "사회",
  "교회",
  "존댓말",
  "에어컨",
  "전화하다",
  "달러",
  "과자",
  "어린이",
  "닦다",
  "곧장",
  "앞으로",
  "아담하다",
  "부럽다",
  "포도주",
  "안녕히",
  "문자",
  "굽다",
  "강아지",
  "지나가다",
  "기차",
  "말씀하시다",
  "괜찮다",
  "눈물",
  "주제",
  "형제",
  "자매",
  "순간",
  "초등학교",
  "호수",
  "숲",
  "속하다",
  "귀",
  "미터",
  "남동생",
  "물어보다",
  "돌아가다",
  "잊어버리다",
  "아파트",
  "성",
  "공기",
  "대다",
  "눌리다",
  "누르다",
  "아니",
  "게임",
  "내려오다",
  "뉴스",
  "안다",
  "센티미터",
  "팔",
  "어깨",
  "과",
  "즐겁다",
  "메시지",
  "내용",
  "단어",
  "영화관",
  "긴장하다",
  "들다",
  "아무도",
  "아무데나",
  "식품",
  "간식",
  "외식하다",
  "후식",
  "걸어가다",
  "똑같다",
  "실수",
  "요리사",
  "적",
  "그릇",
  "드리다",
  "미래",
  "내려가다",
  "쓰레기",
  "남녀",
  "첫날",
  "원래",
  "허리",
  "근처",
  "낮",
  "가볍다",
  "갖다주다",
  "동물",
  "목",
  "코",
  "배고프다",
  "원하다",
  "과거",
  "교통",
  "새벽",
  "불다",
  "한글",
  "손가락",
  "꽤",
  "마르다",
  "말고",
  "그냥",
  "배추",
  "버섯",
  "소고기",
  "이마",
  "축구",
  "밝다",
  "눕다",
  "밉다",
  "게",
  "홍차",
  "차갑다",
  "실력",
  "고등학교",
  "별",
  "귤",
  "소개하다",
  "편",
  "맞은편",
  "비하다",
  "발음",
  "박물관",
  "어서",
  "지다",
  "반찬",
  "계산서",
  "갖다",
  "점원",
  "레슨",
  "학기",
  "또",
  "뚱뚱하다",
  "지도",
  "남쪽",
  "외국인",
  "부인",
  "지난달",
  "시계",
  "잎",
  "스포츠",
  "월급",
  "합계",
  "노랗다",
  "화",
  "첫째",
  "아주머니",
  "잡지",
  "결국",
  "못",
  "거절하다",
  "구석",
  "여전하다",
  "다녀오다",
  "비디오",
  "이쪽",
  "내년",
  "검다",
  "땅",
  "젖다",
  "방송하다",
  "실시간",
  "안녕하다",
  "깨지다",
  "부유하다",
  "계절",
  "닿다",
  "중학교",
  "회색",
  "확실하다",
  "어차피",
  "공유",
  "미인",
  "잃어버리다",
  "지갑",
  "잔돈",
  "표",
  "거울",
  "되돌리다",
  "마룻바닥",
  "떠오르다",
  "울리다",
  "날다",
  "드라마",
  "취직하다",
  "복",
  "강하다",
  "고요하다",
  "목록",
  "위험하다",
  "북쪽",
  "호랑이",
  "없다",
  "파티",
  "자막",
  "구름",
  "인형",
  "쇠고기",
  "카드",
  "도와주다",
  "칼",
  "가늘다",
  "경찰관",
  "오랜만에",
  "아줌마",
  "군대",
  "깎다",
  "호텔",
  "운동장",
  "한복",
  "대신하다",
  "대하다",
  "어리다",
  "때",
  "아이디어",
  "닮다",
  "햇살",
  "모습",
  "선명하다",
  "환하다",
  "비추다",
  "죽이다",
  "책임",
  "한자",
  "별장",
  "상관없다",
  "심장",
  "비둘기",
  "모기",
  "모래",
  "노약자",
  "안경",
  "전철",
  "좌석",
  "따로",
  "이",
  "비누",
  "왠지",
  "신다",
  "지우다",
  "닭",
  "교과서",
  "샌드위치",
  "자연스럽다",
  "일부러",
  "카페é",
  "서쪽",
  "악몽",
  "심각하다",
  "불고기",
  "목욕",
  "상태",
  "경험하다",
  "걸어오다",
  "여권",
  "피아노",
  "청취자",
  "출퇴근하다",
  "메뉴",
  "자장면",
  "휴일",
  "달력",
  "추워지다",
  "기온",
  "집",
  "실패하다",
  "땀",
  "데리고가다",
  "펴다",
  "크게",
  "걸어다니다",
  "소풍",
  "농구",
  "양말",
  "경치",
  "넘다",
  "올라오다",
  "자라다",
  "콜라",
  "대사관",
  "정류장",
  "정확하다",
  "알아보다",
  "이어지다",
  "포함하다",
  "헤어지다",
  "좀처럼",
  "자존심",
  "기사",
  "기억나다",
  "고통",
  "취소하다",
  "창조하다",
  "결혼식",
  "사촌",
  "분류하다",
  "이미지",
  "상품",
  "가격",
  "출판하다",
  "장바구니",
  "특히",
  "야간",
  "자유",
  "휴지",
  "태권도",
  "슈퍼마켓",
  "계란",
  "젊다",
  "학습",
  "상황",
  "각자",
  "실례",
  "숟가락",
  "치약",
  "배부르다",
  "운동화",
  "칠판",
  "주로",
  "최근",
  "되게",
  "공포",
  "무서워하다",
  "새롭다",
  "급행열차",
  "반대",
  "미술",
  "환영하다",
  "뜻하다",
  "탈의실",
  "전기",
  "우연",
  "귀걸이",
  "목걸이",
  "교환학생",
  "전공하다",
  "아시아",
  "성장",
  "장미",
  "사탕",
  "사실",
  "정거장",
  "정차하다",
  "신비하다",
  "오해",
  "사귀다",
  "경고하다",
  "환대",
  "저번",
  "얼른",
  "짧게",
  "시스템",
  "귀중하다",
  "생명",
  "세상",
  "헷갈리다",
  "사업",
  "성함",
  "매력",
  "인상",
  "친하다",
  "공중",
  "빼다",
  "피해",
  "수건",
  "위하다",
  "자녀",
  "십대",
  "반드시",
  "예약",
  "숫자",
  "끄다",
  "자기",
  "떡",
  "가난하다",
  "바라보다",
  "마디",
  "경우",
  "그만하다",
  "검사하다",
  "신형",
  "나서다",
  "가능하다",
  "근무",
  "서운하다",
  "왜냐하면",
  "경기",
  "선배",
  "비키다",
  "멀미",
  "학위",
  "관람",
  "동갑",
  "바보",
  "확인하다",
  "어울리다",
  "과장하다",
  "나타나다",
  "기침",
  "전편",
  "추천하다",
  "식구",
  "차이",
  "넘어가다",
  "느끼다",
  "임금",
  "안내",
  "이기다",
  "언제",
  "야채",
  "관계",
  "여성",
  "국가",
  "배낭",
  "메다",
  "가지",
  "시대",
  "주변",
  "깃발",
  "자꾸",
  "점",
  "쌓다",
  "너머",
  "전쟁",
  "이웃",
  "밀다",
  "인간",
  "너",
  "통하다",
  "화장",
  "따르다",
  "멀리",
  "귀찮다",
  "둘러보다",
  "존경하다",
  "자신",
  "데",
  "막",
  "놓치다",
  "편안하다",
  "아무래도",
  "티켓",
  "기계",
  "자동판매기",
  "언어",
  "선택하다",
  "당분간",
  "갔다오다",
  "코멘트",
  "알리다",
  "비교하다",
  "뇌물",
  "상상하다",
  "더럽다",
  "버릇",
  "행동",
  "결과",
  "남색",
  "빛나다",
  "의미",
  "밝히다",
  "외롭다",
  "다양하다",
  "그림자",
  "바라다",
  "신사",
  "참가하다",
  "해외",
  "사생활",
  "마찬가지",
  "당신",
  "국민",
  "성격",
  "소심하다",
  "사건",
  "또한",
  "작품",
  "반대하다",
  "두다",
  "즉",
  "당시",
  "더욱",
  "지방",
  "부정",
  "부서지다",
  "한심하다",
  "비우다",
  "다가가다",
  "동작",
  "담다",
  "쫓다",
  "가져가다",
  "분량",
  "데려오다",
  "지옥",
  "푸르다",
  "말끔",
  "실제",
  "연구",
  "환경",
  "전문",
  "정리하다",
  "계약",
  "고용하다",
  "기념품",
  "중독",
  "문구",
  "놈",
  "피부",
  "정도",
  "정부",
  "화면",
  "나누다",
  "객실",
  "가까이",
  "자체",
  "시합하다",
  "고장",
  "이틀",
  "물가",
  "요새",
  "질리다",
  "푹",
  "감독하다",
  "활동",
  "불편하다",
  "불완전하다",
  "균형",
  "주의하다",
  "거지",
  "지르다",
  "무게",
  "짐",
  "익숙하다",
  "자세히",
  "현실",
  "부분",
  "기업",
  "변화하다",
  "오토바이",
  "발전",
  "세기",
  "세우다",
  "향하다",
  "세월",
  "해결하다",
  "조건",
  "항공권",
  "제안하다",
  "시절",
  "개인",
  "시기",
  "광고",
  "천연",
  "이르다",
  "사자",
  "행운",
  "빗",
  "그대",
  "수준",
  "표현",
  "분야",
  "질서",
  "전자의",
  "랭킹",
  "우선",
  "순위",
  "지원하다",
  "치과",
  "공간",
  "맡다",
  "선거",
  "일월",
  "감동하다",
  "팬",
  "중심",
  "이루다",
  "최소",
  "차지하다",
  "비명",
  "최소한",
  "자격",
  "형사",
  "수갑",
  "범인",
  "잘생기다",
  "다듬다",
  "양복",
  "든든하다",
  "고모",
  "떠나다",
  "기술",
  "전체",
  "민족",
  "용기",
  "일단",
  "영상",
  "자세",
  "참조하다",
  "구독",
  "검",
  "부드럽다",
  "천둥",
  "저주",
  "화재",
  "수영장",
  "금속",
  "흙",
  "야망",
  "수다",
  "아쉬워하다",
  "썰렁하다",
  "연예",
  "엊그제",
  "승객",
  "뒷문",
  "아르바이트",
  "동네",
  "조사",
  "도로",
  "그대로",
  "구조하다",
  "어마어마하다",
  "솔직하다",
  "내밀다",
  "혀",
  "쓰다듬다",
  "흔들다",
  "진지하다",
  "속옷",
  "짜증",
  "허락하다",
  "질투",
  "가만히",
  "꺼내다",
  "키스",
  "과음하다",
  "말짱하다",
  "의심",
  "응답",
  "원숭이",
  "물다",
  "차이다",
  "남자친구",
  "여자친구",
  "차다",
  "동생",
  "혼나다",
  "전원",
  "소녀",
  "정각",
  "퇴근하다",
  "행사",
  "오히려",
  "프로그램",
  "이루어지다",
  "터",
  "남",
  "의식",
  "둘이",
  "상자",
  "힘세다",
  "장화",
  "칭찬하다",
  "출입",
  "소개팅",
  "신나다",
  "드디어",
  "고객",
  "외딴",
  "대체",
  "시설",
  "차례",
  "성인",
  "초급",
  "무료",
  "자료",
  "신청하다",
  "간접화법",
  "대부분",
  "산업",
  "표정",
  "상식",
  "째려보다",
  "열",
  "코피",
  "피우다",
  "도둑",
  "기대하다",
  "휴대전화",
  "주차하다",
  "갈아타다",
  "절박하다",
  "찬란하다",
  "유산",
  "존재하다",
  "종료",
  "일부",
  "비용",
  "그치다",
  "수입",
  "장면",
  "폭력",
  "목장",
  "무",
  "괴짜",
  "감히",
  "인생",
  "하숙",
  "위엄",
  "명령",
  "이모",
  "재계",
  "욕심",
  "완벽하다",
  "속상하다",
  "외교",
  "훈훈하다",
  "안심하다",
  "김새다",
  "봉사",
  "찾아가다",
  "명절",
  "물품",
  "쌀",
  "동참하다",
  "홀로",
  "피",
  "이불",
  "목표",
  "틀리다",
  "위로하다",
  "아무리",
  "졸다",
  "특별하다",
  "공주",
  "고개",
  "관련하다",
  "형태",
  "분위기",
  "큰일",
  "심하다",
  "재산",
  "요구하다",
  "마치다",
  "도움",
  "빠지다",
  "염색하다",
  "피하다",
  "태도",
  "판단하다",
  "눈치",
  "안색",
  "무슨",
  "명함",
  "바깥",
  "내다보다",
  "막다",
  "기막히다",
  "꼼꼼하다",
  "낳다",
  "열리다",
  "역할",
  "석양",
  "도로변",
  "화살표",
  "말리다",
  "충격",
  "진심으로",
  "제법",
  "녹다",
  "밋밋하다",
  "충분하다",
  "쌍둥이",
  "쳐다보다",
  "원망하다",
  "빤히",
  "본체만체하다",
  "주먹",
  "달려오다",
  "꽉",
  "결심",
  "짓",
  "제공",
  "기념",
  "손잡이",
  "넘어지다",
  "장난",
  "기도하다",
  "날씬하다",
  "수술",
  "나비",
  "수다떨다",
  "연기",
  "국내",
  "기관",
  "견적",
  "열정",
  "의지",
  "선",
  "조언",
  "입장하다",
  "방식",
  "영향",
  "금연",
  "설득하다",
  "매연",
  "살펴보다",
  "사방",
  "환상",
  "농업",
  "의견",
  "넋",
  "거미",
  "대충",
  "곰",
  "노릇",
  "찬물",
  "음반",
  "외모",
  "오죽",
  "끄덕이다",
  "흐르다",
  "깊다",
  "모양",
  "재주",
  "무언가",
  "벌금",
  "독서하다",
  "저장하다",
  "진급하다",
  "소문",
  "걸음",
  "교도소",
  "지지하다",
  "의도",
  "주무시다",
  "제과점",
  "운명",
  "똑바로",
  "가능성",
  "괴롭다",
  "단계",
  "교사",
  "마을",
  "대표",
  "지독하다",
  "침",
  "이전",
  "대상",
  "기간",
  "소년",
  "진지",
  "몸살",
  "참석하다",
  "무시하다",
  "동창",
  "예절",
  "기쁘다",
  "방향",
  "대회",
  "움직이다",
  "단체",
  "갈색",
  "곤충",
  "가리다",
  "아픔",
  "만남",
  "밀치다",
  "지치다",
  "밤새도록",
  "목격하다",
  "실망시키다",
  "뱀",
  "화분",
  "책장",
  "꽂다",
  "옷걸이",
  "꽃병",
  "연애",
  "아깝다",
  "쓸모있다",
  "강조하다",
  "설날",
  "금",
  "연휴",
  "시골",
  "평생",
  "제품",
  "주방",
  "돌리다",
  "자식",
  "주장하다",
  "알려지다",
  "끊다",
  "요란하다",
  "수레",
  "한참",
  "고민",
  "뒷담화",
  "몰래",
  "귀신",
  "저리",
  "금융",
  "내기",
  "도리어",
  "변명",
  "마늘",
  "진가",
  "심사하다",
  "사또",
  "전화번호",
  "실종",
  "예능",
  "지구",
  "태양",
  "주위",
  "돌다",
  "곡",
  "발표",
  "확정하다",
  "통화하다",
  "존중하다",
  "지문",
  "편견",
  "녹음실",
  "단둘",
  "겸손하다",
  "형용사",
  "또는",
  "매력적",
  "주민",
  "동시에",
  "기능",
  "산보하다",
  "아가씨",
  "미혼",
  "고속버스",
  "동쪽",
  "봉투",
  "순수하다",
  "간주하다",
  "완강하다",
  "바탕",
  "목욕탕",
  "깔끔하다",
  "외양",
  "노래자랑",
  "신분",
  "빛",
  "무역",
  "오징어",
  "가위",
  "연극",
  "정상",
  "넥타이",
  "우습다",
  "회장",
  "고아",
  "고모부",
  "테니스",
  "전부",
  "막차",
  "진주",
  "토끼",
  "침묵",
  "호주머니",
  "욕",
  "위치",
  "노동",
  "가치",
  "현대",
  "속다",
  "서류",
  "빠뜨리다",
  "급하다",
  "분실",
  "최선",
  "발행",
  "예정",
  "의지하다",
  "등산",
  "선반",
  "퇴근시간",
  "하필",
  "화내다",
  "효과",
  "당황하다",
  "노출하다",
  "환불",
  "변호사",
  "정장",
  "차표",
  "조절하다",
  "미루다",
  "성공",
  "입학하다",
  "줍다",
  "상영",
  "맡기다",
  "꾸다",
  "숟갈",
  "깨우다",
  "번역",
  "감정",
  "해안",
  "다름없이",
  "놀이터",
  "그네",
  "불교",
  "끌다",
  "대륙",
  "마주보다",
  "번쩍",
  "가정",
  "수필",
  "간절히",
  "홀랑",
  "시선",
  "시상식",
  "볼",
  "이리저리",
  "보호하다",
  "신체",
  "상하다",
  "위협",
  "왜곡하다",
  "새침하다",
  "양다리",
  "비만",
  "인내심",
  "추억",
  "잠기다",
  "부대",
  "총",
  "파리",
  "시청",
  "식탁",
  "과로",
  "통과하다",
  "영원히",
  "지성",
  "비축하다",
  "연료",
  "구분하다",
  "논리적",
  "추측하다",
  "일체",
  "환전하다",
  "천장",
  "쇼핑",
  "탁자",
  "우주",
  "의학",
  "울적하다",
  "무릎",
  "답",
  "각",
  "알맞다",
  "씹다",
  "혼자서",
  "신선하다",
  "빼앗다",
  "지시하다",
  "밑줄",
  "일",
  "함부로",
  "나타내다",
  "명예",
  "기말",
  "문병가다",
  "표시하다",
  "및",
  "종류",
  "경주",
  "우편",
  "쥐",
  "펜",
  "전화기",
  "적어도",
  "낡다",
  "성취하다",
  "취득하다",
  "초인종",
  "상징",
  "뵙다",
  "계산하다",
  "살살",
  "마치",
  "생산하다",
  "농사",
  "못생기다",
  "삼촌",
  "오래되다",
  "건너다",
  "시도하다",
  "오리",
  "남성",
  "남방",
  "부장",
  "급식",
  "금지하다",
  "잠그다",
  "일정하다",
  "교문",
  "교장",
  "풀",
  "무용",
  "수도",
  "언덕",
  "친척",
  "방문하다",
  "믿음",
  "멍청이",
  "기타",
  "손목",
  "잦다",
  "페이지",
  "사모님",
  "설화",
  "갈비",
  "안주",
  "국",
  "밭",
  "심부름",
  "철도",
  "연기하다",
  "집사람",
  "러시아",
  "성냥",
  "부자",
  "날개",
  "검사",
  "부치다",
  "소포",
  "웬일",
  "임무",
  "음료수",
  "살려주다",
  "캠퍼스",
  "안전하다",
  "참치",
  "그립다",
  "눈썹",
  "딱딱하다",
  "불가능하다",
  "머리카락",
  "수상하다",
  "즉시",
  "트럭",
  "흰색",
  "수리하다",
  "공장",
  "간판",
  "부끄럽다",
  "소",
  "발견하다",
  "군중",
  "커피숍",
  "빌딩",
  "눈병",
  "땅콩",
  "체육",
  "초",
  "정문",
  "왼편―",
  "두려워하다",
  "운전사",
  "모이다",
  "시민",
  "제대로",
  "줄거리",
  "회원",
  "가입하다",
  "모음",
  "방대하다",
  "얇다",
  "적당하다",
  "속삭이다",
  "시청자",
  "예비군",
  "생각나다",
  "기름",
  "그늘",
  "완전히",
  "알약",
  "흥미",
  "한편",
  "기자",
  "건너편",
  "회계사",
  "녹차",
  "개성",
  "커튼",
  "숨쉬다",
  "용법",
  "굵다",
  "세다",
  "가로등",
  "화나다",
  "청소기",
  "세금",
  "속이다",
  "통일",
  "원인",
  "제주도",
  "큰형",
  "학원",
  "대기실",
  "작동",
  "부정확",
  "예식장",
  "회식",
  "음식점",
  "어둠",
  "지식",
  "도표",
  "매표소",
  "플랫폼",
  "법원",
  "다방",
  "낚시",
  "붓다",
  "병실",
  "재판",
  "금방",
  "시어머니",
  "독감",
  "예방하다",
  "곁",
  "매달",
  "출소하다",
  "데우다",
  "배탈나다",
  "끓다",
  "동아리",
  "흔하다",
  "당하다",
  "학자",
  "전학가다",
  "특이하다",
  "확보하다",
  "연락처",
  "가해자",
  "불리하다",
  "매다",
  "드물다",
  "책꽂이",
  "쓸데없다",
  "괜히",
  "서두르다",
  "흉기",
  "자정",
  "분수",
  "모시다",
  "삼다",
  "일으키다",
  "조조",
  "부사",
  "서투르다",
  "놀리다",
  "덕분에",
  "불평",
  "주인공",
  "호의",
  "연두색",
  "동양",
  "미용실",
  "요금",
  "기르다",
  "케이크",
  "빨다",
  "부침개",
  "속도",
  "서랍",
  "벌주다",
  "엄청",
  "까다롭다",
  "털다",
  "다행이다",
  "꼬마",
  "발목",
  "늘다",
  "빚",
  "작전",
  "과목",
  "깨끗이",
  "미처",
  "훈련",
  "수저",
  "시험지",
  "부채",
  "똑같이",
  "역사적",
  "충고",
  "증명하다",
  "억지로",
  "준수하다",
  "맞추다",
  "느닷없이",
  "노인",
  "무조건",
  "동료",
  "비결",
  "옥상",
  "목도리",
  "퇴장",
  "싣다",
  "자음",
  "마침내",
  "새로",
  "그러나",
  "공해",
  "콧물",
  "알아듣다",
  "깨다",
  "배반",
  "사직하다",
  "유럽",
  "선적",
  "요청",
  "재촉하다",
  "동그라미",
  "촬영",
  "잡수시다",
  "늘",
  "정열",
  "라디오",
  "달리",
  "붙이다",
  "창고",
  "외출하다",
  "감미롭다",
  "바람나다",
  "손톱",
  "한가지",
  "런던",
  "성당",
  "인물",
  "전국",
  "다만",
  "발걸음",
  "내가다",
  "재벌",
  "여왕",
  "황금",
  "선착순으로",
  "치료",
  "지은이",
  "개미",
  "나",
  "기념일",
  "압력",
  "데이트",
  "추석",
  "국기",
  "어색하다",
  "장점",
  "설거지",
  "모자라다",
  "거실",
  "최종",
  "보고하다",
  "전경",
  "미끄럼",
  "호기심",
  "유감",
  "죄",
  "젓다",
  "군인",
  "어쨌든",
  "등록하다",
  "마감",
  "보험",
  "만족하다",
  "스트레스",
  "감소하다",
  "답답하다",
  "나르다",
  "상세",
  "감사하다",
  "무엇뭐",
  "그",
  "맛있다",
  "아니다",
  "시",
  "클래스",
  "대",
  "튼튼하다",
  "명",
  "권",
  "두껍다",
  "마리",
  "술집",
  "접시",
  "중에서",
  "장",
  "영국",
  "한식",
  "김치",
  "스파게티",
  "양식",
  "노래방",
  "그녀",
  "간장",
  "컵",
  "따라가다",
  "수첩",
  "비빔밥",
  "군데",
  "향수",
  "일식",
  "된장",
  "후배",
  "냉면",
  "깜빡하다",
  "싱겁다",
  "육교",
  "보라색",
  "바닷가",
  "주부",
  "크리스마스",
  "어떻다",
  "신분증",
  "김밥",
  "소주",
  "장보다",
  "사업가",
  "분홍색",
  "지우개",
  "가요",
  "드레스",
  "상추",
  "희다",
  "양파",
  "잘리다",
  "콩",
  "따라오다",
  "생물",
  "꽃집",
  "손수건",
  "햇볕",
  "송이",
  "손자",
  "손녀",
  "철",
  "고정시키다",
  "홍콩",
  "공휴일",
  "만년필",
  "심리학",
  "고백하다",
  "더듬다",
  "따라다니다",
  "결말",
  "대기하다",
  "실내",
  "실외",
  "기후",
  "가사",
  "후추",
  "부동산",
  "치우다",
  "식",
  "무대",
  "붙다",
  "약혼",
  "배구",
  "집안일",
  "한가하다",
  "소중하다",
  "구좌",
  "언짢다",
  "보장하다",
  "장마",
  "밀가루",
  "사거리",
  "차리다",
  "입원하다",
  "희한하다",
  "집집마다",
  "막내",
  "네모",
  "영혼",
  "불쌍하다",
  "채소",
  "동그랗다",
  "빈칸",
  "점수",
  "목적",
  "장난감",
  "매점",
  "허전하다",
  "뿌리",
  "느끼하다",
  "바치다",
  "뉴욕",
  "과외",
  "승용차",
  "양",
  "운반하다",
  "화산",
  "온라인",
  "논리",
  "벌레",
  "빚내다",
  "망설이다",
  "횡재",
  "운",
  "몫",
  "벌",
  "별것",
  "비상",
  "장비",
  "돌",
  "한잔하다",
  "접속",
  "차라리",
  "반년",
  "겨우",
  "마련하다",
  "일어서다",
  "소아과",
  "약사",
  "디자인",
  "구멍",
  "외투",
  "건너가다",
  "회전하다",
  "구입하다",
  "야만적",
  "주급",
  "글자",
  "천국",
  "정직",
  "원장",
  "무너지다",
  "예금",
  "어쩌다가",
  "재료",
  "각각",
  "비겁하다",
  "임신하다",
  "국립",
  "사립학교",
  "교환하다",
  "금년",
  "매번",
  "악담하다",
  "신부",
  "익다",
  "단도",
  "협박하다",
  "도대체",
  "난리",
  "팽창",
  "쫓아가다",
  "충돌",
  "음주운전",
  "매달다",
  "상상력",
  "이리",
  "해고하다",
  "꺼지다",
  "중국집",
  "잘나다",
  "뒷줄",
  "무사히",
  "결코",
  "옮기다",
  "무술",
  "잔혹하다",
  "참외",
  "해산물",
  "달라고하다",
  "재수",
  "실제로",
  "상당히",
  "영광",
  "남산",
  "동대문",
  "변함없이",
  "명성",
  "유지하다",
  "할리우드",
  "악명",
  "멋지다",
  "권한",
  "저절로",
  "집다",
  "저렴하다",
  "새우",
  "레이더",
  "오보",
  "육감",
  "굉장하다",
  "여관",
  "정원",
  "연결하다",
  "굴다",
  "목사님",
  "쌍",
  "위층",
  "부츠",
  "재킷",
  "방송국",
  "사은품",
  "스웨터",
  "스카프",
  "아래층",
  "쉽게",
  "재미있게",
  "괴롭히다",
  "상점",
  "경호",
  "통",
  "부족하다",
  "닳다",
  "다하다",
  "강력하다",
  "화염",
  "의류",
  "갇히다",
  "두드리다",
  "짬",
  "껄끄럽다",
  "살피다",
  "해치다",
  "약점",
  "개인적",
  "회",
  "구체적",
  "경쟁사",
  "철저히",
  "성별",
  "아무튼",
  "어지럽다",
  "유치장",
  "고생하다",
  "상투적",
  "당장",
  "마음대로",
  "이모부",
  "청순하다",
  "극찬",
  "들리다",
  "스치다",
  "번지다",
  "온기",
  "달려들다",
  "게다가",
  "장례식",
  "도자기",
  "파혼하다",
  "겁",
  "챙기다",
  "실시하다",
  "소비자",
  "규모",
  "연세",
  "엉터리",
  "불량하다",
  "핑계",
  "정하다",
  "동침",
  "권유하다",
  "각오하다",
  "꼬시다",
  "넘어오다",
  "두렵다",
  "소감",
  "조회",
  "찌르다",
  "망치",
  "패스하다",
  "기준",
  "샘",
  "갖추다",
  "그러니까",
  "받아들이다",
  "현장",
  "초대장",
  "동창회",
  "이어서",
  "아예",
  "근육",
  "이성",
  "둔하다",
  "설악산",
  "영수증",
  "희생하다",
  "연장하다",
  "안방",
  "숙어",
  "달구다",
  "거듭나다",
  "떠맡다",
  "세끼",
  "가위바위보",
  "승자",
  "따스하다",
  "품",
  "선수",
  "쌓이다",
  "싱크대",
  "온종일",
  "머릿속",
  "불길하다",
  "혁명",
  "진정하다",
  "건네다",
  "포옹하다",
  "건설하다",
  "노동자",
  "전하다",
  "음악회",
  "통장",
  "진하다",
  "기운",
  "감돌다",
  "퇴원하다",
  "남몰래",
  "달콤하다",
  "우연히",
  "모친",
  "부친",
  "자책하다",
  "해명하다",
  "배려",
  "소박하다",
  "물러서다",
  "흘리다",
  "껴안다",
  "활짝",
  "다정하다",
  "소생하다",
  "체포하다",
  "연기자",
  "코너",
  "기록하다",
  "야근하다",
  "화제",
  "최신",
  "긁다",
  "복권",
  "직관",
  "가정부",
  "풍경",
  "기호",
  "물결",
  "종교",
  "승인",
  "비난하다",
  "떠들다",
  "실업",
  "곤란하다",
  "안타깝다",
  "느껴지다",
  "자발적",
  "예고",
  "신간",
  "서명",
  "댓글",
  "호흡",
  "평일",
  "품목",
  "주군",
  "중식",
  "소원",
  "개월",
  "한마디",
  "이데올로기",
  "대중",
  "합치다",
  "하숙집",
  "입력하다",
  "구매",
  "감상하다",
  "말투",
  "유리",
  "빌다",
  "아끼다",
  "의외",
  "음",
  "비참하다",
  "버티다",
  "붙잡다",
  "미만",
  "청소년",
  "적절하다",
  "이목",
  "시청률",
  "최저",
  "노임",
  "한식집",
  "할인",
  "각종",
  "늘어나다",
  "되찾다",
  "호",
  "유배",
  "아쉽다",
  "막바지",
  "즐겁게",
  "상속하다",
  "어린아이",
  "다그치다",
  "수많다",
  "딛다",
  "사소하다",
  "웃음",
  "손길",
  "얼어붙다",
  "버전",
  "잠자리",
  "스키",
  "짝",
  "태국",
  "공무원",
  "자택",
  "삼각형",
  "혹은",
  "응급",
  "종반",
  "시달리다",
  "짝사랑",
  "설정",
  "이상야릇하다",
  "몰다",
  "반전",
  "애쓰다",
  "부리다",
  "투혼",
  "전설",
  "신",
  "훌륭하다",
  "동거하다",
  "생시",
  "다행히",
  "그럭저럭",
  "싱가포르",
  "육개장",
  "은근히",
  "참고",
  "재우다",
  "찬성",
  "화해하다",
  "터미널",
  "캄캄하다",
  "수신자",
  "제국",
  "망하다",
  "깜짝",
  "남김없이",
  "그리",
  "영원하다",
  "감싸다",
  "살아가다",
  "바람둥이",
  "연하",
  "어젯밤",
  "들어가다",
  "희미하다",
  "꿈꾸다",
  "맞이하다",
  "거치다",
  "나아가다",
  "화가",
  "쐬다",
  "면도하다",
  "잔디",
  "보상",
  "진단",
  "이만",
  "교복",
  "엉망",
  "넘어뜨리다",
  "점점",
  "새끼",
  "욕먹다",
  "와인",
  "사이다",
  "와이셔츠",
  "호응",
  "차별",
  "계급",
  "검찰",
  "교무실",
  "집단",
  "빈자리",
  "집착",
  "스키장",
  "지켜보다",
  "부주의",
  "만원",
  "인류",
  "특징",
  "부당하다",
  "부적절하다",
  "까다",
  "돌보다",
  "건드리다",
  "위선",
  "너희",
  "취침",
  "귀띔",
  "주식",
  "야단치다",
  "각설하다",
  "갚다",
  "의논하다",
  "취하다",
  "텐트",
  "애들아이들",
  "멀어지다",
  "닥치다",
  "감당하다",
  "싸움하다",
  "호적",
  "남매",
  "우정",
  "참여하다",
  "가물가물하다",
  "지도하다",
  "제약",
  "정해지다",
  "강도",
  "늦잠",
  "듯하다",
  "필수",
  "규칙",
  "부러지다",
  "뜨다",
  "우표",
  "체육관",
  "권투",
  "채널",
  "숙소",
  "묵다",
  "벌떡",
  "빨개지다",
  "양보하다",
  "예의",
  "바르다",
  "촌스럽다",
  "대낮",
  "띄다",
  "조카",
  "귀여워하다",
  "당번",
  "순번",
  "국수",
  "언제든지",
  "뼈",
  "영양",
  "포장",
  "중요시하다",
  "담기다",
  "졸업식",
  "탕수육",
  "볶음밥",
  "짬뽕",
  "콤비",
  "카레",
  "식혜",
  "고추",
  "형식",
  "걸치다",
  "겪다",
  "한턱내다",
  "편리하다",
  "한산하다",
  "오답",
  "삐다",
  "썰다",
  "베개",
  "베다",
  "뛰어가다",
  "편찮다",
  "태워주다",
  "빚지다",
  "본업",
  "백수",
  "왕관",
  "울음",
  "해당하다",
  "과정",
  "예술",
  "대학",
  "채",
  "현상",
  "작업",
  "올리다",
  "행위",
  "장관",
  "삭제하다",
  "고소",
  "언급",
  "계집아이",
  "빌려주다",
  "인심",
  "제복",
  "뜬금없이",
  "가만두다",
  "대한민국",
  "사내아이",
  "키우다",
  "기죽다",
  "상대",
  "외계인",
  "나머지",
  "예고편",
  "돋보이다",
  "물고기",
  "생신",
  "돌아가시다",
  "일기",
  "쫓아오다",
  "눈사람",
  "골프",
  "도전",
  "기초",
  "격려하다",
  "굽",
  "까맣다",
  "주황색",
  "휴게실",
  "괘씸하다",
  "개구리",
  "샴페인",
  "관점",
  "기본",
  "구박하다",
  "정략",
  "선택권",
  "보류하다",
  "단순하다",
  "실체",
  "놀랍다",
  "우승",
  "돌멩이",
  "상쾌하다",
  "비꼬다",
  "살짝",
  "엄연하다",
  "주민등록증",
  "엿듣다",
  "온수",
  "떨다",
  "구성",
  "관리",
  "형수",
  "서방",
  "시상",
  "입맛",
  "방안",
  "된장찌개",
  "야경",
  "연구실",
  "학비",
  "이메일",
  "피다",
  "소독하다",
  "예매하다",
  "치사하다",
  "태극기",
  "갈다",
  "기세",
  "직원",
  "부하",
  "승진",
  "박히다",
  "터널",
  "목숨",
  "겁쟁이",
  "올림픽",
  "악기",
  "연주하다",
  "외치다",
  "오직",
  "늑대",
  "위기",
  "자살",
  "심다",
  "끓이다",
  "잡히다",
  "감다",
  "뽀뽀",
  "개다",
  "국제적",
  "찌푸리다",
  "찌다",
  "헤어스타일",
  "젓가락",
  "기내식",
  "한강",
  "동물원",
  "반사하다",
  "노트하다",
  "여쭤보다",
  "밤새",
  "적립",
  "노름하다",
  "오래간만",
  "과장님",
  "지겹다",
  "기대다",
  "일기예보",
  "유언",
  "사다리",
  "경복궁",
  "부산",
  "현금",
  "어찌",
  "상담",
  "연예인",
  "회견",
  "대만",
  "북한",
  "처리하다",
  "조깅하다",
  "최악",
  "식후",
  "무리",
  "굶다",
  "꼬다",
  "수수께끼",
  "비타민",
  "워낙",
  "내복",
  "귀하",
  "복용하다",
  "미장원",
  "장난치다",
  "보여주다",
  "제비",
  "반납하다",
  "눈싸움",
  "영구히",
  "제출하다",
  "가구점",
  "반도",
  "암기하다",
  "거꾸로",
  "지극히",
  "소스",
  "하이힐",
  "줄이다",
  "사흘",
  "진열하다",
  "설렁탕",
  "자",
  "목적지",
  "칫솔",
  "사표",
  "상사",
  "빵집",
  "화장품",
  "효자",
  "구조",
  "단층집",
  "침실",
  "욕실",
  "차고",
  "탁구",
  "무선",
  "가스",
  "동전",
  "기본적",
  "첫눈",
  "태연하다",
  "창밖",
  "새하얗다",
  "횡설수설하다",
  "마주치다",
  "대사",
  "여배우",
  "포근하다",
  "예감",
  "배경",
  "바래다",
  "찜질방",
  "떨리다",
  "일거리",
  "전차",
  "보트",
  "시간표",
  "모범",
  "들려주다",
  "횟수",
  "늘리다",
  "작곡",
  "제작",
  "성향",
  "끌어내다",
  "발매",
  "유효하다",
  "방면",
  "인천",
  "여행사",
  "도망치다",
  "훔치다",
  "제자리",
  "맴돌다",
  "독",
  "못되다",
  "바뀌다",
  "황당하다",
  "경솔하다",
  "성적",
  "인연",
  "마술",
  "결론",
  "이기적",
  "호감",
  "조치",
  "절반",
  "거스르다",
  "미련하다",
  "끔찍하다",
  "대박",
  "유혹하다",
  "우울하다",
  "급속히",
  "악수",
  "평범하다",
  "괴물",
  "도둑질하다",
  "사투리",
  "처녀",
  "기웃기웃",
  "애원하다",
  "공격",
  "두부",
  "관사",
  "공개하다",
  "수수료",
  "억양",
  "표준",
  "여리다",
  "순하다",
  "만두",
  "섞다",
  "대표적",
  "취향",
  "미소",
  "메달",
  "따다",
  "달래다",
  "감격하다",
  "세트",
  "과제",
  "불행하다",
  "장사",
  "밀리다",
  "몽땅",
  "우체부",
  "세일",
  "면허증",
  "지저분하다",
  "사치품",
  "비롯하다",
  "과연",
  "틀다",
  "습도",
  "무덥다",
  "건조하다",
  "대체로",
  "식다",
  "흑인",
  "전통",
  "명물",
  "현대인",
  "고독하다",
  "떡볶이",
  "마침",
  "불쾌하다",
  "인터뷰",
  "사납다",
  "그저께",
  "전해주다",
  "시다",
  "초밥",
  "일식집",
  "눅눅하다",
  "장마철",
  "내내",
  "사인",
  "견디다",
  "그룹",
  "억",
  "다투다",
  "신청곡",
  "세대",
  "마음속",
  "흥정하다",
  "공평하다",
  "생물학",
  "경영",
  "국적",
  "품질",
  "관광",
  "모델",
  "유용하다",
  "휴지통",
  "은은하다",
  "향기",
  "편도",
  "수정과",
  "기린",
  "중앙",
  "생활비",
  "불꽃",
  "국제선",
  "커다랗다",
  "시인",
  "왼손",
  "평가",
  "달라지다",
  "삶다",
  "통신",
  "코끼리",
  "맏아들",
  "우애",
  "내적",
  "자랑거리",
  "자동",
  "돌아다니다",
  "양심",
  "유괴하다",
  "유머",
  "시력",
  "도서",
  "딱",
  "오른손",
  "장남",
  "고구마",
  "국경",
  "탑",
  "재혼하다",
  "바둑",
  "온천",
  "놀이",
  "스케이트",
  "공상과학",
  "책방",
  "피서",
  "쌀쌀하다",
  "부수",
  "인구",
  "항의",
  "꿀",
  "수십",
  "부담",
  "일상",
  "최대",
  "칠순",
  "영토",
  "음력",
  "월세",
  "어귀",
  "항구",
  "장녀",
  "하반기",
  "모임",
  "물질",
  "외할머니",
  "아나운서",
  "엄청나다",
  "폭발하다",
  "첩",
  "얌전하다",
  "따님",
  "신고하다",
  "솜씨",
  "순서",
  "시동",
  "억울하다",
  "짝퉁",
  "쪽팔리다",
  "충전하다",
  "등신",
  "중지하다",
  "부디",
  "내",
  "유료",
  "장인",
  "백성",
  "청년",
  "돌이키다",
  "용의자",
  "데모",
  "끼다",
  "식모",
  "들러리",
  "업체",
  "펴놓다",
  "선비",
  "파악하다",
  "실천",
  "공동",
  "까닭",
  "이윽고",
  "한눈",
  "나뉘다",
  "눈부시다",
  "빈틈",
  "방패",
  "입찰",
  "작성하다",
  "미역국",
  "금발",
  "수염",
  "이상형",
  "애교",
  "센터",
  "건장하다",
  "혈압",
  "용납하다",
  "험하다",
  "상대하다",
  "물러나다",
  "무례하다",
  "통역하다",
  "색칠하다",
  "영리하다",
  "지혜",
  "독특하다",
  "처지",
  "미숙하다",
  "조미료",
  "식용유",
  "해바라기",
  "깨",
  "물끄러미",
  "금품",
  "몸매",
  "듬직하다",
  "활발하다",
  "자상하다",
  "분석",
  "관계자",
  "지적하다",
  "신청서",
  "온전하다",
  "고급",
  "음절",
  "앞면",
  "북녘",
  "펭귄",
  "해변",
  "관광객",
  "요원",
  "장물",
  "순진하다",
  "쏘다",
  "앙큼하다",
  "연락선",
  "첫인상",
  "투표하다",
  "투자하다",
  "수익",
  "신제품",
  "신속하다",
  "마지못해",
  "숙면",
  "털",
  "얼빠지다",
  "악성",
  "포장마차",
  "검은색",
  "빨갛다",
  "노란색",
  "녹색",
  "빨강",
  "청색",
  "검정",
  "하양",
  "하늘색",
  "밤색",
  "노랑",
  "핑크",
  "고동색",
  "파랑",
  "연보라",
  "하얀색",
  "바래다주다",
  "입구",
  "현관",
  "섭섭하다",
  "말대꾸하다",
  "꼬박꼬박",
  "잔뜩",
  "시리다",
  "택배",
  "꿇다",
  "인턴",
  "엽서",
  "찾아오다",
  "극복하다",
  "실연하다",
  "배신하다",
  "사직서",
  "힘내다",
  "전처",
  "야무지다",
  "출연하다",
  "원기",
  "밥상",
  "씩씩하다",
  "덮다",
  "탈락하다",
  "막히다",
  "재미없다",
  "잘못되다",
  "쓰레기통",
  "저기",
  "오븐",
  "욕조",
  "변기",
  "장식장",
  "주전자",
  "화장대",
  "계획표",
  "소방관",
  "주유소",
  "일과표",
  "투명하다",
  "유행가",
  "집들이",
  "저쪽",
  "그쪽",
  "수영복",
  "서늘하다",
  "일출",
  "전하",
  "구역",
  "삽",
  "대장",
  "팔씨름하다",
  "뺏다",
  "야단맞다",
  "고자질하다",
  "강의실",
  "시시하다",
  "새치기하다",
  "가버리다",
  "절대",
  "솥",
  "피해자",
  "뻔뻔하다",
  "올바로",
  "화창하다",
  "통통하다",
  "머물다",
  "터지다",
  "추가하다",
  "요일",
  "횡단보도",
  "지하도",
  "허리띠",
  "그래도",
  "더하다",
  "정오",
  "놀이공원",
  "고아원",
  "다락",
  "갈아입다",
  "신호등",
  "안부",
  "화랑",
  "추위",
  "싸늘하다",
  "어리석다",
  "뾰족하다",
  "분실물",
  "가죽",
  "연하다",
  "탄탄하다",
  "뒤돌아보다",
  "친숙하다",
  "그리움",
  "또래",
  "뒤처지다",
  "표준어",
  "생각",
  "탈출하다",
  "함정",
  "불타다",
  "업다",
  "파업",
  "썩다",
  "통제",
  "하찮다",
  "사진기",
  "필통",
  "정열적",
  "회화",
  "재다",
  "이별하다",
  "도저히",
  "포위하다",
  "명심하다",
  "고무",
  "문방구",
  "사기꾼",
  "복도",
  "죽",
  "감추다",
  "원칙",
  "양초",
  "처자",
  "공지하다",
  "얕다",
  "다람쥐",
  "볶다",
  "수줍다",
  "진실되다",
  "맹세하다",
  "파트너",
  "발달하다",
  "한라산",
  "확대하다",
  "배송",
  "찌개",
  "영화제",
  "높임말",
  "신입사원",
  "옆집",
  "연주회",
  "벽보",
  "고사하다",
  "합창단",
  "후보",
  "앞서",
  "숙이다",
  "되뇌다",
  "고집",
  "잠수하다",
  "널리",
  "이롭다",
  "만일",
  "답장",
  "먹이",
  "녹음기",
  "전시하다",
  "원피스",
  "가곡",
  "들키다",
  "상견례",
  "교훈",
  "양쪽",
  "실컷",
  "연수",
  "알몸",
  "용돈",
  "해수",
  "두통",
  "집안",
  "아웃",
  "신중하다",
  "팔꿈치",
  "단위",
  "모집하다",
  "죽음",
  "등장하다",
  "한계",
  "솜",
  "선전",
  "소방서",
  "애완동물",
  "헬스클럽",
  "연속",
  "연인",
  "화장지",
  "저항",
  "손해",
  "급히",
  "문패",
  "혼동하다",
  "번갈다",
  "낙엽",
  "여유",
  "난로",
  "파괴하다",
  "임시",
  "흔히",
  "갈등",
  "초기",
  "이론",
  "골탕먹다",
  "사기",
  "풀리다",
  "탓",
  "끝장",
  "회복하다",
  "녹화",
  "기자회견",
  "무지",
  "군소리",
  "이어폰",
  "웬만하다",
  "잽싸다",
  "이륙하다",
  "뛰어나다",
  "한반도",
  "풍부하다",
  "인근",
  "어긋나다",
  "설레다",
  "절망",
  "이성적",
  "날아가다",
  "맺다",
  "방울",
  "흘러내리다",
  "모질다",
  "머무르다",
  "그저",
  "얼다",
  "잡채",
  "먼지",
  "멋대로",
  "주의사항",
  "질색하다",
  "양치질하다",
  "반응",
  "건배",
  "원수",
  "곱다",
  "스타일",
  "데뷔하다",
  "출렁거리다",
  "재난",
  "암",
  "스킨십",
  "박다",
  "크레파스",
  "가루",
  "켤레",
  "학과",
  "언론",
  "없어지다",
  "주정하다",
  "껍데기",
  "녀석",
  "미끈미끈하다",
  "맨손",
  "부딪치다",
  "기절하다",
  "지나치다",
  "결승",
  "혈액",
  "일반적",
  "관습",
  "근시",
  "교정하다",
  "오싹하다",
  "원점",
  "수집하다",
  "유리하다",
  "법칙",
  "육지",
  "신하",
  "감염",
  "정지하다",
  "수명",
  "밤새우다",
  "엘리베이터",
  "난방",
  "불어",
  "망치다",
  "외향적",
  "탐내다",
  "어기다",
  "의젓하다",
  "진학하다",
  "낭만",
  "사연",
  "예정일",
  "착수하다",
  "단조롭다",
  "이력",
  "이주하다",
  "애매하다",
  "특집",
  "사막",
  "출신",
  "동영상",
  "냉정하다",
  "관광지",
  "배드민턴",
  "이끌다",
  "콘센트",
  "여기다",
  "굴뚝",
  "평평하다",
  "따갑다",
  "놓아두다",
  "정화",
  "뱉다",
  "천벌",
  "정밀",
  "구석구석",
  "멎다",
  "논문",
  "문화재",
  "여신",
  "파도",
  "종영",
  "엉덩이",
  "합의",
  "선약",
  "피신하다",
  "청춘",
  "건국",
  "금실",
  "어림",
  "지휘하다",
  "죄목",
  "붉다",
  "용감하다",
  "등불",
  "안개",
  "주동자",
  "충성",
  "엎드리다",
  "뻗다",
  "토박이",
  "오냐오냐하다",
  "사양",
  "소장",
  "수속",
  "생생하다",
  "당사자",
  "면전",
  "주저하다",
  "맞선",
  "기약",
  "납득하다",
  "주선하다",
  "딴",
  "독신",
  "최대한",
  "미화하다",
  "주름",
  "건축",
  "뒤지다",
  "미모",
  "겸비하다",
  "웅변",
  "어림없다",
  "납기일",
  "울보",
  "낭비하다",
  "기어이",
  "거칠다",
  "사오다",
  "쟁반",
  "체조",
  "회계",
  "동호회",
  "풍선",
  "높이",
  "뚫다",
  "업무",
  "내놓다",
  "줄곧",
  "연말",
  "유부남",
  "솟다",
  "헌",
  "간섭하다",
  "가보다",
  "맞히다",
  "체험",
  "데다",
  "단골",
  "원한",
  "넉넉하다",
  "초조하다",
  "공감",
  "적중하다",
  "의리",
  "영웅",
  "멀뚱멀뚱",
  "건축물",
  "인삼",
  "거문고",
  "굴",
  "소매치기",
  "과식하다",
  "복장",
  "체결하다",
  "빈방",
  "은혜",
  "큰아버지",
  "큰어머니",
  "스페인",
  "운동복",
  "국어",
  "민속촌",
  "남미",
  "소설가",
  "끊어지다",
  "메모",
  "서양",
  "전화비",
  "킬로그램",
  "시외",
  "다이어트",
  "기뻐하다",
  "수돗물",
  "업소",
  "합창",
  "외삼촌",
  "외할아버지",
  "파",
  "줄다",
  "마약",
  "샴푸",
  "멕시코",
  "경기장",
  "서먹하다",
  "남학생",
  "영하",
  "주머니",
  "사원",
  "점퍼",
  "찾아내다",
  "조그마하다",
  "소형",
  "면세점",
  "쿨",
  "아랍",
  "사물",
  "즐거움",
  "태풍",
  "학생증",
  "고추장",
  "로션",
  "내과",
  "벚꽃",
  "선풍기",
  "아프리카",
  "나빠지다",
  "주택",
  "대학원",
  "봉지",
  "깃",
  "베트남",
  "음악가",
  "담그다",
  "증세",
  "스타킹",
  "무궁화",
  "나흘",
  "여학생",
  "떡국",
  "후원",
  "반성",
  "절",
  "가두다",
  "남대문",
  "브라질",
  "소화",
  "축제",
  "공중전화",
  "박수",
  "외숙모",
  "종로",
  "닫히다",
  "패션",
  "보육원",
  "냄비",
  "실물",
  "보고서",
  "작은아버지",
  "작은어머니",
  "바이올린",
  "잔치",
  "삼계탕",
  "몽골",
  "독하다",
  "블라우스",
  "껌",
  "미술관",
  "올려놓다",
  "대학원생",
  "소년원",
  "골목",
  "액세서리",
  "주사",
  "베이징",
  "무척",
  "탤런트",
  "연봉",
  "스스로",
  "쓸쓸하다",
  "레몬",
  "이거",
  "이쪽으로",
  "오월",
  "유월",
  "이월",
  "삼월",
  "씨",
  "일본어",
  "사월",
  "칠월",
  "한국말",
  "팔월",
  "구월",
  "시월",
  "십일월",
  "십이월",
  "지난주",
  "건강하다",
  "날마다",
  "세탁",
  "닭고기",
  "부탁",
  "팥",
  "시장보다",
  "가전제품",
  "조용히",
  "팔찌",
  "여전히",
  "솔직히",
  "바닥",
  "대학생",
  "가습기",
  "이상",
  "대단히",
  "비밀번호",
  "별일",
  "사계절",
  "새해",
  "국제",
  "국물",
  "대구",
  "세계적",
  "판매",
  "인사동",
  "안내하다",
  "크기",
  "편히",
  "좌회전하다",
  "신혼여행",
  "특별히",
  "직행",
  "옥수수",
  "우회전하다",
  "때때로",
  "매년",
  "티셔츠",
  "주요",
  "늙다",
  "전문가",
  "익숙해지다",
  "강남",
  "콘서트",
  "옳다",
  "물음",
  "드라이어",
  "다가오다",
  "손바닥",
  "월말",
  "적극적",
  "개발하다",
  "젊은이",
  "점차",
  "길이",
  "높이다",
  "형님",
  "놓이다",
  "발생하다",
  "에너지",
  "계산기",
  "밥솥",
  "찾아보다",
  "뿌리다",
  "가리키다",
  "박사",
  "팝송",
  "단지",
  "낮잠",
  "뽑다",
  "불리다",
  "교포",
  "도시락",
  "몇몇",
  "명동",
  "식물",
  "이익",
  "어려움",
  "온",
  "오랫동안",
  "농촌",
  "잘되다",
  "한쪽",
  "그만큼",
  "찻잔",
  "쥐다",
  "벌어지다",
  "커지다",
  "시리즈",
  "폭",
  "조상",
  "승무원",
  "상대방",
  "강물",
  "문제점",
  "꾸미다",
  "깊이",
  "비교적",
  "예술가",
  "몹시",
  "최초",
  "과학자",
  "한두",
  "서비스",
  "춘천",
  "활용하다",
  "미역",
  "가꾸다",
  "생년월일",
  "감옥",
  "중요성",
  "차비",
  "온돌방",
  "번개",
  "선글라스",
  "거품",
  "채우다",
  "식빵",
  "이동",
  "너무하다",
  "신랑",
  "누구나",
  "오페라",
  "김",
  "한발",
  "선선하다",
  "생겨나다",
  "종합",
  "응원하다",
  "사회적",
  "틀림없다",
  "달려가다",
  "고속도로",
  "관객",
  "프로",
  "화려하다",
  "귀고리",
  "다리다",
  "종",
  "살리다",
  "해수욕장",
  "이천",
  "건지다",
  "쏟다",
  "바닷물",
  "약도",
  "뮤지컬",
  "진찰",
  "옛날얘기",
  "유치원",
  "동남아",
  "액자",
  "밟다",
  "없애다",
  "매진",
  "이태원",
  "미끄럽다",
  "예상하다",
  "테이프",
  "눈앞",
  "이하",
  "빠져나가다",
  "색연필",
  "팔리다",
  "신혼부부",
  "스케줄",
  "부족",
  "감동받다",
  "붕대",
  "어느새",
  "먹이다",
  "관찰하다",
  "대전",
  "저금하다",
  "액션",
  "자유롭다",
  "대학로",
  "미용",
  "웨딩드레스",
  "건너",
  "이발하다",
  "관악산",
  "아리랑",
  "광주",
  "빗물",
  "중고품",
  "돌잔치",
  "다리미",
  "토론",
  "샌들",
  "감독",
  "덕수궁",
  "인삼차",
  "둥글다",
  "움직임",
  "예습",
  "숙녀",
  "유치원생",
  "큰소리",
  "다지다",
  "도장",
  "왕복",
  "쏟아지다",
  "강연회",
  "무늬",
  "코미디",
  "봉급",
  "손등",
  "소극장",
  "빠져나오다",
  "볼링",
  "노약자석",
  "노트북",
  "속초",
  "수출",
  "근로자",
  "윷놀이",
  "혼내다",
  "파인애플",
  "켜져있다",
  "톤",
  "풍습",
  "연구소",
  "항공",
  "내부",
  "불국사",
  "외부",
  "파마하다",
  "찢다",
  "클래식",
  "마사지",
  "이민",
  "빈대떡",
  "조끼",
  "슬리퍼",
  "물냉면",
  "전통적",
  "인상적",
  "재즈",
  "현실적",
  "안동",
  "자가용",
  "발라드",
  "탈",
  "명랑하다",
  "홈페이지",
  "책임지다",
  "싱싱하다",
  "저울",
  "동해",
  "실",
  "전시회",
  "세배",
  "설사",
  "남해",
  "마라톤",
  "핸드백",
  "매운탕",
  "사위",
  "벌이다",
  "소비",
  "너무나",
  "장군",
  "낯",
  "들려오다",
  "폭풍",
  "대개",
  "차이점",
  "물약",
  "직후",
  "총장",
  "소매",
  "간첩",
  "교외",
  "흥분하다",
  "권총",
  "뚜렷하다",
  "안과",
  "이발소",
  "결백하다",
  "기술자",
  "판소리",
  "장부",
  "축복",
  "농부",
  "팩스",
  "죄책감",
  "파스",
  "급료",
  "교대",
  "증가하다",
  "은행원",
  "답변",
  "짙다",
  "잠바",
  "오누이",
  "어르신",
  "환상적",
  "반팔",
  "엑스레이",
  "매체",
  "을지로",
  "육박하다",
  "애절하다",
  "카세트",
  "세차하다",
  "무모하다",
  "책임자",
  "넘어서다",
  "서너",
  "자주색",
  "불결하다",
  "따끈따끈하다",
  "통조림",
  "매",
  "파일",
  "처방",
  "단풍",
  "전주",
  "안약",
  "고려하다",
  "상품권",
  "여의도",
  "압구정",
  "평균",
  "이래",
  "최고급",
  "조명하다",
  "엔지니어",
  "무렵",
  "뺨맞다",
  "얼룩",
  "새집",
  "착각하다",
  "드러내다",
  "유력하다",
  "음성",
  "잠실",
  "올바르다",
  "위대하다",
  "명상",
  "영업시간",
  "직전",
  "앞지르다",
  "급여",
  "논",
  "동점",
  "정형외과",
  "꼴불견",
  "봉사활동",
  "옛일",
  "교과목",
  "충전기",
  "청구서",
  "계산원",
  "재채기하다",
  "교양",
  "세균",
  "암소",
  "성형수술",
  "휴식",
  "자재",
  "인하",
  "경청하다",
  "백과사전",
  "퇴직",
  "대우하다",
  "접수",
  "도심지",
  "피로",
  "수거하다",
  "첨부하다",
  "안락하다",
  "무덤",
  "재방송",
  "당당하다",
  "추리소설",
  "경력",
  "준비물",
  "무관심하다",
  "얄밉다",
  "무기",
  "우유부단하다",
  "필독",
  "눈보라",
  "강요",
  "농장",
  "방귀",
  "충동",
  "민주주의",
  "이내",
  "삐치다",
  "독자",
  "변경",
  "정체",
  "드러나다",
  "섬세하다",
  "막걸리",
  "경쟁자",
  "걷히다",
  "용모",
  "사이좋게",
  "난민",
  "식량",
  "금붕어",
  "새장",
  "인증",
  "간직하다",
  "면",
  "심지어",
  "유령",
  "긍정적",
  "반나절",
  "악취",
  "죄짓다",
  "소망",
  "중국",
  "망원경",
  "게시판",
  "민생",
  "친부모",
  "연설",
  "선입견",
  "주먹밥",
  "수사",
  "열애",
  "소속사",
  "교제",
  "시점",
  "차원",
  "떠가다",
  "측",
  "게재하다",
  "목격자",
  "게시물",
  "동행하다",
  "목청껏",
  "전날",
  "서리",
  "유부녀",
  "묘",
  "아래쪽",
  "상반기",
  "제왕",
  "사슴",
  "미신",
  "결석하다",
  "괴담",
  "사치스럽다",
  "총동원하다",
  "멍청하다",
  "패배하다",
  "총점",
  "왕자",
  "상인",
  "애정",
  "과시하다",
  "배신자",
  "까먹다",
  "야식",
  "홍수",
  "따로따로",
  "사돈",
  "창가",
  "보안",
  "직진하다",
  "치수",
  "완결",
  "사냥하다",
  "야외",
  "질병",
  "접하다",
  "띄우다",
  "속담",
  "하마",
  "호두",
  "좋아지다",
  "닷새",
  "하여튼",
  "뇌",
  "벼",
  "웨이터",
  "성실하다",
  "애독자",
  "햄",
  "베이지색",
  "접촉",
  "수화기",
  "액운",
  "명단",
  "어학연수",
  "책자",
  "입장권",
  "과수원",
  "동구",
  "거르다",
  "끼니",
  "검사관",
  "당첨되다",
  "선곡",
  "마무리하다",
  "욕설",
  "입장",
  "학제",
  "이력서",
  "연차",
  "월차",
  "거래",
  "매출",
  "협상",
  "접대",
  "선박",
  "정감",
  "개학하다",
  "어설프다",
  "돌아서다",
  "아득하다",
  "익명",
  "문맹",
  "손발",
  "예민하다",
  "상무",
  "긴장감",
  "무난하다",
  "오그라들다",
  "어미",
  "일꾼",
  "다소",
  "산뜻하다",
  "차분하다",
  "기일",
  "소송",
  "변태",
  "출세하다",
  "음모",
  "계략",
  "문득",
  "내쉬다",
  "의욕",
  "봉변",
  "헐다",
  "정수기",
  "쓰러지다",
  "생방송",
  "묘미",
  "공손하다",
  "명찰",
  "자취",
  "무의식적",
  "상큼하다",
  "멍들다",
  "명곡",
  "대결",
  "중독성",
  "마귀할멈",
  "또박또박",
  "식상하다",
  "시범",
  "하나씩",
  "하락",
  "물어내다",
  "눈길",
  "회의실",
  "계산대",
  "국회",
  "동아줄",
  "깃털",
  "독서실",
  "상금",
  "사극",
  "여사",
  "주거",
  "범위",
  "별명",
  "유출되다",
  "중복",
  "수락",
  "등급",
  "뒤져보다",
  "수정",
  "분주하다",
  "꽃잎",
  "흩날리다",
  "비틀다",
  "넘치다",
  "반기다",
  "미남",
  "학문",
  "청와대",
  "강제",
  "황색",
  "노년기",
  "잔인하다",
  "분장하다",
  "벌리다",
  "충치",
  "꾸준히",
  "흔들리다",
  "미녀",
  "왕비",
  "귀국하다",
  "중순",
  "발휘하다",
  "지장",
  "시댁",
  "곧잘",
  "어구",
  "재작년",
  "비자",
  "기러기",
  "바구니",
  "소나무",
  "저고리",
  "투수",
  "도토리",
  "까치",
  "폐",
  "외래어",
  "궤도",
  "외가",
  "신작",
  "늪",
  "분식",
  "장님",
  "펄럭펄럭",
  "기이하다",
  "내려앉다",
  "허용되다",
  "쪽지",
  "실명",
  "바늘",
  "앞줄",
  "생강",
  "헛소문",
  "물비누",
  "통합",
  "입장료",
  "일시",
  "저자",
  "강사",
  "수다쟁이",
  "기차역",
  "안내원",
  "나열하다",
  "햇빛",
  "사진관",
  "주중",
  "원룸",
  "새다",
  "보증금",
  "타자",
  "상대편",
  "개시하다",
  "중개인",
  "쇼핑센터",
  "전세",
  "집주인",
  "놀이기구",
  "전달하다",
  "베란다",
  "별나다",
  "칼날",
  "권력",
  "굳이",
  "국화",
  "좁히다",
  "장가가다",
  "감시하다",
  "운영하다",
  "설치",
  "폭로하다",
  "상",
  "할부",
  "초보자",
  "조정",
  "무디다",
  "눈빛",
  "우수하다",
  "고무신",
  "해롭다",
  "축소하다",
  "물집",
  "절레절레",
  "거두다",
  "곡식",
  "오락",
  "하이웨이",
  "산꼭대기",
  "전환",
  "코스",
  "전문적",
  "옷차림",
  "지팡이",
  "계곡",
  "유리창",
  "보호자",
  "보건소",
  "기혼",
  "일상생활",
  "만담",
  "밥맛",
  "친근하다",
  "개방하다",
  "잠옷",
  "놀이동산",
  "송별회",
  "공공기관",
  "처방전",
  "시행",
  "구청",
  "수년",
  "불법",
  "강당",
  "핫도그",
  "학생회관",
  "찬밥",
  "물리학",
  "변소",
  "조교",
  "룸메이트",
  "외아들",
  "가렵다",
  "물리다",
  "재생하다",
  "공학",
  "당초에",
  "쾌적하다",
  "칼국수",
  "쌈밥",
  "바퀴",
  "썰매",
  "망가지다",
  "인출하다",
  "적히다",
  "전봇대",
  "새기다",
  "길가",
  "석유",
  "머리핀",
  "정찰제",
  "측면",
  "도구",
  "수단",
  "주어",
  "친목",
  "행인",
  "전골",
  "무역항",
  "대도시",
  "수족관",
  "물속",
  "식물원",
  "새끼손가락",
  "사당",
  "휴양지",
  "여행지",
  "복종하다",
  "기껏",
  "대리",
  "김장",
  "한옥",
  "열매",
  "진행자",
  "지폐",
  "대청소",
  "국자",
  "앞뒤",
  "대조하다",
  "체력",
  "의무",
  "기획",
  "부서",
  "의뢰인",
  "짠돌이",
  "튀김",
  "메모지",
  "비기다",
  "환갑",
  "적응하다",
  "고사",
  "골",
  "하키",
  "법학",
  "인용",
  "판사",
  "긋다",
  "기본형",
  "기다",
  "어지르다",
  "초청하다",
  "확장하다",
  "오류",
  "문의",
  "관리자",
  "체계",
  "만족도",
  "개편",
  "제시간",
  "여부",
  "이용자",
  "단독",
  "상승하다",
  "입양하다",
  "이빨",
  "손주",
  "무능력하다",
  "비닐",
  "분리하다",
  "원상복구",
  "은퇴하다",
  "새어머니",
  "가면",
  "채권자",
  "채무",
  "불륜",
  "초점",
  "정면",
  "악마",
  "분리수거하다",
  "탈진하다",
  "액체",
  "우두머리",
  "앞일",
  "조직",
  "구성원",
  "상업",
  "금하다",
  "고유하다",
  "밤낮",
  "동의어",
  "학점",
  "대출",
  "출력하다",
  "경비",
  "철학",
  "유형",
  "단서",
  "올케",
  "전망",
  "장단점",
  "관리비",
  "결제",
  "금액",
  "추첨하다",
  "농가",
  "벨트",
  "한도",
  "계좌",
  "합리적",
  "망상",
  "수직",
  "매형",
  "낭독하다",
  "맛보다",
  "윗사람",
  "눈부처",
  "처남",
  "청혼하다",
  "창",
  "표적",
  "혼란",
  "덫",
  "청첩장",
  "태우다",
  "차편",
  "마땅하다",
  "시체",
  "탄생하다",
  "일정",
  "당구",
  "가뭄",
  "자수하다",
  "대가족",
  "떠올리다",
  "줄어들다",
  "설문지",
  "콤플렉스",
  "광화문",
  "광장",
  "둘러싸다",
  "당기다",
  "감각",
  "샅샅이",
  "꽃바구니",
  "찻집",
  "묶다",
  "목수",
  "미지근하다",
  "앵무새",
  "요술",
  "가라앉다",
  "촛불",
  "서예",
  "탈춤",
  "에펠탑",
  "체류하다",
  "면회",
  "신세",
  "며느리",
  "승리",
  "좌측",
  "노선도",
  "따위",
  "날로",
  "알아차리다",
  "운수",
  "유래",
  "이를테면",
  "계기",
  "청명하다",
  "재능",
  "정책",
  "논란",
  "취지",
  "오염되다",
  "능하다",
  "전보",
  "등기우편",
  "우체통",
  "집어넣다",
  "창구",
  "성능",
  "환율",
  "원작",
  "받아쓰다",
  "도우미",
  "큰아들",
  "짭짤하다",
  "열연",
  "덮이다",
  "앓다",
  "본래",
  "펼쳐지다",
  "폭풍우",
  "애태우다",
  "끊기다",
  "들여다보다",
  "온통",
  "거만하다",
  "유로화",
  "체중",
  "대여하다",
  "인식",
  "완성하다",
  "유창하다",
  "대중교통",
  "음식물",
  "단말기",
  "끈",
  "묘사",
  "주간",
  "엿새",
  "예언",
  "경영학",
  "수식하다",
  "외교관",
  "학력",
  "도리",
  "넘기다",
  "추신",
  "병들다",
  "호칭",
  "상급생",
  "비록",
  "반영하다",
  "세제",
  "그루",
  "나뭇가지",
  "술래잡기",
  "어느덧",
  "아낌없이",
  "금반지",
  "스승",
  "짤막하다",
  "민망하다",
  "오진",
  "이치",
  "벗어나다",
  "과도하다",
  "자격증",
  "각서",
  "은신처",
  "동정",
  "숙취",
  "보름달",
  "금연석",
  "흡연석",
  "납치하다",
  "암호",
  "문명",
  "지불하다",
  "열흘",
  "거부하다",
  "양로원",
  "줄무늬",
  "세련되다",
  "물방울",
  "수선하다",
  "호박",
  "훗날",
  "유적지",
  "해돋이",
  "해양",
  "장기간",
  "생선회",
  "고궁",
  "잔디밭",
  "하품하다",
  "금고",
  "표지판",
  "접근하다",
  "칠하다",
  "관리실",
  "펼치다",
  "미워하다",
  "아드님",
  "수능수학능력시험",
  "택일",
  "전채",
  "출출하다",
  "위반하다",
  "알",
  "앞서다",
  "반죽",
  "악역",
  "차세대",
  "심기",
  "전매특허",
  "악행",
  "오슬오슬",
  "퐁당",
  "튀기다",
  "김치전",
  "긴하다",
  "삽입하다",
  "속성",
  "전송하다",
  "골고루",
  "송편",
  "규정",
  "유람선",
  "불꽃놀이",
  "통로",
  "궁궐",
  "여행용",
  "파다",
  "수하물",
  "물기",
  "낯설다",
  "제한",
  "마주서다",
  "후춧가루",
  "신용",
  "온몸",
  "정",
  "정신적",
  "증상",
  "한꺼번에",
  "껍질",
  "지속되다",
  "개업하다",
  "염소",
  "고래",
  "부근",
  "반장",
  "역사가",
  "연구자",
  "조그맣다",
  "줄기",
  "후반",
  "지니다",
  "의원",
  "나물",
  "물개",
  "벽돌",
  "철새",
  "디자이너",
  "붐비다",
  "농민",
  "대책",
  "셈",
  "불구하다",
  "지하철역",
  "도쿄",
  "구두쇠",
  "담당자",
  "뜨개질",
  "멋쟁이",
  "성명",
  "선로",
  "안내문",
  "점검하다",
  "주걱",
  "중고차",
  "휴학하다",
  "삼국",
  "떼다",
  "달아나다",
  "소리치다",
  "기울다",
  "쓰이다",
  "공예",
  "마중하다",
  "짖다",
  "곱슬곱슬하다",
  "턱수염",
  "턱",
  "층지다",
  "호출기",
  "조리법",
  "지급하다",
  "수표",
  "명세서",
  "단풍나무",
  "직장인",
  "하룻밤",
  "폭설",
  "수량",
  "탁하다",
  "명소",
  "딸꾹질하다",
  "별도",
  "독수리",
  "배우자",
  "견학",
  "세종대왕",
  "조선시대",
  "경호원",
  "통치",
  "침착하다",
  "소극적",
  "인수하다",
  "제시하다",
  "불과하다",
  "작용",
  "공업",
  "경향",
  "반면에",
  "특성",
  "서럽다",
  "끌어안다",
  "한없다",
  "밀어내다",
  "굳다",
  "보도",
  "여기저기",
  "타이밍",
  "마누라",
  "지원자",
  "조만간",
  "상의",
  "선호하다",
  "안정성",
  "대기업",
  "적성",
  "근로",
  "발자국",
  "자네",
  "노려보다",
  "쳐들다",
  "또라이",
  "아침저녁",
  "전체적",
  "분쟁",
  "진료",
  "초라하다",
  "가불하다",
  "우아하다",
  "실크",
  "절약하다",
  "일자리",
  "압도적으로",
  "문제집",
  "사항",
  "숙지하다",
  "채점하다",
  "표명하다",
  "보관하다",
  "유일하다",
  "공통점",
  "시위",
  "환절기",
  "치킨",
  "인쇄하다",
  "자켓",
  "초보",
  "담요",
  "취급하다",
  "전자레인지",
  "램프",
  "학창",
  "범죄",
  "바위",
  "클럽",
  "권하다",
  "끝내",
  "대문",
  "비판하다",
  "세워지다",
  "확실히",
  "교류",
  "섞이다",
  "심리",
  "적용하다",
  "그다지",
  "부처",
  "상세하다",
  "헛소리",
  "정직하다",
  "단추",
  "프린터",
  "은",
  "원목",
  "천",
  "대형",
  "배달원",
  "재래시장",
  "수산시장",
  "장수",
  "꽃다발",
  "앞머리",
  "인상착의",
  "미아",
  "장식하다",
  "여유롭다",
  "점치다",
  "돌잡이",
  "장래",
  "청진기",
  "야유회",
  "쿵",
  "떫다",
  "벼룩시장",
  "길거리",
  "작업실",
  "색다르다",
  "완공되다",
  "차량",
  "주목",
  "귀금속",
  "탐색하다",
  "토하다",
  "굴욕",
  "건반",
  "대인",
  "엉금엉금",
  "획득하다",
  "평온하다",
  "군것질",
  "작정",
  "보물",
  "중단하다",
  "출판사",
  "클릭하다",
  "머뭇거리다",
  "사물함",
  "방문",
  "해내다",
  "가업",
  "대접하다",
  "배웅하다",
  "건달",
  "단정하다",
  "간지럽다",
  "허탈하다",
  "검토하다",
  "요리책",
  "진수성찬",
  "부엌용품",
  "개강",
  "협찬하다",
  "도착지",
  "까불다",
  "맛집",
  "코뿔소",
  "버겁다",
  "달인",
  "잠복",
  "벌칙",
  "시각",
  "대로",
  "배터리",
  "꼼짝못하다",
  "성질",
  "뭉치다",
  "환호하다",
  "완수하다",
  "쾌감",
  "개조하다",
  "소유하다",
  "본격적",
  "이적하다",
  "다급하다",
  "빛깔",
  "업히다",
  "골인하다",
  "난동",
  "검거하다",
  "쿠폰",
  "제외하다",
  "모함",
  "연",
  "팀장",
  "행방",
  "승패",
  "수포",
  "실험하다",
  "돋보기",
  "오피스텔",
  "누이",
  "꼴찌",
  "숨바꼭질",
  "꿀꺽",
  "빼앗기다",
  "완패",
  "귀가하다",
  "즐비하다",
  "씁쓸하다",
  "거북하다",
  "겹치다",
  "대보다",
  "현수막",
  "별말―",
  "보람있다",
  "포스터",
  "벚꽃놀이",
  "장만하다",
  "시집―",
  "항공편",
  "만석",
  "동맹",
  "완료하다",
  "대기자",
  "더군다나",
  "지지리",
  "다물다",
  "뜯다",
  "돗자리",
  "진리",
  "언뜻",
  "애칭",
  "처제",
  "효율적",
  "최강",
  "전략",
  "얽히다",
  "일화",
  "품절되다",
  "주장",
  "빡빡하다",
  "아군",
  "날카롭다",
  "지령",
  "감탄하다",
  "모욕하다",
  "습기",
  "소나기",
  "겉",
  "소질",
  "사례",
  "추천서",
  "걸레",
  "믿음직하다",
  "널다",
  "유학생",
  "추리하다",
  "정성껏",
  "저축하다",
  "북경",
  "홀수―",
  "생수",
  "갈매기",
  "보따리",
  "최상",
  "인원",
  "메뚜기",
  "지배하다",
  "예리하다",
  "인질",
  "유쾌하다",
  "꿈속",
  "필기하다",
  "구별하다",
  "조개",
  "기색",
  "연합",
  "바가지",
  "식히다",
  "감동적",
  "쫓기다",
  "법정",
  "배즙",
  "만점",
  "지리",
  "이해심",
  "봐주다",
  "무전기",
  "내성적",
  "자신감",
  "단원",
  "덤벙거리다",
  "둔감하다",
  "엉성하다",
  "확률",
  "주꾸미",
  "진로",
  "설계하다",
  "지난번",
  "지난날",
  "휴가철",
  "저런",
  "수료하다",
  "장구",
  "작별",
  "출전하다",
  "경제적",
  "담당하다",
  "벗기다",
  "불빛",
  "미련",
  "해석하다",
  "우편물",
  "정비사",
  "출국하다",
  "취업",
  "창업하다",
  "자영업",
  "박람회",
  "유망하다",
  "포털",
  "마음가짐",
  "설문",
  "후텁지근하다",
  "건기",
  "뚝",
  "새싹",
  "싹",
  "초원",
  "나들이",
  "돌풍",
  "벼락",
  "밤사이",
  "유한하다",
  "아이돌",
  "경쾌하다",
  "공식적",
  "헤매다",
  "개최하다",
  "등록금",
  "수강",
  "끼우다",
  "송금하다",
  "집세―",
  "선불",
  "신입생",
  "납부하다",
  "외동딸",
  "창립",
  "푸짐하다",
  "어버이",
  "개교하다",
  "카네이션",
  "축의금",
  "화환",
  "게양하다",
  "간편하다",
  "무지개",
  "탑승하다",
  "종종",
  "한동안",
  "해마다",
  "국회의원",
  "터뜨리다",
  "반품하다",
  "평",
  "여가",
  "계발하다",
  "복어―",
  "재충전하다",
  "활력",
  "연령",
  "비율",
  "때우다",
  "처지다",
  "안정",
  "해소",
  "들통이나다",
  "프러포즈",
  "수면제",
  "말다툼",
  "터놓다",
  "콧노래",
  "꾸중",
  "거래처",
  "보수",
  "회상하다",
  "흥미진진하다",
  "실감하다",
  "실화",
  "뭉클하다",
  "윙크하다",
  "핸드볼",
  "악필",
  "위축되다",
  "효도하다",
  "보행자",
  "버터",
  "좌절하다",
  "주사위",
  "물병",
  "앞치마",
  "줄넘기하다",
  "바비큐",
  "솜사탕",
  "트이다",
  "이야깃거리",
  "매너",
  "늦추다",
  "유모차",
  "동화",
  "돌고래",
  "위인",
  "빗나가다",
  "반찬거리",
  "새까맣다",
  "볼거리",
  "강연하다",
  "빨대",
  "사나이",
  "아우",
  "흐뭇하다",
  "시연",
  "항복하다",
  "어묵",
  "본색",
  "기저귀",
  "웃어른",
  "김치찌개",
  "성묘하다",
  "신청자",
  "한국인",
  "음원",
  "손실",
  "양해",
  "고춧가루",
  "새콤하다",
  "일과",
  "방과",
  "공연장",
  "쓸다",
  "앞두다",
  "면목",
  "빗자루",
  "착용하다",
  "출간하다",
  "염려",
  "대걸레",
  "등교하다",
  "딱밤",
  "반숙하다",
  "제자",
  "드럼",
  "소인",
  "발레",
  "어머님",
  "기말시험",
  "종이접기",
  "끈끈하다",
  "침술",
  "수다스럽다",
  "입히다",
  "육아",
  "배꼽",
  "울타리",
  "사고방식",
  "단맛",
  "메기",
  "노비",
  "뜰",
  "당연히",
  "마구",
  "미디어",
  "수상",
  "자랑스럽다",
  "곳곳",
  "내지",
  "정식",
  "망고",
  "마술사",
  "뒤집다",
  "양손",
  "무용수",
  "순식간에",
  "둘레",
  "자물쇠",
  "대견하다",
  "갯벌",
  "계약직",
  "악어",
  "데굴데굴",
  "튀다",
  "뒤집히다",
  "동화책",
  "포즈",
  "흉내",
  "몸짓",
  "발표회",
  "용건",
  "차단하다",
  "사채",
  "끌리다",
  "불쑥",
  "분가하다",
  "오만하다",
  "실장",
  "날리다",
  "산소",
  "질",
  "부작용",
  "먹방",
  "쑥스럽다",
  "묘하다",
  "논술하다",
  "사로잡다",
  "가시",
  "더듬이",
  "샘물",
  "물감",
  "스케치북",
  "총명하다",
  "펄쩍펄쩍",
  "운동회",
  "회전목마",
  "낙지",
  "유교",
  "과학적",
  "환기",
  "탐험하다",
  "낙타",
  "건방지다",
  "잔소리하다",
  "부정적",
  "저물다",
  "보일러",
  "꼭꼭",
  "보약",
  "도끼",
  "연못―",
  "크림",
  "거북",
  "우동",
  "구급차",
  "체형",
  "상어",
  "공통되다",
  "보배",
  "베풀다",
  "공룡",
  "넘나들다",
  "화단",
  "고소하다",
  "드러눕다",
  "씨름",
  "알아내다",
  "멸치",
  "마녀",
  "기특하다",
  "미끄러지다",
  "꺾다",
  "뛰어다니다",
  "저금통",
  "색종이―",
  "일기장",
  "이자",
  "등산화",
  "분명하다",
  "현지",
  "넓히다",
  "얹다",
  "털실",
  "꽃무늬",
  "질기다",
  "감기다",
  "말랑말랑하다",
  "세뱃돈",
  "고립",
  "덤비다",
  "리본",
  "벗겨지다",
  "병아리",
  "진동하다",
  "자극하다",
  "조르다",
  "소형차",
  "징그럽다",
  "배식하다",
  "향기롭다",
  "대추",
  "선발하다",
  "우르르",
  "흥",
  "구슬",
  "연약하다",
  "비비다",
  "자금",
  "샐러드",
  "헬멧",
  "낙서하다",
  "차례차례",
  "운전기사",
  "안전띠",
  "뚜껑",
  "삼키다",
  "송아지",
  "시외버스",
  "정성",
  "마음씨",
  "내버리다",
  "효녀",
  "양치기",
  "정승",
  "누렇다",
  "귓속말",
  "걸레질",
  "그런대로",
  "재활용",
  "재활용품",
  "플라스틱",
  "틀",
  "녹이다",
  "씨앗",
  "오늘날",
  "초가집",
  "기와집",
  "우물",
  "시냇가",
  "세미나",
  "복사기",
  "재수하다",
  "성금",
  "불우하다",
  "굶주리다",
  "달라붙다",
  "몰려오다",
  "시행착오",
  "적당히",
  "진출하다",
  "슬픔",
  "아무렇지도않다",
  "이튿날",
  "참기름",
  "재물",
  "날달걀",
  "큐브",
  "맞서다",
  "정치적",
  "날아오다",
  "담임",
  "도중에",
  "각국",
  "연도",
  "다수",
  "스님",
  "엉뚱하다",
  "조각",
  "푸른색",
  "무시무시하다",
  "붉은색",
  "요가",
  "단무지",
  "회초리",
  "훈장",
  "사용자",
  "논의하다",
  "파란색",
  "반하다",
  "전화위복",
  "조심스럽다",
  "피시",
  "흘러나오다",
  "간단히",
  "모니터",
  "흘러가다",
  "내려다보다",
  "일주일",
  "오래오래",
  "그런",
  "이곳",
  "맛없다",
  "다양성",
  "당황스럽다",
  "약혼자éé",
  "이후",
  "무관하다",
  "곧바로",
  "형부",
  "지점",
  "내려놓다",
  "안쪽",
  "오가다",
  "양념",
  "교통사고",
  "뺨",
  "여름철",
  "꼭대기",
  "매주",
  "몸무게",
  "심경",
  "온돌",
  "깨뜨리다",
  "식성",
  "기부하다",
  "무식하다",
  "활기",
  "쇼",
  "카운터",
  "콩나물",
  "거짓",
  "어쩐지",
  "규칙적",
  "밥그릇",
  "발가락",
  "운전자",
  "나뭇잎",
  "넓어지다",
  "미팅",
  "신문지",
  "자라나다",
  "가이드",
  "밤늦다",
  "멋",
  "앞길",
  "두세",
  "보리",
  "반칙",
  "마음껏",
  "보석",
  "귀하다",
  "시무룩하다",
  "술잔",
  "신문사",
  "이웃집",
  "부러워하다",
  "이것저것",
  "가져다주다",
  "사무",
  "기력",
  "챔피언",
  "겨울철",
  "골프장",
  "달빛",
  "뒷산",
  "산속",
  "식초",
  "필름",
  "월드컵",
  "잘살다",
  "졸업생",
  "볼일",
  "구실",
  "부끄러움",
  "받침",
  "위쪽",
  "택하다",
  "일회용",
  "차남",
  "팬티",
  "개나리",
  "골목길",
  "사랑스럽다",
  "술병",
  "책가방",
  "다녀가다",
  "발등",
  "슬퍼하다",
  "앞쪽",
  "왼발",
  "소용없다",
  "술자리",
  "묻히다",
  "오른발",
  "한국적",
  "감",
  "네거리",
  "먹히다",
  "무더위",
  "박스",
  "밤중에",
  "접선",
  "수행하다",
  "낭만적",
  "부활하다",
  "약탈하다",
  "버튼",
  "손뼉",
  "자판기",
  "저녁때",
  "적어지다",
  "테스트",
  "가로",
  "부잣집",
  "섭씨",
  "캐다",
  "짜릿하다",
  "선하다",
  "양배추",
  "읽히다",
  "깜빡",
  "시부모",
  "초청장",
  "세로",
  "종이컵",
  "독일어",
  "축구공",
  "메일",
  "시아버지",
  "말레이시아",
  "갈비탕",
  "우편함",
  "큰절",
  "부쩍",
  "개그",
  "동굴",
  "용도",
  "허무",
  "강렬하다",
  "책임감",
  "함성",
  "살금살금",
  "일교차",
  "찜질하다",
  "속셈",
  "개입하다",
  "매달리다",
  "살림",
  "살찌다",
  "강점",
  "손잡다",
  "손대다",
  "도박하다",
  "향",
  "향상되다",
  "무능",
  "명당",
  "본인",
  "노선",
  "자기소개",
  "분노",
  "그분",
  "제거하다",
  "소화제",
  "소득",
  "정기",
  "덩어리",
  "동기",
  "부위",
  "인적",
  "소속되다",
  "숙박하다",
  "하숙비",
  "엄숙하다",
  "승낙하다",
  "입시",
  "막상",
]