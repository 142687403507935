import React from "react";

import "../styles2.css"

function App(props) {
  return (
    <div className="App">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="19"
        viewBox="0 0 15 19"
      >
        <g transform="translate(-804 -2162)">
          <defs>
            <filter id="inset" x="-50%" y="-50%" width="200%" height="200%">
              <feFlood flood-color="#1B5A7A" result="inside-color" />
              <feMorphology in="SourceAlpha" operator="erode" radius="0.1" />
              <feComposite
                in="inside-color"
                operator="in"
                result="inside-stroke"
              />

              <feMorphology in="SourceAlpha" operator="dilate" />
              <feComposite
                in="SourceGraphic"
                operator="in"
                result="fill-area"
              />

              <feMergeNode in="fill-area" />

              <feMerge>
                <feMergeNode in="fill-area" />
                <feMergeNode in="inside-stroke" />
              </feMerge>
            </filter>
            <g id="letters">
              {props.character.strokes.map((stroke, i) => {
                return (
                  <path
                    className={`squiggle${i + 1}`}
                    d={stroke.props.d}
                    stroke="white"
                    stroke-width="2"
                    fill="none"
                    transform={stroke.props.transform}
                    pathLength="1"
                  />
                );
              })}
            </g>
            <mask id="myClipPath" width="15" height="19">
              <use xlinkHref="#letters" overflow="visible" />
            </mask>
            <g mask="url(#myClipPath)" id="final">
              <path
                d={props.character.path.props.d}
                transform="translate(804 2177)"
                fill="#1CA7FC"
              />
            </g>
          </defs>
          <path
            d={props.character.path.props.d}
            transform="translate(804 2177)"
            fill="#142E38"
          />
          <use xlinkHref="#final" overflow="visible" filter="url(#inset)" />
        </g>
      </svg>
    </div>
  );
}

export default App;