import React from "react";

import "./keyboard.css";
import styled from "styled-components";
import { useEffect } from "react";
import { useState } from "react";
import { englishToHangul } from "hangul-utils";
import { useRecoilState } from "recoil";
import { inputState } from "../atoms/atoms";

function Keyboard({
  buttonClick,
  newChar,
  levels,
  level,
  onKeyDown,
  highlight,
  dull,
  softHighlight,
  ...props
}) {
  useEffect(() => {
    document.body.onkeydown = function (e) {
      try {
        const element = document.querySelector(
          `a[data-key-text='${
            englishToHangul(e.key) ? englishToHangul(e.key) : e.key
          }']`
        );
        if (element) {
          element.style.background = "#1B5A7A";
        }
      } catch (err) {}
    };

    document.body.onkeyup = function (e) {
      try {
        const element = document.querySelector(
          `a[data-key-text='${
            englishToHangul(e.key) ? englishToHangul(e.key) : e.key
          }']`
        );
        if (element) {
          element.style.background = "#153845";
        }
      } catch (err) {}
    };
  }, []);

  useEffect(() => {
    if (highlight) {
      highlight.map((h) => {
        const element = document.querySelector(`a[data-key-text='${h}']`);
        if (element) {
          element.classList.add("highlight");
        }
      });
    }

    if (softHighlight) {
      softHighlight.map((h) => {
        const element = document.querySelector(`a[data-key-text='${h}']`);
        if (element) {
          element.classList.add("soft-highlight");
        }
      });
    }

    if (dull) {
      dull.map((h) => {
        const element = document.querySelector(`a[data-key-text='${h}']`);
        if (element) {
          element.classList.add("dull");
        }
      });
    }
  }, [highlight, softHighlight, dull]);

  return (
    <div {...props}>
      <div
        class="keyboard"
        id="60-percent-keyboard"
        onClick={(e) => {
          const click = e.target.getAttribute("data-key-text");
          if (click) {
            buttonClick(click)
          }
        }}
      >
        <a class="keyboard__key keyboard__key--modifier keyboard__key--escape"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        {/* <a class="keyboard__key k"></a> */}
        <a class="keyboard__key k"></a>
        {/* <a class="keyboard__key k"></a> */}
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key k"></a>
        <a class="keyboard__key keyboard__key--2u keyboard__key--modifier" data-key-text="⌫"></a>

        <a class="keyboard__key keyboard__key--1-5u keyboard__key--modifier"></a>
        <a class="keyboard__key k" data-key-text="ㅂ"></a>
        <a class="keyboard__key k" data-key-text="ㅈ"></a>
        <a class="keyboard__key k" data-key-text="ㄷ"></a>
        <a class="keyboard__key k" data-key-text="ㄱ"></a>
        <a class="keyboard__key k" data-key-text="ㅅ"></a>
        <a class="keyboard__key k" data-key-text="ㅛ"></a>
        <a class="keyboard__key k" data-key-text="ㅕ"></a>
        <a class="keyboard__key k" data-key-text="ㅑ"></a>
        <a class="keyboard__key k" data-key-text="ㅐ"></a>
        <a class="keyboard__key k" data-key-text="ㅔ"></a>
        {/* <a class="keyboard__key k" data-key-text="["></a> */}
        {/* <a class="keyboard__key k" data-key-text="]"></a> */}
        <a class="keyboard__key keyboard__key--1-5u keyboard__key--modifier"></a>

        <a class="keyboard__key keyboard__key--1-75u keyboard__key--modifier"></a>
        <a class="keyboard__key k" data-key-text="ㅁ"></a>
        <a class="keyboard__key k" data-key-text="ㄴ"></a>
        <a class="keyboard__key k" data-key-text="ㅇ"></a>
        <a class="keyboard__key k" data-key-text="ㄹ"></a>
        <a class="keyboard__key k" data-key-text="ㅎ"></a>
        <a class="keyboard__key k" data-key-text="ㅗ"></a>
        <a class="keyboard__key k" data-key-text="ㅓ"></a>
        <a class="keyboard__key k" data-key-text="ㅏ"></a>
        <a class="keyboard__key k" data-key-text="ㅣ"></a>
        {/* <a class="keyboard__key k" data-key-text=";"></a> */}
        {/* <a class="keyboard__key k" data-key-text="'"></a> */}
        <a class="keyboard__key keyboard__key--2-25u keyboard__key--modifier" data-key-text="↵"></a>

        <a class="keyboard__key keyboard__key--2-25u keyboard__key--modifier"></a>
        <a class="keyboard__key k" data-key-text="ㅋ"></a>
        <a class="keyboard__key k" data-key-text="ㅌ"></a>
        <a class="keyboard__key k" data-key-text="ㅊ"></a>
        <a class="keyboard__key k" data-key-text="ㅍ"></a>
        <a class="keyboard__key k" data-key-text="ㅠ"></a>
        <a class="keyboard__key k" data-key-text="ㅜ"></a>
        <a class="keyboard__key k" data-key-text="ㅡ"></a>
        <a class="keyboard__key k" data-key-text=","></a>
        {/* <a class="keyboard__key k" data-key-text="."></a> */}
        {/* <a class="keyboard__key k" data-key-text="/"></a> */}
        <a class="keyboard__key keyboard__key--1-25u keyboard__key--modifier"></a>
        <a class="keyboard__key keyboard__key--1-5u keyboard__key--modifier"></a>

        <a class="keyboard__key keyboard__key--1-25u keyboard__key--modifier"></a>
        <a class="keyboard__key keyboard__key--1-25u keyboard__key--modifier"></a>
        <a class="keyboard__key keyboard__key--1-25u keyboard__key--modifier"></a>
        <a class="keyboard__key keyboard__key--6-25u"></a>
        <a class="keyboard__key keyboard__key--1-5u keyboard__key--modifier"></a>
        <a class="keyboard__key keyboard__key--1-5u keyboard__key--modifier"></a>
        {/* <a class="keyboard__key keyboard__key--1-25u keyboard__key--modifier"></a> */}
        {/* <a class="keyboard__key keyboard__key--1-25u keyboard__key--modifier"></a> */}
      </div>
    </div>
  );
}

export default styled(Keyboard)`
  .k::before {
    padding-bottom: 100%;
  }

  .keyboard__key {
    cursor: pointer;
  }

  .keyboard__key--2-75u::before {
    padding-bottom: 15%;
  }
  .keyboard__key--1-25u::before {
    padding-bottom: 75%;
  }

  .highlight.highlight {
    background: #204b6b !important;
    border: 1px solid #47a6f8;
    &::after {
      color: #47a6f8;
      opacity: 1;
    }
  }

  .soft-highlight {
    &::after {
      color: #e7a85f;
      opacity: 1;
    }
    background: #7b552b !important;
    border: 1px solid #e7a85f;
  }

  .dull {
    background: #12262e !important;
  }

  a:active {
    background: #1B5A7A;
  }

  [data-key-text="${(props) => props.char}"] {
    &::before {
      color: #1ba3fa;
      font-weight: bold;
    }
    border: 1px solid rgba(27, 163, 250, 0.4);
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.4);
    scale: 1.1;
    z-index: 1;
    animation: pulse 3s infinite;
    opacity: 1;
  }

  @keyframes pulse {
    0% {
      scale: 1;
    }
    50% {
      scale: 1.1;
    }
    100% {
      scale: 1;
    }
  }
`;
