import React, { useState } from "react";
import styled from "styled-components";

import hangul from "hangul-js";
import curated_list from "../../curated_list";
import common_words from "../../common_words";
import cword_list from "../../cword_list";
import c6000_words from "../../c6000_words";
import Keyboard from "../../components/Keyboard";
import { inputState } from "../../atoms/atoms";
import { useRecoilState } from "recoil";
import { englishToHangul } from "hangul-utils";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import kedict_words from "../../kedict_words";
import InputSlider from "react-input-slider";
import Card from "./Card";
import memoizee from "memoizee";
import useLocalStorage from "react-use-localstorage";
import { v4 as uuidv4 } from "uuid";

function getValidGuess(word) {
  const possible_words = [
    ...new Set([
      ...curated_list,
      ...common_words,
      ...kedict_words,
      ...cword_list,
      ...c6000_words,
    ]),
  ];
  return possible_words.includes(hangul.assemble(word.filter((r) => r)));
}

function getHighlights(word, word2) {
  const result = word.map(function (item, i) {
    return item === word2[i] ? item : undefined;
  });
  return result.filter((r) => r);
}

function getSoftHighlights(word, word2) {
  const result = word.map(function (item, i) {
    return word2.includes(item) ? item : undefined;
  });
  return result.filter((r) => r);
}

function answersToEmoji(word, answers) {
  const tries =
    word === hangul.assemble(answers[answers.length - 1].filter((f) => f))
      ? answers.length
      : "X";
  let emoji = `https://hangul.cool/wordle ${tries} / ${guesses + 1} \n`;
  answers.map((answer) => {
    answer
      .filter((r) => r)
      .map((l, i) => {
        if (l === hangul.disassemble(word)[i]) {
          emoji += "🟦";
        } else if (hangul.disassemble(word).includes(l)) {
          emoji += "🟧";
        } else {
          emoji += "⬛";
        }
      });
    emoji += "\n";
  });

  return emoji;
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const wordLength_ = Number(params.wordLength) || 5;
const maxGuesses = 5;

function getWords(length) {
  const words = curated_list.filter((word, i) => {
    return (
      hangul.disassemble(word).length === length &&
      hangul.disassemble(word).length !== word.length &&
      !hangul.disassemble(word).includes("ㅃ") &&
      !hangul.disassemble(word).includes("ㅉ") &&
      !hangul.disassemble(word).includes("ㄸ") &&
      !hangul.disassemble(word).includes("ㄲ") &&
      !hangul.disassemble(word).includes("ㅆ") &&
      !hangul.disassemble(word).includes("ㅒ") &&
      !hangul.disassemble(word).includes("ㅖ")
    );
  });
  return words;
}

function getRandomWord(length) {
  const w = getWords(length);
  return w[Math.floor(Math.random() * w.length)];
}

const getWordColor = (word, guess) => {
  const splitSolution = word;
  const splitGuess = guess;

  const solutionCharsTaken = splitSolution.map((_) => false);

  const statuses = Array.from(Array(guess.length));

  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = "correct";
      solutionCharsTaken[i] = true;
      return;
    }
  });

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return;

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = "";
      return;
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    );

    if (indexOfPresentChar > -1) {
      statuses[i] = "almost_correct";
      solutionCharsTaken[indexOfPresentChar] = true;
      return;
    } else {
      statuses[i] = "";
      return;
    }
  });

  return statuses;
};

function WordlePage(props) {
  const [input, setInput] = useRecoilState(inputState);
  const [answers, setAnswers] = useState([]);
  const [enters, setEnters] = useState(0);
  const [wordLength, setWordLength] = useState(wordLength_);
  const [word, setWord] = useState(getRandomWord(wordLength));
  const [shake, setShake] = useState(false);
  const [game_id, setGameId] = useState(uuidv4());
  const [games, setGames] = useLocalStorage("games", "{}");

  const handleLengthChange = memoizee((length) => {
    setInput("");
    setAnswers([]);
    setWord(getRandomWord(length));
    setWordLength(length);
    setGameId(game_id);
  });

  const handleInput = (event) => {
    let key = englishToHangul(event.key.toLocaleLowerCase()) || event.key;
    let newInput;
    if (
      event.key !== "Enter" &&
      event.key !== "⌫" &&
      event.key !== "↵" &&
      event.key !== "Backspace"
    ) {
      newInput = [...input, key];
    } else {
      newInput = [...input];
    }

    if (
      (event.key === "Enter" || event.key === "↵") &&
      input.length === wordLength
    ) {
      const boxes = document.querySelectorAll(".current");

      for (const box of boxes) {
        box.classList.add("shake");
      }

      setEnters(enters + 1);
      let newGame = JSON.parse(games)[wordLength];

      if (answers.length === 0) {
        newGame = {
          ...newGame,
          games: newGame?.games + 1 || 1,
        };
      }

      if (getValidGuess(newInput)) {
        if (hangul.assemble(newInput) === word) {
          let streak = newGame?.streak + 1 || 1;
          newGame = {
            ...newGame,
            wins: newGame?.wins + 1 || 1,
            guessedIn: { ...newGame?.guessedIn, [answers.length + 1]: newGame?.guessedIn?.[answers.length + 1] + 1 || 1 },
            streak: streak,
            maxStreak: newGame?.maxStreak > streak ? newGame?.maxStreak : streak
          };
        } else if (newInput !== word && answers.length === 5) {
          newGame = {
            ...newGame,
            guessedIn: { ...newGame?.guessedIn, ['x']: newGame?.guessedIn?.['x'] + 1 || 1 },
            losses: newGame?.losses + 1 || 1,
            streak: 0,
          };
        }
        newGame = {
          ...newGame,
          guesses: newGame?.guesses + 1 || 1,
        };

        setGames(JSON.stringify({...JSON.parse(games), [wordLength]: newGame}));

        setShake(false);
        setInput([]);
        setAnswers([...answers, newInput]);
      } else {
        setShake(true);
      }
    } else if (event.key === "Enter" || event.key === "↵") {
      setEnters(enters + 1);
      setShake(true);
    } else if (event.key === "Backspace" || event.key === "⌫") {
      const r = newInput.splice(0, newInput.length - 1);
      setInput(r);
    } else if (
      [
        "ㅂ",
        "ㅈ",
        "ㄷ",
        "ㄱ",
        "ㅅ",
        "ㅛ",
        "ㅕ",
        "ㅑ",
        "ㅐ",
        "ㅔ",
        "ㅁ",
        "ㄴ",
        "ㅇ",
        "ㄹ",
        "ㅎ",
        "ㅗ",
        "ㅓ",
        "ㅏ",
        "ㅣ",
        "ㅋ",
        "ㅌ",
        "ㅊ",
        "ㅍ",
        "ㅠ",
        "ㅜ",
        "ㅡ",
        "ㅒ",
        "ㅖ",
      ].includes(key)
    ) {
      if (newInput.length <= wordLength) {
        setInput(newInput);
      }
    }
  };

  return (
    <div {...props}>
      <div className="info">
        <div className="wordLength">Word Length {wordLength}</div>
        <InputSlider
          style={{ width: "100%" }}
          axis="x"
          xstep={1}
          xmin={2}
          xmax={10}
          x={wordLength}
          styles={{
            active: {
              backgroundColor: "#204b6b",
            },
            track: {
              backgroundColor: "#182c35",
            },
          }}
          onChange={({ x }) => handleLengthChange(x)}
        />
        {answers.length > 0 && (
          <>
            {(hangul.assemble(answers[answers.length - 1].filter((r) => r)) ===
              word ||
              answers.length === maxGuesses + 1) && (
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(answersToEmoji(word, answers));
                }}
              >
                Share Score
              </Button>
            )}
          </>
        )}
      </div>
      <div className="blocks-container">
        <div
          className="blocks"
          style={{
            maxWidth:
              wordLength === 2
                ? "174.666px"
                : wordLength === 3
                ? "256px"
                : wordLength === 4
                ? "337.333px"
                : wordLength === 5
                ? "418.666px"
                : wordLength === 6
                ? "500px"
                : wordLength === 7
                ? "581.333px"
                : wordLength === 8
                ? "662.666px"
                : wordLength === 9
                ? "744px"
                : "825.333px",
            gridTemplateColumns: "1fr ".repeat(wordLength),
          }}
        >
          {answers.map((random_word) => {
            return (
              <>
                {hangul.disassemble(word).map((stroke, i) => {
                  return (
                    <Card
                      key={wordLength + i + word}
                      index={i}
                      flip={true}
                      className={`block`}
                      color={
                        getWordColor(
                          hangul.disassemble(word),
                          hangul.disassemble(random_word)
                        )[i]
                      }
                    >
                      <div
                        style={{
                          fontSize: `min(max(10px, 8vw), ${
                            (70 * (11 - wordLength * 0.9)) / 10
                          }px)`,
                          margin: "auto",
                        }}
                        key={stroke + i}
                      >
                        {hangul.disassemble(random_word)[i]}
                      </div>
                    </Card>
                  );
                })}
              </>
            );
          })}

          {/* Current Guess */}
          {answers.length <= maxGuesses &&
            hangul.disassemble(word).map((stroke, i) => {
              return (
                <div>
                  {React.cloneElement(
                    <Card
                      index={i}
                      key={wordLength + i + word}
                      className={`block ${shake ? "shake" : ""}`}
                      key={enters}
                    >
                      <div
                        style={{
                          fontSize: `min(max(10px, 8vw), ${
                            (70 * (11 - wordLength * 0.6)) / 10
                          }px)`,
                          margin: "auto",
                        }}
                        className={`current block`}
                      >
                        {hangul.disassemble(input)[i]}
                      </div>
                    </Card>
                  )}
                </div>
              );
            })}

          {answers.length < maxGuesses &&
            [...new Array(maxGuesses - answers.length)].map((random_word) => {
              return (
                <>
                  {hangul.disassemble(word).map((stroke, i) => {
                    return (
                      <Card
                        index={i}
                        key={wordLength + i + word}
                        className={`dull block`}
                      >
                        <div
                          style={{
                            fontSize: `min(max(10px, 8vw), ${
                              (70 * (11 - wordLength * 0.9)) / 10
                            }px)`,
                            margin: "auto",
                          }}
                          key={stroke + i}
                          className={`block dull`}
                        ></div>
                      </Card>
                    );
                  })}
                </>
              );
            })}
        </div>
      </div>
      <Keyboard
        buttonClick={(key) => {
          handleInput({ key: key });
        }}
        highlight={answers
          .map((answer) => getHighlights(hangul.disassemble(word), answer))
          .flat()}
        softHighlight={answers
          .map((answer) => getSoftHighlights(hangul.disassemble(word), answer))
          .flat()}
        dull={answers.flat().filter((a) => {
          return !hangul.disassemble(word).includes(a);
        })}
      />
      {answers.length > 0 && (
        <>
          {hangul.assemble(answers[answers.length - 1].filter((r) => r)) ===
            word && <Modal>You guessed the word correctly!</Modal>}
          {answers.length === maxGuesses + 1 && (
            <Modal>The word was {word}!</Modal>
          )}
        </>
      )}
      <KeyboardEventHandler
        handleKeys={["all"]}
        onKeyEvent={(key, e) => {
          // if (e.repeat) return {};

          handleInput(e);
        }}
      />
    </div>
  );
}

export default styled(WordlePage)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .info {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 32px;

    button {
      margin-top: 24px;
      width: 100%;
    }
  }

  .blocks-container {
    height: 100%;
    flex-basis: 0;
  }

  .blocks {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 4px;
    margin: 0 auto;
    padding: 8px;
  }

  .block {
    aspect-ratio: 1;
    color: #3f5661;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .correct {
    color: #47a6f8;
    background: #204b6b;
    border: 2px solid #47a6f8;
  }

  .almost_correct {
    color: #e7a85f;
    background: #7b552b;
    border: 2px solid #e7a85f;
  }

  .dull {
    opacity: 0.5;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .wordLength {
    color: #606060;
    font-weight: bold;
    text-align: center;
  }
`;
