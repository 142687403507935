export const map = {
  ㅓ: ["_ㅓ_1.svg"],
  ㅣ: ["_ㅣ_1.svg"],
  ㅏ: ["_ㅏ_1.svg"],
  ㅑ: ["_ㅑ_1.svg"],
  ㅡ: ["_ㅡ_1.svg"],
  ㅜ: ["_ㅜ_1.svg"],
  ㅠ: ["_ㅠ_1.svg"],
  ㅗ: ["_ㅗ_1.svg"],
  ㅛ: ["_ㅛ_1.svg"],
  ㅕ: ["_ㅕ_1.svg"],
  ㅇ: ["_ㅇ_1.svg"],
  ㄹ: ["_ㄹ_1.svg"],
  ㅎ: ["_ㅎ_1.svg"],
  ㅊ: ["_ㅊ_1.svg"],
  ㅈ: ["_ㅈ_1.svg"],
  ㅂ: ["_ㅂ_1.svg"],
  ㄱ: ["_ㄱ_1.svg"],
  ㄴ: ["_ㄴ_1.svg"],
  ㅍ: ["_ㅍ_1.svg"],
  ㅋ: ["_ㅋ_1.svg"],
  ㅁ: ["_ㅁ_1.svg"],
  ㄷ: ["_ㄷ_1.svg"],
  ㅅ: ["_ㅅ_1.svg"],
  ㅌ: ["_ㅌ_1.svg"],
  어: ["_ㅇ_(ㅓ)_2.svg", "_(ㅇ)_ㅓ_2.svg"],
  이: ["_ㅇ_v_2.svg", "_c_ㅣ_2.svg"],
  아: ["_ㅇ_v_2.svg", "_c_ㅏ_2.svg"],
  야: ["_ㅇ_v_2.svg", "_c_ㅑ_2.svg"],
  으: ["_ㅇ_hu_2.svg", "_c_ㅡ_2.svg"],
  우: ["_ㅇ_hd_2.svg", "_(ㅇ)_ㅜ_2.svg"],
  유: ["_ㅇ_hd_2.svg", "_c_ㅠ_2.svg"],
  오: ["_ㅇ_(ㅗ)_2.svg", "_(ㅇ)_ㅗ_2.svg"],
  요: ["_ㅇ_hu_2.svg", "_c_ㅛ_2.svg"],
  여: ["_ㅇ_(ㅕ)_2.svg", "_(ㅇ)_ㅕ_2.svg"],
  러: ["_ㄹ_(ㅓ)_2.svg", "_c_ㅓ_2.svg"],
  리: ["_ㄹ_(ㅣ)_2.svg", "_c_ㅣ_2.svg"],
  라: ["_ㄹ_(ㅏ)_2.svg", "_c_ㅏ_2.svg"],
  랴: ["_ㄹ_v_2.svg", "_c_ㅑ_2.svg"],
  르: ["_ㄹ_(ㅡ)_2.svg", "_c_ㅡ_2.svg"],
  루: ["_ㄹ_hd_2.svg", "_c_ㅜ_2.svg"],
  류: ["_ㄹ_hd_2.svg", "_c_ㅠ_2.svg"],
  로: ["_ㄹ_(ㅗ)_2.svg", "_(ㄹ)_ㅗ_2.svg"],
  료: ["_ㄹ_(ㅛ)_2.svg", "_(ㄹ)_ㅛ_2.svg"],
  려: ["_ㄹ_v_2.svg", "_(ㄹ)_ㅕ_2.svg"],
  허: ["_ㅎ_v_2.svg", "_c_ㅓ_2.svg"],
  히: ["_ㅎ_v_2.svg", "_c_ㅣ_2.svg"],
  하: ["_ㅎ_v_2.svg", "_c_ㅏ_2.svg"],
  햐: ["_ㅎ_v_2.svg", "_c_ㅑ_2.svg"],
  흐: ["_ㅎ_hu_2.svg", "_c_ㅡ_2.svg"],
  후: ["_ㅎ_hd_2.svg", "_c_ㅜ_2.svg"],
  휴: ["_ㅎ_hd_2.svg", "_c_ㅠ_2.svg"],
  호: ["_ㅎ_hu_2.svg", "_(ㅎ)_ㅗ_2.svg"],
  효: ["_ㅎ_hu_2.svg", "_(ㅎ)_ㅛ_2.svg"],
  혀: ["_ㅎ_v_2.svg", "_(ㅎ)_ㅕ_2.svg"],
  처: ["_ㅊ_v_2.svg", "_c_ㅓ_2.svg"],
  치: ["_ㅊ_v_2.svg", "_c_ㅣ_2.svg"],
  차: ["_ㅊ_v_2.svg", "_c_ㅏ_2.svg"],
  챠: ["_ㅊ_v_2.svg", "_c_ㅑ_2.svg"],
  츠: ["_ㅊ_hu_2.svg", "_c_ㅡ_2.svg"],
  추: ["_ㅊ_hd_2.svg", "_c_ㅜ_2.svg"],
  츄: ["_ㅊ_hd_2.svg", "_c_ㅠ_2.svg"],
  초: ["_ㅊ_hu_2.svg", "_c_ㅗ_2.svg"],
  쵸: ["_ㅊ_hu_2.svg", "_(ㅊ)_ㅛ_2.svg"],
  쳐: ["_ㅊ_v_2.svg", "_(ㅊ)_ㅕ_2.svg"],
  저: ["_ㅈ_v_2.svg", "_c_ㅓ_2.svg"],
  지: ["_ㅈ_v_2.svg", "_c_ㅣ_2.svg"],
  자: ["_ㅈ_v_2.svg", "_c_ㅏ_2.svg"],
  쟈: ["_ㅈ_v_2.svg", "_c_ㅑ_2.svg"],
  즈: ["_ㅈ_hu_2.svg", "_c_ㅡ_2.svg"],
  주: ["_ㅈ_hd_2.svg", "_(ㅈ)_ㅜ_2.svg"],
  쥬: ["_ㅈ_hd_2.svg", "_c_ㅠ_2.svg"],
  조: ["_ㅈ_hu_2.svg", "_c_ㅗ_2.svg"],
  죠: ["_ㅈ_hu_2.svg", "_c_ㅛ_2.svg"],
  져: ["_ㅈ_v_2.svg", "_(ㅈ)_ㅕ_2.svg"],
  버: ["_ㅂ_v_2.svg", "_c_ㅓ_2.svg"],
  비: ["_ㅂ_v_2.svg", "_c_ㅣ_2.svg"],
  바: ["_ㅂ_v_2.svg", "_c_ㅏ_2.svg"],
  뱌: ["_ㅂ_v_2.svg", "_c_ㅑ_2.svg"],
  브: ["_ㅂ_hu_2.svg", "_c_ㅡ_2.svg"],
  부: ["_ㅂ_hd_2.svg", "_c_ㅜ_2.svg"],
  뷰: ["_ㅂ_hd_2.svg", "_c_ㅠ_2.svg"],
  보: ["_ㅂ_(ㅗ)_2.svg", "_(ㅂ)_ㅗ_2.svg"],
  뵤: ["_ㅂ_hu_2.svg", "_c_ㅛ_2.svg"],
  벼: ["_ㅂ_(ㅕ)_2.svg", "_(ㅂ)_ㅕ_2.svg"],
  거: ["_ㄱ_v_2.svg", "_c_ㅓ_2.svg"],
  기: ["_ㄱ_v_2.svg", "_c_ㅣ_2.svg"],
  가: ["_ㄱ_v_2.svg", "_c_ㅏ_2.svg"],
  갸: ["_ㄱ_v_2.svg", "_c_ㅑ_2.svg"],
  그: ["_ㄱ_(ㅡ)_2.svg", "_c_ㅡ_2.svg"],
  구: ["_ㄱ_(ㅜ)_2.svg", "_(ㄱ)_ㅜ_2.svg"],
  규: ["_ㄱ_(ㅠ)_2.svg", "_(ㄱ)_ㅠ_2.svg"],
  고: ["_ㄱ_hu_2.svg", "_c_ㅗ_2.svg"],
  교: ["_ㄱ_hu_2.svg", "_(ㄱ)_ㅛ_2.svg"],
  겨: ["_ㄱ_(ㅕ)_2.svg", "_(ㄱ)_ㅕ_2.svg"],
  너: ["_ㄴ_v_2.svg", "_c_ㅓ_2.svg"],
  니: ["_ㄴ_v_2.svg", "_c_ㅣ_2.svg"],
  나: ["_ㄴ_v_2.svg", "_c_ㅏ_2.svg"],
  냐: ["_ㄴ_v_2.svg", "_c_ㅑ_2.svg"],
  느: ["_ㄴ_hu_2.svg", "_c_ㅡ_2.svg"],
  누: ["_ㄴ_hd_2.svg", "_c_ㅜ_2.svg"],
  뉴: ["_ㄴ_hd_2.svg", "_c_ㅠ_2.svg"],
  노: ["_ㄴ_hu_2.svg", "_c_ㅗ_2.svg"],
  뇨: ["_ㄴ_hu_2.svg", "_(ㄴ)_ㅛ_2.svg"],
  녀: ["_ㄴ_v_2.svg", "_(ㄴ)_ㅕ_2.svg"],
  퍼: ["_ㅍ_v_2.svg", "_c_ㅓ_2.svg"],
  피: ["_ㅍ_v_2.svg", "_c_ㅣ_2.svg"],
  파: ["_ㅍ_v_2.svg", "_c_ㅏ_2.svg"],
  퍄: ["_ㅍ_v_2.svg", "_c_ㅑ_2.svg"],
  프: ["_ㅍ_hu_2.svg", "_c_ㅡ_2.svg"],
  푸: ["_ㅍ_hd_2.svg", "_c_ㅜ_2.svg"],
  퓨: ["_ㅍ_hd_2.svg", "_c_ㅠ_2.svg"],
  포: ["_ㅍ_(ㅗ)_2.svg", "_(ㅍ)_ㅗ_2.svg"],
  표: ["_ㅍ_(ㅛ)_2.svg", "_(ㅍ)_ㅛ_2.svg"],
  펴: ["_ㅍ_v_2.svg", "_(ㅍ)_ㅕ_2.svg"],
  커: ["_ㅋ_v_2.svg", "_c_ㅓ_2.svg"],
  키: ["_ㅋ_v_2.svg", "_c_ㅣ_2.svg"],
  카: ["_ㅋ_v_2.svg", "_c_ㅏ_2.svg"],
  캬: ["_ㅋ_v_2.svg", "_c_ㅑ_2.svg"],
  크: ["_ㅋ_(ㅡ)_2.svg", "_c_ㅡ_2.svg"],
  쿠: ["_ㅋ_(ㅜ)_2.svg", "_(ㅋ)_ㅜ_2.svg"],
  큐: ["_ㅋ_hd_2.svg", "_(ㅋ)_ㅠ_2.svg"],
  코: ["_ㅋ_hu_2.svg", "_c_ㅗ_2.svg"],
  쿄: ["_ㅋ_hu_2.svg", "_c_ㅛ_2.svg"],
  켜: ["_ㅋ_v_2.svg", "_(ㅋ)_ㅕ_2.svg"],
  머: ["_ㅁ_v_2.svg", "_c_ㅓ_2.svg"],
  미: ["_ㅁ_v_2.svg", "_c_ㅣ_2.svg"],
  마: ["_ㅁ_v_2.svg", "_c_ㅏ_2.svg"],
  먀: ["_ㅁ_v_2.svg", "_c_ㅑ_2.svg"],
  므: ["_ㅁ_hu_2.svg", "_c_ㅡ_2.svg"],
  무: ["_ㅁ_hd_2.svg", "_c_ㅜ_2.svg"],
  뮤: ["_ㅁ_hd_2.svg", "_c_ㅠ_2.svg"],
  모: ["_ㅁ_(ㅗ)_2.svg", "_(ㅁ)_ㅗ_2.svg"],
  묘: ["_ㅁ_(ㅛ)_2.svg", "_(ㅁ)_ㅛ_2.svg"],
  며: ["_ㅁ_(ㅕ)_2.svg", "_(ㅁ)_ㅕ_2.svg"],
  더: ["_ㄷ_v_2.svg", "_c_ㅓ_2.svg"],
  디: ["_ㄷ_v_2.svg", "_c_ㅣ_2.svg"],
  다: ["_ㄷ_v_2.svg", "_c_ㅏ_2.svg"],
  댜: ["_ㄷ_v_2.svg", "_c_ㅑ_2.svg"],
  드: ["_ㄷ_hu_2.svg", "_c_ㅡ_2.svg"],
  두: ["_ㄷ_hd_2.svg", "_c_ㅜ_2.svg"],
  듀: ["_ㄷ_hd_2.svg", "_c_ㅠ_2.svg"],
  도: ["_ㄷ_(ㅗ)_2.svg", "_(ㄷ)_ㅗ_2.svg"],
  됴: ["_ㄷ_hu_2.svg", "_c_ㅛ_2.svg"],
  뎌: ["_ㄷ_v_2.svg", "_(ㄷ)_ㅕ_2.svg"],
  서: ["_ㅅ_v_2.svg", "_c_ㅓ_2.svg"],
  시: ["_ㅅ_v_2.svg", "_c_ㅣ_2.svg"],
  사: ["_ㅅ_v_2.svg", "_c_ㅏ_2.svg"],
  샤: ["_ㅅ_v_2.svg", "_c_ㅑ_2.svg"],
  스: ["_ㅅ_hu_2.svg", "_c_ㅡ_2.svg"],
  수: ["_ㅅ_hd_2.svg", "_c_ㅜ_2.svg"],
  슈: ["_ㅅ_hd_2.svg", "_c_ㅠ_2.svg"],
  소: ["_ㅅ_hu_2.svg", "_c_ㅗ_2.svg"],
  쇼: ["_ㅅ_hu_2.svg", "_c_ㅛ_2.svg"],
  셔: ["_ㅅ_v_2.svg", "_(ㅅ)_ㅕ_2.svg"],
  터: ["_ㅌ_v_2.svg", "_c_ㅓ_2.svg"],
  티: ["_ㅌ_v_2.svg", "_c_ㅣ_2.svg"],
  타: ["_ㅌ_v_2.svg", "_c_ㅏ_2.svg"],
  탸: ["_ㅌ_v_2.svg", "_c_ㅑ_2.svg"],
  트: ["_ㅌ_hu_2.svg", "_c_ㅡ_2.svg"],
  투: ["_ㅌ_hd_2.svg", "_c_ㅜ_2.svg"],
  튜: ["_ㅌ_hd_2.svg", "_c_ㅠ_2.svg"],
  토: ["_ㅌ_hu_2.svg", "_(ㅌ)_ㅗ_2.svg"],
  툐: ["_ㅌ_(ㅛ)_2.svg", "_(ㅌ)_ㅛ_2.svg"],
  텨: ["_ㅌ_v_2.svg", "_(ㅌ)_ㅕ_2.svg"],
  엉: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  얼: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㄹ_3.svg"],
  엏: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  엋: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  엊: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  업: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  억: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  언: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  엎: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  엌: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  엄: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  얻: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  엇: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  엍: ["_ㅇ_(ㅓ)_c_3.svg", "_(ㅇ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  잉: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  일: ["_ㅇ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  잏: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  잋: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  잊: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  입: ["_ㅇ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  익: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  인: ["_ㅇ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  잎: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  잌: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  임: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  읻: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  잇: ["_ㅇ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  잍: ["_ㅇ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  앙: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  알: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  앟: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  앛: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  앚: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  압: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  악: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  안: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  앞: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  앜: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  암: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  앋: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  앗: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  앝: ["_ㅇ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  양: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  얄: ["_ㅇ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  얗: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  얓: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  얒: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  얍: ["_ㅇ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  약: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  얀: ["_ㅇ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  얖: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  얔: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  얌: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  얃: ["_ㅇ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  얏: ["_ㅇ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  얕: ["_ㅇ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  응: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  을: ["_ㅇ_(ㅡ)_(ㄹ)_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  읗: ["_ㅇ_(ㅡ)_(ㅎ)_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  읓: ["_ㅇ_(ㅡ)_(ㅊ)_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  읒: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  읍: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  윽: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  은: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  읖: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  읔: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  음: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  읃: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  읏: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  읕: ["_ㅇ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  웅: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  울: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㄹ)_3.svg", "_(ㅇ)_(ㅜ)_ㄹ_3.svg"],
  웋: ["_ㅇ_(ㅜ)_(ㅎ)_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  웇: ["_ㅇ_(ㅜ)_(ㅊ)_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  웆: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  웁: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  욱: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  운: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  웊: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  웈: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  움: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  욷: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  웃: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㅇ)_(ㅜ)_ㅅ_3.svg"],
  웉: ["_ㅇ_hd_c_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  융: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  율: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_(ㅇ)_(ㅠ)_ㄹ_3.svg"],
  윻: ["_ㅇ_(ㅠ)_(ㅎ)_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  윷: ["_ㅇ_(ㅠ)_(ㅊ)_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  윶: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  윱: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  육: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  윤: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  윺: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  윸: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  윰: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  윧: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  윳: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  윹: ["_ㅇ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  옹: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  올: ["_ㅇ_(ㅗ)_(ㄹ)_3.svg", "_(ㅇ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  옿: ["_ㅇ_(ㅗ)_(ㅎ)_3.svg", "_(ㅇ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  옻: ["_ㅇ_(ㅗ)_(ㅊ)_3.svg", "_(ㅇ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  옺: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  옵: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  옥: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  온: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  옾: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  옼: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  옴: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  옫: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  옷: ["_ㅇ_(ㅗ)_c_3.svg", "_(ㅇ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  옽: ["_ㅇ_(ㅗ)_(ㅌ)_3.svg", "_(ㅇ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  용: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  욜: ["_ㅇ_(ㅛ)_(ㄹ)_3.svg", "_(ㅇ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  욯: ["_ㅇ_(ㅛ)_(ㅎ)_3.svg", "_(ㅇ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  욫: ["_ㅇ_(ㅛ)_(ㅊ)_3.svg", "_(ㅇ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  욪: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  욥: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  욕: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  욘: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  욮: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  욬: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  욤: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  욛: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  욧: ["_ㅇ_(ㅛ)_c_3.svg", "_(ㅇ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  욭: ["_ㅇ_(ㅛ)_(ㅌ)_3.svg", "_(ㅇ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  영: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  열: ["_ㅇ_(ㅕ)_(ㄹ)_3.svg", "_(ㅇ)_ㅕ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  옇: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  옃: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  옂: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  엽: ["_ㅇ_(ㅕ)_(ㅂ)_3.svg", "_(ㅇ)_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  역: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  연: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  옆: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  옄: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  염: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  엳: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  엿: ["_ㅇ_v_c_3.svg", "_(ㅇ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  옅: ["_ㅇ_(ㅕ)_(ㅌ)_3.svg", "_(ㅇ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  렁: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  럴: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  렇: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  렃: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  렂: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  럽: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  럭: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  런: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  렆: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  렄: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  럼: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  럳: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  럿: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  렅: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  링: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  릴: ["_ㄹ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  맇: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  맃: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  맂: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  립: ["_ㄹ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  릭: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  린: ["_ㄹ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  맆: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  맄: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  림: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  릳: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  릿: ["_ㄹ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  맅: ["_ㄹ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  랑: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  랄: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  랗: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  랓: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  랒: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  랍: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  락: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  란: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  랖: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  랔: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  람: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  랃: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  랏: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  랕: ["_ㄹ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  량: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  랼: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  럏: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  럋: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  럊: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  럅: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  략: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  랸: ["_ㄹ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  럎: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  럌: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  럄: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  랻: ["_ㄹ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  럇: ["_ㄹ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  럍: ["_ㄹ_v_c_3.svg", "_(ㄹ)_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  릉: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  를: ["_ㄹ_(ㅡ)_(ㄹ)_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  릏: ["_ㄹ_(ㅡ)_(ㅎ)_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  릋: ["_ㄹ_(ㅡ)_(ㅊ)_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_(ㄹ)_hu_ㅊ_3.svg"],
  릊: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  릅: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  륵: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  른: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  릎: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  릌: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  름: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  륻: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  릇: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  릍: ["_ㄹ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  룽: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㅇ)_3.svg", "_(ㄹ)_(ㅜ)_ㅇ_3.svg"],
  룰: ["_ㄹ_hd_(ㄹ)_3.svg", "_(ㄹ)_ㅜ_(ㄹ)_3.svg", "_c_(ㅜ)_ㄹ_3.svg"],
  뤃: ["_ㄹ_hd_(ㅎ)_3.svg", "_(ㄹ)_ㅜ_(ㅎ)_3.svg", "_(ㄹ)_hd_ㅎ_3.svg"],
  룿: ["_ㄹ_hd_(ㅊ)_3.svg", "_(ㄹ)_ㅜ_(ㅊ)_3.svg", "_(ㄹ)_hd_ㅊ_3.svg"],
  룾: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㅈ)_3.svg", "_(ㄹ)_hd_ㅈ_3.svg"],
  룹: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  룩: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㄱ)_3.svg", "_(ㄹ)_hd_ㄱ_3.svg"],
  룬: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_c_3.svg", "_c_hd_ㄴ_3.svg"],
  뤂: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㅍ)_3.svg", "_(ㄹ)_hd_ㅍ_3.svg"],
  뤀: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㅋ)_3.svg", "_(ㄹ)_hd_ㅋ_3.svg"],
  룸: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_c_3.svg", "_(ㄹ)_hd_ㅁ_3.svg"],
  룯: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㄷ)_3.svg", "_(ㄹ)_hd_ㄷ_3.svg"],
  룻: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅜ_(ㅅ)_3.svg", "_(ㄹ)_(ㅜ)_ㅅ_3.svg"],
  뤁: ["_ㄹ_hd_(ㅌ)_3.svg", "_(ㄹ)_ㅜ_(ㅌ)_3.svg", "_(ㄹ)_hd_ㅌ_3.svg"],
  륭: ["_ㄹ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  률: ["_ㄹ_hd_(ㄹ)_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  륳: ["_ㄹ_hd_(ㅎ)_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_(ㄹ)_hd_ㅎ_3.svg"],
  륯: ["_ㄹ_hd_(ㅊ)_3.svg", "_c_ㅠ_c_3.svg", "_(ㄹ)_hd_ㅊ_3.svg"],
  륮: ["_ㄹ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_(ㄹ)_hd_ㅈ_3.svg"],
  륩: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅠ_(ㅂ)_3.svg", "_c_hd_ㅂ_3.svg"],
  륙: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅠ_(ㄱ)_3.svg", "_(ㄹ)_(ㅠ)_ㄱ_3.svg"],
  륜: ["_ㄹ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  륲: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅠ_(ㅍ)_3.svg", "_(ㄹ)_hd_ㅍ_3.svg"],
  륰: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅠ_(ㅋ)_3.svg", "_(ㄹ)_hd_ㅋ_3.svg"],
  륨: ["_ㄹ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_(ㄹ)_hd_ㅁ_3.svg"],
  륟: ["_ㄹ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_(ㄹ)_hd_ㄷ_3.svg"],
  륫: ["_ㄹ_hd_c_3.svg", "_(ㄹ)_ㅠ_(ㅅ)_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  륱: ["_ㄹ_hd_(ㅌ)_3.svg", "_c_ㅠ_c_3.svg", "_(ㄹ)_hd_ㅌ_3.svg"],
  롱: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  롤: ["_ㄹ_(ㅗ)_(ㄹ)_3.svg", "_(ㄹ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  롷: ["_ㄹ_(ㅗ)_(ㅎ)_3.svg", "_(ㄹ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  롳: ["_ㄹ_(ㅗ)_(ㅊ)_3.svg", "_(ㄹ)_ㅗ_(ㅊ)_3.svg", "_(ㄹ)_hu_ㅊ_3.svg"],
  롲: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  롭: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  록: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  론: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  롶: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  롴: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  롬: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  롣: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  롯: ["_ㄹ_hu_c_3.svg", "_(ㄹ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  롵: ["_ㄹ_(ㅗ)_(ㅌ)_3.svg", "_(ㄹ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  룡: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  룔: ["_ㄹ_(ㅛ)_(ㄹ)_3.svg", "_(ㄹ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  룧: ["_ㄹ_(ㅛ)_(ㅎ)_3.svg", "_(ㄹ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  룣: ["_ㄹ_(ㅛ)_(ㅊ)_3.svg", "_(ㄹ)_ㅛ_(ㅊ)_3.svg", "_(ㄹ)_(ㅛ)_ㅊ_3.svg"],
  룢: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  룝: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  룍: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  룐: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  룦: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  룤: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  룜: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  룓: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  룟: ["_ㄹ_(ㅛ)_c_3.svg", "_(ㄹ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  룥: ["_ㄹ_(ㅛ)_(ㅌ)_3.svg", "_(ㄹ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  령: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  렬: ["_ㄹ_(ㅕ)_(ㄹ)_3.svg", "_(ㄹ)_ㅕ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  렿: ["_ㄹ_(ㅕ)_(ㅎ)_3.svg", "_(ㄹ)_ㅕ_(ㅎ)_3.svg", "_c_v_ㅎ_3.svg"],
  렻: ["_ㄹ_(ㅕ)_(ㅊ)_3.svg", "_(ㄹ)_ㅕ_(ㅊ)_3.svg", "_c_v_ㅊ_3.svg"],
  렺: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  렵: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  력: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  련: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  렾: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_(ㅍ)_3.svg", "_c_v_ㅍ_3.svg"],
  렼: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_(ㅋ)_3.svg", "_c_v_ㅋ_3.svg"],
  렴: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  렫: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  렷: ["_ㄹ_(ㅕ)_c_3.svg", "_(ㄹ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  렽: ["_ㄹ_(ㅕ)_(ㅌ)_3.svg", "_(ㄹ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  헝: ["_ㅎ_(ㅓ)_c_3.svg", "_(ㅎ)_ㅓ_(ㅇ)_3.svg", "_(ㅎ)_(ㅓ)_ㅇ_3.svg"],
  헐: ["_ㅎ_(ㅓ)_(ㄹ)_3.svg", "_(ㅎ)_ㅓ_(ㄹ)_3.svg", "_(ㅎ)_(ㅓ)_ㄹ_3.svg"],
  헣: ["_ㅎ_(ㅓ)_(ㅎ)_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㅎ_3.svg"],
  헟: ["_ㅎ_(ㅓ)_(ㅊ)_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㅊ_3.svg"],
  헞: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㅈ_3.svg"],
  헙: ["_ㅎ_(ㅓ)_(ㅂ)_3.svg", "_(ㅎ)_ㅓ_(ㅂ)_3.svg", "_(ㅎ)_(ㅓ)_ㅂ_3.svg"],
  헉: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㄱ_3.svg"],
  헌: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  헢: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㅍ_3.svg"],
  헠: ["_ㅎ_(ㅓ)_(ㅋ)_3.svg", "_(ㅎ)_ㅓ_(ㅋ)_3.svg", "_(ㅎ)_(ㅓ)_ㅋ_3.svg"],
  험: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㅁ_3.svg"],
  헏: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㄷ_3.svg"],
  헛: ["_ㅎ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅎ)_(ㅓ)_ㅅ_3.svg"],
  헡: ["_ㅎ_(ㅓ)_(ㅌ)_3.svg", "_(ㅎ)_ㅓ_(ㅌ)_3.svg", "_(ㅎ)_(ㅓ)_ㅌ_3.svg"],
  힝: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  힐: ["_ㅎ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_(ㅎ)_(ㅣ)_ㄹ_3.svg"],
  힣: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_(ㅎ)_(ㅣ)_ㅎ_3.svg"],
  힟: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_(ㅎ)_(ㅣ)_ㅊ_3.svg"],
  힞: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  힙: ["_ㅎ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  힉: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  힌: ["_ㅎ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  힢: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  힠: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  힘: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  힏: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  힛: ["_ㅎ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  힡: ["_ㅎ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_(ㅎ)_(ㅣ)_ㅌ_3.svg"],
  항: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  할: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_(ㅎ)_v_ㄹ_3.svg"],
  핳: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_(ㅎ)_v_ㅎ_3.svg"],
  핯: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_(ㅎ)_v_ㅊ_3.svg"],
  핮: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  합: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  학: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  한: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  핲: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  핰: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  함: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  핟: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  핫: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  핱: ["_ㅎ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_(ㅎ)_v_ㅌ_3.svg"],
  향: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  햘: ["_ㅎ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_(ㅎ)_v_ㄹ_3.svg"],
  햫: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_(ㅎ)_v_ㅎ_3.svg"],
  햧: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_(ㅎ)_v_ㅊ_3.svg"],
  햦: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  햡: ["_ㅎ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  햑: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  햔: ["_ㅎ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  햪: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  햨: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  햠: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  햗: ["_ㅎ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  햣: ["_ㅎ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  햩: ["_ㅎ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_(ㅎ)_v_ㅌ_3.svg"],
  흥: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㅇ_3.svg"],
  흘: ["_ㅎ_(ㅡ)_(ㄹ)_3.svg", "_(ㅎ)_ㅡ_(ㄹ)_3.svg", "_(ㅎ)_(ㅡ)_ㄹ_3.svg"],
  흫: ["_ㅎ_(ㅡ)_(ㅎ)_3.svg", "_(ㅎ)_ㅡ_(ㅎ)_3.svg", "_(ㅎ)_(ㅡ)_ㅎ_3.svg"],
  흧: ["_ㅎ_(ㅡ)_(ㅊ)_3.svg", "_(ㅎ)_ㅡ_(ㅊ)_3.svg", "_(ㅎ)_(ㅡ)_ㅊ_3.svg"],
  흦: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㅈ_3.svg"],
  흡: ["_ㅎ_(ㅡ)_(ㅂ)_3.svg", "_(ㅎ)_ㅡ_(ㅂ)_3.svg", "_c_hu_ㅂ_3.svg"],
  흑: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_hu_ㄱ_3.svg"],
  흔: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  흪: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㅍ_3.svg"],
  흨: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㅋ_3.svg"],
  흠: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㅁ_3.svg"],
  흗: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㄷ_3.svg"],
  흣: ["_ㅎ_(ㅡ)_c_3.svg", "_(ㅎ)_ㅡ_c_3.svg", "_(ㅎ)_(ㅡ)_ㅅ_3.svg"],
  흩: ["_ㅎ_(ㅡ)_(ㅌ)_3.svg", "_(ㅎ)_ㅡ_(ㅌ)_3.svg", "_(ㅎ)_(ㅡ)_ㅌ_3.svg"],
  훙: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅇ)_3.svg", "_(ㅎ)_hd_ㅇ_3.svg"],
  훌: ["_ㅎ_hd_(ㄹ)_3.svg", "_(ㅎ)_ㅜ_(ㄹ)_3.svg", "_(ㅎ)_(ㅜ)_ㄹ_3.svg"],
  훟: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅎ)_3.svg", "_(ㅎ)_hd_ㅎ_3.svg"],
  훛: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅊ)_3.svg", "_(ㅎ)_hd_ㅊ_3.svg"],
  훚: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅈ)_3.svg", "_(ㅎ)_hd_ㅈ_3.svg"],
  훕: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅂ)_3.svg", "_(ㅎ)_hd_ㅂ_3.svg"],
  훅: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㄱ)_3.svg", "_(ㅎ)_hd_ㄱ_3.svg"],
  훈: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㄴ)_3.svg", "_(ㅎ)_hd_ㄴ_3.svg"],
  훞: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅍ)_3.svg", "_(ㅎ)_hd_ㅍ_3.svg"],
  훜: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅋ)_3.svg", "_(ㅎ)_hd_ㅋ_3.svg"],
  훔: ["_ㅎ_hd_(ㅁ)_3.svg", "_(ㅎ)_ㅜ_(ㅁ)_3.svg", "_(ㅎ)_hd_ㅁ_3.svg"],
  훋: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㄷ)_3.svg", "_(ㅎ)_hd_ㄷ_3.svg"],
  훗: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅅ)_3.svg", "_(ㅎ)_(ㅜ)_ㅅ_3.svg"],
  훝: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅜ_(ㅌ)_3.svg", "_(ㅎ)_hd_ㅌ_3.svg"],
  흉: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅇ)_3.svg", "_(ㅎ)_(ㅠ)_ㅇ_3.svg"],
  휼: ["_ㅎ_hd_(ㄹ)_3.svg", "_(ㅎ)_ㅠ_(ㄹ)_3.svg", "_(ㅎ)_hd_ㄹ_3.svg"],
  흏: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅎ)_3.svg", "_(ㅎ)_(ㅠ)_ㅎ_3.svg"],
  흋: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅊ)_3.svg", "_(ㅎ)_(ㅠ)_ㅊ_3.svg"],
  흊: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅈ)_3.svg", "_(ㅎ)_(ㅠ)_ㅈ_3.svg"],
  흅: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅂ)_3.svg", "_(ㅎ)_(ㅠ)_ㅂ_3.svg"],
  휵: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㄱ)_3.svg", "_(ㅎ)_(ㅠ)_ㄱ_3.svg"],
  휸: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㄴ)_3.svg", "_(ㅎ)_(ㅠ)_ㄴ_3.svg"],
  흎: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅍ)_3.svg", "_(ㅎ)_(ㅠ)_ㅍ_3.svg"],
  흌: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅋ)_3.svg", "_(ㅎ)_hd_ㅋ_3.svg"],
  흄: ["_ㅎ_hd_(ㅁ)_3.svg", "_(ㅎ)_ㅠ_(ㅁ)_3.svg", "_(ㅎ)_(ㅠ)_ㅁ_3.svg"],
  휻: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㄷ)_3.svg", "_(ㅎ)_(ㅠ)_ㄷ_3.svg"],
  흇: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  흍: ["_ㅎ_hd_c_3.svg", "_(ㅎ)_ㅠ_(ㅌ)_3.svg", "_(ㅎ)_(ㅠ)_ㅌ_3.svg"],
  홍: ["_ㅎ_(ㅗ)_(ㅇ)_3.svg", "_(ㅎ)_ㅗ_(ㅇ)_3.svg", "_c_hu_ㅇ_3.svg"],
  홀: ["_ㅎ_(ㅗ)_(ㄹ)_3.svg", "_(ㅎ)_ㅗ_(ㄹ)_3.svg", "_(ㅎ)_hu_ㄹ_3.svg"],
  홓: ["_ㅎ_(ㅗ)_(ㅎ)_3.svg", "_(ㅎ)_ㅗ_(ㅎ)_3.svg", "_(ㅎ)_(ㅗ)_ㅎ_3.svg"],
  홏: ["_ㅎ_(ㅗ)_(ㅊ)_3.svg", "_(ㅎ)_ㅗ_(ㅊ)_3.svg", "_(ㅎ)_(ㅗ)_ㅊ_3.svg"],
  홎: ["_ㅎ_(ㅗ)_(ㅈ)_3.svg", "_(ㅎ)_ㅗ_(ㅈ)_3.svg", "_c_hu_ㅈ_3.svg"],
  홉: ["_ㅎ_(ㅗ)_(ㅂ)_3.svg", "_(ㅎ)_ㅗ_(ㅂ)_3.svg", "_c_hu_ㅂ_3.svg"],
  혹: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_(ㅎ)_hu_ㄱ_3.svg"],
  혼: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  홒: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_(ㅎ)_hu_ㅍ_3.svg"],
  홐: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_(ㅎ)_hu_ㅋ_3.svg"],
  홈: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_(ㅎ)_hu_ㅁ_3.svg"],
  혿: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_(ㅎ)_hu_ㄷ_3.svg"],
  홋: ["_ㅎ_(ㅗ)_c_3.svg", "_(ㅎ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  홑: ["_ㅎ_(ㅗ)_(ㅌ)_3.svg", "_(ㅎ)_ㅗ_(ㅌ)_3.svg", "_(ㅎ)_hu_ㅌ_3.svg"],
  횽: ["_ㅎ_(ㅛ)_(ㅇ)_3.svg", "_(ㅎ)_ㅛ_(ㅇ)_3.svg", "_c_hu_ㅇ_3.svg"],
  횰: ["_ㅎ_(ㅛ)_(ㄹ)_3.svg", "_(ㅎ)_ㅛ_(ㄹ)_3.svg", "_(ㅎ)_hu_ㄹ_3.svg"],
  훃: ["_ㅎ_(ㅛ)_(ㅎ)_3.svg", "_(ㅎ)_ㅛ_(ㅎ)_3.svg", "_(ㅎ)_(ㅛ)_ㅎ_3.svg"],
  횿: ["_ㅎ_(ㅛ)_(ㅊ)_3.svg", "_(ㅎ)_ㅛ_(ㅊ)_3.svg", "_(ㅎ)_(ㅛ)_ㅊ_3.svg"],
  횾: ["_ㅎ_(ㅛ)_(ㅈ)_3.svg", "_(ㅎ)_ㅛ_(ㅈ)_3.svg", "_c_hu_ㅈ_3.svg"],
  횹: ["_ㅎ_(ㅛ)_(ㅂ)_3.svg", "_(ㅎ)_ㅛ_(ㅂ)_3.svg", "_c_hu_ㅂ_3.svg"],
  횩: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_(ㅎ)_hu_ㄱ_3.svg"],
  횬: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  훂: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_(ㅎ)_hu_ㅍ_3.svg"],
  훀: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_(ㅎ)_hu_ㅋ_3.svg"],
  횸: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_(ㅎ)_hu_ㅁ_3.svg"],
  횯: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_(ㅎ)_hu_ㄷ_3.svg"],
  횻: ["_ㅎ_(ㅛ)_c_3.svg", "_(ㅎ)_ㅛ_c_3.svg", "_(ㅎ)_(ㅛ)_ㅅ_3.svg"],
  훁: ["_ㅎ_(ㅛ)_(ㅌ)_3.svg", "_(ㅎ)_ㅛ_(ㅌ)_3.svg", "_(ㅎ)_(ㅛ)_ㅌ_3.svg"],
  형: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  혈: ["_ㅎ_(ㅕ)_(ㄹ)_3.svg", "_(ㅎ)_ㅕ_(ㄹ)_3.svg", "_(ㅎ)_(ㅕ)_ㄹ_3.svg"],
  혛: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㅎ_3.svg"],
  혗: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㅊ_3.svg"],
  혖: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㅈ_3.svg"],
  협: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_(ㅂ)_3.svg", "_(ㅎ)_(ㅕ)_ㅂ_3.svg"],
  혁: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㄱ_3.svg"],
  현: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  혚: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㅍ_3.svg"],
  혘: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㅋ_3.svg"],
  혐: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㅁ_3.svg"],
  혇: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_(ㅎ)_(ㅕ)_ㄷ_3.svg"],
  혓: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  혙: ["_ㅎ_v_c_3.svg", "_(ㅎ)_ㅕ_(ㅌ)_3.svg", "_(ㅎ)_(ㅕ)_ㅌ_3.svg"],
  청: ["_ㅊ_(ㅓ)_c_3.svg", "_(ㅊ)_ㅓ_(ㅇ)_3.svg", "_c_v_ㅇ_3.svg"],
  철: ["_ㅊ_(ㅓ)_(ㄹ)_3.svg", "_(ㅊ)_ㅓ_(ㄹ)_3.svg", "_(ㅊ)_(ㅓ)_ㄹ_3.svg"],
  첳: ["_ㅊ_(ㅓ)_(ㅎ)_3.svg", "_c_ㅓ_c_3.svg", "_(ㅊ)_(ㅓ)_ㅎ_3.svg"],
  첯: ["_ㅊ_(ㅓ)_(ㅊ)_3.svg", "_(ㅊ)_ㅓ_(ㅊ)_3.svg", "_c_v_ㅊ_3.svg"],
  첮: ["_ㅊ_(ㅓ)_c_3.svg", "_(ㅊ)_ㅓ_(ㅈ)_3.svg", "_c_v_ㅈ_3.svg"],
  첩: ["_ㅊ_(ㅓ)_c_3.svg", "_(ㅊ)_ㅓ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  척: ["_ㅊ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅊ)_(ㅓ)_ㄱ_3.svg"],
  천: ["_ㅊ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  첲: ["_ㅊ_(ㅓ)_c_3.svg", "_(ㅊ)_ㅓ_(ㅍ)_3.svg", "_(ㅊ)_(ㅓ)_ㅍ_3.svg"],
  첰: ["_ㅊ_(ㅓ)_c_3.svg", "_(ㅊ)_ㅓ_(ㅋ)_3.svg", "_c_v_ㅋ_3.svg"],
  첨: ["_ㅊ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  첟: ["_ㅊ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_(ㅊ)_(ㅓ)_ㄷ_3.svg"],
  첫: ["_ㅊ_(ㅓ)_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  첱: ["_ㅊ_(ㅓ)_c_3.svg", "_(ㅊ)_ㅓ_(ㅌ)_3.svg", "_(ㅊ)_(ㅓ)_ㅌ_3.svg"],
  칭: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  칠: ["_ㅊ_(ㅣ)_(ㄹ)_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  칳: ["_ㅊ_(ㅣ)_(ㅎ)_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  칯: ["_ㅊ_(ㅣ)_(ㅊ)_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  칮: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  칩: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  칙: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  친: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  칲: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  칰: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  침: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  칟: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  칫: ["_ㅊ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  칱: ["_ㅊ_(ㅣ)_(ㅌ)_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  창: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  찰: ["_ㅊ_(ㅏ)_(ㄹ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  챃: ["_ㅊ_(ㅏ)_(ㅎ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  찿: ["_ㅊ_(ㅏ)_(ㅊ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  찾: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  찹: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  착: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  찬: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  챂: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  챀: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  참: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  찯: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  찻: ["_ㅊ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  챁: ["_ㅊ_(ㅏ)_(ㅌ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  챵: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  챨: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_(ㅊ)_(ㅑ)_ㄹ_3.svg"],
  챻: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_(ㅊ)_(ㅑ)_ㅎ_3.svg"],
  챷: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_(ㅊ)_(ㅑ)_ㅊ_3.svg"],
  챶: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  챱: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  챡: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  챤: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  챺: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  챸: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  챰: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  챧: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  챳: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  챹: ["_ㅊ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_(ㅊ)_(ㅑ)_ㅌ_3.svg"],
  층: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  츨: ["_ㅊ_(ㅡ)_(ㄹ)_3.svg", "_(ㅊ)_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  츻: ["_ㅊ_(ㅡ)_(ㅎ)_3.svg", "_(ㅊ)_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  츷: ["_ㅊ_(ㅡ)_(ㅊ)_3.svg", "_(ㅊ)_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  츶: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  츱: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  측: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  츤: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  츺: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  츸: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  츰: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  츧: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  츳: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  츹: ["_ㅊ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  충: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  출: ["_ㅊ_(ㅜ)_(ㄹ)_3.svg", "_(ㅊ)_ㅜ_(ㄹ)_3.svg", "_(ㅊ)_(ㅜ)_ㄹ_3.svg"],
  춯: ["_ㅊ_(ㅜ)_(ㅎ)_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  춫: ["_ㅊ_hd_(ㅊ)_3.svg", "_(ㅊ)_ㅜ_(ㅊ)_3.svg", "_(ㅊ)_hd_ㅊ_3.svg"],
  춪: ["_ㅊ_(ㅜ)_c_3.svg", "_(ㅊ)_ㅜ_(ㅈ)_3.svg", "_(ㅊ)_(ㅜ)_ㅈ_3.svg"],
  춥: ["_ㅊ_(ㅜ)_(ㅂ)_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  축: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  춘: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  춮: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  춬: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  춤: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  춛: ["_ㅊ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  춧: ["_ㅊ_(ㅜ)_c_3.svg", "_(ㅊ)_ㅜ_(ㅅ)_3.svg", "_(ㅊ)_(ㅜ)_ㅅ_3.svg"],
  춭: ["_ㅊ_hd_(ㅌ)_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  츙: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  츌: ["_ㅊ_hd_(ㄹ)_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  츟: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  츛: ["_ㅊ_hd_(ㅊ)_3.svg", "_c_ㅠ_c_3.svg", "_(ㅊ)_hd_ㅊ_3.svg"],
  츚: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  츕: ["_ㅊ_hd_c_3.svg", "_(ㅊ)_ㅠ_(ㅂ)_3.svg", "_c_hd_ㅂ_3.svg"],
  츅: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  츈: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  츞: ["_ㅊ_hd_c_3.svg", "_(ㅊ)_ㅠ_(ㅍ)_3.svg", "_(ㅊ)_(ㅠ)_ㅍ_3.svg"],
  츜: ["_ㅊ_hd_c_3.svg", "_(ㅊ)_ㅠ_(ㅋ)_3.svg", "_(ㅊ)_(ㅠ)_ㅋ_3.svg"],
  츔: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  츋: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  츗: ["_ㅊ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  츝: ["_ㅊ_hd_(ㅌ)_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  총: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  촐: ["_ㅊ_(ㅗ)_(ㄹ)_3.svg", "_(ㅊ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  촣: ["_ㅊ_(ㅗ)_(ㅎ)_3.svg", "_(ㅊ)_ㅗ_(ㅎ)_3.svg", "_(ㅊ)_(ㅗ)_ㅎ_3.svg"],
  촟: ["_ㅊ_(ㅗ)_(ㅊ)_3.svg", "_(ㅊ)_ㅗ_(ㅊ)_3.svg", "_(ㅊ)_(ㅗ)_ㅊ_3.svg"],
  촞: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  촙: ["_ㅊ_(ㅗ)_(ㅂ)_3.svg", "_(ㅊ)_ㅗ_(ㅂ)_3.svg", "_c_hu_ㅂ_3.svg"],
  촉: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  촌: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  촢: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  촠: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  촘: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  촏: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  촛: ["_ㅊ_(ㅗ)_c_3.svg", "_(ㅊ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  촡: ["_ㅊ_(ㅗ)_(ㅌ)_3.svg", "_(ㅊ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  춍: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅇ_3.svg"],
  춀: ["_ㅊ_(ㅛ)_(ㄹ)_3.svg", "_(ㅊ)_ㅛ_(ㄹ)_3.svg", "_(ㅊ)_(ㅛ)_ㄹ_3.svg"],
  춓: ["_ㅊ_(ㅛ)_(ㅎ)_3.svg", "_(ㅊ)_ㅛ_(ㅎ)_3.svg", "_(ㅊ)_(ㅛ)_ㅎ_3.svg"],
  춏: ["_ㅊ_(ㅛ)_(ㅊ)_3.svg", "_(ㅊ)_ㅛ_(ㅊ)_3.svg", "_(ㅊ)_(ㅛ)_ㅊ_3.svg"],
  춎: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅈ_3.svg"],
  춉: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅂ_3.svg"],
  쵹: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  쵼: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  춒: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅍ_3.svg"],
  춐: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅋ_3.svg"],
  춈: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅁ_3.svg"],
  쵿: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㄷ_3.svg"],
  춋: ["_ㅊ_(ㅛ)_c_3.svg", "_(ㅊ)_ㅛ_c_3.svg", "_(ㅊ)_(ㅛ)_ㅅ_3.svg"],
  춑: ["_ㅊ_(ㅛ)_(ㅌ)_3.svg", "_(ㅊ)_ㅛ_(ㅌ)_3.svg", "_(ㅊ)_(ㅛ)_ㅌ_3.svg"],
  쳥: ["_ㅊ_(ㅕ)_c_3.svg", "_(ㅊ)_ㅕ_(ㅇ)_3.svg", "_c_v_ㅇ_3.svg"],
  쳘: ["_ㅊ_(ㅕ)_(ㄹ)_3.svg", "_c_ㅕ_c_3.svg", "_(ㅊ)_(ㅕ)_ㄹ_3.svg"],
  쳫: ["_ㅊ_(ㅕ)_(ㅎ)_3.svg", "_c_ㅕ_c_3.svg", "_(ㅊ)_(ㅕ)_ㅎ_3.svg"],
  쳧: ["_ㅊ_(ㅕ)_(ㅊ)_3.svg", "_(ㅊ)_ㅕ_(ㅊ)_3.svg", "_(ㅊ)_(ㅕ)_ㅊ_3.svg"],
  쳦: ["_ㅊ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  쳡: ["_ㅊ_(ㅕ)_(ㅂ)_3.svg", "_(ㅊ)_ㅕ_(ㅂ)_3.svg", "_(ㅊ)_(ㅕ)_ㅂ_3.svg"],
  쳑: ["_ㅊ_(ㅕ)_c_3.svg", "_(ㅊ)_ㅕ_(ㄱ)_3.svg", "_(ㅊ)_(ㅕ)_ㄱ_3.svg"],
  쳔: ["_ㅊ_(ㅕ)_(ㄴ)_3.svg", "_(ㅊ)_ㅕ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  쳪: ["_ㅊ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  쳨: ["_ㅊ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  쳠: ["_ㅊ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  쳗: ["_ㅊ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  쳣: ["_ㅊ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  쳩: ["_ㅊ_(ㅕ)_(ㅌ)_3.svg", "_(ㅊ)_ㅕ_(ㅌ)_3.svg", "_(ㅊ)_(ㅕ)_ㅌ_3.svg"],
  정: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  절: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㄹ_3.svg"],
  젛: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  젗: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  젖: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  접: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  적: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  전: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  젚: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  젘: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  점: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  젇: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  젓: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  젙: ["_ㅈ_v_c_3.svg", "_(ㅈ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  징: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  질: ["_ㅈ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  짛: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  짗: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  짖: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  집: ["_ㅈ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  직: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  진: ["_ㅈ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  짚: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  짘: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  짐: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  짇: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  짓: ["_ㅈ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  짙: ["_ㅈ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  장: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  잘: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  잫: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  잧: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  잦: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  잡: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  작: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  잔: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  잪: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  잨: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  잠: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  잗: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  잣: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  잩: ["_ㅈ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  쟝: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  쟐: ["_ㅈ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  쟣: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  쟟: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  쟞: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  쟙: ["_ㅈ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  쟉: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  쟌: ["_ㅈ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  쟢: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  쟠: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  쟘: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  쟏: ["_ㅈ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  쟛: ["_ㅈ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  쟡: ["_ㅈ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  증: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  즐: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  즣: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  즟: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  즞: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  즙: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  즉: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  즌: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  즢: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  즠: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  즘: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  즏: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  즛: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  즡: ["_ㅈ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  중: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  줄: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㄹ)_3.svg", "_(ㅈ)_hd_ㄹ_3.svg"],
  줗: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  줓: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  줒: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  줍: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  죽: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  준: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  줖: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  줔: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  줌: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  줃: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  줏: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㅈ)_(ㅜ)_ㅅ_3.svg"],
  줕: ["_ㅈ_hd_c_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  즁: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  쥴: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_(ㅈ)_hd_ㄹ_3.svg"],
  즇: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  즃: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  즂: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  쥽: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  쥭: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  쥰: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  즆: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  즄: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  쥼: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  쥳: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  쥿: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  즅: ["_ㅈ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  종: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  졸: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  좋: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  좇: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  좆: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  좁: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  족: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  존: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  좊: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  좈: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  좀: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  졷: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  좃: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  좉: ["_ㅈ_(ㅗ)_c_3.svg", "_(ㅈ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  죵: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  죨: ["_ㅈ_(ㅛ)_(ㄹ)_3.svg", "_(ㅈ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  죻: ["_ㅈ_(ㅛ)_(ㅎ)_3.svg", "_(ㅈ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  죷: ["_ㅈ_(ㅛ)_(ㅊ)_3.svg", "_(ㅈ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  죶: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  죱: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  죡: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  죤: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  죺: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  죸: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  죰: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  죧: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  죳: ["_ㅈ_hu_c_3.svg", "_(ㅈ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  죹: ["_ㅈ_(ㅛ)_(ㅌ)_3.svg", "_(ㅈ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  졍: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  졀: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄹ_3.svg"],
  졓: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  졏: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  졎: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  졉: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  젹: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  젼: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  졒: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  졐: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  졈: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  젿: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  졋: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  졑: ["_ㅈ_(ㅕ)_c_3.svg", "_c_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  벙: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  벌: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㄹ_3.svg"],
  벟: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  벛: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  벚: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  법: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  벅: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  번: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  벞: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  벜: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  범: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  벋: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  벗: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  벝: ["_ㅂ_v_c_3.svg", "_(ㅂ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  빙: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  빌: ["_ㅂ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  빟: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  빛: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  빚: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  빕: ["_ㅂ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  빅: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  빈: ["_ㅂ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  빞: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  빜: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  빔: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  빋: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  빗: ["_ㅂ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  빝: ["_ㅂ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  방: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  발: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  밯: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  밫: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  밪: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  밥: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  박: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  반: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  밮: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  밬: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  밤: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  받: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  밧: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  밭: ["_ㅂ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  뱡: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  뱔: ["_ㅂ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  뱧: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  뱣: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  뱢: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  뱝: ["_ㅂ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  뱍: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  뱐: ["_ㅂ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  뱦: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  뱤: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  뱜: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  뱓: ["_ㅂ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  뱟: ["_ㅂ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  뱥: ["_ㅂ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  븡: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  블: ["_ㅂ_hu_(ㄹ)_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  븧: ["_ㅂ_hu_(ㅎ)_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  븣: ["_ㅂ_hu_(ㅊ)_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  븢: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  븝: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  븍: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  븐: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  븦: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  븤: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  븜: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  븓: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  븟: ["_ㅂ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  븥: ["_ㅂ_hu_(ㅌ)_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  붕: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  불: ["_ㅂ_hd_c_3.svg", "_(ㅂ)_ㅜ_(ㄹ)_3.svg", "_(ㅂ)_(ㅜ)_ㄹ_3.svg"],
  붛: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  붗: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  붖: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  붑: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  북: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  분: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  붚: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  붘: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  붐: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  붇: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  붓: ["_ㅂ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㅂ)_(ㅜ)_ㅅ_3.svg"],
  붙: ["_ㅂ_hd_c_3.svg", "_(ㅂ)_ㅜ_(ㅌ)_3.svg", "_(ㅂ)_hd_ㅌ_3.svg"],
  븅: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  뷸: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  븋: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  븇: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  븆: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  븁: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  뷱: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  뷴: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  븊: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  븈: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  븀: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  뷷: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  븃: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  븉: ["_ㅂ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_(ㅂ)_hd_ㅌ_3.svg"],
  봉: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  볼: ["_ㅂ_(ㅗ)_(ㄹ)_3.svg", "_(ㅂ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  봏: ["_ㅂ_(ㅗ)_(ㅎ)_3.svg", "_(ㅂ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  봋: ["_ㅂ_(ㅗ)_(ㅊ)_3.svg", "_(ㅂ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  봊: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  봅: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  복: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  본: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  봎: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  봌: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  봄: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  볻: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  봇: ["_ㅂ_(ㅗ)_c_3.svg", "_(ㅂ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  봍: ["_ㅂ_(ㅗ)_(ㅌ)_3.svg", "_(ㅂ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  뵹: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  뵬: ["_ㅂ_(ㅛ)_(ㄹ)_3.svg", "_(ㅂ)_ㅛ_(ㄹ)_3.svg", "_(ㅂ)_(ㅛ)_ㄹ_3.svg"],
  뵿: ["_ㅂ_hu_(ㅎ)_3.svg", "_(ㅂ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  뵻: ["_ㅂ_hu_(ㅊ)_3.svg", "_(ㅂ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  뵺: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  뵵: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  뵥: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  뵨: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  뵾: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  뵼: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  뵴: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  뵫: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  뵷: ["_ㅂ_hu_c_3.svg", "_(ㅂ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  뵽: ["_ㅂ_hu_(ㅌ)_3.svg", "_(ㅂ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  병: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  별: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㄹ_3.svg"],
  볗: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  볓: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  볒: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  볍: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅂ_3.svg"],
  벽: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  변: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  볖: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  볔: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  볌: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  볃: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  볏: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  볕: ["_ㅂ_(ㅕ)_c_3.svg", "_(ㅂ)_ㅕ_c_3.svg", "_c_v_ㅌ_3.svg"],
  겅: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  걸: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㄹ_3.svg"],
  겋: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  겇: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  겆: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  겁: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  걱: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  건: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  겊: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  겈: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  검: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  걷: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  것: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  겉: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  깅: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  길: ["_ㄱ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  깋: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  깇: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  깆: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  깁: ["_ㄱ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  긱: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  긴: ["_ㄱ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  깊: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  깈: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  김: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  긷: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  깃: ["_ㄱ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  깉: ["_ㄱ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  강: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  갈: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  갛: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  갗: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  갖: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  갑: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  각: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  간: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  갚: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  갘: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  감: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  갇: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  갓: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  같: ["_ㄱ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  걍: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  걀: ["_ㄱ_(ㅑ)_(ㄹ)_3.svg", "_(ㄱ)_ㅑ_(ㄹ)_3.svg", "_(ㄱ)_(ㅑ)_ㄹ_3.svg"],
  걓: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  걏: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  걎: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  걉: ["_ㄱ_v_c_3.svg", "_(ㄱ)_ㅑ_(ㅂ)_3.svg", "_(ㄱ)_(ㅑ)_ㅂ_3.svg"],
  갹: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  갼: ["_ㄱ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  걒: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  걐: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  걈: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  갿: ["_ㄱ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  걋: ["_ㄱ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  걑: ["_ㄱ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  긍: ["_ㄱ_(ㅡ)_(ㅇ)_3.svg", "_(ㄱ)_ㅡ_(ㅇ)_3.svg", "_c_hu_ㅇ_3.svg"],
  글: ["_ㄱ_(ㅡ)_(ㄹ)_3.svg", "_(ㄱ)_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  긓: ["_ㄱ_(ㅡ)_(ㅎ)_3.svg", "_(ㄱ)_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  긏: ["_ㄱ_(ㅡ)_(ㅊ)_3.svg", "_(ㄱ)_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  긎: ["_ㄱ_(ㅡ)_(ㅈ)_3.svg", "_(ㄱ)_ㅡ_(ㅈ)_3.svg", "_c_hu_ㅈ_3.svg"],
  급: ["_ㄱ_(ㅡ)_(ㅂ)_3.svg", "_(ㄱ)_ㅡ_(ㅂ)_3.svg", "_c_hu_ㅂ_3.svg"],
  극: ["_ㄱ_(ㅡ)_c_3.svg", "_(ㄱ)_ㅡ_(ㄱ)_3.svg", "_c_hu_ㄱ_3.svg"],
  근: ["_ㄱ_(ㅡ)_(ㄴ)_3.svg", "_(ㄱ)_ㅡ_(ㄴ)_3.svg", "_c_hu_ㄴ_3.svg"],
  긒: ["_ㄱ_(ㅡ)_(ㅍ)_3.svg", "_(ㄱ)_ㅡ_(ㅍ)_3.svg", "_c_hu_ㅍ_3.svg"],
  긐: ["_ㄱ_(ㅡ)_(ㅋ)_3.svg", "_(ㄱ)_ㅡ_(ㅋ)_3.svg", "_c_hu_ㅋ_3.svg"],
  금: ["_ㄱ_(ㅡ)_(ㅁ)_3.svg", "_(ㄱ)_ㅡ_(ㅁ)_3.svg", "_c_hu_ㅁ_3.svg"],
  귿: ["_ㄱ_(ㅡ)_(ㄷ)_3.svg", "_(ㄱ)_ㅡ_(ㄷ)_3.svg", "_c_hu_ㄷ_3.svg"],
  긋: ["_ㄱ_(ㅡ)_(ㅅ)_3.svg", "_(ㄱ)_ㅡ_(ㅅ)_3.svg", "_c_hu_ㅅ_3.svg"],
  긑: ["_ㄱ_(ㅡ)_(ㅌ)_3.svg", "_(ㄱ)_ㅡ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  궁: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅜ_(ㅇ)_3.svg", "_(ㄱ)_(ㅜ)_ㅇ_3.svg"],
  굴: ["_ㄱ_(ㅜ)_(ㄹ)_3.svg", "_(ㄱ)_ㅜ_(ㄹ)_3.svg", "_(ㄱ)_(ㅜ)_ㄹ_3.svg"],
  궇: ["_ㄱ_(ㅜ)_(ㅎ)_3.svg", "_(ㄱ)_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  궃: ["_ㄱ_(ㅜ)_(ㅊ)_3.svg", "_(ㄱ)_ㅜ_(ㅊ)_3.svg", "_(ㄱ)_(ㅜ)_ㅊ_3.svg"],
  궂: ["_ㄱ_(ㅜ)_(ㅈ)_3.svg", "_(ㄱ)_ㅜ_(ㅈ)_3.svg", "_(ㄱ)_(ㅜ)_ㅈ_3.svg"],
  굽: ["_ㄱ_(ㅜ)_(ㅂ)_3.svg", "_(ㄱ)_ㅜ_(ㅂ)_3.svg", "_c_hd_ㅂ_3.svg"],
  국: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅜ_(ㄱ)_3.svg", "_(ㄱ)_(ㅜ)_ㄱ_3.svg"],
  군: ["_ㄱ_(ㅜ)_(ㄴ)_3.svg", "_(ㄱ)_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  궆: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  궄: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅜ_(ㅋ)_3.svg", "_(ㄱ)_(ㅜ)_ㅋ_3.svg"],
  굼: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅜ_c_3.svg", "_(ㄱ)_(ㅜ)_ㅁ_3.svg"],
  굳: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅜ_c_3.svg", "_(ㄱ)_(ㅜ)_ㄷ_3.svg"],
  굿: ["_ㄱ_(ㅜ)_(ㅅ)_3.svg", "_(ㄱ)_ㅜ_(ㅅ)_3.svg", "_(ㄱ)_(ㅜ)_ㅅ_3.svg"],
  궅: ["_ㄱ_hd_(ㅌ)_3.svg", "_(ㄱ)_ㅜ_c_3.svg", "_(ㄱ)_hd_ㅌ_3.svg"],
  귱: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  귤: ["_ㄱ_hd_(ㄹ)_3.svg", "_(ㄱ)_ㅠ_(ㄹ)_3.svg", "_(ㄱ)_hd_ㄹ_3.svg"],
  귷: ["_ㄱ_hd_(ㅎ)_3.svg", "_(ㄱ)_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  귳: ["_ㄱ_hd_(ㅊ)_3.svg", "_(ㄱ)_ㅠ_(ㅊ)_3.svg", "_(ㄱ)_(ㅠ)_ㅊ_3.svg"],
  귲: ["_ㄱ_hd_(ㅈ)_3.svg", "_(ㄱ)_ㅠ_(ㅈ)_3.svg", "_(ㄱ)_hd_ㅈ_3.svg"],
  귭: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  귝: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_(ㄱ)_3.svg", "_(ㄱ)_(ㅠ)_ㄱ_3.svg"],
  균: ["_ㄱ_hd_(ㄴ)_3.svg", "_(ㄱ)_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  귶: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  귴: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  귬: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  귣: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  귯: ["_ㄱ_hd_c_3.svg", "_(ㄱ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  귵: ["_ㄱ_hd_(ㅌ)_3.svg", "_(ㄱ)_ㅠ_(ㅌ)_3.svg", "_(ㄱ)_hd_ㅌ_3.svg"],
  공: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  골: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㄹ_3.svg"],
  곻: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅎ_3.svg"],
  곷: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅊ_3.svg"],
  곶: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  곱: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  곡: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  곤: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  곺: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  곸: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  곰: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  곧: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  곳: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  곹: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅗ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  굥: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  굘: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㄹ_3.svg"],
  굫: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅎ_3.svg"],
  굧: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅊ_3.svg"],
  굦: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  굡: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  굑: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  굔: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  굪: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  굨: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  굠: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  굗: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  굣: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  굩: ["_ㄱ_hu_c_3.svg", "_(ㄱ)_ㅛ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  경: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  결: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㄹ_3.svg"],
  곃: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  겿: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  겾: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  겹: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅂ_3.svg"],
  격: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  견: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  곂: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  곀: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  겸: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  겯: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  겻: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  곁: ["_ㄱ_(ㅕ)_c_3.svg", "_(ㄱ)_ㅕ_c_3.svg", "_c_v_ㅌ_3.svg"],
  넝: ["_ㄴ_v_c_3.svg", "_c_ㅓ_(ㅇ)_3.svg", "_c_v_ㅇ_3.svg"],
  널: ["_ㄴ_v_c_3.svg", "_(ㄴ)_ㅓ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  넣: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  넟: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  넞: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  넙: ["_ㄴ_v_c_3.svg", "_(ㄴ)_ㅓ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  넉: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  넌: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  넢: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  넠: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  넘: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  넏: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  넛: ["_ㄴ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  넡: ["_ㄴ_v_c_3.svg", "_(ㄴ)_ㅓ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  닝: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  닐: ["_ㄴ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  닣: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  닟: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  닞: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  닙: ["_ㄴ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  닉: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  닌: ["_ㄴ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  닢: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  닠: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  님: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  닏: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  닛: ["_ㄴ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  닡: ["_ㄴ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  낭: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  날: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  낳: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  낯: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  낮: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  납: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  낙: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  난: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  낲: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  낰: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  남: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  낟: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  낫: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  낱: ["_ㄴ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  냥: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  냘: ["_ㄴ_v_c_3.svg", "_(ㄴ)_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  냫: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  냧: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  냦: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  냡: ["_ㄴ_v_c_3.svg", "_(ㄴ)_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  냑: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  냔: ["_ㄴ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  냪: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  냨: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  냠: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  냗: ["_ㄴ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  냣: ["_ㄴ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  냩: ["_ㄴ_v_c_3.svg", "_(ㄴ)_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  능: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  늘: ["_ㄴ_(ㅡ)_(ㄹ)_3.svg", "_(ㄴ)_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  늫: ["_ㄴ_(ㅡ)_(ㅎ)_3.svg", "_(ㄴ)_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  늧: ["_ㄴ_(ㅡ)_(ㅊ)_3.svg", "_(ㄴ)_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  늦: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  늡: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  늑: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  는: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  늪: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  늨: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  늠: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  늗: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  늣: ["_ㄴ_(ㅡ)_c_3.svg", "_(ㄴ)_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  늩: ["_ㄴ_(ㅡ)_(ㅌ)_3.svg", "_(ㄴ)_ㅡ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  눙: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  눌: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㄹ)_3.svg", "_c_(ㅜ)_ㄹ_3.svg"],
  눟: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  눛: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  눚: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  눕: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  눅: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  눈: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  눞: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  눜: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  눔: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  눋: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  눗: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㄴ)_(ㅜ)_ㅅ_3.svg"],
  눝: ["_ㄴ_hd_c_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  늉: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  뉼: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  늏: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  늋: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  늊: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  늅: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  뉵: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  뉸: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  늎: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  늌: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  늄: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  뉻: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  늇: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  늍: ["_ㄴ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  농: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  놀: ["_ㄴ_(ㅗ)_(ㄹ)_3.svg", "_(ㄴ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  놓: ["_ㄴ_(ㅗ)_(ㅎ)_3.svg", "_(ㄴ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  놏: ["_ㄴ_(ㅗ)_(ㅊ)_3.svg", "_(ㄴ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  놎: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  놉: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  녹: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  논: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  높: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  놐: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  놈: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  녿: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  놋: ["_ㄴ_(ㅗ)_c_3.svg", "_(ㄴ)_ㅗ_c_3.svg", "_(ㄴ)_(ㅗ)_ㅅ_3.svg"],
  놑: ["_ㄴ_(ㅗ)_(ㅌ)_3.svg", "_(ㄴ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  뇽: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  뇰: ["_ㄴ_(ㅛ)_(ㄹ)_3.svg", "_(ㄴ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  눃: ["_ㄴ_(ㅛ)_(ㅎ)_3.svg", "_(ㄴ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  뇿: ["_ㄴ_(ㅛ)_(ㅊ)_3.svg", "_(ㄴ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  뇾: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  뇹: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  뇩: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  뇬: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  눂: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  눀: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  뇸: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  뇯: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  뇻: ["_ㄴ_(ㅛ)_c_3.svg", "_(ㄴ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  눁: ["_ㄴ_(ㅛ)_(ㅌ)_3.svg", "_(ㄴ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  녕: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  녈: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_(ㄹ)_3.svg", "_(ㄴ)_(ㅕ)_ㄹ_3.svg"],
  녛: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  녗: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  녖: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  녑: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  녁: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  년: ["_ㄴ_(ㅕ)_(ㄴ)_3.svg", "_(ㄴ)_ㅕ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  녚: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  녘: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  념: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  녇: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  녓: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  녙: ["_ㄴ_(ㅕ)_c_3.svg", "_(ㄴ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  펑: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  펄: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㄹ_3.svg"],
  펗: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  펓: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  펒: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  펍: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  퍽: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  펀: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  펖: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  펔: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  펌: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  펃: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  펏: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  펕: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  핑: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  필: ["_ㅍ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  핗: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  핓: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  핒: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  핍: ["_ㅍ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  픽: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  핀: ["_ㅍ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  핖: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  핔: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  핌: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  핃: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  핏: ["_ㅍ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  핕: ["_ㅍ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  팡: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  팔: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  팧: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  팣: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  팢: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  팝: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  팍: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  판: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  팦: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  팤: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  팜: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  팓: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  팟: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  팥: ["_ㅍ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  퍙: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  퍌: ["_ㅍ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  퍟: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  퍛: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  퍚: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  퍕: ["_ㅍ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  퍅: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  퍈: ["_ㅍ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  퍞: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  퍜: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  퍔: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  퍋: ["_ㅍ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  퍗: ["_ㅍ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  퍝: ["_ㅍ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  픙: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  플: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_(ㅍ)_hu_ㄹ_3.svg"],
  픟: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  픛: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  픚: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  픕: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  픅: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  픈: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  픞: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  픜: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  픔: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  픋: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  픗: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  픝: ["_ㅍ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  풍: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  풀: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㄹ)_3.svg", "_c_(ㅜ)_ㄹ_3.svg"],
  풓: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  풏: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  풎: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  풉: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  푹: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  푼: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  풒: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  풐: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  품: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  푿: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  풋: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㅍ)_(ㅜ)_ㅅ_3.svg"],
  풑: ["_ㅍ_hd_c_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  퓽: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_(ㅍ)_(ㅠ)_ㅇ_3.svg"],
  퓰: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  픃: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  퓿: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  퓾: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  퓹: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  퓩: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  퓬: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  픂: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  픀: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  퓸: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  퓯: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  퓻: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  픁: ["_ㅍ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  퐁: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  폴: ["_ㅍ_hu_(ㄹ)_3.svg", "_(ㅍ)_ㅗ_(ㄹ)_3.svg", "_(ㅍ)_hu_ㄹ_3.svg"],
  퐇: ["_ㅍ_hu_(ㅎ)_3.svg", "_(ㅍ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  퐃: ["_ㅍ_hu_(ㅊ)_3.svg", "_(ㅍ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  퐂: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  폽: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  폭: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  폰: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  퐆: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  퐄: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  폼: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  폳: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  폿: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  퐅: ["_ㅍ_hu_c_3.svg", "_(ㅍ)_ㅗ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  푱: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  푤: ["_ㅍ_(ㅛ)_(ㄹ)_3.svg", "_(ㅍ)_ㅛ_(ㄹ)_3.svg", "_(ㅍ)_hu_ㄹ_3.svg"],
  푷: ["_ㅍ_(ㅛ)_(ㅎ)_3.svg", "_(ㅍ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  푳: ["_ㅍ_(ㅛ)_(ㅊ)_3.svg", "_(ㅍ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  푲: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  푭: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  푝: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  푠: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  푶: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  푴: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  푬: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  푣: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  푯: ["_ㅍ_(ㅛ)_c_3.svg", "_(ㅍ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  푵: ["_ㅍ_(ㅛ)_(ㅌ)_3.svg", "_(ㅍ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  평: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  펼: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㄹ_3.svg"],
  폏: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  폋: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  폊: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  폅: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅂ_3.svg"],
  펵: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  편: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  폎: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  폌: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  폄: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  펻: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  폇: ["_ㅍ_v_c_3.svg", "_(ㅍ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  폍: ["_ㅍ_(ㅕ)_(ㅌ)_3.svg", "_(ㅍ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  컹: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  컬: ["_ㅋ_(ㅓ)_(ㄹ)_3.svg", "_(ㅋ)_ㅓ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  컿: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  컻: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  컺: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  컵: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  컥: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  컨: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  컾: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  컼: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  컴: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  컫: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  컷: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  컽: ["_ㅋ_(ㅓ)_c_3.svg", "_(ㅋ)_ㅓ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  킹: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  킬: ["_ㅋ_v_(ㄹ)_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  킿: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  킻: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  킺: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  킵: ["_ㅋ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  킥: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  킨: ["_ㅋ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  킾: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  킼: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  킴: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  킫: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  킷: ["_ㅋ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  킽: ["_ㅋ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  캉: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  칼: ["_ㅋ_v_(ㄹ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  캏: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  캋: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  캊: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  캅: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  칵: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  칸: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  캎: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  캌: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  캄: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  칻: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  캇: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  캍: ["_ㅋ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  컁: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  캴: ["_ㅋ_v_(ㄹ)_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  컇: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  컃: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  컂: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  캽: ["_ㅋ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  캭: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  캰: ["_ㅋ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  컆: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  컄: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  캼: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  캳: ["_ㅋ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  캿: ["_ㅋ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  컅: ["_ㅋ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  킁: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  클: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㄹ_3.svg"],
  킇: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅎ_3.svg"],
  킃: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅊ_3.svg"],
  킂: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  큽: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  큭: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  큰: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  킆: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  킄: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  큼: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  큳: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  큿: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  킅: ["_ㅋ_(ㅡ)_c_3.svg", "_(ㅋ)_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  쿵: ["_ㅋ_hd_(ㅇ)_3.svg", "_(ㅋ)_ㅜ_(ㅇ)_3.svg", "_(ㅋ)_(ㅜ)_ㅇ_3.svg"],
  쿨: ["_ㅋ_hd_(ㄹ)_3.svg", "_(ㅋ)_ㅜ_(ㄹ)_3.svg", "_(ㅋ)_hd_ㄹ_3.svg"],
  쿻: ["_ㅋ_hd_(ㅎ)_3.svg", "_(ㅋ)_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  쿷: ["_ㅋ_hd_(ㅊ)_3.svg", "_(ㅋ)_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  쿶: ["_ㅋ_(ㅜ)_c_3.svg", "_(ㅋ)_ㅜ_c_3.svg", "_c_hd_ㅈ_3.svg"],
  쿱: ["_ㅋ_(ㅜ)_c_3.svg", "_(ㅋ)_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  쿡: ["_ㅋ_hd_(ㄱ)_3.svg", "_(ㅋ)_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  쿤: ["_ㅋ_hd_(ㄴ)_3.svg", "_(ㅋ)_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  쿺: ["_ㅋ_hd_(ㅍ)_3.svg", "_(ㅋ)_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  쿸: ["_ㅋ_hd_(ㅋ)_3.svg", "_(ㅋ)_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  쿰: ["_ㅋ_hd_(ㅁ)_3.svg", "_(ㅋ)_ㅜ_(ㅁ)_3.svg", "_(ㅋ)_hd_ㅁ_3.svg"],
  쿧: ["_ㅋ_hd_(ㄷ)_3.svg", "_(ㅋ)_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  쿳: ["_ㅋ_hd_(ㅅ)_3.svg", "_(ㅋ)_ㅜ_(ㅅ)_3.svg", "_(ㅋ)_(ㅜ)_ㅅ_3.svg"],
  쿹: ["_ㅋ_hd_(ㅌ)_3.svg", "_(ㅋ)_ㅜ_(ㅌ)_3.svg", "_(ㅋ)_(ㅜ)_ㅌ_3.svg"],
  큥: ["_ㅋ_hd_(ㅇ)_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  큘: ["_ㅋ_(ㅠ)_(ㄹ)_3.svg", "_(ㅋ)_ㅠ_(ㄹ)_3.svg", "_(ㅋ)_(ㅠ)_ㄹ_3.svg"],
  큫: ["_ㅋ_(ㅠ)_(ㅎ)_3.svg", "_(ㅋ)_ㅠ_(ㅎ)_3.svg", "_(ㅋ)_(ㅠ)_ㅎ_3.svg"],
  큧: ["_ㅋ_(ㅠ)_(ㅊ)_3.svg", "_(ㅋ)_ㅠ_(ㅊ)_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  큦: ["_ㅋ_(ㅠ)_c_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  큡: ["_ㅋ_(ㅠ)_c_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  큑: ["_ㅋ_hd_(ㄱ)_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  큔: ["_ㅋ_hd_(ㄴ)_3.svg", "_(ㅋ)_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  큪: ["_ㅋ_hd_(ㅍ)_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  큨: ["_ㅋ_(ㅠ)_(ㅋ)_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  큠: ["_ㅋ_hd_(ㅁ)_3.svg", "_(ㅋ)_ㅠ_(ㅁ)_3.svg", "_(ㅋ)_hd_ㅁ_3.svg"],
  큗: ["_ㅋ_hd_(ㄷ)_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  큣: ["_ㅋ_hd_(ㅅ)_3.svg", "_(ㅋ)_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  큩: ["_ㅋ_hd_(ㅌ)_3.svg", "_(ㅋ)_ㅠ_(ㅌ)_3.svg", "_(ㅋ)_hd_ㅌ_3.svg"],
  콩: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  콜: ["_ㅋ_(ㅗ)_(ㄹ)_3.svg", "_(ㅋ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  콯: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  콫: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  콪: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  콥: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  콕: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  콘: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  콮: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  콬: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  콤: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  콛: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  콧: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  콭: ["_ㅋ_(ㅗ)_c_3.svg", "_(ㅋ)_ㅗ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  쿙: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  쿌: ["_ㅋ_(ㅛ)_(ㄹ)_3.svg", "_(ㅋ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  쿟: ["_ㅋ_(ㅛ)_(ㅎ)_3.svg", "_(ㅋ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  쿛: ["_ㅋ_(ㅛ)_(ㅊ)_3.svg", "_(ㅋ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  쿚: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  쿕: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  쿅: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  쿈: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  쿞: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  쿜: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  쿔: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  쿋: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  쿗: ["_ㅋ_(ㅛ)_c_3.svg", "_(ㅋ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  쿝: ["_ㅋ_(ㅛ)_(ㅌ)_3.svg", "_(ㅋ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  켱: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  켤: ["_ㅋ_(ㅕ)_(ㄹ)_3.svg", "_(ㅋ)_ㅕ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  켷: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  켳: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  켲: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  켭: ["_ㅋ_(ㅕ)_(ㅂ)_3.svg", "_(ㅋ)_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  켝: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  켠: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  켶: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  켴: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  켬: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  켣: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  켯: ["_ㅋ_(ㅕ)_c_3.svg", "_(ㅋ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  켵: ["_ㅋ_(ㅕ)_(ㅌ)_3.svg", "_(ㅋ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  멍: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  멀: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㄹ_3.svg"],
  멓: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  멏: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  멎: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  멉: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅂ_3.svg"],
  먹: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  먼: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  멒: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  멐: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  멈: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  먿: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  멋: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  멑: ["_ㅁ_v_c_3.svg", "_(ㅁ)_ㅓ_c_3.svg", "_c_v_ㅌ_3.svg"],
  밍: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  밀: ["_ㅁ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  밓: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  및: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  밎: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  밉: ["_ㅁ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  믹: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  민: ["_ㅁ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  밒: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  밐: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  밈: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  믿: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  밋: ["_ㅁ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  밑: ["_ㅁ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  망: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  말: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  맣: ["_ㅁ_(ㅏ)_(ㅎ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  맟: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  맞: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  맙: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  막: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  만: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  맢: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  맠: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  맘: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  맏: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  맛: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  맡: ["_ㅁ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  먕: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  먈: ["_ㅁ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  먛: ["_ㅁ_(ㅑ)_(ㅎ)_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  먗: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  먖: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  먑: ["_ㅁ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  먁: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  먄: ["_ㅁ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  먚: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  먘: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  먐: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  먇: ["_ㅁ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  먓: ["_ㅁ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  먙: ["_ㅁ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  믕: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  믈: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㄹ_3.svg"],
  믛: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅎ_3.svg"],
  믗: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅊ_3.svg"],
  믖: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  믑: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  믁: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  믄: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  믚: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  믘: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  믐: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  믇: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  믓: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  믙: ["_ㅁ_(ㅡ)_c_3.svg", "_(ㅁ)_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  뭉: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  물: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㄹ)_3.svg", "_(ㅁ)_hd_ㄹ_3.svg"],
  뭏: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  뭋: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  뭊: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  뭅: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  묵: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  문: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  뭎: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  뭌: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  뭄: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  묻: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  뭇: ["_ㅁ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㅁ)_(ㅜ)_ㅅ_3.svg"],
  뭍: ["_ㅁ_hd_c_3.svg", "_(ㅁ)_ㅜ_(ㅌ)_3.svg", "_(ㅁ)_hd_ㅌ_3.svg"],
  뮹: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  뮬: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_(ㅁ)_hd_ㄹ_3.svg"],
  뮿: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  뮻: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  뮺: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  뮵: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  뮥: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  뮨: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  뮾: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  뮼: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  뮴: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  뮫: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  뮷: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  뮽: ["_ㅁ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_(ㅁ)_hd_ㅌ_3.svg"],
  몽: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  몰: ["_ㅁ_hu_(ㄹ)_3.svg", "_(ㅁ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  뫃: ["_ㅁ_hu_(ㅎ)_3.svg", "_(ㅁ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  몿: ["_ㅁ_hu_(ㅊ)_3.svg", "_(ㅁ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  몾: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  몹: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  목: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  몬: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  뫂: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  뫀: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  몸: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  몯: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  못: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  뫁: ["_ㅁ_hu_(ㅌ)_3.svg", "_(ㅁ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  묭: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  묠: ["_ㅁ_hu_(ㄹ)_3.svg", "_(ㅁ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  묳: ["_ㅁ_hu_(ㅎ)_3.svg", "_(ㅁ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  묯: ["_ㅁ_hu_(ㅊ)_3.svg", "_(ㅁ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  묮: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  묩: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  묙: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  묜: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  묲: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  묰: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  묨: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  묟: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  묫: ["_ㅁ_hu_c_3.svg", "_(ㅁ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  묱: ["_ㅁ_hu_(ㅌ)_3.svg", "_(ㅁ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  명: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  멸: ["_ㅁ_(ㅕ)_(ㄹ)_3.svg", "_(ㅁ)_ㅕ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  몋: ["_ㅁ_(ㅕ)_(ㅎ)_3.svg", "_(ㅁ)_ㅕ_(ㅎ)_3.svg", "_c_v_ㅎ_3.svg"],
  몇: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  몆: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  몁: ["_ㅁ_(ㅕ)_(ㅂ)_3.svg", "_(ㅁ)_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  멱: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  면: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  몊: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  몈: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  몀: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  멷: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  몃: ["_ㅁ_(ㅕ)_c_3.svg", "_(ㅁ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  몉: ["_ㅁ_(ㅕ)_(ㅌ)_3.svg", "_(ㅁ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  덩: ["_ㄷ_v_c_3.svg", "_c_ㅓ_(ㅇ)_3.svg", "_c_v_ㅇ_3.svg"],
  덜: ["_ㄷ_v_c_3.svg", "_(ㄷ)_ㅓ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  덯: ["_ㄷ_v_c_3.svg", "_(ㄷ)_ㅓ_(ㅎ)_3.svg", "_c_v_ㅎ_3.svg"],
  덫: ["_ㄷ_v_c_3.svg", "_(ㄷ)_ㅓ_(ㅊ)_3.svg", "_c_v_ㅊ_3.svg"],
  덪: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  덥: ["_ㄷ_v_c_3.svg", "_(ㄷ)_ㅓ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  덕: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  던: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  덮: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  덬: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  덤: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  덛: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  덧: ["_ㄷ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  덭: ["_ㄷ_v_c_3.svg", "_(ㄷ)_ㅓ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  딩: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  딜: ["_ㄷ_v_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  딯: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  딫: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  딪: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  딥: ["_ㄷ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  딕: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  딘: ["_ㄷ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  딮: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  딬: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  딤: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  딛: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  딧: ["_ㄷ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  딭: ["_ㄷ_v_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  당: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  달: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  닿: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  닻: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  닺: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  답: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  닥: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  단: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  닾: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  닼: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  담: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  닫: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  닷: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  닽: ["_ㄷ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  댱: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  댤: ["_ㄷ_v_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  댷: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  댳: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  댲: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  댭: ["_ㄷ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  댝: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  댠: ["_ㄷ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  댶: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  댴: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  댬: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  댣: ["_ㄷ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  댯: ["_ㄷ_v_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  댵: ["_ㄷ_v_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  등: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  들: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  듷: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  듳: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  듲: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  듭: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  득: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  든: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  듶: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  듴: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  듬: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  듣: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  듯: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  듵: ["_ㄷ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  둥: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  둘: ["_ㄷ_(ㅜ)_c_3.svg", "_(ㄷ)_ㅜ_(ㄹ)_3.svg", "_(ㄷ)_(ㅜ)_ㄹ_3.svg"],
  둫: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  둧: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  둦: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  둡: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  둑: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  둔: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  둪: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  둨: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  둠: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  둗: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  둣: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㄷ)_(ㅜ)_ㅅ_3.svg"],
  둩: ["_ㄷ_(ㅜ)_c_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  듕: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  듈: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  듛: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  듗: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  듖: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  듑: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  듁: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  듄: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  듚: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  듘: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  듐: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  듇: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  듓: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  듙: ["_ㄷ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  동: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  돌: ["_ㄷ_(ㅗ)_(ㄹ)_3.svg", "_(ㄷ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  돟: ["_ㄷ_(ㅗ)_(ㅎ)_3.svg", "_(ㄷ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  돛: ["_ㄷ_(ㅗ)_(ㅊ)_3.svg", "_(ㄷ)_ㅗ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  돚: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  돕: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  독: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  돈: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  돞: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  돜: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  돔: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  돋: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  돗: ["_ㄷ_(ㅗ)_c_3.svg", "_(ㄷ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  돝: ["_ㄷ_(ㅗ)_(ㅌ)_3.svg", "_(ㄷ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  둉: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  됼: ["_ㄷ_(ㅛ)_(ㄹ)_3.svg", "_(ㄷ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  둏: ["_ㄷ_(ㅛ)_(ㅎ)_3.svg", "_(ㄷ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  둋: ["_ㄷ_(ㅛ)_(ㅊ)_3.svg", "_(ㄷ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  둊: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  둅: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  됵: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  됸: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  둎: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  둌: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  둄: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  됻: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  둇: ["_ㄷ_(ㅛ)_c_3.svg", "_(ㄷ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  둍: ["_ㄷ_(ㅛ)_(ㅌ)_3.svg", "_(ㄷ)_ㅛ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  뎡: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  뎔: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㄹ_3.svg"],
  뎧: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅎ_3.svg"],
  뎣: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅊ_3.svg"],
  뎢: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  뎝: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅂ_3.svg"],
  뎍: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  뎐: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㄴ_3.svg"],
  뎦: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  뎤: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  뎜: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  뎓: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  뎟: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  뎥: ["_ㄷ_(ㅕ)_c_3.svg", "_(ㄷ)_ㅕ_c_3.svg", "_c_v_ㅌ_3.svg"],
  성: ["_ㅅ_v_c_3.svg", "_c_ㅓ_(ㅇ)_3.svg", "_c_v_ㅇ_3.svg"],
  설: ["_ㅅ_v_(ㄹ)_3.svg", "_(ㅅ)_ㅓ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  섷: ["_ㅅ_v_(ㅎ)_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅎ_3.svg"],
  섳: ["_ㅅ_v_(ㅊ)_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅊ_3.svg"],
  섲: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  섭: ["_ㅅ_v_c_3.svg", "_(ㅅ)_ㅓ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  석: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  선: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  섶: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  섴: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  섬: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  섣: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  섯: ["_ㅅ_v_c_3.svg", "_c_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  섵: ["_ㅅ_v_(ㅌ)_3.svg", "_(ㅅ)_ㅓ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  싱: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  실: ["_ㅅ_v_(ㄹ)_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  싷: ["_ㅅ_v_(ㅎ)_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅎ_3.svg"],
  싳: ["_ㅅ_v_(ㅊ)_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅊ_3.svg"],
  싲: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  십: ["_ㅅ_v_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  식: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  신: ["_ㅅ_v_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  싶: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  싴: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  심: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  싣: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  싯: ["_ㅅ_v_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  싵: ["_ㅅ_v_(ㅌ)_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  상: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  살: ["_ㅅ_v_(ㄹ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  샇: ["_ㅅ_v_(ㅎ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅎ_3.svg"],
  샃: ["_ㅅ_v_(ㅊ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅊ_3.svg"],
  샂: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  삽: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  삭: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  산: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  샆: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  샄: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  삼: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  삳: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  삿: ["_ㅅ_v_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  샅: ["_ㅅ_v_(ㅌ)_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  샹: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  샬: ["_ㅅ_v_(ㄹ)_3.svg", "_(ㅅ)_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  샿: ["_ㅅ_v_(ㅎ)_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅎ_3.svg"],
  샻: ["_ㅅ_v_(ㅊ)_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅊ_3.svg"],
  샺: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  샵: ["_ㅅ_v_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  샥: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  샨: ["_ㅅ_v_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  샾: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  샼: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  샴: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  샫: ["_ㅅ_v_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  샷: ["_ㅅ_(ㅑ)_(ㅅ)_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  샽: ["_ㅅ_v_(ㅌ)_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  승: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  슬: ["_ㅅ_hu_(ㄹ)_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  슿: ["_ㅅ_hu_(ㅎ)_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  슻: ["_ㅅ_hu_(ㅊ)_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  슺: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  습: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  슥: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  슨: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  슾: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  슼: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  슴: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  슫: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  슷: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  슽: ["_ㅅ_hu_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  숭: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  술: ["_ㅅ_hd_c_3.svg", "_(ㅅ)_ㅜ_(ㄹ)_3.svg", "_(ㅅ)_hd_ㄹ_3.svg"],
  숳: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  숯: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  숮: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅈ)_3.svg", "_c_hd_ㅈ_3.svg"],
  숩: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  숙: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  순: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  숲: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  숰: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  숨: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  숟: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  숫: ["_ㅅ_hd_c_3.svg", "_c_ㅜ_(ㅅ)_3.svg", "_(ㅅ)_(ㅜ)_ㅅ_3.svg"],
  숱: ["_ㅅ_hd_c_3.svg", "_(ㅅ)_ㅜ_(ㅌ)_3.svg", "_(ㅅ)_hd_ㅌ_3.svg"],
  슝: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  슐: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_(ㅅ)_hd_ㄹ_3.svg"],
  슣: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  슟: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  슞: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_c_(ㅠ)_ㅈ_3.svg"],
  슙: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  슉: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  슌: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  슢: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  슠: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  슘: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  슏: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  슛: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  슡: ["_ㅅ_hd_c_3.svg", "_c_ㅠ_c_3.svg", "_(ㅅ)_hd_ㅌ_3.svg"],
  송: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  솔: ["_ㅅ_(ㅗ)_(ㄹ)_3.svg", "_(ㅅ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  솧: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  솣: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_(ㅊ)_3.svg", "_(ㅅ)_(ㅗ)_ㅊ_3.svg"],
  솢: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  솝: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  속: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  손: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  솦: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  솤: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  솜: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  솓: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  솟: ["_ㅅ_(ㅗ)_c_3.svg", "_(ㅅ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  솥: ["_ㅅ_(ㅗ)_(ㅌ)_3.svg", "_(ㅅ)_ㅗ_(ㅌ)_3.svg", "_c_hu_ㅌ_3.svg"],
  숑: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  숄: ["_ㅅ_hu_(ㄹ)_3.svg", "_(ㅅ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  숗: ["_ㅅ_hu_(ㅎ)_3.svg", "_(ㅅ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  숓: ["_ㅅ_hu_(ㅊ)_3.svg", "_(ㅅ)_ㅛ_(ㅊ)_3.svg", "_c_hu_ㅊ_3.svg"],
  숒: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  숍: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  쇽: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  숀: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  숖: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  숔: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  숌: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  숃: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  숏: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  숕: ["_ㅅ_hu_c_3.svg", "_(ㅅ)_ㅛ_c_3.svg", "_c_hu_ㅌ_3.svg"],
  셩: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  셜: ["_ㅅ_(ㅕ)_(ㄹ)_3.svg", "_(ㅅ)_ㅕ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  셯: ["_ㅅ_(ㅕ)_(ㅎ)_3.svg", "_(ㅅ)_ㅕ_(ㅎ)_3.svg", "_c_v_ㅎ_3.svg"],
  셫: ["_ㅅ_v_(ㅊ)_3.svg", "_(ㅅ)_ㅕ_(ㅊ)_3.svg", "_c_v_ㅊ_3.svg"],
  셪: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  셥: ["_ㅅ_v_c_3.svg", "_c_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  셕: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  션: ["_ㅅ_(ㅕ)_(ㄴ)_3.svg", "_c_ㅕ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  셮: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  셬: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  셤: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  셛: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  셧: ["_ㅅ_v_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  셭: ["_ㅅ_(ㅕ)_(ㅌ)_3.svg", "_(ㅅ)_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  텅: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㅇ_3.svg"],
  털: ["_ㅌ_(ㅓ)_(ㄹ)_3.svg", "_(ㅌ)_ㅓ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  텋: ["_ㅌ_(ㅓ)_(ㅎ)_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_(ㅌ)_v_ㅎ_3.svg"],
  텇: ["_ㅌ_(ㅓ)_(ㅊ)_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_(ㅌ)_v_ㅊ_3.svg"],
  텆: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㅈ_3.svg"],
  텁: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  턱: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㄱ_3.svg"],
  턴: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㄴ_3.svg"],
  텊: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㅍ_3.svg"],
  텈: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㅋ_3.svg"],
  텀: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㅁ_3.svg"],
  턷: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㄷ_3.svg"],
  텃: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_c_3.svg", "_c_v_ㅅ_3.svg"],
  텉: ["_ㅌ_(ㅓ)_c_3.svg", "_(ㅌ)_ㅓ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  팅: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅇ_3.svg"],
  틸: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  팋: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_(ㅌ)_v_ㅎ_3.svg"],
  팇: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_(ㅌ)_v_ㅊ_3.svg"],
  팆: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅈ_3.svg"],
  팁: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  틱: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄱ_3.svg"],
  틴: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  팊: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅍ_3.svg"],
  팈: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅋ_3.svg"],
  팀: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅁ_3.svg"],
  틷: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㄷ_3.svg"],
  팃: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_c_3.svg", "_c_v_ㅅ_3.svg"],
  팉: ["_ㅌ_(ㅣ)_c_3.svg", "_c_ㅣ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  탕: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅇ_3.svg"],
  탈: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄹ_3.svg"],
  탛: ["_ㅌ_(ㅏ)_(ㅎ)_3.svg", "_c_ㅏ_c_3.svg", "_(ㅌ)_v_ㅎ_3.svg"],
  탗: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_(ㅌ)_v_ㅊ_3.svg"],
  탖: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅈ_3.svg"],
  탑: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅂ_3.svg"],
  탁: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄱ_3.svg"],
  탄: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄴ_3.svg"],
  탚: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅍ_3.svg"],
  탘: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅋ_3.svg"],
  탐: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅁ_3.svg"],
  탇: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㄷ_3.svg"],
  탓: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅅ_3.svg"],
  탙: ["_ㅌ_(ㅏ)_c_3.svg", "_c_ㅏ_c_3.svg", "_c_v_ㅌ_3.svg"],
  턍: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅇ_3.svg"],
  턀: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㄹ)_3.svg", "_c_v_ㄹ_3.svg"],
  턓: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_(ㅌ)_v_ㅎ_3.svg"],
  턏: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_(ㅌ)_v_ㅊ_3.svg"],
  턎: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅈ_3.svg"],
  턉: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  탹: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄱ_3.svg"],
  탼: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  턒: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅍ_3.svg"],
  턐: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅋ_3.svg"],
  턈: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㅁ_3.svg"],
  탿: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_c_3.svg", "_c_v_ㄷ_3.svg"],
  턋: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㅅ)_3.svg", "_c_v_ㅅ_3.svg"],
  턑: ["_ㅌ_(ㅑ)_c_3.svg", "_c_ㅑ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  틍: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  틀: ["_ㅌ_(ㅡ)_(ㄹ)_3.svg", "_c_ㅡ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  틓: ["_ㅌ_(ㅡ)_(ㅎ)_3.svg", "_c_ㅡ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  틏: ["_ㅌ_(ㅡ)_(ㅊ)_3.svg", "_c_ㅡ_(ㅊ)_3.svg", "_(ㅌ)_hu_ㅊ_3.svg"],
  틎: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  틉: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  특: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  튼: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  틒: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  틐: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  틈: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  튿: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  틋: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  틑: ["_ㅌ_(ㅡ)_c_3.svg", "_c_ㅡ_c_3.svg", "_(ㅌ)_hu_ㅌ_3.svg"],
  퉁: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㅇ)_3.svg", "_c_(ㅜ)_ㅇ_3.svg"],
  툴: ["_ㅌ_(ㅜ)_(ㄹ)_3.svg", "_(ㅌ)_ㅜ_(ㄹ)_3.svg", "_c_(ㅜ)_ㄹ_3.svg"],
  퉇: ["_ㅌ_(ㅜ)_(ㅎ)_3.svg", "_c_ㅜ_(ㅎ)_3.svg", "_c_hd_ㅎ_3.svg"],
  퉃: ["_ㅌ_hd_(ㅊ)_3.svg", "_c_ㅜ_(ㅊ)_3.svg", "_c_(ㅜ)_ㅊ_3.svg"],
  퉂: ["_ㅌ_hd_(ㅈ)_3.svg", "_(ㅌ)_ㅜ_(ㅈ)_3.svg", "_(ㅌ)_hd_ㅈ_3.svg"],
  툽: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_c_3.svg", "_c_hd_ㅂ_3.svg"],
  툭: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㄱ)_3.svg", "_c_(ㅜ)_ㄱ_3.svg"],
  툰: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  퉆: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㅍ)_3.svg", "_c_hd_ㅍ_3.svg"],
  퉄: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㅋ)_3.svg", "_c_hd_ㅋ_3.svg"],
  툼: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㅁ)_3.svg", "_c_hd_ㅁ_3.svg"],
  툳: ["_ㅌ_hd_c_3.svg", "_c_ㅜ_(ㄷ)_3.svg", "_c_hd_ㄷ_3.svg"],
  툿: ["_ㅌ_hd_c_3.svg", "_(ㅌ)_ㅜ_(ㅅ)_3.svg", "_(ㅌ)_(ㅜ)_ㅅ_3.svg"],
  퉅: ["_ㅌ_(ㅜ)_(ㅌ)_3.svg", "_c_ㅜ_(ㅌ)_3.svg", "_c_hd_ㅌ_3.svg"],
  튱: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_(ㅇ)_3.svg", "_c_(ㅠ)_ㅇ_3.svg"],
  튤: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_(ㄹ)_3.svg", "_c_(ㅠ)_ㄹ_3.svg"],
  튷: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_(ㅎ)_3.svg", "_c_(ㅠ)_ㅎ_3.svg"],
  튳: ["_ㅌ_hd_(ㅊ)_3.svg", "_c_ㅠ_c_3.svg", "_c_(ㅠ)_ㅊ_3.svg"],
  튲: ["_ㅌ_hd_(ㅈ)_3.svg", "_c_ㅠ_(ㅈ)_3.svg", "_(ㅌ)_hd_ㅈ_3.svg"],
  튭: ["_ㅌ_(ㅠ)_c_3.svg", "_(ㅌ)_ㅠ_(ㅂ)_3.svg", "_c_hd_ㅂ_3.svg"],
  튝: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_(ㄱ)_3.svg", "_c_(ㅠ)_ㄱ_3.svg"],
  튠: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_(ㄴ)_3.svg", "_c_hd_ㄴ_3.svg"],
  튶: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅍ_3.svg"],
  튴: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅋ_3.svg"],
  튬: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_(ㅁ)_3.svg", "_c_(ㅠ)_ㅁ_3.svg"],
  튣: ["_ㅌ_(ㅠ)_c_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㄷ_3.svg"],
  튯: ["_ㅌ_(ㅠ)_c_3.svg", "_(ㅌ)_ㅠ_(ㅅ)_3.svg", "_c_(ㅠ)_ㅅ_3.svg"],
  튵: ["_ㅌ_hd_(ㅌ)_3.svg", "_c_ㅠ_c_3.svg", "_c_hd_ㅌ_3.svg"],
  통: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  톨: ["_ㅌ_(ㅗ)_(ㄹ)_3.svg", "_(ㅌ)_ㅗ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  톻: ["_ㅌ_(ㅗ)_(ㅎ)_3.svg", "_(ㅌ)_ㅗ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  톷: ["_ㅌ_(ㅗ)_(ㅊ)_3.svg", "_(ㅌ)_ㅗ_(ㅊ)_3.svg", "_(ㅌ)_hu_ㅊ_3.svg"],
  톶: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  톱: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  톡: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  톤: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  톺: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  톸: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  톰: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  톧: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  톳: ["_ㅌ_(ㅗ)_c_3.svg", "_(ㅌ)_ㅗ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  톹: ["_ㅌ_(ㅗ)_(ㅌ)_3.svg", "_(ㅌ)_ㅗ_(ㅌ)_3.svg", "_(ㅌ)_hu_ㅌ_3.svg"],
  툥: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅇ_3.svg"],
  툘: ["_ㅌ_(ㅛ)_(ㄹ)_3.svg", "_(ㅌ)_ㅛ_(ㄹ)_3.svg", "_c_hu_ㄹ_3.svg"],
  툫: ["_ㅌ_(ㅛ)_(ㅎ)_3.svg", "_(ㅌ)_ㅛ_(ㅎ)_3.svg", "_c_hu_ㅎ_3.svg"],
  툧: ["_ㅌ_(ㅛ)_(ㅊ)_3.svg", "_(ㅌ)_ㅛ_(ㅊ)_3.svg", "_(ㅌ)_hu_ㅊ_3.svg"],
  툦: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅈ_3.svg"],
  툡: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅂ_3.svg"],
  툑: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㄱ_3.svg"],
  툔: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㄴ_3.svg"],
  툪: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅍ_3.svg"],
  툨: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅋ_3.svg"],
  툠: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅁ_3.svg"],
  툗: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㄷ_3.svg"],
  툣: ["_ㅌ_(ㅛ)_c_3.svg", "_(ㅌ)_ㅛ_c_3.svg", "_c_hu_ㅅ_3.svg"],
  툩: ["_ㅌ_(ㅛ)_(ㅌ)_3.svg", "_(ㅌ)_ㅛ_(ㅌ)_3.svg", "_(ㅌ)_hu_ㅌ_3.svg"],
  텽: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅇ_3.svg"],
  텰: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄹ_3.svg"],
  톃: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_(ㅌ)_v_ㅎ_3.svg"],
  텿: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_(ㅌ)_v_ㅊ_3.svg"],
  텾: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅈ_3.svg"],
  텹: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_(ㅂ)_3.svg", "_c_v_ㅂ_3.svg"],
  텩: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄱ_3.svg"],
  텬: ["_ㅌ_(ㅕ)_(ㄴ)_3.svg", "_(ㅌ)_ㅕ_(ㄴ)_3.svg", "_c_v_ㄴ_3.svg"],
  톂: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅍ_3.svg"],
  톀: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅋ_3.svg"],
  텸: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅁ_3.svg"],
  텯: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㄷ_3.svg"],
  텻: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_c_3.svg", "_c_v_ㅅ_3.svg"],
  톁: ["_ㅌ_(ㅕ)_c_3.svg", "_c_ㅕ_(ㅌ)_3.svg", "_c_v_ㅌ_3.svg"],
  엃: [undefined, undefined, undefined, undefined],
  얿: [undefined, undefined, undefined, undefined],
  얽: [undefined, undefined, undefined, undefined],
  엂: [undefined, undefined, undefined, undefined],
  얾: [undefined, undefined, undefined, undefined],
  엀: [undefined, undefined, undefined, undefined],
  엁: [undefined, undefined, undefined, undefined],
  없: [undefined, undefined, undefined, undefined],
  얷: [undefined, undefined, undefined, undefined],
  얺: [undefined, undefined, undefined, undefined],
  얹: [undefined, undefined, undefined, undefined],
  잃: [undefined, undefined, undefined, undefined],
  읿: [undefined, undefined, undefined, undefined],
  읽: [undefined, undefined, undefined, undefined],
  잂: [undefined, undefined, undefined, undefined],
  읾: [undefined, undefined, undefined, undefined],
  잀: [undefined, undefined, undefined, undefined],
  잁: [undefined, undefined, undefined, undefined],
  잆: [undefined, undefined, undefined, undefined],
  읷: [undefined, undefined, undefined, undefined],
  읺: [undefined, undefined, undefined, undefined],
  읹: [undefined, undefined, undefined, undefined],
  앓: [undefined, undefined, undefined, undefined],
  앏: [undefined, undefined, undefined, undefined],
  앍: [undefined, undefined, undefined, undefined],
  앒: [undefined, undefined, undefined, undefined],
  앎: [undefined, undefined, undefined, undefined],
  앐: [undefined, undefined, undefined, undefined],
  앑: [undefined, undefined, undefined, undefined],
  앖: [undefined, undefined, undefined, undefined],
  앇: [undefined, undefined, undefined, undefined],
  않: [undefined, undefined, undefined, undefined],
  앉: [undefined, undefined, undefined, undefined],
  얋: [undefined, undefined, undefined, undefined],
  얇: [undefined, undefined, undefined, undefined],
  얅: [undefined, undefined, undefined, undefined],
  얊: [undefined, undefined, undefined, undefined],
  얆: [undefined, undefined, undefined, undefined],
  얈: [undefined, undefined, undefined, undefined],
  얉: [undefined, undefined, undefined, undefined],
  얎: [undefined, undefined, undefined, undefined],
  앿: [undefined, undefined, undefined, undefined],
  얂: [undefined, undefined, undefined, undefined],
  얁: [undefined, undefined, undefined, undefined],
  읋: [undefined, undefined, undefined, undefined],
  읇: [undefined, undefined, undefined, undefined],
  읅: [undefined, undefined, undefined, undefined],
  읊: [undefined, undefined, undefined, undefined],
  읆: [undefined, undefined, undefined, undefined],
  읈: [undefined, undefined, undefined, undefined],
  읉: [undefined, undefined, undefined, undefined],
  읎: [undefined, undefined, undefined, undefined],
  윿: [undefined, undefined, undefined, undefined],
  읂: [undefined, undefined, undefined, undefined],
  읁: [undefined, undefined, undefined, undefined],
  욿: [undefined, undefined, undefined, undefined],
  욻: [undefined, undefined, undefined, undefined],
  욹: [undefined, undefined, undefined, undefined],
  욾: [undefined, undefined, undefined, undefined],
  욺: [undefined, undefined, undefined, undefined],
  욼: [undefined, undefined, undefined, undefined],
  욽: [undefined, undefined, undefined, undefined],
  웂: [undefined, undefined, undefined, undefined],
  욳: [undefined, undefined, undefined, undefined],
  욶: [undefined, undefined, undefined, undefined],
  욵: [undefined, undefined, undefined, undefined],
  윯: [undefined, undefined, undefined, undefined],
  윫: [undefined, undefined, undefined, undefined],
  윩: [undefined, undefined, undefined, undefined],
  윮: [undefined, undefined, undefined, undefined],
  윪: [undefined, undefined, undefined, undefined],
  윬: [undefined, undefined, undefined, undefined],
  윭: [undefined, undefined, undefined, undefined],
  윲: [undefined, undefined, undefined, undefined],
  윣: [undefined, undefined, undefined, undefined],
  윦: [undefined, undefined, undefined, undefined],
  윥: [undefined, undefined, undefined, undefined],
  옳: [undefined, undefined, undefined, undefined],
  옯: [undefined, undefined, undefined, undefined],
  옭: [undefined, undefined, undefined, undefined],
  옲: [undefined, undefined, undefined, undefined],
  옮: [undefined, undefined, undefined, undefined],
  옰: [undefined, undefined, undefined, undefined],
  옱: [undefined, undefined, undefined, undefined],
  옶: [undefined, undefined, undefined, undefined],
  옧: [undefined, undefined, undefined, undefined],
  옪: [undefined, undefined, undefined, undefined],
  옩: [undefined, undefined, undefined, undefined],
  욣: [undefined, undefined, undefined, undefined],
  욟: [undefined, undefined, undefined, undefined],
  욝: [undefined, undefined, undefined, undefined],
  욢: [undefined, undefined, undefined, undefined],
  욞: [undefined, undefined, undefined, undefined],
  욠: [undefined, undefined, undefined, undefined],
  욡: [undefined, undefined, undefined, undefined],
  욦: [undefined, undefined, undefined, undefined],
  욗: [undefined, undefined, undefined, undefined],
  욚: [undefined, undefined, undefined, undefined],
  욙: [undefined, undefined, undefined, undefined],
  엻: [undefined, undefined, undefined, undefined],
  엷: [undefined, undefined, undefined, undefined],
  엵: [undefined, undefined, undefined, undefined],
  엺: [undefined, undefined, undefined, undefined],
  엶: [undefined, undefined, undefined, undefined],
  엸: [undefined, undefined, undefined, undefined],
  엹: [undefined, undefined, undefined, undefined],
  엾: [undefined, undefined, undefined, undefined],
  엯: [undefined, undefined, undefined, undefined],
  엲: [undefined, undefined, undefined, undefined],
  엱: [undefined, undefined, undefined, undefined],
  럻: [undefined, undefined, undefined, undefined],
  럷: [undefined, undefined, undefined, undefined],
  럵: [undefined, undefined, undefined, undefined],
  럺: [undefined, undefined, undefined, undefined],
  럶: [undefined, undefined, undefined, undefined],
  럸: [undefined, undefined, undefined, undefined],
  럹: [undefined, undefined, undefined, undefined],
  럾: [undefined, undefined, undefined, undefined],
  럯: [undefined, undefined, undefined, undefined],
  럲: [undefined, undefined, undefined, undefined],
  럱: [undefined, undefined, undefined, undefined],
  릻: [undefined, undefined, undefined, undefined],
  릷: [undefined, undefined, undefined, undefined],
  릵: [undefined, undefined, undefined, undefined],
  릺: [undefined, undefined, undefined, undefined],
  릶: [undefined, undefined, undefined, undefined],
  릸: [undefined, undefined, undefined, undefined],
  릹: [undefined, undefined, undefined, undefined],
  릾: [undefined, undefined, undefined, undefined],
  릯: [undefined, undefined, undefined, undefined],
  릲: [undefined, undefined, undefined, undefined],
  릱: [undefined, undefined, undefined, undefined],
  랋: [undefined, undefined, undefined, undefined],
  랇: [undefined, undefined, undefined, undefined],
  랅: [undefined, undefined, undefined, undefined],
  랊: [undefined, undefined, undefined, undefined],
  랆: [undefined, undefined, undefined, undefined],
  랈: [undefined, undefined, undefined, undefined],
  랉: [undefined, undefined, undefined, undefined],
  랎: [undefined, undefined, undefined, undefined],
  띿: [undefined, undefined, undefined, undefined],
  랂: [undefined, undefined, undefined, undefined],
  랁: [undefined, undefined, undefined, undefined],
  럃: [undefined, undefined, undefined, undefined],
  랿: [undefined, undefined, undefined, undefined],
  랽: [undefined, undefined, undefined, undefined],
  럂: [undefined, undefined, undefined, undefined],
  랾: [undefined, undefined, undefined, undefined],
  럀: [undefined, undefined, undefined, undefined],
  럁: [undefined, undefined, undefined, undefined],
  럆: [undefined, undefined, undefined, undefined],
  랷: [undefined, undefined, undefined, undefined],
  랺: [undefined, undefined, undefined, undefined],
  랹: [undefined, undefined, undefined, undefined],
  릃: [undefined, undefined, undefined, undefined],
  륿: [undefined, undefined, undefined, undefined],
  륽: [undefined, undefined, undefined, undefined],
  릂: [undefined, undefined, undefined, undefined],
  륾: [undefined, undefined, undefined, undefined],
  릀: [undefined, undefined, undefined, undefined],
  릁: [undefined, undefined, undefined, undefined],
  릆: [undefined, undefined, undefined, undefined],
  륷: [undefined, undefined, undefined, undefined],
  륺: [undefined, undefined, undefined, undefined],
  륹: [undefined, undefined, undefined, undefined],
  룷: [undefined, undefined, undefined, undefined],
  룳: [undefined, undefined, undefined, undefined],
  룱: [undefined, undefined, undefined, undefined],
  룶: [undefined, undefined, undefined, undefined],
  룲: [undefined, undefined, undefined, undefined],
  룴: [undefined, undefined, undefined, undefined],
  룵: [undefined, undefined, undefined, undefined],
  룺: [undefined, undefined, undefined, undefined],
  룫: [undefined, undefined, undefined, undefined],
  룮: [undefined, undefined, undefined, undefined],
  룭: [undefined, undefined, undefined, undefined],
  륧: [undefined, undefined, undefined, undefined],
  륣: [undefined, undefined, undefined, undefined],
  륡: [undefined, undefined, undefined, undefined],
  륦: [undefined, undefined, undefined, undefined],
  륢: [undefined, undefined, undefined, undefined],
  륤: [undefined, undefined, undefined, undefined],
  륥: [undefined, undefined, undefined, undefined],
  륪: [undefined, undefined, undefined, undefined],
  륛: [undefined, undefined, undefined, undefined],
  륞: [undefined, undefined, undefined, undefined],
  륝: [undefined, undefined, undefined, undefined],
  롫: [undefined, undefined, undefined, undefined],
  롧: [undefined, undefined, undefined, undefined],
  롥: [undefined, undefined, undefined, undefined],
  롪: [undefined, undefined, undefined, undefined],
  롦: [undefined, undefined, undefined, undefined],
  롨: [undefined, undefined, undefined, undefined],
  롩: [undefined, undefined, undefined, undefined],
  롮: [undefined, undefined, undefined, undefined],
  롟: [undefined, undefined, undefined, undefined],
  롢: [undefined, undefined, undefined, undefined],
  롡: [undefined, undefined, undefined, undefined],
  룛: [undefined, undefined, undefined, undefined],
  룗: [undefined, undefined, undefined, undefined],
  룕: [undefined, undefined, undefined, undefined],
  룚: [undefined, undefined, undefined, undefined],
  룖: [undefined, undefined, undefined, undefined],
  룘: [undefined, undefined, undefined, undefined],
  룙: [undefined, undefined, undefined, undefined],
  룞: [undefined, undefined, undefined, undefined],
  룏: [undefined, undefined, undefined, undefined],
  룒: [undefined, undefined, undefined, undefined],
  룑: [undefined, undefined, undefined, undefined],
  렳: [undefined, undefined, undefined, undefined],
  렯: [undefined, undefined, undefined, undefined],
  렭: [undefined, undefined, undefined, undefined],
  렲: [undefined, undefined, undefined, undefined],
  렮: [undefined, undefined, undefined, undefined],
  렰: [undefined, undefined, undefined, undefined],
  렱: [undefined, undefined, undefined, undefined],
  렶: [undefined, undefined, undefined, undefined],
  렧: [undefined, undefined, undefined, undefined],
  렪: [undefined, undefined, undefined, undefined],
  렩: [undefined, undefined, undefined, undefined],
  헗: [undefined, undefined, undefined, undefined],
  헓: [undefined, undefined, undefined, undefined],
  헑: [undefined, undefined, undefined, undefined],
  헖: [undefined, undefined, undefined, undefined],
  헒: [undefined, undefined, undefined, undefined],
  헔: [undefined, undefined, undefined, undefined],
  헕: [undefined, undefined, undefined, undefined],
  헚: [undefined, undefined, undefined, undefined],
  헋: [undefined, undefined, undefined, undefined],
  헎: [undefined, undefined, undefined, undefined],
  헍: [undefined, undefined, undefined, undefined],
  힗: [undefined, undefined, undefined, undefined],
  힓: [undefined, undefined, undefined, undefined],
  힑: [undefined, undefined, undefined, undefined],
  힖: [undefined, undefined, undefined, undefined],
  힒: [undefined, undefined, undefined, undefined],
  힔: [undefined, undefined, undefined, undefined],
  힕: [undefined, undefined, undefined, undefined],
  힚: [undefined, undefined, undefined, undefined],
  힋: [undefined, undefined, undefined, undefined],
  힎: [undefined, undefined, undefined, undefined],
  힍: [undefined, undefined, undefined, undefined],
  핧: [undefined, undefined, undefined, undefined],
  핣: [undefined, undefined, undefined, undefined],
  핡: [undefined, undefined, undefined, undefined],
  핦: [undefined, undefined, undefined, undefined],
  핢: [undefined, undefined, undefined, undefined],
  핤: [undefined, undefined, undefined, undefined],
  핥: [undefined, undefined, undefined, undefined],
  핪: [undefined, undefined, undefined, undefined],
  핛: [undefined, undefined, undefined, undefined],
  핞: [undefined, undefined, undefined, undefined],
  핝: [undefined, undefined, undefined, undefined],
  햟: [undefined, undefined, undefined, undefined],
  햛: [undefined, undefined, undefined, undefined],
  햙: [undefined, undefined, undefined, undefined],
  햞: [undefined, undefined, undefined, undefined],
  햚: [undefined, undefined, undefined, undefined],
  햜: [undefined, undefined, undefined, undefined],
  햝: [undefined, undefined, undefined, undefined],
  햢: [undefined, undefined, undefined, undefined],
  햓: [undefined, undefined, undefined, undefined],
  햖: [undefined, undefined, undefined, undefined],
  햕: [undefined, undefined, undefined, undefined],
  흟: [undefined, undefined, undefined, undefined],
  흛: [undefined, undefined, undefined, undefined],
  흙: [undefined, undefined, undefined, undefined],
  흞: [undefined, undefined, undefined, undefined],
  흚: [undefined, undefined, undefined, undefined],
  흜: [undefined, undefined, undefined, undefined],
  흝: [undefined, undefined, undefined, undefined],
  흢: [undefined, undefined, undefined, undefined],
  흓: [undefined, undefined, undefined, undefined],
  흖: [undefined, undefined, undefined, undefined],
  흕: [undefined, undefined, undefined, undefined],
  훓: [undefined, undefined, undefined, undefined],
  훏: [undefined, undefined, undefined, undefined],
  훍: [undefined, undefined, undefined, undefined],
  훒: [undefined, undefined, undefined, undefined],
  훎: [undefined, undefined, undefined, undefined],
  훐: [undefined, undefined, undefined, undefined],
  훑: [undefined, undefined, undefined, undefined],
  훖: [undefined, undefined, undefined, undefined],
  훇: [undefined, undefined, undefined, undefined],
  훊: [undefined, undefined, undefined, undefined],
  훉: [undefined, undefined, undefined, undefined],
  흃: [undefined, undefined, undefined, undefined],
  휿: [undefined, undefined, undefined, undefined],
  휽: [undefined, undefined, undefined, undefined],
  흂: [undefined, undefined, undefined, undefined],
  휾: [undefined, undefined, undefined, undefined],
  흀: [undefined, undefined, undefined, undefined],
  흁: [undefined, undefined, undefined, undefined],
  흆: [undefined, undefined, undefined, undefined],
  휷: [undefined, undefined, undefined, undefined],
  휺: [undefined, undefined, undefined, undefined],
  휹: [undefined, undefined, undefined, undefined],
  홇: [undefined, undefined, undefined, undefined],
  홃: [undefined, undefined, undefined, undefined],
  홁: [undefined, undefined, undefined, undefined],
  홆: [undefined, undefined, undefined, undefined],
  홂: [undefined, undefined, undefined, undefined],
  홄: [undefined, undefined, undefined, undefined],
  홅: [undefined, undefined, undefined, undefined],
  홊: [undefined, undefined, undefined, undefined],
  혻: [undefined, undefined, undefined, undefined],
  혾: [undefined, undefined, undefined, undefined],
  혽: [undefined, undefined, undefined, undefined],
  횷: [undefined, undefined, undefined, undefined],
  횳: [undefined, undefined, undefined, undefined],
  횱: [undefined, undefined, undefined, undefined],
  횶: [undefined, undefined, undefined, undefined],
  횲: [undefined, undefined, undefined, undefined],
  횴: [undefined, undefined, undefined, undefined],
  횵: [undefined, undefined, undefined, undefined],
  횺: [undefined, undefined, undefined, undefined],
  횫: [undefined, undefined, undefined, undefined],
  횮: [undefined, undefined, undefined, undefined],
  횭: [undefined, undefined, undefined, undefined],
  혏: [undefined, undefined, undefined, undefined],
  혋: [undefined, undefined, undefined, undefined],
  혉: [undefined, undefined, undefined, undefined],
  혎: [undefined, undefined, undefined, undefined],
  혊: [undefined, undefined, undefined, undefined],
  혌: [undefined, undefined, undefined, undefined],
  혍: [undefined, undefined, undefined, undefined],
  혒: [undefined, undefined, undefined, undefined],
  혃: [undefined, undefined, undefined, undefined],
  혆: [undefined, undefined, undefined, undefined],
  혅: [undefined, undefined, undefined, undefined],
  첧: [undefined, undefined, undefined, undefined],
  첣: [undefined, undefined, undefined, undefined],
  첡: [undefined, undefined, undefined, undefined],
  첦: [undefined, undefined, undefined, undefined],
  첢: [undefined, undefined, undefined, undefined],
  첤: [undefined, undefined, undefined, undefined],
  첥: [undefined, undefined, undefined, undefined],
  첪: [undefined, undefined, undefined, undefined],
  첛: [undefined, undefined, undefined, undefined],
  첞: [undefined, undefined, undefined, undefined],
  첝: [undefined, undefined, undefined, undefined],
  칧: [undefined, undefined, undefined, undefined],
  칣: [undefined, undefined, undefined, undefined],
  칡: [undefined, undefined, undefined, undefined],
  칦: [undefined, undefined, undefined, undefined],
  칢: [undefined, undefined, undefined, undefined],
  칤: [undefined, undefined, undefined, undefined],
  칥: [undefined, undefined, undefined, undefined],
  칪: [undefined, undefined, undefined, undefined],
  칛: [undefined, undefined, undefined, undefined],
  칞: [undefined, undefined, undefined, undefined],
  칝: [undefined, undefined, undefined, undefined],
  찷: [undefined, undefined, undefined, undefined],
  찳: [undefined, undefined, undefined, undefined],
  찱: [undefined, undefined, undefined, undefined],
  찶: [undefined, undefined, undefined, undefined],
  찲: [undefined, undefined, undefined, undefined],
  찴: [undefined, undefined, undefined, undefined],
  찵: [undefined, undefined, undefined, undefined],
  찺: [undefined, undefined, undefined, undefined],
  찫: [undefined, undefined, undefined, undefined],
  찮: [undefined, undefined, undefined, undefined],
  찭: [undefined, undefined, undefined, undefined],
  챯: [undefined, undefined, undefined, undefined],
  챫: [undefined, undefined, undefined, undefined],
  챩: [undefined, undefined, undefined, undefined],
  챮: [undefined, undefined, undefined, undefined],
  챪: [undefined, undefined, undefined, undefined],
  챬: [undefined, undefined, undefined, undefined],
  챭: [undefined, undefined, undefined, undefined],
  챲: [undefined, undefined, undefined, undefined],
  챣: [undefined, undefined, undefined, undefined],
  챦: [undefined, undefined, undefined, undefined],
  챥: [undefined, undefined, undefined, undefined],
  츯: [undefined, undefined, undefined, undefined],
  츫: [undefined, undefined, undefined, undefined],
  츩: [undefined, undefined, undefined, undefined],
  츮: [undefined, undefined, undefined, undefined],
  츪: [undefined, undefined, undefined, undefined],
  츬: [undefined, undefined, undefined, undefined],
  츭: [undefined, undefined, undefined, undefined],
  츲: [undefined, undefined, undefined, undefined],
  츣: [undefined, undefined, undefined, undefined],
  츦: [undefined, undefined, undefined, undefined],
  츥: [undefined, undefined, undefined, undefined],
  춣: [undefined, undefined, undefined, undefined],
  춟: [undefined, undefined, undefined, undefined],
  춝: [undefined, undefined, undefined, undefined],
  춢: [undefined, undefined, undefined, undefined],
  춞: [undefined, undefined, undefined, undefined],
  춠: [undefined, undefined, undefined, undefined],
  춡: [undefined, undefined, undefined, undefined],
  춦: [undefined, undefined, undefined, undefined],
  춗: [undefined, undefined, undefined, undefined],
  춚: [undefined, undefined, undefined, undefined],
  춙: [undefined, undefined, undefined, undefined],
  츓: [undefined, undefined, undefined, undefined],
  츏: [undefined, undefined, undefined, undefined],
  츍: [undefined, undefined, undefined, undefined],
  츒: [undefined, undefined, undefined, undefined],
  츎: [undefined, undefined, undefined, undefined],
  츐: [undefined, undefined, undefined, undefined],
  츑: [undefined, undefined, undefined, undefined],
  츖: [undefined, undefined, undefined, undefined],
  츇: [undefined, undefined, undefined, undefined],
  츊: [undefined, undefined, undefined, undefined],
  츉: [undefined, undefined, undefined, undefined],
  촗: [undefined, undefined, undefined, undefined],
  촓: [undefined, undefined, undefined, undefined],
  촑: [undefined, undefined, undefined, undefined],
  촖: [undefined, undefined, undefined, undefined],
  촒: [undefined, undefined, undefined, undefined],
  촔: [undefined, undefined, undefined, undefined],
  촕: [undefined, undefined, undefined, undefined],
  촚: [undefined, undefined, undefined, undefined],
  촋: [undefined, undefined, undefined, undefined],
  촎: [undefined, undefined, undefined, undefined],
  촍: [undefined, undefined, undefined, undefined],
  춇: [undefined, undefined, undefined, undefined],
  춃: [undefined, undefined, undefined, undefined],
  춁: [undefined, undefined, undefined, undefined],
  춆: [undefined, undefined, undefined, undefined],
  춂: [undefined, undefined, undefined, undefined],
  춄: [undefined, undefined, undefined, undefined],
  춅: [undefined, undefined, undefined, undefined],
  춊: [undefined, undefined, undefined, undefined],
  쵻: [undefined, undefined, undefined, undefined],
  쵾: [undefined, undefined, undefined, undefined],
  쵽: [undefined, undefined, undefined, undefined],
  쳟: [undefined, undefined, undefined, undefined],
  쳛: [undefined, undefined, undefined, undefined],
  쳙: [undefined, undefined, undefined, undefined],
  쳞: [undefined, undefined, undefined, undefined],
  쳚: [undefined, undefined, undefined, undefined],
  쳜: [undefined, undefined, undefined, undefined],
  쳝: [undefined, undefined, undefined, undefined],
  쳢: [undefined, undefined, undefined, undefined],
  쳓: [undefined, undefined, undefined, undefined],
  쳖: [undefined, undefined, undefined, undefined],
  쳕: [undefined, undefined, undefined, undefined],
  젏: [undefined, undefined, undefined, undefined],
  젋: [undefined, undefined, undefined, undefined],
  젉: [undefined, undefined, undefined, undefined],
  젎: [undefined, undefined, undefined, undefined],
  젊: [undefined, undefined, undefined, undefined],
  젌: [undefined, undefined, undefined, undefined],
  젍: [undefined, undefined, undefined, undefined],
  젒: [undefined, undefined, undefined, undefined],
  젃: [undefined, undefined, undefined, undefined],
  젆: [undefined, undefined, undefined, undefined],
  젅: [undefined, undefined, undefined, undefined],
  짏: [undefined, undefined, undefined, undefined],
  짋: [undefined, undefined, undefined, undefined],
  짉: [undefined, undefined, undefined, undefined],
  짎: [undefined, undefined, undefined, undefined],
  짊: [undefined, undefined, undefined, undefined],
  짌: [undefined, undefined, undefined, undefined],
  짍: [undefined, undefined, undefined, undefined],
  짒: [undefined, undefined, undefined, undefined],
  짃: [undefined, undefined, undefined, undefined],
  짆: [undefined, undefined, undefined, undefined],
  짅: [undefined, undefined, undefined, undefined],
  잟: [undefined, undefined, undefined, undefined],
  잛: [undefined, undefined, undefined, undefined],
  잙: [undefined, undefined, undefined, undefined],
  잞: [undefined, undefined, undefined, undefined],
  잚: [undefined, undefined, undefined, undefined],
  잜: [undefined, undefined, undefined, undefined],
  잝: [undefined, undefined, undefined, undefined],
  잢: [undefined, undefined, undefined, undefined],
  잓: [undefined, undefined, undefined, undefined],
  잖: [undefined, undefined, undefined, undefined],
  잕: [undefined, undefined, undefined, undefined],
  쟗: [undefined, undefined, undefined, undefined],
  쟓: [undefined, undefined, undefined, undefined],
  쟑: [undefined, undefined, undefined, undefined],
  쟖: [undefined, undefined, undefined, undefined],
  쟒: [undefined, undefined, undefined, undefined],
  쟔: [undefined, undefined, undefined, undefined],
  쟕: [undefined, undefined, undefined, undefined],
  쟚: [undefined, undefined, undefined, undefined],
  쟋: [undefined, undefined, undefined, undefined],
  쟎: [undefined, undefined, undefined, undefined],
  쟍: [undefined, undefined, undefined, undefined],
  즗: [undefined, undefined, undefined, undefined],
  즓: [undefined, undefined, undefined, undefined],
  즑: [undefined, undefined, undefined, undefined],
  즖: [undefined, undefined, undefined, undefined],
  즒: [undefined, undefined, undefined, undefined],
  즔: [undefined, undefined, undefined, undefined],
  즕: [undefined, undefined, undefined, undefined],
  즚: [undefined, undefined, undefined, undefined],
  즋: [undefined, undefined, undefined, undefined],
  즎: [undefined, undefined, undefined, undefined],
  즍: [undefined, undefined, undefined, undefined],
  줋: [undefined, undefined, undefined, undefined],
  줇: [undefined, undefined, undefined, undefined],
  줅: [undefined, undefined, undefined, undefined],
  줊: [undefined, undefined, undefined, undefined],
  줆: [undefined, undefined, undefined, undefined],
  줈: [undefined, undefined, undefined, undefined],
  줉: [undefined, undefined, undefined, undefined],
  줎: [undefined, undefined, undefined, undefined],
  죿: [undefined, undefined, undefined, undefined],
  줂: [undefined, undefined, undefined, undefined],
  줁: [undefined, undefined, undefined, undefined],
  쥻: [undefined, undefined, undefined, undefined],
  쥷: [undefined, undefined, undefined, undefined],
  쥵: [undefined, undefined, undefined, undefined],
  쥺: [undefined, undefined, undefined, undefined],
  쥶: [undefined, undefined, undefined, undefined],
  쥸: [undefined, undefined, undefined, undefined],
  쥹: [undefined, undefined, undefined, undefined],
  쥾: [undefined, undefined, undefined, undefined],
  쥯: [undefined, undefined, undefined, undefined],
  쥲: [undefined, undefined, undefined, undefined],
  쥱: [undefined, undefined, undefined, undefined],
  졿: [undefined, undefined, undefined, undefined],
  졻: [undefined, undefined, undefined, undefined],
  졹: [undefined, undefined, undefined, undefined],
  졾: [undefined, undefined, undefined, undefined],
  졺: [undefined, undefined, undefined, undefined],
  졼: [undefined, undefined, undefined, undefined],
  졽: [undefined, undefined, undefined, undefined],
  좂: [undefined, undefined, undefined, undefined],
  졳: [undefined, undefined, undefined, undefined],
  졶: [undefined, undefined, undefined, undefined],
  졵: [undefined, undefined, undefined, undefined],
  죯: [undefined, undefined, undefined, undefined],
  죫: [undefined, undefined, undefined, undefined],
  죩: [undefined, undefined, undefined, undefined],
  죮: [undefined, undefined, undefined, undefined],
  죪: [undefined, undefined, undefined, undefined],
  죬: [undefined, undefined, undefined, undefined],
  죭: [undefined, undefined, undefined, undefined],
  죲: [undefined, undefined, undefined, undefined],
  죣: [undefined, undefined, undefined, undefined],
  죦: [undefined, undefined, undefined, undefined],
  죥: [undefined, undefined, undefined, undefined],
  졇: [undefined, undefined, undefined, undefined],
  졃: [undefined, undefined, undefined, undefined],
  졁: [undefined, undefined, undefined, undefined],
  졆: [undefined, undefined, undefined, undefined],
  졂: [undefined, undefined, undefined, undefined],
  졄: [undefined, undefined, undefined, undefined],
  졅: [undefined, undefined, undefined, undefined],
  졊: [undefined, undefined, undefined, undefined],
  젻: [undefined, undefined, undefined, undefined],
  젾: [undefined, undefined, undefined, undefined],
  젽: [undefined, undefined, undefined, undefined],
  벓: [undefined, undefined, undefined, undefined],
  벏: [undefined, undefined, undefined, undefined],
  벍: [undefined, undefined, undefined, undefined],
  벒: [undefined, undefined, undefined, undefined],
  벎: [undefined, undefined, undefined, undefined],
  벐: [undefined, undefined, undefined, undefined],
  벑: [undefined, undefined, undefined, undefined],
  벖: [undefined, undefined, undefined, undefined],
  벇: [undefined, undefined, undefined, undefined],
  벊: [undefined, undefined, undefined, undefined],
  벉: [undefined, undefined, undefined, undefined],
  빓: [undefined, undefined, undefined, undefined],
  빏: [undefined, undefined, undefined, undefined],
  빍: [undefined, undefined, undefined, undefined],
  빒: [undefined, undefined, undefined, undefined],
  빎: [undefined, undefined, undefined, undefined],
  빐: [undefined, undefined, undefined, undefined],
  빑: [undefined, undefined, undefined, undefined],
  빖: [undefined, undefined, undefined, undefined],
  빇: [undefined, undefined, undefined, undefined],
  빊: [undefined, undefined, undefined, undefined],
  빉: [undefined, undefined, undefined, undefined],
  밣: [undefined, undefined, undefined, undefined],
  밟: [undefined, undefined, undefined, undefined],
  밝: [undefined, undefined, undefined, undefined],
  밢: [undefined, undefined, undefined, undefined],
  밞: [undefined, undefined, undefined, undefined],
  밠: [undefined, undefined, undefined, undefined],
  밡: [undefined, undefined, undefined, undefined],
  밦: [undefined, undefined, undefined, undefined],
  밗: [undefined, undefined, undefined, undefined],
  밚: [undefined, undefined, undefined, undefined],
  밙: [undefined, undefined, undefined, undefined],
  뱛: [undefined, undefined, undefined, undefined],
  뱗: [undefined, undefined, undefined, undefined],
  뱕: [undefined, undefined, undefined, undefined],
  뱚: [undefined, undefined, undefined, undefined],
  뱖: [undefined, undefined, undefined, undefined],
  뱘: [undefined, undefined, undefined, undefined],
  뱙: [undefined, undefined, undefined, undefined],
  뱞: [undefined, undefined, undefined, undefined],
  뱏: [undefined, undefined, undefined, undefined],
  뱒: [undefined, undefined, undefined, undefined],
  뱑: [undefined, undefined, undefined, undefined],
  븛: [undefined, undefined, undefined, undefined],
  븗: [undefined, undefined, undefined, undefined],
  븕: [undefined, undefined, undefined, undefined],
  븚: [undefined, undefined, undefined, undefined],
  븖: [undefined, undefined, undefined, undefined],
  븘: [undefined, undefined, undefined, undefined],
  븙: [undefined, undefined, undefined, undefined],
  븞: [undefined, undefined, undefined, undefined],
  븏: [undefined, undefined, undefined, undefined],
  븒: [undefined, undefined, undefined, undefined],
  븑: [undefined, undefined, undefined, undefined],
  붏: [undefined, undefined, undefined, undefined],
  붋: [undefined, undefined, undefined, undefined],
  붉: [undefined, undefined, undefined, undefined],
  붎: [undefined, undefined, undefined, undefined],
  붊: [undefined, undefined, undefined, undefined],
  붌: [undefined, undefined, undefined, undefined],
  붍: [undefined, undefined, undefined, undefined],
  붒: [undefined, undefined, undefined, undefined],
  붃: [undefined, undefined, undefined, undefined],
  붆: [undefined, undefined, undefined, undefined],
  붅: [undefined, undefined, undefined, undefined],
  뷿: [undefined, undefined, undefined, undefined],
  뷻: [undefined, undefined, undefined, undefined],
  뷹: [undefined, undefined, undefined, undefined],
  뷾: [undefined, undefined, undefined, undefined],
  뷺: [undefined, undefined, undefined, undefined],
  뷼: [undefined, undefined, undefined, undefined],
  뷽: [undefined, undefined, undefined, undefined],
  븂: [undefined, undefined, undefined, undefined],
  뷳: [undefined, undefined, undefined, undefined],
  뷶: [undefined, undefined, undefined, undefined],
  뷵: [undefined, undefined, undefined, undefined],
  봃: [undefined, undefined, undefined, undefined],
  볿: [undefined, undefined, undefined, undefined],
  볽: [undefined, undefined, undefined, undefined],
  봂: [undefined, undefined, undefined, undefined],
  볾: [undefined, undefined, undefined, undefined],
  봀: [undefined, undefined, undefined, undefined],
  봁: [undefined, undefined, undefined, undefined],
  봆: [undefined, undefined, undefined, undefined],
  볷: [undefined, undefined, undefined, undefined],
  볺: [undefined, undefined, undefined, undefined],
  볹: [undefined, undefined, undefined, undefined],
  뵳: [undefined, undefined, undefined, undefined],
  뵯: [undefined, undefined, undefined, undefined],
  뵭: [undefined, undefined, undefined, undefined],
  뵲: [undefined, undefined, undefined, undefined],
  뵮: [undefined, undefined, undefined, undefined],
  뵰: [undefined, undefined, undefined, undefined],
  뵱: [undefined, undefined, undefined, undefined],
  뵶: [undefined, undefined, undefined, undefined],
  뵧: [undefined, undefined, undefined, undefined],
  뵪: [undefined, undefined, undefined, undefined],
  뵩: [undefined, undefined, undefined, undefined],
  볋: [undefined, undefined, undefined, undefined],
  볇: [undefined, undefined, undefined, undefined],
  볅: [undefined, undefined, undefined, undefined],
  볊: [undefined, undefined, undefined, undefined],
  볆: [undefined, undefined, undefined, undefined],
  볈: [undefined, undefined, undefined, undefined],
  볉: [undefined, undefined, undefined, undefined],
  볎: [undefined, undefined, undefined, undefined],
  벿: [undefined, undefined, undefined, undefined],
  볂: [undefined, undefined, undefined, undefined],
  볁: [undefined, undefined, undefined, undefined],
  걿: [undefined, undefined, undefined, undefined],
  걻: [undefined, undefined, undefined, undefined],
  걹: [undefined, undefined, undefined, undefined],
  걾: [undefined, undefined, undefined, undefined],
  걺: [undefined, undefined, undefined, undefined],
  걼: [undefined, undefined, undefined, undefined],
  걽: [undefined, undefined, undefined, undefined],
  겂: [undefined, undefined, undefined, undefined],
  걳: [undefined, undefined, undefined, undefined],
  걶: [undefined, undefined, undefined, undefined],
  걵: [undefined, undefined, undefined, undefined],
  긿: [undefined, undefined, undefined, undefined],
  긻: [undefined, undefined, undefined, undefined],
  긹: [undefined, undefined, undefined, undefined],
  긾: [undefined, undefined, undefined, undefined],
  긺: [undefined, undefined, undefined, undefined],
  긼: [undefined, undefined, undefined, undefined],
  긽: [undefined, undefined, undefined, undefined],
  깂: [undefined, undefined, undefined, undefined],
  긳: [undefined, undefined, undefined, undefined],
  긶: [undefined, undefined, undefined, undefined],
  긵: [undefined, undefined, undefined, undefined],
  갏: [undefined, undefined, undefined, undefined],
  갋: [undefined, undefined, undefined, undefined],
  갉: [undefined, undefined, undefined, undefined],
  갎: [undefined, undefined, undefined, undefined],
  갊: [undefined, undefined, undefined, undefined],
  갌: [undefined, undefined, undefined, undefined],
  갍: [undefined, undefined, undefined, undefined],
  값: [undefined, undefined, undefined, undefined],
  갃: [undefined, undefined, undefined, undefined],
  갆: [undefined, undefined, undefined, undefined],
  갅: [undefined, undefined, undefined, undefined],
  걇: [undefined, undefined, undefined, undefined],
  걃: [undefined, undefined, undefined, undefined],
  걁: [undefined, undefined, undefined, undefined],
  걆: [undefined, undefined, undefined, undefined],
  걂: [undefined, undefined, undefined, undefined],
  걄: [undefined, undefined, undefined, undefined],
  걅: [undefined, undefined, undefined, undefined],
  걊: [undefined, undefined, undefined, undefined],
  갻: [undefined, undefined, undefined, undefined],
  갾: [undefined, undefined, undefined, undefined],
  갽: [undefined, undefined, undefined, undefined],
  긇: [undefined, undefined, undefined, undefined],
  긃: [undefined, undefined, undefined, undefined],
  긁: [undefined, undefined, undefined, undefined],
  긆: [undefined, undefined, undefined, undefined],
  긂: [undefined, undefined, undefined, undefined],
  긄: [undefined, undefined, undefined, undefined],
  긅: [undefined, undefined, undefined, undefined],
  긊: [undefined, undefined, undefined, undefined],
  귻: [undefined, undefined, undefined, undefined],
  귾: [undefined, undefined, undefined, undefined],
  귽: [undefined, undefined, undefined, undefined],
  굻: [undefined, undefined, undefined, undefined],
  굷: [undefined, undefined, undefined, undefined],
  굵: [undefined, undefined, undefined, undefined],
  굺: [undefined, undefined, undefined, undefined],
  굶: [undefined, undefined, undefined, undefined],
  굸: [undefined, undefined, undefined, undefined],
  굹: [undefined, undefined, undefined, undefined],
  굾: [undefined, undefined, undefined, undefined],
  굯: [undefined, undefined, undefined, undefined],
  굲: [undefined, undefined, undefined, undefined],
  굱: [undefined, undefined, undefined, undefined],
  귫: [undefined, undefined, undefined, undefined],
  귧: [undefined, undefined, undefined, undefined],
  귥: [undefined, undefined, undefined, undefined],
  귪: [undefined, undefined, undefined, undefined],
  귦: [undefined, undefined, undefined, undefined],
  귨: [undefined, undefined, undefined, undefined],
  귩: [undefined, undefined, undefined, undefined],
  귮: [undefined, undefined, undefined, undefined],
  귟: [undefined, undefined, undefined, undefined],
  귢: [undefined, undefined, undefined, undefined],
  귡: [undefined, undefined, undefined, undefined],
  곯: [undefined, undefined, undefined, undefined],
  곫: [undefined, undefined, undefined, undefined],
  곩: [undefined, undefined, undefined, undefined],
  곮: [undefined, undefined, undefined, undefined],
  곪: [undefined, undefined, undefined, undefined],
  곬: [undefined, undefined, undefined, undefined],
  곭: [undefined, undefined, undefined, undefined],
  곲: [undefined, undefined, undefined, undefined],
  곣: [undefined, undefined, undefined, undefined],
  곦: [undefined, undefined, undefined, undefined],
  곥: [undefined, undefined, undefined, undefined],
  굟: [undefined, undefined, undefined, undefined],
  굛: [undefined, undefined, undefined, undefined],
  굙: [undefined, undefined, undefined, undefined],
  굞: [undefined, undefined, undefined, undefined],
  굚: [undefined, undefined, undefined, undefined],
  굜: [undefined, undefined, undefined, undefined],
  굝: [undefined, undefined, undefined, undefined],
  굢: [undefined, undefined, undefined, undefined],
  굓: [undefined, undefined, undefined, undefined],
  굖: [undefined, undefined, undefined, undefined],
  굕: [undefined, undefined, undefined, undefined],
  겷: [undefined, undefined, undefined, undefined],
  겳: [undefined, undefined, undefined, undefined],
  겱: [undefined, undefined, undefined, undefined],
  겶: [undefined, undefined, undefined, undefined],
  겲: [undefined, undefined, undefined, undefined],
  겴: [undefined, undefined, undefined, undefined],
  겵: [undefined, undefined, undefined, undefined],
  겺: [undefined, undefined, undefined, undefined],
  겫: [undefined, undefined, undefined, undefined],
  겮: [undefined, undefined, undefined, undefined],
  겭: [undefined, undefined, undefined, undefined],
  넗: [undefined, undefined, undefined, undefined],
  넓: [undefined, undefined, undefined, undefined],
  넑: [undefined, undefined, undefined, undefined],
  넖: [undefined, undefined, undefined, undefined],
  넒: [undefined, undefined, undefined, undefined],
  넔: [undefined, undefined, undefined, undefined],
  넕: [undefined, undefined, undefined, undefined],
  넚: [undefined, undefined, undefined, undefined],
  넋: [undefined, undefined, undefined, undefined],
  넎: [undefined, undefined, undefined, undefined],
  넍: [undefined, undefined, undefined, undefined],
  닗: [undefined, undefined, undefined, undefined],
  닓: [undefined, undefined, undefined, undefined],
  닑: [undefined, undefined, undefined, undefined],
  닖: [undefined, undefined, undefined, undefined],
  닒: [undefined, undefined, undefined, undefined],
  닔: [undefined, undefined, undefined, undefined],
  닕: [undefined, undefined, undefined, undefined],
  닚: [undefined, undefined, undefined, undefined],
  닋: [undefined, undefined, undefined, undefined],
  닎: [undefined, undefined, undefined, undefined],
  닍: [undefined, undefined, undefined, undefined],
  낧: [undefined, undefined, undefined, undefined],
  낣: [undefined, undefined, undefined, undefined],
  낡: [undefined, undefined, undefined, undefined],
  낦: [undefined, undefined, undefined, undefined],
  낢: [undefined, undefined, undefined, undefined],
  낤: [undefined, undefined, undefined, undefined],
  낥: [undefined, undefined, undefined, undefined],
  낪: [undefined, undefined, undefined, undefined],
  낛: [undefined, undefined, undefined, undefined],
  낞: [undefined, undefined, undefined, undefined],
  낝: [undefined, undefined, undefined, undefined],
  냟: [undefined, undefined, undefined, undefined],
  냛: [undefined, undefined, undefined, undefined],
  냙: [undefined, undefined, undefined, undefined],
  냞: [undefined, undefined, undefined, undefined],
  냚: [undefined, undefined, undefined, undefined],
  냜: [undefined, undefined, undefined, undefined],
  냝: [undefined, undefined, undefined, undefined],
  냢: [undefined, undefined, undefined, undefined],
  냓: [undefined, undefined, undefined, undefined],
  냖: [undefined, undefined, undefined, undefined],
  냕: [undefined, undefined, undefined, undefined],
  늟: [undefined, undefined, undefined, undefined],
  늛: [undefined, undefined, undefined, undefined],
  늙: [undefined, undefined, undefined, undefined],
  늞: [undefined, undefined, undefined, undefined],
  늚: [undefined, undefined, undefined, undefined],
  늜: [undefined, undefined, undefined, undefined],
  늝: [undefined, undefined, undefined, undefined],
  늢: [undefined, undefined, undefined, undefined],
  늓: [undefined, undefined, undefined, undefined],
  늖: [undefined, undefined, undefined, undefined],
  늕: [undefined, undefined, undefined, undefined],
  눓: [undefined, undefined, undefined, undefined],
  눏: [undefined, undefined, undefined, undefined],
  눍: [undefined, undefined, undefined, undefined],
  눒: [undefined, undefined, undefined, undefined],
  눎: [undefined, undefined, undefined, undefined],
  눐: [undefined, undefined, undefined, undefined],
  눑: [undefined, undefined, undefined, undefined],
  눖: [undefined, undefined, undefined, undefined],
  눇: [undefined, undefined, undefined, undefined],
  눊: [undefined, undefined, undefined, undefined],
  눉: [undefined, undefined, undefined, undefined],
  늃: [undefined, undefined, undefined, undefined],
  뉿: [undefined, undefined, undefined, undefined],
  뉽: [undefined, undefined, undefined, undefined],
  늂: [undefined, undefined, undefined, undefined],
  뉾: [undefined, undefined, undefined, undefined],
  늀: [undefined, undefined, undefined, undefined],
  늁: [undefined, undefined, undefined, undefined],
  늆: [undefined, undefined, undefined, undefined],
  뉷: [undefined, undefined, undefined, undefined],
  뉺: [undefined, undefined, undefined, undefined],
  뉹: [undefined, undefined, undefined, undefined],
  놇: [undefined, undefined, undefined, undefined],
  놃: [undefined, undefined, undefined, undefined],
  놁: [undefined, undefined, undefined, undefined],
  놆: [undefined, undefined, undefined, undefined],
  놂: [undefined, undefined, undefined, undefined],
  놄: [undefined, undefined, undefined, undefined],
  놅: [undefined, undefined, undefined, undefined],
  놊: [undefined, undefined, undefined, undefined],
  녻: [undefined, undefined, undefined, undefined],
  녾: [undefined, undefined, undefined, undefined],
  녽: [undefined, undefined, undefined, undefined],
  뇷: [undefined, undefined, undefined, undefined],
  뇳: [undefined, undefined, undefined, undefined],
  뇱: [undefined, undefined, undefined, undefined],
  뇶: [undefined, undefined, undefined, undefined],
  뇲: [undefined, undefined, undefined, undefined],
  뇴: [undefined, undefined, undefined, undefined],
  뇵: [undefined, undefined, undefined, undefined],
  뇺: [undefined, undefined, undefined, undefined],
  뇫: [undefined, undefined, undefined, undefined],
  뇮: [undefined, undefined, undefined, undefined],
  뇭: [undefined, undefined, undefined, undefined],
  녏: [undefined, undefined, undefined, undefined],
  녋: [undefined, undefined, undefined, undefined],
  녉: [undefined, undefined, undefined, undefined],
  녎: [undefined, undefined, undefined, undefined],
  녊: [undefined, undefined, undefined, undefined],
  녌: [undefined, undefined, undefined, undefined],
  녍: [undefined, undefined, undefined, undefined],
  녒: [undefined, undefined, undefined, undefined],
  녃: [undefined, undefined, undefined, undefined],
  녆: [undefined, undefined, undefined, undefined],
  녅: [undefined, undefined, undefined, undefined],
  펋: [undefined, undefined, undefined, undefined],
  펇: [undefined, undefined, undefined, undefined],
  펅: [undefined, undefined, undefined, undefined],
  펊: [undefined, undefined, undefined, undefined],
  펆: [undefined, undefined, undefined, undefined],
  펈: [undefined, undefined, undefined, undefined],
  펉: [undefined, undefined, undefined, undefined],
  펎: [undefined, undefined, undefined, undefined],
  퍿: [undefined, undefined, undefined, undefined],
  펂: [undefined, undefined, undefined, undefined],
  펁: [undefined, undefined, undefined, undefined],
  핋: [undefined, undefined, undefined, undefined],
  핇: [undefined, undefined, undefined, undefined],
  핅: [undefined, undefined, undefined, undefined],
  핊: [undefined, undefined, undefined, undefined],
  핆: [undefined, undefined, undefined, undefined],
  핈: [undefined, undefined, undefined, undefined],
  핉: [undefined, undefined, undefined, undefined],
  핎: [undefined, undefined, undefined, undefined],
  픿: [undefined, undefined, undefined, undefined],
  핂: [undefined, undefined, undefined, undefined],
  핁: [undefined, undefined, undefined, undefined],
  팛: [undefined, undefined, undefined, undefined],
  팗: [undefined, undefined, undefined, undefined],
  팕: [undefined, undefined, undefined, undefined],
  팚: [undefined, undefined, undefined, undefined],
  팖: [undefined, undefined, undefined, undefined],
  팘: [undefined, undefined, undefined, undefined],
  팙: [undefined, undefined, undefined, undefined],
  팞: [undefined, undefined, undefined, undefined],
  팏: [undefined, undefined, undefined, undefined],
  팒: [undefined, undefined, undefined, undefined],
  팑: [undefined, undefined, undefined, undefined],
  퍓: [undefined, undefined, undefined, undefined],
  퍏: [undefined, undefined, undefined, undefined],
  퍍: [undefined, undefined, undefined, undefined],
  퍒: [undefined, undefined, undefined, undefined],
  퍎: [undefined, undefined, undefined, undefined],
  퍐: [undefined, undefined, undefined, undefined],
  퍑: [undefined, undefined, undefined, undefined],
  퍖: [undefined, undefined, undefined, undefined],
  퍇: [undefined, undefined, undefined, undefined],
  퍊: [undefined, undefined, undefined, undefined],
  퍉: [undefined, undefined, undefined, undefined],
  픓: [undefined, undefined, undefined, undefined],
  픏: [undefined, undefined, undefined, undefined],
  픍: [undefined, undefined, undefined, undefined],
  픒: [undefined, undefined, undefined, undefined],
  픎: [undefined, undefined, undefined, undefined],
  픐: [undefined, undefined, undefined, undefined],
  픑: [undefined, undefined, undefined, undefined],
  픖: [undefined, undefined, undefined, undefined],
  픇: [undefined, undefined, undefined, undefined],
  픊: [undefined, undefined, undefined, undefined],
  픉: [undefined, undefined, undefined, undefined],
  풇: [undefined, undefined, undefined, undefined],
  풃: [undefined, undefined, undefined, undefined],
  풁: [undefined, undefined, undefined, undefined],
  풆: [undefined, undefined, undefined, undefined],
  풂: [undefined, undefined, undefined, undefined],
  풄: [undefined, undefined, undefined, undefined],
  풅: [undefined, undefined, undefined, undefined],
  풊: [undefined, undefined, undefined, undefined],
  푻: [undefined, undefined, undefined, undefined],
  푾: [undefined, undefined, undefined, undefined],
  푽: [undefined, undefined, undefined, undefined],
  퓷: [undefined, undefined, undefined, undefined],
  퓳: [undefined, undefined, undefined, undefined],
  퓱: [undefined, undefined, undefined, undefined],
  퓶: [undefined, undefined, undefined, undefined],
  퓲: [undefined, undefined, undefined, undefined],
  퓴: [undefined, undefined, undefined, undefined],
  퓵: [undefined, undefined, undefined, undefined],
  퓺: [undefined, undefined, undefined, undefined],
  퓫: [undefined, undefined, undefined, undefined],
  퓮: [undefined, undefined, undefined, undefined],
  퓭: [undefined, undefined, undefined, undefined],
  폻: [undefined, undefined, undefined, undefined],
  폷: [undefined, undefined, undefined, undefined],
  폵: [undefined, undefined, undefined, undefined],
  폺: [undefined, undefined, undefined, undefined],
  폶: [undefined, undefined, undefined, undefined],
  폸: [undefined, undefined, undefined, undefined],
  폹: [undefined, undefined, undefined, undefined],
  폾: [undefined, undefined, undefined, undefined],
  폯: [undefined, undefined, undefined, undefined],
  폲: [undefined, undefined, undefined, undefined],
  폱: [undefined, undefined, undefined, undefined],
  푫: [undefined, undefined, undefined, undefined],
  푧: [undefined, undefined, undefined, undefined],
  푥: [undefined, undefined, undefined, undefined],
  푪: [undefined, undefined, undefined, undefined],
  푦: [undefined, undefined, undefined, undefined],
  푨: [undefined, undefined, undefined, undefined],
  푩: [undefined, undefined, undefined, undefined],
  푮: [undefined, undefined, undefined, undefined],
  푟: [undefined, undefined, undefined, undefined],
  푢: [undefined, undefined, undefined, undefined],
  푡: [undefined, undefined, undefined, undefined],
  폃: [undefined, undefined, undefined, undefined],
  펿: [undefined, undefined, undefined, undefined],
  펽: [undefined, undefined, undefined, undefined],
  폂: [undefined, undefined, undefined, undefined],
  펾: [undefined, undefined, undefined, undefined],
  폀: [undefined, undefined, undefined, undefined],
  폁: [undefined, undefined, undefined, undefined],
  폆: [undefined, undefined, undefined, undefined],
  펷: [undefined, undefined, undefined, undefined],
  펺: [undefined, undefined, undefined, undefined],
  펹: [undefined, undefined, undefined, undefined],
  컳: [undefined, undefined, undefined, undefined],
  컯: [undefined, undefined, undefined, undefined],
  컭: [undefined, undefined, undefined, undefined],
  컲: [undefined, undefined, undefined, undefined],
  컮: [undefined, undefined, undefined, undefined],
  컰: [undefined, undefined, undefined, undefined],
  컱: [undefined, undefined, undefined, undefined],
  컶: [undefined, undefined, undefined, undefined],
  컧: [undefined, undefined, undefined, undefined],
  컪: [undefined, undefined, undefined, undefined],
  컩: [undefined, undefined, undefined, undefined],
  킳: [undefined, undefined, undefined, undefined],
  킯: [undefined, undefined, undefined, undefined],
  킭: [undefined, undefined, undefined, undefined],
  킲: [undefined, undefined, undefined, undefined],
  킮: [undefined, undefined, undefined, undefined],
  킰: [undefined, undefined, undefined, undefined],
  킱: [undefined, undefined, undefined, undefined],
  킶: [undefined, undefined, undefined, undefined],
  킧: [undefined, undefined, undefined, undefined],
  킪: [undefined, undefined, undefined, undefined],
  킩: [undefined, undefined, undefined, undefined],
  캃: [undefined, undefined, undefined, undefined],
  칿: [undefined, undefined, undefined, undefined],
  칽: [undefined, undefined, undefined, undefined],
  캂: [undefined, undefined, undefined, undefined],
  칾: [undefined, undefined, undefined, undefined],
  캀: [undefined, undefined, undefined, undefined],
  캁: [undefined, undefined, undefined, undefined],
  캆: [undefined, undefined, undefined, undefined],
  칷: [undefined, undefined, undefined, undefined],
  칺: [undefined, undefined, undefined, undefined],
  칹: [undefined, undefined, undefined, undefined],
  캻: [undefined, undefined, undefined, undefined],
  캷: [undefined, undefined, undefined, undefined],
  캵: [undefined, undefined, undefined, undefined],
  캺: [undefined, undefined, undefined, undefined],
  캶: [undefined, undefined, undefined, undefined],
  캸: [undefined, undefined, undefined, undefined],
  캹: [undefined, undefined, undefined, undefined],
  캾: [undefined, undefined, undefined, undefined],
  캯: [undefined, undefined, undefined, undefined],
  캲: [undefined, undefined, undefined, undefined],
  캱: [undefined, undefined, undefined, undefined],
  큻: [undefined, undefined, undefined, undefined],
  큷: [undefined, undefined, undefined, undefined],
  큵: [undefined, undefined, undefined, undefined],
  큺: [undefined, undefined, undefined, undefined],
  큶: [undefined, undefined, undefined, undefined],
  큸: [undefined, undefined, undefined, undefined],
  큹: [undefined, undefined, undefined, undefined],
  큾: [undefined, undefined, undefined, undefined],
  큯: [undefined, undefined, undefined, undefined],
  큲: [undefined, undefined, undefined, undefined],
  큱: [undefined, undefined, undefined, undefined],
  쿯: [undefined, undefined, undefined, undefined],
  쿫: [undefined, undefined, undefined, undefined],
  쿩: [undefined, undefined, undefined, undefined],
  쿮: [undefined, undefined, undefined, undefined],
  쿪: [undefined, undefined, undefined, undefined],
  쿬: [undefined, undefined, undefined, undefined],
  쿭: [undefined, undefined, undefined, undefined],
  쿲: [undefined, undefined, undefined, undefined],
  쿣: [undefined, undefined, undefined, undefined],
  쿦: [undefined, undefined, undefined, undefined],
  쿥: [undefined, undefined, undefined, undefined],
  큟: [undefined, undefined, undefined, undefined],
  큛: [undefined, undefined, undefined, undefined],
  큙: [undefined, undefined, undefined, undefined],
  큞: [undefined, undefined, undefined, undefined],
  큚: [undefined, undefined, undefined, undefined],
  큜: [undefined, undefined, undefined, undefined],
  큝: [undefined, undefined, undefined, undefined],
  큢: [undefined, undefined, undefined, undefined],
  큓: [undefined, undefined, undefined, undefined],
  큖: [undefined, undefined, undefined, undefined],
  큕: [undefined, undefined, undefined, undefined],
  콣: [undefined, undefined, undefined, undefined],
  콟: [undefined, undefined, undefined, undefined],
  콝: [undefined, undefined, undefined, undefined],
  콢: [undefined, undefined, undefined, undefined],
  콞: [undefined, undefined, undefined, undefined],
  콠: [undefined, undefined, undefined, undefined],
  콡: [undefined, undefined, undefined, undefined],
  콦: [undefined, undefined, undefined, undefined],
  콗: [undefined, undefined, undefined, undefined],
  콚: [undefined, undefined, undefined, undefined],
  콙: [undefined, undefined, undefined, undefined],
  쿓: [undefined, undefined, undefined, undefined],
  쿏: [undefined, undefined, undefined, undefined],
  쿍: [undefined, undefined, undefined, undefined],
  쿒: [undefined, undefined, undefined, undefined],
  쿎: [undefined, undefined, undefined, undefined],
  쿐: [undefined, undefined, undefined, undefined],
  쿑: [undefined, undefined, undefined, undefined],
  쿖: [undefined, undefined, undefined, undefined],
  쿇: [undefined, undefined, undefined, undefined],
  쿊: [undefined, undefined, undefined, undefined],
  쿉: [undefined, undefined, undefined, undefined],
  켫: [undefined, undefined, undefined, undefined],
  켧: [undefined, undefined, undefined, undefined],
  켥: [undefined, undefined, undefined, undefined],
  켪: [undefined, undefined, undefined, undefined],
  켦: [undefined, undefined, undefined, undefined],
  켨: [undefined, undefined, undefined, undefined],
  켩: [undefined, undefined, undefined, undefined],
  켮: [undefined, undefined, undefined, undefined],
  켟: [undefined, undefined, undefined, undefined],
  켢: [undefined, undefined, undefined, undefined],
  켡: [undefined, undefined, undefined, undefined],
  멇: [undefined, undefined, undefined, undefined],
  멃: [undefined, undefined, undefined, undefined],
  멁: [undefined, undefined, undefined, undefined],
  멆: [undefined, undefined, undefined, undefined],
  멂: [undefined, undefined, undefined, undefined],
  멄: [undefined, undefined, undefined, undefined],
  멅: [undefined, undefined, undefined, undefined],
  멊: [undefined, undefined, undefined, undefined],
  먻: [undefined, undefined, undefined, undefined],
  먾: [undefined, undefined, undefined, undefined],
  먽: [undefined, undefined, undefined, undefined],
  밇: [undefined, undefined, undefined, undefined],
  밃: [undefined, undefined, undefined, undefined],
  밁: [undefined, undefined, undefined, undefined],
  밆: [undefined, undefined, undefined, undefined],
  밂: [undefined, undefined, undefined, undefined],
  밄: [undefined, undefined, undefined, undefined],
  밅: [undefined, undefined, undefined, undefined],
  밊: [undefined, undefined, undefined, undefined],
  믻: [undefined, undefined, undefined, undefined],
  믾: [undefined, undefined, undefined, undefined],
  믽: [undefined, undefined, undefined, undefined],
  맗: [undefined, undefined, undefined, undefined],
  맓: [undefined, undefined, undefined, undefined],
  맑: [undefined, undefined, undefined, undefined],
  맖: [undefined, undefined, undefined, undefined],
  맒: [undefined, undefined, undefined, undefined],
  맔: [undefined, undefined, undefined, undefined],
  맕: [undefined, undefined, undefined, undefined],
  맚: [undefined, undefined, undefined, undefined],
  맋: [undefined, undefined, undefined, undefined],
  많: [undefined, undefined, undefined, undefined],
  맍: [undefined, undefined, undefined, undefined],
  먏: [undefined, undefined, undefined, undefined],
  먋: [undefined, undefined, undefined, undefined],
  먉: [undefined, undefined, undefined, undefined],
  먎: [undefined, undefined, undefined, undefined],
  먊: [undefined, undefined, undefined, undefined],
  먌: [undefined, undefined, undefined, undefined],
  먍: [undefined, undefined, undefined, undefined],
  먒: [undefined, undefined, undefined, undefined],
  먃: [undefined, undefined, undefined, undefined],
  먆: [undefined, undefined, undefined, undefined],
  먅: [undefined, undefined, undefined, undefined],
  믏: [undefined, undefined, undefined, undefined],
  믋: [undefined, undefined, undefined, undefined],
  믉: [undefined, undefined, undefined, undefined],
  믎: [undefined, undefined, undefined, undefined],
  믊: [undefined, undefined, undefined, undefined],
  믌: [undefined, undefined, undefined, undefined],
  믍: [undefined, undefined, undefined, undefined],
  믒: [undefined, undefined, undefined, undefined],
  믃: [undefined, undefined, undefined, undefined],
  믆: [undefined, undefined, undefined, undefined],
  믅: [undefined, undefined, undefined, undefined],
  뭃: [undefined, undefined, undefined, undefined],
  묿: [undefined, undefined, undefined, undefined],
  묽: [undefined, undefined, undefined, undefined],
  뭂: [undefined, undefined, undefined, undefined],
  묾: [undefined, undefined, undefined, undefined],
  뭀: [undefined, undefined, undefined, undefined],
  뭁: [undefined, undefined, undefined, undefined],
  뭆: [undefined, undefined, undefined, undefined],
  묷: [undefined, undefined, undefined, undefined],
  묺: [undefined, undefined, undefined, undefined],
  묹: [undefined, undefined, undefined, undefined],
  뮳: [undefined, undefined, undefined, undefined],
  뮯: [undefined, undefined, undefined, undefined],
  뮭: [undefined, undefined, undefined, undefined],
  뮲: [undefined, undefined, undefined, undefined],
  뮮: [undefined, undefined, undefined, undefined],
  뮰: [undefined, undefined, undefined, undefined],
  뮱: [undefined, undefined, undefined, undefined],
  뮶: [undefined, undefined, undefined, undefined],
  뮧: [undefined, undefined, undefined, undefined],
  뮪: [undefined, undefined, undefined, undefined],
  뮩: [undefined, undefined, undefined, undefined],
  몷: [undefined, undefined, undefined, undefined],
  몳: [undefined, undefined, undefined, undefined],
  몱: [undefined, undefined, undefined, undefined],
  몶: [undefined, undefined, undefined, undefined],
  몲: [undefined, undefined, undefined, undefined],
  몴: [undefined, undefined, undefined, undefined],
  몵: [undefined, undefined, undefined, undefined],
  몺: [undefined, undefined, undefined, undefined],
  몫: [undefined, undefined, undefined, undefined],
  몮: [undefined, undefined, undefined, undefined],
  몭: [undefined, undefined, undefined, undefined],
  묧: [undefined, undefined, undefined, undefined],
  묣: [undefined, undefined, undefined, undefined],
  묡: [undefined, undefined, undefined, undefined],
  묦: [undefined, undefined, undefined, undefined],
  묢: [undefined, undefined, undefined, undefined],
  묤: [undefined, undefined, undefined, undefined],
  묥: [undefined, undefined, undefined, undefined],
  묪: [undefined, undefined, undefined, undefined],
  묛: [undefined, undefined, undefined, undefined],
  묞: [undefined, undefined, undefined, undefined],
  묝: [undefined, undefined, undefined, undefined],
  멿: [undefined, undefined, undefined, undefined],
  멻: [undefined, undefined, undefined, undefined],
  멹: [undefined, undefined, undefined, undefined],
  멾: [undefined, undefined, undefined, undefined],
  멺: [undefined, undefined, undefined, undefined],
  멼: [undefined, undefined, undefined, undefined],
  멽: [undefined, undefined, undefined, undefined],
  몂: [undefined, undefined, undefined, undefined],
  멳: [undefined, undefined, undefined, undefined],
  멶: [undefined, undefined, undefined, undefined],
  멵: [undefined, undefined, undefined, undefined],
  덣: [undefined, undefined, undefined, undefined],
  덟: [undefined, undefined, undefined, undefined],
  덝: [undefined, undefined, undefined, undefined],
  덢: [undefined, undefined, undefined, undefined],
  덞: [undefined, undefined, undefined, undefined],
  덠: [undefined, undefined, undefined, undefined],
  덡: [undefined, undefined, undefined, undefined],
  덦: [undefined, undefined, undefined, undefined],
  덗: [undefined, undefined, undefined, undefined],
  덚: [undefined, undefined, undefined, undefined],
  덙: [undefined, undefined, undefined, undefined],
  딣: [undefined, undefined, undefined, undefined],
  딟: [undefined, undefined, undefined, undefined],
  딝: [undefined, undefined, undefined, undefined],
  딢: [undefined, undefined, undefined, undefined],
  딞: [undefined, undefined, undefined, undefined],
  딠: [undefined, undefined, undefined, undefined],
  딡: [undefined, undefined, undefined, undefined],
  딦: [undefined, undefined, undefined, undefined],
  딗: [undefined, undefined, undefined, undefined],
  딚: [undefined, undefined, undefined, undefined],
  딙: [undefined, undefined, undefined, undefined],
  닳: [undefined, undefined, undefined, undefined],
  닯: [undefined, undefined, undefined, undefined],
  닭: [undefined, undefined, undefined, undefined],
  닲: [undefined, undefined, undefined, undefined],
  닮: [undefined, undefined, undefined, undefined],
  닰: [undefined, undefined, undefined, undefined],
  닱: [undefined, undefined, undefined, undefined],
  닶: [undefined, undefined, undefined, undefined],
  닧: [undefined, undefined, undefined, undefined],
  닪: [undefined, undefined, undefined, undefined],
  닩: [undefined, undefined, undefined, undefined],
  댫: [undefined, undefined, undefined, undefined],
  댧: [undefined, undefined, undefined, undefined],
  댥: [undefined, undefined, undefined, undefined],
  댪: [undefined, undefined, undefined, undefined],
  댦: [undefined, undefined, undefined, undefined],
  댨: [undefined, undefined, undefined, undefined],
  댩: [undefined, undefined, undefined, undefined],
  댮: [undefined, undefined, undefined, undefined],
  댟: [undefined, undefined, undefined, undefined],
  댢: [undefined, undefined, undefined, undefined],
  댡: [undefined, undefined, undefined, undefined],
  듫: [undefined, undefined, undefined, undefined],
  듧: [undefined, undefined, undefined, undefined],
  듥: [undefined, undefined, undefined, undefined],
  듪: [undefined, undefined, undefined, undefined],
  듦: [undefined, undefined, undefined, undefined],
  듨: [undefined, undefined, undefined, undefined],
  듩: [undefined, undefined, undefined, undefined],
  듮: [undefined, undefined, undefined, undefined],
  듟: [undefined, undefined, undefined, undefined],
  듢: [undefined, undefined, undefined, undefined],
  듡: [undefined, undefined, undefined, undefined],
  둟: [undefined, undefined, undefined, undefined],
  둛: [undefined, undefined, undefined, undefined],
  둙: [undefined, undefined, undefined, undefined],
  둞: [undefined, undefined, undefined, undefined],
  둚: [undefined, undefined, undefined, undefined],
  둜: [undefined, undefined, undefined, undefined],
  둝: [undefined, undefined, undefined, undefined],
  둢: [undefined, undefined, undefined, undefined],
  둓: [undefined, undefined, undefined, undefined],
  둖: [undefined, undefined, undefined, undefined],
  둕: [undefined, undefined, undefined, undefined],
  듏: [undefined, undefined, undefined, undefined],
  듋: [undefined, undefined, undefined, undefined],
  듉: [undefined, undefined, undefined, undefined],
  듎: [undefined, undefined, undefined, undefined],
  듊: [undefined, undefined, undefined, undefined],
  듌: [undefined, undefined, undefined, undefined],
  듍: [undefined, undefined, undefined, undefined],
  듒: [undefined, undefined, undefined, undefined],
  듃: [undefined, undefined, undefined, undefined],
  듆: [undefined, undefined, undefined, undefined],
  듅: [undefined, undefined, undefined, undefined],
  돓: [undefined, undefined, undefined, undefined],
  돏: [undefined, undefined, undefined, undefined],
  돍: [undefined, undefined, undefined, undefined],
  돒: [undefined, undefined, undefined, undefined],
  돎: [undefined, undefined, undefined, undefined],
  돐: [undefined, undefined, undefined, undefined],
  돑: [undefined, undefined, undefined, undefined],
  돖: [undefined, undefined, undefined, undefined],
  돇: [undefined, undefined, undefined, undefined],
  돊: [undefined, undefined, undefined, undefined],
  돉: [undefined, undefined, undefined, undefined],
  둃: [undefined, undefined, undefined, undefined],
  됿: [undefined, undefined, undefined, undefined],
  됽: [undefined, undefined, undefined, undefined],
  둂: [undefined, undefined, undefined, undefined],
  됾: [undefined, undefined, undefined, undefined],
  둀: [undefined, undefined, undefined, undefined],
  둁: [undefined, undefined, undefined, undefined],
  둆: [undefined, undefined, undefined, undefined],
  됷: [undefined, undefined, undefined, undefined],
  됺: [undefined, undefined, undefined, undefined],
  됹: [undefined, undefined, undefined, undefined],
  뎛: [undefined, undefined, undefined, undefined],
  뎗: [undefined, undefined, undefined, undefined],
  뎕: [undefined, undefined, undefined, undefined],
  뎚: [undefined, undefined, undefined, undefined],
  뎖: [undefined, undefined, undefined, undefined],
  뎘: [undefined, undefined, undefined, undefined],
  뎙: [undefined, undefined, undefined, undefined],
  뎞: [undefined, undefined, undefined, undefined],
  뎏: [undefined, undefined, undefined, undefined],
  뎒: [undefined, undefined, undefined, undefined],
  뎑: [undefined, undefined, undefined, undefined],
  섫: [undefined, undefined, undefined, undefined],
  섧: [undefined, undefined, undefined, undefined],
  섥: [undefined, undefined, undefined, undefined],
  섪: [undefined, undefined, undefined, undefined],
  섦: [undefined, undefined, undefined, undefined],
  섨: [undefined, undefined, undefined, undefined],
  섩: [undefined, undefined, undefined, undefined],
  섮: [undefined, undefined, undefined, undefined],
  섟: [undefined, undefined, undefined, undefined],
  섢: [undefined, undefined, undefined, undefined],
  섡: [undefined, undefined, undefined, undefined],
  싫: [undefined, undefined, undefined, undefined],
  싧: [undefined, undefined, undefined, undefined],
  싥: [undefined, undefined, undefined, undefined],
  싪: [undefined, undefined, undefined, undefined],
  싦: [undefined, undefined, undefined, undefined],
  싨: [undefined, undefined, undefined, undefined],
  싩: [undefined, undefined, undefined, undefined],
  싮: [undefined, undefined, undefined, undefined],
  싟: [undefined, undefined, undefined, undefined],
  싢: [undefined, undefined, undefined, undefined],
  싡: [undefined, undefined, undefined, undefined],
  삻: [undefined, undefined, undefined, undefined],
  삷: [undefined, undefined, undefined, undefined],
  삵: [undefined, undefined, undefined, undefined],
  삺: [undefined, undefined, undefined, undefined],
  삶: [undefined, undefined, undefined, undefined],
  삸: [undefined, undefined, undefined, undefined],
  삹: [undefined, undefined, undefined, undefined],
  삾: [undefined, undefined, undefined, undefined],
  삯: [undefined, undefined, undefined, undefined],
  삲: [undefined, undefined, undefined, undefined],
  삱: [undefined, undefined, undefined, undefined],
  샳: [undefined, undefined, undefined, undefined],
  샯: [undefined, undefined, undefined, undefined],
  샭: [undefined, undefined, undefined, undefined],
  샲: [undefined, undefined, undefined, undefined],
  샮: [undefined, undefined, undefined, undefined],
  샰: [undefined, undefined, undefined, undefined],
  샱: [undefined, undefined, undefined, undefined],
  샶: [undefined, undefined, undefined, undefined],
  샧: [undefined, undefined, undefined, undefined],
  샪: [undefined, undefined, undefined, undefined],
  샩: [undefined, undefined, undefined, undefined],
  슳: [undefined, undefined, undefined, undefined],
  슯: [undefined, undefined, undefined, undefined],
  슭: [undefined, undefined, undefined, undefined],
  슲: [undefined, undefined, undefined, undefined],
  슮: [undefined, undefined, undefined, undefined],
  슰: [undefined, undefined, undefined, undefined],
  슱: [undefined, undefined, undefined, undefined],
  슶: [undefined, undefined, undefined, undefined],
  슧: [undefined, undefined, undefined, undefined],
  슪: [undefined, undefined, undefined, undefined],
  슩: [undefined, undefined, undefined, undefined],
  숧: [undefined, undefined, undefined, undefined],
  숣: [undefined, undefined, undefined, undefined],
  숡: [undefined, undefined, undefined, undefined],
  숦: [undefined, undefined, undefined, undefined],
  숢: [undefined, undefined, undefined, undefined],
  숤: [undefined, undefined, undefined, undefined],
  숥: [undefined, undefined, undefined, undefined],
  숪: [undefined, undefined, undefined, undefined],
  숛: [undefined, undefined, undefined, undefined],
  숞: [undefined, undefined, undefined, undefined],
  숝: [undefined, undefined, undefined, undefined],
  슗: [undefined, undefined, undefined, undefined],
  슓: [undefined, undefined, undefined, undefined],
  슑: [undefined, undefined, undefined, undefined],
  슖: [undefined, undefined, undefined, undefined],
  슒: [undefined, undefined, undefined, undefined],
  슔: [undefined, undefined, undefined, undefined],
  슕: [undefined, undefined, undefined, undefined],
  슚: [undefined, undefined, undefined, undefined],
  슋: [undefined, undefined, undefined, undefined],
  슎: [undefined, undefined, undefined, undefined],
  슍: [undefined, undefined, undefined, undefined],
  솛: [undefined, undefined, undefined, undefined],
  솗: [undefined, undefined, undefined, undefined],
  솕: [undefined, undefined, undefined, undefined],
  솚: [undefined, undefined, undefined, undefined],
  솖: [undefined, undefined, undefined, undefined],
  솘: [undefined, undefined, undefined, undefined],
  솙: [undefined, undefined, undefined, undefined],
  솞: [undefined, undefined, undefined, undefined],
  솏: [undefined, undefined, undefined, undefined],
  솒: [undefined, undefined, undefined, undefined],
  솑: [undefined, undefined, undefined, undefined],
  숋: [undefined, undefined, undefined, undefined],
  숇: [undefined, undefined, undefined, undefined],
  숅: [undefined, undefined, undefined, undefined],
  숊: [undefined, undefined, undefined, undefined],
  숆: [undefined, undefined, undefined, undefined],
  숈: [undefined, undefined, undefined, undefined],
  숉: [undefined, undefined, undefined, undefined],
  숎: [undefined, undefined, undefined, undefined],
  쇿: [undefined, undefined, undefined, undefined],
  숂: [undefined, undefined, undefined, undefined],
  숁: [undefined, undefined, undefined, undefined],
  셣: [undefined, undefined, undefined, undefined],
  셟: [undefined, undefined, undefined, undefined],
  셝: [undefined, undefined, undefined, undefined],
  셢: [undefined, undefined, undefined, undefined],
  셞: [undefined, undefined, undefined, undefined],
  셠: [undefined, undefined, undefined, undefined],
  셡: [undefined, undefined, undefined, undefined],
  셦: [undefined, undefined, undefined, undefined],
  셗: [undefined, undefined, undefined, undefined],
  셚: [undefined, undefined, undefined, undefined],
  셙: [undefined, undefined, undefined, undefined],
  턿: [undefined, undefined, undefined, undefined],
  턻: [undefined, undefined, undefined, undefined],
  턹: [undefined, undefined, undefined, undefined],
  턾: [undefined, undefined, undefined, undefined],
  턺: [undefined, undefined, undefined, undefined],
  턼: [undefined, undefined, undefined, undefined],
  턽: [undefined, undefined, undefined, undefined],
  텂: [undefined, undefined, undefined, undefined],
  턳: [undefined, undefined, undefined, undefined],
  턶: [undefined, undefined, undefined, undefined],
  턵: [undefined, undefined, undefined, undefined],
  틿: [undefined, undefined, undefined, undefined],
  틻: [undefined, undefined, undefined, undefined],
  틹: [undefined, undefined, undefined, undefined],
  틾: [undefined, undefined, undefined, undefined],
  틺: [undefined, undefined, undefined, undefined],
  틼: [undefined, undefined, undefined, undefined],
  틽: [undefined, undefined, undefined, undefined],
  팂: [undefined, undefined, undefined, undefined],
  틳: [undefined, undefined, undefined, undefined],
  틶: [undefined, undefined, undefined, undefined],
  틵: [undefined, undefined, undefined, undefined],
  탏: [undefined, undefined, undefined, undefined],
  탋: [undefined, undefined, undefined, undefined],
  탉: [undefined, undefined, undefined, undefined],
  탎: [undefined, undefined, undefined, undefined],
  탊: [undefined, undefined, undefined, undefined],
  탌: [undefined, undefined, undefined, undefined],
  탍: [undefined, undefined, undefined, undefined],
  탒: [undefined, undefined, undefined, undefined],
  탃: [undefined, undefined, undefined, undefined],
  탆: [undefined, undefined, undefined, undefined],
  탅: [undefined, undefined, undefined, undefined],
  턇: [undefined, undefined, undefined, undefined],
  턃: [undefined, undefined, undefined, undefined],
  턁: [undefined, undefined, undefined, undefined],
  턆: [undefined, undefined, undefined, undefined],
  턂: [undefined, undefined, undefined, undefined],
  턄: [undefined, undefined, undefined, undefined],
  턅: [undefined, undefined, undefined, undefined],
  턊: [undefined, undefined, undefined, undefined],
  탻: [undefined, undefined, undefined, undefined],
  탾: [undefined, undefined, undefined, undefined],
  탽: [undefined, undefined, undefined, undefined],
  틇: [undefined, undefined, undefined, undefined],
  틃: [undefined, undefined, undefined, undefined],
  틁: [undefined, undefined, undefined, undefined],
  틆: [undefined, undefined, undefined, undefined],
  틂: [undefined, undefined, undefined, undefined],
  틄: [undefined, undefined, undefined, undefined],
  틅: [undefined, undefined, undefined, undefined],
  틊: [undefined, undefined, undefined, undefined],
  튻: [undefined, undefined, undefined, undefined],
  튾: [undefined, undefined, undefined, undefined],
  튽: [undefined, undefined, undefined, undefined],
  툻: [undefined, undefined, undefined, undefined],
  툷: [undefined, undefined, undefined, undefined],
  툵: [undefined, undefined, undefined, undefined],
  툺: [undefined, undefined, undefined, undefined],
  툶: [undefined, undefined, undefined, undefined],
  툸: [undefined, undefined, undefined, undefined],
  툹: [undefined, undefined, undefined, undefined],
  툾: [undefined, undefined, undefined, undefined],
  툯: [undefined, undefined, undefined, undefined],
  툲: [undefined, undefined, undefined, undefined],
  툱: [undefined, undefined, undefined, undefined],
  튫: [undefined, undefined, undefined, undefined],
  튧: [undefined, undefined, undefined, undefined],
  튥: [undefined, undefined, undefined, undefined],
  튪: [undefined, undefined, undefined, undefined],
  튦: [undefined, undefined, undefined, undefined],
  튨: [undefined, undefined, undefined, undefined],
  튩: [undefined, undefined, undefined, undefined],
  튮: [undefined, undefined, undefined, undefined],
  튟: [undefined, undefined, undefined, undefined],
  튢: [undefined, undefined, undefined, undefined],
  튡: [undefined, undefined, undefined, undefined],
  톯: [undefined, undefined, undefined, undefined],
  톫: [undefined, undefined, undefined, undefined],
  톩: [undefined, undefined, undefined, undefined],
  톮: [undefined, undefined, undefined, undefined],
  톪: [undefined, undefined, undefined, undefined],
  톬: [undefined, undefined, undefined, undefined],
  톭: [undefined, undefined, undefined, undefined],
  톲: [undefined, undefined, undefined, undefined],
  톣: [undefined, undefined, undefined, undefined],
  톦: [undefined, undefined, undefined, undefined],
  톥: [undefined, undefined, undefined, undefined],
  툟: [undefined, undefined, undefined, undefined],
  툛: [undefined, undefined, undefined, undefined],
  툙: [undefined, undefined, undefined, undefined],
  툞: [undefined, undefined, undefined, undefined],
  툚: [undefined, undefined, undefined, undefined],
  툜: [undefined, undefined, undefined, undefined],
  툝: [undefined, undefined, undefined, undefined],
  툢: [undefined, undefined, undefined, undefined],
  툓: [undefined, undefined, undefined, undefined],
  툖: [undefined, undefined, undefined, undefined],
  툕: [undefined, undefined, undefined, undefined],
  텷: [undefined, undefined, undefined, undefined],
  텳: [undefined, undefined, undefined, undefined],
  텱: [undefined, undefined, undefined, undefined],
  텶: [undefined, undefined, undefined, undefined],
  텲: [undefined, undefined, undefined, undefined],
  텴: [undefined, undefined, undefined, undefined],
  텵: [undefined, undefined, undefined, undefined],
  텺: [undefined, undefined, undefined, undefined],
  텫: [undefined, undefined, undefined, undefined],
  텮: [undefined, undefined, undefined, undefined],
  텭: [undefined, undefined, undefined, undefined],
};
