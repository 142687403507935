export default [
  "내가",
  "난",
  "그",
  "안",
  "내",
  "수",

  "이",
  "네",
  "거야",
  "좀",



  "그래",
  "왜",
  "있어",
  "더",
  "그리고",
  "한",
  "우리",
  "잘",

  "할",
  "다",
  "어떻게",
  "무슨",

  "정말",
  "그냥",
  "게",
  "하지만",
  "그럼",

  "있는",
  "해",
  "지금",
  "뭐",
  "아니",
  "있어요",
  "것",
  "날",
  "제가",
  "넌",
  "여기",
  "저",
  "전",
  "건",
  "이제",
  "거",
  "걸",
  "우리가",
  "아",
  "없어",

  "그게",
  "나",
  "못",
  "제",
  "당신이",
  "거예요",
  "닥터",
  "네가",
  "그런",
  "다른",
  "돼",
  "다시",
  "너",
  "그렇게",
  "좋아",
  "너무",

  "당신은",
  "그래서",
  "우린",
  "그건",
  "모든",
  "당신",
  "하는",
  "나도",
  "좋은",
  "이런",
  "응",

  "이건",
  "하고",
  "때",
  "나는",
  "뭘",
  "같이",
  "봐",
  "또",

  "없어요",
  "아냐",
  "어서",

  "오늘",
  "아니야",

  "아주",
  "오",
  "말이야",
  "어",
  "누가",
  "빨리",
  "어떤",
  "알아",
  "해요",
  "이게",
  "말",
  "마",
  "같은",
  "이렇게",
  "줄",
  "자",
  "아직",
  "하지",
  "이거",
  "야",


  "널",


  "일이",
  "가",
  "있습니다",
  "괜찮아",
  "모두",
  "알고",
  "여기서",
  "바로",
  "우리는",
  "될",
  "어디",
  "엄마",
  "그는",

  "전에",
  "사람이",
  "진짜",


  "당신을",


  "예",
  "있을",
  "때문에",
  "위해",
  "겁니다",
  "계속",
  "얼마나",
  "않아",
  "사람",
  "제발",
  "안돼",
  "두",
  "그래요",
  "근데",
  "안녕",


  "하나",
  "몇",
  "거지",
  "있지",
  "수도",
  "많이",
  "집에",
  "좋아요",
  "아니에요",
  "아뇨",
  "거죠",
  "알았어",
  "그걸",
  "없는",
  "그렇지",
  "대해",
  "그가",
  "저기",
  "가서",
  "함께",
  "같아",
  "아빠",
  "가자",
  "말을",
  "했어",
  "와",
  "그러니까",
  "가장",
  "보고",
  "그만",
  "참",
  "돼요",
  "나한테",
  "항상",
  "일을",
  "뭐야",
  "음",
  "큰",
  "것도",
  "안녕하세요",
  "꼭",
  "그런데",
  "아무도",

  "뭐가",

  "아무것도",
  "니가",
  "저는",
  "일",
  "알아요",
  "괜찮아요",
  "몰라",
  "씨",
  "당신의",
  "만약",

  "있고",
  "절대",
  "많은",
  "말야",
  "아무",

  "그거",
  "하면",
  "그럴",
  "말이",
  "아니라",
  "그녀는",
  "사람은",
  "고마워요",
  "곧",
  "수가",
  "아니면",
  "말고",
  "했어요",
  "같아요",
  "잠깐",
  "갈",
  "맞아",
  "것을",
  "사람들이",
  "있다고",
  "거기",
  "말은",
  "그래도",
  "이미",

  "이봐",
  "온",
  "이걸",
  "가지고",
  "젠장",
  "뭐라고",
  "나를",
  "해야",
  "한번",
  "않아요",
  "뭔가",
  "않을",
  "거에요",
  "자기",
  "당장",
  "둘",
  "있다",
  "대한",
  "이젠",
  "그를",
  "봐요",
  "있죠",
  "아마",
  "선생님",

  "고마워",
  "시간",
  "여자",
  "전부",
  "그의",


  "여긴",
  "동안",
  "됐어",
  "사람을",
  "감사합니다",
  "먼저",
  "되는",
  "지금은",
  "있는데",
  "너도",

  "있잖아",
  "돈",
  "세상에",
  "볼",
  "어때",
  "없습니다",
  "절",
  "자네",
  "그녀가",
  "알았어요",
  "된",
  "번",
  "엄마가",
  "저도",
  "들어",
  "시간이",

  "얘기",
  "그들은",
  "아버지",
  "다들",
  "아니요",
  "일은",
  "합니다",
  "남자",
  "친구",
  "이리",
  "이상",
  "싶어",
  "줘",
  "수술",
  "본",
  "물론",
  "혼자",
  "있으면",
  "중",
  "내게",
  "미안해",
  "우릴",
  "싫어",
  "내일",
  "거라고",
  "안에",
  "니",
  "것이",
  "가는",
  "자네가",
  "그러면",
  "생각해",
  "나쁜",
  "주세요",
  "있었어",
  "작은",

  "가지",
  "그들이",
  "문제가",
  "순",
  "말도",

  "일이야",
  "것은",
  "사실",

  "그저",
  "말해",
  "마세요",


  "우리의",
  "그러니",
  "대체",
  "같은데",
  "있나",


  "아이",
  "다음",
  "사람들은",
  "필요",
  "가요",
  "완전",
  "거요",
  "언제",
  "전혀",
  "거의",
  "아는",
  "해도",
  "중요한",
  "나와",
  "적이",
  "나중에",
  "알",
  "너는",
  "미안해요",
  "생각",
  "새로운",
  "있었어요",
  "잠깐만",
  "방금",
  "것이다",
  "맞아요",
  "갖고",
  "벌써",
  "여보",
  "나랑",
  "아빠가",
  "하죠",
  "싶지",


  "아직도",
  "소리",

  "어디서",
  "없다",
  "적",
  "오래",
  "혹시",
  "조지",
  "나가",
  "너희",
  "몰라요",
  "해서",
  "아닙니다",
  "없이",
  "새",
  "누구",

  "그녀를",
  "맙소사",
  "있다는",
  "기다려",

  "환자",
  "수술을",
  "했는데",
  "죄송합니다",
  "위해서",
  "부인",
  "됐어요",
  "모르겠어",
  "차",
  "죄송해요",
  "왔어",
  "아니지",
  "살",
  "오늘은",
  "원하는",
  "죽을",
  "밖에",
  "양",
  "말이에요",
  "있나요",
  "와서",
  "돈을",
  "필요해",
  "마지막",
  "직접",
  "죽은",
  "말이지",
  "여기에",
  "완전히",
  "시간을",
  "알지",
  "매우",

  "올",
  "글쎄",
  "봤어",
  "서로",
  "것입니다",
  "짓을",
  "없지",
  "그레이",
  "메러디스",
  "도움이",
  "싶어요",


  "환자가",
  "여자가",
  "보면",
  "한다",
  "여보세요",
  "그렇죠",
  "왜요",
  "말이죠",
  "이지",
  "후",
  "건가요",
  "그녀의",
  "없고",
  "단지",
  "멋진",
  "하는데",
  "않고",
  "지난",
  "정말로",
  "밤",
  "제대로",
  "나의",
  "왜냐면",
  "조금",
  "있다면",

  "싶은",
  "신경",
  "아니고",
  "했지",
  "아닌",
  "베일리",
  "제일",
  "가고",
  "있는지",
  "위한",
  "도대체",
  "어때요",
  "때까지",
  "아이고",
  "우리를",
  "아저씨",
  "있어서",
  "데리고",
  "않았어",
  "어디로",
  "너한테",
  "어느",
  "사람들",
  "없을",

  "왔어요",
  "말아요",
  "되면",
  "않는",
  "아버지가",
  "모르겠어요",

  "뿐이야",
  "그러지",
  "거다",
  "말하지",
  "그것은",
  "전화",
  "이름이",
  "모르는",
  "꽤",
  "먹고",
  "여러분",
  "집",
  "보여",
  "않은",
  "미안",
  "이름은",
  "이번",
  "당연히",
  "있잖아요",
  "술",
  "보자",

  "왜냐하면",
  "않습니다",
  "그러나",
  "되지",
  "사람들을",
  "별로",
  "손",
  "보세요",
  "사는",
  "어머니",
  "대해서",
  "되고",
  "세",
  "딱",
  "아이를",

  "결국",
  "얘",
  "앞으로",
  "지",
  "사랑해",
  "않으면",

  "아침",
  "손을",
  "하세요",
  "생각을",
  "첫",

  "듣고",

  "집으로",
  "남자가",
  "처음",
  "안돼요",
  "오말리",
  "어쨌든",
  "말할",
  "여전히",
  "엄마는",
  "이야기",
  "친구가",
  "땐",
  "텐데",
  "대신",
  "당신도",
  "말하는",
  "놈이",
  "있게",
  "없다고",
  "걱정",
  "것처럼",
  "라고",
  "생각이",
  "알겠어요",
  "수는",
  "오빠",
  "가야",
  "언제나",

  "했죠",
  "필요한",
  "너의",
  "알겠습니다",
  "훨씬",
  "너를",
  "최고의",
  "거기서",
  "건데",
  "후에",
  "못해",
  "데",
  "놈",
  "두고",
  "사랑하는",
  "그때",
  "자넨",
  "없잖아",
  "가면",
  "보는",
  "매일",
  "년",
  "고맙습니다",
  "알았지",
  "뭔지",
  "뒤에",
  "씨발",
  "금방",
  "잡아",
  "살아",
  "크리스티나",
  "않았어요",
  "가진",
  "있네",
  "누구야",
  "하게",
  "엄청",
  "게다가",
  "너랑",
  "건가",
  "둘이",
  "누군가",
  "갑자기",
  "보지",
  "미친",
  "어쩌면",
  "어제",
  "해봐",
  "기분이",
  "유일한",
  "한다고",
  "얘기를",
  "따라",
  "확실히",
  "있어야",
  "당신과",
  "마치",
  "테니까",
  "는",
  "이봐요",
  "막",
  "너가",
  "집에서",
  "이상한",
  "스스로",
  "아이구",
  "환자를",
  "저를",
  "당신에게",
  "존",
  "문",
  "그들을",
  "자신을",
  "중에",
  "놔",
  "나에게",
  "필요해요",
  "알게",
  "아니죠",
  "아마도",
  "때가",
  "아이가",
  "서",
  "알렉스",
  "이름을",
  "여기가",
  "어이",
  "앉아",
  "됩니다",
  "조용히",
  "클라라",
  "사랑",
  "아니다",
  "애가",
  "자신의",
  "살고",
  "계세요",
  "했잖아",
  "가세요",
  "준비",
  "이유가",

  "그만해",
  "일단",
  "모두가",
  "잘못",
  "알겠어",
  "없었어",
  "적어도",
  "저녁",
  "대로",
  "아침에",
  "있소",
  "뭐하는",
  "죽일",
  "타고",

  "좋지",
  "눈을",
  "찾고",
  "뭐지",
  "입니다",
  "데릭",
  "늘",
  "거고",

  "머리",
  "없죠",
  "형",
  "눈",
  "좋아하는",

  "일어나",
  "애",
  "있지만",
  "원래",
  "아가씨",
  "알잖아",
  "과장님",
  "다음에",
  "뭔",
  "뭔데",
  "길을",
  "조심해",
  "찾을",
  "입",

  "뭐죠",
  "싫어요",
  "훌륭한",
  "기다리고",
  "잠깐만요",
  "잠시",
  "가끔",
  "일도",
  "뭐요",
  "밥",
  "여길",
  "봤어요",
  "요즘",
  "이것",
  "스티븐스",
  "없었어요",
  "줘요",
  "들어가",
  "그렇지만",
  "저희",
  "오는",
  "물",
  "마라",
  "있던",
  "여자를",
  "밤에",
  "생각엔",

  "만나서",
  "괜찮은",
  "정신",
  "망할",
  "앞에서",
  "문을",
  "방법을",


  "선생님이",
  "그럼요",
  "에",
  "하루",
  "거기에",
  "아까",
  "저기요",
  "아녜요",

  "생각하는",
  "괜찮을",
  "이번엔",
  "있었는데",
  "우리도",

  "옆에",
  "겁니까",
  "아님",
  "임마",
  "위에",
  "걸로",
  "천천히",

  "얘들아",
  "테니",
  "잘했어",
  "분명히",
  "말이다",
  "분",
  "찾아",
  "자기가",
  "만들어",
  "믿을",
  "알아서",
  "못할",
  "생각은",
  "아들",
  "있으니까",
  "앞에",
  "속에",
  "아버지는",
  "이럴",
  "그것을",
  "피터",
  "없는데",
  "그것도",
  "누군지",

  "잔",

  "그대로",
  "집을",
  "여자는",
  "차를",
  "아니라고",
  "남은",
  "아름다운",
  "버크",
  "들고",
  "죽었어",
  "자식",
  "간다",

  "있을까",
  "빌어먹을",
  "밖으로",
  "아니잖아",
  "그들의",
  "필요가",
  "좋을",
  "주고",
  "들었어",
  "가게",
  "말하고",
  "정도",
  "힘을",
  "방법이",
  "바보",
  "기분",


  "생각해요",
  "할머니",
  "같네요",
  "새끼",
  "여기는",
  "우리에게",
  "의사",
  "저런",
  "단",
  "의",
  "평생",
  "말입니다",
  "저희가",
  "했다",
  "번째",
  "하자",
  "먹어",
  "받아",
  "간",
  "영원히",
  "만든",
  "입고",
  "충분히",
  "언니",
  "돈이",
  "있었던",
  "받고",
  "먹을",
  "총",
  "될까요",
  "개",
  "얘길",
  "별",
  "되죠",
  "환자는",
  "만큼",
  "기회를",


  "맞지",


  "를",
  "준",
  "마음에",
  "있니",
  "서둘러",
  "애들",
  "역시",
  "있었다",
  "다시는",
  "정확히",
  "원",
  "말씀",
  "하나도",
  "끔찍한",
  "하려고",

  "주",
  "딴",
  "죽어",

  "그렇다면",
  "결혼",
  "엄마를",
  "애를",
  "했던",
  "머리를",
  "총을",
  "절대로",
  "나야",
  "무엇을",
  "얘야",
  "곳에",
  "보게",
  "일에",
  "수술에",
  "생각하지",
  "손에",
  "저게",
  "사람이야",
  "없다는",
  "어째서",
  "솔직히",
  "말라고",
  "약",
  "꿈을",
  "조금만",
  "된다",
  "뭐라고요",
  "役",
  "걔가",
  "오면",
  "아빠는",
  "멀리",

  "어딨어",
  "그리",
  "자꾸",
  "병원에",
  "있습니까",
  "뒤로",
  "있었죠",
  "문제",
  "있을까요",

  "전화를",
  "하지마",
  "할까",
  "잡고",
  "맘에",

  "얼마",
  "했습니다",
  "옷",
  "어린",
  "쉐퍼드",
  "받을",
  "더이상",
  "걔",
  "셋",
  "열심히",
  "수술이",
  "죽는",
  "멍청한",
  "굉장히",
  "분명",
  "하기",
  "관한",
  "사실은",


  "준비가",
  "거짓말",
  "제게",
  "제발요",
  "와요",
  "얼른",
  "삶을",
  "주는",

  "엄청난",
  "만들",
  "한잔",

  "병원",
  "점점",
  "여기로",
  "하는지",
  "죽인",
  "꽉",
  "그랬어",
  "누구도",
  "보니",
  "그에게",
  "갈게",
  "아세요",
  "멈춰",
  "보러",
  "했지만",
  "아무리",
  "어딜",
  "떨어져",
  "그렇다고",
  "일이에요",
  "쓸",
  "너와",
  "얼굴을",
  "비켜",
  "방에",

  "일찍",
  "오고",
  "같습니다",


  "되어",


  "놈들이",
  "필요는",
  "말한",
  "할지",
  "문제는",
  "자네는",
  "없네",

  "가만히",
  "아니오",
  "봐라",
  "세상을",
  "몸이",
  "잠시만요",
  "오지",
  "있었지",


  "알죠",
  "보내",

  "안녕히",
  "닥쳐",
  "힘든",
  "이해가",
  "아닌데",
  "학교",
  "이유는",
  "사랑해요",
  "가까이",
  "거라",
  "크게",
  "마음을",
  "치프",

  "놈을",
  "도와줘",

  "커피",
  "알았는데",
  "아기",

  "사람의",
  "짐",
  "통해",

  "뭐든",
  "말해봐",

  "좋겠어",


  "그래야",
  "걸요",
  "마요",
  "마지막으로",

  "줄게",

  "한다면",
  "그것",
  "죽게",
  "이름",
  "자주",
  "주지",
  "특별한",
  "사람도",
  "을",

  "저리",
  "최선을",
  "상황이",

  "될거야",
  "리처드",

  "이래",
  "아니었어",
  "아버지를",
  "만약에",
  "없지만",



  "모르고",
  "심장",
  "오세요",
  "뭐예요",
  "달",
  "있을거야",
  "자는",
  "정도로",
  "눈이",
  "한다는",
  "젊은",
  "알겠지",
  "목숨을",
  "좋았어",
  "떠날",
  "열",
  "보이는",
  "심장을",
  "내려",

  "아니예요",

  "피를",
  "가져와",
  "모르지",
  "어쩔",

  "의사가",
  "불러",
  "들어봐",
  "요",
  "있거든",
  "믿지",
  "형님",

  "걘",
  "자고",
  "끝났어",
  "분이",
  "또한",
  "저거",
  "사실을",
  "소리야",
  "쉽게",
  "남자는",
  "경찰",
  "약간",
  "아내가",
  "일이죠",
  "아기를",
  "진정해",
  "찾는",
  "누군가가",
  "오랫동안",
  "출혈이",
  "노래",
  "저한테",

  "애들이",
  "알았다",
  "갔다",
  "생각하고",
  "누굴",
  "못한",
  "겨우",
  "같은데요",
  "마음이",
  "하나가",
  "어차피",
  "보여요",
  "정말요",
  "나서",
  "다섯",
  "들었어요",
  "건지",
  "왔다",
  "얘기가",
  "가능한",
  "못해요",
  "그러고",
  "새끼야",

  "차에",
  "어디에",
  "할게",
  "만날",

  "뭔가를",
  "가만",
  "세상에서",
  "새로",

  "수정",


  "만한",
  "들어와",

  "믿어",
  "않게",

  "미스터",
  "때는",
  "모두들",
  "때도",
  "인생을",
  "된다고",
  "다신",
  "해야지",
  "꺼져",
  "놈은",
  "맞죠",
  "마음",
  "중이야",
  "지금까지",
  "글쎄요",
  "맞는",
  "해라",
  "남자를",
  "했고",
  "저건",
  "실례합니다",
  "특히",
  "어머",
  "그러는",
  "않았다",
  "거니",

  "현재",
  "얼굴",

  "남편이",
  "좋다",
  "이리와",
  "생긴",
  "말하면",

  "보다",
  "사랑을",
  "곳이",
  "눈에",
  "그랬지",
  "아들을",
  "옷을",
  "남편",
  "없어서",

  "심장이",
  "들어요",
  "예쁜",
  "숨을",
  "아니지만",

  "일로",
  "만난",
  "모르겠지만",
  "착한",
  "사이에",
  "많아",
  "얘기해",

  "진짜로",

  "진실을",
  "전하",

  "소리를",
  "기억",
  "선생님은",
  "기억이",
  "며칠",
  "네게",
  "할게요",
  "그려",
  "배",
  "갑시다",

  "사진",
  "딸",

  "아닌가",
  "뿐이에요",
  "녀석",
  "해줘",
  "그거야",
  "상관",

  "자리를",
  "써",

  "움직이지",
  "아나",
  "했을",
  "만드는",
  "잭",
  "당신한테",
  "됐다",
  "수술은",
  "문제야",
  "있으니",
  "나갈",
  "대",
  "쓰고",
  "잊지",
  "앉아서",
  "알지만",
  "가족을",
  "가족",
  "그날",
  "없으면",

  "여기요",
  "소리가",
  "됐지",
  "하니까",
  "시간은",

  "갖다",
  "없소",
  "이야기를",
  "있겠지",
  "이러지",
  "얼굴이",
  "않을거야",
  "죽고",
  "거잖아",

  "좋습니다",


  "순간",
  "있는거야",
  "카레프",
  "거냐",
  "곁에",
  "약을",
  "밖에서",
  "병원에서",
  "좋아해",
  "죽지",
  "환자의",
  "기다려요",
  "보통",
  "흠",
  "가져",

  "하는거야",
  "운이",
  "불쌍한",
  "속에서",
  "받은",
  "열어",
  "데이트",
  "방법은",
  "어찌",
  "알았죠",
  "될까",

  "완벽한",
  "돌아",

  "보기",
  "그것이",
  "피가",
  "죽었어요",
  "당연하지",
  "그걸로",
  "아들이",

  "놓고",
  "했다고",

  "너에게",
  "카레브",
  "할거야",
  "쓰는",
  "곳을",
  "몸",

  "뛰어",
  "설마",
  "일하는",

  "같다",
  "물론이죠",
  "이쪽으로",

  "몸을",
  "없잖아요",
  "외과",

  "똑같은",
  "박사님",

  "때마다",
  "샘",
  "아이는",
  "갔어",
  "못하게",
  "조",
  "거짓말을",
  "할까요",

  "둬",
  "짓이야",
  "심각한",
  "모습을",
  "되는데",
  "않는다",
  "그리곤",
  "우선",
  "방",
  "위험한",

  "자신이",
  "생명을",
  "놈의",
  "보이지",
  "것에",
  "음악",
  "간에",
  "쓰지",
  "해주세요",
  "봤지",
  "그러죠",
  "그동안",
  "검사",
  "어려운",
  "못하는",

  "압니다",
  "잠시만",
  "있는데요",
  "다리",
  "한번도",

  "진정한",
  "저렇게",
  "대단한",

  "화가",
  "죽이고",
  "하잖아",
  "같지",
  "타",
  "친구들",
  "만들고",
  "명",
  "기회가",
  "차라리",
  "에이",
  "빼고",
  "도움을",
  "하나만",
  "쟤",
  "움직여",
  "가족이",
  "척",
  "너희들",
  "경우",
  "좋겠어요",
  "않아도",

  "아니잖아요",
  "믿고",
  "자막",

  "미국",
  "신이",
  "바랍니다",

  "다리를",
  "데려다",

  "위대한",
  "있기",
  "산",
  "안에서",
  "얘가",

  "일이지",
  "정보를",
  "드디어",
  "하실",
  "짓",
  "헤이",
  "그랬어요",
  "어머니가",
  "드릴게요",
  "몰라도",
  "하면서",
  "도와줄",
  "거란",

  "도와",
  "달라고",
  "곳으로",
  "지금도",
  "있도록",
  "반드시",
  "길",
  "나가서",
  "하라고",
  "거대한",
  "날이",
  "모를",
  "녀석이",
  "결정을",
  "하",
  "언젠가",
  "이해",
  "식으로",
  "싶으면",
  "아내를",
  "엄마한테",
  "나면",
  "프랭크",
  "되잖아",
  "했잖아요",
  "덕분에",
  "할수",
  "머리가",
  "봐봐",
  "해줄",

  "돌아올",

  "세상",

  "느낌이",
  "끝까지",
  "이쪽은",
  "거니까",
  "계획이",
  "갔어요",
  "여러",
  "딸이",


  "봐야",
  "그렇고",
  "막을",

  "세상이",

  "죽여",
  "칼",
  "박사",
  "최대한",
  "돌아가",
  "필요할",
  "얘기는",
  "와우",
  "거긴",
  "저희는",
  "도울",
  "할아버지",
  "시작",
  "이것은",
  "처럼",
  "결코",
  "같군요",
  "숨",
  "원하지",
  "하니",
  "나온",
  "옛날",


  "너네",
  "했는지",
  "동생",
  "오직",
  "죽으면",
  "내버려",
  "원해",
  "마시고",
  "왔습니다",
  "폐하",
  "아이들을",
  "경찰이",
  "것보다",
  "돌아갈",
  "미리",
  "애는",

  "냄새가",

  "종일",
  "없다면",
  "가질",
  "필",
  "가도",
  "아유",
  "목을",
  "진심으로",
  "가봐",
  "않나",
  "빠져",
  "우리한테",
  "갈게요",
  "아가",
  "모두를",
  "아빠를",
  "만",
  "뒤",
  "계획을",
  "닉",
  "달라",
  "그렇습니다",
  "머리에",
  "이라고",
  "이유를",
  "그렇군요",
  "딸을",
  "살인",
  "남의",
  "번도",
  "잘못된",

  "사람들에게",
  "자네도",
  "먹는",

  "고맙다",
  "문제를",
  "씨가",
  "저랑",
  "조니",
  "아픈",
  "책",
  "알다시피",
  "사진을",
  "조지가",
  "사고",
  "혼자서",
  "친구를",
  "나은",
  "마십시오",
  "걱정하지",
  "뭐라",
  "상관없어",
  "쓴",
  "같아서",
  "일어난",
  "하네",
  "자리에",
  "고맙네",
  "돈은",

  "안으로",
  "느낄",
  "말이오",
  "명이",
  "없나요",
  "고통을",
  "놈들",
  "마크",
  "적은",
  "그녀",
  "시간에",
  "이만",
  "있거든요",
  "내내",
  "긴",
  "곳은",
  "나오는",
  "잡을",
  "되요",
  "메리",
  "좋아해요",
  "하나는",
  "좋아하지",
  "얘기하고",
  "친한",
  "여기까지",
  "알아야",
  "있네요",
  "일어날",
  "버려",
  "사랑에",
  "싶어서",
  "실수를",
  "아저씨가",
  "곳에서",
  "만나",
  "같네",
  "사건",
  "크리스마스",
  "물론이지",
  "자기야",
  "없었다",
  "들을",
  "애야",
  "배를",

  "셰퍼드",
  "심지어",
  "실은",
  "오늘밤",
  "뭐해",
  "올게",
  "덜",
  "분은",
  "넣어",


  "아무튼",
  "몰랐어요",
  "있었습니다",
  "아니었어요",
  "말했어",
  "구할",
  "전엔",
  "정신이",
  "있었고",
  "손이",
  "도와줘요",
  "주면",
  "미안하지만",
  "당신들",
  "팔",
  "모든게",
  "생각할",
  "모든걸",
  "들려",
  "받는",
  "부인이",
  "영화",

  "죽이지",
  "죽이는",
  "자식이",
  "기다리는",
  "강한",
  "힘이",
  "되나요",
  "비행기",
  "슬론",
  "그러세요",
  "내겐",
  "나올",
  "자넬",
  "생각도",
  "그러게",
  "근처에",
  "같군",
  "하나를",
  "모르지만",
  "때문이야",
  "전쟁을",
  "아파",

  "왔는데",
  "말해줘",
  "먼",
  "똑바로",
  "몸에",

  "상처를",
  "온다",
  "없네요",
  "원치",
  "몰랐어",
  "않지",
  "처음부터",
  "대니",
  "것과",


  "모르겠네요",

  "돌려",
  "길이",
  "원한다면",
  "채",
  "들어가서",
  "인간",
  "중입니다",
  "셔먼",
  "있단",

  "미쳤어",
  "받지",

  "보자고",
  "있겠어",
  "신의",
  "가능성이",
  "엄마랑",
  "어젯밤",
  "따라와",
  "이후로",
  "그들",
  "실제로",
  "가라",
  "상황을",
  "차가",
  "쪽으로",
  "지도",
  "팀",
  "도와주세요",
  "더러운",
  "그러다",
  "몰래",
  "하루종일",
  "그땐",
  "장",
  "피",
  "돼서",
  "가끔은",
  "대화를",
  "밤새",
  "애들을",

  "검사를",
  "어서요",
  "아기가",
  "맨날",
  "리",
  "관심",
  "처음으로",
  "모르겠다",
  "비밀을",
  "라는",
  "않죠",

  "즉시",
  "행운을",

  "보낸",

  "못하고",
  "물을",

  "한데",

  "얘긴",
  "미안하다",
  "약속을",
  "아무래도",
  "개가",
  "좋은데",
  "줄은",
  "캣니스",
  "인간이",

  "얻을",
  "입을",
  "아버지의",
  "아래",
  "것만",
  "상태가",
  "배가",
  "수술실에",
  "생각했어",
  "과장님이",
  "괜히",
  "모른다",
  "적도",

  "거라면",
  "위로",
  "봅시다",
  "좀더",
  "없으니까",
  "중이에요",
  "믿어요",
  "아직은",
  "치료를",

  "사람과",
  "걸어",
  "같애",
  "싶은데",
  "향해",
  "쉬운",
  "이지가",

  "뭐든지",
  "가르쳐",
  "그치만",
  "걸린",
  "당신들이",
  "간다고",
  "생일",
  "따르면",
  "볼까",


  "행복한",
  "무서운",
  "안전한",
  "다음엔",
  "또는",
  "어렸을",
  "결과를",
  "싶었어",
  "했나",
  "은",
  "버크가",
  "죄송하지만",
  "및",
  "그녀에게",
  "되겠지",

  "그치",
  "보죠",
  "남편은",
  "봤는데",
  "주십시오",
  "확인",
  "이리로",
  "밑에",
  "고",
  "검은",
  "있었는지",
  "드릴까요",
  "들어갈",
  "되게",
  "개를",
  "아닐",
  "자네를",

  "어젯밤에",
  "비밀",
  "있겠죠",
  "그런거",
  "전에는",
  "있단다",

  "못했어",
  "찾아야",

  "갑니다",
  "오른쪽",
  "이놈아",
  "된다는",
  "말했잖아",
  "이해할",
  "오신",
  "이제는",
  "예전에",
  "걸세",
  "하던",
  "준비해",
  "있군",
  "담배",
  "얘는",
  "미안합니다",
  "있다고요",
  "한거야",
  "리가",
  "이곳에",
  "하겠습니다",
  "이동",
  "모르죠",
  "감히",
  "많아요",
  "죽였어",
  "배포",
  "전해",
  "사람에게",
  "떠나",
  "걸릴",
  "바람",
  "좋네요",

  "즐거운",
  "찾았어",
  "뭐라구요",
  "내놔",

  "빨간",
  "어떡해",
  "바라는",
  "개의",
  "왼쪽",
  "것으로",
  "너희가",

  "감옥에",
  "소식을",
  "불",
  "손으로",
  "게임",
  "포",
  "애들은",
  "안나",
  "너희들은",

  "드릴",
  "누군가를",
  "감정을",
  "맞다",
  "하나의",

  "나가요",
  "언제부터",
  "들은",
  "루이",
  "왓슨",
  "무서워",
  "얼굴에",
  "지금부터",
  "로",
  "가자고",
  "비가",
  "기억해",
  "때부터",
  "최악의",
  "관심이",
  "즉",
  "이러는",



  "따로",
  "없군",
  "왔지",
  "맥스",
  "팔을",
  "싶을",
  "않지만",

  "법을",
  "데려가",
  "죽기",
  "누구죠",
  "인간의",
  "높은",
  "뜻이야",
  "있고요",
  "봐도",
  "뭔데요",

  "질문",
  "하나요",
  "가까운",
  "나무",
  "암",
  "씨는",


  "인턴",


  "제기랄",
  "결과가",
  "필요합니다",
  "좋게",

  "얘기할",
  "보낼",
  "데릭이",
  "물이",
  "제임스",
  "의해",
  "보니까",
  "계신",
  "없나",
  "잘못이",
  "찾았어요",
  "나요",
  "마이크",
  "형이",
  "오빠가",
  "무척",

  "내말은",

  "하러",
  "일하고",
  "됐습니다",
  "만세",
  "질문을",
  "술을",
  "섹스",
  "잘하고",
  "것들을",
  "집이",
  "끌고",
  "물건을",


  "늙은",
  "남아",

  "학교에서",
  "자격이",
  "자식아",
  "결혼을",
  "첫번째",

  "어디야",
  "선물을",
  "친구는",
  "정말이야",
  "있어도",
  "있는게",
  "도",
  "반가워요",
  "끝난",
  "괜찮지",
  "싶었어요",
  "알렌",
  "맘대로",

  "있다가",
  "걸까",
  "보라고",
  "거잖아요",
  "줄까",
  "정도는",
  "엄마도",
  "아니라면",
  "자네의",
  "아파요",
  "처음엔",
  "거여",
  "나머지",
  "좋죠",
  "이길",
  "뭡니까",
  "생각합니다",
  "갈까",
  "이걸로",
  "됐죠",
  "발",
  "이상해",
  "점심",
  "붙어",

  "하셨어요",
  "음식을",
  "그와",
  "연락",
  "오케이",

  "오늘이",

  "끝나면",
  "우리와",
  "더는",
  "당연하죠",
  "가야지",
  "아내",
  "사람이에요",
  "가기",
  "마침내",
  "다음은",
  "해줘요",
  "나만",
  "마약",
  "깊은",
  "아시죠",
  "무기를",
  "환영합니다",
  "누구지",
  "출처를",

  "웬",
  "죽음을",
  "삼촌",
  "말인지",
  "가슴",

  "힘들어",
  "곳",
  "말로",
  "올게요",
  "나보다",
  "않나요",
  "생겼어",
  "수술실에서",
  "않았습니다",

  "똑똑한",
  "말해야",
  "찰리",
  "세상은",
  "쟤가",
  "생각했는데",
  "놈들은",
  "발사",

  "귀여운",
  "톰",
  "이따",
  "스튜",
  "소릴",

  "아주머니",
  "미래를",

  "입은",
  "만나고",
  "든",

  "치프가",
  "아휴",
  "싸울",
  "아이들이",
  "별거",
  "수밖에",
  "뭐냐",
  "쪽",
  "찾지",
  "평범한",
  "사람한테",

  "세계",
  "것들",
  "여기선",
  "친구야",
  "전에도",
  "셜록",
  "싱크",
  "너무나",
  "배포시",
  "괜찮습니다",
  "갈래",
  "섹스를",
  "났어",
  "보내고",
  "나에",
  "죄를",
  "확인해",
  "나가는",
  "불을",
  "모릅니다",
  "확실해",
  "뜻은",
  "친구들이",
  "사건을",
  "싶습니다",
  "형은",
  "종양이",
  "말게",
  "잠을",
  "명의",
  "생각에",
  "사랑은",
  "테디",
  "아빤",
  "있겠어요",
  "됐네",
  "녀석은",
  "오래된",
  "능력을",
  "미란다",
  "올려",


  "이지는",
  "아기는",
  "마셔",
  "위치를",
  "데니",
  "베일리가",
  "줘야",

  "선을",
  "싸우는",
  "만나는",
  "말하자면",
  "번역",
  "믿는",

  "이것이",
  "이해해",
  "뭔가가",
  "찾아서",
  "피바디",
  "맞았어",
  "있을지",
  "대부분",
  "그들에게",

  "자리",
  "끝났어요",
  "저에게",
  "누구나",
  "상처",
  "전까지",
  "걔는",
  "아는데",
  "꼬마",
  "반",
  "뿐이지",
  "환자에게",
  "말이여",
  "아니라는",
  "누구요",
  "뭐하고",
  "동시에",

  "좋겠다",
  "어떤가",
  "받았어요",
  "떨어진",
  "다야",
  "렉시",
  "사장님",
  "싸우고",
  "오후",
  "침대",



  "서로를",
  "숨어",
  "부르지",

  "꽤나",
  "등",
  "위험해",


  "잘됐네",
  "새끼가",
  "가방",
  "발을",
  "너희들이",
  "캡틴",
  "앤디",
  "레지던트",
  "레이",
  "돌아온",
  "부터",
  "쏴",
  "약간의",
  "필요로",
  "책을",
  "명령을",
  "아내는",
  "메러디스가",

  "애디슨",
  "물어",
  "선생",
  "번만",

  "옳지",
  "않아서",
  "인생은",
  "왜죠",
  "시애틀",
  "안다고",
  "돌아와",
  "낼",
  "하나님",
  "꿈",
  "말했지",
  "워",
  "아야",
  "왕이",
  "아예",
  "말하길",
  "맥주",
  "서서",
  "않는다면",

  "말인데",
  "방으로",
  "달려",
  "녀석을",
  "내려놔",
  "똑같이",
  "여행",
  "하느님",
  "가치가",

  "뭐하는거야",
  "유명한",
  "누구든",
  "듣지",
  "놈들을",
  "혈액",
  "카메라",
  "버리고",
  "생각이야",
  "이야",

  "결과",
  "아버진",
  "엎드려",
  "지구",
  "않네",
  "맡아",
  "물어봐",
  "하셨죠",
  "밤을",
  "드세요",
  "어디가",
  "하겠어",
  "함",
  "때문이지",
  "그랬죠",
  "받았어",

  "전까진",

  "예전",
  "행동을",
  "군",
  "생각하면",

  "번이나",

  "넣고",
  "없으니",
  "돈도",
  "있으세요",
  "잠이",
  "수술실",

  "경찰에",
  "칼을",
  "나이",
  "행복하게",
  "오게",
  "듯",
  "시체를",
  "나보고",
  "떠나지",
  "하려는",
  "아니니까",
  "부르는",
  "싶다면",
  "저요",
  "말로는",
  "현지어",
  "만나게",
  "보이는데",
  "이번에",
  "없게",
  "오히려",
  "그렇겠지",
  "그만해요",
  "바꿀",
  "사랑한다고",
  "괜찮으세요",
  "남편을",

  "의미가",
  "아서",
  "견딜",
  "자네에게",
  "것들이",
  "학교에",
  "모자",
  "거군요",
  "여자의",
  "갈거야",
  "더욱",
  "루디",
  "해봐요",
  "노래를",
  "선생님을",
  "자가",
  "사람들의",
  "소식이",
  "편지를",
  "일부러",
  "세게",

  "말했어요",
  "좋네",
  "시작해",
  "거였어",
  "전쟁",
  "프랑스",
  "비록",
  "기다릴",
  "들었는데",

  "없이는",
  "엄청나게",
  "혈압이",
  "여자랑",
  "들어봐요",
  "모두에게",
  "밝혀주세요",
  "인생이",
  "사",
  "걱정할",
  "아이의",
  "빠진",
  "된다면",
  "축하해",

  "데가",
  "아니란",
  "정신을",
  "않다",
  "배리",
  "쉴",
  "어떻게든",
  "언니가",
  "생각했어요",
  "최소한",
  "혹은",
  "대해선",
  "달이",
  "이제야",
  "삶의",
  "이대로",
  "하긴",
  "좋다고",
  "인생",
  "예를",

  "문이",

  "어떤지",
  "파티",
  "손님",
  "수술실로",
  "쉽지",

  "그만둬",
  "방에서",
  "일인지",
  "끝이야",
  "알잖아요",
  "종류의",
  "둘다",
  "하는게",
  "수많은",
  "어쩌다",
  "파티에",
  "고작",
  "알어",
  "떠난",
  "누구한테",
  "간단한",
  "위험을",
  "자를",
  "벌",
  "나와서",
  "불이",
  "태어난",
  "할래",
  "바닥에",
  "개인",
  "냄새",
  "싶진",
  "관계를",
  "우리랑",
  "기억을",
  "모르겠네",
  "같구나",
  "스타크",
  "폴",
  "돌아오지",
  "요새",
  "질",
  "생각해봐",
  "마찬가지야",
  "뿐",

  "뿐입니다",
  "잡았어",
  "울지",
  "침대에",
  "아가야",
  "합시다",
  "늦었어",
  "오라고",
  "살릴",
  "사실이",
  "쳐",
  "거라는",
  "회사",
  "대학",
  "만나러",
  "선물",
  "돼지",
  "안되",
  "신발",
  "못하면",
  "잃은",
  "사랑이",
  "엘리엇",

  "옳은",
  "빠르게",
  "아닌가요",
  "쉿",
  "우주의",
  "화를",

  "고맙소",

  "게임을",
  "아무런",
  "벤",
  "이거야",
  "따라서",
  "생기면",
  "크고",
  "데이브",
  "마리",
  "도시",
  "보입니다",

  "끝날",
  "않소",
  "때문이죠",
  "조심해요",
  "나이가",
  "뒤에서",
  "삶은",
  "있었다고",
  "코트니",
  "오셨어요",
  "춤을",
  "말해요",
  "같으니",
  "제이",
  "죽이려고",
  "해본",
  "프레스턴",
  "에서",
  "좋고",
  "동네",

  "엄만",

  "결혼식",

  "둘은",
  "받아야",
  "신",
  "봤죠",
  "죽어가고",
  "쉬어",
  "당신들은",
  "식사",
  "때문이다",
  "위하여",
  "오랜",
  "저녁에",
  "올라가",
  "우와",
  "안됩니다",

  "사고가",
  "대부분의",
  "정보",
  "기억나",
  "탈",


  "들려요",
  "안다",
  "간호사",
  "약속",
  "완벽하게",
  "줄래",

  "일주일",
  "형사님",
  "행복해",

  "메러디스는",
  "최근에",
  "수정금지",
  "영향을",
  "여기야",
  "속",
  "놀라운",
  "몰리",
  "일어나는",
  "원해요",
  "중국",
  "이것도",

  "이러면",
  "잤어",
  "무슨일이",
  "감사해요",
  "반갑습니다",
  "하루에",
  "재미",
  "계속해서",
  "양반",
  "아닙니까",
  "열쇠",
  "무기",
  "하도록",
  "자유",

  "모른다고",

  "있어서요",
  "빠른",
  "저와",
  "멈출",
  "가득",
  "빈",
  "되겠죠",
  "지금이",

  "말씀을",
  "뻔",
  "꿈이",
  "그런가",

  "들",
  "전쟁이",
  "밑에서",
  "브루스",
  "토레스",
  "아래로",
  "누구세요",
  "이러고",
  "침대에서",
  "뿐이죠",
  "부탁",
  "위에서",
  "금지",

  "싶다고",
  "기꺼이",

  "비슷한",
  "충분한",
  "알면",
  "모르겠는데",
  "한참",
  "스스로를",
  "전부터",
  "병이",
  "않잖아",
  "하얀",
  "차로",
  "경",
  "김",
  "어머나",
  "거라고요",
  "우주",
  "정말이지",
  "특별히",
  "보일",

  "엄마의",
  "메이브",
  "돕고",
  "데려와",
  "사람들도",
  "롭",
  "마음대로",
  "가슴을",
  "아줌마",
  "고칠",
  "늦게",
  "못했어요",
  "있었을",
  "너흰",
  "러시아",
  "아니겠지",

  "맞습니다",
  "사랑하지",
  "굉장한",

  "필립",
  "확",
  "왔을",
  "호텔",
  "약속해",
  "않습니까",
  "집은",

  "누구랑",
  "준비를",
  "않을까",
  "하거든요",
  "살아있는",
  "더그",
  "가져다",
  "뭐라도",
  "나하고",
  "부모님이",
  "다니는",
  "하시는",
  "인생의",
  "마담",
  "피해",
  "되나",
  "두려워",
  "됐나",
  "나을",
  "중요하지",
  "줬어요",
  "프랭키",

  "바람이",
  "화장실",
  "않았지",
  "백",
  "용의",
  "어떠세요",
  "누워",
  "지내",
  "여자야",
  "부인은",
  "안되요",
  "않았을",
  "듣는",
  "가시죠",
  "봐서",
  "뭐에요",
  "쇼",
  "영국",
  "여지가",

  "길에",
  "저걸",
  "않겠어",
  "삶이",
  "아버님",
  "이를",
  "편지",
  "생각한",

  "효과가",
  "준비됐어",
  "했으면",
  "엄니",
  "않니",
  "힘",
  "만들었어",
  "버릴",

  "앤",
  "안그래",
  "물건",
  "틀렸어",
  "되기",
  "필요도",
  "제길",
  "그림",
  "뭘요",
  "아빠한테",
  "있긴",
  "뒤를",
  "사랑하고",
  "그렇군",
  "복잡한",
  "부를",
  "여행을",
  "필요없어",
  "전화해",
  "종양을",
  "그러는데",
  "장기",
  "필요하면",
  "했네",
  "전화가",
  "만에",
  "안해",
  "일종의",
  "남았어",
  "세상의",

  "가봐야",
  "동생이",

  "물러서",

  "치료",
  "모습이",
  "건배",
  "빌어",
  "하자고",
  "왔네",
  "전체",
  "지가",
  "임무를",

  "어릴",
  "답을",
  "만들지",
  "잃고",
  "주셔서",
  "에이미",
  "두번째",
  "때문입니다",
  "내고",
  "알려줘",
  "니들",
  "도시를",
  "주에",
  "말아",

  "가라고",

  "끝나고",
  "비행기를",

  "대신에",
  "땅에",
  "편이",
  "미안하네",

  "안되는",

  "씨를",
  "끝이",
  "인생에서",
  "서울",
  "재밌는",
  "말만",
  "듣기",
  "얜",
  "진짜야",
  "될지",
  "말해줘요",
  "편하게",

  "어머니는",
  "만일",
  "각자",
  "까지",
  "남자들은",
  "않았죠",
  "해낼",
  "치워",
  "관심을",
  "일할",
  "해냈어",
  "몸은",
  "차는",
  "들어가면",
  "잠",
  "하거든",
  "천",
  "나한텐",
  "일본",
  "없었지",
  "동무",
  "겠어",
  "공",
  "강력한",
  "같아서요",
  "앉으세요",
  "한번만",
  "말에",
  "치고",
  "어머니를",
  "자신",
  "남을",
  "놈아",
  "있군요",
  "부분을",
  "어두운",
  "감독",
  "이후",
  "토미",

  "저들이",
  "먹으면",
  "있다구요",
  "없거든",
  "울고",
  "사건이",
  "윌리엄",
  "차에서",
  "개인적인",
  "나와요",
  "희망을",
  "말인가",
  "줬어",
  "언젠간",
  "보인다",
  "컴퓨터",
  "사망",
  "속의",
  "와라",
  "있었잖아",
  "되니까",
  "생각보다",
  "뜻이죠",
  "멋져",
  "쏘지",
  "해결할",
  "먹은",
  "찾은",
  "쭉",
  "임신",
  "시작할",
  "누군가는",
  "상당히",
  "년이",
  "안전하게",
  "홀로",

  "새벽",
  "헨리",
  "정도면",

  "불러요",
  "많지",
  "거네",
  "하더군요",
  "할머니가",
  "낫지",
  "헌트",
  "최근",
  "나쁘지",
  "달리",
  "너야",
  "눈으로",
  "알고있다",
  "하잖아요",
  "뭐니",
  "보여줘",
  "보스",
  "하다니",
  "너희는",
  "미안한데",
  "받게",
  "저의",
  "얘기하는",
  "살게",
  "알지도",
  "마음은",
  "배운",

  "줘봐",
  "사실이야",

  "있었나",
  "나머지는",
  "않겠어요",
  "무릎",
  "됐는데",
  "크리스",
  "건물",
  "참말로",
  "맛이",
  "말하기",
  "하겠어요",
  "팀이",
  "요원",
  "언젠가는",
  "소식은",
  "차려",

  "아니거든",
  "부모님은",

  "방을",
  "고양이",
  "뇌",
  "있대",

  "윌",

  "이식",
  "이상의",
  "자세히",
  "마을",
  "심하게",
  "왕",

  "살아요",

  "뉴욕",
  "없는데요",
  "뜻이",
  "걸려",
  "멈추지",

  "다만",
  "받으면",
  "만나면",
  "수있는",
  "돌아가는",
  "이유",
  "엉덩이",

  "해서요",
  "내일은",
  "선택을",
  "되잖아요",

  "어떨까",
  "최고",
  "그런게",
  "스티브",
  "죽이려",
  "내로",
  "상대가",
  "함부로",
  "확실하게",

  "소중한",
  "여자와",
  "대고",
  "죽어도",
  "상처가",
  "일곱",
  "남자와",
  "속이",
  "괴물이",
  "맨",
  "되겠어",
  "해가",
  "도로",
  "달에",
  "없었죠",
  "시간도",
  "음식",
  "귀찮게",

  "쓰레기",
  "싫어하는",
  "커다란",
  "굳이",
  "의사를",
  "지킬",
  "이름으로",


  "남자랑",
  "높이",
  "데려",
  "꼼짝",
  "조심",
  "여성",
  "나오지",
  "런던",
  "독일",
  "열고",
  "건데요",
  "구멍을",
  "똥",
  "말했다",
  "너희를",


  "매번",
  "지켜",
  "훌륭해",
  "허",
  "했소",
  "벗어",
  "정신과",
  "맞을",
  "점이",
  "먹어요",
  "수술할",
  "말아라",
  "전화해서",
  "장난",
  "찾기",

  "속도를",
  "키스",
  "가려고",
  "죽여라",
  "부인을",
  "관련된",
  "야야",
  "아가씨가",
  "들지",
  "여자들은",
  "이틀",
  "하든",
  "여잔",
  "오기",
  "열쇠를",

  "웃음",
  "시키는",
  "나가야",
  "나가면",

  "니네",
  "데서",
  "바람에",
  "데리러",
  "중에서",
  "완료",

  "바",
  "와봐",
  "아닌데요",
  "남자의",
  "신경을",

  "핑크",
  "모르겠습니다",

  "질문에",
  "쿠퍼",
  "누나",
  "말해봐요",

  "클리어",

  "안했어",
  "손은",
  "누군데",

  "조차",

  "어딨어요",
  "불가능한",

  "자식을",

  "선택의",
  "나왔어요",
  "꺼",
  "영화를",
  "여섯",
  "경찰은",
  "그야",
  "단순한",
  "어느날",
  "땅을",
  "찾으러",
  "읽을",
  "나처럼",
  "찰스",
  "많고",
  "시험",
  "타는",
  "움직일",
  "달린",
  "뭐가요",
  "말했죠",
  "뭣",
  "다녀",

  "달러",
  "짧은",

  "전쟁은",
  "피타",
  "손님이",
  "엄마와",
  "만지지",
  "엿",
  "전기",
  "슬픈",
  "짓은",
  "입에",
  "아따",
  "잘난",

  "생각하세요",
  "때를",
  "무엇이",
  "각",
  "하네요",
  "물어볼",

  "경찰을",
  "부탁해요",
  "잃을",
  "자요",
  "테고",
  "봤을",
  "인간은",
  "동생을",
  "오후에",
  "아이들은",
  "총알이",
  "있대요",
  "안녕하십니까",
  "아스나",

  "잘됐네요",
  "무언가",
  "먹으러",
  "방법",
  "구",
  "줘서",
  "수치다",
  "잘해",
  "가져가",
  "코드",
  "이제부터",
  "있었나요",
  "없단",
  "해리",
  "거는",
  "막아",
  "일어나서",
  "소녀",
  "몰라서",
  "바래",
  "멈춰요",
  "할아버지가",
  "살짝",
  "일하러",
  "가짜",
  "시",
  "살면서",
  "총에",

  "조지는",
  "왕의",

  "병",
  "나왔어",
  "다물어",
  "일만",
  "당시",
  "밥을",
  "매기",

  "은하",
  "제이크",
  "좋아할",
  "알려",
  "운명을",
  "않는다고",
  "필요하다고",
  "아들은",
  "있구나",
  "찍어",
  "결국엔",
  "어쩌지",
  "혀",
  "다리가",
  "내는",
  "마실",
  "이년아",
  "거란다",
  "싶네요",
  "짐을",
  "죽었다고",
  "시체",
  "좋았어요",
  "생겼어요",
  "레이첼",
  "살려",
  "테러",
  "상관없어요",
  "댄스",
  "목에",
  "조엘",
  "촬영",
  "이놈",
  "여태",

  "무엇",
  "죽어요",
  "바비",
  "않았나",
  "일인데",
  "말예요",
  "아우",
  "얘기도",
  "없단다",
  "복수를",
  "점",
  "명은",
  "반가워",
  "춤",
  "이상은",
  "않네요",
  "그니까",
  "관해",
  "등을",
  "앉아요",
  "해야할",
  "마을을",
  "온다고",
  "왔나",
  "허나",

  "하늘을",



  "끝",
  "실제",
  "진",


  "일들을",
  "있을지도",
  "가죠",
  "사람이랑",
  "둘이서",
  "텐데요",
  "바람을",
  "낸시",
  "과연",
  "어쩌고",

  "깊이",
  "다치게",
  "작년에",

  "여러분이",
  "계속해",
  "와인",
  "풀어",
  "빚을",
  "있으니까요",
  "부탁해",

  "누군가의",

  "도움",

  "말해서",
  "그래서요",
  "되었습니다",
  "고든",
  "이사",
  "테스트",
  "사건에",
  "말입니까",
  "염려",
  "싶지만",

  "힘들게",
  "옛날에",
  "조금이라도",
  "아빠도",
  "다르게",
  "증거가",
  "에그시",
  "상황",
  "거면",
  "전체가",
  "먹었어",
  "주시겠어요",
  "꼴을",
  "진정",
  "좋아서",
  "구해",
  "아프게",
  "때문이",
  "지키고",
  "어째",
  "어떻게요",
  "양이",
  "자동차",
  "바보야",
  "없군요",
  "맞춰",
  "이쪽",
  "용감한",
  "키스를",
  "군대를",
  "인사",
  "시간이야",
  "나가고",

  "치즈",
  "말이요",
  "오냐",
  "들어와요",
  "드리죠",
  "괜찮",
  "그에",
  "가방을",
  "정확한",

  "조만간",
  "않다고",
  "맞고",

  "연락이",

  "뛰어난",
  "찾으면",
  "칠",
  "파티를",
  "폭탄",
  "속으로",
  "가족은",
  "건드리지",
  "편히",
  "된거야",
  "나라면",

  "계획은",

  "메시지를",
  "있다구",
  "느끼는",
  "해야만",
  "괜찮다고",
  "됐네요",
  "바래요",
  "아니네",
  "죽었을",
  "생활을",
  "돌아가서",
  "행크",
  "보기엔",
  "책임이",
  "크리스티나가",
  "엉덩이를",
  "보려고",
  "나리",
  "쉬는",
  "옆에서",
  "분을",
  "되는거야",
  "챙겨",
  "제이콥",
  "지금쯤",

  "역할을",
  "깜짝",
  "한가지",
  "부모님",

  "맛있는",
  "싶다",
  "사건의",
  "상태로",
  "있냐",

  "걱정은",

  "제작",
  "물에",
  "버크는",
  "무서워요",
  "있었으면",

  "애나",
  "있는거",
  "헬렌",
  "위험에",
  "알기",
  "미스",
  "어이구",
  "브라이스",
  "작업을",
  "상황은",
  "위험해요",
  "상황에",
  "봅니다",
  "나가자",


  "말해주지",
  "나무가",
  "때문에요",
  "묻지",
  "먹지",
  "가슴에",
  "느낌",
  "말일세",
  "저도요",
  "사람들과",
  "제니",
  "연락을",
  "대위",
  "질문이",
  "들어라",
  "부탁이야",
  "하시죠",
  "인제",
  "법",
  "그런건",
  "밴드",
  "그렇구나",
  "길은",
  "병을",
  "아시잖아요",
  "아무나",
  "없음",

  "인해",
  "이곳",
  "손가락을",
  "지는",
  "기술을",

  "받아요",
  "뭐라구",
  "우는",



  "주무세요",
  "진정하세요",
  "우리에겐",
  "보이네요",
  "줄을",
  "사라진",
  "듯이",
  "들어서",
  "어땠어",
  "필요하지",
  "마이클",
  "사랑의",
  "새끼들",
  "들어온",
  "한이",
  "옛",
  "터질",
  "그곳에",
  "전체를",
  "먹자",
  "흥미로운",
  "카드",
  "증거를",
  "봤다고",
  "조금씩",
  "때려",
  "사적인",
  "보이네",
  "생각에는",

  "병원으로",
  "말고요",
  "피곤해",
  "한대",
  "어딨지",
  "일들이",
  "친구들은",
  "주로",
  "가슴이",
  "되었다",
  "몽고메리",

  "똑같아",
  "걸고",
  "공주님",
  "바꿔",
  "가야겠어",
  "넷",
  "성",
  "통해서",
  "나간",

  "없었습니다",
  "서류",


  "그래야지",
  "왔다고",
  "들어올",

  "했거든요",
  "목소리가",
  "상황에서",
  "아요",

  "기억하지",
  "나가지",
  "죽음의",
  "자체가",

  "마시오",
  "왔구나",
  "들었지",
  "이상하게",
  "뇌가",

  "본적이",

  "곳이야",
  "자기를",
  "않았는데",
  "알겠죠",
  "왠지",
  "여자친구",
  "채로",
  "생각했던",

  "못하겠어",
  "신을",
  "굴지",
  "혼자가",
  "잊을",
  "따위",
  "쉬",
  "괴물",
  "피트",
  "그렇겠죠",
  "보냈어",
  "마저",
  "걸을",
  "죄다",
  "심한",
  "땅",
  "옮겨",
  "괜찮을거야",
  "사랑한다",
  "나오면",
  "살지",
  "끊어",
  "지역",
  "제인",
  "아파트",
  "됩니까",
  "있는건",

  "생길",
  "삼촌이",
  "그도",
  "났어요",
  "정보가",
  "저흰",
  "사부님",
  "직업을",
  "선수",
  "고등학교",
  "떠나는",
  "관련이",

  "조건부",
  "거길",
  "둘을",
  "오스왈드",



  "걱정마",
  "결혼은",
  "계셔",
  "웨버",

  "말이냐",
  "캘리",
  "누구냐",
  "않았으면",
  "느그",
  "알겠다",
  "적을",
  "대답을",
  "보면서",
  "남자야",

  "주시오",
  "점은",
  "해주고",
  "못된",
  "달고",
  "모르니까",
  "신경쓰지",

  "웃고",

  "있지요",
  "쏠",
  "교수님",
  "대기",
  "선",
  "아니라고요",
  "돈으로",
  "들어가는",
  "당신에",
  "없었던",
  "말씀해",
  "호출",
  "쟨",
  "그녀와",
  "도저히",
  "시작하면",
  "들면",
  "놀랄",
  "삶에",
  "기다려야",

  "주려고",
  "가자구",
  "배고파",
  "무엇입니까",
  "다스탄",
  "알겠나",
  "능력이",
  "때문이에요",
  "위험",
  "하지도",
  "아버지와",

  "후로",
  "병신",
  "우주에서",
  "느끼고",
  "위",
  "이것을",
  "조이",
  "점을",
  "됐고",

  "리사",
  "스노우",
  "살아야",
  "돌아가신",
  "그렇소",
  "이유로",
  "너만",
  "푹",
  "갖게",
  "지냈어",
  "데릭은",
  "거겠지",
  "있는거지",
  "넘게",
  "틀린",
  "더스티",
  "단순히",
  "병에",
  "먹어라",
  "종종",

  "모르잖아",

  "엄마야",
  "친구도",
  "걱정이",
  "거나",
  "묻는",
  "토마스",
  "달라요",
  "가는데",
  "버스",
  "파이팅",

  "떨어지고",
  "힘들",
  "알겠네",

  "관계가",
  "저것",
  "한다고요",
  "그러니깐",

  "친구들을",
  "언제든",
  "적의",

  "쉬고",


  "코",
  "키",
  "법이",
  "살기",
  "목소리",
  "없구나",
  "했었지",
  "눈은",
  "떠나야",

  "최종수정",
  "말씀이",
  "테니까요",
  "되는지",
  "대단해",
  "자비를",

  "시저",


  "옥희야",
  "상태를",
  "안되지",

  "잘못한",
  "시작했어요",
  "하기로",
  "환자들이",
  "읽고",

  "밤이",
  "폭탄을",
  "여자들이",
  "같다고",
  "생각했죠",
  "엔진",
  "차고",
  "오늘도",
  "토요일",
  "하는거",
  "꺼내",
  "에그",

  "로라",
  "부분이",
  "아들아",

  "없었을",
  "해설자",
  "트럭",
  "쪽에",
  "호출해",

  "죽어가는",
  "얼마든지",


  "누구를",
  "싸움을",
  "꺼야",
  "참을",
  "문제죠",
  "엘리스",
  "않을까요",
  "준비하고",
  "찬",
  "암이",
  "있었지만",
  "해봤어",
  "좋겠네요",
  "일어나고",


  "기분은",
  "들으면",
  "무슈",
  "했다는",
  "지미",

  "통",
  "놔둬",
  "돌아가고",
  "상상도",
  "해주지",

  "찾아봐",
  "알렉스가",
  "시켜",
  "아이스크림",
  "씨의",
  "잊어",
  "출혈을",
  "괜찮니",
  "끝에",
  "소식",
  "맥박이",
  "앨런",
  "그놈이",
  "따윈",
  "급한",
  "관련",
  "걸렸어",
  "않는다는",
  "정확하게",
  "결혼한",
  "너나",
  "알겠니",
  "가운데",
  "시계",
  "세상에나",
  "일반적인",
  "드립니다",
  "죠지",
  "가져왔어요",
  "사건은",
  "길로",
  "타미",
  "원하면",
  "맞은",
  "잊어버려",
  "것이고",

  "방법으로",
  "아빠랑",
  "일입니다",
  "꽃",
  "출발",
  "시작하는",
  "들었다",
  "쫌",
  "포기하지",
  "충분해",
  "몇가지",
  "왕을",
  "들어와서",
  "때때로",
  "그쪽",
  "이곳을",
  "언니는",
  "있구요",
  "그리워",
  "호",
  "내려와",
  "이야기는",
  "도망쳐",
  "말라",
  "님",
  "준비됐어요",
  "그럴지도",
  "숨이",
  "게이",
  "비싼",
  "매년",
  "가야해",
  "명을",
  "실험을",

  "작업",
  "근무",
  "것들은",
  "바보같은",
  "당신이랑",
  "양은",
  "없어도",
  "수만",
  "소년",
  "봤나",
  "어딘가에",

  "잊고",
  "집어",
  "죽었다",
  "생각만",
  "순간을",
  "기회는",
  "술이",
  "송",
  "집안",
  "보여줄",
  "인턴이",
  "나가게",
  "오줌",
  "뜨거운",
  "셰퍼드가",
  "영혼을",
  "못해서",
  "하는데요",
  "보내는",
  "시끄러",
  "모자를",
  "형제",
  "그러길",
  "오웬",
  "애도",
  "지구를",
  "집도",
  "해야겠어",
  "산을",
  "이유야",
  "하더군",
  "책임을",

  "쉐퍼드가",

  "치료할",
  "놈이야",

  "아니란다",

  "안전",

  "두번",
  "번호",
  "비밀로",
  "예뻐",
  "만났어",
  "수있습니다",
  "싫다고",
  "올바른",
  "해보자",
  "리차드",
  "사이로",

  "장소를",
  "인간을",
  "이는",
  "시작합니다",
  "인마",
  "같소",
  "일어나지",
  "돌아가면",
  "가세",
  "피쉬",
  "맡겨",
  "있는걸",
  "생각했지",
  "부모가",
  "대답",
  "정말이에요",
  "의료",
  "그놈",
  "하고요",
  "보라구",
  "잘했어요",

  "사세요",
  "말어",
  "허큘리스",
  "않았고",
  "권리가",
  "자유를",
  "여러분의",
  "없을거야",
  "이해해요",
  "따뜻한",

  "엄",
  "꼬마야",
  "레너드",
  "저예요",
  "해보고",
  "언제든지",
  "뇌에",
  "자리가",
  "신은",
  "해치지",
  "있어야지",
  "나아",
  "뭐여",
  "갔지",
  "싫으면",
  "아저씨는",
  "화나게",
  "바빠",
  "도그",
  "포기",
  "살려주세요",
  "사람처럼",
  "하며",
  "사랑스런",
  "기가",
  "데이빗",
  "말했듯이",
  "이야기가",
  "아직까지",
  "거울",
  "비밀이",
  "도니",
  "우",
  "희망이",
  "슬론이",
  "키가",
  "얼음",
  "댁이",
  "갔는데",
  "자살",
  "드래곤",
  "당한",

  "형을",
  "있었다는",
  "이해를",

  "되길",
  "이러한",
  "진지하게",
  "음악이",
  "한테",
  "데이트를",
  "아니냐",

  "주변에",
  "심장에",
  "안전해",
  "같았어요",
  "오말리가",
  "상",
  "작전",
  "아닐까",
  "하신",
  "자신에게",

  "같고",
  "흥",
  "과",
  "하겠지",
  "전에요",
  "말했잖아요",
  "그리고는",
  "그림을",
  "병원을",
  "조용",
  "없거든요",
  "인류의",

  "운",
  "계획",
  "발견",
  "커",
  "삶",

  "아시다시피",
  "모조리",
  "궁금해",
  "오실",
  "결과는",
  "목이",
  "한단",

  "시스템",
  "탄",
  "약이",
  "과장님은",
  "맡은",
  "기다려봐",
  "통증이",

  "응급",
  "내릴",
  "보이나",
  "구하는",
  "어딘가",
  "보안",
  "얻은",
  "끝나는",

  "천만에",
  "오빠는",
  "세워",
  "택시",
  "외상",
  "말해도",
  "재미있는",
  "마티",
  "진심이야",
  "백인",
  "담당",
  "겁에",
  "가지는",
  "않았지만",
  "무언가를",
  "위해선",
  "잡으러",
  "보렴",
  "모르잖아요",
  "건강한",
  "생각하나",
  "말해줄",
  "여름",
  "라",

  "변호사",
  "대장",
  "하려면",
  "있었다면",
  "봐야지",

  "거리를",

  "코를",
  "환자야",
  "신음",
  "데려갈",
  "알겠지만",
  "내보내",
  "사람으로",
  "감정이",
  "파이",
  "사람이라고",
  "방이",
  "마시는",
  "온통",
  "가만있어",
  "움직이는",
  "시작한",
  "으",
  "같죠",
  "주요",

  "써요",
  "주변",
  "벽을",
  "남자들이",
  "세계를",
  "잘한다",

  "떠",
  "되서",
  "페니",
  "전투",
  "보이고",
  "볼게요",
  "일어나요",
  "그다지",
  "대부분은",
  "중지",
  "걸까요",
  "참아",
  "케이트",
  "거군",
  "공부",
  "돌아가야",
  "뚫고",
  "바다",
  "차례야",
  "학교를",
  "죽는다",
  "비디오",
  "잘했다",
  "왔군",
  "홈즈",
  "했나요",
  "해보세요",
  "알렉스는",
  "방은",
  "괜찮다",
  "나더러",
  "다예요",
  "고통",
  "살아서",
  "예요",
  "것이지",
  "이용해",
  "맞어",
  "늦었어요",
  "끝나지",

  "여자들",


  "던져",
  "아니까",
  "아이들",
  "반지",

  "되세요",
  "핀",
  "맛",
  "알았다고",
  "어리석은",
  "구하고",
  "잔뜩",
  "살인을",

  "불가능해",
  "범인이",
  "잃어버린",
  "숙녀",
  "이해하지",
  "걜",
  "손가락",
  "거기가",
  "연락해",
  "있겠니",
  "성을",
  "벗고",
  "사람들한테",
  "않도록",
  "사라져",
  "짓이",
  "않았나요",
  "강아지",

  "왕은",
  "없겠죠",
  "목",

  "중인데",
  "다르지",
  "소리는",
  "천만에요",
  "하나로",

  "휴가",
  "볼래",

  "확인하고",
  "매",
  "비",

  "않았다면",
  "담배를",
  "큰일",
  "물건이",
  "되니",

  "뭐하러",
  "안전하지",
  "사라",
  "아멘",
  "끝낼",
  "안된다고",

  "왼쪽으로",

  "로버트",
  "거라도",
  "이름도",
  "돕는",
  "아무한테도",
  "얘기야",
  "귀신",
  "어딨는지",
  "언제까지",
  "분의",
  "거래",
  "죽였어요",
  "크리스티나는",
  "되",
  "임무는",
  "이곳은",
  "미화야",
  "사진이",
  "그것에",
  "됐다고",
  "넘어",
  "주인이",
  "뇌를",
  "줄리",
  "진정해요",
  "코니",
  "나지",
  "부디",
  "한동안",
  "거기로",
  "읽어",
  "수잔",
  "무슨일이야",
  "실컷",
  "다를",
  "무고한",
  "종이",
  "심장은",
  "축하해요",
  "수학",
  "볼게",
  "알았나",
  "있었소",
  "이래요",
  "메스",
  "끝났다",
  "어쨋든",
  "블루",
  "살았어",
  "싫어해",
  "릭",
  "남편과",
  "빼면",
  "의하면",
  "위해서요",
  "갔었어",
  "드리겠습니다",
  "하길",
  "어딘지",

  "어떠니",
  "지낼",
  "가끔씩",
  "자랑스러워",
  "이란",
  "사용할",
  "하던데",
  "어떠한",
  "싫다",
  "할지도",
  "정상적인",
  "가문의",
  "제리",
  "잃게",
  "날은",
  "대화",
  "드는",
  "위험이",

  "은행",
  "주위를",
  "케이지",
  "어디든",
  "최고야",
  "그보다",
  "산에",
  "주실",
  "남지",
  "산소",
  "없다고요",

  "아니여",
  "꼴이",


  "그쵸",
  "있을게",
  "기다리세요",
  "충분하지",
  "귀가",
  "드리고",
  "에릭",
  "미래에",
  "현실을",
  "늦은",
  "퍼뜩",
  "목숨",
  "확실한",
  "기술이",
  "이곳에서",
  "나오고",

  "그런가요",
  "음악을",
  "하시고",
  "시작하자",
  "애들한테",
  "하나씩",
  "겁이",
  "하더라",
  "장기를",
  "에리카",
  "왜그래",
  "통증을",
  "부르고",
  "말했던",

  "빼",
  "잘하는",
  "근처",
  "멍청아",

  "다물고",
  "들어오게",
  "암흑",
  "적당한",
  "맡고",
  "나고",
  "실례",
  "줄래요",
  "얘기하지",


  "그럴게요",

  "호텔에",
  "뭘로",
  "예이",
  "저희를",
  "여덟",
  "전까지는",
  "로이",
  "돌아와서",
  "부인의",
  "되었어요",
  "오너라",
  "시작했어",
  "대충",
  "떠났어",
  "죽인다",
  "내려가",
  "저쪽으로",
  "아니고요",

  "그분은",
  "현명한",
  "해야죠",
  "때면",
  "못하겠어요",

  "배에",
  "땜에",
  "그나저나",
  "틀림없이",

  "스트레스",
  "헛소리",
  "하셨잖아요",
  "주게",
  "뜻이지",
  "다행이야",

  "안했어요",
  "농담",
  "싫은",
  "그레이가",
  "달러를",
  "완벽해",
  "아버지도",
  "시체가",

  "구하기",
  "의학",
  "나무에",
  "돌아왔어",
  "작고",
  "놓을",
  "맞았어요",
  "있겠네요",
  "사라졌어",
  "운전",
  "없다구요",
  "그분이",
  "가족과",
  "왔소",

  "개새끼야",
  "않은데",
  "신선한",
  "선택할",
  "대가를",
  "가야겠다",
  "머리는",
  "우주를",
  "모른다는",
  "알았네",
  "하는구나",
  "거에",
  "남자친구가",

  "알았는데요",
  "없었는데",

  "애한테",
  "남아서",
  "사이",
  "바뀔",
  "박사가",
  "있겠지만",

  "주인님",
  "누구라도",

  "되지만",
  "마님",
  "버틸",
  "종양",
  "일이라고",
  "자자",
  "숲",
  "미래의",
  "멋지다",
  "안전할",

  "여자친구가",

  "고맙지만",
  "맞서",
  "계십니까",
  "않거든",
  "스파이더맨",
  "자라고",
  "들어가요",
  "좋겠는데",
  "됐을",
  "조심하세요",

  "여자애",
  "현장에서",
  "남았어요",
  "좋군",
  "여자도",
  "죽였다고",
  "그랬잖아",
  "목숨이",
  "만들었어요",
  "배울",
  "대답은",
  "쓸데없는",

  "그레이스",
  "지나면",
  "설명",
  "선생님도",

  "머프",
  "의사들이",
  "못합니다",
  "저기서",
  "유일하게",
  "죽었고",
  "잘라",
  "모르게",
  "여기도",
  "없겠지",
  "몇몇",
  "보군",
  "들어오세요",
  "됐소",
  "올라",
  "물론입니다",
  "서둘러요",

  "공식적으로",
  "일이나",
  "보네",
  "사실대로",
  "규칙을",
  "잡았다",
  "의미",

  "중요해",
  "사악한",
  "기분을",
  "접근",
  "결혼할",
  "그만큼",
  "상처는",
  "몰랐는데",
  "사과를",
  "꿈은",
  "놀고",
  "왔는지",
  "암에",
  "웃어",
  "아름다워",
  "괜찮죠",
  "설명해",

  "임무",

  "중인",
  "애기",
  "고통이",

  "지내고",
  "정지",
  "여기를",
  "블랙홀이",
  "어쩜",
  "리젤",
  "그렇진",
  "우리들의",
  "너처럼",
  "신호가",
  "일인가",
  "미쳤다고",
  "먹었어요",
  "많은데",
  "벽에",
  "내일부터",
  "개인적으로",


  "아닌지",
  "괜찮아질",
  "하오",
  "거였어요",
  "팀을",
  "무지",
  "판",
  "캐시",
  "찍고",
  "이거지",
  "쓰면",
  "범죄",
  "잡아라",
  "했어야",
  "다친",
  "지키는",
  "가지마",
  "싶어하는",
  "선택한",
  "바란다",

  "신사",
  "대해서는",
  "약은",
  "이지를",
  "어떨까요",
  "에너지를",
  "그레이는",
  "딸은",
  "웨인",
  "이안",
  "변화가",
  "꽃을",
  "마무리",
  "반지를",
  "아니니",

  "전쟁에서",
  "시작했다",
  "때요",
  "참고",
  "싶은데요",
  "닥치고",
  "나한테서",
  "석션",
  "별일",
  "셰인",
  "브라이언",
  "그럴까",

  "즐겁게",

  "이래서",
  "남자친구",
  "있었네",

  "번에",
  "사격",
  "사무실에",
  "니키",
  "잃었어",
  "지옥에",
  "어디죠",
  "경고",
  "식사를",
  "테스트를",
  "지나간",
  "팀은",
  "백만",


  "오렴",
  "그렇다",

  "이분은",
  "하다",
  "그웬",
  "말대로",
  "죽여야",
  "거라곤",
  "정신차려",
  "당연한",

  "헤드",
  "안의",
  "안해요",
  "치는",
  "보이게",

  "타디스를",
  "어디다",
  "세자르",
  "안될",
  "무릎을",
  "토르",


  "누구시죠",
  "주신",
  "시작하지",
  "사무실로",
  "안전을",
  "차트",

  "모시고",
  "몽땅",
  "죽겠어",
  "드레스",
  "선생님의",
  "고기",
  "파리",
  "놔줘",
  "갓",
  "기록을",
  "바바라",
  "존재를",
  "의사들은",
  "엄마에게",
  "대통령",
  "앞",
  "도망",
  "미안하다고",
  "노먼",
  "오전",
  "걔를",

  "많아서",
  "뭐랄까",
  "말한다",
  "들어오는",
  "않았잖아",
  "술이나",

  "의견을",
  "여기에서",
  "명령",
  "있었니",
  "맡을",
  "믿기",
  "보냈어요",

  "피할",
  "라디오",
  "대단히",
  "순간이",
  "당신네",
  "아플",
  "아니었다",

  "리즈",
  "거리가",
  "저번에",
  "않았다는",
  "빵",

  "저쪽에",
  "후면",
  "마을에",
  "배우고",
  "여",
  "것이오",
  "그런지",
  "영웅",
  "주실래요",

  "돌아가지",


  "수를",
  "뭐에",
  "자네들",
  "발견한",
  "조사를",
  "아니라니까",
  "멋진데",
  "하룻밤",
  "찍은",
  "만났어요",
  "괜찮네",
  "소리도",
  "술에",


  "아무데도",
  "전원",
  "관리",
  "진작",
  "어떡해요",
  "장군",
  "말하는거야",
  "군대가",
  "싸게",
  "사과",
  "에디",
  "겠어요",
  "문제에",
  "온거야",
  "추워",
  "서방님",
  "않다면",
  "들어가도",
  "어머님",

  "조용해",
  "결정할",
  "김선임",
  "가니",
  "날씨가",
  "고양이를",
  "고맙군",
  "얼굴은",


  "어쩌죠",
  "파티가",

  "바빠서",
  "하루가",
  "그대",
  "줄게요",
  "웃긴",

  "때의",
  "살살",
  "바가스",
  "마셔요",
  "와줘서",
  "신문",
  "여러분은",
  "읽는",
  "잡아요",
  "조직을",
  "물어봐도",
  "받았습니다",
  "자리에서",
  "받아들일",
  "태워",
  "됐군",

  "모르는데",
  "여잘",

  "한마디",
  "내버려둬",

  "시스템을",
  "하하",
  "나무를",
  "죽음이",
  "뿐이다",
  "창녀",



  "겁을",
  "사탕",
  "먹어야",
  "평화를",
  "찾게",
  "나이트",
  "공을",
  "끝내고",

  "쉬세요",
  "여자한테",
  "누구의",

  "당신하고",
  "멕시코",
  "나갔어요",
  "네놈이",
  "되겠네",
  "복부",
  "돌아왔어요",

  "가져왔어",
  "놀라게",
  "그녀도",
  "경기",
  "말자",

  "감옥에서",
  "기계",
  "들어왔어",
  "떠나고",
  "애인",
  "썩",
  "당분간",
  "대답해",
  "쯤",
  "숀",
  "때에",
  "나아질",
  "폭풍이",
  "약속할게",
  "이번에는",
  "지나",
  "영광입니다",
  "소린",
  "부탁합니다",
  "가는거야",
  "못했다",
  "나와라",
  "아무거나",
  "놓은",
  "잤어요",
  "주제에",
  "간호사가",
  "뼈를",
  "존재가",
  "부탁이",
  "신이시여",
  "어휴",
  "궁금한",

  "될지도",
  "자막공장",
  "만나지",
  "제이슨",
  "두지",
  "애인이",


  "아프로",
  "들어가야",
  "아기의",
  "느껴져",
  "나라",
  "역사상",
  "부탁드립니다",
  "웃기지",

  "실례지만",
  "옷은",
  "나갔어",
  "잘못이야",
  "토비",
  "하냐",
  "그랬으면",
  "저래",
  "춘향아",
  "타요",
  "했었죠",
  "빌",
  "양의",
  "훔친",

  "벽",
  "못할거야",
  "밑으로",
  "죄",

  "피에르",
  "그들과",
  "발로",
  "용서해",
  "기억해요",
  "어쨌거나",
  "반응이",
  "외로운",
  "쇼를",
  "한숨",
  "보자구",
  "아빠의",

  "넣을",
  "개자식",
  "로봇",
  "해준",

  "아셨죠",
  "없습니까",
  "은하는",
  "냄새를",
  "하시오",
  "부른",
  "보셨어요",
  "여기다",
  "멍청이",

  "아이에게",

  "년을",
  "이혼",
  "케빈",
  "도중에",
  "갈까요",
  "안부",
  "의사는",

  "데로",
  "충격을",
  "해줄게",
  "인생에",
  "치료가",
  "사랑합니다",
  "없다니까",
  "것이죠",
  "당겨",
  "없었고",
  "도망가",
  "싶나",
  "사람이죠",
  "버렸어",
  "의미를",
  "모르겠군요",
  "비행기가",
  "원하는게",
  "친",
  "말이예요",
  "루시",
  "실력이",
  "정직한",
  "평생을",
  "웬일이야",
  "너보다",
  "션",
  "옆으로",

  "했거든",
  "감고",
  "행성",
  "변하지",

  "한단다",
  "유리",
  "하루를",
  "한게",

  "없냐",
  "휘릴",
  "기다리지",
  "유감입니다",
  "당장은",
  "어디까지",
  "말구",
  "전부야",
  "아아",
  "근사한",
  "바지",
  "하늘에",
  "생각하게",
  "날을",

  "표정",

  "다하고",
  "말하게",
  "아홉",
  "안드레우",
  "할꺼야",
  "확률이",
  "파란",
  "어떠냐",
  "세계에서",
  "짓도",
  "손대지",
  "실수로",
  "안다면",
  "있잖니",
  "마틴",
  "미국의",
  "오늘부터",

  "걸리지",
  "늦어서",
  "말아야",
  "정말로요",
  "쪽은",
  "마누라",
  "구멍이",

  "현장에",
  "아니거든요",
  "보지도",
  "군대",
  "그랜드",
  "뜻인지",
  "감정",
  "빛을",
  "죽어서",
  "듣게",
  "오빠를",
  "사람아",
  "존나",
  "에워싼데도",
  "입어",
  "않겠습니다",
  "말해주세요",
  "상을",
  "섹시한",
  "부분은",
  "해줘야",
  "바짝",
  "오른쪽으로",
  "않는데",
  "다해",
  "고맙다고",
  "웃는",
  "무엇이든",
  "할래요",
  "수치가",
  "젊고",
  "그러자",
  "누구예요",
  "낯선",
  "했더니",
  "딱히",
  "자네와",
  "괜찮다면",
  "정도의",
  "사이가",
  "엘리자베스",
  "하이",
  "뭐랬어",

  "밤의",
  "첫째",
  "얻는",

  "말할게",
  "인공",
  "폐를",

  "모두의",

  "덕에",
  "동료",
  "날려",
  "자란",
  "정부",
  "준비는",
  "그러냐",
  "すべてを僕が敵にまわしても",
  "걷고",
  "미래",
  "대해서도",
  "공연",
  "벌을",
  "에드",
  "떠나면",

  "하겠다고",
  "형사",
  "하라는",

  "기다리게",
  "살이",
  "말하려고",

  "글을",
  "산사",
  "다니엘",
  "바라지",
  "규칙",
  "상태는",
  "붙여",
  "사람이지",
  "약물",
  "다행이다",
  "존이",
  "써도",
  "그딴",

  "빛이",

  "들어가게",
  "수술도",
  "자라",
  "용서",
  "하비",
  "계실",
  "마시면",
  "뼈",
  "목소리를",
  "각각",
  "감자",
  "발견했습니다",
  "안고",
  "자야",
  "귀",
  "어떡하지",

  "신께서",
  "도망칠",
  "도와드릴까요",
  "문은",
  "끝내주는",
  "신발을",
  "임신한",
  "싸워",
  "순간부터",
  "얘기좀",
  "하늘이",
  "어른이",
  "영원한",
  "파는",
  "신문에",
  "뭐해요",
  "앙뚜완",

  "만들어서",

  "노래가",
  "없을까",
  "멈추게",
  "싸움",
  "느끼지",
  "잉",
  "레드",
  "아십니까",
  "유감이에요",

  "되겠어요",
  "떠났어요",
  "최고로",
  "이모",

  "일해요",
  "모양이야",
  "거고요",
  "미",
  "경비",
  "가족에게",
  "개는",
  "어둠",
  "묻고",
  "저들은",
  "우리끼리",

  "설",
  "병원이",
  "때론",
  "합니까",
  "막고",
  "올리고",
  "남는",
  "피로",
  "전화할게",
  "유전자",
  "쟤는",
  "과거",
  "확신할",
  "곳이죠",
  "가나",
  "슬슬",
  "메시지",

  "이동합니다",
  "해야겠어요",
  "발이",
  "우연히",
  "하여튼",
  "가방에",
  "들어가자",
  "올거야",
  "봐야겠어",
  "주말에",
  "질문은",
  "말인가요",
  "얘기한",
  "에이프릴",

  "데도",

  "참으로",

  "공격",
  "노력하고",
  "하진",
  "어쩐",

  "방식으로",
  "로비",
  "무조건",
  "없인",
  "범인은",
  "잡지",
  "드려",
  "정",
  "되도록",
  "혈압은",
  "봤습니다",
  "가거라",
  "그랬나",
  "우유",
  "죄송합니다만",
  "쌍둥이",
  "노는",
  "하려",

  "향한",
  "있겠다",
  "포함해서",
  "놀아",
  "외과의가",

  "같았어",
  "끼고",
  "좋아하고",
  "계란",
  "구하러",
  "잠도",
  "둔",
  "가야겠어요",
  "나온다",
  "도련님",
  "이들이",
  "꿈도",
  "가스",
  "요즘은",

  "싶었는데",
  "거기다",
  "지고",
  "기쁘게",
  "시스템이",
  "보이죠",
  "지난번에",
  "술집에",
  "워낙",
  "써야",
  "총은",
  "사소한",
  "고맙구나",
  "단서를",
  "되었어",
  "뭐래",
  "싸우지",
  "폐",
  "할께요",
  "그것들은",
  "마침",
  "쌀",
  "저기에",
  "영",
  "예쁘게",
  "보통은",
  "제겐",
  "돌아오는",
  "빨리요",
  "쥐고",
  "내리는",
  "특별",
  "머릴",
  "일이라도",

  "부탁을",
  "있었거든요",
  "반은",
  "전화는",
  "전화도",
  "아니구요",

  "멋지네요",
  "축하합니다",

  "없고요",

  "고마",
  "깨닫게",
  "문제지",
  "못한다",
  "무사히",
  "미안하구나",
  "이용해서",
  "지금이야",
  "울어",
  "거래를",
  "연기",
  "경험이",
  "얻어",
  "단단히",
  "전해줘",
  "앞서",
  "실례할게요",
  "메러디스를",
  "연구",
  "뜻",
  "집중해",
  "십",
  "재판을",
  "생기는",
  "타임",
  "화재",
  "제가요",
  "모르겠군",
  "바랄",
  "드레스를",
  "부스",
  "조용한",
  "가능성은",
  "좋소",
  "아니겠죠",
  "비켜요",
  "애초에",
  "놈도",
  "궁금해서",
  "그림자",
  "똑똑하고",
  "주인",
  "너에",
  "로빈",
  "화난",
  "열이",
  "이유도",
  "파일을",
  "결혼식에",
  "생각했다",
  "봤잖아",
  "같던데",
  "도시의",
  "하는건",
  "앞으로도",
  "일이다",
  "해주는",

  "지금의",
  "지켜보고",
  "붉은",
  "소문이",
  "네드",
  "붙잡고",
  "적절한",
  "아니군",
  "때만",
  "사용",
  "있겠나",
  "자유롭게",

  "지금껏",
  "원하는지",
  "신랑",
  "어떻습니까",

  "사람입니다",


  "신고",
  "너희들을",

  "들어가지",
  "수술하는",
  "사용하는",
  "잃었어요",
  "데는",

  "메세지를",

  "가족들이",
  "만들었지",
  "많을",
  "했었어",
  "사샤",
  "만나요",
  "아델",

  "먹을래",
  "깊게",
  "힐",

  "시계를",
  "장군님",
  "못하지",
  "칼로",
  "생각한다면",

  "가게에서",
  "몹시",
  "번은",
  "죽었습니다",
  "티리온",
  "찾았다",
  "그지",
  "살던",
  "마크가",
  "살해",
  "있을게요",
  "란",
  "무",
  "주님",
  "소아과",
  "하자는",


  "들어본",

  "뇌의",


  "죽어라",
  "처음에",
  "팔코니",
  "데릴",
  "빛의",
  "어디를",

  "장벽",
  "않기로",
  "놈들의",
  "구나",

  "신성한",
  "미치겠네",
  "알았습니다",
  "확실해요",
  "지난번",
  "신호를",
  "싹",
  "우주에",
  "이상해요",
  "오랜만에",
  "저희도",
  "막지",
  "군요",

  "와도",
  "눈치",
  "돌아서",
  "했습니까",
  "마음의",
  "갇혀",
  "같지만",
  "들어가세요",
  "공격을",
  "환자들을",
  "감사드립니다",
  "그이는",
  "다요",

  "위해서는",
  "있어라",
  "멜",
  "아시겠죠",
  "죽음",
  "떨어졌어",
  "있음",
  "아내와",
  "좋군요",
  "만나기로",
  "걸지",

  "아니라구요",
  "지켜야",

  "그분",
  "싫어해요",
  "선택은",
  "재",
  "걱정하고",

  "바라",
  "나나",
  "드려요",
  "들어간",
  "캘리가",
  "제이미",
  "아빠와",
  "뼈가",
  "그런거야",
  "집이야",
  "친구예요",
  "규칙이",
  "설명할",
  "수업",
  "재능이",
  "선배",
  "마이",
  "여왕",
  "테야",
  "들어보세요",
  "눌러",
  "선생이",
  "기뻐",
  "왔는데요",
  "처리",
  "최종",
  "중앙",
  "친절한",
  "발작을",
  "답이",
  "변화를",
  "전투에서",
  "착하지",
  "누구에게",
  "돌",
  "않겠다고",
  "레지던트가",
  "늦지",
  "멀쩡해",
  "끝나",
  "훔쳐",
  "안된다",
  "어울려",

  "사고로",
  "않았던",
  "꼴",

  "또다른",
  "밥이",
  "팔이",
  "강하게",
  "들어가고",

  "찾아요",

  "회사에",
  "죽음에",
  "기사가",
  "한번에",
  "그시간에",
  "거거든",
  "고통스럽고",
  "집중",
  "손상이",
  "이브",
  "돌려줘",
  "주변을",
  "마실래",
  "아침입니다",
  "셔츠",
  "만들기",
  "거리에",
  "걔들이",

  "작품",
  "애론",
  "바지에",
  "않기",
  "해변에",

  "걔네",
  "열차",
  "적당히",
  "하셨어",
  "길에서",
  "기억은",
  "소용없어",
  "필요하다",
  "워싱턴",
  "총알을",
  "그런데도",
  "오야",
  "소용",
  "그때가",
  "숨길",
  "빅터",
  "영웅이",
  "못한다면",
  "멈추고",
  "내린",

  "날에",
  "코너",
  "졸라",
  "겁",
  "줬으면",

  "했니",
  "출혈",
  "그놈의",
  "케이스",
  "부모님을",
  "죄송한데",
  "순간에",
  "탈출",
  "짜증",
  "앨리슨",
  "둬요",
  "놔요",
  "만났을",
  "있겠네",

  "거니까요",
  "생명",
  "군대에",
  "하라",
  "가본",
  "윌슨",
  "않으세요",
  "바꾸는",

  "환자들은",
  "나이에",
  "딘",
  "고대",
  "것뿐이야",
  "않으니까",
  "돌아가셨어요",
  "비타민",
  "충성",
  "뜻을",


  "저들을",
  "것인가",

  "믿음을",
  "방송",
  "예전엔",
  "부족해",
  "찾아와",
  "기도를",
  "라니스터",
  "왔고",
  "주소",
  "실수",
  "짧게",
  "자세한",

  "했다면",
  "우리집",
  "베일리는",

  "머시",
  "이쁜",
  "기억하고",
  "저녁을",
  "느끼게",
  "않았다고",

  "원한",
  "무엇인지",
  "주의를",
  "죽을지도",
  "괜찮은데",
  "뻔한",
  "에너지",

  "그랬는데",
  "없이도",
  "동안은",
  "저녁은",
  "도착",

  "그놈을",
  "생각하죠",
  "선생님께",
  "서로에게",
  "끝났습니다",
  "아닐세",
  "간단히",
  "시끄러워",
  "사고를",

  "인한",
  "시속",
  "보이니",
  "이전에",
  "다행이네요",
  "금요일",
  "저지른",
  "노아",
  "역",
  "이식을",
  "좋겠네",
  "저스틴",
  "볼까요",
  "준다고",
  "멋지게",
  "알아볼",
  "하다가",
  "기록",
  "했단",
  "생활",
  "시에",
  "갔을",
  "총이",
  "밀어",
  "괜찮나",
  "용",
  "못했을",
  "싶소",
  "결혼하고",
  "당신처럼",
  "구하려고",
  "귀신이",
  "영혼이",
  "않겠죠",
  "둘째",
  "지은",
  "잡았어요",
  "사업을",
  "팔에",
  "유럽",
  "존재하지",

  "왔죠",
  "주시죠",
  "양쪽",
  "때로는",
  "공주",
  "퀼프",
  "이기는",
  "죄가",
  "울",
  "칼이",
  "자네들이",
  "떼",

  "뭐고",
  "헌데",
  "걔들은",

  "생각한다",
  "이가",
  "알아낼",
  "맘",
  "기억나요",
  "없기",
  "알려진",
  "갈래요",
  "보일러",

  "성함이",
  "것이야",
  "이따가",
  "허락",
  "만들어야",
  "거라구요",
  "래리",
  "분위기",
  "귀하의",
  "째",
  "예쁘다",
  "서류에",

  "애들도",
  "나라를",

  "엉뚱한",
  "한시간",
  "이에요",
  "쫓아",
  "감당할",
  "경우는",
  "같진",

  "뒤집어",
  "훈련",
  "여자에게",
  "부모",
  "가져온",
  "주위에",
  "미래는",
  "할머니는",
  "모아",
  "화장실에",
  "섹스는",
  "과거를",
  "죽길",
  "누구보다",

  "신장을",
  "와야",
  "않을래",
  "사랑할",
  "클럽",
  "로빈스",

  "하필",

  "관계는",
  "고통은",
  "이용한",

  "들었습니다",
  "브랜드",
  "있는지도",
  "구했어",
  "건너",
  "안하고",
  "모여",

  "통증",
  "이들은",
  "지로",
  "끝내",
  "다닐",
  "싫은데",
  "나한테는",
  "노력",
  "의한",
  "되어서",
  "한번은",

  "기계가",
  "이번이",
  "힉스",
  "던",
  "주목",
  "낳을",
  "혜린아",
  "아하",
  "않겠지",
  "앗",
  "타디스가",
  "승리",
  "호텔에서",
  "이놈의",
  "내지",
  "조싼",
  "회사에서",
  "파트너",
  "솔직하게",
  "중간에",
  "오늘밤에",
  "제프",
  "하니까요",
  "번호를",
  "샀어",
  "실험",
  "좋구나",

  "버린",
  "초",
  "행동이",
  "옷이",
  "그랬던",
  "않냐",
  "안협네",
  "가게에",
  "금",
  "좋겠군",
  "가는지",
  "깨끗이",
  "그때는",
  "젠장할",
  "남편의",
  "덤보",
  "파커",
  "병신아",
  "불리는",
  "얘기하자",
  "살아남을",
  "싶네",
  "스티븐",
  "팀에",
  "동생은",
  "하나야",
  "계십니다",
  "그런걸",
  "돌아오면",
  "떨어지면",

  "빛나는",
  "대학에",
  "술도",
  "누구인지",
  "요원이",
  "찾아낼",
  "사업",
  "가족의",

  "되겠습니까",
  "신장",
  "없으니까요",
  "먹어도",

  "등에",
  "죽일거야",
  "그녈",
  "생각하는지",
  "걱정하는",
  "바쁜",
  "긴급",
  "클레어",
  "어때서",
  "아닐까요",
  "신부님",
  "그렇긴",
  "제거하고",


  "변태",
  "얼굴도",

  "컷",
  "앨리스",
  "말았어야",
  "그린",
  "굶어",
  "글",
  "아마도요",
  "넘겨",
  "경우엔",
  "하도",
  "있길",
  "위해서라면",
  "자릴",
  "뜨고",
  "힘들지",
  "것인지",
  "돌아올게",
  "물은",
  "서류를",
  "악마",
  "상상할",
  "바보가",
  "사귀는",
  "가겠어요",
  "브라보",
  "이자벨",

  "걱정을",
  "공기를",
  "술은",
  "어둠의",
  "것이며",
  "섬",
  "닮은",
  "거라구",
  "블레이드",
  "시간의",
  "내부",
  "갔는지",
  "정보는",
  "그쪽이",
  "그러다가",
  "변호사가",
  "기차",
  "인터뷰",
  "가지만",
  "어쩌려고",
  "영어",
  "중이다",
  "이후에",
  "이기고",
  "표정이",
  "이상이",
  "잘생긴",
  "시작한다",
  "알기나",

  "문제로",
  "자매",
  "조각을",
  "됐나요",
  "선택권이",
  "사랑스러운",
  "장소",
  "어땠어요",
  "주마",
  "하셔",
  "아내의",
  "예수",
  "확신이",
  "전화로",
  "추적",

  "나라고",
  "시킬",
  "언제쯤",
  "소년이",
  "나쁘진",
  "않다는",
  "어울리는",

  "환자에",
  "쉴드",
  "늑대",

  "없다구",
  "했는데요",
  "존스",
  "것이라고",
  "그것들이",

  "받아라",
  "서약을",
  "떨어져서",
  "뭔가요",

  "자기는",
  "약한",
  "불구하고",
  "입이",
  "머물",
  "걸렸어요",
  "상관이",
  "하는거지",

  "수천",
  "기억할",
  "도와주고",
  "이분이",
  "군인",
  "필요없어요",

  "도리가",
  "부탁이에요",


  "보여줄게",

  "소녀를",
  "뜻이에요",

  "될거에요",
  "선물로",
  "생겨서",
  "두면",
  "아침부터",
  "최대",
  "지내는",
  "놓치고",
  "너한텐",
  "아부지",
  "카드를",
  "말했을",
  "사이의",
  "두는",
  "책이",
  "나타나서",
  "쉬지",


  "불어",
  "철이",
  "속을",
  "벗어날",
  "안좋은",
  "그리구",
  "주의",
  "보이는군",
  "직업이",
  "휴가를",
  "과학",
  "환자도",
  "공부를",
  "관계",
  "테지",
  "선물이야",
  "거랑",
  "이라",
  "사무실에서",
  "두개골",
  "취해",
  "아버지께서",
  "왔던",
  "자길",
  "허드슨",
  "선상님",
  "점심을",
  "났네",
  "포드",

  "신호",
  "끊임없이",
  "빨라",
  "한쪽",
  "동안이나",
  "장소에",
  "감사",
  "음식이",
  "안경",

  "사형",
  "어디있어",
  "셰리",
  "가겠습니다",

  "갔죠",
  "조직",
  "돼야",
  "되네",
  "깨끗하게",
  "데려온",
  "죽도록",
  "의식이",
  "조금은",
  "지루한",
  "배워야",
  "제안을",
  "제거할",
  "어머니의",
  "되는거지",
  "딸의",
  "일과",
  "위를",
  "힘내",
  "오빤",
  "이라는",
  "댁",
  "환자분",
  "속도",
  "행복을",
  "작동",
  "그것들을",
  "싶군요",
  "암을",
  "에버딘",
  "부드럽게",
  "증상이",
  "자도",
  "되겠다",
  "잡아야",
  "반가웠어요",
  "베이비",
  "얻고",
  "동영상",
  "계시죠",
  "하시면",
  "처리할",
  "승남아",
  "보아하니",
  "손상을",
  "셈이죠",
  "기회야",
  "나왔습니다",
  "어떤가요",
  "걔한테",
  "새가",
  "운명이",
  "겨울",
  "기사",
  "말씀이세요",
  "똑똑히",
  "하나라도",
  "가득한",

  "거리에서",
  "타일러",
  "문제도",
  "리나",
  "프랭클린",
  "보며",
  "긴장",
  "고생",
  "매주",

  "일반",
  "그앨",
  "밥은",
  "뉴욕에",

  "통제",
  "죽는다고",
  "누구에게도",
  "고향으로",

  "해봐야",
  "잘도",
  "근처에서",
  "식당",
  "열차가",
  "느낌을",
  "말씀하세요",
  "심각하게",
  "놓으면",
  "써서",
  "전의",
  "믿게",
  "준다면",
  "즐기는",
  "댁에",
  "누워서",
  "산타",
  "그이가",
  "아리아",
  "감시",
  "진작에",
  "이해합니다",
  "켈리",
  "몰고",

  "전해주세요",
  "채널",
  "받으세요",
  "진실은",
  "여유가",
  "로즈",
  "잡혀",
  "줄도",
  "타디스에",
  "달콤한",
  "미칠",
  "공이",
  "사람에",
  "폐가",

  "고향에",
  "평화",
  "잔소리",
  "떨어지는",
  "최초의",
  "밝은",
  "한스",
  "과장이",

  "하는군요",
  "인도",
  "범죄를",
  "마시지",
  "가실",

  "시도",
  "의학감수",
  "기억도",
  "번의",
  "내면",
  "친구라고",
  "자식들",
  "어니",
  "당신이라면",
  "생각해서",
  "남성",
  "서장님",
  "되겠군요",
  "들어야",

  "비명",
  "그런식으로",
  "있다니까",
  "돌고",
  "가벼운",
  "즐길",

  "있었거든",
  "독을",
  "잭슨",
  "말려",
  "마미짱",
  "범인을",
  "가볍게",


  "최선의",

  "메르디스",
  "息をしたくて",
  "일이잖아",
  "짓이에요",
  "일입니까",
  "내리고",
  "사촌",
  "되겠네요",
  "엉덩이에",
  "기본적으로",
  "있을겁니다",
  "갈께",
  "아침엔",
  "기타",
  "프로그램을",
  "해결",
  "멋지지",


  "용서를",
  "일주일에",
  "베티",

  "믿어야",
  "ここは苦しくて",
  "있더군요",
  "억지로",
  "모세",
  "있는가",
  "놀러",
  "다음날",
  "의식을",
  "난다",
  "연결",
  "일해",
  "부러진",
  "가지러",

  "늦게까지",
  "같으면",
  "해볼까",
  "기계를",
  "가보자",
  "가동",
  "때까진",
  "만나야",

  "인간들이",
  "썼어요",
  "올라와",
  "언니를",
  "그만하고",
  "회복",
  "이것들",
  "추가",
  "반복한다",
  "밤마다",
  "바깥",
  "기능을",
  "순수한",
  "미래가",
  "수연아",
  "일하지",
  "후엔",
  "차이가",
  "나두",
  "자전거",
  "아빠야",

  "숨기고",
  "놓친",
  "분들이",
  "응답하라",
  "너희들의",



  "샐리",
  "짠",
  "그애가",
  "지나서",
  "왕자님",
  "엘사",
  "못했습니다",
  "크리스틴",
  "그런데요",
  "알아봐",
  "죄송",
  "귀를",
  "없으세요",
  "보내지",
  "얘기하면",

  "술집에서",
  "차우",
  "죽이면",
  "좋지만",
  "스타",
  "정의를",

  "미안하게",
  "성공할",
  "혹시라도",
  "무기가",
  "줄거야",
  "마약을",
  "거짓말은",
  "동안에",
  "생명이",
  "일들은",

  "환영해",
  "재밌게",
  "스티븐스는",
  "따르는",
  "거냐고",
  "재밌어",
  "어머니도",
  "뭘까",
  "낮은",
  "하거나",
  "아가씨는",
  "나쁘게",
  "간단하게",
  "버리지",

  "바라고",
  "싶어서요",
  "화학",

  "죽여버릴",

  "귀에",
  "방해해서",
  "능력",

  "아녀",
  "날이야",
  "열려",
  "명심해",
  "머리도",
  "아씨",
  "놔두고",
  "지옥",
  "환자들",
  "베스",
  "돈만",
  "주시면",
  "이들을",

  "여기저기",
  "멋져요",
  "내에",
  "부끄러운",
  "아파서",
  "아네",
  "뭣이",
  "단어를",
  "지나치게",
  "괜찮겠어",
  "이죠",
  "그러게요",
  "길고",
  "친구들과",
  "할께",
  "전문",
  "파일",

  "결정은",
  "좋을거야",
  "패트릭",

  "왜들",
  "원할",
  "서로의",
  "선수가",

  "잡은",
  "과거의",
  "시작하죠",
  "왔잖아",
  "신께",
  "하늘에서",
  "무거운",
  "용기가",
  "역사를",
  "옆집",
  "형씨",
  "체육",
  "아버지한테",
  "편지가",
  "사모님",
  "애들아",

  "빠졌어",
  "시험을",
  "목적은",
  "비상",
  "버텨",
  "셈",
  "그곳에서",
  "되는데요",
  "않겠지만",
  "생각하니",
  "않길",
  "상태입니다",
  "거구요",
  "닥쳐요",
  "칭",

  "알아낸",
  "새끼들아",

  "클램프",
  "깨끗한",
  "상태",
  "없어서요",
  "과정을",
  "나타날",
  "법은",
  "진정하고",
  "그렇네요",
  "보는데",

  "그만둬요",
  "썅",
  "경우에",
  "아니니까요",
  "살뤼스트",

  "얘기나",
  "모른",
  "도와드릴",
  "거구나",
  "선장님",
  "자식들이",
  "이렇게까지",
  "화상",
  "오늘의",
  "기다려라",
  "때나",
  "커서",
  "떨어졌어요",

  "양반이",
  "원숭이",
  "효과",
  "말씀은",
  "예전의",
  "봉합",
  "아프지",
  "속도로",
  "홈즈씨",
  "에요",
  "순순히",
  "행운의",
  "죽어야",
  "닫아",
  "은행에",
  "땅에서",
  "실례하겠습니다",
  "사라질",
  "웃겨",
  "니나",
  "원한다고",
  "사람이요",
  "들어갔어요",
  "싫어서",
  "올리버",
  "머",
  "착하게",

  "보내서",
  "그랬다고",
  "대비해서",
  "집까지",
  "잘할",
  "배는",

  "조심해서",
  "말하는데",
  "그래야만",
  "않을꺼야",
  "지원",
  "구멍",
  "힘으로",
  "쳐다보지",
  "공장",
  "엘나",
  "짜증나",
  "얘기해요",
  "불법",
  "인형",
  "척추",
  "블랙홀은",
  "헤리",
  "남았습니다",
  "아무말도",
  "정부가",
  "삼룡아",
  "그니깐",
  "노",
  "축하",
  "거지만",
  "상관이야",
  "맞네",
  "다양한",
  "공기",
  "바늘",
  "암튼",
  "어우",
  "하퍼",
  "아버님이",
  "동일한",
  "말해줄게",

  "좋은데요",
  "지내요",
  "그러네",
  "부상을",

  "시작하고",

  "화",
  "믿는다",

  "저놈",
  "싶었던",
  "원인을",
  "책에",
  "인",
  "나루토",
  "도시에",
  "쓸모가",
  "걱정하지마",
  "길이야",
  "고향",
  "필요한데",
  "싶니",
  "현지어로",
  "다행이네",
  "이름의",
  "일들",
  "당신들을",
  "형님이",
  "섬에",
  "로켓",
  "바빠요",
  "하셔야",
  "오늘밤은",
  "도움은",
  "설명을",
  "맡기고",
  "태어날",
  "신부",
  "허가",
  "용기를",
  "아니군요",
  "한때",
  "없구요",
  "뚱뚱한",
  "사라졌어요",
  "정작",
  "스테판",
  "알고있어",
  "했으니까",
  "받았지",
  "조그만",
  "나에겐",
  "발생",
  "어렵지",
  "되었을",
  "공기가",
  "나갔다",
  "밤은",

  "알았다면",
  "시도는",
  "이겼어",
  "요리",
  "뉴욕에서",
  "되었지",
  "고만",
  "그랬는지",

  "전부를",
  "이것들이",
  "몸도",
  "머리카락",

  "않는게",
  "가던",
  "제정신이",
  "쪽에서",
  "외계인",
  "낫게",
  "보셨나요",

  "하더라도",
  "걸지도",
  "모습은",
  "되돌릴",
  "받아서",
  "친구지",
  "우리처럼",
  "당신에겐",

  "차트를",
  "물어보세요",
  "있는거죠",
  "영희야",
  "은하의",
  "잡는",
  "곧장",
  "이런걸",

  "자기한테",
  "털",
  "퀼",
  "일이니",
  "얭",
  "온게",


  "젊은이",
  "쐈어",
  "나네",
  "과거에",
  "내에서",
  "그러려고",
  "왜지",
  "니는",
  "살을",
  "남자로",
  "달을",
  "알면서",
  "친구로",
  "고래",
  "직장을",
  "요점은",

  "만났는데",
  "순식간에",
  "확신해",
  "소",
  "탁",
  "한다구",
  "끔찍해",
  "감정은",
  "백화점",
  "있는거에요",
  "약속은",
  "그러네요",
  "자면",
  "전화번호",
  "옆",

  "트럭을",
  "통화",
  "수술실이",
  "동만아",
  "목적이",
  "베고",
  "어딨나",

  "학생",
  "내일이",
  "작전을",
  "못했지만",
  "좋겠군요",
  "부분",

  "스틸",

  "갔나",

  "거겠죠",
  "사실이에요",
  "어여",
  "도와달라고",
  "알았으면",
  "떠나요",
  "숫자",

  "손도",
  "이끌고",
  "일부",
  "놓으세요",
  "기운이",
  "여러분을",
  "싫고",
  "일의",
  "용서할",
  "들어갔어",
  "받아들이지",
  "가도록",
  "상자",
  "소리예요",
  "주여",
  "같지는",
  "아파트에",
  "테사",
  "극적인",
  "두들겨",
  "없니",
  "테디가",
  "막스",
  "상대로",
  "잡아서",
  "변했어",
  "하찮은",
  "수백",
  "맨디",
  "지하",
  "위성",
  "신경도",
  "전형적인",
  "마일",
  "나무들이",
  "좋더라",
  "시골",

  "음성",
  "일이예요",
  "위치",
  "오십시오",
  "했었는데",
  "받기",
  "흑인",
  "보여주지",
  "보네요",
  "얻게",
  "재키",
  "같으니라고",
  "부모님께",

  "풀고",
  "경기를",
  "금발",
  "받았는데",
  "보여주는",
  "마리아",
  "말하지마",
  "수영을",

  "토니",
  "걍",
  "새를",
  "주머니에",
  "결혼해",
  "진행",
  "너라면",

  "밤낮",
  "친구랑",
  "중이지",
  "걱정마세요",

  "제법",
  "케이틀린",
  "아버지께",
  "기도",
  "조니가",
  "없는지",
  "구두",
  "나라가",

  "에이그",
  "기회",
  "절개",
  "버크한테",
  "들었나",
  "제거",
  "똥을",
  "약속한",
  "테디는",
  "않은가",
  "못했지",
  "쳐다보는",
  "상대를",
  "선생님께서",
  "친구와",
  "있던데",
  "우리들",

  "싸움은",
  "분명해",
  "구경",
  "괴물을",
  "명이나",
  "바보같이",
  "원해서",
  "그앤",
  "뿐인데",
  "쓸모",
  "바라요",
  "사랑하게",
  "저쪽",
  "낳고",
  "교수",
  "오셔서",
  "덤벼",
  "잘됐어",
  "막혀",
  "작년",
  "원하던",



  "버크의",
  "예쁘네요",
  "올리비아",
  "소녀가",
  "모습",
  "올라가서",

  "년간",
  "좋아했어요",
  "핸드폰",
  "보고싶어",
  "이것만",
  "왜이래",
  "진짜가",
  "안내해",
  "사이를",
  "다룰",
  "거네요",
  "으로",
  "발작이",

  "짜리",
  "된단",
  "여는",
  "스티븐스가",
  "풀",
  "피자",
  "가시는",
  "할머니를",
  "나타나지",
  "강제로",
  "가네",
  "끝을",
  "독립",
  "문으로",
  "직전에",
  "괜찮나요",
  "당할",

  "가버려",
  "디제이",
  "두려운",
  "실패",
  "발작",
  "증명할",
  "성공",
  "여자에",

  "피부",
  "도둑",
  "무기는",
  "조각",
  "싶고",
  "부상",
  "걸려서",
  "프로그램",
  "하나밖에",
  "밀고",
  "고양이가",
  "결국은",
  "못했죠",
  "명예를",
  "동무는",
  "무대",
  "별을",
  "놀",
  "일어났는지",
  "녹색",
  "수술실을",
  "밖의",
  "씻고",
  "바가",
  "김에",
  "안가",
  "어딨죠",
  "중간",
  "리드",
  "무엇인가",
  "팔고",
  "가능해",
  "한을",
  "정맥",
  "로드",
  "두렵지",
  "쓸모없는",
  "건들지",
  "무섭지",
  "것뿐이에요",
  "구한",
  "시계가",
  "저보다",
  "어어",
  "어깨",
  "맡게",

  "여동생",
  "나같은",
  "대하여",
  "있더라도",

  "유감이야",

  "말구요",
  "죽었지",
  "권력을",
  "양을",
  "지독한",
  "힘들어요",

  "내말",
  "서로가",
  "행동",
  "부부가",
  "밤새도록",
  "연극",
  "데려올",
  "그랬더니",
  "대화가",
  "애런",
  "한국",
  "않겠다",
  "그럴리가",
  "음식은",
  "생각하는데",
  "상사",

  "어쩐지",

  "그쪽은",
  "부인과",
  "싶지도",
  "싶다는",
  "되거든요",
  "다행히",
  "악마가",
  "맞나",
  "없었다면",
  "인류가",
  "모리스",
  "고개를",
  "개나",
  "일이고",
  "잘됐다",
  "이기면",

  "아래에",
  "나아요",
  "되진",
  "직장",
  "잊었어",
  "간신히",
  "권리를",
  "하겠네",
  "멋대로",
  "보이는데요",
  "앞을",
  "차가운",
  "커피를",
  "나한테도",
  "이랴",
  "믿습니다",
  "되겠군",
  "때리지",
  "노틀담",
  "가정을",
  "소개합니다",
  "조금도",
  "거기엔",
  "학교가",
  "않을겁니다",
  "보내줘",
  "그랬을",
  "수백만",


  "숲에",
  "도와줄까",
  "뒤엔",
  "주머니",

  "친구인",
  "빠져나갈",
  "아름다워요",
  "해주십시오",
  "자의",
  "그래야죠",
  "저번",
  "남겨",
  "교열",
  "돌볼",
  "딜런",
  "향하고",
  "이용",
  "있냐고",
  "아침이에요",
  "성적",

  "이래도",
  "고장",
  "거리",
  "직원",

  "요원님",


  "보고서를",
  "넣은",
  "블랙홀",
  "뛸",
  "형제가",
  "보석",

  "닥터가",
  "거꾸로",
  "아빠에게",
  "저보고",

  "할텐데",
  "온갖",
  "말인데요",
  "낸",
  "당신만",
  "먹기",
  "하느라",
  "회사를",
  "테리",
  "무서워서",
  "엉",
  "용서하세요",

  "그룹",
  "위치로",
  "넬",
  "엘리베이터",
  "얘기했어",
  "괜찮을까요",
  "얘를",
  "불가능해요",
  "본부",
  "눈물",

  "틀림없어",
  "보여주고",
  "다오",
  "들어왔어요",
  "인정해",

  "강하고",
  "싼",
  "오는데",
  "알았을",
  "많죠",
  "가르시아",
  "메러디스랑",
  "호출했어",
  "되겠나",
  "진심이에요",
  "만들었다",
  "결혼식을",
  "자기도",
  "어디에서",
  "날씨",
  "끌어",
  "미쳤어요",
  "우리에",
  "당신은요",
  "오말리는",
  "후회하게",
  "기대",
  "인턴들",
  "그림자가",

  "옷도",
  "처음이야",
  "아저씨도",
  "내기",
  "보도록",
  "그놈은",
  "사흘",
  "해보자고",
  "결정",
  "수고했어",
  "넘는",
  "곳이지",
  "호도",
  "그런다고",
  "관을",

  "허락을",
  "트레이",
  "기쁜",
  "임시",
  "끄는",
  "친구의",
  "아가씨들",
  "아오",
  "진짜요",
  "조지를",
  "죽음은",
  "기다리면",
  "거기까지",
  "안하면",
  "될꺼야",
  "자네한테",
  "뿐이라고",
  "원하는건",
  "생각나",
  "도착하면",
  "쏜",
  "살면",
  "아니라요",
  "파리에",
  "예뻐요",
  "잔인한",
  "피부를",
  "발견했어요",
  "계시는",
  "흉부",
  "법이지",
  "그까짓",
  "볼수",
  "알았소",
  "겪고",
  "하늘의",
  "일라",
  "있다니",
  "토레스가",
  "아니라서",
  "저만",
  "지시를",
  "사회",

  "얘한테",
  "몸에서",

  "할걸",
  "피해자",
  "배우는",
  "하루는",
  "알려줄",
  "알겠는데",
  "축하한다",
  "먹여",
  "더요",
  "방해하지",
  "기뻐요",
  "묻은",
  "있을꺼야",
  "전부다",
  "거짓말이야",
  "글렌",
  "불에",

  "해줄래",
  "전혀요",
  "지나가는",

  "고맙네요",
  "신경이",
  "꼬맹아",
  "에이버리",
  "있거라",
  "힘은",
  "따라야",
  "확인할",
  "이러다",
  "무섭게",
  "부끄러워",
  "검을",
  "어떨",
  "되네요",
  "푸른",
  "뭐라는",
  "제말은",
  "카일",
  "반갑다",
  "그들도",
  "곧바로",
  "구했어요",
  "죽는다면",
  "일인",
  "촬영을",
  "시를",
  "알던",
  "넣는",
  "누구니",
  "어떻고",
  "제로",
  "장이",
  "갈색",
  "압력을",
  "창문",
  "있다는걸",

  "맞게",
  "영광을",
  "찾아낸",
  "별로야",
  "경험을",
  "나눠",
  "불렀어",
  "엘리",
  "선배님",
  "놓치지",
  "한꺼번에",
  "있겠군",
  "오길",
  "아니라구",

  "하곤",
  "둘만",
  "아무렴",
  "그랬다면",
  "계시잖아요",
  "안나요",


  "나오세요",
  "썼어",
  "잠자리를",
  "많다",
  "중력",
  "최후의",
  "금속",
  "넓은",
  "퇴원",
  "오나",
  "화살을",

  "기억하세요",
  "않잖아요",
  "선생님과",
  "책은",
  "지구에",
  "어른",
  "드리는",
  "진단을",
  "전화기",
  "래디스",
  "수지",
  "그랜디",
  "뿐만",
  "교회",
  "아버님은",
  "이년",
  "가족들",
  "도시는",
  "넣어서",
  "재수",
  "녹음",
  "조심히",
  "한다구요",
  "하겠지만",
  "월터",
  "시신을",
  "니들이",


  "느껴",
  "그나마",
  "박",

  "사람이라면",
  "킴",

  "더럽게",
  "왔네요",
  "틀렸어요",
  "후에는",
  "마을의",
  "원한다",
  "공간이",
  "필요하죠",
  "사람인",
  "했었어요",


  "그사람",
  "줘야지",

  "일어",

  "배로",
  "올릴",
  "이런게",
  "죽이게",
  "사는데",
  "웃기는",
  "제군들",
  "방식을",
  "에이미가",
  "오로지",
  "코끼리가",
  "베스트",
  "사무실",
  "해봤어요",
  "없는거야",

  "조사",
  "데이비드",
  "폭발",
  "같니",
  "자게",
  "뭐를",
  "헬기",
  "이상을",
  "상관없는",
  "가는게",
  "인자",
  "준다",
  "시작합시다",
  "실수는",
  "된단다",
  "남자한테",
  "만들려고",
  "좋겠구나",
  "돌봐",
  "못난",
  "살펴",
  "안해도",
  "뭐였죠",

  "그럴거야",
  "방사선",

  "레베카",
  "고기를",
  "하늘",
  "있을것",
  "잘못은",
  "죽이기",
  "벌어",
  "같았다",
  "초음파",
  "년아",
  "염소",
  "론",
  "현실이",
  "문제라도",

  "바른",
  "피터가",

  "일어났어",
  "안녕하시오",
  "좆나",
  "물어보고",
  "방법도",
  "만든다",

  "녀석들은",
  "인턴들이",
  "받으러",
  "살아갈",
  "하시지",
  "말아줘",
  "걸려요",
  "낫다",
  "우리만",
  "셔츠를",
  "고통스러운",
  "한명이",
  "바라네",
  "조언을",
  "쏜월드",
  "학생이",

  "남기지",
  "부인에게",
  "부족한",

  "움직여요",
  "있었구나",
  "런던에",
  "떠나기",
  "해변에서",

  "없어진",
  "없도록",
  "또다시",
  "아이와",
  "봤니",
  "표",
  "인턴을",

  "붙일",
  "맞춰서",
  "상태에",
  "부르세요",

  "영화는",
  "기사를",
  "쳐다봐",
  "어떻소",
  "수녀님",
  "이론은",
  "짓고",
  "말고는",
  "조카",
  "식당에서",
  "보고서",
  "흉부외과",

  "기술",
  "지켜보는",


  "떨고",
  "각각의",
  "가졌어",
  "안하는",
  "약속해요",
  "싸",
  "말라는",
  "아저씨를",
  "사랑과",
  "다리에",
  "된거죠",
  "달렸어",
  "못했던",
  "고급",
  "심해",
  "부분에",
  "너희도",
  "서쪽",
  "대개",
  "보십시오",
  "행성이",
  "그것만",
  "낫다고",
  "가르치는",
  "보물을",
  "있지도",
  "델타싸이",
  "예정입니다",
  "할아버지는",

  "했냐",
  "쓰게",

  "젤",
  "에바",
  "악마의",
  "빨리빨리",
  "그러셔",
  "보겠습니다",
  "에게",
  "인형을",
  "앤드류",
  "만들어진",
  "가능성을",
  "불러도",
  "마다",
  "좋았을",
  "정신적",
  "라이터",
  "치명적인",
  "없던",

  "아그네스",
  "최상의",
  "있기를",
  "사령관",
  "원했던",
  "북쪽으로",
  "병원의",
  "기적을",
  "희망은",
  "응급실에",
  "얘기함",

  "겪은",
  "앉아도",
  "하워드",
  "원래는",
  "침착하게",
  "열어요",
  "보이진",
  "저절로",

  "않겠소",
  "오버",
  "다니",
  "드문",
  "두려워요",
  "물어보면",
  "말좀",
  "이런거",
  "굴어",
  "자들이",
  "드라이브",
  "마디",
  "개새끼",
  "최종교열",
  "썩은",

  "버렸어요",
  "마을에서",
  "역겨운",
  "수록",

  "심각해",
  "봤다",
  "웨스트",
  "도착했습니다",
  "했을까",

  "설탕",
  "사인",
  "병은",
  "살려줘",
  "두수씨",
  "기록이",
  "앞으로는",
  "아래에서",
  "들여다",
  "앉게",
  "않군",
  "싸고",
  "창문을",
  "예상",
  "아참",
  "지켜라",
  "환",
  "모르니",
  "우측",
  "안정을",

  "오시는",

  "알아냈어",
  "놓치면",
  "타츠오",
  "했겠지",
  "스스로에게",
  "재능을",
  "아내에게",
  "간의",

  "빌려",
  "일어나라",
  "우주가",
  "그만들",
  "두개",
  "오죠",
  "산소를",
  "뭘하고",
  "정체를",
  "사장",
  "모르나",
  "승상",
  "모르겠구나",
  "게임은",
  "말할게요",
  "텍사스",
  "변하는",
  "본데",
  "기",
  "시작했죠",
  "낙하",
  "말씀드릴",
  "없을까요",
  "차트리",
  "오셨군요",
  "않았네",
  "내리지",
  "곡",
  "한대요",
  "내서",
  "나타나는",
  "겁쟁이",
  "병장",
  "로스",
  "들이",
  "연료",
  "장면을",
  "수술에서",
  "위의",
  "목표는",
  "훈련을",
  "던컨",
  "어허",
  "옥희",

  "싶은거야",
  "적어",
  "상관할",
  "셋에",
  "사이먼",

  "인간과",
  "마누라가",
  "스트레스를",
  "사라지고",
  "새벽에",
  "숫자가",
  "같기도",

  "임자",
  "서명",
  "숲에서",
  "입으로",
  "넥타이",
  "걸리면",
  "찾았습니다",
  "전사가",
  "미처",
  "치면",
  "여러가지",
  "것일",

  "어딨니",
  "걷는",
  "얻지",

  "날짜를",
  "자리는",
  "침대로",
  "생각해야",
  "있었잖아요",
  "중의",
  "가난한",
  "삶에서",
  "이지한테",
  "가족에",
  "방향을",
  "집이에요",
  "그러실",
  "그렇네",
  "사람들이야",
  "하셨습니다",
  "밤중에",
  "쟤들은",
  "기억하는",

  "천국",
  "특이한",
  "들어오고",
  "만져",
  "부드러운",
  "빠질",
  "번씩",

  "이해하는",
  "오셨습니다",
  "프레드",
  "요리를",
  "화장실에서",
  "잃지",
  "의학적",
  "깨고",
  "아니었죠",
  "감기",
  "투",
  "내려가서",
  "무료",
  "전사",
  "포기할",

  "괜찮다는",
  "메이",
  "안되죠",
  "전쟁에",

  "불러야",
  "가봐야겠어",
  "라우리",
  "좋으면",
  "표정을",
  "이상하지",
  "할려고",
  "새끼를",
  "소령",
  "낡은",
  "짜증나는",
  "뛰고",
  "싸구려",
  "시작해야",
  "방해가",
  "이곳의",
  "겨울이",
  "장난해",
  "역사에",
  "생각나는",
  "타입이",
  "체크",
  "시장",
  "녀석들이",
  "망칠",
  "미치게",
  "있답니다",
  "찾으려고",
  "맡을게",
  "게임에",
  "경보",
  "조사해",
  "과장",
  "없었잖아",
  "가족도",
  "흔적을",
  "쪽이",

  "섹스가",
  "그렇게는",
  "하하하",
  "혈관",
  "에이프럴",
  "즐거웠어요",
  "움직이면",
  "숲을",
  "의사야",
  "안보여",
  "꿈에",
  "안됐어",
  "좋아한다고",
  "돌아왔네",
  "부르면",
  "전화했어",
  "겸",
  "들리나",
  "가치를",
  "걔도",

  "정도가",
  "했으니",
  "모양이군",
  "숨기는",


  "경기가",
  "머릿속에",
  "성공하면",
  "붙잡아",
  "살인자",
  "장소는",
  "하는거죠",
  "명단에",
  "붙은",
  "세번째",
  "법이야",
  "보살펴",
  "해야할지",
  "사러",
  "되려고",
  "메러디스의",
  "아시겠어요",
  "말아주세요",
  "소녀는",
  "메스를",
  "호랑이",
  "어쩌라고",
  "용서하지",
  "팬티",
  "좋아하나",
  "안경을",

  "지겨워",
  "앉으시죠",
  "도와줄게",
  "떡",

  "깔끔하게",
  "한명",
  "될겁니다",
  "후에도",
  "모르겠소",

  "것의",
  "많으니까",
  "생각하십니까",
  "반장님",
  "웃을",
  "버크를",
  "분노를",
  "후회할",
  "달리고",
  "은하가",
  "약속이",

  "몰랐네",
  "있는지는",
  "캐롤",
  "왔으면",
  "꿈에서",
  "열면",
  "편안하게",
  "심해요",
  "하더니",
  "말든",
  "차도",
  "폭탄이",
  "생각해봤는데",

  "대동맥",
  "딕",


  "누군가와",

  "걸어서",
  "카터",
  "조사하고",
  "시절",
  "였어요",
  "사람들처럼",


  "정이",
  "맘이",
  "날도",
  "나뭇잎",
  "루비",
  "가든",
  "말이라도",

  "유지",
  "글로리아",
  "말하라고",
  "세상엔",
  "피는",
  "되어야",
  "좋아하게",
  "노래는",
  "돌리고",
  "안전하다고",
  "인간들은",
  "소원을",
  "자매님",
  "사이에서",
  "드실래요",
  "감염",
  "주님의",
  "시카고",

  "알려주세요",
  "정리",
  "했군",
  "열지",
  "가야돼",
  "희망",
  "나오게",
  "동물",

  "매일같이",
  "꺼낼",
  "판단을",
  "죽여서",
  "왔니",
  "자긴",
  "몰랐지",
  "야이",
  "같을",
  "빚",
  "본적",
  "없었으면",
  "잘됐군",

  "운동",
  "닫고",
  "풋",
  "산부인과",

  "경력을",
  "충돌",
  "물건은",
  "보였어요",
  "탱크",
  "아이들에게",
  "기저귀",
  "릴리가",
  "친구잖아",
  "조심스럽게",
  "조명",
  "아저씬",

  "할때",
  "젖은",
  "중심에",
  "잠들",

  "됐으니",
  "달리는",
  "동물을",

  "규칙은",
  "채워",
  "같은거",
  "지나친",
  "군인이",
  "낫죠",
  "침대를",

  "바꿨어요",
  "하는군",
  "케이티",
  "느꼈어요",
  "나타난",
  "렉시가",
  "한건",
  "개리",
  "됐니",
  "지방",
  "이러세요",
  "다쳤어",
  "길게",
  "버리는",
  "인턴들은",
  "공간을",
  "수술해야",
  "알을",
  "타이윈",
  "만나자",
  "기운을",
  "봤거든",
  "하십시오",
  "나니까",
  "살았어요",
  "풀어줘",
  "친애하는",
  "두세요",
  "하나에",
  "핵",
  "잡으면",

  "봤소",

  "여러분께",
  "날개",


  "총알",
  "놓지",
  "노력은",
  "인류를",
  "굴",
  "수없습니다",
  "줄까요",
  "맷",
  "지나갈",
  "자기들",
  "봤고",
  "비행",
  "했다구요",
  "스무",
  "가져와요",
  "외부",
  "내용은",

  "없다네",
  "닭",
  "따라와요",
  "열린",
  "호흡",
  "아일",

  "만나기",
  "단검을",

  "출발해",
  "들어오면",
  "내말이",
  "그러시겠죠",
  "폐하를",
  "조앤",
  "들었을",
  "옳았어요",
  "치킨",
  "연습",
  "댄",
  "딸에게",

  "동네에",
  "혹시나",
  "후회",
  "찾아라",
  "잘된",
  "했고요",
  "들리는",
  "준비됐나",
  "본다",
  "줬는데",
  "여자애를",
  "잘했네",

  "로저스",
  "없어졌어",
  "모니터",

  "로레인",
  "아내한테",
  "밖엔",
  "덩치",
  "너희의",

  "구룡아",
  "믿으세요",

  "계약",
  "사방에",

  "인터넷",
  "타고난",

  "창조주께서",
  "생겼다",
  "잘가",
  "같나",
  "죽였다",
  "어둠이",
  "보이세요",
  "시간만",
  "구조",
  "녀석의",
  "행동은",
  "테디를",
  "마찬가지로",
  "애비",
  "남자에게",
  "마샬",
  "국가",
  "사람하고",
  "중이었어요",
  "말이군요",
  "생각해봐요",

  "시간입니다",
  "막는",
  "전부가",
  "조지랑",
  "좋다는",
  "형편없는",
  "뽕",
  "발견된",
  "다음에는",
  "좋아하는지",
  "전과",

  "너희에게",
  "고맙게",
  "돌아가자",
  "해볼",
  "저길",
  "사실입니다",
  "같다는",
  "잘해요",
  "즐기고",
  "남",
  "그중",
  "괜찮겠죠",
  "나서는",
  "로건",
  "품고",

  "멍청하게",
  "없었지만",
  "방송을",
  "가겠어",
  "말란",
  "만큼은",
  "모양인데",
  "됐군요",
  "에휴",
  "불록",
  "이해하고",
  "있어봐",
  "사진은",
  "장난이",
  "가능성도",
  "활화산",
  "잊게",
  "갚을",
  "좀만",
  "아니네요",
  "셀리아",
  "앉아라",
  "수십",
  "분리",
  "중학교",
  "총상",
  "어머닌",
  "갇힌",
  "올해",
  "분만",
  "차량",
  "학교는",
  "필립스",
  "물어볼게",
  "없었다고",
  "댁의",
  "악몽을",
  "구해야",
  "경찰들이",
  "치지",
  "가치",
  "아시나요",

  "느껴져요",
  "연구를",
  "조심해야",
  "해칠",
  "죽을거야",
  "누명을",
  "끝났다고",
  "뽑아",
  "한글자막",
  "침착해",
  "그렇잖아",
  "감옥",
  "내게서",
  "해봤는데",
  "바꾸고",
  "자지",
  "잊은",
  "좋아하죠",
  "뭔지는",
  "어린애",
  "얘기만",
  "불행히도",
  "주겠네",
  "비명을",


  "따위는",

  "나좀",
  "여성이",
  "바꾼",
  "부리는",
  "나가라고",
  "쉬어야",
  "할일이",
  "녀석이야",
  "뿐이예요",
  "승리를",
  "되냐",
  "차우더",
  "보여서",


  "거친",
  "겁나",
  "잘못을",
  "아삐꼬까",
  "놨어",

  "축하하네",
  "얌전히",
  "가기로",
  "브래들리",
  "다른데",

  "그대를",
  "이야기하고",


  "엉망이",
  "이의",

  "타스",
  "해보라고",
  "턱",
  "아버지랑",
  "자동",

  "작별",
  "고마웠어요",
  "이것저것",
  "게야",
  "미래에서",
  "게임이",
  "못하죠",
  "독",
  "뗄",
  "멈춰라",
  "응급실",
  "뜻대로",
  "입자",
  "움직여야",
  "뜰",

  "전기가",
  "전자",
  "만지고",
  "웃지",
  "가보겠습니다",
  "로자",
  "이전",
  "이제서야",
  "에디슨",
  "모르겠는데요",
  "생명체가",
  "원하시면",
  "상상을",
  "쟤들이",
  "방향",
  "필요하단",
  "들리지",
  "당신같은",
  "야옹",
  "카메라가",
  "하구요",
  "짝이",
  "아무일도",
  "얘기지",
  "편안히",
  "단서가",
  "다리는",
  "하겠다는",
  "수없이",
  "너하고",
  "마구",
  "만들어요",
  "아침을",

  "안될까",
  "해를",
  "대화는",
  "거짓말이",
  "자꾸만",


  "구역",
  "세계의",
  "딸이야",
  "쟤들",
  "누굽니까",
  "얼간이",
  "나머진",
  "눈물이",
  "자격",
  "꽃이",

  "엉망이야",


  "버리면",
  "남자도",

  "수고",
  "나름대로",
  "집안에",
  "필요해서",
  "짜",
  "하겠나",
  "밟아",
  "바퀴",
  "읽었어요",

  "약속했잖아",
  "다치지",
  "위치가",
  "주렴",
  "용의자",
  "죽음과",
  "일일이",
  "현",
  "마음으로",
  "특정",
  "보여주세요",
  "있겠습니까",
  "전화하지",
  "들었잖아",

  "시선을",
  "삶과",
  "나게",
  "숨도",
  "않으니",
  "세고",
  "커요",
  "주기로",
  "보시면",
  "시집",
  "멋있는",
  "않으려고",
  "수행",

  "됐구나",
  "데니가",
  "됐잖아",

  "죽었는데",
  "행복할",
  "가다가",
  "로사",
  "쩨나이",

  "신세",
  "종양은",
  "싫다는",
  "히컵",
  "깨어",

  "대단해요",
  "주시고",
  "마음도",
  "맹세해",
  "싶구나",

  "원인은",
  "주사를",
  "전진",
  "해달라고",
  "없앨",
  "때문일",
  "사지",
  "라면",
  "차리고",
  "했잖니",
  "구스타브",
  "하나입니다",
  "마른",
  "않고요",
  "면에서",
  "못생긴",
  "열쇠는",
  "아프다고",
  "쫓는",
  "보시오",
  "갔다가",
  "선물이",
  "취한",
  "무단",
  "로렌",
  "해주면",
  "브래드",
  "앉을",
  "내려놓고",
  "앨리샤",
  "그쪽으로",
  "필요하고",
  "고문",
  "그렉",
  "어머님이",
  "주라고",
  "마크는",

  "전적으로",
  "호출했어요",
  "누르면",
  "마찬가지고",

  "나선",
  "와인을",
  "빨라요",
  "대장님",
  "아들에게",

  "나빠",
  "알겠소",
  "시작해요",
  "시작됐어",
  "아라리가",
  "국장님",
  "존은",
  "살아있어",
  "데려가고",
  "치프는",

  "산에서",
  "피오나",
  "남자답게",

  "착하고",
  "놨어요",
  "대부분이",

  "장소가",
  "의사의",
  "안전해요",

  "보군요",
  "시간과",
  "빨리와",
  "준비한",
  "재판",
  "이겨낼",
  "계획대로",
  "애니",
  "맞다면",
  "수치",
  "기본",
  "위험합니다",

  "영상을",
  "부적절한",
  "피부가",
  "그러시죠",
  "혈액을",
  "있으신가요",
  "모여서",
  "완벽히",
  "쉬어요",
  "고쳐",
  "미라",

  "하겠소",


  "생각이에요",
  "본다면",

  "계단",
  "방향으로",
  "계산",
  "걸리는",
  "바로는",
  "보내요",
  "택시를",
  "스콧",

  "몇몇은",
  "못했는데",
  "데요",
  "달걀",
  "죽이러",


  "사랑한",

  "언제요",
  "죠",
  "끄고",
  "바에",
  "원하고",
  "쉘든",
  "우리보다",
  "그라고",
  "실패하면",
  "막아야",
  "보내세요",
  "것이요",
  "이요",
  "몰랐던",
  "계약을",
  "셈이지",
  "도와주는",

  "인사를",
  "미쳤나",
  "회의에",
  "존재",
  "알았으니까",
  "골라",

  "추운",
  "바보처럼",
  "고정",
  "결혼도",

  "정의의",
  "이사를",
  "되거든",
  "죽는다는",
  "키스해",
  "일루",
  "어제는",
  "부대",
  "들었니",
  "사람들에",
  "장난감",
  "아들이야",
  "봤는데요",
  "바이탈은",
  "생존",
  "흰",
  "방식이",
  "얌마",

  "사냥",
  "뛰어요",
  "슬픔을",
  "보내면",
  "선택",
  "대략",
  "기차를",
  "한글번역",
  "누군데요",
  "쳐다보고",
  "열차는",
  "친절하게",
  "봤으면",
  "검색",
  "지냈어요",
  "빚이",

  "재미로",
  "도시에서",
  "흔들어",
  "스님",
  "누나가",
  "텅",
  "만들면",
  "장비",
  "문도",
  "쏘는",
  "보고해",
  "주기",
  "결혼했어",
  "아끼는",
  "않겠나",

  "행복해요",
  "호의를",
  "차마",
  "해보지",
  "죽",
  "당하고",
  "품에",
  "말하지만",
  "기상",
  "파리에서",
  "잃어",
  "알프레드",
  "조지의",
  "아실",
  "받았다",
  "아깐",
  "어유",
  "강을",
  "의사예요",
  "씨에게",
  "세월이",
  "싸워야",
  "유령이",
  "맞나요",
  "릴리",
  "찾아냈어",
  "너같은",
  "어르신",

  "잘못했어",
  "지도를",
  "잘은",
  "아이야",
  "수표를",
  "박사의",
  "그럴까요",

  "알아보고",
  "물어보지",
  "겨",
  "안녕하신가",
  "때처럼",
  "피셔",
  "피하는",
  "코드를",
  "싣고",
  "거시기",
  "누군가에게",
  "전기를",
  "값을",
  "사서",
  "빌어요",
  "도와줘야",
  "소리지",
  "해야해요",
  "시켜서",
  "닥치라고",
  "꺼요",
  "잘못이에요",
  "원하시는",
  "음반",
  "무얼",
  "형의",
  "어떡하죠",

  "속일",
  "꼬맹이",
  "살려고",
  "주시는",
  "그분을",
  "솜씨가",
  "사다",

  "중력이",

  "은행을",
  "말레피센트",
  "빕니다",
  "열리는",
  "된다고요",

  "외에",
  "따르지",

  "예의",
  "밝고",

  "훌륭해요",
  "계단으로",
  "이해하기",
  "마이키",
  "분들은",
  "검사는",


  "살수",
  "당신이나",
  "알거든",
  "물어본",
  "그만두고",
  "지구의",
  "오스본",
  "가시오",
  "본다고",
  "봤던",
  "영화가",
  "세계에",
  "사람이었어",
  "떠나게",
  "온종일",
  "버크랑",
  "법정에서",
  "지켜줄",

  "농담이야",
  "시킨",
  "소변",
  "보여줘요",
  "트래비스",
  "맛있어",
  "한거지",
  "생각하지만",
  "한마디도",
  "각하",
  "나는데",
  "번째는",
  "이쪽에",
  "수영",
  "초질량",
  "않아서요",
  "부르죠",
  "싶었다",
  "이뻐",
  "이쪽이야",
  "동쪽",
  "없는거",
  "마치고",
  "데니는",
  "여태까지",
  "돈이야",
  "됐는지",
  "멈추라고",
  "카레브가",
  "어머니와",

  "따님이",
  "주시기",
  "할거에요",
  "밝히는",
  "있으십니까",
  "비열한",
  "사회적",
  "한심한",
  "이상하네요",
  "알고있는",
  "가봐요",
  "공짜로",
  "눈엔",
  "데이터",
  "우주는",
  "부탁입니다",
  "편한",
  "펜",
  "띄워",
  "가셔도",
  "결국에는",
  "배웠어",
  "운전을",
  "있더라고",
  "넵",
  "가져갔어",
  "있었군",
  "신뢰를",
  "시작된",
  "보다는",
  "에드워드",
  "무시하는",
  "그놈들이",
  "사라지는",
  "컴퓨터를",
  "소리만",
  "들었네",
  "옮길",
  "안할",
  "알렉스랑",
  "찍는",
  "할머니도",
  "괜찮은거야",
  "들어갑니다",
  "콜",
  "안에는",
  "다르죠",
  "도망갈",
  "않을거에요",
  "담고",
  "만요",
  "가르치고",
  "스미스",
  "싫다면",
  "엘리베이터에서",

  "걸쳐",
  "경감님",
  "회사가",
  "않았겠지",

  "극장",
  "먹게",
  "통하는",

  "돌아가게",

  "아니었다면",
  "런던에서",
  "들여",
  "정상으로",
  "한방",
  "자리로",
  "찍을",
  "번째로",
  "강도",
  "알면서도",
  "보실",
  "계시면",
  "꺼내지",
  "난쟁이",
  "줬잖아",
  "작품을",
  "휴식",
  "더러워",
  "가족들은",
  "들여보내",
  "낫겠어",

  "씨와",
  "악의",
  "있자",
  "감염이",
  "소설",
  "각자의",

  "여봐라",
  "재판장님",

  "오스카",
  "살인자가",
  "걔랑",
  "내면의",
  "직원이",
  "가야해요",
  "왔으니",
  "잡아줘",

  "실패한",
  "하실래요",
  "외계",
  "시시한",
  "저에",
  "베일리를",
  "도와주지",
  "사무라이",
  "지옥으로",
  "그대는",
  "버스를",
  "말하려는",
  "메러디스와",
  "보고만",
  "죽겠어요",
  "연기를",
  "보이면",
  "초기",
  "직업",
  "아나요",

  "때리고",
  "테이블",
  "불과",
  "전하를",
  "카메라를",
  "발라",
  "셋이",
  "친구에게",
  "늙은이",

  "같잖아",
  "있더라",

  "이른",
  "신경외과",
  "고개",
  "하겠죠",
  "말해주고",
  "여자예요",
  "잘될",
  "응급실에서",
  "약속하지",
  "보아",
  "다는",
  "싶었지",
  "사랑도",
  "반쯤",
  "있으라고",
  "화났어",

  "철수",
  "길어",
  "갔습니다",
  "흔한",
  "잘하네",
  "도일",
  "아기도",
  "헉",
  "그러지마",
  "놀란",

  "장치가",
  "많거든",
  "안내",
  "조프리",
  "경우가",
  "빛",
  "튜브를",
  "의자에",
  "자들",
  "죽었죠",

  "놓칠",
  "일어나면",
  "지어",


  "망치고",
  "일어났어요",
  "사용해",
  "치",

  "흔적이",
  "앞에선",
  "사전",
  "어울리지",
  "세워요",

  "질질",
  "스벤",
  "랄라",
  "하는걸",
  "보소",
  "지수야",
  "알아들었어",
  "있다네",
  "각본",
  "난다고",
  "몸으로",
  "죽겠네",
  "무슨일",
  "응급실로",


  "왕비",
  "기분이야",
  "과거로",
  "지쳤어",

  "거리는",
  "내버려둬요",
  "미소",
  "동전",
  "소문을",
  "복잡해",
  "맛있게",
  "â",
  "사실에",
  "그때까지",
  "전하께서",
  "공원",
  "눈앞에",
  "기회에",
  "자르고",
  "벌어지고",

  "물러",

  "먹일",
  "어라",
  "철",
  "보다시피",
  "쳐서",
  "너희들도",
  "먼지",
  "땅이",
  "숫자를",
  "넣으면",
  "돈이나",
  "출산",
  "그분의",
  "죽였지",
  "우승",
  "셈이야",
  "그러면서",
  "하여간",
  "트레일러",
  "계단을",
  "그때부터",
  "괜찮으니까",
  "올라갈",
  "왕비가",
  "귀한",
  "방식",
  "베니",
  "저희에게",
  "멋지군",
  "누군",
  "그렇다니까",
  "갖는",
  "곁에서",
  "서비스",
  "중력은",
  "배야",
  "일인데요",

  "알리지",
  "신원미상",
  "같거든",
  "닦고",
  "없다니까요",
  "거슬러",
  "않거든요",
  "아들도",
  "농담을",
  "할겁니다",
  "터커",
  "생애",
  "노력을",
  "은하를",
  "엑스레이",
  "울타리",
  "하란",
  "년에",
  "어디보자",
  "조선",
  "목적을",
  "가족이야",
  "그릇",
  "공주야",
  "안되고",
  "진실이",
  "센",
  "조치를",
  "바래다",
  "바꿔야",
  "동물원",
  "생겼지",
  "버려진",
  "없었거든",
  "상상",
  "도난",
  "있으면서",
  "물질",
  "웬일이세요",
  "소식입니다",
  "식사는",
  "묶어",
  "어떤게",
  "허락하지",
  "하기엔",
  "상상이",
  "게요",
  "오메",
  "강해",
  "뵙죠",
  "덴",

  "보트",
  "아버지에게",
  "울면서",
  "아이씨",
  "버스에",
  "번을",

  "클리닉",
  "드릴께요",

  "그만하면",
  "이러니",
  "환자한테",
  "계셨어요",
  "보시다시피",
  "대사",
  "아시겠지만",
  "전력을",
  "내일까지",
  "친구한테",
  "교육",
  "미소를",
  "믿었어",
  "남자들",
  "뭔진",
  "벤햐민",
  "데려가야",
  "낚시",
  "틀림없어요",
  "주겠어",
  "위험하지",
  "잠든",
  "무장",
  "전체에",
  "때로",
  "차례",
  "묻어",
  "옳다고",
  "떠난다",
  "오줌을",
  "당했어",
  "교회에서",
  "비밀은",
  "밤에는",
  "중요한건",
  "한판",
  "하는거에요",
  "절개를",
  "공통점이",
  "우리들은",
  "루벤",
  "헌트가",
  "자기의",
  "모자라",

  "달은",
  "이불",
  "초대",
  "딕슨",
  "그러는거야",
  "없어질",
  "만질",

  "몇개",
  "누군지도",
  "어머니께",
  "어머니께서",
  "대회",
  "그간",

  "지켜봐",
  "전해줘요",
  "했다고요",
  "구름이",
  "데릭과",
  "루크",
  "브라운",
  "신부가",
  "없겠지만",
  "눈도",
  "저한텐",
  "괜찮은지",

  "다니면",

  "냈어요",
  "연습을",
  "치프한테",
  "버티고",
  "성적인",
  "잘됐어요",
  "화려한",
  "돌아가요",
  "과장님을",
  "쥐",
  "로지",
  "도와주려고",
  "스타니스",
  "남편에게",
  "뭐냐고",
  "쳤어",
  "그랬겠지",
  "싶었을",


  "누구와",
  "볼일",

  "나무는",

  "있겠소",
  "생명의",
  "여기엔",
  "미안하오",
  "여보게",
  "바라던",
  "스스로가",
  "났다",
  "사랑해서",
  "가볼게요",
  "떠나서",

  "지난주에",
  "남편도",
  "넣었어",
  "충분해요",
  "끝장이야",
  "현실은",
  "수술로",
  "것보단",
  "알았잖아",
  "다녀오세요",
  "사람이다",
  "환영해요",
  "고집",
  "다루는",
  "부탁인데",
  "데려가서",
  "뛰지",

  "전화할",
  "보석을",

  "건드리면",
  "단독",
  "산다",
  "몸의",
  "부자가",
  "스파이더맨이",



  "한거에요",
  "싶어도",

  "후의",
  "지을",
  "돌을",
  "결혼하는",
  "죽겠다",
  "좋았다",
  "해결하고",
  "감각이",
  "사랑했어",
  "계시니",
  "북부의",
  "해줄까",
  "미국에서",
  "그녀에",



  "위해서라도",
  "기억나지",
  "만지면",
  "뵙겠습니다",
  "운명은",
  "나이를",
  "배터리",


  "것도요",
  "부부",
  "가져올게요",
  "괴롭히는",

  "가방은",
  "생각지",

  "때야",
  "잘못했어요",
  "잖아",
  "동무가",
  "뻔했어",
  "알려주고",
  "그렇게도",
  "아들의",
  "의식",
  "겁내지",
  "공장에",
  "보신",
  "그대의",
  "네놈",
  "이것봐",
  "관해서",
  "싫을",
  "죽었다는",
  "연속",
  "다쳤어요",
  "자신도",
  "안아",
  "목요일",
  "북쪽",


  "한달",
  "오우",
  "지키기",
  "접니다",
  "맥박은",
  "가능하지",
  "최소",
  "증상을",
  "축복을",
  "남기고",
  "듀켓",

  "치료는",
  "결혼하면",
  "서서히",
  "법도",
  "판막",
  "식물",
  "허튼",
  "캘리포니아",
  "끝내주네",
  "페리",
  "크리스찬",
  "미친놈",

  "맛을",
  "보이잖아",
  "구석에",
  "따뜻하게",
  "취해서",
  "가야죠",
  "편",
  "팔아",
  "장치",
  "하나님의",
  "당신이야",
  "버스가",
  "나이든",
  "전화한",

  "아셨어요",
  "가능할",
  "분들",
  "갔었어요",

  "혜린이",
  "가시면",
  "버렸지",
  "때에는",
  "차원을",
  "마크햄",
  "하시네요",
  "구름",
  "말하죠",
  "임무가",
  "감독님",
  "강간",
  "ª",

  "의사로서",
  "다니고",
  "수업에",
  "살리려고",
  "따라가",
  "확실하지",
  "목걸이를",
  "이기적인",
  "푼",

  "문에",

  "부",
  "쏴라",

  "여기있는",
  "도리",
  "버튼을",
  "빼고요",
  "얻었다",
  "싫습니다",
  "온다면",
  "기적이",
  "타디스는",
  "똥물",
  "잃는",
  "맡지",
  "자코",

  "필욘",
  "슛",
  "숲이",
  "무슨짓을",
  "휴대폰",
  "않는군",
  "섬에서",

  "하루도",
  "자궁",
  "문제예요",
  "얻기",
  "사장이",
  "일이요",
  "초콜릿",
  "하겠다",
  "폐에",
  "기다렸어",


  "날개가",



  "상관없이",
  "낭비",
  "곤경에",
  "급하게",
  "주먹을",

  "그대가",

  "신원",
  "찾아가서",
  "일부를",
  "영혼의",
  "타디스",
  "걔네들이",


  "슬론은",
  "검사가",

  "대통령님",
  "헤나",

  "한명은",
  "가능성",
  "지옥에서",
  "그러던데",



  "뭐였지",
  "오로라",

  "원하는걸",
  "요거",
  "약물을",

  "말콤",



  "불행한",
  "현장",

  "이번에도",
  "환자예요",
  "보냈다",


  "실망시키지",
  "거였죠",
  "몰랐죠",
  "성공적으로",



  "면회",
  "실수가",

  "셋을",

  "파괴",
  "이미지를",
  "경계를",
  "해선",
  "받았죠",
  "살려줘요",
  "나타나",
  "복도",
  "태우고",
  "녀석들",
  "이곳이",
  "여러번",
  "더더욱",
  "계속해요",
  "않았니",
  "들게",
  "현장을",
  "불쾌한",
  "시작해서",
  "보고서에",
  "일부가",
  "못하잖아",
  "생활에",
  "봐야겠어요",
  "폰티액",
  "다해서",
  "경찰한테",
  "콜슨",
  "동력이",
  "아닌거",
  "평범하게",
  "두개의",
  "내요",
  "알렉산더",
  "가지게",

  "상당한",
  "감시하고",
  "그죠",
  "정희",
  "포르노",
  "영차",
  "아파트에서",
  "알수",
  "간절히",
  "영어로",
  "상황입니다",
  "많습니다",
  "않기를",
  "실례해요",
  "무언가가",
  "갔고",
  "어둡고",
  "힘들죠",
  "작게",
  "영혼",
  "라이자",
  "있을거라",
  "선택이",
  "불공평해요",
  "보호",
  "살아야지",
  "혈압을",
  "누르고",
  "들었죠",
  "맹세",
  "지쳤어요",
  "자라는",
  "임상",
  "캐나다",
  "세인트",
  "여자애가",
  "많이요",
  "일한",
  "빈다",



  "데릭의",
  "월",
  "새는",
  "말거라",
  "프라니",
  "몰랐습니다",
  "경우를",
  "원하신다면",
  "타세요",
  "담긴",
  "반대로",
  "뭐하세요",

  "연쇄",
  "제니퍼",
  "그전에",
  "받았을",
  "두가지",
  "내꺼야",
  "큐브를",
  "줄곧",
  "돌아왔다",

  "병력이",
  "재산을",
  "끝내야",
  "자들은",
  "미쳐",
  "비행기로",
  "죽었는지",
  "국제",
  "전달",
  "긴장을",
  "열쇠가",
  "바지를",
  "꿇어",
  "성형외과",
  "시험에서",
  "개너",
  "뭐냐면",

  "새해",
  "과거는",
  "메세지",
  "타면",

  "립",
  "환자와",
  "맥",
  "뭐하니",
  "바닥에서",
  "옮기고",
  "실력",
  "금고",
  "들어오지",
  "자연의",
  "하루의",
  "봤자",
  "더한",
  "인간들",

  "나라에",
  "좋긴",
  "아이들의",
  "공은",
  "거봐",
  "날까지",
  "넘치는",
  "피와",
  "말해라",
  "완전한",

  "결혼해서",
  "신혼",
  "이놈이",
  "싶군",
  "찾으세요",
  "유진",
  "되었고",
  "스타크의",
  "중이고",
  "연결해",
  "허지만",
  "따러",
  "고생을",
  "따를",
  "두려움",
  "에미",
  "자거라",
  "벗겨",
  "헤어진",
  "뭣들",
  "놓는",
  "노릇을",
  "전화해요",
  "어찌나",
  "과학자들은",
  "왔냐",
  "놀리는",
  "화내지",
  "서지",
  "생각했습니다",
  "위해서야",
  "괜한",
  "언제까지나",
  "이다",
  "뭐였어요",
  "미아",
  "소용이",
  "월남",
  "찾아온",
  "빠지게",
  "싫어할",
  "과장님의",
  "꿇고",
  "나타나면",
  "보시죠",
  "줬지",
  "두려움을",
  "주소를",
  "지금요",
  "색스",
  "음모를",
  "복수",
  "폐하의",
  "털리버",

  "문제입니다",
  "권한이",
  "업무",
  "목표가",
  "짐은",
  "증거는",
  "바꾸지",
  "한밤중에",
  "몇이나",
  "데다",
  "친구요",
  "소송을",
  "고장난",
  "했네요",
  "아름답지",
  "기운",

  "돼죠",
  "이것들은",
  "건강",
  "장면",
  "비를",
  "드",
  "벌어진",
  "남쪽으로",
  "다소",
  "전쟁의",
  "수업을",
  "벗어나",
  "있을거에요",
  "거기다가",
  "신경쓰지마",
  "마셔야",
  "그래두",
  "나가세요",
  "무덤",
  "오기로",
  "알코올",
  "알았지만",
  "아파트를",
  "미국에",
  "뒤에는",
  "꾸미고",
  "했었잖아",
  "뭔지도",
  "안다구요",
  "그래햄",
  "난실아",
  "이상으로",
  "바다를",
  "저주를",
  "말하세요",
  "건물에",
  "근디",
  "헌팅던",
  "연설",
  "프림",
  "결혼하지",
  "흥미를",
  "얼어",
  "만성",
  "두리",
  "수술하고",
  "보험",
  "옥경아",
  "그러므로",
  "설명이",
  "치르게",
  "주문",
  "회진",
  "모스",
  "렝",
  "멈추면",
  "튀어",
  "간이",
  "뛰는",
  "영웅을",

  "끝내는",
  "박사는",
  "자식은",
  "의사한테",
  "좌측",
  "사자",

  "하늘은",
  "이제부턴",
  "관",
  "알겠네요",
  "안면",
  "내야",
  "별들은",
  "남고",
  "에너지가",

  "망가진",
  "담아",
  "있거나",
  "병태야",
  "버려야",
  "상자에",
  "너흴",
  "조의",
  "친구로서",


  "멀쩡해요",
  "좋잖아",
  "쪽을",
  "오라",
  "친절하고",


  "아니었는데",

  "손상",
  "모두다",
  "반응을",
  "물로",

  "의용",
  "이씨",
  "생겨",
  "두려워하지",
  "자들을",

  "프라이",
  "자연스럽게",
  "함께할",
  "부탁할게",
  "프로",
  "칼리",
  "좋아하거든요",
  "수학여행",
  "간다면",
  "변호사를",
  "여기에요",

  "실종",
  "테일러",
  "운동을",
  "가보세요",
  "관계에",
  "카를로스",
  "직장에서",
  "친구니까",
  "멋지네",
  "야구",
  "개같은",
  "경관님",
  "되겠니",
  "부모를",
  "팔은",
  "장치를",
  "하룻밤만에",
  "열차를",
  "임무에",

  "주겠다",
  "사실상",

  "물질이",

  "와봐요",
  "있었다구요",
  "규칙에",
  "미셸",
  "비행기는",
  "말라니까",
  "신사숙녀",
  "우리한텐",
  "중이잖아",
  "상대",
  "칼에",

  "에피네프린",
  "멍청하긴",
  "길버트",
  "불고",
  "그애",
  "여기예요",
  "여자들을",
  "말해보세요",
  "소년을",
  "남아있는",
  "계신데",
  "죽음으로",
  "평생동안",
  "않았잖아요",
  "찾았다고",
  "인사해",
  "놀라지",
  "남들이",
  "총상을",
  "의사도",
  "이제까지",

  "손가락으로",

  "누구는",
  "대지",
  "쉽진",
  "키우고",
  "옳았어",
  "스탠",
  "베스가",
  "금을",
  "험한",
  "경관",
  "발견했어",
  "맘을",
  "보시지",
  "트럭에",
  "비행기에",
  "기술은",
  "말처럼",
  "피도",

  "질러",
  "발에",
  "돌봐줄",
  "시도해",
  "이야기하지",
  "쳐다",

  "깜짝이야",
  "드려야",
  "와주셔서",
  "빚진",
  "스트레스가",
  "집과",
  "계획에",
  "위가",
  "뵈러",
  "자매가",
  "좋구만",
  "내기를",
  "망치지",
  "있음을",
  "쓸만한",
  "좋은거야",
  "놓쳤어",
  "좋아하잖아",
  "지구에서",

  "올림픽",
  "이거나",
  "보안관",
  "스케줄을",
  "엉덩이가",
  "하시기",
  "줬죠",
  "뚱녀",
  "베로니카",
  "피해를",
  "마스크를",
  "씬",
  "벤웨이",
  "아름다움을",
  "만나보고",
  "자료를",
  "야간",
  "슈퍼",
  "어떻든",
  "어둠을",
  "하고는",
  "서방",
  "나이스",


  "안된",
  "병력을",
  "깡패",
  "써니",
  "밀러",
  "셰퍼드는",
  "손가락이",
  "창",
  "이사람",
  "했대요",
  "피워",
  "놓아",
  "앉어",
  "미미",

  "비슷해",
  "이어",

  "한창",
  "쉴드가",



  "의무가",
  "퍽",
  "돌연변이",
  "광고",
  "변화",
  "계신가요",
  "고치고",
  "훔치는",
  "마자",
  "먹어야지",
  "연약한",
  "찢어",
  "이쯤에서",

  "와서는",
  "맞았다",
  "잠겨",
  "장사",
  "디렉터",
  "스타일",
  "마냥",
  "불편한",

  "샅샅이",
  "환자랑",
  "우유를",
  "듯한",
  "자네들은",
  "쓸데",
  "않어",

  "보았다",
  "염병",

  "나왔는데",
  "종석아",
  "오셨어",
  "사람이었어요",
  "우리야",
  "조직이",
  "가지구",
  "ㆍ",
  "사람인데",
  "체",
  "궁금하지",
  "우주로",
  "마리가",
  "아름답고",
  "싶어해",
  "마시러",
  "저분은",
  "있었군요",
  "나간다",

  "데려가는",
  "졸업",
  "터져",
  "고른",
  "나까지",
  "스트라이크",
  "노리고",
  "보안관님",

  "누구인가",
  "휴",
  "결혼했어요",
  "처리해",
  "타이어",
  "서른",
  "델",
  "보냈지",
  "투슬리스",
  "가져가요",
  "바르게",
  "머저리",
  "정상이",
  "좋아하는데",
  "안됐어요",
  "왔었어",
  "오랜만이다",
  "수석",
  "개자식아",
  "주사",

  "축하드립니다",
  "전장에서",
  "언니한테",
  "생겼는데",
  "괜찮소",

  "점수를",

  "리타",

  "어리고",
  "엄마처럼",
  "그거알아",
  "받어",
  "나와야",
  "모인",
  "오셨나요",
  "에이전트",
  "루",
  "떨어질",
  "엑스레이를",
  "혼자서도",
  "있느냐",
  "블랙",

  "소리요",
  "같긴",
  "굴고",
  "경로",
  "신분을",
  "지났어",
  "그림이",
  "레스터",
  "인생이야",
  "조심하고",
  "죽였고",
  "남겨주세요",
  "닉이",
  "창을",
  "해드릴게요",
  "아인",
  "에잇",
  "뵙게",
  "오니",
  "기악",
  "장난하는",
  "괴롭히지",
  "도우려고",

  "숨긴",
  "레지던트를",
  "연관이",
  "나다",
  "싫지만",
  "내일이면",
  "할아버지를",
  "갈꺼야",

  "있으시면",
  "않겠네",
  "그랬잖아요",
  "좋을까",
  "물고기",
  "남자라고",
  "박살",
  "많잖아",
  "원하세요",
  "교통",
  "도무지",
  "하지요",
  "맥주를",
  "감방",
  "상관하지",
  "엄밀히",
  "다수의",
  "모든것을",

  "동료가",
  "사스케를",
  "아린",
  "이보다",
  "복도를",
  "자유의",
  "하루만",
  "저리가",
  "결과에",
  "피고",
  "꾸고",
  "가셨어요",
  "됐든",
  "형도",
  "행운이",
  "걔들",
  "환영한다",
  "숨는",

  "애예요",
  "해피",
  "아시오",
  "역겨워",
  "나빠질",
  "남자애",
  "싶으세요",
  "만났죠",
  "유능한",

  "건물을",
  "비디오드롬",
  "레코드",

  "찾을수",
  "개새끼들",
  "찾도록",
  "충전",
  "하던데요",

  "연결되어",
  "못한다는",
  "같이요",
  "살아남은",
  "가능해요",
  "늙고",
  "가지를",
  "좋겠다고",
  "어디예요",
  "회의",
  "꾸지",
  "아이도",
  "한은",
  "보인다고",
  "연방",
  "결정이",
  "공작",
  "아버지에",

  "철저히",
  "되오",
  "잃었다",
  "맞소",
  "돌격",
  "잊었어요",
  "요즘엔",
  "존재하는",
  "카밀",
  "아시는",
  "이런일이",
  "입술",
  "들립니까",
  "약에",
  "건지도",
  "경찰이다",
  "알거야",
  "비숍",

  "죄책감",
  "겁나게",
  "냈어",
  "놈들에게",
  "잘들",
  "척하는",
  "케이스가",
  "귀엽다",
  "주겠소",
  "이론",
  "열을",
  "한두",
  "헐",
  "정당한",
  "보기만",
  "지금쯤이면",
  "노력하는",
  "아니었지",
  "하십니다",
  "안됐군",
  "누구에요",
  "도는",
  "나라도",
  "죽었잖아",
  "옳아",
  "안다는",
  "사람만",
  "보조",
  "장비를",
  "했구나",
  "싸운다",
  "산책",
  "악한",
  "밖에는",
  "믿을만한",
  "하자면",
  "놀이",
  "여기서는",
  "맹세해요",
  "했단다",
  "싫지",
  "터널",
  "노엘",

  "빌리",
  "자랄",
  "사용하지",
  "평화가",
  "분노",
  "선생님한테",
  "실어",
  "옙",
  "있더군",
  "어딘가에서",
  "정부의",
  "보여줘야",
  "숙여",
  "참여할",
  "했어야지",
  "어디에요",
  "박수",
  "생기고",
  "내려야",
  "이상하다",
  "말라구",
  "하는지도",
  "다같이",
  "어디서부터",
  "흰색",
  "케이스를",

  "리틀",
  "화낼",
  "안좋아",
  "닮았어",
  "서류가",

  "있으며",
  "리스트에",
  "찾았지",
  "도착했을",
  "어렸을때",
  "데릭한테",
  "날개를",
  "조준",
  "쓰던",
  "반대",
  "행성에서",
  "기밀",
  "때린",
  "원인이",
  "이지의",

  "게이라고",
  "다했어",
  "경찰에게",
  "파티는",
  "조직의",
  "지구는",
  "생활이",
  "의대",
  "활동을",
  "아들과",

  "거래는",
  "고객이",
  "산다는",
  "중요한게",
  "되었죠",
  "외과의사가",
  "어쩌나",
  "봐줘",


  "어쩌고요",
  "마음껏",
  "캠",
  "가지가",
  "다녀요",
  "감정적으로",
  "데릭을",
  "보세",
  "타지",
  "금이",

  "아기에",
  "깨어날",

  "말했지만",
  "시저는",
  "예예",
  "주어진",
  "그렇다는",
  "기다렸어요",
  "수영장",
  "연락할",
  "바나나",
  "교수님이",

  "지역에",
  "일어났던",
  "끌",
  "노릇",
  "자식들을",
  "순자",
  "사는지",
  "알았더니",
  "잤다고",
  "양말",
  "질병을",

  "겁먹지",
  "의미는",
  "누구든지",
  "오셨죠",
  "든다",
  "그이",
  "시작할까요",
  "하느님은",
  "겁먹은",
  "때문이라고",
  "신생아",
  "평소보다",
  "증거",
  "기차에서",
  "박상길",
  "폴리",
  "상담을",
  "앉지",
  "시드니",
  "선수들",
  "계집",
  "돌아올게요",
  "간수",
  "스톤",
  "뿐인",

  "현실로",
  "중력을",
  "일이잖아요",
  "안들려",
  "내생각엔",
  "불만",
  "갈수록",

  "그거예요",
  "이런식으로",
  "조카가",
  "사람들이랑",
  "거짓말쟁이",
  "들릴",
  "있진",
  "앨범",
  "짓는",
  "월급",
  "깨어나면",
  "장면이",
  "동맥",
  "바늘을",
  "게일",
  "일으킬",

  "에드윈",
  "주말",
  "찾아볼",
  "가스를",

  "나니",
  "안쪽이",
  "위층에",
  "편이에요",
  "반을",
  "코끼리",
  "이리저리",
  "가위",
  "너두",
  "보고도",
  "만들죠",
  "있게요",
  "잘린",
  "시장님",
  "브레이크",
  "들어왔다",
  "느낀",
  "목적지에",
  "않았소",
  "자막은",
  "진지한",
  "하시겠습니까",
  "노크",
  "해봐라",
  "맞잖아",
  "되실",
  "함께하는",
  "때보다",
  "주치의가",
  "멋쟁이",
  "알려주지",
  "하난",
  "눈에서",
  "혁수야",
  "그것보다",
  "저더러",
  "울려",
  "안됐다",
  "액션",
  "외국",
  "싶냐",
  "달아",
  "않는데요",
  "옐라",
  "하세가와",
  "월요일에",
  "통제할",
  "터진",
  "교회에",
  "정말이요",

  "호수",
  "현준아",
  "여자로",
  "안그래요",
  "쌤",
  "돌아가실",
  "성은",
  "골라요",
  "시민",

  "정체가",
  "당했어요",
  "최고예요",
  "리스",
  "경이",
  "슬퍼",
  "장벽을",
  "돌아왔을",
  "있을래",
  "저라면",
  "부인께서",
  "쉐퍼드는",
  "보진",
  "인기가",
  "슬픔에",
  "합병증이",
  "핸슨",
  "학교도",
  "해야해",
  "약도",
  "예전처럼",
  "들으니",
  "그레이를",
  "베스는",
  "강",
  "필요가있다",
  "걔네들",
  "잽싸게",

  "너무도",
  "결혼이",

  "편이야",
  "얘도",
  "싶은게",
  "모르핀",
  "남긴",
  "신중하게",
  "아니지요",
  "붙이는",
  "어디있는지",
  "데릭이랑",
  "사람이고",
  "영상",
  "급히",
  "보라",
  "가봐야겠어요",
  "요구하는",
  "떼어",
  "가정",
  "춤추는",


  "으악",
  "더구나",
  "해리스",
  "회사는",
  "이쪽입니다",

  "관둬",
  "이용할",
  "괴물로",
  "신스케",
  "인정",
  "망쳤어",
  "낭비하고",
  "달라질",
  "저두요",
  "마셨어요",
  "불안해",
  "중요하게",
  "땅의",
  "불타는",
  "샌드라",
  "토비가",
  "우연의",
  "죄로",
  "너무나도",
  "이들의",
  "교도소에",
  "나가라",
  "그녀랑",
  "했을까요",
  "지구가",
  "여자처럼",
  "일단은",
  "많네요",
  "디노",
  "최",

  "흥미",
  "겔빈",
  "무례한",
  "권리",
  "책상에",
  "단어",
  "깨끗해요",
  "칼은",
  "골랐어",
  "이딴",
  "조심하라고",
  "하나님은",
  "예상치",
  "올리는",
  "알았니",
  "악의는",
  "데에",
  "장난을",
  "다니엘스",
  "클",
  "창문이",
  "환상을",
  "물질은",
  "자살을",
  "가렴",
  "말해보게",

  "아버님을",
  "구경만",
  "식도",
  "너까지",
  "멀쩡히",
  "왔었어요",
  "혀를",
  "대답할",

  "단둘이",
  "되려면",
  "알리고",
  "야가",
  "충고",
  "빌게",
  "그라믄",
  "모리아티",
  "거래요",
  "있었겠지",
  "지저분한",
  "가려",
  "예비",
  "말고도",
  "살리는",
  "잘못도",
  "포기하는",
  "싸워서",
  "털이",
  "이분",
  "여기있어",
  "걸리고",
  "계집애",
  "성문을",
  "일요일",
  "연애",
  "남겨두고",
  "것만으로도",

  "얘들이",
  "긍정적으로",
  "말했습니다",
  "나설",
  "베일리쉬",
  "당분간은",
  "항생제",
  "사이렌",
  "되겠소",
  "비켜봐",
  "눈물을",
  "박사님이",
  "거대",
  "원수를",
  "있어야죠",
  "군대에서",
  "꼼짝마",
  "호흡을",
  "좋았던",
  "나타났습니다",
  "그만하라고",
  "편지는",
  "사랑한다면",
  "파리로",
  "살리기",
  "고쳐야",
  "세포",

  "어디지",
  "아이리스",
  "해야돼",
  "결정해",
  "얘기죠",
  "아이들도",
  "비는",
  "잘가요",
  "엄마에",
  "저에요",
  "들러리",
  "떨어져요",

  "떠나라",
  "나와봐",
  "재스퍼",

  "아무렇지도",
  "모양",
  "열어봐",
  "소장",
  "참조하십시오",
  "사라졌습니다",
  "닫을",
  "신용",
  "올해는",
  "산다고",
  "나옵니다",
  "세계로",
  "믿기지",
  "방송국",
  "몰아",

  "보이",
  "오래전에",
  "생겼네",
  "있어야합니다",
  "바에서",
  "갔다고",
  "있습니다만",
  "줄수",
  "것과는",
  "교육을",
  "전부예요",

  "아닌것",
  "소변을",
  "라이트",
  "지켜볼",
  "찾는데",
  "드래곤을",
  "항복",

  "마이크가",
  "듣자하니",
  "주길",
  "해결해",
  "살아가는",

  "폭력을",
  "현대",
  "대답이",
  "덕",
  "보내주세요",
  "성님",
  "저기로",
  "머리카락을",


  "싶어하지",
  "라인",

  "재미를",
  "해놓고",
  "교도소",
  "장을",
  "루퍼트",
  "맹세를",
  "그만한",
  "멀쩡한",
  "명백한",
  "동쪽으로",
  "스페인어",
  "충분치",
  "좋아하세요",
  "시체는",

  "죽여버리겠어",

  "콩",
  "그렇습니까",
  "여러분들",
  "찾았죠",
  "화상을",
  "밥도",
  "종",
  "고마운",
  "무엇에",
  "이식이",
  "젤리",

  "처럼요",
  "죽거나",
  "있을거",
  "청소",
  "협상을",
  "재미없는",
  "흘리고",
  "다이어트",
  "한거죠",
  "말이네",
  "예약을",
  "곳의",
  "위험하다",
  "네겐",
  "인간으로",
  "못봤어",
  "그놈들",
  "스카우트",
  "준비되면",
  "뛰게",

  "마당에",
  "막상",
  "놔줘요",
  "멈췄어요",
  "접근할",
  "데이트가",
  "그애는",
  "스탠바이",
  "휴식이",
  "막으려고",
  "어린애를",
  "잡기",
  "저에겐",
  "감지",
  "센츄럴",
  "아미타불",
  "살아있어요",
  "무엇보다도",
  "달라는",
  "고모",
  "기어",
  "가소",
  "프로젝트",
  "거참",
  "모습으로",
  "떨어",
  "난생",

  "천사가",
  "좋아질",
  "새롭게",
  "위해서도",
  "있잖습니까",
  "생기지",
  "와아",
  "계시지",
  "피해자는",
  "남자요",
  "시간이에요",

  "멈춰야",
  "빠져서",
  "국경을",
  "한계를",
  "나누고",


  "번호는",
  "보냈죠",
  "두부",
  "타게",

  "전설적인",
  "폴란드",
  "절차를",
  "폭발이",

  "포기하고",
  "보냈습니다",
  "계란을",
  "기쁨을",
  "승객",
  "봤냐",
  "무엇보다",
  "남쪽",

  "봤지만",
  "나름",
  "거짓",
  "숨은",
  "레너드가",
  "상관도",
  "어떡하고",
  "레이디",
  "쳐요",
  "가길",
  "내년에",
  "말해준",
  "시동",
  "될수",
  "전하의",

  "일루와",
  "멋지다고",

  "사인을",
  "사랑한다는",
  "밤엔",
  "대통령이",
  "사귀고",
  "밖은",

  "주문을",
  "헤일리",
  "도망친",
  "스파이",
  "말하는게",
  "봐줄",
  "아들한테",

  "어데",
  "갈께요",
  "오지마",
  "치마",
  "실행",
  "커피는",

  "사과하지",
  "이해하려고",
  "니콜라스",
  "물어보는",
  "필요하다면",
  "천사",
  "노바",
  "있어선",
  "적혀",
  "알렉세이",
  "차렷",
  "맞아야",
  "장례식에",
  "이곳으로",
  "호출을",

  "웃음을",
  "계십시오",
  "당신들의",

  "마법",
  "이왕",
  "절단",
  "얼어붙은",
  "싫소",

  "출",
  "애의",
  "틈이",
  "알아차리지",
  "허허",
  "있어줘",
  "유감이네요",
  "먹었지",
  "향하는",

  "좋다면",
  "혹",
  "성구야",
  "팔지",
  "괜찮네요",
  "제약",
  "시작했는데",
  "같으니라구",
  "괜찮습니까",
  "잘자요",
  "휴식을",
  "보지마",
  "병실로",
  "가지세요",
  "보겠네",
  "지면",
  "인류",
  "싸움이",

  "가엾은",
  "않음",
  "낮에",
  "상자를",
  "클럽에",
  "애디슨이",

  "현재의",
  "강에",

  "보내려고",
  "꿀",
  "제공",
  "댈",
  "하려고요",
  "난데",
  "녀석한테",
  "뿅",
  "비하면",
  "딸과",
  "태아",
  "노인",
  "중대한",

  "따라오세요",


  "스테이시",
  "연이",
  "휴대용",
  "왔잖아요",
  "짜식",
  "감사할",
  "동맥이",
  "날고",
  "에구",

  "으흠",

  "맞는데",

  "주변의",
  "사람에겐",
  "말했는데",
  "벗은",

  "했대",
  "아느냐",
  "살인은",
  "스캔",
  "주소는",
  "콜라",
  "여태껏",
  "샀어요",
  "부브",
  "가졌다고",
  "골치",
  "붙었어",
  "놀라워",
  "빠르고",

  "속은",
  "연주",
  "여동생이",
  "조심해라",
  "남편한테",
  "핸드볼",
  "토하고",
  "재밌어요",
  "크면",
  "많다고",
  "어울려요",
  "차례다",

  "앨",
  "마술",
  "않을게요",
  "기본적인",
  "선물은",
  "깊고",
  "안할거야",
  "맞지만",
  "아줌마가",
  "가죽",
  "너에겐",
  "치프에게",
  "짓이지",

  "웃어요",
  "지금으로선",
  "보자꾸나",
  "라일리",
  "가셔야",
  "공격해",
  "콥",

  "파란색",
  "책상",
  "김준식",
  "허리",
  "보자마자",
  "제외하고",
  "목걸이",
  "볼텍스",
  "별의",

  "자렴",
  "알아보지",
  "썼다",
  "선수들이",
  "벌레",
  "뭐였어",
  "한때는",
  "해줘서",
  "의견이",
  "같냐",
  "갖기",
  "의무를",
  "빵을",
  "것처럼요",
  "못한다고",
  "오브",
  "출신",
  "에이미는",
  "안들어",
  "잡으려고",
  "인터뷰를",
  "아래층에",
  "잠만",
  "어깨에",
  "행복",
  "독이",
  "나눌",
  "없애",
  "부탁한",
  "가능하다면",
  "까마귀",
  "월요일",
  "날아",
  "있었",
  "졌어",
  "세월",
  "가셔서",

  "키를",
  "젠",
  "지내세요",
  "명만",
  "없지요",
  "시작하기",
  "병사를",
  "릭스비",

  "안전합니다",
  "사랑이야",
  "왕께서",
  "상태에서",
  "망했어",
  "초록색",
  "죽여요",
  "지갑",

  "친구죠",
  "자른",
  "확인합니다",
  "똑",
  "멋지군요",
  "대접을",
  "동의해",
  "꿈꾸는",
  "잡히지",
  "군사",
  "총으로",

  "입었어요",
  "물러나",
  "킹",
  "해결하는",
  "서두르지",
  "집안의",
  "이유입니다",
  "언어",
  "파멜라",
  "금요일에",
  "완벽해요",
  "신문을",
  "사진도",
  "그러기",
  "증명해",
  "몬",
  "끊지",
  "대니가",
  "시간이나",
  "뇌는",
  "어깨를",
  "하더라고요",
  "두시간",
  "달아나",
  "생각하시는",
  "행복하고",
  "잠깐이면",

  "예약해",
  "시간이다",
  "자막제작",
  "특수",
  "놀랐어요",
  "속옷",
  "높습니다",
  "말했다고",
  "노력해",
  "넘어갈",
  "괴물들이",
  "마법의",
  "두려워하는",
  "할거",
  "필요하신",
  "바란",
  "친구들이랑",
  "말해주면",
  "서쪽으로",
  "똑같지",
  "의사에게",
  "카드가",
  "키우는",
  "만들었죠",
  "자신에",
  "죄책감을",
  "내년",
  "보여야",
  "아내랑",
  "나무에서",
  "장비가",
  "그거에",
  "내용을",
  "출동",
  "서부",
  "태어나서",

  "다름",
  "보내줘요",
  "팬더",
  "카렌",
  "병원은",
  "직업은",
  "수고하셨습니다",
  "도망치는",
  "주시겠습니까",
  "훔쳤어",
  "시대는",
  "왠",
  "괴상한",
  "지내지",
  "뜨는",
  "세계가",

  "보기에",
  "괜찮고",
  "당신보다",
  "캐더린",
  "일자리를",
  "행동에",
  "천국에",
  "사회에",
  "끼워",
  "경찰의",
  "가면서",
  "가죽을",
  "조건이",
  "벨라미",
  "해보는",
  "믿겠어",
  "비밀리에",
  "지식을",
  "확인해야",
  "비해",
  "잘만",
  "단계",
  "어린이",

  "다녔어요",

  "어서들",
  "뵐",
  "무시무시한",
  "허블",
  "좆같은",
  "말이나",
  "살아온",


  "군데",
  "동생에게",
  "일하게",
  "있을텐데",
  "빠지면",
  "좋을걸",
  "테온",
  "짓지",
  "싸운",
  "줄께",
  "질투하는",
  "벽이",
  "차트에",

  "놀랍지",
  "호르몬",
  "동화",
  "파도가",
  "돌리지",
  "그랬었지",
  "운명의",
  "죽겠지",
  "캘리는",
  "군대의",
  "도시로",
  "수건",
  "위험하고",
  "간다는",
  "학교로",
  "좋았지",
  "해보면",
  "진실한",
  "부부는",
  "명백하게",
  "집에는",

  "틀림",
  "지를",

  "꿔",
  "말하던",
  "나쁠",
  "움직이고",

  "담배는",
  "비어",

  "도착한",
  "방법이야",
  "사실인가요",

  "숙제",
  "걸면",
  "신으로",
  "오셨다",
  "저놈이",
  "싶으시면",
  "바보로",
  "좁은",
  "단계를",
  "있을때",
  "신뢰할",
  "사람일",
  "빼고는",
  "그럴게",
  "줘라",
  "여자라고",
  "있는데도",
  "뉴스",
  "사람이란",

  "해보죠",
  "그거면",
  "나왔다",
  "거북이",
  "로키",
  "어떡할",
  "영영",
  "위치에",
  "토멘",
  "모르겠고",
  "식모",
  "생각했지만",
  "낭만적인",
  "체스",
  "마찬가지지",
  "보거라",
  "잘들어",
  "불러줘",
  "삼촌은",
  "서울에",
  "상수",
  "절반을",
  "투표를",
  "자연스러운",
  "가족들을",

  "사랑으로",
  "토끼",
  "올까",

  "반갑네",
  "분명한",
  "보단",

  "오시오",

  "오드리",
  "좋겠지만",
  "나타샤",
  "계시고",
  "힘도",
  "메린",
  "여왕님",
  "돌아오고",
  "동무들",

  "사이몬",
  "좋아하네",
  "놈이지",
  "돌릴",
  "상상해봐",
  "크림",
  "일이시죠",
  "코가",
  "와중에",
  "오시면",
  "치료에",
  "요점이",
  "진실의",
  "수녀가",

  "건방진",
  "못하면서",
  "시작이",
  "어디있지",
  "아드님",
  "붙이고",
  "다름없어",
  "일이라면",
  "마오",

  "제왕절개를",

  "된거지",
  "실수였어",
  "모래",
  "죽이는데",
  "무한한",

  "남편이랑",
  "받았고",

  "샌드위치",
  "천재",

  "안그러면",
  "모르면서",
  "은주",
  "그럴수",
  "간호사들이",
  "낮춰",
  "내게도",
  "괜찮겠지",
  "미워",
  "오빠도",
  "좋아합니다",
  "유령",
  "네네",
  "일부는",
  "됐지만",
  "일이지만",
  "기쁩니다",
  "편집",
  "말씀하시는",
  "발견할",
  "뒤져",
  "바보는",
  "들어가시죠",
  "쇼는",
  "농담하는",
  "갖지",
  "야벳",
  "꾸민",
  "문제요",
  "마을은",
  "아프리카",
  "메이슨",

  "보이기",
  "취소",
  "같은건",
  "압니까",
  "데이트는",
  "많았어요",
  "성이",
  "준비됐습니다",
  "봉수",
  "기대하고",
  "어서와",
  "과자",
  "수술대",
  "집중하고",
  "진정하라고",
  "나이는",
  "사이버맨",
  "의미도",
  "알겠군",
  "양도",
  "하려던",
  "추수",
  "받아들여",
  "상태예요",
  "알려주는",

  "닥터를",
  "베를린",
  "주가",
  "표시를",
  "안을",
  "빨갱이",
  "주면서",
  "장갑을",

  "키는",
  "되돌려",
  "보였다",
  "집중할",

  "실제로는",
  "죽여도",
  "아무에게도",
  "움직이지마",
  "공짜",
  "대표",
  "진정제를",
  "오호",
  "좋아하거든",
  "복도에서",
  "이빨을",
  "했구요",
  "알았고",
  "자유야",
  "간단해",

  "터졌어요",
  "토요일에",
  "윗층에",
  "나라의",
  "거시기를",
  "공포",
  "돌아다니는",

  "마지막에",
  "생일을",
  "대신해서",
  "놈들한테",
  "의심의",
  "경감",
  "수색해",

  "납치",
  "몰랐다",
  "스웨터를",
  "하는건데",
  "할것",
  "공부하고",
  "평균",
  "있겠습니다",
  "이마에",
  "네에",
  "이끌어",
  "피켄스",
  "비행기에서",

  "쥬니어",
  "챔버스",
  "여성을",
  "잡히면",
  "온라인",
  "하던가",
  "레오",
  "블랙홀의",
  "헤이글",
  "나중엔",
  "집합",
  "드라고",
  "유감스럽게도",
  "이거죠",
  "뎀시",
  "확인을",

  "성질",
  "부엌",
  "앞으론",
  "간단하지",
  "섹스에",
  "그레이요",
  "보호하기",
  "반가운",
  "올라가면",
  "읽은",
  "명도",
  "정원을",
  "박힌",
  "만드는데",
  "데스",
  "추워요",
  "소문",
  "홈즈가",
  "헛된",
  "있을걸",
  "넘어서",
  "얘기예요",
  "오베린",
  "살았다",
  "물어봤어",
  "스물",

  "가능하죠",
  "거기도",
  "아침까지",
  "사뮤엘",
  "환자분의",
  "마마",
  "뭐하나",
  "겠다",
  "자랑스럽게",
  "저녁식사",
  "선택하지",
  "조니는",
  "대상이",
  "로맨틱한",
  "왕자",
  "얻었어",
  "요청을",
  "샘플을",
  "해명",
  "연",

  "잘못되면",
  "잡혔어요",
  "짜잔",
  "잠이나",
  "마비가",
  "대학에서",
  "마실래요",
  "아셔야",
  "태양이",
  "삼",
  "필요한건",
  "나잖아",
  "날이에요",
  "벌거벗고",
  "일생에",
  "무당이",

  "표현",
  "굿윈",
  "성으로",
  "좋을텐데",
  "된다니까",
  "행성에",
  "마취를",
  "강아지를",


  "챈드라",
  "그곳은",
  "소린지",


  "뜻이다",
  "전화하고",
  "죽진",
  "버지니아",
  "미래로",
  "해결해야",
  "누구보다도",
  "그러려면",
  "전략을",
  "쓰라고",
  "색이",
  "존재의",
  "아침마다",
  "마디만",
  "가을",
  "크기의",


  "마사지",

  "일으킨",
  "리처드가",
  "동생이야",
  "다음번에",

  "살리고",
  "군인들이",
  "울면",
  "많았어",
  "놔둘",
  "사람이라",
  "시민들이",

  "돌아가기",
  "당신께",
  "가방이",
  "차려요",
  "다행이군",
  "아래서",
  "것부터",
  "행성을",
  "도둑이",
  "중이었는데",
  "부치",
  "다음으로",
  "테이프",
  "산소가",
  "유죄",
  "줄이야",
  "종류",
  "커피나",
  "수류탄",
  "알아들어",
  "전화하는",

  "데니를",
  "직업도",
  "케이블",
  "괜찮으실",
  "미술",
  "급성",
  "환자요",
  "동맥을",
  "알았던",
  "인류는",
  "흔히",
  "영자",
  "할리",
  "글고",
  "와야지",
  "물질을",

  "샬롯",
  "사정을",
  "겪는",

  "불러서",
  "수미야",
  "바닥이",

  "퀸",
  "부수고",
  "본다는",
  "닉은",
  "처리하고",
  "신세가",
  "넣어야",
  "울어봐",
  "가져갔어요",
  "떨어뜨려",

  "맞습니까",
  "딸이에요",
  "부탁드려요",
  "도망을",
  "스페인",
  "빠지고",

  "숙모",
  "건은",
  "클럽에서",
  "하지는",
  "닥터한",
  "기회도",
  "위험할",
  "깨어나지",
  "증언을",
  "활동",
  "아웃",
  "배에서",
  "몰랐네요",
  "쪽이야",
  "올라가는",
  "않았습니까",
  "너희들에게",
  "해볼게",
  "불러와",

  "기막힌",

  "였어",
  "사용해서",
  "사랑했어요",
  "견뎌낼",
  "할까봐",
  "악",

  "만나자고",
  "투여해",
  "앉고",
  "요정",
  "소름",
  "배고픈",
  "계시네요",
  "무거워",
  "으아",

  "데다가",
  "베일리의",
  "병신같은",
  "살자고",
  "해냈어요",
  "성적이",
  "학기",

  "질렸어",
  "되자",
  "내도",
  "하더라고",
  "보여줍니다",
  "숙제를",
  "적합한",
  "영감",
  "돌면",
  "무스",
  "아일랜드",
  "일이란",
  "언제라도",
  "경은",
  "환영",
  "터널을",
  "준비됐지",
  "치유",
  "쏘고",
  "월급을",
  "첫사랑",
  "바꾸기",
  "재미가",
  "제시",
  "날입니다",
  "목소리는",

  "요청",
  "푸딩",
  "복부에",

  "지하실에",
  "활짝",
  "이것들을",
  "없어져",
  "블라즈",
  "성에서",
  "이빨",
  "보긴",
  "가지고있다",
  "자정",
  "조용하고",
  "복잡해요",
  "지역을",
  "인턴이야",
  "내려가면",
  "아는게",

  "연극을",
  "줄여",
  "씨발놈아",
  "피하고",
  "지나고",
  "앉은",
  "취소해",
  "얘기해봐",
  "이만큼",
  "조개",
  "아픈데",
  "어디에도",
  "쥬디",
  "서라",

  "기다릴게요",
  "아멜리는",
  "훔치고",
  "로이스",

  "헤헤",
  "구하지",
  "스코복스",
  "위협이",
  "있구만",

  "만들었습니다",
  "얘기했어요",
  "따라오게",
  "만났던",
  "쫙",
  "그년",
  "됬어",

  "결정해야",
  "재미있게",
  "일해야",


  "드시죠",
  "장은",
  "멈췄어",
  "안정적이고",
  "답은",
  "기다린다",
  "심실",
  "상담",
  "노래도",
  "목요일에",
  "부모는",
  "찔린",
  "안될까요",
  "찾았는데",
  "병실에",
  "어디선가",
  "게리",


  "별이",
  "논",
  "크리스티나를",
  "위험은",
  "지원을",
  "마셨어",
  "물어봐요",
  "헬프만",
  "알아내는",
  "아퍼",
  "예를들어",
  "숨겨",
  "잡수세요",
  "끔찍하게",
  "물고",
  "웬일이냐",
  "맞네요",
  "지우고",
  "방법입니다",
  "양복",
  "케일럽",
  "오른쪽에",
  "카메론",

  "나가주세요",
  "하셨다",
  "일어서",
  "보이지만",

  "애랑",
  "마사",

  "교훈을",
  "변한",
  "가야만",
  "년은",
  "생각이지",
  "팔자",
  "해주실",
  "박을",
  "마신",
  "원래대로",
  "이러지마",
  "베일리에게",
  "먹힐",
  "교사",
  "오염",
  "할말이",
  "사과하고",
  "적고",

  "섹스도",
  "일급",
  "도시가",
  "쫓고",
  "공격할",
  "하구",
  "그만하세요",
  "시멘트",
  "회사의",
  "마찬가지죠",
  "지났는데",
  "생활은",
  "두어",
  "되는거죠",

  "위협을",
  "원하는데",
  "암호",
  "모셔",
  "바이",

  "못하도록",
  "가져올",
  "것들도",
  "통신",
  "드네",

  "적힌",
  "뭘까요",
  "좋으니까",
  "들어가기",
  "고귀한",


  "게임에서",
  "않은데요",
  "동의",
  "하셨지",
  "염병할",
  "사정이",
  "말한거야",
  "저야",
  "멀리서",
  "처음에는",
  "피해자의",
  "마디도",
  "퍽이나",
  "많네",
  "성환댁",
  "내사",

  "돌아가셨어",
  "신세를",
  "밖",
  "수원댁",
  "같애요",
  "기계는",
  "딸기",
  "거울을",
  "오래전",
  "우주선을",
  "해서는",
  "났지",
  "고맙군요",
  "시대가",
  "생각하나요",
  "열두",

  "감염된",

  "가슴은",
  "유심히",
  "구린내",
  "그랬다",
  "당",
  "시작하자고",
  "이긴",
  "올리",
  "중이라고",
  "이탈리아",

  "이웃",
  "막대한",
  "여편네",
  "죽여버려",

  "빠졌어요",



  "믿죠",
  "총각",
  "고메즈",
  "다니면서",
  "들리는데",
  "소리에",
  "알려야",
  "곡을",
  "지랄",
  "도망가는",
  "결정한",
  "고약한",
  "본인",
  "로렌스",
  "겁쟁이가",
  "메이데이",
  "살자",
  "가야할",
  "아나토미",
  "쐈어요",
  "뿐이고",
  "마리를",
  "중요하다고",

  "작별인사를",
  "자전거를",
  "죽여버릴거야",
  "그야말로",
  "인사도",
  "총도",
  "흔적도",
  "안된다는",
  "있어서는",
  "목사님",
  "저러는",
  "홀딱",
  "된거",
  "산채로",
  "블랙홀을",
  "콱",
  "것이여",
  "똑똑해",
  "계단에서",
  "버틀",
  "충격이",
  "외엔",
  "반가웠어",
  "남자친구는",
  "살았던",
  "나네요",
  "쇼가",
  "남길",
  "삼룡이",
  "골",
  "걱정돼",
  "느리게",

  "됐거든",
  "어디요",
  "펴",
  "일어날지",
  "해주길",
  "방해하고",
  "파이프",
  "꾼",
  "독특한",
  "생각해보면",
  "굳게",


  "광대",
  "트라팔가",
  "그렇게까지",
  "잃었을",
  "말하는건",
  "창고",
  "싸인",
  "시작했지",
  "나도요",
  "쉬었다",
  "척수를",
  "명령에",
  "사망한",
  "흔적은",
  "아니겠습니까",
  "언니랑",
  "간을",
  "니들은",

  "이지에게",
  "원장",

  "외상이",
  "취했어",
  "말이군",
  "걸어요",
  "모양이",
  "말해줬어",
  "권",

  "소리냐",
  "쉴드의",
  "것만큼",
  "분이에요",
  "갈아",
  "틀렸다고",
  "오오",
  "않을거예요",

  "일으키고",
  "기다리다",
  "담엔",
  "혈관이",

  "선이",
  "하라구",
  "맞는지",
  "공연을",
  "다가",
  "나오기",
  "철학과",
  "찾아내서",
  "소리로",
  "두분",
  "잠그고",
  "새라는",

  "전력",
  "똑같아요",
  "결정하는",
  "느껴지지",

  "않겠니",
  "이런건",


  "살다가",
  "너한테는",
  "시작했습니다",
  "조건을",
  "드네요",
  "장군은",
  "놀리지",
  "왔단다",
  "아니겠지만",
  "보였어",
  "끝내준다",

  "옮겨야",

  "잘됐군요",
  "없었단다",
  "모양으로",
  "소리여",
  "아만다",
  "저기가",

  "있겠군요",
  "아니라니까요",
  "불이야",
  "증세가",

  "이어서",
  "감사를",
  "다릅니다",
  "식은",
  "꺼내서",
  "괜찮을꺼야",
  "당기고",
  "말아야지",
  "남자라면",

  "상상이나",

  "재밌네",
  "이쁜이",
  "죄송한데요",
  "참여",
  "신나게",
  "술집",
  "연주를",
  "좋아하니까",
  "케이크",
  "창조주께선",
  "차량을",
  "카레프는",
  "가스가",
  "모임",
  "피닉",
  "혼자만",
  "북",
  "머지않아",
  "해줬어요",
  "제거하는",
  "깊숙히",
  "돼가",
  "질색이야",
  "정식으로",
  "치즈를",
  "줍니다",
  "깨달았다",
  "따",
  "돌아보지",
  "에어프릴",
  "자존심이",
  "그만둘",
  "정부는",
  "요새는",
  "해줬어",
  "그래선",
  "바람은",
  "모두는",
  "재수가",
  "경미한",
  "이거요",
  "늦었다",
  "야한",
  "책임이야",
  "남았는데",

  "대단한데",


  "약속했어",
  "루카스",
  "방해하는",
  "도착했어요",
  "보실래요",
  "드와이트",
  "말도록",
  "돌아와요",
  "알잖나",
  "노란",
  "이후로는",
  "밝게",
  "왕관을",
  "싫어하지",
  "오랜만이야",
  "발생했습니다",
  "잊어버린",
  "농담이",
  "열정을",
  "존재는",
  "물고기가",
  "달간",
  "옳아요",
  "들어간다",
  "모레",
  "훔쳐서",
  "너야말로",
  "정상",
  "번호가",
  "영자야",
  "근육을",
  "아무짓도",
  "병사",
  "절제술을",
  "방식은",
  "클리닉에",
  "것까지",

  "대답하지",
  "보호해",
  "알렉사",
  "나빠요",
  "기차에",
  "냉장고",
  "만들어낸",
  "최신",
  "미자야",
  "싫단",
  "드실",
  "꼼짝도",
  "못하니까",
  "있었단",
  "애들과",
  "시대에",
  "여왕이",
  "그레이와",
  "진술을",
  "쏙",
  "흥분한",
  "그렇기",
  "누님",
  "갇혔어",
  "스캇",
  "의해서",
  "찾던",
  "창고에",
  "치프를",
  "분과",
  "실장님",
  "곁을",
  "나라에서",
  "저희의",
  "대전",
  "낮잠",
  "계속하세요",
  "썼던",
  "종교",
  "너네들",
  "만도",
  "되거나",
  "집이나",
  "펭",
  "과장님께",
  "차원이",

  "쓰세요",
  "잠자는",
  "가져가서",
  "모델",
  "오토",
  "여성의",
  "선택했어",
  "볼트",
  "어림도",
  "내려가고",
  "쉬게",
  "죽이려는",
  "랜달",
  "애정을",
  "끈",
  "틱톡",
  "피츠",
  "베일의",

  "마가렛",

  "그이를",
  "한거",
  "구급차",
  "자국이",
  "때까지는",
  "않는구나",
  "날려버릴",
  "봐바",
  "도중",

  "지붕에서",
  "전하는",
  "존경하는",
  "수고하세요",
  "일어나기",
  "통제하고",
  "침실에",
  "과정에서",
  "전화번호를",
  "도와줄래",
  "자아",
  "사랑했던",
  "헤어질",
  "절차",
  "사람이나",
  "수야",
  "바라보는",
  "로맹",
  "인가요",
  "된대",
  "기다렸다",
  "느껴지는",
  "그말은",
  "없대요",
  "알렉스를",
  "로니",
  "사생활을",
  "연결이",
  "바닥",
  "수년간",
  "죽었나요",
  "찾아보자",
  "쏴버려",

  "돌이",
  "비프",

  "곰",
  "당하는",
  "생일에",
  "없었거든요",
  "사스케",
  "한의",
  "있을거라고",
  "아이들과",
  "애비가",


  "곳이에요",
  "뉴",
  "매독",
  "발견하면",
  "구해준",
  "시작은",
  "막기",
  "혼인",
  "물러서요",

  "벌어지는",
  "녀석아",
  "이것으로",
  "옥수수",
  "스포츠",
  "시애틀에서",
  "남들",
  "계좌",
  "생겼다고",
  "들어줄",
  "생각하진",
  "살펴봐",
  "본이",
  "가볼게",
  "나만의",
  "사연이",


  "역사가",
  "부인한테",
  "말씀이신지",
  "때릴",
  "될걸",
  "일이오",

  "깨끗해",
  "의문을",
  "부탁하는",
  "가수",
  "한계가",
  "들었소",
  "약혼자가",
  "용기",
  "생각하는거야",
  "마시게",
  "말하라",
  "석",
  "달의",
  "테다",
  "스노우가",
  "나라는",
  "진실",


  "가볼까",
  "이놈들",
  "괜찮다니까",
  "바다에서",
  "블레이크",
  "이메일",
  "뚫을",
  "돕게",
  "읽었는데",
  "신에게",
  "굶주린",
  "산으로",
  "유감이지만",
  "짐승",
  "자신은",
  "흥분",
  "오시죠",
  "파티에서",
  "혈관을",
  "일이라",
  "잠에서",
  "빨랑",
  "지붕",
  "소원",
  "알아내",
  "경비가",
  "지금처럼",
  "알아야지",
  "연기가",
  "공주가",
  "하다고",

  "미친듯이",
  "베리",
  "놀랐어",
  "꼬리",


  "괜찮으시다면",
  "혐의로",
  "그런것",
  "유럽에",
  "해야겠다",
  "먹을까",
  "혈액이",
  "착륙",
  "상태야",

  "되는군",
  "몹쓸",
  "안타깝게도",
  "멋지고",
  "시작된다",
  "체온을",
  "스텔라",
  "요샌",
  "성현",
  "거지요",
  "웨이컴",
  "이놈들아",
  "대회에서",
  "얼음을",
  "남았지",
  "달러짜리",
  "이겼어요",
  "얘들은",
  "부인께",
  "얘네",
  "보여준",
  "새디",
  "말자고",
  "저러고",
  "자야지",
  "낳는",
  "순철이",
  "대박",
  "건강하고",
  "꿈의",
  "싸서",
  "놀자",
  "중이죠",
  "입었어",
  "느꼈어",
  "따뜻하고",
  "원했어요",
  "묶여",
  "피우지",
  "상대할",

  "고통받고",
  "컵",

  "모험을",
  "내리면",
  "결정에",
  "아니였어요",
  "전방",

  "대형",
  "가졌어요",
  "안젤라",
  "그곳으로",
  "꺼내야",

  "성가시게",
  "예감이",
  "들었고",

  "밟고",
  "취직",
  "믿을수",
  "전화기를",
  "민아",
  "빨아",

  "웃으면서",
  "떠오르는",
  "피곤해요",
  "벨트",
  "소용없어요",
  "마렴",
  "넘었어",
  "싸우기",
  "유코",
  "근본적인",
  "무리",
  "오다니",
  "세르",
  "괜찮대",
  "사려고",
  "신난다",
  "로라가",
  "쇼크",
  "대하는",
  "유쾌한",
  "대위님",
  "관심도",
  "손목을",
  "댁은",
  "사이야",
  "산도발",
  "배우",
  "내용이",
  "사실이라면",
  "잃었다고",
  "사용을",
  "필수적인",
  "이거면",
  "일년",
  "잡혔어",
  "쓰려고",
  "장거리",
  "수상한",

  "베스를",
  "드라마",
  "메러디스한테",
  "장소에서",
  "드류",
  "않으실",
  "거기는",
  "끝내줘",
  "비슷하게",
  "샤론",
  "피우고",
  "남편하고",

  "피부는",
  "노래해",
  "안정적이에요",
  "구린내야",

  "친구에요",
  "놈이랑",
  "당근",
  "가깝게",
  "탔어",
  "어려워",
  "하겠니",
  "거칠게",
  "오닐",
  "운전해",
  "잃어버렸어",
  "선한",
  "건강을",
  "등이",
  "성칠아",
  "최소한의",
  "오려고",
  "일마레",
  "선택권을",

  "관심은",
  "따님의",
  "거기선",
  "이름이죠",
  "배웠어요",
  "침입",
  "스위스",
  "킷",
  "범죄자",
  "도와야",
  "기분인지",
  "주변에서",
  "배터리를",
  "이병",
  "따님",
  "망원경을",
  "아시면",
  "공격적인",
  "의심을",
  "떨어지게",
  "신과",

  "변할",
  "스타크는",
  "이겼다",

  "발표",

  "우리집에",
  "잠깐요",
  "했지요",
  "쳐다보지도",
  "되긴",
  "수고했어요",
  "보구나",
  "몫을",
  "신분증",
  "고향이",
  "없을걸",
  "알긴",
  "두통이",
  "하나님이",
  "시작됩니다",
  "제어할",
  "버크와",

  "발견하지",
  "불쑥",
  "경찰이야",
  "지냈나",
  "끝났네",
  "잘하면",
  "앤드루",
  "찾아와서",
  "엔진을",
  "알까",
  "그쪽도",
  "집에선",
  "되야",
  "발견을",
  "사건으로",
  "욕을",
  "그리울",
  "이년이",
  "쯤은",
  "짐이",
  "돌아가라",
  "들어갔다",
  "맞아서",
  "해볼래",
  "별다른",
  "졌다",
  "팔아서",
  "소림사를",
  "부상이",
  "늦기",
  "잘못됐어",
  "레몬",
  "들리네요",
  "설리번",

  "팔면",
  "가능하게",
  "암호를",
  "고를",
  "인턴은",
  "케이스에",
  "임신을",
  "구급",
  "괜찮았어요",
  "건요",

  "떼지",
  "저한테는",
  "키운",
  "부서진",
  "클램프를",
  "얀",
  "해봅시다",
  "순간까지",
  "고담",
  "경사",
  "노란색",
  "귀여워",
  "되는거",
  "별들",
  "춥고",

  "죽이라고",

  "키스하는",
  "될거다",
  "속도가",
  "동부",
  "상황이야",
  "해주시면",
  "경우에는",
  "체크해",
  "수준의",
  "그분께서",

  "시험에",

  "왔다갔다",
  "공장에서",
  "가져와라",

  "어쩌다가",
  "이제껏",
  "코트",
  "구역을",

  "병사들을",
  "눈에는",

  "영화의",
  "봐서는",

  "시방",


  "버렸다",
  "아까는",
  "오늘을",
  "원하는거야",
  "취하고",

  "둘의",
  "번개",
  "효과를",
  "얘긴데",
  "거거든요",
  "무서워하지",
  "발자국",
  "아는데요",
  "선택해야",
  "높아요",
  "드리지",
  "정신적으로",
  "시타",
  "주셨어요",
  "면이",
  "숨쉬는",
  "워싱튼",
  "몸속에",
  "있던가요",
  "광고를",
  "그레이의",
  "그때도",
  "저들의",
  "여인이",
  "품위",

  "되니까요",
  "뜻인가요",
  "시절이",
  "오브를",
  "아니래",
  "중요해요",
  "어딘데",
  "하는가",
  "바위",
  "하고싶지",

  "장가",


  "묘한",
  "켜",
  "다물어요",
  "가르칠",
  "치프의",
  "없을지도",
  "퍼시",
  "같았는데",
  "필요했던",
  "셰일라",
  "실례가",
  "부엌에",
  "공부하는",
  "오셨습니까",
  "으응",
  "돌봐야",
  "선배가",
  "방사능",
  "쓰여",
  "붙어있는",
  "비네",
  "작가",
  "있었는데요",
  "황홀한",
  "곳도",
  "스케줄",
  "만스",
  "불만이",
  "몇년",
  "준비하세요",
  "매니",
  "영주가",
  "레슬리",
  "헬멧을",
  "푸는",
  "해주려고",
  "뚝",
  "도착할",
  "불행하게도",

  "명성을",
  "보호할",
  "이럴수가",
  "피하려고",
  "고민",
  "예약",
  "알레르기가",
  "잡았습니다",
  "동식이",
  "여친",
  "불은",
  "감을",
  "리처드를",
  "땀이",
  "개도",
  "해제",
  "재스퍼씨",
  "것이니",
  "막혀서",
  "작전은",
  "드시고",
  "물건들을",
  "일이냐",
  "권한",
  "사이는",
  "말빈",
  "말았어",
  "독수리",
  "축구",

  "입어요",

  "기쁘네요",
  "났는데",
  "미사일",
  "아저씨들",
  "희생자를",
  "땀",
  "다녀오겠습니다",
  "아저씨한테",
  "명령은",
  "달라진",
  "마땅한",
  "사모님이",
  "형제들을",
  "필요할거야",
  "하던지",
  "일이니까",
  "준비도",
  "그런다",
  "먹어서",
  "커튼",
  "버니",
  "그루트다",
  "나란",
  "보곤",
  "트럭이",
  "승리의",
  "결혼에",
  "퓨리",
  "먹으라고",
  "아니구",
  "은혜를",
  "잘한",
  "간거야",
  "어디든지",
  "잡으세요",
  "아침이야",

  "가져왔다",
  "되라고",
  "왔다가",
  "부위",
  "아름답게",
  "찍어야",
  "흘러",

  "부작용이",
  "해순아",
  "어떡하면",

  "솔직한",
  "멍하니",
  "기다리라고",
  "말씀드리죠",
  "입장이",
  "드린",
  "대신해",
  "마지막이야",
  "랑",
  "데니스",
  "당해",
  "인기",
  "까진",
  "티켓",
  "벙어리",
  "라마",
  "발견했다",
  "연설을",
  "컴퓨터로",
  "장례식",
  "자세하게",
  "시체들을",
  "잊어요",
  "밴",
  "라이커",
  "바뀌지",
  "이들",
  "모셔다",
  "클라크",
  "이것만은",
  "확인해봐",
  "자러",
  "다르다는",
  "미국인",
  "힘들었어",
  "에이미를",
  "소문은",
  "틀려",
  "배너",
  "피아노",
  "기억에",
  "반으로",
  "물건들",
  "그래라",
  "저녁이",
  "멍청이가",
  "젖",
  "계셔야",
  "슬픔",
  "사정",
  "피의",
  "안이",
  "수트",
  "시신이",
  "무니",
  "뱃속에",
  "먹었다",
  "약점을",
  "묻죠",
  "놈한테",
  "한데요",
  "일전에",
  "줄만",
  "참나",
  "줄로",
  "자연",
  "영화에",
  "신체",
  "헬기를",
  "펜실베니아",
  "팀의",
  "버는",
  "가격을",
  "색시",

  "호텔의",
  "불리한",
  "서장님이",
  "꿈속에",
  "위치는",
  "영혼은",
  "예쁘지",
  "관계도",

  "하그레이브",
  "사람인지",
  "친구여",
  "섬세한",
  "외과의",
  "건강에",
  "박수를",
  "눈과",
  "우예",
  "창조주의",
  "무기도",
  "바이러스가",
  "디",
  "공항에서",
  "성스러운",
  "귀염둥이",
  "아이다",
  "박사를",
  "실크",
  "박이",
  "도망치지",
  "초대를",
  "뭐로",
  "멋지죠",
  "그렇단다",
  "거절할",
  "따라올",
  "제프리",
  "구해줄",
  "믿어도",
  "맹세코",
  "아버지요",
  "바다로",
  "일이었어요",
  "그렇담",
  "컴퓨터가",
  "아담",
  "톰은",
  "뒷",
  "문제라고",
  "얼마죠",
  "산산조각",
  "이야기도",
  "돼지가",
  "고생이",
  "말씀드리고",
  "말해주는",
  "지금까지는",
  "만든다고",
  "합법적인",
  "이러시면",
  "그럴듯한",
  "코에",
  "강에서",
  "갈아입고",
  "둘러",
  "집의",
  "박스트롤을",
  "블록",
  "십만",
  "장관님",
  "접속",
  "싫었어",

  "라프",
  "도망가지",
  "점보",
  "거짓말쟁이야",
  "해온",
  "문자",
  "흐르는",
  "기름이",
  "사라지지",
  "본은",
  "알아냈어요",
  "노리는",
  "가치도",

  "예술",
  "피에",
  "좋아한다",

  "행동으로",
  "신고를",
  "지니고",
  "오스코프",
  "신경은",
  "지우씨",
  "그렇게나",
  "없었소",
  "말과",
  "단서",
  "범블비",
  "예의를",
  "임자가",
  "로즈랑",
  "대단하다고",
  "낯이",
  "향단아",
  "믿어주세요",
  "무슨말인지",
  "재미있을",
  "훈련은",
  "보상을",
  "버크에게",
  "마고",
  "국가를",
  "올께",
  "모양이에요",
  "바라보고",
  "선생을",

  "아악",
  "보고싶었어",
  "꼬꼬마",
  "적에",
  "출장",
  "내려요",
  "마찬가지",
  "흔들",
  "한다니까",
  "앞이",
  "재미있어",
  "문에서",
  "그것으로",
  "판카실라",
  "기적",
  "증말",
  "쉬워",
  "조직에",
  "걱정도",
  "번엔",
  "두려움에",
  "따른",
  "배신자",
  "이성을",
  "어쩔건데",
  "어렵고",
  "걔의",
  "낀",
  "외",
  "인상을",
  "기다렸다가",
  "끔찍해요",
  "숨어서",
  "절반은",
  "오겠습니다",
  "않구나",
  "나왔지",
  "들어가라",
  "있다며",
  "되기를",
  "흉부에",
  "지닌",
  "못해도",
  "되겠습니다",
  "찔러",
  "기쁘구나",
  "현상",
  "인간이야",
  "헤어져",
  "놔라",
  "무대에",
  "일반적으로",
  "복",
  "리사는",
  "왕십리",
  "분께",
  "양동이",
  "뒤돌아",
  "지자쓰",
  "준태야",

  "스텐",
  "오바",
  "싶어할",
  "오겠지",
  "보",
  "수비",
  "쟤네",
  "구체적으로",
  "게지",
  "이상하네",
  "법적으로",
  "절친한",
  "알라",
  "왔으니까",
  "물었어",
  "거였지",
  "거를",
  "카테터를",
  "토할",
  "잘하셨어요",
  "봐봐요",
  "빙고",
  "많잖아요",
  "용의자가",
  "고객",
  "새끼는",
  "미자씨",
  "생체",
  "극히",
  "지켜봐야",
  "보물",

  "잠자코",
  "추고",
  "골이",
  "남았다",
  "면을",
  "차가워",
  "자에게",
  "연구에",
  "마을이",
  "압니다만",
  "바이러스",

  "언제나처럼",
  "서세이",
  "슬론을",
  "내려온",

  "한발",
  "잘있어",

  "동의를",
  "의학의",
  "좋아하니",
  "모르는군",
  "봤나요",
  "주십쇼",

  "모",


  "차야",
  "표현을",
  "역사",
  "비참한",
  "형제들",

  "입어야",


  "정연아",
  "걸어라",
  "좋았죠",
  "믿음이",
  "처치를",

  "가능하다고",
  "그리고요",
  "세계적인",
  "마취",

  "휴대",
  "사귈",
  "행사",
  "적색",
  "국왕",
  "중이니까",
  "평소",
  "너네는",
  "분명해요",
  "미쳤군",
  "형과",
  "싫거든",
  "전부는",
  "취하지",
  "지옥에나",
  "쥐가",
  "희귀한",
  "여하튼",
  "믿겠어요",
  "줬다고",

  "옥경이",
  "오토바이",
  "참여하고",
  "루이스",

  "버릇",
  "냄새도",
  "악몽",
  "그리웠어",
  "찼어",
  "가려면",
  "계셨죠",
  "가져가세요",
  "생겼고",
  "못봤어요",
  "멋졌어요",
  "안은",
  "험프리",
  "사랑하세요",
  "너넨",
  "대단하군",
  "거절",
  "축하드려요",
  "두명이",
  "부위를",
  "영양",
  "재밌을",
  "처치",
  "확인하러",
  "지구로",
  "단계로",

  "살죠",
  "아처",
  "영어를",
  "함께라면",
  "행복하지",
  "미친년",
  "올라프",
  "대령님",

  "태양",
  "직장도",
  "뚫어",
  "주었다",
  "같거든요",
  "아니길",
  "인터넷에서",
  "피해자가",
  "여유를",
  "호출하셨어요",
  "집이라고",
  "봉합을",
  "태양을",
  "테이프를",
  "의심하는",
  "연락해서",
  "사랑한단다",
  "거짓말하는",
  "보드카",
  "사이엔",
  "어떨지",
  "공식",
  "말한게",
  "밧줄을",
  "해줬으면",
  "리브",
  "플랜",
  "비타민을",
  "찢어진",
  "아니더라도",
  "참아요",
  "밴드를",
  "선택해",

  "한결",
  "소리나",
  "호출하고",
  "옆집에",
  "물었죠",
  "사야",
  "어울릴",
  "대상",
  "가석방",
  "걸렸다고",
  "따바르",
  "데이트하는",
  "뭐하시는",
  "이리와요",
  "국경",
  "펭귄",
  "성격",
  "얼마를",
  "형편없어",
  "늙어서",
  "전화해야",
  "수있다",
  "펠랑이",
  "라스베가스",

  "그런거지",
  "여행은",
  "마리안느",

  "일자리",
  "다른건",
  "세울",

  "망쳐",
  "발목",
  "진행할",
  "다르지만",
  "도끼를",
  "일명",




  "혜린",


  "열어야",




  "장애",
  "붙인",
  "우주선이",

  "일기",
  "여자친구를",


  "전부요",
  "입자를",
  "제안은",
  "훔쳤어요",
  "하렴",
  "걱정해야",
  "미쳤냐",
  "사랑하는지",
  "압박",
  "애리조나",

  "평화롭게",

  "끝없는",
  "만지는",

  "의사와",
  "토스트",
  "층",
  "변화는",
  "정리할",



  "단순",
  "보겠어",
  "사업은",
  "시계는",
  "사람들로",
  "오셨네",
  "기름",
  "만들었는데",
  "아니였어",
  "예스",


  "기다려주세요",
  "이모는",
  "알려줄게",
  "해야하지",
  "처음이에요",

  "되든",
  "장담하는데",
  "빼야",
  "벌거벗은",
  "안되는데",
  "주인공이",
  "지르고",
  "옮기는",
  "반지가",
  "기쁠",
  "기껏",


  "어려워요",

  "설명해봐",
  "현금",
  "그랬다간",
  "예수님",
  "자존심",
  "공간에",

  "하나뿐인",
  "보고싶지",
  "멍청해",
  "몇달",
  "멍이",

  "일으키는",
  "이야기야",
  "맹세컨대",
  "조지한테",
  "샴페인",
  "남게",
  "회의를",
  "메러디스에게",

  "냉장고에",
  "워드",
  "일종",


  "시각은",
  "멋있어",
  "싶은가",
  "멀어요",
  "판사가",
  "나갈게",
  "했군요",


  "돌아오길",
  "걔네는",
  "궁금해서요",
  "제공합니다",
  "변경",
  "보호하는",

  "미치지",
  "사귄",

  "미확인범이",
  "짖는",
  "장난해요",
  "외출",
  "만듭니다",
  "쉐퍼드랑",
  "의대에",
  "군대는",

  "멀지",
  "대화할",
  "방해",
  "하지마요",
  "꺼져버려",
  "들죠",
  "충성을",
  "경비원",
  "바를",
  "여러분들이",
  "치를",
  "우울한",
  "사항이",
  "남자들을",
  "아프고",
  "뵈요",

  "대장동무",
  "언덕",
  "봐선",
  "공항",
  "무릎에",

  "옷장",
  "살라고",

  "정도밖에",
  "생선",

  "대화하는",
  "사슴",
  "불렀어요",
  "버튼",

  "없애는",
  "성공한",
  "몫",
  "데려가라",
  "재산",
  "터",
  "알파",
  "조니를",
  "할말",
  "뵙고",
  "전선을",
  "지키려고",
  "되다니",
  "습격을",
  "온다는",
  "기도가",
  "가보고",
  "그러더니",

  "떠난다고",
  "이것좀",
  "정원에",
  "발견되지",
  "살인이",
  "존의",
  "무덤을",

  "이보세요",
  "사기",
  "다음주에",
  "정도에",
  "가니까",
  "둘러싸여",


  "잡힐",
  "호흡이",
  "카레브는",
  "사냥을",

  "가자꾸나",
  "조차도",
  "그애를",
  "자랐어요",

  "목격한",
  "얼마예요",
  "생각과",
  "거리의",
  "없다니",


  "동상",
  "걱정해",
  "손에서",
  "공산주의자",
  "겁먹고",
  "공부도",
  "어려울",
  "작업은",
  "나는이",
  "미치는",
  "복잡하게",
  "그쪽에",
  "만큼이나",

  "듣던",
  "감마선",


  "무료로",
  "하기도",
  "고치는",
  "임신했어",
  "실시",
  "끝나요",
  "있다면요",
  "킹스맨",
  "익숙한",
  "갈수",
  "기쁘군요",
  "세금",

  "먹었는데",
  "정확히는",
  "사면",
  "생각이었어",
  "끝이에요",
  "제나",
  "숙녀분들",
  "옆을",
  "쓴다",
  "샤워",
  "구할수",
  "동안의",

  "조조의",
  "모은",
  "태울",

  "아니었습니다",
  "편을",
  "슬론의",
  "가져야",

  "좋겠소",
  "이용하는",

  "카라브락소스",
  "여깄어",
  "없더군",
  "빅토리아",

  "마이크를",
  "잘해봐",
  "있든",
  "괴물이야",
  "헤어",
  "적게",
  "키도",
  "인턴이에요",
  "맥키",
  "해야겠군",
  "돈에",
  "형님은",

  "두명의",
  "괜찮대요",
  "숲으로",
  "지미는",
  "죄인",
  "암의",
  "당직실에서",
  "청년",
  "죽었다고요",
  "개인적",
  "필요한게",
  "노예로",
  "보라색",
  "빼앗아",
  "메건",
  "장담할",
  "분대장",
  "범죄에",
  "필요한지",
  "당신한텐",
  "눈의",
  "음악은",
  "질거야",
  "보셨죠",
  "놓으라고",
  "해리는",
  "서있는",

  "공주는",
  "분이세요",
  "수면",
  "서비스를",
  "뜨면",
  "무서움은",
  "스카이",
  "아가리",
  "막힌",
  "실험실에",
  "스타렛",
  "욕실",

  "것이든",
  "시험이",
  "돌아왔습니다",
  "나오라고",
  "써라",
  "넣지",
  "올리지",
  "남잘",
  "격리",
  "신장이",
  "글자",
  "살았지",
  "울음을",

  "날엔",

  "나아졌어",

  "레오나르도",
  "손가락에",
  "죽으려고",
  "그리고나서",
  "칼라마",
  "치프랑",
  "그럼에도",
  "오늘따라",
  "사내",
  "세포를",
  "꿈이야",
  "뺄",
  "내일의",
  "어머님은",
  "하아",
  "숨었니",
  "받아들이고",
  "오네요",
  "부풀어",
  "명확하게",
  "방도가",
  "치우고",
  "짝을",
  "복도에",
  "뭔가에",
  "그년이",
  "이야기의",
  "정도야",
  "낫군",
  "에어",
  "무슨일이죠",
  "아캄",
  "빠지는",
  "살린",
  "들리니",
  "좋거든요",
  "존경해요",
  "시작하세요",
  "드는데",
  "물도",
  "새고",
  "할수있어",
  "바다에",
  "비디오를",
  "맡길",
  "멀어",
  "사건이야",
  "끊고",
  "수동",
  "훌쩍",
  "있었다고요",
  "공원에서",
  "장갑",
  "좆도",
  "일이었어",
  "친구들도",
  "몰랐지만",
  "강철",
  "세상과",
  "만났고",
  "놀라서",
  "하늘로",
  "숨겨진",
  "나도야",
  "말곤",
  "피곤해서",
  "레베카는",
  "놀이를",
  "돕기",
  "떨지",
  "상냥한",
  "모를까",
  "갔던",
  "오말",
  "게임의",
  "바꿔서",
  "허가를",
  "배우지",
  "지쳐",
  "용납할",
  "비용을",
  "가져도",
  "빅",
  "만나본",
  "독한",

  "여깄다",
  "나서지",
  "그만하지",
  "이집트",
  "야호",
  "노력이",
  "웨버가",
  "사장님이",
  "날이다",
  "싸우게",

  "자신만의",
  "산드라",
  "못하겠지",
  "싸웠어",
  "물려",
  "엉터리",
  "마미",
  "경찰도",

  "놀랍군",
  "벗어나지",
  "스튜어트",
  "잘라서",
  "약간은",
  "생명체를",
  "일하던",
  "꺼지라고",
  "글씨",
  "죽지는",
  "고집이",
  "돈과",
  "불필요한",

  "끝냈어요",
  "하고싶은",
  "싶어해요",
  "나타나고",
  "라나",

  "뚱땡이",
  "소개해",
  "찾았나",
  "그랬겠죠",
  "들어도",
  "베쓰",
  "사과할",
  "애들에게",
  "대니는",
  "타오르는",
  "헥",
  "늙은이가",
  "지적이야",
  "아무렇지",
  "여자분이",
  "베이스",
  "척추를",
  "올려요",
  "보려면",
  "이불을",
  "뉴스를",
  "속상해",
  "길이에요",
  "안개",
  "싸가지",
  "해야하는",
  "교통사고",
  "신디",
  "신발은",
  "확실합니다",
  "리더가",
  "쓰레기통에",
  "마련이다",
  "내다",
  "엔터테인먼트",
  "재미없어",
  "잊지마",
  "귀족",
  "성가신",
  "충격",
  "바보라고",
  "나가도",
  "들었는지",
  "예쁘네",
  "맞다고",
  "뭐하냐",
  "바꿨어",
  "개새끼들아",
  "생겼는지",
  "지난주",
  "베라",
  "시합이",
  "여자들과",
  "여자란",
  "가족들에게",
  "질투",
  "말씀하신",

  "스캔을",
  "자격도",
  "받습니다",
  "중이었어",
  "힘들어서",

  "지친",
  "소피",
  "불가능",
  "놈과",
  "말거야",
  "있나봐요",
  "시애틀로",

  "책임질",
  "뿐이니까",
  "봉투",
  "놈인데",
  "좌표를",
  "어쩔수",
  "말이라고",
  "아니게",
  "로맨틱하게",
  "오역",
  "때문인가요",
  "섹시해",
  "소질이",
  "보나",
  "날에는",
  "컸어",

  "음악에",
  "우주선",
  "여름이",
  "붙어서",
  "않을게",
  "달랐어요",
  "케일럽이",
  "명예",
  "건드린",
  "앤더슨",
  "물론이야",
  "인정하기",
  "무장한",
  "손님들이",
  "보여줄까",
  "이에",
  "다음주",
  "내버려둘",
  "서요",
  "기다려봐요",
  "컸구나",
  "갈거에요",
  "라고요",
  "해드리죠",
  "의자",
  "미쳤니",

  "감독이",
  "샘이",
  "춘향이",
  "있을거예요",
  "고양이는",
  "지지",
  "없더라",
  "불명료",
  "멍청하고",
  "원했어",
  "사람이니까",
  "상의",
  "포틀리",
  "크리스토퍼",
  "정원",
  "정말입니까",
  "이천",
  "가지도",
  "룰을",
  "시작됐다",
  "외과의사",
  "의사잖아",
  "힘껏",
  "그럴수도",
  "희생자",
  "화나",
  "결정했어",
  "버려라",
  "선수를",
  "층에",
  "책임",
  "지도자가",
  "항생제를",

  "피타를",
  "인물이",
  "생겼습니다",
  "돌아오라고",
  "노예가",
  "많군요",
  "준비할",
  "없어졌어요",
  "잊어버리고",
  "척수",

  "종양에",
  "기다립니다",
  "주겠어요",

  "마로니",
  "아빠예요",
  "화났어요",
  "받아들여야",
  "알아야겠어",
  "살다",
  "다행이지",

  "저래요",
  "여행이",
  "지나는",
  "에이미의",
  "차례예요",
  "집엔",
  "가겠다",
  "다행이에요",
  "보시는",
  "일등",
  "사람만이",
  "로밀리",
  "적으로",
  "얘기해야",
  "그에겐",
  "아르뛰르",
  "듣지도",
  "돼지를",
  "번개가",
  "모양을",
  "죄인을",
  "봄",
  "함은",
  "부모님과",
  "밑",
  "소중히",

  "누구라고",
  "사용하고",
  "알기론",
  "영국의",
  "한장",

  "단서는",
  "괜찮았어",
  "나갑니다",
  "됐는데요",
  "강해질",
  "축소",
  "종족을",
  "날마다",
  "당장이요",
  "반짝이는",
  "작동하는",
  "않군요",
  "봤다는",
  "뜻으로",
  "경계",
  "남자에",
  "유인원이",

  "서울로",
  "사람이예요",
  "아이디어를",
  "식구가",

  "꺼내고",
  "있었으니",
  "불가능합니다",
  "묻혀",
  "거라네",
  "주셨으면",
  "여름에",
  "정직하게",
  "연관된",
  "바뀐",
  "애들이나",
  "보여드리고",
  "다음번엔",
  "병사들이",

  "무서웠어요",
  "희생을",
  "좆",
  "찾아올",
  "진실된",
  "밀실",
  "거미",
  "문제없어",
  "잡아야지",
  "수색",
  "조절할",
  "트렁크에",
  "둘도",
  "비로소",
  "의미하지",
  "오디션",
  "게다",
  "움직임을",
  "희미한",
  "가까스로",
  "수년",
  "말들을",
  "영군아",
  "쟤한테",
  "엔진이",
  "쏘기",
  "캐리",
  "두려워서",
  "망",
  "끓여",
  "ㅋ",
  "얘기했죠",
  "한번이라도",
  "깨닫지",
  "사건에서",
  "랜스",
  "권한을",
  "어서오세요",
  "있어야만",
  "운영하고",
  "아니었고",
  "입는",

  "내렸어",
  "곱게",
  "오말리를",
  "키라",
  "패턴이",
  "폰",
  "전투를",
  "가지지",
  "슬론이랑",
  "성공했어",
  "있었던거야",
  "인연이",
  "명령이다",
  "더럽고",

  "아케디아",
  "허니",
  "전할",
  "네스키",
  "그러는지",
  "음식도",
  "누이를",
  "도와줄수",
  "한달에",
  "가만두지",
  "어렵게",
  "크기를",
  "인가",
  "감정에",
  "삶이란",
  "기억나는",
  "세우고",
  "이후론",
  "별들의",
  "주입해",
  "얼씬도",
  "말이유",
  "박스",
  "썼고",
  "해줘야지",
  "얼씨구",

  "죽습니다",
  "겠지",

  "데비",
  "올라가고",
  "내버려두지",
  "우회술을",
  "유",
  "깨워",
  "분위기를",
  "가져갈",
  "경우도",
  "흠뻑",
  "기다리면서",
  "얘기하기",
  "나밖에",
  "둬야",
  "화이트",
  "해줄거야",
  "어째서요",
  "이렇게요",
  "낙하산",
  "데려왔어",
  "의미로",
  "떠났다",
  "챙겨서",
  "저사람",
  "불과해",
  "샹",
  "데이트도",

  "먹어봐",
  "마차를",
  "결론을",

  "놀라워요",
  "며칠만",
  "전환",
  "것이라",
  "밥이나",
  "잠금",
  "소풍",
  "잡지에",
  "이래야",
  "펴고",
  "의리",
  "형태의",
  "중이거든요",
  "생각해보고",
  "노래하는",
  "소식이에요",
  "않으셨어요",
  "하에",
  "쥐어",
  "주십시요",
  "허락해",
  "죽으라고",
  "화요일",
  "몸과",
  "섬으로",
  "알리는",
  "즐겨",
  "그러셨어요",
  "어린애가",
  "다운",
  "삼십",
  "친구나",
  "섬이",
  "지냈니",
  "제거하기",
  "아이제야",

  "찾는게",
  "척하고",
  "하시네",
  "하마",
  "닐",
  "재밌네요",
  "관리인",
  "찾아가",
  "깜빡",
  "괜찮냐",
  "왕비님",
  "의사로",
  "시작했고",
  "챔피언",
  "분노가",
  "인순이",
  "이룰",
  "쏜다",
  "실종된",
  "있을테니",
  "봤더니",
  "발은",

  "따르라",
  "정리하고",
  "켈리가",
  "북부",
  "아빠처럼",
  "재미있군",
  "공중",
  "안엔",
  "토",
  "된대요",
  "그깟",
  "정확해",
  "주죠",
  "만들었잖아",

  "서두르는",

  "생각했거든",
  "여가",
  "여자친구는",
  "데이터를",

  "면목이",
  "붕대",
  "누구에게나",
  "나치",
  "크기",
  "암은",
  "멋지구나",
  "적에게",
  "만났지",
  "퇴각",
  "먼로",
  "뿐이오",
  "인젠",
  "사항은",
  "현실",
  "어디로요",

  "잊는",
  "뻥",
  "좋겠지",
  "철도",
  "프랑스에",
  "전화번호는",
  "현재로선",
  "있나봐",
  "이것에",
  "라니스터가",
  "떨어지지",
  "허리가",

  "차원",

  "짚",
  "동생아",
  "많거든요",
  "시작하겠습니다",
  "야야야",
  "짜증나게",
  "가겠소",

  "목격자가",
  "암살",
  "질서를",
  "소년은",
  "노인이",
  "고객님",
  "전화기가",
  "중요합니다",
  "볼일이",
  "심장도",
  "모르면",
  "케이트가",
  "사스케는",

  "긁는",
  "전하고",
  "북으로",
  "빤히",
  "오셨네요",
  "클리닉에서",
  "칼슨",
  "정원에서",
  "지금이라도",
  "우리집에서",
  "알아야만",
  "킹스랜딩에서",
  "있으렴",
  "등등",
  "쫓아내",
  "일항",
  "말해줘야",
  "투여하고",
  "홍콩",
  "실패를",
  "고려해",
  "행복해질",
  "여자고",
  "보이나요",
  "김동무",
  "때와",
  "태어나기",
  "보는게",
  "말했었지",
  "불공평해",
  "입만",
  "챙겨요",
  "과부",
  "세계는",
  "훔칠",

  "한바탕",

  "젊었을",
  "말하던가요",
  "공간",
  "있다니까요",
  "봐야죠",

  "가문",
  "성모",
  "주민들은",

  "규정에",
  "일생을",
  "길거리에서",

  "데미안",
  "안가요",

  "걸립니다",
  "다르고",
  "웃기네",
  "파트너가",
  "요양원에",
  "위해서죠",
  "사기꾼",
  "비겁한",
  "쓰레기를",

  "하는건가",
  "안돼지",
  "늦으면",
  "저희들이",
  "정리해",
  "지휘관",
  "줄에",
  "아니었으면",
  "녀석들을",

  "놔두지",
  "짜식아",
  "스톱",
  "파이가",
  "배터리가",
  "건다",
  "하느님이",
  "생각하면서",
  "조각이",
  "따위가",
  "특히나",
  "장례를",

  "않았으니",
  "당최",
  "쉴드는",

  "발목을",
  "여자애는",
  "분도",
  "고맙다는",
  "직장에",
  "인물",
  "같단",
  "땅은",

  "보이던데",
  "멀린",
  "미안한",
  "견뎌",

  "모르는군요",
  "곤란하게",
  "아가씨를",
  "이유죠",
  "기다렸지",
  "잘못하면",

  "방울",
  "깨끗하고",
  "고통스러워",
  "처박혀",
  "통한",
  "축제",
  "비난할",
  "낫잖아",
  "환각을",
  "차지",
  "참이야",

  "커플이",
  "정신적인",
  "방장님",
  "목숨은",
  "재미있네",
  "취급",
  "동생의",
  "요청합니다",

  "빅커스",
  "없어지고",
  "서문도",
  "싱글",

  "마리오",
  "도우러",

  "전무님",

  "키스하고",
  "실패했어",

  "영리한",


  "내리게",
  "잘못했다고",
  "혼자선",
  "방어",
  "편에",
  "거울에",
  "분자",
  "박사님은",
  "책임은",
  "본래",
  "있는것",
  "오래간만에",
  "받으실",
  "웃겨요",
  "밖에선",
  "초콜렛",
  "똑똑해요",
  "토리",
  "이론이",
  "난리",
  "싶죠",
  "가셨어",
  "깨우지",
  "빼지",
  "돌보는",

  "히틀러를",
  "차이를",
  "한다네",

  "터무니",
  "간격으로",
  "전설",
  "몇분",
  "모양이군요",
  "노력했어",
  "신혼여행",
  "들었는데요",
  "과일",
  "음료수",
  "고위",
  "살아남기",
  "개월",
  "자비스",
  "잔을",
  "집에만",
  "끝내자",
  "주소가",
  "아무데나",
  "없더군요",
  "돌아왔군",
  "늦었네",
  "의술을",
  "세가지",
  "철이가",
  "크리스티나랑",
  "연락도",

  "큽니다",
  "다녀올게",
  "클레릭",
  "마스크",
  "일하기",
  "건의",
  "모실",
  "이상한데",
  "뒤쪽에",
  "팀으로",
  "맞으면",
  "보셔야",
  "맑은",
  "그랴",
  "기증자가",
  "언론에",
  "온대",
  "남편에",
  "위험하다고",
  "상관이죠",
  "놀라울",
  "일일",
  "말하진",
  "과다",
  "친구고",

  "목욕",
  "도와드릴게요",
  "갔소",
  "난들",
  "스컬리",
  "그거지",
  "출혈은",
  "유지해",
  "경민아",
  "다니지",
  "일어나게",
  "보물이",
  "아내도",
  "잘자",
  "신부를",
  "무리가",
  "사라져라",
  "성격을",
  "그럴만한",


  "더워",

  "늦었군",
  "체스를",
  "잊어버리지",
  "커녕",
  "검정",
  "나사",

  "수술대에서",
  "보이는구나",
  "젖을",
  "화물",
  "태양은",
  "불량",
  "동작",
  "맞아도",
  "시합",

  "시작이다",
  "역을",
  "꿈에도",
  "결정이야",
  "살아있다는",

  "말씀드린",
  "인상",
  "내려와서",
  "옛날엔",
  "포함한",
  "지울",

  "시지",
  "아니겠어",
  "죽을지",
  "냄새는",
  "이빌",
  "생겼구나",
  "휠체어",

  "자정에",

  "주시하고",
  "괴로운",
  "하셨는데",
  "싸워라",
  "수준이",

  "밋첼",
  "일어납니다",
  "어이쿠",
  "의도는",
  "오빠한테",
  "이야기할",
  "영호",
  "너라고",
  "장의",
  "읽어야",
  "과부가",
  "스테파니",
  "외삼촌",

  "건강하게",
  "갤럭사",
  "생각하거든",
  "도와준",
  "문제에요",
  "똑똑하지",

  "주머니를",
  "가십시오",
  "글쎄다",
  "삽관을",

  "지나가던",
  "시내로",
  "짐승이",
  "표를",
  "대화도",
  "가다",

  "날부터",
  "가구",
  "방자야",
  "만들게",
  "시끄럽게",
  "판을",
  "혼인을",
  "위험하게",
  "전력이",
  "밤낮으로",
  "뜨겁게",
  "이기",
  "특정한",
  "상관없다",
  "두드리는",

  "공주를",
  "박아",
  "누군진",
  "한복판에",
  "중이",
  "돌리는",
  "엿이나",
  "프로토콜을",
  "미군",
  "해병대",
  "올래",
  "짜고",
  "많구나",
  "고집을",
  "방법이죠",
  "별들을",
  "이내",
  "들리겠지만",
  "큐",
  "살아남는",
  "죽이진",

  "실험실",
  "하는구만",
  "탓이야",
  "신원을",
  "무섭고",
  "가로",
  "미쳤지",
  "한가지만",
  "그리는",
  "별명",
  "해줄게요",
  "긍정적인",
  "별명이",
  "집어치우고",
  "가나요",
  "졸업하고",
  "사와",
  "불평",

  "말하시오",
  "죄책감이",
  "호카게",
  "별들이",
  "찾는다면",
  "이빨이",
  "뿌리",
  "불러주세요",
  "천국이",
  "이왕이면",
  "지켜주고",

  "있는줄",
  "마시죠",
  "어떡합니까",
  "백성을",

  "야인",
  "사이버맨이",
  "다닌",
  "아메리카",

  "판에",
  "데려다줄",
  "과장님께서",
  "내려오는",
  "과장님한테",
  "의학적으로",
  "우연이",
  "맛있다",
  "보호자",
  "여인",
  "폭발할",
  "아드님은",
  "오니까",
  "뚱보",
  "아니었지만",

  "넬슨",
  "사고였어요",
  "누구신가",
  "빼낼",


  "지옥의",
  "명예롭게",
  "국수",
  "유전자를",

  "비우고",
  "차차",
  "짐승을",
  "식당에",
  "너만의",
  "출입",
  "걸친",
  "이놈은",
  "말할수",

  "어디에나",
  "공유하고",
  "키스할",
  "대단하네요",
  "일어나세요",
  "가치는",
  "이번주에",
  "손님을",
  "우후",

  "말이지요",


  "대령",
  "닿을",
  "붙을",
  "애기가",
  "감당",
  "좋은걸",
  "벌써부터",
  "꼴로",

  "부브가",
  "그러겠지",
  "어딨냐",
  "생겼나",
  "아니세요",
  "느낄수",
  "갔을까",
  "찾으라고",
  "이사람이",
  "붙들고",
  "협곡",
  "장기가",
  "가지면",
  "바다가",
  "챨리",
  "시술을",
  "오말리랑",
  "걸음",
  "은행은",
  "당시에",
  "엘",
  "덩어리",
  "했기",
  "지식",
  "사람들이에요",
  "알텐데",
  "구식",
  "마이크는",
  "폭풍을",
  "독립선언서를",
  "조셉",
  "벤자민",
  "용의자는",
  "행복하길",
  "두려워하고",
  "덩어리가",

  "서둘러서",
  "네트워크",
  "재밌지",
  "표합니다",
  "용감하고",
  "호소하고",

  "당신도요",
  "컨설트가",
  "뻔했어요",
  "혼자서는",
  "살았죠",
  "스트립",
  "구는",
  "동네를",
  "나눠서",
  "그렇고요",
  "건너편에",
  "터키",
  "핸드폰을",
  "멋졌어",
  "해결책을",
  "가져오면",
  "인근",
  "기능은",
  "놈이라고",
  "너랑은",
  "동굴",
  "크다",
  "시점에서",
  "즐거움을",
  "억조",
  "약해",
  "시작해볼까",
  "용왕님",

  "태워서",
  "있으므로",
  "잠에",
  "어무이",
  "잘렸어",
  "이안이",
  "별로요",
  "확신해요",
  "끝으로",
  "어지간히",
  "호기심이",
  "안다구",
  "하시는지",
  "전세계",
  "땅콩",
  "더블",
  "다가오는",

  "청소를",
  "요원은",
  "부릅니다",
  "그림자를",
  "조프리를",
  "않단다",
  "구역에서",
  "그렇겐",

  "생각이네",
  "괴로워",
  "전차",
  "준태",
  "시주",
  "사랑하기",
  "가져오고",
  "얘기지만",



  "피해서",
  "후크",



  "뉴욕으로",
  "싸움에서",
  "나누는",
  "그러시겠지",

  "모른다고요",
  "맥박",
  "말씀드려",
  "칠면조",
  "시간동안",
  "프랑스에서",
  "업데이트",
  "그것들",
  "카메라는",
  "날카로운",
  "파이필드",
  "세월을",
  "클로다",
  "일이라는",

  "장군의",
  "어젯",
  "필요하오",
  "일이세요",
  "처리할게",
  "지르지",
  "인정하지",
  "하는건지",
  "도장",
  "할로웨이",
  "달렸어요",
  "제게는",
  "내줄",

  "뜬",
  "말은요",
  "머물러",
  "알아둬",
  "보이는군요",
  "사업에",
  "읽지",
  "한미숙",
  "주간",
  "작동하지",
  "여기서도",
  "그대로야",
  "못했네",
  "벌어서",
  "없었다는",
  "생일날",
  "투여",
  "같다면",
  "피해야",
  "닦아",
  "근무가",
  "올거에요",
  "이리오렴",
  "우습게",
  "알았다구",
  "우치하",
  "시작하게",
  "않았는지",
  "좋을거",
  "수치를",
  "외국인",
  "해는",
  "빌려줘",
  "이상할",
  "환자분이",
  "다물게",
  "저는요",
  "통과",
  "꺼에요",
  "그러던",
  "장난치는",
  "바쁠",
  "결과도",
  "거기에서",
  "것들에",
  "할로윈",
  "머리로",
  "문열어",
  "뱀",
  "의사고",
  "지상",
  "왕과",
  "소대장",
  "몇번",
  "우리들을",
  "준비했어",
  "나가봐",
  "싸우다",
  "그랬고",
  "못되게",
  "시작했을",
  "명과",
  "어디갔어",
  "걸게",
  "열어줘",
  "이나",
  "용을",
  "안심이",
  "문신",
  "사람이면",
  "트립",
  "해봐도",
  "감방에",
  "범인",
  "하면요",
  "던진",
  "병원에선",
  "형편",
  "입지",
  "환자로",
  "용서해줘",
  "호출한",
  "동의하지",
  "땀을",
  "의학적인",
  "사막",
  "불빛",
  "제거해야",
  "회전",
  "할거예요",
  "앤디는",
  "대상은",
  "팁",
  "하나죠",
  "높아",
  "치워요",
  "즐거워",
  "수호",
  "사람이잖아",
  "공격이",
  "그런말",
  "그런일은",
  "이해는",
  "대상으로",
  "생각이었어요",
  "아니기",
  "나타났다",
  "불안정한",
  "암호화",
  "따지고",
  "로리",
  "지속적인",
  "전화야",
  "배고파요",
  "시애틀에",
  "달렸다",
  "알았으니",
  "성공적인",
  "자면서",
  "옵니다",
  "간밤에",
  "있냐고요",
  "은색",
  "일치하는",
  "야채",
  "학위를",
  "당황하지",
  "일요일에",
  "보이시죠",
  "남았을",
  "고통과",
  "아닐거야",
  "근처의",
  "매독에",
  "올리면",
  "없어지는",
  "맞출",
  "아니었나",
  "믿어줘",
  "생긴다면",
  "수염",
  "본드",
  "몬스터",
  "잔디",
  "효과는",
  "왔대",
  "익명의",
  "빠지지",

  "올슨",
  "죽었네",
  "움직인다",
  "다칠",
  "블로그에",
  "친구들에게",
  "애디슨은",
  "말씀해주세요",
  "못써",
  "온거",
  "후부터",
  "디지털",
  "듣는다면",
  "떠들고",
  "언니의",
  "예전부터",
  "인터넷에",
  "말했었죠",
  "따님은",
  "에린",

  "저쪽이",
  "아이비",
  "해야겠네",
  "마약에",
  "러셀",
  "외국에",
  "매티",
  "않더군",
  "되시죠",
  "해고야",
  "오늘날",
  "엘렌",
  "나오미",
  "살아있다",
  "벌레를",
  "컸어요",
  "자기들이",
  "제대로된",
  "대마초",
  "역에",
  "수고했다",
  "고상한",
  "외로워",
  "악을",
  "문신을",
  "깰",
  "언제지",
  "포기한",
  "웃기고",
  "아들이에요",
  "통제가",
  "북부를",
  "꿈속에서",
  "어쩌라는",
  "쉬울",
  "우정은",
  "속였어",
  "다할",
  "하셨나요",
  "도주",
  "평소처럼",
  "끊어야",
  "왔대요",
  "기다릴게",
  "약하게",
  "했다구",
  "순종자는",
  "입자들이",
  "지낸",
  "떠났을",

  "영감을",
  "이루어진",
  "손자",
  "뉴스에서",
  "개만",
  "세리",
  "대가가",
  "끊을",
  "숙녀는",
  "이쪽이에요",
  "패스",
  "길인데",
  "찾는데요",
  "머리카락이",
  "페드로",
  "용납",
  "뒤의",
  "데이지",
  "챙기고",
  "여보시오",
  "기다리죠",
  "낭비하지",
  "이리와봐",
  "슬프게",
  "거짓말한",
  "싸우면",
  "될거",
  "빨간색",
  "사건과",
  "대게",
  "계획이야",
  "이중",
  "나가기",
  "했답니다",
  "않구",
  "도서관",
  "간호사를",
  "양반은",
  "잡지를",
  "통제하는",
  "돕지",
  "인디언",

  "나혼자",

  "그리스도",
  "그자가",
  "그따위",
  "사형을",
  "대표해서",
  "썼지",
  "아침이",
  "맛있어요",
  "불알",
  "차나",
  "모양이지",
  "무례하게",
  "지문",
  "했는가",
  "록시",
  "레베카가",
  "일인가요",
  "빼놓고",
  "때까지요",
  "있었으니까",
  "읽었어",

  "준비하는",
  "지원이",
  "유지할",
  "그보단",
  "불러봐",

  "다혜씨",
  "그룹이",
  "생깁니다",
  "웃긴다",
  "일어났을",
  "늙어",
  "극장에",
  "통째로",
  "낄",
  "행위",
  "죽는걸",
  "생각났어",
  "경의를",
  "묘지",
  "몰핀을",
  "머릿속에서",
  "진통제를",
  "민우씨",
  "쉬워요",
  "설마요",
  "끝없이",
  "이어지는",
  "책임자가",
  "형사가",
  "구체적인",
  "자르지",
  "치료하는",
  "사람에게는",
  "런던의",
  "형수",
  "중에는",
  "졌어요",
  "시간당",
  "노력했어요",
  "경기에",
  "영웅은",
  "네요",
  "크루즈",

  "굿모닝",
  "못했고",
  "협상",
  "초음파를",
  "움직이지도",
  "말도안돼",
  "락",
  "말해줄래요",
  "인데",
  "의미하는",
  "죽이거나",
  "사람보다",
  "판단",
  "금고에",
  "신처럼",
  "그랬습니다",
  "앉아봐",
  "그랬니",
  "행방을",
  "박은",
  "얘기하게",
  "좋은거",
  "드시면",
  "같더라",
  "사라졌다",

  "모른다구요",
  "싶은건",
  "키키",
  "쏘냐",
  "상업용으로",
  "지났다",
  "근육",
  "영업",
  "지루해",
  "먹으려고",
  "도와줘서",
  "그날이",
  "우그웨이",
  "사랑했네",
  "어떠셔",
  "늦을",
  "전해요",

  "태워다",
  "괜찮단다",
  "얘기하죠",

  "까지는",
  "생각인데",
  "닮아서",
  "싶어하죠",
  "힘든데",
  "못하지만",
  "딸도",
  "마찬가지예요",
  "보트를",
  "힘과",
  "잘지냈어",
  "왔나요",
  "침",
  "그러더군요",
  "그놈들은",
  "종을",
  "왜냐고",
  "놔두면",
  "끝났고",
  "타야",
  "끝냈어",
  "괜찮은데요",
  "내일도",
  "맹세합니다",
  "나으리",
  "의견은",
  "같나요",
  "전체의",
  "시대를",
  "뜻입니다",
  "데려가려고",
  "찾는다고",
  "없으면서",
  "알거든요",
  "이었어요",
  "포기했어",
  "자매들은",
  "사인은",
  "셰퍼드를",
  "투어",
  "합병",
  "환상적인",
  "말한대로",
  "견디고",
  "노력하지",
  "있는디",
  "근거",
  "사망시각",
  "화내는",

  "리스본",
  "싫어하고",
  "가끔씩은",
  "옳소",
  "손목",
  "우정을",
  "영국에",
  "논문을",
  "쯤에",
  "케익",
  "당신이요",
  "큐브",
  "무척이나",
  "정상이야",
  "무당",
  "전보를",
  "분홍",
  "수술실은",
  "아이예요",

  "그리스",


  "취향이",
  "바뀌는",
  "후드",
  "기쁘군",

  "없어야",
  "실",
  "자라지",
  "공항에",
  "골드",
  "관리인이",
  "바랬어",
  "감아",
  "사인해",
  "턱을",
  "엄말",
  "바란다고",
  "가득찬",
  "짝",
  "별난",
  "마다라메",
  "엄마예요",
  "누굴까",
  "원주민",
  "케이드",
  "공부는",
  "자라서",
  "가져간",
  "죄도",
  "보셨잖아요",

  "직원들이",
  "라임",
  "새로온",

  "행성의",
  "갚아",
  "유감이군",
  "있은",
  "크지",
  "됐잖아요",
  "머리에서",
  "잠시동안",
  "역사의",
  "피부에",
  "당신이에요",
  "해냈다",



  "도착했어",
  "의지가",
  "생각하는데요",
  "잊어버려요",
  "드렸어요",
  "내놔요",
  "위해서지",
  "리빙스턴",
  "수갑을",
  "루즈벨트",
  "알았냐",
  "옵티머스",
  "햄",
  "터스",
  "수고하십니다",

  "좋아했죠",

  "명절",
  "현금으로",
  "강제",
  "작전이",
  "해결책이",
  "강도를",
  "구해줘",
  "우째",
  "때렸어",

  "폭파",

  "실수야",
  "원이",
  "확률은",
  "쓰죠",
  "없대",
  "키스한",
  "프랭크가",
  "혈압",
  "이방인",
  "버려요",
  "들어와라",
  "사라지게",
  "실수예요",
  "아까부터",
  "힘에",
  "어메이징",

  "정상적으로",
  "진저",
  "길도",
  "드리겠어요",
  "찾아내",
  "람세스",
  "못살게",
  "평화의",
  "감수할",
  "채우고",
  "했다니까",


  "아이요",
  "전해라",
  "아버질",


  "것인데",
  "끊어요",
  "않는단다",
  "배우가",
  "살아날",
  "가는거",
  "주스",
  "밖을",
  "당신들에게",
  "부모님의",
  "해나",
  "아버지처럼",
  "유명",
  "하고자",
  "친구들하고",
  "나였어",
  "위스키",
  "먹으면서",
  "면",
  "버키",
  "백조",
  "죽었소",
  "부담이",
  "전원을",
  "고르는",
  "요원을",
  "프랜신",
  "흐름을",
  "에테르를",
  "무시하고",
  "바쳐",
  "시원하게",
  "로져",
  "잠들어",
  "끔찍했어요",

  "진정해라",
  "말씀이죠",
  "체온이",
  "지각",
  "목표를",
  "부대를",
  "서명을",
  "문서를",
  "탐사",
  "당하지",
  "망친",
  "갖춘",
  "불리하게",
  "습격",
  "키스도",
  "넘기지",
  "될텐데",

  "나무로",

  "타깃",
  "부다페스트",

  "끝은",
  "남편이에요",
  "소리죠",
  "저주",
  "성장",
  "그러시는",
  "발견됐습니다",

  "고맙단",
  "조프리가",
  "성격이",

  "돌려보낼",
  "틀리지",
  "고아",
  "안전하다",
  "이륙",
  "건너는",
  "신중히",
  "자료",

  "벗는",
  "한공주",
  "호텔로",

  "푸시업",
  "보기보다",
  "제공하는",
  "구역의",
  "만났으면",
  "작동을",
  "샌프란시스코",
  "돈의",
  "타워",

  "정철민",
  "타라",
  "넣었어요",
  "지랄이여",
  "재판이",
  "로난이",
  "들었거든",
  "대기실에서",
  "자신과",
  "편안한",
  "썼는데",

  "재미는",
  "핸드백",
  "미국이",
  "놔둬요",

  "퓨리가",
  "위장을",
  "고통스럽게",
  "미확인범은",
  "최초로",
  "고맙긴",
  "셜리",
  "좋아했어",
  "레스토랑에",
  "두배로",
  "이거는",
  "보호하려는",
  "그라니온",
  "애자",
  "하느님의",
  "갈고리",

  "기적은",
  "낫겠다",
  "귀엽게",
  "물었다",
  "참지",
  "대기하고",
  "비켜라",
  "열차에",
  "것만도",
  "제안",

  "관점에서",
  "걷지",
  "폐하께서",
  "음탕한",
  "본의",
  "만났다",
  "비난",
  "복권",
  "얼만데",
  "기자",
  "있었겠죠",
  "랭스턴",
  "어딨냐고",
  "지키지",
  "페이지",
  "그러기엔",
  "느그들",
  "광선",
  "뭐꼬",

  "절도",
  "이거에",
  "보이소",

  "됐다는",
  "찾아왔어",
  "뉘",
  "협조",
  "보관",
  "하셨을",
  "없었네",
  "수로",

  "잠은",
  "조의를",
  "쓰레기야",
  "오해가",
  "실험은",
  "명령이야",
  "이글",
  "준비해요",
  "의사라고",

  "수고들",
  "공연이",
  "포위된",
  "내라",
  "가거나",
  "트럭으로",
  "말하기를",
  "설사",
  "크리스마스를",
  "소개시켜",
  "지역에서",
  "열립니다",
  "나오는데",
  "코도",
  "어딥니까",
  "대원들",
  "반복해서",
  "엄연히",
  "경향이",
  "강렬한",
  "일본에서",
  "모험",
  "딸린",
  "자네라면",
  "양식을",
  "가게가",
  "알바",
  "춤추고",
  "믿을수가",
  "보내줄",
  "혼을",
  "대공",
  "불쌍하고",
  "진실로",
  "않으려면",
  "절반",
  "자금을",
  "떠났다고",

  "여름을",
  "좋아하지만",
  "뒷조사를",
  "쑤셔",

  "말하려",
  "걱정마요",

  "신이여",
  "알려고",
  "자서",
  "알았거든",
  "병력",

  "마시구요",
  "숙이고",
  "교사가",
  "셰익스피어",
  "같은게",
  "고양이도",
  "맥플라이",
  "하던가요",

  "아닐지도",
  "둥지를",
  "신분증을",
  "방귀",
  "돌아다니고",
  "속삭여준단다",
  "제목이",
  "걱정되는",
  "커크",
  "시작됐어요",
  "있었기",
  "데려가세요",
  "애들의",
  "입도",
  "생각했나",
  "구만",
  "끔찍하고",
  "두번이나",
  "명령이",
  "캐롤린",
  "아이쿠",
  "얼마동안",

  "살았는지",
  "목적으로",
  "이동한다",
  "아싸",
  "시스템은",
  "노래하고",
  "모양이구나",
  "침실",
  "아니라도",
  "농담도",
  "입으면",
  "맨손으로",
  "사는게",
  "만들려면",
  "상하게",
  "목숨도",
  "제국을",

  "문자를",
  "침입자",
  "남자친구를",
  "쌀을",
  "요석",
  "린다",
  "싶거든",

  "흉내를",
  "굼벵이",

  "권리는",
  "일상적인",
  "알아야겠어요",
  "빨래",
  "그거죠",
  "좋아한다는",
  "비용이",
  "이야길",
  "그룹에",
  "이름만",
  "할줄",
  "걱정말아요",
  "휴지",
  "미치겠어",
  "최악이야",
  "멍청이들",
  "진통제",
  "필름",
  "포옹을",
  "얼빠진",
  "갔었지",
  "직접적으로",
  "심해질",
  "살거야",
  "쇼핑",
  "가사",
  "나가자고",
  "그거요",
  "대화하고",
  "않았거든요",
  "보냈는데",
  "가보렴",
  "그들에",
  "해야겠지",

  "인간적인",
  "심호흡",
  "좋진",
  "애쓰고",
  "만들었다고",
  "하지마세요",
  "그자는",
  "예쁘고",

  "빌딩",
  "안정적입니다",
  "떠오를",
  "바꾸려고",
  "아는지",
  "모니터를",
  "데려왔어요",
  "인자는",
  "읽어봐",
  "무선",
  "죽은거야",
  "허긴",
  "지나가면",
  "주시길",
  "사랑이란",
  "자르면",
  "굴지마",
  "쟤랑",
  "깨달은",
  "남이",
  "시작을",
  "감사해야",
  "순찰",
  "이름과",
  "본인의",
  "자국",
  "도둑이야",
  "가수가",
  "파트너를",
  "마누벤스",
  "남자란",
  "아름답구나",
  "궁금한게",
  "녀석도",
  "피해자를",
  "마찬가지에요",

  "몸이야",
  "말하곤",
  "잊어버릴",
  "발생한",
  "쓰러져",
  "대니를",
  "보내야",
  "걸었어요",
  "인생도",
  "행세를",
  "스나이더",
  "있으려고",
  "웃게",
  "도망자",
  "수표",
  "박스트롤",
  "시카고에서",
  "보길",
  "정신병원",
  "어제도",
  "스내처",
  "실력을",
  "범행을",
  "거짓말하지",
  "레즈비언",
  "소림사에",
  "가겠네",
  "이룬",

  "쿠키",
  "원하나",
  "거지같은",
  "드려서",
  "일이군",
  "들어오라고",
  "않을거요",
  "준비해라",
  "어떻죠",
  "도와주러",
  "아카츠키의",
  "얘기했지",
  "그러진",
  "돔",
  "러시아어",
  "타키유키",
  "흉터",
  "총격",
  "강도가",
  "일에서",
  "아리아리랑",
  "아리랑",
  "멋있지",
  "쏘면",
  "조지에게",
  "죽자",
  "맡긴",
  "밑을",
  "체포",
  "하나하나",
  "급해",
  "개처럼",
  "라쉬네씨",
  "결혼식이",
  "부산",
  "쓰리쓰리랑",
  "전원이",
  "콥씨",

  "미끼로",
  "왕국",
  "뭐좀",
  "준대",
  "사귀어",
  "시도하고",
  "않을걸",
  "해주시겠어요",
  "놈으로",
  "위험도",
  "세운",
  "슬프고",
  "실을",
  "어무이요",
  "일하면서",
  "위안이",
  "받았던",
  "일본의",
  "할지라도",
  "신임",
  "지극히",
  "레인저",
  "약속할게요",
  "속도는",
  "배꼽",
  "위니",
  "앨트먼",
  "돌려요",
  "잘라내",
  "크롭호퍼",
  "드레스가",
  "친하게",
  "부르지마",
  "나왔고",
  "변호사는",
  "학교의",
  "선물이에요",

  "모건",
  "베시",
  "그렇단",
  "안개가",
  "무너지고",
  "놓쳤어요",
  "생일이",
  "키울",
  "같구만",
  "카우보이",
  "살게요",
  "모르는구나",

  "리는",
  "제우스의",
  "임신이",
  "악당",
  "마찬가지고요",
  "작가가",
  "군에",
  "이제와서",
  "무책임한",
  "비난하는",
  "친척",
  "산책을",
  "내려갈",
  "갤러거",
  "눌러서",
  "뿐이잖아",
  "위해서가",
  "가격이",
  "여기와서",
  "껄",
  "치우는",
  "총알은",
  "피드백은",
  "동료들",
  "죽다니",
  "묻지도",
  "알겠냐",
  "준비됐죠",
  "인도에서",
  "다이아몬드",
  "망을",
  "씨스코",
  "좋아했지",
  "공산주의자들을",
  "굉장해",
  "찾을거야",
  "웰스",
  "보여드리죠",
  "앞의",
  "타서",
  "잔만",
  "페르시아의",
  "가문은",
  "금융",
  "시티",
  "조용히해",
  "되었네",
  "대장이",
  "페랄타",
  "저그",
  "폭포",
  "모르시잖아요",
  "푼도",
  "터틀",
  "불도",

  "같은거야",
  "너네가",
  "존재할",
  "상상해",

  "죽일꺼야",
  "생각하네",
  "달러요",
  "일했던",
  "폴카",
  "전문가가",
  "두명",
  "전투가",
  "믿으면",
  "치과",
  "지장을",
  "되겠구나",
  "환자들의",
  "위기",
  "일이네",
  "이상하군",
  "사례를",
  "지하로",
  "스케줄이",
  "심문을",
  "몰려",
  "반경",
  "일에는",
  "천국을",
  "배의",
  "빌릴",
  "방안에",
  "말해줄래",
  "쓰이는",
  "안보여요",
  "믿기지가",
  "테러리스트",
  "것이었다",
  "초점을",
  "미치",
  "상관없지",
  "훗날",
  "걔네들은",
  "조를",
  "것이라는",
  "늦어",
  "천재야",
  "충고를",
  "것들이야",
  "잡는다",
  "찾아봐야",
  "산은",
  "뒤지고",
  "죽였을",
  "정부를",
  "인터넷으로",
  "꿨어",
  "워렌",
  "보게나",
  "맞았다고",
  "렌리",
  "존재야",
  "보내기",
  "호텔을",
  "병의",
  "실수한",
  "이겁니다",
  "딱지",
  "우리만의",
  "불쌍해",
  "파괴하고",
  "랜디",
  "허락도",
  "그곳",
  "베를린에서",
  "쓰나",
  "다음과",
  "수사",
  "트레드스톤",
  "다리로",
  "미터",
  "준비된",
  "진정으로",
  "속이고",
  "스카이라크",
  "죽였는데",
  "김정은",
  "눈물의",
  "악수를",
  "찾아보라고",
  "마법사",
  "빠짐없이",
  "레이븐",
  "당신에게서",
  "맛이야",
  "팔꿈치",
  "보기로",
  "엠마",
  "곳곳에",
  "내렸습니다",
  "지게",
  "토레스는",
  "기억하니",

  "크리스티나에게",
  "날이죠",
  "나타났어",
  "작아",
  "크다고",
  "되는게",
  "최고가",
  "해본적",
  "심지어는",
  "죽는거야",
  "데려가면",
  "슬프게도",

  "아이에",
  "웃기지도",
  "승리는",
  "했을거야",
  "모자가",
  "해두죠",


  "물러나세요",
  "법적",
  "프로그램이",
  "모임이",
  "병태",
  "좋아지고",

  "거기야",
  "헤이미치",
  "회의가",
  "하길래",
  "아는건",
  "아껴",
  "말한다면",
  "그녀한테",
  "사로잡혀",

  "은밀한",
  "애라고",
  "싶잖아",
  "멀쩡하게",
  "싶대요",
  "틈에",
  "사왔어",

  "않는다면요",




  "아바",
  "않으려",
  "기를",


  "한다니까요",
  "악수",
  "기억나니",
  "스케이트",

  "얘기해줘",
  "스웨터",
  "루루루루",


  "여자였어요",

  "경력이",


  "좋아해서",
  "라니스터의",

  "여편네가",
  "형사입니다",
  "사라졌지",


  "하셔서",
  "집에도",
  "인사하고",
  "정치적",
  "느낀다",

  "평화는",
  "잘하는데",
  "대학을",


  "선택이야",
  "귀엽네요",
  "됐단",

  "봤어야",
  "요법을",
  "의상을",

  "아멜리",

  "안정이",
  "봤네",
  "교통사고를",
  "텐가",
  "잘하지",
  "느낌도",
  "에이고",
  "입장에서",
  "너잖아",
  "보호하려고",
  "멀리까지",
  "도움도",
  "어릴때",
  "얘기했던",
  "같잖아요",
  "살았는데",
  "능력은",
  "알려줘요",
  "실수였어요",
  "누가요",
  "미린의",
  "인질로",

  "며칠간",

  "짜는",
  "처녀",
  "잽",
  "때문인",
  "목표로",
  "헛간에",
  "걸리죠",
  "남자들과",
  "비밀이야",
  "지하철",
  "미국으로",
  "탄환",
  "닥터베일리",
  "시키는대로",
  "완벽하고",
  "워싱턴으로",
  "통증은",
  "변하고",
  "마리아님",
  "팸",
  "아스피린",
  "심박수가",
  "발견하고",
  "끝났군",
  "때문이었어요",
  "멋있다",
  "출신이에요",
  "태어나는",
  "데려오면",
  "걸로요",
  "아닐걸",
  "검사도",
  "중환자실에",
  "있잖소",
  "자궁을",
  "대학교",
  "시민들을",
  "때까지만",
  "분위기가",
  "색깔이",
  "진짜로요",
  "모르냐",
  "전등을",
  "죽었으면",
  "나아지고",
  "조종할",
  "십대",
  "반짝이",
  "마일즈",

  "따르고",
  "봤을때",
  "안되네",
  "시각",
  "내용",
  "장부를",
  "예약이",
  "첨",
  "충동을",
  "배워",
  "죽인다고",
  "드루드",
  "서는",
  "어렵죠",
  "디아발",
  "핀이",
  "해준다고",
  "시키면",
  "싶거든요",
  "아나스타샤",
  "타이리스",
  "시켰어",
  "증오",
  "부상당한",
  "의사양반",
  "오렌지",
  "체포될",
  "돌아가시고",
  "작품은",
  "싫었어요",
  "견인기",
  "개너는",
  "의미인지",
  "쓰지마",
  "환영을",
  "멀고",
  "살아라",
  "아직요",

  "왜곡",
  "그랬냐",
  "리지",
  "저녁식사에",
  "닉키",
  "싫어하죠",
  "배짱이",
  "윈터펠의",
  "추억을",
  "목표물을",
  "크리스티나한테",
  "부담",
  "에이씨",
  "장례",
  "매력적인",
  "얼굴과",
  "싫어하잖아",
  "찾길",
  "심판을",
  "왕국을",
  "맡아줄",
  "예전에는",
  "계속하게",
  "군이",
  "여왕은",
  "결과야",
  "쟤도",
  "접촉",
  "일어났습니다",
  "올해의",
  "정확하다",
  "궁금해요",
  "신기한",
  "타러",
  "단독수술을",
  "동네에서",
  "지상에",
  "자랑스러운",
  "몰랐을",
  "피곤하고",
  "확신",
  "헐크",
  "했길래",
  "나치가",
  "벌벌",
  "인내심을",
  "영수",
  "사도",
  "떠올리게",
  "머잖아",

  "아니라는걸",
  "춤추러",
  "나죠",
  "오지도",
  "홍영수",
  "모는",
  "같지도",
  "그럴려고",
  "이론을",
  "높게",
  "페로디낭이야",
  "삐에로",
  "왕국의",
  "병동에",
  "나왔다고",

  "옮겨요",
  "될까봐",

  "자유가",
  "자네랑",
  "봐주는",
  "버스는",
  "교회를",

  "이것보다",
  "간대",

  "웃음이",
  "나기",
  "주겠다고",

  "끌어내",
  "깨진",
  "않으니까요",
  "놀다",
  "오도록",

  "사랑하니까",
  "찍었어",
  "줘봐요",
  "왔군요",
  "들여보내줘",
  "법원",
  "데려가지",
  "원래부터",
  "애들이랑",
  "동료들이",
  "화면",
  "이혼한",
  "따라가면",
  "열어라",
  "말장난",
  "듀이",

  "하라니까",
  "고향을",
  "최근의",
  "육체적",
  "수술의",
  "말겠어",
  "앙",
  "息をしてみて",
  "가족들과",
  "흘리는",
  "접근하지",
  "고기가",
  "받도록",
  "모든것이",
  "단숨에",
  "주입하고",
  "가란",
  "생각해보세요",
  "받는다",
  "왔거든",
  "동맹을",
  "도독",
  "바라나",
  "인사는",
  "피쉬는",
  "동료를",
  "보내라",
  "들이지",
  "골라서",
  "화살",
  "이랬어요",
  "뜻하는",
  "여자요",

  "과도한",
  "씻어",
  "사실이잖아",
  "없애고",
  "직후",
  "죽였죠",
  "뾰로롱팜므",
  "콜슨이",
  "선수들은",
  "약혼을",
  "주디",
  "안달이",
  "치면서",
  "부는",
  "콘스탄스",

  "부처님",
  "간판",
  "기술의",
  "무시할",

  "오신걸",
  "아스가르드",

  "호출기",
  "않는거야",
  "무대감독님",
  "생각하겠지",
  "울프",
  "보이지도",
  "마시면서",
  "닥터한이",
  "제어",
  "이기지",
  "형제여",
  "느껴요",

  "프로스트",
  "첫날부터",
  "아프니",
  "수술한",
  "벗어나게",

  "다르다",
  "필요없다고",
  "머시웨스트",
  "봐줘요",
  "단어가",
  "광산",
  "뭐더라",
  "전송",
  "빚은",
  "동굴에",
  "넘어가",
  "플레이",
  "체포할",
  "전략적",
  "뿐이라고요",
  "얘기해서",
  "예정된",
  "가져오지",
  "한편으론",
  "공평하게",
  "예정",
  "맘껏",
  "가까워",
  "작아서",
  "해군",
  "전설이",
  "연구실에서",
  "날이지",
  "핀을",
  "점화",
  "피운",
  "아침이네요",
  "헤어졌어",
  "포도주",
  "티가",
  "스토익",
  "그것의",
  "소녀의",
  "티",
  "불가",
  "지금보다",
  "간단",
  "도망치고",
  "내가요",
  "않았을까",
  "빈센트",
  "가깝고도",
  "재호",
  "걷자",
  "싶었죠",
  "진동",
  "의심",
  "재장전",
  "했었다",
  "앤은",
  "섹스하는",

  "끊은",
  "크리스토프",
  "깨달았지",
  "프로그램에",
  "기술로",
  "가깝지",
  "죽일지도",
  "말랬잖아",
  "치료법을",
  "인간에게",
  "로봇이",
  "이별",
  "사이버",
  "누우세요",
  "간은",
  "미화가",
  "질투를",
  "되어가고",
  "가르친",
  "예수님이",
  "정도요",

  "번즈",
  "셈이",
  "주의가",
  "같았죠",
  "있잖나",
  "죽나요",
  "명령도",
  "포함",
  "우리더러",
  "훌륭했어",
  "어떻겠니",
  "약속했어요",
  "가능한한",


  "이렇게는",
  "태어나지",
  "두려움이",

  "그랬을까",
  "아팠어요",
  "나왔죠",


  "타입이야",
  "맞춰야",
  "빼곤",
  "알아내야",
  "수사를",
  "씨로",
  "알아보려고",
  "혼자만의",
  "금고를",
  "기능이",
  "좋은지",
  "중위",
  "파",
  "경주",
  "필요한거",
  "데니의",
  "디나",
  "뛰면",
  "버크도",
  "두꺼운",
  "그리워요",
  "떠나보내",
  "압박을",
  "라니",
  "나빠지고",
  "나가야지",
  "부동산",
  "그만하자",
  "근무를",
  "의사들",
  "케프너",
  "말하는지",
  "정형외과",
  "위원회",
  "되돌아",
  "필요하거든",
  "제한",
  "엘런",
  "셀",
  "다녀왔습니다",

  "귀엽고",
  "그토록",
  "저긴",
  "욕",
  "할테니까",
  "착하기도",
  "개야",
  "얠",
  "꺼내는",
  "연결된",
  "접촉을",

  "중요치",
  "기다린",

  "독감",
  "대해서요",
  "그만하게",
  "시험은",
  "보호하고",
  "출구",
  "깨어난",

  "자식들아",
  "환경",

  "인해서",

  "성기를",
  "바입니다",
  "견디기",
  "맡았어",
  "혼란스러워",
  "보내줄게",




  "테이블에",
  "방식대로",
  "지역이",
  "이전의",
  "질문이야",
  "돌아서서",

  "관리하는",
  "끝내주지",
  "연인",
  "이해했어",
  "운명선",
  "선생은",
  "언니도",
  "찍힌",
  "바깥에",


  "시절에",
  "애들처럼",
  "생물",
  "외상을",
  "마리나",
  "폭스",
  "앨범을",
  "원작",
  "값이",
  "피곤한",
  "피곤하지",
  "칼린다",
  "꼬박",
  "그것보단",
  "신발이",
  "생명은",
  "으이구",
  "수억분의",
  "됐으니까",
  "기분으로",

  "모르몬트",
  "없답니다",
  "녀석에게",
  "보겠다고",
  "어려",
  "번이라도",
  "블랙홀에",
  "만들었는지",
  "멀었어",
  "좋겠니",
  "골목에서",
  "가리지",
  "것이냐",
  "속아",

  "자기랑",
  "쓰리",
  "소린이",
  "손님은",
  "않단",
  "나랑은",
  "이겨야",
  "진심이세요",
  "풍부한",
  "우리같은",
  "헤어지는",
  "뜻도",
  "수갑",
  "담에",
  "외딴",

  "부탁할",
  "중대장님",
  "말이니",
  "의역",
  "여자만",
  "재주가",
  "어기고",
  "얼마야",
  "빌어먹게",
  "실례했습니다",
  "앤이",
  "주둥이",
  "이고",

  "뜯어",
  "있었대요",
  "행복은",
  "자네에겐",
  "보시겠습니까",
  "입에서",
  "맥콜씨",
  "경을",
  "더군다나",
  "발표를",
  "다쳐서",
  "식구들",
  "말할까",
  "쓰러진",
  "그렇듯이",
  "처지가",
  "값",
  "주인을",

  "두수",
  "우스워",
  "형편이",
  "지하실",
  "히드라",
  "출발할",
  "낸들",
  "하나같이",
  "들키지",
  "것임을",
  "따서",
  "억제",

  "소원이",
  "야아",
  "틀릴",
  "욘두",
  "침을",
  "테면",
  "사요",
  "닥치지",
  "지하실에서",
  "않구요",
  "하여금",
  "저두",
  "필요하시면",
  "그래그래",
  "명씩",

  "데이터가",
  "할머니한테",
  "허블은",
  "가시지",
  "유지하고",
  "날더러",
  "맛있네",
  "병도",
  "주나",
  "되질",

  "달링",
  "솜씨",
  "철이야",
  "중으로",

  "않겠습니까",
  "명확히",

  "머나먼",
  "그러고도",
  "다르다고",
  "조수",
  "빛은",
  "용서하십시오",
  "그러고는",
  "씨드를",
  "찾아오면",
  "화신",
  "하겠네요",
  "뒀어",
  "해변으로",
  "하나쯤",

  "매디",
  "될거라고",
  "장군이",
  "말합니다",
  "살아남지",
  "무기로",
  "판매",
  "이유지",
  "생각했을",
  "모른단",
  "필요했어요",
  "어쩌겠어",
  "부위가",
  "알아보는",
  "생각하지는",
  "예쁜이",
  "쉐퍼드와",
  "화재로",
  "조직은",
  "선로",
  "소위",
  "곁으로",

  "달랑",
  "전으로",
  "경치",
  "경찰서로",
  "됐거든요",
  "바가스가",
  "축하할",
  "닥터다",
  "누구고",
  "우울해",
  "경찰입니다",
  "젊은이가",
  "핵폭탄",
  "늑대가",
  "전통적인",
  "사나이",

  "넥타이를",
  "알려주면",
  "결혼했고",
  "때쯤",
  "고용한",
  "취해야",
  "오예",
  "드려도",
  "센터에서",
  "역대",
  "자네들을",
  "말랬지",
  "수액",

  "간만에",
  "냄새나는",
  "섬기는",
  "그거랑",
  "작성해",
  "둘러싸고",
  "폐하께",
  "안되네요",
  "어머머",
  "승인",
  "벌려",
  "처벌을",
  "주겠나",
  "할배",
  "싸돌아",
  "원숭아",
  "만납시다",
  "구운",
  "다중",
  "엥",
  "샤일라",
  "수술하러",
  "복구할",
  "의미있는",
  "왕에게",
  "거짓말할",
  "농담이지",
  "어찌된",
  "올께요",
  "명입니다",
  "가라니까",
  "하이드라",
  "자라게",
  "투자를",
  "건축",
  "감자를",
  "있으니깐",
  "화장을",
  "이식할",
  "정치",
  "씨도",
  "오웬이",
  "이유라도",
  "과학자들이",
  "구조를",
  "박혀",
  "밑의",
  "달러가",
  "좋아졌어요",
  "고대의",
  "설령",
  "조가",
  "없잖니",
  "죄는",
  "숨겼어",
  "내려올",

  "고통도",
  "걔들을",
  "매력",
  "그만해라",
  "유산",

  "결혼식은",
  "시장에",
  "약속했잖아요",
  "목표",
  "볼께",
  "킵",
  "불렀죠",
  "기억나나",
  "먼지가",

  "일에만",
  "살이야",
  "있었고요",
  "최선은",

  "민감한",
  "데이트하고",
  "오르는",
  "환경을",
  "인정할",
  "차서",
  "년이나",
  "판엠의",
  "첫날",
  "캘리의",
  "세금을",
  "부와",
  "미지의",
  "작품이",
  "될거요",
  "금색",
  "남에게",
  "장군을",
  "정도로요",
  "고그는",
  "실력은",
  "대회가",
  "사망했습니다",
  "대포",
  "탄지",
  "그럭저럭",
  "데려와야",
  "양입니다",
  "삼켜",
  "많은걸",
  "덮어",
  "이정현",
  "전면",
  "계시네",
  "있었단다",
  "연료가",
  "폭행",
  "끼어들",
  "외롭게",
  "수요일",
  "붙여서",
  "도착했다",
  "이예요",
  "신에",
  "친구들한테",
  "로봇을",
  "그룹은",
  "가의",
  "옛다",
  "소설을",
  "놓으란",
  "고마운데",
  "알츠하이머",
  "날리고",
  "손엔",
  "옥상으로",
  "환영하네",
  "심슨",
  "전공을",
  "문신이",
  "이거랑",
  "돌아갈래요",
  "쓰는데",
  "리벤지",
  "보였습니다",
  "알고있지",
  "토미가",
  "요시다",
  "의미죠",
  "먹었니",
  "어느새",
  "설계한",
  "차예요",
  "무시하지",
  "팬티를",
  "펌프를",
  "마을로",
  "싶겠지",
  "존경을",
  "비도",
  "애디슨의",
  "중심",
  "입구",
  "복숭아",
  "협박",

  "몇년간",
  "의심이",

  "형수님",
  "예산",
  "매버릭",

  "환자고",
  "김치",
  "분당",
  "자궁이",
  "냉큼",
  "예수님은",
  "여자들도",
  "소파에서",
  "아주머니가",
  "생각하는게",
  "어제의",
  "고민하지말지어다",

  "기관",
  "열어서",
  "니도",
  "통에",
  "끔찍하군",
  "수도에",
  "월급은",
  "상우",
  "크라비스를",
  "죽일수",
  "병신이",
  "춥지",
  "쪽이든",

  "독약을",
  "망치는",
  "돌려줘요",
  "영광스러운",
  "가냐",
  "이겨",
  "가까워요",
  "시작되면",
  "싫대",
  "쓰도록",
  "출신의",
  "바깥에서",
  "잊어버렸어",
  "헤어져야",

  "말릴",

  "나예요",
  "끝에서",
  "에스포지토",
  "맺어진",
  "누구와도",
  "레벨",
  "주진",
  "좋잖아요",
  "모랄레스",
  "실로",
  "사라지면",
  "일들에",
  "서장님은",
  "동윤이",
  "좋을지",
  "연금",
  "성인이야",

  "삽관",
  "다했어요",
  "가져올게",

  "릴리아나",
  "윤이",
  "태도는",
  "만족해요",
  "진주",
  "따지면",
  "드리면",
  "저사람이",
  "테디랑",
  "안전한가요",
  "군복을",

  "봐주면",

  "일러",
  "시커먼",
  "강의",
  "분다",
  "일어났는데",
  "오해를",

  "야마자키",
  "닦을",
  "구경이나",
  "식탁에",
  "알아듣게",
  "ㅋㅋ",
  "훈련이",
  "집처럼",
  "만은",
  "아빠에",
  "바닥을",
  "하십니까",
  "원숭이가",
  "윈드리프터",
  "없을거에요",
  "추억이",
  "바른대로",
  "이런짓을",
  "욕조",
  "늦는",
  "이지랑",
  "풀러",
  "차리지",
  "해야합니다",
  "뿐야",
  "바람과",
  "요안나",
  "벌이",
  "창문으로",
  "다른게",
  "인정하는",
  "듣기론",
  "아델은",
  "레지던트야",
  "바쁘신",
  "결과로",
  "어때서요",
  "증상",

  "엘리베이터를",
  "패터슨",
  "구급차를",
  "받아줄",
  "싶으신",
  "데려오지",
  "좋아했잖아",
  "지불할",
  "시키고",
  "칸",
  "바르고",
  "우회로를",
  "간달프",
  "아프면",
  "같으세요",
  "뿐더러",
  "죽여줘",
  "잭이",
  "심정을",
  "조심하게",
  "한수",
  "짓이라고",
  "잡게",
  "반만",
  "호출하세요",
  "나서요",
  "고마워할",
  "받아들이는",
  "건진",
  "수술하기",
  "후작",
  "얘기하세요",
  "죽이네",
  "해야겠네요",
  "구해줘요",
  "고운",
  "동의하는",
  "바보였어",
  "어렵습니다",
  "하듯이",
  "합치면",
  "자르는",
  "대량",
  "원한을",
  "사용하면",
  "되려",
  "산사는",
  "누나는",
  "뇌수술을",
  "봐주지",
  "새로운걸",
  "싸움에",
  "전이된",
  "부대가",
  "아픈가요",
  "딸기사시미",
  "아는가",
  "경고를",
  "진짜라니까",
  "체포한다",
  "맞춰봐",
  "바지가",
  "감사하는",
  "부엌에서",
  "싸우러",
  "저어",
  "길거리",
  "알콜",

  "늘상",
  "그거나",
  "싫거든요",
  "짝코",
  "못하니",
  "올지도",
  "트럭은",
  "영감이",

  "관리를",
  "싶지는",
  "브레이크를",
  "화도",
  "친구였어",
  "경험",
  "곳마다",
  "막은",
  "깼어요",
  "말했고",
  "이야기하는",
  "자식도",
  "톰슨",
  "돌아가세요",

  "팀과",
  "남아있지",
  "무덤에",
  "짓입니다",
  "찾아내면",
  "내려가는",
  "기록은",
  "겸자",
  "발차기",
  "있지만요",
  "눈빛이",
  "매달",
  "원하죠",
  "진짜에요",


  "악기",
  "모르세요",
  "자세",
  "개방",
  "되신",

  "가문을",
  "당연해",
  "빌린",
  "두목님",


  "케익을",
  "박물관",
  "예전과",
  "마지막이",

  "모르오",
  "있",
  "수의사가",



  "콜먼",
  "위쪽에",
  "응답",
  "좋아한",
  "위층으로",
  "지켜줘",
  "그쪽에서",
  "상황에선",
  "일이든",
  "자초한",
  "머리띠를",
  "가야돼요",
  "무엇인가를",
  "쫓기고",
  "오셨소",
  "차단",
  "연결돼",
  "할일",
  "받아도",
  "대머리",
  "마크랑",
  "트레일러에",
  "성능",

  "남았네",
  "부유한",
  "지노",

  "꿈만",
  "릴라",
  "말해야지",
  "괜찮으니",
  "철로",
  "사랑하잖아",
  "연애를",
  "얼굴의",
  "읽어요",
  "침대가",

  "책도",
  "역사는",
  "시기에",

  "아끼고",
  "과정에",

  "인정해요",
  "뒤에요",
  "막으면",
  "어디가는거야",
  "가봐라",
  "뇌물",
  "보던",
  "막대기를",
  "해이스팅스",
  "구해요",
  "마사키",
  "이토",
  "깔끔한",

  "페인트",
  "이상이야",
  "파스타",
  "형제를",

  "전달할",
  "웨스트로",

  "기차가",
  "그리로",
  "못할까",
  "동쪽에서",
  "눈썹",
  "왕자를",
  "생각했거든요",

  "생각해보니",

  "뼈에",
  "포드릭",
  "탕",

  "머지",
  "그쯤",
  "강으로",
  "생각하기",
  "방법이지",
  "동생이에요",
  "고객의",

  "바이러스를",
  "정원이",
  "모르니까요",
  "아니구나",
  "보여줄래",
  "느낌은",
  "생각나게",
  "도덕적",
  "들어주세요",
  "프로메테우스",
  "않았겠죠",
  "상업적",
  "도로를",

  "라스베가스에서",

  "라파엘",
  "일어나는지",
  "한번씩",
  "흘린",
  "영호야",
  "타이렁이",
  "보장",
  "주먹이",
  "내세요",
  "떨어집니다",
  "언제죠",
  "어마어마한",
  "당당하게",

  "모아서",
  "고소할",
  "많다는",

  "오스본이",

  "아무일",
  "출현",
  "굴어서",
  "경사님",
  "온걸",
  "로드니",
  "왕비는",

  "국가의",
  "움직여라",
  "활을",
  "여왕의",

  "있을테니까",
  "종이를",
  "가족한테",
  "적들이",

  "사람으로서",

  "바란다면",
  "다루기",
  "줬다",
  "마스터",
  "사적으로",
  "죽기전에",
  "어떡해야",
  "구두를",

  "하고도",
  "금지하며",

  "정말이죠",


  "되찾을",

  "티리온이",
  "매력이",

  "취하게",
  "악어",


  "균형이",
  "추진",
  "보라구요",
  "있다면서",
  "여동생을",
  "최악인",
  "알려져",

  "정렬",
  "뜨거",
  "수리",
  "잠자리에",

  "알았다고요",
  "하셔도",
  "뒀어요",
  "무진장",

  "된건지",
  "신들께",
  "전화예요",
  "케이스에서",
  "회장님",
  "아니겠어요",
  "깨끗",
  "그닥",

  "식량을",

  "원하는대로",
  "내부에서",
  "설명해야",
  "물리",
  "준다는",
  "성기",
  "아버님께서",


  "옷에",
  "법에",
  "베트남",
  "자신들의",
  "면도",

  "죽였는지",
  "노출",

  "사장님은",
  "심전도",
  "정상입니다",
  "다에요",
  "식사나",
  "들으세요",
  "반가웠습니다",
  "남자애가",
  "사내가",
  "이쪽이다",
  "언제고",
  "매고",
  "심박동이",
  "집에서는",
  "안부를",

  "대회에",
  "유골을",
  "없길",
  "갈지",
  "서둘러야",
  "놈들이야",
  "가마",
  "여행하는",

  "두라고",
  "들었던",
  "신경이나",
  "불쾌하게",
  "안와르",
  "누구냐고",
  "알고있습니다",
  "깨",
  "머스탱을",
  "이사갑니다",
  "시작이야",
  "스위블러",
  "말씀입니다",
  "하드",
  "여기고",
  "봉쇄하고",
  "마저리",
  "빌어주소서",
  "슬로언",
  "버릇이",
  "때려요",
  "무덤에서",
  "경의",
  "없었나요",
  "저처럼",
  "예루살렘",
  "줄이",
  "문명의",
  "고통의",
  "경고하는데",
  "만들거야",
  "못할걸",
  "빼내",
  "귀찮아",
  "살해한",
  "존재하지도",
  "결혼식에서",
  "저분",
  "한겁니다",
  "다니니",
  "자구",

  "연관되어",
  "배신했어",
  "제네바",
  "이었어",
  "혐의를",
  "살인자야",
  "당장요",
  "리처드는",
  "묵고",
  "닫힙니다",
  "가겠다고",
  "거울이",
  "두드려",
  "점에",
  "통하지",
  "위기에",
  "산타가",
  "생각해도",
  "애쓰는",
  "더할",
  "정신나간",
  "형이랑",
  "얻을수",
  "확실한가",
  "할텐가",
  "돌려서",
  "바치는",
  "니미",
  "축",
  "밀번",
  "돌보고",
  "스키",
  "외계인이",
  "환자분은",
  "가져가는",
  "비극적인",
  "드라이",
  "보여주게",
  "대령이",
  "지하에",
  "카라",
  "죽었다면",
  "작용할",
  "많이들",
  "사격중지",
  "팔도",

  "골라봐",
  "알고는",
  "요전에",
  "틈을",

  "애기를",
  "없겠네",
  "성인",
  "마시고요",
  "북한",
  "계약은",
  "빌리는",
  "걷지도",
  "살인에",
  "노인네",
  "생각해야지",

  "돌이킬",
  "러시아의",
  "지쳐서",
  "보고하라",

  "입장을",
  "악착같이",
  "대기하라",
  "뻔하지",
  "생일이야",
  "제거해",
  "잭슨이",
  "그러한",
  "우정이",
  "꿨어요",


  "보스턴",
  "왼쪽에",
  "개념을",
  "투여했어요",
  "사실이지",
  "되는건",
  "하는지는",
  "맞추고",
  "한마디로",

  "폭탄은",
  "육신을",
  "일엔",
  "하면은",
  "걷게",
  "분석",

  "역사적인",
  "미워할",
  "떠나려고",
  "고문을",


  "그중에",
  "접시",
  "바칠",

  "음료",
  "드셨어요",
  "소심한",
  "달릴",
  "뿌리는",
  "그렇잖아요",
  "안할게",
  "가능",
  "악마야",
  "샤워를",
  "자신감이",
  "케이",

  "일으켜",
  "안드레",
  "됐고요",
  "백조의",
  "두게",
  "집에나",
  "오를",
  "재미있어요",
  "겠네요",
  "늦추지",
  "말없이",

  "뛰어내려",
  "싸우자",
  "저녁엔",
  "가겠지",
  "진지",
  "절반이",
  "직감을",
  "가면을",
  "죽었단",
  "한다니",
  "개들이",
  "찾아야지",

  "들러서",
  "타미나",
  "물건들이",
  "일지도",
  "군대도",
  "잔혹한",
  "수작",
  "날아다니는",
  "가르시브",
  "부른다",

  "스위치",
  "주름",

  "맡기지",
  "감시를",
  "못가",
  "일하는데",
  "구슬을",
  "힘의",
  "멜리사",
  "빕케",
  "라니스터를",
  "이것과",
  "전화라도",
  "경비를",
  "니그마",
  "사람인가요",
  "잡어",
  "눈동자",
  "균형을",
  "오스왈드다",
  "어마",
  "깡통",
  "질서",
  "죽기를",
  "매력을",
  "과분한",
  "향수",

  "데보라",
  "최고지",

  "그랬나요",
  "대원들은",
  "실제론",

  "첫번째로",
  "실례할께요",
  "된다구요",

  "갔잖아",
  "상대하고",
  "놀다가",
  "기분도",
  "아니었다고",
  "신사분들",
  "쉐퍼드의",
  "제자리",
  "풋볼",
  "변했어요",
  "둘이서만",
  "마취과",

  "눈알을",
  "네놈을",

  "미첼",
  "생각으로",
  "가능합니다",
  "무시해",
  "너한테도",
  "범인의",
  "죄없는",
  "그런거죠",
  "타이그리스",
  "가셔야죠",
  "애송이",
  "이봐들",
  "듀크",
  "끝났소",
  "운동도",
  "아놀드",
  "끝낸",
  "씨요",

  "왜그래요",
  "공부에",
  "페이지양",
  "대가리",
  "기념으로",

  "가슴도",
  "바탕으로",

  "포스터",
  "익숙하지",
  "가져가야",
  "층을",
  "요구할",
  "몰랐잖아",
  "연구실",
  "메러디스에",
  "고통에",
  "페니가",
  "아드님을",
  "낫겠지",
  "괜찮을거에요",
  "하느냐",
  "거짓말로",
  "도착하기",
  "맨필드",

  "나이도",
  "커지고",
  "안됐지만",

  "냄새나",
  "정신에",
  "데려가게",
  "저쪽에서",
  "준비나",
  "바둑을",
  "걱정이야",
  "분이야",
  "요새를",
  "브라질",
  "현실이야",
  "못을",
  "의사랑",
  "벌어질",
  "로난을",
  "너구나",
  "쐈는데",
  "병든",
  "암으로",

  "땅으로",
  "놔둬라",
  "정면으로",
  "동의해요",
  "해변",
  "트럽쇼의",
  "유럽에서",
  "어디라고",
  "출구가",
  "왔을때",
  "성당에",
  "소식이야",
  "놀아요",
  "소방관",
  "손들어",
  "어찌됐든",
  "않",
  "헤어졌어요",
  "없었나",
  "화끈한",
  "여왕을",
  "있었구요",
  "좋아했는데",
  "서방님이",
  "취했어요",
  "않는군요",
  "하소서",
  "하는것",
  "위쪽",
  "깨달았어",
  "문젠데",
  "황당한",
  "하시겠어요",
  "사랑하지만",
  "닥터양",
  "몇번이나",
  "더프",
  "듬뿍",

  "입장",
  "성경에",
  "어디냐",
  "어쩌겠어요",
  "던지고",
  "차차차",
  "이었습니다",
  "지시가",
  "토하는",
  "주인공",
  "페니는",
  "미안했어",
  "수술용",
  "웹",

  "가긴",
  "충돌이",
  "굴면",
  "희생자가",
  "부잣집",
  "신비로운",
  "났습니다",
  "증명하기",
  "싫어하거든",
  "르네",
  "독사",
  "너한테서",
  "코너에",
  "파멸의",
  "기쁘다",
  "치료하고",
  "햄버거",

  "볼래요",
  "줄테니까",
  "몰핀",
  "공포에",
  "비참하게",
  "쿱",
  "물리학",
  "덫에",


  "발견했는데",
  "접어",
  "전날",
  "가여운",
  "잡으라고",
  "전화했어요",
  "정돈",
  "잡힌",
  "생각하겠죠",
  "오블리비언",
  "레나",
  "가정에",
  "봤잖아요",
  "경찰에서",
  "이러는거야",
  "물어보게",
  "가지로",
  "변신",
  "순진한",
  "괴물은",
  "여러분도",
  "그러라고",
  "얘들",
  "이러다가",
  "부분만",
  "전보다",
  "묘지에",
  "돌아가려고",
  "삼아",
  "오사장",

  "가려고요",

  "따라오는",
  "모양이다",
  "벌레가",
  "눈길을",
  "졸려",
  "때리는",
  "가졌던",
  "온나",
  "폭력적인",
  "여기지",

  "천둥",
  "근육이",
  "시장을",
  "과정이",
  "온도가",
  "가게를",
  "안단다",
  "재가",

  "거였다",
  "틀어",
  "주슈",
  "한글",
  "뒈져라",
  "언제야",
  "따위로",
  "공격한",
  "의상",
  "지었어요",

  "서두를",
  "랜슬롯",
  "얼굴에서",
  "놀라는",
  "행동해",
  "먹으렴",
  "모두한테",
  "이만하면",
  "겁낼",
  "렉시는",
  "점차",
  "중엔",
  "답답해",
  "선량한",
  "남자예요",
  "스코틀랜드",
  "생겼을",
  "무식한",
  "이태리",
  "알러지가",
  "혼란",
  "최고라고",
  "가려던",
  "포기하면",

  "전망이",
  "애잖아",
  "갔냐",
  "이광민",
  "얇은",
  "없던데",
  "라쉬네",
  "바뀌었어",
  "담요를",
  "도청",
  "소아외과",
  "계산해",
  "질병",
  "감",
  "싱크대",
  "봄이",
  "질문입니다",
  "검은색",
  "오는지",
  "골절",
  "임충",

  "비둘기",
  "풀지",
  "끝이다",
  "작업이",

  "라인드",
  "수술이나",

  "해놨어요",

  "자니",
  "상처도",
  "편도",
  "지키겠습니다",
  "창씨개명",
  "묶고",

  "시내에",
  "뿌려",
  "유대인",
  "죽을수도",
  "사랑방",
  "찾았군",
  "보이냐",
  "잃었습니다",
  "수업이",
  "전국",
  "애에게",

  "가져갈게",
  "기획",
  "웬일이에요",
  "중이거든",
  "늦겠다",
  "타겟",
  "권총",
  "어벤져스",
  "무작정",
  "서봐",
  "알렉스한테",

  "반찬",
  "나갈거야",
  "가져와서",
  "눈빛을",
  "번개에",
  "거라고는",
  "엄한",
  "사과는",
  "주라",
  "바라오",
  "만약을",
  "영아",
  "꺼낸",
  "일했어요",
  "폭풍",
  "멈춰서",
  "대답도",
  "찾으려면",
  "미래도",
  "설득할",
  "북부는",
  "상상했던",
  "손들고",
  "나타나기",
  "고장났어",
  "범죄의",
  "이겼다고",
  "팔아야",
  "때였어",
  "남자로서",
  "당겨라",
  "형님을",
  "그렇구",
  "들키면",
  "후보",
  "여기와",
  "지겨운",
  "문화적인",
  "마시자",
  "성에",
  "인턴에게",
  "골반",
  "크리스티나와",
  "납작하게",
  "저것들을",
  "올라갑니다",
  "댁을",
  "낳지",
  "듣기만",
  "지금이나",

  "경찰관이",
  "나비",
  "벡스",
  "토마토",
  "인간이란",
  "한방에",
  "여자애들이",
  "거부하는",

  "찌르지",
  "놀아라",
  "지났어요",
  "뒤에도",
  "그렇지도",
  "눈치가",

  "부릴",
  "진전이",
  "하질",
  "뭐래요",
  "체계가",
  "따라가는",
  "강요할",
  "폼페오",
  "뿐이네",

  "물러서지",
  "양심의",
  "감기에",
  "셰일가스",
  "무명",
  "운명",
  "선서",
  "네빌",

  "환상이",
  "신발에",
  "가버린",

  "됐을까",
  "시작할까",
  "없더라고",
  "커질",
  "남는다",
  "불법이야",
  "요청한다",
  "뿐이잖아요",
  "있다는거",
  "도우려는",



  "하자구",
  "좋을꺼야",
  "좋았을텐데",
  "너부터",
  "본때를",
  "환상",
  "볼때",
  "도련님이",
  "있겠다고",
  "벌고",
  "드십시오",
  "크레인",
  "않더군요",
  "쳤어요",
  "대사형",
  "죽겠는데",

  "어디서든",
  "걸어야",
  "악당이",
  "가져오는",
  "받는다고",
  "섹스하고",
  "같았지",
  "순간들이",

  "본인이",
  "쓸쓸한",
  "동윤아",

  "좋아했던",
  "점심이나",
  "분들을",
  "요원들을",
  "여편네를",
  "하더라구요",
  "레베카를",
  "프랑스가",
  "무너지는",
  "봤다면",
  "경기에서",
  "스튜디오",
  "낭비할",
  "심장으로",
  "값진",

  "찾아볼게",
  "관상동맥",
  "아프진",
  "물건이야",
  "구석이",
  "거부합니다",
  "강아지야",
  "열었어",
  "대가",
  "종료",
  "다루고",
  "노래나",
  "놈들과",
  "봐야겠다",
  "웬만한",
  "상관은",
  "판사님",
  "필요했어",
  "하시는군요",
  "예쁘다고",
  "쐬고",
  "팬",

  "춤출",
  "그레이조이",
  "버디",
  "싶었거든",
  "반갑소",
  "레스토랑",
  "그것만은",
  "왔다는",
  "달라졌어",
  "배나",
  "심판의",
  "불렀지",
  "있어줘서",
  "아버님의",
  "음식과",
  "자리도",
  "도와주면",
  "애와",
  "괜찮지만",
  "지워",
  "지점이",
  "극도로",
  "컴퓨터에",

  "셀빅",
  "농담이에요",
  "부탁하네",
  "좋아하지도",

  "칫솔",
  "대군을",
  "뇌사",
  "못살아",

  "동의서에",
  "흥분을",
  "나온다고",
  "형제들이",
  "저녀석",
  "잡았다고",
  "호외",
  "마셔라",
  "물어요",
  "믿어지지",
  "울었지",
  "중요하죠",
  "안됐네",
  "형상을",
  "테냐",
  "말씀인지",
  "셋째",
  "소주",
  "분에",
  "치워라",
  "내장을",
  "보여주면",
  "바빠서요",
  "본부로",
  "나는요",
  "걸렸다",
  "스네익스",
  "일어나야",
  "학생들이",
  "내사랑",

  "지진",
  "파괴할",
  "봤대",
  "천국에서",
  "손자를",
  "대단하지",
  "자식의",
  "얼마전에",
  "조명을",
  "통로를",
  "살아도",
  "놀랍군요",
  "의자가",
  "취할",
  "한마디만",
  "합당한",


  "기다리며",
  "어떠신가요",

  "것이에요",
  "그겁니다",
  "찌를",



  "따님을",
  "홀트",
  "부탁해서",
  "별말씀을",
  "협박하는",
  "말씀하시길",
  "기초",
  "만나다니",

  "저지를",

  "동정심",
  "장난으로",
  "찾아내야",

  "지미가",
  "꿇어라",

  "경찰과",
  "괴물아",
  "장난감을",
  "반이나",

  "모으고",
  "대위가",
  "둘러싸인",
  "주소로",
  "공식적인",
  "있다는데",
  "범죄와",
  "가보게",

  "입력",
  "비키",
  "로시",
  "작동합니다",
  "옮기지",
  "출발한다",
  "들어있는",
  "못하겠네요",
  "건을",
  "쳐라",

  "따라오지",
  "이이가",
  "들어와도",
  "전화할게요",
  "새라",
  "뛰라고",
  "부모들이",
  "행적을",
  "의미합니다",
  "신들의",
  "괴물이라고",
  "업로드",
  "폭격",
  "얘기요",
  "지금까진",
  "기자가",
  "가려는",
  "과학적인",
  "날씨도",
  "겁쟁이야",
  "걸릴거야",
  "돌로",
  "애들하고",
  "커피가",
  "역할",
  "퍼플베리",
  "경련이",
  "조라",
  "모르는데요",

  "가치있는",

  "개시",
  "운전할",

  "꽁꽁",
  "잠재적으로",
  "형부",
  "째깍",
  "평소에",

  "건드려",
  "재수없는",


  "그림은",

  "알려준",
  "데이",
  "충실히",
  "타자",
  "생각하도록",
  "섹스할",
  "무엇을하고",
  "오고있어",

  "자전거가",
  "벗지",
  "신사들의",
  "여권",


  "스피드",
  "타이밍",
  "드리려고",

  "척추에",
  "들기",

  "방호벽이",
  "신고가",
  "안색이",
  "필요하잖아",
  "팔코니가",
  "기대가",
  "이래라",
  "무서웠어",
  "뭔갈",

  "느려",

  "경찰서에서",

  "신념이",
  "끊겼어요",
  "말거나",
  "중독이",
  "아프네",
  "감시해",
  "깎고",
  "조공인",
  "만나죠",
  "창고에서",
  "에릭이",
  "가든지",
  "결합",
  "났나",
  "주목을",
  "체육관",
  "딕스",
  "이해하게",
  "가디언",
  "안해봤어",
  "인원이",

  "저년을",
  "흥미롭군요",
  "알게되면",
  "갈라",
  "당당히",
  "결정하지",
  "광경을",
  "알라무트의",
  "올라가요",

  "실내",
  "그렇다니까요",
  "스웬더",
  "오말리에게",
  "스펜서",
  "어따",

  "부모님한테",
  "여깁니다",
  "페르시아",
  "샤워하고",
  "방아쇠를",
  "세부사항을",
  "정한",
  "값은",
  "헝거게임",
  "강하지",
  "시발",
  "유령들이",
  "명단을",
  "전해질",
  "여자들의",
  "일련의",
  "마음과",
  "헛",
  "최선이야",
  "카톨릭",
  "병사가",
  "입게",

  "가족이나",
  "비밀스러운",
  "오십",
  "투약을",
  "하키",
  "시체로",
  "어린시절",
  "언젠데",
  "폼",
  "희생은",
  "블랙브라이어",
  "기미갤",
  "희한한",
  "누구였어",
  "떠는",
  "모스가",

  "발전을",
  "메시지가",
  "게이가",
  "레즐리",

  "공격은",
  "트레이시",
  "쓰지도",
  "이동해야",
  "라이사",
  "하그로브",

  "풋내기",
  "아름답네요",
  "땅돼지",
  "저격수가",
  "우라질",
  "태릉",
  "에라",
  "공부해야",

  "계속해봐",
  "시켰지",
  "가리고",
  "지문이",
  "밖에요",

  "마비",
  "예쁜데",
  "탓에",
  "가버렸어",
  "쌓여",
  "피우면",
  "쉐퍼드예요",

  "아니까요",
  "돌볼게요",
  "생을",
  "힘들겠지만",
  "부탁하면",
  "금발머리",
  "실험실에서",
  "중환자실로",
  "소개를",
  "전방에",
  "도와줄게요",
  "책임자",
  "눈만",
  "한거니",
  "어기면",
  "못이",
  "물어보자",
  "여부를",
  "쪽지를",
  "법이다",
  "유령은",
  "있기는",
  "감이",
  "하룻밤만",
  "환자지",
  "지식의",
  "신부님이",
  "새꺄",
  "딴데",
  "도를",
  "왔단",
  "거부하고",
  "자려고",
  "크죠",
  "거처럼",
  "전통을",
  "뽑는",
  "뉴스에",
  "공정한",
  "받으려면",
  "생검을",
  "문제의",
  "배고프지",
  "잘게",
  "몫이야",
  "램",
  "집입니다",
  "믿네",

  "넣어요",
  "정부에서",

  "레지던트들은",
  "지평선",
  "콜라야",
  "슷하조아",
  "꺼내면",
  "남편분이",
  "싶었습니다",
  "신문에서",
  "이라크에서",
  "세기",
  "다른걸",
  "티리온은",
  "소스",
  "화장실이",
  "목숨이야",
  "스스로의",
  "죽었던",
  "미워하는",
  "모처럼",
  "분해",
  "좋게도",
  "속옷을",
  "치료해",
  "여인의",
  "로마노프",
  "이자식",
  "치사한",

  "네빌씨",
  "장소야",
  "에이구",
  "허벅지",
  "저분이",
  "종족의",
  "동식아",
  "안전이",
  "말씀만",
  "켄드릭스",
  "편견",
  "생명체",

  "연희",
  "반복합니다",
  "준비하지",
  "이야기로",

  "당신에게는",
  "열정적으로",
  "결정하게",
  "태양의",
  "그곳엔",
  "불과해요",
  "과거가",
  "살피고",
  "정의",
  "심장과",
  "선생님들이",
  "다녔어",
  "땜시",
  "바라지도",
  "내일을",
  "탁자",
  "욕구를",
  "주먹으로",
  "믿었어요",
  "궤도를",
  "나다니엘",
  "마누라한테",
  "몇년동안",
  "옥스퍼드",
  "계획의",
  "졌습니다",
  "날로",
  "곤란해",
  "일어나봐",
  "되찾아",

  "물좀",
  "폭력",
  "렉터",
  "좋아하는게",
  "이유에요",
  "병가를",

  "들렸다",
  "사니",
  "망치를",
  "일생",
  "깨는",
  "출발해요",
  "새우",
  "궤도에",
  "카레프가",
  "본론으로",

  "보름",
  "꺼져라",

  "연구는",
  "전직",
  "자격을",
  "물의",
  "년의",
  "아니래요",
  "말의",
  "방해할",
  "동네가",
  "시장이",
  "용서하고",
  "기억해둬",
  "되셨어요",
  "무모한",
  "수정및",
  "없었잖아요",
  "시원한",
  "주소서",
  "하늘엔",
  "결혼해요",
  "커플",
  "빼내야",
  "거였는데",
  "전과가",
  "지난밤에",
  "개자식들",
  "두려움은",
  "파운드",
  "알아듣겠어",
  "짐작",
  "들어갔고",
  "돌아간다",
  "곳이다",

  "하루하루",

  "걸렸지",
  "어딨는거야",
  "보여주시죠",
  "상처받은",

  "사부님이",
  "깨면",
  "나가야해",
  "원합니다",
  "신경쓰는",
  "편해",
  "맞서는",
  "갇혀서",
  "맹세컨데",
  "패들",
  "모여있는",
  "갔으니",
  "사지가",
  "열라고",

  "무리를",
  "어울리고",
  "있던데요",


  "사랑하니",
  "의대에서",
  "슬쩍",
  "선수는",
  "알잖니",
  "잡았지",

  "주사해",

  "열리고",
  "즐거웠어",
  "의사요",
  "마운트",
  "태어났고",
  "풀리지",
  "후에요",
  "처방을",

  "기록하고",
  "보관하고",
  "너밖에",
  "사회의",
  "자체는",
  "사람이라도",
  "흘렸어요",
  "테죠",
  "여럿",
  "같으니까",
  "자그마한",
  "사망할",
  "타디스의",
  "저들에게",


  "어쩌란",
  "돼도",
  "와예",
  "작별인사",
  "않으면서",
  "숨겨둔",

  "무릅쓰고",
  "이메일을",
  "넣어라",
  "정해진",
  "의원님",
  "예정대로",
  "베일리한테",
  "죄지은",

  "한번더",
  "한수야",
  "학생들을",
  "웨이드",
  "였지",
  "가구를",
  "늙었어",
  "미주리",
  "손톱",
  "존재한다는",
  "마치면",
  "암울한",
  "서두르자",
  "링컨",
  "체스터",
  "밤이면",

  "먹었고",
  "소파",

  "확인했어요",
  "여깄어요",
  "이따금",
  "중에서도",
  "아들로",
  "바는",
  "금발에",
  "경계에",
  "예상했던",
  "뭐냐고요",
  "많았지",

  "어제밤에",
  "처한",
  "모시게",
  "뽀뽀",
  "가스로",

  "거기를",
  "다리도",
  "가진게",
  "냅둬",
  "하래",
  "스스로도",
  "자일스",
  "명이에요",

  "아이디어가",
  "어머니한테",
  "싸웠어요",
  "잘됐구나",
  "모르겠나",
  "엄격히",
  "받으려고",
  "명단이",

  "죄수",
  "이후의",

  "방사선이",
  "슬픔이",
  "휴대전화",

  "카스털리",
  "소아과에",
  "혼란이",
  "오라버니",
  "물에서",
  "밝힐",
  "움직여서",

  "두려웠어요",
  "쪽지",
  "사생활에",
  "생각하겠지만",
  "타인의",
  "멈춘",
  "현상이",
  "연락하고",
  "인연을",
  "미워요",
  "소리쳐",
  "점심때",
  "실수도",
  "어깨가",
  "헬기가",
  "짧고",
  "클리닉은",

  "삽니다",
  "치르고",
  "개별",
  "진짜예요",
  "절름발이",
  "색깔",
  "그레이스의",
  "광범위한",
  "요리도",
  "참말",
  "수호자",
  "두개내압이",
  "없이요",
  "최대의",
  "불안정해",
  "민간인",
  "굿",
  "책임감이",
  "방영일",
  "부자",

  "장교",

  "갔니",
  "왔어야",
  "대낮에",
  "장난감이",
  "스타일이",
  "사물함에",
  "겠네",
  "알트만",
  "마빈",
  "호빵",
  "곰이",
  "개요",
  "놈들도",
  "줄지도",
  "줄려고",
  "전문의",
  "처리해야",
  "무슨일이에요",
  "불러줘요",
  "안전하고",
  "부분에서",
  "감자가",
  "확신하지",
  "용이",
  "말들이",
  "쓰일",
  "보구",
  "될것이다",
  "가면은",
  "어디있어요",
  "맞냐",
  "달러는",
  "맹장",
  "없는것",
  "않았더라면",
  "병원비",
  "블랙홀로",
  "듣는데",

  "헛소리야",
  "목록을",
  "이놈을",
  "능금",
  "독일어로",
  "쉬면",
  "스캇이",
  "경관이",
  "무려",

  "리튬",
  "쓰자",


  "후반",
  "동지",
  "수술하게",
  "수치는",
  "그거는",
  "챕",
  "계좌를",
  "연습은",
  "닫는",
  "어기는",

  "조슈아",
  "고객을",
  "죽였습니다",
  "줘야겠어",
  "좋아했다",
  "괜찮은거",
  "매달려",
  "사무실은",
  "구해주세요",
  "잠들지",
  "진단",
  "입었지",
  "엑스테",
  "스카이넷",

  "생일은",
  "찾았나요",
  "스타가",
  "부대는",
  "되었는데",
  "호출에",
  "없으며",
  "생각인",
  "할일은",
  "크로노다인",
  "깼어",
  "앞면",
  "참이었어요",
  "총독",
  "헤어지고",
  "조건부배포",

  "뉴욕의",
  "존슨",
  "신경과",
  "행운도",
  "공부가",
  "제작자와",
  "간호사에게",
  "아가씨야",
  "구하려는",
  "티렐",
  "표기해",
  "결혼생활을",
  "헬멧",
  "소포를",
  "기어이",
  "죽일거라고",
  "화상은",
  "안했는데",
  "판막을",
  "반즈",
  "브라이스가",
  "암것도",
  "의지할",
  "엑소시즘을",
  "거짓말도",
  "쫓겨날",
  "걔네가",

  "연구가",

  "상대는",
  "피차",
  "태도",

  "로저",
  "벗어나고",
  "대는",
  "키치",
  "좋아하면",
  "아직까진",

  "차리세요",
  "보호를",
  "콩나물",
  "본즈",
  "질릴",
  "환경에서",
  "섹시하게",
  "꾸는",


  "믿음은",
  "담을",
  "어른들은",
  "옆방에",
  "라지가",
  "사이죠",
  "울타리를",
  "유리를",
  "생각입니다",

  "대기자",
  "외에는",
  "확인했습니다",
  "깨워서",
  "이창수",
  "주머니에서",
  "무섭다는",
  "안되겠어",
  "조기",
  "빌고",
  "사준",
  "심각해요",
  "스",

  "절호의",

  "로난",
  "추가로",
  "다니던",
  "떼고",
  "이라크에",
  "방도",
  "토네이도가",
  "선생님이요",
  "얼간아",
  "노력했지만",
  "돌아올거야",
  "레이몬드",
  "말씀드리지만",
  "아름답군",
  "돼야지",
  "않을테니",
  "사람들입니다",
  "훌륭하고",
  "연주하고",
  "쟤를",
  "세탁기",
  "잤니",

  "전략",
  "제이콥의",
  "규제",
  "서세이는",
  "보이긴",
  "통로가",
  "때문이었어",
  "마시라고",
  "함량이",
  "놔봐",
  "혼자야",
  "지나갑니다",
  "충분하다고",
  "충실한",
  "차갑고",
  "첫눈에",
  "전속력으로",
  "한마리",
  "당신이군요",
  "단추를",
  "장부",
  "요새로",
  "듣겠어",
  "허세",
  "삼돌이",

  "과정은",
  "좋지요",
  "것에서",

  "영향",
  "없겠어요",
  "집중해야",


  "씨푸",
  "강아지가",
  "보내게",
  "본거야",
  "대단하다",

  "역할은",
  "틀림없는",
  "피부과",
  "머무를",
  "경우에도",
  "시상에",
  "수퍼",
  "법과",
  "허어",
  "휴대전화를",
  "애러비",
  "먹여야",

  "문제인지",
  "정맥주사",
  "변화에",
  "지난달에",
  "백모님",
  "책임지고",
  "저쪽이에요",
  "내게로",
  "파악하고",
  "하기에",
  "아프단",
  "스테이크",
  "해고",
  "조난실",
  "반지도",
  "행동하고",
  "깨달았죠",
  "선택해요",
  "말해줘서",
  "반응",
  "나서서",
  "말이네요",
  "고등어",
  "먹던",
  "찾아줘",
  "쥐는",
  "빙빙",
  "새낀",
  "켜봐",
  "죽을래",
  "그기",
  "하셨거든요",
  "성욱아",
  "제거를",
  "화내고",
  "이모가",
  "가량",
  "신호는",

  "지랄이야",
  "바이퍼",
  "하셨네",
  "다정한",
  "전쟁과",
  "그곳이",
  "예상보다",
  "발견하게",
  "깬",
  "맹세할게",
  "또라이",
  "사람은요",
  "말하겠어",
  "만만치",
  "상자가",
  "결론은",
  "사람이라는",
  "웃어봐",
  "자극",
  "산의",
  "기대를",
  "그만두는",
  "코너로",
  "겪게",
  "요청하는",
  "멈추세요",
  "레이스",
  "맛도",
  "대할",
  "이루어",
  "하이고",
  "대학의",
  "저여자가",
  "방앗간",
  "성공을",
  "마샤",
  "찼어요",
  "쇼의",
  "대동맥을",
  "법정에",
  "과장님과",
  "감추고",
  "단어는",
  "그녀석",
  "작지만",
  "상상하는",
  "울보",
  "천재적인",
  "줄리가",
  "근본적으로",
  "추억",
  "내리기",
  "생겨요",
  "구룡이",
  "되지도",
  "테레사",
  "습관이",
  "버리기",

  "공장으로",
  "이바",
  "나리는",
  "타입",
  "칼리오피",


  "어머님을",
  "데릭도",
  "마누라는",
  "일병",
  "콜린",

  "부드럽고",
  "말썽",
  "간지러워",
  "중이예요",
  "창문에서",
  "걸릴까요",
  "보자면",
  "돌려보내",
  "식량",
  "했잖소",
  "프롭워시",
  "그놈들을",
  "흩어져",
  "다리에서",
  "가봐야겠네요",
  "스텔링",
  "순간입니다",
  "어울린다고",
  "하르만",
  "구조가",
  "염증이",
  "동전을",
  "혈관에",
  "처음인데",
  "입자가",
  "단단한",
  "심장혈관계",
  "한명의",
  "스카치",
  "봐주세요",
  "추수감사절",
  "아이한테",
  "뒤통수를",
  "높고",
  "돌아올께",
  "고기나",
  "진입",

  "어디있나",
  "지불",
  "데렉",
  "터지면",
  "은수",
  "악독한",
  "신비한",
  "있을수",
  "동작을",
  "애인을",
  "아기들은",
  "소년이여",
  "밸리",
  "자원",
  "노린",
  "타이밍이",
  "하룻밤을",
  "있는거니",

  "어쩔거야",
  "거실에",
  "방앗간을",
  "역에서",
  "나무들을",
  "예전으로",
  "쉐퍼드에게",
  "밀린",
  "국가에",
  "수수께끼",
  "부족합니다",

  "좋든",
  "없겠다",
  "뒤따라",

  "바리스",
  "누군가요",
  "좋아하는거",
  "만난적이",
  "오네",
  "숨지",
  "농구",
  "평화로운",
  "열정",
  "같은걸",
  "심장기",
  "된장",
  "환자분을",
  "안쪽",
  "날의",
  "보라니까",
  "개라고",
  "어렵다는",
  "순수",
  "과거와",
  "매기가",
  "물지",
  "맞잖아요",
  "얘기할게",
  "게임도",
  "출신이죠",
  "보거나",
  "이자는",

  "구했죠",
  "하사",
  "살해당한",
  "있기만",
  "이긴다",
  "반면",
  "정할",
  "훌륭합니다",
  "나가죠",
  "흉터가",
  "마디로",
  "바쁘게",
  "기념일",
  "설명하는",
  "구경도",
  "영주님",
  "초대한",


  "팍",
  "오일",
  "어떤데",
  "이십",

  "진료를",
  "말했어야",
  "뻗고",
  "먹었잖아",
  "공과",
  "가야되",
  "들어갈게요",
  "그러니까요",
  "코티스",
  "선생님들",

  "온거에요",
  "출신이야",
  "보따리",
  "팔러",
  "쌀이",
  "소리다",
  "공평한",
  "조각들을",
  "시끄러운",
  "들어왔습니다",
  "행복에",
  "카스텔라",

  "동굴에서",
  "기다렸는데",
  "요게",
  "오긴",
  "사이토씨",
  "돌았냐",


  "상대하는",
  "와인은",
  "보여줄께",
  "조종하고",
  "아래층",
  "살려면",
  "사령관님",
  "어머님께서",
  "혼란스럽게",
  "돌려줄",
  "알려지지",
  "가르침을",
  "살겠다고",
  "볼튼",
  "장소와",
  "만들어줄",
  "쏴요",
  "카인의",
  "너같이",
  "진심",
  "재밌잖아",
  "절제술",
  "아니지만요",
  "많지만",
  "대면",
  "직장이",
  "폴이",
  "눈앞에서",
  "터커가",
  "얘기로",
  "했을때",
  "방해를",
  "선택했어요",
  "할거요",
  "협박이",
  "힘내자호빵",
  "혼이",


  "찾았네",
  "떠났고",
  "하루만에",
  "일하면",

  "죽였잖아",
  "맥박도",
  "진행을",
  "생각보단",
  "주민",
  "종족",
  "아무데서나",
  "본능이",
  "건달",
  "오빠와",
  "유감이군요",
  "곳이라고",
  "안됐네요",
  "해야될",
  "마리안",
  "김순경",
  "당신한테는",
  "우아한",
  "우승할",
  "비워",
  "욕심이",
  "의사들도",
  "당길",
  "동크",
  "인력이",
  "베라가",
  "잤던",

  "스페셜",
  "사고의",
  "죄야",
  "애써",
  "좋은게",
  "기다",
  "존경",
  "누난",
  "꼬마가",
  "불길한",
  "나가란",
  "놔둔",
  "홈즈씨가",
  "으음",
  "가져옵니다",
  "무당의",
  "갤러리",
  "똑똑하게",
  "뉴저지",
  "뭐든지요",
  "힘들어도",

  "되기도",
  "만나려고",
  "그러잖아",
  "난소",
  "키스는",
  "얼마요",
  "저녁이나",


  "날씨는",
  "호출합니다",


  "주셔야",
  "이름이지",
  "건물에서",
  "땅과",
  "학살",
  "보는거야",
  "확인하는",
  "들어봤어",
  "떠났죠",
  "로라스",
  "의자를",
  "지켜줄게",

  "렉시를",
  "정보에",
  "두뇌가",
  "물품",
  "가주세요",
  "드시겠어요",
  "우리집을",
  "진심을",
  "남자만",
  "이라니",
  "되는군요",
  "주먹",
  "머리와",
  "벵트",
  "수연이",
  "기다렸죠",
  "죽어가요",
  "없겠어",
  "먹죠",
  "보초를",
  "뒷문으로",
  "저것들",
  "알아줬으면",
  "지르는",
  "있었을까",
  "명심해라",
  "저기다",
  "댓글로",
  "것일지도",
  "도박을",
  "대원",
  "닥치는",
  "압력",
  "느끼기",
  "찰리는",
  "발정난",
  "의견",
  "게이츠",
  "신나는",
  "존재해",
  "지옥이",
  "미안하군",
  "곰을",
  "사기를",
  "괜찮겠어요",
  "남았죠",
  "돌아갑시다",
  "낮게",
  "안톤",
  "법정",
  "칩을",
  "차려야",
  "여기서부터",
  "졸졸",
  "불쌍해서",
  "안그럼",
  "걸어봐",

  "맞는거",
  "동",
  "많았습니다",
  "사랑했다",
  "시절의",
  "가져가라",
  "책상에서",
  "영원할",
  "되었다고",

  "한편",
  "죽어버릴",
  "폭동이",
  "루시는",
  "사태를",
  "피아노를",
  "장애가",
  "임신은",
  "확실치",
  "행동하는",

  "시작부터",
  "행크는",
  "집어넣어",
  "애미",
  "생각조차",
  "오스틴",
  "가고있어",
  "어떡하나",
  "생판",
  "해드릴",
  "원수",
  "연극이",
  "센터에",
  "천섬",
  "집인데",
  "가로질러",
  "경기는",
  "잔말",

  "혈압도",
  "기억하나",
  "너머로",
  "지내나",
  "표정은",
  "상사님",
  "위장",
  "부친",
  "위태롭게",
  "그만두게",
  "브라스",
  "숨겨져",
  "김정은이",
  "짓이죠",
  "새미",
  "그리스도의",
  "물어보려고",
  "초시",
  "구했지",
  "북한에",
  "레스토랑에서",
  "일년에",
  "부서에서",
  "면역",
  "애란동무",
  "차근차근",
  "동생과",
  "낮엔",
  "안녕들",
  "아이에요",
  "봤으니까",
  "병동",
  "써야지",
  "강간범",
  "젊어",
  "대대장님",
  "불타고",
  "여자애랑",
  "모킹제이",
  "평범하고",
  "안한",
  "베일리와",
  "의도가",
  "말씀드리는",
  "스카이의",
  "성적으로",
  "댓가로",
  "상대방을",
  "들린다",
  "않을텐데",
  "흥미가",

  "통화를",
  "케이크를",
  "말해줘도",

  "무서워하는",
  "있다간",
  "이상입니다",
  "놈입니다",
  "설득해",
  "브레나",
  "이본",
  "속이는",
  "중이었습니다",
  "목소리에",
  "안되잖아요",
  "짱이야",
  "줄여서",
  "호출해요",
  "세로토닌",
  "음악과",
  "무전",
  "질리",
  "된건",
  "몽고메리가",
  "물러가라",
  "띄지",
  "없는걸",
  "자랑스럽다",
  "중국어",
  "개소리야",
  "옛적에",
  "간과",

  "우리는이",
  "엄마하고",
  "상황이다",
  "전장에",
  "되었으면",
  "깨닫고",
  "규정을",

  "묻힌",
  "옥상에서",
  "지긋지긋해",
  "옛날로",
  "범위를",
  "손잡고",
  "말해보렴",
  "저것도",
  "있었을까요",
  "겨누고",
  "삼촌을",
  "찾아왔어요",
  "공동",
  "믿음",
  "왔을까",
  "적합하지",
  "필요하다고요",
  "되겠는데",
  "기도하고",
  "그들에겐",
  "이웃이",
  "트라우마",
  "아기에게",
  "파고",
  "스파이크",
  "벗기고",
  "시작도",
  "지나가요",
  "뭐하는지",
  "불꽃이",
  "루스자매",
  "프로토콜에",
  "해결을",
  "그리운",
  "신인",
  "케일럽은",
  "대기실에",
  "무슨말을",
  "최고급",
  "계셨군요",
  "사줄게",
  "대신할",
  "실망할",
  "가운",
  "환자입니다",
  "발견했고",
  "끝내주는데",
  "할아버지의",
  "있다는게",
  "엉망으로",
  "모르겠지",
  "어색한",
  "시리얼",
  "포기해",
  "일곱신의",
  "바퀴가",
  "교활한",
  "피타는",
  "개두술을",
  "법률",
  "로치",
  "칭칭",
  "이보오",
  "허큘리스의",
  "끝내주게",
  "과장은",
  "운동화",
  "레지던트로",
  "서명하고",
  "수백만의",
  "말투가",
  "심장마비",
  "부족할",
  "완벽하지",
  "급해요",
  "넘버",
  "시작되는",
  "진짜루",
  "디퍼",
  "환자에게서",
  "알아냈지",

  "곳입니다",
  "굴러",
  "예측할",
  "조선의",
  "중인데요",
  "자빠졌네",
  "멍",
  "물어봤어요",
  "이상하군요",
  "탓이에요",
  "이새끼",
  "나오라",
  "죽겠죠",
  "별은",
  "부자로",
  "걸죠",
  "저승에서",
  "팔렸습니다",
  "기적의",
  "출혈도",

  "미쳤다는",
  "추적해",
  "죄송했어요",
  "귀찮은",
  "메일",
  "저승",
  "어쩌구",
  "참견할",
  "아프세요",
  "비키세요",

  "들어가실",
  "게스트",
  "손바닥에",
  "갖길",
  "기관에",
  "이외의",
  "보이는지",
  "야단이야",
  "새끼들이",
  "방법에",
  "내말을",
  "왕이다",
  "아이디어",
  "계약이",
  "몇개의",
  "유머",
  "국민을",
  "왕국은",
  "헬로",
  "사이도",

  "선에서",
  "챙겼어",
  "법한",
  "형에게",
  "소프트웨어를",
  "둘이나",
  "수련",
  "경찰서에",
  "싱크수정",
  "훔치려고",

  "완벽",
  "성함은",
  "들어줘",
  "수잔이",
  "신뢰",
  "도구가",
  "후회하지",
  "본을",
  "흥미롭네요",
  "회복할",
  "어머니에게",
  "이름에",
  "동맥류",
  "불의",
  "영화에서",

  "일라이",
  "태미",
  "위협",
  "동안만",

  "심리",
  "악성",
  "발렌타인",
  "스타크가",
  "부탁하지",
  "오웬이랑",
  "냄새야",
  "어려서",
  "잡아봐",
  "어떡하니",
  "나무의",
  "주오",
  "아프네요",
  "리암",
  "보스를",
  "쏟아",
  "검게",
  "대항하는",
  "좆을",
  "독성",
  "도로에서",
  "그만두지",
  "사진이야",
  "설사를",
  "보내진",
  "사실이라고",
  "한답니다",
  "낭비야",
  "이상하다고",
  "배도",
  "너더러",
  "프렌치",
  "할망구",
  "보오",
  "답답한",
  "추잡한",
  "가야겠네요",
  "보이질",
  "시끄럽고",
  "심해서",
  "죽이겠다",
  "아래쪽에",

  "바라면서",
  "관심없어",
  "전설을",

  "클럽의",
  "인사나",
  "검",
  "가지기",
  "확신은",

  "쳐봐",
  "고인의",
  "듣거라",
  "여자나",
  "아니아니",
  "달아날",
  "플로렌시아",
  "보잘",
  "소송",
  "깨어났어",
  "불가능할",
  "법칙",
  "퍼거스",

  "되기로",
  "개소리",
  "소령님",
  "허면",
  "지내니",
  "전문적인",
  "막다른",
  "씨께서",
  "젖소",
  "알만큼",
  "만든거야",
  "생사를",
  "게냐",
  "친구라도",
  "어떠신가",
  "넘었어요",
  "연주하는",
  "지불하지",
  "오두막을",
  "놀지",
  "서세이가",
  "기다린다고",
  "저하고",
  "모르거든요",
  "노먼은",
  "범죄가",
  "다녀올게요",
  "붙잡을",
  "쫓아낼",
  "얼간이가",
  "으휴",
  "미치겠군",
  "들리면",
  "기회라고",
  "부검을",
  "풋볼을",
  "아이디어는",
  "공항으로",

  "댁에서",
  "요구를",
  "쉬지도",
  "어떻니",
  "말하지도",
  "로비에",
  "일원이",
  "팀이야",
  "순이",
  "하믄",
  "팀이라고",

  "온전히",
  "기지로",
  "척추가",
  "파리의",
  "퀸랜이",
  "보험이",
  "반지하",
  "울음",
  "요기",
  "게임할",
  "언어를",
  "없구",
  "이상하고",
  "랜덤하게",
  "하필이면",
  "저나",
  "안했지",
  "스티븐스예요",
  "헬레나",
  "길러",
  "영호씨",
  "수술하면",
  "얼간이처럼",
  "하셔야죠",
  "쫌만",
  "알려줍니다",
  "덩어리를",
  "어텐딩이",
  "꼴리뇽은",
  "묻자",
  "정리가",
  "안에서는",
  "즐기세요",

  "상처만",

  "제때",




  "케이시",

  "다이너마이트를",
  "사전에",

  "사온",
  "어디니",
  "밀지",
  "온라인에",
  "특수한",
  "끝난다",
  "파편이",
  "바가스의",

  "뻔히",
  "내렸어요",
  "밝혀낼",
  "지어낸",
  "해답이",
  "코라",
  "뻔해",
  "은퇴할",
  "처",
  "열흘",
  "생각하며",
  "알았다니까",
  "전부인",
  "택한",
  "기억하게",
  "두렵다",
  "목사",
  "많군",
  "체면",

  "협박을",
  "본질적으로",
  "판사",
  "가져왔는데",
  "끼",
  "실패하고",
  "물었지",
  "영혼과",
  "캐서린",
  "코치",
  "묻겠다",
  "조사는",
  "선반에",
  "행동해야",
  "단계야",
  "자와",
  "낮",

  "하고싶어",
  "힘들고",
  "들쑤시고",
  "말이잖아",
  "아냐고",
  "사진에서",
  "라이언",
  "표현이",
  "돌아오기",
  "일어난다",
  "뽑힌",
  "영장을",
  "시합에서",
  "물어봐야",
  "총각파티",
  "죄수를",
  "경계가",
  "왕창",
  "남았다고",
  "리치몬드",
  "공격하지",
  "머물고",
  "느낌이야",
  "죽기도",
  "할아버지도",
  "놈하고",
  "여생을",


  "약물에",
  "쓰시죠",
  "있었어야",
  "보냐",
  "축구를",
  "바이올렛",
  "라이어",
  "망했다",

  "꼬리가",
  "약점이",
  "늦었잖아",
  "담당이",
  "쾌지나",

  "해줬지",
  "올라가지",
  "아리우스",
  "이기려고",
  "들렸어요",
  "옥분이",
  "아기한테",
  "몰랐으면",
  "쓰레기통에서",
  "설탕을",
  "은하입니다",
  "트라우마를",
  "풀릴",
  "의사에요",
  "보기에는",
  "개념은",
  "정신감응",
  "일뿐",
  "약속했는데",
  "식으로는",
  "따라가지",

  "레버",
  "필요까진",
  "찍을게요",
  "깊숙이",
  "연을",
  "입었네",
  "고맙고",
  "돌아가셨죠",
  "스탠리",

  "머피",
  "바꾸자",
  "때렸어요",
  "프랭키가",
  "중독",
  "얭이",
  "참고로",
  "만들어지는",
  "이쪽을",
  "했겠죠",
  "사이즈",
  "헌트는",
  "해낸",

  "프로그램은",
  "보통의",
  "됐다니까",
  "중심을",
  "그래봤자",
  "이용하고",
  "조국을",
  "나아갈",
  "동료들을",
  "도망가야",
  "할만한",
  "감사의",
  "될게",
  "점수",
  "나호코",
  "주었습니다",
  "미란다의",
  "또야",
  "넘어가면",
  "받아야지",
  "사람들이요",
  "슬론한테",
  "왕자님이",
  "미시",
  "도와줄래요",
  "어쩌라고요",
  "해결하려고",
  "씻을",
  "라이카게",
  "쳐도",
  "만나니",
  "들으면서",
  "않으셔도",
  "참으면",
  "녀석들의",
  "정말이세요",
  "메러디스도",
  "빠져나가",
  "오늘이야",

  "훌륭하게",
  "서명하면",
  "좋아하잖아요",
  "톰이",
  "수프를",
  "점수가",
  "이웃을",
  "농담이죠",
  "프레스튼",
  "없지만요",
  "자신감을",
  "외국에서",
  "첫날에",
  "않는구만",
  "괜찮을까",
  "데려와서",
  "영희를",
  "방패",
  "만드는지",

  "받으셨어요",
  "재산이",
  "김은주",
  "컸고",
  "아침식사",
  "국민",
  "오마이갓",
  "대단하네",
  "병사들은",
  "별명은",
  "말하긴",
  "있다던데",
  "필요하니까",
  "다시한번",

  "데려갔어",
  "멋있게",
  "그후",
  "웃기시네",
  "맹세했다",
  "찍자",
  "조용해요",
  "끝나서",
  "노력중이야",
  "뜻인가",
  "일손이",
  "겁먹을",
  "넘을",
  "내보낼",
  "때문이겠죠",
  "솔로몬",
  "맞았지",
  "넘고",
  "사는거야",

  "기절한",
  "모르네",
  "헤더",
  "설진영",
  "총력",
  "절차가",
  "될지는",
  "욕구가",
  "과장님도",
  "경험에",
  "시행할",
  "돌아와야",
  "한거예요",
  "안가고",
  "않던데",
  "린지",
  "사과할게",
  "정상이고",
  "넘으면",
  "답변을",
  "할건데",
  "살리려면",
  "생각했었어",
  "꺼내요",
  "좋아졌어",
  "원했지",
  "건배를",
  "답니다",
  "부탁한다고",
  "이길수",
  "살려야",
  "싶게",
  "계속될",
  "탁일항",
  "위기가",
  "신념을",

  "본인은",
  "알리서",
  "신도",
  "반복",
  "떠돌이",
  "가더라고",
  "생각이다",
  "궁극의",
  "고건수",
  "노인네가",
  "되냐고",
  "과정",
  "들었나요",
  "따라가서",
  "대한민국",
  "프로젝트를",
  "벌은",
  "여자친구와",
  "있을께",
  "샘은",

  "되었는지",
  "빠졌다고",
  "참으세요",
  "으이그",
  "내려줘",
  "의지",

  "했다면서",

  "휘플",

  "농담은",
  "맛은",
  "찾아보세요",
  "서로에",
  "철저하게",
  "세면",
  "하군요",
  "저쪽이다",
  "아니었잖아",
  "마음속",
  "꼼짝없이",
  "가능한가",
  "빛과",
  "트렁크",
  "용범이",
  "서둘러라",
  "지나가지",
  "어딨소",
  "일수도",
  "포레스트",
  "미치광이",
  "혼날",
  "후라이",
  "문학",
  "당신만을",
  "물어봐라",
  "전화입니다",
  "취소할",
  "팔자가",
  "쾅",
  "그때의",
  "오신다고",
  "기타를",
  "돌아가시기",
  "흥분되지",
  "가시",
  "제자리로",
  "치우지",
  "먹을수",
  "대놓고",
  "제거한",
  "가기만",
  "운전은",
  "철이는",
  "줄리는",
  "일자",
  "재빨리",
  "내부에",
  "껌",
  "휠체어를",

  "틈",
  "현실에",
  "친구였어요",
  "죽을때까지",

  "아렌델의",
  "받겠습니다",
  "이득을",
  "불행하게",
  "애리조나가",
  "못하겠군",
  "퍼즐",
  "중심에서",
  "말해줬어요",
  "그러더군",
  "떠나진",
  "날씨에",
  "울었어",
  "요소가",
  "지시에",
  "집안이",
  "일하잖아",
  "변명을",
  "자격으로",
  "대리",

  "달지",
  "자잖아",
  "아침은",
  "시간이면",
  "전기로",
  "나오죠",
  "깁스를",
  "학생이야",
  "아래가",
  "수술해",
  "칠면조를",
  "신경쓸",
  "조앤은",
  "놈들이지",
  "말에요",
  "따라다니는",
  "드리자면",
  "헛간",
  "오스왈드는",
  "반이",
  "말이지만",

  "감각을",
  "농장",
  "떨어진다",
  "꽃은",
  "치프께서",
  "감정적인",
  "최면을",

  "극심한",
  "소식도",
  "정의가",
  "사는걸",
  "헬레나가",

  "준거야",
  "미친거",
  "이해할수",
  "마운틴",
  "자기만",
  "외삼촌이",
  "날인데",
  "킬리",
  "지겨워요",
  "완성",
  "가져왔지",
  "손과",
  "철사로",
  "이잖아요",

  "불덩이를",
  "중이니",



  "하늘과",
  "옮겼어",
  "쓴다고",
  "계산이",
  "영감님",
  "굿나잇",
  "바퀴벌레",

  "동식",
  "쓴다는",
  "주워요",
  "현상을",
  "좋아하시는",
  "찾았지만",
  "도우미",

  "나쁘다는",
  "시골에",

  "구역으로",
  "던질",
  "진통이",
  "나을지도",

  "기업",
  "이런저런",
  "첫번째는",
  "호출하지",
  "하겠는데",
  "사무실을",
  "모양입니다",

  "있게끔",
  "추적하고",
  "신원미상의",
  "시간으로",
  "나이로",
  "안정됐어요",
  "천만의",
  "전선에서",
  "카페",
  "아이텐",
  "빨려",
  "못가요",
  "영광으로",
  "젖어",
  "서너",

  "유동",
  "시가",

  "저지르고",
  "상수가",

  "섹시",

  "계신가",



  "중력의",
  "안정",
  "위협하는",

  "지옥을",
  "나갔다가",
  "설거지",
  "먹이를",



  "가격에",
  "캠프",
  "송정연",
  "조절이",
  "발가벗고",
  "침입해서",
  "심부름",
  "있었냐고",
  "작동이",
  "그럴께요",
  "버린다",
  "의미에서",
  "님이",
  "비숍을",
  "반대편에",
  "넓게",
  "폈어",
  "출발하면",
  "장난하냐",
  "내놓고",
  "들러리를",
  "체포합니다",
  "행성은",
  "챔프",
  "에밀리",
  "신발도",
  "마커스",
  "정신병",
  "재생",
  "라스트",
  "살려주면",
  "않았거든",
  "회사도",
  "흑사병이",
  "애인은",

  "표정이야",
  "살지도",
  "알아들었나",
  "좋으신",
  "극단적인",
  "잃어버렸어요",
  "치죠",

  "어제부터",
  "내일이라도",
  "알고싶지",
  "재활",
  "유지하는",
  "영구",
  "바쁜데",
  "관통상",
  "가볼께요",
  "옥상에",
  "쳐야",
  "낚시를",

  "나오네",
  "짓이냐",
  "악당들",
  "곳까지",
  "망원경",
  "이모한테",
  "색을",

  "닮았어요",
  "렉시와",
  "술만",
  "지나지",
  "됐단다",
  "발목이",
  "없을텐데",
  "말씀이십니까",
  "직전",
  "감사하고",
  "가망이",
  "떴다",
  "하우스",
  "크루",
  "거짓말이었어",
  "문득",
  "가버리면",
  "유기농",
  "경과를",

  "남편분은",
  "데지",
  "생각합니까",
  "무리한",
  "마련이지",
  "밝혀라",
  "슬픔은",
  "루즈",
  "곤란한",
  "특권을",
  "풀어야",
  "사람이거든",
  "이들에게",
  "옛날의",
  "예전에도",
  "비키라고",
  "이해하지만",
  "쏘라고",
  "카지노",
  "기증자를",
  "요요",
  "골수",
  "법칙을",

  "단계는",
  "사용합니다",


  "계시니까",
  "보기도",
  "인터존",
  "시키세요",
  "응급실을",
  "왔지만",
  "도박",
  "전이죠",
  "지금이다",
  "싫어해서",
  "윗층으로",
  "본게",
  "가너",


  "예언을",
  "여자애들은",
  "훔치지",
  "일이었다",
  "누이가",
  "아들들이",
  "추적할",
  "클라인",
  "어디도",
  "이랬어",

  "넘어졌어요",
  "엿먹어",


  "들어갑시다",
  "말았어야지",

  "김종배",
  "더그는",
  "그랬군요",
  "닥칠",
  "닫지",
  "사실이다",
  "있었습니까",
  "발리",
  "모를거야",
  "어땠죠",
  "치인",

  "몰",
  "어디입니까",
  "지혜",
  "뒤는",
  "물품을",
  "울기",
  "갚아야",
  "초등학교",
  "부친을",
  "돈이지",
  "일정을",
  "주제",
  "바람둥이",
  "깔고",
  "풀어서",
  "수입이",
  "으잉",
  "지경이",
  "손상된",
  "온몸에",
  "보여주기",
  "만지지마",
  "시야를",
  "삼보",
  "남잔",
  "들리나요",
  "죽기는",
  "놓여",
  "섬을",
  "토레도",
  "날이잖아",
  "서울에서",
  "이녀석",
  "정답이",
  "초대해",
  "초자연적",
  "넘어져서",
  "윤애",
  "버스로",
  "줄리아나",
  "다행이군요",
  "걸었어",
  "에구머니",
  "받았으면",
  "재판은",
  "떠요",
  "씨예요",
  "심장마비가",
  "아무한테나",
  "마법이",
  "좋아라",
  "약속해줘",
  "사상",
  "분야가",
  "물러서세요",
  "대동맥이",
  "먹지도",
  "사설",
  "숄토",
  "살렸어",
  "잤을",
  "이혼을",
  "좋겠죠",
  "했겠어",
  "팔아요",
  "당신만이",
  "되지요",
  "마땅히",
  "자막을",
  "짚고",
  "어여쁜",
  "등신아",
  "골절이",
  "연희야",
  "순서대로",
  "도타를",
  "아부지가",
  "딕슨이",
  "도타",
  "오늘만",
  "정해",
  "시민들이여",
  "체크하고",
  "서고",
  "쪼깨",
  "여러분들은",
  "회복이",
  "시작일",
  "뽑고",
  "페이스북",
  "총독부에서",
  "도우라고",
  "한이랑",
  "요리사",
  "캣",
  "사용한",

  "다행스럽게도",
  "없",
  "사려",
  "긴히",
  "크리스티나도",
  "썩을",
  "수도는",
  "이혼은",
  "흉부외과에",
  "질문할",
  "드래곤의",
  "이제라도",
  "할라고",
  "태어나고",
  "곳에는",
  "보여드릴",
  "레티",
  "주려는",
  "타이렐",
  "아프다",
  "농장에서",
  "하냐고",
  "결정적인",
  "아름답지만",
  "가져요",
  "잠옷",
  "잘래",
  "외과의들은",
  "위인이",
  "심해지고",
  "토리가",
  "굽고",
  "그레이에게",
  "예쁘구나",
  "도울게요",
  "부하",
  "깨어나서",
  "닭이",

  "생검",
  "자랑스럽구나",
  "생겼죠",
  "따라갈게",
  "말해주길",
  "말했니",
  "에너지의",
  "소프트웨어",
  "사우스",
  "데를",
  "앞에서는",
  "어머님의",
  "필요하겠어",
  "꼭대기에",
  "출생",
  "거로",
  "뿐이니",
  "귀엽지",
  "때죠",
  "하군",
  "팬티가",
  "오늘까지",
  "음반을",
  "파도",
  "지랄을",
  "던져서",

  "없는거죠",
  "다르잖아",
  "안협집",
  "계약서에",
  "앞둔",
  "집어치워",
  "펠랑은",

  "의지에",
  "안톤은",
  "출근",
  "안정된",
  "월리를",
  "질투가",
  "다녀와",
  "밧줄",
  "할꺼에요",
  "분야의",
  "낫네",
  "없긴",

  "그따위로",
  "집주인이",
  "이지도",
  "위험하잖아",
  "돌아가도",
  "새삼스럽게",
  "방탄",
  "있었는지도",
  "부담을",
  "여어",
  "손만",
  "약자를",
  "보아도",
  "않는지",
  "이틀간",
  "낳게",
  "잘나가는",
  "뭐하는거에요",
  "설교를",
  "에미야",
  "사니까",

  "버려두고",
  "여유",
  "채울",
  "뽕을",
  "다나",
  "였습니다",
  "믿으니까",
  "찾느라",
  "살펴보고",
  "혼자는",
  "베야한다",
  "블릿처",
  "해마다",
  "먹이",
  "원했다",
  "가는데요",
  "피자를",
  "위급한",
  "묶을",
  "에피",
  "멜라크",
  "성공하지",
  "오셔야",
  "대비해",
  "시절을",
  "드렸잖아요",
  "원하게",
  "리더",
  "서있지",
  "깔보는",
  "데모",

  "방의",
  "주방",
  "역으로",
  "프로그램의",
  "트라스크",
  "싶은거에요",
  "아빠라고",
  "다행입니다",
  "알만",
  "구해줬어요",

  "봉쇄해",
  "감사해",
  "몫은",
  "우울증",
  "개발한",
  "닫습니다",
  "고든은",
  "쓰레기가",
  "갖은",
  "함께한",
  "홈즈는",
  "보고있어",
  "해답을",
  "하든지",
  "중요할",
  "오만한",
  "벨",
  "멀",
  "연구소",

  "상사에게",
  "떠도는",
  "인이",
  "도둑놈",
  "파일이",
  "보통이",
  "허가가",
  "바랬는데",
  "이잖아",
  "무엇으로",
  "요구",
  "섣불리",
  "말지",

  "들어갈수",
  "왕자가",

  "앉거라",
  "사업가",
  "이렇게도",
  "에유",
  "점검",
  "달랐어",
  "지내게",

  "볼륨",
  "무적의",
  "반응은",
  "확신합니다",
  "그림자는",
  "그거보다",
  "사냐",
  "덮고",
  "들어줄래",
  "치매",
  "광물",
  "에디가",
  "여느",
  "춘향",

  "기다리시면",
  "지난밤",
  "심히",
  "부하들이",
  "상한",
  "스파이더맨을",
  "해킹",
  "당하게",
  "무섭다고",
  "아이리스가",
  "저들",
  "졌다고",
  "재밌었어요",
  "부서",
  "원고",
  "갱스터",
  "맥과이어",
  "표적이",
  "믿거나",
  "중국의",
  "쇼핑을",
  "멈추질",
  "압력이",
  "기지",
  "팀장님",
  "도와줄거야",

  "와보세요",
  "세월에",
  "까불지",
  "여러분과",
  "깔깔깔",
  "클레이",
  "잘했군",
  "버리자",
  "돌아올지도",
  "보관하는",
  "참어",
  "정도였죠",
  "연기하는",
  "케일런",
  "설명은",
  "아가씬",
  "합쳐서",
  "차린",
  "그럴리",
  "움직이자",
  "술집을",
  "들어보고",
  "기쁘네",
  "시나",
  "소닉",
  "여기있어요",
  "힘으로도",
  "은퇴한",
  "표준",
  "사라베스",
  "파티도",
  "복이",
  "마십쇼",
  "삶으로",
  "형제들이여",
  "싶나요",
  "흘리게",
  "병원이야",
  "만지게",
  "지도요",
  "중요하고",
  "이와",
  "했다간",
  "무엇도",
  "소용없는",
  "방사선을",
  "빈센트는",


  "안되잖아",
  "경동맥",
  "느껴본",
  "위원회에서",
  "불탈",
  "현재는",

  "실패할",
  "혈액형이",
  "다가오고",
  "내려가지",
  "새에",
  "간식",
  "힘들었던",
  "무섭다",
  "여정을",
  "봐야할",
  "느끼는지",
  "속이지",
  "찾았고",
  "모르핀을",
  "데려오는",
  "워싱턴에",
  "멜린다",
  "끝도",
  "바바둑이",
  "서베이",
  "수단을",
  "디저트",
  "건물로",
  "남기는",
  "대업이",
  "우습지",
  "만났습니다",
  "뻔하고",
  "원인",
  "고그의",
  "한놈",
  "성전을",
  "여자보다",
  "부분의",
  "사이에는",
  "시간이지",
  "된다네",
  "다이버",
  "출입금지",
  "피타가",
  "저거야",
  "마법을",
  "다섯을",
  "심폐소생술",
  "제안이",
  "갑판에",
  "키퍼를",
  "광장",
  "북쪽에서",
  "저번엔",
  "텔레포트",

  "사라가",
  "갈겨",
  "어서와요",
  "잤잖아",
  "건에",
  "없구만",

  "프랑크",
  "그리프",
  "성질을",
  "대장장이",
  "몇일",
  "않던",
  "탔어요",
  "되면서",
  "추운데",
  "수동으로",

  "꾸며",
  "몰랐다고",
  "집사람은",

  "체력",
  "받죠",
  "숙녀가",
  "정말이냐",
  "가네요",
  "죽었거든",
  "아시고",
  "뾰족한",
  "변동",
  "나간다고",
  "찔렀어",
  "자동으로",


  "나여",
  "허리를",
  "뿐만이",
  "시야",
  "중대",
  "외계인을",

  "구려",
  "수혈을",
  "부서지고",
  "푸드",
  "넘",

  "실행할",
  "한숨도",

  "자칭",
  "새끼들은",
  "척하지",
  "안보고",
  "똑같네",
  "전해주게",
  "지시",
  "아르바이트",
  "니콜",
  "받았나",
  "모이는",
  "보겠소",
  "가뜩이나",
  "자궁에",
  "무대에서",
  "흘려",
  "생각들을",
  "아일링",
  "뚱뚱해",
  "싶대",
  "신고할",
  "있지는",
  "아는군",
  "어무이예",
  "수직",
  "마신다고",
  "소년의",
  "피곤할",
  "이쁘다",
  "웬디",
  "아빨",
  "뛰면서",
  "거구만",

  "저리로",
  "믿었다",
  "런던으로",
  "편지에",
  "나오나",

  "가신",
  "토바이어스",
  "주차장에",
  "귀엽다고",
  "시키지",
  "어디쯤",
  "해치진",
  "오만과",
  "알고보니",
  "기대하지",
  "나머지를",
  "저희한테",
  "의심하지",
  "부셔",
  "정말인가",
  "내려가요",
  "까꿍",
  "아저씨의",
  "비웃지",
  "잤지",
  "아름다웠어요",
  "언젠데요",
  "찾아도",
  "부르게",
  "직원들을",
  "원칙을",
  "근거가",
  "말레피센트는",
  "해준다면",
  "달란",
  "종말을",
  "박사님의",
  "과테말라",
  "보냈다고",
  "옥희는",
  "살아난",
  "타격을",
  "아직이요",
  "찍지",
  "얼굴만",
  "괜찮어",
  "국립",
  "학부모의",

  "발견했지",
  "끊었어요",
  "가만있자",
  "운에",
  "가르치지",
  "의원",
  "이에게",
  "잡아주세요",
  "지켜야지",
  "불알을",
  "샌",
  "칵테일",
  "연락처를",
  "바톤",
  "동기가",
  "발견해서",
  "낸다",
  "춰",
  "아니었네",
  "쪽바리",
  "도둑은",
  "미카엘",
  "해치고",
  "끝났으니까",
  "들리세요",
  "선수의",
  "남자친구라고요",
  "그때까진",
  "저럴",
  "기사도",
  "번호로",
  "깨지고",
  "지킨다",
  "비정상적으로",
  "포로가",
  "방문할",
  "고파",
  "금메달",
  "다혜씨를",
  "사라진다",

  "바가지",

  "괜찮은가",
  "어이고",
  "아시아",
  "묻었어요",
  "존을",
  "있다면서요",
  "안합니다",
  "반대야",
  "자랐지",

  "간호사한테",
  "많으면",
  "존중하는",
  "살려요",
  "것이니까",


  "대우를",
  "협회",

  "북을",
  "구실을",
  "시작했다고",
  "정신병원에",
  "그랬었죠",
  "상처에",
  "숙소를",
  "소리에요",
  "생산",
  "억수로",
  "홍수가",
  "로프를",

  "의지를",
  "쉐퍼드를",
  "서두르세요",
  "당신들과",
  "가재이",
  "스카이넷이",
  "받았소",
  "아드님이",
  "식으로든",
  "왔대이",
  "탄생",
  "부리지",
  "춤도",

  "애디슨이랑",
  "아니잖니",
  "것이기",
  "가버릴",
  "언닌",
  "다면",
  "평가를",
  "일해서",
  "대봐",
  "돌아가는지",
  "할머니랑",
  "반에",
  "바뀌면",
  "발생하는",
  "아차",
  "그것두",
  "차이",
  "함께하고",

  "것같아",
  "좀전에",
  "누굴까요",
  "그렇다고요",
  "성님예",
  "운을",
  "필립이",
  "벗어요",


  "벽돌",
  "기대했던",
  "약속해줘요",
  "모양이죠",
  "친구였던",
  "왕국에",
  "느낌이에요",
  "죽지도",
  "씨한테",

  "물건에",

  "데야",
  "퇴학",
  "주방에서",
  "받으며",
  "욕조에",
  "큰돌",
  "법이죠",
  "떨어지라고",

  "좋아하실",


  "녹음이",


  "준식아",
  "치기",
  "알기로는",
  "외롭고",
  "미쳤군요",
  "알길",
  "갔네",
  "치게",
  "나가줘",

  "여깄습니다",
  "바보같아",
  "알기는",
  "방지",
  "아주머니는",
  "대처",



  "믿어져",
  "줬으니",
  "의심하고",
  "달이나",
  "잘랐어",
  "엄마들은",
  "과제를",
  "찾겠어",
  "대좌님",
  "속한",

  "참가할",
  "것조차",
  "가져왔습니다",
  "독감에",
  "축복이",
  "훌륭하군",
  "발견했죠",
  "연구하고",
  "유혹에",

  "남친",
  "비극을",
  "하거라",
  "업",
  "컵을",

  "보살펴야",
  "균형",
  "위험했어",
  "익숙해",
  "되고요",
  "강물에",
  "전이",
  "샌드위치를",
  "오케",
  "유태인",
  "구하려",
  "회",

  "궁금하네",
  "싸지",
  "피우는",
  "덕분이야",
  "통신을",
  "들으라고",
  "소금",
  "해주죠",
  "청혼을",
  "실없는",
  "초대하고",
  "차례가",
  "양복을",
  "놓쳐",
  "무당을",

  "상관인데",
  "가에",
  "센트럴",
  "까만",
  "생",
  "사무엘",
  "파트너는",
  "부인도",
  "돈이면",
  "생각하길",
  "주님을",
  "되던",
  "났는지",
  "연락할게",
  "묵을",
  "없는게",
  "인사해야지",
  "잘랐어요",
  "베푸소서",
  "생각이라고",
  "이것밖에",
  "자신감",
  "존나게",
  "나약한",

  "현금이",
  "돌아오게",
  "깜빡했네",
  "파리가",
  "얼만지",
  "힘들었을",
  "사자가",
  "이렇지",
  "사촌은",
  "잘했지",
  "유인원은",
  "이들과",
  "알아들었어요",
  "멍청하지",
  "희생",
  "도와주기",
  "앉아있는",
  "라이",
  "사령관은",
  "가졌을",
  "주제를",
  "됐노",
  "병실",
  "트라우트",
  "추는",
  "마로니는",
  "병장님",
  "얼굴로",
  "내장",
  "읽으면",
  "만들어줄게",
  "손해",
  "나인",
  "사이드",
  "아파트가",
  "빼는",
  "감사드려요",
  "코리",
  "해볼께요",
  "보낼게요",
  "입어도",
  "모임을",
  "결혼해줘",
  "생각나요",
  "씨팔",
  "방송국에",
  "사람이오",
  "가모라",
  "씁니다",

  "기왕",
  "않은거야",
  "빠르지",

  "연습이",
  "땅도",
  "생각하시나요",
  "서장님께",
  "박스트롤이",
  "맹세했어",
  "피바디가",
  "하인",
  "턱이",
  "액슬",
  "하하하하",
  "바쁘지",
  "미치도록",
  "성경을",

  "숨기는게",
  "엘르",
  "회색",
  "통화가",
  "왕좌를",
  "저하",
  "그러겠죠",
  "허락할",
  "은행에서",

  "교통사고로",
  "잘해낼",
  "꺼예요",
  "삭제",
  "사라졌죠",
  "마차",
  "지금당장",
  "요리하고",
  "않았기",
  "못했소",
  "증인을",
  "분야에서",
  "창문에",
  "꿈과",
  "합병증",
  "돌아오실",
  "호모",
  "흐르고",
  "강이",
  "팔코니를",
  "물과",
  "될거예요",
  "터무니없는",
  "경도",
  "혁명의",
  "참에",
  "살려내",
  "왜이렇게",
  "대단했어",
  "커플이에요",
  "파워",
  "흥분돼",
  "샤르마",

  "될테니까",
  "간단해요",
  "주의해",

  "아프잖아",

  "마련이야",
  "준비됐니",
  "앓고",
  "재시험을",
  "시신",
  "도서관이",
  "카운트",
  "백성들은",
  "퍼지는",
  "제이콥이",
  "됐냐",
  "연필",
  "세리나",
  "애요",
  "키우면",
  "둘러보고",
  "만해",
  "스티븐스랑",
  "차려라",
  "구했을",
  "그럴거",
  "공격하고",
  "미쳐서",
  "리셋",
  "생존자는",
  "학생은",
  "있으라",
  "싫으니까",
  "구두가",
  "히스",
  "또래",
  "노예",
  "돌려봐",
  "즐겨요",
  "설명해줄",
  "애론이",
  "소개할",
  "안써요",
  "공부해",
  "인민",
  "데일",
  "속에서도",
  "영국에서",
  "한바퀴",
  "꾸준히",
  "갔으면",
  "처음이었어요",
  "주자",
  "손상은",
  "외상은",
  "터널로",
  "키스해줘",
  "크래스터",
  "마시기",
  "이거봐",
  "영국으로",
  "룸메이트가",
  "뜬금없이",
  "무슨일을",
  "화장실을",

  "잘봐",

  "배웠다",
  "귀중한",
  "그정도는",
  "테쳐",
  "반한",
  "형태로",
  "기억한다",
  "모욕을",
  "수술이요",
  "밴드가",
  "대화로",
  "잘라야",
  "남학생",

  "가시나",
  "생각지도",

  "요렇게",
  "아뢰오",
  "구역은",
  "모금만",
  "선물도",
  "다니게",
  "돌아가시오",
  "관장",
  "의사인",
  "예수님을",
  "입혀",
  "따라해",
  "나아진",
  "야생",
  "동물들을",
  "보람이",
  "저택에",
  "해결하면",
  "부족의",
  "번씩이나",
  "오느라",

  "매독을",
  "대통령을",
  "전까지는요",
  "없다면요",
  "그러시면",
  "가능하면",
  "당해서",
  "전달해",
  "지배하고",
  "일보",
  "질문으로",
  "필이",
  "없다면서",
  "좋았지만",
  "최상기",
  "게시판에",
  "멈춰봐",
  "바라는게",
  "사태가",
  "되십시오",
  "우리보고",
  "구하면",
  "건강해",
  "힘듭니다",
  "하지만요",
  "견인기를",
  "낫겠어요",
  "드럼",
  "필사적으로",
  "정신과에",
  "토레스와",
  "타라고",
  "애매한",
  "빔",
  "옥희가",
  "내라고",
  "보낸다",
  "우주에는",
  "목록",
  "뜬다",
  "지구와",
  "그런일을",
  "소개",
  "대단했어요",
  "믿어라",
  "레지던트들이",
  "여성은",
  "김철",
  "메르디스가",
  "달렸지",
  "해결될",
  "존재로",
  "부정맥을",
  "조사할",
  "넘은",
  "창피하게",
  "수업아",
  "것이지요",
  "켜고",
  "섹시한데",
  "불꽃",
  "쓸어버릴",
  "막혔어",
  "알았다구요",
  "반해",
  "재미있지",
  "환자들도",
  "살림",
  "아니라서요",
  "사줘",
  "강력반",
  "알프레도",
  "느꼈다",
  "것이네",

  "다물라고",
  "헤에에",
  "등신",
  "어떤것도",
  "척은",
  "경찰들",
  "팀에서",
  "쓰러질",
  "있다는건",
  "땡큐",
  "하느님을",
  "볼께요",
  "저항을",
  "움직임이",
  "날려버린",
  "살았습니다",
  "오면서",
  "바꿔봐",
  "증후군",
  "지들이",
  "먹는다고",
  "운다",
  "부대의",
  "크리스가",
  "운영하는",
  "납셨네",
  "상우가",
  "거든요",
  "바둑",
  "싶다구요",
  "대기시켜",
  "사고는",
  "베써니",
  "툭",

  "계신지",
  "심장병",
  "약이에요",
  "리처드에게",
  "아는거야",
  "하나면",
  "기영아",

  "계약서",
  "자네에",
  "오픈",
  "맹장을",
  "수의사랑",
  "스토리를",

  "장소입니다",
  "차들이",
  "배고프니",
  "수프",
  "여자하고",
  "아픔을",
  "표현할",
  "안에선",
  "낳은",
  "이렇게나",
  "기록도",
  "둘이요",
  "신경써",
  "말씀드릴게요",
  "이정도면",
  "끓는",
  "왕좌에",
  "깜찍한",
  "주파수를",
  "산사를",
  "언제가",
  "괴롭히고",
  "얼어죽을",
  "움직이게",
  "다발성",
  "독일의",
  "못생겼어",
  "자나",
  "이기려면",
  "마지막까지",
  "온거지",
  "줘도",
  "뺏고",


  "처음이라",

  "보내주지",
  "썼죠",
  "폰을",
  "전화하세요",

  "궁금했어요",
  "갈꺼에요",
  "독일은",
  "걱정하실",
  "불안한",
  "니잠",
  "한명을",

  "현관에",
  "럭스",
  "않았다구",
  "클락",
  "미국인들이",
  "제외하면",
  "처음입니다",
  "첫째로",

  "포르쉐",
  "없다는걸",
  "미린에",
  "온단",
  "보안이",
  "그이의",
  "후티후",
  "루디를",
  "조그마한",
  "찾아볼게요",
  "갔었는데",
  "했냐고",
  "전자기",
  "앤을",
  "며칠은",
  "계셨나요",

  "없을겁니다",
  "아셔",
  "그대에게",
  "소가",
  "왈더",
  "캠핑",
  "주도권을",
  "생기기",
  "전술",
  "하지말고",
  "사나",
  "진화",
  "날릴",
  "응답해요",
  "방송에",
  "말그대로",
  "지역은",
  "해야하는지",
  "출구를",
  "개자식이",
  "비쳐",
  "찾아내는",
  "있는건가",
  "유감이네",

  "지리",
  "세명의",
  "자존심을",
  "오수희",
  "끔찍히",
  "보수는",
  "식물을",
  "안전벨트",
  "올라간",
  "잖아요",
  "각기",
  "떨어졌고",

  "새야",
  "해야하나",
  "혼란스러운",
  "싶단",
  "잘해서",
  "우스운",
  "시푸",
  "가득해",
  "유럽의",
  "행사를",
  "달려있어",
  "둘에게",
  "비싸게",
  "베카",
  "멍청하다고",
  "기다리기",

  "대비를",
  "활용할",
  "바구니에",
  "껍질을",
  "사이공",
  "자라면",
  "회복을",
  "반면에",
  "내비게이션을",
  "브라보스",
  "카트",
  "만족하지",
  "사실도",
  "자책하지",
  "시대의",
  "희망도",
  "꺼져요",
  "가져다가",
  "금지야",
  "발렌타인이",
  "직원들",
  "잊었나",
  "쳐다보지마",
  "궁금하군",
  "선영이",
  "가져가지",
  "육군",
  "해볼게요",
  "까",
  "은퇴",
  "반대하는",
  "유방암",
  "당했다",
  "정신은",
  "대강",
  "집착하고",

  "두껍고",
  "불합격",
  "알고요",
  "카운트다운",
  "향기",
  "인도에",
  "범위",
  "준비해야",
  "사슴을",
  "카메라에",
  "둘게요",
  "신체적",
  "조사하는",
  "여러분에게",
  "바니",
  "다행히도",
  "아줌마는",
  "저기야",
  "경치가",
  "플라스틱",
  "폐쇄",
  "먹기로",
  "코치님",
  "맙시다",
  "일으켰어",
  "잃었고",
  "잡담",
  "쥐새끼",
  "프란츠",

  "부족해요",
  "오래도록",
  "라니스터는",

  "많은지",
  "반갑네요",
  "용기는",
  "워커",
  "모임에",
  "도슨",
  "사진들을",
  "열받게",
  "할아버지한테",
  "갈려고",
  "알아라",
  "오시라고",
  "할수있는",
  "속지",
  "할거라고",
  "배포도",
  "같지가",
  "수수께끼야",

  "화물이",
  "기사는",
  "할아버지랑",
  "나오는지",
  "주연",
  "연락하지",
  "준석이",
  "가졌다는",
  "그런말을",
  "아프기",

  "있던거야",
  "지루할",
  "말일",
  "어젯밤엔",
  "천문학자들은",

  "안전한지",
  "경례",
  "신는",
  "잤는데",
  "행동하지",
  "살펴봤는데",
  "들어가야지",
  "버나",
  "근처에도",
  "했는",
  "불길",
  "약해지고",
  "시합을",
  "잔은",
  "실망",
  "워워",
  "적응",
  "치루게",
  "방법이다",
  "증인이",
  "계집애처럼",
  "것좀",
  "흘렸어",
  "슬론과",
  "계획도",
  "피야",
  "주셨어",
  "야단",
  "아임",
  "질리는",
  "증세를",
  "말해다오",
  "같았으면",
  "돌봐줘야",
  "주겠지",
  "비웃고",
  "웨이백을",
  "다시요",

  "건강해요",
  "떨어뜨렸어요",
  "네놈은",
  "인간보다",
  "경기장에서",
  "헤어졌을",
  "의식하지",
  "어땠나요",
  "얘기하자면",
  "결혼하자고",
  "레슬링",
  "났었어",
  "우리나라",

  "명색이",
  "파일들을",
  "기억나죠",



  "침공이",

  "가볼께",
  "듯합니다",

  "꼴은",
  "결정되는",
  "단추",
  "우려가",
  "부품도",
  "팍팍",

  "쐈다고",

  "센터",
  "천하의",
  "태어나",
  "살인도",
  "불러올",
  "관심없어요",
  "넋이",
  "지금은요",
  "도미닉",
  "빨래를",
  "자원을",
  "살기로",
  "믿음의",
  "리스트",
  "맞추기",
  "필요한가",
  "정당성을",
  "얼마에",
  "내렸다",
  "멀어지고",
  "착륙한",
  "드레스는",
  "오는구나",
  "사람요",
  "마부의",
  "교수가",
  "생각했는데요",
  "불가능하다",
  "가루를",
  "에바가",
  "시켜야",
  "버츠",
  "죽여주마",
  "원은",
  "유럽으로",
  "좋아하는걸",
  "꺼냈어요",
  "첫째는",
  "대를",
  "소리지를",

  "일치해",
  "시청에",
  "있었다네",
  "발진",
  "눈치도",


  "초대하지",
  "가이드",
  "취향은",
  "편의점",
  "결심을",
  "닥터야",
  "한마리가",
  "재밌군",
  "나서야",
  "기준이",
  "긴장한",
  "바뀌고",
  "자연이",
  "다치는",
  "시력을",
  "사랑스러워",
  "아이하라",
  "바깥으로",
  "표정으로",
  "응답해",
  "달러로",
  "선택된",
  "크다는",
  "남부",
  "초과",
  "복부를",
  "밤까지",
  "도둑질",
  "어떡하냐",

  "지껄이는",
  "흥분하지",
  "불쌍하지",
  "연인이",
  "전학",
  "감정도",
  "편지도",
  "어두워",
  "며칠동안",
  "혼자인",
  "유감이구나",
  "임충은",
  "해서라도",
  "깎아",
  "후가",

  "역시나",

  "병력은",
  "자유배포",
  "빅터를",
  "마취가",
  "여겨요",
  "놔두세요",
  "예전보다",
  "저여자는",
  "그냥요",
  "떠올랐어",
  "할머닌",
  "분만을",
  "이기적으로",
  "집이고",
  "몇천",
  "부모님께서",
  "보안을",
  "자선",
  "괴짜",
  "환상의",
  "데이타",
  "베지",
  "배짱",
  "찾아보고",
  "이로써",
  "탐",
  "조합을",
  "영희",
  "차이는",
  "무슨말",
  "주시해",
  "방문을",
  "내게는",
  "엉망",
  "듭니다",
  "많아서요",
  "질환",
  "하루아침에",
  "장님이",
  "싱어선생님",
  "끌리는",
  "정하는",
  "일이십니까",
  "콘크리트",
  "증상은",
  "부분이지",
  "숨쉬고",
  "알았는지",
  "물리학자들은",
  "동결",
  "정리하는",
  "타임머신이야",
  "결론이",
  "타이어가",
  "장만",
  "엊그제",
  "납셨군",
  "어딨는데",
  "어렵네",
  "임금을",
  "괴롭혀",
  "될걸요",
  "깨어나고",
  "여자애들",
  "찍었어요",
  "자네한테도",
  "시원해",
  "위험부담이",
  "올바르게",
  "모니터링",
  "두개골을",
  "달래",
  "출연",
  "능력에",
  "구원",
  "상관없잖아",
  "스테판은",
  "배우게",
  "여자애한테",
  "일을하고",
  "히포크라테스",
  "맡았던",
  "섹시하지",
  "갈리프레이",
  "수술방에",
  "마쳤습니다",
  "세기의",

  "창자를",

  "믿니",
  "신병",
  "사랑했지만",
  "돌봐주고",
  "꼴리뇽",
  "충전해",
  "행복의",
  "넘길",

  "말했소",

  "메리예요",
  "스톤을",
  "냐",
  "깨끗합니다",
  "올거지",
  "대회는",
  "좋냐",
  "복부가",
  "바꾸면",
  "루를",
  "오기만",
  "인물을",

  "가격은",
  "잡혀요",
  "점쟁이",
  "해볼께",
  "선과",
  "자랐고",

  "들었거든요",
  "마녀가",
  "남으면",

  "계획된",
  "글렌이",
  "이거다",
  "릉온이",
  "겐가",
  "우리요",
  "던지는",
  "고등학교에서",
  "댁으로",
  "심각하다고",
  "인사이트",
  "자기만의",
  "모르는거야",
  "그러셨죠",
  "나왔네",
  "살잖아",
  "놈이에요",
  "팝",
  "약속하죠",
  "차량이",
  "부분도",
  "한다며",


  "영희가",
  "남친은",
  "생각하냐",
  "디노가",
  "방광",
  "기념품",
  "미워서",
  "로미오와",
  "알루미늄",
  "새댁",
  "음메",
  "결혼하게",
  "말한다고",
  "산더미",
  "심각합니다",
  "깨닫는",
  "사실인가",
  "상처의",
  "전통입니다",
  "아드레날린",
  "기계들을",
  "그럴줄",

  "과도하게",
  "사람들아",
  "제인을",
  "신경성",
  "매일밤",
  "주일",
  "의학이",
  "복용한",
  "들어볼래",
  "옷장에",
  "결정했어요",
  "살이에요",
  "느껴야",
  "했던거야",
  "순결을",
  "아이반",
  "자발적",
  "곳이요",
  "아이처럼",
  "용돈",
  "감사히",
  "없는건",
  "산이",
  "친구다",
  "입구를",
  "세상으로",
  "보살피는",
  "보고는",
  "페니를",
  "역할이",

  "들렀다",


  "피곤하게",
  "확인하기",
  "출장소",
  "다치면",
  "그랬구나",
  "손실이",
  "만난다고",
  "도서관에",
  "결승전",
  "한번쯤은",
  "동생한테",
  "고의로",
  "아이작",

  "생각하지마",
  "소음",
  "지배할",
  "기사로",
  "좋아하진",
  "태어나면",
  "받으셔야",

  "모나크",
  "인상이",

  "깨지",
  "감방에서",
  "차인",

  "뜻밖의",
  "양에게",
  "어쩔래",
  "꼴도",
  "수술대에",
  "막걸리",
  "이론의",
  "정의로운",
  "취미",
  "거구",
  "늙으면",
  "부브는",
  "먹자고",

  "프랭크는",
  "다크",

  "부브의",
  "것이기도",
  "풀렸어",
  "이럼",
  "이도",
  "바뀌었어요",
  "공급이",


  "발작이야",
  "쉐퍼드한테",

  "코와",
  "해보려고",

  "절대적인",
  "별수",
  "성기가",

  "복을",
  "꺼라",
  "여러모로",
  "되면요",
  "여러가지로",
  "도킹",
  "책임감",
  "소리지르고",
  "돌연",
  "나타납니다",
  "여지껏",
  "부탁할게요",
  "안전한가",
  "여사",
  "편은",
  "징후가",
  "위험하다는",
  "부브들이",
  "미확인",
  "아팠어",
  "맡기는",
  "애나가",
  "박사예요",
  "담아서",
  "고그",

  "화나지",

  "카팔디",
  "있었는데도",
  "근신",
  "진정하게",
  "상에",
  "이성적인",

  "보그스",
  "핵심",
  "메고",
  "하세",
  "노력할",
  "뭐라든",
  "목적에",
  "않거나",
  "수술하지",

  "맛있네요",
  "살아있을",
  "그것뿐이야",
  "평화와",
  "소리지르는",
  "바라며",
  "주라구",
  "속히",
  "의심가는",

  "탈출할",
  "후로는",
  "말해달라고",
  "질투할",
  "심장박동이",
  "캐피탈이",
  "방문",
  "목소리로",

  "정상이에요",


  "소리와",
  "제안할",
  "좋기는",
  "뚫린",
  "역자",
  "선택에",
  "늦었네요",
  "의사입니다",
  "데려갔어요",


  "상금이",
  "혼자예요",
  "잡혀서",
  "구경하고",
  "뻔했지",
  "천주의",
  "비켜주세요",
  "노래만",
  "끝이죠",

  "다르네",
  "하나둘셋넷",
  "수업이죠",
  "캐피탈의",
  "공개적으로",
  "가져가면",
  "한동안은",
  "추한",
  "핵무기를",
  "알려줘서",
  "아름답다",
  "싸워야합니다",

  "희생이",
  "정종석",
  "알고싶어",

  "생기게",

  "호손",
  "깁슨",
  "잠들게",
  "투표로",
  "때라고",
  "손실을",
  "약해요",

  "페루",

  "유지하기",
  "아닐수도",
  "까짓",
  "안써",
  "발표하겠습니다",



  "보여주려고",
  "끼치지",
  "샀죠",
  "멈추는",
  "관련해서",

  "자발적으로",

  "해봤지",
  "안한다고",
  "이끌",

  "혼자서요",

  "생각해낸",
  "내보내야",
  "개로",
  "독소를",
  "끌어들이지",

  "그런적",
  "비스트",
  "고든입니다",
  "시스템에",
  "와본",
  "아침에는",
  "밥이랑",
  "말리지",
  "떴어요",
  "인간들을",
  "그날밤",
  "고정시켜",

  "전기도",

  "홀든",

  "일개",
  "나는야",


  "받아야만",
  "기집애",
  "새들은",
  "견디지",
  "갔더니",

  "초점이",

  "골치가",
  "샐러드",
  "차갑게",
  "건전한",
  "늙은이와",


  "춘삼이",
  "가문이",
  "수녀는",
  "물어도",
  "모르겠는",
  "떠나자",
  "피어스",
  "재고",
  "헷갈려요",
  "계획한",
  "생겼네요",
  "제외한",
  "천국으로",

  "오로라를",
  "천국과",
  "실수라고",


  "저녁식사를",

  "대변을",

  "쏟아져",
  "요가",
  "까맣게",
  "더운",
  "마크를",
  "하시나요",
  "장관",
  "절단된",
  "생겼을까",

  "사람들보다",
  "티를",

  "들어주는",


  "임신했어요",
  "허락이",
  "주님께서",
  "측",
  "여행할",
  "심각하지",
  "변호사와",
  "비티",
  "야넥",
  "일정이",
  "했는데도",
  "연달아",
  "그러곤",
  "심폐소생술을",

  "해결한",
  "홍차",
  "엄마라고",
  "다음달에",
  "안중에도",
  "바쁘세요",
  "감안할",
  "피신",
  "어쨌건",
  "황금",
  "사탄의",
  "문도야",
  "끔찍하다",
  "엄격한",
  "기댈",
  "했었거든요",
  "후론",
  "뜨거워",
  "오르고",

  "가라고요",
  "부근에",
  "기쁘지",
  "조종하는",
  "님은",
  "늦고",
  "요원들이",
  "안와",
  "미친짓이야",
  "건지는",

  "유력한",
  "인상적이군",

  "꽥",
  "환자라고",
  "내부를",

  "메러디스요",
  "고향은",

  "우리쪽",
  "태너",
  "모시러",
  "만원",
  "문턱이",
  "생겼잖아요",
  "붙여요",
  "은폐",
  "없을거다",
  "붙였어",
  "급격히",
  "스탠튼",
  "두뇌를",

  "서울서",
  "보니깐",
  "인턴이고",
  "있을만큼",
  "미끼를",
  "할일을",
  "있었지요",
  "얼룩이",
  "모르겠다고",
  "재밌다",

  "안녕하슈",

  "같다니까",

  "탓이",
  "즐기지",
  "껴",
  "쟤네들",
  "나왔는데요",
  "좋았잖아",
  "이토록",

  "육체를",

  "신체는",
  "심상치",
  "와선",
  "없을꺼야",
  "만들자",
  "최고다",
  "싫은가",

  "니를",
  "요청한",


  "알아두세요",
  "맞춘",
  "거라니까",
  "이러니까",
  "소녀에게",
  "여친이",

  "진심이",

  "처녀가",
  "나쁜건",
  "생긴게",

  "버터",
  "꺽",
  "해리가",
  "스승을",
  "기린",
  "캡",

  "모르실",
  "느이",
  "창에",
  "믿나",
  "타스가",
  "불확실한",
  "동의할",
  "밀",
  "클리닉을",
  "호레",
  "믿어봐",
  "말았습니다",
  "그러슈",
  "지우지",
  "이야기나",
  "벗을",
  "병으로",
  "할배요",
  "다이앤",
  "재앙이",

  "기도는",
  "말해볼래",
  "마이클은",
  "아버님과",

  "인턴과",
  "돌아왔지",

  "저항군",
  "오래오래",
  "콜링스",
  "아버지야",

  "진행하고",

  "물론이고",
  "그려진",
  "호오",
  "가게로",
  "거랑은",
  "생각으론",
  "모양의",
  "프랑스와",
  "괜찮다고요",
  "무릎이",
  "묶인",
  "계집을",

  "인턴도",
  "소용돌이",

  "예루살렘을",
  "실질적인",
  "상태라",
  "걷기",
  "살인자는",

  "여린",
  "참여하지",
  "참이었어",
  "힐러리",
  "직경",
  "가시게",
  "킵스씨",
  "교대",
  "갖추고",
  "물었어요",

  "다할게",
  "외치는",
  "공평하지",
  "몸값을",
  "부탁은",
  "의학에",
  "주에는",
  "죽였어야",
  "누군지는",
  "울며",
  "예루살렘의",

  "것뿐이다",
  "가브리엘",
  "살래요",
  "재밌겠네",
  "물러서서",
  "불륜",
  "같다고요",
  "잠자기",
  "도로가",
  "폐하는",

  "사용하게",
  "되어도",
  "잔다르를",
  "자격증이",
  "중심의",
  "은하와",
  "반지는",
  "미싸",
  "너지",
  "허락받은",
  "부부의",
  "로빈슨",
  "스틱",

  "막혔어요",
  "맞을지도",
  "개뿔",
  "잔돈은",
  "온몸이",
  "병실에서",
  "독극물",
  "수영장에",
  "미안해서",
  "병신이라고",
  "원장님",
  "느려터진",
  "어떻고요",
  "나이테",
  "영화도",
  "얻었어요",
  "화재를",
  "퇴직",
  "브라이스는",

  "남자들의",
  "않았을까요",
  "않았고요",
  "뻔하잖아",
  "해부",

  "놔주세요",
  "샴페인을",
  "두르고",
  "됐으면",
  "돌았나",
  "불편하게",
  "하나일",
  "핑크는",
  "분께서",
  "대책을",

  "진정제가",

  "시집갈",
  "여러분들의",
  "긴장해서",
  "전까지만",
  "내일로",
  "줬을",
  "위험하니까",


  "만들다니",
  "용기와",

  "도와주실",
  "사랑하는거",
  "지나쳐",
  "보더니",
  "알아둬야",
  "울긴",
  "잭슨의",
  "시작할게요",
  "접고",
  "한도",
  "렉시랑",
  "타락한",
  "말씀입니까",
  "터놓고",
  "만나뵙게",
  "책에서",
  "카페테리아에서",
  "뵐게요",
  "영주",
  "셋이나",
  "말씀드렸잖아요",
  "천만",
  "은하에",
  "싸웠다",
  "자네처럼",
  "저번에도",
  "기록에",

  "보이시네요",
  "후계자가",
  "여행도",
  "속임수",
  "규칙이야",

  "따른다",
  "중국에",
  "바이탈",
  "중요한지",
  "행복하다",
  "사제",
  "커버",
  "몰라야",
  "무시하면",
  "봉합할",
  "갚고",
  "쳐다도",
  "혼란을",
  "뒤로는",

  "되어있어",
  "쟤네가",
  "대규모",
  "지도원",
  "훈장",
  "취급을",
  "시설을",
  "쪼까",


  "밀크쉐이크",
  "보고에",
  "만스가",
  "마실까",
  "포르노요",
  "붙들어",
  "과일을",
  "오른",
  "있을거다",
  "그날은",
  "물러날",
  "몰랐고",
  "뒤돌아서",
  "신들께서",
  "레이시",
  "적과",
  "승리가",
  "맥주가",

  "주식",
  "갇혀있는",
  "기억해라",
  "달님에게",
  "갔었죠",
  "병실은",
  "남아있어",

  "분노에",
  "테이블에서",
  "크링글",

  "이사람은",
  "혼",
  "들어왔을",
  "뽑을",
  "삐",
  "예방",
  "워째",
  "하나와",
  "가보는게",
  "행위를",
  "평소와",
  "반란을",
  "말씀이신가요",
  "주민들을",
  "감사절",
  "혁명을",
  "보내달라고",
  "비행을",
  "생명체는",
  "라디오를",
  "부대에서",
  "청구서",
  "천박한",
  "어떤건지",
  "비었어",
  "즐거워요",
  "틀렸다",
  "미국은",
  "정말인가요",
  "랄피",
  "테치",
  "사부",
  "댐",
  "짧아",
  "같대",
  "방식이야",
  "찍어도",
  "걸릴지",
  "변해",
  "붙어요",
  "자에",
  "헤드에게",
  "가십쇼",
  "시작되고",
  "하자꾸나",
  "위조",
  "뛰어다니고",
  "비었어요",
  "털도",
  "기절",
  "않고는",
  "참을성이",
  "노틀담의",
  "익은",
  "축제를",
  "된거에요",
  "거미를",
  "정해야",
  "무사할",
  "꼭대기",
  "줄일",
  "돕겠다고",
  "선생한테",
  "내려서",

  "남녀",
  "탄약",
  "애들만",
  "나트륨",
  "맡기게",
  "못했군",
  "명백히",
  "연기는",
  "선은",
  "레이크",
  "슬로",
  "달러의",
  "상부",
  "보여드릴게요",
  "범죄로",
  "일시적",
  "앞쪽에",
  "들린다면",
  "사막의",
  "도와서",
  "일예요",
  "끔찍하죠",
  "진다",
  "찰",
  "참여하는",
  "앰버",
  "없느냐",
  "제이슨은",
  "인턴에",
  "룸메이트",
  "혈액검사",

  "추측",
  "샀다고",
  "물어보지도",

  "짱",
  "옛날부터",
  "조영자",
  "멍청이야",
  "기르는",
  "끝났으면",
  "말씀하셨어요",
  "행복했으면",
  "필요해서요",
  "꼬추",
  "의지로",
  "갑옷",
  "말리고",
  "잡았는데",
  "그거다",
  "출구로",
  "스파이더맨이야",
  "오겠소",
  "보이거든",
  "이보게",

  "사과드립니다",
  "할수도",
  "흠잡을",
  "숨결",
  "조치",
  "사장님을",
  "높이고",
  "제이슨이",
  "찾기가",
  "코치가",
  "달아나는",
  "스피커",
  "사냥꾼",
  "오자마자",
  "온건",
  "유니폼",
  "빨강",
  "알아야할",
  "확대해",
  "때문",
  "성탄절",
  "목적",
  "헛되이",
  "오류",
  "실례해도",
  "얻어서",
  "참가한",
  "작전에",
  "히틀러",
  "싸이코",
  "랜디가",
  "도끼",
  "저격수",
  "고소",
  "먹이는",
  "화재가",
  "플래시",
  "브루스터",
  "스파이더맨은",
  "칭찬이",
  "그러더라",
  "시민들은",
  "그렇다구요",
  "죽였나",
  "북한의",
  "너였어",
  "증명",
  "요리할",
  "설치",
  "현실적으로",
  "따라잡을",
  "다하는",
  "않았으니까요",
  "가실까요",
  "얼음처럼",

  "기지를",
  "년동안",
  "트럭에서",
  "차타고",
  "믿으라고",

  "하는것은",
  "놈들만",
  "자일스가",

  "보여줬어",
  "인쇄",
  "찾았는지",
  "아파트로",
  "줄이고",
  "상관없다고",
  "정체",

  "인간도",
  "찍으러",

  "좋으시네요",
  "사령부",

  "자고로",
  "아무말",
  "동무를",
  "동무들은",
  "소문에",
  "너무너무",

  "잘못될",
  "이후엔",
  "약속드립니다",
  "보이겠지만",
  "부탁이니",
  "잘하네요",
  "늙은이를",
  "공격하는",
  "사랑하면",
  "음료를",
  "항문에",
  "쓰레기통",
  "내장이",
  "술취한",
  "감성적인",
  "것이라면",
  "프리몬트",
  "중국에서",
  "카이의",

  "때랑",
  "주문한",
  "끊을게요",
  "모라고",
  "대개는",

  "잔돈",
  "까먹었어",
  "산티아고",
  "다른거",
  "몰랐는데요",
  "제국의",
  "무기들을",
  "머리통을",
  "바라면",
  "지구상의",
  "그녀석이",
  "근데요",
  "대대로",
  "심문",
  "부정한",
  "피터는",
  "사돈",
  "해봐야지",
  "쫓겨난",
  "흔들지",
  "생명과",
  "싶으시다면",
  "네빌군",
  "이웃집",
  "칼도",
  "바리스탄",
  "얌전한",

  "형태를",
  "풀리는",
  "틀",
  "셈은",
  "심실성",
  "오토바이에서",
  "기어코",
  "부품을",
  "뿌린",
  "사고였어",
  "친구하고",
  "모습도",
  "반응도",
  "종족은",
  "요건",

  "노력해도",
  "지었어",
  "가게나",
  "하게끔",
  "잘해라",
  "들었지만",
  "제거하지",
  "낮에는",
  "으깬",
  "맡아줘",
  "쯤이면",
  "시험해",
  "사또",
  "드와넬",
  "바베큐",

  "말씀이오",
  "데려갈게요",
  "나았어",
  "선장",
  "말하겠죠",
  "실망한",
  "도망쳐요",
  "잊으면",
  "꺼주세요",
  "잠들었어",
  "뻔했잖아",
  "환장하겠네",
  "영화사",
  "나가봐야",
  "활동은",
  "춤은",
  "들었다고",

  "가줘",
  "레코드를",
  "봐달라고",
  "어땠는지",
  "깨질",
  "한다면요",

  "옷과",
  "이야기좀",
  "육체에",
  "공사",
  "시기를",
  "신기하다",
  "맞추는",
  "표정이요",
  "든든한",
  "말지어다",
  "뇌물을",
  "둥",
  "태어나지도",
  "비극이",
  "불법적인",

  "고의는",
  "느낌으로",
  "욕실에서",
  "돌아보면",

  "지났고",
  "흙이",
  "광란의",
  "불빛이",
  "잃었는데",
  "아가사",
  "오십쇼",
  "울어요",
  "말하네",
  "뒤쪽",
  "봉합해",
  "구역이",
  "규모의",
  "들려서",
  "친구라",
  "현장으로",
  "저작권",
  "몰리가",
  "똑같을",
  "보여주죠",
  "전용",

  "겨울에",
  "부끄러워하지",
  "경비대",
  "탓으로",
  "기업의",
  "할리는",
  "네트워크는",
  "차요",
  "어머님께",
  "프랜시스",
  "퀸랜은",
  "어처구니",
  "팀이에요",
  "봐서요",
  "생각의",
  "샘야자",
  "전화번호부",
  "요한",
  "찾은게",
  "연관",
  "버클리",
  "죽었겠죠",
  "하시더군요",
  "올라타",
  "아직까지는",
  "저기예요",
  "운전하는",
  "이야기죠",
  "하시는데",
  "가발",
  "책이나",
  "꺾어",
  "그런일이",
  "재능",
  "추적을",
  "법이에요",
  "충분합니다",
  "오리",
  "양심이",
  "전하가",
  "토르문드",
  "아기들",
  "요리사가",
  "상대해야",
  "짜샤",
  "멋질",
  "되겠지만",
  "암만",
  "궤도",
  "앞뒤가",

  "줄꺼야",
  "뉴스는",
  "무게를",
  "말했나요",
  "동호야",
  "킹스랜딩",

  "그들이야",
  "유감스럽지만",
  "생명으로",
  "거세병",
  "때쯤이면",
  "정신없이",
  "찍어요",
  "울지마",
  "시내",
  "에고",
  "말했잖니",
  "시골로",
  "팬더가",
  "몇명",
  "잡아다",
  "생길거야",
  "해야한다고",
  "진동을",
  "춘향이를",
  "트란트",
  "도가",
  "날이네",
  "장난치지",
  "루티거",
  "웨스테로스",
  "북부로",

  "쿵푸의",
  "네이트",
  "짓인지",
  "잘해냈어",
  "차분히",
  "드는군",
  "찾아보지",
  "봤단",
  "시간이군",
  "제국",
  "소동이",
  "대사야",
  "생각한다고",
  "무섭니",
  "전하께",
  "배우려고",
  "아래쪽",
  "측면에서",
  "걷어",
  "자살로",
  "풀이",
  "얹고",
  "사또께서",
  "참인데",
  "뭐하는거니",
  "잠재의식",
  "다른사람이",
  "만일을",
  "동물은",
  "케이블을",
  "년도",
  "양반의",
  "내를",
  "싸우세요",
  "배신을",
  "마무리를",
  "낫네요",
  "집단",
  "싸웠고",
  "오리엔트",
  "없다는게",
  "업적을",
  "끝납니다",
  "전보가",
  "모굴리스",
  "누고",
  "비교적",
  "먹어본",
  "자막입니다",
  "시까지",
  "기구를",
  "주니까",
  "다음이",
  "얼간이야",
  "살아있다고",
  "똥이",
  "노틀담에",
  "고문하고",
  "지나가게",
  "찌르는",
  "금새",
  "폈다고",
  "몇은",
  "최초인의",

  "필요하실",
  "해결되지",
  "대기를",
  "가르쳐줄",
  "칠왕국의",
  "자네야",
  "조지와",
  "주이소",
  "이러죠",
  "사람들이지",
  "모르겠다는",
  "중지합니다",
  "있을수도",
  "일했어",
  "살인범을",
  "증거도",
  "너머",
  "피해자들이",
  "기대할",
  "자금",
  "이곳에서의",
  "교수님의",
  "흑흑",
  "모임에서",
  "말여",
  "비친",
  "달러에",
  "다행인",
  "용서해주세요",
  "씹어",
  "섞여",
  "아닌게",
  "말해줄께",
  "안심하고",
  "아버지세요",
  "폐기물",
  "특별하지",
  "가에서",
  "추천",
  "누구하고",
  "떨어져야",
  "알릴",
  "이쪽에서",
  "샀는데",
  "일라이자",
  "뼈도",
  "들었으면",
  "소스를",
  "그렇지요",
  "사스케에",

  "가볼",
  "그거에요",
  "슬림",
  "런던을",
  "곁들인",
  "저녁도",
  "이상하게도",
  "헌신적인",
  "돈까지",
  "그한테",
  "상상력이",
  "걸어도",
  "박물관에",
  "반역자",
  "선물입니다",
  "진정하라구",
  "지나가고",
  "비밀들을",
  "까놓고",
  "난장이가",
  "아이죠",
  "얻었지",
  "늘어진",
  "봤거든요",
  "바에야",
  "야망이",

  "약속했죠",
  "부질없는",
  "시인의",
  "나는군",

  "윙크한",
  "해가지고",
  "기대하는",
  "알고나",
  "에이즈",
  "어렵지만",
  "활기찬",
  "걸렸죠",
  "떨어졌는데",
  "할때마다",
  "있던가",
  "회장",
  "문화",
  "업혀",
  "난장이",
  "캡슐",
  "없겠군",
  "차안에",
  "배웠죠",

  "지하실로",
  "막내",
  "오해",
  "리사가",
  "오라구",
  "쳐다볼",
  "아파도",
  "접촉한",
  "세웠어",
  "캐닝씨",
  "전화했는데",
  "니아신을",
  "리듬이",
  "와인이",
  "달라구",
  "찾읍시다",
  "구했다",
  "해변을",
  "없어지지",
  "넷이",
  "소파에",
  "반가웠다",
  "은가락지",
  "감독관",
  "분이요",
  "착",
  "전해주시겠어요",
  "쉘든이",

  "잔인하게",
  "톰의",
  "교장",
  "물질에",

  "약혼자를",
  "분입니다",
  "만족할",
  "샤랄라",
  "형제는",
  "다스리는",
  "랄프",
  "받길",
  "뿐이지만",
  "마이어스",
  "노이스",
  "캐고",
  "보석이",
  "수배",
  "냈는데",
  "붕대를",
  "정말이니",
  "실험에서",
  "교도관",
  "안되서",
  "목격자",
  "아씨가",
  "일시적인",
  "생겨서요",

  "후원",
  "죄의",
  "조지도",
  "전통",
  "보려고요",
  "캘리를",
  "허락되지",
  "데릭에게",
  "교육은",
  "브루클린",
  "마셔봐",
  "서약은",
  "가자구요",
  "넘어요",
  "알잖소",
  "때마침",
  "괜찮아졌어",
  "지나자",
  "황소",
  "하이드라가",
  "노트를",
  "마시며",
  "살인범으로",
  "생각해보자",
  "행복하다고",
  "돼가요",
  "확실하죠",
  "수신",
  "정옥씨",
  "비슷해요",
  "똑똑하다면",
  "촬영보",
  "햇빛",

  "알수가",
  "셋이서",

  "생쥐",
  "피리부는",
  "도란",

  "전화하라고",
  "엔리케",
  "와줘",
  "나눈",
  "우리들이",
  "조사에",
  "먹을거야",
  "인석아",
  "불과합니다",
  "알이",
  "내보내요",
  "보여주마",
  "재주도",
  "그만두세요",
  "데리구",
  "시집을",
  "카운티",
  "가정부가",

  "초를",
  "보낼게",
  "확인했어",
  "크로포드",
  "났고",
  "말해줬지",
  "돌리면",
  "함정에",
  "부를게",
  "가져오라고",
  "감방을",
  "동감이야",


  "부족해서",
  "붙는",
  "렉터가",
  "지문을",
  "질려",
  "헬기로",
  "데려오라고",
  "났죠",
  "썅년",
  "못하는데",
  "부탁한다",
  "난리를",
  "사건들을",
  "착오가",
  "지상의",
  "기계에",
  "있구",
  "헤일리는",
  "태도가",
  "나는구나",
  "잡혔다",
  "찾아왔지",
  "쥔",
  "깨졌어",
  "싶어한다고",
  "임상시험",
  "수고를",
  "모르긴",
  "해내면",
  "납작한",
  "웃지마",
  "아이랑",
  "고도의",
  "싯호칭",
  "이래뵈도",
  "가련한",
  "보내신",
  "운전하고",
  "에번스가",
  "그러든",
  "찾습니다",
  "할아버님",
  "지갑을",
  "굉장한데",
  "가게는",
  "닫았어요",
  "루푸스",
  "빠르군",
  "배달",
  "다니는데",
  "말했다면",
  "서비스가",
  "터져서",
  "다행이죠",

  "나에게도",
  "웃으며",
  "이제부터는",

  "용건이",


  "송아지",
  "잘됐지",

  "남았나",
  "얼",
  "근심이",
  "합의",

  "제니를",
  "창자가",
  "네놈의",
  "미워하지",
  "이라도",


  "않을래요",

  "창피해서",
  "떨어졌지",
  "걱정이나",
  "사촌이",
  "들러",
  "전이야",
  "필요하네",
  "영원하라",
  "파이를",
  "다정하게",
  "혀라",
  "구멍에",
  "마법에",
  "소용이야",
  "한국에서",
  "지점",
  "신전",
  "인정합니다",
  "작정인가",
  "보물은",
  "말했나",
  "사랑하는데",
  "싫다구",
  "국내",
  "로키가",
  "황군의",
  "지랄이",
  "경력",
  "되겠구만",
  "공기도",
  "실례하겠네",
  "맡아서",
  "엑시엄",
  "매튜",
  "진짜라고",
  "뭐혀",
  "알겠는데요",
  "만족해",
  "국민학교",
  "있어줄",
  "치자",

  "습관을",
  "홉스",

  "학생들",
  "에스핀",
  "끝났지",
  "엄마나",
  "그녀에겐",
  "루스",
  "경찰서",
  "배신했다고",
  "처음은",

  "미수",
  "태우는",
  "펄스",
  "생각했었는데",
  "뜻이오",
  "보호해야",
  "지금부턴",
  "뎁",
  "죽었나",
  "않으리",
  "것인디",
  "장식",
  "흉관을",
  "바람도",
  "사방이",
  "죄송하다고",
  "상담이",
  "두시오",
  "난리가",
  "이시다",
  "짓이오",
  "재수없게",
  "정중하게",
  "했는디",
  "캠프를",
  "이안은",
  "체이스",

  "스위치를",
  "세상도",
  "가져갑니다",
  "이름이야",
  "하찮게",
  "꾸며낸",
  "여자친구한테",
  "검정색",
  "이리와서",
  "공의",
  "독은",
  "예언이",
  "전화해줘",
  "배신",
  "기념",

  "찾으려",
  "있어야해",
  "해야되",
  "서방이",
  "신들이",
  "모스크바",
  "예의가",
  "색",
  "구출해",

  "모바일",
  "둘만의",
  "구원을",
  "수행하는",
  "명확한",
  "덫을",
  "선지자",
  "디자인",
  "통과하고",
  "비밀번호",

  "드려라",
  "얼마전",
  "조프리는",
  "가지곤",
  "급할",
  "에디슨은",
  "달시",
  "사실은요",
  "건물의",
  "것들의",
  "데나",
  "남잔데",
  "물고기를",
  "듣기로는",
  "오기전에",
  "믿지만",
  "알맞은",
  "사본",
  "날봐",
  "죽긴",
  "오코너",
  "하여",
  "자매들이",
  "들거야",
  "작품이지",

  "블론스키",
  "마주",
  "요들레이히",
  "오르면",
  "감시하는",
  "토끼가",
  "여기서요",
  "있는건지",
  "바쁘다고",
  "바라테온",
  "이대로는",
  "설득력",
  "정션",
  "카드로",
  "연습할",
  "도티",
  "집중하세요",
  "수표는",
  "뭐냐구요",
  "생리",
  "전화나",

  "벨벳",
  "작가는",
  "차례는",
  "어디서나",
  "몸값",
  "쫄딱",
  "그여자",
  "마음만",
  "가보라고",

  "져",
  "아니어도",
  "어떤걸",
  "애쓰지",
  "우회술",
  "얼굴이나",
  "해봤다고",
  "올라오면",
  "한적",
  "중요",
  "구해줄게",
  "취업",
  "그곳을",
  "감싸",
  "도대체가",
  "나올까",
  "속임수를",
  "조건으로",
  "테킬라",
  "비밀번호를",
  "둘러봐",
  "이기적이고",
  "밟으면",
  "오븐에",
  "출발하기",
  "헤어지기",
  "마루",
  "생각처럼",
  "남겼어",
  "그사람은",
  "부상은",
  "의대를",
  "살기를",
  "당신과는",
  "않았군요",
  "될만한",
  "목격자를",
  "운명에",
  "마지막엔",
  "용기에",
  "맞췄어",
  "엘리슨",
  "걸치고",
  "알았더라면",
  "예끼",
  "쿠키를",
  "개의치",
  "받았다고",

  "튄",
  "절정에",
  "있을래요",
  "나만이",
  "어쨌는데",
  "오프",
  "왔었는데",
  "말걸",
  "차고에서",
  "레지던트는",
  "걱정돼서",
  "노력하면",
  "체트",
  "애디",
  "소리니",
  "할멈",
  "조건",
  "아빠라는",
  "잘생기고",
  "사나운",
  "도착하고",
  "속인",
  "돈이요",
  "잊으려고",
  "어딜요",
  "주게나",
  "살도록",
  "떼어낼",
  "다치고",
  "막으려",
  "선생님에",
  "가로지르는",
  "주인은",
  "목욕을",
  "된다구",
  "스위치가",
  "가잖아",
  "부부를",
  "밭에",
  "여자들한테",
  "부하를",
  "힘내요",
  "우리중",
  "일하나",
  "소중해",
  "달려가서",
  "읍내에",
  "이웃에",
  "작자가",
  "피기",

  "퍼",
  "그러겠다고",
  "쏴서",
  "기차는",
  "에리카가",
  "싶었다고",
  "노크를",
  "해서든",

  "남부의",
  "어쨌다고",
  "헤어지면",
  "있수",
  "이러기야",
  "칠성아",
  "드리러",

  "부딪혔어요",
  "멍청히",
  "뺀",
  "누구에",
  "일어났다고",
  "집에서도",
  "게임이야",
  "시공간을",
  "피쉬가",
  "헌",
  "월급도",
  "저래라",
  "집중을",
  "전쟁으로",
  "감염의",
  "자세를",
  "웃기지마",
  "나타났잖아",
  "감동적인",
  "아냐아냐",
  "결투의",
  "리나가",
  "봉투에",
  "이때까지",
  "늙어가고",
  "생겨도",
  "장난이야",
  "당하기",
  "도넛",
  "동맥류가",
  "끝내줘요",
  "세번",
  "파블로",
  "만나보지",
  "적혈구",
  "때려서",
  "패치",
  "수고해",
  "강아지는",
  "그만둬야",
  "후에나",
  "철없는",

  "피해자인",

  "적지",
  "공연할",
  "헌트를",
  "꿀꿀아",
  "플리즈",
  "지도자",
  "주실수",
  "거면서",
  "말하려던",
  "비즈니스",
  "빠져나올",
  "입원한",
  "언급한",
  "공유",
  "찾자",
  "이내에",
  "규정",
  "그룹을",
  "슬프지",
  "돌아온다",


  "시도를",
  "대너리스",
  "무너진",
  "강간하고",
  "팔자야",
  "잘못했습니다",
  "설치해",
  "언급",
  "죽는게",
  "사랑스럽고",
  "그년은",
  "영리하고",
  "찾았잖아",
  "하나있어",
  "벌인",
  "수나",
  "농담해요",
  "아기와",
  "잘못이지",
  "귀여워요",
  "닿는",
  "공격했어",
  "시한",
  "받을수",

  "났소",
  "압박이",
  "아줌마도",
  "피셔를",
  "가족이라고",
  "그러믄요",
  "저랑은",
  "최고였어요",
  "울리는",
  "얽힌",
  "바싹",
  "즐거움이",
  "전화해도",
  "정성이",
  "순서를",
  "작별을",
  "질풍전은",
  "구룡이가",
  "나갔고",
  "빼내면",
  "용감하게",
  "보고싶어요",
  "무너지면",
  "각도로",
  "가슴으로",
  "전통이",
  "가슴에서",
  "해냈습니다",


  "후회하고",
  "한번의",

  "독종",
  "신호음",
  "가득했지",
  "차회",
  "준비했어요",
  "없습니다만",
  "암시랑도",
  "데브",
  "적어서",
  "생사의",
  "길바닥에서",
  "힘든지",
  "처리하지",
  "남은건",
  "그러겠어",
  "의사잖아요",
  "알프리드",
  "수술이야",
  "그만좀",
  "바이올린",
  "이런일",
  "해결책은",
  "영구적인",
  "동정심을",
  "늘고",
  "하는거요",
  "뭐였는데",
  "소식이네요",
  "받아들이기",

  "숙희야",
  "개랑",
  "시켰어요",
  "매력적이고",
  "과장으로",
  "오는게",
  "우리엄마는",
  "이짓을",
  "구해줘서",
  "미팅이",
  "보내자",
  "가기나",
  "전생에",
  "해적",
  "우는거야",
  "열렸어",
  "인턴들을",
  "저것들이",
  "마흔",
  "사바하",
  "와이프",
  "리무진",
  "보내도록",
  "경력에",
  "아기들을",
  "답게",
  "종이에",
  "돌아왔구나",
  "각오가",
  "시간이라고",
  "무엇이든지",
  "아멜리아",
  "책임져야",
  "서류들을",
  "형이야",
  "중고",

  "아으",
  "좋으니",
  "설명할게요",
  "커피라도",
  "거부",
  "에렛",
  "즉각",
  "보낸다고",
  "시작돼",
  "거인",
  "과학의",
  "게으른",
  "상이",
  "아니구만",
  "지구상에",
  "어렴풋이",
  "괜찮았지",
  "드래곤들을",
  "신속하게",
  "체포해",
  "행복이",
  "바위에",
  "지금이요",
  "준비될",
  "써봐",
  "공중에",
  "이엔",
  "놈에게",
  "확신을",

  "우물을",
  "어이가",
  "부탁했어",
  "기억해야",
  "드래곤이",
  "나갔는데",
  "심했어",
  "아무것도요",
  "나와줘",
  "멜의",
  "기절할",
  "아처는",
  "망쳐놨어",
  "캠프에",
  "똑같죠",
  "죽었지만",
  "호수에서",
  "발카",
  "꽂아",
  "당신입니다",
  "고치게",
  "결장",
  "연락해요",
  "너보고",
  "계속되는",
  "선장은",
  "노래에",
  "슬퍼하지",
  "닫아요",
  "이름은요",
  "연구하는",
  "콜슨은",
  "훌륭하다고",
  "슌",
  "영국인",
  "위험하죠",
  "먹는다",
  "정교한",
  "건드릴",
  "토요일은",

  "떨어졌습니다",
  "기침",
  "갑니까",
  "그랬거든",
  "잡아줄",
  "옳고",
  "중이셔",
  "가거든요",
  "금액을",
  "지내는지",
  "메마른",
  "민아야",
  "공상",
  "효자",
  "이끄는",
  "요법",
  "관할",
  "안나가",
  "현금을",
  "끊는다",
  "었어",
  "전쯤",
  "지켜주는",
  "사진에",
  "홀",
  "존중해",
  "방법이라고",
  "다음부턴",
  "록우드",
  "식물인간이",
  "칭찬을",
  "조조는",
  "명령입니다",
  "키라씨",
  "잘했다고",
  "붙지",
  "옥분",
  "수있을",
  "디아즈",
  "맞긴",
  "인순이가",
  "소리네",
  "때문이겠지",
  "알아봐야",
  "세라",
  "하시니",
  "스타일이야",
  "주류",
  "이름이에요",

  "크루가",
  "명령한다",
  "도망치라고",
  "망토를",
  "함께하지",
  "의사처럼",
  "아름답죠",
  "이유예요",
  "자체로",
  "정하지",
  "리가요",
  "용무가",
  "병신처럼",
  "뮤지컬",
  "피스톤",
  "들어온다",
  "찾아야만",
  "투여했습니다",
  "머핀",
  "스톰플라이",
  "같습니까",
  "중이라",
  "레이싱",
  "구실",
  "참아야",
  "미사",
  "유니폼을",
  "진행되고",
  "입니까",
  "나질",
  "김풍풍이",
  "질문이요",
  "국가가",
  "재건술을",
  "이해한다고",
  "그러도록",
  "홀에",
  "당했습니다",
  "계산대",
  "불길이",
  "놀라",

  "못됐어",
  "클라라는",
  "공장장",
  "대체로",


  "실망을",
  "이것의",
  "레니",
  "고거",
  "도망가고",
  "혈액형",
  "공개",
  "여성입니다",
  "하라고요",
  "알아채지",
  "헬로우",
  "정확해요",
  "息もできないくらい澱んだ人の群れ",
  "수준에",
  "필요하대",
  "알러지",
  "충돌을",
  "매혹적인",
  "회진을",
  "무리들",

  "팬이라고",
  "골목쟁이네",
  "잘못됐나요",
  "힘들다",
  "응답하세요",
  "끼쳐",
  "기는",
  "허큘리스가",
  "설득",

  "낮추고",
  "쉬지못할",
  "아프구나",
  "망쳤어요",
  "오라니까",
  "버텨요",
  "움직였어요",
  "혈액과",
  "모양이네요",
  "있었대",
  "씨에",
  "비장",
  "외치네",
  "호흡기",
  "眞夜中の詩が叫んだ",
  "수입",
  "알려줬어",
  "비용은",

  "은행이",
  "폐는",
  "후퍼",
  "깜박",
  "좋고요",
  "僕ほんとうは",
  "목적지",
  "듣질",

  "체내",
  "카카시",
  "쓰기",
  "당신같이",
  "내생각에",
  "체외",

  "에반",
  "원한이",
  "막강한",

  "あの日からずっと",
  "폭발을",
  "카테터",
  "보다도",
  "코뿔소",
  "창백해",
  "때문이고",

  "아프다는",

  "캘리한테",
  "아키텍트",
  "놓을게요",
  "풀려",
  "잘릴",
  "후진",
  "연극이야",



  "소문도",
  "서있어",
  "듣자",
  "씨앗을",
  "모르는게",
  "근무는",
  "무자비한",
  "농도",
  "자부심을",
  "보래이",
  "있었으니까요",

  "당시엔",
  "제멋대로",
  "차의",
  "날려버렸어",
  "네잣",

  "올까요",
  "넘치고",
  "바이람",
  "농담이었어",
  "웨딩",
  "놀라운데",

  "방법조차",
  "만들라고",
  "오백",
  "탱크를",
  "산다면",
  "출발을",
  "浮かぶ方法もないダイバ",
  "빌보",
  "놨으니까",
  "미네르바",
  "것만으로",
  "숙녀분",
  "실루엣",
  "죽이겠다고",
  "차리게",
  "자정이",
  "웃겼어",
  "고요",
  "장소로",
  "나세요",
  "거짓말했어",

  "분노로",
  "조심하는",
  "같겠지만",
  "모른다면",
  "비롯한",
  "중요하다",
  "부적",
  "착하구나",
  "컸네",
  "기무라",
  "뚜렷한",
  "의무",
  "끊을게",
  "활활",
  "내려다",
  "슬플",
  "들어본적",
  "연습하는",
  "이쪽이",
  "무지개",
  "권총을",
  "체포한",
  "보면은",
  "영수증",
  "오빠랑",

  "망치",
  "そこまで行けそうなら",
  "살인마",

  "라구요",
  "독립선언서",
  "지냈는데",
  "미안하군요",
  "도달",
  "수축이",
  "비행이",
  "십억",
  "생각했다면",
  "만나봤어요",
  "레지던트예요",
  "감소가",
  "해결하지",
  "단번에",
  "개념",
  "증명해봐",
  "놀던",
  "식의",
  "심박",
  "말하나",
  "이쯤",
  "차거",
  "있는건가요",
  "수술입니다",
  "아내에",
  "닉을",
  "기다리던",
  "생겼나요",
  "것이었어요",
  "들으셨어요",
  "봐야해",
  "크기가",

  "모킹제이가",
  "회사로",
  "새까만",
  "말하자",
  "데에는",
  "들어가시면",
  "일이라니",
  "고독한",
  "고그가",
  "모두요",
  "매력적이야",
  "경찰이에요",
  "않으시면",
  "되시는",
  "책이에요",
  "전사는",

  "피크",
  "저놈들이",
  "짜야",
  "말들은",
  "수치도",
  "귀도",
  "열도",
  "문제될",
  "테니스",
  "들어왔는데",
  "수현아",
  "중이잖아요",
  "에디슨이",
  "고려할",
  "씩",
  "드리려고요",
  "스티븐스를",
  "도왔어요",
  "강력하게",
  "고백",
  "법적인",
  "열쇠도",
  "이건가",
  "지상인을",
  "죽이는거야",
  "해보시지",
  "그루트",
  "일기장을",
  "찾아왔다",
  "하나지",
  "해결하게",
  "은근히",
  "보드",
  "재미도",
  "캣니스가",
  "걸렸을",
  "여쭤볼",
  "있으면요",
  "죠지를",
  "곡이",
  "너네한테",
  "친군데",
  "그만뒀어요",
  "대일본",
  "연결할",
  "생긴거",
  "지금부터는",
  "끝났지만",

  "복수심에",

  "창문은",
  "정해져",
  "내면서",
  "우리들에게",
  "에이전트는",
  "아니니깐",
  "너만이",
  "강릉",
  "들어왔지",
  "지배하는",
  "넘기고",
  "브라운씨",
  "충동적인",
  "소리하고",
  "사망했어요",
  "기능",

  "실력으로",
  "달러만",
  "중력에",
  "무덤까지",
  "조안나",
  "브로디",
  "찾으시는",
  "들렸어",
  "남자죠",
  "삶이야",
  "그렉은",
  "사람이니까요",
  "안단",
  "보내죠",
  "신입",

  "였죠",
  "부숴",
  "전설은",
  "부모의",
  "심장의",
  "아무때나",
  "대문",
  "없든",
  "외로워요",
  "비둘기가",
  "뇌로",
  "먹다가",
  "보물에",
  "동물의",
  "히틀러가",
  "무지개를",
  "어머니에",
  "갈비",
  "전설에",
  "부모님에게",
  "돌린",
  "있었네요",
  "깨물어",
  "내봐",

  "시켜라",
  "앤의",
  "야시오",
  "관점을",
  "여쭤",
  "드리기",
  "배신하면",
  "많았고",
  "흐름이",
  "싫든",
  "주황색",
  "충근이",
  "오죽하면",
  "쟤넨",
  "관두고",
  "교실에",
  "까고",
  "낼게",
  "다보스",
  "인사하는",
  "통하고",
  "놀랍네요",
  "무서울",
  "너무하네요",
  "공격한다",
  "규율을",
  "내주세요",
  "하슈",
  "괜찮으신가요",
  "살인자를",
  "이레네",
  "게시판",
  "해왔던",
  "시끄러워요",
  "만들었고",

  "애디슨과",
  "원하니까",
  "닦는",
  "폭발물",
  "계산을",
  "천사의",
  "자연히",
  "가족이잖아",
  "저녁으로",
  "당장이라도",
  "안녕하쇼",
  "진료",
  "고도",
  "치료하지",
  "먹을래요",
  "아픈가",
  "안길",
  "왔겠지",
  "척이라도",
  "레이놀즈",
  "참견하지",
  "그러시든가",
  "보일지",
  "젠장맞을",
  "자매를",
  "일거수",
  "하잖니",
  "필요로한다",
  "표는",
  "암만해도",
  "달아났어",
  "본능에",
  "의사선생님",
  "타시죠",
  "며칠째",
  "살아있다면",
  "여동생은",
  "알려줘야",
  "약속할",
  "중이었지",
  "도구",
  "저깄다",
  "눈치채지",
  "가유",
  "장기들을",
  "웬일이여",
  "영광이에요",
  "변호사님",
  "깨어있는",
  "싫잖아",
  "외국으로",
  "고향에서",
  "보내주면",
  "충수돌기",
  "열릴",
  "호칭을",
  "창자에",
  "안되나요",
  "심할",
  "클라우드",
  "코코",
  "뭣이여",
  "생각하잖아",
  "패드",
  "학이",
  "보라고요",
  "정신차리고",
  "애타게",
  "말씀이라도",
  "스탠포드",
  "하셨는데요",
  "거머리",
  "배신한",
  "뿌리를",
  "걱정할거",
  "판결",

  "스티븐스와",
  "제외하고는",
  "터지기",
  "해고할",
  "줄줄",
  "끼어들지",
  "말에서",
  "손목에",
  "벤저민",

  "고쳐주세요",
  "말해선",
  "분이시죠",
  "실체를",
  "설정",
  "뒤졌어",
  "떠나겠다고",
  "굉장해요",

  "인간이지",
  "놈만",
  "부에노스",
  "구해줬잖아",
  "성인이",
  "목록에",
  "남아있을",
  "어떻다는",
  "오늘날의",
  "거잖니",
  "파라오",
  "가르쳐줬어요",
  "앞만",
  "게이트",
  "입장에선",
  "세서",
  "몰랐소",

  "소는",
  "움직인",
  "완벽하군",
  "제시간에",
  "구역에",
  "당했지",
  "뉴욕을",

  "어려요",
  "사막에",
  "농사를",
  "준비하라고",
  "위로가",
  "패들을",
  "불렀을",
  "식사에",
  "닭은",
  "때문인가",
  "인내심이",
  "주님이",
  "포기했어요",
  "처리하죠",
  "부분이야",
  "분부대로",
  "공포로",
  "어딘데요",
  "훔치기",
  "중인가",
  "공황",
  "좋구",
  "나치라고",
  "들어갔습니다",
  "목격자는",
  "자매는",
  "참석",
  "행",
  "별로예요",
  "위해서예요",
  "사람이기",
  "노랠",
  "글루",
  "다음부터",
  "수행할",
  "있겠는데요",
  "주셔야죠",
  "남편이야",
  "말씀드리겠습니다",
  "잠자리는",

  "심고",
  "달래요",
  "필수",
  "필요할지도",
  "지기",
  "부르며",
  "향이",
  "장치도",
  "열게",

  "미식축구",
  "지켜요",

  "그르니까",
  "음주",
  "해산",
  "그분께",
  "안타깝네요",
  "신진대사",
  "그렇구요",
  "만났을때",
  "이상도",
  "봉합은",
  "혁명은",
  "꼼꼼히",
  "당신만큼",
  "발도",
  "되어가는",
  "어딘가로",
  "코는",
  "사망했다",
  "동반한",
  "인간이라고",
  "날이었어",
  "풍향이",
  "있겠지요",
  "버렸습니다",
  "담은",
  "담배도",
  "제니는",

  "취급하는",
  "퍼즐을",
  "살길",
  "새들이",
  "늦겠어",
  "건강하지",
  "우승하면",
  "알려드리죠",
  "그럴리가요",
  "방해한",
  "댐이",
  "용서해라",
  "올려라",
  "팬이에요",
  "그건가",
  "나쁘다고",
  "상관안해",
  "달도",
  "찾아보면",
  "따귀를",
  "달리기",
  "먹이고",
  "물건도",
  "티나",
  "딸꾹질이",
  "집이죠",
  "나비야",
  "슬퍼할",
  "생전에",
  "난폭한",
  "필요없지",
  "코드가",
  "화장도",
  "민우가",
  "들어야지",
  "생각하든",
  "대학은",
  "반기를",
  "시범",
  "간직하고",
  "죽어버려",
  "오늘처럼",
  "숨어있는",
  "보시고",
  "더불어",
  "이니까",
  "떠나질",
  "열어줘요",
  "놈인지",
  "형수가",
  "얘기하려고",
  "시뮬레이션",
  "성냥",
  "받던",
  "그때마다",
  "민우는",
  "가졌는지",
  "왠일이야",
  "민우씨가",
  "않는한",
  "크로스",
  "하겠다면",
  "체계를",
  "삼고",
  "불평을",
  "데릴이",
  "해킹했어",

  "오페라",
  "못했겠지",
  "내리라고",
  "아니던가",
  "여간",
  "찾았을",
  "연인은",
  "서신을",
  "팩스로",
  "고모를",
  "마는",
  "좋아한단",
  "좋아보이네요",
  "초대장을",
  "식탁",
  "생활도",
  "나가는데",
  "박고",
  "포주",
  "색의",
  "화살은",
  "나갔죠",
  "아는군요",
  "길준이",
  "가진걸",
  "돌보지",
  "고문하는",
  "이루는",

  "연결한",
  "막았어",
  "달러나",
  "기적이야",
  "인명",
  "합니다만",


  "졸업식",
  "오지는",

  "조금전",
  "거짓말에",
  "것쯤은",
  "마라톤",
  "오셨는데요",
  "프로그램된",
  "만나봐",
  "부친의",
  "열정적인",
  "깨울",
  "분이라고",
  "응응",
  "부족을",
  "꿰뚫고",
  "뭐하려고",
  "안되나",
  "천사처럼",
  "운전사가",
  "치울",
  "납치된",
  "각색",
  "에라이",
  "행사에서",
  "도울게",
  "하구나",
  "간호사들",
  "마셔도",
  "환장을",
  "동맹이",
  "공연히",
  "도로용",

  "가시고",
  "몰라서요",
  "갑작스럽게",
  "잠잘",
  "경주에",

  "사과하러",
  "당신네들은",
  "비포장",
  "저놈은",
  "파업",
  "흥미롭군",
  "데이트에",
  "수비대가",
  "찾아주세요",
  "아니긴",
  "멀었어요",
  "말하겠는데",
  "농약",
  "시간까지",
  "셋의",
  "중심으로",
  "청소부",
  "그들보다",
  "컨설트",
  "상황에서는",
  "가정은",
  "스티븐스요",
  "돌면서",
  "램프",
  "확인된",
  "품에서",
  "싸는",
  "헌혈을",
  "자유로운",
  "지켜주려고",
  "패",
  "새겨진",
  "사라졌고",
  "성난",
  "해순이",
  "큰일이다",
  "살어",
  "모텔",
  "숫자로",

  "발라리아산",

  "책임감을",
  "증가하고",
  "명령어",
  "수액을",
  "비켜봐요",
  "키우지",
  "미상",
  "바이탈이",
  "공기막이",
  "재는",
  "배제하고",
  "선수로",
  "상자는",
  "끈다",
  "하시게",
  "동기를",
  "찾는다",
  "승리하는",

  "기록된",
  "사회에서",
  "부러져서",
  "보내주신",
  "정신으로",
  "말인",
  "저거봐",
  "오죽",
  "뭣하러",
  "사실이지만",
  "떴어",


  "불평하고",
  "아름답군요",
  "에이브리",
  "먹었으면",
  "다니잖아",
  "생각인가",
  "달엔",
  "울타리가",
  "생명체들은",
  "간다니까",
  "데려왔을",

  "보니께",
  "직후에",
  "민우의",
  "한결같이",
  "시작하는거야",
  "일이여",

  "딱지를",
  "보란",
  "그러질",
  "아빠하고",
  "없는데도",
  "지금에",
  "구하게",

  "빌이",
  "만다",
  "알리나",
  "불난",
  "지시할",
  "심박동은",
  "보내라고",
  "운전수",
  "남자고",

  "어째서지",
  "여자에요",
  "거만",
  "팔과",
  "안정적인",
  "암호가",
  "지독하게",
  "진심은",
  "사진이나",
  "도구는",
  "소유한",
  "들어가십시오",
  "마릴린",
  "프레디",
  "그대로예요",
  "토레스에게",
  "미친년아",
  "벽도",
  "화면을",
  "믿는다는",
  "독일에",
  "하모",
  "오는거야",
  "선서를",
  "선생님이야",
  "해놓은",
  "찢고",

  "모델이",
  "태워줄까",
  "잡아먹어",
  "관심사가",
  "하더구나",
  "죽일려고",
  "뿐이었어",
  "가지요",
  "구글",
  "싶으니까",

  "냈지",
  "괜찮잖아",
  "머리카락에",
  "들것",
  "반지에",
  "엄마하구",
  "왔거든요",
  "부모님도",
  "딸아",
  "떨어진다는",
  "겁쟁이라고",
  "딜런이",
  "차림으로",
  "명령대로",
  "돌콧",

  "애완",
  "페퍼",
  "불행을",
  "얻었죠",
  "일반외과",
  "테니께",
  "말해주렴",
  "주도록",
  "평가할",
  "코바는",
  "햇빛을",
  "지어다",
  "영웅이야",
  "구덩이에",
  "간대요",
  "문좀",
  "시죠",
  "힘들겠어",
  "유용하게",
  "가라는",
  "안되니까",
  "가시겠습니까",

  "보상",
  "진흙",
  "포터",
  "비행기야",
  "갈건데",
  "고백할",
  "편해요",
  "셰퍼드의",
  "예쁘죠",

  "그랬지만",
  "치즈의",
  "흡입기",
  "늦어요",
  "오웬을",
  "싶은걸",
  "부품",
  "얘기해줬어",
  "지나간다",
  "아이고매",
  "리스트를",

  "다음에도",
  "놈이죠",
  "돼지야",
  "붙었어요",
  "셰퍼드와",
  "하강",
  "별루",
  "남자다운",
  "리즈는",

  "이메일로",

  "몸매",
  "즐기라고",
  "칭찬",
  "호출해서",
  "심기를",
  "스파키",
  "사랑하니까요",
  "확인중",
  "작아진",
  "빠져나오는",
  "살인하는",
  "시인",

  "신나",
  "것이란",
  "희망에",
  "누르지",
  "심실세동이야",
  "얻으려고",
  "그애에게",

  "우리나",
  "만졌어",
  "오질",
  "여행하고",
  "환상적이야",
  "필립을",
  "따라다녀",

  "중년의",
  "함정을",
  "말로만",
  "레이저",
  "공부할",
  "노래할",
  "차게",
  "이분을",
  "물려받은",
  "커닝햄",
  "눈밖에",
  "가리키고",
  "음식으로",
  "조심하십시오",
  "너라도",
  "줄이나",
  "투자한",
  "저러다",
  "않았음",
  "그래봐야",

  "면도도",
  "따라온",
  "계곡",

  "리사의",
  "이야기만",
  "장에",
  "주셨죠",
  "살인마가",
  "토론을",
  "생각나서",
  "슈가",
  "켤",
  "증오할",

  "적군의",
  "인류에",
  "말씀이지만",
  "도와드려요",
  "켜면",
  "그레첸",
  "제안하는",
  "보시겠어요",
  "방문하는",
  "빌어도",

  "패턴을",
  "짓을했는지",

  "영예를",

  "마크햄씨",
  "복장",

  "어린애야",
  "파티요",
  "파괴하는",
  "탯줄을",
  "무기력한",
  "가능하지만",
  "필요하다니까",
  "닮았구나",
  "죽네",

  "올라갈게",
  "못하실",
  "같더군요",
  "봤었어",
  "생각일",
  "점잖은",
  "창녀를",
  "까치",
  "없잖나",

  "장치는",
  "틀렸네",
  "획기적인",
  "살해된",
  "치우라고",
  "반갑구나",

  "다치게하지",
  "잘생겼어",
  "참석할",
  "식구들이",
  "짓이여",
  "셔먼은",

  "캔",
  "좋구요",
  "이웃들",
  "휙",
  "집이오",
  "만들려는",

  "확실한건",
  "소비에트",
  "식이",
  "마담이",
  "군주",
  "과학자",
  "중사",
  "됐다구",
  "감옥으로",
  "일하죠",
  "원체",
  "이군요",
  "비서",
  "원천을",
  "체첸",

  "넣었을",
  "어땠니",
  "들어가봐",
  "간다구",
  "시저를",
  "유인원",
  "늙다리",

  "식량이",
  "드리지요",
  "교수는",

  "다이",
  "누군갈",
  "행복했어",
  "미룰",
  "기대해도",
  "데만",
  "제거하면",
  "그러십니까",
  "부러워",
  "당신만의",
  "댄서",
  "드리리다",
  "재주는",

  "체력이",
  "놀랍도록",
  "들리지만",
  "사스케가",
  "되가고",
  "사교",
  "괴사",
  "어렵다",
  "스크린에",
  "겁주고",
  "여자애처럼",
  "무역",
  "동의했다",
  "좋다구",
  "힐에",
  "받겠어요",
  "않겠다는",
  "늦었지",
  "받을게",
  "아쉽네요",
  "필요없소",
  "전사를",
  "가령",
  "작품에",
  "탁월한",
  "충격적인",
  "아들이요",
  "코바",
  "용병",
  "프로그램되어",
  "향해서",
  "기억난다",
  "콜터",
  "출발합니다",
  "이야기해",
  "익명",
  "패턴",
  "져야",
  "저년이",
  "지루하지",
  "한푼",
  "우아하고",
  "주위",
  "전화기로",
  "일때문에",
  "관장을",
  "통제하지",
  "괜찮아지는",
  "끔찍하네요",
  "먹나",
  "실종자",

  "애칭",
  "구석",
  "구경을",
  "먹었나",
  "해주실래요",
  "슬퍼하고",
  "짓들을",
  "제거술",
  "재밌고",
  "플루토늄",
  "돼가는",
  "상태로는",
  "수분",
  "떠오르고",

  "쳐들어",
  "했잖나",
  "끌게",
  "그리던",
  "잡아와",
  "구스타브는",
  "나아가",
  "했습니다만",
  "확신하는",
  "사랑아",
  "예로",
  "로비에서",
  "돌아옵니다",
  "거울도",
  "총무부",
  "명옥이",
  "줄지",
  "속에는",
  "만났군요",
  "전쟁터에서",
  "낼께",
  "위원회가",
  "느껴졌다",
  "갑옷을",
  "사장한테",
  "압통이",
  "부르지도",
  "정확하지",
  "대처하는",
  "소유",
  "벗어봐",
  "날이고",
  "그레고르",

  "착해요",
  "문제에서",
  "선사해",
  "역시도",
  "재정",

  "끝내기",
  "필드",
  "토드",

  "하루라도",
  "자비로운",
  "다한",
  "영향이",

  "의의",
  "킹스랜딩의",
  "얻죠",
  "미행",
  "댄스를",
  "없다면서요",
  "내한테",
  "있는거잖아",
  "민간",
  "달라서",
  "가질수",
  "들리는지",
  "뭐하게",
  "학비를",
  "나이테가",

  "포어톨드의",
  "들려줘",
  "리차드가",
  "발끝까지",

  "모르시겠어요",
  "다른사람",
  "사랑이라면",
  "친구란",
  "팻시",
  "사귄다고",
  "하기는",
  "ㅠ",
  "차례로",
  "무엇이냐",
  "나하곤",
  "적이야",
  "보낸거야",

  "무슨일이지",
  "없었으니까",
  "다리야",
  "등지고",
  "인간들에게",
  "개두술",
  "바라티언",
  "이러지마세요",
  "뭍으로",
  "삼천리",

  "잡아봐라",
  "증인",
  "도련님을",
  "너니",
  "입대를",
  "있데",
  "아인슈타인",
  "했으니까요",
  "해볼래요",
  "레지던트로서",
  "발가락을",
  "살아남았어요",
  "캠프에서",
  "날이니까",
  "물으면",
  "복잡하고",
  "지나가",
  "싶었지만",
  "감염으로",
  "지붕이",
  "가졌고",
  "만들어내는",
  "맛있었어요",
  "줬잖아요",
  "모습이야",
  "기존",
  "올린",
  "치유될",
  "위험하긴",
  "가봤어요",

  "부탁하신",
  "가지가지",
  "가능하다",
  "오래는",
  "축하하는",
  "도련님은",
  "알립니다",
  "떨어지기",
  "태풍이",
  "배고프다",
  "분이고",
  "조카의",
  "이집",
  "독일이",
  "하고있어",
  "슬프다",
  "루피",
  "조니의",
  "아까운",
  "두고봐",
  "고통받는",
  "한것",
  "정말입니다",
  "살려준",
  "길입니다",
  "동물이",
  "해야겠군요",
  "상자에서",
  "비용도",
  "옥상",
  "부인에",
  "털어",
  "펜을",
  "출근해야",
  "워워워",
  "확신했다",
  "훅",
  "약속대로",
  "타우리엘",
  "클래식",
  "확인하십시오",
  "유죄를",
  "반도",
  "니콜라이",
  "황제",
  "말한건",

  "담당이야",
  "생각해봤어",
  "가져갈게요",
  "편의를",
  "사로잡힌",
  "그거이",
  "불평하지",
  "가시겠어요",

  "견인",
  "구요",
  "아이리쉬",
  "써주는",
  "저것들은",
  "마실게",
  "만나봤어",

  "존재에",
  "한적이",
  "주쇼",

  "없는거지",
  "추악한",
  "받았잖아",
  "혀가",
  "기침을",

  "청춘을",
  "패는",
  "할머니의",

  "재산이야",
  "환호",
  "바라보면서",
  "어떻다고",
  "분명하지",
  "경과",
  "매달아",
  "기다려도",
  "제외하곤",
  "감기가",
  "옷이나",
  "멋있네",
  "딸이다",
  "않았군",
  "실패했다고",
  "녹초가",
  "알렉스도",
  "번이",
  "부분입니다",
  "생각하시죠",
  "떠났습니다",

  "홀에서",
  "니말이",
  "도망쳤어",
  "것밖에",
  "정신병자",

  "상황이라고요",
  "되는구나",
  "셈이냐",
  "보이더군",
  "확실",
  "해보렴",
  "당신들도",
  "해야겠습니다",
  "남들은",
  "잉글랜드",
  "신체를",
  "들어오실",
  "비첸",
  "환자들에게",
  "해냈다고",
  "대답해야",
  "소아과에서",
  "대사는",
  "하느라고",
  "마음속에",
  "맥주도",
  "길이지",
  "약혼자",
  "수백명의",
  "여성에게",
  "희망의",
  "찾아냈어요",
  "참이었는데",
  "앞뒤로",
  "남자처럼",
  "뒀다",
  "간질",
  "잔다",
  "비교해",
  "했다면서요",
  "그런짓",
  "말씀하시면",
  "불면",
  "빨리해",
  "도통",
  "하신다",
  "하자고요",
  "애기야",

  "살까",

  "권리입니다",

  "얄카",
  "모자라서",

  "여기에도",
  "핏",

  "모자는",
  "초당",
  "이상하긴",
  "떠날거야",


  "찜",


  "앞엔",

  "카푸치노",
  "직감",
  "게로구나",
  "칼마인",
  "수녀",


  "들어오너라",
  "늑대는",
  "가져와야",
  "건설",
  "불었어",

  "있었다구",



  "매너",


  "퍽도",
  "에스파냐",
  "가자고요",
  "코로나",
  "하버드",

  "촬영이",
  "다니는지",
  "까요",


  "연락은",
  "오는군",
  "최고인",
  "엄마가요",
  "먹인",
  "답장",
  "보았고",
  "얼마지",
  "자동차가",
  "복장을",
  "도망가요",
  "모실까요",
  "벌게",
  "아크",
  "해줬잖아",
  "생각이나",
  "여신",
  "분야",
  "불구가",
  "울지도",
  "바가스는",
  "끊게",
  "소를",
  "따님과",

  "적에는",


  "더해",
  "불빛을",
  "좋거든",
  "하나라고",

  "마약이",

  "숫자에",
  "직감이",
  "사생활이",
  "창피한",

  "오거라",

  "시야가",
  "있을만한",
  "열기",

  "본부에서",
  "영사님",
  "디나는",
  "조작된",

  "받았는지",





  "녹화",
  "변화시킬",
  "자랐어",
  "되겠다고",
  "부인하고",
  "지휘를",
  "감방으로",
  "부위에",
  "비춰봐",

  "수잔은",
  "중요하니까",
  "계획하고",
  "봤다고요",
  "활기",
  "편견을",



  "보이도록",
  "남았잖아",
  "알기도",
  "쫓아다니는",

  "오마",



  "정의는",
  "타디스로",
  "내리실",
  "텀블러에",

  "끼는",
  "밤이었지",
  "반복되는",
  "폭풍우가",
  "코트니는",
  "마리는",

  "멕시코에",


  "팁도",
  "인연은",
  "협상은",
  "실종됐을",

  "살수가",
  "웃으면",

  "노래예요",
  "뵌",
  "투여해요",
  "휴가는",
  "흥분해서",
  "보관해",
  "별말씀을요",
  "테디와",
  "상담은",
  "먹었을",
  "필요하대요",
  "닫혀",
  "포효",
  "치료의",
  "하틀리",
  "시계탑을",
  "소품",
  "없겠니",
  "치유를",
  "아덴",
  "의논할",
  "변했네",
  "풍요의",
  "나만큼",
  "얼음이",
  "맡아요",
  "소유하고",

  "맞았네",


  "아카츠키를",
  "했을텐데",
  "떼라고",
  "빌지",
  "메이브가",
  "전이에요",
  "빼내고",
  "우수한",
  "벌일",

  "버번",
  "빨라서",
  "가져가도",
  "돌아간",
  "진행이",
  "하늘색",
  "접근해서",
  "취직을",
  "결과입니다",

  "행복했던",
  "놀게",
  "경찰이나",
  "음식에",
  "엄마에겐",
  "눈깔",
  "헤",
  "싶었거든요",
  "인간들의",
  "신데렐라",
  "클라라가",
  "갈라진",
  "멋진데요",
  "연장",
  "그랑",
  "들이쉬고",
  "그러자고",
  "미안할",
  "오시지",
  "팬케익",
  "파리를",
  "계시나요",


  "탈출한",
  "최주사",
  "발명한",
  "손님의",
  "싫더라",
  "형제야",
  "말해야겠어요",
  "스트라이커",
  "안정장치를",
  "군체의",

  "쳐박혀",
  "끝장을",
  "허지",
  "제거반이",
  "좋았고",
  "솜씨를",
  "꼬리를",
  "뿔",

  "여자입니다",
  "내리",
  "여자분",
  "예수님의",


  "사업이",
  "차원으로",
  "그렇구만",
  "당신네들",

  "출구는",
  "장사를",
  "이자",
  "출혈로",

  "여기다가",
  "순수하고",
  "주를",
  "오토바이를",
  "싶은지",
  "시작이군",
  "살아있어야",
  "지점을",
  "정맥을",
  "가슴과",
  "설명할게",
  "좋겠는데요",
  "올렸어요",
  "인터넷도",
  "제대",
  "말들",
  "섬은",
  "제법인데",
  "났을",
  "인생과",
  "자네만",
  "기울이고",
  "애들한테도",
  "이동하자",

  "끝내지",
  "붙잡아요",
  "해두지",
  "할아범",
  "그럴께",
  "보인",
  "청년이",
  "맞기",
  "돌아가시면",
  "허구",

  "편이죠",
  "죄라고",
  "낼거야",
  "셋은",
  "생각하자",
  "캘빈",
  "자살하고",
  "그와는",
  "안녕하셨어요",
  "메리가",
  "다뤄",
  "이상적인",
  "도와줘라",
  "파커가",
  "어떻겠나",
  "있을꺼에요",
  "도와줄께",
  "상황에서도",
  "이라크",
  "리신",
  "좋을까요",
  "사고에",
  "정희를",
  "이리와라",
  "토니는",
  "뻗은",
  "쭉쭉",
  "해봤죠",
  "생일이에요",
  "무를",
  "받았잖아요",

  "대답해봐",
  "이기기",
  "방이야",
  "대가로",
  "넘기면",
  "스노우는",
  "조절하는",
  "수작을",
  "자둬",
  "흠이",
  "로라를",
  "종양의",
  "파일에",
  "여기까지야",
  "알아냈나",
  "뭔짓을",
  "크롤리",
  "죽겠다고",
  "믿는다고",
  "신부에게",
  "선뜻",
  "코로",
  "호수에",
  "미행을",
  "보겠어요",
  "있다는군",
  "카버",
  "방송에서",
  "활성화",
  "맹렬히",
  "좋아하던",
  "달라지지",
  "모르기",
  "공연에",
  "도른",
  "말보다",
  "형님도",
  "화면에",
  "이자를",
  "솔로",
  "외과의사들은",
  "간호사는",
  "프랭키는",
  "너만큼",


  "진정시켜",
  "크래쉬",
  "서장님을",
  "고장이",
  "욕망에",
  "있더라고요",
  "당시에는",
  "헬리콥터가",
  "못했을거야",
  "우승자가",
  "테스트는",
  "더이상은",
  "잠깐이라도",
  "겉보기엔",
  "초록",
  "변함이",
  "쭈욱",
  "그걸로는",
  "재즈",
  "기증",
  "뇌종양이",
  "배꼽을",
  "잠자리",
  "오래됐어요",
  "보이려고",
  "뿌리고",
  "바꿔요",
  "열상",
  "돼지새끼",
  "내려고",
  "미사일이",
  "기존의",
  "소금을",
  "내주고",
  "얘기에",
  "이만큼이나",
  "문제있어",
  "피터의",
  "안그런가",
  "쓸데없이",
  "속도와",
  "찾아갈",
  "믿으셔야",
  "무렵",
  "낫잖아요",
  "헬프",
  "나오시오",
  "지지를",
  "아는거",
  "나오잖아",
  "알았단다",
  "범인으로",
  "넘어가지",
  "토니가",
  "데이브는",
  "하마터면",
  "변태야",
  "막을수",
  "주차장에서",
  "온도를",
  "뼈는",
  "미쳤구나",
  "진한",
  "만들지마",
  "라벨",
  "일자리가",
  "걸리겠지만",
  "안할꺼야",
  "자신만",
  "거짓말하고",
  "살인사건",
  "흠씬",
  "잡을거야",
  "해변이",
  "노력할게요",
  "케이틀린의",
  "해주시죠",
  "그렸어",
  "주무셨어요",
  "공격에",
  "캐피탈",
  "옮겼어요",
  "내쫓아",
  "대통령은",
  "않던가",
  "밥에",
  "믿은",
  "겠구나",
  "깃발을",
  "남과",
  "섹스하면",
  "어디있죠",
  "투쟁을",
  "문자가",
  "세계적으로",
  "하수",

  "있으면서도",
  "꽃도",
  "유닛",
  "보이니까",
  "섹스만",
  "자수를",
  "안경이",
  "듣고있어",
  "복용",

  "느끼면",
  "말했네",
  "불여시",
  "현명하게",
  "실수하지",
  "바퀴를",
  "했을지",
  "좋아할거야",
  "왔다면",
  "비서가",
  "우찌",
  "체포된",
  "고든이",

  "카드도",
  "성실한",
  "척해",
  "약속의",
  "쉴드에",
  "넣으려고",
  "룸",
  "달렸죠",
  "옳다는",
  "차례에요",
  "되더라",
  "바뀌게",
  "아줌마야",
  "상태의",
  "고요한",
  "해보게",
  "끈을",
  "내밀어",
  "받았네",
  "검사결과가",
  "훌륭하네",
  "증오를",
  "알고있죠",
  "왕자는",
  "보려",
  "제보를",
  "에미가",
  "일한다고",
  "지루하고",
  "복사",
  "빼서",
  "앞에서요",
  "배우면",
  "열라",
  "공기와",
  "이유만으로",
  "구린",
  "버렸네",
  "좋았는데",
  "지치고",
  "거래가",
  "돌아가셨을",
  "아니겠지요",
  "줘야해",
  "말씀드리자면",
  "낫고",
  "국장님이",
  "않는가",
  "보라는",
  "주러",
  "집중하는",
  "애미가",
  "탁구",
  "봉사하는",
  "진부한",
  "못했다는",
  "참여해",
  "외로움을",
  "성한",
  "구로카와",
  "타겟이",
  "주차",
  "수술만",
  "잡아다가",
  "기증을",
  "닫아라",
  "여행에",
  "거세요",
  "뵙도록",
  "파악해",
  "티키가",
  "일치",
  "들지도",

  "떡을",
  "않았단다",
  "그후로",
  "피라미드를",
  "영군님",
  "예거",
  "생각할지",
  "교회의",
  "허세를",
  "트랙",
  "네덜란드",
  "출발했던",
  "과거도",
  "있겠는가",
  "나와도",
  "곤잘레스가",
  "사장을",
  "않았단",
  "아니랍니다",
  "뇌도",
  "식으론",
  "자이양은",
  "끊어진",
  "임상시험을",
  "개발",
  "없애기",
  "빼낸",
  "사과가",
  "꼭이요",
  "공격으로",
  "키어스틴",
  "셰퍼드에게",
  "상에서",
  "전투에",
  "후퇴",
  "연결하는",
  "똑같잖아",
  "보고할",
  "확률의",
  "쇼핑몰",
  "베이컨",
  "거라며",
  "인민의",
  "휴전이",
  "붐",
  "시트를",
  "내린다",
  "태어났어요",

  "영광이",
  "축제가",
  "위층에서",
  "원수로",
  "전부라고",
  "맞니",
  "유혹",
  "유리한",
  "있기에",
  "어떤거",
  "이겼지",
  "안했다고",
  "부르셨습니까",
  "보내긴",
  "내놓을",
  "모방범이",
  "너희들한테",
  "부모한테",
  "살아남았어",
  "쿨한",
  "생각하는건",
  "올라갔어",
  "방정식을",
  "주더라",
  "지붕에",
  "준비시켜",
  "쉬잇",

  "알아내려고",
  "약속하는",
  "알겠다고",
  "안부나",
  "하드웨어를",
  "있겠느냐",
  "좋으냐",
  "발전기",
  "받지도",
  "한테는",
  "세척",
  "모양이오",
  "생각하기에",
  "쉽고",
  "교란",
  "주무시고",
  "척을",
  "이래로",
  "나라와",
  "살인자의",
  "부수지",
  "불편해",
  "복도로",
  "발목에",
  "불타는고구마",
  "망정이지",
  "않았냐",
  "빨리들",
  "조사가",
  "길다",
  "셀든",
  "답답해서",
  "버렸는데",
  "일어설",
  "놀랐지",
  "항공",
  "드라블로",
  "클리닉으로",
  "플리",
  "서울까지",
  "끔찍했어",
  "말씀이에요",
  "자질을",
  "자는게",
  "낄래",
  "이러기",
  "위생병",
  "보십쇼",
  "눈부시게",
  "작용을",
  "기원전",
  "눈물은",
  "침투",
  "확인했다",
  "확보",
  "아들이라고",
  "이번만큼은",
  "쩔어",
  "농담해",
  "카밀은",
  "장난친",
  "상사랑",

  "주치의고",
  "암살을",
  "결혼했다",
  "깊어",

  "통화했잖아",
  "메간",
  "음악도",
  "가망",
  "노력했다",
  "도착한다",
  "뭐든간에",
  "느껴질",

  "있었을거야",
  "인턴이요",
  "로맨스",
  "배웠습니다",
  "집어쳐요",
  "알리",
  "취소했어요",
  "상처와",
  "조이는",
  "간호원",
  "끊겼습니다",
  "대열을",
  "설득해서",
  "소속",
  "하디",
  "느끼면서",
  "다뤄야",
  "군인은",
  "그랬단",
  "팬들이",
  "구석에서",

  "쪼그만",
  "콘돔을",

  "선두",
  "아니타",
  "거짓이",
  "마시",
  "삼일",
  "알아냈습니다",
  "비락",
  "고마울",
  "빨치산의",

  "깨물고",
  "교정",
  "슬퍼요",
  "떼요",
  "티켓을",
  "차라도",
  "양으로",
  "걸릴까",
  "분명하다",
  "안에요",
  "특별하게",
  "실패하지",
  "않고서는",
  "했다네",
  "아메리칸",
  "취미는",
  "도와주실래요",
  "저러면",
  "소리질러",
  "가리는",
  "최근엔",
  "타기",
  "바보지",

  "보내셨어요",
  "될꺼에요",

  "차와",
  "그렇지는",
  "사람마다",
  "손의",
  "들어갈까",

  "일생동안",
  "들어갈래",
  "하이파이브",
  "인간에",
  "활동이",
  "구석구석",
  "차들은",
  "이상하죠",
  "결함이",
  "박살낼",
  "시간이죠",
  "받겠죠",
  "오스본의",
  "전화기는",
  "버즈",
  "사람인가",
  "프랑스로",
  "시작될",
  "팔려고",
  "말하는거",
  "몫이",
  "모였습니다",

  "두목이",
  "그로부터",
  "달콤하고",
  "밀키는",
  "이곳엔",
  "들라",
  "주사기를",
  "도망쳐야",
  "짜식이",
  "신분이",
  "미남",
  "보일이",
  "의미한다",
  "시작으로",
  "일기를",
  "이식은",
  "그인",
  "이름이나",
  "방식이지",
  "그것만으로도",
  "말라고요",
  "방사능을",
  "우측으로",
  "했어도",

  "배웠지",
  "케네디",
  "들러리가",
  "그걸론",
  "맞았잖아",
  "왔습니까",
  "않을거다",
  "있어줄래",
  "노력한",
  "이동해",
  "깨서",
  "식당을",
  "사실이니까",
  "웨스트에",
  "들어보면",
  "알아내기",
  "한눈에",
  "오셨을",
  "넘어선",
  "가봐도",
  "명령하는",
  "꿀을",
  "긍께",

  "느껴봐",
  "확인이",
  "어떤놈이",

  "모르거든",
  "지저분하게",
  "놈들아",
  "재밌는데",
  "흘렸습니다",
  "관계로",
  "있다더군",
  "인사로",
  "콴토니움을",

  "살아있음을",
  "통신도",
  "인민군이",
  "중이래요",
  "웨이터",
  "돕기로",
  "준대요",
  "얘기라",
  "제대한",
  "내꺼",
  "살리지",
  "갔다올게",
  "가드라고",
  "전부입니다",
  "어쩔려구",

  "내버려두고",
  "약물이",
  "어머니랑",
  "까다로운",
  "코트니가",
  "브룩",
  "무서워하고",
  "물속에",
  "굴려봐",
  "실수라도",
  "다름이",
  "이동무",
  "편찮으세요",
  "홈",
  "만났다고",
  "봉합사",
  "고와",
  "식민지",
  "안할래요",
  "끝났네요",
  "기다리렴",
  "팁을",
  "쓴거야",
  "사건도",
  "사방에서",
  "거쳐",
  "셀리나",
  "나위",
  "불공",
  "같겠지",
  "만들었군",
  "우산을",
  "접시를",
  "쏘려고",
  "강철로",
  "인간인",
  "도나",
  "죽였소",
  "알아들을",
  "좋아하니까요",
  "가까이서",
  "흘리지",
  "한가지는",
  "평소엔",
  "어서가",

  "가질래",

  "시작되기",
  "님께서",


  "위생",
  "대로만",
  "수작이야",
  "뒤면",
  "만화",
  "먹었네",
  "기자들이",
  "가르쳐주세요",
  "독일에서",
  "핵심이",
  "소외",
  "통해요",
  "나오는게",
  "들어줘요",
  "비추는",
  "데릭에",
  "매니저",
  "달려갈",
  "물어보는데",
  "경찰국",
  "말할지",
  "그러잖아요",
  "쟈가",
  "조절",
  "썩고",

  "랜드리스양",

  "레버를",
  "됐겠지",
  "괜찮다구",
  "쳐다본",
  "미래입니다",
  "장님",
  "예민한",
  "상병님",
  "요청했어요",
  "눈이야",
  "쿡",
  "생각이네요",
  "관찰을",
  "망신",
  "태어나자마자",
  "쪽이에요",
  "받다니",
  "안식을",
  "절차는",
  "운전해서",
  "두개가",
  "수고했소",
  "물리칠",
  "세리자와",
  "위안을",

  "것이었습니다",
  "대피",
  "꼬마를",
  "말끔히",
  "죽여야만",
  "덕분이죠",
  "진정제",
  "복수할",
  "떠나기로",
  "질린",
  "ㅗ",
  "저장",

  "조종",
  "실수들을",
  "섞인",
  "포남루",
  "말래",
  "겔러",
  "내부의",
  "리나와",
  "라몬트",
  "기타미드겔러리",
  "반갑지",
  "군용",
  "지혜를",
  "중앙에",
  "와이",
  "코스모",

  "멀쩡하고",
  "손가락은",
  "안절부절",
  "라다",
  "네코반",
  "그런짓을",
  "내리네",
  "폭풍의",
  "식도에",
  "시키신",
  "저년",
  "누워있는",
  "멀쩡한데",
  "생활의",
  "덴트",
  "되찾고",
  "결정하기",
  "도덕적으로",
  "침묵",
  "초대칭",
  "발생했어요",
  "죽치고",
  "아프가니스탄에서",
  "있으라구",
  "닿지",
  "종자",
  "날이면",
  "엉덩이는",
  "오늘밤엔",
  "되셨습니까",
  "라지",
  "예루살렘은",
  "캐쉬",
  "곧잘",
  "세실리아가",
  "홈즈의",
  "나야말로",
  "그만두면",
  "인원을",
  "우리엄마를",
  "귀환",

  "줘야만",
  "작업할",
  "떠나라고",
  "아버님도",
  "기술이지",
  "침착히",
  "몇주동안",
  "영양소가",
  "양파",
  "물러서라",
  "해봐야겠어",
  "엘사가",
  "전화해봐",
  "경로에",
  "상기시켜",
  "가까워지고",
  "하기전에",
  "성당",
  "흉터는",

  "무시하세요",
  "초대했어",
  "순간엔",
  "했던거",
  "죄와",
  "신께선",
  "전해드립니다",

  "있잖냐",
  "교수님은",
  "파자마",
  "거로군",
  "나리의",

  "넘지",
  "남산리",
  "맹세한다",
  "원하지만",
  "모셨습니다",
  "있는거고",
  "둘러싼",
  "가벼워",
  "두는게",
  "일을했다",
  "예기치",
  "낳았어요",
  "일찌감치",
  "병신은",
  "빼면요",
  "기억하죠",
  "막판에",
  "이해한",
  "하노",
  "열리지",
  "생각했었다",
  "왕좌의",

  "무섭냐",

  "작아요",
  "깨지면",
  "비타민이",
  "난실",
  "아카츠키",
  "안쪽으로",
  "사막에서",


  "좋아보이네",
  "델고",
  "치워야",
  "저녀석은",
  "버티는",
  "수학을",
  "항암",
  "그리지",
  "선생님하고",
  "어쨋거나",
  "받았지만",
  "형님한테",

  "빠져나오고",
  "외곽",
  "움직임은",
  "저여자",
  "펄리",
  "남자친구랑",
  "영국은",
  "벌면",
  "말게나",
  "주엔",
  "오래동안",
  "메리는",
  "끝났죠",
  "만남",
  "쿨하게",
  "띄게",
  "사이였어요",
  "발견하기",
  "돌아다니면서",
  "겉으로는",
  "녀석과",
  "참는",
  "찬스",
  "할리우드",
  "찾아봐요",
  "노만",
  "바이킹",
  "김혜경",
  "억",
  "대행",
  "올지",
  "명예는",
  "저지르는",
  "되겠지요",
  "신시아",
  "추",
  "꾸물대지",
  "잊기",
  "천벌을",

  "그렇다네",
  "찼고",
  "겠군",
  "피해가",
  "파악",

  "석유",
  "어쨌어",
  "언제는",
  "맡겨야",
  "껍질",
  "승리입니다",
  "점순이",
  "임박",
  "스티븐스의",
  "경성",
  "그러기로",
  "웬일이니",
  "자격은",

  "터졌어",
  "요트를",
  "신입이",
  "참치",
  "말라깽이",
  "반장",
  "보겠지",
  "기다리겠습니다",
  "고맙긴요",
  "아침으로",
  "통틀어",
  "사막으로",
  "항생제가",
  "선생님이랑",
  "제레미",
  "찍혀",

  "죽어야만",
  "아직이야",
  "뷰티",
  "들으니까",
  "파티야",
  "씨야",
  "상길",
  "엄숙한",
  "외박",
  "힘차게",
  "마크와",
  "보여서요",
  "보다니",
  "드레스에",
  "보라야",

  "공연은",
  "리오",
  "베인브리지",
  "파도를",
  "마법은",

  "옷만",
  "장기간",
  "어땠을까",
  "패배자",
  "가졌지",
  "버려서",
  "아저씨들은",
  "서에",
  "훨",
  "기억하시죠",
  "홈즈와",
  "단체로",
  "공격합니다",
  "골짜기",

  "바다와",
  "과하게",
  "거절했어",
  "징계",

  "뜯고",
  "주신다면",
  "명희",
  "우승자는",
  "울부짖는",
  "절제를",
  "쉽죠",
  "된다니까요",
  "마그네슘",
  "묻었어",
  "진료소에",
  "저희들은",
  "응당",
  "라구",
  "내려라",
  "빠져나가는",
  "만난다",
  "작업이야",
  "거실에서",
  "환각",
  "방과후에",
  "피난처를",
  "정중히",
  "토레스랑",
  "사이몬은",

  "돌봐주는",
  "여인을",
  "핍",
  "몇시간",
  "환경이",
  "악당들이",
  "오줌도",
  "보호받고",

  "자녀",

  "신만이",
  "문이요",
  "핑계",
  "착하다",
  "요리가",
  "먼데",
  "게이지",
  "베넷",
  "건으로",
  "아니라네",
  "광장에",
  "티리온을",
  "소중함을",
  "백발",
  "동생인",

  "가지신",


  "피임약을",
  "피클",

  "건널",
  "작동시켜",
  "년째",

  "아편",
  "냈습니다",
  "오래전부터",
  "운도",
  "놀아도",
  "걸어가고",
  "무전기",
  "틀렸다면",
  "수십만",
  "현관",
  "물리학자들이",

  "맞으시죠",
  "자넷",
  "어떻겠소",
  "있다면야",
  "금지합니다",
  "감사하지만",
  "숨어있어",

  "움직이기",
  "캘리에게",
  "무슨일인지",
  "뭐랬지",
  "가르쳐야",
  "페퍼에게",
  "카메라로",
  "후보가",
  "생각한다구요",
  "인간처럼",
  "배치",
  "나에게서",

  "나오니",
  "시야에",

  "딸인",
  "한술",
  "국장",
  "시체에서",
  "똑똑하다고",
  "극복할",
  "변명할",
  "작작",
  "배역",
  "대기해",
  "존중하고",
  "술로",
  "빼앗긴",
  "드리도록",
  "마드리드",
  "우주선에",
  "단순하게",
  "신들은",
  "입원했어요",
  "데려올게",
  "힘들었지",
  "코트를",
  "고담에서",
  "벌려서",
  "힉스입자가",
  "제수씨",
  "똑똑하군",
  "우회전",
  "전부였어",
  "전설의",
  "성벽",
  "대사가",
  "대사를",

  "의뢰인이",
  "본부를",
  "중단하라",
  "윈터펠을",
  "못했구나",
  "리키",
  "빔이",
  "작위를",
  "녹음기",
  "제목은",
  "수수께끼는",
  "장담컨데",

  "알았을까",
  "이익을",

  "쓰인",
  "증오와",
  "메모",


  "알겠는가",

  "팔코니는",
  "세심한",
  "부터요",
  "리나는",
  "환자나",
  "유전적",
  "터미네이터",

  "성장을",
  "죽여버릴꺼야",
  "엇",
  "약점은",
  "공장을",

  "맞는데요",
  "궁금할",



  "여자다",


  "망했네",
  "자세로",
  "외로워서",


  "대인",
  "만들었단다",
  "지뢰를",
  "넘쳐",
  "재미있고",
  "둬라",
  "끝날거야",
  "세탁물",
  "꿈쩍도",
  "살긴",

  "궁극적인",
  "정문으로",

  "잡죠",
  "죽였으니",
  "공간은",
  "거랍니다",
  "되돌리고",
  "나쁜놈들",

  "코너가",


  "피쉬를",
  "생포해야",

  "공격하라",
  "딸에",
  "뻔한데",
  "의사지",
  "고르면",


  "튜브",
  "빈손으로",
  "해드릴까요",
  "수고가",


  "풍기는",

  "주는지",
  "금고가",
  "줄께요",
  "재미있네요",
  "단속",
  "성경",

  "투자",
  "오르거나",
  "ㅁ",
  "청소년",
  "브록",
  "장인어른",
  "예측",
  "경비대를",


  "지수카",
  "카이",

  "할거고",
  "못하나",
  "무릎도",
  "입었고",
  "있나보군",
  "먹었죠",
  "면접을",
  "잡힌다",
  "개인의",
  "입원",
  "아아아",
  "앉아있어",
  "파렐",
  "유령을",

  "개에",

  "라마는",
  "시타를",
  "힉스가",
  "숲속에서",
  "필요없을",
  "보냈나",
  "동물들이",
  "라마의",
  "달라니까",
  "가라구",
  "지켜만",
  "인질을",
  "따분한",
  "했던게",
  "간호사랑",
  "제생각엔",
  "해고를",
  "전세계에",
  "레이첼을",
  "몇명이나",
  "원숭이를",
  "아비를",
  "실수하는",
  "뜻하지",
  "혹독한",
  "없었",
  "순경",
  "됬죠",
  "말했잖나",
  "물고기는",
  "글이",
  "틀렸다는",
  "어느정도",
  "좁아",

  "딱한",
  "지나치지",
  "닮았네",
  "들어가죠",
  "금은",
  "문제점을",
  "겠습니다",
  "노인과",
  "하느님께서",
  "기리는",
  "잘못이죠",
  "먹니",
  "문서",
  "중부",
  "무기야",
  "싶으실",
  "외부의",
  "즐겁지",

  "드리게",
  "새우털",
  "피하지",
  "저새끼",
  "창조주님의",

  "위에선",

  "억지",
  "빛에",
  "에릭을",
  "릴리를",
  "간다고요",
  "방패벽",



  "켜져",
  "부처님이",
  "의심할",
  "베티는",
  "베티가",
  "나리를",
  "달린은",

  "마련해",
  "기력이",
  "건다고",
  "발견이",
  "여겨",
  "기절했어요",

  "몰랐군",

  "꽂으면",
  "얘기했는데",
  "추론",
  "트레일러는",
  "베라를",
  "별장을",

  "돌려야",
  "야외",
  "빠졌다",
  "데이타가",
  "마쇼",
  "우편",
  "빔을",

  "생각밖에",
  "희생할",
  "따르겠습니다",
  "그에게서",
  "제지",
  "판단이",

  "부를까",
  "명복을",
  "세는",

  "이르게",
  "싫었을",
  "우연도",
  "혁수",
  "펄",
  "찾아오겠지",

  "돼라",

  "컴",
  "안타까운",
  "전문가는",
  "입었던",
  "무덤이",
  "새디가",
  "났던",
  "사촌을",

  "너머에",
  "우크라이나",
  "분야에",
  "낫겠죠",
  "금으로",
  "토비는",
  "왕위를",
  "불렀는데",
  "리드를",

  "올려줘",
  "도타는",
  "살려낼",
  "편으로",
  "일하세요",
  "당신에게도",
  "거였소",

  "예수는",

  "좋을것",
  "말해주게",
  "위로해",
  "저지르면",
  "중심은",
  "웨버는",
  "열네",
  "자신한테",
  "알아야죠",
  "가족이에요",
  "문제들을",
  "작년의",
  "연세가",
  "염소가",
  "사는건",
  "일어나라고",
  "바닐라",
  "흥미롭네",

  "답",
  "싫대요",
  "작년엔",
  "좆까",
  "떠올라",

  "아이카와의",
  "저희에겐",
  "막장",
  "지나야",
  "단독수술",

  "됐던",
  "보는지",
  "비웃을",

  "있다는거야",
  "했는지는",
  "트라스크를",
  "마로니가",
  "화나면",

  "당했고",
  "저와는",
  "끼어들고",


  "기술도",
  "생긴거야",
  "내려와요",
  "놓치는",
  "데이비스",
  "되었습니까",
  "인구가",
  "설명해줄게",
  "댓가를",
  "떠났는지",
  "정을",
  "생생하게",
  "차돌아",
  "차례입니다",
  "계산은",
  "들어볼까요",
  "알려드리고",
  "군의",
  "안될것",
  "범행",
  "주유소",
  "난다는",
  "프로젝트에",
  "그녀에게서",
  "앉으면",
  "수술인데",
  "정보도",
  "사라져야",
  "미친놈이",
  "뭐하는데",
  "입었습니다",
  "만큼만",
  "구성",
  "꽂힌",
  "소송하고",
  "매니저가",
  "하는거고",
  "전화했더니",
  "했든",
  "그룹에서",
  "정밀",
  "산부인과에",
  "부처는",
  "소질",
  "맡아야",
  "그랬대",
  "케일럽을",
  "스님은",
  "가는군",
  "태도를",
  "남들과",
  "일어나든",
  "알아봐요",
  "잊혀진",
  "밀려",
  "메이가",
  "정했어",
  "있는한",
  "워디",
  "반역을",
  "마피아",
  "대릴",
  "섹스의",
  "맘은",
  "오자고",

  "알아볼께",
  "사주고",
  "전에는요",
  "살림을",

  "스카이가",
  "부처가",
  "소대",
  "크리스는",
  "잡아낼",
  "주겠니",
  "나왔을",
  "사람들만",
  "응답이",
  "아브라함",
  "따지는",
  "매일매일",
  "가려구",
  "보험을",
  "응괴를",
  "벨소리",
  "올려놓고",
  "진료소를",
  "가상",
  "사과할게요",
  "필요했다",
  "알아내야해",
  "운영",
  "얘기라도",
  "만나길",
  "가가",
  "침실로",
  "형한테",
  "동등한",
  "빅뱅",
  "맞았죠",
  "하셔라",
  "상병",
  "문화를",

  "안뇽",
  "차랑",
  "딴데로",
  "태어났다",

  "도망치면",
  "어렵네요",

  "도망갔어요",
  "않든",
  "심심해서",
  "돌아온다고",
  "테드",
  "부화하는",
  "소재를",
  "하루에도",
  "헬리콥터에서",

  "로자를",
  "꼬치꼬치",
  "똥끼",
  "광년",
  "사이즈는",
  "피해도",
  "캐롤라인",

  "중이란",
  "외상성",
  "위층",

  "헤어지자고",
  "들으렴",
  "사비",
  "회진에",
  "연락주세요",
  "근무하는",
  "손길이",
  "돌연변이가",
  "시설에",
  "프랑스의",
  "밟는",
  "동영상을",
  "나아졌어요",
  "직원들은",
  "커뮤니케이션",
  "그사람이",
  "킹스랜딩에",
  "들렀어요",
  "말렴",
  "늦었습니다",
  "두개를",
  "치사하게",
  "수색을",
  "이미지가",
  "맡는",
  "좋아하는구나",
  "낸스",
  "윈슨",
  "족보를",
  "지킨",
  "이쁘네",
  "때문이란",
  "미란다가",
  "통제를",
  "남자잖아",
  "사생아",
  "뺏은",
  "났다고",
  "지들",
  "부끄럽지",
  "인터넷이",

  "기분이에요",
  "열쇠요",
  "다행이구나",
  "침입한",
  "필름을",
  "농어",
  "봤겠지",
  "심실세동이에요",
  "집으로요",
  "부인께선",
  "에리카의",
  "라이프",
  "키스가",
  "끊었어",
  "상냥하고",
  "해내고",
  "차징",
  "변하게",
  "높은데",
  "자식들은",

  "놀래라",
  "얘기하는거",
  "만난지",
  "포지",
  "에리카는",
  "중국인",
  "어머님도",
  "해에",
  "아더",
  "지속적으로",
  "저한테서",
  "충격으로",
  "가져올께요",
  "코카인",
  "암피아라오스",
  "부서졌어요",
  "아기처럼",
  "아스가르드의",
  "믿었다고",
  "부르자",
  "하고싶진",
  "양심을",
  "있는겁니다",
  "단도직입적으로",
  "꼬맹이가",
  "컨트리",
  "힘들었어요",
  "우리엄마가",
  "카메라와",
  "주말을",
  "매사에",
  "지날",
  "캐나다로",
  "한참을",
  "살해했어",
  "당일",
  "대회를",
  "차릴",
  "나이트워치의",
  "동기는",
  "사람이군요",
  "중이니까요",
  "나갑시다",
  "노아가",
  "쪽도",
  "봬요",
  "제롬",
  "피셔씨",
  "예쁜아",
  "살아있고",
  "웃지도",
  "결혼이라는",
  "앞길을",
  "조네",
  "그날을",
  "둘에",
  "올리비아랑",
  "서울을",
  "친구라는",
  "일본에",
  "데이트라도",

  "영역을",
  "훈장을",
  "많이는",
  "염색",
  "저놈의",
  "최고죠",
  "제트",
  "프로지움",
  "타마",
  "내의",
  "겁주려는",
  "먼저야",
  "더들스는",
  "근방에",
  "제독님",
  "너머를",
  "스톰",
  "버크예요",
  "하는진",
  "출발하겠습니다",
  "구름은",
  "퇴원을",
  "작년에도",
  "그정도로",

  "쳐다보면",
  "크기는",
  "현지",
  "재앙을",
  "직장은",
  "기술에",
  "알파를",

  "묵비권을",
  "발견됐어",
  "가능할까요",
  "자본",
  "꿈속",
  "바닷가에서",
  "번이면",
  "세례를",
  "바빴어",
  "되는거에요",
  "힘이야",
  "구해줬어",
  "랩",
  "마련이죠",
  "얘기했잖아",
  "지구상에서",
  "있건",
  "말레키스는",
  "타가리엔",
  "데이트할",
  "상공에서",
  "떠났나요",
  "제롬의",
  "방법으로든",
  "댁들",
  "몹",
  "칼리시",
  "질문도",

  "아파트도",
  "머리의",
  "해치워",
  "귀엽네",
  "살펴봐야",
  "있겠는데",
  "잠겼어요",
  "번째야",
  "고파요",
  "총기",
  "멋있다고",
  "번쯤",
  "소속이야",
  "하피의",
  "쏜월드가",
  "들었단다",
  "원자",
  "아니란걸",
  "않더라",
  "모르시는",
  "차지하고",
  "재산은",
  "막대",
  "해미시",
  "소변이",
  "젖이",
  "거즈",
  "불평할",
  "로키는",
  "떠나세요",
  "필요하다는",
  "골절을",
  "않구만",
  "수술받을",
  "대단했지",
  "중이랍니다",
  "서쪽에서",
  "인정해야",
  "한과",
  "수십억",
  "이비인후과",
  "남자애들은",
  "격렬하게",
  "싸움이야",
  "힘들었죠",
  "다이애나",
  "믿지마",
  "빰",
  "됬다",
  "형들이",
  "후회는",
  "토미는",
  "저쪽이야",
  "인종",
  "키웠어",
  "떨리고",
  "날아갈",
  "봐주셔서",
  "바보냐",
  "테고요",
  "최첨단",
  "영화나",
  "자랑을",
  "던지곤",
  "남겨놓고",
  "시간째",
  "쉽지가",
  "블랙아웃",
  "창녀가",
  "치료제를",
  "보았습니다",
  "결정했다",
  "여자지",
  "식사로",
  "은신처를",
  "재워",
  "법의",
  "남들처럼",
  "은미",
  "건물이",
  "꿰매",
  "아담과",
  "이르면",

  "아니라면요",
  "외과의는",
  "일러요",
  "전립선",
  "보는걸",
  "집도를",
  "했는지도",
  "고백하라",
  "포로로",
  "아저씨랑",
  "컬리",
  "중일",
  "쿵푸",
  "차지할",
  "만들거나",
  "불평만",
  "바뀌었다",
  "만족을",
  "윌슨의",
  "은하들은",
  "잤다",
  "전쟁이요",
  "거라면서요",
  "마구아는",
  "케일럽의",
  "이번은",
  "죽으러",
  "생각하는거",
  "장담하건대",
  "곡은",
  "돼지처럼",
  "하시던",
  "니네가",
  "서게",
  "전장의",
  "있더라구요",
  "지루하게",
  "여봐요",
  "소나",
  "원하지도",
  "않는다네",
  "권력이",

  "대중",
  "샤론씨",
  "음악의",
  "들어와야",
  "옛날처럼",
  "형광등",
  "자살한",
  "필요하니",
  "것들요",
  "약물은",
  "광산에서",
  "온라인으로",

  "질병에",
  "가능한데",
  "흉내",
  "어릴적",
  "욕하는거지",
  "때문은",
  "사람중에",
  "수사에",
  "전체적으로",
  "떳떳하게",
  "그랜트",
  "검이",
  "궁전은",
  "킬러가",
  "좋겠습니다",
  "정보원",
  "선생님은요",
  "해주신다면",
  "상세한",
  "보다가",
  "전재산을",
  "찍어서",
  "맛난",
  "타디스에서",
  "잔다고",
  "닌자",

  "맞았을",
  "장례식을",
  "거기에다",
  "다니엘스는",
  "피셔가",
  "시술",
  "에디는",
  "작자",
  "거리로",
  "줘야죠",
  "달리면서",
  "어려서부터",
  "잘가라",
  "아빠요",
  "말아줘요",
  "전화번호가",
  "모두와",
  "스타니스가",

  "비웠어",
  "잠가요",
  "일이라구요",
  "진정하십시오",

  "켄",
  "임무지",
  "가능성에",
  "연락처",
  "영역이",
  "州",

  "굴과",
  "손님들",
  "맞았습니다",
  "보는군",
  "여관에서",

  "본적은",
  "만들었을",
  "모르겠습니다만",
  "잘해줘",
  "칠십",
  "타이",
  "만나실",
  "고담의",

  "세를",
  "만들어줘",
  "밝혀",
  "고립된",
  "승부",
  "학장",
  "제임스는",
  "버드",
  "눈사람",
  "워털루",
  "곳이기도",
  "투여를",
  "증언에",
  "병균이",
  "떠야",
  "장모님",
  "적수가",
  "향상",
  "선물이지",
  "비교가",
  "전화기도",
  "검토해",
  "다가와요",
  "빌려서",
  "통할",
  "싸운다고",
  "장신구는",
  "진드기",
  "의사로서의",
  "민트",
  "나뿐이야",
  "직장으로",
  "보여줘야지",
  "블라디미르",
  "준비하러",
  "고맙기도",
  "나왔으니",
  "장소도",
  "알렉스와",
  "영향력을",
  "약속하세요",
  "하신다고",
  "그만뒀어",
  "죽었을지도",
  "노동을",
  "생각들",
  "비정상적인",
  "개흉술을",
  "결론",
  "니키를",
  "따끔할",
  "어디에서나",
  "요원의",
  "대중의",
  "난실이",
  "취했을",
  "대단하구나",
  "잘해야",
  "마츠오카",
  "진짜지",
  "날들을",
  "도마뱀",
  "흡인",
  "특별해요",
  "줄리를",
  "같은가",

  "얼래",
  "두통",
  "강해져야",
  "때였죠",
  "셰인이",
  "있겠구나",
  "이랑",
  "말했듯",
  "때문이라고요",
  "지시로",
  "걸게요",
  "명심해요",
  "간호사도",
  "베일리랑",

  "전통은",

  "유난히",
  "낫거든",
  "부러우니까",
  "전차를",
  "바텐더",

  "언제였지",
  "총알도",
  "솜씨는",
  "꿰뚫어",
  "무리하지",
  "얘기했잖아요",
  "베를린에",
  "되며",
  "나였으면",
  "무화가",
  "추리",
  "이러다간",
  "가둬",
  "빵은",
  "침입자는",
  "핀이랑",
  "자네라고",
  "알버트",
  "물린",
  "떨려",
  "수면제를",
  "찾았대요",
  "보도",
  "요원들",

  "병원도",
  "밴을",
  "동맹",
  "크로마티가",
  "연속으로",
  "지자기",
  "교도소를",
  "소다수",
  "가운을",

  "이새끼야",
  "다니죠",
  "자살하는",
  "지글",
  "먹었습니다",
  "받아들인",
  "순간으로",

  "아니잖나",
  "리디아",
  "가지란",
  "하는것도",
  "전차에",
  "다스릴",
  "늦었다고",
  "벅찬",
  "안됐지",
  "눈빛",
  "내버려두라고",
  "울기만",
  "살았다는",
  "나찌",
  "남자라는",

  "관찰",
  "썰어",
  "말할거야",
  "써서라도",
  "델타",
  "두려워해요",
  "옳은지",
  "열한",
  "필요하거든요",
  "전하지",
  "항공기",
  "나지만",
  "걸렸지만",
  "주워",
  "격납고",
  "마주치는",
  "저한테도",
  "되겠냐",
  "씻기",
  "은희",
  "바늘과",
  "애완동물",
  "요리사를",
  "도와주게",
  "들어가거라",

  "알아봤는데",
  "바친",
  "묘지를",
  "참석해",
  "대가리를",
  "요소를",
  "이걸론",
  "떠나는데",
  "조용히들",
  "있었다니",
  "에바는",
  "조심하렴",
  "테일러가",

  "하더라구",
  "부족",
  "지불해야",
  "해야겠죠",
  "약점",
  "관통한",
  "메뉴는",
  "되라는",
  "해석",
  "그러는게",
  "이놈들이",

  "놓으라니까",
  "축하하기",
  "맬",

  "못됐다",
  "여기있었네",
  "처음이다",
  "있으니깐요",
  "칸의",
  "종소리",
  "사항",

  "질병으로",
  "붙었다",
  "부케",
  "남자친구와",
  "봉쇄",
  "소식이라도",
  "잡음이",
  "사장님께",
  "구한다고",
  "가정부",
  "착해",
  "그딴식으로",
  "편이라",
  "늦출",
  "기준으로",


  "시켜요",

  "차원에서",
  "있으리라",

  "알아봐야지",

  "나부터",
  "뽑아야",
  "당직",
  "쌓는",
  "근사해요",
  "외상값",
  "조국의",
  "올라오세요",
  "달랐죠",
  "울었어요",
  "미안요",
  "나왔나",
  "죽이겠지",
  "한번쯤",
  "아델이",
  "비만",
  "납치해서",
  "체다",
  "가려구요",
  "잘해보려고",
  "필요하세요",
  "검으로",
  "모니터가",
  "새들도",
  "오잖아",
  "지어진",
  "왜인지",
  "오하이오",
  "열렸다",

  "가진거",

  "주정뱅이",
  "돌아가셨고",
  "속였어요",
  "이사회에",
  "소문난",

  "놀았어",
  "들었다면",
  "약속한다",
  "눈감아",
  "샴푸",
  "있을지는",
  "말했었어",

  "신부는",
  "구분할",
  "일가의",
  "감정적",

  "재미있었어",
  "아쉽게도",
  "거냐구요",
  "잘못한거",
  "고려",
  "세명이",
  "피난시켜",
  "거실",
  "철저한",
  "불가능하지",



  "먹읍시다",
  "임명",
  "위치한",
  "노예상을",
  "갚을게요",
  "나사로",
  "손바닥을",
  "조던",
  "다리의",
  "망치기",
  "노라",

  "입술을",
  "거스",
  "해서도",
  "솔직하지",
  "주었죠",
  "하나였어요",
  "김정은은",
  "엿같은",

  "찾아보죠",
  "실험용",
  "꿈이라고",
  "지나쳤어",
  "머리요",
  "스카이라크씨",
  "동료와",
  "상위",
  "백혈구",
  "얘기잖아요",
  "이름조차",
  "해안에",
  "식사도",
  "부러뜨릴",
  "잘됬네",
  "났나요",
  "잊어버렸어요",
  "있으시다면",
  "도파민",
  "용기도",
  "단어로",
  "느끼실",
  "로체",
  "깊숙한",
  "사라져서",
  "아플거야",
  "한푼도",
  "말해두지만",
  "못들었어",
  "렁",
  "모르시겠죠",
  "시도라도",
  "지났지만",
  "찾고있는",
  "전문의가",
  "바이탈도",
  "잡으려",
  "부르는데",
  "양수가",
  "난그냥",
  "앤지",
  "멀리는",
  "우선은",
  "감정으로",
  "조스트",
  "아이리스를",
  "지켜주지",
  "한잔만",
  "베일리요",

  "해보자구",
  "아저씨와",
  "가봐야겠다",
  "좋아했거든",
  "따님께",
  "것에도",
  "목적지는",
  "깨어났을",
  "표본",
  "사실이죠",
  "안면이",

  "머릿속으로",
  "둬서",
  "상의를",
  "노숙자를",
  "요원님이",
  "만니톨",
  "수년이",
  "뻔하죠",
  "것이지만",
  "쫓겨",
  "총이나",
  "일상이",
  "과장이야",
  "삼촌과",
  "침팬지",
  "포털을",
  "히다카",
  "고맙지",
  "후룩",
  "표현은",
  "환자에게는",
  "이용하면",
  "멀게",
  "잘려진",
  "분노의",
  "설계도",
  "성부",
  "이상한게",
  "컴퓨터는",
  "잊었군",
  "투입",
  "안겨",
  "똥오줌",
  "화장실로",
  "별것",
  "약으로",
  "뛰어나",
  "맞을지",
  "살아야죠",
  "사샤가",
  "비밀번호가",
  "바라진",
  "있을줄",
  "모가지",
  "정신없는",
  "구했고",

  "않은게",
  "겠군요",
  "들렀어",
  "방향에",
  "인도의",
  "독일군",
  "관찰하고",
  "안끝났어",
  "여분의",
  "하든가",
  "집어치워요",

  "죗값을",
  "치른",

  "이상이죠",
  "영혼에",
  "마님이",
  "늦었으니",

  "폭발물이",
  "태어나기도",
  "남자에겐",
  "죽고싶어",
  "같은데서",
  "특별해",

  "르노의",
  "수천의",
  "광대하고",
  "금지돼",
  "컬버",

  "드시겠습니까",
  "나오겠지",

  "어째서죠",
  "감사한",
  "생겼다는",
  "공범이",
  "사냥도",
  "있었더라면",
  "쫓겨나게",
  "바쁘니까",
  "지출",
  "상황이라고",
  "그레타",
  "새롭고",
  "구미가",
  "코브라",
  "노래로",
  "자매의",
  "저로서는",
  "낸다고",
  "즐겨라",
  "그것마저",
  "얘기해도",
  "섹스랑",
  "확인해야겠어",
  "출입을",
  "물이나",
  "똑딱",
  "레스트레이드",
  "가다니",
  "시간들이",
  "부족하고",
  "하실지",
  "반군의",
  "사이오니스",
  "사진과",

  "결혼하셨어요",
  "유치원에",
  "글래스고",

  "멜빵",
  "안나와",
  "화면이",
  "아그네스의",
  "시나리오는",
  "같더군",
  "소주동무",
  "도일이",
  "레슨",
  "새도",
  "할아버지께서",
  "반대의",
  "으이",
  "결투를",
  "늑대들이",

  "참선을",
  "날아가서",
  "무지하게",

  "상점에서",
  "필라델피아",
  "방장",
  "나았어요",
  "그림자처럼",
  "맥스가",
  "별로였어요",
  "하잖나",
  "두려워할",
  "존재도",
  "이동을",
  "기다려줘",
  "방법이에요",
  "찾겠지",
  "동정을",
  "프레스턴은",
  "두려울",
  "독성을",

  "있었을지도",
  "숫자는",
  "승무원",
  "짓이란",
  "상태고",
  "형사님들",
  "보여드릴까요",
  "사일런스",

  "호흡기를",

  "많을수록",
  "도와드리고",
  "주려던",
  "싶겠어",
  "소식에",
  "알아본",
  "수업은",
  "나가려고",
  "해독제를",
  "얘기군",
  "깨어나",
  "그대들의",
  "누이",
  "지미한테",
  "돌아가거라",
  "칼날",
  "입어라",
  "블럭",
  "어긋나는",
  "나였다면",
  "아까워",
  "경골",
  "총과",
  "원했고",
  "주거든",
  "농담하지",
  "비교할",
  "데려와라",
  "폭발해",
  "하였다",
  "접근을",
  "크리스틴과",
  "나타났지",
  "부하들에게",
  "한순간도",
  "추지",

  "선장이",
  "조심성이",
  "박사에게",
  "둘러봐도",
  "뭐였는지",

  "대중들이",
  "애로우",
  "넘어갔어",
  "의문의",
  "형만",
  "탄약이",
  "되는디",
  "의식은",
  "디나가",
  "가선",
  "씩이나",
  "있었냐",
  "알리기",

  "악몽이",

  "갖다줘",
  "말하기로",
  "과학이",

  "그러셨어",


  "행동도",
  "당했는데",
  "싹싹",

  "원제",
  "실린",
  "제작부장",
  "혁명이",
  "나무들",
  "전해야",
  "아쉬워",
  "조난실이",
  "떠나길",
  "갸가",
  "들어오니",
  "좋구먼",
  "도착할거야",
  "못했네요",
  "수산",
  "되찾기",

  "고문할",
  "아셨나요",
  "놀랬죠",
  "친정",

  "웃자고",
  "전차가",
  "말이겠지",

  "삼키는",
  "마늘",
  "몰랐다는",
  "행동할",
  "교도소로",
  "값비싼",
  "뿐이었어요",
  "시장에서",

  "채소를",
  "쳐먹고",

  "감지하는",
  "부르렴",
  "흩어져서",
  "와인에",
  "밀수",
  "뜻이겠지",
  "제거하려고",
  "시설이",
  "일시",
  "앞까지",
  "보안은",
  "수송",
  "죽는건",
  "죽었다구요",
  "먹어야겠어",
  "증명하려",
  "바톤이",
  "처절한",
  "보이더군요",
  "기사님",
  "유리가",
  "성생활",
  "상관없잖아요",
  "거보다",
  "여름의",
  "말하는걸",
  "돌아가야지",
  "박스에서",
  "아그네스가",
  "우산",

  "싸우라고",

  "어쩌냐",
  "숲이길래",
  "당신께서",
  "만종이",
  "놀리면",
  "놀라고",
  "알아봤어",
  "때문이라",

  "없었겠지",
  "전술적",
  "무수한",
  "좋을대로",
  "눈부신",


  "같아져",
  "애도를",
  "지도가",

  "수술이죠",
  "좋아하시네",
  "갈아입어",
  "갖다줘요",
  "서랍",

  "이기게",
  "나타냅니다",
  "이거놔",
  "조회",
  "뭐하는거요",
  "므파나",
  "줄리아",
  "관리자",
  "이토군",
  "죽어있는",
  "심호흡을",
  "담요",

  "내려놔라",

  "있자나",

  "에이리언",
  "탓할",
  "카틀린",
  "잊었다고",
  "빵도",
  "적들의",
  "인간일",
  "겁주는",
  "티셔츠",
  "음모의",
  "도망친다",
  "축이",
  "철로를",
  "어느쪽이야",
  "바쳐서",
  "허가한다",
  "남기면",

  "미친것",
  "음모",
  "대니야",
  "아이하라상",
  "걸린다고",
  "이를테면",
  "공원에",
  "와인과",
  "밀어내고",
  "라우리입니다",
  "잔씩",
  "싫어했어요",
  "싫어한다",
  "줄거라고",
  "감옥을",
  "검사실에",
  "본능을",
  "폭이",

  "사람처럼요",
  "정부에",
  "마에스터",
  "돌연변이를",
  "확실하다고",
  "기준으론",
  "명예로운",
  "수요일에",

  "동맥에",
  "죄에",
  "감각도",
  "먹었더니",
  "헛간에서",
  "대박선수",
  "주먹치기",
  "파리는",
  "엿보는",
  "다녔던",
  "노인의",
  "동정심이",
  "미쳐가고",
  "로우",
  "아르곤",
  "분이지",
  "랜디예요",
  "코블팟을",
  "역겹다",
  "해본적이",
  "생물학적",
  "프롤린",
  "계획이란",
  "치료제",

  "합법적으로",
  "돌기",
  "죽였나요",
  "방해하면",
  "스코트",
  "훔쳐가",
  "부모에게",
  "지혜의",
  "펀치와",
  "잘못이라고",
  "중독자",
  "딸들을",
  "바울은",
  "정보의",
  "남았군",
  "바바라가",
  "저만큼",
  "이런다고",
  "큐타로",
  "놓쳤잖아",
  "여기입니다",

  "닫히고",
  "구했잖아",
  "상심이",
  "퍼킨스",
  "낚시는",
  "절망적인",
  "이틀은",
  "떠납니다",
  "멋지구만",
  "딱딱한",

  "혼잣말",
  "기다렸는지",
  "확실한가요",
  "줬군",
  "구하죠",
  "누구한테도",
  "사사키",
  "댔어",
  "배은망덕한",
  "다하겠습니다",
  "뉴스입니다",
  "나한테만",
  "받는다면",
  "낮아",
  "어니는",
  "레바",
  "반역",
  "거짓을",

  "열려고",
  "아닌걸",
  "자매들을",
  "기간",
  "돌려주고",
  "아쇼",
  "아비가",
  "온겁니다",

  "벅시",
  "벽에서",
  "팀원",
  "허위",
  "진짜라면",
  "남겨둔",
  "실망시킬",

  "개방된",
  "이빨은",
  "작별인사는",
  "인섹토사우루스",
  "교도소에서",
  "않던가요",
  "상호",
  "멋진지",
  "쫓아가서",
  "만이야",
  "보고를",

  "해봐서",
  "링크",
  "배신하지",
  "무리예요",

  "적들을",
  "받는데",
  "였다",
  "서명만",
  "버렸고",
  "만이",
  "전화하면",
  "죽은줄",
  "너하고는",
  "나침반이",
  "에밀리가",
  "고유",
  "아끼지",

  "소식이지",
  "귀하게",
  "짓들이야",
  "듀파옐",
  "항구로",
  "암호는",
  "오야붕",
  "용감했어",
  "도서관에서",
  "그것과",
  "낫는",
  "싸워요",
  "베일리도",
  "떼를",
  "정문",
  "망설이지",
  "저기도",
  "맺고",
  "자금이",
  "기쁨이",
  "루시엥",
  "신호에",
  "비앙카",
  "시간이라",

  "교회가",
  "터지는",
  "이러실",

  "피터와",
  "저항할",
  "누구였지",

  "울려요",
  "집중해요",
  "요시다군",
  "기다리는데",
  "충격에",
  "무게가",
  "챙길",
  "곳엔",
  "뚜껑",
  "자료에",
  "경막하",
  "밝히고",
  "먹습니다",
  "신참",
  "충분하게",
  "계시오",
  "부탁했어요",
  "필요하다구요",
  "마법사의",
  "감옥이",
  "목매달아",
  "오메가",
  "열차에서",
  "마법으로",
  "부끄러워할",
  "걱정해요",

  "어쨌던",
  "버튼은",
  "부르라고",
  "탈락",
  "잭슨은",
  "실패야",
  "도와주려는",

  "오거든",
  "총독부",
  "불만은",
  "비밀이란",
  "거스를",

  "도착해서",

  "데릭하고",
  "줄줄이",
  "끝내주네요",
  "배우를",
  "부상자",
  "관문을",
  "꺾을",

  "죽여버리고",

  "믿었지",
  "단검",
  "주랴",
  "이해하네",

  "놀랍고",
  "느리지만",
  "아트로핀",

  "훌륭하지",

  "들어갔을",

  "존재라고",
  "기도해야",
  "아침이면",
  "도왔어",
  "아직까지도",
  "대한건",
  "이뤄져",
  "지나칠",
  "내주지",
  "아줌마들",
  "좋았다고",
  "되어줄",
  "영화배우",
  "한눈",
  "남았네요",
  "일했죠",

  "뜨겁고",
  "심을",
  "하시게요",
  "징그러운",
  "바랬지",
  "가봤어",


  "앤드류는",
  "사건입니다",
  "구슬이",
  "비범한",
  "노래야",
  "암요",

  "순이가",
  "네버크래커",
  "동생도",
  "수술방",
  "브라더톤",
  "나입니다",
  "모텔에서",
  "기뻐할",
  "바뀌어서",
  "미희야",
  "양한테",
  "움직이자고",
  "말하란",
  "무신",
  "싶지가",
  "별도",
  "밤도",
  "않았",
  "오해하지",
  "알아들었지",
  "수술과",
  "수술들",
  "깨운",
  "살에",
  "백을",
  "조이스",
  "깔려",
  "갈고",
  "가로막고",

  "빈맥",
  "이르는",
  "끝내줬지",
  "아래는",
  "으르렁",
  "도와줬으면",
  "드칼로",
  "태쳐",
  "흐음",
  "상승",
  "사생활은",
  "아내분",
  "알아내고",
  "힘들다고",

  "해주려는",
  "보모가",
  "앞으로의",
  "땡겨",
  "상황인지",
  "행복이란",
  "꼭대기로",
  "두번째로",
  "못했다고",
  "절대적으로",
  "절개술을",
  "소지",
  "콘서트",
  "숙여요",
  "장보람",
  "먹겠어",
  "느린",
  "안타깝지만",
  "넘어가자",
  "불안하게",
  "옴팔로셀",
  "고디오",
  "리네커",
  "대표로",
  "어두워질",

  "죽은게",
  "몇시에",
  "좋아해야",
  "생각했네",
  "먹이로",
  "주위에서",
  "다르니까",
  "나올지",
  "물러설",
  "바닥까지",
  "말라구요",
  "것을요",

  "도냐",
  "아담을",
  "그지같은",

  "막어",

  "전만",
  "모른단다",
  "경연대회",
  "제네바에",

  "목장을",
  "생기기도",
  "집어넣을",
  "부정맥이",
  "퀸랜",
  "창조주는",
  "칼륨",
  "망가지고",
  "준비되어",
  "봤고요",
  "마르시아",
  "죄값을",
  "보유하고",
  "반복하는",
  "세뇨르",
  "누이의",
  "찌르고",
  "않았다고요",
  "나중에요",

  "가족이란",
  "찾으실",
  "보냈고",
  "사고라도",
  "탄생하는",
  "어쩌니",
  "이자가",
  "빠이빠이",
  "정식",
  "혼인은",
  "아무하고나",
  "약속도",
  "몰려올",
  "엘리엇이",
  "외로울",
  "모험이",
  "쇼에서",
  "넣어도",
  "강해요",
  "밤늦게",
  "쏘리",
  "싶었나",
  "휴전",
  "보답을",
  "에릭과",
  "도와주셔야",
  "파일은",
  "부모도",
  "발음",
  "뵈는",
  "따면",
  "근처엔",
  "영향력이",
  "바람난",
  "카레브의",
  "보인다면",
  "나거든",
  "행복했어요",
  "간섭을",
  "힘드네요",
  "아버지예요",
  "있데요",
  "숲의",
  "엘리트",
  "부수는",
  "배우들",
  "놓게",
  "올려서",
  "치즈버거",

  "마르텔",
  "데일리",
  "무리야",
  "바라죠",
  "겸자로",
  "봉기가",
  "실험이",
  "시체에는",
  "이마를",
  "겪었던",
  "벗어나는",
  "비치는",
  "부검은",
  "일투족을",
  "겁도",
  "심하잖아요",
  "용의자로",
  "날짜가",
  "좋아했었어",
  "없더라구요",
  "음침한",
  "보인다는",
  "금지다",
  "끝입니다",
  "소련",
  "탐지기",
  "띄는",
  "오전에",
  "돈토스",
  "공장은",
  "제복",
  "온것",
  "초상화",

  "잠옷을",
  "호르몬이",
  "집인지",
  "흥분할",
  "보너스",
  "쓸게요",
  "얼마면",

  "보초",
  "자르려고",
  "싫어하는지",
  "평생에",
  "만져봐",
  "마셨다",
  "광대한",
  "농담한",
  "강요",
  "충직한",
  "나갔습니다",
  "공포증",
  "망치게",
  "스프링",
  "어리지",
  "보고지고",

  "날개는",
  "품지",
  "분리된",
  "시작한다면",
  "쾌감을",
  "인력",
  "친절하기도",
  "아티",
  "수있어",
  "사무관님",
  "로마노",
  "구름들로",
  "중이세요",
  "감정의",
  "의미하는게",
  "하니깐",
  "대화에",
  "기억나세요",
  "요원입니다",
  "톨스타인",
  "일처럼",
  "인간으로서",
  "크누트",
  "트윈파인",
  "눕혀",
  "본격적으로",
  "운다고",
  "베인",
  "하형사님",
  "때다",
  "범위가",
  "호세",
  "글로",
  "치즈가",
  "방이요",
  "확실히는",
  "시간이네",
  "그만둔",
  "조금요",

  "왔었지",
  "머리카락도",
  "좋으시겠어요",
  "검사하고",
  "간선에서",
  "첨엔",
  "잘라내고",
  "무언가에",
  "씻는",
  "박사와",
  "퍼지고",
  "찬장",
  "일부야",
  "모르구요",
  "못하잖아요",
  "크네",
  "희생으로",
  "명성이",
  "감싸고",
  "방과",
  "그럴수가",

  "전문가들",
  "필요하게",
  "환자들한테",

  "걸렸고",
  "나갔을",
  "가봐야지",
  "디젤",
  "붉게",
  "업무를",
  "막히네",
  "생각만큼",
  "살려달라고",
  "생각이세요",
  "엔리께따",
  "인조",
  "느끼지도",
  "때에도",
  "행한다",
  "춤이나",
  "남겨두지",
  "팻시는",
  "아녔어",
  "닌자가",
  "당신이잖아요",
  "떨어졌다고",
  "걸렸던",
  "집이니",
  "감염은",
  "바랬어요",
  "않다고요",
  "모욕감을",
  "하나뿐이지",
  "나오질",
  "돌아가면서",

  "문제들이",
  "티셔츠를",
  "항복하지",

  "붙잡지",

  "박동이",
  "음악이야",
  "순간은",
  "숨쉴",
  "일들도",
  "약만",
  "실비아",
  "사만다",

  "성적을",
  "찾고있어",
  "연주할",
  "보답으로",
  "무게로",
  "발라서",

  "사탕을",
  "않다구",
  "레지던트들을",
  "노동",
  "하염없이",
  "순회",
  "디오니스",

  "데이타를",
  "따분해",
  "감염에",
  "앨트먼이",
  "했던가",
  "힘들거야",

  "머릿",

  "날려버리고",
  "끝나게",
  "요리로",

  "네놈들",
  "밴이",
  "감탄할",
  "이올라우스",
  "공유하는",
  "사이킥",
  "조용하네요",
  "망명자",
  "나가시죠",
  "골동품",
  "알아도",
  "있단걸",
  "빵에",
  "꺼내줄게",
  "내이름은",

  "타임머신을",
  "유람선",
  "결정인데다",
  "아래층으로",
  "연루된",
  "돌려받고",
  "공공",
  "얘기에요",
  "또렷하게",

  "날짜는",
  "웨딩드레스를",

  "몸이나",
  "알란",
  "생각하시겠지만",
  "서점",
  "미린에서",
  "매시간",
  "앉기",
  "때부터요",
  "나갔으면",
  "해준다",
  "너희들과",
  "뚫는",
  "부인이에요",
  "들이고",
  "고삐리",

  "싫어한다는",

  "표시",
  "무겁고",
  "바보짓",
  "다쳐",
  "희생자는",
  "하나님을",
  "음조를",

  "돌아갔어요",
  "세상이야",
  "방주를",
  "바텐더가",
  "길지",
  "사숙님",
  "톤이나",
  "차단할",
  "젊은이는",


  "말했",
  "죽였다는",
  "피트를",
  "객실",
  "가을에",
  "이사가나요",

  "주리",
  "몫이지",
  "동네에선",
  "아인슈타인이",
  "숙제는",
  "명예의",
  "회사와",
  "자유에",
  "산타를",
  "끝내면",

  "진짜라기엔",
  "무슨말이야",
  "계기로",
  "차려서",
  "수술이지",
  "믿음직한",
  "방주에",
  "구해서",
  "큐브가",
  "서명하세요",
  "작으니",
  "없겠군요",

  "내인생",
  "손대면",
  "오웬은",
  "커튼을",


  "파델라가",
  "플루토늄을",

  "느낀다고",
  "촬영해",

  "깃든",
  "침대는",
  "예터",
  "기회에요",
  "조석차가",
  "말랬는데",
  "이거군",

  "번째예요",

  "예정이다",
  "사적",
  "그럴걸",
  "아닌거지",
  "똑똑하네",
  "목숨걸고",
  "시도할",
  "환상은",
  "고치면",
  "열정과",
  "독립된",
  "달빛",
  "대변",
  "놀면",
  "빵집",
  "그여자가",
  "거니깐",
  "시간마다",
  "컨버전스가",
  "갖다놔",
  "내기는",
  "섹스한",
  "되잖나",
  "종잇장처럼",
  "알았겠어요",
  "너였구나",
  "쓸게",
  "모이라고",
  "워너",
  "꿈일",
  "숨막히게",
  "부정",
  "좋을게",
  "사람이기도",
  "머리속에",
  "합병을",
  "찾아줄",
  "부서지는",
  "프라임",
  "안먹고",
  "선실",
  "당해도",
  "뿐이란",
  "메건은",
  "모르겠죠",

  "로키의",

  "지옥이야",
  "질소",
  "딛고",



  "ㅈ",
  "여왕님을",
  "아기라고",

  "변했지",
  "척수가",
  "믿는다면",
  "가위를",
  "조그만한",
  "존경할",
  "몰랐구나",
  "점심은",
  "들여야",
  "두렵다는",
  "않는다구요",
  "이사회가",
  "짐승은",
  "엉망인",
  "안아줘",
  "벌어요",
  "할만큼",
  "모방하기",

  "전해줄",
  "천하",
  "찔려",
  "실장",
  "자입니다",
  "처분을",
  "만나겠죠",
  "괜찮겠니",
  "올라올",

  "침실을",
  "가둘",

  "목말라",

  "흐",
  "고속도로",
  "형네",
  "스테로이드",
  "원했는데",

  "불입니다",
  "자랐다",
  "전화하게",
  "드래곤은",
  "증언이",
  "걸거야",
  "에렛의",
  "조수가",
  "멀어서",
  "신청하는",
  "감으면",
  "끼친",
  "그랬어야",
  "맹세하는데",
  "우울증에",
  "주었지",
  "김종욱",
  "보스가",
  "당신을위한",
  "시절은",
  "상사의",

  "훔쳤지",
  "손떨림이",
  "마일로",
  "뇌와",
  "흘러도",

  "발렌틴",
  "않으셨잖아요",
  "사이버맨이에요",
  "잊어서는",

  "뇌진탕",
  "약혼",
  "데려갔죠",
  "상관없죠",
  "잘되고",
  "엄마께",
  "매트",
  "말았다",
  "강간범이",
  "주거나",
  "드려야지",
  "뛰어들었어요",
  "갑작스런",
  "아직은요",
  "잘못하고",
  "거창한",
  "것만은",
  "할리우드에서",
  "포옹",
  "찍도록",
  "스케쥴을",
  "변화하는",
  "전선에",
  "시즌",
  "서적",
  "한계에",
  "분이죠",
  "일어났죠",
  "문제라면",
  "일했습니다",
  "멕시코에서",
  "창조주를",

  "많았는데",
  "벽에다",
  "남았소",
  "보기라도",
  "넘어졌습니다",
  "신혼여행을",

  "가로파",
  "검토하고",
  "자물쇠",
  "싸워야지",
  "돌아갈거야",
  "떨어졌네",
  "없었고요",
  "친구처럼",
  "박사입니다",
  "재밌군요",
  "이후에도",
  "무대감독",
  "건드리는",
  "명단",
  "이식받을",
  "터뜨릴",
  "주위의",
  "설명하기",
  "안드리아",
  "망상에",
  "쳤죠",
  "사람들이나",
  "말하는거에요",
  "사형수",
  "벌어야",
  "신경쓰고",
  "취약한",
  "열었어요",
  "배경이",

  "약물로",
  "샀지",
  "판결을",
  "아이들이에요",
  "쉬지않고",
  "둘째는",
  "팅커벨",
  "환자잖아",
  "애인한테",
  "발생합니다",
  "씨져",
  "드는군요",
  "고교",
  "타다가",
  "울혈성",
  "했더라면",
  "흑인이",
  "이네요",

  "트랙터를",
  "기다리자",
  "한가해",
  "너한테만",
  "분대",
  "견학",
  "빠져나오려고",
  "빡",
  "아인데",
  "드물죠",
  "조용하지",
  "누구입니까",
  "제품",
  "생존자가",
  "호랑이를",
  "와보렴",
  "친구에",
  "돌아오겠죠",
  "인원은",
  "기대는",
  "병실을",
  "모르겠",
  "댄스파티에",
  "늦었거든",
  "집착",
  "외곽에",
  "애로",

  "나라에선",
  "듣길",
  "쓰러졌어요",

  "셀레나",
  "실험에",

  "끼쳐서",
  "부패한",
  "안톤이",
  "위대하신",
  "따릅니다",
  "자네보다",
  "소관이",
  "연구소에",
  "박혔어",
  "행사할",
  "만들었소",

  "세트가",

  "돌아간다고",
  "남성의",
  "야들아",
  "동창",
  "사망자",
  "인부",
  "밀착",
  "경동맥만",
  "없애려고",
  "귀엽긴",
  "오말리요",
  "삼을",
  "요점을",
  "옴",
  "임충야분",
  "검사와",
  "적이다",
  "찾았다구요",
  "질러요",

  "맞구나",
  "보였고",
  "울리고",
  "기억합니다",
  "전등이",
  "동무들의",
  "알지요",
  "폭력에",
  "망설이는",
  "조명탄",
  "장미",
  "여자라도",
  "여자일",
  "쓰여진",
  "진심으로요",
  "간혹",
  "분하고",
  "엄니는",

  "이네",
  "워매",
  "봤었는데",
  "탁일항을",
  "언넘이",

  "해야하고",
  "크리코토미를",
  "삼킨",
  "향했다",
  "원래의",
  "분들께",
  "메인",
  "의심한",
  "바꾸어",
  "갔다는",
  "가부키",
  "이물질",
  "부하에게",

  "올림",

  "관계요",
  "수리할",
  "자동차에",
  "현기증",
  "나가시오",
  "궁지에",
  "나겠지",
  "물체가",
  "그랬듯이",

  "항구에서",
  "죄악이",
  "판이",
  "그것때문에",
  "스타일리스트",
  "미즈시마",
  "생각하기도",
  "청춘도",
  "겠는데",
  "열렬한",

  "않았구요",
  "사망시각은",
  "끔찍할",

  "실패는",
  "바꿔줘",
  "열쇠야",
  "둘로",
  "뛰기",
  "작",
  "즉결",
  "부대에",

  "거울이나",
  "김영",
  "앨렌",
  "약하고",

  "나올거야",
  "장난쳐",
  "불렀습니다",
  "상상하고",
  "간통한",
  "할려면",
  "상실감을",
  "담지",
  "타당한",
  "존중하지",
  "레수스가",
  "문제지만",

  "데이브가",
  "나탈리아",
  "인도를",
  "크레이그",
  "분석이",
  "금일",
  "보이기도",
  "준비되었습니다",
  "해답",
  "더욱이",
  "받들어",
  "먹겠어요",
  "흘러가는",
  "로즈는",


  "치고는",
  "레리",
  "도착하지",
  "주말엔",
  "될거라는",

  "크는",
  "꼬마는",
  "예쁘진",
  "찐하게",
  "살려서",
  "갈렙",
  "벗어나기",
  "가호를",
  "아는한",
  "약들이",
  "외부에",
  "다함께",
  "그는이",
  "저택",
  "장례식에서",
  "백성들을",
  "사의",

  "나이지리아",


  "여잔데",
  "동상에",

  "향한다",
  "떼어내야",
  "팔아라",
  "니네들",
  "자켓을",
  "로프",
  "오만",
  "킴벌리",
  "마사키라고",
  "민석이",
  "물리적으로",
  "학부모",
  "영웅으로",

  "앉으라고",
  "관이",
  "나냐",
  "여성과",
  "들어보렴",
  "이상하다는",
  "따듯하게",
  "않을걸세",
  "먹는게",
  "말이지를",
  "한성현",
  "거기까진",
  "블로우",
  "운명이야",
  "아들이예요",
  "시간인데",
  "살겠지",
  "믿음으로",
  "저주받은",
  "하나뿐이야",
  "거였고",
  "깎는",
  "말해보시오",
  "처방전을",
  "조국",
  "주희야",
  "극도의",
  "랭",
  "변함없는",
  "쥐를",
  "미용실",
  "팜피오",
  "트위스트",
  "천사여",

  "여쭤봐도",
  "될것",


  "하나랑",
  "온거죠",
  "주었어",
  "압박감이",
  "죽어가",
  "시간여행자야",

  "같았거든",
  "케이토",

  "드레싱",
  "염소랑",

  "출근하지",
  "눈썹을",
  "자켓",
  "보키",
  "안심",
  "것들이에요",

  "하기에는",
  "이사오기",
  "해리의",
  "신앙을",
  "반에서",
  "빨간머리",
  "후보는",
  "사라져요",
  "결혼식날",

  "죽었잖아요",
  "쓸모없어",
  "기다려서",
  "징징대는",
  "쓴다고요",
  "말브",
  "이해하긴",

  "흉터를",
  "일이래",
  "짐작도",
  "젠은",
  "알려줄래",
  "코트는",
  "거만한",
  "시청",

  "견딜만",
  "선거에",
  "절도범",
  "권력에",

  "사귀어요",
  "화상이",
  "밴쉬",
  "앞선",

  "날만",
  "상대의",
  "다니진",
  "닫았어",
  "섹시하고",

  "설명하고",
  "짜서",
  "집착하지",
  "개인용",
  "학교서",

  "속력을",
  "도끼가",
  "확인한",
  "심심한",
  "나갈래",
  "뭔일이",

  "말했었잖아",
  "그럴래",
  "카일은",

  "장애인",


  "집에가서",
  "죽인게",
  "미안했어요",
  "염소를",
  "압박하고",
  "관해선",
  "들어봤어요",
  "결혼하기로",
  "해줄꺼야",
  "에반스",
  "과학을",
  "천사는",
  "반란",


  "놔도",
  "서두르면",
  "진료소로",
  "짊어지고",
  "전동무",
  "안되겠다",
  "먹는데",
  "달라지는",

  "말짱",
  "후딱",
  "헤매고",
  "참석한",
  "썩어빠진",
  "훤히",


  "도트락",
  "깜짝할",
  "다가오지",
  "둘중",
  "전이가",
  "으으",
  "보고하는",
  "평판을",
  "뽑아서",

  "볼때마다",
  "위한거야",
  "네이든",
  "돼주세요",
  "비겁하게",
  "쓰시는",
  "물이라도",
  "아깝다",
  "수건을",
  "당신한테도",

  "빨아들이고",
  "장담컨대",
  "조심하시오",

  "해주게",
  "해야하죠",
  "그럴순",
  "임충이",
  "단검은",
  "에바를",
  "하기를",
  "좋아한단다",

  "순간들",
  "편지들을",
  "모를꺼야",
  "지혈",
  "반대편",
  "아디",
  "하노버",
  "잘할거야",
  "확인할게요",
  "지난번엔",
  "기다리시오",

  "몇시야",
  "너네를",
  "보냈잖아",
  "카드에",
  "어떻겠어요",
  "그날까지",
  "말이시",
  "전파",
  "그러지요",
  "있으니께",
  "이쪽이요",
  "나이아가라",
  "탐정",
  "봉합이나",
  "말씀이시죠",
  "매트리스",
  "반갑구만",
  "사각",

  "남부군",
  "약해서",
  "묶는",
  "올려놔",
  "정글",
  "뭐한거야",
  "식대로",
  "괜찮구나",
  "빨고",
  "예상하고",
  "고담을",
  "히드라의",
  "가르치려고",
  "들었냐",
  "이상하구나",
  "정각에",
  "해줬다",
  "금속을",
  "배터리는",
  "베일리시",
  "상표를",
  "장난전화",
  "보냈던",
  "한사람이",
  "가지려고",
  "의학을",
  "싫어하게",
  "얘기인데",
  "나가기로",
  "이광민이",
  "무기들",
  "소름이",
  "복잡하죠",

  "커피요",
  "풀어준",
  "건드리지나요",
  "성대한",
  "윈터펠은",
  "사라진다면",
  "일이네요",
  "정도인데",
  "친해",
  "욕망을",
  "감수",
  "보낼거야",
  "장전",
  "받네",
  "우리한테서",
  "폭",
  "고형사님",
  "격렬한",
  "국민들이",
  "벨기에",
  "손님들은",
  "찾죠",
  "엘리아",
  "수영복",
  "행진",
  "마취제",
  "미션이",
  "칙칙",
  "폭폭",
  "메이요",
  "왕께",
  "도난당한",
  "괴물한테",
  "뭉쳐야",
  "되찾게",
  "미쳐라",
  "심박이",
  "복통이",
  "미션을",
  "돌아왔는데",
  "전통적으로",
  "전하께서는",
  "빼구요",
  "기다렸습니다",
  "거부를",
  "와이프가",
  "보셨어야",
  "머시웨스트로",
  "바비의",
  "인공위성이",
  "한건데",
  "지랄맞은",
  "아틀란티스",
  "한턱",
  "고형사",
  "진공",
  "배지",
  "홀리",

  "배신할",
  "내려줄",
  "거짓말이에요",
  "러시아가",
  "전문가야",
  "꿀꿀",
  "할지를",
  "가보면",
  "경계선을",
  "이쁘네요",
  "떨어졌다",
  "긴급한",
  "후보로",
  "우표를",
  "자다",
  "벗으면",
  "광경은",
  "먹으랴느냐",
  "피해자들의",
  "날짜에",
  "예산을",
  "별일도",
  "에스",
  "어딘지도",

  "옆방",
  "가야합니다",
  "물어보시죠",
  "그란데",
  "달리면",
  "나일",
  "꿈속의",
  "희생자의",
  "인정한",
  "자그마치",
  "미린",
  "비닐",
  "걱정했어",
  "살인범은",
  "주장을",
  "인질",
  "어딨습니까",
  "본부에",
  "가만있지",
  "지원팀",

  "짓인",
  "하셨다고",
  "이정표를",
  "안전에",
  "뒷면에",
  "유봉이",
  "모두에",
  "알아선",
  "전화벨",
  "사용되는",
  "관계자들은",
  "춤이",
  "물만",
  "죄송한",
  "단단하게",
  "알츠하이머에",
  "정자를",
  "천재가",
  "성훈아",
  "여기죠",
  "크리스티나의",
  "오겠어",
  "사용이",
  "바비가",
  "경쟁을",
  "돼가고",
  "장면은",
  "열리면",
  "자유와",
  "존과",
  "참으라고",
  "쉬겠어요",
  "안자고",
  "아니냐고",
  "취미가",
  "네번째",
  "매매",
  "제이크는",
  "복싱",
  "상황의",
  "짐의",
  "마야",
  "기숙사에",
  "밤입니다",
  "지나도",
  "배고플",
  "심각하군",
  "참여하게",
  "단독으로",
  "전능하신",
  "태평양",
  "그라면",
  "밤만",
  "일주일도",
  "수술이라",

  "마스",
  "왜일까",
  "샀다",
  "하구로",
  "주니어",
  "가볼래",
  "골키퍼",
  "빠이",
  "일진이",
  "캐피톨에",
  "우승을",
  "교과서",
  "사람한테는",
  "액체를",
  "두가지를",
  "의문이",
  "스티븐스입니다",
  "투입조",
  "아파트는",
  "못할거요",
  "리노어",
  "손자가",
  "찾은거",
  "알렉산드라",
  "자일스양",
  "대나무",
  "지저귐",
  "어젠",
  "명월채를",
  "양반들",
  "거였나",
  "일까요",
  "그것밖에",
  "용의자의",
  "드렸습니다",
  "못들은",
  "병사는",
  "남겨진",
  "입술에",
  "창피해",
  "않았길",
  "익사",

  "외부에서",
  "왕실",
  "섬의",
  "러시아에서",

  "퍼플베리녀석들을",
  "하루빨리",
  "말랐어",
  "좋은걸요",
  "미스터리한",
  "가짜가",
  "쿵",
  "사랑하죠",
  "내년에는",
  "익사한",
  "곳에선",


  "당기는",
  "대장인",
  "시동을",
  "화해하고",
  "가는것",
  "팔뚝이",
  "무의미한",
  "시타는",
  "알게되겠지",
  "머무는",
  "헬기는",
  "얘기군요",
  "짓입니까",
  "상황인가",
  "뭍에",
  "우리는요",
  "있었음",
  "있을것입니다",
  "얻으려면",
  "아동",
  "업무는",
  "싫었는데",
  "찾아주는",
  "잤는지",
  "만족",
  "대장을",
  "성공이야",
  "구타",
  "이해해줘",
  "짐승처럼",
  "좋아한다면",
  "눈썹이",
  "오겠다고",
  "머리엔",
  "못할겁니다",
  "왕자님을",

  "주잖아",
  "알아가는",

  "모르길",
  "깜빡이는",
  "길어서",
  "대통령의",
  "나아지지",
  "보트에",
  "싶겠죠",
  "였는데",
  "싶었으면",
  "조군의",
  "즐거움",
  "사상자",
  "치웠어",
  "전쟁터에",
  "생각하거든요",
  "기다리십니다",
  "내려가자",
  "흡입",
  "걔들도",
  "용문서를",
  "시푸사부님",
  "생각이군",
  "잠깐이지만",

  "풍경을",
  "이야기네요",
  "주장하는",
  "차면",
  "용의전사가",
  "밀리",
  "건너면",
  "신청",
  "리나의",
  "얘기했을",
  "로봇은",
  "사람들에게서",

  "고쳤어",
  "연회가",

  "시멘트에",
  "적들은",
  "관점입니다",
  "성욕을",
  "자문을",
  "이거에요",
  "수상",

  "토한",
  "해주셔야",
  "타이렁을",
  "무슨소리야",
  "하겠습니까",
  "않는다고요",
  "엘비스",
  "변명",
  "상은",
  "공간과",
  "이해한다",


  "본건",
  "초조해",
  "없을거요",
  "반군",
  "채모",
  "아군의",
  "문신은",
  "자물쇠를",
  "누나를",
  "괜찮은거지",
  "빠졌지",
  "해대고",
  "화끈하게",
  "꿀꿀이",
  "고압",
  "미워해",
  "악어를",
  "될테니까요",

  "않는이상",
  "괜찮으십니까",
  "생각해본",
  "움직였어",
  "맨티스",
  "기둥",
  "와주세요",
  "베일",
  "궁금하네요",
  "몽키",
  "노인은",
  "명으로",
  "폴의",
  "상관없소",
  "병사들의",
  "달립니다",
  "단계에서",

  "보험에",

  "존에게",
  "사무실이",
  "고파서",
  "탓",
  "허블의",
  "술마시고",
  "어디의",
  "귀신을",
  "프리츠",
  "흔하지",
  "좋아해서요",

  "실종과",
  "검둥이",
  "일이지요",
  "타도",

  "딕슨을",
  "남동생",
  "속속들이",
  "제레미가",
  "교환",
  "푸세요",
  "끝났는데",
  "영양을",
  "행복해서",
  "두려움도",
  "양자",
  "즐겁고",
  "주니",
  "사람들한텐",

  "춘향이가",
  "모르고요",

  "하룻",
  "된다니",

  "디렉",
  "못알아",
  "지키려는",
  "꺼내줘",
  "동물들은",
  "도시락",
  "베풀어",
  "직접적인",
  "사귄다",
  "대화하지",
  "가을이",
  "원하기만",
  "죽이지는",
  "오빠들이",
  "꼬추가",
  "동이",
  "이겨내고",
  "베는",
  "진행하는",
  "도이칠란트",
  "서기",
  "처리하는",
  "왕으로",
  "쉽지는",
  "것보다도",
  "겪을",

  "수단이",
  "캐시를",
  "듣죠",
  "관에",
  "아늑한",
  "불쌍하게",
  "지냈지",
  "전이지",
  "기다리기로",
  "난처한",
  "그게요",
  "기다리시는",
  "편이지",
  "웃기게",
  "고리를",
  "다행으로",
  "들어들",
  "잘하는군",
  "쏘아",
  "일레인",
  "시간쯤",
  "하치너",
  "종양도",
  "생길지",
  "이걸로는",
  "망가뜨린",
  "고모가",
  "날이라고",

  "경례를",
  "남자친구에게",
  "할거라",
  "날들이",
  "나말고",

  "찾는건",
  "지불하고",

  "거부했지",
  "보아라",
  "데려갈게",
  "오락가락",
  "당신한테서",
  "성형",
  "빠지기",
  "돌아왔고",
  "케잌",
  "생각이었는데",
  "구별",
  "일주일만",
  "졸업생",
  "아이디어야",
  "어울리네요",

  "삼룡이가",
  "꼬추를",

  "인턴들과",
  "개한테",
  "지나가다",
  "모트",
  "일으키지",
  "자녀가",
  "위쪽으로",
  "결정이에요",
  "불법이",
  "만두",
  "행한",

  "내려오게",
  "아니었군",
  "가져왔죠",
  "두죠",
  "경에",
  "난다고요",
  "제공하고",
  "치프와",
  "불태워",
  "떠오른",
  "말하더군요",
  "생각해봐도",

  "전요",
  "원장님께서",
  "아일링이",
  "향수를",
  "재주를",
  "쳇",
  "안믿어",
  "얘기네요",
  "남부에",
  "기다리겠어요",
  "욕실에",
  "벗어나서",
  "박리",
  "보이는게",
  "종로",
  "볼거야",
  "도시락을",
  "장식용",
  "재주",
  "빨래도",
  "악마는",
  "지겹지",
  "웃었어",
  "가자는",
  "뺏긴",
  "한병",
  "오징어",
  "준비하라",
  "광식아",
  "같은지",

  "순간에는",
  "택시가",
  "남자나",
  "모퉁이를",
  "정녕",
  "훌륭하네요",
  "여자거든",

  "민호야",
  "알려드릴게요",
  "탓은",
  "오랜만이에요",
  "자네들도",
  "생각나네",
  "잔에",
  "그레이시",
  "동물원이",
  "로저스는",
  "잘거야",
  "봤는지",
  "타샤는",
  "처방",
  "쓰는게",
  "된것",
  "준다니까",
  "타워를",
  "지원한",
  "뛰었어",
  "하면서도",
  "뒈지게",
  "존재하는지도",
  "수트가",

  "좋으련만",
  "라나는",
  "아래를",
  "문제인가요",
  "모건과",
  "가기도",
  "다닌다고",
  "끼칠",
  "병원엔",
  "재활용",
  "주셔도",
  "치곤",
  "덕이야",
  "질문엔",
  "그리하여",
  "펼쳐진",
  "얘기합시다",
  "위스키를",
  "어디다가",
  "무서워해야",
  "베이",
  "그리스도를",
  "얼마나요",
  "장하다",

  "발견했다고",
  "허블이",
  "께",
  "먹었다고",
  "되어있다",
  "아지메요",
  "톱을",
  "멋있어요",

  "허니문",
  "희망과",
  "피터를",
  "야수가",
  "몰러",

  "동성애",
  "된게",
  "창녀들을",
  "났군",
  "톡톡히",
  "왔었죠",
  "대관절",
  "늙은이는",
  "이혼하고",
  "장교가",
  "모킹제이를",
  "얘기하는거에요",
  "여러분들을",
  "정장",
  "에번스",

  "그랬소",
  "퇴각하라",
  "종말이",
  "왕위에",
  "부모들은",
  "아는거지",
  "우물",
  "해야될지",
  "창녀라고",
  "바다의",
  "죄송하네요",
  "뒈졌어",
  "갈바트론",
  "떠날수",
  "남아라",
  "소장이",
  "하나님께",
  "쳤는데",
  "진희야",
  "무늬가",
  "우등생",
  "상품이",
  "잘려",
  "감동",
  "심술궂은",
  "윌슨은",
  "한명도",
  "기회다",
  "그리스월",
  "지켜봤어요",
  "온이",
  "오늘로",
  "모르겠다만",
  "대접할",
  "시계야",
  "농부가",
  "축배를",
  "본성을",
  "난리야",
  "해병",
  "부인이랑",
  "되리라",

  "우선이야",
  "고생하고",
  "경민이",
  "액체",
  "분들과",
  "아니지만은",
  "오겠어요",
  "천성이",
  "경쟁에서",
  "장벽의",
  "뱃속에서",
  "블랙홀입니다",
  "했었던",
  "말이고",
  "채우면",
  "자리야",
  "부통령을",
  "나가려던",
  "걱정이에요",
  "공장장은",
  "콕",
  "올려다",
  "기술적으로",
  "여자잖아",
  "감은",
  "경비대에",
  "나비가",


  "표가",
  "번요",
  "가장자리를",
  "츠나데",
  "들여라",
  "요놈",
  "뺏어가려고",
  "버렸죠",
  "의사선생님이",
  "채프만",
  "던이",
  "누님이",
  "간섭",
  "그룹의",
  "다했다고",
  "평판이",
  "형광",
  "있는건데",
  "데이터는",
  "잃었죠",
  "입힌",
  "편애하는",
  "무서워해도",
  "종교가",
  "노력하겠습니다",
  "만났는지",
  "호들갑",
  "해달란",
  "콜비",
  "느낌인지",
  "보이십니까",
  "돌아가도록",
  "생각할게",
  "화나고",
  "뱉어",
  "지금으로썬",
  "일이란다",
  "증상에",
  "보살필",
  "전기세",
  "원점으로",
  "새삼",

  "터져요",
  "조와",
  "폴라",

  "에피네프린을",
  "인식을",
  "주말까지",
  "이삭",
  "작동해",
  "실습",
  "먹는거",
  "자체",
  "밤하늘",
  "지켰어",
  "물질의",
  "생각대로",
  "지루해서",
  "얘기입니다",
  "이었다",
  "사진이에요",
  "이상하잖아",
  "광부",
  "쪼끄만한",
  "키워",
  "저들도",
  "쏘게",
  "엘리베이터에",
  "가줄래",
  "환경에",
  "처방이",

  "가야겠네",
  "이지수",
  "바늘에",
  "은하들이",

  "오랜만이네",
  "다녀라",
  "똥같은",
  "똥꼬에",

  "고민이",
  "태아는",
  "나간다면",
  "수수께끼처럼",
  "수술방에서",
  "꽝이야",
  "미덕을",
  "리포트",

  "출신이라고",
  "곤란합니다",
  "많진",
  "딸들은",
  "파편",
  "해두고",
  "투명한",
  "놨는데",
  "정보입니다",
  "조건에",
  "나에게는",
  "헛소릴",
  "있는지를",
  "집구석",
  "릉",
  "납니다",

  "사라졌잖아요",
  "좀비",

  "이르지",
  "포도주를",
  "따라선",
  "알려야지",
  "갈거예요",
  "가족입니다",
  "부르겠습니다",
  "신물",
  "고맙습니다만",
  "폐동맥",
  "행사에",
  "비키니",
  "목숨보다",
  "앤디랑",
  "설득하는",
  "호랑이는",
  "하나고",
  "유머감각이",

  "레이첼은",
  "테인",
  "클럽은",
  "비운",
  "바디",
  "탐지",
  "지내면",
  "년이야",
  "해주시오",
  "하기야",
  "이자리에",
  "영웅들은",
  "양과",
  "존경받는",
  "주려고요",
  "은하들을",
  "동물처럼",
  "못했거든요",
  "경력은",
  "모카",
  "걸리겠네",

  "도착하는",
  "니꼴",
  "주치의를",
  "했건",
  "셰일라는",
  "여편네는",

  "저만의",

  "그저께",
  "봤으니",
  "지상에서",
  "연락했어요",
  "항성",
  "가게요",
  "곳이네",
  "길이가",
  "아버님께",
  "각별히",
  "관찰할",
  "겁나는",
  "엄마만",
  "스트라우스가",
  "키멜",
  "이전엔",
  "떠들어",
  "어쩐다",
  "손님한테",
  "오래간만이다",
  "데라도",
  "찢어질",
  "샘플이",
  "광경이",
  "싶어했어",

  "선거",

  "다르잖아요",
  "해결하기",
  "여쭈어라",

  "했다며",
  "담겨",
  "강의를",

  "은화",
  "더워서",
  "영역",
  "연기처럼",
  "하는건가요",
  "빛나고",
  "설명되는군",
  "탔네",
  "에비",
  "그들처럼",
  "환희를",
  "그새",
  "한없이",

  "오크들이",
  "구성되어",
  "심하지",
  "종합",
  "구하라고",
  "저놈을",
  "올라와요",
  "준다니",
  "나같으면",
  "놀랍게도",
  "진행된",
  "엔진은",
  "한걸",
  "그랬다는",
  "진정하시고",
  "사실이예요",
  "지져스",
  "놓는다",
  "열정이",

  "이따위",
  "웃으세요",

  "긋고",
  "병들게",
  "어떡하라고",

  "좌회전",
  "우정",
  "충분하니까",
  "아빠에요",
  "반사",
  "정말이예요",
  "이미지",
  "후드를",

  "죽은지",
  "감사합니다만",
  "거그가",
  "함정",
  "하루하루를",
  "주는게",
  "보자는",

  "해주신",
  "속임수가",
  "망치로",
  "일부분은",
  "뛴다",
  "요정이",
  "브래드는",
  "수염을",
  "어려움이",
  "하얀색",
  "악에",
  "이떻게",
  "육체가",
  "전까지요",
  "전부에요",
  "말요",
  "얘기하지만",
  "방어가",

  "문자로",
  "무당은",
  "우리들과",
  "복되시며",
  "내려놔요",
  "종족이",
  "거랬어",
  "기뻐하소서",
  "뿔뿔이",
  "은총이",
  "분에게",
  "삶도",

  "수염이",
  "진군하고",
  "알아내지",
  "침대와",
  "노예는",
  "꺼지는",
  "보장할",
  "수영장이",
  "오랜만이군",
  "괜찮으셔",
  "감사하겠습니다",
  "지내다",
  "들립니다",
  "위험하다고요",
  "명숙이가",
  "성당에서",

  "고해성사를",
  "시간씩",
  "사기가",
  "지원자가",
  "늦진",
  "머독",
  "본명이",
  "나타나요",
  "볼텍스를",
  "루이씨",
  "분수에",
  "거시길",
  "브라이언이",
  "돼가나",
  "외계인의",

  "하루살이",
  "당신네들이",
  "단체",
  "명숙이",
  "그러는데요",
  "건강이",
  "공간의",

  "돈좀",
  "나름의",
  "앤데",
  "많았다",
  "부품이",
  "씨바",
  "아비",
  "점에서",
  "비행장을",
  "법원에",
  "뿐예요",
  "말하는군",


  "벌기",
  "뒤늦게",
  "낳아서",
  "땄어요",
  "뒈질",
  "받나요",
  "약속하면",
  "거짓말만",
  "없냐고",
  "전화에",
  "실제가",
  "돌아간다면",
  "접근하면",
  "백작",
  "코바가",
  "스페인에서",
  "할란",
  "도구를",
  "있냐는",
  "군은",
  "일으켰어요",
  "대동맥에",
  "성관계를",
  "키스로",
  "풀어줄",

  "아까워서",
  "어쭈",

  "사람들이다",
  "언덕으로",
  "깬다",
  "간수가",
  "때입니다",
  "야인들이",
  "먼지를",
  "절망을",
  "후원자",
  "애나는",
  "벽은",
  "문관",
  "풀면",
  "그림이야",
  "강간은",
  "하셨군요",
  "책임자는",

  "여보쇼",
  "받았거든",
  "세력을",
  "슬론에게",

  "아닐텐데",
  "숲은",
  "바인",
  "창녀야",
  "망나니",
  "설희",

  "트로이",
  "어떤데요",
  "가두는",
  "연대기",
  "곁엔",
  "한놈은",
  "스케쥴이",
  "기억하기",
  "거울은",
  "돌멩이",
  "관둬요",
  "김진규",

  "캡틴을",
  "마르셀라",
  "들은거",
  "할아버진",
  "스카이넷을",
  "작품의",
  "창으로",
  "딱해서",
  "우유가",
  "주인공을",
  "욕심",
  "심장에서",
  "비디오드롬을",
  "계속할",
  "살찐",
  "겨를이",
  "자물쇠가",
  "보내겠다",
  "이정애",
  "방법밖에",
  "쉬다",
  "시카고에",
  "구조는",
  "진찰할",
  "내시경을",
  "기증자의",
  "종류를",
  "스카프를",
  "아니우",
  "노를",
  "인질이",
  "짐짝",
  "우상귀",
  "마크에게",
  "용도로",
  "노름",
  "자체를",
  "들으려고",
  "하려는지",


  "사랑한대",
  "바르드",
  "일어나자",
  "없었더라면",
  "미녀",
  "묻힐",
  "용서하소서",

  "이겨내야",
  "있어야겠어",
  "나와는",
  "손발이",
  "선배는",
  "정연",
  "수면제",
  "재미나",
  "결백해요",
  "교사라니까요",
  "싫다는데",
  "짜증이",
  "여동생과",

  "협조를",
  "오해는",
  "애정이",

  "내놓지",
  "함을",
  "드니",
  "요새가",
  "박스를",
  "찾을게",
  "포인트야",
  "전문가",
  "연필을",
  "독약",
  "공적인",
  "포인트",
  "미첼이",
  "갤러해드",
  "앗싸",
  "교사입니다",
  "첫사랑은",
  "달려있다",
  "소란",
  "갔어야",

  "다고",
  "만나셨어요",
  "믿을게",
  "대의를",


  "어디론가",
  "병원과",
  "드릴을",

  "세대가",
  "첼로를",
  "이겼네",
  "그레함",
  "발론",
  "아니든",
  "영웅이에요",
  "분장",
  "이동할",
  "곳이오",
  "지붕을",
  "태플리가",
  "삼천",
  "이노무",
  "자살이",
  "어린애는",
  "벌어야지",
  "이그",
  "여행해",
  "블록을",
  "쫓기는",
  "임상실험",
  "잠궈",
  "잃어버릴",
  "숨기지",
  "던피",
  "무능한",
  "꼭꼭",
  "졸병을",
  "코바를",
  "라고만",
  "데커",
  "세트",
  "비결은",
  "이해했어요",
  "오럴",
  "경적",
  "정도입니다",
  "가겠나",
  "폭력은",
  "형제처럼",
  "받으니",
  "저질렀는지",
  "벌금",
  "안그랬어요",
  "당신입니까",
  "남편이고",
  "차이점이",
  "백년",
  "빠졌을",
  "장벽으로",
  "불렀잖아",
  "잃기",
  "준씨",


  "갈지도",
  "않는단",
  "괜찮아질거야",
  "데려다가",
  "지옥같은",
  "드웨인",
  "윈터펠로",
  "친구입니다",
  "케이스는",

  "난쟁이들이",
  "갔나요",
  "캠은",
  "말든가",
  "상대방이",
  "캠이",
  "아픔",
  "예정이야",
  "과장을",
  "따라갈",
  "볼튼이",
  "보여줬습니다",
  "클리작씨",
  "책에는",
  "끝나가",
  "리그",
  "리스트는",
  "딕슨은",
  "마셔서",
  "어처구니가",
  "찍으세요",
  "모른다니까",
  "램지",
  "나타났죠",
  "화장품",
  "면상을",
  "너머의",
  "아기는요",
  "이번만은",
  "허는",
  "심박동도",
  "나빠서",
  "섹스로",
  "가기전에",
  "순환이",
  "쓰실",
  "세워야",
  "마나",
  "기회요",
  "예외는",
  "탄다",
  "말하기가",
  "공화국",
  "한거고",
  "기회예요",
  "여자들이랑",
  "나이론",
  "밤인데",
  "형무소",
  "보여도",
  "미사일을",
  "뭐랬어요",

  "동안에는",

  "진지해",

  "시야에서",
  "항구에",

  "외계인이야",
  "오뎅",
  "착각하지",

  "운전하지",
  "미친놈들",
  "식기",
  "분석을",
  "몰아내고",
  "폭력적으로",
  "이동하여",
  "낙태를",
  "오른쪽을",
  "손좀",
  "보여주자",
  "한순간",
  "예쁘단",
  "않았을거에요",
  "되는걸",
  "전기나",
  "전화좀",
  "사이다",
  "꺼내줄",
  "신경외과의가",
  "일으켜서",
  "늦었구나",
  "서명해",
  "거울아",
  "어떡할래",
  "신부의",

  "하나예요",
  "잠재적",
  "소아",
  "가보는",
  "먹고는",
  "안아주세요",
  "죽을뻔",
  "그것만이",
  "대학도",
  "구했잖아요",
  "딱이야",
  "로빈슨씨",
  "돌겠네",
  "보거든요",
  "도사리고",
  "말씀도",
  "아니어서",
  "막아요",
  "차례지",
  "지하실을",
  "내리세요",
  "죽을꺼야",
  "쟬",
  "병사와",
  "봐주길",
  "수고하셨어요",
  "알아내면",
  "팝니다",
  "이슬람",
  "자백을",
  "처하게",
  "있길래",
  "거대하고",
  "열어봐요",
  "알람",
  "여왕폐하",
  "지키라고",
  "상상해보세요",
  "엎드려요",

  "찾아오지",
  "곳인가요",
  "선물이다",
  "웬일이십니까",

  "있더라구",
  "호구",

  "좋은가",
  "일어나야지",
  "엘리베이터가",
  "뭐였더라",
  "표현이야",
  "강화",
  "여부는",
  "나올게요",
  "넣어주세요",
  "참나무방패",

  "쇼에",
  "검사실로",
  "안아보자",
  "뺏어",
  "부인이요",
  "자야겠어",
  "연인을",

  "임신했다고",
  "어딨는",
  "가히",

  "늦은거",
  "풀라스키",
  "안오면",
  "실망시켰어",
  "레이더에",
  "가오",


  "구장을",
  "생겼군",
  "불구",
  "신경쓰여",
  "싫구나",
  "찾느라고",
  "태워버려",
  "그말",

  "기대한",
  "뛰자",
  "근사한데",
  "장인",
  "구원의",
  "괘씸한",
  "살았으면",
  "거유",
  "저주가",
  "않으면요",
  "눈빛은",
  "바꿨지",
  "회복실에",
  "눈치를",

  "뭐유",
  "사무라이가",

  "살인자입니다",
  "우물에",
  "다쳤다고",
  "맞겠지",
  "하셨지만",
  "데킬라",
  "가봤는데",
  "찾아야해",


  "저희들",

  "왔구만",


  "추위를",

  "자랑하고",
  "여자였어",
  "팔콘",
  "교회는",
  "닥칠거야",
  "직원을",
  "보호하라",
  "통제된",
  "보겠다",
  "종교는",
  "아기랑",
  "대답해라",
  "장담해요",

  "딸입니다",
  "잿더미로",
  "보호자가",
  "놀랍지도",
  "터치다운",
  "떠나야만",
  "내줘",
  "베르나테드",
  "빨래는",

  "배다",
  "영장이",

  "벨라",
  "움파",
  "양반한테",
  "장수",
  "뺨을",
  "경솔한",
  "자겠어",
  "나오니까",
  "한군데",
  "터지고",
  "실력도",
  "속엔",

  "밀가루",
  "생에",
  "질환이",
  "바트",
  "어젯밤은",
  "알아들어요",
  "안돼죠",
  "치료하면",
  "몸뚱이를",
  "제레미는",
  "세실리아",
  "걱정하기",
  "지긋지긋한",
  "강요하는",
  "그럴겁니다",
  "모금",
  "의미야",
  "사이지",
  "감마",
  "살지만",
  "첫날인데",
  "모셔라",
  "노무",
  "엄마지",
  "수용소",
  "싸이",
  "글짓기",
  "쥐뿔도",
  "놈이라",
  "치워버려",
  "낫겠네요",
  "원만",
  "목표물이",

  "며칠이나",
  "핫도그",
  "왕한테",
  "인형이",
  "갠",

  "엉망이네요",
  "자연을",
  "시작하는데",
  "뜻입니까",
  "그리티",
  "종교적인",

  "못혀",
  "시간엔",
  "많았지만",
  "목사님이",
  "분명하게",
  "천장에",
  "단백질",
  "미친거야",
  "힘드네",
  "농담하세요",
  "기발한",

  "불만을",
  "얇게",
  "조직에서",
  "팔자를",
  "알진",
  "말씀하십시오",
  "지옥도",
  "고무",
  "갔거든",

  "닉의",
  "사람밖에",
  "신중한",

  "될때까지",
  "깨우면",
  "최초",
  "재판에",
  "교육에",
  "다쳐야",
  "이해해야",
  "과학에",
  "졸업을",
  "사과도",
  "강가에서",
  "의사들의",

  "글레그",
  "재미난",
  "현명하고",
  "뒤틀린",
  "가호가",
  "가지고는",
  "이모를",
  "문제니",
  "일정한",
  "청량리",
  "다른것도",
  "익명으로",
  "집세",
  "데이타로",
  "엘리엇은",
  "사람살려",
  "해대는",
  "신어",
  "유해",
  "실패했어요",
  "잤구나",
  "엄마로서",
  "멀다",
  "함께일",
  "앉는",
  "변호사에게",
  "포크를",

  "저주하고",
  "시바",
  "밖에도",
  "에드먼즈",
  "장애물이",
  "대응",
  "검사하는",
  "머리나",
  "속이려",
  "징조야",
  "공식적으론",
  "고생하는",
  "놓쳤나",
  "종잡을",
  "하운드",
  "그러렴",
  "까먹고",
  "항구",
  "말죠",
  "변소",
  "웨이컴이",
  "방어를",
  "한가운데서",
  "해봤지만",
  "윤",
  "메이브를",
  "낮잠을",

  "공장이",
  "열여섯",

  "카루이",
  "무너지기",
  "양이랑",
  "말까",
  "슈미트는",

  "요청할",
  "전단지",
  "목도리",
  "아니면서",
  "똥이나",
  "허기사",
  "녹아",
  "그자를",

  "총이야",
  "보호해야지",
  "아메리카가",
  "언덕에",

  "열에",
  "거처를",
  "키만",
  "밀러의",
  "자르게",
  "중국은",
  "근처를",
  "심했어요",
  "손녀를",
  "죽든",
  "타마노조",
  "그럴거에요",

  "가르쳐요",
  "줄테니",
  "맡겨도",
  "말씀으로는",
  "답례로",
  "드셔보세요",
  "들른",
  "측정",
  "생각했소",
  "했다니까요",
  "틀렸으면",
  "됬지",
  "만들수",
  "데비가",
  "정신차려요",
  "불편을",
  "초대했어요",
  "죽더라도",
  "목소릴",
  "해볼까요",
  "즉흥적으로",
  "찾을때까지",

  "이스트",
  "마지막이라고",
  "정호",
  "들구",
  "심장외과",
  "난잡한",

  "주디스",
  "갔겠지",
  "명이야",
  "식",
  "우리에게는",
  "아니었다고요",
  "맡아봐",
  "대부",
  "공격했어요",
  "되살려",
  "장도",
  "씨나",
  "가져오게",
  "하고픈",
  "논리적으로",
  "결정하고",
  "나중에는",
  "미안해하지",
  "보내준",
  "부스가",
  "고속도로에서",
  "소방",
  "캐슬블랙으로",
  "들을래",
  "중이라면",
  "방콕에",
  "말씀드렸듯이",
  "무신론자",
  "낱낱이",
  "개편",
  "계시나",
  "없어지기",
  "달리도록",
  "즐거웠습니다",
  "싶은대로",
  "관심있는",
  "집사",
  "다녀야",
  "선택이에요",
  "정도만",
  "모드로",
  "세팅",
  "본거",
  "화장실도",
  "사달라고",
  "일류",
  "배달을",
  "힘들지만",
  "해커가",
  "언더그라운드",
  "말테다",
  "나을까",
  "견과가",
  "그러면서도",
  "측면",
  "걸렸는데",

  "결말을",
  "들어보니",
  "꿈자리가",
  "절개할",
  "공허한",
  "숙소에",
  "오토봇은",
  "의뢰해",
  "천사를",
  "왼쪽을",
  "해줘야겠어",
  "강해서",
  "객관적으로",
  "건수",
  "의외로",
  "존재한다",
  "까마귀가",
  "생각이면",
  "건너편",
  "경찰관",
  "영광",
  "거저",
  "노르웨이",
  "사라지기",
  "비단",
  "멀쩡하다고",
  "싱어",
  "질문이군요",

  "가정이",
  "고용해서",
  "경주를",
  "다음이야",
  "인사해라",
  "않았어도",
  "모친",
  "왕국이",
  "알아야해",


  "들어줄래요",
  "미인이",
  "되기엔",
  "구할거야",
  "순간이야",
  "없을거예요",
  "가방안에",
  "필립은",

  "나서도",
  "웬만하면",
  "그쪽한테",
  "다니다가",
  "경우의",
  "그럴거예요",
  "스폰지",

  "씻었어",
  "저리가요",
  "인연",
  "떠난지",
  "배이커가",
  "중이었죠",
  "가지라고",
  "아들이다",
  "치프도",
  "로웨나",
  "불행의",
  "도망치게",
  "떠났지",
  "먹여주고",

  "오르지",
  "대의",
  "꽉잡아",
  "시켰죠",
  "가느냐",
  "시끄럽다",
  "재능은",

  "만드는게",
  "손전등",

  "뭐라나",

  "탈장",
  "스테판이",
  "이끌게",
  "국",
  "계란들",
  "돌려주지",

  "가짜야",

  "말씀드려야",
  "치라고",
  "돌았어",
  "우리나라에서",
  "넘겨줄",
  "새파란",
  "생각난다",
  "로마",
  "살아간다",
  "봤구나",
  "포상금",
  "걱정했어요",
  "요전",
  "안정적이야",
  "베일리예요",
  "두자고",
  "도전",
  "경제적인",
  "돼서요",
  "차트가",
  "장담해",
  "상처주지",
  "문이나",

  "필요없는",
  "장시간",
  "생각하기엔",
  "났으니",
  "수준으로",

  "올거예요",
  "시달리고",
  "가젤",
  "만회할",

  "논다",
  "출신이지",
  "방식의",
  "중반",

  "얍",
  "장모",
  "리차드는",
  "외계인들이",
  "가버렸어요",
  "찾아왔습니다",
  "얼음에",
  "돌아올께요",
  "정신분열증",
  "낭비일",
  "발표가",
  "승객을",
  "숨기",
  "오류가",
  "아내야",
  "마주치지",
  "발전은",
  "바뀌어",
  "건널목",
  "메모리얼",
  "스트리트",
  "먹을게",
  "급속",
  "잘지내",
  "귀는",
  "타냐",
  "흙",
  "참겠어",
  "탔던",
  "팩",
  "잤다는",
  "외과의사야",
  "들어갔지",
  "되려는",
  "찰칵",
  "멋지세요",
  "이사회",
  "긴장하지",

  "바야",

  "승리할",
  "법으로",
  "패닉에",
  "보라지",
  "겁주려고",
  "시대야",
  "변치",
  "앉으시오",
  "사쿠라",
  "기적적으로",
  "워커를",
  "할거지",
  "절대로요",


  "서야",
  "욕망이",
  "만스는",
  "뿐이",
  "벼락",
  "오딜",
  "라던가",


  "웃음거리가",
  "장애를",
  "이해했나",
  "백성",

  "콩밥",
  "넬리",
  "성냥팔이",
  "오토봇들",
  "생각했겠지",
  "가졌으면",
  "바보라는",
  "쫓아가",
  "발언을",
  "나왔잖아",
  "해주렴",
  "낳았다",
  "대단하죠",

  "서두르라고",
  "베이징",
  "환자죠",
  "수상해",
  "있으신",
  "했었소",

  "되십니까",
  "뒤덮은",
  "하시나",
  "모두가요",
  "셰퍼드한테",
  "물었더니",
  "바쁘고",
  "옮긴",
  "나갈게요",
  "그냥이",
  "며느리가",
  "오던",
  "가졌죠",
  "부를게요",
  "환각이",
  "아주머니도",
  "해와",
  "오빠야",
  "정도죠",
  "길핀",

  "기별이",
  "에프",
  "나갔지",
  "토대로",
  "에너지는",
  "남원",
  "갈매기",
  "피클스",
  "남북전쟁",
  "위기를",
  "힘들다는",
  "만들진",
  "말씀을요",
  "무너져",
  "정리를",
  "요청이",
  "싸우느냐",
  "알릴게",
  "어색해",

  "언능",
  "부상의",
  "사겠습니다",
  "좋단",
  "사고도",
  "결혼이요",
  "일제히",
  "떠봐",
  "판단은",
  "엉덩이도",
  "열려요",
  "시설로",
  "떠날까",
  "대학생",
  "해왔어",
  "생각인가요",
  "떠나려",
  "환영받지",
  "커피도",
  "나아가고",

  "후배",
  "두렵게",
  "요상한",
  "누구여",
  "잠시만이라도",
  "베푸는",
  "웅크리고",
  "알고리즘",
  "마이애미",
  "누구십니까",
  "두자",

  "의사였어요",
  "고이",
  "카운셀링",
  "해줄수",
  "마쉬",
  "부어",
  "경험은",
  "지라도",
  "텔레비전",
  "오게나",
  "계집이",
  "기회만",

  "했더라",

  "끝나자마자",
  "프랑스어",
  "죽이라는",
  "보여줘봐",
  "맞선",

  "사빈느",
  "매달린",
  "별소릴",
  "바랐던",
  "하실까요",
  "아직두",
  "전두엽",

  "달랐다",
  "폐하가",
  "제일로",
  "선반",
  "애였어",
  "돌아가시게",
  "뽀뽀해",
  "서양",
  "드렸어",
  "잘하세요",
  "정복",
  "계획입니다",
  "이하",
  "기껏해야",
  "처리를",
  "즐거웠다",
  "진찰",
  "식물인간",
  "남동생이",
  "제왕절개",
  "좀처럼",
  "물질과",
  "잔소리를",
  "고래를",
  "아카츠키는",
  "사하여",
  "확률",
  "만수",
  "평소에는",
  "예잇",
  "자도록",
  "퇴직금",
  "샘플",
  "점원이",
  "젤리를",
  "강해지고",
  "사랑했고",
  "창자",
  "두수씨가",
  "호텔도",
  "영상이",
  "인주력을",

  "입맛",
  "올드",
  "돌아보고",
  "무거워요",
  "누에",
  "일행이",
  "나오너라",
  "북서쪽",
  "오메가를",
  "딕비",

  "아줌니",
  "라이스",
  "같던데요",
  "천을",
  "일이라고요",
  "창밖으로",
  "아나벨라",
  "통화했는데",
  "계셨던",
  "모욕하지",
  "지금이에요",
  "점만",

  "인생이란",
  "겨울엔",
  "밥값",
  "팔았어",
  "피하기",
  "끌어올려",
  "읽게",
  "비난하지",
  "예수가",
  "쥐약을",
  "했으면서",
  "메러디스예요",
  "들어갔는데",
  "만들까",
  "존재해요",
  "때지",

  "번째가",
  "세리나는",
  "늙을",
  "암세포가",
  "맛만",
  "제작자",
  "도로로",
  "일거리를",
  "빨리도",
  "명예가",

  "영주는",
  "다녔죠",
  "복용으로",
  "잡아먹을",
  "닥",
  "도처에",
  "안협댁",
  "그런거에요",
  "봤지요",
  "그렇기에",
  "죄인은",
  "거의요",
  "나루토를",
  "하려구요",
  "음료수를",
  "이럇",
  "일주일이",
  "낳으면",
  "터보",
  "닉과",
  "소용도",
  "그레이랑",
  "기다리는거야",

  "강요하지",
  "오진",
  "나이테는",
  "파트너야",
  "외과의를",
  "치타우리",
  "하는거예요",
  "바이러스에",
  "먹을걸",
  "경련을",
  "퇴근",
  "최우선",
  "찾아주셔서",
  "드리세요",

  "덕을",
  "페이스북에",
  "놀기",
  "워드가",
  "알았어야",
  "있다지",
  "도착해야",
  "선택하는",

  "멋있네요",
  "그랬을까요",

  "긁어",
  "새들을",

  "맨발로",
  "흉악한",
  "먹이지",
  "미련",
  "안되지만",
  "버렸잖아",
  "테니깐",
  "미련한",
  "너희한테",
  "홀로그램",
  "변기에",
  "이까짓",
  "해안에서",
  "있으란",
  "삼가",
  "운송",
  "카드는",
  "공통의",
  "시키려고",
  "전사다",
  "소박한",
  "수다나",
  "그거라면",
  "실려",
  "생각치",
  "존재합니다",
  "맥피",
  "파열",
  "앉아만",
  "맛없는",
  "악몽은",
  "손톱으로",
  "건배하자",
  "요런",
  "배역을",
  "쓰러",
  "솔란도",
  "소화",
  "경험도",
  "상금을",
  "관점이",
  "아가씨한테",
  "낭비하는",
  "대니얼",
  "붙잡혀",
  "해달라는",
  "가문에서",
  "발생하지",
  "바이오",
  "사건들은",
  "몰아넣고",
  "불멸의",
  "미쳐버릴",
  "슬로우",
  "못하네요",
  "경수야",
  "분장을",
  "나오려고",
  "긁지",
  "입좀",
  "저기있어",
  "세대",
  "후에야",
  "규범을",
  "다했으면",

  "보신다면",
  "마무리할",
  "눈빛으로",
  "그들한테",
  "빌리고",
  "봤느냐",
  "질을",
  "듭신다",
  "풀기",
  "주장이",

  "나가셔야",
  "감추기",
  "지체할",
  "내캉",
  "안쪽에서",
  "있는거예요",

  "도적이야",
  "제외",
  "따질",
  "하야",
  "항로를",
  "모른다구",
  "우영민",
  "다른사람에게",
  "선원",
  "가는건",
  "유부녀",
  "되었느냐",
  "있든지",
  "아일랜드의",
  "어디가요",
  "퍼시픽",
  "한명이라도",
  "화풀이를",
  "일화를",
  "사위",
  "어련하시겠어요",
  "사람이었습니다",
  "빈티지",
  "죽였던",
  "졸업식에",
  "유지를",
  "벌리고",
  "엉망진창이",
  "사기꾼이야",
  "훔치러",
  "정복할",
  "듣는다고",
  "유리알",
  "수의",
  "어른이야",
  "북동쪽",
  "배포자유",
  "잃어버리고",
  "배와",
  "쉬어라",
  "부끄럽게",
  "아프냐",
  "가슴팍에",
  "자잘한",

  "미니",
  "옥주가",
  "리스본가",
  "농장에",
  "가졌습니다",
  "곳곳에서",
  "알렌이",
  "경찰에선",
  "죽었으니까",
  "치마를",
  "블랙게이트",
  "테사를",
  "거기있는",
  "카리브",
  "부서의",
  "흑사병",
  "비린내가",
  "사랑했습니다",
  "울게",
  "관계라도",
  "새들",
  "알프스",
  "프레이",

  "혁명",
  "장벽에서",
  "생각이죠",
  "경보기",
  "어머니께선",
  "절반만",
  "하느님도",
  "통치를",
  "담",
  "차량으로",
  "마소",
  "믿기질",

  "안토니오",
  "썼네",

  "월급이",
  "방울까지",
  "장비는",
  "그물",
  "여십시오",
  "필요로하는",
  "기숙사",
  "똑똑",
  "모을",
  "맹세하지",
  "없었으니까요",
  "곳이잖아",
  "명뿐이야",
  "몇명이",
  "버려야죠",
  "도로타",
  "뭐다",
  "해두",
  "유감이다",
  "우리라고",
  "막았어요",
  "아아들",
  "간직해",
  "귀엽잖아",
  "잡다한",
  "연료를",
  "주치의",
  "덕태",
  "자신있어",
  "낭군",
  "애당초",
  "들지만",
  "레스트래이드",
  "말하기는",
  "돌려줄래",
  "맛있는데",
  "된거니",
  "나보단",
  "조프리의",
  "사례",
  "수준을",


  "게이트를",
  "춘향일",
  "독일인들이",
  "탱고",
  "개새끼가",
  "우리엄마",
  "총포두님",
  "잃는다면",
  "떠돌아",
  "차례차례",
  "동력",
  "이륙할",
  "노련한",
  "결혼했잖아",
  "일어나도",
  "나하구",
  "좋아할까",
  "이단",
  "경로를",
  "걸리기만",
  "혼잣말을",
  "이루지",
  "발이나",
  "책의",
  "달라졌어요",
  "닫아야",
  "그만이야",
  "제이콥을",
  "것입니까",
  "말해보게나",
  "갈바트론이",
  "조금이나마",
  "되살리고",
  "드레이크",
  "먹이사슬에서",
  "마부",
  "방긋",
  "직원은",
  "토크",
  "서명한",
  "떠나도",
  "정신병원에서",
  "성문",
  "위험해질",
  "났군요",
  "왓슨이",
  "할아버지와",
  "끝끝내",
  "짜식들",
  "최형사",
  "잡지나",
  "방송국에서",
  "처리된",
  "세워놓고",
  "들어있어",
  "지원하는",
  "외투를",
  "넘어져",
  "사건들이",
  "본질을",
  "독가스",
  "보석금",
  "늦었단",
  "주치의랑",
  "것들이죠",
  "층의",
  "뛰듯",
  "나가겠어",
  "살은",
  "않기도",
  "안달족과",

  "기소할",
  "최고이자",
  "떠다니는",
  "나정옥",
  "들어주십시오",
  "그사람을",
  "벌레들이",
  "독에",
  "뻗어",
  "사업상",
  "수영장을",
  "말라면",
  "돌아가셨다고",
  "동전에",
  "서두르고",
  "여행한",
  "약주",
  "있다가는",
  "결정이었어",

  "도련님께서",
  "함께요",
  "보슈",
  "뉴스가",
  "치료사",
  "전이었어요",


  "소용없었어요",
  "의사들을",
  "캠프가",
  "얘기했나",
  "벌이는",
  "백화점은",
  "페이스",
  "사물함",
  "호이안",
  "힘들면",
  "열어주세요",
  "틀고",

  "냈다고",
  "해야겠는데",
  "파워를",
  "아이만",
  "무작위로",
  "사망의",
  "피해요",
  "현명하지",
  "쪼깨만",
  "전하와",
  "뵙겠어요",
  "앰뷸런스",
  "최선이에요",
  "일하다",
  "손님이야",
  "주던가",
  "잘못해서",
  "들겠어",
  "경사가",
  "불쌍혀",
  "사랑만",
  "죄인입니다",
  "우주선의",
  "똑같다고",
  "들린다는",
  "저앤",
  "교육이",
  "오스트리아",
  "부검",
  "스튜를",
  "법이란다",
  "그만둔다고",

  "리무진을",
  "하려거든",
  "긴치",
  "스크류드라이버를",
  "갔다구요",
  "풀을",
  "용맹한",
  "훔쳤다",
  "아프다오",
  "달랐다면",
  "수청",
  "맺지",
  "네명의",
  "이유인지",

  "대공이",

  "오신다",
  "말리는",
  "놈이나",
  "삼촌도",
  "물리학에선",
  "사줄께",
  "보고하지",
  "기억안나",
  "창이",
  "저놈들",
  "필요합니까",
  "고독이",
  "난리도",
  "페기",
  "종적을",
  "무서워해",
  "베일에",
  "오줌이나",
  "오늘부로",
  "오토봇을",
  "증인도",
  "게놈",
  "만사가",
  "궁상",
  "규정상",
  "병동에서",
  "웨버를",
  "회담을",
  "구절은",
  "씨발새끼",
  "텍사스에",
  "왕비의",
  "시키라고",
  "인간들아",
  "이럴줄",
  "보았던",
  "지경으로",
  "쉬엄쉬엄",
  "빠져나가야",
  "빼요",
  "긴장되는",
  "본다고요",
  "쾌활한",
  "뛰어가서",

  "한쪽으로",
  "아니옵니다",
  "순간이다",
  "최미정",
  "동네로",
  "필요할지",
  "안심해도",
  "거위",
  "질겁니다",
  "얘들을",
  "젖었네",
  "일이었는지",
  "인도로",
  "텔러가",
  "헤로인",
  "그때랑",
  "일과가",
  "오신건가요",
  "적습니다",
  "따뜻해지니까",
  "뒤론",
  "새겨져",
  "나성에",
  "옥희씨",
  "회복될",
  "재혼을",
  "딸이랑",
  "목적지가",
  "사명을",
  "어떻지",
  "마셨잖아",
  "충분",




  "얼매나",
  "남대문",
  "그럴때",
  "삼촌한테",
  "꼬부랑",
  "채찍을",
  "버거",
  "악에서",
  "동그란",
  "주옵시고",
  "빗물",
  "하이구",
  "분류",
  "남성으로",
  "레이는",
  "그자리에",
  "세균이라면",
  "고칠수",
  "시민들",
  "십자가에",
  "발생한다",

  "답해",
  "보지를",
  "갈려면",
  "크리스티나라고",
  "선생님이라고",
  "해냈죠",
  "혼인할",
  "데려왔지",
  "주동안",
  "한상",
  "모음집",
  "여자니까",
  "마음씨",
  "스테이크를",

  "당장에",
  "적이라도",

  "비장에",
  "당신요",
  "만날까요",
  "테지만",
  "토네이도",
  "도울수",

  "현상금",
  "금년",

  "하인들이",
  "넣었다가",
  "스카이는",
  "감춰",
  "내려가세요",
  "머무르게",
  "좋아지기",
  "타겟을",
  "냉동",
  "그것보다도",

  "놀랬어요",
  "지침을",
  "몸매를",
  "지키러",
  "왕비님께서",
  "피터한테",
  "돕고싶어",
  "데려가겠다",
  "햇빛이요",
  "끝났다는",
  "소형",
  "스타로드",
  "걸어다니는",
  "얹혀",
  "쥐들이",
  "안정될",
  "바랬을",
  "종자는",
  "수백명은",
  "얼마간",
  "정도에요",

  "폐동맥에",
  "효소",
  "사람까지",
  "놀아나는",
  "좋아하기",
  "마님은",
  "남녀가",
  "중요한거야",
  "난거야",
  "넣었지",
  "끝장나는",
  "주인의",
  "여기의",
  "멘지스",
  "타다",
  "레이디성인용",
  "아니었을",
  "아파치",
  "증",
  "성의를",

  "테나",
  "증언할",
  "전체에서",
  "근무해요",
  "설렁탕",
  "토레스한테",
  "비숍으로",
  "미래에는",
  "때문이란다",

  "적극",
  "수염은",
  "공유를",
  "피트가",
  "폭발하지",
  "충돌은",
  "살림이",
  "소시지",
  "테스트해",

  "금지된",
  "열어줄",
  "살살해",
  "공원으로",
  "거슬리게",
  "괴롭힌",
  "의뢰인은",
  "책상을",
  "세금은",
  "바가스를",
  "부르십니다",
  "낡았어",
  "물읍시다",
  "질량을",
  "가설일",
  "말하라구요",
  "일이었지",
  "맞는디",
  "표정이거든",
  "제이슨의",
  "현재와",
  "철이를",
  "방송이",

  "따라다닌",
  "잡아올게",
  "사용해도",
  "알구",
  "훌륭하군요",
  "수포로",

  "년한테",
  "ㅋㅋㅋ",
  "동면에",
  "결판을",
  "업무에",
  "아브라함은",
  "다방에",
  "지원했어",
  "부서진다면",
  "기둥에",
  "빠뜨린",
  "결정했지",
  "물었는데",
  "스완슨",
  "어디부터",
  "케빈이",
  "구획",
  "저같은",
  "나무도",
  "혼자라고",
  "부은",
  "통증도",
  "차리라고",
  "따라다니면서",
  "그대들은",
  "번번이",
  "강아지처럼",
  "수남아",
  "드미트리",
  "버립니다",
  "고치기",
  "그런다면",
  "일으켰죠",
  "떨리는",
  "들어오기",
  "참여해도",
  "안그럴거야",
  "시어머니",
  "이렇다",
  "사이고",
  "부엌으로",
  "짓이었지",
  "흥분해",
  "보드라고",

  "신식",
  "거주",
  "내보내고",
  "내려오지",
  "치던",
  "급속도로",
  "통화하고",
  "파열될",
  "치유의",
  "풀린",
  "도리를",
  "없어라우",
  "오른손",
  "방주",
  "테이프가",
  "해줄께",
  "바라겠습니다",
  "지지해",

  "동생들을",
  "바뻐",

  "부적을",
  "출신이오",
  "벗어나려면",
  "제시라고",
  "앤디의",
  "속셈이",
  "훔쳐보고",
  "포틀랜드",
  "센터로",
  "가주셔야",
  "이겨내지",
  "선택권은",
  "떨어뜨렸어",

  "안됬어",
  "먹지마",


  "지옥이라도",
  "안돼안돼",
  "열었지",
  "들를게",

  "있을거요",
  "영계",
  "알려드릴께요",
  "어머닐",
  "괜찮겠나",
  "죽었거나",

  "받질",
  "찬송가를",
  "유언장",
  "못들었어요",
  "들었군",
  "원하는거",
  "끼어",
  "인피니티",
  "풀어주면",

  "거미가",
  "부딪힌",
  "신랑이",
  "햇살",

  "비워주세요",
  "복잡할",
  "작업복",
  "때였지",
  "키웠지",
  "볼테니",
  "뽑기",
  "화해할",

  "죄수들은",
  "병문안",
  "포스트잇",
  "잡으라구",
  "간단하고",
  "보는거에요",
  "묻혔어",
  "푸시킨",
  "속옷이",
  "외과의사로서",
  "더들스",
  "할수는",
  "않는거",
  "만드셨다",
  "말까지",
  "억울해",
  "사려깊고",
  "가져야지",
  "명함",
  "하고있는거야",
  "이겨내는",
  "듣는다",
  "털어놔",
  "누구처럼",
  "부친께서",
  "인간이다",
  "동상이",
  "할지는",
  "살았었죠",
  "몸조심",
  "빠진다",
  "부수기",
  "다른사람을",
  "우짠",
  "전송됐어요",
  "비명소리만",
  "튀는",
  "바지는",
  "쓰러지는",
  "이쁘다고",
  "동상의",
  "데니얼",
  "모텔에",

  "붓기가",
  "납으로",
  "식구",
  "던져요",
  "세탁소에서",
  "신청을",
  "모시는",
  "따듯한",
  "놓았어요",
  "모스의",
  "퇴원하기",
  "활동에",
  "껍질이",
  "갈테니까",

  "고정하세요",
  "길동아",

  "수잔네",
  "눈길이",

  "프로덕션",

  "하룻밤에",
  "체크를",
  "예배당에서",
  "모자도",
  "일일까",
  "혁명에",
  "경험하게",
  "칼슘",
  "실실",
  "돌아오세요",
  "빗자루",
  "비유",
  "여성들을",

  "가르쳐줬죠",
  "순간적으로",

  "의사님",
  "절망",
  "잔다르",
  "되돌아가서",
  "싶어하는데",
  "생사람",
  "없을거라고",
  "생각하니까",

  "움직이질",
  "죽인다면",
  "그처럼",
  "결정되지",
  "이쁘지",
  "카이로에",
  "짐작이",
  "모리스는",
  "짓밟는",
  "사양",
  "비장이",
  "날리는",
  "바라야지",
  "볼려고",
  "게임기",
  "오랬동안",
  "네뷸라",
  "스토커",
  "돌아서는",
  "안녕하셨습니까",
  "뜨거워요",
  "굴복하지",
  "생명유지장치를",
  "근사해",
  "살인한",
  "아내로",
  "언제부터요",
  "벽쪽으로",
  "변기",
  "담는",
  "주구",
  "태어났지",
  "의역이",
  "라인을",
  "계란은",
  "홍콩에",

  "괜찮아지겠죠",
  "해보겠습니다",
  "미끄러지고",
  "끌기",
  "읽기",
  "달걀을",
  "아하하",
  "동성",
  "계시를",
  "불쌍하지도",
  "앤디를",
  "다니냐",
  "오라버니가",
  "같구",
  "아저씨두",
  "급격하게",
  "탈출구는",
  "나뭇가지가",
  "감염됐어요",
  "간으로",
  "펜으로",
  "끝났나",
  "적었어",

  "요구되는",

  "독일어",
  "하고있습니다",
  "나갈까",
  "비밀번호는",
  "앗아갈",
  "동공이",
  "다치기",
  "복종하지",
  "없더라고요",
  "그릴",
  "호감을",
  "이리줘",
  "감독을",
  "남자친구의",
  "리벨",
  "아껴서",
  "코르소씨",

  "남자친구요",
  "알아봤어요",
  "다이너마이트",

  "패배를",
  "싶어했죠",
  "할망구가",
  "오래됐어",
  "나가야겠어",
  "이야기하자",
  "든다고",
  "부커",
  "처먹고",
  "바르셀로나",
  "움직임",
  "허고",
  "봐야만",
  "가깝다",
  "들어봤을",
  "양파가",
  "머리칼",
  "노스",
  "북부에서",
  "마데른",

  "부끄러워요",
  "부끄럽지만",
  "미린으로",
  "수술실에는",
  "명이지",
  "믿었던",
  "국대",
  "못했잖아",
  "새끈한",
  "못지",
  "장모님이",
  "차장",
  "탈리",
  "연희가",
  "놀아야지",
  "땀시",
  "정했어요",

  "젖꼭지가",
  "말투는",
  "오셨수",
  "머리야",
  "아가르타에",
  "삼춘",
  "밝아",
  "너바나",
  "코너를",
  "심하진",
  "여서",
  "아빠는요",
  "남김없이",
  "같고요",
  "즈그",
  "송정란",
  "긍게",
  "색다른",
  "밀크",
  "일부라고",
  "술좀",
  "좋아하던데",
  "명성",
  "책이요",

  "메레디스",
  "코드블루",
  "골랐어요",
  "누님은",
  "우리들도",
  "생신",
  "쉽다",
  "안되면",
  "바로잡을",
  "클라라라고",
  "누군간",
  "세일즈",
  "되찾는",

  "사올",
  "딸들이",
  "박차고",
  "올라가려고",
  "선택하면",
  "루머가",
  "못했다면",
  "도와줬어",
  "날개로",
  "따라라",
  "없어지겠지",
  "고상하게",
  "다하지",
  "남들보다",
  "느껴지지가",
  "다이어트를",
  "바라보면",
  "국가대표",
  "한발짝",
  "여성들이",
  "맺는",
  "일말의",
  "망설임도",
  "농장으로",

  "찾았니",
  "말순",
  "우정의",
  "용량으로",
  "적정",
  "없었기",
  "적이지",
  "숨쉬기",

  "호빗",
  "사과나",
  "붙어있어",
  "누님을",
  "있냔",
  "간부가",
  "부회장님",
  "줄로만",

  "여기오면",
  "괜찮을거예요",
  "하지말라고",
  "늦지마",
  "스키를",
  "봉투를",
  "챙",
  "쯤이야",
  "아들에",
  "김삼수는",
  "알아보기",
  "할땐",
  "비밀이에요",
  "관계자들이",
  "멈춰봐요",
  "워커가",
  "그다음",
  "아이들에",
  "수근",

  "잡을게요",
  "장애물을",

  "잔다는",
  "걸었잖아",
  "장학금을",
  "돌아오니",
  "기분이었는지",
  "산장에",
  "안고파",
  "앞장",
  "헬리콥터",
  "보마",
  "지켜봤죠",
  "물어봤는데",
  "물어보라고",

  "어디있나요",
  "안쪽에",
  "천국인데",
  "미안하다는",
  "맡겼어",

  "엔",
  "밀러가",

  "기억의",
  "훈련받은",
  "일부분만",
  "사과했어",
  "않을지도",
  "나간거야",
  "왔느냐",
  "바우메스",
  "앉으렴",
  "허파를",
  "숨기려",
  "올때까지",

  "대가리에",
  "도와드릴께요",
  "엄청나지",
  "방법이라도",
  "양에",
  "빨갛게",
  "놀랍게",
  "걱정돼요",
  "쓰기엔",
  "기대감",
  "아줌마한테",
  "사나요",
  "섹시하잖아",
  "일요일은",
  "줄에서",
  "아가르타로",
  "삐또리우아는",
  "줘야겠다",

  "말씀드리기",
  "공유할",
  "안타깝군",
  "행동들을",
  "전쟁통에",
  "한낱",
  "나가버려",
  "전번에",
  "뻔했다고",
  "출발하는",
  "말씀하셨잖아요",
  "문관님",
  "비용",
  "아버지셔",
  "형님의",
  "살고싶어",

  "얼간이를",
  "세기를",
  "팔게",
  "연애도",
  "종자가",
  "차지야",
  "채우지",
  "필리",
  "루씨",


  "가지마요",
  "견디세요",
  "여기좀",
  "드라이버",

  "점퍼",
  "싫여",
  "심어",
  "것이라곤",
  "파편에",
  "샘과",



  "실망하지",
  "정도까지",
  "죽였단",
  "주란",
  "겝니다",
  "누구로부터",
  "허브",
  "근사할",

  "갱",
  "남편이죠",
  "내것이",
  "오르게",
  "파서",
  "럭스가",
  "인터넷의",
  "입학",
  "연회에",
  "휴일",
  "시킨대로",
  "하자는대로",
  "주책없이",
  "필요했습니다",
  "존중을",
  "멕시코로",
  "당사자",
  "명옥이란",
  "물어보잖아",
  "태어났죠",
  "생일도",
  "보셨습니까",
  "무턱대고",
  "안가면",
  "풀렸다",
  "그러디",
  "돈이냐",
  "본사에서",
  "놈이다",
  "생사가",
  "업보는",
  "지금과",
  "오해야",
  "생각이지만",
  "올려줘요",
  "빛납니다",
  "가스와",
  "유럽은",
  "놀랍습니다",
  "감염자들이",
  "대통령이라든가",
  "따름입니다",
  "전송할",
  "말해야겠어",
  "도와주려",
  "됐다고요",
  "남아요",

  "끝났거든요",
  "속고",
  "뗏목에",
  "아침의",
  "달라구요",
  "건전지",
  "하시면서",
  "낭비예요",
  "싶겠어요",
  "깨달음을",
  "미안한데요",
  "그보다도",
  "집사람",
  "황",
  "시작되었습니다",

  "지팡이",
  "술값",
  "되구",
  "댕겨",
  "눈먼",
  "도합",
  "소장은",
  "마땅치",

  "뺏기고",
  "아들이고",

  "일컬어",
  "탈출하는",
  "돌아다니면",
  "뚱뚱해서",
  "저기를",
  "쓰니",
  "죽이는걸",
  "하나만은",
  "션이",
  "따뜻할",
  "연결되는",
  "국민의",
  "아슈",
  "누나야",

  "포가",
  "거라니",
  "득이",
  "연구할",
  "복제",
  "폭탄에",
  "망원경은",
  "끝이지",

  "염산을",
  "황경민",
  "대중이",
  "쌈바",
  "모가지가",
  "인상착의를",
  "요리해",
  "고르고",
  "늦잠",
  "풀어줘라",
  "먹겠습니다",
  "갖도록",
  "이거예요",
  "약속만",
  "옥경이는",
  "만든다면",
  "옥경이가",
  "의지와",
  "존재예요",
  "어기지",
  "그때가서",
  "똑같다는",
  "참새",
  "하이가든의",
  "꿩",
  "도구도",
  "생각인지",
  "혼수",
  "마치는",
  "이식술을",
  "갖춰",
  "질량이",
  "명령하지",
  "여하간",
  "변명의",
  "만나야겠어",
  "사람하고는",
  "아닌걸요",

  "놀아나고",
  "있질",
  "오겠죠",
  "죄송하게",
  "헬레나의",
  "되건간에",
  "몫으로",
  "십자가",
  "사망자가",
  "흩어지거나",
  "라이온",
  "카레브랑",
  "괘안타",
  "만나거든",
  "사람끼리",
  "울어도",
  "조각들이",
  "유령처럼",
  "올라온",
  "로라는",
  "매를",
  "조명보",
  "마침표",
  "같아선",
  "석출이",
  "만나지도",
  "지불합니다",
  "되있어요",

  "스티븐스에게",
  "무덤이라고",
  "돌려주세요",
  "오말리야",
  "아버지만",
  "넓고",
  "이미지는",
  "말이제",
  "볼을",
  "손댈",
  "시작됐네",
  "신물이",
  "혼돈의",
  "셈인가",
  "돈이라면",
  "궁금한데",
  "미안하지",
  "누이는",
  "주겠다는",
  "생전",
  "가십니까",
  "변호를",
  "햐",
  "왜이리",
  "올라서",
  "목숨으로",

  "똑똑이",
  "가자니까",
  "순이야",
  "어딘가요",
  "내방에서",
  "옥경이를",
  "과장의",
  "듣고보니",
  "별안간",

  "핸드폰은",
  "다가오는데",
  "우준이",
  "농구를",
  "내용입니다",
  "안승필",
  "값이야",
  "많아야",
  "보이지가",

  "우주인",
  "지하야",
  "무대로",
  "일로와",
  "뽑지",
  "친구지간에",
  "두세",
  "마시겠어",
  "신부님을",
  "속으로는",
  "유학",
  "바뀌길",
  "위해서입니다",
  "알았나요",
  "이런짓",
  "땀에",
  "복남이",
  "길리",
  "기초적인",
  "분야를",
  "브릭",
  "버렸다고",
  "절벽을",
  "시비를",
  "어디나",
  "없잖습니까",
  "멍청이들은",
  "새겨들을게요",
  "지도에",
  "부작용으로",
  "함이",
  "선언서를",
  "짓이나",
  "매뉴얼",
  "조깅",
  "몇개나",
  "발톱을",
  "바와",
  "에어락",
  "생식",
  "원본",
  "지도는",
  "하든가요",
  "옆에다",
  "펜이",
  "좀비로",
  "흥분되는",
  "열망을",
  "날으는",
  "훌륭한데",
  "저까지",
  "했잖여",
  "녹여",
  "운전자가",
  "것엔",
  "세월은",
  "여기라고",
  "잔거야",
  "찬성",
  "팻",
  "킨",
  "써먹을",
  "믿겠으면",
  "모리아티의",
  "지역의",
  "둘씩",
  "이기적이야",
  "아가씨와",
  "이었지",
  "상징하는",
  "인도하는",
  "아니였다면",
  "넘어가요",
  "윙윙",
  "그때나",
  "스웨덴",
  "아이구야",
  "스내처가",
  "찾아냈죠",
  "미국을",
  "몇번을",
  "소방관서",
  "편이라고",
  "팔았어요",
  "상체를",
  "말했다시피",
  "영광의",
  "찾아야합니다",
  "있었더라도",
  "부츠",
  "알마",
  "분위기에",
  "증명서",
  "벌러",
  "되면은",
  "해드려",
  "건네줄",
  "스타일은",
  "팔코니에게",
  "필요하다구",
  "황량한",
  "대담한",
  "떨어뜨리면",
  "물어봐줘서",
  "교전",
  "요청해",
  "의견입니다",
  "대씩",
  "못했단",
  "숟가락을",
  "배경",
  "친구였네",
  "답장도",
  "전제",
  "고용했어요",
  "클랜",
  "위선자",
  "탐욕스런",
  "괜찮으면",
  "옷장을",
  "감시카메라",
  "취미로",
  "해적을",
  "전구",
  "진실과",
  "통이",
  "가지의",
  "필요할거",
  "지나도록",
  "경비들이",
  "용기있는",
  "해주셨어요",
  "메뉴를",

  "아닌지는",
  "보존",
  "커터가",
  "색깔을",
  "도른을",
  "보증기간",
  "샌프란시스코에",
  "생각들이",
  "샬롯이",
  "캐롤은",
  "로우보로우",
  "콘",
  "조난실의",
  "않으며",
  "결심했어요",
  "가르칩니다",
  "놓을게",
  "죽음이요",
  "로키를",
  "놨다",
  "대화하기",
  "묶어두고",
  "존경과",
  "왕자와",
  "감자는",
  "트럽쇼",
  "젖었어",
  "그냐",
  "낮아서",
  "괴롭힐",
  "할머니와",
  "잘못했나요",
  "족히",
  "정오",
  "양반도",
  "믿도록",
  "여기있네",
  "목격했다는",
  "미뤄",
  "출처",
  "사냥꾼들이",
  "원숭이는",
  "러브크래프트는",
  "둔한",
  "줬던",
  "여러가지의",
  "잡아당겨",
  "들이는",
  "명령으로",
  "의미합니까",
  "미소가",
  "규범이",
  "니미럴",
  "수도를",
  "자책",
  "좌석을",
  "환자인데",
  "기다릴까요",
  "운전하면",
  "물론이에요",
  "다리와",
  "기뻐하실",
  "거절한",
  "체중이",
  "말씀드렸어요",
  "재밌니",
  "아울러",
  "아치발드",
  "병원에도",
  "발전기가",
  "강연회",
  "당하면",
  "아치",
  "비빔밥",
  "아들들",
  "호출이야",
  "잡았네",
  "받았었어",
  "콧수염",
  "팔코니와",
  "빌겠어요",
  "온다는데",
  "있기도",
  "폭력으로",
  "진리는",
  "신고하지",
  "대통령에게",
  "페인",
  "적부터",
  "실험실은",
  "사람에게만",
  "살때",
  "살해하고",
  "쓰냐",
  "상상만",
  "유리에",
  "조언이",
  "왕도",
  "퐁텐느",
  "실험실을",
  "체중",
  "디커슨",
  "도착했네",
  "않아야",
  "좋았는지",
  "우즈마키",
  "김이",
  "전염병이",
  "환자에겐",
  "적혀있지",
  "돌아오겠다고",
  "깨달았으면",
  "고소도",
  "됐대요",
  "회담",
  "용감히",
  "젊은이들은",
  "공포는",
  "아닌디",
  "본능은",
  "무사하지",
  "자던",
  "박스트롤은",
  "중이래",
  "분이셨어요",
  "가책을",
  "전화까지",
  "느낌이었어요",
  "책과",
  "저지르지",
  "반년",
  "사생활",
  "밀리슨트를",
  "자살하려고",
  "일부의",
  "찾아내고",
  "영양분을",
  "생존자들을",
  "부하들을",
  "인물은",
  "헐크로의",
  "바뀌었네",
  "거래의",
  "그리고선",
  "모시죠",
  "지켜보면서",

  "두말",
  "캐롤이",
  "알아냈죠",
  "이대로가",
  "중독된",
  "운전사",
  "바바",
  "소개하는",
  "마음에서",
  "불행이",
  "내용이야",
  "막힐",
  "투나잇",
  "들리냐",
  "규정은",
  "자비에",
  "중요성을",
  "잘못됐어요",
  "타스의",

  "승인을",
  "임상실험을",
  "커트",
  "옆의",
  "크리스티안",
  "우습죠",
  "주름이",
  "자지를",
  "빠뜨릴",
  "장치에",
  "태어났다고",
  "겸손한",
  "단서도",
  "안좋은데",
  "살인지",
  "톡",
  "벤이",
  "보였죠",
  "킹스랜딩으로",
  "이별은",
  "피로감",
  "변호사의",
  "되어있지",
  "요원들은",
  "임무로",
  "그러시군요",
  "나타내는",
  "있었잖나",
  "걸었지",
  "주제로",
  "재피",
  "위협적인",
  "스미스입니다",
  "사실이란",
  "드리라고",
  "했던건",
  "섬기고",
  "적는",
  "이해하는데",
  "사죠",
  "멀리도",
  "왕대비",
  "운전이나",
  "목은",
  "찍으면",
  "슬퍼서",
  "허둥지둥",
  "알아봐야겠어",
  "지배인이",
  "성의",
  "여러분한테",
  "취미를",
  "정확히요",
  "수감자",
  "오바마",
  "세심하게",
  "배짱을",
  "잡아야죠",
  "덥네",
  "도둑놈아",
  "생각하던",
  "훔쳐간",
  "사항을",
  "녹여서",
  "시작하네",
  "배우예요",
  "사라졌소",
  "기관에서",
  "하녀가",
  "보던데",
  "거짓말이지",
  "실수했어",
  "인적",
  "제시카",
  "바바둑은",
  "사람입니까",
  "시절에는",
  "유전",
  "에피소드를",

  "구절을",
  "심야",
  "상상의",
  "들어가겠습니다",
  "자들에게",
  "식사가",
  "틀리면",
  "드려야죠",
  "아낌없이",
  "불어라",
  "분해서",
  "말해보시지",
  "해주겠나",
  "뿐이라구",
  "어쩌자고",
  "웬일인가",
  "일정",
  "타인을",
  "사랑스럽구나",
  "줌",
  "선물일세",
  "탐정을",
  "해외로",

  "글씨가",
  "관여하지",
  "막기위해",
  "남자라",

  "묶었어요",
  "아들들은",
  "기묘한",
  "비행법을",
  "저항하는",
  "쓰시나요",
  "될거라",
  "알아와",
  "자취를",
  "손잡이가",
  "재갈을",
  "속에서는",
  "고양이에게",

  "에일",
  "있어봐요",
  "폼나게",
  "인큐베이터에",
  "어디가는거에요",
  "질문이라도",
  "있음에도",
  "허들",
  "교도소가",
  "마술사",
  "먹이면",
  "평화로울",
  "쓰이지",
  "걸음을",
  "탓을",
  "모든일이",
  "안도",
  "스파이로",
  "시골에서",
  "끄집어",
  "장엄한",
  "있을테지",
  "위성을",
  "어서가요",
  "것들로",
  "가서는",
  "죽이겠어",
  "낼까",
  "강물이",
  "저명한",
  "프랭클린은",
  "따르십시오",
  "절반도",
  "옷부터",
  "나눠주는",
  "리사와",
  "모험에",
  "하겠군",
  "허황된",
  "근본",
  "항복해라",
  "영국인들이",
  "멈췄으면",
  "은총을",
  "그림이나",
  "애들이잖아",
  "위긴스",
  "놓거라",
  "산책이나",
  "친숙한",
  "소리군",
  "네트워크를",
  "이동하고",
  "불었다",
  "되는겁니다",
  "추격해",
  "누",
  "생물학",
  "희망이야",
  "원망하지",
  "산골",
  "둘이야",
  "브리엔",
  "이혼했고",
  "브로드웨이",
  "늙은이도",
  "알아냈군",
  "않을거라는",
  "찐",
  "윙크였던",
  "안타깝군요",
  "들어가려면",

  "교회로",
  "뒤쪽으로",
  "전하라",
  "아이스크림이",
  "도둑질을",
  "토네이도를",
  "마법사를",
  "점으로",
  "놓쳤네",
  "관제탑",

  "독실한",
  "참회자",
  "기사의",
  "뒤에나",
  "살진",
  "스타킹",
  "가라사대",
  "예술품",
  "명호",
  "사람들이군",
  "아티프가",
  "걸맞은",
  "따라와서",
  "건강도",
  "있을께요",
  "입장권",
  "고용할",
  "귀로",
  "영화관",
  "잡는데",
  "프리",
  "부모로서",
  "게이란",
  "타이렐과",
  "없었지요",
  "날렸어",
  "실패하는",
  "자신이나",
  "질병은",
  "수치야",
  "놀려",
  "칩",
  "도망가라고",
  "장례식은",
  "캐비",
  "사실입니까",
  "무슨생각",
  "선택하게",
  "기도해",
  "같은거요",
  "안아줘요",
  "봐주겠어",
  "계셨는데",
  "입이나",
  "통과한",
  "준비하게",
  "결석",
  "음악이나",
  "알았습니까",
  "에이미한테",
  "독립을",
  "활개치고",
  "사인하세요",
  "저흴",
  "파열된",
  "해지기",
  "챙겼어요",
  "계약서를",
  "공주처럼",
  "느낍니다",
  "파고다",
  "신분",
  "형사님이",
  "돌아갈게",
  "앉았다",
  "처음봐",
  "미팅에",
  "동안엔",
  "상징이야",
  "돌아오겠지",
  "영수증을",
  "알고도",
  "네게는",
  "스톤월",
  "아내인",
  "어니를",
  "드러내지",
  "자니까",
  "비우면",
  "연주가",
  "돼지일까",
  "끼치고",
  "편할",
  "나오네요",
  "소일까",
  "않았대",
  "뭉치는",
  "불꽃놀이",
  "꿈속으로",
  "감당하기",
  "메모를",
  "한순간에",
  "정하면",
  "갈거니",
  "생각해라",
  "잃었단다",
  "줄어들고",

  "되셨나요",
  "산장",
  "잃는다는",
  "술잔",
  "어지러운",
  "지른",
  "기관총",

  "레티를",
  "석션해",
  "오는데요",
  "하네만",
  "어쩌려고요",
  "미친게",
  "들었잖아요",
  "토리는",
  "벗어난",
  "얻는게",
  "불안",
  "도망쳤어요",
  "말했어야지",
  "장애는",
  "남아야",
  "지으며",
  "놈한테서",
  "싶던",
  "죽이려면",
  "정통",
  "창녀와",
  "카림",
  "본론만",
  "애미야",
  "그애와",
  "캐드",
  "잠재의식을",
  "챙겨줘",
  "숨겨주고",
  "뇌척수액이",
  "가르쳤다",
  "등도",
  "않는거지",
  "말씀드리는데",
  "범죄자가",
  "대가리나",
  "아래층에서",
  "바에는",
  "중격막",
  "살아왔어",
  "땅이라고",
  "나을거",
  "나아서",

  "혈소판",
  "산모는",
  "셰이",
  "일본은",
  "갈기갈기",
  "얘기하는게",
  "지냈고",
  "얘기했다",
  "흔들고",
  "熙楊",
  "이론에",
  "아더는",
  "살인의",
  "행복해야",
  "축제는",
  "성구",
  "마크도",
  "열성적인",
  "식이야",
  "결혼하기",
  "오른쪽이",
  "겁주지",
  "얘네는",
  "수십년",
  "고난을",
  "알아보자",
  "소령입니다",
  "규칙입니다",
  "권동현입니다",
  "버스에서",
  "있는다면",
  "걸었는데",
  "되겠다는",
  "했냐면",
  "해내지",
  "준비완료",
  "타이리스가",
  "두사람",
  "치려고",
  "헷갈리는",
  "말하는군요",
  "것뿐이지",
  "억만장자",
  "수시로",
  "후회가",
  "안에만",
  "가정의",
  "제작한",
  "놀랬잖아",
  "기철씨",
  "그러는거",
  "기어박스",
  "주실거에요",
  "골치아픈",
  "기록과",
  "이래라저래라",
  "투르크가",
  "접근하는",
  "활주로",
  "스키퍼",
  "싶으신가요",
  "인생이죠",
  "살았잖아",
  "동현아",
  "부쩍",
  "중환자실에서",
  "있던거",
  "도착합니다",
  "싶어했잖아",
  "슈뢰더가",
  "하느님께",
  "자신들을",
  "소개한",
  "다른사람의",
  "자격증",
  "몰랐거든",
  "소들은",
  "꾹",
  "저질",
  "되잖소",
  "손잡이를",
  "같았고",
  "쥬스",
  "오신거죠",
  "말조심해",
  "소송에서",
  "워킹데드",
  "떠난다면",
  "나로선",
  "몸조리",
  "시킬까",
  "점심으로",
  "찾아다니고",
  "성분을",
  "데려가기로",
  "잃는다",
  "씻어요",
  "외과의에게",
  "돌아다니며",
  "혈종이",
  "최고였지",
  "질주하고",
  "목적지를",
  "소화기",
  "쓰려면",

  "동경으로",
  "입었다",
  "보드에",
  "합하고",
  "죽을까",
  "한가지가",
  "다카쿠라",
  "형식적인",
  "야밤에",
  "안되게",
  "가명을",
  "천왕폐하",
  "말씀하지",
  "황군은",
  "여부가",
  "해놨어",
  "냉정한",
  "맡았는데",
  "환자분께",
  "적극적인",
  "마돈나",
  "바꿔놓을",
  "고생은",
  "족발",
  "주셨는데",
  "인간적으로",
  "들겠나",
  "무너져서",
  "맺은",
  "구조대",
  "책임도",
  "했을거에요",
  "공급하는",
  "잘라낼",
  "예를들면",
  "관뒀어",
  "당연",
  "아내예요",
  "고소해",
  "하는겁니까",
  "건드렸어요",
  "내리자",
  "심정",
  "가책",
  "믿나요",
  "양자장",
  "사후",
  "아쉽다",
  "내겠습니다",
  "것하고",
  "부르기도",
  "생각할거야",
  "사랑받는",
  "사람답게",
  "수조차",
  "걸어가는",
  "여자친구도",
  "추천해",
  "맡기겠습니다",
  "속삭이며",
  "뽑으면",
  "유머가",
  "코빼기도",
  "자정까지",
  "참가자",
  "꺼내줘요",
  "치즈브릿지",
  "모세를",
  "슈발",
  "치료제가",
  "단위로",
  "돌아왔다는",
  "햇빛이",
  "빠졌나",
  "손해를",
  "어지러워",
  "있었을텐데",
  "긴급상황입니다",
  "놀자고",
  "칩시다",
  "얘기해주고",
  "불행은",
  "종류가",
  "파랗게",
  "퍼레이드",
  "악질",
  "이건요",
  "하고있는",
  "바닷물이라면",

  "용왕님예",
  "포기를",
  "하얗죠",
  "미리암",
  "박사님을",
  "탈출했죠",
  "주인에게",
  "마지막이다",
  "질렸어요",
  "보이던",
  "틸리",
  "곳이니",
  "동키",
  "좀비가",
  "관리하고",
  "받들겠습니다",
  "딘씨",
  "정신이야",
  "그애도",
  "봉사",
  "현장은",
  "비밀의",
  "태석아",
  "그편이",
  "입으세요",
  "장군께서",
  "죠셉",
  "공께서",
  "가줘요",
  "사회적으로",
  "살이지",
  "기억엔",
  "지퍼",
  "국수를",
  "슈투트가르트",
  "소개가",
  "쓰질",
  "묵는",
  "놈들이에요",
  "있겠냐",
  "위에는",
  "살며",
  "없었니",
  "슈뢰더",
  "식료품점에",
  "죄악을",
  "종소리가",
  "진실하게",
  "드러날",
  "그때쯤이면",
  "도와주셨어요",
  "생각한단",
  "섀넌",
  "참견",
  "이해한다는",
  "토스터",
  "여자라서",
  "산업",
  "판단력을",
  "소등",
  "호외예요",
  "핑씨",
  "때는요",
  "영광스런",
  "고향입니다",
  "기독교인",
  "버트",
  "리치",
  "내렸잖아",
  "도착한지",
  "여유는",
  "대위의",
  "수술복",
  "투명",
  "도달하기",
  "긴급상황",
  "고객은",
  "전신",
  "방해만",
  "오사카에서",
  "레이튼",
  "도쿄",
  "안보이는데",
  "지요",
  "이스라엘",
  "터커는",
  "아이구나",
  "남자지",
  "되다",
  "참겠어요",
  "끊으면",
  "싹을",
  "진압",

  "부분보다",
  "분이예요",
  "대피시켜",
  "훌륭하신",

  "그냥은",
  "갚는",
  "모를걸",
  "아들처럼",
  "잔디를",
  "그림에",
  "이모부님",
  "얘기잖아",
  "간지러워요",
  "모르겠거든",
  "보내드리겠습니다",
  "만난다면",
  "자기들끼리",
  "밟지",
  "다녀서",
  "반대증언을",
  "성공했고",
  "마스터를",
  "뒤집힌",
  "지내라",
  "애틀랜타",
  "설명하지",
  "만들자고",
  "없더라도",
  "네코자무라이",
  "정직하고",
  "저희랑",
  "피자는",
  "말해줄수",
  "테즈",
  "일했다",
  "너하곤",
  "액체가",
  "말도안되는",
  "스타일로",
  "진단이",
  "이런데서",
  "공급",
  "센터를",
  "목장",
  "아놔",

  "반대쪽으로",
  "동안요",
  "할말도",
  "지나버린",
  "살았고",
  "조감독",
  "히브리",
  "총소리를",
  "파운드를",
  "조사도",
  "땡전",
  "급",
  "돈으로는",
  "말씀이야",
  "곰한테",
  "취하는",
  "그럴일은",
  "찾을거에요",
  "로맨틱해요",
  "늬들",
  "계란에",
  "다행이로군",
  "정답을",

  "올라오지",
  "중이네",
  "나쁘고",
  "해친",
  "심장마비로",
  "떼어내고",
  "살고있는",
  "서비스는",
  "이슬이",
  "배신하게",
  "바보인",
  "마수드",
  "대야",
  "돌격해라",
  "소송은",
  "클라이드",
  "생길지도",
  "도끼로",

  "있는것처럼",
  "립스틱",
  "신기해요",
  "아빠보다",
  "않다니",
  "연습에",

  "뒤져봤는데",
  "구토가",
  "헤어지자",
  "한가한",
  "못하다니",
  "머물러도",
  "킴이",
  "경찰들은",
  "혐의가",
  "이놈들은",
  "데어데블",
  "소고기",
  "짰어",
  "잇는",
  "무기고",
  "저자",
  "보고하도록",
  "십년",
  "헤쳐",

  "조치가",
  "이자벨라",
  "저희와",

  "줄어",
  "선생과",
  "계획입니까",
  "나누기",
  "살았나",
  "있으나",
  "관계의",
  "대통령도",
  "동무들이",
  "서류작업",
  "나없이",
  "갔었고",
  "이러는거",
  "우리까지",
  "경고야",

  "드라마틱한",
  "끊겼어",
  "쓰러져서",
  "복장이",
  "불안해서",
  "왔수",
  "피해는",
  "생각하셨어요",
  "실례합니다만",
  "떠날게",
  "놔주지",
  "동지요",
  "있구먼",
  "조용하게",
  "잘해봅시다",
  "자궁외",
  "공세를",
  "손이다",
  "잠꾸러기",
  "끝내자는",
  "나가라구요",
  "억울합니다",
  "것두",
  "들리는가",
  "관두자",
  "기쁨",
  "충수돌기를",
  "아메리카를",
  "사투를",
  "질이",
  "대학교수",
  "바람피는",


  "충성심이",
  "얘기들은",
  "방이나",
  "잤잖아요",
  "안와요",
  "오셨는지",
  "일리노이",
  "불쌍하다고",
  "그넘",

  "지역이야",
  "경찰하고",
  "네말이",
  "신분증도",
  "아이참",
  "쪄",

  "능력도",

  "닥치면",
  "잤나",
  "도와드려",
  "쪽으로요",
  "일하고자",
  "로라랑",
  "걸리겠다",
  "뒤쫓는",
  "흔드는",

  "플로릭씨",
  "부탁할께",
  "소생시키지",
  "꺾고",
  "국경이",
  "누군가에",
  "찾아와요",
  "교신",
  "전투는",
  "탔다",
  "녀석으로",
  "생겼다고요",
  "처치가",
  "잠잠해질",
  "안되겠어요",
  "실종됐어",
  "페니실린",

  "틀림없습니다",
  "시들어",
  "록하트",
  "훌륭했어요",
  "가드너",

  "섹스요",
  "갈등이",
  "있잔아",
  "융통성",
  "껀",
  "먹구",
  "알리샤",
  "부럽다",
  "이보",
  "발전",
  "마지막일",

  "김서방",

  "지배적인",
  "퇴원할",
  "경기도",

  "보입니까",
  "꼴에",

  "마셨고",

  "딥",
  "카탈로그",
  "주시리라",
  "이러나",
  "않으신",
  "희생자들이",
  "무례하네",
  "자코비",
  "아이구머니",
  "중지하고",
  "민호",
  "증거품",
  "최고였어",
  "정리좀",
  "벽의",
  "가정부를",

  "애기는",
  "속옷도",
  "남자들한테",
  "민석아",
  "사후에",
  "소수의",
  "축축한",
  "그해",
  "불놀이야",
  "으어",
  "케이트는",
  "아닙니다만",
  "반동",
  "장차",
  "심하면",
  "필요했을",
  "퍼져",
  "니사",
  "자크",
  "전세가",
  "임상실험에",
  "다가오면",
  "동무와",
  "집착하는",
  "플로릭",
  "블룸",
  "끝냈다고",
  "비교해서",
  "예쁘군",
  "부른거야",

  "컷트",
  "반쪽",
  "당신밖에",
  "바라자고",
  "백만장자가",
  "덩치가",
  "닥터는",
  "켜도",
  "빠른데",
  "복수도",
  "로도",

  "떼서",
  "되풀이",
  "같다구요",
  "괜찮다구요",
  "약혼한",
  "결백해",
  "가족이지",
  "선생께서",
  "닥터와",
  "잘못한게",
  "학원",
  "속절없이",

  "휩쓸렸을",
  "그른",
  "그러우",
  "쪼끄만",
  "정치위원",
  "않고서",
  "손길을",
  "같어",
  "치유가",
  "쪽팔리게",
  "색상이",
  "에어컨",
  "라못",
  "세스",
  "밤이란",

  "차들을",
  "소립니까",
  "예선",
  "그녀들은",
  "희숙",
  "계단은",
  "개구리",
  "허머스가",
  "통증과",
  "협박할",
  "아랍어로",
  "할건지",
  "식사하고",
  "들판",
  "꽂고",
  "길목을",
  "처량해",
  "청해야죠",


  "기억하시나요",
  "미워하고",
  "정말인지",
  "버밍햄",
  "여자라면",
  "노래방",
  "기억납니다",
  "안돼는",
  "한시간도",
  "실탄",
  "도널드",
  "이겨서",
  "갔었니",
  "키스하지",
  "컨디션이",
  "레스터가",
  "주냐",
  "똥강아지",

  "하늘도",
  "맞았구나",
  "모양이니",
  "제레미를",
  "구해주지",
  "업고",
  "껴안고",
  "빼라고",
  "밟아요",
  "내렸지",
  "당췌",
  "클린턴이",
  "여기보다",
  "망원경의",
  "꼈어",
  "들어올려",
  "육체",

  "복수는",
  "중에도",
  "도른의",
  "되었는가",
  "대단치",
  "잘못했다",
  "되었구나",
  "못하겠다",

  "허블을",


  "최보라",
  "큰지",
  "돌게",
  "왔는가",
  "사정은",

  "무엇때문에",
  "새끼한테",
  "빛날",
  "했구만",


  "들렸죠",
  "날과",
  "자지도",
  "침실이",
  "정원의",
  "베어라",
  "김씨의",
  "정부와",
  "기대감이",
  "경비대가",
  "기다리신다",
  "내려놓으라고",
  "계집은",
  "고마웠소",
  "하나인데",
  "간호사예요",
  "게임하는",
  "듣더니",
  "수용소에서",
  "높을",
  "그거야말로",
  "재로",

  "최강의",
  "연고",
  "아버지나",
  "남은게",
  "심리를",
  "유감입니다만",
  "공공장소에서",
  "프랑스는",
  "교도관이",
  "게이야",
  "헤르만입니다",
  "하소",
  "보시기에",
  "오셨는데",
  "머무르는",
  "켈리를",
  "잡히기만",
  "잡아먹고",
  "순전히",
  "요것이",
  "자라면서",
  "저였어요",
  "터이니",
  "찍을래",
  "끔직한",
  "비춰",
  "태양계",
  "알았다는",
  "그대도",
  "쉐인리씨",
  "가문에",
  "친척들이",
  "하나다",
  "섹시하다고",
  "뒈져",
  "흑과",
  "현찰",
  "날세",
  "아가씨의",
  "십자군",
  "술과",
  "바보들",
  "절로",
  "크리스핀",
  "소매를",
  "사무실의",
  "이름들",

  "이름들이",
  "안되거든",
  "신고한",
  "턱도",
  "불리우는",
  "만나야지",
  "마취약을",
  "대사부님",
  "앞날을",
  "정원은",
  "사냥개",
  "절에",

  "내기할까",
  "증가",
  "자신들이",
  "비키지",
  "위고",
  "데릭이요",
  "어딘가엔",
  "휴스턴",
  "반했어",
  "속옷만",
  "내쫓아야",

  "신호하겠다",
  "이러구",
  "딴거",
  "망자의",
  "부어서",

  "꼴사나운",
  "시적인",
  "끽해야",
  "못할것",
  "쥐새끼가",
  "탓도",
  "연인들의",
  "왕위",
  "시체에",
  "말뜻",
  "마찬가지였다",
  "일렬로",
  "좋",
  "모르던",
  "나같이",
  "아씨를",
  "첩자가",

  "그래유",
  "하수구를",
  "찾기위해",

  "감명",
  "근접",
  "쓰리썸",
  "벗어나야",

  "경관들이",
  "지리산",
  "켈리랑",

  "당신부터",
  "떠나가는",
  "떨어지세요",
  "다룬",

  "런던행",
  "일등석",
  "넘어진다",
  "안협집이",
  "착륙을",
  "해줄래요",

  "무사",
  "축에",
  "이크",
  "철거",
  "만나도",
  "다음번",
  "따다",
  "명백하게도",

  "자라난",
  "구축",
  "요망",
  "훔쳤다고",
  "부축해",
  "벙어리가",
  "서방님을",

  "걸라고",


  "대원은",


  "이지라고",
  "천하에",
  "왕비께서",
  "똑똑하니까",
  "몰라유",
  "보시게",
  "남자일",
  "했다가",
  "가짜로",
  "결정한다",
  "윈저",
  "변장한",
  "현대적인",
  "가운데에서",
  "깨우고",
  "형부가",
  "약속드리죠",
  "육체적인",
  "이장",
  "느꼈지",
  "속보",
  "뽕이",
  "였다면",
  "구출하러",
  "주신다고",
  "싫어하는데",
  "알았슈",
  "외교적",
  "결혼했지만",
  "있는겨",
  "넘겨주면",
  "곳이나",
  "못해본",
  "주셨습니다",
  "부서로",

  "끝내죠",
  "따지",
  "해주겠소",
  "맞설",
  "골로",
  "몫까지",
  "그림자의",
  "기쁘고",
  "좋았습니다",
  "쫓아라",
  "영광은",
  "헌다",
  "듯이요",
  "붉은색",
  "발령",
  "간다면서요",
  "부상자가",
  "알기에",
  "태아의",
  "남쪽에",
  "이전에도",
  "돌아왔으면",

  "미트볼",
  "해결했어",
  "서자",
  "않았는가",
  "남친이",
  "경찰들을",
  "에이드",
  "싶었고",


  "부류의",
  "비위를",
  "데까지",
  "반가웠네",
  "팔이야",
  "급해서",
  "죽일수도",
  "달이야",
  "요원이야",
  "누워요",
  "통신하는",
  "애들이란",
  "토니의",
  "육",
  "찾는걸",
  "부서는",
  "영군이",


  "배리는",
  "연구실에",
  "만나세",
  "처치해",
  "어떡할지",
  "물건이나",
  "싫어하는거",
  "이틀동안",
  "돼지고기",
  "애쓸",
  "경험상",
  "녀석들도",
  "힘들거든",
  "잃습니다",
  "전날에",
  "아이리스는",
  "홈즈입니다",
  "있었나봐",
  "이름하고",
  "안테나",
  "쓰겠어",
  "떠나자고",
  "원한다는",
  "알렌은",
  "가축",
  "농사",
  "추워서",

  "천하무적",
  "알라무트는",
  "배웠다고",
  "갚지",
  "각도를",
  "버틴",
  "감쪽같이",
  "펑",
  "암스트롱이",
  "러브크래프트를",
  "다스탄은",
  "덕분이에요",
  "얼음은",
  "타조",
  "현태씨",

  "부러워요",

  "언뜻",
  "좋아보이는데",
  "특권이",
  "떠나겠어요",
  "매너가",
  "지냈는지",
  "형이나",
  "가둔",
  "잊혀지지",
  "않나봐",
  "의회를",
  "찾으려는",
  "상공에",
  "꾸지마",
  "살았답니다",
  "긴급상황과",
  "사왔어요",
  "숙",
  "고픈데",
  "쐈소",
  "어디있니",
  "안다만",
  "걱정말고",
  "발견되었습니다",
  "찍혔어요",
  "걱정마라",
  "쉘리",
  "뚱뚱하고",
  "근심",
  "여자끼리",
  "키스해줘요",
  "괜찮군",
  "앰부백",

  "쌍놈의",
  "휴지가",
  "일들의",
  "잡지사",
  "꼬셔서",
  "변형인간이",
  "령도자님의",
  "싶은가요",
  "지난이야기",
  "연습해",
  "그러려던",
  "기억해줘",
  "볼에",
  "괴롭혔어",
  "오늘만은",

  "에즈라",
  "말씀하시죠",
  "살아있잖아",

  "역까지",
  "호기심",
  "이모님이",
  "신념",
  "계약한",
  "돌아가겠어",
  "래포포트씨",
  "모욕적인",
  "계산서",
  "없었겠죠",
  "트레드스톤을",
  "차까지",
  "이온",
  "중동",
  "자라날",
  "센티넬",
  "숙이",
  "헤일리가",
  "하워드가",
  "스탬프",
  "배포는",
  "켄터키",

  "터지지",
  "뜻하는지",
  "기울여",
  "추첨을",
  "알겠나요",
  "실종됐어요",
  "실패하게",
  "상어",
  "훔치면",

  "라지네",
  "택시비",
  "갚은",
  "목표에",
  "추락하고",

  "두둑히",
  "거였잖아",
  "안드레가",
  "있었구만",
  "보여주렴",
  "장에서",
  "슈퍼마켓",
  "진정들",
  "급하다고",
  "것은요",
  "감추는",
  "칠판",
  "몰려옵니다",
  "사람들에겐",
  "군사조직",
  "부를까요",
  "태플리",
  "피리",
  "의사거든요",
  "코트에",
  "까지는요",
  "숙소로",
  "열정으로",
  "알아야해요",
  "구역질",

  "딸이나",
  "놈이었어",
  "약속할께",

  "처참하게",
  "뒷문을",
  "싸우려고",
  "일치하지",
  "끝내버려",
  "그정도면",
  "오믈렛",
  "넉",
  "작업에",
  "갈비뼈",
  "맞은거야",
  "맡기면",
  "체포팀",
  "만들어준",
  "칩이",
  "이빨도",
  "용어를",
  "양실장",
  "아이입니다",
  "아까도",
  "수첩을",
  "지키기위해",
  "있다오",
  "왔는지도",
  "사정도",
  "찾는지",
  "돌연변이들이",
  "기대고",
  "제이슨을",
  "센티넬이",
  "중단",
  "양식이",
  "앤디가",
  "추격",
  "잡길",
  "해내는",
  "귀하신",
  "배고",
  "다녀와서",
  "크구나",
  "만났나",
  "영자막",
  "주구려",
  "교사랍니",
  "국가적",
  "계속하고",
  "스파이더맨의",
  "병사였다면서",
  "안드레를",
  "수트를",
  "늦었죠",
  "그러는거에요",
  "체포를",
  "비에",
  "기적같은",

  "모욕했어",
  "여행가방",
  "왕자의",
  "슬레지",
  "서류철",
  "담당자가",
  "고든을",

  "페레리우",
  "물러서게",
  "알아보시겠어요",
  "때리면",
  "누울",
  "국군",
  "결정한거야",
  "특허",
  "전우를",
  "만들었다면",
  "찔러서",
  "내용에",
  "모으는",
  "덮친",
  "알게됐어",

  "있었는",
  "성교를",
  "버기",
  "열은",
  "터뜨려",


  "오이소",
  "포즈",
  "여행자가",
  "하라구요",

  "딜레마에",
  "만해요",
  "먹여라",
  "자기네",
  "노래들",
  "도와준다고",
  "돌아오셨어요",
  "어디서요",
  "비텐베어게",

  "거짓으로",
  "밴에",
  "팀에게",
  "지식이",
  "이브에",
  "주다니",
  "일자리도",
  "손님이에요",
  "여기있다",
  "연락드리죠",
  "얘기하길",
  "취하면",
  "하루하루가",
  "걸렸네",
  "잊을수",
  "손녀",
  "장기이식센터에서",
  "죽어버리고",
  "가믄",
  "프로처럼",
  "이런데",
  "났거든",
  "오더니",
  "덕분이지",
  "가릴",
  "발의",
  "변형이",
  "어쨌단",

  "나참",

  "브랜든",
  "주소와",

  "바이바이",
  "자유롭지",
  "빌면서",
  "알려주게",
  "사무실입니다",
  "된거예요",
  "과학은",
  "간호병",
  "찾았다는",
  "오른팔",
  "드는지",

  "만날까",
  "없을텐데요",
  "응급실이",
  "셜록이",
  "일리가",
  "셴을",
  "기른",
  "처단하라",
  "좋았거든",
  "갚으면",
  "빨치산",
  "두려움과",

  "않겠는가",
  "예언자",
  "불러줄래",
  "자랑스런",
  "건졌어",
  "풀어달라고",
  "현상금이",
  "하이드라는",
  "지저분해",
  "푸른색",
  "케니",
  "머리칼을",

  "아우야",
  "해쳐",
  "치열한",
  "해방",
  "움직인다는",
  "승리로",
  "결과적으로",
  "지불하는",
  "크로노다인이",
  "금만",
  "켄드라",
  "사용된",
  "탓하지",
  "창고를",
  "흔치",
  "치구",
  "폭발로",
  "오줌이",
  "말하렴",
  "고스트",
  "조사해봐",
  "그러게나",
  "싶다네",
  "하지마라",
  "차트나",
  "다혜씨가",
  "원판이",
  "분노하고",

  "안전가옥에",

  "허락없이",
  "절도범을",
  "섬머",
  "다음에요",
  "첫사랑을",
  "민우야",
  "부르겠어요",
  "표현하는",
  "헤디",
  "경제적",
  "딸아이가",
  "가능하다는",
  "시몬",
  "남편분과",
  "무기들은",
  "찾겠다고",
  "진정시킬",
  "호랑이랑",
  "더하기",
  "사랑하나",
  "자살했어",
  "결정된",
  "마누라를",
  "설득했어",
  "문제란",
  "박살내",
  "이름인데",
  "불결한",
  "부모님들은",
  "행운",
  "집게로",
  "내용의",
  "말해준다면",

  "몸무게",
  "행운이야",
  "나가려면",

  "생각했니",

  "눈알",
  "통과해서",
  "여행자",
  "비스",
  "마이크로프트",
  "모양도",
  "문앞에",
  "만나던",
  "지원은",
  "협회에서",
  "바꾼다고",
  "올라간다",
  "알아봤지",
  "메리크리스마스",

  "환자보다",
  "스카이넷에",
  "없겠는데요",
  "돌아온다면",
  "부당한",
  "뽑았어요",
  "절때",
  "블릿처가",
  "안났어요",

  "안왔어요",
  "정리했어요",
  "적외선",
  "이상만",
  "용맹을",
  "조건은",
  "물어본다면",
  "산소포화도가",
  "받겠지",
  "짓이든",
  "이중에",
  "살수있는",
  "앞서고",
  "되었지만",
  "꿇는",

  "발가락",
  "잠갔어",
  "많이도",
  "공격하려",
  "케첩",

  "시는",
  "모셔야",
  "풀어줘요",
  "잡겠다",
  "않는거죠",
  "감각은",
  "시간에도",
  "살필",
  "네번이나",
  "갑작스러운",
  "괜찮지요",
  "조정",
  "독극물을",
  "간직할",

  "계단에",
  "철철",
  "이틀이나",
  "모든건",
  "참아낼",

  "회복되지",
  "가족이요",
  "부분까지",
  "급하니",
  "빌게요",
  "소리치고",

  "보장은",
  "내쉬고",
  "야만인들",
  "심호흡하고",
  "반복되고",
  "가족들이랑",
  "기분나쁘게",
  "라며",
  "않게끔",
  "잠긴",
  "드셨죠",
  "질문하실",
  "짜부라졌을",

  "책으로",
  "도넛을",
  "기로에",
  "불안정해요",
  "살라딘이",
  "체면을",
  "계시잖아",
  "숨쉬게",
  "옮긴다고",
  "해줍니다",
  "아름답다고",
  "지어요",
  "알았지요",
  "지경이야",
  "시체처럼",
  "버스터",
  "허락한다면",
  "바라건대",
  "록우드와",
  "감찰반",
  "에코",
  "우리끼리만",
  "됐다구요",
  "출전",
  "나나요",
  "왔지요",
  "쿠션",

  "마녀",
  "지나쳤어요",

  "레지던트한테",
  "쳐다봤어요",

  "이름이네요",
  "어지럽혀",
  "총소리",
  "꺼내게",
  "거인도",
  "유인해",
  "술이라도",
  "놓인",
  "핑계로",
  "없었대",
  "질병의",
  "뛰어나죠",
  "초음파와",
  "파크",
  "이안이랑",
  "왕자님이야",
  "취한다",
  "화장",
  "웃었지",
  "이참에",
  "수직형",
  "승부를",

  "원하오",
  "날아가",
  "양면을",
  "뻔뻔하게",
  "병사들",
  "못했거든",
  "자는데",
  "심전도는",
  "날아라",
  "셀던",
  "요양원에서",
  "경리",
  "마스터라고",
  "북방",
  "찾을꺼야",
  "여력이",
  "발리안",
  "나란히",
  "잔이요",
  "해킹할",
  "잔인하고",
  "얘기하러",
  "걸으면서",
  "치즈도",
  "거짓말쟁이라고",
  "크리스티나예요",
  "지켜줘서",
  "어울리네",
  "한국의",
  "형상이",
  "순찰차",
  "막론하고",
  "제쳐두고",
  "우즈",
  "뇌종양을",
  "전두엽을",
  "두개골이",
  "나아지는",
  "소령이",
  "이브는",
  "손전등이지",
  "라슨",
  "필요한거야",
  "것만이",
  "출발하자",
  "코끼리의",
  "오말리예요",
  "너뿐이야",
  "믿었는데",
  "열렸어요",
  "딸꾹질을",
  "막히고",
  "통역",
  "미루는",
  "고등학교를",
  "이성적으로",
  "너네들이",
  "철수해",
  "이산화탄소",
  "상류층",
  "일꾼",
  "있었다는데",
  "쌍둥이는",
  "불러올게",
  "가사를",
  "그남자랑",
  "황새",
  "파는데",
  "온지",
  "추가적인",
  "미야자키",
  "유미",
  "뻘갱이",
  "바뀌었다고",
  "분홍색",
  "셔츠에",
  "어땠지",
  "먹히지",
  "들이켜",
  "용감하다고",
  "젖었어요",
  "먹히는",
  "약이나",
  "초신성이",
  "환상적이군",

  "될진",

  "인상적인",
  "서부경찰서",
  "들린",
  "폭발물을",
  "받아주지",
  "그레이입니다",
  "피검사",
  "증거야",
  "별일은",
  "기독교",
  "해내야",
  "놀리시는",
  "귀신이다",
  "자랑하는",
  "스페인어를",
  "봉지",
  "만들었던",
  "꼴이야",
  "그렇기도",
  "단계고요",
  "들어보자",
  "에너지에",
  "변해서",
  "아버지라고",
  "쌌어",
  "보이더라",
  "정도라면",
  "들으신",

  "담배에",
  "아이라고",
  "베스의",
  "머독이",
  "문제라는",
  "얼굴이에요",
  "이러냐",
  "살과",
  "좋아하긴",

  "자슥",
  "구급카트",
  "인터넷을",
  "뛰어내릴",
  "상금",
  "머네",
  "십자가를",
  "복귀할",
  "도와줘야죠",
  "쉽지만은",
  "오스왈드가",
  "고장났어요",
  "데려왔잖아",
  "위해서다",
  "갈갈이",
  "이러십니까",
  "식이죠",
  "로니는",
  "직업에",
  "복귀한다",
  "대답해요",
  "과격하게",
  "다루지",
  "끙",



  "호위",
  "레이놀드",
  "얻읍시다",
  "당근이지",
  "수송선이",


  "혀에",
  "밝혀질",
  "밤이야",
  "중앙에서",
  "동정",
  "태워요",
  "씹고",
  "캐시가",
  "버렸소",
  "그레이스는",
  "확인하지",
  "지시하셨어요",
  "따라오고",
  "충돌했을",
  "돌아올까요",
  "떠내려",
  "북동쪽으로",
  "코너는",
  "닥쳐라",
  "사력을",
  "끝이라고",
  "크기로",
  "한복판이야",
  "같더니",

  "사이버맨이라고",
  "되시면",
  "기적이에요",
  "멀잖아요",
  "전군",
  "성질이",
  "아팠던",
  "담배가",
  "똥의",
  "고생한",
  "못가겠어",
  "약속했다",
  "저깁니다",
  "게시판에서",
  "감염을",
  "화만",
  "합리적인",
  "명하노니",

  "당신말이",
  "기억력이",
  "해준게",
  "해보신",
  "할순",
  "멈추려고",
  "대피소로",
  "유대교",

  "트리플",
  "알던가요",
  "불바다가",
  "존경하고",
  "그립다",
  "잘라내서",

  "낳았지",
  "신뢰가",
  "과장님이랑",
  "복지국에",
  "남겨뒀어",
  "이라뇨",
  "분석해서",



  "겨울을",
  "늦었지만",
  "잘못됐다고",
  "공주의",
  "썩게",
  "방어선을",
  "공기에",
  "분이나",
  "깨어나는",
  "지켜보도록",
  "지운",


  "오브라이언",
  "저주여",
  "연구실로",
  "오자",
  "레이더",
  "함선",
  "떨려서",
  "근거로",
  "절망적이고",
  "무엇을해야",
  "선생님들은",
  "상대방의",
  "내버려두면",
  "우스워요",
  "가는거지",
  "샬롯의",
  "구해줘야",
  "일대일로",
  "애인도",

  "공명",
  "신의를",
  "당신더러",
  "껍질은",
  "동해",
  "요법으로",

  "괴저",
  "차만",
  "벗어라",
  "푸르니에",
  "해둘",

  "히틀러는",

  "때문도",
  "맞추면",

  "잡고야",
  "칸디루",
  "다쳤습니다",
  "순철이가",

  "제발하지",
  "매일을",
  "수행하지",

  "때라면",
  "호박",
  "가기를",
  "읽어줄",
  "붙이면",
  "알아둘",
  "사람들로부터",
  "아그네스를",
  "파이는",
  "정책은",
  "고리",
  "남편분의",
  "은밀하게",
  "전前",
  "갈비뼈가",
  "봤을땐",
  "맞았는지",
  "레수스는",
  "번에는",
  "연결하고",
  "세상에선",
  "말하겠다",
  "캠벨",
  "강렬하게",
  "친해지고",
  "막중한",
  "과소평가",
  "역주",

  "혀도",

  "죄책감에",
  "전한다",
  "비틀거릴",
  "따위를",
  "사랑한대요",
  "바라나요",
  "출산을",
  "차원은",
  "게임이라고",
  "말할께",
  "쉘비",
  "터트릴",
  "함선을",
  "생각해보죠",
  "안하셨어요",
  "영화들을",
  "가속기",
  "왜그렇게",

  "항복을",
  "벌떡",

  "앞두고",
  "마티노",
  "믿든",
  "덤벼봐",
  "화살이",
  "캐피톨에서",
  "높다고",
  "뜨자",

  "옥나찰",
  "절제",
  "깼을",
  "해왔어요",
  "막아라",
  "내죠",
  "쟌",
  "그렇지만은",
  "사라집니다",

  "준비했는데",

  "폐에서",
  "천식이",
  "양치질",
  "분열을",
  "처음이",
  "산모가",
  "세포가",
  "활동하는",
  "던져버릴",
  "가십시다",
  "환자들과",
  "음성으로",
  "불밤",
  "마음이야",

  "여지는",
  "동의서가",

  "느꼈을",
  "충격기",
  "비운의",
  "보쇼",
  "즐겨야",
  "검사님",
  "카페에서",

  "개지",
  "퇴원하실",
  "살래",
  "했을지도",
  "전염병",
  "서류들",

  "광장이야",
  "살인죄로",
  "캠프로",
  "속도라면",
  "쓰러집니다",
  "척할",
  "도와요",
  "무게",
  "잭을",

  "에드먼즈의",
  "쐈다고요",
  "축복",
  "마리아는",
  "찾았으니",
  "노파",
  "보냈는지",

  "지내도",
  "말씀하셨죠",
  "시빌코이",
  "면전에",

  "끌어올릴",
  "분이시네요",
  "정자",
  "낚시하러",
  "가더니",
  "발작은",
  "몰랐",
  "달리기를",
  "줄이면",
  "울리기",
  "숙모가",
  "옷들이",

  "알아보죠",
  "빨뿌리",
  "때문이잖아",
  "똑똑하죠",
  "내려갑니다",
  "있었다던데",
  "작성",
  "전례가",
  "해달라고요",

  "노출이",
  "붓게",
  "우리에게도",

  "적법한",
  "해치",
  "잡기만",

  "야인들과",
  "지원에",
  "원자로가",
  "도와달라는",
  "택도",
  "부른다고",
  "혼자이고",
  "빈맥이",
  "얕은",
  "알까요",
  "계집애가",
  "면회는",

  "무토는",
  "스파이더",
  "버리게",
  "국립공원",
  "그대로의",
  "사춘기",
  "잔해가",
  "감시할",

  "사진들이",
  "공격적으로",
  "경막외",
  "호스를",
  "암말",
  "폭주",
  "소염기",
  "라디오에서",
  "애정결핍",
  "등록",
  "도착해",

  "그러려는",
  "반역죄로",
  "씹는",
  "고랄",
  "못하다고",
  "찾을게요",
  "크리스마스에",
  "캐딜락",
  "콜레스테롤",
  "위한거지",
  "척하지만",
  "낮아요",
  "사이버맨의",
  "머야",

  "준비하도록",
  "늦은건",
  "가시는가",
  "미키",
  "형사야",
  "싶어하면",
  "빠졌는데",
  "소원대로",
  "진실에",
  "점심이",

  "장난하는거지",
  "비율이",

  "피땀",
  "소주동무가",

  "베일리야",

  "녀석들아",
  "샘을",
  "악몽도",
  "어떠셨어요",
  "벨트가",
  "말렸어야지",


  "캘리랑",
  "재료가",
  "피나",
  "헬기에",
  "하게요",
  "세련된",
  "않으셔",
  "담판을",
  "참조",
  "만들래",
  "피투성이",
  "면허증",

  "시버트",
  "묻는다면",
  "소포",
  "말하도록",

  "타코",
  "활발한",
  "배웅해",
  "주저",
  "집이지",
  "하셨나",
  "지금와서",
  "무토를",
  "애네요",
  "바람피고",
  "상관이지",
  "큰가",
  "신분과",
  "말했을텐데",
  "샤넬",
  "실망감을",
  "눕히는",
  "끌면",
  "놓쳤는지",
  "둘밖에",
  "순간에도",

  "유치한",
  "크리스틴을",
  "래리버드",
  "대관식",

  "머물게",
  "메시지는",

  "진정할",
  "나타났을",
  "허접한",
  "혈액은",
  "플러그",
  "처리했어",
  "제거반",
  "승진",
  "가냐고",

  "좋아했을",
  "듣긴",
  "채널이",
  "들인",
  "뺑소니",
  "에덴",
  "엘리스는",

  "나무한테",
  "지휘",
  "에기",
  "아라",
  "스위츠",
  "받았기",
  "집안에서",
  "저장하는",
  "냉정하고",
  "되지는",
  "직전입니다",


  "무시",

  "이해했죠",
  "해볼만",
  "아침식사로",
  "원조",
  "여기랑",
  "다르겠죠",
  "아카데미에",
  "맞대고",
  "결말은",
  "최후",
  "자식이라고",
  "되어라",



  "닷새",
  "각하께서",
  "메이나드",
  "벌로",

  "씻어내고",

  "목소리도",
  "준비만",

  "그렇게들",
  "널린",
  "원하잖아",
  "친해요",
  "프로그램에서",
  "만의",
  "담당하는",
  "매달릴",
  "더하지",
  "실망했을",
  "동물이야",
  "부스에게",
  "상태라서",
  "나에요",
  "일거리가",
  "말투를",
  "떠나면서",
  "올라갔어요",
  "원위치",
  "어머님과",
  "헤비급",

  "당했거든요",
  "주사가",
  "인턴들에게",

  "동반자를",
  "줄여야",
  "일생의",
  "하겠단",

  "반응하는",
  "바로잡힐",
  "입양을",
  "안심시켜",
  "못하는게",
  "한껏",
  "위험하니",
  "이루기",

  "거절하면",
  "내려줘요",
  "외과의예요",
  "악마같은",
  "구름이요",
  "돼지로",
  "뭐겠어",
  "탈선",
  "연습하고",
  "근데도",
  "너보단",

  "타카시",

  "엔진에",
  "렌스",
  "상해",
  "엘엠엔트릭스",
  "아카데미",
  "전공",
  "수수께끼가",
  "사과해",
  "사치",
  "이일이",
  "돌아가셔서",


  "아빠였어",
  "했다는군",
  "수집",

  "신령님께",
  "빠져있어",
  "놔뒀어",
  "움",
  "소리치는",
  "되어가",
  "해주셔서",
  "증오해",
  "외치고",
  "해외",
  "사과라고",
  "결심했다",
  "죽던",
  "초코",
  "교통사고가",
  "생각에는요",
  "순환을",
  "서면",
  "말해줬죠",
  "하치",


  "에이몬",
  "스타일도",
  "별로네요",
  "녀석들에게",
  "환상적이죠",
  "즈웽",
  "험난한",
  "미팅을",
  "지금까지의",
  "의미지",
  "변하기",
  "에스포지토냐",
  "자궁에서",
  "주무셔",
  "사냥하는",

  "돌아오겠습니다",

  "못봤는데",
  "파워가",
  "내친구",
  "시작하도록",
  "잡으려면",
  "보험회사",
  "생각이라면",
  "따지자면",
  "동료야",
  "생각했고",
  "죽이고도",

  "숙소",
  "했겠어요",
  "흡수한",
  "하루였어요",
  "경기장에",
  "짓이라도",
  "소스가",
  "일하자",

  "가르",
  "붙이지",
  "캐피톨의",
  "꿈이라면",
  "부활하는",
  "얘기다",
  "죽이죠",
  "사태에",
  "은하계",
  "쳐야지",
  "나쁜놈",
  "머프가",
  "제어가",
  "구하다",
  "규칙이에요",
  "보이드",
  "쳐다봐요",

  "싫음",

  "공격했다",
  "귀족이",
  "마니",
  "거룩한",

  "마르지",
  "넣었는데",

  "판단하지",
  "꿔봤어요",
  "나아가는",
  "끝났으니",
  "도와드리죠",
  "언니에게",
  "자이양이",
  "나을까요",
  "좋다고요",



  "원했지만",
  "세실",
  "요청하신",
  "연대",
  "고담으로",
  "군에서",

  "방송할",
  "전도",
  "완소남",
  "배고프면",
  "건드렸어",
  "감각",
  "상관이에요",
  "밸리언트",
  "구워",
  "파괴된다면",
  "잘못됐다는",
  "곤잘레스를",

  "내시경",
  "깊어요",
  "모르시는군요",
  "넘어지면",
  "꼴레트",
  "휴고",
  "매순간",

  "후드는",

  "못내",
  "집에까지",
  "수영할",
  "악마를",
  "밖에서도",

  "주는군",
  "있는거라고",
  "선생도",
  "심하고",
  "그루버",
  "묻는거야",
  "로드는",
  "도와주십시오",
  "들어주소서",
  "조금의",
  "표면",
  "일어나네요",
  "이제와",
  "복되시나이다",
  "든다는",
  "독방에",
  "불침번을",
  "태중의",

  "장례식장",
  "누구신지",
  "진입하기",
  "서줄",


  "알려주도록",
  "날라",

  "빌려가도",
  "계산에",
  "조언",

  "테이블로",
  "분들도",
  "안가도",
  "기억이나",
  "크지만",
  "관상",
  "고양이의",
  "말해줬으면",

  "문명화된",
  "기관을",
  "스탠은",
  "아마추어",
  "소린가요",
  "느꼈습니다",
  "말하면서",
  "패트릭이",
  "몰려서",
  "투표",
  "저한테만",
  "남성과",
  "운명선을",
  "인정받는",
  "케이트의",
  "채웠어",
  "저번주에",

  "일어나죠",



  "취임하여",
  "썬더버드가",
  "느껴졌어",
  "들러리는",
  "조금이지",
  "카레브와",
  "엄호해",
  "라이더",
  "진찰을",
  "매듭을",

  "끔찍해서",
  "병원에서는",
  "구린내가",
  "조난을",
  "떠나신",
  "영적인",
  "수술보다",
  "입술이",
  "골목",

  "걸리나요",
  "발휘할",
  "넓적해서",
  "지하감옥에",
  "일상으로",
  "망가져",
  "접근이",
  "가드를",
  "명뿐이었죠",
  "인정을",
  "놓친거야",
  "두드리고",
  "거만하고",

  "사귀던",
  "한다면서",

  "곳인지",
  "난소를",
  "맞으면서",



  "시에서",

  "않으신다면",
  "좋아할지",
  "정도인",
  "벽화라고",
  "달라지진",
  "주는거야",
  "숲속에",
  "그만해도",
  "필름이",
  "죽죠",

  "마지막이라고요",
  "음악회에",
  "생각이었지",

  "인형극죠",
  "평범하지",
  "구름기지는",
  "없어지면",
  "털어놓는",

  "우스꽝스러운",

  "소름끼치게",
  "무정한",
  "잘못입니다",
  "수법을",
  "뚜껑을",

  "지켜보게",
  "남편분을",
  "파괴한",
  "마르티니크",
  "몇달간",

  "리서치",
  "돌아왔으니",
  "나로",
  "행성이라고",

  "말꺼야",
  "조작해서",
  "나체로",
  "지도자를",
  "불러왔더니",
  "곤두서는",
  "느려요",
  "선언합니다",
  "저에게는",
  "받아줘",
  "가득하신",
  "흐트러진",
  "연민을",
  "삼촌이랑",
  "막으려면",
  "휴슨",
  "것들이다",
  "검사실",
  "몰랐다구요",
  "찾아오는",
  "일했는데",

  "시절로",
  "아버지는요",
  "여쭤보고",
  "애론은",
  "돌아올꺼야",
  "걸려라",
  "가져올께",
  "방식이죠",
  "아닌데다",
  "사람들하고",
  "아름답다는",
  "모셔와",
  "친절해",
  "윗",
  "소설이",

  "기억하라고",
  "일가견이",
  "기증하고",
  "준비되셨나요",
  "만났다는",

  "주물러",
  "영구적으로",
  "받아왔어요",
  "혈액의",
  "린",
  "공간이란다",
  "돌아온걸",
  "쉽사리",
  "쓴다면",
  "인턴인데",
  "식을",
  "배신자야",

  "반하게",
  "힘이다",
  "질문이네요",
  "말하러",

  "아니신가",
  "대비",
  "혈관조영술",
  "처리하게",

  "나았지",
  "규칙으로",
  "받으라고",
  "크네요",
  "한지",
  "상상은",
  "갈때",
  "잊어라",
  "버리라고",

  "최고를",
  "두뇌",
  "부지를",
  "후도",
  "건물은",
  "못한게",
  "일으키기",
  "드물게",
  "몰랐으니까",
  "굼떠",
  "인턴이잖아",
  "골랐고",
  "레지던트랑",
  "상사가",
  "시기는",
  "자만",

  "쉘비가",
  "성장하고",
  "꺼냈어",
  "리처드의",
  "몬토야와",
  "파트",
  "신장은",
  "팔로",

  "도착했겠지",
  "자극하고",
  "시청에서",
  "쌔고",
  "앨리스는",
  "할만",
  "똑같습니다",
  "스친",
  "인터뷰가",
  "외상환자",
  "해결하죠",
  "소리잖아",
  "무섭죠",
  "꺼내지도",
  "기억들이",
  "빅토리안",
  "웨인부부",

  "시민의",
  "영원히요",
  "자다가",
  "뿐이라면",

  "편안해",
  "가서도",
  "보석에",

  "수술받지",
  "일종이죠",

  "변명은",
  "함께잖아",
  "올라가야",
  "중요하잖아",
  "클라우드의",
  "인턴의",
  "한가운데",
  "안하는거야",
  "그렉이",
  "감정적이",
  "올라오는",
  "마약은",
  "궐기하라",
  "벽으로",

  "초대할",
  "여자친구랑",
  "스페인이여",
  "받아들이셔야",
  "돼지는",
  "낙서한테서",
  "날렸어요",
  "여러분들도",
  "이식에",

  "뜰지도",
  "진행될",


  "저쪽을",
  "헛간이",
  "시작이네",
  "지내면서",
  "상어가",
  "로저슨",

  "바랐어",
  "쥘",
  "하시고요",
  "뿐일지도",

  "말씀이군요",
  "긴장했어요",
  "의족을",
  "때문에라도",
  "아이에겐",

  "켜세요",
  "사슴이",
  "자이양의",
  "진실만을",
  "쓰네",

  "그일이",

  "헤어졌다고",
  "겁나요",
  "칼리가",
  "아닌건",
  "바쁘네",
  "세금도",
  "예상대로",
  "루트를",
  "심흉부외과",

  "해야할까",
  "잤냐",
  "타임라인에서",
  "결혼했지",
  "이동해주시길",
  "무어",
  "바라세요",
  "모습입니다",
  "시간여행",
  "기구",
  "톰킨스",
  "쓰시면",
  "그렇게되면",

  "보장해",
  "연기에",
  "쟤네들은",
  "가야되요",
  "태어났어",
  "선택한다면",
  "발랄한",
  "씌웠어",
  "게시판을",

  "무니가",

  "게으르고",


  "입원했습니다",


  "맡을게요",
  "내려놓게",
  "혈류를",
  "자기와",
  "얘네들이",
  "이국적인",
  "약의",
  "놔뒀지",
  "돌봐드릴",
  "왕국에서",
  "삽관할",
  "총통",
  "경보입니다",
  "죽여줘요",
  "시치미",
  "인턴이었을",
  "천장을",
  "필요했지",
  "다해야",
  "커야지",
  "주네",

  "날씨를",
  "줬고",
  "가혹한",
  "세우면",
  "뜻이겠죠",
  "대니요",
  "봤습니까",
  "물질이야",
  "이었는데",
  "통합",
  "양아치",
  "곤란해요",
  "마크의",
  "여행과",
  "의도",
  "일어나봐요",
  "깡",
  "양수",
  "회복되는",
  "묻히고",
  "받아들였어",
  "시시하게",
  "팔아야지",
  "생일엔",

  "혀는",
  "튜브가",
  "복강",

  "카밀이",
  "나누었다",
  "예측하기",
  "최적의",
  "신선동결혈장",
  "레베카의",
  "성공하고",
  "씌워",
  "벡스는",
  "피울",
  "클수록",
  "창씨개명을",
  "일어났고",
  "얼간이들",
  "굉장하다",
  "체크하러",
  "진정이",
  "였다고",
  "맛보고",
  "잡지에서",

  "캐피탈은",
  "개다",
  "들일",
  "레이스를",
  "록의",
  "깨닫기",
  "띄어",
  "시간이잖아",

  "떨어뜨린",
  "지켜낼",
  "키스코",
  "들를",
  "고민하는",
  "떨면서",
  "자네답지",
  "슬픔도",
  "흥분했어",
  "무언가를요",
  "지나갈게요",
  "된답니다",
  "그늘",
  "진실이야",

  "종마",
  "헌트에게",
  "차에선",
  "냈고",
  "처해",
  "풀도록",
  "그애의",
  "찾아왔다고",
  "팔려는",

  "자녀를",
  "괴사성",

  "교수형",
  "폐색전",
  "생성된",
  "콘돔",
  "회의실로",
  "정오에",
  "성우",
  "받긴",
  "엽니",
  "춘자",
  "뜻이니까",
  "애초부터",
  "미워하게",

  "협정을",
  "계절이",
  "열과",
  "찍으시는",
  "죽느냐",
  "많았네",
  "세대의",

  "끝냈습니다",
  "달렸습니다",
  "이러시는",
  "이사벨",
  "생기죠",
  "잊어야",
  "할테니",
  "세개",
  "수고하십시오",
  "책임져",
  "갖자",
  "태양보다",
  "돌격하라",
  "고맙겠어",
  "뵙자고",
  "헤어지니까",
  "춘자야",
  "읽어보고",
  "놔뒀어야",
  "받으시죠",
  "위원회에",


  "자장가",
  "살려둔",
  "드나",
  "이름이라도",
  "능력의",
  "통제하기",
  "차안에서",


  "님의",
  "받았다는",
  "캐피탈에",
  "이부프로펜",
  "당직이야",
  "어긴",
  "갈테니",
  "문제없어요",
  "공급을",

  "좌심실",

  "별것도",
  "일어서게",
  "지내다가",
  "이러는지",
  "깨달을",
  "사내들",
  "자동폭파까지",
  "이맘",
  "군을",
  "신성",

  "사람들도요",
  "궁금합니다",
  "떠올려봐",
  "이뤄진",
  "그럴싸",
  "상황이요",
  "장담",
  "돌아가봐",
  "쳐다보는데",
  "시간이었지",
  "그렇대도",
  "우편함",
  "닥터라고",
  "자연에",
  "납치해",
  "치세요",
  "본보기를",
  "대마",
  "이지에",
  "심각하단",
  "생각났다",
  "안나야",

  "긁힌",
  "비추어",
  "렉시예요",

  "자료는",
  "관찰하는",

  "마땅해요",
  "성좌의",
  "흘려요",
  "데이터뱅크",
  "오붓하게",
  "박으면",
  "본색을",
  "괴상할",


  "토레스를",
  "후버만",
  "않았겠지만",
  "허락하기",
  "나오길",
  "상사와",
  "해결할게",
  "듣곤",

  "찌른",
  "빠져나간",
  "국민들은",
  "욕심은",
  "구리",
  "너머에서",
  "방황하는",
  "따라와라",

  "행위에",
  "종양이야",
  "관리할",
  "사랑하지도",

  "이송",
  "신고해야",
  "안들어요",
  "성형외과로",

  "리온에서",
  "않게요",
  "바꾸게",
  "말씀하실",

  "종결된",
  "키운다는",
  "풍선이",
  "성생활에",
  "가정하에서",
  "우승자들을",
  "죽였으면",
  "국토",
  "있어서의",
  "잘못이라는",
  "과학자가",
  "전쯤에",
  "특성을",
  "잘될거야",
  "말하고자",
  "암세포를",
  "당연한거",
  "캐스터보로우스",
  "블랙풀",
  "시민이",
  "도로시",

  "설탕이",
  "센터가",
  "들어오자마자",
  "술값은",
  "크리스토프가",
  "지웠다구요",
  "물어볼까",
  "떠나온",
  "예습을",
  "뭐랬죠",
  "베가스에서",

  "일주일은",
  "공물이어라",
  "아무말이나",
  "찾아줘요",
  "침묵의",
  "포로",
  "하는지에",
  "이웃들이",
  "효험이",
  "컨트롤",

  "심하다",
  "만들었다는",

  "상당수가",
  "울린다",
  "개념만큼이나",

  "공간이라는",
  "기계야",
  "떨어졌을",
  "이상이지",
  "교육도",
  "쓰레기들",
  "내쫓고",
  "성령의",
  "말자구",
  "트레일러에서",
  "놔뒀어요",
  "짧지만",
  "굉장했어요",
  "문장을",
  "수술이라고",
  "언덕을",
  "앞에만",
  "헌신을",
  "미씽",
  "킴의",
  "미쳤거나",
  "늙었거나",
  "마우어",
  "어리거나",
  "일인지는",
  "항문",
  "데이나",
  "끌어낸",
  "학교야",
  "유유히",
  "캐뉼러",
  "손아귀에서",
  "확보해",
  "품위있고",
  "취소했어",
  "눈가로",
  "엄지",
  "난다면",
  "반란에",
  "파괴적인",
  "앰뷸런스가",
  "근처야",
  "의도를",
  "근무시간을",
  "교회도",
  "기대도",
  "기다릴거야",
  "주문해",
  "수술실도",
  "헷갈려",
  "지원군이",
  "싫군",
  "끌려갔어요",
  "모르겠니",
  "주도",
  "지평선을",
  "기능도",
  "지평선에",
  "멍청하다",

  "성벽은",
  "뺏지",
  "들어올래",
  "채우기",
  "옮기기",
  "그분들을",
  "싫어하나",
  "부활",
  "투여할",
  "떨",
  "같대요",
  "볼러",
  "가거든",
  "들어오시죠",
  "마취에서",
  "독서",
  "것뿐입니다",
  "팔아먹은",
  "후보를",


  "온도차가",
  "존에",
  "젊을",
  "확인해볼",

  "진심인",
  "헌신",
  "승산이",
  "싸우길",

  "라떼",
  "여자죠",
  "사랑했다고",
  "질량의",

  "소동",
  "한개",
  "싫었고",
  "기립",
  "수고했네",
  "아시는군요",
  "과학적으로",
  "대표가",
  "캠핑을",
  "죽여달라고",
  "나오시겠다",
  "신이라고",

  "그만두라고",
  "형성",
  "나라로",
  "밴드에",
  "없애버려",
  "그럴꺼야",
  "믿어야지",
  "도우려",

  "임산부를",
  "계를",
  "진정하렴",
  "않지만요",
  "용액",
  "마누라도",
  "싸웠습니다",
  "콩을",
  "듣기나",
  "불러다",
  "부처",
  "안났어",
  "뚫렸어",
  "부처의",
  "진료실에",
  "비행기도",
  "댁도",
  "보내려면",
  "복귀",
  "낙찰",
  "이정도",
  "촌놈",
  "생각했었지",
  "믿어봐요",
  "그것을했다",
  "했더군",
  "룰",
  "빠릅니다",
  "국제적으로",
  "매형",
  "시트",
  "룰이",
  "도망가자",
  "실행하고",
  "댁들이",
  "재수없어",
  "오는거",
  "운전대를",
  "아이오와",
  "퀸을",
  "쪽팔려",
  "미션",
  "스키너",
  "흔들어서",
  "성령이",
  "원자로",
  "무너졌어요",
  "거래한",
  "여겼지",
  "힘주고",
  "다가와서",
  "목졸라",
  "맛있다고",
  "선두를",
  "덮개",

  "정신과로",
  "보내주실",
  "안전장치",
  "청색",
  "고용량",
  "지냈죠",
  "동료들은",
  "가리키는",
  "수연이가",
  "도망가게",
  "날아라봉구",
  "무스키",
  "아내니까",

  "때려봐",
  "마셔야겠어",
  "감정들을",

  "일치란",
  "앞이야",
  "해보",
  "선임",

  "어른들이",
  "가져다주고",
  "삭제할",
  "채드",
  "제정신이야",
  "심장박동",
  "할까봐요",
  "결승전에",
  "마음속으로",
  "지산",
  "방이에요",
  "제왕",
  "가있어",
  "법이오",
  "암실에",
  "데려간",
  "애들보다",
  "콩팥",
  "알테고",
  "여자였습니다",
  "끝났다구요",
  "영재",
  "바로기제",
  "스트레스로",
  "존중할",
  "밖이",
  "살아있지",
  "가르쳤어요",
  "시력이",
  "충분하다",
  "모습만",
  "아침만",
  "안주",
  "크래독",
  "찾아야겠어",
  "그다음엔",
  "나막",
  "면접",
  "찌질이",
  "캡틴이",
  "괜찮아도",
  "엑스터시",
  "쯤이라고",
  "쟈니",
  "전하시오",
  "박사야",
  "맥박을",
  "지어내는",
  "결정입니다",
  "배트맨이야",
  "조여",
  "뇌에서",
  "행동하면",
  "시신은",
  "이렇습니다",
  "생명체야",
  "날이었어요",
  "있는다고",
  "텔레비전을",
  "이것봐라",
  "훔쳤고",
  "수없는",
  "엄마요",
  "핑계를",
  "재밌",
  "조종해",
  "아프지도",
  "거냐면",
  "부인께서는",
  "바뀌었죠",
  "낙서꾼",
  "언론이",
  "구제",
  "떠라",
  "세탁기가",
  "부러뜨리고",
  "예지력이",
  "장담하지",
  "클레어가",
  "장점이",
  "시신경을",
  "시작하니",
  "주둥아리",
  "브라타스키",
  "대니랑",
  "선약이",
  "난관을",
  "해나갈",
  "생존자",
  "초대에",
  "넘겨야",
  "심령술",
  "아슬아슬하게",

  "기준아",
  "떼가",

  "수도요",
  "구하라",
  "얘기하는데",
  "크리스티나야",
  "살아남으려고",
  "전이요",
  "고요함이",
  "결정해요",
  "도망가면",
  "좋대",
  "저질이야",
  "비울",
  "다르네요",
  "모욕하는",
  "범인도",
  "죽이세요",
  "쉰",


  "석션을",
  "예외로",
  "켈리는",
  "맞는다고",
  "돼지의",
  "용의자를",
  "녀석이랑",
  "나온다면",
  "조지에",
  "느낄거야",
  "뜨개질은",
  "수축기",
  "멜이",
  "경찰이죠",
  "거물이",
  "싫어했죠",
  "후아",
  "말해줄까",
  "갚기",

  "부딪혀",
  "사진으로",
  "용서받지",

  "들킬",
  "인정받을",
  "쓴대요",
  "들자",
  "괜찮아지실",
  "닥쳐줄래",
  "전구를",
  "반대죠",
  "실질적으로",
  "기준씨",
  "지나갑시다",
  "마셨구나",
  "안정적이지만",
  "씻지",
  "죽일뻔",
  "한거냐고",
  "물었을",
  "위하는",
  "뭐하자는",
  "색은",
  "스트라이커가",
  "단기",
  "도와주셔서",
  "벌었어",
  "때에요",
  "침착한",
  "때문인지",

  "입히고",
  "안심하세요",
  "단계가",
  "좋으라고",
  "키스했잖아",

  "또라이야",
  "브라이언은",
  "많았죠",
  "성가대",
  "관중",
  "여정이",
  "다녀온",
  "꿰맬",
  "크레이그는",
  "개방성",
  "뭐했어",
  "길가에",
  "혈종",
  "구해야지",

  "무너질",
  "자식들도",
  "올라갔을",

  "생각되지",
  "수업이요",
  "지갑이",
  "종착역에",
  "늙었다고",
  "키스했어",
  "일이니까요",
  "돈이랑",
  "자로",
  "진하게",
  "펠로십",
  "나는데요",

  "밟았어",
  "올려야",
  "아기예요",
  "죽은건",
  "무스가",
  "화신이",
  "씩씩하게",
  "이모랑",
  "저질러",
  "떠드는",
  "생기진",
  "참이에요",

  "유치원",
  "혼수상태에",
  "복수의",
  "검색해",
  "내년엔",
  "시오",
  "좋지않아",
  "타잔",
  "에롤",
  "채우시고",
  "필요한가요",
  "이름이랑",
  "유모가",
  "되고자",
  "현실이에요",
  "쪽만",
  "술집이요",
  "악화되고",
  "손실에",
  "앉아있을",
  "시즌스",
  "정답입니다",
  "주입",
  "여자든",
  "살이고",
  "요구한다",
  "신경에",
  "체온",

  "감사하죠",
  "박사지",
  "의사에",
  "청각",

  "입죠",
  "사냥하고",
  "버림받은",
  "슬론입니다",
  "안정적이었어요",
  "왕비를",
  "시미즈상",
  "걸렸다는",
  "미친놈처럼",
  "기대해",
  "갈망",
  "전반적으로",
  "크리스에게",
  "카레브한테",
  "놔두는",
  "쳐먹어",
  "피해자들을",
  "도망치자",
  "채로요",
  "문젠지",
  "점수는",
  "불임",
  "또요",
  "시간인",
  "애더맨슘",
  "친구잖아요",
  "제도해",
  "도망갈까",
  "구강",
  "치러야",
  "의뢰할",
  "케일라",
  "재미있어서",
  "잊었잖아",
  "돌렸어",
  "우승한",
  "형사는",
  "넣었습니다",
  "취하기",
  "장미를",
  "잡았고",
  "남기세요",
  "게로군",
  "고치려고",
  "남쪽의",
  "낫다는",
  "갔다와",
  "않았네요",
  "학대를",
  "허만",
  "이종",
  "낭성",
  "견디는",
  "포도",
  "얼굴없는",
  "매일요",
  "대가는",
  "머리띠는",
  "자상한",
  "목격할",
  "담수를",
  "피부로",
  "날아서",
  "팬티는",
  "하얗고",
  "거실을",
  "연습도",
  "정리나",
  "블라인드",
  "관객들이",
  "가방에서",
  "다무는",
  "마이크에",
  "비슷합니다",
  "욕망은",

  "샤워할",
  "하와이",
  "손쓸",
  "예상한",
  "사람들은요",
  "제거술을",

  "환자네",
  "소나무",
  "리허설",
  "최선책은",
  "테스트도",
  "토르는",
  "차리자",
  "팔꿈치를",
  "비상벨",
  "걸리진",

  "찍습니다",
  "인디언이",

  "할걸세",
  "도달했습니다",
  "구급차가",
  "견뎌내지",

  "요정의",
  "깨어나실",
  "수술이에요",
  "종착역",
  "알몸으로",
  "얘랑",
  "뺏으면",
  "주긴",
  "이어져",
  "떠나버린",

  "크리스마스는",
  "영어할",
  "때문일지도",
  "대단하겠어",
  "그러던가",
  "장소에요",
  "좋지도",
  "행방불명",
  "여자에겐",
  "모두에게서",

  "계시냐",
  "싶어했어요",
  "움직입시다",
  "바자드",
  "걷잡을",
  "이혼하지",
  "대기권",
  "모르셨어요",
  "드라이브를",
  "그말을",
  "사라졌으니",
  "숭고한",
  "디저트를",
  "할수가",
  "운전자는",
  "노력해야",
  "샐",
  "좋던데",
  "릴리는",
  "그짓",
  "집중이",
  "싶어한다고요",


  "자세가",
  "생기거든",
  "낙하산이",
  "스릴",
  "에드윈이",
  "비밀에",
  "비다",
  "인간들하고",
  "만드셨고",
  "사과드리죠",
  "재능도",
  "지능을",
  "아시겠습니까",
  "봉합에",
  "보고가",
  "관여할",

  "버섯",

  "당신처럼요",
  "티데우스",
  "내리시죠",
  "못했을까",
  "핵폭탄들을",
  "진동기",
  "인구를",
  "앨리슨을",
  "작곡가",
  "가져가려고",
  "뛰어드는",

  "말해주실래요",

  "불륜을",
  "출혈의",
  "방사성",
  "시끄러워서",
  "그리면",

  "그러지마요",
  "놈들처럼",
  "늘어나고",
  "파티나",
  "기술과",
  "곳에요",
  "피라미드",
  "상놈의",
  "열매나",


  "다리오",
  "취소하고",
  "인기있는",
  "사랑하잖아요",
  "아이작이",
  "걸로도",
  "에드윈을",
  "베프가",
  "잡아줘요",
  "말하는거죠",
  "괜찮겠지만",
  "명이요",
  "체크인",
  "시대",
  "곰은",
  "버크야",
  "화학치료를",
  "의지하는",
  "싶다고요",
  "죽이다니",
  "자들의",
  "먹은거야",
  "있는걸로",
  "형성하고",
  "잠들기",
  "끝마칠",

  "경쟁적이고",
  "처제",
  "가족들도",
  "메이드",

  "싸우는데",
  "박물관에서",
  "삐져",
  "똑똑해서",
  "안하잖아",
  "전문가를",
  "반응하지",
  "당신이었어요",
  "담가",
  "흙으로",
  "오토바이에",
  "사전을",
  "못하거든요",
  "같구요",
  "말하겠소",
  "재스퍼는",
  "받아들이게",
  "알아채는",
  "경련",
  "도망쳐라",
  "거부했어",

  "심한데요",
  "결혼한게",
  "차례를",
  "성폭행",
  "볼만한",
  "찍겠습니다",
  "룰은",
  "초대장",
  "용량이",
  "중이라고요",
  "오시기",
  "시간있어",

  "파델라",
  "생각에도",
  "없잖소",
  "좋은시간을",
  "남겼는데",
  "들어봤나",
  "치료해야",
  "판정을",
  "말씀드리면",
  "거부한",
  "방사선과",
  "남았군요",

  "찾으시죠",
  "온전한",

  "기다리다가",
  "사용됩니다",
  "당연하잖아",
  "발견되었으며",
  "요정들",
  "궁전에서",

  "사랑하고요",
  "애들이야",
  "부목을",
  "운동하는",
  "치아",
  "강도는",
  "불렀다",
  "롤러",
  "슬론도",
  "몰린",
  "스투",
  "도와준다면",
  "시내에서",
  "횃불을",
  "민지",
  "기증자",
  "보았어",
  "신청해",

  "멍청해서",
  "구멍은",
  "잇어",
  "진정해야",
  "노아의",
  "팜",
  "계시라고",
  "먹으며",
  "재앙의",
  "고객들이",

  "탑",
  "뽑은",
  "입원해",
  "계시는데요",
  "우주왕복선",
  "전화일",
  "인도네시아",
  "밝혀졌습니다",
  "널렸어",
  "아무와도",
  "있던걸",
  "날씨야",
  "아낄",
  "달째",
  "폭풍우",
  "매그넘",
  "사립",
  "노랑머리",
  "즐기면서",
  "뜨게",
  "연락했어",
  "셔먼이",
  "미트러그",
  "버릇을",
  "만들었거든",
  "들어서면",
  "빨랐어요",
  "감사하게",
  "친구인데",
  "궁금",
  "시작하기로",
  "길이의",
  "이군",
  "클릭",

  "시작했으니",

  "중이라서요",
  "올리비아가",

  "여분",
  "프론트",
  "비슷하지",
  "후식",
  "좋겠",
  "잡겠다고",
  "싶잖아요",
  "하셨네요",
  "알렉스의",
  "빼주세요",
  "만들겁니다",
  "사느니",
  "스타니스는",
  "집이요",
  "통증에",
  "고생했어",
  "들이닥칠",
  "오메가가",
  "계약에",
  "곳이고",
  "계획인",
  "굶어죽게",
  "어텐딩을",
  "참이라고",
  "벤치에",
  "닥터얭",

  "청소하고",
  "군주는",
  "엡스테인",
  "옆자리",
  "궁전",
  "마법도",
  "괜찮더라",

  "채리",
  "일상",
  "여기있을",
  "거부할",
  "말도하지",
  "바쁘면",
  "친구일",
  "어텐딩",
  "레지던트들",
  "평범해",
  "하셨거든",
  "영웅이라고",
  "단어들을",
  "알프레도를",
  "만화책",
  "그레이한테",
  "로니가",
  "사자와",
  "퇴역했으니까요",
  "해버려",
  "숨겨놓은",
  "난리예요",

  "제이크가",
  "늘어져서",
  "요정들은",
  "자위",
  "보았지",
  "사람에게서",
  "농부",
  "옮기죠",
  "허큘리스를",
  "도전하고",

  "시도하는",
  "들어가있어",
  "줄어드는",
  "유인할게",

  "제정신",
  "맘에들어",
  "반군이",
  "알렉스야",
  "제자리를",
  "시장의",
  "얭은",
  "윈터펠에서",
  "오스본은",
  "새조개에요",
  "앨라배마",
  "조작",
  "취소한다",
  "커진",
  "틀라고",
  "동의합니다",
  "모든사람들이",
  "섞은",
  "병만",
  "왕비님은",
  "시작에",
  "나지금",
  "유사한",
  "이동하십시오",
  "책임감에",
  "형편없어요",
  "홍과장은",
  "저것봐",
  "받는게",
  "상냥하게",
  "로열",
  "말레피센트가",
  "코카인을",
  "양털",
  "쫓아서",
  "준비하자",
  "사귀면",
  "일이거든",
  "노예를",
  "대화하려고",
  "운좋은",
  "다투는",

  "드물지만",
  "논리가",
  "제롬은",
  "로자는",
  "알아서들",
  "밥그릇을",
  "제안하고",
  "증명이",
  "지켜준",
  "많은것을",
  "영웅이라",
  "못하겠지만",
  "노래에요",
  "귀찮아서",

  "찢어졌어",
  "말론",

  "자유로워",
  "이성이",
  "병자를",
  "동생이랑",
  "아얏",

  "행동이라고",
  "저승이",
  "가르치게",
  "운영해요",
  "교수야",
  "몰랐거든요",
  "방사선치료를",
  "가브리엘라",
  "에로남",
  "화장지",
  "물어봐서",
  "재킷",
  "걸을까",
  "궁극적으로",
  "뇌까지",

  "몸무게가",
  "당신없이",
  "엉망이었어",
  "길거리에",
  "자랑",
  "자란다",
  "주님께",
  "질거에요",
  "어찌됐던",
  "물푸레",
  "뜻에서",

  "드비토",
  "안되니",
  "나노",
  "제목",
  "순간도",
  "마구아의",
  "보그",
  "찾았거든요",
  "먹냐",
  "생긴다",
  "멈출수",
  "줄거리는",
  "스타크를",

  "달려있는",
  "배어",
  "겁줘서",
  "움직이세요",
  "디저트는",
  "어떠냐고",
  "짜릿한",

  "마지막을",
  "우리나라는",

  "달라고요",
  "승자가",
  "이길지",
  "잔류",
  "유인원을",
  "풍선",
  "말벌",
  "차트는",
  "좋더군",
  "접근하고",
  "고의적으로",
  "좋아야",
  "잘라내야",
  "비소세포성",
  "얘긴지",

  "쩔은",
  "팔려",
  "에릭의",
  "소음이",

  "빠르네",
  "렌씨",
  "생겼다면",

  "캔디",
  "대략적인",
  "적어야",

  "버릴거야",
  "선로에",
  "귀족들이",
  "말하기도",
  "들자면",
  "발생하고",
  "먹혀",
  "벌려봐",
  "보여줬어요",
  "신화에",
  "오믈렛을",
  "얘기해줘요",
  "들려오는",
  "확인해보고",
  "허큘리스는",
  "믿소",
  "교수와",
  "중일세",
  "그거였어",
  "베가스",
  "다른데로",
  "세븐",
  "마르코",
  "포함해서요",
  "지내셨어요",
  "걔네들을",
  "구슬",

  "반말",
  "아이디",
  "식당으로",
  "추수감사절을",
  "천상의",

  "다르지만요",
  "소화기를",
  "도와줘야해",
  "못하는군",
  "융커스",
  "패션",
  "차이점은",
  "들으셔야",
  "안에서만",
  "상처받지",
  "물러나라",
  "피고는",
  "콜슨입니다",
  "멤버가",
  "빌려도",
  "부르면서",
  "의사일",
  "터지게",
  "장난하나",
  "실제보다",
  "수준",
  "배급",

  "선반이",
  "라이브",
  "테인이",

  "상황이면",
  "피묻은",
  "조제뜨",
  "돌아가셨는데",
  "자유는",
  "심한거",

  "일항을",
  "아폴로",
  "정맥이",
  "귀신은",
  "뇌수술",
  "천정이",
  "안녕을",

  "몇몇의",
  "굴을",
  "의심은",
  "아가씨도",
  "검사해",
  "비디오드롬은",
  "수연인",
  "빠를",

  "장비도",

  "이달",
  "말씀에",
  "케잌을",
  "강하다",
  "레벨이",
  "기업을",
  "증거로",
  "오말리한테",
  "배신자를",
  "사에",
  "걱정했잖아",
  "녹슨",
  "과장님하고",
  "만나줘서",
  "더러",
  "발생할",
  "업히세요",
  "범인이야",

  "그분에게",
  "가하는",
  "그러시오",
  "기다릴래",
  "실화를",
  "근사하지",
  "지금이지",
  "잠들었어요",
  "없을걸세",
  "만물의",
  "다음부터는",
  "사납게",
  "생각하시오",
  "바빴어요",
  "절을",
  "창가에",
  "음주를",
  "물었던",

  "인류에게",
  "담장과",
  "재밌었어",
  "제치고",
  "몰리의",
  "도전하는",
  "맞았는데",
  "아닌거같아",
  "그레고리오",
  "사이라며",

  "나머지가",
  "재판으로",
  "지치게",
  "엉엉",

  "남긴다",

  "피츠버그",
  "노친네",
  "후쯤",
  "이야기한",
  "형들은",
  "브란트",
  "게임이나",
  "시계에",
  "외의",
  "알아차렸어",
  "그저그런",
  "생화학",
  "오래야",
  "싸이클",
  "본인도",
  "수천명의",
  "덩",
  "프랑키",
  "처치할",
  "블랙워터",
  "사이버맨을",
  "블로그",
  "차라고",
  "점쟁이가",
  "소행이",
  "아캄으로",
  "웃기려고",
  "헐리웃",
  "보이거든요",
  "홀린",
  "외과의로서",
  "항해",
  "떠나는게",
  "글렌은",
  "잃어선",
  "테이프로",
  "복구",
  "대상을",
  "말해주시오",
  "스트릭",
  "것들이요",
  "보내면서",
  "심하지만",
  "집이잖아",
  "거라면서",
  "여지를",
  "맡았어요",
  "잘못이예요",
  "택했어",
  "있다가요",

  "카탈로그를",
  "등장하는",
  "발밑에서",
  "마구아가",
  "간데",
  "탔죠",
  "거미줄",

  "생각되면",
  "의도한",
  "좋은건",
  "해주겠니",
  "하려는거야",
  "높으신",

  "낮습니다",
  "누출",
  "실망시켜서",
  "사비는",
  "돌아왔을때",

  "알아보면",
  "언제인지",

  "소소한",
  "처음이자",
  "전세계가",
  "모델은",
  "벽화에",
  "브레이크가",
  "네놈들을",
  "다섯째",
  "다리만",
  "지키면",

  "악몽에서",
  "그래야겠지",
  "쉴라",
  "신령님이",

  "군인들을",
  "자석이",
  "언론",
  "집이랑",

  "지나온",
  "보리싹",
  "돌아다니지",

  "철민아",

  "부하가",
  "저항",
  "게시판이",
  "와이스",
  "올드타운",
  "추억과",
  "침공",
  "세대에",
  "수다쟁이",
  "읽죠",
  "야기할",
  "치명적이지",
  "스티븐스한테",
  "산산조각이",
  "놓쳐서",
  "사귀기",
  "않으셨지",
  "몰랐군요",
  "무인",
  "자막으로",
  "에서요",
  "침몰한",
  "딸아이",
  "이것아",
  "자극을",
  "술한잔",
  "살아있게",
  "모호한",
  "피예요",
  "과거력이",
  "가능은",
  "자국을",
  "아끼던",
  "하겠대",
  "해골",
  "데려간다고",
  "취향",
  "어둠과",
  "자체야",

  "망쳐놓은",
  "생계를",

  "넣어줘",
  "전화했군",
  "러버스",
  "신지",
  "바이러스의",
  "후원자님",
  "공교롭게도",
  "몬쵸",
  "해야돼요",
  "외과에서",
  "산모",
  "인사가",

  "아캄에",

  "공에",
  "얘기랑",
  "결혼했는데",
  "일시적으로",
  "방황하고",
  "레지던트도",
  "했음",
  "브레도또",
  "차릴래",
  "참가",
  "농장을",
  "붙였는지",
  "죠지가",

  "모른척",
  "죽었어야",
  "처리하면",
  "지랄이고",
  "말했던거",
  "뿐이었다",
  "윈터펠",
  "뺐어요",
  "타임머신",
  "아담이",
  "삼각대를",
  "현실의",
  "녀석에",
  "차리면",
  "기소가",

  "오다",
  "혼조",

  "왼손",
  "덤으로",
  "마다라",
  "있다더라",
  "일부터",
  "아래쪽으로",

  "석양",
  "절개로",

  "늑대를",
  "와달라고",
  "지휘관님",
  "보살님",
  "뜻인",

  "치르는",
  "그라는",
  "그레이예요",
  "수집한",

  "경기장",
  "갖고있는",

  "도달하면",
  "응급실은",
  "페리보트가",
  "법이지요",
  "어머니셔",
  "해야하나요",
  "기억하라",
  "색스는",
  "도달한",
  "깔린",
  "잘있어요",
  "저작물의",
  "인물로",
  "싶고요",


  "왔었다고",
  "학생들이냐",
  "블러디",
  "베일로",
  "지슈카",
  "시작할때",
  "크잖아",

  "담배나",
  "승낙",
  "총기를",
  "클랜이",
  "에번스는",
  "외상환자가",
  "떠나실",
  "베드스타일",
  "홍수는",
  "셔터",

  "살려낸",
  "나왔는지",
  "여차장",

  "벤슨",
  "하수도",
  "퇴원하셔도",
  "일하려면",
  "토냐는",
  "백성들이",


  "보냈소",
  "제닝스가",
  "잡혔고",
  "추락한",
  "마담께서",

  "탈출을",
  "엘리샤",
  "화학제가",
  "레비아탄이",
  "그릇된",
  "걸어갈",
  "기도했어",
  "드류가",
  "저기엔",

  "속해",
  "힘들겠네요",
  "책임지겠습니다",
  "먹었거든요",
  "열차의",
  "취급하지",

  "성격도",
  "이해하시죠",
  "전환할",
  "산장을",
  "내려간",
  "아서를",
  "였고",
  "시작했나요",
  "나가는걸",
  "고르지",

  "실버톤",

  "내용물은",
  "틀렸고",
  "시작해도",
  "샐러드는",
  "아시잔아요",
  "갔었다고",
  "드립",
  "처치는",
  "배웠소",
  "병동엔",
  "체크할",

  "지속될",
  "과목이",
  "절벽",
  "아무하고도",
  "말았으면",
  "도와줬어요",
  "알아내도록",
  "숙련된",
  "핸들을",
  "높을것입니다",
  "각오는",
  "보고해봐",
  "원나잇",
  "출입할",
  "잘못인데",

  "미드겔",
  "브리",


  "어리잖아",

  "뜻에",
  "우리아빠",

  "날들은",
  "고객들",

  "최저",
  "산책이라도",
  "잘할게",

  "워키토끼",
  "없으십니다",
  "응원하는",
  "군인이죠",
  "대볼래",
  "비상시",
  "섬엔",
  "갈고리는",
  "당했을",
  "마비를",
  "먹지는",
  "바이패스",
  "합법적",
  "허구의",
  "가르쳐준",
  "쪽이요",
  "홉",
  "음부",
  "끝내려",
  "세우는",
  "인식",
  "하여라",
  "노벨",
  "공항을",
  "소녀야",
  "타깃에",
  "점도",
  "소름끼치는",
  "우연이라고",
  "번호도",
  "살렸어요",
  "다혜를",
  "제안한",
  "만드는거지",
  "저녁때",
  "않은거지",

  "모르다니",

  "팔면서",
  "꽃다발",
  "뭉치를",
  "세일",
  "넘어가죠",
  "조작할",
  "올라가게",
  "윈터펠에",
  "끝마친",
  "노르망디",
  "기술적인",
  "쓰러지기",
  "서머",
  "나가주셔야",
  "느낌이죠",
  "내껀",
  "케일린",
  "위니프리드",
  "피치입니다",
  "얼음으로",
  "조단",
  "조직으로",
  "살아나고",
  "교도소의",
  "슈미트를",
  "갔는가",

  "값으로",
  "명령하고",
  "손에는",
  "읽으려고",
  "다루게",
  "미호",

  "그밖에",
  "되라",
  "혈장",


  "이처럼",
  "버전",

  "도우면서",
  "슈",
  "갔대",
  "놈이요",
  "하시라구요",
  "못들어가",
  "각오를",
  "시체들이",

  "유전자가",
  "말했는지",
  "비어서",
  "꿩을",
  "트리샤가",
  "상태였어요",
  "엘드리치",
  "독소가",
  "자넨가",
  "심해졌어요",
  "맡기시오",
  "열어보고",
  "에러",
  "건재하고",
  "그레이스에",
  "건축가",
  "투르크는",
  "촌스러워",
  "활기차게",
  "레지던트잖아",
  "아날로그",
  "꺼졌어",
  "탓입니다",
  "안아봐도",
  "넬슨이",
  "들어갈래요",
  "수술하면서",
  "탑에",
  "저딴",
  "홍보",
  "두려운지",
  "빨개졌어",
  "너란",
  "걔넨",
  "신사가",
  "멕시코의",
  "이상해졌어",
  "말해보시죠",
  "팡",
  "화해의",

  "걸었다",
  "괴물들을",


  "소다",
  "올랐어요",
  "지하가",
  "냄새에",
  "오실거야",
  "않을텐데요",
  "외우고",
  "들던데",
  "한거라고",
  "야쿠자",
  "시시하다",
  "안됐잖아",
  "교류를",
  "지점에서",
  "할매",
  "차네",
  "하고있는지",
  "옵션을",
  "되가니",
  "추수감사절은",
  "힘들겠죠",
  "어서와라",
  "찾았고요",

  "믿었죠",
  "되는것",
  "관한게",
  "콘도를",
  "제거했어요",
  "있을진",
  "사업차",
  "북극에서",
  "스티브야",
  "타깃을",
  "맡았다고",
  "주먹질",
  "섰어",
  "오스틴이",
  "사냥꾼들",
  "아쉽지만",
  "공간에서",
  "한테서",
  "짓이군",
  "의심되는",
  "너니까",
  "곳에서도",
  "거시기랑",
  "버리자고",

  "니이타카상이",
  "많지는",
  "의사라는",
  "고친",
  "섬유",
  "그럴거라고",
  "즐기게",
  "오려면",
  "깨끗하지",
  "마이키는",
  "정체성을",
  "재미있는거",
  "빌리자면",
  "학부모들",
  "현상은",
  "하는거라구요",
  "익숙치",
  "최근에는",
  "수법이",
  "보는건",
  "필요하겠어요",
  "편인데",
  "불편해서",
  "생각해본적",
  "음식의",
  "저작권은",
  "몰골이",
  "동경",
  "잘라내지",
  "여러분들께",
  "부탁했습니다",

  "대문관",

  "가던가",

  "랩을",
  "원저작권자",

  "쌓은",
  "일본에도",
  "사자死者를",
  "아베크롬비",
  "휴일도",
  "무호흡",
  "건드리고",


  "잠그는",
  "붙여야",
  "메스가",
  "찼다",
  "내려갔어요",
  "그림으로",
  "봐야겠네",
  "시상대로",
  "못잤어",
  "시도해봐",
  "기한은",
  "무리의",
  "하려는게",
  "죽여주는",
  "씨트",
  "감수하고",
  "알고싶니",
  "작용",
  "아니였지",
  "부탁하겠네",
  "구별할",
  "범죄현장에서",
  "어찌할",
  "살해했어요",
  "네것도",
  "마시지도",
  "다름없는",
  "가난하게",
  "사인이",
  "낭비를",
  "악기를",

  "적응해",
  "지니",
  "절친",
  "평온한",
  "떠나겠어",
  "환자만",

  "없길래",

  "알려주십시오",
  "기대돼",
  "지켜보지",
  "들어줄게",
  "던졌어요",
  "놀래켰어",
  "오밤중에",
  "독가스를",
  "말하던데",
  "아시아계",
  "것뿐야",
  "연구로",
  "초라한",
  "육안으로",
  "길이라도",
  "뭐의",
  "사이버다인의",
  "빈치",
  "의사로써",
  "수에",
  "긴장풀어",
  "바구니",
  "한명씩",
  "닥터헌트",
  "통신이",
  "싶은것",
  "노력도",

  "술집이",
  "구역질이",
  "성공하기",
  "인사조차",
  "것으로도",
  "구하는게",
  "머리띠",
  "그럴만",
  "죽어가지",
  "생각하셔",
  "들통",
  "귀에서",
  "영웅이지",
  "권력과",

  "극복했어",
  "재미란",
  "잠자리가",
  "얻었군",
  "짓일까",
  "믿음이라고",
  "남자답지",
  "너네도",
  "부서지지",
  "탈래요",

  "경험해",
  "고달퍼",
  "이야긴",
  "우연한",
  "표시야",
  "시각을",
  "안단말이야",
  "거슨",
  "관두면",
  "색전",


  "못할거라고",
  "완고하고",

  "프로작",
  "수천명이",
  "자네들의",
  "그거라고",
  "사랑받고",

  "아름다움은",

  "말고죠",
  "불편하다고",
  "정답은",
  "티셔츠는",
  "환자분과",
  "감독관님",

  "전과를",
  "학점",
  "군인을",
  "아니었다는",
  "내려간다",
  "첫발을",
  "투자해야",
  "청혼",
  "잃었지",
  "사업엔",
  "끔찍이",

  "말던",
  "극복",
  "갚아야지",
  "권고합니다",
  "칭찬만",
  "잊혀지는",
  "맹세한",
  "차분한",
  "주문했어",
  "속삭이는",
  "봄은",
  "아빠같은",
  "보안에",
  "나앉게",
  "떠올려라",
  "찬다고",
  "저기있다",
  "적당해",
  "상관있나",
  "소스코드는",
  "점에선",

  "애이브리",
  "루시가",
  "짓거릴",
  "텅빈",
  "태아를",
  "손전등을",
  "유심카드",
  "살충제",

  "꿈이지",
  "때문이지요",
  "딸딸이들",
  "헌트랑",

  "앞도",
  "탐지기에",
  "있단말야",
  "살아가면서",
  "믿으려고",
  "닦아야",
  "아빠잖아",

  "테온이",
  "괜찮겠네",
  "파운드가",
  "장난이지",
  "부정할",
  "바치고",
  "로페즈",
  "그릇에",
  "부분들이",
  "하프",
  "영철아",
  "물어봐야죠",
  "습한",
  "별걸",
  "취했고",

  "수법",
  "소스코드",
  "내키는",
  "횡격막",
  "굶주리는",
  "끝내줬어",
  "함정이야",

  "강가에",
  "않았더군",

  "거북이들",
  "상상력을",
  "소스코드가",
  "화난게",
  "포틀랜드로",
  "화학제를",
  "보어햄",
  "약속했었지",
  "대답하기",
  "기다리기만",
  "때릉",
  "떨어졌던",
  "동네의",
  "입는다",
  "연인으로",
  "잊어줘",
  "잘나신",
  "코일",
  "스카로사",
  "개흉",
  "잠복",
  "쏟는",
  "웨스테로스의",
  "말해봐라",
  "병종",
  "짓이라",
  "이모들",
  "됬고",
  "목사는",
  "고장이라도",
  "로즈가",
  "톰과",
  "주사위",
  "없기를",
  "유지한다",
  "기분이지",
  "괜찮은가요",

  "꾸몄어",
  "기운내",
  "어울려야",
  "시티에선",

  "농장이",
  "났니",

  "지역에선",
  "황야에서",
  "딸애가",
  "봄에",
  "출입문",


  "말할려고",
  "말하네요",
  "스프린터",
  "강영철",
  "생각할까요",
  "최고니까",
  "합격",
  "주인장",
  "기억패턴",
  "잘못했니",
  "호주머니에서",
  "발전소",
  "기분에",
  "처지야",
  "초대해도",
  "만든거잖아",
  "예술을",
  "보여줘서",
  "무기력하게",
  "모르는가",
  "하겠느냐",
  "믿겠다고",
  "올드타운에",
  "복수에",
  "듯해요",
  "망가지기",
  "자애로운",
  "그말이",
  "사실만",
  "마티니",
  "시치코로",
  "히드라는",
  "벌이나",
  "기술인데",
  "변화의",
  "야망은",
  "망토와",
  "인질들을",
  "해봤니",
  "필리핀",

  "예술이",
  "안했었잖아",
  "잘할수",
  "화장실좀",
  "재킷이",
  "반스",
  "지도자는",

  "소풍을",
  "만드는거죠",
  "부하들",
  "히치콕",
  "월리스",
  "오랜만이네요",
  "일이거든요",
  "전화했다",
  "레이첼이",
  "언짢아하지",
  "사사로운",
  "핫한",
  "포기하기",
  "받기를",
  "기뻤어",
  "탄거야",
  "씨께",
  "에리카와",
  "시작되었어",
  "주된",
  "사셨어요",
  "비위",
  "희망으로",
  "잿더미가",

  "계시도록",
  "좋아지면",
  "평판도",
  "감성에",
  "표하는",
  "약속했지",
  "남자보다",
  "경치를",
  "추천서를",
  "목장에서",
  "머리띠가",

  "헌트와",
  "언급하지",
  "인격",
  "쓰셨어요",

  "지어냈나",
  "말했습니까",
  "않돼",
  "아프로가",
  "정비소에",
  "무장을",
  "지는거야",
  "약품",
  "경쟁이",
  "하시는게",
  "다쳤니",
  "사무",
  "어림없는",
  "자원이",
  "도장을",
  "경매에",
  "이야기해야",
  "마시던",

  "프레드릭",
  "방앗간의",
  "톰을",
  "진상",
  "망쳤다고",
  "전달이",
  "얻으려",
  "야생동물",
  "머리끈에",
  "에스카인",
  "판도라의",

  "않았구만",

  "시오님",
  "실패로",
  "살리려",
  "닿게",
  "병태의",
  "정액을",
  "채굴을",
  "없었다고요",
  "몇살이에요",
  "데이나는",
  "구세요",
  "조례",
  "여성들은",
  "과제물을",
  "풀어요",
  "데이비드는",
  "날이란",
  "수정은",
  "환각제",
  "명인데",
  "밤이요",
  "크리스피",
  "충동이",
  "검사들을",
  "쑤시면",
  "짭새",
  "해봤고",
  "생일이지",
  "탈출했어",
  "준비되지",
  "발견은",
  "신호로",
  "큰일인데",
  "낼게요",
  "아니었던",
  "올줄",
  "헤임즈의",
  "생겼잖아",
  "말도안되",
  "몇살",
  "잔지라",
  "솔로는",
  "플로스",
  "눕히세요",
  "환자에요",
  "들으며",
  "그쪽은요",
  "쓰레기나",
  "그런겁니다",

  "마중",
  "워싱턴에서",

  "없으시군요",
  "쉬는게",
  "안습니다",
  "학교까지",
  "화학제",
  "듣네요",
  "발각되면",
  "커다랗고",
  "꾸죠",
  "바깥쪽보다",
  "기회지",
  "스트리킹이다",
  "취급합니다",
  "지점으로",
  "이용하려고",
  "남겨서",
  "왈",
  "토네이도에",
  "오거린",
  "없진",
  "딱이지",
  "야만적인",
  "것들아",
  "아니었단다",
  "포틀랜드에",
  "협상할",
  "대령의",

  "잔인해",
  "기후는",
  "거품을",
  "식품",
  "하구만",
  "처음인",
  "자칫하면",
  "감상적인",

  "요금",
  "질투해요",

  "모닥불",
  "돌아오셨군요",
  "뚱뚱하다고",
  "판단할",
  "피터예요",
  "검사결과는",
  "대본대로",
  "고쳐주고",
  "가지고요",
  "느껴지나",
  "동감이에요",
  "브래니스",
  "재미있다고",
  "동의서를",
  "삼켜버렸는데",
  "좀요",
  "아름다움이",
  "컸다고",

  "나가거라",
  "참이었습니다",
  "장난이라고",
  "안하지",
  "해결이",
  "물론이거니와",
  "끄게",
  "나약하고",
  "치룰",
  "둡시다",
  "샐러드를",
  "대로에요",

  "잘못됐던",
  "마찬가지입니다",
  "해고될",
  "뭐를요",
  "외계인이에요",
  "보병",
  "캠퍼스",
  "마리아가",
  "지붕으로",
  "행복하다면",
  "씨부렁",
  "보여주겠어",
  "바위를",
  "없을껄",
  "서약서를",
  "잘못이라도",
  "방탕한",
  "느낀다면",
  "뭔줄",
  "막아줘요",
  "알려줄게요",
  "남겼습니다",
  "등대로",
  "도박은",
  "없었습니까",
  "돌로레스",
  "시간이니",
  "캐시는",
  "소용돌이가",
  "만들거든",
  "기다린다는",
  "몰려오고",
  "풀었어요",
  "이벤트에",
  "임명된",
  "폭력성을",
  "기억하잖아",
  "일상에서",
  "체계에",
  "대령입니다",
  "부분이죠",
  "결혼과",
  "여자였죠",
  "팔머",
  "혼인이",
  "공포의",
  "하시겠죠",
  "예수의",
  "쓰나미",
  "포도주가",
  "어쩌면요",
  "항의를",
  "캥거루",
  "죽었대",
  "내무부",
  "사고였다",
  "살아있었어",

  "행운으로",
  "발사해",
  "일어났네요",
  "개념이",

  "혐오감을",
  "생의",
  "있냐구요",
  "흘릴",
  "해치는",
  "바느질을",
  "슈미트",

  "나이트워치에",
  "플래쉬",


  "신이나",
  "잊었네",
  "상속",
  "나머지들은",
  "꼬시는",
  "올라갔죠",
  "뚝뚝",
  "둘중에",
  "저입니다",
  "일했네",
  "않지요",
  "즐기는거야",
  "하인이",
  "그후에",

  "편이다",
  "인사과에서",
  "산업은",
  "거로군요",
  "옥스포드",
  "부모들도",
  "반이야",
  "게이새끼",
  "영혼들은",
  "유지하도록",
  "우리모두",
  "화장하지",
  "않았구나",
  "불편하지",
  "해주기",
  "벗으세요",
  "말해주러",
  "입장에",
  "물어볼께",
  "그만하시오",
  "올드타운으로",
  "사과할께요",
  "믿어주셔야",



  "날이요",
  "모팻",
  "나만을",
  "웨이컴을",


  "그것좀",
  "반복적으로",

  "란셀",
  "사랑에서",
  "샤워도",
  "땄어",
  "저지할",
  "걱정되서",
  "사이먼이",
  "물어보셨어요",
  "치료제로",
  "한가하다고",
  "안게",
  "압박할",
  "오크",
  "저택을",
  "욕정에",
  "최대로",
  "땡",

  "얘기니까",
  "금발을",
  "살점을",
  "주문은",
  "위장이",
  "사용했죠",
  "초상화를",
  "저널",

  "질투는",
  "허리케인이",
  "있었던거지",
  "잘때",
  "새장",
  "요람에서",
  "회로도",
  "호카게가",
  "의식의",
  "앞사람",
  "필립과",
  "히드라가",

  "사랑했기",
  "해주겠다고",
  "의미에",


  "사랑이었어",
  "와일드펠홀의",
  "뺏어서",
  "사랑해야",

  "로프는",

  "골목에",
  "큰일이라고",
  "스파이라고",
  "상처야",
  "팔짱을",
  "사이란",

  "생각했단다",
  "완벽할",
  "신랑과",
  "이것보단",
  "멋지긴",
  "좋아하시나요",
  "다름없이",

  "태워줘",

  "하면서요",

  "뛰어들",
  "배짱도",
  "둬선",
  "홀랑",
  "끝장난",
  "공통된",
  "예가",
  "앙갚음을",
  "호수마을",
  "판인데",
  "들어가는데",
  "기대치를",
  "긁으면",
  "망치면",
  "닿았어",
  "피니시",
  "죽일년",
  "얼마인지",
  "웨이백",
  "장군님이",
  "고통스런",
  "이용했어요",
  "발견했을때",
  "동업자들을",
  "그러냐구요",
  "전송을",
  "엑소슈트",
  "오블리비언을",
  "어데서",
  "겠죠",
  "머프는",
  "자서전",
  "부탁하고",
  "해결한다면",
  "연습이나",
  "올립니다",
  "올렸어",
  "짝코가",
  "들어왔던",
  "이점",
  "짝코라는",
  "디나의",
  "깁스",
  "브래드가",
  "수두",
  "잘못이다",
  "돌아설",
  "짜증나네",
  "보고요",
  "그러기에",
  "무시한다",
  "리도",
  "생산에",
  "장래를",
  "되셨으니",
  "탐내지",
  "주입해요",
  "여드름",
  "산책하는",
  "루저",
  "거만하게",
  "안됐잖아요",
  "망토로",
  "베개",
  "꿇게",
  "판단력이",
  "지휘관을",
  "것이라도",
  "이미지에",
  "그러시나요",
  "견디겠어요",
  "없어지길",
  "조심하면",
  "올거라고",
  "엑스터시를",
  "간호사요",
  "틀어박혀서",
  "주의하십시오",
  "배후에",
  "단단하고",
  "합쳐져서",
  "아에몬",
  "롱",
  "타이머",
  "로리가",
  "짐승의",
  "일본식",
  "병신이야",
  "사무소",
  "주인님의",
  "계시다",
  "부족으로",
  "있었겠네요",
  "닥쳐올",
  "부르던",
  "원했습니다",
  "집어쳐",
  "김삼수",
  "찾아야겠다",
  "오해하고",
  "부끄러워서",
  "맥스야",
  "대대",
  "소리하네",
  "오래되고",
  "걸어가서",
  "신경계를",
  "부족하다고",
  "모진",
  "적절히",
  "써봐요",
  "베르됭에서",
  "더럽혀진",
  "시미안",
  "선택했죠",
  "심리가",
  "상으론",
  "향후",
  "그들로부터",
  "기술적",
  "아가르타의",
  "테마",
  "변호사님이",
  "드시지요",
  "아가르타는",
  "웃음소리",
  "죽다",
  "원자력",
  "먹고싶지",
  "스타일을",
  "스트릭에",
  "기밀을",
  "언론의",
  "녀석이지",
  "제일이야",
  "까여봐야",
  "낮의",
  "깔아",
  "한말",
  "롭을",
  "군홧발에",
  "송기열",
  "레골라스",
  "욕하고",
  "주식회사",
  "놈이냐",
  "브라잇",
  "식이란",
  "뱀의",
  "정액",
  "민간인이",
  "수건이",
  "지껄여",
  "어느때보다",
  "세이브라",
  "세계에게",
  "この世界に",
  "そして君のいない",
  "차단해",
  "파일들",
  "내일부터는",
  "발톱",
  "현상이죠",
  "카페테리아",
  "컸지",
  "발전소에",

  "터프가이",
  "찾다가",
  "던은",
  "산통",
  "미팅",
  "용맹히",
  "부탁하오",
  "역사에서",
  "자체였다",
  "추구하는",
  "의향이",
  "선수도",
  "올랐습니다",
  "이슌",

  "양성",
  "취했지",
  "깨달았습니다",
  "풀어봐",
  "보려구",
  "들었단",

  "잘못했는지",
  "학생들의",
  "듣기로",
  "보유한",
  "힌트가",
  "결혼까지",
  "환기구",
  "패스트",
  "명에게",
  "협박한",
  "계셔서",
  "후끈",
  "하시려고요",
  "부모님이요",
  "하느니라",
  "사랑스럽게",

  "사람이라고요",
  "해주더군",
  "남자하고",
  "이상해서요",
  "아니래도",
  "워커들이",
  "잘들어라",
  "선택받은",
  "물리적",
  "하냐면",
  "합의를",
  "때문일세",
  "가져가는게",
  "자국이야",
  "넘겨주지",
  "궁합이",
  "감사하네",
  "안잖아",
  "가신다",

  "위험하오",
  "해리슨",
  "먹겠다",
  "투자할",
  "친절을",
  "수만은",
  "외람되지만",

  "머프에게",
  "킹스맨이",
  "모가지를",
  "존중",
  "않더라도",
  "초자연적인",
  "입자의",
  "혓바닥을",
  "얘기해봤는데",

  "레벨은",
  "상황만",
  "일제",
  "글은",
  "해줘야죠",
  "확인이라도",
  "단연",
  "원합니까",
  "닿으면",
  "레벨에",
  "결합하여",
  "출발해야",
  "쿨녀는",
  "했다니",
  "벳을",
  "전장은",
  "새끼들을",
  "오랜만인데",
  "구멍으로",
  "놈들인데요",
  "피워요",
  "우주선으로",
  "음료수나",
  "셀프",
  "미치기",
  "자리라도",
  "싶어해서",
  "방침을",
  "크리스틴이",
  "볼꺼야",
  "등록증",
  "때문일거야",
  "그사람도",
  "엑스터시는",
  "손발을",
  "장벽에",
  "농담으로",
  "후진해",
  "은행으로",
  "상상해요",
  "폭탄이야",
  "월남에",
  "짐승이야",

  "퇴짜",
  "가동해",
  "파실",
  "다녔다",
  "회복하는데",
  "고치면서",
  "알아듣냐",
  "보인다구요",
  "올꺼야",
  "채워줄",
  "추죠",


  "치르지",
  "사용했다",
  "망가졌어",

  "한숨을",
  "금속으로",
  "배신했다",
  "현실과",
  "사고난",
  "저러니",
  "특수요원",
  "죽었겠지",
  "반항하는",
  "전데요",
  "중대장",
  "깜박했어",
  "싫나",
  "쉴드를",
  "가르쳐줘",
  "사무총장",
  "로미오",
  "해도요",
  "맞추러",
  "준비됐다고",
  "두시죠",

  "가르강튀아",
  "곳인데요",
  "노출되어",
  "아파하고",
  "수의사한테",
  "소비",

  "선하고",
  "않느냐",
  "법무부",
  "남짓",
  "둥근",
  "그러시다면",
  "칼과",
  "개그",
  "통신병",
  "싸웠죠",
  "저것이",
  "실패했지",

  "말씀이요",
  "친절히",
  "보았나",
  "집중하라고",
  "사용해요",
  "뛴",
  "내앞에서",
  "채굴",

  "냈다",
  "당연해요",
  "통신은",
  "손금",
  "딘이",
  "텅텅",
  "몸매도",


  "쓰다가",
  "기저귀를",
  "큰아빠",
  "와있습니다",
  "도착하실",
  "여행이야",
  "묻습니다",
  "나잇",
  "나갈수",
  "입안에",
  "줘야하지",
  "스팅거",
  "이예춘",
  "조항",
  "초반에",
  "않았을거야",
  "지방열",
  "김기영",
  "베트콩",
  "이야기들",
  "잔디에",
  "이해하겠어",
  "기억으론",
  "디트로이트",
  "곳이란다",
  "알아볼게",
  "가지려",
  "절박해",
  "스콧의",
  "봐둬",
  "넘쳐요",
  "놈들이죠",
  "나온다는",
  "밀러는",
  "스페인에",

  "살기는",
  "거절하는",
  "델타싸이는",
  "명예도",
  "용케",
  "주둥이를",
  "쓰이죠",
  "한몫",
  "나누지",

  "비켜줘요",
  "맞은거",
  "행복할꺼야",
  "샘웰",
  "쎄게",
  "비슷할",
  "미스란디르",
  "평민",

  "그런디",
  "관점은",
  "균열",
  "현대판",
  "많더군",
  "아가르타",
  "만드는걸",
  "아메리카의",
  "담배랑",
  "존경의",
  "환영하는",
  "피드백",
  "내야지",
  "원작자",
  "인생이에요",
  "뚝딱",
  "렌치",
  "나가겠소",
  "상황이라",
  "아스나가",
  "거짓말했어요",
  "기질은",
  "삼백",
  "환만",
  "보더라고",
  "않을걸요",
  "수행합니다",
  "알아내서",
  "전화했죠",
  "계속됩니다",

  "구하세요",
  "흔해",
  "봤으나",
  "천국의",
  "문지기",
  "재미있을거고",
  "창고로",
  "집에요",
  "버려야지",
  "원이라면",
  "리더를",
  "통채로",
  "마카로니",
  "운전수가",
  "술집으로",
  "오란",
  "안내할",
  "요만큼",
  "바닷가에",
  "발생했다",
  "기분나쁜",
  "두기",
  "밤에도",
  "듣기는",
  "쌓인",
  "실수였지",
  "맹세할",
  "베이커",
  "댐을",
  "하고싶었던",
  "들어와서는",
  "반대쪽",
  "좋게끔",
  "나갈꺼야",
  "밤이에요",
  "꿈으로",
  "장사가",
  "지원해",
  "쩐",
  "군의관",
  "했으며",
  "근무하던",
  "울려서",
  "그리워서",

  "생각나는군",
  "미디어의",
  "어이없네",
  "두려워했어요",
  "래가",
  "날려버릴거야",
  "만났네",
  "바꾼다는",
  "유의미한",
  "받아낼",
  "오호라",
  "놔둬야",
  "수분이",
  "갖고있어요",
  "히로시마",
  "어느게",
  "저어라",
  "랑데뷰",
  "졌고",
  "늦으셨어요",
  "말대꾸",
  "옆구리에",
  "린다와",
  "계획이라도",
  "않는다구",
  "덕이죠",
  "쓰레기라고",
  "땅꼬마",
  "리스트가",
  "대단한데요",
  "그림자도",
  "국방",
  "비싸지",
  "왜나면",
  "아까워라",
  "알려줄까",
  "구출",
  "싶소만",
  "엡스타인",
  "생겼니",
  "잃었지만",
  "목자",
  "하워드는",
  "비겼어",
  "가져왔다고",
  "주더라고",
  "뭍은",
  "살다보면",
  "재야",
  "애두",
  "평범해요",
  "모르겠다구",
  "퇴원시킬",
  "놓치다니",
  "흉칙한",
  "탈이야",
  "셔먼을",
  "모아라",
  "왼쪽에서",
  "미린까지",
  "오랜만일세",
  "아긴",
  "혼구녕을",
  "오두막에서",
  "갚을래",
  "프로젝트는",
  "닫기",
  "못하겠니",
  "기집애가",
  "제임스의",
  "남겨두는",
  "아이였죠",
  "가야했다",
  "이사하는",
  "가족이죠",
  "공증",
  "갔다오마",
  "안되는군",
  "전화하죠",
  "섹시하군",
  "에서는",
  "놀래서",
  "속였잖아",
  "면할",
  "타노스의",
  "자연적인",
  "맞군",
  "차구",
  "킷캣이",
  "맞을거야",
  "보내셨지",
  "시집이나",
  "차려입은",
  "크리족",
  "사지를",
  "벌에",
  "왕이요",
  "밥두",
  "동료의",
  "끓고",
  "못가게",
  "훈련도",
  "데스몬드",
  "하두",
  "사람보단",
  "병명이",
  "로난에게",
  "망측한",
  "날개의",
  "아주머니한테",
  "쌓고",
  "고생만",
  "본단다",
  "적용이",
  "해독제",
  "들들",
  "파라다이스",
  "이쁘게",

  "반까지",
  "차네요",
  "어둠으로",
  "주의깊게",
  "묻기",
  "스타킹을",
  "않았다면요",
  "내선",
  "찰리를",
  "트면",
  "저스틴이",
  "달려요",
  "매리언",
  "악이",
  "기어가",
  "누리고",

  "계시다고",
  "명절을",
  "지내죠",
  "싸우겠다",
  "통과하지",
  "글레디스",
  "피에로를",
  "오로라의",
  "말려서",
  "이윤을",
  "성문으로",

  "안타깝네",
  "방안에서",
  "몸무게는",
  "요금을",
  "경비는",
  "변이",
  "이전과",
  "기억났어",
  "타진",
  "데이비슨",
  "밤과",
  "따라가야",
  "타운에",
  "리마",
  "카바나",
  "올거란",
  "며칠을",
  "피우게",
  "아니였다",
  "그러자면",
  "경호원",
  "그래놓고",
  "도주한다",
  "드릴게",
  "함께하면",
  "선배도",
  "중요해서",
  "게이들이",
  "로난은",
  "삭제에",
  "죽기만을",
  "주디스를",
  "뉴욕타임즈",
  "일어나시죠",
  "위성이",
  "외로이",
  "기냥",
  "전영선",
  "최악이었어",
  "달렸고",
  "나치는",
  "놀러와",
  "레즈비언이",
  "좋아져",
  "자외선",
  "어른은",
  "소란을",
  "봤다구",
  "집세도",
  "배워야만",
  "먹힐거야",
  "원숭이도",
  "믿어선",
  "거의다",
  "조촐하게",
  "찾아야돼",
  "독수리처럼",

  "저질렀어",
  "행성엔",
  "녀석이었어",
  "아셨습니까",
  "가레스",
  "좀비들을",

  "쏘겠다",
  "저여자를",
  "살거든요",
  "가격도",
  "삼엄한",
  "알겠냐고",
  "원한다고요",
  "마스터스",

  "우우",
  "오른손이",
  "경고하러",
  "옛날이",
  "동물원에서",
  "정거장",
  "근데말야",
  "복수가",
  "로난의",
  "하누카",
  "사라졌다는",
  "늙었다",
  "않을거라고",
  "저주는",
  "프로스트씨",
  "조애나",
  "이세상에서",
  "기분이네",
  "준비시켜요",
  "에계",
  "깨달았어요",
  "두꺼비",
  "착륙은",
  "걸렸거든요",
  "중환자실",
  "어둠에서",
  "독극물이",
  "딸꾹질",
  "오셨나",
  "ㅇㅇ",
  "좌우간",
  "대비시켜줄",
  "상하지",
  "지속",
  "백성들에게",
  "부르거든요",

  "선생님으로",
  "동의한다",
  "지배자가",
  "샀잖아",
  "맴돌고",
  "경주가",
  "뭐였는데요",
  "캔자스",
  "봄을",
  "가보슈",
  "환장한",
  "어데를",
  "제외한다면요",
  "옷장이",
  "형태는",
  "많아도",
  "둘째치고",
  "내렸고",
  "이리온",
  "혼나",
  "며느리를",
  "헬기야",
  "증오는",
  "스파게티",
  "기를수",
  "밑바닥",
  "추측은",
  "있었답니다",
  "배신하는",
  "아니겠소",
  "허기야",
  "빌렸어",
  "전투태세",

  "어이없는",
  "체크해야",
  "내연녀",
  "익숙해진",
  "불이라도",
  "초월하는",
  "두명을",
  "춤추게",
  "췌장",
  "업어",
  "건수를",
  "활",
  "화장실은",

  "강력하고",
  "재고해",
  "볼만",
  "죄송할",
  "애정",
  "정종석씨",
  "계시긴",
  "주라고요",
  "무시하기로",
  "입맛이",
  "천문학자들이",
  "방광을",

  "축하의",

  "이용하려는",
  "악화시킬",
  "측에서",
  "갈겁니다",
  "이상합니다",
  "사진처럼",
  "중력으로",
  "친거야",
  "높였어",
  "말하거나",
  "철이의",
  "별과",
  "판다는",
  "단련",
  "인듀어런스",
  "전무님하고",
  "아닐걸요",
  "물질이라",
  "추적기",
  "일어나진",
  "감소",
  "킬",
  "먹는다는",
  "사용하여",
  "주장하고",
  "공전하는",
  "초음파에서",
  "검사에",
  "사과드릴게요",

  "큰소리",
  "시끄럽지",
  "빠졌구나",
  "날까",
  "입자들을",
  "다른쪽",
  "러브",
  "타월",
  "자유민들은",
  "인터폴",
  "집이라고요",
  "은팔찌를",
  "잉어",
  "거로구나",
  "작습니다",
  "목표물",
  "아이유",
  "세우십시오",
  "보면서도",
  "교수님을",
  "먹도록",
  "비키니를",
  "확보했다",
  "망가졌어요",
  "승진했어",
  "오시게",
  "전화했었는데",
  "칸에",
  "국장이",
  "알아들었습니다",
  "도가니니까",
  "철야",

  "이집트에",
  "아무려면",
  "반할",
  "망원경이",

  "포함합니다",
  "버크입니다",
  "외출을",

  "빵빵",
  "살란",
  "이혼하려고",
  "전사들을",
  "이분도",
  "상부의",
  "승모판",
  "갖겠습니다",
  "곤란한데요",
  "학위가",
  "있자니",
  "코앞에",
  "호출기야",
  "머리만",
  "부인한다",
  "시기야",
  "배트록",
  "태어",
  "부인하는",
  "올라갔다",
  "강원도",
  "화분을",
  "티온",
  "동물원을",
  "알걸",
  "타이리스는",
  "영역은",
  "가르쳐주지",
  "이유에선지",
  "부족하다는",
  "뒤덮여",

  "재미있죠",
  "찢겨져",

  "뭘하든",
  "겪었어",
  "냉수",
  "앉게나",
  "아브라함이",
  "백번",
  "제이미는",
  "이보쇼",
  "자식놈을",
  "되고싶지",
  "해당하는",
  "정신이상",
  "빼내기",

  "존재한다면",
  "찬장에",
  "가시는데",
  "마녀회는",
  "내립니다",
  "로시는",
  "놓습니다",
  "선고",
  "정크",
  "리셋하는",
  "날이구나",
  "론다",
  "않으시고",
  "푹푹",
  "캐스털리",
  "카밀라",
  "헌병대",
  "질환의",
  "차도가",
  "한것도",
  "얼마만큼의",
  "삼진",
  "해야하는거야",
  "종교를",
  "곰곰히",
  "주립",
  "오메가는",
  "모양인데요",

  "염색체",
  "하옵시고",
  "일용할",
  "만났겠죠",
  "못에",
  "권세와",
  "쉡",

  "날라리",
  "돌지",
  "가졌었지",
  "드리구",
  "드로곤",
  "주자고",
  "애비는",
  "해당되는",
  "그런거요",

  "효력을",
  "구두쇠",
  "착륙하는",
  "국밥",
  "소장님",
  "얘기할까",

  "슈트가",
  "인사하세요",
  "단일",
  "것일까",
  "바라는건",
  "발포",
  "입사한",
  "움직여야해",
  "신호도",
  "무관한",
  "맨땅에서",
  "우리와는",
  "본사",
  "알기라도",
  "이틀째",
  "넷은",
  "놈이네",

  "젖먹던",
  "찬영아",
  "끝날때까지",
  "인간이니까",
  "곤란한데",
  "부친이",
  "무너뜨려",
  "버릇없이",
  "취직이",
  "쏴버리면",
  "죽이는게",
  "나간다는",
  "외출하고",
  "이력을",
  "술술",
  "어설픈",
  "의사다",
  "끝난게",
  "슈가라고",
  "찾으면서",
  "뒤집을",
  "자랑스럽다고",
  "덮으려고",
  "돌진하고",
  "장관을",
  "설교는",
  "미스타",
  "물러서라고",
  "좇까",
  "안드로메다",
  "철학은",
  "갈비뼈에",
  "파괴될",
  "진료실로",
  "잡숫고",
  "아이인가요",
  "처녀를",
  "오겠다",
  "치명적",

  "끄라고",
  "닥터스티븐스",
  "도망쳤죠",

  "시간밖에",
  "난지금",
  "심판이",
  "시세로",
  "올랐어",
  "가져왔나",
  "모습이지",
  "부브를",
  "불태워라",
  "설명하라",
  "렉터의",
  "루디야",

  "질리게",
  "채팅을",
  "때이다",
  "만나는군",
  "그분들이",
  "문화에",
  "마르면",
  "봤겠지만",
  "썼다고",
  "가량의",
  "스티브는",
  "작동했어요",
  "증오로",
  "없으십니까",
  "치루고",
  "놓쳤다",
  "가져온거야",

  "오리야",
  "태우면",

  "어쩌고는",
  "대비해야",
  "상업적인",
  "무서운데",
  "심리학",
  "셀리",
  "이집을",
  "보내셨습니다",
  "로라스를",
  "출판사에",
  "저쪽도",
  "찾아냈지",
  "학위",
  "맡기겠소",
  "하시길",
  "모킹",
  "흥정을",
  "피그",
  "이해해줄",
  "풀려나게",
  "버밍햄에",
  "세균이라고",
  "발생기인가",
  "머리카락은",
  "관리는",
  "끼면",
  "맘마",
  "빠집니다",
  "느끼나",
  "대기할",
  "사단",
  "있어서야",
  "잊으신",
  "바윗돌",
  "셀라",
  "늠름한",
  "공주님을",
  "별명을",
  "잃어버리지",
  "이론학자들이",
  "동굴이",
  "요새에서",
  "똑똑해야",
  "폰으로",
  "보드가",
  "놔드려",
  "일치치곤",
  "숭배하거든",
  "상으로",
  "알겠구만",


  "경께서",
  "늑대인간이",
  "다행이예요",
  "지켜주소서",
  "덮을",
  "플루타르크가",
  "쪽지는",
  "굳은",
  "쌌잖아",
  "인턴일",
  "트링켓",
  "낙태수술을",
  "수류탄이다",
  "해놔",
  "문제인",
  "근거리포",
  "쟤들을",
  "내내는",
  "답장을",
  "말씀드립니다",
  "캐피탈에서",
  "가스는",
  "무자비하게",
  "기초가",
  "이익은",

  "병사의",
  "쉽습니다",
  "돌아와서는",
  "전언을",
  "혼자두고",

  "병약한",
  "뜻인데",
  "일치합니다",
  "생각하는구나",
  "노팅엄",
  "임신이야",
  "교사랑",
  "경이를",
  "말씀하셨다",
  "말려요",
  "달렸다고",
  "찾아야죠",
  "없었던거야",
  "말이쥐",
  "결혼으로",
  "이벤트를",
  "예술가",
  "되는줄",
  "수다",
  "똥덩어리",
  "얘기해봐요",
  "나갔네",
  "아랍",
  "감출",
  "반란이",
  "존경하지만",
  "화난거",
  "지화자",
  "부두에서",
  "바보예요",
  "그랬군",
  "약합니다",
  "탐욕스러운",
  "뭐였나요",
  "안정적인지",
  "효과적으로",
  "에도",
  "여동생의",
  "에헤이",
  "햄버거나",

  "나올거에요",
  "그렇",
  "죽겠지만",
  "되어있어요",

  "아니에유",
  "도망가느냐",
  "됨",

  "스포츠에",
  "바치겠다고",
  "도중이라도",
  "되려나",
  "관여",
  "시작했을때",
  "안아주는",
  "인생을당신과",
  "대니라고",
  "끝내도록",
  "꿇라",
  "중요하지만",


  "노숙자가",
  "코인이",
  "패한",
  "다있어",
  "애틀란타",
  "서로한테",
  "니네들은",
  "치나요",
  "비법",
  "닥터한은",
  "미셸이",
  "그러시던데",
  "지역입니다",
  "박사도",
  "돋보이게",
  "바쳤지",
  "해주겠지",
  "놓치겠어",
  "닥터그레이",
  "기반으로",
  "적응할",
  "올레나",

  "요구하지",
  "달만",
  "막스를",
  "혼란에",
  "바닥나서",
  "보기에도",
  "본보기로",
  "날아가고",
  "위대함",
  "살아남는다면",
  "계획이라고",
  "사귀진",
  "사네",
  "가난하고",
  "얼굴들이",
  "개리가",
  "철판",

  "거북이가",
  "말렸어요",
  "대표하는",
  "프로포즈",
  "국왕폐하",
  "허머스",
  "중동음식",
  "들어왔고",
  "해골의",
  "뛰어나다",

  "지날수록",
  "약초",
  "구하다니",
  "벽과",
  "마이크한테",
  "선전을",
  "차고로",

  "펜과",
  "만지려",
  "임신하는",
  "정직하지",
  "편지입니다",
  "확인하라고",
  "갈리프레이의",
  "제자리에",
  "아이라도",
  "부부야",
  "요정이라고",
  "왼손을",
  "다쳤을",
  "라니스터와",
  "살꺼야",
  "주만",
  "서명했어요",
  "펠로우",

  "정리해라",
  "해낼거야",

  "레이나는",
  "둘이랑",
  "도데체",
  "가진건",
  "활주로에",
  "확인됐어",
  "가상의",
  "해주는게",
  "결혼하러",

  "베이비시터가",
  "기억력",


  "수금",
  "딱밤",


  "꺼낼게요",
  "프림은",
  "접착제를",
  "늦는군",
  "무니는",

  "주목해",
  "바꿔놨어",
  "제임스가",
  "조지에요",


  "위해서든",
  "놀랐습니다",
  "중재",
  "연락할게요",
  "지치면",
  "쳐들어와서",
  "수명",

  "아주아주",

  "버린거야",

  "쉬거라",
  "행방은",
  "아이들밖에",

  "끝장이",
  "데이비드도",

  "그럴려면",
  "수술준비",
  "여학생이",
  "미쳤나봐",
  "제니가",

  "빠져나와",
  "안쓰는",
  "계속하는",
  "승",
  "민우씨를",
  "불안정",
  "물어봤잖아",
  "해봐야겠다",
  "될줄",
  "말할수도",
  "불고기",
  "깃발은",

  "닥치게",
  "결혼할꺼야",
  "안지",
  "영웅인",
  "제이는",
  "안았어",
  "하면돼",
  "말려들게",

  "크랜베리",
  "일요일엔",
  "모자와",
  "할껄",
  "현재에",
  "확실하다",
  "그렇게라도",

  "지났을",
  "있곤",

  "축하해야지",
  "동공에",
  "없더구나",
  "사인하면",

  "단정하게",
  "간호사야",
  "띠를",
  "펼쳐져",
  "말해주는데",
  "출근하는",
  "저녁까지",
  "웨인의",
  "알겠다고요",
  "쏘세요",
  "시작하려는",
  "마십시요",
  "골반을",
  "잡히네요",
  "만났군",
  "열려서",
  "마법같은",
  "저장해",
  "멍청하군",

  "같다만",
  "에이드리언",
  "부탁하마",
  "쌀쌀맞게",
  "군인으로",
  "약속합니다",
  "사이즈를",
  "봤군",
  "민우씨는",

  "죽였으니까",
  "반복하면",
  "리스가",
  "세팅을",
  "프란시스",
  "필요하지만",
  "협박은",
  "가야겠군",

  "연관성이",


  "요정들의",
  "빙",
  "파괴하기",
  "있는거란다",
  "그때까지는",

  "촬영하고",
  "건너왔어요",
  "않을테니까",
  "튕긴",
  "발역",

  "후드가",
  "호출했나",
  "행위는",
  "숨어있을",
  "잭슨에게",
  "천성은",
  "프레스캇",
  "음악으로",
  "옮겨졌어",
  "공격해서",
  "동기화",
  "누구일까",
  "흔들리고",
  "흘러간다",
  "책이랑",
  "선전",
  "달란하이드",
  "승객이",
  "두번째는",
  "의사인가",
  "빨려들어가서",
  "바랄게요",
  "그립지",
  "볼텍스로",
  "구울",
  "넥타이가",
  "고백하는",
  "시장님이",
  "납득",
  "배웠거든요",
  "생명에",
  "오시고",
  "좋다니까",
  "멸망시키도록",

  "가까워질",
  "뻔했는데",

  "타디스라고",


  "서있으면",
  "친절하시네요",
  "페퍼는",
  "팔코니의",
  "아테네",
  "있으라니까",
  "고아원에서",
  "조차도요",

  "무의미하게",
  "규정이",
  "가톨릭",
  "기적으로",

  "랜더",
  "하이드라에",
  "했었는지",
  "양키",


  "유심카드를",
  "다니기",

  "물리고",

  "콩이",

  "텐",
  "변경되었습니다",
  "폭격을",

  "간구하오니",
  "누리게",
  "금발이고",
  "이중으로",
  "도로에",

  "일어나다니",
  "재물을",
  "납치하지",
  "내장의",
  "하겠군요",

  "스카이도",
  "싹수가",
  "형하고",
  "다혜씨는",
  "특별하죠",

  "들어오십시오",
  "셴은",
  "셔머니우스",
  "다혜씨도",
  "절망에",
  "도와드리겠습니다",
  "배신했지",
  "어미를",

  "적혀있어",
  "지켜주세요",
  "되구요",

  "참된",

  "나가다",
  "것같이",
  "감수하는",
  "하옵니다",
  "두렵고",

  "어서가자",

  "좋아하는지도",
  "서방님께",
  "있는동안",
  "얘기한게",
  "승리한",
  "보았네",
  "한평생",
  "식사하러",
  "전부들",
  "몰몬트",
  "슈나이더",
  "잘하니까",
  "내걸",
  "놈이잖아",
  "애팅거",

  "헛되게",
  "성지로",

  "정성껏",
  "사람한테도",
  "금주",

  "생각했기",
  "성행위",
  "무궁무진한",
  "설치는",
  "거역할",
  "안전해질",
  "하옥시켜라",
  "가까워져",
  "티반",
  "끊어야겠어요",
  "바이론",
  "남쪽에서",
  "세탁기에",

  "쫓아온다",
  "조지예요",
  "돌아왔잖아",
  "죽일지",
  "모니터도",


  "베네사",
  "이사갈",
  "입대",



  "밧줄로",
  "무서우면",
  "영을",
  "사이렌은",
  "마옵소서",
  "광주",
  "배틀링",
  "카포",
  "전주",
  "남이야",
  "르네가",
  "세미터리",
  "알아볼까",
  "기러기",
  "의심스러운",
  "알리바이가",

  "바라옵건데",
  "추러",
  "키어스튼",

  "씌여",
  "저랬다",
  "우익",
  "이랬다",
  "써줄",
  "묶은",
  "비나이다",
  "계부가",
  "이식칩",
  "있다네요",
  "오프라인",
  "생각해봅시다",
  "적출을",
  "풀려고",
  "맥주나",
  "오마이곧",
  "영토",

  "팀입니다",
  "불가능하게",
  "스마일",
  "약하지만",
  "하자구요",
  "강간한",
  "옮기면",
  "살아있었구나",

  "더럽히고",
  "관계에서",
  "옵티머스가",
  "거동",
  "연설이",
  "좌현",
  "농담이요",
  "찾았다면",

  "풀어주게",

  "농도를",
  "밥으로",
  "이것들로",
  "저승으로",
  "고을",
  "입대했어요",
  "유전공학",
  "짐승같은",
  "그전엔",
  "수절한",
  "약과",
  "생각해봤어요",
  "게르만",
  "될수는",

  "살인범의",
  "의민지",
  "단조",
  "알아듣는",
  "해변가를",
  "노립니다",

  "했던것",
  "동생이요",
  "범블비를",
  "다음에야",
  "무의식적으로",
  "호화로운",
  "내딸",

  "일곱살",
  "가십",
  "점심시간에",
  "않는걸",

  "이거밖에",
  "진거",
  "구매",
  "거리지",
  "계셔요",
  "영웅도",
  "어련할까",
  "점이죠",
  "싸울거야",
  "머피의",
  "기지가",
  "얼마만이야",
  "문장",
  "컨셉",
  "아이였을",
  "기생",
  "마다하고",
  "거들떠",
  "소리지르지",
  "뜻인진",
  "편에서",
  "됐구요",
  "지체",
  "테인에게",
  "안하게",
  "태양에",
  "페니의",
  "굶주리고",
  "습니다",
  "파도에",
  "즉슨",
  "될걸세",
  "뻔했네",

  "펑크가",
  "나왔으면",
  "계속해도",
  "오니라",
  "모르겠수다",
  "고속도로로",
  "멀리요",
  "되겠느냐",

  "샌님",

  "전자석",
  "웬디는",

  "빈민가",
  "칼린다가",
  "텔",
  "보스에게",
  "덩치를",
  "긴장감이",
  "그로",

  "필터를",

  "기다리는게",
  "살이냐",
  "들어봅시다",
  "배관",
  "펠랑의",
  "요년",
  "죽을걸",
  "공포와",
  "안하려고",
  "기부를",
  "점입니다",
  "전선이",
  "니꺼",
  "싶으냐",
  "철왕좌를",
  "날짜",
  "모이라",

  "위스콘신으로",
  "현신",
  "부스입니다",
  "편의점에",
  "찬성이야",
  "도와주시는",
  "운동장에",
  "당치도",
  "저라고요",
  "블레이크가",

  "요구는",
  "먹여야지",
  "해파리",
  "뼛속",
  "피조물을",
  "어엿한",

  "케너",
  "한양",
  "니들을",
  "서방님은",
  "일찍이",
  "사이예요",

  "연설도",
  "중세",
  "무삼",

  "군대로",
  "잃으면",
  "제목이야",
  "신연",
  "갈거다",

  "인을",
  "물어내",
  "걔들한테",
  "변명이",
  "한가하게",
  "참수",
  "은가락지가",
  "불가능하죠",


  "행실",
  "부친은",
  "벌써요",
  "거신",
  "룸메가",
  "못하시는",
  "실시간",
  "음식물",
  "상금은",
  "병이라고",


  "대책",
  "초저녁부터",
  "타운십",
  "싸가지가",
  "꺼지라",
  "노인네들",
  "좋아지는",
  "별로고",
  "움직임에",
  "전화주세요",
  "놀음을",
  "아코디언을",
  "흩어지지",
  "성도",
  "강화하고",
  "맞나봐",
  "협정",
  "소나기",
  "두렵지도",
  "아니더라고",
  "기각합니다",
  "십니다",
  "퇴원하면",
  "만점에",
  "살인이라고",

  "팔리게",
  "지나갔는데",
  "심문은",

  "합친",
  "찼지",
  "있으실",


  "건들지마",
  "초상",
  "잘못인가",
  "들었겠죠",
  "반해서",
  "군인의",
  "볼수가",
  "미운",
  "케이트랑",
  "안협네가",
  "셰일라를",
  "어련히",
  "양성자를",
  "신기하지",
  "피타랑",
  "흉폭한",
  "자살은",
  "구하기위해",

  "화장이",
  "트위터에",
  "찾아볼까",
  "가격으로",

  "기대감에",
  "붙이나요",
  "합",
  "혼란은",
  "백인이야",
  "무기력하고",
  "억울하게",


  "맡겨요",
  "백성은",

  "티는",
  "억양은",
  "거두노라",
  "꾸게",
  "어른을",
  "달라면",
  "콜비를",
  "변호할",
  "없어졌네",
  "홍삼",
  "궁금하면",
  "지겹지도",
  "능력으로",

  "베라는",

  "타샤",

  "지들끼리",
  "컨디션",
  "읽어줄게",

  "놈까지",
  "할머니께서",
  "훑어",
  "아자",

  "안아줄거야",
  "교차로",
  "송유관을",
  "뭐라는거야",

  "가르침",
  "필요하겠지",
  "손이지",
  "대군이",
  "양성자",
  "스폰서를",


  "이벨린의",
  "진출",
  "압박에",
  "방어할",
  "승자조",

  "그리젤다",

  "알려주시면",
  "안녕이라고",
  "순간이죠",
  "무서워하지도",
  "만지작",
  "안되는지",
  "다녀도",
  "더러워서",

  "세상에는",
  "올인",
  "살라딘의",
  "오기를",
  "파일럿",

  "찾아보는",
  "달린이",
  "그말이야",
  "굉장했어",
  "잭의",
  "죽일겁니다",
  "켄리의",
  "영주의",
  "테스트에",
  "요정님",
  "화이팅",
  "내어",
  "칼질을",

  "꿈꿔왔던",

  "아들인",
  "창녀랑",
  "납시오",

  "셰일라와",

  "낙태",
  "찍었을",
  "갈비를",
  "사다리를",
  "잘하도록",
  "원정",
  "똥줄이",
  "하나님과",
  "질투의",
  "준비해서",
  "플로리다에서",
  "아름다웠지",
  "더없이",
  "빨아먹는",
  "고얀",

  "말인즉슨",
  "미안하네요",
  "그래프",
  "오필리아",
  "머슴",
  "삼돌이가",
  "그놈이야",
  "건네줘",


  "돈벌이가",
  "힘겨운",
  "약속했던",
  "선례가",

  "베이씨",
  "있노",
  "킵의",
  "차려봐",
  "살펴봐도",

  "손톱을",
  "찾으십시오",

  "말인디",

  "따는",
  "개인이",
  "일당",
  "국가는",

  "중이오",
  "기사들은",
  "녹색등",
  "죽었",
  "팀이다",

  "시카고를",
  "발자국만",
  "예상하는",
  "마나와",
  "찢어져",
  "음성을",
  "묘기를",

  "개똥",
  "경제적으로",
  "이벨린",
  "자신보다",
  "장군이라고",
  "증언은",
  "남성용",
  "따고",

  "따야",
  "켄리는",
  "뒷산에",
  "깨끗함",
  "반죽을",
  "켄리가",
  "카디프",
  "노예처럼",
  "메세지는",
  "농담하시는",

  "라의",
  "클쓰워너비",
  "중이었네",
  "덮어주세요",
  "하드코어야",
  "공증인",
  "무니의",
  "기억하냐",
  "보낸거",
  "결혼하려고",
  "노을이",
  "유용한",
  "갔는데요",
  "박스는",
  "멈췄다가",
  "지난해",
  "클로르프로마진",
  "질문들",
  "우주복",

  "멈춰버린",
  "내리소서",
  "아쉬운",
  "천석",
  "아기야",
  "전일성",
  "해군을",
  "인순",
  "무리해서",
  "우실장",
  "소리입니다",
  "무리하게",
  "는데요",
  "얘요",
  "내가있다",
  "티리온의",
  "시경",
  "파견",
  "업체",
  "웨스테로스로",
  "둥둥둥",
  "에두아르도",
  "이루고",
  "얘기고",
  "뭐하게요",
  "노예상들이",
  "올라가세요",
  "잃어버렸다",
  "유서깊은",
  "협해를",
  "청구서를",
  "달랐던",
  "자경단원이",
  "추앙받던",
  "클럽을",

  "돌아왔소",
  "간청",
  "차량에",
  "카메라들이",
  "심판",
  "품안에",
  "아입니까",
  "사이버맨으로서",
  "팔아먹고",
  "이혁명은",
  "제공할",
  "드로고의",
  "몇개로",
  "짬밥이",
  "강력계",
  "웃죠",
  "방향은",
  "왼손으로",
  "냈죠",
  "요지는",
  "시빌코이에서",
  "삼류",
  "감사하세요",
  "달러와",
  "릭의",
  "취향도",
  "난잡하게",
  "토멘을",
  "백진수",
  "알려주려고",
  "주차장",
  "사형이",
  "가소잉",
  "그러려무나",
  "민법",
  "다방",
  "포투나",
  "좀있다",
  "코미디를",
  "싸인을",
  "헤르만",
  "곤잘레스",
  "사체를",
  "알아듣지",
  "인테리어",
  "그렇답니다",
  "존재와",
  "들이대지",
  "양손을",
  "받아들여요",
  "기관사",
  "모퉁이",
  "마치겠습니다",
  "얼굴이군",

  "우형사",
  "자루에",
  "토멘은",
  "만반의",
  "행성들이",
  "도리스",
  "알고싶어요",
  "안한다는",
  "영상에",
  "봉투가",
  "터는",
  "아매도",
  "실패했습니다",
  "나왔다는",
  "파우더",
  "이시도로",
  "씹새끼",
  "페이지를",
  "계획이에요",

  "못하네",
  "위치도",
  "답지",
  "앵글",
  "했으니깐",
  "보석금으로",
  "작성해서",
  "사표",
  "리카르도",
  "채찍질",
  "해냈는지",
  "보냈을",
  "하대",
  "재밌어지는군",
  "그러겠습니다",
  "그놈도",
  "걸었습니다",
  "니콜라이를",
  "오요",
  "부딪쳐서",
  "도와줬던",

  "금속에",
  "찾으며",
  "기대해요",
  "괜찮았을",
  "분리를",
  "대형을",
  "저것은",
  "나오신",
  "그리워하지",
  "보내야겠어",
  "전부인이",
  "박경위",
  "오슨을",
  "싫증이",
  "그런대로",
  "얘들의",
  "외진",
  "모시겠습니다",
  "내켜",
  "누구신데",
  "불공평하잖아",
  "떨어지나",
  "탓이지",

  "여기에있다",
  "싶어한다",
  "드는데요",
  "비웠어요",
  "물어봤던",
  "때문이었죠",
  "다아시",
  "선택이죠",
  "레이저를",
  "이름표를",
  "공로를",
  "임무의",
  "로니를",
  "바위가",
  "문제있는",
  "의사들과",
  "펜타닐",
  "겁탈했어",
  "가루",
  "아처의",
  "아처가",
  "바람개비",
  "되돌아갈",
  "옮기자고",
  "구보",
  "신들과",
  "점심도",
  "아처를",
  "러브크래프트",
  "애비야",
  "끄도록",
  "파타",
  "허무로",
  "시나리오",
  "하러요",
  "제복을",
  "받아야죠",
  "봐주실",
  "가난뱅이",
  "벌지",
  "부자고",
  "박창민",
  "정글에서",
  "바쁩니다",
  "스스로는",
  "그분들은",
  "주인님을",
  "인상적이야",
  "간거죠",

  "키에",
  "안돼서",
  "미라를",
  "가족이니",
  "평해",
  "부욱",
  "관두지",
  "스코틀랜드의",
  "헝거게임의",
  "의논해",
  "살인으로",
  "왔냐고",
  "화물에",
  "휘이",
  "고메즈는",
  "경이로운",
  "아린의",
  "온건가",
  "직면한",
  "환상에서",
  "쫓을",
  "철수가",
  "빠르다",
  "도와주시면",
  "말씀해주실",

  "방법이니까",
  "머리부터",
  "정상에",
  "난방을",
  "송화야",
  "남편이란",
  "문과",
  "고소하고",
  "놈에겐",
  "그러면은",
  "해안이",
  "사항에",
  "왕가의",
  "샌드위치가",
  "정치가",
  "안그래도",
  "파시스트",
  "독일을",
  "났단다",
  "골절된",
  "전이잖아",
  "들어가니까",
  "실종자들을",
  "읽어라",
  "않으마",
  "바쁘니",
  "규명하는",
  "간호",
  "悲しみなんて吐きだして",
  "슬픔따윈",
  "접근하기",
  "할때는",
  "동네는",
  "토해버리고",
  "너무해요",
  "갔을때",
  "前だけ見てればいいんだっけ",
  "특급이란",
  "아시면서",
  "남자애를",


  "혈관들이",
  "함게",
  "니콜라스는",
  "시작됐죠",
  "대너리스가",
  "부탁드릴게요",
  "받을때",
  "모든것",
  "それじゃとてもまともでいられない",
  "많으십니다",
  "떳떳해",
  "괜찮은거죠",
  "하는건데요",
  "안올거야",
  "가면된다고",
  "다됐어",
  "맞거든",
  "바늘이",
  "캐틀린",
  "두목",
  "중위님",
  "적과의",
  "기관차가",
  "제정신이에요",
  "탑승",
  "인사라도",
  "あの水平線が遠ざかっていく",
  "받았거든요",

  "범행에",
  "수평선이",
  "내방에",
  "있던걸까",
  "靑すぎた空には明日すら描けなくて",
  "푸르기만한",
  "내일조차",
  "불사신에",
  "별론데",
  "켈시",
  "타곤",
  "걱정말게",
  "트리",
  "브라보스의",
  "기관실",
  "僕はいつからここに潛りこんだんだ",
  "이틈에",
  "발견했을",
  "한시간만",
  "안갈거에요",
  "든다면",
  "쥐새끼를",

  "만족한다면",
  "수술하라고",
  "もう二度と溺れないよ",
  "따바르씨",
  "가벼웠어",
  "지켜봤어",
  "누리아는",
  "유기",
  "말썽이",

  "단조가",
  "보여줬지",
  "속하지",
  "제거했어",
  "발짝",
  "차크라가",
  "걸릴지도",
  "데려가자",
  "한땐",
  "일이냐고",
  "일본인은",
  "카제카게",
  "사격을",
  "도와주기로",
  "카레브입니다",
  "도쿄에",
  "당한거야",
  "생존을",
  "죽어간다고",
  "나쁘지만은",
  "루시아",
  "올수",
  "뭉개지겠어",
  "혐의",
  "낼수",

  "준비됐다",
  "光をかすかに感じてるんだ",
  "통행증",

  "기관차는",
  "꽝",

  "따라가요",
  "병신들아",
  "스펜서가",
  "闇を見上げるだけの僕じゃ",
  "짓이었어",
  "어디가세요",
  "안와서",
  "야인들을",
  "만들어주는",
  "착륙했다면",
  "새랑",
  "버터를",
  "넌덜머리가",
  "자이언트",
  "이일을",
  "ただの幸せに氣づいたら",
  "한명만",
  "준석아",
  "올려볼",
  "작아도",
  "멘토가",
  "예뻐라",
  "나로썬",
  "형제의",
  "기억나는거",
  "구린내입니다",
  "모종의",
  "계좌에",
  "돌아버리겠네",
  "탄환은",
  "찢어발기려고",
  "하현수",
  "괴롭힘",
  "이십니다",
  "가셨을",
  "피부색",
  "재밌단",


  "알바니아어",
  "고쳤고",
  "좋았단다",
  "인공지능",
  "기다린다면",
  "헤어지지",
  "대씩만",
  "째는",
  "미니보이",
  "진급",
  "잡지는",
  "약속이야",
  "은기",
  "경위",
  "키웠다",
  "스콧이",
  "오빠의",
  "탄환에",
  "독해",
  "세세하게",
  "어딨는데요",
  "판사에게",
  "케넥스",
  "기관차의",
  "부관",
  "않을거라",
  "이지현",
  "헤이스팅",
  "많은게",
  "세계와",
  "졸업한",
  "넘었는데",
  "통로로",
  "보이구요",
  "비치지",
  "유형이",
  "데죠",
  "지마",
  "빼고는요",
  "입주권을",
  "좋았네",
  "시기가",
  "너에게는",
  "대원이",
  "생각해보렴",

  "서류와",
  "사느라",
  "애미를",
  "할라구",
  "구형",
  "주드",
  "노트",
  "조용했어요",
  "은혜는",
  "신기하죠",
  "말까한",
  "살아나지",
  "수준이에요",
  "내려도",
  "스테이션",
  "따라잡고",
  "버리겠어",
  "직원들의",
  "빠리올",
  "영광이네요",
  "이외에",
  "여자친구에게",
  "싶지않아",
  "씨라고",
  "빠울렛따",
  "안드레이",
  "쟝",
  "캐슬린",
  "허락할게",
  "요트",

  "뒤쳐져",
  "콜야",
  "갤러리에",
  "우리사이가",
  "앉았지",
  "꺼버려",
  "이사했어요",
  "아비로서",
  "어떻던가요",
  "네비게이션",
  "곳에서는",
  "흐를",
  "죽였습니까",
  "체육관으로",
  "끝났을",
  "공정하게",
  "도리안",
  "년대",
  "확인은",
  "이후부터",
  "그랬는데요",
  "찌르면",
  "쌍둥이가",
  "삐또리우아",
  "걸어야지",
  "설계도를",
  "했었다는",
  "옮으면",
  "데킬라를",
  "보니는",
  "드리는데",
  "어디인가",
  "소리재",
  "완수한",
  "대피할",
  "보래요",
  "조국에",
  "염부가",
  "위험하단",
  "자국은",
  "후방",
  "로갈",
  "어긋나",

  "피곤",
  "육체적으로",
  "누구더라",
  "천체",
  "잘려나간",
  "익숙해져",
  "씁시다",
  "령도자님이",
  "마리아여",
  "끝난거",
  "승리한다",
  "동일",
  "안돼고",
  "육군에",
  "일거야",
  "해서지",
  "가까워지면",

  "데려다줄게",
  "안녕히계세요",
  "타박상",
  "나오셨습니까",
  "이륙해",
  "의심됩니다",

  "구사하라",
  "옳다",
  "보여달라고",

  "화공을",
  "한오백년",
  "심각한거야",
  "사자는데",
  "성화요",

  "하나인",
  "개입",

  "동맹은",

  "정답",
  "허름한",
  "일좀",
  "강력합니다",
  "릴라를",
  "떠들면",
  "안했죠",
  "필요로하지",
  "말할때",
  "부리토",
  "하십쇼",
  "생각이고",
  "계곡을",
  "기도할게요",
  "상부에서",
  "가정부는",
  "케익은",
  "바랠",
  "프라이를",
  "적군이",
  "바닐라카푸치노",
  "않은건",
  "지키고자",
  "별빛이",


  "돌봐줘",
  "방금전까지",
  "아프리카를",
  "준비에",
  "규모는",
  "반죽기",
  "플린",
  "아이스",
  "부탁했다",
  "삽입하고",
  "못하겠네",
  "했노",
  "저급한",
  "리노어가",
  "멈추시오",
  "상태도",

  "노는게",
  "오래가는",
  "조조가",
  "지점에",
  "미군을",
  "모니카",
  "일세",
  "베드로는",
  "재판도",
  "순구",
  "팀원들",
  "짓이니까",
  "회의실에",
  "강요는",
  "군장",
  "때렸는데",
  "부친께선",
  "진행한다",
  "생각하시겠죠",
  "범죄자로",
  "안개를",
  "자동차는",
  "라바",
  "자연계의",
  "켄타우로스",
  "보여주십시오",
  "베오그라드",
  "연구의",
  "병세가",

  "전쟁에서도",
  "숙재",
  "놓쳐선",
  "장마",
  "해야하는데",
  "사람이라구",
  "노래와",
  "뮤지컬로",
  "옘병",
  "원하시오",
  "쫓지",
  "끊기",
  "사분님",

  "이분과",
  "민주주의를",
  "주당",
  "어쩔까요",
  "열대",
  "이야기지",
  "두당",
  "안된다구요",
  "고아를",
  "스리랑카의",

  "관통했어요",
  "사람과의",
  "생각이요",
  "안정되면",
  "상우야",
  "군단을",
  "확보를",
  "좋아하지는",
  "회생",
  "단검이",
  "손잡이의",
  "분명합니다",
  "중고차",
  "감기는",
  "모방범",
  "자식한테",
  "통계",
  "벤치를",

  "배운다",
  "카레브를",

  "동반자",
  "습기가",
  "회비",
  "폐렴에",
  "호출하게",
  "일어났네",
  "갈리프레이를",
  "추위와",
  "섭취",
  "아들하고",
  "맹장염",
  "밑이나",
  "하고나서",

  "활화산처럼",
  "마을도",
  "됐을걸요",

  "셀든이",

  "했지만요",
  "공간으로",
  "청춘은",

  "벌어졌다",

  "니잠은",
  "꿍꿍이가",
  "타임로드께서",
  "명월채",
  "잡았냐",
  "부끄러운줄",
  "뿐이라면요",
  "고용했어",
  "투여하세요",
  "친구들의",
  "추측할",
  "재호야",
  "찾으셨나요",

  "일했지",
  "빚진게",
  "도플러",
  "철수를",
  "폭발은",
  "책임자로",
  "먹었냐",
  "코앞에서",
  "지키겠다고",
  "보스토니안써니",
  "조이드버그",

  "전역을",
  "꿇지",
  "편찮으십니까",
  "버리겠다",
  "구합시다",
  "그넘을",
  "형수를",
  "헛되지",
  "가봐야해",
  "데려가라고",
  "다릴",
  "샅샅히",
  "버텨라",
  "브리핑",
  "옥순",
  "크리스탈",
  "사수하라",
  "사이렌이",
  "화력",
  "민병대는",
  "골프",
  "정도를",
  "오셨으니",
  "찬스를",
  "진입한다",
  "빨아들이는",
  "태양계를",
  "말한적",
  "제닝스",
  "애덤슨",

  "동반자로",
  "화학검사",
  "셀게요",
  "탐지할",
  "안타깝게",
  "목걸이도",
  "정해보라고",
  "편이지만",
  "가르쳤어",
  "대열이",
  "좋으세요",
  "중간에서",
  "알프레드가",

  "골든",
  "싸요",
  "맞기도",
  "많다니까",
  "보여줘라",
  "잡일을",
  "창씨를",
  "하동무",
  "니네집",
  "있는지요",
  "된걸",

  "은주야",
  "가정적인",
  "어디가면",
  "썩어",
  "쓰이지만",
  "방사선학자",
  "공중전화를",
  "맥없이",
  "아들이기",
  "물리치고",
  "쿵푸가",
  "정원이야",
  "테레비",
  "일일랑",
  "대기중입니다",
  "가득차",
  "미친거죠",
  "아들이오",
  "모스크바에서",

  "그아이는",
  "축하를",
  "무사해요",
  "대사부가",
  "부탁인데요",
  "공부합니다",
  "통해서는",
  "봐주고",
  "쿵푸를",
  "수술이었어",
  "선조",
  "리사를",
  "먼로의",
  "수속",
  "수고해라",
  "이순경",
  "추모",
  "시작됐습니다",
  "더하면",
  "황송합니다",
  "우승자",
  "방마다",
  "아프대요",
  "노틀담은",

  "다른지만",

  "혼인하면",
  "그대들에게",
  "잔이",
  "이웃과",
  "전역에서",
  "하부",
  "수군은",
  "오리온",
  "마일을",
  "열매가",
  "포기하라고",
  "않으리라",
  "어깨와",
  "의미하죠",
  "스피드를",
  "비정한",
  "보금자리를",
  "대항해서",
  "싶습니다만",
  "따라가고",
  "회복한",
  "친히",
  "데브을",
  "쓰거든",
  "명사",
  "사람들요",
  "될수도",
  "혈을",
  "코블팟",
  "필요하겠군",
  "있어야돼",
  "서커스가",
  "비밀스런",
  "메달을",
  "닥터카레프",
  "야이씨",
  "띄면",
  "원격",
  "유지합니다",
  "당근이죠",
  "사람이고요",
  "좋은아침",
  "토멘이",
  "이게뭐야",
  "숨쉬기가",
  "늘었어",
  "시체의",
  "정리만",
  "시피",
  "떠났다는",

  "기자들도",
  "귀족들은",
  "동성애자들은",
  "편이거든요",
  "안다니까",
  "랄라가",
  "실수입니다",
  "어처구니없는",
  "샘도",
  "돌아온거",
  "오는걸",
  "기도합니다",
  "길죠",
  "묻게",
  "황제는",
  "알려줄께",
  "성과를",
  "좆됐다",
  "덴마크의",
  "황제가",
  "팬더는",
  "불리죠",
  "외교",
  "질문들에",
  "우왕",

  "왕복선이",
  "피웠어",
  "동만이",
  "누를려고",
  "떠날때",
  "공부하기",
  "확대해서",
  "사탕주면",
  "안잡아먹지",
  "덤보를",
  "계속하시오",
  "감으라고",
  "풍족하게",
  "잘돼가",
  "호출해줘요",
  "코끼리는",
  "달래자",
  "가라앉고",
  "탐침을",

  "흥이",
  "나눠진",
  "은신처로",
  "바라보며",
  "서있던",
  "한달동안",
  "밀주",

  "시작한지",


  "왔더군요",
  "빅토르",
  "나온거야",
  "대왕",

  "제출해",

  "잡히나",
  "제보가",
  "국내외로",
  "내도록",

  "산호",
  "원하네",
  "귀엽군",
  "이여",
  "가져다줄게",
  "메기",
  "군주가",
  "글자를",
  "모집",
  "뚱보가",
  "잇지",

  "포함해",
  "동족들을",
  "가져갔습니다",
  "먹어치우는",
  "길준아",
  "탄약을",
  "무등",
  "지냈습니다",
  "수배자",
  "오리가",
  "신화",
  "미숙한",
  "소설에",
  "아들이죠",
  "동수",
  "수군",
  "차례요",
  "남자였어",
  "점원",
  "장비야",
  "없었을거야",
  "그댄",


  "말해주마",
  "뭐람",
  "주시니",
  "뒤로하고",
  "왔으며",
  "환상적이네요",
  "알린다",
  "하우먼",
  "손자병법",
  "정중한",
  "숨막히는",
  "페니스",
  "퀴즈",
  "최악도",
  "공에게",
  "부재",
  "입구에",
  "걸렸나",
  "땄다",

  "집게",
  "백업",
  "어른스럽게",
  "사본을",
  "공격해야",
  "임시로",
  "쐈지",
  "얘기해주지",
  "짓일랑",
  "주유는",
  "집중력을",
  "요란하게",
  "테크",
  "리얼리티",
  "친척이",
  "모였어",
  "살해당했어",
  "두려고",
  "고밖에",
  "돌아가는게",
  "무리에",
  "나쁜놈이",
  "행여",
  "하나였다",
  "이면",
  "살아있나",
  "쓰지말고",
  "닭강정",
  "계시는군요",
  "아마존",

  "시간이고",
  "싸웠지",
  "재미없어요",
  "꺼내기",
  "걸렸습니다",
  "질병이",

  "좋아졌습니다",
  "이대론",
  "무의미해",
  "들어가는걸",
  "빠져나오려",
  "숙녀들은",
  "바꿔도",

  "어리석게",
  "식인",
  "설탕맛",
  "있어야한다",
  "진흙에",
  "선생님이에요",
  "누이에게",
  "제공한",
  "각종",
  "보내드립니다",
  "딴건",
  "카펫",
  "술법",
  "이지와",
  "사스케의",

  "욕조에서",
  "도버",
  "슬라이드",
  "쉐인",
  "백마",
  "속한다",
  "산딸기요",
  "아스트리드",
  "브라더",

  "거짓이야",
  "죽었을걸",
  "새끼지",
  "점잖게",
  "가져왔느냐",
  "극을",

  "확인하려고",

  "뿐이라는",
  "물어봤죠",
  "앉아있다",
  "선택하신",
  "붙들아",
  "딜러",
  "블랙잭",
  "있었던거",
  "루드비히",
  "내꺼라고",
  "뻥치시네",
  "활용해",
  "결말",
  "맞춥니다",
  "들어있고",
  "발견됐죠",
  "봉급",
  "뭍으루",
  "싣지",
  "쥬디를",
  "알아낼거야",
  "시위를",
  "새바라야",
  "조부",
  "거물",
  "가고싶어",
  "망가트린",
  "만행",
  "적어줬어요",
  "중독자야",
  "이용하지",
  "먹기지",
  "문제냐고",
  "비응급",
  "들어왔다고",
  "다나다라",
  "말인지는",
  "침대에선",
  "나모라",
  "요금이",
  "누구한테요",
  "죽이기로",
  "딸이니까",
  "끌어들일",
  "보았다고",
  "책상위에",
  "스토리는",
  "마리화나",

  "틀어봐",
  "안도감을",
  "보내셔야",
  "감사했어요",

  "보고서가",
  "빌릴까요",
  "제안에",
  "돌아와라",
  "무기라고",
  "지불했고",
  "잃진",

  "뚜리뚜밥",
  "이리들",
  "스튜와",
  "일라가",
  "칼라",
  "나갔군",
  "닫으면",
  "현실이라니",
  "밴드의",
  "최고일",
  "천지에",
  "불과하다",
  "죽고싶지",
  "지금이든",
  "난하지",
  "진저리가",
  "내가해야",
  "말해준다는",
  "피곤하실",
  "죽여버린다",
  "고버",
  "타임라인을",
  "리더는",
  "타락시킨",
  "나아지게",
  "립이",
  "가야한다",
  "추억으로",
  "가야한다고",
  "도시도",
  "블루레이",
  "처음도",
  "덕입니다",
  "질문들이",
  "우편으로",
  "호기심을",
  "원숭이처럼",
  "버리겠다고",
  "울림",
  "띄우세요",
  "강간당한",
  "어젯밤부터",
  "심각하군요",
  "뜻이라고",
  "속였지",
  "대답하면",
  "모퉁이에",

  "는요",
  "원하",
  "지내냐",
  "이뤄낼",
  "브랜디를",
  "훌륭한지",
  "범죄자를",

  "당신없이는",
  "작품이야",
  "숨소리를",
  "걔네한테",
  "산업이",
  "연필이",
  "좋을거에요",
  "작성하는",
  "이브닝",

  "오래죠",
  "뒤엉키는구나",
  "고치러",
  "엉망이다",
  "그랜디를",
  "고딩",
  "브라더스",
  "유산을",
  "조나스",
  "유산은",
  "유서를",
  "제공하지",
  "기다린단다",
  "회사들은",
  "엉덩이로",
  "들어있었어",
  "조부님을",
  "토미를",
  "곳이었죠",
  "뜨지",
  "신고는",
  "장소다",
  "해적행위를",
  "고소한",
  "산체스가",
  "사회적인",
  "새하얀",
  "확신하는데",


  "전향",
  "부린",
  "고문이다",
  "꺼져있어",
  "침실에서",
  "전화할까",
  "인쇄술은",
  "전화길",

  "스트레치",
  "수수께끼를",
  "안했다",
  "어떻게할까요",
  "길어요",

  "쓰러뜨려",
  "고전적인",
  "됐을지",
  "우리로",
  "나오나요",
  "민족의",
  "아루틴",
  "아메리카에서",
  "했었고",

  "써주세요",
  "영화와",
  "누르세요",
  "명망",

  "평생의",
  "괜찮겠습니까",
  "탈거야",
  "갇혔어요",
  "코러스",
  "들어오거나",
  "타이슨",
  "우연일",
  "조용히하라",
  "퀸이",
  "정맥에",
  "난이",
  "해적선",
  "결혼한다는",
  "조심하시고요",
  "그럴땐",
  "써야겠어",
  "언어로",
  "코박스",
  "쐈습니다",
  "릭은",
  "프렌드",
  "아니었",
  "샀을",
  "알야",
  "돌과",
  "매일매일이",
  "왼편으로",
  "아녀요",
  "재하야",
  "선녀님이",
  "해하지",
  "묘지들이",
  "어딨는지는",
  "영광이야",
  "데려갈거야",
  "탱크가",
  "대피해야",
  "가출",
  "조교님",
  "살아있습니다",
  "때까지만요",

  "보지는",
  "한기준",
  "번뇌를",
  "더기",
  "실시간으로",
  "엄청났어요",

  "이분의",
  "손이나",
  "먹통",
  "방식이라고",
  "알다",
  "블러드비스트",
  "호랑이가",
  "뛰어서",
  "돌리라고",
  "퀸랜의",
  "박탈",

  "친구들처럼",

  "사내는",
  "토스트를",
  "마티넬리를",
  "넘어진",
  "제안합니다",
  "글쎼",
  "안하죠",
  "선보일",

  "살아보고",
  "심해져요",
  "아놀드씨",
  "빗물만이",
  "검사로",
  "안봐",
  "겁먹지는",
  "그런걸로",
  "알아내려",
  "상대하려면",
  "집중할게",

  "이안이에요",
  "산딸기가",
  "뒷간에",
  "브레멘",
  "으웩",
  "일라는",
  "넘겼어요",
  "장가를",
  "흑해",
  "치르거라",
  "뒤쪽을",

  "폐와",
  "햇살이",
  "명심하세요",
  "애국심",
  "그래왔듯",
  "혈중",
  "아스가르드를",
  "세상이다",
  "이유고",
  "공급하고",
  "복용하고",
  "사이에요",
  "재혼",
  "사람이었죠",
  "안되는데요",
  "토르가",
  "왕이시여",
  "나을거야",
  "머물러야",
  "무서운지",
  "전환점이",

  "귀머거리",
  "기분만",
  "읽어봐요",
  "고민하고",
  "대리인이",
  "마취제에",
  "커지는",
  "죽는거",
  "처녀는",

  "신사는",

  "관련도",
  "감독님이",
  "셈과",
  "퇴직을",
  "백조를",
  "수술해서",
  "헤라는",
  "저남자는",
  "자네나",
  "치료했습니다",
  "있는대로",
  "혼인한",
  "장루",
  "부패할",
  "치어서",
  "다음은요",
  "않던데요",

  "확인해봤어요",
  "박씨",
  "일어난건지",
  "절벽에서",
  "형제와",
  "갈망하고",
  "잠재력을",
  "들러붙어서",
  "의지하고",
  "팔만",
  "똥구멍이",
  "내비둬",
  "기업이",
  "수업도",
  "보고있어요",

  "아름다웠던",
  "발목은",
  "참가하고",
  "그렇지않아",
  "멍청하다는",
  "포커",
  "형상대로",
  "전시돼",
  "담당한",
  "여는데",
  "섬길",
  "에테르가",
  "대에",
  "하버드에서",
  "자리니까",
  "봉합사를",
  "문닫게",
  "앞길이",
  "그사람들",
  "순간부터요",
  "자연재해였죠",
  "아쉽군",
  "진지하고",
  "재능있고",
  "하드코어",
  "서러워",
  "가진다고",
  "이거라고",
  "가지고있는",
  "좋아유",
  "사형선고를",
  "권력은",
  "텔레비전에서",
  "그러신",
  "야해",
  "뜻하신",
  "잘못되고",
  "부탁하나만",
  "연락만",
  "잘되면",
  "공작의",
  "생각일랑",

  "볼튼의",
  "자식입니다",
  "오시네",
  "로프로",
  "입원해야",
  "가져왔던",
  "객실에서",
  "재워주고",

  "우즈요",

  "밝혀내면",
  "작지",
  "것이나",

  "그럴필요",
  "훔쳐가서",
  "댁들은",
  "진술",
  "라스",
  "얘기할게요",

  "상실을",
  "듣지마",
  "만으로도",
  "라멕의",
  "존경합니다",
  "멱",
  "작살",
  "왕비한테",
  "도련님도",
  "요새도",
  "해서든지",

  "오늘밤을",
  "되었다는",
  "에펠",
  "장난하니",

  "들어가세",
  "행복하니",
  "별장에",
  "영식이",
  "쉬자",
  "우주인들이었고",
  "얘기라고",
  "화제가",
  "조각나지",
  "일이든지",
  "도우면",
  "느이들",
  "입고있는",

  "토끼처럼",
  "뿐이었죠",
  "찾는거",
  "어머니요",
  "확인좀",
  "어디들",

  "상쾌하지",

  "바다는",
  "일해도",
  "멀미약인데요",
  "주말이",
  "않으실래요",
  "지원할",
  "상아부리",

  "영광이오",

  "시스템으로",
  "야벳은",
  "어디가는데",

  "구스타브가",
  "무셔",


  "아웅",
  "라스베가스에",
  "보트에서",



  "아셨겠죠",
  "친절하지",
  "않으리란",
  "짐승과",
  "자비의",
  "정의요",
  "달동안",
  "자장",
  "춰요",
  "호텔이",
  "기조",
  "어느쪽",
  "살펴보겠습니다",
  "명확하고",

  "귈",


  "도착하게",
  "스승이었습니다",
  "엄니가",

  "상처받는다고요",
  "이번건",
  "원하는가",
  "다가와",
  "조작기를",
  "조지라고",
  "건배합시다",
  "상관입니까",
  "당신이에",
  "죽인다고요",

  "친구이기도",
  "보내야지",
  "손잡이",
  "뺏으려",
  "고전",
  "괜찮은거에요",
  "말레키스",
  "팔찌형",
  "안맞는것",

  "풍기면서",
  "지내시죠",
  "도와줘도",
  "썼습니다",
  "멜은",
  "안승필이",
  "죽어있었어",
  "올거다",
  "그분께선",

  "받거나",
  "지니아",
  "쓰레기같은",
  "믿는게",
  "걱정하게",
  "여든",

  "초코렛",
  "보는거",
  "어두울",
  "보는것",
  "왼편",
  "소매에",
  "나가야겠어요",
  "흉부외과를",
  "대비해서요",
  "어슬렁거리고",
  "트레일러를",
  "사라졌네요",
  "사랑했단",
  "성공해야",
  "일부만",
  "주인들이",
  "그랬는",
  "녹색으로",
  "씩스",
  "빅맥",
  "계시지만",
  "바로잡기",
  "폐도",
  "안간힘을",
  "すくえるくらいで",
  "자원해서",
  "쓸쓸히",
  "充分なんだ",
  "건져내는",
  "물건으로",
  "책이죠",
  "分け合える",
  "부처님한테",
  "장치인진",
  "人がいるか",
  "메리에게",
  "맞춰요",
  "いないかだけだ",
  "성숙한",
  "두려웠다",
  "어찌해야",
  "해왔지만",
  "들판에",
  "굶겨",
  "이라면",
  "가질수록",
  "촛불을",
  "던졌어",
  "매니큐어",
  "맡기세요",

  "버티진",
  "진전",
  "안정되고",
  "축하하고",
  "찾아올게",
  "김소동",
  "전입니다",
  "사마귀가",
  "간난이",
  "연출보",
  "코리아",

  "뜻이네",
  "오십니까",

  "어려운지",
  "암시장에",
  "억양을",
  "들었네요",
  "나무들은",
  "지혈이",
  "먹었소",
  "예정보다",
  "이내로",
  "으유",
  "그들에게서",
  "권력",
  "상처받을",
  "대량의",
  "보기가",
  "기온은",
  "평이나",
  "지혜야",
  "몇년전에",
  "작곡도",

  "내려오면",

  "판자를",
  "본능적으로",
  "헌터",
  "영혼으로",
  "유언장을",
  "삼촌이야",
  "노래해요",
  "알아듣겠니",
  "땅콩을",
  "못가서",
  "않으셨어",
  "신사분",
  "베가스에",
  "건들면",
  "구룡이는",
  "엄마한테도",

  "뭐냐구",
  "해보란",
  "그동안의",
  "직격할",
  "부딪쳤어요",
  "생각하나봐",
  "미숙",
  "놔두질",
  "나서부터",
  "딱이네",
  "보호하려",
  "사는것",
  "않다가",
  "없으니깐",
  "누워있어",
  "스푼으로",

  "실습을",
  "세탁소",
  "고모는",
  "블라디",
  "방사선과에",
  "변함없어",
  "空に座り",
  "앤이에요",
  "それぞれの音を奏でていた",

  "세상에서가장",
  "염두에",
  "조안",
  "素直になれと言われなくても",
  "솔직해지라고",
  "이곳과",
  "淚はもう",
  "져서",
  "징징대",
  "박는",
  "足元で小さな海になった",

  "울기도",
  "질펀한",
  "카스텔루치오",
  "프랭키를",
  "병처럼",
  "보여봐",
  "배웠는지",

  "제군은",
  "僕の想いを連れて",
  "星達は",
  "땋는",
  "심장파트를",
  "정치적인",
  "때려줄",
  "한계는",
  "앉자",
  "살려주고",
  "돌아갑니다",
  "재생성할",
  "들의",
  "알아차렸어요",
  "생명이다",

  "일어나선",
  "먼지로",
  "끼치는",
  "측선으로",
  "주문했어요",


  "생각한다는",
  "다르게요",

  "뇌졸중이",
  "산사가",
  "되셨죠",
  "침입했어요",
  "번씩은",
  "잃었다고요",
  "바론",
  "측에",
  "파인애플",
  "기계에서",
  "赤く透き通る夕暮れの後",
  "당직실로",
  "약들을",
  "기관차",
  "와가",
  "만나보시죠",
  "연결하면",
  "보관할",
  "大嫌いだ",
  "大切を",
  "장물",
  "헬레나를",
  "빈혈",
  "살려줘야",
  "しってしまった",
  "패스를",
  "개구리를",
  "잘되는",
  "식당이",
  "외출할",
  "푸줏간",
  "알아버린",
  "ああ幸せなんて",
  "뉴욕은",
  "小さなスプ",
  "일기장",
  "확신했어요",
  "ンで",
  "바이크",
  "완벽하다",
  "백작님",
  "주고요",
  "카프로니",
  "엔트리에서",
  "쥐면",
  "먹어보는",
  "겁나지",
  "출발해도",
  "자네에게도",
  "갑상선",
  "말하실",
  "감독할",
  "키스와",
  "모질게",
  "空は動かない",
  "외래",
  "못하기",
  "무릎꿇고",
  "陽が昇り降りるだけ",
  "질뿐",
  "地面は動かない",
  "지면은",
  "조규철이",

  "병원이고",
  "獨りが嫌いだ",
  "발전할",
  "식품으로",
  "싫은데요",
  "그려서",
  "맞았군",
  "분이셔",
  "죽게될거야",
  "君が步くか",
  "처방해",
  "쪼께",
  "남자친구도",
  "미미야",
  "코고는",
  "步かないかだけだ",
  "무대가",
  "재미있었지",
  "뭐래나",
  "없을걸요",
  "일치할",
  "내옆에",
  "버렸을",
  "가축처럼",
  "네트워크에",
  "컸을",
  "형이에요",
  "숨거라",
  "초하르",
  "딸하고",
  "캐롤린을",
  "협조할",

  "여행사",
  "여쭤볼게요",
  "플러스",
  "집이라",
  "소련이",
  "생명체일",

  "했거나",
  "햇볕을",
  "몸매가",
  "의사소통",
  "무서워할",
  "스페인어로",
  "감사하다고",
  "갖겠다는데",
  "밥한테",
  "끝났나요",
  "뭐하지",
  "댕기는",
  "파탄",
  "피기백",
  "들르는",
  "영화처럼",
  "토끼는",
  "토끼를",

  "갔지만",
  "폭탄도",

  "소유가",
  "남잖아요",
  "정보와",
  "말들과",
  "출입이",
  "리네커를",
  "잃을게",
  "손해볼",
  "끌려고",
  "놓았습니다",
  "행크가",
  "전체는",
  "책장에",
  "새태님으로",
  "양쪽에",
  "상관없고",
  "연이를",
  "찾아냈고",
  "차기",
  "취급하고",
  "주려",
  "새태님이",
  "점장이",
  "전체적인",
  "누구던",
  "업그레이드하고",

  "바지도",
  "퀸랜에게",
  "피웠잖아",
  "모텔로",
  "게이라",
  "국제적인",
  "유치하게",
  "미래도어",
  "스캔해봤어",

  "지팡이를",
  "신체가",
  "몹은",
  "때라도",
  "것중",
  "필요하신가요",
  "고속도로에",
  "감정과",
  "통해서만",

  "연장을",
  "죽겠습니다",
  "시인役",
  "침대보를",
  "논문",
  "므두셀라",
  "파괴하실",

  "음성사서함으로",
  "아이돌",
  "궁금했습니다",
  "카레브에게",
  "일어서는",
  "배게",
  "들어간다고",
  "파나쉬",

  "종인",
  "직감은",
  "화학치료",
  "걷다",
  "떨며",
  "잡자",
  "콧수염이",
  "들리는군",
  "것이었죠",
  "방향이",

  "임무죠",
  "맛본",
  "것들만",
  "같냐고",
  "곡만",
  "석션해줘",
  "등의",
  "잡아갈",
  "리스토",
  "얻은게",
  "생각이군요",
  "시력",

  "돌아가자고",
  "상실",
  "힘들지도",
  "지나치는",
  "식이지",
  "오티스",
  "취소야",
  "태초엔",
  "된데",
  "홍수",
  "합쳐",
  "안갈래",
  "해주시구요",
  "동산에서",
  "이거보다",
  "생각안해",
  "지겹구나",
  "장담은",
  "주입한",
  "감자다",
  "현자",


  "지냈던",
  "올라와서",
  "경제가",
  "보물처럼",
  "넣어둬",
  "넉넉히",
  "사람들일",
  "줬지만",
  "납치한",
  "시설도",
  "존재했던",
  "성자",
  "돌려주러",
  "쿨해",
  "위협은",
  "뚫어서",
  "활동도",
  "내상을",
  "없어지겠네요",
  "불안정합니다",
  "벗겨지고",
  "하려구",
  "만났구나",
  "초기에",
  "어린애일",
  "영웅적인",
  "내것",

  "주잖아요",
  "치뤄",
  "담당하고",
  "인용하고",
  "피켓은",
  "빌려온",

  "고기는",
  "뭉툭한",
  "지낸다는",
  "시작하겠소",
  "산도르",
  "분야는",
  "누출이",
  "잘해보세요",
  "시애틀까지",
  "부수적인",

  "말이랑",
  "억조가",
  "가져올까요",
  "돌아가시는",
  "눈들이",
  "쉬우니까",

  "크레이그가",
  "에헤이야",
  "셈이군요",
  "프리에고",

  "마찬가지다",

  "닌",
  "바스토",

  "펴봐",
  "눈물도",
  "모래가",
  "환자일",
  "바보다",
  "작동하게",
  "컴퓨터에서",
  "마이애미의",
  "그려요",
  "끌린",
  "연아",
  "만들긴",
  "소비자가",
  "안하는게",
  "영혼도",
  "빌어줘",
  "잇는구나",
  "빗속에",
  "사실과",
  "프로그램으로",
  "가져가실",
  "끊어버렸어",
  "없다는건",
  "하자는데",
  "만들지도",

  "하자니까",
  "방세를",
  "카인",
  "작작해",
  "맛에",
  "사다야",
  "가져라",
  "멍청이는",
  "다운로드",
  "때조차도",
  "아벨",
  "다이어리",
  "먹였어",
  "못자모지",
  "자비가",

  "술마시게",
  "감시자들은",
  "된다는데",

  "중이었거든요",
  "옴살바",
  "선택사항이",

  "묻겠습니다",
  "데려갔을",
  "빚도",
  "식구들은",
  "있었겠지요",
  "복수하고",
  "심장마비를",
  "무대는",
  "브렌든",
  "했더니만",
  "건너야",
  "장군도",
  "백상허",
  "이어야",
  "작업실",

  "아버지라",
  "진타로",
  "청춘",
  "조선인",


  "실종에",
  "위협하고",
  "있응께",
  "그루",
  "자매님을",
  "환자부터",
  "큰일났어요",
  "상대에게",
  "복지",

  "뜻이고",
  "부르셨어요",
  "받기로",
  "빨치산이",

  "가지고도",
  "요코",
  "외계의",
  "오살할",
  "살았을",
  "할멈은",

  "대모님",
  "종양학과에",
  "가는디",
  "브라이오니",
  "멈추기",
  "오겄소",
  "제안했어요",
  "양아",
  "관객",
  "외과의처럼",

  "태양풍을",

  "발생시켰어",

  "니들한테",

  "무서운가",
  "만원이에요",
  "바꿔줄",
  "고발할",

  "좆이",
  "낭만의",
  "어딨나요",
  "죽였냐",

  "불가능은",
  "총독부에",
  "낯설고",
  "날씨예요",
  "인화성",

  "토레토",
  "탓인",
  "옷장의",
  "일어서라",
  "물망초",
  "물어보려",
  "꽂은",
  "백합",
  "인동",
  "전하라고",
  "밭에는",

  "토스트가",
  "되란",
  "저장소",
  "무드",
  "바에요",
  "없는디",

  "오렌지색",


  "장갑이",
  "사람말야",
  "눈처럼",
  "혀요",
  "인정했잖아",

  "자매들의",

  "지경까지",
  "산만",

  "도와주겠어",
  "에어백처럼",
  "부풀리면",
  "마리아야",
  "떠나버렸지",
  "남자였어요",

  "사고에서",
  "만나주셔서",


  "차비",
  "잊은거야",


  "자죠",
  "던지든",
  "자매님의",
  "날린",
  "뜻일까",
  "소송이",
  "세포는",
  "네녀석",
  "맨하튼",
  "염치가",
  "못참겠어",

  "것들보다",
  "루비가",
  "주나요",
  "개여울에",
  "트라키아",
  "병맥주",
  "상부에",
  "원자폭탄이",
  "세리가",

  "두개골에",
  "욕심내지",

  "공동묘지를",
  "보일땐",
  "이르다고",
  "부류",
  "사랑했는지",
  "있듯이",
  "시작이었다",
  "먹음직한",
  "노랫소리",
  "자전거로",
  "파괴한다",
  "이분들은",
  "돈많은",
  "말해본",
  "재앙은",
  "있겠다는",
  "많으시네요",
  "노선이",

  "처지를",
  "여러분들과",
  "죽이는거",
  "부정적인",

  "아시네요",
  "텔레파시가",

  "믿다니",
  "늙은이한테",
  "안됐었다",
  "걔에게",
  "이런거에",
  "코미디",
  "휴런과",
  "찾아가지고",
  "호텔은",
  "찾기를",
  "돌아다녀",
  "할거다",
  "괴로워하고",

  "보겠나",


  "여관",
  "뜻이잖아",


  "방법일",
  "쉐퍼드입니다",
  "케네스",
  "거는요",
  "마도",
  "번이고",
  "정희의",
  "반납작기",
  "위로는",

  "고문이",
  "놀랠",

  "풍차",
  "씹",
  "베이비시터",
  "완벽해야",
  "스토킹하고",
  "그놈들의",
  "끌려",
  "다니며",


  "십중팔구",
  "농사일",

  "뿔랑은",
  "몰랐었어",
  "방염",
  "헤어지게",
  "오즈의",
  "실었다",
  "가깝고",
  "아멜리의",
  "뿐이라구요",
  "해왔습니다",
  "벨트를",
  "잃어버렸던",
  "장교는",

  "쳐넣어",
  "옆방에서",
  "힘들겠지",
  "견딜수가",
  "소박하고",

  "에는",
  "리들리",
  "레티한테",
  "까마귀는",
  "나오더군요",

  "유독성",
  "불러라",
  "게르솜",

  "글씨를",
  "맹세합니까",

  "모신",
  "종교도",
  "토끼씨",
  "휴일을",

  "수속을",
  "밟을",
  "셀카",
  "따뜻함을",
  "위엔",

  "탓이다",

  "노파가",
  "말을하지",

  "사랑하네",

  "보낼수",
  "호출하면",
  "없으시네요",
  "데쓰",
  "인간이죠",
  "오트밀",
  "버팔로",
  "차에나",
  "맞혔어",
  "패하고",
  "교수형에",
  "구석으로",
  "책임질게",
  "될만큼",


  "하기위해",
  "까먹은",
  "무사한",
  "마이너스",
  "조사하러",
  "반동을",
  "수리남",
  "월터가",
  "테이블을",
  "가속기를",

  "뭐냐에",
  "일하잖아요",
  "평범할",
  "쏘는거야",
  "잔여물이",
  "훔쳐가는",



  "차안으로",
  "출력을",
  "누구부터",

  "내장도",
  "속단하면",
  "처리기",
  "우주에선",
  "발전한",
  "버전을",
  "꽂는",
  "적응하고",
  "희귀하고",
  "이런말",
  "새로고침",
  "발전해",
  "같아도",
  "범죄자들을",
  "팬이",

  "참는다",
  "생겨날",
  "믿겨져요",
  "마크한테",

  "위대하고",

  "계속하십시오",
  "두어야",
  "주에서",
  "가능하겠지",
  "굶주림을",
  "장부가",
  "루미스",
  "변경을",
  "있어줘야",
  "재미있었어요",
  "저기에요",
  "번쯤은",
  "친구분은",
  "사람이라는걸",
  "브릿지",
  "프랭클린이",
  "챔피온쉽",
  "끼워줘",
  "기회로",
  "피쉬의",
  "궁리를",
  "남을거야",
  "머피가",
  "유도",
  "곳이니까",
  "똥구멍에",
  "구해주려고",
  "찾아야해요",
  "실망하게",
  "사용했어요",
  "장군님은",
  "반갑군요",
  "어림",
  "메세지가",

  "한적은",
  "두곤",
  "기사에",
  "먼길을",
  "괴물의",
  "전공이",
  "묶여서",
  "끊어졌어",
  "꾸릴",
  "써볼까",
  "아지트를",

  "살아있단",
  "구급대원",
  "돌아라",
  "돌아오니까",

  "마이클을",
  "좋아하나봐",
  "발렌타인데이에",
  "싸울때",
  "식량과",
  "바뀌어야",
  "열려진",
  "당근을",
  "팔코니께서",
  "따름이야",
  "스타키",
  "시계도",
  "추출한",
  "애덤",
  "키퍼님의",
  "왕들이",
  "편지들이",
  "해안으로",
  "있을거고",
  "계산하지",
  "틈새",
  "시스템도",
  "순찰을",
  "키퍼는",
  "마찬가지구요",
  "애란동무는",
  "상철",

  "도왔지",
  "노예였던",
  "간지",

  "안정시키고",
  "이야기했다",

  "단장",
  "그래왔어",
  "톱",

  "싫어할지",

  "하겠냐",
  "곤란해지겠지",

  "개자식을",

  "병신들",
  "만주사변",
  "컸으면",
  "창조주",
  "수술들이",
  "잡자고",
  "패밀리",
  "잔인",
  "책만",
  "수묘水墓지",
  "사냥하러",

  "설치하고",
  "기사단의",
  "죽인다는",
  "필립의",
  "특별하다고",
  "내몰고",
  "피보나치",
  "소니아",
  "결정하죠",
  "그전에도",
  "후터스에",


  "수정을",
  "웅",

  "놈이고",

  "돌에",
  "파괴되고",
  "춤에",
  "친절했어요",

  "아기들이",

  "찰스는",
  "갖혀",
  "잭슨을",
  "꼬이는",
  "들었는디",
  "수작이냐",

  "카라는",
  "만종아",

  "화창한",
  "탈을",
  "오타와",
  "찾는거지",
  "타임로드의",
  "타마노조는",
  "도와라",


  "읽어봐라",
  "거두게",
  "독립선언문을",
  "고마워해야",
  "전사한",
  "유지에",
  "세상이라도",
  "주기적으로",
  "목표라고",
  "엘사는",
  "받으면서",
  "에드가",
  "안됐다고",
  "잡았죠",
  "만나세요",
  "살펴봤어요",
  "에비게일",
  "워싱턴의",
  "얘기하시는",
  "확신하죠",
  "정리하는데",
  "정의해봐",
  "선장에게",
  "빌려줘요",
  "증거들을",
  "귀환을",
  "구장",
  "잎이",

  "생각안해요",

  "손길에",
  "흉기를",

  "미끄러지는",
  "번영을",
  "야는",
  "나치를",
  "재스가",
  "단서야",
  "왔었잖아",
  "존재한",
  "중천에",
  "김씨",
  "주었고",
  "댜",
  "짝짓기",
  "서명이",
  "영리해",
  "바꾸는게",
  "이따봐",
  "티모시",
  "다른곳에",
  "가둬둘",
  "우편함에",
  "도어",
  "갈걸세",
  "구경한",
  "아담스",
  "대사관에서",
  "승객들",
  "펜은",
  "스토킹",
  "고향에선",
  "기뻐해야",
  "동료는",
  "공동체",
  "전화하기",
  "하는거라고",
  "당한거",
  "스승님",
  "보살펴주고",
  "무슨일로",
  "말썽을",
  "주시지",
  "작업용",
  "너없이",
  "데려다주면",
  "마쉬멜로우",
  "맡아달라고",
  "가는걸",
  "너땜에",

  "안했고",
  "오브라이언씨",
  "바람으로",
  "고함",
  "놨을",
  "아이하라가",
  "패트모어씨",
  "시마자키",
  "받을만",
  "질문해",
  "와야겠어",
  "가시게요",
  "이정도는",
  "공기는",
  "이병원에",
  "터치",
  "내꺼다",
  "총담낭관을",
  "담낭을",
  "원한게",
  "혼전",
  "정각",
  "너희둘다",
  "에임스",
  "보냅니다",
  "오년",
  "조종당하고",
  "브라우닝",
  "뒀는데",
  "판단해",
  "고맙죠",
  "페인트를",
  "해보는게",
  "척수액",
  "가는군요",
  "소림사는",
  "탈옥",
  "스케치",
  "꿈이죠",

  "정착",
  "뱉어내",
  "꿈속에서는",
  "끝내주는군",

  "폭풍은",
  "이민",
  "일당이",
  "도망갔다고",
  "돼지치기",
  "소포가",
  "너희처럼",
  "터지도록",
  "거나요",
  "성령",
  "미로를",
  "태우지",
  "그만하라니까",
  "구경할",
  "라이커는",
  "수준에서",
  "로건을",
  "걸리겠어",
  "가는거에요",

  "마리아와",
  "목숨만",
  "착하네",
  "타워가",
  "안정적으로",
  "킬러",
  "얘기한다고",
  "쉽스테드",
  "요시다랑",
  "삽",
  "합창",
  "생각해보게",
  "이토군은",
  "조그맣고",
  "도망간",
  "총성이",
  "놔두라고",
  "어디있었어",
  "전화로는",
  "촬영할",
  "미로",
  "그러쇼",
  "약물치료",
  "되셨어",
  "타일러가",
  "발라리아",
  "학생이죠",
  "일한다",
  "식료품점",
  "바래야지",
  "심령",
  "아저씨라고",
  "올래요",
  "안받는거야",
  "소개하지",
  "다운을",
  "아저씰",
  "항아리",
  "쉘",
  "나때문에",
  "뭔말인지",
  "초대해서",
  "없어졌다고",
  "시간좀",
  "부딪히는",
  "떠벌리고",
  "꿈깨",
  "높이의",
  "준비했지",
  "상징적인",
  "그러지말고",
  "실험하는",

  "부분으로",
  "짤릴",

  "걱정된다",
  "순한",
  "스캔한",
  "발렌타인의",
  "더운데",
  "안되는거야",
  "부인인데",
  "마스터가",
  "이런일을",
  "알아차릴",
  "증명하고",
  "일하다가",
  "사부의",
  "요시다가",
  "빠질래",
  "제이크를",
  "모델을",
  "나쁜데",
  "스케줄은",
  "말해보라고",
  "덥다",
  "파이프가",
  "감정인지",
  "생각났어요",
  "제이콥은",
  "샌프란시스코에서",
  "모르는거지",
  "까야지",
  "오신겁니까",
  "일이랍니다",
  "게이라서",
  "실망시키는",
  "어찌됐건",
  "그거냐",
  "날자",
  "술먹고",
  "죽는거에요",
  "쉬는데",
  "이루어집니다",
  "빼먹고",
  "연합군의",
  "슈퍼에",
  "바다도",
  "아름답습니다",
  "기회라구요",
  "평소의",
  "베프",
  "통로",
  "안구",
  "사이비",
  "찾아봤어요",
  "당신이죠",
  "되찾으려고",
  "걸쳐서",
  "많으니까요",
  "사실이요",
  "중국으로",
  "기어박스는",
  "같이가",
  "가능성이라도",
  "준비됐소",
  "한달이",
  "층에서",
  "수술들을",
  "기억으로는",
  "캐비닛을",

  "무서우세요",
  "기억들을",
  "맥을",
  "무엇이든간에",
  "아는사람은",
  "신경좀",
  "잘났다고",
  "지장이",
  "미치고",
  "기차야",
  "참여한",
  "앱",
  "소립니다",
  "표시된",

  "스탠포드에서",
  "모양이네",
  "않으셨나요",
  "다툼을",
  "패자는",
  "듣고는",
  "찾아봤어",
  "단계까지",
  "같더라구",
  "단계별로",
  "왔는",
  "시설",
  "추스릴",

  "떠나버렸어",
  "코티스가",
  "학교다닐",
  "상품을",
  "백화점을",
  "카운티의",
  "못할꺼에요",
  "인디언들은",
  "온거다",
  "관둘",
  "베스랑",
  "사부를",
  "단독수술에",
  "전쟁터로",
  "나오셔서",
  "듣냐",
  "바칩니다",
  "가만있어봐",
  "빠르다고",
  "한둘이",
  "어떠십니까",
  "혜택을",
  "평안히",
  "잡아당기는",
  "않았어야",
  "끊을께",
  "지갑은",
  "알아내야지",
  "요구하고",
  "영적",
  "해줬어야",
  "여자한테서",
  "모니터에",
  "화살도",
  "심실세동이",
  "서툴러",
  "성공이다",
  "떨어트리셨네요",
  "머릿속의",
  "통은",
  "로스키가",
  "이긴다면",
  "잘라버릴",
  "안한다니까",
  "넣어둘",
  "이야기에",
  "마셔야지",
  "떠났잖아",

  "안할게요",
  "악몽이야",
  "싫어하거든요",

  "달려있지",
  "없더라구",
  "고백해",
  "매달리는",
  "속임수로",

  "암탉",
  "데이지를",
  "벗겨줘",
  "찾아내기",
  "해줄테니",
  "총독을",
  "기뻐서",

  "영국식",

  "투디스",
  "연합군",
  "웨어",

  "성격은",
  "부스러기",
  "헨젤과",
  "걱정이죠",
  "노래하러",
  "마련이니까요",
  "카르페",
  "끝내려고",
  "의심하게",
  "폴리네시아",

  "늙는",
  "곤란하지",
  "합쳐진게",
  "밀어서",
  "떠올랐다",

  "아프긴",
  "이집트의",
  "그루버는",
  "들고다니는",
  "놈이든",
  "덕분에요",
  "웬일로",
  "덮쳤어",
  "알바레즈",
  "했었거든",
  "욕구는",
  "선생이야",

  "공책에",
  "떨려요",
  "물건만",
  "원하진",
  "적어놨어",
  "인형은",
  "받쳐줘요",
  "개년아",

  "주는데",
  "대적할",
  "리네타",

  "점령",


  "죽여주지",
  "브라이스에게",
  "아멜리가",
  "생각하려고",


  "부모와",
  "네일건을",
  "그런것도",
  "십분",
  "해드리겠습니다",
  "채우는",
  "떠나겠습니다",
  "체리맛",
  "자석",
  "잡아먹힐",
  "토하면",

  "합쳐진",
  "자신감과",
  "씨리얼",
  "도착했고",
  "선지",
  "수탉이",
  "나탈리",
  "천한",
  "죽여줄게",
  "잡도록",
  "총독이",
  "힘드니까",
  "월트",
  "트리스탐",

  "손님도",
  "숙제한",
  "미래죠",
  "잼",
  "열었는데",
  "윤양하",
  "빼줄게",
  "변했다",
  "벌주고",

  "임권택",
  "요리에",
  "손효신",

  "됐구만",
  "가졌다",
  "만든다는",
  "돌아보네",

  "조상",
  "머금은",
  "뒤돌아도",
  "정숙한",

  "아베",
  "느끼죠",
  "웰자인",
  "밟아서",
  "노력하고있어",
  "모형",
  "도와줘야겠어",
  "그것참",
  "바구니를",
  "끌어들여서",
  "사라졌는데",
  "엉망진창",
  "협박하고",
  "한건지",
  "행복했는데",
  "심박수",
  "부딪히고",
  "겁먹게",
  "난동",
  "포톨스키는",
  "전략은",
  "풀어주세요",
  "믿어서",
  "목록이",
  "피크닉",
  "아니겠나",
  "울버린",
  "이해못해",
  "농담예요",
  "빨랐어",
  "사이였어",
  "게으름뱅이",
  "데려갈까요",
  "이유요",
  "배트",
  "의념",
  "욕망의",
  "대시오",
  "꾸미는",
  "같더라니",
  "식사해요",
  "라지는",
  "안전장치를",
  "육즙은",
  "지발",
  "자랐죠",
  "죄수가",
  "잡아오면",
  "말문이",
  "진실입니다",
  "애꾸",
  "제로가",

  "소음을",
  "만져봤다고",
  "가깝죠",
  "중령",
  "죽였다면",
  "마사지를",
  "마르고",
  "능사는",
  "바이퍼는",
  "가축을",
  "터질거야",
  "벅벅",

  "찾았답니다",
  "라틴어",
  "다른사람한테",
  "일원입니다",
  "조는",

  "의견도",
  "파랑",

  "용건",
  "살려주시오",
  "가져가자",
  "임대",
  "무학의",
  "펭귄을",
  "노력했는데",
  "못할거다",
  "상대도",
  "지나치구나",
  "로스키는",



  "있지말고",
  "웃긴다니까",
  "소림사",
  "페디",
  "호흡음이",
  "들어보실래요",
  "맞춤",
  "여전하구나",
  "포기하세요",
  "꾸물대",
  "창녀는",
  "오해에요",
  "물체를",
  "나중에봐",
  "근친한",
  "급한데",
  "대책이",
  "거룩히",
  "브래들리가",
  "여김을",
  "저놈과",
  "넣습니다",
  "브라가가",
  "알고싶지도",
  "죽음도",
  "부럽구만",
  "연골을",

  "꾸려야",
  "공포를",
  "일어난거야",
  "사망이",
  "만큼도",

  "한놈을",
  "강연을",
  "클래쉬",
  "정면",
  "보일까",
  "열렬히",
  "총살",
  "협조해",

  "큰걸",
  "느껴지고",
  "점심식사가",
  "성공했어요",
  "우주비행사",
  "통해서요",
  "게이츠씨",
  "자존심은",
  "있었는지는",
  "틀어놓고",
  "그짓을",

  "확산",

  "너무한다",
  "먹을꺼야",
  "그녀석은",

  "사진이라도",
  "경쟁심을",
  "경卿",
  "것보다는",

  "해롤드",
  "주께서",
  "뇌진탕을",
  "일으키려는",
  "의도한게",
  "시작했거든요",
  "아흐메드",
  "지위를",

  "미시가",


  "저녁에는",
  "와이프랑",
  "차있어요",
  "특허가",
  "향상시켜",
  "모레를",
  "얻어낸",
  "휘저어놨어",

  "깜박했네",
  "제포즈",


  "즉석에서",
  "카드나",
  "보석들을",

  "부탁드릴께요",

  "훔쳐갔어",
  "코드는",
  "티타늄",

  "브루노",
  "장난도",
  "핑크빛",
  "걸렸나요",
  "굽히고",
  "베베르",
  "해드릴께요",
  "이론도",

  "독립적인",
  "결혼하는데",
  "인데요",
  "안했지만",
  "세탁",
  "앙뚜완은",
  "벽화야",
  "적극적으로",

  "과장해서",

  "생각나니",
  "절개해서",
  "눈썹도",
  "아들이랑",
  "잘났다",
  "실마리를",
  "편하지",
  "사람같이",
  "할껀",
  "어두워요",
  "시의회가",

  "안했거든",

  "멀잖아",


  "친구이고",
  "위한게",
  "공룡",
  "신분증으로",
  "널사랑해",
  "신용카드",
  "수학은",
  "그때로",
  "완벽하네",
  "해냈구나",
  "결혼생활이",
  "겪었어요",

  "했나봐",
  "통치할",
  "감시가",
  "고치는데",
  "야외에서",
  "기다릴수",
  "하고있어요",
  "말씀드릴께요",

  "생각한단다",
  "위기의",
  "추억의",
  "생각했었어요",
  "년전에",
  "양막",
  "있다는거죠",
  "받았다면",
  "드릴수",
  "낙서일",
  "장면에서",
  "비행해서",
  "총사령관이십니다",
  "웜홀을",
  "깨끗한데",
  "귀여웠어",
  "보여줬잖아",
  "헤이스팅스",
  "아네요",
  "클라우스",
  "않았었나요",
  "모레가",
  "가능합니까",
  "내역은",
  "제프는",
  "노랭이",
  "상태라고",
  "왕이나",
  "부부와",

  "취침",
  "겪어봤어요",
  "쪽이죠",

  "있으시네요",
  "바랐는데",
  "레너드와",
  "입력했더니",
  "흔히들",

  "수천년간",
  "보이는거",
  "결백을",
  "뼈의",
  "감아라",
  "당해야",
  "확보할",
  "없을지",
  "섹스했어요",
  "군대지",
  "사시나요",
  "켄드리",
  "걸린거",
  "틈은",
  "챙겨야",
  "프랑스어로",
  "덫",
  "펀치를",

  "날아다니며",
  "입술은",
  "때보다도",

  "아픔은",
  "정도예요",
  "연구실을",
  "중이었소",
  "술마시는",
  "되었소",


  "같았습니다",
  "엉망이네",
  "묻을",
  "뼈로",
  "악취를",
  "장문",
  "납치했어",

  "나타났다고",
  "일항아",
  "고양이와",
  "시작할거야",
  "왕자님께서",
  "바늘로",
  "카이는",
  "알라무트를",
  "일생일대의",
  "맞이할",
  "아를",
  "으스러지는",
  "궁전에",
  "밥맛없는",
  "파괴하지",
  "됐는지도",
  "머릿수",
  "벤에",
  "훔쳤는데",
  "유감",
  "보았다는",
  "비켜주십시오",
  "여자들에게",
  "줄서",


  "들어본적도",
  "열도록",
  "이어주는",

  "이야기하면",
  "주운",
  "성장하는",
  "아버지라면",
  "핸드백에",
  "체포하겠다",
  "간게",

  "그애랑",
  "상상일",
  "엿듣는",
  "자유다",
  "나중을",


  "전철을",
  "놔주면",
  "잘해줬어",
  "다이아몬드가",
  "사이버맨들은",
  "자아를",
  "겨울에는",
  "루신을",
  "살인자에",
  "숨기도록",
  "괜찮아져요",
  "삽으로",
  "당뇨병",
  "법이거든",
  "정문에서",
  "성문이",
  "사람과는",
  "역할에",
  "가드",

  "기소를",
  "세관",

  "아이로부터",
  "전통에",
  "귀걸이를",
  "하다는",
  "심란한",
  "첼시",

  "출신이라",
  "익숙해질",
  "악의가",
  "돌아보니",
  "곱하기",
  "주체할",
  "역경을",
  "쥐꼬리만한",
  "유인할테니",
  "대포를",
  "총알에",
  "오셔",
  "좋아하든",
  "듯하게",
  "생겼냐",
  "임충을",

  "대니한테",
  "니잠이",
  "시간이요",
  "왼쪽이야",
  "그늘에",
  "클램프로",

  "사라졌을",
  "보래",
  "캐나다에서",
  "밤이었어",
  "연합군이",
  "먹구름이",
  "발견될",
  "모양새가",
  "의사만",
  "춤춘다",
  "형제들은",
  "폐의",
  "황도련님이",
  "썰매",
  "서부로",
  "저죠",
  "나누어",
  "가야하는데",
  "불리지",
  "활화산의",
  "미라이",
  "달린다",
  "플로릭은",
  "초록빛",
  "살기위해",
  "사토",
  "발발",
  "지네",

  "따르기",

  "창자는",

  "싫네",
  "체육관에서",
  "적하",
  "추후",

  "습격했어요",

  "특이점은",
  "현관으로",
  "쥬우",
  "집인",
  "안된다니까",
  "그러고보니",
  "외칠",

  "택배",
  "죽으면서",
  "확실하군",
  "이현상",
  "자진해서",
  "꼬라지",
  "에헴",
  "어떻겠습니까",
  "왔더니",
  "중립",
  "최선",
  "최동무",
  "말드라고",
  "이백",
  "찰스가",
  "피하세요",
  "전투로",
  "해대겠지",
  "뚫렸군요",
  "악취가",
  "냈을",
  "느끼는거야",
  "애좀",
  "무서워한",
  "구하긴",
  "지켜야해요",
  "과학도",
  "진실이에요",
  "고백했지",
  "타임라인이",
  "됐지요",
  "밴드요",
  "조사하기",
  "민망하거든요",
  "어딘",
  "당신보고",
  "출신이",
  "철책",
  "파괴해야",
  "때에야",
  "민주당",
  "도망갔어",
  "경기도청",
  "이유에",

  "안남았어",
  "무기와",
  "주방에",
  "배불러",
  "역할이잖아",
  "악화되어",
  "본햄",
  "안정화",
  "머서씨",
  "대원들의",

  "나빠지기",
  "그럴지",
  "탔잖아",
  "철조망",
  "거라니까요",

  "없어지게",
  "상태일",
  "번쩍이는",
  "신장의",
  "어케",
  "다시금",
  "지켜봐야죠",
  "죽여버려요",
  "대배심",

  "물이다",
  "알겠습니까",
  "붐비는",

  "돛을",
  "사람일까",
  "질문만",
  "살아있는데",
  "얼굴들",
  "깨웠어요",
  "해일리",
  "괴력의",
  "원천",
  "노인을",
  "모양은",
  "동무의",
  "거짓말이라고",
  "제이니",
  "기계들은",
  "돌아가셔도",
  "기계들이",
  "구경하러",
  "기계의",
  "스타일리스트가",

  "푸딩을",
  "듣도록",
  "성공했을",

  "샘플은",
  "줘버리면",
  "일동",
  "누나랑",
  "아느냐고",
  "기자로",
  "허구헌날",
  "뭐라더라",
  "타라니까",
  "나을것",
  "좋아보이진",

  "완벽하진",
  "지휘관이",
  "과한",
  "아닌듯",
  "음바카",
  "앞가림은",
  "보이던데요",
  "가봤자",
  "홍환",
  "보냈거든",
  "강도들",
  "나는거야",
  "저기있는",
  "캐묻고",
  "희생해야",
  "비겁자",
  "떠나있는",

  "서약이",
  "마친",
  "빠져나가자",
  "응원해",
  "파괴해",
  "급한일이",
  "앨리",
  "일과를",
  "제기",
  "여정",
  "샤워나",
  "반복하지",
  "모르겠는걸",
  "언짢게",
  "죽지만",
  "발생하니까",

  "내거야",

  "뭔소리야",
  "유키",

  "적출된",
  "서울은",
  "피바다가",
  "일항이",


  "주목하세요",
  "훔쳐보는",
  "리온에",



  "실행합니다",
  "어린아이",
  "잡담을",
  "데이트라니까요",
  "재밌는건",
  "곡조",
  "될거니까",
  "화염방사기에",
  "원을",
  "이름하여",
  "약속이다",
  "퀘이사는",
  "말씀해보세요",
  "피해라",
  "칼이야",
  "동지를",

  "후퇴하라",
  "기다려야지",
  "아프잖아요",
  "정답이야",
  "여사에게",
  "조젠",
  "질식해",
  "놀랍구나",
  "버릇이야",
  "정의라고",

  "하는거잖아",
  "지우는",
  "팽창하고",
  "나타나길",
  "공비들이",
  "알라무트에",
  "낫",
  "아픔이",

  "어두워지면",
  "호도르",
  "스코필드",



  "마미짱이",

  "방법들이",
  "침입자가",

  "있서요",
  "찾겠어요",
  "렌리를",
  "상대한테서",
  "겪었는지",
  "보험금을",
  "가져가게",
  "전부인가요",
  "뱃지를",
  "환자들에",
  "에미넴이",
  "불러오는",
  "제압할",
  "지웠잖아",
  "첩자를",
  "혐오스러워",
  "조용할",
  "세르비아",
  "패니",
  "경고해",


  "본국으로",
  "거리야",
  "운전대",
  "흔들리는",
  "경고했어",
  "겨울날",
  "꿈이요",
  "남겼어요",

  "발포하라",

  "만났었어",
  "입증",
  "용서할게요",
  "줄어들어",
  "타디스라도",
  "초래한",
  "돼야만",
  "헬리콥터를",
  "납치를",
  "만든거지",
  "기도한",
  "전시",
  "곳이라도",
  "규모",
  "프레즈노",
  "랭글리",
  "공모한",
  "후작부인",
  "임무야",
  "낭만적이야",
  "콩클린",
  "사람한텐",
  "들리고",
  "시작입니다",
  "약속하마",
  "엽니다",
  "모니터로",
  "딱이죠",
  "느리고",
  "레이븐의",
  "우정에",
  "뜻이예요",
  "받아야합니다",
  "작업복에",
  "보이는걸",
  "면전에서",
  "침대도",
  "의자는",
  "재건할",
  "해봤는데요",
  "퍼거슨",
  "파괴했어",
  "줄여요",
  "콩클린이",
  "돌려달라고",
  "폭포에",
  "동업을",
  "없애야",
  "숨소리가",
  "수리하는",
  "금화",
  "빠져나가면",
  "시도했던",
  "방문한",
  "신상에",
  "갔습니까",
  "꾸물거리다",
  "도망쳤습니다",
  "발린",
  "토비를",
  "되셔서",
  "인터페이스야",
  "베를린으로",
  "재능으로",
  "심폐",
  "맡아라",
  "살해당했어요",
  "접선",
  "소행이라고",
  "브랜디",
  "처리했어요",
  "끔찍하군요",
  "성공이라고",
  "암살자가",
  "승인하지",
  "만들어졌습니다",
  "테이블에는",
  "나폴리",
  "대성당에",
  "주장",
  "물리는",
  "받아본",
  "악어가",
  "땅이야",
  "김정은의",
  "너블스",
  "쫄쫄이",
  "줄리의",
  "냠냠",
  "가시나요",
  "배고프다고",
  "웃길",
  "않은거",
  "내키지",
  "트레드스톤은",
  "해체",
  "뻔하군",
  "불꽃놀이를",
  "사자의",
  "과묵한",
  "알려졌습니다",
  "선택입니다",

  "바바둑",
  "멋진걸",
  "안될겁니다",
  "파티였어",
  "심장수술을",
  "스턴스",
  "그림자에",
  "혼자라는",

  "키퍼의",
  "버텼어",
  "깃발이",
  "아침에도",
  "찰리가",
  "갈길을",
  "속물이야",
  "터프한",

  "세다고",

  "해주시겠습니까",
  "좋은거지",

  "잘못되었다고",
  "부러",
  "다가갈",
  "올라타서",
  "파이프를",
  "갔는지도",
  "넘었지",
  "게이츠가",
  "알았잖아요",
  "미래만",
  "물리친",
  "멋지잖아",
  "온건데",
  "터뜨리는",
  "클론을",
  "프랭클린의",
  "넘겨라",
  "되겠는데요",
  "혼자도",
  "솔저",
  "촉이",
  "그녀라면",
  "연료는",
  "사업일",
  "원했다면",
  "소총",
  "정원사",
  "말씀하셨던",
  "과부하",
  "뭐하잔",
  "추출",
  "사흘째",

  "학생들과",
  "에밀리의",
  "하면되",
  "베티를",
  "공격이다",
  "같았나",
  "완전하고",
  "전사로",
  "확신하고",
  "우승했어",
  "메이스",
  "얼굴이야",
  "하스",
  "뭐하는거죠",
  "실례하죠",
  "살인마야",
  "불편하고",
  "철수하라",
  "이런말을",
  "정말로다가",
  "살았네",
  "공습",
  "독으로",
  "뇌심부",
  "평안을",
  "남겨둘",
  "한심하군",
  "돈때문에",
  "살아있을지도",
  "흡족해",
  "대화라도",
  "날려버렸지",
  "고난이",
  "파줄",
  "깜빡할",
  "남자였다",
  "넘긴",
  "성전",
  "과외",
  "있는진",
  "소년원에서",
  "예언자들의",
  "알아채고",
  "헷갈릴",
  "로군요",
  "이익이",
  "지하철을",
  "수련을",
  "원장님께선",
  "않은거죠",
  "지하세계의",
  "그러셔야죠",
  "내거나",
  "오스코프는",

  "지켜볼거야",
  "어원이",
  "끝내야겠어",
  "키퍼",
  "행동개시",
  "안경은",

  "선지자가",
  "갔단다",
  "온몸으로",
  "마법사가",
  "오프라",
  "잡히게",
  "횡격막에",
  "니마",
  "동기",
  "문제다",
  "헐크가",
  "쏘인",
  "천하무적의",
  "직행할지도",
  "같이가자",
  "사이퍼가",
  "안전하죠",
  "스퀘어",
  "딴사람",
  "뭐였나",
  "순자야",
  "보이길래",
  "자살하게",
  "개씩",
  "절친은",
  "질색이에요",
  "맥과이어의",
  "랭리",
  "야간에",
  "소리치지",
  "준표",
  "나사를",
  "붕",
  "사진같은",
  "헌신하겠나",
  "루퍼트요",
  "내주셔서",
  "찔렀어요",
  "끝났다고요",
  "봉지를",
  "범벅이",
  "연설문을",
  "얘긴가요",
  "배리와",
  "따라서요",
  "음식이나",
  "실제로도",
  "사랑했다는",
  "어렵군요",
  "방식이에요",
  "과학자로서",
  "발설하지",
  "짓에",
  "노린다",
  "동성애자를",
  "가져서",
  "들어서는",

  "배리가",
  "아이카와",
  "놈이었어요",
  "뿐이고요",
  "들어갈거야",
  "인원",
  "제국은",
  "김씨가",
  "나라구",

  "도주했다",
  "상태라는",
  "수첩",
  "과제",

  "시간대가",
  "세번이나",

  "관측",
  "류의",



  "토리노",
  "엉망진창이야",
  "모스크바에",
  "폐기",
  "레스토랑이",

  "햇병아리",
  "똑똑하면",
  "소원은",
  "알카트라스는",
  "월리",
  "만약의",
  "없애려",
  "튀어나와",
  "고려하면",
  "어디갔지",
  "이론적으로",
  "죽었다가",
  "되었든",
  "혼자였네",
  "낭비해버렸군",
  "웃긴데",
  "되있어",
  "불리고",
  "온거예요",
  "살려야지",
  "열어놓고",
  "됬는데",
  "머시웨스트가",
  "흉부외과의",
  "싫다구요",
  "핵심은",
  "한잔할",
  "마니가",
  "사기꾼이",
  "검둥이를",
  "진화는",
  "작성하고",
  "스탠이",
  "래키",
  "뗐어",
  "바꼈어",
  "정전이",
  "좋아한다구",
  "아름다움에",
  "쇠똥구리",
  "지름길로",

  "드리자",
  "마테",
  "시간나면",
  "났다는",
  "공산당",
  "낮추는",
  "남친도",
  "흉추",
  "맛있지",
  "닫은",
  "곳인데",
  "됬어요",
  "여지도",
  "낮춰라",
  "도시와",
  "아빌라",
  "장난치고",
  "봤겠죠",
  "신참이",
  "녀석이에요",

  "카센터",
  "녀석인데",
  "짱인데",
  "얘기하는거야",
  "극복하는",
  "청소부가",
  "뿐이군요",
  "됐어도",
  "일원",
  "발견됐고",
  "변했구나",
  "양로원",
  "성과가",
  "가득할",
  "노망난",
  "양로원에",
  "아들이자",
  "주민들이",
  "웹은",
  "뼛속까지",
  "궤양",
  "랜디는",
  "생명유지",
  "사망했어",
  "휠체어에",
  "출발이",
  "상태지",
  "실험실로",
  "일하려고",
  "일하라고",

  "디노를",
  "정치인",
  "부패를",
  "브렉커",
  "잉잉",
  "사이버맨은",
  "자폭하지",
  "령도자님께서는",
  "국장은",
  "크림을",
  "데려다주마",
  "스카이라크는",
  "문제였지",
  "방문은",
  "맞군요",
  "껴안는",
  "승인이",
  "깃털",

  "펠리샤",
  "될거고",
  "분석해",
  "실례하마",
  "끝나기도",
  "시각이",
  "거짓말하는게",
  "가볍고",
  "외계인이요",
  "안나는",
  "걸었을",
  "주고받는",
  "발기",
  "해부하고",
  "데려와요",
  "표적",
  "알려드릴",
  "그러냐고",
  "게이는",
  "코트가",
  "지니윈",
  "추적이",
  "하이브리드",
  "되살릴",
  "서명해요",
  "왜그러냐",
  "이혼했어요",
  "퍼시가",
  "숨통이",
  "관리가",
  "휴대폰을",
  "셉트",
  "기르던",
  "여부",
  "스트맄에",
  "준비되셨죠",
  "미끄러져",
  "통하는지",

  "일본이",
  "전함",
  "대사관",
  "함시롱",
  "만든거에요",
  "일광욕을",

  "괜찮구요",
  "떠나거라",
  "고양이요",
  "그웬을",
  "의사죠",
  "폭로",

  "가짜라고",

  "험비를",
  "물러나게",
  "사람이잖아요",
  "사과하겠네",
  "요네자와",
  "저쪽은",
  "채식주의자",
  "보는데요",
  "공격하기",
  "앤과",
  "맡겼는데",
  "하겠는가",
  "펭귄이",
  "없애면",
  "자폭이",
  "정서적",

  "없앤",
  "노려",
  "농담이시죠",
  "씨네",
  "애썼지",
  "본이야",

  "죽어선",
  "감당해",
  "만들던",
  "안될거에요",
  "여왕에게",
  "설치할",
  "과속",
  "로그인",
  "되는거요",
  "얘기였어요",
  "쉬라고",
  "휴대폰은",
  "끝나죠",
  "약속하셨잖아요",
  "책들을",
  "전활",
  "몰랐나",
  "잡아가",
  "어땠소",
  "할거죠",
  "가정해",
  "때문이오",
  "뜨개질",
  "데려가도",
  "파악할",
  "지혈을",
  "잘렸어요",
  "학자",
  "된겁니까",
  "프리악",
  "회진이",
  "의사란",
  "그레이에요",
  "진원지를",
  "괜",
  "보물이야",
  "전국을",
  "저러나",
  "있을리가",
  "슬로언이",
  "도미니크",
  "담요가",
  "앉아야",
  "계시겠죠",
  "거하게",
  "잊혀지고",
  "영안실로",
  "빠는",
  "유혹하는",
  "점이라도",
  "가닥",
  "반군지역을",
  "연인들이",
  "사이좋게",
  "무지막지한",
  "영도자님을",
  "생각하오",
  "되거라",
  "온단다",
  "정기적으로",
  "아니라며",
  "주째",
  "밴드에서",
  "정도까지는",
  "끼었어요",
  "작년에는",
  "새출발",
  "상의도",
  "형편없이",
  "기록해",
  "잊어버리세요",
  "결점이",
  "적출할",
  "겪었습니다",
  "여자만큼",
  "폭풍우에",
  "할려구",
  "계시니까요",
  "않겠대",
  "불가피한",
  "목적이야",
  "중요합니까",
  "공동의",
  "실전",

  "운명으로",

  "인간이에요",

  "바엔",
  "합디다",


  "나쁜년",
  "선로로",
  "알듯이",
  "안다니",
  "탓이라는",
  "다트",
  "문제없지",
  "사람이네요",
  "만진",
  "먹기만",
  "돌아왔네요",
  "짓이다",
  "물리치료를",
  "짓자",
  "오기가",
  "신용을",
  "말에는",
  "새댁이",
  "때문이예요",
  "정성을",
  "상했어",
  "지혜가",
  "난처하게",
  "답도",
  "보고해야",
  "주인이야",
  "경비원이",
  "작성할",
  "허락만",
  "지켜봅니다",
  "헤매는",
  "찍을까",
  "가로채",
  "만지지도",
  "즈음에",
  "딱지가",
  "사이였지",
  "불안감을",
  "놓고는",
  "희미야",
  "찾아오세요",
  "혹이",
  "다락방에",
  "일어난다면",
  "육신은",
  "엉망이고",
  "닫게",
  "서있는데",
  "그렇게만",
  "사오고",
  "소인이",
  "블루베리",
  "거절한다면",
  "아님을",
  "가져본",
  "피곤하네",
  "약속하겠소",
  "품",
  "것이랍니다",
  "쏴봐",
  "오락실에서",
  "데스크에",
  "유후",
  "발버둥을",
  "일리나",

  "사용했던",
  "컨트롤러",
  "불규칙한",
  "방법으로도",
  "높여",
  "걸어다닐",
  "구명보트",
  "각오로",
  "못하고요",
  "마찬가지요",
  "앞장서",
  "걸어가",
  "하다보면",
  "영어도",
  "안될거",
  "자들이다",
  "휩쓸고",
  "드문데",
  "둘러보는",
  "열어볼",
  "혈관도",
  "파상풍",
  "들켰어",
  "선택적",
  "멸망의",
  "효율적인",
  "용서도",
  "난장판이",
  "거짓말쟁이에",
  "짖어",
  "피곤해서요",
  "결심이",
  "처리하라고",
  "손짓을",
  "막아줄",
  "집행",
  "부서를",
  "규정입니다",
  "나락으로",
  "남자들에게",
  "관통해서",
  "된댔어요",
  "서두릅시다",
  "진을",
  "뭘해",
  "아일랜드계",
  "올렸다",
  "썼을까",
  "뺏을",
  "발휘해",
  "십여",
  "부수면",
  "들래",
  "탈출하고",
  "발작의",
  "전화했을",
  "몰아세우지",
  "경감님이",
  "아니가",
  "리브리아",
  "리모컨",
  "물러가지",
  "잘해보게",
  "함께해야",
  "유아",
  "유지되고",
  "은인이야",
  "데니에",
  "갈아입어야",
  "일치해요",
  "고마웠어",
  "결정이란",
  "빌려준",
  "보내도",
  "추구하기",
  "가실래요",
  "춘호는",
  "코에서",
  "유부남이래",
  "자기에게",
  "물이랑",
  "그날부터",
  "졸라게",
  "생각했으니까",
  "야비한",
  "쉬이",
  "들어가야죠",
  "깨달았을",
  "외롭지",
  "일원으로",
  "영도자님",
  "공사를",
  "들으시오",
  "허다",
  "끓이고",
  "우선시",
  "텐트를",
  "작거든",
  "알게될",
  "사모님도",
  "의사분들",
  "가슴속에",
  "밑바닥에",
  "살려두는",
  "메일로",
  "원본이",
  "보내드릴게요",
  "베토벤",
  "말기",
  "그랬지요",
  "성별",
  "클럽에도",
  "발자크",
  "신용카드를",
  "분리하는",
  "진흙이",
  "윤주",
  "겉으로",
  "발견됐어요",
  "지키려",
  "럭스를",
  "방대한",
  "유죄가",
  "어떻대",
  "얼간이한테",
  "수은",
  "당했는지",
  "불만이야",
  "그거라도",
  "절차에",
  "발생하기",
  "결론에",
  "제국이",
  "외과의사잖아",
  "승강기",
  "시원하다",
  "그랬을지도",
  "너라는",
  "사라졌다고",
  "박살을",
  "진행중인",
  "이혼해",
  "좋니",
  "됐어야",
  "마련하는",
  "건방지고",
  "시도도",
  "바라시는",
  "처녀적",
  "버린다는",
  "죽이든",
  "그러시던가",
  "존경심을",
  "살리든",
  "이벤트",
  "먹다",
  "동생하고",
  "이름입니다",
  "아저씨에게",
  "물러나시오",
  "장담하죠",
  "공무",
  "사람한테만",
  "파란색이",
  "원치도",
  "노력에",
  "증거에",
  "성기에",
  "의심하기",
  "다니나",
  "잘났어",
  "모였네",
  "수술인",
  "내다볼",
  "미역국",
  "정신도",
  "잘났으니까",
  "씌운",
  "맞는다",
  "입양",
  "남성은",
  "그년한테",
  "예쁘시네요",
  "차우가",
  "사기에",
  "들어가려고",
  "그럽니다",
  "흔들리지",
  "용납하지",
  "진짠가",
  "연애는",
  "괜찮을지",
  "잤대",
  "아침이네",
  "음악회",
  "길어야",
  "목격자도",
  "경쟁",
  "뒤쫓아",
  "시애틀을",
  "온대요",
  "주일에",
  "일이었다고",
  "주의하세요",
  "외곽에서",
  "병이지",
  "잘했구나",
  "따라다닐",
  "이용하세요",
  "폐소",
  "호수의",
  "나쁘다",
  "전화할래요",
  "기억될",
  "눈치챈",
  "풍기고",
  "격식을",
  "둘까요",
  "챙겨주고",
  "제군들의",
  "얘기하자고",
  "만주",
  "덤비면",
  "밖이야",
  "해순이가",
  "떠들지",
  "테라스",
  "맥클린",
  "분노와",
  "아델하고",
  "과민반응",

  "방해했나",
  "너였지",
  "건드려서",
  "데이트할래",
  "도나는",
  "해방시켜",
  "일이겠지",
  "원판을",
  "치우자",
  "양들을",
  "향하게",
  "와서요",
  "발걸음",
  "지난달",
  "집중하게",
  "나아가게",
  "여자분은",
  "사기치고",
  "들리는데요",
  "잘리는",
  "하시려면",
  "두사람이",
  "셰익스피어를",
  "달려서",
  "꺼졌어요",
  "흉부를",
  "뽀뽀도",
  "제모도",
  "구해주신",
  "않더라고요",
  "포르노를",
  "반격",
  "진심이란",
  "호르몬을",
  "넘볼",
  "너무하잖아",
  "발명품",
  "진정시키고",
  "이웃은",
  "대접",
  "놨습니다",
  "유방",
  "도리어",
  "아주머니께서",
  "비치",
  "수축은",
  "이렇다고",
  "흐린",
  "났었어요",
  "묵지",
  "벗어야",
  "후회하는",
  "만찬이",
  "알우",
  "페로디낭",
  "끼어든",
  "천연",
  "순대",
  "기후에",
  "지각이야",
  "연회",
  "그루지어스",
  "엄마잖아요",
  "말마따나",
  "그분과",
  "가슴이야",
  "패고",
  "버틸수",
  "집중하지",
  "생리식염수를",
  "좋지는",
  "연결하지",
  "천년을",
  "그렇대",
  "사장님도",
  "가곤",
  "한잠도",
  "갖겠지",
  "나오미는",
  "대장은",
  "뗏목을",
  "왔었거든",
  "명성은",
  "변함",
  "했다는데",
  "끊었죠",
  "개에게",
  "골육종",
  "제공됩니다",
  "돈벌이를",
  "쇼핑몰에서",
  "뜻인지는",
  "저러지",
  "헤치고",
  "부두",
  "어르신네",
  "신원에",
  "인턴이랑",
  "아저씨들이",
  "연락하게",
  "한에",
  "몇군데",
  "언급이",
  "간거지",
  "전해드려",
  "안계세요",
  "개만도",
  "동의하네",
  "말이라면",
  "맛좀",
  "얻었습니다",
  "사인만",
  "썼거든",
  "지체없이",
  "추측이",
  "하다간",
  "맞다니까",
  "어딜가도",
  "호감이",
  "사람들에게는",
  "비명의",
  "파출소",
  "들어보죠",
  "의미없는",
  "구급요원들이",
  "수평",
  "안방에",
  "야야야야",
  "쓰러지고",
  "싸우려",
  "어쩌겠나",
  "홈즈씨를",
  "패혈증",
  "느껴보세요",
  "됐다면",
  "협조하고",
  "합병증은",
  "보이잖아요",
  "자살할지도",
  "두셨네요",
  "얘네들은",
  "쥐약",
  "팀도",
  "두마",
  "들어봤니",
  "뜻깊은",
  "결정했소",
  "뵈려고",
  "시절엔",
  "때렸지",
  "에드윈과",
  "전까진요",
  "생산적인",
  "자살할",
  "만났었지",
  "기반을",
  "글자가",
  "살아가지",
  "먼가",
  "애인으로",

  "폐로",
  "터크",
  "보고서는",
  "깨워줘",
  "고상하고",
  "자리좀",
  "크리스파클",
  "비켜주시겠어요",
  "말하는구나",
  "테스트가",
  "앉아있는데",
  "잘하는지",
  "폭발하면",
  "로봇이잖아",
  "전염되는",
  "우리네",
  "테치가",
  "외할머니가",
  "나가라고요",
  "찼는데",
  "씨발년",
  "그러든가",
  "먹어봤어",
  "살려주십시오",
  "끊어서",
  "젊어지는",
  "반기지",
  "아파서요",
  "그나",
  "커리어가",
  "극복하기",
  "몬쵸야",
  "혈흔",
  "바꿨죠",
  "없어졌지",
  "파업이",
  "카운터에",
  "손대고",
  "기적이지",
  "맙니다",
  "왠줄",
  "켤레",
  "해달래",
  "회계",
  "떨리지",
  "게임입니다",
  "뜻의",
  "희생하고",
  "장본인이",
  "잘못됐는지",
  "약이야",
  "라이터를",
  "홈즈를",
  "했었나요",
  "말이었어",
  "희망하고",

  "학을",
  "치료와",
  "벌레처럼",
  "시장은",

  "분노는",
  "지금까지도",
  "안심해",
  "아니었나봐",

  "봉사하고",
  "죽으란",
  "거짓된",
  "무섭나",
  "추악하고",
  "나왔으니까",
  "게이머가",
  "최악이다",
  "충분할",
  "두렵군요",
  "슬로언한테",
  "만들잖아",
  "못하셨어요",
  "내리신",
  "해준다는",
  "클라리넷",
  "혈관과",
  "배우라고",
  "빈틈없이",
  "설득한",
  "분이네요",
  "자가용",
  "식염수를",
  "좋자고",
  "많어",
  "실행에",
  "세월의",
  "인턴으로",
  "라라",
  "일체",
  "죽어서요",
  "처를",
  "금지되어",
  "엄격하게",
  "존경하지",
  "대학으로",
  "나뭇가지에",
  "병동이",
  "싫어했잖아",
  "몰리는",
  "계시게",
  "둘거야",
  "녹음한",
  "프렌드야",
  "안심할",
  "구하기가",
  "억세게",
  "들어가서도",
  "정책에",
  "거물급",
  "레스토랑은",
  "적어줘",
  "거랬잖아",
  "화났구나",
  "낳았어",
  "들어간다면",
  "후요",
  "대답이야",
  "농가에",
  "지적하고",
  "입어서",
  "차고에",
  "실탄이",
  "이유일",
  "짤렸어",
  "닮았지",
  "교내",
  "전부였어요",
  "우두머리가",
  "이틀전",
  "읽었고",
  "신경쇠약",
  "편하고",
  "바꿔치기",
  "문제니까",
  "능력있는",
  "권총은",
  "묻잖아",
  "렝스에서",
  "새끼도",
  "좋았다는",
  "테이블의",
  "풀렸어요",
  "비웃는",
  "풀장",
  "예술에",
  "근무도",
  "빔은",
  "럭스는",
  "생각할수록",
  "광",
  "정사를",
  "것인",
  "왕자님이라고",
  "환영이",
  "캐시의",
  "커져서",
  "그만두겠다고",
  "쩍",
  "외람된",
  "어텐딩한테",
  "소리내서",
  "스웨터도",
  "연루되어",
  "오뎅이",
  "판막은",
  "쉬겠어",
  "부인이란",
  "계셨어",
  "공식적으로는",
  "좋아졌지",
  "마술사가",
  "약혼녀",
  "보비",
  "다녀올",
  "미수를",
  "소작해",
  "부셔드",
  "망신을",
  "진짜고",
  "내부는",
  "땡기라",
  "도달할",
  "메러디스한텐",
  "말했겠죠",
  "현실이라고",
  "그것이다",
  "하나거든요",
  "주셨지",
  "갖는다",
  "장으로",
  "생각나면",
  "역할도",
  "충성심",
  "생기긴",
  "포기한다면",
  "싸움엔",
  "빠져든",
  "파도처럼",
  "나가달라고",
  "주중에",
  "차버릴",
  "이을",
  "씨죠",
  "쏘거나",

  "끼워줄",
  "나두요",
  "렝스에",
  "시켰던",
  "모범이",
  "도운",
  "뽑게",
  "탓하고",
  "있었기에",
  "어딨었어",
  "만치",
  "사게",
  "다행이라고",
  "절제술이",

  "꽃처럼",
  "놈이란",

  "마부양반",
  "들으시면",
  "아니었군요",
  "더들스가",
  "이슈를",
  "복덕방",
  "마리의",
  "차량의",
  "끝장날",
  "드레싱을",
  "아파야",
  "인구",
  "필요하고요",
  "매질을",
  "살구",
  "꽃피는",
  "간단하잖아",
  "쌓이는",
  "경과했습니다",
  "인턴들이에요",
  "바람처럼",
  "핏줄을",
  "퉁퉁",
  "끊어져",
  "걸리기",
  "소견으로는",
  "조지요",
  "위협에",
  "소송에",
  "한쪽을",
  "없거나",
  "냈잖아",
  "똑똑하지도",
  "병동으로",
  "선천적으로",
  "놀랍구만",
  "돌아가야겠어",
  "시합에",
  "어려워서",
  "네년",
  "배터리에",
  "빠를수록",
  "위야",
  "손바닥",
  "동안이나요",
  "비싸다",
  "두수씨하고",
  "현자의",
  "유행을",
  "작동하고",
  "운전도",
  "알아주는",
  "아이로",
  "자발적인",
  "놓아야",
  "마요네즈",
  "척척",
  "강연",
  "기억했으면",
  "잘왔어",
  "들어야겠어요",
  "향기가",
  "명랑한",
  "출근을",
  "치는데",
  "글렀어",
  "어른답게",
  "환자와의",
  "해로운",
  "흐릿하게",
  "어리석었어",
  "건장한",
  "데이트야",
  "우울하고",
  "중단하고",
  "치료할게요",
  "가까워졌어",
  "아이그",
  "실망했어",
  "왕따",
  "귀신에",
  "나든",
  "부서도",
  "부러졌어요",
  "맞춰볼까",
  "배려할",
  "높아지고",
  "떨어졌는지",
  "기념일에",
  "보겠지만",
  "강력반입니다",
  "왔다고요",
  "답하기",
  "데려오고",
  "배우자는",
  "친구까지",
  "술래잡기",
  "스태프",
  "목에서",
  "순위를",
  "스테레오",
  "버티지",
  "젊지",
  "초인종",
  "깔끔하고",
  "지겹다",
  "항체를",
  "있다해도",
  "검사해야",
  "담임",
  "났었죠",
  "가르쳐줄게",
  "초콜릿은",
  "말부터",
  "보고싶어서",
  "크리스마스잖아요",
  "발짝도",
  "방학",
  "봉",
  "줬다면",
  "줄어들",
  "없겠는걸",
  "학생의",
  "죽었다구",
  "막대를",
  "들어오려고",
  "온다니까",
  "이성은",
  "후추",
  "스토리가",
  "고난",
  "미모의",
  "시예요",
  "사랑한다고요",
  "세어",
  "들었어야",
  "사람이거든요",
  "심각하다고요",
  "실망시켜",
  "먹히고",
  "무사했구나",
  "받아보세요",
  "폭탄이랑",
  "왜라뇨",
  "멀찌감치",
  "코치를",
  "절제술은",
  "부자와",
  "있다잖아",
  "깨어있고",
  "마비된",
  "도망간다",
  "떨어졌다면",
  "동맥류를",
  "파리도",
  "쫓아갈",
  "만큼의",
  "행위가",
  "쫓아내야",
  "소망",
  "공만",
  "동맥류는",
  "갚는다",
  "얘기해봤어",
  "숨만",
  "자백",
  "그만이라고",
  "위험요소가",
  "놀아야",
  "올해도",
  "아프거나",
  "반쪽만",
  "놀라실",
  "보기보단",
  "꾼거야",

  "상투적인",
  "가져와야지",
  "윌리엄스",
  "보장합니다",
  "결혼이란",
  "연속극",
  "음향",
  "아기들도",
  "심장이야",
  "방콕",
  "문제인데",
  "차례잖아",
  "근원을",
  "원했잖아",
  "아침도",
  "해보니까",
  "테잎",
  "형사들이",
  "간호사들은",
  "마실게요",
  "게임만",
  "줄창",
  "시의",
  "짜증난다",
  "믿겠다",
  "빠져나가게",
  "웃겨서",
  "깍쟁이",
  "봤다니까",
  "스페이스",
  "프로포즈를",
  "시작이에요",
  "되곤",
  "받아들이면",
  "않다니까",
  "음식이야",
  "서랍에",
  "쳐다보면서",

  "욕실로",
  "터틀이",
  "첨부터",
  "해결하려",
  "안전히",
  "그랬네",
  "조폭",
  "지급하라",
  "앉았네",
  "정도는요",
  "먹었으니",
  "해줬죠",
  "수영장에서",
  "싸우긴",
  "명중",
  "사귀지",
  "알겠고",
  "세세한",
  "지하에서",
  "싶기도",
  "어쩌겠니",
  "헤어졌잖아",
  "입힐",
  "옻이",
  "붙었는데",
  "훌륭하지만",
  "해보라구",

  "군인들",
  "누구한테나",
  "없었는데요",
  "대세요",
  "침대야",
  "말하더군",
  "해당",
  "빨라지고",
  "충격은",
  "처리하세요",
  "장난은",
  "조공인들을",
  "길바닥에",
  "전철",
  "말이든",
  "달라붙어",
  "북부에",
  "안에다",
  "사용될",
  "마시려고",
  "이해해주세요",
  "살아오면서",
  "했더군요",
  "손상시킬",
  "전화했어야",
  "갔었나",
  "배우자를",
  "레이를",
  "검진을",
  "주디가",
  "해줬고",
  "날려버렸어요",
  "책임이잖아",
  "떠나야겠어",
  "방송은",
  "감염으로부터",
  "불러야겠어",
  "행패야",
  "오늘에서야",
  "따님도",
  "꺼지지",

  "신문지",
  "여학생을",
  "커텐을",
  "달라지겠죠",
  "애처럼",
  "멈추길",
  "네놈에게",
  "내과",
  "무는",
  "풍경이",
  "급료를",
  "장갑에",
  "절개하고",
  "함께라고",
  "이거야말로",
  "천사들은",
  "퇴근해서",
  "낸시가",
  "해결됐어",
  "보너스로",
  "창가로",
  "전화요",
  "사람이었다",
  "한거요",
  "말했으니",
  "얘기하자는",
  "어색하게",
  "출근하고",
  "확인하게",
  "싫군요",
  "헌법",
  "놀랍죠",
  "평가가",
  "복직",
  "첫날이야",
  "꿈이에요",
  "백인이",
  "인턴하고",
  "있어야겠지",
  "심박동을",
  "살려둘",
  "껴서",
  "마티가",
  "기분일",
  "머서",
  "쪼금",
  "예언은",
  "우리한테는",
  "밑천을",
  "오랜만입니다",
  "끄세요",
  "말고는요",
  "버린다고",
  "열배",
  "버는데",
  "쉽잖아",
  "버리지마",
  "꼼꼼한",
  "소리하지",
  "많던데",
  "없지만은",
  "밥이야",
  "해결되는",
  "밑도",
  "드레스야",
  "피한",
  "웃기는군",
  "다쳤지",
  "약혼녀가",
  "말하잖아",
  "아니겠네요",
  "지뢰",
  "성공시킬",
  "마셨으면",
  "나가자구",
  "혈류",

  "기간에",
  "고쳐졌어요",
  "죽이길",
  "선생님요",
  "넘어지면서",

  "문제였어",
  "똥냄새가",
  "퍼뜩퍼뜩",
  "퍼진",
  "아프죠",
  "잘해주지",
  "저로",
  "뜸들이지",
  "힘들었는지",
  "쌍둥이를",
  "상대적으로",
  "락커",
  "다가올",
  "부인인",
  "어림없어",
  "조지고",
  "슬펐어",
  "일합니다",
  "의자로",

  "걱정하시는",
  "낡아빠진",
  "돌아올거요",
  "일하는게",
  "친군데요",
  "장비에",
  "교실",
  "이별을",
  "지어준",
  "느껴보고",
  "돌아가네",
  "위치한다",
  "최하위에",
  "꺼란",
  "합병증으로",
  "영화보러",
  "변환",
  "찬양하는",
  "가져갔다고",
  "인턴이자",
  "혈안이",
  "울거나",
  "복귀한",
  "맴도는",
  "구식이지만",
  "해내려면",
  "전령이",
  "사람이었다면",
  "그만이죠",
  "일어났지",
  "그러던가요",
  "여자겠군요",
  "하셨던",
  "딸이요",
  "중요한가요",
  "테쳐를",
  "사과해야",
  "누군들",
  "찢는",
  "걸리셨어요",
  "대단했죠",
  "남아있어야",
  "미안하단",


  "간헐적인",
  "싱글이야",
  "지나가야",
  "아데노신",
  "현명하신",
  "좋았소",
  "아내잖아",
  "심",
  "엉망이군",
  "생각에서",
  "살더라도",
  "격리되어",
  "타입은",
  "지켜본",
  "이번의",
  "악몽에",
  "결과로는",
  "그리워할",
  "줄리안",
  "이랬죠",
  "싫었거든",
  "찬찬히",
  "아내는요",
  "재료를",
  "가보겠네",
  "잤죠",
  "사람이군",
  "공포가",
  "전해져",
  "소식이죠",
  "되었을까",
  "아무짝에도",
  "수술에는",
  "유별난",
  "햄과",
  "잘까",
  "상사는",
  "쫓아내려고",
  "의사였죠",
  "던지기",
  "간에요",
  "죽는대",
  "살인면허",
  "기대되는군",
  "주의할",
  "맹장으로",
  "늦었으니까",
  "불어서",
  "전령",
  "아델한테",
  "친구분",
  "전화하셨어요",
  "내일이나",
  "외과의로서의",
  "박사학위",
  "쉰다고",
  "굶어죽는",
  "완두콩",
  "최후통첩을",
  "잊어버리겠다",
  "왼편에",

  "노엘은",
  "만찬을",
  "손대지마",
  "아껴요",
  "불쾌해",
  "아내요",
  "제품이다",
  "퇴근해",
  "문젠가",
  "처형",
  "사회를",
  "여자일지도",
  "머리맡에",
  "시집온",
  "심적",
  "갉아먹는",
  "친군",
  "페이지에",
  "처벌",
  "시각으로",
  "피임약",
  "바쁜가",
  "대화해",
  "나치야",
  "모르셔",
  "받진",
  "임종",
  "월시",
  "벌레같은",
  "시편",
  "떨어뜨릴",
  "잘해주고",
  "없어집니다",
  "가신다고",
  "명백하지",
  "힘들어질",
  "찾아내지",
  "철학",
  "해주니",
  "선생의",
  "그레이시는",
  "병원이에요",
  "여왕이야",
  "투약",
  "닉슨",
  "폐렴",
  "이자식아",
  "딸만",
  "인용",
  "대출은",
  "추정되는",
  "후두",
  "기록부",
  "엄살부리지",
  "시작하라고",
  "찾았구나",
  "인사하게",
  "한계점까지",
  "사업의",
  "빌라",
  "빠졌고",
  "살도",
  "알겠구나",
  "편이길",
  "얼마든",
  "일으켜요",
  "진료실에서",
  "거기에도",
  "싱글턴",
  "다가가면",
  "신혼여행은",
  "바바라는",
  "아저씨야",
  "서세요",
  "의도적으로",
  "미혼",
  "쓰러졌다",
  "이런거죠",
  "찾았단",
  "해변가에서",
  "집들이",
  "정리하지",
  "톤",
  "복잡해서",
  "금전",
  "발자크와",
  "먹었는지",
  "쳐다봤어",

  "심심하면",

  "벗어봐요",
  "은밀히",
  "열매를",
  "이야기다",
  "엄연한",
  "지긋지긋하다",
  "산소포화도는",
  "뛰어나고",
  "신원확인을",
  "국부",
  "오해입니다",
  "쪽입니다",
  "별거아냐",
  "자네같이",
  "말씀대로",
  "숨진",
  "없겠네요",
  "막아도",
  "훈련된",
  "주시겠소",
  "예후는",
  "똑같구나",
  "모르겠거든요",
  "틈만",
  "광자",
  "우회로",
  "포토샵",
  "아티반",
  "성전환",
  "따님에게",
  "틀리단",
  "협의회",
  "의거",
  "선생들",
  "부동산은",
  "태운",
  "손상되지",
  "테크닉이",
  "상사이기도",
  "괜찮길",
  "아이는요",
  "의사세요",
  "했었잖아요",
  "택할",
  "켜지",
  "구두로",
  "죽음으로부터",
  "목격자들이",
  "마차가",
  "글로리아가",
  "부족하네",
  "통과하는",
  "들어왔네",
  "되었답니다",
  "알아듣겠어요",
  "문합술이",
  "충성심을",
  "계획이요",
  "힘센",
  "도파민을",
  "인터뷰는",
  "다녔으니",
  "늘리고",
  "적인",
  "재미있잖아",
  "반군은",
  "사마귀",
  "뒤쫓을",
  "나가있어",
  "무사해",
  "한치의",
  "부두에",
  "하나겠죠",
  "사건이라고",
  "교수를",
  "클레릭은",
  "인형도",
  "파업을",
  "경호원이",
  "동물도",
  "친구군",
  "사랑받기",
  "보내시오",
  "뭉치",
  "전하기",
  "골라야",
  "복구해",
  "필요하겠지만",
  "등급이",
  "일년은",
  "울릉도",
  "표정도",
  "미세한",
  "들어가긴",
  "썼니",
  "다뤄요",
  "여기도요",
  "떠났다는게",
  "짐작을",
  "맞은편에",
  "정상은",
  "이쁘고",
  "절단해야",
  "심막을",
  "극장에서",
  "룸메이트를",
  "기질이",
  "조공인들은",
  "올라온나",
  "모리아티가",
  "생물학적으로",
  "아래까지",
  "따라오진",
  "방보다",
  "백기를",
  "챌",
  "죽겠다는",
  "강력히",
  "품위를",
  "떨어뜨리는",
  "해보시오",

  "처형될",
  "도와줘야지",
  "흉골",
  "왕자님과",
  "잘있었어",
  "걱정했는데",
  "장난하자는",
  "우리같이",
  "님과",
  "잡고있어",
  "살해됐어요",
  "그렇게요",
  "품은",
  "셋이야",
  "일까",
  "알려주시겠어요",
  "원대로",
  "도와주소서",
  "인턴들도",
  "애들인데",
  "폭발물은",
  "불안정하고",
  "없다잖아",
  "딜런이랑",
  "불평하는",
  "칠하고",
  "밝으면",
  "미스트",
  "기다릴수록",
  "까딱",
  "켓니스",
  "지급",
  "심장부로",
  "라이언엘제이",
  "정보보상부에",
  "층은",
  "빅햄",
  "실천하는",
  "주스를",
  "잰",
  "상대하지",
  "후식좀",
  "지켜보잖아",
  "놀리는거야",
  "대재앙",
  "점프",
  "오다가",
  "귀담아",
  "오시는데",
  "벗었고",
  "것이란다",
  "벗을래",
  "벙커에",
  "처하면",
  "하나님도",
  "무지한",
  "과학적",
  "보였거든",
  "커튼이",
  "따분하고",
  "도착을",
  "확실하진",
  "늦었을",
  "갈아입어요",
  "항문을",
  "우리한테도",
  "갈아요",
  "파티로",
  "빠졌잖아",
  "환자용",
  "기관이",
  "편인",
  "해이미치",
  "말해주죠",
  "요리하는",
  "들어와서요",
  "간호사들을",
  "바니가",
  "기다렸고",
  "가차없이",
  "혼난다",
  "인내에",
  "주었소",
  "엿볼",
  "찾아오고",
  "조언은",
  "조각에",
  "부르실",
  "대기자가",
  "다름없어요",
  "고용량의",
  "전해주고",
  "섣부른",
  "에단",
  "이해하시겠지만",
  "화는",
  "데려가죠",
  "코엔",
  "실수하면",
  "낮춰요",
  "부르잖아",
  "이층",
  "분인",
  "똥개",
  "맞으세요",

  "친다",
  "의사놀이",
  "열리니",
  "불도저로",
  "들었구나",
  "발진이",
  "영리하군",
  "우연히도",
  "붙잡아둘",
  "다치셨어요",
  "뛰어들었어",
  "견뎌내고",
  "뛰지마",
  "장가갈",
  "가정하고",
  "적어주세요",
  "놓치게",
  "송어",
  "타박상을",
  "신경써야",
  "그랬다니까",
  "혈종을",
  "다음의",
  "알았겠지",
  "아들이지",
  "착하다고",
  "스케줄에",
  "올랐을",
  "새해도",
  "때문만은",
  "있나니",
  "쳤지",
  "물론이지요",
  "박았어",
  "등산",
  "기겁을",
  "계속해야",
  "뜻이잖아요",
  "듀켓을",
  "이끈",
  "일주일간",
  "지내야",
  "불과하지",
  "그릇이",
  "잉크가",
  "빠져야",
  "손해보는",
  "자유라고",
  "비첸이",
  "노크하는",
  "웃기다",
  "행복합니다",
  "외투",
  "졸업했죠",
  "참았어",
  "염색을",
  "났대",
  "반이에요",
  "오래가지",
  "이마",

  "일가를",
  "즉석",
  "마셨는데",
  "관계죠",
  "쓰러졌어",
  "스반티에",
  "쐈다는",
  "요걸",
  "해적들",
  "맞춰볼게",
  "샤이엔",
  "농담이다",
  "하는걸요",
  "남편이나",
  "응괴는",
  "붙어라",
  "날아간다",
  "소식이요",
  "집어넣고",
  "바주카포로",
  "친구이긴",
  "짝으로",
  "겁줄",
  "놓기만",

  "권투",

  "매춘부",
  "좋아하더라고요",
  "당신때문에",
  "단지로",
  "만나기만",
  "안받아",
  "나팔",
  "재빠른",
  "들어옵니다",
  "구속",
  "찌그러져",
  "침팬지들은",
  "시부터",
  "만들기로",
  "남자니까요",
  "뒷마당에서",
  "천재에요",
  "교통이",
  "비워라",
  "값어치",
  "증거라도",
  "폐암",
  "툭하면",
  "찾았소",
  "어미",
  "노력했죠",
  "보려는",

  "창가에서",
  "아닐거에요",
  "아니잖소",
  "사람이니",
  "양말만",
  "많겠지",
  "털어놓을",
  "체중을",
  "침착하고",
  "놀기로",
  "신부도",
  "애새끼들",
  "돌아오마",
  "해리한테",
  "구했다고요",
  "만남을",
  "들어있다",
  "예뻤는데",
  "적지만",
  "석탄",
  "잘하는구나",
  "볼트가",
  "불렀는지",

  "미하엘",
  "울릴",
  "극장구경",
  "못하니까요",
  "들어주면",
  "촛불",
  "임산부",
  "썼나",
  "성인은",
  "고양이란",
  "물론이예요",
  "매지",
  "생기가",
  "자라니까",
  "자전거에",
  "전반적인",
  "세며",
  "곤히",
  "갔군",
  "힘내세요",
  "장기들이",
  "죽여봐",
  "선생님이라면",
  "크레이머",
  "자주는",
  "늘었네",
  "괜찮으시면",
  "놔야지",
  "궁금했는데",
  "끊었는데",
  "반대편으로",
  "헤르니아가",
  "횡경막",
  "갖다주세요",
  "누나도",
  "퇴원시켜",
  "예정되어",
  "차례나",
  "답답해요",
  "동기들",
  "고용주가",
  "예수님도",
  "진심이었어",
  "못하거든",
  "시벅",
  "면목",
  "교훈",
  "들것을",
  "닮았네요",
  "큰소리로",
  "외과의들에게",
  "괴롭게",
  "파악이",
  "멀리멀리",
  "쿠니군다",
  "난거죠",
  "보내야죠",
  "휴지통에서",
  "세트를",
  "배다른",
  "대니의",
  "주유소에서",
  "시험도",
  "들어갔나",
  "당신인가요",
  "유부남이야",
  "여자친구야",
  "마이클이",
  "소용없다",
  "손상됐어",
  "낭비하기",
  "모여봐",
  "공동묘지에",
  "앞에는",
  "아셔야죠",
  "멈췄고",
  "모이고",
  "어쩌자는",
  "전하세요",
  "범인과",
  "심리적",
  "바쁘잖아",
  "실례했어요",
  "하녀",
  "의사이기",
  "완벽했어",
  "못할테니까",
  "떠난대",
  "산모와",
  "로맨스를",
  "여념이",
  "안해서",
  "와서야",
  "말고가",
  "마누란",
  "흐려",
  "풍선을",
  "충분하죠",
  "단순하고",
  "일어난다고",
  "료",
  "아드레날린이",
  "실례하겠어요",
  "어쨌길래",
  "가자마자",
  "싶으시죠",
  "일이었죠",
  "로레인은",
  "식사하세요",
  "목욕하고",
  "저놈이야",
  "그릇을",
  "안좋아서",
  "사랑하나요",
  "흥분하면",
  "인생이고",
  "생각하신다면",
  "결정했죠",
  "돈이에요",
  "라커를",
  "피웠어요",
  "심령술사가",
  "표정이네요",
  "말씀인가요",
  "오르가즘을",
  "멀죠",
  "녀석이죠",
  "테드가",
  "야구를",
  "인격을",
  "미래엔",
  "놓을까요",
  "아는거죠",
  "버리는게",
  "트윙클톤양",
  "튀긴",
  "끼어들기",
  "팀이지",
  "바랬죠",
  "악화",
  "알수는",
  "하실수",
  "남았고",
  "뗏목",
  "복역을",
  "유모",
  "미성년자",
  "맥고비",
  "때리게",
  "로얄",
  "하다니요",
  "재밌다고",
  "동태",
  "하겠는데요",
  "때와는",
  "상세히",
  "합의한",
  "근거한",
  "놓쳤다고",
  "공부하면",
  "프랑카",
  "악명",
  "수술복을",
  "가엾어라",
  "끝내야죠",
  "만족하는",
  "작다는",
  "주의하겠습니다",
  "집에다",

  "시민을",
  "킴은",
  "달랬어",
  "행사가",
  "계셨네요",
  "명호군",
  "교체",
  "수위가",
  "찾나",
  "애디슨에게",
  "더해서",

  "프로야",
  "평화로워",
  "얘기하던",
  "벽돌로",
  "멀쩡할",
  "논리를",
  "건너에",
  "가족으로",
  "시키실",
  "대단할",
  "했네만",
  "약속이나",
  "손떼",
  "만하지",

  "숙제는요",
  "염려하는",
  "사시는",
  "괜찮다니까요",
  "뒤집어쓰게",
  "루시를",
  "먹였어요",
  "구토",
  "미리엄",
  "잠기는",
  "동정심은",
  "틀니를",
  "제껴",
  "원하기",
  "놀이터",
  "실례하지",
  "케인",
  "되나봐",
  "빠진다는",
  "킹슬리",
  "나았을",
  "속에도",
  "양배추",
  "못할거예요",
  "기도하러",
  "누구겠어",
  "심했다",
  "여보게들",
  "시어머니가",
  "도둑들",
  "하나라는",
  "유령과",
  "못하는구나",
  "송도",
  "약속에",
  "탈수",
  "짜증나죠",
  "아버지께서는",
  "바랬다",
  "감동했어",
  "말씀하셔야",
  "조끼",
  "제거합니다",
  "혼란스러울",
  "수녀를",
  "공부나",
  "부인이라고",
  "우유도",
  "거짓말이죠",
  "강합니다",
  "프레스턴의",
  "레이가",
  "저고리",
  "인도해",
  "기간이",
  "붙어야",
  "체온도",
  "근사하다",
  "말했던가",

  "물어봤잖아요",
  "일해라",
  "폴리네시아로",
  "저분을",
  "지정",
  "조깅을",
  "상이집트에서",
  "져요",
  "별말",
  "사방으로",
  "싶으니",
  "제어하는",
  "불안해요",
  "짜네",
  "댄스파티",
  "점심식사",
  "쓰니까",
  "부탁이다",
  "늘어",
  "쳐다보잖아요",
  "엽기",
  "마샬이",
  "끼어들게",


  "죽는다고요",
  "멜라니가",
  "잃어버려서",
  "고유의",
  "오말리와",
  "혹을",
  "관계자",
  "투명인간",
  "길이오",
  "인심",
  "잠깐씩",
  "태웠어요",
  "애인이에요",
  "물어봤을",
  "약할",
  "파괴의",
  "얘깃거리가",
  "부르기로",
  "눈에도",
  "안다니까요",
  "불려",
  "어른들",
  "경마와",
  "콘서트를",
  "얻었는데",
  "태아가",
  "이유에서",
  "그쪽을",
  "눈길",
  "안고파요",
  "노고에",
  "자연스럽고",
  "절제해야",
  "드리겠소",
  "체온은",
  "괴로워요",
  "먹는거지",
  "깨보니",
  "살았는지도",
  "널리",
  "검사나",
  "재배치",
  "야구에",
  "에릭은",
  "받았으니",
  "애디슨을",
  "여행용",
  "미끄러져서",
  "결점을",
  "철자",
  "고선애",
  "만들어줬어",
  "과제는",
  "면허를",
  "밥만",
  "핸드폰에",
  "망친다고",
  "지어야",
  "고추",
  "입었을",
  "그대로를",
  "김기범",
  "소리라도",
  "얼간이에요",
  "계산하고",
  "전부지",
  "여우",
  "닦은",
  "사진들은",

  "색으로",
  "기본이",
  "맞췄어요",
  "허무를",
  "파이브로마이알지아",
  "결정도",
  "증상도",
  "브래드의",
  "수건은",
  "베니가",
  "밀어줘",
  "똑같다",
  "늦으셨군요",
  "부자는",
  "쓰이고",
  "자들도",
  "주제가",
  "반갑군",
  "체질이",
  "빠져나가진",
  "따뜻해요",
  "잠들고",
  "상대방은",
  "컴퓨터도",
  "뭔대",
  "고인돌",
  "콘덴서에",
  "설명하긴",
  "미를",
  "잃었나",
  "신상옥",
  "어저께",
  "엎드려서",
  "주치의들이",
  "거기서도",
  "사흘만",
  "등불을",
  "있다더군요",
  "커져",
  "암인",
  "끈질긴",
  "병이나",
  "무섭네요",
  "마지막이에요",
  "먹여요",
  "딸애",
  "나갈래요",
  "말썽이야",
  "기차로",
  "빼줘요",
  "잘못하는",
  "그칠",
  "생각됩니다",
  "일부로",
  "기대할게",
  "남한테",
  "받았으니까",
  "말렸어",
  "여기저기서",
  "동생이나",
  "미워해요",
  "센터의",
  "모르는걸",
  "아신다면",
  "그네",
  "쉬셔야",
  "종교적",
  "승낙을",
  "무릎꿇지",
  "임신하고",
  "이송할",
  "수녀에",
  "생각하니까요",
  "고통받지",
  "싶은거죠",
  "기둥을",
  "바랬던",
  "되도",
  "극장이",
  "뿐인가",
  "나비의",
  "말리려고",
  "환자란",
  "오른손으로",
  "떠서",
  "않았던가",
  "몇주간",
  "인내를",
  "뒤에는요",
  "아지메",
  "인생으로",
  "도달했어",
  "바라니",
  "복권을",
  "닭고기",
  "소통이",
  "들리게",
  "엄청나네요",
  "때인",
  "연다",
  "정신감정",
  "애까지",
  "심한데",
  "심해지면",
  "옳죠",
  "들어가는군",
  "기도하는",
  "싶긴",
  "갈피를",
  "저자가",
  "내기로",
  "나가신다",
  "닦아요",
  "연설은",
  "회원",
  "모습과",
  "그럴걸요",
  "좋아하겠군",
  "뛰어내리는",
  "되더니",
  "없대도",
  "방앗간에",
  "밑천",
  "오렌지주스",
  "수사는",
  "최선인가",
  "예외",
  "방엔",
  "늘어날",
  "새빨간",
  "해주라",
  "대동맥류",
  "남편분",
  "페퍼민트",
  "여름은",
  "근처까지",
  "춤출래",
  "맥드리미",
  "계시기",
  "남자인데",
  "그랬듯",
  "뒀지",
  "괜찮았는데",
  "붙여놓고",
  "손수",
  "완벽하군요",
  "그럽시다",
  "거즈를",
  "아그네스는",
  "보라니까요",
  "날거야",
  "가운데로",
  "안았을",
  "사람들이죠",
  "보여줄게요",
  "연구소를",
  "떠났다가",
  "죽여줄",
  "놔둬도",
  "안심해요",
  "문제없이",
  "나가보게",
  "해주던가요",
  "아내분께",
  "사실이잖아요",
  "전기충격",
  "나왔군",
  "최남현",
  "착각을",
  "줬어야",
  "상상조차",
  "수녀원에서",
  "야채를",
  "타입을",
  "방식도",
  "물어보죠",
  "보안관이",
  "예정에",
  "풀려난",
  "괜찮군요",
  "느꼈죠",
  "노출시키고",
  "보이겠어",
  "일요일마다",
  "감사드리고",
  "아무거나요",
  "생각이라도",
  "내실",
  "살인과",
  "나팔을",
  "식욕이",
  "미아를",
  "말씀드렸을",
  "돌아왔잖아요",
  "섰을",
  "일과는",
  "뒤도",
  "이번만",
  "안락사를",
  "범죄는",
  "남들도",
  "뭉쳐서",
  "돼있어",
  "아파할",
  "빼세요",
  "치료법이",
  "낯을",
  "멎게",
  "초콜렛이",
  "어길",
  "아들인가",
  "불륜이",
  "어둠에",
  "슬펐어요",
  "도금봉",
  "만드셨어요",
  "찾아봐야지",
  "죽었으니",
  "수차례",
  "소수만이",
  "담당의가",
  "최은희",
  "들을까",
  "없던데요",
  "마시겠나",
  "조심하지",
  "저쩌고",
  "샤나",
  "그리워해",
  "블론드",
  "먹는다잖아요",
  "뵙네요",
  "물속에서",
  "슬로안",
  "결혼해야",
  "움직이네요",
  "엄청나고",
  "친절하시군요",
  "졸업했어요",
  "했을걸",
  "게임이란",
  "운명적인",
  "용아",
  "고용",
  "놓이소",
  "했는진",
  "치프로서",
  "양보",
  "맡는다",
  "이름이네",
  "기세가",
  "손대는",
  "노인들은",
  "진술서를",
  "시장으로",
  "철자는",
  "트링코말리",
  "운동장에서",
  "마누라와",
  "찼다고",
  "로레인한테",
  "몰리토",
  "가버려요",
  "관절",
  "정장을",
  "이건가요",
  "도시야",
  "첫키스를",
  "기왕이면",
  "켄싱턴",
  "기저귀에",
  "역이",
  "바닷속",
  "한심해",
  "앉아있어요",
  "대학교를",
  "폭탄이나",
  "가쇼",
  "어쨌든요",
  "꿈꿔온",
  "추자",
  "최면",
  "흉내는",
  "인사드려라",
  "낱말",
  "오랬어",
  "들리잖아",
  "레지날드",
  "생생한",
  "계시요",
  "심실세동입니다",
  "사용하세요",
  "마이크로",
  "중계",
  "결심했어",
  "불쌍히",
  "잊었네요",
  "서커스",
  "그러긴",
  "들었으니",
  "그거잖아요",
  "병원이란",
  "학교에는",
  "발견하는",
  "사랑해줘요",
  "부순",
  "줄기",
  "주차장으로",
  "두렵습니다",
  "열상이",
  "놓았어",
  "단둘이서",
  "때문이니",
  "초콜릿을",
  "의사분들이",
  "웬일이요",
  "나가라는",
  "정하고",
  "뻔했군",
  "차지하지",
  "애다",
  "곡이죠",
  "만난건",
  "대학원",
  "플러그를",
  "친구였죠",
  "심각하고",
  "반복하고",
  "살인범이",
  "멜로디",
  "찔리는",
  "죠지랑",
  "시간여행을",
  "사업이란",
  "바랄게",
  "웃음거리로",
  "주겠대",
  "핵폭탄을",
  "중이시죠",
  "스폰서가",
  "때려눕혀",
  "상황과",
  "두통을",
  "핑크색",
  "다에게",
  "시작인데",
  "왔나봐",
  "기타는",
  "맞질",
  "조끼를",
  "리앤",
  "내기에",
  "주막에",
  "과격한",
  "스카트가",
  "증명될",
  "언니들",
  "차지하게",
  "마라톤이",
  "실험입니다",
  "싸다",
  "올리브",
  "흥미진진한",
  "달아난",
  "뽀뽀하고",
  "우체국",
  "그치면",
  "안대",
  "봐준다",
  "때렸나",
  "허튼소리",
  "외면하지",
  "보낼까요",
  "변기가",
  "영화에선",
  "성함을",
  "모였지",
  "좋으실대로",
  "태치",
  "당황하게",
  "맡도록",
  "괴팍한",
  "이집트에서",
  "와트의",
  "않으시죠",
  "죽었구나",
  "어릴때부터",
  "이상하단",
  "믿지도",
  "산거야",
  "생각할걸",
  "만들면서",
  "생각이었죠",
  "바보죠",
  "운동은",
  "벨을",
  "시간들",
  "보바리",
  "뺏어갔어",
  "그루지어스씨",
  "그거를",
  "날인",
  "제닛",
  "돌아오다",
  "참내",
  "달빛이",
  "벵골",
  "밀어붙이는",
  "정도였다",
  "뜻밖에",
  "고등학교에",
  "참구",
  "조사와",
  "상관없으니",
  "각오해",
  "벌이고",
  "싶다는데",
  "그만이지",
  "금욕",
  "야수",
  "틀니가",
  "찍었다고",
  "방울도",
  "화내면",
  "튼튼하고",
  "생각하냐고",
  "헤어스타일을",
  "유럽을",
  "들어보지",
  "뻔뻔한",
  "클러이스터럼에",
  "휴가에서",
  "좋아한대",
  "좋아지질",
  "순간의",
  "어떤건",
  "행운아야",
  "재미없게",
  "연결을",
  "치켜",
  "달라서요",
  "정확하다면",
  "홀을",
  "광범위하게",
  "전기는",
  "폐쇄성",
  "임종을",
  "갖다주는",
  "났구나",
  "문란한",
  "주입기에",
  "전선의",
  "춤추면서",
  "나갔나",
  "대요",
  "페리를",
  "육신이",
  "엔진도",
  "창수",
  "되더라고요",
  "작정이야",
  "장기에",
  "요소",
  "틀니",
  "효과적인",
  "가방이야",
  "다가온다",
  "개인적으로는",
  "문의",
  "치료하기",

  "나사가",
  "있으셔",
  "이거냐",
  "현재를",
  "않고도",
  "켜야",
  "많긴",
  "종류로",
  "보나마나",
  "왼",
  "드루드씨",
  "정반대로",
  "나와있어요",
  "떨어졌대요",
  "래요",
  "뺏어간",
  "재건술은",
  "말해버려",
  "삼촌에게",
  "삼는",
  "날일",
  "규칙도",
  "까짓거",
  "일부분을",
  "되었군요",
  "농담이고",
  "누군가도",
  "에어로빅",
  "병원까지",
  "콩고",
  "죽다시피",
  "죽였네",
  "심막",
  "자본주의",
  "수술이랑",
  "맞았다는",
  "탕수육",
  "돌아가야만",
  "걸려도",
  "자연스런",
  "분은요",
  "힘들었거든",
  "중점을",
  "웨버라고",
  "남자분",
  "울리면",
  "만들거든요",
]