import React from "react";

import styled from "styled-components";

function Button(props) {
  return <button {...props}>{props.children}</button>
}

export default styled(Button)`
  background: #094C6D;
  border: 1px solid #1BA3FA;
  padding: 8px 16px;
  border-radius: 8px;
  color: #1BA3FA;
  font-weight: bold;
  cursor: pointer;
`;