import hangul from "hangul-js";

export const vowels = [
  "ㅓ",
  "ㅣ",
  "ㅏ",
  "ㅑ",
  "ㅡ",
  "ㅜ",
  "ㅠ",
  "ㅗ",
  "ㅛ",
  "ㅕ",
  // "ㅐ",
  // "ㅔ"
];

// const double_consants = [
//   "ㅃ",
//   "ㅉ",
//   "ㄸ",
//   "ㄲ",
//   "ㅆ",
// ]

export const consonants = [
  "ㅇ",
  "ㄹ",
  "ㅎ",
  "ㅊ",
  "ㅈ",
  "ㅂ",
  "ㄱ",
  "ㄴ",
  "ㅍ",
  "ㅋ",
  "ㅁ",
  "ㄷ",
  "ㅅ",
  "ㅌ",
  // ...double_consants
];

export function* permutateBlocks({ vowels = [], consonants = [] }, length) {
  let block;
  switch (length) {
    case 1: {
      for (const vc of [...vowels, ...consonants]) {
        block = hangul.assemble([vc]);
        if (block.length === 1) {
          yield block;
        }
      }
      break;
    }
    case 2: {
      for (const c of consonants) {
        for (const v of vowels) {
          block = hangul.assemble([c, v]);
          if (block.length === 1) {
            yield block;
          }
        }
      }
      break;
    }
    case 3: {
      for (const c of consonants) {
        for (const v of vowels) {
          for (const f of consonants) {
            block = hangul.assemble([c, v, f]);
            if (block.length === 1) {
              yield block;
            }
          }
        }
      }
      break;
    }
    case 4: {
      for (const c of consonants) {
        for (const v of vowels) {
          for (const f of consonants) {
            for (const f2 of consonants) {
              block = hangul.assemble([c, v, f, f2]);
              if (block.length === 1) {
                yield block;
              }
            }
          }
        }
      }
      break;
    }
    default: {
      break;
    }
  }
}

export const levels = {
  1: [...permutateBlocks({ consonants: consonants.slice(0, 1) }, 1)],
  2: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 1), vowels: vowels.slice(0, 1) },
      1
    ),
  ],
  3: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 2), vowels: vowels.slice(0, 1) },
      1
    ),
  ],
  4: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 2), vowels: vowels.slice(0, 2) },
      1
    ),
  ],
  5: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 3), vowels: vowels.slice(0, 2) },
      1
    ),
  ],
  6: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 3), vowels: vowels.slice(0, 3) },
      1
    ),
  ],
  7: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 4), vowels: vowels.slice(0, 3) },
      1
    ),
  ],
  8: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 4), vowels: vowels.slice(0, 4) },
      1
    ),
  ],
  9: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 5), vowels: vowels.slice(0, 4) },
      1
    ),
  ],
  10: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 5), vowels: vowels.slice(0, 5) },
      1
    ),
  ],
  11: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 6), vowels: vowels.slice(0, 5) },
      1
    ),
  ],
  12: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 6), vowels: vowels.slice(0, 6) },
      1
    ),
  ],
  13: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 7), vowels: vowels.slice(0, 6) },
      1
    ),
  ],
  14: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 7), vowels: vowels.slice(0, 7) },
      1
    ),
  ],
  15: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 8), vowels: vowels.slice(0, 7) },
      1
    ),
  ],
  16: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 8), vowels: vowels.slice(0, 8) },
      1
    ),
  ],
  17: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 9), vowels: vowels.slice(0, 8) },
      1
    ),
  ],
  18: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 9), vowels: vowels.slice(0, 9) },
      1
    ),
  ],
  19: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 10), vowels: vowels.slice(0, 9) },
      1
    ),
  ],
  20: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 10), vowels: vowels.slice(0, 10) },
      1
    ),
  ],
  21: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 11), vowels: vowels.slice(0, 10) },
      1
    ),
  ],
  22: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 11), vowels: vowels.slice(0, 8) },
      1
    ),
  ],
  23: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 12), vowels: vowels.slice(0, 9) },
      1
    ),
  ],
  24: [
    ...permutateBlocks(
      { consonants: consonants.slice(0, 12), vowels: vowels.slice(0, 10) },
      1
    ),
  ],
  25: [...permutateBlocks({ consonants, vowels }, 2)],
  26: [...permutateBlocks({ consonants, vowels }, 3)],
  // 27: [...permutateBlocks({consonants, vowels}, 4)],
  // 28: [...permutateBlocks({consonants, vowels}, 5)]
};

export const newChar = {
  1: [...permutateBlocks({ consonants: consonants.slice(0, 1) }, 1)],
  2: [...permutateBlocks({ vowels: vowels.slice(0, 1) }, 1)],
  3: [...permutateBlocks({ consonants: consonants.slice(1, 2) }, 1)],
  4: [...permutateBlocks({ vowels: vowels.slice(1, 2) }, 1)],
  5: [...permutateBlocks({ consonants: consonants.slice(2, 3) }, 1)],
  6: [...permutateBlocks({ vowels: vowels.slice(2, 3) }, 1)],
  7: [...permutateBlocks({ consonants: consonants.slice(3, 4) }, 1)],
  8: [...permutateBlocks({ vowels: vowels.slice(3, 4) }, 1)],
  9: [...permutateBlocks({ consonants: consonants.slice(4, 5) }, 1)],
  10: [...permutateBlocks({ vowels: vowels.slice(4, 5) }, 1)],
  11: [...permutateBlocks({ consonants: consonants.slice(5, 6) }, 1)],
  12: [...permutateBlocks({ vowels: vowels.slice(5, 6) }, 1)],
  13: [...permutateBlocks({ consonants: consonants.slice(6, 7) }, 1)],
  14: [...permutateBlocks({ vowels: vowels.slice(6, 7) }, 1)],
  15: [...permutateBlocks({ consonants: consonants.slice(7, 8) }, 1)],
  16: [...permutateBlocks({ vowels: vowels.slice(7, 8) }, 1)],
  17: [...permutateBlocks({ consonants: consonants.slice(8, 9) }, 1)],
  18: [...permutateBlocks({ vowels: vowels.slice(8, 9) }, 1)],
  19: [...permutateBlocks({ consonants: consonants.slice(9, 10) }, 1)],
  20: [...permutateBlocks({ vowels: vowels.slice(9, 10) }, 1)],
  21: [...permutateBlocks({ consonants: consonants.slice(10, 11) }, 1)],
  22: [...permutateBlocks({ consonants: consonants.slice(11, 12) }, 1)],
  23: [...permutateBlocks({ consonants: consonants.slice(12, 13) }, 1)],
  24: [...permutateBlocks({ consonants: consonants.slice(13, 14) }, 1)],
};

export const charsForLevel = {
  1: [...consonants.slice(0, 1)],
  2: [...consonants.slice(0, 1), ...vowels.slice(0, 1)],
  3: [...consonants.slice(0, 2), ...vowels.slice(0, 1)],
  4: [...consonants.slice(0, 2), ...vowels.slice(0, 2)],
  5: [...consonants.slice(0, 3), ...vowels.slice(0, 2)],
  6: [...consonants.slice(0, 3), ...vowels.slice(0, 3)],
  7: [...consonants.slice(0, 4), ...vowels.slice(0, 3)],
  8: [...consonants.slice(0, 4), ...vowels.slice(0, 4)],
  9: [...consonants.slice(0, 5), ...vowels.slice(0, 4)],
  10: [...consonants.slice(0, 5), ...vowels.slice(0, 5)],
  11: [...consonants.slice(0, 6), ...vowels.slice(0, 5)],
  12: [...consonants.slice(0, 6), ...vowels.slice(0, 6)],
  13: [...consonants.slice(0, 7), ...vowels.slice(0, 6)],
  14: [...consonants.slice(0, 7), ...vowels.slice(0, 7)],
  15: [...consonants.slice(0, 8), ...vowels.slice(0, 7)],
  16: [...consonants.slice(0, 8), ...vowels.slice(0, 8)],
  17: [...consonants.slice(0, 9), ...vowels.slice(0, 8)],
  18: [...consonants.slice(0, 9), ...vowels.slice(0, 9)],
  19: [...consonants.slice(0, 10), ...vowels.slice(0, 9)],
  20: [...consonants.slice(0, 10), ...vowels.slice(0, 10)],
  21: [...consonants.slice(0, 11), ...vowels.slice(0, 10)],
  22: [...consonants.slice(0, 12), ...vowels.slice(0, 10)],
  23: [...consonants.slice(0, 13), ...vowels.slice(0, 10)],
  24: [...consonants.slice(0, 14), ...vowels.slice(0, 10)],
  25: [...consonants, ...vowels],
  26: [...consonants, ...vowels],
};

export const lookup = [
  "_c_hd_v_ㄹ_c_5.svg",
  "_c_hu_v_ㄱ_c_5.svg",
  "_c_hu_v_ㄴ_c_5.svg",
  "_c_ㅜ_v_c_c_5.svg",
  "_c_ㅡ_v_c_c_5.svg",
  
  // 3 syllable blocks
  "_ㅊ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅗ)_(ㅊ)_3.svg",
  "_ㅇ_(ㅗ)_(ㅌ)_3.svg",
  "_ㅇ_(ㅗ)_(ㅎ)_3.svg",
  "_ㅋ_(ㅠ)_(ㅋ)_3.svg",
  "_ㅋ_(ㅠ)_(ㅊ)_3.svg",
  "_ㄱ_(ㅑ)_(ㄹ)_3.svg",
  "_ㅋ_(ㅠ)_(ㄹ)_3.svg",
  "_ㄱ_(ㅜ)_(ㄴ)_3.svg",
  "_ㄱ_(ㅜ)_(ㄹ)_3.svg",
  "_ㄱ_(ㅜ)_(ㅅ)_3.svg",
  "_ㄱ_(ㅜ)_(ㅂ)_3.svg",
  "_ㄱ_(ㅜ)_(ㅈ)_3.svg",
  "_ㄱ_(ㅜ)_(ㅊ)_3.svg",
  "_ㄱ_(ㅜ)_(ㅎ)_3.svg",
  "_ㄴ_(ㅕ)_(ㄴ)_3.svg",
  "_ㄴ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅠ)_(ㅎ)_3.svg",
  "_ㅇ_(ㅠ)_(ㅊ)_3.svg",
  "_ㄴ_(ㅛ)_(ㅊ)_3.svg",
  "_ㄴ_(ㅛ)_(ㅌ)_3.svg",
  "_ㄴ_(ㅛ)_(ㅎ)_3.svg",
  "_ㄹ_(ㅕ)_(ㄹ)_3.svg",
  "_ㄹ_(ㅕ)_(ㅊ)_3.svg",
  "_ㄹ_(ㅕ)_(ㅌ)_3.svg",
  "_ㄹ_(ㅕ)_(ㅎ)_3.svg",
  "_ㅅ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅅ_(ㅗ)_(ㅌ)_3.svg",
  "_ㅅ_(ㅑ)_(ㅅ)_3.svg",
  "_ㅅ_(ㅕ)_(ㄹ)_3.svg",
  "_ㅅ_(ㅕ)_(ㅎ)_3.svg",
  "_ㅅ_(ㅕ)_(ㅌ)_3.svg",
  "_ㅌ_(ㅜ)_(ㅌ)_3.svg",
  "_ㅌ_(ㅜ)_(ㄹ)_3.svg",
  "_ㅌ_(ㅜ)_(ㅎ)_3.svg",
  "_ㅅ_(ㅕ)_(ㄴ)_3.svg",
  "_ㅋ_(ㅓ)_(ㄹ)_3.svg",
  "_ㅌ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅌ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅌ_(ㅕ)_(ㄴ)_3.svg",
  "_ㅋ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅋ_(ㅠ)_(ㅎ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅍ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅌ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅁ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅊ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅅ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅈ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅇ)_3.svg",
  "_ㄱ_(ㅡ)_(ㄴ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅂ)_3.svg",
  "_ㄱ_(ㅡ)_(ㄷ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅎ)_3.svg",
  "_ㄱ_(ㅡ)_(ㅋ)_3.svg",
  "_ㄱ_(ㅡ)_(ㄹ)_3.svg",
  "_ㅁ_(ㅑ)_(ㅎ)_3.svg",
  "_ㅁ_(ㅏ)_(ㅎ)_3.svg",
  "_ㅂ_(ㅗ)_(ㅎ)_3.svg",
  "_ㅂ_(ㅗ)_(ㅊ)_3.svg",
  "_ㅂ_(ㅗ)_(ㅌ)_3.svg",
  "_ㅂ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅎ_(ㅓ)_(ㅎ)_3.svg",
  "_ㄴ_(ㅗ)_(ㄹ)_3.svg",
  "_ㄴ_(ㅗ)_(ㅎ)_3.svg",
  "_ㄴ_(ㅗ)_(ㅌ)_3.svg",
  "_ㄴ_(ㅗ)_(ㅊ)_3.svg",
  "_ㄴ_(ㅡ)_(ㄹ)_3.svg",
  "_ㄴ_(ㅡ)_(ㅎ)_3.svg",
  "_ㄴ_(ㅡ)_(ㅌ)_3.svg",
  "_ㄴ_(ㅡ)_(ㅊ)_3.svg",
  "_ㄹ_(ㅡ)_(ㅊ)_3.svg",
  "_ㄹ_(ㅡ)_(ㅎ)_3.svg",
  "_ㄹ_(ㅡ)_(ㄹ)_3.svg",
  "_ㅁ_(ㅕ)_(ㄹ)_3.svg",
  "_ㅁ_(ㅕ)_(ㅎ)_3.svg",
  "_ㅁ_(ㅕ)_(ㅌ)_3.svg",
  "_ㅎ_(ㅓ)_(ㅊ)_3.svg",
  "_ㅁ_(ㅕ)_(ㅂ)_3.svg",
  "_ㅋ_(ㅕ)_(ㄹ)_3.svg",
  "_ㅋ_(ㅕ)_(ㅌ)_3.svg",
  "_ㅋ_(ㅕ)_(ㅂ)_3.svg",
  "_ㅋ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅕ)_(ㅌ)_3.svg",
  "_ㅊ_(ㅕ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅕ)_(ㅎ)_3.svg",
  "_ㅊ_(ㅕ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅋ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅋ_(ㅛ)_(ㅎ)_3.svg",
  "_ㅋ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅌ_(ㅏ)_(ㅎ)_3.svg",
  "_ㅌ_(ㅡ)_(ㄹ)_3.svg",
  "_ㅌ_(ㅡ)_(ㅎ)_3.svg",
  "_ㅌ_(ㅡ)_(ㅊ)_3.svg",
  "_ㅌ_(ㅓ)_(ㅎ)_3.svg",
  "_ㅌ_(ㅓ)_(ㅊ)_3.svg",
  "_ㅌ_(ㅓ)_(ㄹ)_3.svg",
  "_ㅌ_(ㅗ)_(ㅌ)_3.svg",
  "_ㅊ_(ㅓ)_(ㅊ)_3.svg",
  "_ㅌ_(ㅗ)_(ㅊ)_3.svg",
  "_ㅌ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅌ_(ㅗ)_(ㅎ)_3.svg",
  "_ㅎ_(ㅓ)_(ㄹ)_3.svg",
  "_ㅎ_(ㅓ)_(ㅌ)_3.svg",
  "_ㅎ_(ㅓ)_(ㅂ)_3.svg",
  "_ㅎ_(ㅓ)_(ㅋ)_3.svg",
  "_ㅂ_(ㅛ)_(ㄹ)_3.svg",
  "_ㄷ_(ㅛ)_(ㄹ)_3.svg",
  "_ㄷ_(ㅛ)_(ㅎ)_3.svg",
  "_ㄷ_(ㅛ)_(ㅌ)_3.svg",
  "_ㄷ_(ㅛ)_(ㅊ)_3.svg",
  "_ㄷ_(ㅗ)_(ㄹ)_3.svg",
  "_ㄷ_(ㅗ)_(ㅎ)_3.svg",
  "_ㄷ_(ㅗ)_(ㅌ)_3.svg",
  "_ㄷ_(ㅗ)_(ㅊ)_3.svg",
  "_ㄹ_(ㅛ)_(ㄹ)_3.svg",
  "_ㄹ_(ㅛ)_(ㅎ)_3.svg",
  "_ㄹ_(ㅛ)_(ㅌ)_3.svg",
  "_ㄹ_(ㅛ)_(ㅊ)_3.svg",
  "_ㄹ_(ㅗ)_(ㄹ)_3.svg",
  "_ㄹ_(ㅗ)_(ㅎ)_3.svg",
  "_ㄹ_(ㅗ)_(ㅌ)_3.svg",
  "_ㄹ_(ㅗ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅓ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅓ)_(ㅎ)_3.svg",
  "_ㅊ_(ㅣ)_(ㅎ)_3.svg",
  "_ㅊ_(ㅣ)_(ㅌ)_3.svg",
  "_ㅊ_(ㅣ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅣ)_(ㄹ)_3.svg",
  "_ㅎ_(ㅡ)_(ㄹ)_3.svg",
  "_ㅎ_(ㅡ)_(ㅎ)_3.svg",
  "_ㅎ_(ㅡ)_(ㅌ)_3.svg",
  "_ㅎ_(ㅡ)_(ㅂ)_3.svg",
  "_ㅎ_(ㅡ)_(ㅊ)_3.svg",
  "_ㅇ_(ㅡ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅡ)_(ㅎ)_3.svg",
  "_ㅇ_(ㅡ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅡ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅡ)_(ㅎ)_3.svg",
  "_ㅊ_(ㅡ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅏ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅏ)_(ㅎ)_3.svg",
  "_ㅊ_(ㅏ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅏ)_(ㅌ)_3.svg",
  "_ㅍ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅍ_(ㅛ)_(ㅎ)_3.svg",
  "_ㅍ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅍ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅍ_(ㅕ)_(ㅌ)_3.svg",
  "_ㅌ_(ㅛ)_(ㅎ)_3.svg",
  "_ㅌ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅈ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅈ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅈ_(ㅛ)_(ㅎ)_3.svg",
  "_ㅈ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅛ)_(ㅎ)_3.svg",
  "_ㅇ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅇ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅇ_(ㅕ)_(ㅌ)_3.svg",
  "_ㅇ_(ㅕ)_(ㄹ)_3.svg",
  "_ㅇ_(ㅕ)_(ㅂ)_3.svg",
  "_ㅊ_(ㅜ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅜ)_(ㅂ)_3.svg",
  "_ㅊ_(ㅕ)_(ㅂ)_3.svg",
  "_ㅊ_(ㅜ)_(ㅎ)_3.svg",
  "_ㅇ_(ㅜ)_(ㅎ)_3.svg",
  "_ㅇ_(ㅜ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅗ)_(ㅌ)_3.svg",
  "_ㅊ_(ㅗ)_(ㅂ)_3.svg",
  "_ㅊ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅗ)_(ㅊ)_3.svg",
  "_ㅊ_(ㅗ)_(ㅎ)_3.svg",
  "_ㅎ_(ㅗ)_(ㅌ)_3.svg",
  "_ㅎ_(ㅗ)_(ㅈ)_3.svg",
  "_ㅊ_(ㅕ)_(ㄴ)_3.svg",
  "_ㅎ_(ㅗ)_(ㅂ)_3.svg",
  "_ㅎ_(ㅗ)_(ㅇ)_3.svg",
  "_ㅎ_(ㅗ)_(ㄹ)_3.svg",
  "_ㅎ_(ㅗ)_(ㅎ)_3.svg",
  "_ㅎ_(ㅗ)_(ㅊ)_3.svg",
  "_ㅎ_(ㅛ)_(ㄹ)_3.svg",
  "_ㅎ_(ㅛ)_(ㅊ)_3.svg",
  "_ㅎ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅎ_(ㅛ)_(ㅈ)_3.svg",
  "_ㅎ_(ㅛ)_(ㅂ)_3.svg",
  "_ㅎ_(ㅛ)_(ㅇ)_3.svg",
  "_ㅎ_(ㅛ)_(ㅎ)_3.svg",
  "_ㅎ_(ㅕ)_(ㄹ)_3.svg",
  "_ㅊ_(ㅛ)_(ㅌ)_3.svg",
  "_ㅊ_(ㅛ)_(ㅎ)_3.svg",
  
  "_(ㅎ)_ㅕ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅊ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅎ)_ㅕ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅎ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅎ)_ㅛ_(ㅇ)_3.svg",
  "_(ㅎ)_ㅛ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅛ_(ㅈ)_3.svg",
  "_(ㅎ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅎ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅎ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅎ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅗ_(ㅇ)_3.svg",
  "_(ㅎ)_ㅗ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅗ_(ㅈ)_3.svg",
  "_(ㅊ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅊ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅊ)_ㅗ_(ㅂ)_3.svg",
  "_(ㅊ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅊ)_ㅠ_(ㅍ)_3.svg",
  "_(ㅇ)_ㅕ_(ㅂ)_3.svg",
  "_(ㅇ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅇ)_ㅕ_(ㄹ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅍ)_3.svg",
  "_(ㅂ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅈ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅈ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅈ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅈ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅈ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅈ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅈ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅈ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅊ)_ㅕ_(ㅊ)_3.svg",
  "_(ㅊ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅌ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅌ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅍ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅍ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅍ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅊ)_ㅡ_(ㅊ)_3.svg",
  "_(ㅊ)_ㅡ_(ㅎ)_3.svg",
  "_(ㅊ)_ㅡ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅡ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅡ_(ㅎ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅍ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅇ)_3.svg",
  "_(ㄹ)_ㅓ_(ㄹ)_3.svg",
  "_(ㄹ)_ㅗ_(ㅊ)_3.svg",
  "_(ㄹ)_ㅗ_(ㅌ)_3.svg",
  "_(ㄹ)_ㅗ_(ㅎ)_3.svg",
  "_(ㄹ)_ㅛ_(ㅊ)_3.svg",
  "_(ㄹ)_ㅛ_(ㅌ)_3.svg",
  "_(ㄹ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅅ)_ㅜ_(ㄹ)_3.svg",
  "_(ㄷ)_ㅗ_(ㅊ)_3.svg",
  "_(ㄷ)_ㅗ_(ㅌ)_3.svg",
  "_(ㄷ)_ㅗ_(ㅎ)_3.svg",
  "_(ㄷ)_ㅗ_(ㄹ)_3.svg",
  "_(ㄷ)_ㅛ_(ㅊ)_3.svg",
  "_(ㄷ)_ㅛ_(ㅌ)_3.svg",
  "_(ㄷ)_ㅛ_(ㅎ)_3.svg",
  "_(ㄷ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅓ_(ㅋ)_3.svg",
  "_(ㅎ)_ㅓ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅓ_(ㅌ)_3.svg",
  "_(ㅎ)_ㅓ_(ㅇ)_3.svg",
  "_(ㅎ)_ㅓ_(ㄹ)_3.svg",
  "_(ㅂ)_ㅜ_(ㅌ)_3.svg",    
  "_(ㅌ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅌ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅌ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅌ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅌ)_ㅓ_(ㄹ)_3.svg",
  "_(ㅌ)_ㅓ_(ㅂ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅋ)_3.svg",
  "_(ㅋ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅋ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅋ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅋ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅕ_(ㅂ)_3.svg",
  "_(ㅋ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅂ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅌ)_ㅜ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅕ_(ㄹ)_3.svg",
  "_(ㅁ)_ㅕ_(ㅂ)_3.svg",
  "_(ㅁ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅁ)_ㅕ_(ㅎ)_3.svg",
  "_(ㅁ)_ㅕ_(ㄹ)_3.svg",
  "_(ㄴ)_ㅡ_(ㅊ)_3.svg",
  "_(ㄴ)_ㅡ_(ㅌ)_3.svg",
  "_(ㅊ)_ㅛ_(ㅊ)_3.svg",
  "_(ㄴ)_ㅡ_(ㅎ)_3.svg",
  "_(ㄴ)_ㅗ_(ㅊ)_3.svg",
  "_(ㄴ)_ㅗ_(ㅌ)_3.svg",
  "_(ㄴ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅂ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅂ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅅ)_ㅕ_(ㅎ)_3.svg",
  "_(ㅂ)_ㅛ_(ㅎ)_3.svg",
  "_(ㄴ)_ㅓ_(ㄹ)_3.svg",
  "_(ㄴ)_ㅓ_(ㅂ)_3.svg",
  "_(ㄴ)_ㅓ_(ㅌ)_3.svg",
  "_(ㅇ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅇ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅇ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅇ)_ㅗ_(ㅎ)_3.svg",
  "_(ㄹ)_ㅜ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅠ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅎ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅇ)_3.svg",
  "_(ㅎ)_ㅠ_(ㄷ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅈ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅊ)_3.svg",
  "_(ㅊ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅠ_(ㄱ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅌ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅍ)_3.svg",
  "_(ㅎ)_ㅠ_(ㄴ)_3.svg",
  "_(ㄹ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅁ)_3.svg",
  "_(ㅋ)_ㅠ_(ㅊ)_3.svg",
  "_(ㅌ)_ㅜ_(ㅅ)_3.svg",
  "_(ㄱ)_ㅑ_(ㄹ)_3.svg",
  "_(ㄱ)_ㅑ_(ㅂ)_3.svg",
  "_(ㄱ)_ㅜ_(ㄱ)_3.svg",
  "_(ㄱ)_ㅜ_(ㄴ)_3.svg",
  "_(ㄱ)_ㅜ_(ㄹ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅂ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅅ)_3.svg",
  "_(ㅎ)_ㅜ_(ㄱ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅇ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅈ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅊ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅋ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅍ)_3.svg",
  "_(ㄱ)_ㅜ_(ㅎ)_3.svg",
  "_(ㄱ)_ㅠ_(ㄱ)_3.svg",
  "_(ㄱ)_ㅡ_(ㄱ)_3.svg",
  "_(ㄴ)_ㅑ_(ㄹ)_3.svg",
  "_(ㄴ)_ㅑ_(ㅂ)_3.svg",
  "_(ㄴ)_ㅑ_(ㅌ)_3.svg",
  "_(ㄴ)_ㅕ_(ㄴ)_3.svg",
  "_(ㅋ)_ㅜ_(ㄱ)_3.svg",
  "_(ㄴ)_ㅕ_(ㄹ)_3.svg",
  "_(ㄴ)_ㅕ_(ㅂ)_3.svg",
  "_(ㄴ)_ㅕ_(ㅌ)_3.svg",
  "_(ㄴ)_ㅛ_(ㄹ)_3.svg",
  "_(ㄴ)_ㅛ_(ㅊ)_3.svg",
  "_(ㄴ)_ㅛ_(ㅌ)_3.svg",
  "_(ㄴ)_ㅛ_(ㅎ)_3.svg",
  "_(ㄹ)_ㅑ_(ㄹ)_3.svg",
  "_(ㄹ)_ㅑ_(ㅂ)_3.svg",
  "_(ㄹ)_ㅑ_(ㅌ)_3.svg",
  "_(ㄹ)_ㅕ_(ㄹ)_3.svg",
  "_(ㄹ)_ㅕ_(ㅂ)_3.svg",
  "_(ㄹ)_ㅕ_(ㅊ)_3.svg",
  "_(ㄹ)_ㅕ_(ㅋ)_3.svg",
  "_(ㄹ)_ㅕ_(ㅌ)_3.svg",
  "_(ㄹ)_ㅕ_(ㅍ)_3.svg",
  "_(ㄹ)_ㅕ_(ㅎ)_3.svg",
  "_(ㅅ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅅ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅅ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅅ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅅ)_ㅑ_(ㄹ)_3.svg",
  "_(ㅅ)_ㅕ_(ㄹ)_3.svg",
  "_(ㅅ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅅ)_ㅕ_(ㅊ)_3.svg",
  "_(ㅋ)_ㅓ_(ㅂ)_3.svg",
  "_(ㅋ)_ㅓ_(ㅌ)_3.svg",
  "_(ㅋ)_ㅓ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅓ_(ㄴ)_3.svg",
  "_(ㅎ)_ㅠ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅠ_(ㅋ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅎ)_3.svg",
  "_(ㅎ)_ㅜ_(ㄷ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅌ)_3.svg",
  "_(ㅎ)_ㅜ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅅ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅇ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅂ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅁ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅍ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅊ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅈ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅍ)_3.svg",
  "_(ㅌ)_ㅓ_(ㅌ)_3.svg",
  "_(ㅌ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅌ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅌ)_ㅜ_(ㅈ)_3.svg",
  "_(ㅌ)_ㅕ_(ㄴ)_3.svg",
  "_(ㅊ)_ㅜ_(ㅊ)_3.svg",
  "_(ㅋ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅋ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅋ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅠ_(ㅌ)_3.svg",
  "_(ㅋ)_ㅠ_(ㅎ)_3.svg",
  "_(ㅋ)_ㅠ_(ㅁ)_3.svg",
  "_(ㅋ)_ㅠ_(ㄴ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅍ)_3.svg",
  "_(ㅅ)_ㅜ_(ㅌ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅌ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅁ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅊ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅅ)_3.svg",
  "_(ㄷ)_ㅓ_(ㅎ)_3.svg",
  "_(ㄷ)_ㅓ_(ㅊ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅈ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅇ)_3.svg",
  "_(ㄱ)_ㅡ_(ㄴ)_3.svg",
  "_(ㅎ)_ㅗ_(ㅌ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅂ)_3.svg",
  "_(ㄱ)_ㅡ_(ㄷ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅎ)_3.svg",
  "_(ㄱ)_ㅡ_(ㅋ)_3.svg",
  "_(ㄱ)_ㅡ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅁ)_3.svg",
  "_(ㅁ)_ㅜ_(ㅌ)_3.svg",
  "_(ㄱ)_ㅠ_(ㄴ)_3.svg",
  "_(ㄱ)_ㅠ_(ㅌ)_3.svg",
  "_(ㄷ)_ㅜ_(ㄹ)_3.svg",
  "_(ㅁ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅁ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅁ)_ㅗ_(ㅌ)_3.svg",
  "_(ㅁ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅅ)_ㅓ_(ㅌ)_3.svg",
  "_(ㅅ)_ㅓ_(ㄹ)_3.svg",
  "_(ㅅ)_ㅓ_(ㅂ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅊ)_3.svg",
  "_(ㅅ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅅ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅅ)_ㅛ_(ㅊ)_3.svg",
  "_(ㄱ)_ㅠ_(ㅎ)_3.svg",
  "_(ㄱ)_ㅠ_(ㄹ)_3.svg",
  "_(ㄱ)_ㅠ_(ㅈ)_3.svg",
  "_(ㄱ)_ㅠ_(ㅊ)_3.svg",
  "_(ㅂ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅂ)_ㅗ_(ㅊ)_3.svg",
  "_(ㄹ)_ㅠ_(ㅂ)_3.svg",
  "_(ㅊ)_ㅠ_(ㅂ)_3.svg",
  "_(ㅌ)_ㅠ_(ㅂ)_3.svg",
  "_(ㄹ)_ㅠ_(ㅅ)_3.svg",
  "_(ㅌ)_ㅠ_(ㅅ)_3.svg",
  "_(ㄴ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅎ)_3.svg",
  "_(ㄴ)_ㅡ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅜ_(ㄴ)_3.svg",
  "_(ㅁ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅁ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅁ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅁ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅅ)_3.svg",
  "_(ㅋ)_ㅜ_(ㄷ)_3.svg",
  "_(ㅋ)_ㅜ_(ㄹ)_3.svg",
  "_(ㅋ)_ㅜ_(ㅌ)_3.svg",
  "_(ㅂ)_ㅜ_(ㄹ)_3.svg",
  "_(ㄷ)_ㅓ_(ㅌ)_3.svg",
  "_(ㄷ)_ㅓ_(ㄹ)_3.svg",
  "_(ㄷ)_ㅓ_(ㅂ)_3.svg",
  "_(ㄹ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅊ)_ㅕ_(ㅂ)_3.svg",
  "_(ㅊ)_ㅓ_(ㄹ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅌ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅋ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅂ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅊ)_3.svg",
  "_(ㅊ)_ㅓ_(ㅈ)_3.svg",
  "_(ㅎ)_ㅡ_(ㄹ)_3.svg",
  "_(ㅎ)_ㅡ_(ㅌ)_3.svg",
  "_(ㅎ)_ㅡ_(ㅊ)_3.svg",
  "_(ㅍ)_ㅗ_(ㄹ)_3.svg",
  "_(ㅍ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅍ)_ㅗ_(ㅊ)_3.svg",
  "_(ㅍ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅍ)_ㅕ_(ㅌ)_3.svg",
  "_(ㅂ)_ㅛ_(ㄹ)_3.svg",
  "_(ㄹ)_ㅜ_(ㄱ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅋ)_3.svg",
  "_(ㄹ)_ㅜ_(ㄷ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅅ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅌ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅈ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅇ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅊ)_3.svg",
  "_(ㄹ)_ㅠ_(ㄱ)_3.svg",
  "_(ㅊ)_ㅕ_(ㅇ)_3.svg",
  "_(ㄹ)_ㅜ_(ㅎ)_3.svg",
  "_(ㅊ)_ㅕ_(ㄴ)_3.svg",
  "_(ㄹ)_ㅠ_(ㅍ)_3.svg",
  "_(ㅊ)_ㅕ_(ㄱ)_3.svg",
  "_(ㄹ)_ㅠ_(ㅋ)_3.svg",
  "_(ㅇ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅇ)_ㅛ_(ㅊ)_3.svg",
  "_(ㅇ)_ㅛ_(ㅎ)_3.svg",
  "_(ㅇ)_ㅛ_(ㅌ)_3.svg",
  "_(ㅊ)_ㅜ_(ㅅ)_3.svg",
  "_(ㅊ)_ㅜ_(ㄹ)_3.svg",
  "_(ㅊ)_ㅜ_(ㅈ)_3.svg",
  "_(ㅊ)_ㅠ_(ㅍ)_3.svg",
  "_(ㅊ)_ㅗ_(ㅎ)_3.svg",
  "_(ㅊ)_ㅠ_(ㅋ)_3.svg",
  "_(ㅎ)_ㅜ_(ㄴ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅋ)_3.svg",
  "_(ㅎ)_ㅜ_(ㅇ)_3.svg",
  "_(ㅎ)_ㅛ_(ㄹ)_3.svg",
  "_(ㅊ)_ㅛ_(ㅌ)_3.svg",

  "_(ㅊ)_(ㅕ)_ㄹ_3.svg",
  "_(ㅊ)_(ㅕ)_ㅎ_3.svg",
  "_(ㅊ)_(ㅕ)_ㅊ_3.svg",
  "_(ㅊ)_(ㅛ)_ㄹ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅇ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅂ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅈ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅌ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅅ_3.svg",
  "_(ㅊ)_(ㅛ)_ㄷ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅁ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅍ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅋ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅕ)_ㄹ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅂ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅈ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅌ_3.svg",
  "_(ㅎ)_(ㅕ)_ㄷ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅁ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅋ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅍ_3.svg",
  "_(ㅎ)_(ㅕ)_ㄱ_3.svg",
  "_(ㅎ)_(ㅛ)_ㅅ_3.svg",
  "_(ㅎ)_(ㅛ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅛ)_ㅌ_3.svg",
  "_(ㅊ)_(ㅕ)_ㅌ_3.svg",
  "_(ㅎ)_(ㅛ)_ㅎ_3.svg",
  "_(ㅎ)_(ㅗ)_ㅊ_3.svg",
  "_(ㅊ)_(ㅠ)_ㅍ_3.svg",
  "_(ㅊ)_(ㅠ)_ㅋ_3.svg",
  "_(ㅊ)_(ㅗ)_ㅊ_3.svg",
  "_(ㅊ)_(ㅗ)_ㅎ_3.svg",
  "_(ㅊ)_(ㅜ)_ㅈ_3.svg",
  "_(ㅊ)_(ㅜ)_ㄹ_3.svg",
  "_(ㅊ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅇ)_(ㅜ)_ㅅ_3.svg",
  "_(ㄹ)_(ㅠ)_ㄱ_3.svg",
  "_(ㄹ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅍ)_(ㅠ)_ㅇ_3.svg",
  "_(ㅍ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅎ_3.svg",
  "_(ㅊ)_(ㅑ)_ㅌ_3.svg",
  "_(ㅊ)_(ㅑ)_ㅎ_3.svg",
  "_(ㅊ)_(ㅑ)_ㄹ_3.svg",
  "_(ㅊ)_(ㅑ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅈ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅋ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅁ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅌ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅍ_3.svg",
  "_(ㅊ)_(ㅛ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅇ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅎ_3.svg",
  "_(ㅅ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅎ)_(ㅣ)_ㅌ_3.svg",
  "_(ㅇ)_(ㅠ)_ㄹ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅈ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅌ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅋ_3.svg",
  "_(ㅎ)_(ㅣ)_ㅎ_3.svg",
  "_(ㅎ)_(ㅓ)_ㄱ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅍ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅇ_3.svg",
  "_(ㅎ)_(ㅣ)_ㄹ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅎ_3.svg",
  "_(ㅎ)_(ㅓ)_ㄹ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅅ_3.svg",
  "_(ㅎ)_(ㅣ)_ㅊ_3.svg",
  "_(ㅊ)_(ㅓ)_ㄹ_3.svg",
  "_(ㅎ)_(ㅓ)_ㄷ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅁ_3.svg",
  "_(ㅂ)_(ㅜ)_ㄹ_3.svg",
  "_(ㅊ)_(ㅕ)_ㅂ_3.svg",
  "_(ㅊ)_(ㅓ)_ㄷ_3.svg",
  "_(ㄹ)_(ㅛ)_ㅊ_3.svg",
  "_(ㅂ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅋ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅈ)_(ㅜ)_ㅅ_3.svg",
  "_(ㄴ)_(ㅜ)_ㅅ_3.svg",
  "_(ㄴ)_(ㅗ)_ㅅ_3.svg",
  "_(ㅇ)_(ㅜ)_ㄹ_3.svg",
  "_(ㅋ)_(ㅜ)_ㅌ_3.svg",
  "_(ㅋ)_(ㅠ)_ㄹ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅎ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅂ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅇ_3.svg",
  "_(ㅎ)_(ㅠ)_ㄷ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅈ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅠ)_ㄱ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅌ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅍ_3.svg",
  "_(ㅎ)_(ㅠ)_ㄴ_3.svg",
  "_(ㅎ)_(ㅠ)_ㅁ_3.svg",
  "_(ㅊ)_(ㅕ)_ㄱ_3.svg",
  "_(ㅎ)_(ㅕ)_ㅎ_3.svg",
  "_(ㅊ)_(ㅓ)_ㅌ_3.svg",
  "_(ㅋ)_(ㅜ)_ㅇ_3.svg",
  "_(ㅎ)_(ㅗ)_ㅎ_3.svg",
  "_(ㅂ)_(ㅛ)_ㄹ_3.svg",
  "_(ㅌ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅊ)_(ㅓ)_ㅎ_3.svg",
  "_(ㄱ)_(ㅑ)_ㄹ_3.svg",
  "_(ㅊ)_(ㅓ)_ㄱ_3.svg",
  "_(ㄱ)_(ㅑ)_ㅂ_3.svg",
  "_(ㅊ)_(ㅓ)_ㅍ_3.svg",
  "_(ㄱ)_(ㅜ)_ㄱ_3.svg",
  "_(ㄱ)_(ㅜ)_ㄷ_3.svg",
  "_(ㄱ)_(ㅜ)_ㄹ_3.svg",
  "_(ㄱ)_(ㅜ)_ㅁ_3.svg",
  "_(ㄱ)_(ㅜ)_ㅅ_3.svg",
  "_(ㄱ)_(ㅜ)_ㅇ_3.svg",
  "_(ㄱ)_(ㅜ)_ㅈ_3.svg",
  "_(ㄱ)_(ㅜ)_ㅊ_3.svg",
  "_(ㄱ)_(ㅜ)_ㅋ_3.svg",
  "_(ㄱ)_(ㅠ)_ㄱ_3.svg",
  "_(ㄷ)_(ㅜ)_ㄹ_3.svg",
  "_(ㄴ)_(ㅕ)_ㄹ_3.svg",
  "_(ㄷ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅅ)_(ㅗ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅎ)_(ㅜ)_ㄹ_3.svg",
  "_(ㅋ)_(ㅠ)_ㅎ_3.svg",
  "_(ㅁ)_(ㅜ)_ㅅ_3.svg",
  "_(ㅎ)_(ㅓ)_ㅂ_3.svg",
  "_(ㄱ)_(ㅠ)_ㅊ_3.svg",
  "_(ㅎ)_(ㅡ)_ㄷ_3.svg",
  "_(ㅎ)_(ㅡ)_ㅅ_3.svg",
  "_(ㅎ)_(ㅡ)_ㄹ_3.svg",
  "_(ㄹ)_(ㅜ)_ㅇ_3.svg",

  "_(ㅍ)_ㅕ_c_3.svg",
  "_(ㅍ)_ㅛ_c_3.svg",
  "_(ㅎ)_ㅡ_c_3.svg",
  "_(ㄴ)_ㅗ_c_3.svg",
  "_(ㄹ)_ㅛ_c_3.svg",
  "_(ㅂ)_ㅗ_c_3.svg",
  "_(ㄱ)_ㅓ_c_3.svg",
  "_(ㄱ)_ㅕ_c_3.svg",
  "_(ㄱ)_ㅗ_c_3.svg",
  "_(ㄱ)_ㅛ_c_3.svg",
  "_(ㄱ)_ㅜ_c_3.svg",
  "_(ㄴ)_ㅕ_c_3.svg",
  "_(ㄴ)_ㅛ_c_3.svg",
  "_(ㄷ)_ㅕ_c_3.svg",
  "_(ㄷ)_ㅗ_c_3.svg",
  "_(ㄷ)_ㅛ_c_3.svg",
  "_(ㅂ)_ㅕ_c_3.svg",
  "_(ㅇ)_ㅓ_c_3.svg",
  "_(ㅇ)_ㅕ_c_3.svg",
  "_(ㅇ)_ㅗ_c_3.svg",
  "_(ㅇ)_ㅛ_c_3.svg",
  "_(ㄹ)_ㅗ_c_3.svg",
  "_(ㄹ)_ㅜ_c_3.svg",
  "_(ㅋ)_ㅠ_c_3.svg",
  "_(ㅎ)_ㅠ_c_3.svg",
  "_(ㅋ)_ㅕ_c_3.svg",
  "_(ㄹ)_ㅕ_c_3.svg",
  "_(ㅁ)_ㅕ_c_3.svg",
  "_(ㅋ)_ㅗ_c_3.svg",
  "_(ㅎ)_ㅗ_c_3.svg",
  "_(ㅅ)_ㅗ_c_3.svg",
  "_(ㅊ)_ㅗ_c_3.svg",
  "_(ㅈ)_ㅗ_c_3.svg",
  "_(ㅋ)_ㅛ_c_3.svg",
  "_(ㅋ)_ㅡ_c_3.svg",
  "_(ㅋ)_ㅓ_c_3.svg",
  "_(ㅋ)_ㅜ_c_3.svg",
  "_(ㅌ)_ㅗ_c_3.svg",
  "_(ㅌ)_ㅛ_c_3.svg",
  "_(ㅌ)_ㅓ_c_3.svg",
  "_(ㄴ)_ㅡ_c_3.svg",
  "_(ㅂ)_ㅛ_c_3.svg",
  "_(ㅅ)_ㅛ_c_3.svg",
  "_(ㅈ)_ㅓ_c_3.svg",
  "_(ㅈ)_ㅛ_c_3.svg",
  "_(ㄹ)_ㅓ_c_3.svg",
  "_(ㅍ)_ㅓ_c_3.svg",
  "_(ㅂ)_ㅓ_c_3.svg",
  "_(ㅁ)_ㅓ_c_3.svg",
  "_(ㅁ)_ㅗ_c_3.svg",
  "_(ㅍ)_ㅗ_c_3.svg",
  "_(ㄱ)_ㅠ_c_3.svg",
  "_(ㅁ)_ㅡ_c_3.svg",
  "_(ㅁ)_ㅛ_c_3.svg",
  "_(ㅎ)_ㅕ_c_3.svg",
  "_(ㅎ)_ㅛ_c_3.svg",
  "_(ㅊ)_ㅛ_c_3.svg",

  "_(ㅎ)_hd_ㅇ_3.svg",
  "_(ㅎ)_hd_ㄴ_3.svg",
  "_(ㅎ)_hd_ㄱ_3.svg",
  "_(ㅇ)_hd_ㄹ_3.svg",
  "_(ㄹ)_hd_ㅎ_3.svg",
  "_(ㄹ)_hd_ㅊ_3.svg",
  "_(ㄹ)_hd_ㅈ_3.svg",
  "_(ㄹ)_hd_ㅌ_3.svg",
  "_(ㄹ)_hd_ㄷ_3.svg",
  "_(ㄹ)_hd_ㅁ_3.svg",
  "_(ㄹ)_hd_ㅋ_3.svg",
  "_(ㄹ)_hd_ㅍ_3.svg",
  "_(ㄹ)_hd_ㄱ_3.svg",
  "_(ㅅ)_hd_ㅌ_3.svg",
  "_(ㅅ)_hd_ㄹ_3.svg",
  "_(ㅂ)_hd_ㅌ_3.svg",
  "_(ㅈ)_hd_ㄹ_3.svg",
  "_(ㅋ)_hd_ㄹ_3.svg",
  "_(ㅎ)_hd_ㄹ_3.svg",
  "_(ㅎ)_hd_ㅋ_3.svg",
  "_(ㅎ)_hd_ㅎ_3.svg",
  "_(ㅎ)_hd_ㄷ_3.svg",
  "_(ㅎ)_hd_ㅌ_3.svg",
  "_(ㅎ)_hd_ㅂ_3.svg",
  "_(ㅎ)_hd_ㅁ_3.svg",
  "_(ㅎ)_hd_ㅊ_3.svg",
  "_(ㅎ)_hd_ㅈ_3.svg",
  "_(ㅎ)_hd_ㅍ_3.svg",
  "_(ㅌ)_hd_ㅈ_3.svg",
  "_(ㅊ)_hd_ㅊ_3.svg",
  "_(ㅋ)_hd_ㅌ_3.svg",
  "_(ㅋ)_hd_ㅁ_3.svg",
  "_(ㅁ)_hd_ㅌ_3.svg",
  "_(ㅁ)_hd_ㄹ_3.svg",
  "_(ㄱ)_hd_ㅌ_3.svg",
  "_(ㄱ)_hd_ㄹ_3.svg",
  "_(ㄱ)_hd_ㅈ_3.svg",
  

  "_(ㅌ)_hu_ㅌ_3.svg",
  "_(ㅌ)_hu_ㅊ_3.svg",
  "_(ㄹ)_hu_ㅊ_3.svg",
  "_(ㅍ)_hu_ㄹ_3.svg",
  "_(ㅎ)_hu_ㅍ_3.svg",
  "_(ㅎ)_hu_ㅋ_3.svg",
  "_(ㅎ)_hu_ㅁ_3.svg",
  "_(ㅎ)_hu_ㄷ_3.svg",
  "_(ㅎ)_hu_ㄱ_3.svg",
  "_(ㅎ)_hu_ㅌ_3.svg",
  "_(ㅎ)_hu_ㄹ_3.svg",

  "_(ㅌ)_v_ㅎ_3.svg",
  "_(ㅌ)_v_ㅊ_3.svg",
  "_(ㅎ)_v_ㅌ_3.svg",
  "_(ㅎ)_v_ㄹ_3.svg",
  "_(ㅎ)_v_ㅎ_3.svg",
  "_(ㅎ)_v_ㅊ_3.svg",
  "_(ㅎ)_v_ㅊ_3.svg",
  
  "_ㅊ_hd_(ㄹ)_3.svg",
  "_ㄹ_hd_(ㅎ)_3.svg",
  "_ㄹ_hd_(ㅊ)_3.svg",
  "_ㄹ_hd_(ㄹ)_3.svg",
  "_ㄹ_hd_(ㅌ)_3.svg",
  "_ㅋ_hd_(ㄷ)_3.svg",
  "_ㅋ_hd_(ㅎ)_3.svg",
  "_ㅋ_hd_(ㄹ)_3.svg",
  "_ㅋ_hd_(ㅍ)_3.svg",
  "_ㅋ_hd_(ㄱ)_3.svg",
  "_ㅋ_hd_(ㅇ)_3.svg",
  "_ㅋ_hd_(ㅁ)_3.svg",
  "_ㅋ_hd_(ㅅ)_3.svg",
  "_ㅋ_hd_(ㅊ)_3.svg",
  "_ㅋ_hd_(ㅋ)_3.svg",
  "_ㄱ_hd_(ㅊ)_3.svg",
  "_ㅎ_hd_(ㅁ)_3.svg",
  "_ㅌ_hd_(ㅌ)_3.svg",
  "_ㅌ_hd_(ㅊ)_3.svg",
  "_ㅌ_hd_(ㅈ)_3.svg",
  "_ㅊ_hd_(ㅌ)_3.svg",
  "_ㅊ_hd_(ㅊ)_3.svg",
  "_ㅋ_hd_(ㅌ)_3.svg",
  "_ㅋ_hd_(ㄴ)_3.svg",
  "_ㄱ_hd_(ㄴ)_3.svg",
  "_ㄱ_hd_(ㅎ)_3.svg",
  "_ㅇ_hd_(ㅊ)_3.svg",
  "_ㅇ_hd_(ㅎ)_3.svg",

  "_ㅊ_(ㅛ)_c_3.svg",
  "_ㅊ_(ㅜ)_c_3.svg",
  "_ㅇ_(ㅗ)_c_3.svg",
  "_ㅍ_(ㅛ)_c_3.svg",
  "_ㅊ_(ㅑ)_c_3.svg",
  "_ㅊ_(ㅏ)_c_3.svg",
  "_ㅎ_(ㅓ)_c_3.svg",
  "_ㄴ_(ㅗ)_c_3.svg",
  "_ㄴ_(ㅛ)_c_3.svg",
  "_ㄴ_(ㅡ)_c_3.svg",
  "_ㄷ_(ㅕ)_c_3.svg",
  "_ㄷ_(ㅗ)_c_3.svg",
  "_ㄷ_(ㅛ)_c_3.svg",
  "_ㄷ_(ㅜ)_c_3.svg",
  "_ㅁ_(ㅡ)_c_3.svg",
  "_ㅇ_(ㅓ)_c_3.svg",
  "_ㅇ_(ㅛ)_c_3.svg",
  "_ㅋ_(ㅕ)_c_3.svg",
  "_ㄱ_(ㅕ)_c_3.svg",
  "_ㄴ_(ㅕ)_c_3.svg",
  "_ㅂ_(ㅕ)_c_3.svg",
  "_ㄹ_(ㅕ)_c_3.svg",
  "_ㅈ_(ㅕ)_c_3.svg",
  "_ㅊ_(ㅕ)_c_3.svg",
  "_ㅁ_(ㅕ)_c_3.svg",
  "_ㅌ_(ㅕ)_c_3.svg",
  "_ㅋ_(ㅗ)_c_3.svg",
  "_ㅎ_(ㅗ)_c_3.svg",
  "_ㅂ_(ㅗ)_c_3.svg",
  "_ㅅ_(ㅗ)_c_3.svg",
  "_ㅈ_(ㅗ)_c_3.svg",
  "_ㅊ_(ㅗ)_c_3.svg",
  "_ㅋ_(ㅛ)_c_3.svg",
  "_ㅋ_(ㅡ)_c_3.svg",
  "_ㅋ_(ㅓ)_c_3.svg",
  "_ㅋ_(ㅜ)_c_3.svg",
  "_ㅌ_(ㅑ)_c_3.svg",
  "_ㅌ_(ㅣ)_c_3.svg",
  "_ㅌ_(ㅗ)_c_3.svg",
  "_ㅌ_(ㅛ)_c_3.svg",
  "_ㅌ_(ㅡ)_c_3.svg",
  "_ㅌ_(ㅏ)_c_3.svg",
  "_ㅌ_(ㅓ)_c_3.svg",
  "_ㅌ_(ㅠ)_c_3.svg",
  "_ㄹ_(ㅛ)_c_3.svg",
  "_ㄱ_(ㅡ)_c_3.svg",
  "_ㄷ_(ㅡ)_c_3.svg",
  "_ㅎ_(ㅡ)_c_3.svg",
  "_ㅊ_(ㅡ)_c_3.svg",
  "_ㅍ_(ㅡ)_c_3.svg",
  "_ㅊ_(ㅓ)_c_3.svg",
  "_ㅊ_(ㅣ)_c_3.svg",
  "_ㅎ_(ㅛ)_c_3.svg",
  "_ㅋ_(ㅠ)_c_3.svg",
  
  "_c_(ㅜ)_ㄱ_3.svg",
  "_c_(ㅜ)_ㅇ_3.svg",
  "_c_(ㅜ)_ㅊ_3.svg",
  "_c_(ㅠ)_ㄱ_3.svg",
  "_c_(ㅠ)_ㄹ_3.svg",
  "_c_(ㅠ)_ㅁ_3.svg",
  "_c_(ㅠ)_ㅅ_3.svg",
  "_c_(ㅠ)_ㅇ_3.svg",
  "_c_(ㅠ)_ㅈ_3.svg",
  "_c_(ㅠ)_ㅊ_3.svg",
  "_c_(ㅠ)_ㅎ_3.svg",
  "_c_(ㅜ)_ㄹ_3.svg",
  
  "_c_ㅑ_(ㅅ)_3.svg",
  "_c_ㅓ_(ㅇ)_3.svg",
  "_c_ㅜ_(ㄱ)_3.svg",
  "_c_ㅜ_(ㄴ)_3.svg",
  "_c_ㅜ_(ㄷ)_3.svg",
  "_c_ㅜ_(ㄹ)_3.svg",
  "_c_ㅜ_(ㅁ)_3.svg",
  "_c_ㅜ_(ㅅ)_3.svg",
  "_c_ㅜ_(ㅇ)_3.svg",
  "_c_ㅜ_(ㅈ)_3.svg",
  "_c_ㅜ_(ㅊ)_3.svg",
  "_c_ㅜ_(ㅋ)_3.svg",
  "_c_ㅜ_(ㅌ)_3.svg",
  "_c_ㅜ_(ㅍ)_3.svg",
  "_c_ㅜ_(ㅎ)_3.svg",
  "_c_ㅠ_(ㄱ)_3.svg",
  "_c_ㅠ_(ㄴ)_3.svg",
  "_c_ㅠ_(ㄹ)_3.svg",
  "_c_ㅠ_(ㅁ)_3.svg",
  "_c_ㅠ_(ㅇ)_3.svg",
  "_c_ㅠ_(ㅈ)_3.svg",
  "_c_ㅠ_(ㅎ)_3.svg",
  "_c_ㅑ_(ㅂ)_3.svg",
  "_c_ㅑ_(ㅌ)_3.svg",
  "_c_ㅑ_(ㄴ)_3.svg",
  "_c_ㅕ_(ㅂ)_3.svg",
  "_c_ㅕ_(ㅌ)_3.svg",
  "_c_ㅕ_(ㄴ)_3.svg",
  "_c_ㅡ_(ㄹ)_3.svg",
  "_c_ㅡ_(ㅎ)_3.svg",
  "_c_ㅡ_(ㅊ)_3.svg",
  "_c_ㅣ_(ㅂ)_3.svg",
  "_c_ㅣ_(ㅌ)_3.svg",
  "_c_ㅣ_(ㄴ)_3.svg",
  "_c_ㅣ_(ㄹ)_3.svg",
  "_c_ㅑ_(ㄹ)_3.svg",
  
  "_ㅅ_v_(ㄹ)_3.svg",
  "_ㅅ_v_(ㅎ)_3.svg",
  "_ㅅ_v_(ㅌ)_3.svg",
  "_ㅅ_v_(ㅊ)_3.svg",
  "_ㅋ_v_(ㄹ)_3.svg",
  "_ㅌ_v_(ㅌ)_3.svg",

  "_ㅎ_hd_(ㄹ)_3.svg",
  "_ㄱ_hd_(ㅌ)_3.svg",
  "_ㄱ_hd_(ㄹ)_3.svg",
  "_ㄱ_hd_(ㅈ)_3.svg",

  "_ㅂ_hu_(ㄹ)_3.svg",
  "_ㅂ_hu_(ㅊ)_3.svg",
  "_ㅌ_hu_(ㅌ)_3.svg",
  "_ㅎ_hu_(ㅂ)_3.svg",
  "_ㅋ_hu_(ㅊ)_3.svg",
  "_ㅋ_hu_(ㅎ)_3.svg",
  "_ㅁ_hu_(ㅊ)_3.svg",
  "_ㅁ_hu_(ㄹ)_3.svg",
  "_ㅁ_hu_(ㅌ)_3.svg",
  "_ㅁ_hu_(ㅎ)_3.svg",
  "_ㅅ_hu_(ㅎ)_3.svg",
  "_ㅅ_hu_(ㄹ)_3.svg",
  "_ㅅ_hu_(ㅊ)_3.svg",
  "_ㅂ_hu_(ㅎ)_3.svg",
  "_ㄴ_hu_(ㄹ)_3.svg",
  "_ㅍ_hu_(ㄹ)_3.svg",
  "_ㅍ_hu_(ㅎ)_3.svg",
  "_ㅍ_hu_(ㅊ)_3.svg",
  "_ㅂ_hu_(ㅌ)_3.svg",

  
  "_c_v_ㄱ_3.svg",
  "_c_v_ㄴ_3.svg",
  "_c_v_ㄷ_3.svg",
  "_c_v_ㄹ_3.svg",
  "_c_v_ㅁ_3.svg",
  "_c_v_ㅂ_3.svg",
  "_c_v_ㅅ_3.svg",
  "_c_v_ㅇ_3.svg",
  "_c_v_ㅈ_3.svg",
  "_c_v_ㅊ_3.svg",
  "_c_v_ㅋ_3.svg",
  "_c_v_ㅌ_3.svg",
  "_c_v_ㅍ_3.svg",
  "_c_v_ㅎ_3.svg",
  
  "_c_ㅏ_c_3.svg",
  "_c_ㅑ_c_3.svg",
  "_c_ㅓ_c_3.svg",
  "_c_ㅗ_c_3.svg",
  "_c_ㅜ_c_3.svg",
  "_c_ㅠ_c_3.svg",
  "_c_ㅡ_c_3.svg",
  "_c_ㅣ_c_3.svg",
  "_c_ㅕ_c_3.svg",
  
  "_ㄱ_v_c_3.svg",
  "_ㄴ_v_c_3.svg",
  "_ㄷ_v_c_3.svg",
  "_ㄹ_v_c_3.svg",
  "_ㅁ_v_c_3.svg",
  "_ㅂ_v_c_3.svg",
  "_ㅅ_v_c_3.svg",
  "_ㅇ_v_c_3.svg",
  "_ㅈ_v_c_3.svg",
  "_ㅊ_v_c_3.svg",
  "_ㅋ_v_c_3.svg", 
  "_ㅌ_v_c_3.svg", 
  "_ㅍ_v_c_3.svg",
  "_ㅎ_v_c_3.svg",
  
  "_c_hu_ㄷ_3.svg",
  "_c_hu_ㅂ_3.svg",
  "_c_hu_ㅅ_3.svg",
  "_c_hu_ㅈ_3.svg",
  "_c_hu_ㅊ_3.svg",
  "_c_hu_ㅋ_3.svg",
  "_c_hu_ㅌ_3.svg",
  "_c_hu_ㅍ_3.svg",
  "_c_hu_ㅎ_3.svg",
  
  "_ㄱ_hu_c_3.svg",
  "_ㅇ_hu_c_3.svg",
  "_ㅂ_hu_c_3.svg",
  "_ㅅ_hu_c_3.svg",
  "_ㅈ_hu_c_3.svg",
  "_ㅁ_hu_c_3.svg",
  "_ㅍ_hu_c_3.svg",
  
  "_ㄱ_hd_c_3.svg",
  "_ㄴ_hd_c_3.svg",
  "_ㅇ_hd_c_3.svg",
  "_ㅎ_hd_c_3.svg",
  "_ㄹ_hd_c_3.svg",
  "_ㅂ_hd_c_3.svg",
  "_ㅅ_hd_c_3.svg",
  "_ㄷ_hd_c_3.svg",
  "_ㅈ_hd_c_3.svg",
  "_ㅊ_hd_c_3.svg",
  "_ㅌ_hd_c_3.svg",
  "_ㅁ_hd_c_3.svg",
  "_ㅍ_hd_c_3.svg",

  "_c_hd_ㄱ_3.svg",
  "_c_hd_ㄴ_3.svg",
  "_c_hd_ㄷ_3.svg",
  "_c_hd_ㄹ_3.svg",
  "_c_hd_ㅁ_3.svg",
  "_c_hd_ㅇ_3.svg",
  "_c_hd_ㅋ_3.svg",
  "_c_hd_ㅌ_3.svg",
  "_c_hd_ㅍ_3.svg",
  "_c_hd_ㅎ_3.svg",
  "_c_hd_ㅂ_3.svg",
  "_c_hd_ㅈ_3.svg",
  
  "_c_hu_ㄱ_3.svg",
  "_c_hu_ㄴ_3.svg",
  "_c_hu_ㄷ_3.png",
  "_c_hu_ㄹ_3.png",
  "_c_hu_ㄹ_3.svg",
  "_c_hu_ㅁ_3.svg",
  "_c_hu_ㅇ_3.svg",
  "_ㄹ_hu_c_3.svg",
  
  // 2 syllable blocks
  "_ㄱ_(ㅕ)_2.svg",
  "_ㄱ_(ㅜ)_2.svg",
  "_ㄱ_(ㅠ)_2.svg",
  "_ㄱ_(ㅡ)_2.svg",
  "_ㄷ_(ㅗ)_2.svg",
  "_ㄹ_(ㅏ)_2.svg",
  "_ㄹ_(ㅓ)_2.svg",
  "_ㄹ_(ㅗ)_2.svg",
  "_ㄹ_(ㅛ)_2.svg",
  "_ㄹ_(ㅡ)_2.svg",
  "_ㄹ_(ㅣ)_2.svg",
  "_ㅁ_(ㅕ)_2.svg",
  "_ㅁ_(ㅗ)_2.svg",
  "_ㅁ_(ㅛ)_2.svg",
  "_ㅂ_(ㅕ)_2.svg",
  "_ㅂ_(ㅗ)_2.svg",
  "_ㅇ_(ㅓ)_2.svg",
  "_ㅇ_(ㅕ)_2.svg",
  "_ㅇ_(ㅗ)_2.svg",
  "_ㅋ_(ㅜ)_2.svg",
  "_ㅋ_(ㅡ)_2.svg",
  "_ㅌ_(ㅛ)_2.svg",
  "_ㅍ_(ㅗ)_2.svg",
  "_ㅍ_(ㅛ)_2.svg",

  "_(ㄱ)_ㅕ_2.svg",
  "_(ㄱ)_ㅛ_2.svg",
  "_(ㄱ)_ㅜ_2.svg",
  "_(ㄱ)_ㅠ_2.svg",
  "_(ㄴ)_ㅕ_2.svg",
  "_(ㄴ)_ㅛ_2.svg",
  "_(ㄷ)_ㅕ_2.svg",
  "_(ㄷ)_ㅗ_2.svg",
  "_(ㄹ)_ㅕ_2.svg",
  "_(ㄹ)_ㅗ_2.svg",
  "_(ㄹ)_ㅛ_2.svg",
  "_(ㅁ)_ㅕ_2.svg",
  "_(ㅁ)_ㅗ_2.svg",
  "_(ㅁ)_ㅛ_2.svg",
  "_(ㅂ)_ㅕ_2.svg",
  "_(ㅂ)_ㅗ_2.svg",
  "_(ㅅ)_ㅕ_2.svg",
  "_(ㅇ)_ㅓ_2.svg",
  "_(ㅇ)_ㅕ_2.svg",
  "_(ㅇ)_ㅗ_2.svg",
  "_(ㅇ)_ㅜ_2.svg",
  "_(ㅈ)_ㅕ_2.svg",
  "_(ㅈ)_ㅜ_2.svg",
  "_(ㅊ)_ㅕ_2.svg",
  "_(ㅊ)_ㅛ_2.svg",
  "_(ㅋ)_ㅕ_2.svg",
  "_(ㅋ)_ㅜ_2.svg",
  "_(ㅋ)_ㅠ_2.svg",
  "_(ㅌ)_ㅕ_2.svg",
  "_(ㅌ)_ㅗ_2.svg",
  "_(ㅌ)_ㅛ_2.svg",
  "_(ㅍ)_ㅕ_2.svg",
  "_(ㅍ)_ㅗ_2.svg",
  "_(ㅍ)_ㅛ_2.svg",
  "_(ㅎ)_ㅕ_2.svg",
  "_(ㅎ)_ㅗ_2.svg",
  "_(ㅎ)_ㅛ_2.svg",

  "_ㄱ_hd_2.svg",
  "_ㄴ_hd_2.svg",
  "_ㄷ_hd_2.svg",
  "_ㄹ_hd_2.svg",
  "_ㅁ_hd_2.svg",
  "_ㅂ_hd_2.svg",
  "_ㅅ_hd_2.svg",
  "_ㅇ_hd_2.svg",
  "_ㅈ_hd_2.svg",
  "_ㅊ_hd_2.svg",
  "_ㅋ_hd_2.svg",
  "_ㅌ_hd_2.svg",
  "_ㅍ_hd_2.svg",
  "_ㅎ_hd_2.svg",

  "_ㄱ_v_2.svg",
  "_ㄴ_v_2.svg",
  "_ㄷ_v_2.svg",
  "_ㄹ_v_2.svg",
  "_ㅁ_v_2.svg",
  "_ㅂ_v_2.svg",
  "_ㅅ_v_2.svg",
  "_ㅇ_v_2.svg",
  "_ㅈ_v_2.svg",
  "_ㅊ_v_2.svg",
  "_ㅋ_v_2.svg",
  "_ㅌ_v_2.svg",
  "_ㅍ_v_2.svg",
  "_ㅎ_v_2.svg",

  "_ㄱ_hu_2.svg",
  "_ㄴ_hu_2.svg",
  "_ㄷ_hu_2.svg",
  "_ㄹ_hu_2.svg",
  "_ㅁ_hu_2.svg",
  "_ㅂ_hu_2.svg",
  "_ㅅ_hu_2.svg",
  "_ㅇ_hu_2.svg",
  "_ㅈ_hu_2.svg",
  "_ㅊ_hu_2.svg",
  "_ㅋ_hu_2.svg",
  "_ㅌ_hu_2.svg",
  "_ㅍ_hu_2.svg",
  "_ㅎ_hu_2.svg",

  "_c_ㅏ_2.svg",
  "_c_ㅑ_2.svg",
  "_c_ㅓ_2.svg",
  "_c_ㅗ_2.svg",
  "_c_ㅛ_2.svg",
  "_c_ㅜ_2.svg",
  "_c_ㅠ_2.svg",
  "_c_ㅡ_2.svg",
  "_c_ㅣ_2.svg",

  // 1 syllable blocks
  "_ㄱ_1.svg",
  "_ㄴ_1.svg",
  "_ㄷ_1.svg",
  "_ㄹ_1.svg",
  "_ㅁ_1.svg",
  "_ㅂ_1.svg",
  "_ㅅ_1.svg",
  "_ㅇ_1.svg",
  "_ㅈ_1.svg",
  "_ㅊ_1.svg",
  "_ㅋ_1.svg",
  "_ㅌ_1.svg",
  "_ㅍ_1.svg",
  "_ㅎ_1.svg",
  "_ㅏ_1.svg",
  "_ㅑ_1.svg",
  "_ㅓ_1.svg",
  "_ㅕ_1.svg",
  "_ㅗ_1.svg",
  "_ㅛ_1.svg",
  "_ㅜ_1.svg",
  "_ㅠ_1.svg",
  "_ㅡ_1.svg",
  "_ㅣ_1.svg",
]