import { atom } from "recoil";

export const inputState = atom({
  key: 'inputState',
  default: ''
});

export const lastCharState = atom({
  key: 'lastCharState',
  default: undefined
})

export const attemptsState = atom({
  key: 'attemptsState',
  default: 0
})