import React from "react";
import { Checkbox, Switch } from "pretty-checkbox-react";

import "./CheckBox.scss";
import styled from "styled-components";

function App({checked, setChecked, ...props}) {

  const handleChange = function() {
    setChecked((prev) => !prev);
  };

  // or useReducer for shorthand...
  // const [checked, handleChange] = React.useReducer(state => !state, false);

  React.useEffect(() => {
    if (checked) {
      // perform some side effect
      // when the state changes
    }
  }, [checked]);

  return (
    <div {...props}>
      <Switch  color="primary" shape="fill" state={checked} onChange={handleChange} onMouseDown={(e) => {
        e.preventDefault()
      }}>
        Hide Keyboard
      </Switch>
    </div>
  );
}

export default styled(App)`
  color: #606060;
  font-weight: bold;
`