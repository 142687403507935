import React, { useState, useEffect } from "react";

import styled from "styled-components";

function sum( obj ) {
  var sum = 0;
  for( var el in obj ) {
    if( obj.hasOwnProperty( el ) ) {
      sum += parseFloat( obj[el] );
    }
  }
  return sum;
}

function Level({ setLevel, level, streak, setInput, ...props }) {
  const [count, setCount] = useState(0);
  useEffect(() => {
      let percents = [];
      for (let s in streak) {
        percents.push(Math.max(Math.min(streak[s] / 10 * 100, 100) / Object.keys(streak).length, 0))
      }
      setCount(sum(percents));
  }, [streak]);

  useEffect(() => {
    document.getElementsByClassName("grid2")[0].scrollLeft =
            (document.getElementsByClassName("current")[0].offsetWidth / 2 +
            document.getElementsByClassName("current")[0].offsetLeft -
            document.getElementsByClassName("grid2")[0].offsetWidth / 2) + 8;
  }, [level])

  return (
    <div {...props}>
      <div className="grid2">
          <div className="scroller2">
            <span className="margin2"></span>
            {
              [...Array(26)].map((l, i) => {
                return <button onClick={() => {
                  setLevel(i+1)
                  setInput("")
                }} className={`${level - 1 > i  ? "active2" : ""} ${level - 1 === i ? "current" : ""}`}>
                  {
                    level - 1 === i ?
                      <span>
                        LEVEL {i + 1}
                      </span>
                    : <span>
                      {i + 1}
                    </span>
                    
                  }
                </button>
              })
            }
        </div>
      </div>
      <div className="progress">
        <progress max="100" value={count}>
          <div class="progress-bar">
            <span style={{ width: "0%" }}>0%</span>
          </div>
        </progress>
      </div>
    </div>
  );
}

export default styled(Level)`
  text-align: center;
  color: #00A7FF;
  font-weight: 900;
  font-family: NanumGothic;

  .grid2 {
    position: relative;
    z-index: 10000;
  }

  .margin2 {
    width: 100px;
    height: 1px;
  }

  button {
    min-width: 30px;
    max-width: 120px;
    text-decoration: none;
    display: inline-block;
    outline: none;
    cursor: pointer;
    border-style: none;
    border-radius: 100px;
    overflow: none;
    text-align: center;
    padding: 0;
    margin-right: 8px;

    color: #606060;
    border: 1px solid #606060;
    background: #283539;

    &.active2 {
      color: #1CA7FC;
      background-color: #1B5A7A;
      border: 1px solid #1CA7FC;
    }

    &.current {
      background: #815421;
      border: 1px solid #F2A64F;
      color: #F2A64F;
      border-radius: 100px;
      height: 100%;
      span {
        max-width: 100%;
        width: 100%;
        padding: 0px 8px;
      }
    }

    &.current::before {
      padding-top: 0%;
    }

    &::before {
      content: '';
      display: inline-block;;
      vertical-align: middle;
      padding-top: 100%;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      max-width: 80%;
    }
  }

  h3 {
    padding: 24px;
  }

  .progress {
    margin: 0px 24px;
  }

  progress {
    overflow: hidden;
    border-radius: 100px;
  }

  progress[value], progress::-webkit-progress-bar {
    appearance: none;
    
    border: none;
    
    width: 100%; height: 20px;
    max-width: 500px;

    background-color: rgba(96, 96, 96, 0.33);
    border-radius: 100px;
    box-shadow: 0 0px 0px 2px #606060 inset;
    /* border: 2px solid #606060; */
    
    color: #00A7FF;
    
    position: relative;
    margin: 0 0 1.5em; 
  }

  progress[value]::-moz-progress-bar { 
    background: rgba(0, 167, 255, 0.33);
    border-radius: 100px; 
    border: 2px solid #00A7FF;
  }

  progress::-webkit-progress-value {
    background: rgba(0, 167, 255, 0.33);
    border-radius: 100px; 
    border: 2px solid #00A7FF;
  }

  @keyframes animate-stripes { 100% { background-position: -100px 0; } }

  /* Fallback technique styles */
  .progress-bar {
      background-color: red;
      border-radius: 100px;
      box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;

      /* Dimensions should be similar to the parent progress element. */
      width: 100%; height:20px;
  }

  .progress-bar span {
      background-color: #00A7FF;
      border-radius: 100px;
      
      display: block;
      text-indent: -9999px;
  }

  p[data-value] { 
    
    position: relative; 
  }

  p[data-value]:after {
      content: attr(data-value) '%';
      position: absolute; right:0;
  }

  .scroller, .scroller2 {
    .margin, .margin2 {
      content: "";
      display: inline-block;
      transition: 0s ease 0s;
    }
    margin-bottom: 16px;
    margin-left: calc(50% - 0px);
    height: 30px;
    overflow: visible;
    white-space: nowrap;
    padding-right: 1000000%;
    > div {
      display: inline-block;
    }
  }

  .grid, .grid2 {
    overflow: hidden;
  }
`;
