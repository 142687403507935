import React from "react";
import ReactDOM from "react-dom";

import {RecoilRoot} from "recoil";
import App from "./App.jsx";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App></App>
    </RecoilRoot>
  </React.StrictMode>,
  rootElement
);
