import React from "react";

import Game from "./pages/typing/TypingPage";

import styled from "styled-components";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import FlashcardPage from "./pages/flashcard/FlashcardPage";
import WordlePage from "./pages/flashcard/WordlePage";

function App(props) {
  return <div className={props.className}>
    <a href="https://twitter.com/HangulCool" target="_blank" rel="noopener noreferrer">Follow @HangulCool</a>
    <a href="/">Korean Typing</a>
    <a href="/wordle">Korean Wordle [new]</a>
    <Router>
      <Switch>
        <Route path="/flash">
          <FlashcardPage />
        </Route>
        <Route path="/wordle">
          <WordlePage />
        </Route>
        <Route path="/">
          <Game />
        </Route>
      </Switch>
    </Router>
  </div>
}

export default styled(App)`
    display: flex;
    justify-content: space-between;
    height: 100vh;
    flex-direction: column;

  > a {
    display: block;
    color: #606060;
    padding: 8px;
    text-decoration: none;
    width: max-content;

    &:hover {
      color: #00A7FF;
    }
  }
`